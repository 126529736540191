import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch, connect } from 'react-redux';
import Spinner from 'reactstrap/lib/Spinner';
import { filter } from 'lodash';

import { Helmet } from 'react-helmet';
import { map } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import NavLink from 'reactstrap/lib/NavLink';

import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';
import CustomModal from '../../Components/Components/Modal';
import LandScapeLayoutMobileHeader from '../Layout/Mobile/LandScapeLayoutMobileHeader';

// import * as constants from '../../../../../constants/constants';
// import * as constants from '../../../../../constants/constants';
// import * as constants from '../../../../../constants/constants';


import coin from '../../../../../images/redesign/common/coin.svg';
import rating from '../../../../../images/redesign/common/rating.svg';
import infoImg from '../../../../../images/redesign/player/info.svg';
import lightInfoImg from '../../../../../images/redesign/light-mode/player/info.svg';
import proIcon from '../../../../../images/icons/proIcon.svg';
import defaultImage from '../../../../../images/redesign/common/default_image.svg';
import lightDefaultImage from '../../../../../images/redesign/light-mode/common/default_image.svg';

import { getRankingsData } from '../../../../../actions/leaderboard';
import { POWER_RANKINGS_FILTER, POWER_RANKINGS_SUB_MENU, POWER_RANKINGS_SWITCH_OPTION, SCREEN_MODE, MENU_NAVIGATION } from '../../../../../constants/constants';

const PowerRankings = ({ 
  screenMode,
  parentChangeTab,
  showUserSettingsModal,
  OpenMobileDropdownModal,
  handleClickStart,
  checked,
  togglePWOption,
  parentActiveTab: activeTab,
  parentChangeTab: setActiveTab
}) => {
  const { t } = useTranslation('common');
  const intvl = useRef(null);
  const dispatch = useDispatch();

  const rankings = useSelector(state => state.leaderboard.rankings);
  const isLoading = useSelector(state => state.leaderboard.isLoading);

  const [infoModal, setInfoModal] = useState(false);

  useEffect(() => {
    dispatch(getRankingsData(checked, menuNavIdToKey(activeTab)));
  }, [checked, activeTab]);

  const menuNavIdToKey = (menuNavId) => {
    return POWER_RANKINGS_FILTER.filter(item => item.id === menuNavId)[0].key;
  }
  /* useEffect(() => {
    const curTab = filter(constants.POWER_RANKINGS_FILTER, item => (item.id === activeTab))[0].key;
    dispatch(getRankingsData(checked, curTab));
  }, [checked, activeTab]); */
  const handleChange = (e) => {
    if (!checked) {
      if (activeTab === MENU_NAVIGATION.powerRankingsWinsCount) {
        console.log('call setActiveTab');
        setActiveTab(MENU_NAVIGATION.powerRankingsLossCount);
        console.log('call setActiveTab');
      } else if (activeTab === MENU_NAVIGATION.powerRankingsWinPercentage) {
        setActiveTab(MENU_NAVIGATION.powerRankingsLossPercentage);
      }
    } else {
      if (activeTab === MENU_NAVIGATION.powerRankingsLossCount) {
        console.log('call setActiveTab');
        setActiveTab(MENU_NAVIGATION.powerRankingsWinsCount);
      } else if (activeTab === MENU_NAVIGATION.powerRankingsLossPercentage) {
        console.log('call setActiveTab');
        setActiveTab(MENU_NAVIGATION.powerRankingsWinPercentage);
      }
    }
    togglePWOption();
  };

  /*
  const changeTab = (tab) => {
    if (activeTab !== tab) {
      parentChangeTab(tab);
      // const tabItem = filter(constants.POWER_RANKINGS_SUB_NAVIGATION)
      // parentChangeTab()
      const element = document.getElementById(filter(MENU_NAVIGATION_TYPE, item => (item.id === activeTab.toString()))[0].label);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }; */

  const changeTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);

      /* const element = document.getElementById(filter(constants.POWER_RANKINGS_SUB_NAVIGATION, item => (item.key === activeTab.toString()))[0].label);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } */
    }
  };

  const changeTabWrapper = (tab) => {
    parentChangeTab(tab);
    changeTab(/*getTabKeyByValue(tab)*/tab);
  }

  const getTabKeyByValue = (value) => {
    for (const key in POWER_RANKINGS_SUB_MENU) {
      if (POWER_RANKINGS_SUB_MENU[key] === value) {
        return key;
      }
    }
    return null;
  }

  const handleInputRef = () => {
    intvl.current.click();
  };

  const renderRating = (t, rating) => (
    <div className="my-info-ratings-step-wrapper">
      {rating < 10 ? (<div className="my-info-ratings-step my-info-ratings-step-weak my-info-ratings-step-active power-rankings-level-step">{t('myInfo.weak')}</div>) : (null)}
      {(rating >= 10 && rating < 20) ? (<div className="my-info-ratings-step my-info-ratings-step-beginner my-info-ratings-step-active power-rankings-level-step">{t('myInfo.beginner')}</div>) : (null)}
      {(rating >= 20 && rating < 30) ? (<div className="my-info-ratings-step my-info-ratings-step-untrained my-info-ratings-step-active power-rankings-level-step">{t('myInfo.untrained')}</div>) : (null)}
      {(rating >= 30 && rating < 40) ? (<div className="my-info-ratings-step my-info-ratings-step-medium my-info-ratings-step-active power-rankings-level-step">{t('myInfo.medium')}</div>) : (null)}
      {(rating >= 40 && rating < 50) ? (<div className="my-info-ratings-step my-info-ratings-step-good my-info-ratings-step-active power-rankings-level-step">{t('myInfo.good')}</div>) : (null)}
      {(rating >= 50 && rating < 60) ? (<div className="my-info-ratings-step my-info-ratings-step-great my-info-ratings-step-active power-rankings-level-step">{t('myInfo.great')}</div>) : (null)}
      {(rating >= 60 && rating < 70) ? (<div className="my-info-ratings-step my-info-ratings-step-fan my-info-ratings-step-active power-rankings-level-step">{t('myInfo.fan')}</div>) : (null)}
      {rating >= 70 ? (<div className="my-info-ratings-step my-info-ratings-step-pro my-info-ratings-step-active"><Media src={proIcon} alt="X" /></div>) : (null)}
    </div>
  );

  const toggleInfoModal = () => {
    setInfoModal(!infoModal);
  };
console.log(activeTab, "pwActiveTab:")
  return (
    <div className="layout-mobile-body layout-mobile-body-power-rankings-page">
      <Helmet>
        <title>
          Zole - {t('route.powerRankings')}
        </title>
      </Helmet>
      <div className="layout-mobile-body-top">
        <Row className="layout-mobile-subheader d-none">
          <Col xs="12" className="layout-mobile-subheader-navmenu">
            <div className="layout-mobile-subheader-navmenu-link" xs={3} id="powerRankings">
              <NavLink
                color="link"
                className={classNames('layout-mobile-subheader-navmenu-link-text', {
                  'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.powerRankings,
                })}
                onClick={() => changeTab(MENU_NAVIGATION.powerRankings)}
              >
                {t('powerRankings.rating')}
              </NavLink>
            </div>
            <div className="layout-mobile-subheader-navmenu-link power-rankings-points-button" xs={3} id="powerRankingsPoints">
              <NavLink
                color="link"
                className={classNames('layout-mobile-subheader-navmenu-link-text', {
                  'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.powerRankingsPoints,
                })}
                onClick={() => changeTab(MENU_NAVIGATION.powerRankingsPoints)}
              >
                {t('powerRankings.points')}
              </NavLink>
            </div>
            <div className="layout-subheader-navmenu-link power-rankings-coins-button" xs={3} id="powerRankingsCoins">
              <NavLink
                color="link"
                className={classNames('layout-mobile-subheader-navmenu-link-text', {
                  'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.powerRankingsCoins,
                })}
                onClick={() => changeTab(MENU_NAVIGATION.powerRankingsCoins)}
              >
                {t('powerRankings.coins')}
              </NavLink>
            </div>
            {
              !checked ? (
                <>
                  <div className="layout-mobile-subheader-navmenu-link power-rankings-wins-count-button" xs={3} id="powerRankingsWinsCount">
                    <NavLink
                      color="link"
                      className={classNames('layout-mobile-subheader-navmenu-link-text', {
                        'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.powerRankingsWinsCount,
                      })}
                      onClick={() => changeTab(MENU_NAVIGATION.powerRankingsWinsCount)}
                    >
                      {t('powerRankings.winsCount')}
                    </NavLink>
                  </div>
                  <div className="layout-mobile-subheader-navmenu-link power-rankings-win-percentage-button" xs={3} id="powerRankingsWinPercentage">
                    <NavLink
                      color="link"
                      className={classNames('layout-mobile-subheader-navmenu-link-text', {
                        'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.powerRankingsWinPercentage,
                      })}
                      onClick={() => changeTab(MENU_NAVIGATION.powerRankingsWinPercentage)}
                    >
                      {t('powerRankings.winPercentage')}
                    </NavLink>
                  </div>
                </>
              ) : (
                <>
                  <div className="layout-mobile-subheader-navmenu-link power-rankings-loss-count-button" xs={3} id="powerRankingsLossCount">
                    <NavLink
                      color="link"
                      className={classNames('layout-mobile-subheader-navmenu-link-text', {
                        'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.powerRankingsLossCount,
                      })}
                      onClick={() => changeTab(MENU_NAVIGATION.powerRankingsLossCount)}
                    >
                      {t('powerRankings.lossCount')}
                    </NavLink>
                  </div>
                  <div className="layout-mobile-subheader-navmenu-link power-rankings-loss-percentage-button" xs={3} id="powerRankingsLossPercentage">
                    <NavLink
                      color="link"
                      className={classNames('layout-mobile-subheader-navmenu-link-text', {
                        'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.powerRankingsLossPercentage,
                      })}
                      onClick={() => changeTab(MENU_NAVIGATION.powerRankingsLossPercentage)}
                    >
                      {t('powerRankings.lossPercentage')}
                    </NavLink>
                  </div>
                </>
              )
            }
          </Col>
        </Row>
      </div>
      <div className="layout-mobile-body-main" style={{display: 'flex', flexDirection: 'column'}}>
        <div className="layout-mobile-body-main-title d-none">{t('route.powerRankings')}</div>
        <Row className="mt-3 new-power-rankings-header-section">
          <Col xs="12" sm="12" className="new-power-rankings-header-switch-section new-power-rankings-header-switch-section-joyride align-items-center mt-3 ml-0 mr-0">
            <Label className={(checked) ? 'disable' : 'active'}>{t(`powerRankings.${POWER_RANKINGS_SWITCH_OPTION.best}`)}</Label>
            <Label className="new-switch-wrapper" onClick={() => handleInputRef}>
              <input
                type="checkbox"
                name="switchOption"
                checked={checked}
                ref={intvl}
                onClick={e => handleChange(e)}
                disabled={isLoading}
              />
              <span
                className={classNames('switch', {
                  'switch-best': checked,
                  'switch-worst': !checked,
                })}
              >
                <span className="switch-handle" />
              </span>
            </Label>
            <Label className={(checked) ? 'active' : 'disable'}>{t(`powerRankings.${POWER_RANKINGS_SWITCH_OPTION.worst}`)}</Label>
          </Col>
          <div className="new-power-rankings-header-tutorial">
            <Media src={screenMode === SCREEN_MODE.light ? lightInfoImg : infoImg} onClick={() => toggleInfoModal()} />
          </div>
        </Row>
        { isLoading ? (
          <div className="new-power-rankings-list-part-loading d-flex align-items-center">
            <Spinner color="light" style={{ width: '5rem', height: '5rem' }} />
          </div>
        ) : (
          <div
            className="layout-body-main-scrollarea layout-mobile-body-main-scrollarea new-power-rankings-scrollarea"
            contentClassName="layout-body-main-scrollarea-body"
            show
            rightOffset={0}
            topOffset={30}
            bottomOffset={0}
            // noScrollButton
          >
            <div className="new-power-rankings-body new-power-rankings-body-joyride">
              {
                map(rankings, (item, key) => (
                  <div
                    className={classNames('new-power-rankings-body-item', {
                      'new-power-rankings-body-item-lg': key === 0,
                      'new-power-rankings-body-item-md': key === 1,
                      'new-power-rankings-body-item-sm': key === 2,
                      'new-power-rankings-body-item-lg-no-stars': key === 0 && checked,
                      'new-power-rankings-body-item-second-row': [3,4,5].includes(key),
                      'new-power-rankings-body-item-remaining-rows': key > 5,
                    })}
                  >
                    <div className="new-power-rankings-body-item-label">
                      <Label className={` aaaa ${[0,1,2].includes(key) ? 'new-power-rankings-body-item-label-key-top' : 'new-power-rankings-body-item-label-key'}`}>{ key + 1 }</Label>
                      <Label className="new-power-rankings-body-item-label-addition">
                        {/**"MENU_NAVIGATION.powerRanking" */}
                        {
                          activeTab !== MENU_NAVIGATION.powerRankingsLossPercentage && activeTab !== MENU_NAVIGATION.powerRankingsWinPercentage ? (
                            !checked ? (
                              `+ ${item[POWER_RANKINGS_FILTER.filter(tab => tab.id === activeTab)[0].filter] || 0}`
                            ) : (
                              item[POWER_RANKINGS_FILTER.filter(tab => tab.id === activeTab)[0].filter] || 0
                            )
                          ) : (
                            `${Number.parseFloat(item[POWER_RANKINGS_FILTER.filter(tab => tab.id === activeTab)[0].filter] * 100).toFixed(2) || 0}%`
                          )
                        }
                      </Label>
                    </div>
                    <div className="new-power-rankings-body-item-photo">
                      <div className="player-info-player-background">
                        <div className="player-info-player-image-wrapper d-flex flex-column align-items-center">
                          <div style={{ backgroundImage: item.photo ? `url(${item.photo})` : (screenMode === SCREEN_MODE.light ? `url(${lightDefaultImage})` : `url(${defaultImage})`), backgroundPosition: 'center', backgroundSize: item.photo ? '100%, 100%' : '80%, 93%' }} className="player-info-player-image" />
                          <div className="player-info-player-image-level">
                            {renderRating(t, item.lvl || 10)}
                          </div>
                        </div>
                        <div className="player-info-player-name-wrapper power-ranking-list-player-name">
                          <div className="player-info-player-name ml-1 mr-1">
                            {item.name || 'Zole'}
                          </div>
                        </div>
                        {/* <div className="new-power-rankings-body-item-score">
                          <div className="new-power-rankings-body-item-score-section d-flex align-items-center">
                            <Media className="ml-2 mr-1" src={coin} alt="zole" />
                            <Label className="mr-2">{item.totalBal || 1600}</Label>
                            <Media className="mr-1" src={rating} alt="zole" />
                            <Label className="mr-2">{item.totalRating || '--' }</Label>
                          </div>
                        </div> */}

                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        )}

      </div>
      <CustomModal
        isOpen={infoModal}
        className="new-version-modal"
        toggle={toggleInfoModal}
        size="md"
        title={t('powerRankings.infoHeader')}
        body={(
          <div className="new-power-rankings-body-modal-content">
            <p className="mb-3">{t('powerRankings.infoContent1')}</p>
            <p className="mb-3">{t('powerRankings.infoContent2')}</p>
            <p className="mb-3">{t('powerRankings.infoContent3')}</p>
          </div>
        )}
        footer={(
          <Button color="secondary" onClick={toggleInfoModal}>
            {t('common:common.close')}
          </Button>
        )}
      />
    </div>
  );
};

export default PowerRankings;

