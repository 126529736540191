import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Moment from 'react-moment';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

class MissedRoomsTotals extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    missedTurnsCount: PropTypes.arrayOf(PropTypes.shape({})),
    missedTurnsData: PropTypes.shape({}),
    fetchmissedTurnsCount: PropTypes.func.isRequired,
    fetchmissedTurnsData: PropTypes.func.isRequired,
  }

  static defaultProps = {
    member: {},
    missedTurnsCount: [],
    missedTurnsData: {},
  }

  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount() {

  }

  render() {
    const { t, bets, states, partyEnded, handEnded, speeds, roomsCount } = this.props;

  //  console.log('MissedRoomsTotals', { bets, states, partyEnded, handEnded, speeds });

    return (
      <Fragment>
        <Row>
          <Col>
            {`Total Counts - ${roomsCount || 0}`}
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <Row>
              <Col>
                Rates
              </Col>
            </Row>
            <Row>
              <Col>
                <LineChart
                  width={400}
                  height={200}
                  data={bets}
                  margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                  <XAxis dataKey="key" stroke="#FFF"/>
                  <YAxis stroke="#FFF" />
                  <Tooltip />
                  <CartesianGrid stroke="#f5f5f5" />
                    <Line type="monotone" dataKey="value" stroke="#ff7300" />
                </LineChart>
              </Col>
            </Row>
          </Col>
          <Col sm="4">
            <Row>
              <Col>
                Game Type
              </Col>
            </Row>
            <Row>
              <Col>
                <LineChart
                  width={400}
                  height={200}
                  data={states}
                  margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                  <XAxis dataKey="key" stroke="#FFF" />
                  <YAxis stroke="#FFF" />
                  <Tooltip />
                  <CartesianGrid stroke="#f5f5f5" />
                    <Line type="monotone" dataKey="value" stroke="#ff7300" />
                </LineChart>
              </Col>
            </Row>
          </Col>
          <Col sm="4">
            <Row>
              <Col>
                Games Played
              </Col>
            </Row>
            <Row>
              <Col>
                <LineChart
                  width={400}
                  height={200}
                  data={partyEnded}
                  margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                  <XAxis dataKey="key" stroke="#FFF" />
                  <YAxis stroke="#FFF" />
                  <Tooltip />
                  <CartesianGrid stroke="#f5f5f5" />
                    <Line type="monotone" dataKey="value" stroke="#ff7300" />
                </LineChart>
              </Col>
            </Row>
          </Col>
          <Col sm="4">
            <Row>
              <Col>
                Hand Number
              </Col>
            </Row>
            <Row>
              <Col>
                <LineChart
                  width={400}
                  height={200}
                  data={handEnded}
                  margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                  <XAxis dataKey="key" stroke="#FFF" />
                  <YAxis stroke="#FFF" />
                  <Tooltip />
                  <CartesianGrid stroke="#f5f5f5" />
                    <Line type="monotone" dataKey="value" stroke="#ff7300" />
                </LineChart>
              </Col>
            </Row>
          </Col>
          <Col sm="4">
            <Row>
              <Col>
                Room speed
              </Col>
            </Row>
            <Row>
              <Col>
                <LineChart
                  width={400}
                  height={200}
                  data={speeds}
                  margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                  <XAxis dataKey="keyVal" stroke="#FFF" />
                  <YAxis stroke="#FFF" />
                  <Tooltip />
                  <CartesianGrid stroke="#f5f5f5" />
                    <Line type="monotone" dataKey="value" stroke="#ff7300" />
                </LineChart>
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default  withTranslation('admin')(MissedRoomsTotals);
