import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';

import CustomModal from '../../Components/Components/ModalQuestion';

import buttonClickedSound from '../../../../../sounds/click_feedback.flac';

import {
  quitRound,
} from '../../../../../actions/game';
import IconPopover from '../../Components/Components/IconPopover';

class QuitRound extends React.Component {
  static propTypes = {
    quitRoundFunction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    soundOn: PropTypes.bool,
    enabled: PropTypes.bool,
    roomId: PropTypes.string,
    mWidth: PropTypes.number,
  };

  static defaultProps = {
    soundOn: false,
    enabled: false,
    roomId: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      quitPopoverOpen: false,
    };

    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentDidMount() {

  }

  /* quitRound = () => {
    const { quitRound, notificationSettings } = this.props;

    if (notificationSettings && notificationSettings.quitRound === false) {
      quitRound();
      this.playButtonSound();
      this.setState({ quitRound: true });
    } else {
      this.setState({ quitRoundModal: true });
    }
  }; */

  approve = () => {
    const { quitRoundFunction, roomId } = this.props;

    quitRoundFunction(roomId);
    this.playButtonSound();
    this.setState({ modalOpen: false });
  }

  openModal = () => {
    console.log('openModal');
    this.setState({ modalOpen: true });
  }

  closeModal = () => {
    this.setState({ modalOpen: false });
  }

  playButtonSound = () => {
    const { soundOn } = this.props;

    if (soundOn) {
      const playPromise = this.buttonClickedAudio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            // Automatic playback started!
            // Show playing UI.
            console.log("audio played auto");
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
            console.log("playback prevented");
          });
      }
    }
  }

  setQuitPopoverOpen = (isOpen) => {
    this.setState({quitPopoverOpen: isOpen});
  }

  render() {
    const {
      t,
      enabled,
    } = this.props;

    const { modalOpen, quitPopoverOpen } = this.state;

    console.log('modalOpen', { modalOpen });

    if (!enabled) {
      return null;
    }

    return (
      <Fragment>
        <div className="quit-round-icon" id="quit-round-popover" onClick={this.openModal} onMouseEnter={() => this.setQuitPopoverOpen(true)} onMouseLeave={() => this.setQuitPopoverOpen(false)}/>
        <IconPopover text={t('common:popovers.quitRound')} placement="top" targetId="quit-round-popover" popoverOpen={quitPopoverOpen} />
        <CustomModal
          isOpen={modalOpen}
          toggle={this.closeModal}
          size="md"
          verticalCentering
          largeText
          title={t('quitRound')}
          footer={(
            <>
              <Button className="question-modal-approve-btn" onClick={this.approve}>
                {t('yes')}
              </Button>
              <Button className="question-modal-decline-btn" onClick={this.closeModal}>
                {t('no')}
              </Button>
            </>
          )}
          body={(
            <p>
              {t('quitRoundConfirm')}
            </p>
          )}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  notificationSettings: (state.userSettings[state.member.uid] && state.userSettings[state.member.uid].notificationSettings) ? (state.userSettings[state.member.uid].notificationSettings) : (state.userSettings.default ? state.userSettings.default.notificationSettings : null),
  uid: state.member.uid,
});

const mapDispatchToProps = {
  quitRoundFunction: quitRound,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('game')(QuitRound));
