import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import { Button, Label } from 'reactstrap';

import slide1Img from '../../../../../images/redesign/landing-view/slide_1.webp';
import slide2Img from '../../../../../images/redesign/landing-view/slide_2.webp';
import slide3Img from '../../../../../images/redesign/landing-view/slide_3.webp';

const RegisterSlide = ({ goToRegister, isLoggedIn }) => {
  const { t } = useTranslation('common');

  const slideHeight = 370;
  const slideWidth = 470;

  let latestUid = null;

  try {
    latestUid = localStorage.getItem('latestUid') || null; //x
  } catch (err) {
    latestUid = 'null';
    console.log(err);
  }

  return (
    <div className="sign-row-slide">
      <Splide
        options={{
          type: 'loop',
          perPage: 1,
          rewind: true,
          //  gap: '20',
          // width: slideWidth,
          // height: slideHeight,
          pauseOnHover: false,
          // fixedWidth: slideWidth,
          // fixedHeight: slideHeight,
          isNavigation: false,
          // gap: 30,
          focus: 'center',
          pagination: true,
          // cover       : true,
          arrows: true,
          autoplay: true,
          trimSpace: false,
        }}
      >
        <SplideSlide>
          <img className="slide-img" src={slide1Img} alt="" />
          <div className="sign-row-slide-content">
            <div className="sign-row-slide-content-title">
              {t('home.tournament')}
            </div>
            <div className="sign-row-slide-content-description">
              {t('home.tournamentDescription')}
            </div>
            {
              (!isLoggedIn && latestUid === 'null') && (
                <div className="sign-row-slide-content-button mt-1">
                  <Button
                    className="sign-row-content-register-button d-flex align-items-center"
                    onClick={goToRegister}
                  >
                    <Label>{t('home.register')}</Label>
                  </Button>
                </div>
              )
            }
            <div className="sign-row-slide-content-footer mt-1">
              {t('home.tournamentDate')}
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <img className="slide-img" src={slide2Img} alt="" />
          <div className="sign-row-slide-content">
            <div className="sign-row-slide-content-title">
              {t('home.tournament')}
            </div>
            <div className="sign-row-slide-content-description">
              {t('home.tournamentDescription')}
            </div>
            {
              (!isLoggedIn && latestUid === 'null') && (
                <div className="sign-row-slide-content-button mt-1">
                  <Button
                    className="sign-row-content-register-button d-flex align-items-center"
                    onClick={goToRegister}
                  >
                    <Label>{t('home.register')}</Label>
                  </Button>
                </div>
              )
            }

            <div className="sign-row-slide-content-footer mt-1">
              {t('home.tournamentDate')}
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <img className="slide-img" src={slide3Img} alt="" />
          <div className="sign-row-slide-content">
            <div className="sign-row-slide-content-title">
              {t('home.tournament')}
            </div>
            <div className="sign-row-slide-content-description">
              {t('home.tournamentDescription')}
            </div>
            {
              (!isLoggedIn && latestUid === 'null') && (
                <div className="1">
                  <Button
                    className="sign-row-content-register-button d-flex align-items-center"
                    onClick={goToRegister}
                  >
                    <Label>{t('home.register')}</Label>
                  </Button>
                </div>
              )
            }

            <div className="sign-row-slide-content-footer mt-1">
              {t('home.tournamentDate')}
            </div>
          </div>
        </SplideSlide>
      </Splide>
    </div>
  );
};

RegisterSlide.propTypes = {
  goToRegister: PropTypes.func.isRequired,
};

export default RegisterSlide;
