import React, {useState} from 'react';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Progress from 'reactstrap/lib/Progress';
import StatisticsInfo from './StatisticsInfo';

import { useTranslation } from 'react-i18next';
import infoImg from '../../../../../../../images/redesign/player/info.svg';
import lightInfoImg from '../../../../../../../images/redesign/light-mode/player/info.svg';

import * as constants from '../../../../../../../constants/constants';

const Statistics = ({ member, betterPos, betterGPlayed, betterGWon, betterTotalPnts, betterBal, screenMode}) => {
  const { t } = useTranslation('common');

  const [toggleStatisticsInfo, setToggleStatisticsInfo] = useState(false);
  // Tavs Reitings

  const toggleStatisticsModal = () => {
    setToggleStatisticsInfo(prevToggleStatisticsInfo => !prevToggleStatisticsInfo);
  };

  return (
    <Row className="my-info-statistics mt-3">
      <Col xs="12" className="my-info-statistics-title">
        {t('myInfo.statistics')}
      </Col>
      <Col xs="12">
        <Row>
          <Col>
            <div>
              {`${t('common.ratings')}:`}
              <label className="ml-1 my-info-statistics-data">{`${member.ratings || 0}`}</label>
            </div>
            <div>
              <Progress
                color="link"
                value={member.ratings}
                className="my-info-statistics-progressbar"
              />
            </div>
            <div className="my-info-statistics-percent">
              {t('common.betterThan')}: <label>{member.ratings || 0}%</label> {t('common.players')}
            </div>
          </Col>
          <Col>
            <div>
              {`${t('common.position')}:`}
              <label className="ml-1 my-info-statistics-data">{`${member.position || 0}`}</label>
            </div>
            <div>
              <Progress
                color="link"
                value={betterPos}
                className="my-info-statistics-progressbar"
              />
            </div>
            <div className="my-info-statistics-percent">
              {t('common.betterThan')}: <label>{betterPos || 0}%</label> {t('common.players')}
            </div>
          </Col>
          <Col>
            <div>
              {`${t('common.parties')}:`}
              <label className="ml-1 my-info-statistics-data">{`${member.gamesPlayed || 0}`}</label>
            </div>
            <div>
              <Progress
                color="link"
                value={betterGPlayed}
                className="my-info-statistics-progressbar"
              />
            </div>
            <div className="my-info-statistics-percent">
              {t('common.betterThan')}: <label>{betterGPlayed || 0}%</label> {t('common.players')}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              {`${t('common.wins')}:`}
              <label className="ml-1 my-info-statistics-data">{`${member.gamesWon || 0}`}</label>
            </div>
            <div>
              <Progress
                color="link"
                value={betterGWon}
                className="my-info-statistics-progressbar"
              />
            </div>
            <div className="my-info-statistics-percent">
              {t('common.betterThan')}: <label>{betterGWon || 0}%</label> {t('common.players')}
            </div>
          </Col>
          <Col>
            <div>
              {`${t('common.points')}:`}
              <label className="ml-1 my-info-statistics-data">{`${member.totalPoints || 0}`}</label>
            </div>
            <div>
              <Progress
                color="link"
                value={betterTotalPnts}
                className="my-info-statistics-progressbar"
              />
            </div>
            <div className="my-info-statistics-percent">
              {t('common.betterThan')}: <label>{betterTotalPnts || 0}%</label> {t('common.players')}
            </div>
          </Col>
          <Col>
            <div>
              {`${t('common.balance')}:`}
              <label className="ml-1 my-info-statistics-data">{`${member.balance || 0}`}</label>
            </div>
            <div>
              <Progress
                color="link"
                value={betterBal}
                className="my-info-statistics-progressbar"
              />
            </div>
            <div className="my-info-statistics-percent">
              {t('common.betterThan')}: <label>{betterBal || 0}%</label> {t('common.players')}
            </div>
          </Col>
        </Row>
      </Col>
      <Media className="my-info-statistics-icon" src={screenMode === constants.SCREEN_MODE.light ? lightInfoImg : infoImg} alt="x" onClick={() => toggleStatisticsModal()} />
      {
        toggleStatisticsInfo && (
          <StatisticsInfo toggleStatisticsInfo={toggleStatisticsInfo} toggleStatisticsModal={toggleStatisticsModal} />
        )
      }
    </Row>
  );
};

Statistics.propTypes = {
//  member: PropTypes.shape(),
//  t: PropTypes.func.isRequired,
};

Statistics.defaultProps = {
//  member: {},
};

export default Statistics;

/*
class Statistics extends React.Component {
  static propTypes = {
    member: PropTypes.shape(),
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    member: {},
  }

  constructor(props) {
    super(props);
    this.state = {
    //  pointsProgressBar: 0,
    };
  }

  componentDidMount() {
  //  const { member } = this.props;
  }

  render() {
    const {
      member, t,
    } = this.props;

    return (
      <Row className="my-info-statistics">
        <Col xs="12" className="my-info-statistics-title">
          Statistika
        </Col>
        <Col xs="12">
          <Row>
            <Col>
              {`${t('common.ratings')}: ${member.position}`}
              <div>
                <Progress
                  color="link"
                  value={65}
                  className="my-info-statistics-progressbar"
                />
              </div>
            </Col>
            <Col>
              {`${t('common.position')}: ${member.position}`}
              <div>
                <Progress
                  color="link"
                  value={65}
                  className="my-info-statistics-progressbar"
                />
              </div>
            </Col>
            <Col>
              {`${t('common.parties')}: ${member.gamesPlayed}`}
              <div>
                <Progress
                  color="link"
                  value={65}
                  className="my-info-statistics-progressbar"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {`${t('common.wins')}: ${member.gamesWon}`}
              <div>
                <Progress
                  color="link"
                  value={65}
                  className="my-info-statistics-progressbar"
                />
              </div>
            </Col>
            <Col>
              {`${t('common.points')}: ${member.totalPoints}`}
              <div>
                <Progress
                  color="link"
                  value={65}
                  className="my-info-statistics-progressbar"
                />
              </div>
            </Col>
            <Col>
              {`${t('common.level')}: ${member.level}`}
              <div>
                <Progress
                  color="link"
                  value={65}
                  className="my-info-statistics-progressbar"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}


export default Statistics; */
