import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withTranslation } from 'react-i18next';

const DealingNewCards = React.memo(({
  t, show, isMobile,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className={classNames('dealingNewCardsText', {
      'dealingNewCardsText-mobile': isMobile,
    })}>
      {t('dealingNewCards')}
    </div>
  );
});

DealingNewCards.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool,
  isMobile: PropTypes.bool,
};

DealingNewCards.defaultProps = {
  show: false,
  isMobile: false,
};

export default withTranslation('game')(DealingNewCards);
