import React from 'react';
import PropTypes from 'prop-types';

const AdministrationChat = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
          Gan sākumlapā, gan istabas skatā ir poga Tehniskā Palīdzība, ar kuru var atvērt saraksti ar spēles administrāciju. Šeit var rakstīt gan par spēles problēmām, ziņot par kļūdām, izteikt idejas u.c. Atbilde parasti tiek sniegta līdz 3 darba dienu laikā. Čata sarakste tiek glabāta 14 dienas.
        </p>
        <p>
          Atceries: atkārtota ziņu sūtīšana nepaātrinās atbildi, tāpat arī uz rupjām un draudus saturošām ziņām administrācija patur tiesības neatbildēt, kā arī ziņot attiecīgajām varas iestādēm.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          Как на начальной странице, так и в виде комнаты есть кнопка «Техническая помощь», при помощи которой можно открыть переписку с администрацией игры. Здесь можете писать о проблемах игры, сообщать об ошибках, высказывать идеи итд. Ответ обычно дается в течении 3 рабочих дней. Переписка чата сохраняется 14 дней.
        </p>
        <p>
          Помните: повторное посылание сообщений не ускорит получение ответа, так же администрация оставляет за собой право не отвечать на грубые и угрожающие сообщения, и информировать о них соответствующие органы власти.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          Both the home page and the room view have a Technical Help button to contact the game administration. Here players can write about the problems of the game, report bugs, share new ideas, etc. A response is usually provided within 3 business days. Chat messages are stored for 14 days.
        </p>
        <p>
          NB! Sending the messages many times will not speed up the response, the administrators reserve the right not to reply to offensive and threatening messages, as well as to report to the relevant authorities.
        </p>
      </>
    )
  }
};

AdministrationChat.propTypes = {
  lang: PropTypes.string,
};

AdministrationChat.defaultProps = {
  lang: 'lv',
};

export default AdministrationChat;
