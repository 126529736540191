import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

const EndResultPules = React.memo(({ pules, myPos }) => {
  let player1Pules = 0;
  let player2Pules = 0;
  let player3Pules = 0;
  let player4Pules = 0;

  Object.keys(pules).map((key) => {
    if (pules[key] && pules[key].status === 'active') {
      if (pules[key].player === 'player1') {
        player1Pules = player1Pules + 1;
      } else if (pules[key].player === 'player2') {
        player2Pules = player2Pules + 1;
      } else if (pules[key].player === 'player3') {
        player3Pules = player3Pules + 1;
      } else if (pules[key].player === 'player4') {
        player4Pules = player4Pules + 1;
      }
    }
  })

    return (
      <table className="score-table-sum">
        <thead />
        <tbody style={{ width: '100%' }}>
          <tr>
            <th className="score-table-sum-col-id">
              <div>
                Aktīvas pules
              </div>
            </th>
            <th className="score-table-points-col">
              <>
                {myPos === 'player1' && (
                  <>
                    {player4Pules}
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    {player1Pules}
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    {player2Pules}
                  </>
                )}
                {myPos === 'player4' && (
                  <>
                    {player3Pules}
                  </>
                )}
              </>
            </th>
            <th className="score-table-points-col">
              <>
                {myPos === 'player1' && (
                  <>
                    {player1Pules}
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    {player2Pules}
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    {player3Pules}
                  </>
                )}
                {myPos === 'player4' && (
                  <>
                    {player4Pules}
                  </>
                )}
              </>
            </th>
            <th className="score-table-points-col">
              <>
                {myPos === 'player1' && (
                  <>
                    {player2Pules}
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    {player3Pules}
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    {player4Pules}
                  </>
                )}
                {myPos === 'player4' && (
                  <>
                    {player1Pules}
                  </>
                )}
              </>
            </th>
            <th className="score-table-points-col">
              <>
                {myPos === 'player1' && (
                  <>
                    {player3Pules}
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    {player4Pules}
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    {player1Pules}
                  </>
                )}
                {myPos === 'player4' && (
                  <>
                    {player2Pules}
                  </>
                )}
              </>
            </th>
            <th className="score-table-sum-col-pules" />
          </tr>
        </tbody>
      </table>
    );
});

EndResultPules.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
};

EndResultPules.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
};

const mapStateToProps = (state, ownProps) => {
  if (state.game) {
    return ({
      pules: state.points.pules || {},
      myPos: state.game.myPos || null,
    });
  } else {
    return null;
  }
};

export default connect(mapStateToProps, null)(EndResultPules);
