import React from 'react';
import PropTypes from 'prop-types';

import Button from 'reactstrap/lib/Button';

import CustomModal from '../../Components/Components/ModalQuestion';

const BlockUserModal = React.memo(({
  t, blockUserModal, blockUserSelectedName, toggleBlockUser, blockUserFunction,
}) => (
  <>
    <CustomModal
      isOpen={blockUserModal}
      toggle={toggleBlockUser}
      size="md"
      title={t('common:common.blockPlayer')}
      footer={(
        <>
          <Button className="question-modal-approve-btn" onClick={blockUserFunction}>
            {t('common:common.yes')}
          </Button>
          <Button className="question-modal-decline-btn" onClick={toggleBlockUser}>
            {t('common:common.no')}
          </Button>
        </>
      )}
      body={t('common:common.blockPlayerConfirm', { player: blockUserSelectedName })}
    />
  </>
));

BlockUserModal.propTypes = {
  blockUserModal: PropTypes.bool,
  blockUserSelectedName: PropTypes.string,
  toggleBlockUser: PropTypes.func.isRequired,
  blockUserFunction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

BlockUserModal.defaultProps = {
  blockUserModal: false,
  blockUserSelectedName: null,
};

export default BlockUserModal;
