import React from 'react';
import PropTypes from 'prop-types';

const MoneyHistory = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
          Sākumlapas lejasdaļā ir pieejama poga ‘Naudas Vēsture’, ar kuras palīdzību var atvērt logu ar pārskatu par bilances izmaiņām pēdējo trīs dienu laikā. Atsevišķi tiek izdalīti ienākumi un izdevumi, kā arī izmaiņas tips, piemēram - sods par nokavētu gājienu, istabas dalības maksa, dienas bonuss u.c.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          В нижней части начальной страницы есть кнопка «Денежный баланс», при помощи которой можно открыть окно с изменениями денежного баланса за последние три дня. Отдельно выделены прибыль и расходы, а так же отдельные типы изменений, например — штраф за просроченный ход, дневной бонус, паевой взнос комнаты итд.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          At the bottom of the home page, there is a "Cash History" button that allows players to open a window with a balance overview in the last three days. Income and expenses are separated, as well as a type of change, for example – penalty for a late pass, room participation fee, daily bonus, etc.
        </p>
      </>
    )
  }
};

MoneyHistory.propTypes = {
  lang: PropTypes.string,
};

MoneyHistory.defaultProps = {
  lang: 'lv',
};

export default MoneyHistory;
