import isEqual from 'react-fast-compare';
import Store from '../store/points';

export const initialState = Store;

export default function gameReducer(state = initialState, action) {
  switch (action.type) {
    case 'POINTS_TOTAL': {
      return {
        ...state,
        totalPoints: action.data,
      };
    }

    case 'POINTS_REPLACE': {
      return {
        ...state,
        points: action.data || {},
      };
    }

    case 'POINTS_CHANGE': {
      if (state.points && !isEqual(state.points[action.key], action.data)) {
        return {
          ...state,
          points: {
            ...state.points,
            [action.key]: action.data || {},
          },
        };
      }
      return state;
    }

    case 'PULES_REPLACE': {
      return {
        ...state,
        pules: action.data || {},
      };
    }

    case 'PULES_CHANGE': {
      if (state.pules && !isEqual(state.pules[action.key], action.data)) {
        return {
          ...state,
          pules: {
            ...state.pules,
            [action.key]: action.data || {},
          },
        };
      }
      return state;
    }

    case 'PULES_REMOVED': {
      const { key } = action;

      if (state) {
        return {
          ...state,
          pules: {
            ...state.pules,
            [key]: null,
          },
        };
      }
      return {
        ...state,
        pules: {
          [key]: null,
        },
      };
    }


    default:
      return state;
  }
}
