import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import classNames from 'classnames';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Alert,
  Pagination,
  PaginationItem,
  PaginationLink,
  Media,
  Form,
  FormGroup,
  Input,
  PopoverBody,
} from 'reactstrap';

import { map, size, slice } from 'lodash';

import moment from 'moment';
import Moment from 'react-moment';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import TextEditor from '../TextEditor/TextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import DateFormat from '../UI/DateFormat';
import * as constants from '../../../constants/constants';
import { errorMessages } from '../../../constants/messages';

import pencilImg from '../../../images/icons/pencil.svg';
import checkImg from '../../../images/icons/check.png';
import cancelImg from '../../../images/icons/close_old.png';
import closeImg from '../../../images/icons/close.png';

import {
  addBlogEditerData,
  deleteBlogEditor,
  updateBlogEditorData,
  getBlogEditorData,
  supportMessageFileUpload,
  blogImageFileUpload,
} from '../../../actions/admin';
import { UncontrolledPopover } from 'reactstrap/lib';

moment.locale('lv');
momentLocalizer();

class Blogs extends React.Component {
  static propTypes = {
    loading: PropTypes.string,
    t: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
    addData: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    blogs: PropTypes.arrayOf(PropTypes.shape({})),
    showNotification: PropTypes.func.isRequired,
    deleteAEditor: PropTypes.func.isRequired,
    blogFileUpload: PropTypes.func.isRequired,
  }

  static defaultProps = {
    blogs: [],
    loading: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      openAddModal: false,
      blogDate: new Date(),
      editorState: EditorState.createEmpty(),
      currentPage: 0,
      pageSize: 50,
      deleteModal: false,
      selectId: '',
      editModal: false,
      ableToSend: false,
      enTitle: '',
      preview: null,
      imageUrl: '',
      uploadFile: null,
      ableToSave: false,
      oldData: {},
      lvTitle: '',
      ruTitle: '',
    };
    this.handleClick = this.handleClick.bind(this);
    this.hiddenFileInput = React.createRef(null);
    this.handleEditPhoto = this.handleEditPhoto.bind(this);
    this.onCrop = this.onCrop.bind(this);
  }

  componentDidMount() {
    const { fetchData } = this.props;

    constants.LANGUAGE_LIST.map((language) => {
      this.setState(prevState => ({ editorState: { ...prevState.editorState, [language]: EditorState.createEmpty() } }));
      return null;
    });

    this.setState({ editorStateSet: true });

    fetchData();
  }

  toggleAdd = () => {
    this.setState(prevState => ({
      openAddModal: !prevState.openAddModal,
      blogDate: new Date(),
      enTitle: '',
      ruTitle: '',
      lvTitle: '',
      imageUrl: null,
      preview: null,
      showCrop: false,
      ableToSave: false,
      editModal: false,
      deleteModal: false,
      editorStateSet: true,
    }));
    constants.LANGUAGE_LIST.map((language) => {
      this.setState(prevState => ({ editorState: { ...prevState.editorState, [language]: EditorState.createEmpty() } }));
    });
  }

  handleDate = (date) => {
    this.setState({
      blogDate: date,
    });
  }

  handleEditState = (editorState, language) => {
    this.setState(prevState => ({
      success: null,
      error: null,
      editorState: { ...prevState.editorState, [language]: editorState },
    }));
  }

  editBlog = () => {
    const { updateData, showNotification, t, fetchData } = this.props;
    const { selectId, blogDate, enTitle, lvTitle, ruTitle, editorState, imageUrl, ableToSave, showCrop, oldData } = this.state;

    let lvDescription = null;
    let enDescription = null;
    let ruDescription = null;
    
    if (editorState && editorState[constants.sendLanguage.lv] && editorState[constants.sendLanguage.en] && editorState[constants.sendLanguage.ru]) {
      const rawContentStateLV = convertToRaw(editorState[constants.sendLanguage.lv].getCurrentContent());
      lvDescription = draftToHtml(rawContentStateLV);

      const rawContentStateEN = convertToRaw(editorState[constants.sendLanguage.en].getCurrentContent());
      enDescription = draftToHtml(rawContentStateEN);

      const rawContentStateRU = convertToRaw(editorState[constants.sendLanguage.ru].getCurrentContent());
      ruDescription = draftToHtml(rawContentStateRU);
    }

    if (!showCrop && !ableToSave) {
      updateData({
        selectId, blogDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl, oldData,
      }).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success!', 'Changes are saved.', 'success');
          fetchData();
        }

        if (res.status === 'error') {
          if (res.message === errorMessages.missingData) {
            showNotification('Error', "Missed the data.", 'danger');
          } else if (res.message === errorMessages.wrongEventDate) {
            showNotification('Error', "The End date was inputed wrong.", 'danger');
          } else if (res.message === errorMessages.missingLVTitle) {
            showNotification('Error', "Missed the title by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENTitle) {
            showNotification('Error', "Missed the title by English.", 'danger');
          } else if (res.message === errorMessages.missingRUTitle) {
            showNotification('Error', "Missed the title by Russian.", 'danger');
          } else if (res.message === errorMessages.missingLVDescription) {
            showNotification('Error', "Missed the description by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENDescription) {
            showNotification('Error', "Missed the description by English.", 'danger');
          } else if (res.message === errorMessages.missingRUDescription) {
            showNotification('Error', "Missed the description by Russian.", 'danger');
          } else if (res.message === errorMessages.missingPhoto) {
            showNotification('Error', "Please upload the event image.", 'danger');
          } else {
            showNotification('Error', res.message, 'danger');
          }
          return;
        }

        this.initialState();
      }).catch(error => showNotification('Error', error.message, 'danger'));
    } else {
      showNotification('Warning', "If you don't apply or close uploaded image, you can't add new event.", 'warning');
    }
  }

  addBlog = () => {
    const { addData, showNotification, t, fetchData } = this.props;
    const { blogDate, enTitle, lvTitle, ruTitle, editorState, imageUrl, showCrop, ableToSave } = this.state;

    let lvDescription = null;
    let enDescription = null;
    let ruDescription = null;

    if (editorState && editorState[constants.sendLanguage.lv] && editorState[constants.sendLanguage.en] && editorState[constants.sendLanguage.ru]) {
      const rawContentStateLV = convertToRaw(editorState[constants.sendLanguage.lv].getCurrentContent());
      lvDescription = draftToHtml(rawContentStateLV);

      const rawContentStateEN = convertToRaw(editorState[constants.sendLanguage.en].getCurrentContent());
      enDescription = draftToHtml(rawContentStateEN);

      const rawContentStateRU = convertToRaw(editorState[constants.sendLanguage.ru].getCurrentContent());
      ruDescription = draftToHtml(rawContentStateRU);
    }

    if (!showCrop && !ableToSave) {
      addData({
        blogDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl,
      }).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success!', 'Blog article is successfully added.', 'success');
          fetchData();
        }

        if (res.status === 'error') {
          if (res.message === errorMessages.missingData) {
            showNotification('Error', "Missed the data.", 'danger');
          } else if (res.message === errorMessages.wrongEventDate) {
            showNotification('Error', "The End date was inputed wrong.", 'danger');
          } else if (res.message === errorMessages.missingLVTitle) {
            showNotification('Error', "Missed the title by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENTitle) {
            showNotification('Error', "Missed the title by English.", 'danger');
          } else if (res.message === errorMessages.missingRUTitle) {
            showNotification('Error', "Missed the title by Russian.", 'danger');
          } else if (res.message === errorMessages.missingLVDescription) {
            showNotification('Error', "Missed the description by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENDescription) {
            showNotification('Error', "Missed the description by English.", 'danger');
          } else if (res.message === errorMessages.missingRUDescription) {
            showNotification('Error', "Missed the description by Russian.", 'danger');
          } else if (res.message === errorMessages.missingPhoto) {
            showNotification('Error', "Please upload the event image.", 'danger');
          } else {
            showNotification('Error', res.message, 'danger');
          }
          return;
        }

        this.initialState();
      }).catch(error => showNotification('Error', error.message, 'danger'));
    } else {
      showNotification('Warning', "If you don't apply or close uploaded image, you can't add new event.", 'warning');
    }
  }

  toggleDeleteModal = (key) => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
      selectId: key,
    }));
  }
  
  deleteEditor = () => {
    const { deleteAEditor, showNotification, t, fetchData } = this.props;
    const { selectId } = this.state;

    if (selectId !== '') {
      deleteAEditor(selectId).then((res) => {
        if (res && res.status === constants.FROUNT_SIDE_STATUS.success) {
          showNotification('Success!', 'Blog article is successfully deleted.', constants.FROUNT_SIDE_STATUS.success);
          fetchData();
        }

        if (res.status === constants.FROUNT_SIDE_STATUS.error) {
          return showNotification('Error!', 'Failed to delete blog article.', constants.FROUNT_SIDE_STATUS.danger);
        }
        this.setState({
          deleteModal: false,
          selectId: '',
        });
      });
    }
  }

  editModal = (id) => {
    const { blogs } = this.props;
    const selectBlog = blogs[id];

    this.setState({
      selectId: blogs[id].key,
      enTitle: selectBlog.titleEN || '',
      lvTitle: selectBlog.titleLV || '',
      ruTitle: selectBlog.titleRU || '',
      blogDate: new Date(selectBlog.blogDate) || null,
      imageUrl: selectBlog.imageUrl || '',
      editModal: true,
      openAddModal: false,
      editorStateSet: true,
      oldData: blogs[id],
    });
    constants.LANGUAGE_LIST.map((language) => {
      if (selectBlog[constants.BLOG_RICH_TEXT[language]]) {
        const blocksFromHtml = htmlToDraft(selectBlog[constants.BLOG_RICH_TEXT[language]]);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        if (contentState) {
          this.setState(prevState => ({ editorState: { ...prevState.editorState, [language]: EditorState.createWithContent(contentState) } }));
        }
      } else {
      //  this.setState({ [language]: EditorState.createEmpty() });
        this.setState(prevState => ({ editorState: { ...prevState.editorState, [language]: EditorState.createEmpty() } }));
      }
    });
  }

  initialState = () => {
    this.setState({
      selectId: '',
      blogDate: new Date(),
      editModal: false,
      deleteModal: false,
      openAddModal: false,
      enTitle: '',
      ruTitle: '',
      lvTitle: '',
      imageUrl: null,
      preview: null,
      showCrop: false,
      ableToSave: false,
      editorStateSet: false,
      editorState: EditorState.createEmpty(),
    });
  }

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  onCrop = (preview) => {
    this.setState({ preview });
  }

  handleEditPhoto = (value) => {
    this.setState({ showCrop: !value });
  }

  uploadEventFile = () => {
    const { blogFileUpload } = this.props;
    const { uploadFile } = this.state;

    this.setState({ ableToSave: true });
    if (uploadFile !== null) {
      blogFileUpload(uploadFile).then((data) => {
        console.log('value@@@@@@@@@@@@@@@@', data);
        this.setState({
          imageUrl: data.url,
          ableToSave: false,
        });
      }).catch((e) => {
        this.setState({
          preview: null,
          ableToSave: false,
        });
      });
    }
  }

  handleImageClick = () => {
    this.hiddenFileInput.current.click();
  };

  _handleImageChange = (e) => {
    e.preventDefault();

    try {
      const { showNotification, t } = this.props;
      const typeList = 'image/jpg,image/png,image/jpeg, image/pjp, image/pjpeg, image/jfif';
      const reader = new FileReader();
      const file = e.target.files[0];
      if (!file) return;

      if (!typeList.includes(file.type) || file.type === '') {
        showNotification("Error", "Unsupported file!", 'danger');
        return e.target.value === '';
      }

      if (file.size / 1024 > 3072) {
        showNotification("Warning", "You can't upload more than 3MB", 'warning');
        return e.target.value === '';
      }

      reader.onloadend = () => {
        this.setState({
          preview: reader.result,
          uploadFile: file,
        });
      };
      reader.readAsDataURL(file);
    } catch (err) {
      const { showNotification, t } = this.props;
      showNotification("Error", err.message, 'danger');

      return e.target.value === '';
    }
  }

  table = () => {
    const {
      t,
      blogs,
      i18n,
    } = this.props;
    const {
      currentPage,
      pageSize,
    } = this.state;
    const pagesCount = size(blogs) / pageSize;
    return (
      <Fragment>
        {size(blogs) > 0 && Object.keys(blogs)
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((key, index) => (
            <Fragment key={key}>
              <tr key={key} className={classNames('allUsers-table-row', ({
                'odd': index %2 === 0,
                'even': index % 2 !== 0
              }))}>
                <td className="allUsers-table-col">
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={blogs[key].blogDate} />
                </td>
                <td className="allUsers-table-col allUsers-table-col-blog-image">
                  <Media src={blogs[key].imageUrl} alt="x" className="admin-blog-list-image" />
                </td>
                
                <td className="allUsers-table-col">
                  {i18n.language === 'en' ? blogs[key].titleEN : (i18n.language === 'lv' ? blogs[key].titleLV : (i18n.language === 'ru' ? blogs[key].titleRU : blogs[key].titleLV))}
                </td>
                <td className="allUsers-table-col">
                  <TextEditor addEditorClassName="blog-editor-read-only" viewEditorState={blogs[key][constants.BLOG_RICH_TEXT[i18n.language || constants.sendLanguage.en]]} readOnly />
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.editModal(key)}>
                    Edit
                  </Button>
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.toggleDeleteModal(blogs[key].key)}>
                    Delete
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        {size(blogs) > pageSize && (
        <div className="pagination-wrapper">
          <Pagination aria-label="Page navigation example">
          <PaginationItem disabled={currentPage === 0}>
              <PaginationLink onClick={e => this.handleClick(e, 0)} href="#">
                {`‹‹‹`}
              </PaginationLink>
            </PaginationItem>

            <PaginationItem disabled={currentPage <= 0}>
              <PaginationLink
                onClick={e => this.handleClick(e, currentPage - 1)}
                previous
                href="#"
              />
            </PaginationItem>

            {[...Array(pagesCount)].map((page, i) => {
              if (i > currentPage - 3 && i < currentPage + 3) {
                return (
                  <PaginationItem active={i === currentPage} key={page}>
                    <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              }

              return null;
            })}

            <PaginationItem disabled={currentPage >= pagesCount - 1}>
              <PaginationLink
                onClick={e => this.handleClick(e, currentPage + 1)}
                next
                href="#"
              />
            </PaginationItem>

            <PaginationItem disabled={currentPage >= pagesCount - 1}>
              <PaginationLink onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                {`›››`}
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
        )}
      </Fragment>
    );
  }

  render() {
    const { t, loading, i18n } = this.props;
    const {
      openAddModal,
      deleteModal,
      editModal,
      blogDate,
      ableToSend,
      preview,
      imageUrl,
      showCrop,
      enTitle,
      lvTitle,
      ruTitle,
      editorState,
      editorStateSet
    } = this.state;
    return (
      <>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-blogs">
          <PopoverBody className="popover-body">
            An admin can create a blog article that will be available on the Landing page - Blogs. Every article requires the title and body text in each of the listed languages, and also the featured image.
          </PopoverBody>
        </UncontrolledPopover>
        <Row className="mt-5">
          <Col md="6">
            <h2>
              Blogs
              <Button className="admin-help-button" id="admin-help-button-blogs">
                ?
              </Button>
            </h2>
          </Col>
          <Col md="6">
            <Button onClick={() => this.toggleAdd()}>
              AddBlog
            </Button>
          </Col>
        </Row>
        <Row>
          <table className="textAlign" style={{ width: '100%', fontSize: 14 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  Image
                </th>
                <th style={{ textAlign: 'center' }}>
                  Title
                </th>
                <th style={{ textAlign: 'center' }}>
                  Description
                </th>
                <th style={{ textAlign: 'center' }}>
                  Edit
                </th>
                <th style={{ textAlign: 'center' }}>
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>
        </Row>
        {/* Edit Version History modal */}
        <Modal container={'div > div'} className="admin-modal" size="lg" isOpen={editModal} toggle={this.initialState}>
          <ModalHeader toggle={this.initialState}>
            Edit
          </ModalHeader>
          <ModalBody>
            <Form className="form-style">
              <FormGroup>
                <Label for="blogDate">
                  Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      time={false}
                      format={constants.FORMAT_DATE_TYPE.pointsfullDate}
                      culture={constants.sendLanguage.lv}
                      onChange={this.handleDate}
                      value={blogDate}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="lvTitle">
                  Title By Latvian
                </Label>
                <Input
                  type="text"
                  name="lvTitle"
                  id="lvTitle"
                  value={lvTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="enTitle">
                  Title By English
                </Label>
                <Input
                  type="text"
                  name="enTitle"
                  id="enTitle"
                  value={enTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="ruTitle">
                  Title By Russian
                </Label>
                <Input
                  type="text"
                  name="ruTitle"
                  id="ruTitle"
                  value={ruTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <b>(Please use text editor which doesn't add extra newlines, for example WordPad or Google Docs)</b>
              <TextEditor addEditorClassName="blog-editor" editorState={editorState} editorStateSet={editorStateSet} languages={constants.LANGUAGE_LIST} readOnly={false} handleEditState={this.handleEditState} />
              <FormGroup style={{position: 'relative', marginTop: '24px'}}>
                <Label>
                  Article Image
                </Label>
                <div className="banner-image">
                  {
                    !preview && !imageUrl ? (
                      <Label className="banner-image-label">Please upload the image for article.</Label>
                    ) : (
                      <img className="event-banner-image" src={preview || imageUrl} alt="Preview" />
                    )
                  }
                </div>
                {!showCrop && (
                <div style={{position: 'absolute', top: '0px', right: '0px'}}>
                  <Media onClick={() => { this.handleEditPhoto(showCrop); }} src={pencilImg} className="menu-player-edit-image" style={{ width: '24px', height: '24px' }} disabled={loading} />
                </div>
                )}
                {!showCrop && (
                <div style={{position: 'absolute', top: '0px', right: '50px'}}>
                  <Media onClick={() => { this.setState({ imageUrl: '', preview: '' }); }} src={cancelImg} className="menu-player-edit-image" style={{ width: '24px', height: '24px' }} disabled={loading} />
                </div>
                )}
                {showCrop && (
                <div style={{position: 'absolute', top: '0px', right: '0px'}}>
                  <Media onClick={() => { this.handleEditPhoto(showCrop); this.uploadEventFile(); }} src={checkImg} style={{ width: '24px', height: '24px' }} className="menu-player-edit-image" />
                </div>
                )}
                {showCrop && (
                <div style={{position: 'absolute', top: '0px', right: '50px'}}>
                  <Media onClick={() => { this.handleEditPhoto(showCrop); this.setState({ preview: null }); }} style={{ width: '24px', height: '24px' }} src={cancelImg} className="menu-player-edit-image" />
                </div>
                )}
                {
                  showCrop && (
                  <div>
                    <div className="event-banner-button" onClick={this.handleImageClick}>
                      Upload a Image
                    </div>
                    <input
                      type="file"
                      ref={this.hiddenFileInput}
                      onChange={this._handleImageChange}
                      style={{ display: 'none' }}
                      accept="image/*"
                    />
                  </div>
                  )
                }
                <Input
                  type="text"
                  name="imageUrl"
                  id="imageUrl"
                  placeholder=""
                  hidden
                  readOnly
                  value={imageUrl}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.editBlog}>Save</Button>
            {' '}
            <Button color="secondary" onClick={this.initialState}>Close</Button>
          </ModalFooter>
        </Modal>

        {/* Add Version History modal */}
        <Modal container={'div > div'} className="admin-modal" size="lg" isOpen={openAddModal} toggle={this.initialState}>
          <ModalHeader toggle={this.initialState}>
            Add Blog
          </ModalHeader>
          <ModalBody>
            <Form className="form-style">
              <FormGroup>
                <Label for="blogDate">
                  Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      time={false}
                      format={constants.FORMAT_DATE_TYPE.pointsfullDate}
                      culture={constants.sendLanguage.lv}
                      onChange={this.handleDate}
                      value={blogDate}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="lvTitle">
                  Title By Latvian
                </Label>
                <Input
                  type="text"
                  name="lvTitle"
                  id="lvTitle"
                  value={lvTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="enTitle">
                  Title By English
                </Label>
                <Input
                  type="text"
                  name="enTitle"
                  id="enTitle"
                  value={enTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="ruTitle">
                  Title By Russian
                </Label>
                <Input
                  type="text"
                  name="ruTitle"
                  id="ruTitle"
                  value={ruTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <b>(Please use text editor which doesn't add extra newlines, for example WordPad or Google Docs)</b>
                <TextEditor addEditorClassName="blog-editor" editorState={editorState} editorStateSet={editorStateSet} languages={constants.LANGUAGE_LIST} readOnly={false} handleEditState={this.handleEditState} />
              </FormGroup>
              <FormGroup style={{position: 'relative'}}>
                <Label>
                  Article Image
                </Label>
                <div className="banner-image">
                  {
                    !preview && !imageUrl ? (
                      <Label className="banner-image-label">Please upload the image for article.</Label>
                    ) : (
                      <img className="event-banner-image" src={preview || imageUrl} alt="Preview" />
                    )
                  }
                </div>
                {!showCrop && (
                <div style={{position: 'absolute', top: '0px', right: '0px'}}>
                  <Media onClick={() => { this.handleEditPhoto(showCrop); }} src={pencilImg} className="menu-player-edit-image" style={{ width: '24px', height: '24px' }} disabled={loading} />
                </div>
                )}
                {!showCrop && (
                <div style={{position: 'absolute', top: '0px', right: '-50px'}}>
                  <Media onClick={() => { this.setState({ imageUrl: '', preview: '' }); }} src={cancelImg} style={{ width: '24px', height: '24px' }} className="menu-player-edit-image" disabled={loading} />
                </div>
                )}
                {showCrop && (
                <div style={{position: 'absolute', top: '0px', right: '0px'}}>
                  <Media onClick={() => { this.handleEditPhoto(showCrop); this.uploadEventFile(); }} src={checkImg} style={{ width: '24px', height: '24px' }} className="menu-player-edit-image" />
                </div>
                )}
                {showCrop && (
                <div style={{position: 'absolute', top: '0px', right: '50px'}}>
                  <Media onClick={() => { this.handleEditPhoto(showCrop); this.setState({ preview: null }); }} style={{ width: '24px', height: '24px' }} src={cancelImg} className="menu-player-edit-image" />
                </div>
                )}
                {
                  showCrop && (
                  <div>
                    <div className="event-banner-button" onClick={this.handleImageClick}>
                      Upload a Image
                    </div>
                    <input
                      type="file"
                      ref={this.hiddenFileInput}
                      onChange={this._handleImageChange}
                      style={{ display: 'none' }}
                      accept="image/*"
                    />
                  </div>
                  )
                }
                <Input
                  type="text"
                  name="imageUrl"
                  id="imageUrl"
                  placeholder=""
                  hidden
                  readOnly
                  value={imageUrl}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.addBlog}>Add Blog</Button>
            {' '}
            <Button color="secondary" onClick={this.initialState}>Close</Button>
          </ModalFooter>
        </Modal>

        {/* Delete version history modal */}
        <Modal container={'div > div'} isOpen={deleteModal} toggle={this.toggleDeleteModal} className="notification">
          <ModalHeader
            toggle={this.toggleDeleteModal}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleDeleteModal} />
            }
          />
          <ModalBody className="notification-body">
            Do you really want to delete the data?
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="btn notification-footer-button" onClick={this.deleteEditor}>
              Yes
            </Button>
            <Button type="button" className="btn notification-footer-button" onClick={this.toggleDeleteModal}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  blogs: state.admin.blogs || {},
});

const mapDispatchToProps = {
  fetchData: getBlogEditorData,
  updateData: updateBlogEditorData,
  addData: addBlogEditerData,
  deleteAEditor: deleteBlogEditor,
  // supportFileUpload: supportMessageFileUpload,
  blogFileUpload: blogImageFileUpload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('admin')(Blogs));
