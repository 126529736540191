import React from 'react';
import PropTypes from 'prop-types';

const DailyBonus = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Reizi dienā spēlētājs var iegriezt laimes ratu un laimēt kādu no pieejamajām spēles naudas balvām, no 10 līdz 300 Zoles monētu apjomā, kas uzreiz tiek pieskaitītas spēlētāja bilancei. Tomēr pastāv arī iespēja nesaņemt neko (0 monētas) vai arī zaudēt no 10 līdz 100 monētām. Zaudējuma varbūtība ir divas reizes mazāka par laimesta iespēju.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
        Один раз в сутки игрок может прокрутить колесо удачи и выиграть один из денежных призов в размере от 10 до 300 монет Zole, которые сразу добавляются к балансу игрока. Однако существует также возможность ничего не выиграть (0 монет) или потерять от 10 до 100 монет. Однако, вероятность выигрыша в два раза больше возможности проиграть.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
        Once a day, the player can spin the Lucky Wheel and win one of the cash prizes ranging from 10 to 300 Zoles coins, which are immediately added to the player's balance. However, there is also the possibility of winning nothing (0 coins) or losing between 10 and 100 coins. However, the chance of winning is twice the chance of losing.
        </p>
      </>
    )
  }
};

DailyBonus.propTypes = {
  lang: PropTypes.string,
};

DailyBonus.defaultProps = {
  lang: 'lv',
};

export default DailyBonus;
