import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
// import ScrollArea from 'react-scrollbar';
import { round, trim } from 'lodash';
// import ScrollArea from 'react-scrollbar';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Input from 'reactstrap/lib/Input';
import Media from 'reactstrap/lib/Media';
// import ScrollAreaButtons from '../../UI/ScrollAreaButtons';

import CustomModal from '../Components/Components/Modal';

import ScrollAreaWrapper from '../Components/ScrollAreaWrapper';

import Message from './SupportMessage';
import * as constants from '../../../../constants/constants';

// import closeImg from '../../../../images/icons/close.png';
import attachImg from '../../../../images/icons/attach.svg';

import {
  sendSupportMessage,
  setSupportAsRead,
  supportMessageFileUpload,
} from '../../../../actions/member';
import { checkAutoForCompensation } from '../../../../actions/admin';
import IconPopover from '../Components/Components/IconPopover';

class ContactSupport extends React.Component {
  static propTypes = {
    //  supportChatStatus: PropTypes.shape({
    //    read: PropTypes.bool,
    //  }),
    supportChat: PropTypes.shape(),
    uid: PropTypes.string,
    name: PropTypes.string,
    //  chatMessages: PropTypes.shape(),
    closeErrorSubmit: PropTypes.bool.isRequired,
    modalOpen: PropTypes.bool,
    resetClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    sendSupportMsg: PropTypes.func.isRequired,
    //  setSupportRead: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    supportMessageFileUpload: PropTypes.func.isRequired,
    screenMode: PropTypes.string,
    checkAutoForComp: PropTypes.func.isRequired,
    platform: PropTypes.string,
  };

  static defaultProps = {
    //  chatMessages: {},
    uid: null,
    name: '',
    supportChat: null,
    //  supportChatStatus: null,
    modalOpen: false,
    platform: constants.DWEB_PLATFORM
  };

  constructor(props) {
    super(props);
    this.state = {
      //  modalOpen: false,
      inputMessage: '',
      status: '',
      statusMessage: '',
      loading: false,
      inlineMessageVisible: false,
      ableToSend: false,
      fileName: '',
      fileUploadUrl: '',
      fileMessage: '',
      filePopoverOpen: false,
    };
    this.hiddenFileInput = React.createRef();
    this.handleFileClick = this.handleFileClick.bind(this);
    this.chooseFile = this.chooseFile.bind(this);
    this.messagesScrollbar = React.createRef();

  }

  onFileHover = () => {
    if (!this.state.filePopoverOpen) {
      this.setState({filePopoverOpen: true});
    }
  }
  onFileHoverLeave = () => {
    this.setState({filePopoverOpen: false});
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    const {
      closeErrorSubmit, resetClose, supportChat, modalOpen,
    } = this.props;
    const prevSupportChat = prevProps.supportChat;
    const prevModalOpen = prevProps.modalOpen;

    if (closeErrorSubmit) {
      resetClose();
      this.closeModal();
    }

    if (
      supportChat
      && (!prevSupportChat
        || (prevSupportChat
          && Object.keys(prevSupportChat).length !== Object.keys(supportChat).length))
    ) {
      this.scrollToBottom();
    }

    if (modalOpen && modalOpen !== prevModalOpen) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    setTimeout(() => {
      if (this.messagesScrollbar) {
        const scrollableArea = this.messagesScrollbar.current;
        if (scrollableArea) {
          scrollableArea.scrollTop = scrollableArea.scrollHeight;
        }
      }

    }, 200);
  };

  closeModal = () => {
    //  this.setState({ modalOpen: false });
  };

  /* toggle = () => {
    const { modalOpen } = this.state;
    const { setSupportRead, supportChatStatus } = this.props;

    if (!modalOpen && supportChatStatus && !supportChatStatus.read) {
      setSupportRead();
    }
  }; */

  handleChange = (event) => {
    const { inlineMessageVisible, fileUploadUrl } = this.state;

    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : (event.target.value).slice(0, 300);
      if (inlineMessageVisible && !fileUploadUrl) {
        this.setState({
          inlineMessageVisible: false,
          status: '',
          statusMessage: '',
        });
      }
      this.setState({
        [event.target.name]: value,
      });
    }
  };

  handleEnter = (event) => {
    const { inputMessage } = this.state;
    if (event.key === 'Enter' && !event.shiftKey && trim(inputMessage) !== '') {
      event.preventDefault();
      this.submitMessage();
    }
  };

  submitMessage = () => {
    const { sendSupportMsg, name, supportChat, checkAutoForComp, uid, platform, t, supportChatMessagesCount } = this.props;
    const { inputMessage, fileName, fileUploadUrl, inlineMessageVisible } = this.state;
    if (trim(inputMessage) === '' && !fileUploadUrl) return;

    if (inlineMessageVisible) {
      this.setState({
        inlineMessageVisible: false,
        status: '',
        statusMessage: '',
      });
    }

    if (supportChat && Object.keys(supportChat).length && Object.keys(supportChat).length >= 1000) {
      this.setState({ inputMessage: '' });
      return null;
    }

    this.setState({ inputMessage: '', fileUploadUrl: '', fileName: '' });
    let screen = 'dWeb'
    if (window.innerWidth <= 1280 && window.innerWidth > 832) {
      screen = 'tablet'
    } else if (window.innerWidth <= 832) {
      screen = 'mWeb'
    }
    console.log({ platform }, window.innerWidth, "device check new: --- 1");

    let lastMessageKey;
    let lastMessage;

    console.log('Object.keys(supportChat)', Object.keys(supportChat), Object.keys(supportChat).length - 1);

    if (Object.keys(supportChat) && Object.keys(supportChat).length, Object.keys(supportChat)[Object.keys(supportChat).length - 1]) {
      lastMessageKey = Object.keys(supportChat)[Object.keys(supportChat).length - 1];
      if (lastMessageKey) {
        lastMessage = supportChat[lastMessageKey];
      }
    }

    console.log('lastMessageKey', lastMessage, lastMessageKey, supportChatMessagesCount);

    if (supportChatMessagesCount >= 5 && lastMessage && lastMessage.date > (Date.now() - (1000 * 60 * 5))) {
      const time = Math.round((Date.now() - lastMessage.date) / 1000);
      const timeLeft = (60 * 5) - time;

      const minutes = Math.floor(timeLeft / 60);
      const seconds = timeLeft % 60;

      console.log('show error for mesage limit', time, timeLeft, minutes, seconds);

      this.setState({
        inlineMessageVisible: true,
        status: constants.FROUNT_SIDE_STATUS.error,
        statusMessage: t('support.messageLimit', { seconds, minutes }),
      });
      // showNotification(t('support.warning'), t('support.messageLimit', { seconds, minutes }), 'warning');

      // return null;
    }

    // if (inputMessage !== '' || fileUploadUrl !== '') {
    return sendSupportMsg({ message: inputMessage, name, fileUploadUrl, fileName, design: 'new', screen }).then((res) => {
      if (res.type === 'SUPPORT_MESSAGE_SUCCESS') {
        checkAutoForComp(uid, name);
      }
    });
  };

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.messagesScrollbar.scrollYTo(newVal);
  }

  handleFileClick = (e) => {
    console.log(this.hiddenFileInput, 'trigger check');
    this.hiddenFileInput.click();
  };

  chooseFile = (e) => {
    e.preventDefault();

    const { t } = this.props;
    const target = e.target.files[0];
    const allowTypes = constants.ALLLOW_FILE.allowTypes;
    if (target) {
      this.setState({
        loading: true,
      });
      console.log(target.type, "chck size");
      var typeList = ".jpg,.jpeg,.png,.pdf,.msword ,.doc,.rtf,.txt,ain";
      if (!typeList.includes((target.type).substring((target.type).length - 3)) || target.type == "") {
        this.setState({
          inlineMessageVisible: true,
          status: constants.FROUNT_SIDE_STATUS.error,
          statusMessage: t('support.unsupportedFile'),
          fileUploadUrl: '',
          fileName: '',
          loading: false,
        });
        return;
      }

      if (target.size / constants.ALLLOW_FILE.unitSize > constants.ALLLOW_FILE.allowSize) {
        this.setState({
          inlineMessageVisible: true,
          status: constants.FROUNT_SIDE_STATUS.error,
          statusMessage: t('support.cantUpload'),
          fileUploadUrl: '',
          fileName: '',
          loading: false,
          inputMessage: `${target.name}(${round(target.size / constants.ALLLOW_FILE.mbSize, 1)}) mb`,
        });
      } else {
        this.setState({
          ableToSend: false,
        });
        const { supportMessageFileUpload } = this.props;
        supportMessageFileUpload(target).then((data) => {
          console.log(target.name, target.size, " upload success check");
          const msgInput = `${target.name}: ${t('support.attachedSuccess')}` 
          this.setState({
            inlineMessageVisible: true,
            status: constants.FROUNT_SIDE_STATUS.success,
            statusMessage: msgInput,
            fileUploadUrl: data.url,
            fileName: target.name,
            fileMessage: target.name,
            loading: false,
          });
        });
      }
    }
    // else {
    //   this.setState({
    //     inlineMessageVisible: false,
    //     status: null,
    //     statusMessage: null,
    //     fileUploadUrl: '',
    //     fileName: '',
    //     loading: false,
    //   });
    // }
  }

  toggleModal() {
    const { toggle } = this.props;
    this.setState({
      inlineMessageVisible: false,
      status: null,
      statusMessage: null,
      fileUploadUrl: '',
      fileName: '',
      loading: false,
      inputMessage: '',
      fileMessage: '',
    });
    toggle();
  }

  render() {
    const { inputMessage, fileMessage, scrollPos, inlineMessageVisible, statusMessage, status, loading, fileUploadUrl, fileName, filePopoverOpen, } = this.state;

    const {
      t, modalOpen, toggle, supportChat, uid, screenMode,
    } = this.props;

    if (!modalOpen) {
      return null;
    }

    return (
      <Row className="contact-support">
        <CustomModal
          isOpen={modalOpen}
          addClassName="new-version-modal contact-admin-modal"
          toggle={() => this.toggleModal()}
          size="lg"
          title={t('support.contact')}
          body={(
            <>
              <Row className="contact-support-chat-description">
                <Col xs="12">
                  <div>
                    {t('support.description1')}<br />
                    {' '}
                    {t('support.description2')}<br />
                    {' '}
                    {t('support.description3')}<br />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="contact-support-chat-body-wrapper" md="12">
                  <div ref={this.messagesScrollbar} className="contact-support-chat-body-wrapper-scrollarea">
                    {supportChat
                      && Object.keys(supportChat).map(key => (
                        <Row key={key}>
                          <Col md="12">
                            <Message
                              uid={uid}
                              messageId={supportChat[key].messageId || null}
                              message={supportChat[key].message || null}
                              translationKey={supportChat[key].translationKey || null}
                              translationKeyData={supportChat[key].translationKeyData || {}}
                              filteredMessage={supportChat[key].filteredMessage || null}
                              fileUploadUrl={supportChat[key].fileUpload || null}
                              fileName={supportChat[key].fileName || null}
                              isFiltered={supportChat[key].isFiltered || false}
                              userUid={supportChat[key].userUid || null}
                              date={supportChat[key].date || null}
                              screenMode={screenMode}
                              t={t}
                            />
                          </Col>
                        </Row>
                      ))}
                    </div>
                </Col>
              </Row>
              <div className="contact-support-chat-footer">
                <div xs="12" className="contact-support-chat-footer-input-wrapper">
                  <Form onSubmit={this.submitMessage}>
                    <Input
                      className="contact-support-chat-footer-input"
                      type="textarea"
                      name="inputMessage"
                      id="inputMessage"
                      autoComplete="off"
                      placeholder={`${t('common.writeMessage')}...`}
                      // value={fileUploadUrl ? fileMessage : inputMessage}
                      value={inputMessage}
                      onChange={this.handleChange}
                      onKeyPress={this.handleEnter}
                      disabled={loading}
                    />
                    {
                      inlineMessageVisible && (
                        <div className={`contact-support-chat-footer-input-wrapper-inputInlineMessage-${status}`}>
                          <p>{statusMessage}</p>
                        </div>
                      )
                    }
                  </Form>
                  <Media
                    onClick={this.handleFileClick}
                    className="contact-support-chat-footer-input-attach-icon"
                    disabled={loading}
                    src={attachImg}
                    alt=""
                    id="attach-file-id"
                    onMouseEnter={this.onFileHover}
                    onMouseLeave={this.onFileHoverLeave}
                  />
                  <IconPopover text={t('support.attachFile')} targetId="attach-file-id" popoverOpen={filePopoverOpen} placement="top" />

                  <input
                    type="file"
                    id="filesDialog"
                    ref={(ref) => { this.hiddenFileInput = ref; }}
                    className="chat-footer-input admin-file-upload-input"
                    accept=".jpg,.png,.pdf,.doc,.rtf,.txt"
                    onChange={this.chooseFile}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    hidden
                  />

                  <Button color="link" disabled={(!inputMessage && !fileUploadUrl) || loading || status === constants.FROUNT_SIDE_STATUS.error} className={`layout-default-button layout-float-right`} onClick={this.submitMessage}>{loading ? t('common.loading') : t('common.send')}</Button>
                </div>
              </div>
            </>
          )}
          footer={null}
        />
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  uid: state.member.uid || '',
  name: state.member.name || '',
  supportChat: state.member.supportChat || null,
  supportChatStatus: state.member.supportChatStatus || null,
  supportChatMessagesCount: state.member.supportChatMessagesCount || null,
});

const mapDispatchToProps = {
  sendSupportMsg: sendSupportMessage,
  setSupportRead: setSupportAsRead,
  supportMessageFileUpload,
  checkAutoForComp: checkAutoForCompensation
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ContactSupport));
