import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


import { Col, Row, Button } from 'reactstrap';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { map, size } from 'lodash';
import ScrollArea, { prototype } from 'react-scrollbar';

import CustomModal from '../../Components/Components/Modal';
import TextEditor from '../../../TextEditor/TextEditor';
// import closeImg from '../../../../images/icons/close.png';
import * as constants from '../../../../../constants/constants';

import {
  getLatestVersion,
} from '../../../../../actions/member';

const LatestVersion = ({ versionHistoryOpen, toggleVersioHistory, toggleHomeContainer }) => {
  const { i18n, t } = useTranslation('common');
  const dispatch = useDispatch();

  const latestVersion = useSelector(state => state.member.latestVersion);

  useEffect(() => {
    dispatch(getLatestVersion());
  }, []);

  const openVersionHsitory = (item) => {
    toggleHomeContainer(item);
    toggleVersioHistory();
  };
  return (
    <CustomModal
      isOpen={versionHistoryOpen}
      toggle={toggleVersioHistory}
      addClassName="version-history-modal"
      size="lg"
      title={t('home.whatsnew')}
      body={(
        <Row className="zole-rules-page">
          <Col xs="12" className="zole-rules-page-body">
            <div
              className="contact-support-chat-body-scroll-area"
            >
              <div className="latest-version-section">
                <div className="latest-version-section-body">
                  {
                    size(latestVersion) > 0 && map(latestVersion, (item, key) => (
                      <TextEditor viewEditorState={latestVersion[key][i18n.language || constants.sendLanguage.lv]} readOnly />
                    ))
                  }
                  <div className="latest-version-section-body-footer mt-5" onClick={() => openVersionHsitory(constants.FOOTER_NAVIGATION_LINK.versionHistory)}>
                    {t('home.seeAllUpdates')}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
      footer={(
        <Button color="secondary" onClick={toggleVersioHistory}>
          {t('common.close')}
        </Button>
      )}
    />
  );
}

LatestVersion.propTypes = {
  versionHistoryOpen: PropTypes.bool,
  toggleVersioHistory: PropTypes.func.isRequired,
  latestVersion: PropTypes.shape({}),
  toggleHomeContainer: PropTypes.func.isRequired,
};

LatestVersion.defaultProps = {
  versionHistoryOpen: false,
  latestVersion: {},
};

export default LatestVersion;
