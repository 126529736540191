import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import Modal from 'reactstrap/lib/Modal';
import Label from 'reactstrap/lib/Label';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import Gift from './Gift';

import ScrollArea from 'react-scrollbar';
import ScrollAreaButtons from '../UI/ScrollAreaButtons';

import giftImg from '../../../images/Game/gift.svg';
import closeImg from '../../../images/icons/close.png';

// import defaultImage from '../../../images/Game/defaultImage.webp';
import coinImg from '../../../images/coin.svg';
import {
  FONT_MODE,
} from '../../../constants/constants';

class SendGift extends Component {
  static propTypes = {
    sendGift: PropTypes.func.isRequired,
    //  toggle: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    gifts: PropTypes.shape(),
    players: PropTypes.shape(),
    notificationSettings: PropTypes.shape(),
    modalOpen: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    fourPRoom: PropTypes.bool,
    roomId: PropTypes.string,
    initialSelected: PropTypes.string,
    fontMode: PropTypes.string,
  }

  static defaultProps = {
    gifts: {},
    players: {},
    notificationSettings: {},
    modalOpen: false,
    isFullscreen: false,
    fourPRoom: false,
    roomId: null,
    initialSelected: null,
    fontMode: FONT_MODE.normal,
  //  initialSelected: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      giftId: '',
      comment: '',
      playerChecked: {
        player0: false,
        player1: false,
        player2: false,
        player3: false,
      },
      allChecked: false,
      playerKeys: ['player1', 'player2', 'player3', 'player4'],
      //  initialSelected: '',
      //  giftsModal: false,
      sendingGift: false,
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    const { initialSelected, players } = nextProps;
    const { playerChecked } = this.state;
    if (players && initialSelected) {
      let selected;
      if (players.player1 && initialSelected === players.player1.uid) {
        selected = 'player0';
      } else if (players.player2 && initialSelected === players.player2.uid) {
        selected = 'player1';
      } else if (players.player3 && initialSelected === players.player3.uid) {
        selected = 'player2';
      } else if (players.player4 && initialSelected === players.player4.uid) {
        selected = 'player3';
      }

      if (selected) {
        this.setState(prevState => ({
          playerChecked: {
            ...prevState.playerChecked,
            [selected]: true,
          },
        }));
      }
    }

    console.log(playerChecked, "checkPlayer")
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const { name } = event.target;

    const { fourPRoom } = this.props;

    if (name === 'allChecked') {
      const { playButtonSound, players, uid } = this.props;
      const {playerChecked, allChecked} = this.state;
      playButtonSound();
      console.log({fourPRoom})
      if (fourPRoom) {
        this.setState({
          playerChecked: {
            player0: players.player1.uid !== uid ? value : false,
            player1: players.player2.uid !== uid ? value : false,
            player2: players.player3.uid !== uid ? value : false,
            player3: players.player4.uid !== uid ? value : false,
          },
          allChecked: value,
        });
      } else {
        console.log({playerChecked}, {allChecked}, {value})
        this.setState({
          playerChecked: {
            player0: players.player1.uid !== uid ? value : false,
            player1: players.player2.uid !== uid ? value : false,
            player2: players.player3.uid !== uid ? value : false,
          },
          allChecked: value,
        });
      }
    } else if (name === 'player0' || name === 'player1' || name === 'player2' || name === 'player3') {
      const { playButtonSound } = this.props;
      const { playerChecked } = this.state;
      playButtonSound();

      let {
        player0, player1, player2, player3,
      } = playerChecked;
      if (name === 'player0') {
        player0 = value;
      } else if (name === 'player1') {
        player1 = value;
      } else if (name === 'player2') {
        player2 = value;
      } else if (name === 'player3') {
        player3 = value;
      }

      this.setState(prevState => ({
        playerChecked: {
          ...prevState.playerChecked,
          [name]: value,
        },
        allChecked: (!!(!fourPRoom && player0 && player1 && player2) || !!(fourPRoom && player0 && player1 && player2 && player3)),
      }));
    } else {
      const striped = value.replace(/[^A-Za-z0-9\s!?\u0020-\u0080\u0400-\u04FF\u0080-\u024F]/g, '');

      const trimmed = striped.substring(0, 100);

      this.setState({
        [name]: trimmed,
      });
    }
  }

  sendGift = (giftId) => {
    const {
      sendGift, players, roomId, playButtonSound,
    } = this.props;
    const {
      playerChecked, comment, sendingGift,
    } = this.state;

    const {
      player0, player1, player2, player3,
    } = playerChecked;

    const selectedPlayerIds = [];
    if (player0 && players.player1) {
      selectedPlayerIds.push(players.player1.uid);
    }
    if (player1 && players.player2) {
      selectedPlayerIds.push(players.player2.uid);
    }
    if (player2 && players.player3) {
      selectedPlayerIds.push(players.player3.uid);
    }
    if (player3 && players.player4) {
      selectedPlayerIds.push(players.player4.uid);
    }

    this.setState({ confirmationModal: false });

    if (!sendingGift && selectedPlayerIds.length > 0 && giftId) {
      playButtonSound();

      this.setState({ sendingGift: true });

      sendGift(roomId, giftId, comment, selectedPlayerIds).then((res) => {
        if (res === 'success') {
          this.closeModal();
        }
        this.setState({ sendingGift: false });
      }).catch(() => {
        this.setState({ sendingGift: false });
      });
    }
  }

  closeModal = () => {
    const { toggle } = this.props;

    this.setState({
      giftId: '',
      comment: '',
      playerChecked: {
        player0: false,
        player1: false,
        player2: false,
        player3: false,
      },
      allChecked: false,
    });

    toggle(null, false);
  }

  startSendingGift = (giftId) => {
    const { notificationSettings, playButtonSound } = this.props;
    playButtonSound();

    if (notificationSettings && notificationSettings.purchaseGift === false) {
      this.sendGift(giftId);
    } else {
      this.setState({ giftId });
      this.toggleConfirmModal(giftId);
    }
  }

  toggleConfirmModal = (giftId) => {
    const { gifts } = this.props;
    const { playerChecked } = this.state;

    const gift = gifts[giftId];

    if (gift) {
      const { price } = gift;

      let totalPrice = 0;
      let selectedusersCount = 0;

      Object.keys(playerChecked).map((key) => {
        if (playerChecked[key]) {
          totalPrice += price;
          selectedusersCount += 1;
        }
        return null;
      });
      this.setState(prevState => ({
        giftNotSelected: false,
        totalPrice,
        selectedusersCount,
        confirmationModal: !prevState.confirmationModal,
      }));
    } else {
      this.setState(prevState => ({
        giftNotSelected: true,
        giftId: '',
        confirmationModal: !prevState.confirmationModal,
      }));
    }
  }

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.giftsScrollbar.scrollYTo(newVal);
  }

  render() {
    const {
      gifts,
      t,
      modalOpen,
      players,
      playButtonSound,
      isFullscreen,
      fontMode,
    } = this.props;

    const {
      playerChecked,
      allChecked,
      giftId,
      comment,
      confirmationModal,
      totalPrice,
      selectedusersCount,
      giftNotSelected,
      playerKeys,
      scrollPos,
    } = this.state;

    return (
      <div>
        <Modal container={'div > div'} size="sm" isOpen={modalOpen} toggle={this.closeModal} className={`notification gift-modal old-modal ${isFullscreen ? 'gift-modal-fullscreen' : ''} root-font-${fontMode}`}>
          <ModalHeader
            toggle={() => { playButtonSound(); this.closeModal(); }}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => { playButtonSound(); this.closeModal(); }} />
            }
          >
            <Media src={giftImg} />
            {' '}
            {t('gifts.gifts')}
          </ModalHeader>
          <ModalBody className="notification-body">
            <Row>
              <Col>
                {t('gifts.buyAnyGift')}
                <Media style={{display: 'inline-block', width: 22, height: 22}} src={coinImg}/>
              </Col>
            </Row>
            <Row>
              <ScrollAreaButtons
                scrollPos={scrollPos}
                show={this.giftsScrollbar && this.giftsScrollbar.state && this.giftsScrollbar.state.realHeight > this.giftsScrollbar.state.containerHeight ? true : null}
                scroll={this.scroll}
                speed={30}
                rightOffset={0}
                topOffset={6}
                bottomOffset={16}
              />
              <ScrollArea
                speed={0.55}
                className="gift-modal-body-scroll-area"
                contentClassName="gift-modal-body-fontMode-change"
                smoothScrolling={true}
                verticalContainerStyle={{
                  background: 'transparent',
                  opacity: 1,
                  width: 11,
                }}
                verticalScrollbarStyle={{
                  background: '#fff',
                  borderRadius: 1,
                  width: 10,
                }}
                minScrollSize={30}
                horizontal={false}
                onScroll={this.updateScrollPos}
                ref={(el) => { this.giftsScrollbar = el; }}
              >
              <div style={{ maxWidth: '100%%', padding: '15px' }}>
                 
                  {gifts && Object.keys(gifts).map(key => (
                    <Fragment key={key}>
                      <Gift
                        id={key}
                        altClassName="float-left"
                        giftId={giftId}
                        gift={gifts[key]}
                        selectGift={() => this.startSendingGift(key)}
                        isFullscreen={isFullscreen}
                      />
                    </Fragment>
                  ))}
              </div>
              </ScrollArea>
            </Row>
          </ModalBody>
        </Modal>

        <Modal container={'div > div'} isOpen={confirmationModal} toggle={this.toggleConfirmModal} className={`notification gifts-send-confirmation root-font-${fontMode}`}>
          <ModalHeader
            toggle={this.toggleConfirmModal}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleConfirmModal} />
          }
          >{giftNotSelected ? t('home.notifications') : t('home.confirm')}</ModalHeader>
          <ModalBody className="notification-body">
            {giftNotSelected ? (
             `${t('gifts.pick')}`
            ) : (
              <Fragment>
                {selectedusersCount === 0 && `${t('gifts.noUser')}`}
                {selectedusersCount === 1 && `${t('gifts.confirmation')} ${totalPrice} ${t('gifts.coins')}­ `}
                {selectedusersCount > 1  && `${t('gifts.multipleGiftConfirmation')} ${totalPrice} ${t('gifts.coins')}­ `}
              </Fragment>
            )}
          </ModalBody>
          <ModalFooter className="notification-footer">
            {giftNotSelected ? (
              <Button type="button" className="btn notification-footer-button" onClick={this.toggleConfirmModal}>
                {t('common.close')}
              </Button>
            ) : (
              <Fragment>
                {selectedusersCount === 0 ? (
                  <Button type="button" className="btn notification-footer-button" onClick={this.toggleConfirmModal}>
                    {t('common.close')}
                  </Button>
                ) : (
                  <Fragment>
                    <Button className="btn notification-footer-button" onClick={() => this.sendGift(giftId)}>
                     {t('common.yes')}
                    </Button>
                    <Button type="button" className="btn notification-footer-button" onClick={this.toggleConfirmModal}>
                    {t('common.no')}
                    </Button>
                  </Fragment>
                )}
              </Fragment>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  gifts: state.rooms.gifts || {},
  players: state.game.players,
  fourPRoom: state.game.globalParams.fourPRoom,
});

// const mapDispatchToProps = {

// };

export default connect(mapStateToProps, null)(withTranslation('common', 'gifts')(SendGift));
