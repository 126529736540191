import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import classNames from 'classnames';

import pro from '../../../../../images/redesign/common/PRO.svg';
import regularSpeed from '../../../../../images/redesign/common/karavs.svg';
import speed from '../../../../../images/redesign/common/Room types speed.svg';
import lightning from '../../../../../images/redesign/common/Room types lightning.svg';
import unlimited from '../../../../../images/redesign/common/Room types unlimited.svg';
// import minGamesImg from '../../../../images/redesign/common/minGames.svg';
import defaultImage from '../../../../../images/redesign/common/default_image.svg';
import lightDefaultImage from '../../../../../images/redesign/light-mode/common/default_image.webp';
import normalDefaultImage from '../../../../../images/redesign/normal-mode/common/default_image.webp';
import minGamesBell from '../../../../../images/redesign/rooms-table/Call bell.png';

import MenuRow from '../../Components/Components/MenuRow';

import * as constants from '../../../../../constants/constants';
import lockImg from '../../../../../images/redesign/rooms-table/lock.svg';
import { isRegularRoom } from '../../../../../utils/roomUtils';
import IconPopover from '../../Components/Components/IconPopover';

const RoomsTableRowRender = React.memo(({
  tooltipId, room, removal, joinedRoom, uid, leaveRoom, activeTournament, finishedTournamentLastRound, popoverOpen, joinRoomClicked, onHover, onHoverLeave, joinRoomClickedFunc, joinPrivateRoomConfirm, screenMode
}) => {
  const { t } = useTranslation('common');

  if (!room || !room.globalParams || !room.playersList) {
    return null;
  }

  if (!room) {
    return null;
  }

  if (removal) {
    console.log('RoomsTableRowRender removal', { room: room.key, removal2: removal, removal: room.removal });
  }
  console.log("roomPrivate", { room }, { joinedRoom });
  return (
    <MenuRow key={`menu-row-${room.key}`} screenMode={screenMode}>
      <Row key={room.key} data-roomkey={room.key} style={removal ? { opacity: 0 } : { opacity: 1 }} className={`rooms-table-row rooms-table-row-${screenMode}`}>
        <Col xs="2" style={{ display: 'inline-block', position: 'relative' }}>
          <>
            <div className="rooms-table-row-speed">
              {isRegularRoom(room) && (
                <Media className="rooms-table-row-speed-img-regular" src={regularSpeed} alt="" />
              )}
              {room.globalParams.fastGame && (
                <Media className="rooms-table-row-speed-img" src={speed} alt="Ātrā" />
              )}
              {room.globalParams.lightningGame && (
                <Media className="rooms-table-row-speed-img" src={lightning} alt="Zibens" />
              )}
              {room.globalParams.unlimitedGame && ( // TODO 'unlimitedGame' path might change
                <Media className="rooms-table-row-speed-img" src={unlimited} alt="Bez limita" />
              )}
            </div>
            <div className="rooms-table-row-type-wrapper">
              <div className="rooms-table-row-bet">
                {room.globalParams.bet}
              </div>
              <div className="rooms-table-row-type">
                {`${room.globalParams.gameType === 'P' ? t('common.parastas') : t('common.galds')}${room.globalParams.smallGame ? `, ${t('common:newGame.smallGame')}` : ''}`}
              </div>
            </div>

            <div className="rooms-table-row-minGames">
              {room.globalParams.minGames > 1 ? (
                <div className="rooms-table-row-minGames-wrapper">
                    <Media className="rooms-table-row-minGames-bell-img" src={minGamesBell} alt="Ātrā" />
                    <div className="rooms-table-row-minGames-bell-count">{room.globalParams.minGames}</div>
                </div>
              ) : (null)}
            </div>

            <div className="rooms-table-row-pro">
              {room.globalParams.proGame ? (
                <div className="rooms-table-row-pro-wrapper">
                  <Media className="rooms-table-row-pro-img" src={pro} alt="Ātrā" />
                </div>
              ) : (null)}
            </div>

          </>
        </Col>
        <Col xs="7" style={{ height: 'auto' }}>
          <Row>
            <Col xs="3">
              {(room.playersList && room.playersList.player1) ? (
                <>
                  <div className="rooms-table-row-player">
                    <div className="rooms-table-row-player-image-frame">
                      <Media className={`rooms-table-row-player-image ${room.playersList.player1.photo ? '' : 'rooms-table-row-player-image-default'}`} src={room.playersList.player1.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                    </div>
                    <div className="rooms-table-row-player-name">
                      {`${room.playersList.player1.shortName || room.playersList.player1.name}`}
                    </div>
                  </div>
                </>
              ) : (
                <div className="rooms-table-row-player">
                  <div className="rooms-table-row-player-image-frame">
                    <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage)} />
                  </div>
                  <div className="rooms-table-row-player-waiting">
                    {(`${t('roomsTable.waiting')}...`)}
                  </div>
                </div>
              )}
            </Col>
            <Col xs="3">
              {(room.playersList && room.playersList.player2) ? (
                <>
                  <div className="rooms-table-row-player">
                    <div className="rooms-table-row-player-image-frame">
                      <Media className={`rooms-table-row-player-image ${room.playersList.player2.photo ? '' : 'rooms-table-row-player-image-default'}`} src={room.playersList.player2.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                    </div>
                    <div className="rooms-table-row-player-name">
                      {`${room.playersList.player2.shortName || room.playersList.player2.name}`}
                    </div>
                  </div>
                </>
              ) : (
                <div className="rooms-table-row-player">
                  <div className="rooms-table-row-player-image-frame">
                    <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage)} />
                  </div>
                  <div className="rooms-table-row-player-waiting">
                    {(`${t('roomsTable.waiting')}...`)}
                  </div>
                </div>
              )}
            </Col>
            <Col xs="3">
              {(room.playersList && room.playersList.player3) ? (
                <>
                  <div className="rooms-table-row-player">
                    <div className="rooms-table-row-player-image-frame">
                      <Media className={`rooms-table-row-player-image ${room.playersList.player3.photo ? '' : 'rooms-table-row-player-image-default'}`} src={room.playersList.player3.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                    </div>
                    <div className="rooms-table-row-player-name">
                      {`${room.playersList.player3.shortName || room.playersList.player3.name}`}
                    </div>
                  </div>
                </>
              ) : (
                <div className="rooms-table-row-player">
                  <div className="rooms-table-row-player-image-frame">
                    <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage)} />
                  </div>
                  <div className="rooms-table-row-player-waiting">
                    {(`${t('roomsTable.waiting')}...`)}
                  </div>
                </div>
              )}
            </Col>
            <Col xs="3">
              {(room.globalParams && room.globalParams.fourPRoom) ? (
                <>
                  {(room.playersList && room.playersList.player4) ? (
                    <>
                      <div className="rooms-table-row-player">
                        <div className="rooms-table-row-player-image-frame">
                          <Media className={`rooms-table-row-player-image ${room.playersList.player4.photo ? '' : 'rooms-table-row-player-image-default'}`} src={room.playersList.player4.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                        </div>
                        <div className="rooms-table-row-player-name">
                          {`${room.playersList.player4.shortName || room.playersList.player4.name}`}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="rooms-table-row-player">
                      <div className="rooms-table-row-player-image-frame">
                        <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage)} />
                      </div>
                      <div className="rooms-table-row-player-waiting">
                        {(`${t('roomsTable.waiting')}...`)}
                      </div>
                    </div>
                  )}
                </>
              ) : (null)}
            </Col>
          </Row>
        </Col>

        <Col xs="3" className="rooms-table-row-button-wrapper">

          {(room.playersList.player1 && room.playersList.player1.uid === uid)
            || (room.playersList.player2 && room.playersList.player2.uid === uid)
            || (room.playersList.player3 && room.playersList.player3.uid === uid)
            || (room.playersList.player4 && room.playersList.player4.uid === uid)
            ? (
              <Fragment>
                {(room.playersList.player1 && room.playersList.player2 && room.playersList.player3 && (!room.globalParams.fourPRoom || (room.globalParams.fourPRoom && room.playersList.player4))) ? (
                  <Link
                    to={`/zole/${room.key}`}
                    className={classNames(`layout-default-button layout-float-right layout-default-button-${screenMode}`, {
                      'private-room-button': room?.globalParams.privateRoom
                    })}
                  >
                    {t('roomsTable.return')}
                  </Link>
                ) : (
                  <Button
                    disabled={room.filled}
                    color="link"
                    className={classNames(`layout-default-button layout-float-right layout-default-button-${screenMode}`, {
                      'private-room-button': room?.globalParams.privateRoom
                    })}
                    onClick={() => leaveRoom(room.key)}
                  >
                    {t('roomsTable.leave')}
                  </Button>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {room.globalParams.privateRoom ? (
                  <Button
                    color="link"
                    className={classNames(`layout-default-button layout-float-right layout-default-button-${screenMode}`, {
                      'disabled': ((activeTournament && !finishedTournamentLastRound) || joinRoomClicked || joinedRoom),
                      'private-room-button': room?.globalParams.privateRoom,
                    })}
                    onClick={joinPrivateRoomConfirm}
                    id={`join-room-button-${room.key}`}
                    name={room.key}
                    onMouseEnter={onHover}
                    onMouseLeave={onHoverLeave}
                  >
                    {t('roomsTable.join')}
                  </Button>
                ) : (
                  <Button
                      className={`layout-default-button layout-float-right layout-default-button-${screenMode} ${((activeTournament && !finishedTournamentLastRound) || joinRoomClicked || joinedRoom) && 'disabled'}`}
                    color="link"
                    onClick={joinRoomClickedFunc}
                    id={`join-room-button-${room.key}`}
                    name={room.key}
                    onMouseEnter={onHover}
                    onMouseLeave={onHoverLeave}
                  >
                    {t('roomsTable.join')}
                  </Button>
                )}
                { tooltipId && (
                  <>
                    {(activeTournament && !finishedTournamentLastRound) && (
                      <IconPopover text={t('common.inTournamentMessage')} placement="left" popoverOpen={!!(popoverOpen && popoverOpen[room.key])} targetId={tooltipId} />
                    )}
                    {joinedRoom && (
                      <IconPopover text={t('common:popovers.alreadyInRoom')} placement="left" popoverOpen={!!(popoverOpen && popoverOpen[room.key])} targetId={tooltipId} />
                    )}
                  </>
                )}
              </Fragment>
            )}
        </Col>
      </Row>
    </MenuRow>
  );
});

RoomsTableRowRender.propTypes = {
  room: PropTypes.shape(),
  joinedRoom: PropTypes.shape(),
  uid: PropTypes.string,
  removal: PropTypes.bool,
  leaveRoom: PropTypes.func.isRequired,
  activeTournament: PropTypes.string,
  finishedTournamentLastRound: PropTypes.bool,
  popoverOpen: PropTypes.shape(),
  joinRoomClicked: PropTypes.bool,
  onHover: PropTypes.func.isRequired,
  onHoverLeave: PropTypes.func.isRequired,
  joinRoomClickedFunc: PropTypes.func.isRequired,
  joinPrivateRoomConfirm: PropTypes.func.isRequired,
  tooltipId: PropTypes.string,
};

RoomsTableRowRender.defaultProps = {
  room: {},
  joinedRoom: null,
  removal: false,
  uid: null,
  activeTournament: null,
  finishedTournamentLastRound: null,
  popoverOpen: {},
  joinRoomClicked: false,
  tooltipId: null,
};

export default RoomsTableRowRender;
