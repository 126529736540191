import React from 'react';
import PropTypes from 'prop-types';

const Tournaments = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <ol>
          <p class='title'>1. Turnīru rīkošana</p>
          <p>
            Zoles turnīrus rīko spēles administrācija. Tiklīdz ir izveidots kāds jauns
            turnīrs, tā pie sadaļas 'Turnīri' tiek parādīta paziņojuma ikona.
          </p>
          <p>Katram turnīram ir individuāli noteikta iestāšanās maksa.</p>
          <p>
            Spēlētājus sadala tā, lai aizpildītu pēc iespējas vairāk 4-spēlētāju galdus,
            un atlikušos izvieto pie 3-spēlētāju galdiem. Turnīrs nesākas, ja ir mazāk
            par 6 spēlētājiem. Pirmajā kārtā spēlētāji pie galdiem tiek izvietoti pēc
            nejaušības principa.
          </p>
          <p>
            Kad ir izspēlēta kārta, katram spēlētājam piešķir 'lielos punktus', kas ir
            atkarīgi no 'mazajiem punktiem'. 1.vietas ieguvējs pie galda saņem 6
            punktus, bet katras nākamās ieguvējs - par 2 punktiem mazāk. Ja X spēlētājiem
            ir vienāds punktu skaits, tad viņu visu vietām attiecīgos punktus saskaita
            kopā un dala ar X - tik 'lielos punktus' iegūst katrs spēlētājs. Piemēram,
            ja 4-spēlētāju galda noslēgumā mazie punkti ir [+4, -2, -2, 0], tad spēlētāji iegūst šādus attiecīgos lielos punktus - [6, 3, 3, 0].
          </p>
          <p>
            Kārtas aprēķins sākas tikai tad, kad pēdējais turnīra galds ir izspēlējis
            pēdējo partiju. Laika limita sasniegšanas gadījumā, pie katra galda tiek
            automātiski aktivizēta opcija 'Pēdējā partija'.
          </p>
          <p>
            Nākošajā kārtā visus spēlētājus sakārto atkarībā no lielo un mazo punktu
            skaita. Pirmie 4 spēlētāji ir 'pirmais galds', nākamie četri ir 'otrais galds', un tā tālāk. Pie katra galda pirmā roka tiek noteikta tam spēlētājam,
            kurš ir pirmajā vietā. Piemēram - pirmā galda pirmā roka ir spēlētājam ar
            kopvērtējuma pirmo vietu. Otrā galda pirmā roka ir spēlētājam ar
            kopvērtējuma piekto vietu (ja pirmajā galdā ir 4 spēlētāji).
          </p>
          <p>
            Ja kāds no spēlētājiem turnīra vidū iziet no spēles, viņam tiek piešķirts
            zaudējums konkrētajā partijā kā lielajam bezstiķī (-8 vai -12, atkarībā no
            cilvēkiem pie galda), automātiski saņem pēdējo vietu pie galda, neskatoties
            uz saņemtajiem mazajiem punktiem un tiek izslēgts no turnīra. Ja pie
            galda, no kura spēlētājs ir izgājis, paliek 3 spēlētāji, tad viņi turpina
            spēli trijatā, ja paliek 2 spēlētāji, tad abiem atlikušajiem spēlētājiem
            piešķir lielos punktus atkarībā no viņu savstarpējās vietas pēc mazajiem
            punktiem.
          </p>
          <p>Turnīra parametri:</p>
          <ul>
            <li>nosaukums</li>
            <li>veids (P, M, G, Ātrā, Privātā, Pro)</li>
            <li>likme</li>
            <li>dalības maksa</li>
            <li>pauze starp kārtām (1 - 10 minūtes)</li>
            <li>kārtu skaits (4 - 10 kārtas)</li>
            <li>kārtas garums (12, 24 vai 36 partijas)</li>
            <li>reģistrācijas sākums</li>
            <li>turnīra sākums</li>
            <li>maksimālais spēlētāju skaits</li>
            <li>laimesta procents</li>
            <li>turnīra papildu banka</li>
          </ul>
          <p>
          </p>
          <p class='title'>2. Turnīra statuss</p>
          <p>Katram turnīram var būt kāds no tālāk minētiem statusiem:</p>
          <ul>
          <li>apstiprināts: ja turnīrs ir izveidots, bet vēl nav sākusies reģistrēšanās</li>
          <li>reģistrēšanās: ir sākusies reģistrēšanās</li>
          <li>aktīvs: turnīrs ir sācies un notiek kārtu/partiju izspēle</li>
          <li>slēgts: turnīrs ir beidzies</li>
          </ul>
          <p>
          </p>
          <p class='title'>3. Turnīra sākums</p>
          <p>Tiklīdz ir palicis mazāk kā 5 minūtes līdz turnīra sākumam:</p>
          <ul>
            <li>nav vairs iespējams izveidot jaunu istabu vai pievienoties citai</li>
            <li>iesāktā istabā automātiski tiek aktivizēta poga 'Pēdējā partija'</li>
            <li>attiecīgi piemērota arī soda nauda, ja ir nospēlēts mazāk par min partijām</li>
            <li>30 sekundes līdz turnīra sākumam tiek parādīts apstiprinājuma logs par to, vai spēlētājs tiešām vēlas piedalīties turnīrā.</li>
          </ul>
          <p>
          </p>
          <p class='title'>4. Turnīra beigas</p>
          <p>Kad turnīrs ir beidzies, tiek parādīts paziņojuma logs ar rezultātiem.</p>

          <p class='title'>5. Nepietiekams dalībnieku skaits</p>
          <p>
            Ja turnīra reģistrācijas laikā nav pieteikušies vismaz 6 spēlētāji, tad
            turnīrs nenotiek un par to tiek parādīts attiecīgs paziņojums. Iemaksātā
            dalības maksa netiek atmaksāta.
          </p>
          <p class='title'>6. Kārtas laika ierobežojums</p>
          <p>
            Tā kā gadījumā, ja visi spēlētāji pie katras kāršu izdales palaiž garām (šāda partija netiek ieskaitīta kā izspēlēta), ir iespēja, ka šāds galds
            var šādi turpināties mūžīgi, līdz ar to turnīrs nevar iet pie nākošās
            kārtas. Tādēļ ir ieviests laika limits, kas ir atkarīgs no paredzētā partiju
            skaita:
          </p>
          <ul>
            <li>12 partijas: 20 minūtes</li>
            <li>24 partijas: 40 minūtes</li>
            <li>36 partijas: 60 minūtes</li>
          </ul>
          <p>
          </p>
          <p class='title'>7. Pauze jeb iespēja izstāties no konkrētas kārtas</p>
          <p>
            Spēlētājam ir iespēja no kādas konkrētas kārtas izstāties jeb to nopauzēt,
            piemēram - ja ir pazudis interneta pieslēgums vai ir kādas neatliekamas
            darīšanas. Pēc attiecīgās kārtas beigām ir atkal iespēja atgriezties un
            turpināt spēli.
          </p>
          <p class='title'>8. Izstāšanās</p>
          <p>
            Spēlētājs var izlemt izstāties no turnīra, piemēram - ja ir iztērētas
            turnīra monētas, vai arī to nav pietiekami daudz, lai turpinātu spēli.
            Reģistrējoties atkārtoti, tiek atkal iekasēta iestāšanās maksa.
          </p>
          <p>Pēc izstāšanās no turnīra spēlētāja rezultāts tiek anulēts no tabulas.</p>
          <p class='title'>9. Laimests</p>
          <p>
            Visa iemaksātā dalības maksas nauda tiek ieskaitīta kopējā bankā (piemēram,
            10 spēlētāji = 10 x 100 = 1000 Zoles monētas kopējā bankā).
          </p>
          <p>No kopējās turnīra bankas tiek atskaitīti 20% kā turnīra nodeva.</p>
          <p>
            Katram turnīram var definēt 'Uzvarētāju procentu' - procentuāli cik
            spēlētājiem tiks izmaksāts laimests (piemēram, ja turnīrā ir 10 spēlētāji un
            uzvarētāju procents ir 30%, tad banka tiek izdalīta pirmajiem 3
            spēlētājiem). Tāpat arī administrācija var katram turnīram pievienot papildu
            banku - summu, kas tiek pievienota spēlētāju iemaksām un kopā veido kopīgo
            banku.
          </p>
          <ul>
            <li>1.vietas ieguvējam tiek piešķirts 30% no bankas.</li>
            <li>2.vietas ieguvējam tiek piešķirts 20% no bankas.</li>
            <li>3.vietas ieguvējam tiek piešķirts 10% no bankas.</li>
          </ul>
          <p>Atlikušie 40% tiek izdalīti vienlīdzīgi pārējiem no uzvarētāju %.</p>
        </ol>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <ol>
          <p class='title'>1. Организация турниров</p>
          <p>
            Одиночные "золе" турниры организует администрация игры. Как только новый
            турнир будет создан, рядом с разделом «Турниры» появится значок уведомления.
          </p>
          <p>Для каждого турнира предусмотрена отдельная вступительная плата.</p>
          <p>
            Игроки распределяются так, чтобы заполнить как можно больше столов для 4
            игроков, а остальные размещаются за столами для 3 игроков. Турнир не
            начинается, если в нем меньше 6 игроков. В первом раунде игроки за столами
            располагаются случайным образом.
          </p>
          <p>
            После того как раунд сыгран, каждому игроку начисляются «большие очки»,
            которые зависят от «маленьких очков». Обладатель 1 места за столом получает
            6 очков, а обладатель каждого последующего места - на 2 очка меньше. Если у
            X игроков одинаковое количество очков, то их соответствующие очки
            складываются и делятся на X, поэтому каждый игрок получает «большие очки».
            Например, если финальные маленькие очки за столом с 4 игроками равны [+4,
            -2, -2, 0], то игроки получают маленькие очки соответственно - 6, 3, 3, 0.
          </p>
          <p>
            Отсчет раундов начинается только После того как последний стол турнира
            сыграет последнюю руку. По истечении лимита времени за каждым столом
            автоматически активируется опция «Последняя игра».
          </p>
          <p>
            В следующем раунде все игроки ранжируются по количеству больших и малых
            очков. Первые 4 игрока — «первый стол», следующие четыре — «второй стол» и
            так далее. За каждым столом первую руку определяет игрок, занявший первое
            место. Например - первая раздача первого стола принадлежит игроку, занявшему
            первое место в общем зачете. Первая раздача второго стола принадлежит
            игроку, занявшему пятое место в общем зачете (если за первым столом 4
            игрока).
          </p>
          <p>
          Если игрок посередине турнира покидает игру, то ему присуждается проигрыш в этой раздаче как "большому", оставшемуся без взяток (-8 или -12 очков, в зависимости от количества игроков за столом), он автоматически занимает последнее место за столом, вне зависимости от полученных малых очков, и исключается из турнира. Если за столом, который покинул игрок, остаются три игрока, то они продолжают игру втроем, если остаются два игрока, то оба оставшийся игрока получают "большие" очки в зависимости от того, кто из них получил больше "малых" очков.
          </p>
          <p>
          </p>
          <p>Параметры турнира:</p>
          <ul>
            <li>Название</li>
            <li>тип (P, M, G, Fast, Lightning, Private)</li>
            <li>ставка</li>
            <li>вступительный взнос</li>
            <li>перерыв между раундами (1 - 10 минут)</li>
            <li>количество раундов (4 - 10 раундов)</li>
            <li>продолжительность раунда (12, 24 или 36 игры)</li>
            <li>начало регистрации</li>
            <li>начало турнира</li>
            <li>максимальное количество игроков</li>
            <li>процент выигрыша</li>
            <li>дополнительный турнирный банк</li>
          </ul>
          <p>
          </p>
          <p class='title'>2. Статус турнира</p>
          <p>Каждый турнир может иметь один из следующих статусов:</p>
          <ul>
            <li>Утвержденный: если турнир создан, но регистрация еще не началась</li>
            <li>Регистрация: Регистрация началась</li>
            <li>Активный: турнир начался и идут раунды/игры</li>
            <li>Законченный: Турнир окончен.</li>
          </ul>
          <p>
          </p>
          <p class='title'>3. Старт турнира</p>
          <p>До начала турнира осталось менее 5 минут:</p>
          <ul>
            <li>Больше нельзя создать новую комнату или присоединиться к другой</li>
            <li>кнопка "Последняя игра" автоматически активируется в начатой комнате</li>
            <li>
              соответственно штраф применяется, если сыграно меньше минимального
              количества игр
            </li>
            <li>
              За 30 секунд до начала турнира появится окно подтверждения того,
              действительно ли игрок хочет участвовать в турнире.
            </li>
          </ul>
          <p>
          </p>
          <p class='title'>4. Окончание турнира</p>
          <p>По окончании турнира отображается окно уведомления с результатами.</p>
          <p class='title'>5. Недостаточное количество участников</p>
          <p>
            Если при регистрации на турнир зарегистрировалось менее 6 игроков,
            турнир не состоится и будет показано уведомление. Оплаченный взнос за
            участие возврату не подлежит.
          </p>
          <p class='title'>6. Ограничение времени для раунда</p>
          <p>
          Так как в случае, если все игроки пасуют, конкретная партия не считается сыгранной, то если игроки пасуют во всех раздачах, такой раунд может продолжаться бесконечно, а турнир не может перейти к следующему раунду. Поэтому введено ограничение по времени, которое зависит от ожидаемого количества партий:
          </p>
          <ul>
            <li>12 игр: 20 минут</li>
            <li>24 игры: 40 минут</li>
            <li>36 игр: 60 минут</li>
          </ul>
          <p>
          </p>
          <p class='title'>7. Пауза или возможность пропустить раунд</p>
          <p>
            У игрока есть возможность пропустить тот или иной раунд, или приостановить его, например - при потере интернет-соединения или из-за неотложных дел. После окончания раунда Вы сможете вернуться и снова продолжить игру.
          </p>
          <p class='title'>8. Выход</p>
          <p>
            Игрок может принять решение о выходе из турнира, например, если турнирные
            монеты были потрачены или их недостаточно для продолжения игры. При
            перерегистрации вступительный взнос взимается снова.
          </p>
          <p>После выхода из турнира результат игрока аннулируется из таблицы.</p>  
          <p class='title'>9. Победа</p>
          <p>
            Все оплаченные входные деньги зачисляются в общий банк (например, 10 игроков
            = 10 x 100 = 1000 монет Sole в общем банке).
          </p>
          <p>
            20% будут вычтены из общего турнирного банка в качестве турнирного взноса.
          </p>
          <p>
            Вы можете определить «Процент победителя» для каждого турнира - процент
            игроков, которые будут выплачены (например, если в турнире 10 игроков, а
            процент победителей составляет 30%, то банк делится на первых 3 игроки).
            Администрация также может добавить к каждому турниру дополнительный банк -
            сумму, которая прибавляется к взносам игроков и составляет общую сумму о
            банка.
          </p>
          <ul>
            <li>Победителю, занявшему 1 место, присуждается 30% банка.</li>
            <li>Победителю, занявшему 2 место, присуждается 20% банка.</li>
            <li>Победителю, занявшему 3 место, присуждается 10% банка.</li>
          </ul>
          <p>
          </p>
          <p>Оставшиеся 40% распределяются поровну между остальными выигрышными.</p>
        </ol>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <ol>
          <p class='title'>1. Organizing tournaments</p>
          <p>
            Zole tournaments are organized by the game administration. As soon as a new
            tournament has been created, a notification icon will appear next to the
            'Tournaments' section.
          </p>
          <p>There is an individual entry fee for each tournament.</p>
          <p>
            Players are split to fill as many 4-player tables as possible, and the rest
            are placed at 3-player tables. The tournament does not start if there are
            fewer than 6 players. In the first round, the players at the tables are
            placed at random.
          </p>
          <p>
            Once the round has been played, each player is awarded 'big points', which
            depend on the 'small points'. The winner of 1st place at the table receives
            6 points, but the winner of each subsequent place - by 2 points less. If X
            players have the same number of points, then their respective points are
            added together and divided by X - so each player gets 'big points'. For
            example, if the final small points on a 4-player table are [+4, -2, -2, 0],
            then the players get the small points respectively - 6, 3, 3, 0.
          </p>
          <p>
            The round count only starts when the last table of the tournament has played
            the last hand. When the time limit is reached, the 'Last game' option is
            automatically activated at each table.
          </p>
          <p>
            In the next round, all players are ranked according to the number of big and
            small points. The first 4 players are the 'first table', the next four are
            the 'second table', and so on. At each table, the first hand is determined
            by the player in the first place. For example - the first hand of the first
            table is for the player with first place in the overall standings. The first
            hand of the second table is for the player with the fifth place in the
            overall standings (if there are 4 players in the first table).
          </p>
          <p>
            If one of the players leaves the game in the middle of the tournament, he is
            awarded a loss in that hand as the big no-one (-8 or -12, depending on the
            people at the table), automatically takes last place at the table,
            regardless of the small points received, and is eliminated from the
            tournament. If there are 3 players left at the table from which the
            player left, they continue the game in threes, if there are 2 players left,
            the two remaining players will be awarded the big points depending on their
            place in the small points.
          </p>
          <p>Tournament parameters:</p>
          <ul>
            <li>name</li>
            <li>type (P, M, G, Fast, Lightning, Private)</li>
            <li>rate</li>
            <li>membership fees</li>
            <li>break between rounds (1 - 10 minutes)</li>
            <li>number of rounds (4 - 10 rounds)</li>
            <li>round length (12, 24, or 36 games)</li>
            <li>start of registration</li>
            <li>start of the tournament</li>
            <li>maximum number of players</li>
            <li>winning percentage</li>
            <li>additional tournament bank</li>
          </ul>
          <p>
          </p>
          <p class='title'>2. Tournament status</p>
          <p>Each tournament can have one of the following statuses:</p>
          <ul>
            <li>Approved: If the tournament has been created but registration has not yet started</li>
            <li>Registration: Registration has started</li>
            <li>Active: the tournament has started and rounds/games are being played</li>
            <li>Closed: The tournament is over</li>
          </ul>
          <p>
          </p>
          <p class='title'>3. Start of the tournament</p>
          <p>If less than 5 minutes left until the start of the tournament:</p>
          <ul>
            <li>it is no longer possible to create a new room or join another one</li>
            <li>the button 'Last game' is automatically activated in the ongoing room</li>
            <li>accordingly, a fine is applied if less than a minimum number of games are played</li>
            <li>30 seconds before the start of the tournament, a confirmation window will be displayed as to whether the player wants to participate in the
              tournament.</li>
          </ul>
          <p>
          </p>
          <p class='title'>4. End of the tournament</p>
          <p>
            When the tournament is over, a notification window with the results is
            displayed.
          </p>
          <p class='title'>5. Insufficient number of participants</p>
          <p>
            If at least 6 players have not registered during the tournament
            registration, the tournament will not take place and a notification will be
            displayed. The paid participation fee is non-refundable.
          </p>
          <p class='title'>6. Sequence time limit</p>
          <p>
            Since if all players miss each hand, the particular hand will not be counted
            as played, there is a possibility that such a table may continue forever, so
            the tournament cannot go to the next round. Therefore, a time limit has been
            introduced which depends on the expected number of lots:
          </p>
          <ul>
            <li>12 games: 20 minutes</li>
            <li>24 games: 40 minutes</li>
            <li>36 games: 60 minutes</li>
          </ul>
          <p>
          </p>
          <p class='title'>7. Pause or possibility to withdraw from a certain round</p>
          <p>
            The player has the opportunity to withdraw from a particular round or
            suspend it, for example - if the Internet connection is lost or there are
            urgent actions. At the end of the round, you have the opportunity to return
            and continue playing.
          </p>
          <p class='title'>8. Withdrawal</p>
          <p>
            The player may decide to withdraw from the tournament, for example, if the
            tournament coins have been spent or there are not enough of them to continue
            the game. Upon re-registration, the entry fee will be charged again.
          </p>
          <p>
            After leaving the tournament, the player's result is canceled from the
            table.
          </p>
          <p class='title'>9. Winning</p>
          <p>
            All paid-entry money is credited to the total bank (for example, 10 players
            = 10 x 100 = 1000 Zole coins in the total bank).
          </p>
          <p>
            20% will be deducted from the total tournament bank as a tournament fee.
          </p>
          <p>
            You can define a 'Winner's Percentage' for each tournament - the percentage
            of players who will be paid out (for example, if there are 10 players in a
            tournament and the winner's percentage is 30%, then the bank is split for
            the first 3 players). The administration can also add an additional bank to
            each tournament - an amount that is added to the players' contributions and
            makes up the total bank.
          </p>
          <ul>
          <li>The winner of the 1st place is awarded 30% of the bank.</li>
          <li>The winner of the 2nd place is awarded 20% of the bank.</li>
          <li>The winner of the 3rd place is awarded 10% of the bank.</li>
          </ul>
          <p>
          </p>
          <p>The remaining 40% is distributed equally to the rest of the winning %.</p>
        </ol>
      </>
    )
  }
};

Tournaments.propTypes = {
  lang: PropTypes.string,
};

Tournaments.defaultProps = {
  lang: 'lv',
};

export default Tournaments;
