import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import CustomModal from '../../Components/Components/Modal';

import davana from '../../../../../images/Menu/davana.svg';

import allNames from '../../../../../constants/allNames.json';
import * as constants from '../../../../../constants/constants';

import {
  claimHoliday,
} from '../../../../../actions/member';

class HolidayModals extends React.PureComponent {
    static propTypes = {
      firstName: PropTypes.string,
      birthday: PropTypes.number,
      t: PropTypes.func.isRequired,
      i18n: PropTypes.shape.isRequired,
      claimHolidayFunc: PropTypes.func.isRequired,
      todayHolidays: PropTypes.shape(),
      claimedRewards: PropTypes.shape(),
      claimedRewardsFetched: PropTypes.bool,
      run: PropTypes.bool,
      toggleHolidayModal: PropTypes.func.isRequired,
      openModal: PropTypes.bool,
      newVersion: PropTypes.string,
      //  nameDayClaimClicked: PropTypes.bool,
    //  birthdayClaimClicked: PropTypes.bool,
    //  holidayClaimClicked: PropTypes.bool,
      holidayTest: PropTypes.bool,
      selectedTestHoliday: PropTypes.shape({}),
      cancelHolidayTest: PropTypes.func.isRequired,
    }

    static defaultProps = {
      firstName: null,
      birthday: null,
      todayHolidays: {},
      claimedRewards: {},
      claimedRewardsFetched: null,
      openModal: false,
      run: false,
      newVersion: '',
      holidayTest: false,
      selectedTestHoliday: null,
      //  nameDayClaimClicked: false,
    //  birthdayClaimClicked: false,
    //  holidayClaimClicked: false,
    }

    constructor(props) {
      super(props);
      const today = new Date();

      let month = today.getMonth() + 1;
      let date = today.getDate();

      if (month < 10) {
        month = `0${month}`;
      }
      if (date < 10) {
        date = `0${date}`;
      }

      let birthdayDate;
      let birthdayMonth;
      if (props.birthday) {
        const birthdayObject = new Date(props.birthday);
        birthdayDate = birthdayObject.getDate();
        birthdayMonth = birthdayObject.getMonth() + 1;
        if (birthdayDate < 10) {
          birthdayDate = `0${birthdayDate}`;
        }
        if (birthdayMonth < 10) {
          birthdayMonth = `0${birthdayMonth}`;
        }
      }


      this.state = {
        year: today.getFullYear(),
        month,
        date,
        birthdayDate,
        birthdayMonth,
        holidays: {
          newYear: '01-01',
          easter: '03-31',
          '4May': '05-04',
          ligo: '06-23',
          jani: '06-24',
          '1September': '09-01',
          lacplesa: '11-11',
          '18November': '11-18',
          christmas: '12-24',
          christmas2: '12-25',
          oldYear: '12-31',
        },
      };

    //  this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
      this.checkIfHolidayModalOpen();
    }

    componentDidUpdate() {
      this.checkIfHolidayModalOpen();
    }

    checkIfHolidayModalOpen = () => {
      const {
        birthdayClaimClicked, holidayClaimClicked, nameDayClaimClicked, date, month, holidays, year, birthdayDate, birthdayMonth,
      } = this.state;
      const {
        todayHolidays, firstName, claimedRewards, newVerison, toggleHolidayModal,
      } = this.props;

      if (
        (!birthdayClaimClicked && birthdayDate && birthdayDate === date && birthdayMonth === month && (!claimedRewards || !claimedRewards.birthday || claimedRewards.birthday < Date.now() - (1000 * 60 * 60 * 24 * 365)))
          || (!holidayClaimClicked && todayHolidays && todayHolidays.holiday && holidays[todayHolidays.holiday] && holidays[todayHolidays.holiday] === `${month}-${date}` && (!claimedRewards || !claimedRewards.holiday || claimedRewards.holiday !== `${year}-${month}-${date}`))
          || (!nameDayClaimClicked && month === 5 && date === 22 && firstName && ((todayHolidays.names && todayHolidays.names.includes(firstName)) || (firstName && allNames && !allNames.includes(firstName))))
          || (!nameDayClaimClicked && todayHolidays && firstName && todayHolidays.names && todayHolidays.names.includes(firstName) && (!claimedRewards || !claimedRewards.nameDay || claimedRewards.nameDay < Date.now() - (1000 * 60 * 60 * 24 * 365)))
      ) {
        toggleHolidayModal(true);
      }
    }

    claimHoliday = (type) => {
      const { claimHolidayFunc, toggleHolidayModal } = this.props;

      toggleHolidayModal(false);
      claimHolidayFunc(type);

      if (type === 'birthday') {
        this.setState({ birthdayClaimClicked: true });
      } else if (type === 'nameDay') {
        this.setState({ nameDayClaimClicked: true });
      } else if (type === 'holiday') {
        this.setState({ holidayClaimClicked: true });
      }
    }

    getHolidayText = (todayHolidays) => {
      const { t } = this.props;
      return `${t(todayHolidays.holiday)} ${t('coinsGift')}`;
    }

    getTestHolidayText = (testHolidays) => {
      const { t } = this.props;
      return `${t(testHolidays.id)} ${t('coinsGift')}`;
    }

    claimTestHoliday = (type) => {
      const { cancelHolidayTest, claimHolidayFunc } = this.props;
      cancelHolidayTest();
      const holidayTestOption = true;
      claimHolidayFunc(type, holidayTestOption);
    }

    render() {
      const {
        holidayTest, selectedTestHoliday, todayHolidays, firstName, t, claimedRewards, claimedRewardsFetched, newVersion, openModal,
      } = this.props;
      const {
        date, month, year, holidays, nameDayClaimClicked, birthdayClaimClicked, holidayClaimClicked, birthdayDate, birthdayMonth,
      } = this.state;

      console.log('todayHolidays', todayHolidays, claimedRewards, month, date, newVersion);

      if (newVersion && newVersion >= constants.NEW_VERSION && !openModal) {
        if (claimedRewardsFetched && !birthdayClaimClicked && birthdayDate && birthdayDate === date && birthdayMonth === month && (!claimedRewards || !claimedRewards.birthday || claimedRewards.birthday < Date.now() - (1000 * 60 * 60 * 24 * 365))) {
          return (
            <CustomModal
              isOpen
              className="new-version-modal"
              toggle={() => this.claimHoliday('birthday')}
              size="lg"
              title={t('birthday')}
              body={(
                <>
                  <Media src={davana} className="holiday-modal-img" />
                </>
              )}
              footer={(
                <Button color="link" className="notification-footer-button" onClick={() => this.claimHoliday('birthday')}>{t('thanks')}</Button>
              )}
            />
          );
        }

        if (!holidayClaimClicked && todayHolidays && todayHolidays.holiday && holidays[todayHolidays.holiday] && holidays[todayHolidays.holiday] === `${month}-${date}` && (!claimedRewards || !claimedRewards.holiday || claimedRewards.holiday !== `${year}-${month}-${date}`)) {
          return (
            <CustomModal
              isOpen
              className="new-version-modal"
              toggle={() => this.claimHoliday('holiday')}
              size="lg"
              title={t('common:common.congratulations')}
              body={(
                <div className="holiday-modal-content">
                  {this.getHolidayText(todayHolidays)}
                  {/* {t('holiday', { holiday: t(todayHolidays.holiday) })} */}
                  <Media src={davana} className="holiday-modal-img" />
                </div>
              )}
              footer={(
                <Button color="link" className="notification-footer-button" onClick={() => this.claimHoliday('holiday')}>{t('thanks')}</Button>
              )}
            />
          );
        }

        {/* if (!nameDayClaimClicked && firstName && ((todayHolidays.names && todayHolidays.names.includes(firstName)) || (firstName && allNames && !allNames.includes(firstName)))) {
          return (
            <CustomModal
              isOpen
              className="new-version-modal"
              toggle={() => this.claimHoliday('nameDay')}
              size="lg"
              title={t('nameDay')}
              body={(
                <>
                  <Media src={davana} className="holiday-modal-img" />
                </>
              )}
              footer={(
                <Button color="link" className="notification-footer-button" onClick={() => this.claimHoliday('nameDay')}>{t('thanks')}</Button>
              )}
            />
          );
        } */}

        if (!nameDayClaimClicked && todayHolidays && firstName && todayHolidays.names && todayHolidays.names.includes(firstName) && (!claimedRewards || !claimedRewards.nameDay || claimedRewards.nameDay < Date.now() - (1000 * 60 * 60 * 24 * 365))) {
          return (
            <CustomModal
              isOpen
              className="new-version-modal"
              toggle={() => this.claimHoliday('nameDay')}
              size="lg"
              title={t('nameDay')}
              body={(
                <>
                  <Media src={davana} className="holiday-modal-img" />
                </>
              )}
              footer={(
                <Button color="link" className="notification-footer-button" onClick={() => this.claimHoliday('nameDay')}>{t('thanks')}</Button>
              )}
            />
          );
        }
      }
      if (holidayTest) {
        if (selectedTestHoliday.id === constants.BIRTHDAY_HOLIDAY) {
          return (
            <CustomModal
              isOpen
              className="new-version-modal"
              toggle={() => this.claimTestHoliday('birthday')}
              size="lg"
              title={t('birthday')}
              body={(
                <>
                  <Media src={davana} className="holiday-modal-img" />
                </>
              )}
              footer={(
                <Button color="link" className="notification-footer-button" onClick={() => this.claimTestHoliday('birthday')}>{t('thanks')}</Button>
              )}
            />
          );
        } else if (selectedTestHoliday.id === constants.NAMEDAY_HOLIDAY) {
          return (
            <CustomModal
              isOpen
              className="new-version-modal"
              toggle={() => this.claimTestHoliday('nameDay')}
              size="lg"
              title={t('nameDay')}
              body={(
                <>
                  <Media src={davana} className="holiday-modal-img" />
                </>
              )}
              footer={(
                <Button color="link" className="notification-footer-button" onClick={() => this.claimTestHoliday('nameDay')}>{t('thanks')}</Button>
              )}
            />
          );
        } else if (constants.PUB_HOLIDAYS.includes(selectedTestHoliday.id)) {
          return (
            <CustomModal
              isOpen
              className="new-version-modal"
              toggle={() => this.claimTestHoliday('holiday')}
              size="lg"
              title={t('common:common.congratulations')}
              body={(
                <div className="holiday-modal-content">
                  {this.getTestHolidayText(selectedTestHoliday)}
                  {/* {t('holiday', { holiday: t(todayHolidays.holiday) })} */}
                  <Media src={davana} className="holiday-modal-img" />
                </div>
              )}
              footer={(
                <Button color="link" className="notification-footer-button" onClick={() => this.claimTestHoliday('holiday')}>{t('thanks')}</Button>
              )}
            />
          );
        }
      }
      return null;
    }
}

const mapStateToProps = state => ({
  firstName: state.member.firstName || null,
  birthday: state.member.birthday || null,
  todayHolidays: state.member.todayHolidays || null,
  claimedRewards: state.member.claimedRewards || null,
  claimedRewardsFetched: state.member.claimedRewardsFetched || false,
  newVersion: state.member.newVersion,
  nameDayBonus: state.member.nameDayBonus || false,
  birthdayBonus: state.member.birthdayBonus || false,
});

const mapDispatchToProps = {
  claimHolidayFunc: claimHoliday,
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('holiday', 'common')(HolidayModals));
