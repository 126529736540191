import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  Button, Media, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import closeImg from '../../../../images/icons/close.png';
import PulesHelpInfo from './PulesHelpInfo';

const EndResultPules = React.memo(({
  pules, myPos, t, betRatio, fontMode,
}) => {
  const [openPluesInfoModal, setOpenPluesInfoModal] = useState(false);
  const { i18n } = useTranslation();
  const lang = i18n.language || '';

  let player1Pules = 0;
  let player2Pules = 0;
  let player3Pules = 0;

  Object.keys(pules).map((key) => {
    if (pules[key] && pules[key].status === 'active') {
      if (pules[key].player === 'player1') {
        player1Pules += 1;
      } else if (pules[key].player === 'player2') {
        player2Pules += 1;
      } else if (pules[key].player === 'player3') {
        player3Pules += 1;
      }
    }
  });

  return (
    <table className="score-table-sum">
      <thead />
      <tbody style={{ width: '100%' }}>
        <tr>
          <th className="score-table-sum-col-id">
            <div>
              {t('activePules')}
            </div>
          </th>
          <th className="score-table-points-col d-flex align-items-center">
            <>
              {myPos === 'player1' && (
                <>
                    {player2Pules}
                </>
              )}
              {myPos === 'player2' && (
                <>
                    {player3Pules}
                </>
              )}
              {myPos === 'player3' && (
                <>
                    {player1Pules}
                </>
              )}
            </>
          </th>
          <th className="score-table-points-col d-flex align-items-center">
            <>
              {myPos === 'player1' && (
                <>
                    {player1Pules}
                </>
              )}
              {myPos === 'player2' && (
                <>
                    {player2Pules}
                </>
              )}
              {myPos === 'player3' && (
                <>
                    {player3Pules}
                </>
              )}
            </>
          </th>
          <th className="score-table-points-col d-flex align-items-center">
            <>
              {myPos === 'player1' && (
                <>
                    {player3Pules}
                </>
              )}
              {myPos === 'player2' && (
                <>
                    {player1Pules}
                </>
              )}
              {myPos === 'player3' && (
                <>
                    {player2Pules}
                </>
              )}
            </>
          </th>
          <th className="score-table-sum-col-pules" />
          <th className="">
            <div>
              <Button color="link" className="end-result-modal-info-button" onClick={() => setOpenPluesInfoModal(!openPluesInfoModal)}>
                {t('info')}
              </Button>
            </div>
          </th>
        </tr>
        <Modal container={'div > div'} isOpen={openPluesInfoModal} size="lg" toggle={() => setOpenPluesInfoModal(!openPluesInfoModal)} className={`old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={() => setOpenPluesInfoModal(!openPluesInfoModal)}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => setOpenPluesInfoModal(!openPluesInfoModal)} />
            }
          >
            {t('commonPersonalPules')}
          </ModalHeader>
          <ModalBody className="notification-body">
            <div className="px-1" style={{minHeight: 200, height: 'calc(100vh - 350px)', maxHeight: 600, overflowY: 'auto'}}>
              <PulesHelpInfo lang={lang} />
            </div>
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="btn notification-footer-button" onClick={() => setOpenPluesInfoModal(!openPluesInfoModal)}>
              {t('ok')}
            </Button>
          </ModalFooter>
        </Modal>
        {/*  <tr>
            <th className="score-table-sum-col-id">
              <div>
                {t('activePulesCoins')}
              </div>
            </th>
            <th className="score-table-points-col">
              <>
                {myPos === 'player1' && (
                  <>
                    {player2Pules ? player2Pules * betRatio : 0}
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    {player3Pules ? player3Pules * betRatio : 0}
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    {player1Pules ? player1Pules * betRatio : 0}
                  </>
                )}
              </>
            </th>
            <th className="score-table-points-col">
              <>
                {myPos === 'player1' && (
                  <>
                    {player1Pules ? player1Pules * betRatio : 0}
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    { player2Pules? player2Pules * betRatio : 0 }
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    {player3Pules ? player3Pules * betRatio : 0}
                  </>
                )}
              </>
            </th>
            <th className="score-table-points-col">
              <>
                {myPos === 'player1' && (
                  <>
                    {player3Pules ? player3Pules * betRatio : 0}
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    {player1Pules ? player1Pules * betRatio : 0}
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    {player2Pules ? player2Pules * betRatio : 0}
                  </>
                )}
              </>
            </th>
            <th className="score-table-sum-col-pules" />
          </tr> */}
      </tbody>
    </table>
  );
});

EndResultPules.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
  fontMode: PropTypes.string,
};

EndResultPules.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
};

const mapStateToProps = (state, ownProps) => {
  if (state.game) {
    return ({
      pules: state.points.pules || {},
      myPos: state.game.myPos || null,
    });
  }
  return null;
};

export default connect(mapStateToProps, null)(EndResultPules);
