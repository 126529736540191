import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch, connect } from 'react-redux';
// import { isEqual } from 'lodash';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Label from 'reactstrap/lib/Label';

import { isArray, map } from 'lodash';

import {
  nextTourStep, prevTourStep, stopTour, nextTourPage, // prevTourPage,
} from '../../../actions/tour';

import * as constants from '../../../constants/constants';
import closeImg from '../../../images/icons/close_dark.png';
import { setJoyrideEndedTrigger } from '../../../actions/state';

const Tooltip = ({
  index,
  step,
  tooltipProps,
  size,
  t,
  setJoyrideEndedTriggerFunc,
}) => {
  const dispatch = useDispatch();

  let titleNo = '';
  let titleLabel = '';
  if (step.title) {
    const titleArray = t(step.title).split('.');
    titleNo = `${titleArray[0]}.${titleArray[1]}`;
    titleLabel = `${titleArray[2]}`;
  }
  const fontMode = useSelector(state => state.member.fontMode || constants.FONT_MODE.normal);
  console.log('fontMode =====', fontMode);

  return (
    <div className={`tooltip-body root-font-${fontMode}`} {...tooltipProps}>
      <div className="tour-tooltip-header">
        <Media src={closeImg} className="tour-tooltip-header-close" alt="X" onClick={() => { dispatch(stopTour()); }} />
      </div>
      {step && step.title && (
        <h2 className="tooltip-body-title">
          <Label className="tooltip-body-title-no">
            {titleNo}
          </Label>
          {titleLabel}
        </h2>
      )}
      <div className="tooltip-body-content">
        {
          step && step.content && isArray(step.content) ? map(step.content, item => (
            <p className="tooltip-body-content-list">
              {t(item)}
            </p>
          )) : (
            t(step && step.content)
          )
        }
      </div>
      <div className="tooltip-body-footer">
        {
          step && step.stepType !== constants.TOUR_PAGE_LIMIT && (
            <Button
              className="tooltip-body-button tooltip-next_section_button-old"
              disabled={step && step.stepType === constants.TOUR_PAGE_LIMIT}
              style={{ width: 'auto', right: 30, position: 'absolute' }}
              onClick={() => { dispatch(nextTourPage(index, step)); }}
            >
              {t('tutorial.nextSection')}
            </Button>
          )
        }
        <div className="tooltip-body-right-buttons tooltip-body-right-buttons-old">
          {step && step.stepType !== constants.TOUR_PAGE_LIMIT ? (
            <Button
              className="tooltip-body-button tooltip-next_page-button"
              // disabled={index + 1 === size && step && step.stepType !== constants.TOUR_PAGE_LIMIT}
              onClick={() => {
                index + 1 === size && step && step.stepType !== constants.TOUR_PAGE_LIMIT ? (
                  dispatch(nextTourPage(index, step))
                ) : (
                  dispatch(nextTourStep(index, step))
                )
              }}
            >
              {`${t('tutorial.next')} (${constants.TOUR_EXTRA_PAGES.includes(step.stepType) ? index : index + 1} / ${constants.TOUR_EXTRA_PAGES.includes(step.stepType) ? size - 1 : size})`}
              {/* {`${t('tutorial.next')} (${index + 1} / ${size})`} */}
            </Button>
          ) : (index + 1 < size && step.stepType === constants.TOUR_PAGE_LIMIT) ? (
            <Button
              className="tooltip-body-button tooltip-next_page-button"
              // disabled={index + 1 === size && step && step.stepType !== constants.TOUR_PAGE_LIMIT}
              onClick={() => {
                index + 1 === size && step && step.stepType !== constants.TOUR_PAGE_LIMIT ? (
                  dispatch(nextTourPage(index, step))
                ) : (
                  dispatch(nextTourStep(index, step))
                )
              }}
            >
              {`${t('tutorial.next')} (${step && constants.TOUR_EXTRA_PAGES.includes(step.stepType) ? index : index + 1} / ${step && constants.TOUR_EXTRA_PAGES.includes(step.stepType) ? size - 1 : size})`}
            </Button>
          ) : (null)}
          {index + 1 === size && step && step.stepType === constants.TOUR_PAGE_LIMIT && (
            <Button className="tooltip-body-button tooltip-close-button-old" onClick={() => { dispatch(stopTour(index, step)); setJoyrideEndedTriggerFunc(true); }}>
              {t('common.close')}
            </Button>
          )}
          {step && step.stepType === constants.TOUR_FIRST_PAGE && index > 0 ? (
            <Button className="tooltip-body-button tooltip-back-button" onClick={() => { dispatch(prevTourStep(index, step)); }}>
              {t('tutorial.back')}
            </Button>
          ) : step && step.stepType !== constants.TOUR_FIRST_PAGE && index >= 0 && (
            <Button className="tooltip-body-button tooltip-back-button" onClick={() => { dispatch(prevTourStep(index, step)); }}>
              {t('tutorial.back')}
            </Button>
          )}
          {/* {step && step.stepType > 1 && (
            <Button className="tooltip-body-button tooltip-back_section-button" onClick={() => { dispatch(prevTourPage(index, step)); }}>
              {t('tutorial.backSection')}
            </Button>
          )} */}
        </div>
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  continuous: PropTypes.bool,
  index: PropTypes.number,
  step: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    stepType: PropTypes.string,
  }),
  backProps: PropTypes.shape({}),
  closeProps: PropTypes.shape({}),
  primaryProps: PropTypes.shape({}),
  tooltipProps: PropTypes.shape({}),
  skipProps: PropTypes.shape({}),
  size: PropTypes.number,
  t: PropTypes.shape({}),
};

Tooltip.defaultProps = {
  continuous: false,
  index: null,
  step: {},
  backProps: {},
  closeProps: {},
  primaryProps: {},
  tooltipProps: {},
  skipProps: {},
  size: null,
  t: {},
};

const mapDispatchToProps = {
  setJoyrideEndedTriggerFunc: setJoyrideEndedTrigger,
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(Tooltip));