import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Media, Row } from 'reactstrap';
import tutorialImage from '../../../images/icons/help2.webp';
import tournamentsImg from '../../../images/icons/tournaments.webp';
import { MENU_NAVIGATION } from '../../../constants/constants';

const TournamentsHeader = ({activeTab, changeTab, toggleShowTournamentTutorial}) => {
    const { t } = useTranslation('common');

    return (
        <Row className="tournaments-header">
            <Col xs="3">
                <Media src={tournamentsImg} className="tournaments-header-image" />
                <div className="tournaments-header-text">
                    {t('tournaments.tournaments')}
                </div>
            </Col>
            {/* <Col xs="1" /> */}
            <Col>
                <Button color="link" className={`tournaments-header-button tournaments-active ${activeTab === MENU_NAVIGATION.tournaments && 'active'}`} onClick={() => changeTab(MENU_NAVIGATION.tournaments)}>
                    {t('tournaments.activeTournaments')}
                </Button>
            </Col>
            <Col>
                <Button color="link" className={`tournaments-header-button tournaments-future ${activeTab === MENU_NAVIGATION.futureTournaments && 'active'}`} onClick={() => changeTab(MENU_NAVIGATION.futureTournaments)}>
                    {t('tournaments.futureTournaments')}
                </Button>
            </Col>
            <Col>
                <Button color="link" className={`tournaments-header-button tournaments-history ${activeTab === MENU_NAVIGATION.tournamentsHistory && 'active'}`} onClick={() => changeTab(MENU_NAVIGATION.tournamentsHistory)}>
                    {t('tournaments.tournamentHistory')}
                </Button>
            </Col>
            <Col xs="1">
                <Media className="tournaments-header-tutorial" src={tutorialImage} onClick={() => toggleShowTournamentTutorial()} />
            </Col>
        </Row>
    );
};

export default TournamentsHeader;