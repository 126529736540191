import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button, Input, Row, Col,Form, FormGroup, Alert, Label } from 'reactstrap';
// import { Firebase, FirebaseRef } from '../../../lib/firebase';

// import ReactGA from 'react-ga';

// import { login } from '../../../actions/member';
// import * as constants from '../../../constants/constants';

class ResetPassword extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    submitResetPassword: PropTypes.func.isRequired,
    email: PropTypes.string,
    password: PropTypes.string,
    confirmPass: PropTypes.string,
    lang: PropTypes.string,
    error: PropTypes.string,
    success: PropTypes.string,
    messageType: PropTypes.string,
    loading: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    email: PropTypes.string,
    password: PropTypes.string,
    confirmPass: PropTypes.string,
    lang: PropTypes.string,
    error: PropTypes.string,
    success: PropTypes.string,
    messageType: PropTypes.string,
    loading: false,
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { t, submitResetPassword, email, password, confirmPass, lang, error, success, messageType, loading, handleChange } = this.props;

    return (
      <div className="landing-content">
        <div className="landing-form-title">
          <h1>{t('home.resetPassword')}</h1>
        </div>
        {/* <div className="landing-login-with">
          <a href="#" className={!FB || loading ? 'disabled' : ''} onClick={this.FBLogin}><img src={fbLoginImg} /></a>
          <a href="#" className={loading ? 'disabled' : ''} onClick={this.googleLogin}><img src={googleLoginImg} /></a>
          <a href="#" className={loading ? 'disabled' : ''} onClick={this.draLogin}><img src={draLoginImg} /></a>
        </div>

        <div className="landing-login-or">
          <hr />
          <span>{t('home.or')}</span>
        </div> */}

        <Row style={{ margin: 0 }}>
          <Col lg={{ size: 4, offset: 4 }} style={{ textAlign: 'center' }}>
            {success && <Alert color="success">{t(`member.${success}`)}</Alert>}
            {error && <Alert color="danger">{t(`member.${error}`)}</Alert>}

            <Form className="common-form">
              <FormGroup>
                <Label for="password">
                  {t('home.newPass')}
                  *
                </Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  disabled={loading}
                  value={password}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="confirmPass">
                  {t('home.confirmPass')}
                  *
                </Label>
                <Input
                  type="password"
                  name="confirmPass"
                  id="confirmPass"
                  placeholder="••••••••"
                  disabled={loading}
                  value={confirmPass}
                  onChange={handleChange}
                />
              </FormGroup>

              <Row>
                <Col sm="6" className="text-left">
                  {/* <Link className="common-link" to="/sign-up">{t('home.register')}</Link> */}
                {/*  <a href="#" className="common-link" onClick={this.goToSignUp}>
                    <span> {t('home.register')} </span>
                  </a>  */}
                </Col>
                <Col sm="6" className="text-right">
                  {/* <Link className="common-link" to="/forgot-password">{t('home.forgotPass')}</Link> */}
                {/*  <a href="#" className="common-link" onClick={this.goToForgotPassword}>
                    <span> {t('home.forgotPass')} </span>
                  </a> */}
                </Col>
              </Row>

              <Button className="common-button lite-shadow submit-button" disabled={loading} onClick={submitResetPassword}>
                {loading ? t('home.loading') : t('home.reset') }
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    );
  };
}

// export default withRouter(EmailVerify);
export default withTranslation('common')(ResetPassword);
