import amplitude from 'amplitude-js';

const instance = amplitude.getInstance();

export const init = async () => {
  console.log('init amplitude');
  console.log(instance);
  await instance.init('637d728efaa47fb2edd27195da7e1337');
};

export const flush = () => {
  instance.uploadEvents();
};

export const log = (eventName, data = null) => {
  instance.logEvent(eventName, data);
};

export const setUserId = (id) => {
  instance.setUserId(id);
};

export const DAILY_BONUSS = 'daily_bonuss';

export const PURCHASE_START_CLICK = 'purchase-start-click';
export const PURCHASE_START = 'purchase-start';
export const PURCHASET_ERROR = 'purchase-error';
export const PURCHASE_COMPLETE = 'purchase-complete';
export const PURCHASE_CONSUMED = 'purchase-consumed';

export const PLAY_CARD_CLICK = 'play-card-click';
export const PLAY_CARD_START = 'play-card-start';
export const PLAY_CARD_FB_TRIGGERED = 'play-card-fb-triggered';
export const PLAY_CARD_ERROR = 'play-card-error';
