import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Row, Col, Alert, Label, Input, Button, FormGroup, Form } from 'reactstrap';
import * as constants from '../../../../constants/constants';
import CustomModal from '../Components/Components/Modal';
import { TextInput } from '../Components/Components/TextInput';
import Footer from '../Components/Components/Footer';

import Dot from '../../../../images/redesign/player/dot.svg';
import ErrorNotification from '../Components/Components/ErrorNotification';

const SignUp = ({ handleSubmitRegister, toggleTermsOfUse, toggleSignUpOpen, toggleSignUp, loadingSubmit, messageType, email, handleInputChange, error, success, name, surname, password, confirmPass, terms, handleChange }) => {
  const { t } = useTranslation('common');

  return (
    <CustomModal
      onButtonClose={toggleSignUp}
      title={!success ? t('home.register') : t('home.registerSuccess')}
      isOpen
      addClassName="register-modal"
      footer={<Footer loadingSubmit={loadingSubmit} stage={constants.stages.register} handleSubmitRegister={handleSubmitRegister} handleCloseButton={toggleSignUp} success={success} terms={terms} />}
      size={constants.SCREEN_MODE_TYPE.sm}
      toggle={toggleSignUp}
      // footerClose={success}
      body={(
        <Row className="register-password-section d-flex align-items-center">
          <>
            {
              !success ? (
                <Col lg={10} md={10} sm={10} className="register-password-section-content d-flex align-items-center mt-2">
                  {(messageType === constants.stages.register && error === 'auth/email-already-in-use-not-confirmed') && (
                    <ErrorNotification isVisible={true} text={t('member.auth/email-already-in-use-not-confirmed', { email })} isSuccess={false}/>
                  )}
                  <Form className="common-form mt-1">
                    <div className="register-password-section-content-input mb-2">
                      <FormGroup>
                        <TextInput
                          width='100%'
                          contentEditable
                          inputType={constants.PROFILE_FIELDS.name}
                          secondaryPlaceholder={t(`home.${constants.PROFILE_FIELDS.name}`)}
                          initialValue={name}
                          onChange={(value, type) => handleInputChange(value, type)}
                          maxLength={25}
                          autoFocus
                        />
                        {
                          messageType === constants.stages.register && error === "missingFirstName" && (
                            <Alert className="input-col-message" color="danger">{t(`member.${error}`)}</Alert>
                          )
                        }
                      </FormGroup>
                    </div>
                    <div className="register-password-section-content-input mb-2">
                      <FormGroup>
                        <TextInput
                          width='100%'
                          contentEditable
                          inputType={constants.PROFILE_FIELDS.surname}
                          secondaryPlaceholder={t(`home.${constants.PROFILE_FIELDS.surname}`)}
                          initialValue={surname}
                          onChange={(value, type) => handleInputChange(value, type)}
                          maxLength={25}
                        />
                        {
                          messageType === constants.stages.register && error === "missingLastName" && (
                            <Alert className="input-col-message" color="danger">{t(`member.${error}`)}</Alert>
                          )
                        }
                      </FormGroup>
                    </div>
                    <div className="register-password-section-content-input mb-2">
                      <FormGroup>
                        <TextInput
                          width='100%'
                          contentEditable
                          inputType={constants.PROFILE_FIELDS.email}
                          secondaryPlaceholder={t(`home.${constants.PROFILE_FIELDS.email}`)}
                          initialValue={email}
                          onChange={(value, type) => handleInputChange(value, type)}
                          maxLength={100}
                        />
                        {
                          (messageType === constants.stages.register && error === 'auth/email-already-in-use') ? (
                            <Alert className="input-col-message" color="danger">{t('member.auth/email-already-in-use', { email })}</Alert>
                          ) : (messageType === constants.stages.register && (error === 'missingEmail' || error === 'wrongEmail')) ? (
                            <Alert className="input-col-message" color="danger">{t(`member.${error}`)}</Alert>
                          ) : (null)
                        }
                      </FormGroup>
                    </div>
                    <div className="register-password-section-content-input mb-2">
                      <FormGroup>
                        <TextInput
                          type={constants.PROFILE_FIELDS.password}
                          width='100%'
                          contentEditable
                          inputType={constants.PROFILE_FIELDS.password}
                          secondaryPlaceholder={t('home.pass')}
                          initialValue={password}
                          onChange={(value, type) => handleInputChange(value, type)}
                          maxLength={100}
                        />
                        {
                          (messageType === constants.stages.register && (error === 'missingPassword' || error === 'wrongPassword')) && (
                            <Alert className="input-col-message" color="danger">{t(`member.${error}`)}</Alert>
                          )
                        }
                      </FormGroup>
                    </div>
                    <div className="register-password-section-content-input mb-2">
                      <FormGroup>
                        <TextInput
                          type={constants.PROFILE_FIELDS.password}
                          width='100%'
                          contentEditable
                          inputType={constants.PROFILE_FIELDS.confirmPass}
                          secondaryPlaceholder={t('home.confirmPass')}
                          initialValue={confirmPass}
                          onChange={(value, type) => handleInputChange(value, type)}
                          maxLength={100}
                        />
                        {
                          (messageType === constants.stages.register && (error === 'missingConfirmPassword' || error === 'passwordsDontMatch')) && (
                            <Alert className="input-col-message" color="danger">{t(`member.${error}`)}</Alert>
                          )
                        }
                      </FormGroup>
                    </div>
                    <div className="register-password-section-content-input rules-section mb-2 d-flex align-items-start">
                      <Label check>
                        <Input
                          type="checkbox"
                          bsSize="lg"
                          name={constants.PROFILE_FIELDS.terms}
                          checked={terms}
                          onChange={(e) => handleChange(e)}
                          className="tou-custom-checkbox custom-control-input"
                        />
                        <span className="rules custom-control-label">
                          {t('home.acceptRules1')}
                          <Label type="button" className="rules-button" color="link" onClick={toggleTermsOfUse}>
                            {t('home.acceptRules2')}
                          </Label>
                        </span>
                      </Label>
                      {/* <p className="rules">
                        {t('home.acceptRules1')}
                        <Label type="button" className="rules-button" color="link" onClick={toggleTermsOfUse}>
                          {t('home.acceptRules2')}
                        </Label>
                      </p> */}
                    </div>
                  </Form>
                </Col>
              ) : (
                <Col lg={8} className="register-password-section-content d-flex align-items-center mt-2">
                  <div className="register-password-section-content-input mb-2">
                    {t('member.auth/reg-success', { email })}
                  </div>
                </Col>
              )
            }
          </>
        </Row>
      )}
    />
  );
};

SignUp.propTypes = {
  toggleSignUpOpen: PropTypes.bool,
  handleSubmitRegister: PropTypes.func.isRequired,
  toggleSignUp: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  toggleTermsOfUse: PropTypes.func.isRequired,
  email: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  password: PropTypes.string,
  confirmPass: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.string,
  messageType: PropTypes.string,
  loadingSubmit: PropTypes.bool,
  terms: PropTypes.bool,
};

SignUp.defaultProps = {
  toggleSignUpOpen: false,
  error: null,
  success: null,
  email: '',
  name: '',
  surname: '',
  password: '',
  confirmPass: '',
  messageType: null,
  loadingSubmit: false,
  terms: false,
};

export default SignUp;
