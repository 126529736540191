import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';

// import { Helmet } from 'react-helmet';
import Spinner from 'reactstrap/lib/Spinner';

import {
  map, get, size, isEqual,
} from 'lodash';

// import isEqual from 'react-fast-compare';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
// import Media from 'reactstrap/lib/Media';
// import Button from 'reactstrap/lib/Button';

import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

// import myInfoImg from '../../../images/icons/my_profile.png';

import {
  getWeeklyStatistics,
} from '../../../actions/member';
import * as constants from '../../../constants/constants';

const WeeklyStatusHome = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const weeklyStatistics = useSelector(state => state.member.weeklyStatistics || {});
  const [weeklyData, setWeeklyData] = useState(null);

  useEffect(() => {
    dispatch(getWeeklyStatistics());
  }, []);

  useEffect(() => {
    if (weeklyStatistics && size(weeklyStatistics) > 0 && !isEqual(weeklyStatistics, weeklyData)) {
      setWeeklyData(weeklyStatistics);
    }
  }, [weeklyStatistics]);

  const getTrend = (week1, week2) => {
    const trend = week1 - week2;

    return trend;
  };
  return (
    <div className="my-info">
      <Row>
        <Col sm="12">
          <Row className="weekly-statistics">
            <Col sm="12" className="weekly-statistics-table weekly-statistics-table-home">
              <Row className="weekly-statistics-table-header weekly-statistics-table-header-in-modal">
                <Col sm="4" className="weekly-statistics-table-header-col weekly-statistics-table-header-col-in-modal" />
                <Col sm="2" className="weekly-statistics-table-header-col weekly-statistics-table-header-col-in-modal">
                  {t('myInfo.lastWeek')}
                </Col>
                <Col sm="2" className="weekly-statistics-table-header-col weekly-statistics-table-header-col-in-modal">
                  {t('myInfo.twoWeeksAgo')}
                </Col>
                <Col sm="2" className="weekly-statistics-table-header-col weekly-statistics-table-header-col-in-modal">
                  {t('myInfo.trend')}
                </Col>
                <Col sm="2" className="weekly-statistics-table-header-col weekly-statistics-table-header-col-in-modal">
                  {t('myInfo.average')}
                </Col>
              </Row>
              <ScrollAreaWrapper
                className="chat-body-scroll-area weekly-statistics-table-home-modal"
                contentClassName="online-users-ReactTableContainer"
                show={weeklyData && Object.keys(weeklyData).length > 7 ? true : null}
                rightOffset={14}
                topOffset={33}
                bottomOffset={23}
              >
                {weeklyData && size(weeklyData) > 0 ? (
                  <div>
                    {constants.WEEKLY_STATISTICS_DATA && map(constants.WEEKLY_STATISTICS_DATA, (item, key) => (
                      <Row key={key} className="weekly-statistics-table-row weekly-statistics-table-row-in-modal">
                        <Col sm="4" className="weekly-statistics-table-row-col weekly-statistics-table-row-col-in-modal weekly-statistics-table-row-col-text">
                          {t(`myInfo.${key}`)}
                        </Col>
                        <Col sm="2" className="weekly-statistics-table-row-col weekly-statistics-table-row-col-text">
                          {get(weeklyData, `oneWeekAgo.${key}`, 0)}
                        </Col>
                        <Col sm="2" className="weekly-statistics-table-row-col">
                          {get(weeklyData, `twoWeeksAgo.${key}`, 0)}
                        </Col>
                        <Col sm="2" className="weekly-statistics-table-row-col weekly-statistics-table-row-col-text-color">
                          {getTrend(get(weeklyData, `oneWeekAgo.${key}`, 0), get(weeklyData, `twoWeeksAgo.${key}`, 0))}
                        </Col>
                        <Col sm="2" className="weekly-statistics-table-row-col">
                          {(get(weeklyData, `oneWeekAgo.${key}`, 0) + get(weeklyData, `twoWeeksAgo.${key}`, 0)) / 2}
                        </Col>
                      </Row>
                    ))}
                  </div>
                ) : (
                  <div className="weekly-statistics-table-home-modal-loading-spineer">
                    <Spinner color="warning" style={{ width: '4rem', height: '4rem' }} />
                  </div>
                )}
              </ScrollAreaWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

WeeklyStatusHome.propTypes = {
  weeklyStatistics: PropTypes.shape({}),
};

WeeklyStatusHome.defaultProps = {
  weeklyStatistics: {},
};

export default WeeklyStatusHome;
