import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { map, filter } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import CountDownDate from '../UI/CountDownDate';
import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import * as constants from '../../../constants/constants';

import {
  getDiscounts,
} from '../../../actions/shop';

class Discounts extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }),
    discounts: PropTypes.shape([]),
    fetchDiscounts: PropTypes.func.isRequired,
  }

  static defaultProps = {
    discounts: [],
    i18n: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      curDate: Date.now(),
    };
  }

  componentDidMount() {
    const { fetchDiscounts } = this.props;
    fetchDiscounts();

    this.intervalID = setInterval(() => {
      this.setState({
        curDate: Date.now(),
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const {
      discounts,
      t,
      i18n,
    } = this.props;

    const {
      curDate,
    } = this.state;
    return (
      <div className="buy-page-block-container discount-event-container">
        <Row>
          <Col>
            <div className="discount-event-box">
              <ScrollAreaWrapper
                className="tournaments-scrollarea"
                contentClassName="tournaments-scrollarea-body"
                show={discounts && discounts.length > 7 ? true : null}
                rightOffset={17}
                topOffset={50}
                bottomOffset={38}
              >

                <table className="tournaments-table">
                  <colgroup>
                    <col span="1" />
                  </colgroup>
                  <thead className="tournaments-table-header">
                    <tr>
                      <th className="tournaments-table-header-col">
                        {t('buyPage.title')}
                      </th>
                      <th className="tournaments-table-header-col">
                        {t('buyPage.description')}
                      </th>
                      <th className="tournaments-table-header-col">
                        {t('buyPage.typeDiscount')}
                      </th>
                      <th className="tournaments-table-header-col">
                        {t('buyPage.newPrice')}
                      </th>
                      <th className="tournaments-table-header-col">
                        {t('buyPage.bonus')}
                      </th>
                      <th className="tournaments-table-header-col">
                        {t('buyPage.countDown')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tournaments-table-body">
                    {discounts && discounts.length ? discounts.map(discount => (
                      <Fragment key={discount.key}>
                        <tr key={discount.key} className="tournaments-table-row">
                          <td className="tournaments-table-col">
                            {i18n.language === 'en' ? discount.titleEN : (i18n.language === 'lv' ? discount.titleLV : (i18n.language === 'ru' ? discount.titleRU : discount.titleLV))}
                          </td>
                          <td className="tournaments-table-col">
                            {i18n.language === 'en' ? discount.descriptionEN : (i18n.language === 'lv' ? discount.descriptionLV : (i18n.language === 'ru' ? discount.descriptionRU : discount.descriptionLV))}
                          </td>
                          <td className="tournaments-table-col">
                            {t(`common.${discount.type}`)}
                          </td>
                          <td className="tournaments-table-col">
                            {
                              discount.type === constants.SPECIALPRICE ? (
                                map(discount.specialPrice, (item, key) => (
                                  <Row>
                                    <Col sm="6">
                                      {filter(constants.SHOP_PRODUCT_TYPES, product => product.label === key)[0].productNr}
                                      :
                                    </Col>
                                    <Col sm="5">{item || 0}</Col>
                                  </Row>
                                ))
                              ) : discount.type === constants.DISCOUNTPRICE ? (
                                <>
                                  <Row>
                                    <Col sm="6">{`${discount.discountPrice}%`}</Col>
                                    {
                                      map(discount.selectedDiscountPackage, item => (
                                        <Col sm="12">{filter(constants.SHOP_PRODUCT_TYPES, product => product.label === item)[0].productNr}</Col>
                                      ))
                                    }
                                  </Row>
                                </>
                              ) : discount.type === constants.BONUS ? (
                                <>
                                  <Row>
                                    <Col sm="6">{`${discount.discountPrice}`}</Col>
                                  </Row>
                                  <Row>
                                    {
                                      map(discount.selectedDiscountPackage, item => (
                                        <Col sm="12">{filter(constants.SHOP_PRODUCT_TYPES, product => product.label === item)[0].productNr}</Col>
                                      ))
                                    }
                                  </Row>
                                </>
                              ) : (null)
                            }
                          </td>
                          <td className="tournaments-table-col">
                            {discount.type === constants.BONUS ? discount.discountPrice : ''}
                          </td>
                          <td className="tournaments-table-col">
                            <div>
                              <CountDownDate date={discount.filterType === constants.CURRENT ? discount.endDate : (discount.filterType === constants.PLANNED ? discount.startDate : Date.now())} newDate={curDate} />
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    )) : (null)}
                  </tbody>
                </table>
              </ScrollAreaWrapper>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  discounts: state.shop.discounts || {},
});

const mapDispatchToProps = {
  fetchDiscounts: getDiscounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Discounts));
