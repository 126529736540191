import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';

import pro from '../../../../images/icons/pro.svg';
import unlimited from '../../../../images/redesign/common/Room types unlimited.svg';
import speed from '../../../../images/icons/fast_game.svg';
import lightning from '../../../../images/icons/fast_game_lightning.svg';
import keyImg from '../../../../images/icons/private.svg';
import fourPImg from '../../../../images/icons/4_spēlētāji.svg';
import minGamesImg from '../../../../images/icons/min_games.svg';

const RoomsTableFooter = React.memo(({ isFullscreen }) => {
  const { t } = useTranslation('common');

  if (isFullscreen) {
    return (
      <div>
        <Row className="rooms-table-footer">
          <Col>
            <div className="display-inline">
              <div className="gameType display-inline">
                <p className="gameType-text">
                  P
                </p>
              </div>
              <div className="display-inline">
                {' '}
                {t('roomsTable.regular')}
                {' '}
              </div>
            </div>
            <div className="display-inline">
              <div className="gameType display-inline">
                <p className="gameType-text">
                  M
                </p>
              </div>
              <div className="display-inline">
                {' '}
                {t('roomsTable.small')}
                {' '}
              </div>
            </div>
            <div className="display-inline">
              <div className="gameType display-inline">
                <p className="gameType-text">
                  G
                </p>
              </div>
              <div className="display-inline">
                {' '}
                {t('roomsTable.table')}
                {' '}
              </div>
            </div>
            <div className="display-inline">
              <div className="display-inline minGames-wrapper">
                <div className="minGames">
                  <Media className="minGames-img" src={minGamesImg} alt="Min games" />
                  <p className="minGames-text"> 1 </p>
                </div>
              </div>
              <div className="display-inline">
                {' '}
                {t('common.minGames')}
                {' '}
              </div>
            </div>
            <div className="display-inline">
              <Media className="label-private display-inline" src={keyImg} alt="Privāta" />
              <div className="display-inline">
                {' '}
                {t('common.private')}
                {' '}
              </div>
            </div>
            <div className="display-inline">
              <Media className="label-speed display-inline" src={unlimited} alt="Bez limita" />
              <div className="display-inline">
                {' '}
                {t('common.unlimited')}
                {' '}
              </div>
            </div>
            <div className="display-inline">
              <Media className="label-speed display-inline" src={speed} alt="Ātrā" />
              <div className="display-inline">
                {' '}
                {t('common.fastRoom')}
                {' '}
              </div>
            </div>
            <div className="display-inline">
              <Media className="label-speed display-inline" src={lightning} alt="Ātrā" />
              <div className="display-inline">
                {' '}
                {t('common.lightningRoom')}
                {' '}
              </div>
            </div>
            <div className="display-inline">
              <Media className="label-pro display-inline" src={pro} alt="Pro" />
              <div className="display-inline">
                {' '}
                {t('common.proRoom')}
                {' '}
              </div>
            </div>
            <div className="display-inline">
              <Media className="label-pro display-inline" src={fourPImg} alt="4 Spēlētāji" />
              <div className="display-inline">
                {' '}
                {t('common.fourPRoom')}
                {' '}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <div>
      <Row className="rooms-table-footer">
        <Col>
          <div className="display-inline">
            <div className="gameType display-inline">
              <p className="gameType-text">
                P
              </p>
            </div>
            <div className="display-inline">
              {' '}
              {t('roomsTable.regular')}
              {' '}
            </div>
          </div>
          <div className="display-inline">
            <div className="gameType display-inline">
              <p className="gameType-text">
                M
              </p>
            </div>
            <div className="display-inline">
              {' '}
              {t('roomsTable.small')}
              {' '}
            </div>
          </div>
          <div className="display-inline">
            <div className="gameType display-inline">
              <p className="gameType-text">
                G
              </p>
            </div>
            <div className="display-inline">
              {' '}
              {t('roomsTable.table')}
              {' '}
            </div>
          </div>
          <div className="display-inline">
            <div className="display-inline minGames-wrapper">
              <div className="minGames">
                <Media className="minGames-img" src={minGamesImg} alt="Min games" />
                <p className="minGames-text"> 1 </p>
              </div>
            </div>
            <div className="display-inline">
              {' '}
              {t('common.minGames')}
              {' '}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="rooms-table-footer">
        <Col>
          <div className="display-inline">
            <Media className="label-private display-inline" src={keyImg} alt="Privāta" />
            <div className="display-inline">
              {' '}
              {t('common.private')}
              {' '}
            </div>
          </div>
          <div className="display-inline">
            <Media className="label-speed display-inline" src={unlimited} alt="Bez limita" />
            <div className="display-inline">
              {' '}
              {t('common.unlimited')}
              {' '}
            </div>
          </div>
          <div className="display-inline">
            <Media className="label-speed display-inline" src={speed} alt="Ātrā" />
            <div className="display-inline">
              {' '}
              {t('common.fastRoom')}
              {' '}
            </div>
          </div>
          <div className="display-inline">
            <Media className="label-speed display-inline" src={lightning} alt="Ātrā" />
            <div className="display-inline">
              {' '}
              {t('common.lightningRoom')}
              {' '}
            </div>
          </div>
          <div className="display-inline">
            <Media className="label-pro display-inline" src={pro} alt="Pro" />
            <div className="display-inline">
              {' '}
              {t('common.proRoom')}
              {' '}
            </div>
          </div>
          <div className="display-inline">
            <Media className="label-pro display-inline" src={fourPImg} alt="4 Spēlētāji" />
            <div className="display-inline">
              {' '}
              {t('common.fourPRoom')}
              {' '}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
});

RoomsTableFooter.propTypes = {
  isFullscreen: PropTypes.bool,
};

RoomsTableFooter.defaultProps = {
  isFullscreen: false,
};

export default RoomsTableFooter;
