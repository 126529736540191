import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import closeImg from '../../../../../images/redesign/components/modal/close.png';
import lightCloseImg from '../../../../../images/redesign/light-mode/components/modal/close.png';

import mobileCloseImg from '../../../../../images/redesign/components/modal/mobileClose.png';

import * as constants from '../../../../../constants/constants';

const ModalQuestion = React.memo(({
  isOpen, toggle, body, footer, title, size,
}) => {
  const screenMode = useSelector(state => state.member.screenMode || null);


  try {
    return (
      <Modal container={'div > div > div'} isOpen={isOpen} toggle={toggle} className="question-modal" size={size || 'sm'}>
        <ModalHeader close={<Media className="web-close" src={(localStorage.getItem('screenMode') && [constants.SCREEN_MODE.light, constants.SCREEN_MODE.normal].includes(JSON.parse(localStorage.getItem('screenMode')))) ? lightCloseImg : closeImg} alt="X" onClick={toggle} />}>
          <div className="question-modal-title">
            {title}
          </div>
        </ModalHeader>
        <ModalBody className={`question-modal-body d-flex justify-content-center align-items-center ${footer ? '' : 'question-modal-body-noFooter'}`}>
          {body}
        </ModalBody>
        {footer && (
          <ModalFooter>
            {footer}
          </ModalFooter>
        )}
        <Media 
          className="mobile-close"
          src={mobileCloseImg}
          alt="X"
          onClick={toggle}
        />
      </Modal>
    );
  } catch (err) {
    console.log('screenMode 2', screenMode);

    return (
      <Modal container={'div > div > div'} isOpen={isOpen} toggle={toggle} className="question-modal" size={size || 'sm'}>
        <ModalHeader close={<Media className="web-close" src={(screenMode && [constants.SCREEN_MODE.light, constants.SCREEN_MODE.normal].includes(screenMode)) ? lightCloseImg : closeImg} alt="X" onClick={toggle} />}>
          <div className="question-modal-title">
            {title}
          </div>
        </ModalHeader>
        <ModalBody className={`question-modal-body d-flex justify-content-center align-items-center ${footer ? '' : 'question-modal-body-noFooter'}`}>
          {body}
        </ModalBody>
        {footer && (
          <ModalFooter>
            {footer}
          </ModalFooter>
        )}
        <Media 
          className="mobile-close"
          src={mobileCloseImg}
          alt="X"
          onClick={toggle}
        />

      </Modal>
    );
  }
});

ModalQuestion.propTypes = {
  body: PropTypes.shape(),
  footer: PropTypes.shape(),
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string,
  size: PropTypes.string,
  isOpen: PropTypes.bool,
};

ModalQuestion.defaultProps = {
  body: null,
  footer: null,
  title: null,
  isOpen: false,
  size: 'sm',
};

export default ModalQuestion;
