import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import NavLink from 'reactstrap/lib/NavLink';
// import ScrollAreaButtons from '../UI/ScrollAreaButtons';

import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';
import CustomDate from '../UI/CustomDate';

import {
  getPointsHistory,
} from '../../../actions/member';

class PointsHistory extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fetchPointsHistory: PropTypes.func.isRequired,
    pointsHistory: PropTypes.arrayOf(PropTypes.shape({})),
    offset: PropTypes.number,
  }

  static defaultProps = {
    pointsHistory: [],
    offset: 0,
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      scrollPos: 0,
    };
  }

  toggle = async (tab) => {
    const { fetchPointsHistory, offset } = this.props;

    if (tab === '1') {
      await fetchPointsHistory('today', offset);
    } else if (tab === '2') {
      await fetchPointsHistory('yesterday', offset);
    } else if (tab === '3') {
      await fetchPointsHistory('2daysBefore', offset);
    }

    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.messagesScrollbar.scrollYTo(newVal);
  }

  render() {
    const {
      pointsHistory, t,
    } = this.props;

    const { activeTab, scrollPos } = this.state;

    return (
      <Fragment>
        <Row className="money-history-tabs" style={{ marginBottom: 10 }}>
          <Col sm="12">
            <Row style={{ margin: 'auto' }}>
              <Col xs="4" md="4" lg={{ size: 3, offset: 1 }} className="money-history-tab">
                <div className={`money-history-tab-wrapper ${activeTab === '1' && 'active'}`}>
                  <NavLink
                    className="money-history-tab-link"
                    onClick={() => { this.toggle('1'); }}
                  >
                    {t('moneyHistory.today')}
                  </NavLink>
                </div>
              </Col>
              <Col xs="4" md="4" lg="3" className="money-history-tab">
                <div className={`money-history-tab-wrapper ${activeTab === '2' && 'active'}`}>
                  <NavLink
                    className="money-history-tab-link"
                    onClick={() => { this.toggle('2'); }}
                  >
                    {t('moneyHistory.yesterday')}
                  </NavLink>
                </div>
              </Col>
              <Col xs="4" md="4" lg="3" className="money-history-tab">
                <div className={`money-history-tab-wrapper ${activeTab === '3' && 'active'}`}>
                  <NavLink
                    className="money-history-tab-link"
                    onClick={() => { this.toggle('3'); }}
                  >
                    {t('moneyHistory.2daysAgo')}
                  </NavLink>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Row className="money-history-table-header">
              <Col sm="2" className="money-history-table-header-col">
                {t('moneyHistory.time')}
              </Col>
              <Col sm="4" className="money-history-table-header-col">
                {t('moneyHistory.type')}
              </Col>
              <Col className="money-history-table-header-col">
                {t('moneyHistory.old')}
              </Col>
              <Col className="money-history-table-header-col">
                {t('moneyHistory.new')}
              </Col>
              <Col className="money-history-table-header-col">
                {t('moneyHistory.change')}
              </Col>
            </Row>
            {/*  <ScrollAreaButtons
              scrollPos={scrollPos}
            //  scrollRef={this.messagesScrollbar && this.messagesScrollbar.state && this.messagesScrollbar.state.realHeight >
            //  this.messagesScrollbar.state.containerHeight ? this.messagesScrollbar : null}
              show={pointsHistory && pointsHistory.length > 8 ? true : null}
              scroll={this.scroll}
              speed={30}
              rightOffset={12}
              topOffset={23}
              bottomOffset={-3}
            />
            <ScrollArea
              speed={0.65}
              className="money-history-table-scrollarea"
              contentClassName="money-history-table-body"
              smoothScrolling
              stopScrollPropagation
              verticalContainerStyle={{
                background: 'transparent',
                opacity: 1,
                width: 11,
              }}
              verticalScrollbarStyle={{
                background: '#fff',
                borderRadius: 0,
                width: 10,
              }}
              horizontal={false}
              onScroll={this.updateScrollPos}
              ref={(el) => { this.messagesScrollbar = el; }}
            > */}
            <ScrollAreaWrapper
              className="money-history-table-scrollarea"
              contentClassName="money-history-table-body"
              show={(pointsHistory && pointsHistory.length > 8) ? true : null}
              rightOffset={12}
              topOffset={23}
              bottomOffset={-3}
              disableAutoHeight={pointsHistory && pointsHistory.length === 0}
            >
              {(pointsHistory && pointsHistory.length > 0) ? pointsHistory.map((data, index) => (
                <Row key={data.key} className={`top-table-row ${index % 2 === 0 ? ('odd') : ('even')}`} style={{ height: 'auto', minHeight: 34 }}>
                  <Col xs="2" sm="2" className="money-history-table-col">
                    <CustomDate format="hh:mm" date={data.time} />
                  </Col>
                  <Col xs="4" sm="4" className="money-history-table-col">
                    {data.type === 'game' && (
                      t('moneyHistory.gameResult')
                    )}
                    {data.type === 'missTurnMe' && (
                      t('moneyHistory.missTurnMe')
                    )}
                    {data.type === 'missTurnOther' && (
                      t('moneyHistory.missTurnOther')
                    )}
                    {data.type === 'leftRoom' && (
                      t('moneyHistory.leftRoom')
                    )}
                    {data.type === 'leftRoomOther' && (
                      t('moneyHistory.leftRoomOther')
                    )}
                    {data.type === 'adminChange' && (
                      t('moneyHistory.adminChange')
                    )}
                    {data.type === 'endRoomPules' && (
                      t('moneyHistory.endRoomPules')
                    )}
                    {data.type === 'pointsReset' && (
                      t('moneyHistory.pointsReset')
                    )}
                    {data.type === 'profileReset' && (
                      t('moneyHistory.profileReset')
                    )}
                  </Col>
                  <Col className="money-history-table-col">
                    {data.old}
                  </Col>
                  <Col className="money-history-table-col">
                    {data.new}
                  </Col>
                  <Col className="money-history-table-col">
                    {data.change}
                  </Col>
                </Row>
              )) : <div className="player-points-history-empty-entries-old">{((activeTab === '1') ? t('pointsHistory.todayHistoryEmptyEntries') : (activeTab === '2') ? t('pointsHistory.yesterdayHistoryEmptyEntries') : t('pointsHistory.twoDaysAgoHistoryEmptyEntries'))}</div>}
            </ScrollAreaWrapper>
          </Col>
        </Row>


      </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  pointsHistory: state.member.pointsHistory,
  offset: state.member.offset || 0,
});

const mapDispatchToProps = {
  fetchPointsHistory: getPointsHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(PointsHistory));
