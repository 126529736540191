import { RoomsRef } from '../lib/firebase';

export function getPoints(roomId) {
  return dispatch => new Promise((resolve) => {
    RoomsRef.child(`roomsPoints/${roomId}/points/total`).on('value', (snapshot) => {
      const data = snapshot.val() || {};

      dispatch({ type: 'POINTS_TOTAL', data, roomId });
    });

    dispatch({ type: 'POINTS_REPLACE', data: {}, roomId });

    RoomsRef.child(`roomsPoints/${roomId}/points/rounds`).on('child_added', (snapshot2) => {
      const data = snapshot2.val() || null;
      const { key } = snapshot2;

      dispatch({
        type: 'POINTS_CHANGE', data, key, roomId,
      });
    });

    RoomsRef.child(`roomsPoints/${roomId}/points/rounds`).on('child_changed', (snapshot2) => {
      const data = snapshot2.val() || null;
      const { key } = snapshot2;

      dispatch({
        type: 'POINTS_CHANGE', data, key, roomId,
      });
    });

    return resolve();
  }).catch((err) => { console.log(err.message); });
}

export function getPules(roomId) {
  return dispatch => new Promise((resolve) => {
    dispatch({ type: 'PULES_REPLACE', data: {}, roomId });
console.log({roomId}, "get Pules");
    RoomsRef.child(`roomsPoints/${roomId}/points/pules/private`).on('child_added', (snapshot) => {
      const data = snapshot.val() || null;
      const { key } = snapshot;

      dispatch({
        type: 'PULES_CHANGE', data, key, roomId,
      });
    });

    RoomsRef.child(`roomsPoints/${roomId}/points/pules/private`).on('child_changed', (snapshot) => {
      const data = snapshot.val() || null;
      const { key } = snapshot;

      dispatch({
        type: 'PULES_CHANGE', data, key, roomId,
      });
    });

    RoomsRef.child(`roomsPoints/${roomId}/points/pules/private`).on('child_removed', (snapshot) => {
      const { key } = snapshot;

     // console.log('child_removed pules', key);

      dispatch({
        type: 'PULES_REMOVED', key, roomId,
      });
    });

    return resolve();
  }).catch((err) => { console.log(err.message); });
}

export function cancelPointsListeners(roomId) {
  return () => new Promise((resolve) => {
    RoomsRef.child(`roomsPoints/${roomId}/points/total`).off();
    RoomsRef.child(`roomsPoints/${roomId}/points/rounds`).off();

    RoomsRef.child(`roomsPoints/${roomId}/points/pules/private`).off();

    return resolve();
  });
}
