import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import Button from 'reactstrap/lib/Button';

import {
  sendChatMsg,
  readChat,
  stopReadChat,
  setChatAsRead,
  getEmotions,
  setEmotion,
  setProfanitiesNotification,
  getReportedMessagesInRoom
} from '../../../../../actions/room';

import { toggleRoomChat } from '../../../../../actions/userSettings';

import ChatComponent from './ChatComponent';
import CustomModal from '../../Components/Components/Modal';
import * as constants from '../../../../../constants/constants';
// import { Label } from 'reactstrap';
// import DateFormat from '../../../UI/DateFormat';
import CountDownDate from '../../../UI/CountDownDate';


class Chat extends Component {
  static propTypes = {
    name: PropTypes.string,
    photo: PropTypes.string,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    emotions: PropTypes.shape(),
    roomChats: PropTypes.shape(),
    sendChatMessage: PropTypes.func.isRequired,
    setChatMessageAsRead: PropTypes.func.isRequired,
    readChatMessages: PropTypes.func.isRequired,
    stopReadChatMessages: PropTypes.func.isRequired,
    sendEmotion: PropTypes.func.isRequired,
    fetchEmotions: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    toggleRoomChatFunc: PropTypes.func.isRequired,
    supportChatStatus: PropTypes.shape(),
    setProfanitiesNotif: PropTypes.func.isRequired,
    toggleSupport: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    userSettings: PropTypes.shape(),
    roomId: PropTypes.string,
    uid: PropTypes.string,
    profanitiesList: PropTypes.shape(),
    profanitiesNotification: PropTypes.string,
    fontMode: PropTypes.string,
    screenMode: PropTypes.string,
    reportedMessages: PropTypes.shape({}),
    fetchReportedMessagesInRoom: PropTypes.func.isRequired,
    mobileChatOpen: PropTypes.bool,
    cursor: PropTypes.bool,
    cursorExpire: PropTypes.number,
  }

  static defaultProps = {
    match: null,
    name: '',
    photo: '',
    emotions: {},
    roomChats: {},
    supportChatStatus: {},
    userSettings: {},
    roomId: null,
    uid: null,
    profanitiesList: {},
    profanitiesNotification: null,
    screenMode: constants.SCREEN_MODE.normal,
    reportedMessages: {},
    mobileChatOpen: false,
    cursor: null,
    cursorExpire: null,
  }

  constructor(props) {
    super(props);
    this.state = {
    //  roomId: '',
      openChat: true,
      emotionsFetched: false,
      chatRead: false,
      showFirstProfanityNotif: false,
      showProfanityExceededNotif: false,
      openCursorModal: false,
    };
  }

  componentDidMount = () => {
    const {
      roomId,
      userSettings,
      fetchReportedMessagesInRoom
    //  uid,
    } = this.props;

    //  const curUserSettings = userSettings[uid] || userSettings.default || {};

    if (roomId && userSettings && (userSettings.roomChatOpen || userSettings.roomChatOpen === undefined)) {
    //  setTimeout(() => {
      this.readChatMessages(roomId);
      this.fetchEmotions();
      fetchReportedMessagesInRoom(roomId);

      this.setState({ chatRead: true });
      //  }, 2000);

      this.setState({
        openChat: true,
      });
    } else {
      this.setState({
        openChat: false,
      });
    }
  }

  componentWillUnmount() {
    const { stopReadChatMessages, roomId } = this.props;

    if (roomId) {
      stopReadChatMessages(roomId);
    }
  }

  toggleChat = () => {
    const {
      setChatMessageAsRead, roomId, playButtonSound, toggleRoomChatFunc, userSettings, uid,
    } = this.props;
    const { chatRead } = this.state;

    setChatMessageAsRead(roomId);
    playButtonSound();

    const curUserSettings = userSettings[uid] || userSettings.default || {};

    if (!curUserSettings.roomChatOpen && !chatRead) {
      this.readChatMessages(roomId);

      this.setState({ chatRead: true });
    } else {
    //  this.stopReadChatMessages(roomId);
    }

    toggleRoomChatFunc(uid);

    this.setState(prevState => ({
      openChat: !prevState.openChat,
    }));
  }

  fetchEmotions = () => {
    const { fetchEmotions } = this.props;
    const { emotionsFetched } = this.state;

    const { hostname } = window.location;

    let devMode = false;

    if (hostname.includes('dev.') || hostname.includes('localhost')) {
      devMode = true;
    }

    if (!emotionsFetched) {
      fetchEmotions(devMode);
      this.setState({ emotionsFetched: true });
    }
  }

  splitMessage = (text) => {
    const splitText = text.replace(/\s/g, '');
    // const desiredText = splitText.replace(/[^\w\s]/gi, '');

    const desiredText = splitText.replace(/[^āčēģīķļņšūž\w\s]+/gi, '');

    return desiredText.toString();
  }


  sendChatMessage = (inputMessage) => {
    const {
      cursor, cursorExpire, uid, sendChatMessage, roomId, playButtonSound, setProfanitiesNotif, profanitiesList, profanitiesNotification, // name, photo, uid,
    } = this.props;
    if (cursor && cursorExpire > Date.now()) {
      this.setState({
        openCursorModal: true,
      })
      return null;
    } 

    const testMessage = inputMessage.toLowerCase();
    const checkedMessage = this.splitMessage(testMessage);

    let hasProfanities = false;
    if (profanitiesList) {
      Object.keys(profanitiesList).map((key) => {
        if (profanitiesList[key].word) {
          if (testMessage.includes(profanitiesList[key].word.toLowerCase()) || checkedMessage.includes(profanitiesList[key].word.toLowerCase())) {
            hasProfanities = true;
          }
        }
        return null;
      });
    }

    console.log(hasProfanities, profanitiesNotification, roomId, "checklist")

    playButtonSound();
    return sendChatMessage(roomId, inputMessage, hasProfanities, uid) // , name, uid, photo
      .then((res) => {
        console.log(res, "sendChatResult")
        if (res && res.status === 'success' && res.data && res.data === 'profanities exceeded') {
          this.setState({ showProfanityExceededNotif: true });
        } else {
          if (hasProfanities) {
            if ((!profanitiesNotification || profanitiesNotification !== roomId)) {
              setProfanitiesNotif(roomId);
            }
            this.setState({ showFirstProfanityNotif: true });
          }
        }

        return (res.status);
      })
      .catch(err => console.log(err));
  }

  closeProfanityNotif = () => {
    this.setState({ showFirstProfanityNotif: false });
  }

  closeProfanityExceeded = () => {
    this.setState({ showProfanityExceededNotif: false });
  }

  setEmotion = (key) => {
    const {
      sendEmotion, emotions, roomId, playButtonSound,
    } = this.props;
    const { emotionSetClicked } = this.state;

    if (emotions[key] && !emotionSetClicked) {
      playButtonSound();
      this.setState({ emotionSetClicked: true });

      setTimeout(() => {
        this.setState({ emotionSetClicked: false });
      }, 2000);
      return sendEmotion(roomId, emotions[key].image)
        .catch(err => console.log(err));
    }
    return null;
  }

  setChatAsRead = () => {
    const { setChatMessageAsRead, roomId } = this.props;

    return setChatMessageAsRead(roomId)
      .then(res => (res.status))
      .catch(err => console.log(err));
  }

  readChatMessages = (roomId) => {
    const { readChatMessages } = this.props;

    return readChatMessages(roomId)
      .catch((err) => {
        console.log(err);
      });
  }

  toggleCursorModal = () => {
    this.setState({
      openCursorModal: false,
    })
  };

  render = () => {
    const {
      t,
      emotions,
      roomChats,
      toggleSupport,
      roomId,
      uid,
      supportChatStatus,
      userSettings,
      playButtonSound,
      fontMode,
      screenMode,
      reportedMessages,
      name,
      mobileChatOpen,
      cursorExpire,
      soundOn,
    } = this.props;

    const {
      openChat,
      showFirstProfanityNotif,
      showProfanityExceededNotif,
      openCursorModal
    } = this.state;

    return (
      <>
        <ChatComponent
          openChat={openChat}
          toggleChat={this.toggleChat}
          toggleSupport={toggleSupport}
          uid={uid}
          roomId={roomId}
          emotions={emotions}
          chatMessages={roomChats}
          sendMessage={this.sendChatMessage}
          setChatAsRead={this.setChatAsRead}
          setEmotion={this.setEmotion}
          supportChatStatus={supportChatStatus}
          userSettings={userSettings || {}}
          soundOn={soundOn}
          playButtonSound={playButtonSound}
          fontMode={fontMode}
          screenMode={screenMode}
          reportedMessages={reportedMessages}
          name={name}
          mobileChatOpen={mobileChatOpen}
        //  fetchEmotions={this.fetchEmotions}
        />

        <CustomModal
          isOpen={showFirstProfanityNotif}
          toggle={this.closeProfanityNotif}
          size="md"
          largeText
          verticalCentering
          title={t('common.warning')}
          footer={(
            <>
              <Button className="modal-footer-button" onClick={this.closeProfanityNotif}>
                {t('common.ok')}
              </Button>
            </>
          )}
          body={t('common.firstProfanity')}
        />

        <CustomModal
          isOpen={showProfanityExceededNotif}
          toggle={this.closeProfanityExceeded}
          size="md"
          title={t('common.profanityExceededTitle')}
          verticalCentering
          largeText
          footer={(
            <>
              <Button className="modal-footer-button" onClick={this.closeProfanityExceeded}>
                {t('common.ok')}
              </Button>
            </>
          )}
          body={<div className="text-center">{t('common.profanityExceeded')}</div>}
        />

        <CustomModal
          isOpen={openCursorModal}
          toggle={this.toggleCursorModal}
          size="md"
          title={t('common.warning')}
          verticalCentering
          largeText
          footer={(
            <>
              <Button className="modal-footer-button" onClick={this.toggleCursorModal}>
                {t('common.ok')}
              </Button>
            </>
          )}
          body={
            <div className='d-flex align-center flex-column'>
              <div>{t('common.detectMessageByCursor')}</div>
              <div className='d-flex flex-row'>
                <div>{t('common.cursorExpireDate')}</div>
                {cursorExpire && (
                  <CountDownDate date={cursorExpire} newDate={Date.now()} showOption={constants.FORMAT_DATE_TYPE.cursorExpireTime} />
                )}
              </div>
            </div>
          }
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  name: state.member.name || '',
  photo: state.member.photo || '',
  cursor: state.member.cursor || null,
  cursorExpire: state.member.cursorExpire || null,
  emotions: state.rooms.emotions || {},
  roomChats: state.rooms.roomChats ? state.rooms.roomChats : {},
  //  userSettings: state.userSettings || {},
  userSettings: state.userSettings[state.member.uid] ? (state.userSettings[state.member.uid]) : (state.userSettings.default ? state.userSettings.default : {}),
  soundOn: state.userSettings.soundOn,
  profanitiesList: state.rooms.profanitiesList || {},
  profanitiesNotification: state.rooms.profanitiesNotification || null,
  reportedMessages: state.rooms.reportedMessages || {},
});

const mapDispatchToProps = {
  sendChatMessage: sendChatMsg,
  setChatMessageAsRead: setChatAsRead,
  readChatMessages: readChat,
  stopReadChatMessages: stopReadChat,
  fetchEmotions: getEmotions,
  sendEmotion: setEmotion,
  toggleRoomChatFunc: toggleRoomChat,
  setProfanitiesNotif: setProfanitiesNotification,
  fetchReportedMessagesInRoom: getReportedMessagesInRoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Chat));
