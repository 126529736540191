import ReactGA from 'react-ga';

export const initGA = (trackingID) => {
  console.log('initGA');
  ReactGA.initialize(trackingID, {
    gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=none' },
  });
};

export const PageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const Event = (category, action, label) => {
//  console.log('Event', { action });

  ReactGA.event({
    category,
    action,
    label,
  });
};
