export default {
  apiKey: 'AIzaSyB7QvvuDPo1AybxHX0zZoj3GcUgJfx1rs8',
  authDomain: 'zole-app.firebaseapp.com',
  databaseURL: 'https://zole-app.firebaseio.com',
  databaseURL2: 'https://zole-app-leaderboard.firebaseio.com/',
  databaseURL3: 'https://zole-app-admin-logs.firebaseio.com/',
  databaseURL4: 'https://zole-app-status.firebaseio.com/',
  databaseURL5: 'https://zole-app-rooms-public.firebaseio.com/',
  databaseURL6: 'https://zole-app-rooms.firebaseio.com/',
  databaseURL7: 'https://zole-app-user-stats.firebaseio.com/',
  databaseURL8: 'https://zole-app-tournaments.firebaseio.com/',
  projectId: 'zole-app',
  storageBucket: 'zole-app.appspot.com',
  messagingSenderId: '241745987415',
  appId: '1:241745987415:web:be8073c82595d0d5a6e012',
};
