export const getMoneyHistoryText = (type, t) => {
  let text = '';

  switch (type) {
    case 'game':
      text = t('moneyHistory.gameResult');
      break;
    case 'joinPrice':
      text = t('moneyHistory.joinPrice');
      break;
    case 'dailyBonus':
      text = t('moneyHistory.dailyBonus');
      break;
    case 'leaveTournament':
      text = t('moneyHistory.leaveTournament');
      break;
    case 'joinTournament':
      text = t('moneyHistory.joinTournament');
      break;
    case 'winTournament':
      text = t('moneyHistory.winTournament');
      break;
    case 'canceledTournament':
      text = t('moneyHistory.canceledTournament');
      break;
    case 'buyTournamentMoney':
      text = t('moneyHistory.buyTournamentMoney');
      break;
    case 'friendReceived':
      text = t('moneyHistory.friendReceived');
      break;
    case 'friendInviteBonus':
        text = t('moneyHistory.friendInviteBonus');
        break;
    case 'friendSent':
      text = t('moneyHistory.friendSent');
      break;
    case 'purchaseCallback':
      text = t('moneyHistory.purchaseCallback');
      break;
    case 'purchase':
      text = t('moneyHistory.purchase');
      break;
    case 'missTurnMe':
      text = t('moneyHistory.missTurnMe');
      break;
    case 'missTurnOther':
      text = t('moneyHistory.missTurnOther');
      break;
    case 'leftRoom':
      text = t('moneyHistory.leftRoom');
      break;
    case 'leftRoomOther':
      text = t('moneyHistory.leftRoomOther');
      break;
    case 'minGamesPenalty':
      text = t('moneyHistory.minGamesPenalty');
      break;
    case 'minGamesReward':
      text = t('moneyHistory.minGamesReward');
      break;
    case 'adminChange':
      text = t('moneyHistory.adminChange');
      break;
    case 'giftsSent':
      text = t('moneyHistory.giftsSent');
      break;
    case 'giftRemoved':
      text = t('moneyHistory.giftRemoved');
      break;
    case 'endRoomPules':
      text = t('moneyHistory.endRoomPules');
      break;
    case 'achievement':
      text = t('moneyHistory.achievement');
      break;
    case 'balanceReset':
      text = t('moneyHistory.balanceReset');
      break;
    case 'profileReset':
      text = t('moneyHistory.profileReset');
      break;
    case 'profanityPenalty':
      text = t('moneyHistory.profanity');
      break;
    case 'holiday':
      text = t('moneyHistory.holiday');
      break;
    case 'nameDay':
      text = t('moneyHistory.nameDay');
      break;
    case 'birthday':
      text = t('moneyHistory.birthday');
      break;
    case 'loginBonus':
      text = t('moneyHistory.loginBonus');
      break;
    case 'treasureChest':
      text = t('moneyHistory.treasureChest');
      break;
    case 'adventeBonus':
      text = t('moneyHistory.adventeBonus');
      break;
    case 'bonusCode':
      text = t('moneyHistory.bonusCode');
      break;
    case 'emailBonus':
      text = t('moneyHistory.emailBonus');
      break;
    case 'tutorialBonus':
      text = t('moneyHistory.tutorialBonus');
      break;
    case 'adminChangeAuto':
      text = t('moneyHistory.adminChangeAuto');
      break;
  }

  return text;
};

export const getBetValue = (bet) => {
  var betValue = 1;
  if (bet === '1:1') {
    betValue = 1;
  } else if (bet === '1:5') {
    betValue = 5;
  } else if (bet === '1:10') {
    betValue = 10;
  } else if (bet === '1:25') {
    betValue = 25;
  } else if (bet === '1:50') {
    betValue = 50;
  } else if (bet === '1:100') {
    betValue = 100;
  } else if (bet === '1:250') {
    betValue = 250;
  } else if (bet === '1:500') {
    betValue = 500;
  } else if (bet === '1:1000') {
    betValue = 1000;
  } else if (bet === '1:5000') {
    betValue = 5000;
  } else if (bet === '1:10000') {
    betValue = 10000;
  }

  return betValue;
};
