import React from 'react';
import PropTypes from 'prop-types';
import { map, filter, find } from 'lodash';

import { withTranslation } from 'react-i18next';
// import PlayerInfo from '../../Players/Components/PlayerInfo';
import PlayerPositionName from './PlayerPositionName';

import * as constants from '../../../../constants/constants';

const Player4PositionScore = ({ myPos, player1ShortName, player2ShortName, player3ShortName, player4ShortName, playerPules, }) => (

  map(constants.PLAYER_SCORE_4_POSITION[myPos], (item, key) => (
    <th className="score-table-header width-20">
      <PlayerPositionName myPos={item.player} player1ShortName={player1ShortName} player2ShortName={player2ShortName} player3ShortName={player3ShortName} player4ShortName={player4ShortName} />
      <div className="plues-section">
        {
          playerPules && map(filter(playerPules, (pule) => { return pule.toString() === item.player; }), (item, idx) => (
            <div key={idx} className='under-pules' />
          ))
        }
      </div>
    </th>
  ))
);

export default withTranslation('notifications')(Player4PositionScore);
