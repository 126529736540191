import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import './ProgressBar.scss';

// import AnimeCropped from './ProgressBarBG.png';
// import {
//   sendLanguage
// } from '../../../../../constants/constants'

// import mainPlayerTimerBg from './mainPlayerTimer.svg';
// import otherPlayerTimerBg from './otherPlayerTimerBg.svg';
// import otherPlayerTimerBgEN from './otherPlayerTimerBg_en.svg';
// import otherPlayerTimerBgRU from './otherPlayerTimerBg_ru.svg';
import BurryTimerEn from './buryTheCards_en.svg';
import BurryTimerLv from './buryTheCards_lv.svg';
import BurryTimerRu from './buryTheCards_ru.svg';

import makeAMoveTimerEn from './makeAMove_en.svg';
import makeAMoveTimerLv from './makeAMove_lv.svg';
import makeAMoveTimerRu from './makeAMove_ru.svg';

import ChooseTimerEn from './choosing_en.svg';
import ChooseTimerLv from './choosing_lv.svg';
import ChooseTimerRu from './choosing_ru.svg';

const constant = 1.2;
// const otherPLayerTimerImg = {
//   'en': otherPlayerTimerBgEN,
//   'lv': otherPlayerTimerBg,
//   'ru': otherPlayerTimerBgRU
// }

const burryTimerImg = {
  'en': BurryTimerEn,
  'lv': BurryTimerLv,
  'ru': BurryTimerRu
}

const ChooseTimerImg = {
  'en': ChooseTimerEn,
  'lv': ChooseTimerLv,
  'ru': ChooseTimerRu
}

const makeAMoveTimerImg = {
  'en': makeAMoveTimerEn,
  'lv': makeAMoveTimerLv,
  'ru': makeAMoveTimerRu
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const ProgressBar = (props) => {

  const { i18n, t } = useTranslation('admin');

  const [offset, setOffset] = useState(0); // , outerOffset, setOuterOffset
  /*  const [size, setSize] = useState(0);
    const [strokeWidth, setStrokeWidth] = useState(0);
    const [center, setCenter] = useState(0);
    const [radius, setRadius] = useState(0);
    const [circumference, setCircumference] = useState(0); */
  const circleRef = useRef(null);
  const {
    //  size,
    progress,
    //  strokeWidth,
    background,
    notActive,
    isMainPlayer,
    gameState,
    text,
    defaultFlag,
    mWidth,
    speed,
    timer,
    currentTurn,
    uid,
  } = props;

  // console.log('text', text, notActive);

  // if (notActive) {
  //   return null;
  // }

  const { height, width } = useWindowDimensions();

  let size = Math.round(width / 6.5);

  if (width > height) {
    size = Math.round(height / 6.5);
  }

  if (size < 120) {
    size = 120;
  }

  const strokeWidth = mWidth <= 1280 ? Math.round(size / 10) : Math.round(size / 5);

  const center = Math.round(size / 2);
  let radius = 0;
  if (mWidth <= 1280) {
    radius = Math.round((size / 2 - strokeWidth / 2) * 0.85) + 2;
  } else {
    radius = Math.round((size / 2 - strokeWidth / 2) * 1.3) + 2;
  }
  const circumference = Math.round(2 * Math.PI * radius);

  //  console.log('circumference', circumference);

  useEffect(() => {
    if (!notActive) {
      console.log(progress, "progress*****");

      const progressOffset = ((100 - progress) / 100) * circumference;

      setOffset(progressOffset);
      if (timer >= speed) {
        if (isMainPlayer) {
          console.log(progress, "progress***** 111");
        }
        //  circleRef.current.style = 'transition: stroke-dashoffset 4ms ease-in-out';
      } else {
        if (isMainPlayer) {
          // console.log(progress, "progress***** 222");
        }
        //  circleRef.current.style = 'transition: stroke-dashoffset 450ms ease-in-out';
      }
      // circleRef.current.style = 'transition: stroke-dashoffset 450ms ease-in-out';
    } else {
      console.log(progress, "progress====");

      if (progress <= 0) {
        console.log(progress, "progress==== 111");

        const progressOffset = ((100 - progress) / 100) * circumference;

        setOffset(progressOffset);
        //  circleRef.current.style = 'transition: stroke-dashoffset 1ms ease-in-out';
      } else if (circleRef && circleRef.current && progress) {
        console.log(progress, "progress==== 222");

        const progressOffset = ((100 - progress) / 100) * circumference;

        setOffset(progressOffset);
        //  circleRef.current.style = 'transition: stroke-dashoffset 1ms ease-in-out';
      }
    }
  }, [setOffset, progress, circumference, isMainPlayer, gameState, notActive, timer, offset, currentTurn]); // 

  /*  if (notActive) {
      return (
        <div
          style={{
            width: Math.round(size * 2),
            height: Math.round(size * 2),
            marginTop: -size / 2,
            marginLeft: -size / 2,
            position: 'relative',
            pointerEvents: 'none',
          }}
        >
          <div
            className="player-info"
            style={{
              borderRadius: '50%',
              position: 'absolute',
              width: Math.round(size * 0.92),
              height: Math.round(size * 0.92),
              left: Math.round((Math.round(size * 2) - (size * 0.93)) / 2) - 1,
              top: Math.round((Math.round(size * 2) - (size * 0.93)) / 2) + 3,
            }}
          >
            <div className="player-info-player">
              <div className="player-info-player-background">
                <div className="player-info-player-image-wrapper">
                  <div className="player-info-player-image-background" />
                  <div style={{ backgroundImage: background ? `url(${background})` : '' }} className="player-info-player-image" />
                  <div className="player-info-player-image-overlay" />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } */

  console.log('render timer', progress, gameState, offset, timer, speed, notActive, currentTurn, uid);

  if (timer >= speed && offset) {
    setOffset(0);
  }

  console.log('render timer 2', progress, gameState, offset, notActive, currentTurn, uid);

  const speedToTurnTimerTime = (speed) => {
    if (speed === 6) {
      return 5;
    } else if (speed === 14) {
      return 15;
    } else if (speed === 24) {
      return 25;
    }
    return speed;
  }

  return (
    <div
      style={{
        width: Math.round(size),
        height: Math.round(size),
        //  marginTop: -size / 2,
        //  marginLeft: -size / 2,
        top: -size / 2,
        left: -size / 2,
        position: 'relative',
        pointerEvents: 'none',
      }}
    >

      {!notActive ? (
        <>
          <img
            src={gameState === 'choose' ? ChooseTimerImg[i18n.language] : (gameState === 'burry' ? burryTimerImg[i18n.language] : makeAMoveTimerImg[i18n.language])}
            style={{
              width: '200%',
              height: '200%',
              position: 'absolute',
              top: '4px',
              left: '-2px',
              transform: 'scale(0.8)'
            }}
            className='player-timer-circle'
          />
          <svg
            className="svg"
            width={Math.round(size * constant)}
            height={Math.round(size * constant)}
            style={{
              overflow: 'visible',
              maxWidth: 'unset',
              position: 'absolute',
              left: Math.round((Math.round(size * 2) - (size * constant)) / 2) - 1,
              top: Math.round((Math.round(size * 2) - (size * constant)) / 2) + 3,

              //  marginTop: -size / 2,
              //  marginLeft: -size / 2,
              //  left: Math.round(((size * constant)) / 2) - 1,
              //  top: Math.round(((size * constant)) / 2) + 3,
              margin: 'auto',
            }}
          >
            <defs>
              <radialGradient id="myGradient" gradientUnits="userSpaceOnUse">
                <stop offset="81%" stopColor="rgba(210,17,10,1)" />
                <stop offset="82%" stopColor="rgba(210,17,10,0.3)" />
                <stop offset="97.5%" stopColor="rgba(210,17,10,0.3)" />
                <stop offset="98.5%" stopColor="rgba(210,17,10,1)" />
              </radialGradient>
            </defs>
            <circle
              className="svg-circle"
              ref={circleRef}
              stroke="url('#myGradient')"
              style={{ border: '1px solid red' }}
              cx={Math.round(center * constant)}
              cy={Math.round(center * constant)}
              r={mWidth <= 1280 ? 47 : Math.round(radius)}
              shapeRendering="geometricPrecision"
              strokeWidth={strokeWidth}
              transform={`translate(${Math.round(size * constant)}, 0) scale(-1, 1) rotate(-90 ${Math.round((size / 2) * constant)} ${Math.round(size / 2) * constant})`}
              strokeDasharray={circumference}
              strokeDashoffset={((100 - progress) / 100) * circumference}
              style={{ transition: (timer >= speed) ? "stroke-dashoffset 0ms" : "stroke-dashoffset 450ms ease-in-out" }}
            />
          </svg>
        </>
      ) : (null)}
      <div
        className="player-info d-flex align-items-center"
        style={{
          borderRadius: '50%',
          position: 'absolute',
          width: Math.round(size * 0.92),
          height: Math.round(size * 0.92),
          left: Math.round((Math.round(size * 2) - (size * 0.93)) / 2) - 1,
          top: Math.round((Math.round(size * 2) - (size * 0.93)) / 2) + 3,

          //  marginTop: -size / 2,
          //  marginLeft: -size / 2,

          //  left: Math.round((size * 0.93) / 2) - 1,
          //  top: Math.round((size * 0.93) / 2) + 3,
        }}
      >
        {
          !notActive && (
            <div className="mobile-player-progressbar d-none">
              {parseInt((progress / 100) * speedToTurnTimerTime(speed), 10)}
              {/* {parseInt((progress), 10)} */}

            </div>
          )
        }
        <div className="player-info-player">
          <div className="player-info-player-background">
            <div className="player-info-player-image-wrapper">
              <div className="player-info-player-image-background" />
              <div style={{ backgroundImage: background ? `url(${background})` : '', backgroundPosition: 'center', backgroundSize: !defaultFlag ? '100%, 100%' : '80%, 93%' }} className="player-info-player-image" />
              <div className="player-info-player-image-overlay" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  background: PropTypes.string,
  notActive: PropTypes.bool,
  isMainPlayer: PropTypes.bool,
  defaultFlag: PropTypes.bool,
};

ProgressBar.defaultProps = {
  progress: 0,
  background: null,
  notActive: true,
  isMainPlayer: false,
  defaultFlag: false,
};


export default ProgressBar;
