import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { Media } from 'reactstrap/lib';

import GameTimer from './GameTimer';
import GameTimerTournament from './GameTimerTournament';
import { SCREEN_MODE } from '../../../../../constants/constants';

import CloseIcon from '../../../../../images/redesign/mobile/icons/close-icon.svg';

class GameStats extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    gameStartTime: PropTypes.number,
    offset: PropTypes.number,
    gameType: PropTypes.string,
    smallGame: PropTypes.bool,
    bet: PropTypes.string,
    party: PropTypes.number,
    scoreTableOpen: PropTypes.bool,
    rPlayed: PropTypes.number,
    maxGames: PropTypes.number,
    minGames: PropTypes.number,
    speed: PropTypes.number,
    privateRoom: PropTypes.bool,
    screenMode: PropTypes.string,
    gameStatsMobileOpen: PropTypes.bool,
    toggleGameStatusMobile: PropTypes.func.isRequired,
    tournamentRound: PropTypes.number
  }

  static defaultProps = {
    gameStartTime: null,
    offset: null,
    gameType: null,
    smallGame: null,
    bet: null,
    party: null,
    scoreTableOpen: true,
    rPlayed: null,
    maxGames: null,
    minGames: null,
    speed: null,
    privateRoom: false,
    screenMode: SCREEN_MODE.dark,
    gameStatsMobileOpen: false,
  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const {
      t,
      gameStartTime,
      offset,
      gameType,
      smallGame,
      bet,
      //  party,
      scoreTableOpen,
      rPlayed,
      maxGames,
      minGames,
      speed,
      privateRoom,
      screenMode,
      gameStatsMobileOpen,
      toggleGameStatusMobile,
      tournamentRound,
    } = this.props;

    return (
      <div className={classNames('game-stats-row', {
        'game-status-row-mobile-open': gameStatsMobileOpen,
        'game-status-row-mobile-close': !gameStatsMobileOpen,
      })}>
        <div className={classNames('game-stats-inner', {
          'game-stats-inner-tournament-header': maxGames,
        })}>
          {maxGames ? (
            <div className={`game-stats-inner-tournament-header-time-${screenMode}`}>
              {t('game:timeTournament', { round: tournamentRound })}
              {gameStartTime ? (
                <GameTimerTournament
                  gameStartTime={gameStartTime}
                  offset={offset}
                  maxGames={maxGames}
                />
              ) : (null)}
            </div>
          ) : (null)}
          <div className="game-stats-inner-tournament-header-optional">
            <div className='game-status-mobile-row'>
              <div className={`game-stats-label mr-1 game-stats-inner-tournament-header-time-${screenMode}`}>
                {`${t('game:time')}: `}
              </div>
              <div className="game-stats-value">
                {gameStartTime ? (
                  <GameTimer
                    gameStartTime={gameStartTime}
                    offset={offset}
                  />
                ) : (null)}
              </div>
              <div className="game-stats-divider" />
            </div>
            <div className='game-status-mobile-row'>
              <div className={`game-stats-label mr-1 game-stats-inner-tournament-header-time-${screenMode}`}>{`${t('game:speed')}: `}</div>
              <div className="game-stats-value mr-1">{t(`game:${speed}`)}</div>
              <div className="game-stats-divider" />
            </div>

            <div className='game-status-mobile-row'>
              <div className={`game-stats-label mr-1 game-stats-inner-tournament-header-time-${screenMode}`}>{`${t('game:minGames')}: `}</div>
              <div className="game-stats-value mr-1">{minGames}</div>
              <div className="game-stats-divider" />
            </div>

            <div className='game-status-mobile-row'>
              <div className={`game-stats-label mr-1 game-stats-inner-tournament-header-time-${screenMode}`}>{`${t('game:parties')}: `}</div>
              <div className="game-stats-value mr-1">{rPlayed ? (rPlayed + 1) : 1}</div>
              <div className="game-stats-divider" />
            </div>

            <div className='game-status-mobile-row'>
              <div className={`game-stats-label mr-1 game-stats-inner-tournament-header-time-${screenMode}`}>{`${t('game:bet')}: `}</div>
              <div className="game-stats-value mr-1">{bet}</div>
            </div>

            <div className='game-status-mobile-row'>
              {privateRoom ? (
                <>
                  <div className="game-stats-divider" />
                  <div className="game-stats-value game-stats-room-value">
                    {t('game:privateRoom')}
                  </div>
                </>
              ) : (null)}
              <div className="game-stats-divider" />
            </div>

            <div className='game-status-mobile-row'>
              <div className={`game-stats-label mr-1 game-stats-inner-tournament-header-time-${screenMode}`}>{`${t('newGame.roomType')}: `}</div>
              <div className="game-stats-value mr-1">
                {
                  gameType === 'P' ? (smallGame ? <>PM</> : <>P</>) : (smallGame ? <>MG</> : <>G</>)
                }
              </div>
            </div>
          </div>
        </div>
        <Media className="game-stats-mobile-close-icon" alt="" onClick={toggleGameStatusMobile} src={CloseIcon} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let speed = 'speedParasta';

  if (state.game.globalParams.speed < 14) speed = 'speedZibens';
  else if (state.game.globalParams.speed < 24) speed = 'speedAtra';
  else if (state.game.globalParams.speed >= 60) speed = 'speedUnlimited';

  return ({
    gameStartTime: state.game.globalParams.gameStartTime,
    offset: state.member.offset || 0,
    gameType: state.game.globalParams.gameType,
    smallGame: state.game.globalParams.smallGame,
    bet: state.game.globalParams.bet,
    speed,
    privateRoom: state.game.globalParams.privateRoom,
    party: state.game.globalParams.party,
    rPlayed: state.game.globalParams.rPlayed || 0,
    maxGames: state.game.globalParams.tournamentRoom ? state.game.globalParams.maxGames : null,
    minGames: state.game.globalParams.minGames || 1,
    tournamentRound: state.game.globalParams.tournamentRound,
  })
};

export default connect(mapStateToProps, null)(withTranslation('common')(GameStats));
