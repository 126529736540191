import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

// import { Helmet } from 'react-helmet';
import { map, filter } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import DropdownItem from 'reactstrap/lib/DropdownItem';

// import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';

// import CustomModal from '../../Components/Components/Modal';
import LandingTopList from './LandingTopList';

import CustomDropdown from '../../Components/Components/CustomDropdown';
// import SwitchSelect from '../../Components/Components/SwitchSelect';

import * as constants from '../../../../../constants/constants';

import buttonClickedSound from '../../../../../sounds/click_feedback.flac';
import proIcon from '../../../../../images/icons/proIcon.svg';

import {
  getLeaderboardLandingFiltered,
} from '../../../../../actions/leaderboard';


const FilterMap = [
  { name: 'Visu laiku', value: '5' },
  { name: 'Šodien', value: '1' },
  { name: 'Šonedēļ', value: '2' },
  { name: 'Šomēnes', value: '3' },
  { name: 'Šogad', value: '4' },
];

const FilterMapRu = [
  { name: 'Все время', value: '5' },
  { name: 'Сегодня', value: '1' },
  { name: 'На этой неделе', value: '2' },
  { name: 'Этот месяц', value: '3' },
  { name: 'Этот год', value: '4' },
];

const FilterMapEn = [
  { name: 'All Time', value: '5' },
  { name: 'Today', value: '1' },
  { name: 'This Week', value: '2' },
  { name: 'This Month', value: '3' },
  { name: 'This Year', value: '4' },
];

const TypeMap = {
  1: 'Punkti',
  2: 'Bilance',
  3: 'Izspēlētās spēles',
  //  4: 'Uzvarētās lielās zoles',
  //  5: 'Uzvarētās mazās zoles',
  //  6: 'Uzvarētie galdi',
  //  7: 'Sasniegumu skaits',
  //  8: 'Raundi vienā spēlē'
};

const TypeMapRu = {
  1: 'Очки',
  2: 'Баланс',
  3: 'Количество сыгранных партий',
  //  4: 'Large zole won',
  //  5: 'Small zole won',
  //  6: 'Tables won',
  //  7: 'Amount of achievements',
  //  8: 'Max Rounds In one game'
};

const TypeMapEn = {
  1: 'Points',
  2: 'Balance',
  3: 'Games played',
  //  4: 'Large zole won',
  //  5: 'Small zole won',
  //  6: 'Tables won',
  //  7: 'Amount of achievements',
  //  8: 'Max Rounds In one game'
};

class LandingTop extends React.Component {
  static propTypes = {
    landingLeaderboard: PropTypes.arrayOf(PropTypes.shape({})),
    t: PropTypes.func.isRequired,
    fetchLeaderboardFiltered: PropTypes.func.isRequired,
  //  userSettings: PropTypes.shape({
  //    soundOn: PropTypes.bool,
  //  }),
    uid: PropTypes.string,
    i18n: PropTypes.shape(),
  }

  static defaultProps = {
    landingLeaderboard: {},
  //  userSettings: {},
    i18n: null,
    uid: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: constants.filterLeaderboardActiveTypes.allTime,
      activeType: constants.filterLeaderboardTypeVal.totalPnts,
      filterOpen: false,
      typeFilterOpen: false,
      tabFilterOpen: false,
      scrollPos: 0,
      millionairesOpen: false,
      showOnlyActive: false,
      filterModalOpen: false,
      orderby: null,
      order: null,
      searchValue: '',
      isFiltered: false,
      popoverOpen: false,
      alertType: '',
      alertStatus: false,
      message: '',
      showFields: {
        showFieldArray: [],
      },
    };

    //  this.toggleFilter = this.toggleFilter.bind(this);

    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentDidMount() {

    const { fetchLeaderboardFiltered } = this.props;
    const { activeTab, activeType } = this.state;
    fetchLeaderboardFiltered(filter(constants.filterLeaderboardType, ['id', activeType.toString()])[0].label, filter(constants.filterLeaderboardActive, ['id', activeTab.toString()])[0].label);
  }

  toggle = (key) => {
    console.log("11111111111111111: ", key)
    const {
      fetchLeaderboardFiltered,
    } = this.props;
    if (key) {
      const { activeTab, activeType, } = this.state;
      let filterType = '';
      filterType = constants.filterLeaderboardType.filter(itemKey => itemKey.id === activeType.toString())[0].label;
      let dateType = '';
      dateType = constants.filterLeaderboardActive.filter(itemKey => itemKey.id === key.toString())[0].label;
      if (activeTab.toString() !== key.toString()) {
        this.playButtonSound();
        fetchLeaderboardFiltered(filterType, dateType);
      }
      this.setState({
        activeTab: key,
        isFiltered: false,
      });
    }
  }

  toggleType = (key) => {
    const {
      fetchLeaderboardFiltered,
    } = this.props;
    if (key) {
      console.log("call toggle type");
      const { searchValue, activeType, activeTab, showOnlyActive, millionairesOpen, showFields, filters } = this.state;

      let dateType = '';
      dateType = constants.filterLeaderboardActive.filter(itemKey => itemKey.id === activeTab.toString())[0].label;
      let keyType = '';
      keyType = constants.filterLeaderboardType.filter(itemKey => itemKey.id === key.toString())[0].label;

      if (constants.SHOW_PLACE_FILEDS.indexOf(keyType) !== -1) {
        const { showFieldArray } = showFields;
        const showFieldArrayTemp = [...showFieldArray];
        const index = showFieldArrayTemp.indexOf(keyType);

        if (index === -1) {
          // NOTE: currently it will be possible to select only 1 extra column, but the showFieldArray will remain as it may change
          // otherwise array can be replaced with a single variable containing keyType of extra table column
          if (showFieldArrayTemp.length > 0) {
            showFieldArrayTemp.pop();
          }
          showFieldArrayTemp.push(keyType);
        } else {
          showFieldArrayTemp.splice(index, 1);
        }
        this.setState(prevState => ({
          showFields: {
            ...prevState.showFields,
            showFieldArray: showFieldArrayTemp,
          },
        }));
        console.log(showFields, keyType, showFieldArrayTemp, "cehck rerender showfileds1");
        return;
      }


      if (activeType.toString() !== key.toString()) {
        this.playButtonSound();
        fetchLeaderboardFiltered(keyType, dateType);
      }
      this.setState({
        activeType: key,
        isFiltered: false,
      });
    }
  }

  updateScrollPos = (val) => {
    console.log('updateScrollPos', { val });
    if (val.topPosition || val.topPosition === 0) {
      this.setState({ scrollPos: val.topPosition || 0 });
    }
  }

  scroll = (newVal) => {
    console.log('scroll', { newVal });
    this.tableScrollbar.scrollYTo(newVal);
  }

  playButtonSound = () => {
    const { soundOn, uid } = this.props;

      if (soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
  }

  toggleFilter = () => {
    this.setState(prevState => ({
      filterOpen: !prevState.filterOpen,
      typeFilterOpen: false,
    }));
  }

  selectFilterMap = () => {
    const { i18n } = this.props;

    if (i18n) {
      if (i18n.language === 'lv') {
        return FilterMap;
      } if (i18n.language === 'ru') {
        return FilterMapRu;
      } if (i18n.language === 'en') {
        return FilterMapEn;
      }
    }
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  onHover = () => {
    this.setState({ popoverOpen: true });
  }

  onHoverLeave = () => {
    this.setState({ popoverOpen: null });
  }

  renderRating = (t, rating) => (
    <div className="my-info-ratings-step-wrapper">
      {rating < 10 ? (<div className="my-info-ratings-step my-info-ratings-step-weak my-info-ratings-step-active">{t('myInfo.weak')}</div>) : (null)}
      {(rating >= 10 && rating < 20) ? (<div className="my-info-ratings-step my-info-ratings-step-beginner my-info-ratings-step-active">{t('myInfo.beginner')}</div>) : (null)}
      {(rating >= 20 && rating < 30) ? (<div className="my-info-ratings-step my-info-ratings-step-untrained my-info-ratings-step-active">{t('myInfo.untrained')}</div>) : (null)}
      {(rating >= 30 && rating < 40) ? (<div className="my-info-ratings-step my-info-ratings-step-medium my-info-ratings-step-active">{t('myInfo.medium')}</div>) : (null)}
      {(rating >= 40 && rating < 50) ? (<div className="my-info-ratings-step my-info-ratings-step-good my-info-ratings-step-active">{t('myInfo.good')}</div>) : (null)}
      {(rating >= 50 && rating < 60) ? (<div className="my-info-ratings-step my-info-ratings-step-great my-info-ratings-step-active">{t('myInfo.great')}</div>) : (null)}
      {(rating >= 60 && rating < 70) ? (<div className="my-info-ratings-step my-info-ratings-step-fan my-info-ratings-step-active">{t('myInfo.fan')}</div>) : (null)}
      {rating >= 70 ? (<div className="my-info-ratings-step my-info-ratings-step-pro my-info-ratings-step-active"><Media src={proIcon} alt="X" /></div>) : (null)}
    </div>
  );

  handleOrderBy = (orderField) => {
    const { orderby, order } = this.state;
    const orderByField = orderField;
    if (orderby === orderByField) {
      if (!order) {
        this.setState({
          order: constants.ORDER_BY_TYPE.asc,
        });
      } else {
        if (order === constants.ORDER_BY_TYPE.asc) {
          this.setState({
            order: constants.ORDER_BY_TYPE.desc,
          })
        } else if (order === constants.ORDER_BY_TYPE.desc) {
          this.setState({
            order: null,
            orderby: null,
          })
        }
      }
    } else {
      this.setState({
        orderby: orderByField,
        order: constants.ORDER_BY_TYPE.asc,
      });
    }
  };

  toggleTypeFilter = () => {
    this.setState(prevState => ({
      filterOpen: false,
      typeFilterOpen: !prevState.typeFilterOpen,
    }));
  }

  toggleTabFilter = () => {
    this.setState(prevState => ({
      tabFilterOpen: !prevState.tabFilterOpen,
    }));
  }

  render() {
    const {
      landingLeaderboard,
      ignoredUsers,
      t,
      i18n,
      toggle,
    } = this.props;


    const {
      activeTab,
      filterOpen,
      typeFilterOpen,
      tabFilterOpen,
      activeType,
      scrollPos,
      millionairesOpen,
      searchValue,
      isFiltered,
      popoverOpen,
      blockUserModal,
      blockUserSelectedName,
      searchOpen,
      filterModalOpen,
      orderby,
      order,
      filters,
      showOnlyActive,
      alertStatus,
      alertType,
      message,
      showFields,
    } = this.state;

    console.log("++++++++++++++++++++++++++++: ", activeTab)

    return (
      <>
        <div className="layout-body">
          <div className="layout-body-list-header d-flex align-items-center">
            <div className="layout-body-list-header-title">
              {t('home.tenTopPlayer')}
            </div>
          </div>
          <div className="layout-body-top">
            <Row className="layout-subheader">
              <Col xs="12" className='layout-subheader-mobile'>
                <div className="layout-float-left layout-subheader-filterType">
                  <CustomDropdown
                    toggle={filter(this.selectFilterMap(), ['value', activeTab])[0].name}
                    toggleFunc={this.toggleTypeFilter}
                    isOpen={typeFilterOpen}
                    inlineMenuClassName="custom-dropdown-menu-wider"
                    menu={(
                      <>
                        {map(this.selectFilterMap(), (item) => (
                          <DropdownItem toggle={true} key={item.value} onClick={(e) => this.toggle(item.value)}>
                            {item.name}
                          </DropdownItem>
                        ))}
                      </>
                    )}
                  />
                </div>
                {/* <div xs="7" className="layout-float-left layout-offset-left layout-subheader-filterTab">
                  <SwitchSelect options={this.selectFilterMap()} selectedOption={activeTab} selectFunc={this.toggle} />
                </div> */}
              </Col>

            </Row>
          </div>
          <div className="layout-body-main">
            {/* <ScrollAreaWrapper
              className="layout-body-main-scrollarea top-table-scrollarea layout-body-main-scrollarea-body top-table-scrollarea-body"
              show
              rightOffset={0}
              topOffset={28}
              bottomOffset={0}
              scrollbarId="top-page-scrollbar"
              horizontal
              vertical={false}
            > */}
            {/* <div className="layout-body-main-scrollarea top-table-scrollarea">
              <div className="layout-body-main-scrollarea-body top-table-scrollarea-body"> */}
            <LandingTopList
              t={t}
              i18n={i18n}
              isFiltered={isFiltered}
              activeType={activeType}
              renderRating={this.renderRating}
              leaderboard={landingLeaderboard}
              order={order}
              orderby={orderby}
              showFields={showFields.showFieldArray}
              handleOrderBy={this.handleOrderBy}
            />
            {/* </div>
            </div> */}
            {/* </ScrollAreaWrapper> */}
            <div className="menu-table-row-filler-out" />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userSettings: state.userSettings[state.member.uid] || state.userSettings.default || {},
  soundOn: state.userSettings.soundOn,
  landingLeaderboard: state.leaderboard.landingLeaderboard || [],
  //  leaderboardMillionaires: state.leaderboard.leaderboardMillionaires || [],
});

const mapDispatchToProps = {
  fetchLeaderboardFiltered: getLeaderboardLandingFiltered,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(LandingTop));
