import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import { size } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import LinkifyWithTargetBlank from './UI/LinkifyWithTargetBlank';
import CustomDate from './UI/CustomDate';
import FileModal from './UI/FileModal';
import {
  FONT_MODE
} from '../../constants/constants';
import { breakLongWord } from '../../utils/stringUtils';

class SupportMessage extends React.Component {
  static propTypes = {
    //  message: PropTypes.shape(),
    message: PropTypes.string,
    fileUpload: PropTypes.string,
    fileUploadUrl: PropTypes.string,
    fileName: PropTypes.string,
    date: PropTypes.number,
    userUid: PropTypes.string,
    uid: PropTypes.string,
    filteredMessage: PropTypes.string,
    messageId: PropTypes.string,
    isFiltered: PropTypes.bool,
    fontMode: PropTypes.string,
    t: PropTypes.func,
    translationKey: PropTypes.string,
    translationKeyData: PropTypes.shape({}),
  };

  static defaultProps = {
    message: null,
    fileUpload: "",
    fileUploadUrl: "",
    fileName: "",
    date: null,
    userUid: null,
    uid: null,
    filteredMessage: null,
    messageId: null,
    isFiltered: false,
    fontMode: FONT_MODE.normal
  };

  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      downloadPopoverOpen: false,
      modalOpen: false,
      modalFile: '',
      modalFileName: '',
    };
  }

  onDownloadHover = () => {
    const { downloadPopoverOpen } = this.state;
    if (!downloadPopoverOpen) {
      this.setState({
        downloadPopoverOpen: true,
      });
    }
  }

  onDownloadHoverLeave = () => {
    this.setState({
      downloadPopoverOpen: false,
    });
  }

  onHover = (key) => {
    const { popoverOpen } = this.state;
    if (!popoverOpen) {
      this.setState({
        popoverOpen: key,
      });
    }
  }

  onHoverLeave = () => {
    this.setState({
      popoverOpen: false,
    });
  }

  openFileLink = (modalFile, modalFileName) => {
    this.setState({
      modalFile,
      modalFileName,
      modalOpen: true,
    });
    // window.open(modalFile, "_blank");
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
    }));
  }

  render() {
    const {
      messageId, message, filteredMessage, isFiltered, date, userUid, uid, fileUpload, fileUploadUrl, fileName, fontMode, t, translationKey, translationKeyData,
    } = this.props;
    const { popoverOpen, downloadPopoverOpen, modalFile, modalFileName, modalOpen } = this.state;

    if (isFiltered && filteredMessage) {
      let splitMessage = [];
      let splitFilteredMessage = [];

      splitMessage = message.split(/\n/);

      splitFilteredMessage = filteredMessage.split(/\n/);

      return (
        <Fragment>
          {uid && userUid && userUid.toString() === uid.toString() ? (
            <Row className="contact-support-chat-message-row">
              <Col md="12" className="contact-support-chat-message">
                <div className="contact-support-chat-message-user-time">
                  {date && (
                    <CustomDate format="DD/MM hh:mm" date={date} />
                  )}
                </div>
                <LinkifyWithTargetBlank>
                  <div className="contact-support-chat-message-user">
                    {/*  {splitMessage.map(row => (
                      <p style={{ marginBottom: 0, lineHeight: '20px' }}>
                        {row}
                      </p>
                    ))}  */}
                    {splitFilteredMessage.map((row, index) => {
                      let words = [];
                      if (splitMessage[index]) {
                        words = splitMessage[index].split(' ');
                      }
                      const filteredWords = row.split(' ');

                      return (
                        <div key={index}>
                          {filteredWords.map((word, index2) => (
                            <>
                              {(words && words[index2] !== word) ? (
                                <>
                                  <div
                                    style={{ display: 'inline-block', marginRight: 2 }}
                                    id={`chat-popover-${messageId}-${index2}`}
                                    onMouseEnter={() => this.onHover(`${messageId}-${index2}`)}
                                    onMouseLeave={this.onHoverLeave}
                                  >
                                    {`${word} `}
                                  </div>
                                  <Popover container={'div > div'} popperClassName={`last-round-popover popover-font-${fontMode}`} placement="top" isOpen={popoverOpen === `${messageId}-${index2}`} target={`chat-popover-${messageId}-${index2}`}>
                                    <PopoverBody className="last-round-popover-body">
                                      {/* {`${words[index2]}`} */}
                                      {`${message}`}
                                    </PopoverBody>
                                  </Popover>
                                </>
                              ) : (
                                <>
                                  {`${word} `}
                                </>
                              )}
                            </>
                          ))}
                        </div>
                      );
                    })}
                    <a
                      className="contact-support-chat-message-file" 
                      onClick={() => this.openFileLink(fileUploadUrl || '', message.fileName || '')}
                      id={`contact-support-chat-message-file-${messageId}`}
                      onMouseEnter={this.onDownloadHover}
                      onMouseLeave={this.onDownloadHoverLeave}
                    >
                      {fileName || ''}
                    </a>
                    <Popover isOpen={downloadPopoverOpen} target={`contact-support-chat-message-file-${messageId}`} placement="left">
                      <PopoverBody>
                        {t('support.view')}
                      </PopoverBody>
                    </Popover>
                  </div>
                </LinkifyWithTargetBlank>
              </Col>
            </Row>
          ) : (
            <Row className="contact-support-chat-message-row">
              <Col md="12" className="contact-support-chat-message">
                <div className="contact-support-chat-message-other-time">
                  {date && (
                    <CustomDate format="DD/MM hh:mm" date={date} />
                  )}
                </div>
                <LinkifyWithTargetBlank>
                  <div className="contact-support-chat-message-other">
                    {translationKey ? (
                      <p style={{ marginBottom: 0, lineHeight: '20px' }}>
                        {t(`support.${translationKey}`, translationKeyData)}
                      </p>
                    ) : (
                      <>
                        {splitMessage.map(row => (
                          <p style={{ marginBottom: 0, lineHeight: '20px' }}>
                            {row}
                          </p>
                        ))}
                      </>
                    )}
                    
                    <a
                      className="contact-support-chat-message-file"
                      onClick={() => this.openFileLink(fileUploadUrl || '', fileName || '')}
                      id={`contact-support-chat-message-file-${messageId}`}
                      onMouseEnter={this.onDownloadHover}
                      onMouseLeave={this.onDownloadHoverLeave}
                    >
                      {fileName || ''}
                    </a>
                    <Popover isOpen={downloadPopoverOpen} target={`contact-support-chat-message-file-${messageId}`} placement="right">
                      <PopoverBody>
                        {t('support.view')}
                      </PopoverBody>
                    </Popover>
                  </div>
                </LinkifyWithTargetBlank>
              </Col>
            </Row>
          )}
          <FileModal fileUpload={modalFile} fileName={modalFileName} toggleModal={this.toggleModal} modalOpen={modalOpen} />
        </Fragment>
      );
    }

    const splitMessage = message ? message.split(/\n/) : false;

    return (
      <Fragment>
        {uid && userUid && userUid.toString() === uid.toString() ? (
          <Row className="contact-support-chat-message-row">
            <Col md="12" className="contact-support-chat-message">
              <div className="contact-support-chat-message-user-time">
                {date && (
                  <CustomDate format="DD/MM hh:mm" date={date} />
                )}
              </div>
              <LinkifyWithTargetBlank>
                <div className="contact-support-chat-message-user">
                  {splitMessage && splitMessage.map(row => (
                    <p style={{ marginBottom: 0, lineHeight: '20px'/*, wordBreak: 'break-all'*/ }}>
                      {breakLongWord(row, 30, {color: 'rgb(255, 132, 0)', textDecoration: 'underline'})}
                    </p>
                  ))}
                  <a
                    className="contact-support-chat-message-file"
                    onClick={() => this.openFileLink(fileUploadUrl || '', fileName || '')}
                    id={`contact-support-chat-message-file-${messageId}`}
                    onMouseEnter={this.onDownloadHover}
                    onMouseLeave={this.onDownloadHoverLeave}
                  >
                    {fileName || ''}
                  </a>

                  <Popover isOpen={downloadPopoverOpen} target={`contact-support-chat-message-file-${messageId}`} placement="left">
                    <PopoverBody>
                      {t('support.view')}
                    </PopoverBody>
                  </Popover>
                </div>
              </LinkifyWithTargetBlank>
            </Col>
          </Row>
        ) : (
          <Row className="contact-support-chat-message-row">
            <Col md="12" className="contact-support-chat-message">
              <div className="contact-support-chat-message-other-time">
                {date && (
                  <CustomDate format="DD/MM hh:mm" date={date} />
                )}
              </div>
              <LinkifyWithTargetBlank>
                <div className="contact-support-chat-message-other">
                  {translationKey ? (
                    <p style={{ marginBottom: 0, lineHeight: '20px' }}>
                      {t(`support.${translationKey}`, translationKeyData)}
                    </p>
                  ) : (
                    <>
                      {splitMessage && splitMessage.map(row => (
                        <p style={{ marginBottom: 0, lineHeight: '20px'/*, wordBreak: 'break-all'*/ }}>
                          {breakLongWord(row, 30, {color: 'rgb(137, 198, 218)', textDecoration: 'underline'})}
                        </p>
                      ))}
                    </>
                  )}
                  <a
                    className="contact-support-chat-message-file"
                    onClick={() => this.openFileLink(fileUploadUrl || '', fileName || '')}
                    id={`contact-support-chat-message-file-${messageId}`}
                    onMouseEnter={this.onDownloadHover}
                    onMouseLeave={this.onDownloadHoverLeave}
                  >
                    {fileName || ''}
                  </a>
                  <Popover isOpen={downloadPopoverOpen} target={`contact-support-chat-message-file-${messageId}`} placement="right">
                    <PopoverBody>
                      {t('support.view')}
                    </PopoverBody>
                  </Popover>
                </div>
              </LinkifyWithTargetBlank>
            </Col>
          </Row>
        )}
        <FileModal fileUpload={modalFile} fileName={modalFileName} toggleModal={this.toggleModal} modalOpen={modalOpen} />
      </Fragment>
    );

  }
}

/*
SupportMessage.propTypes = {
  message: PropTypes.string,
  date: PropTypes.number,
  userUid: PropTypes.string,
  uid: PropTypes.string,
};

SupportMessage.defaultProps = {
  message: null,
  uid: null,
  userUid: null,
  date: null,
}; */

export default SupportMessage;
