import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Render4PlayerCards = React.memo(({
  currentTable, cardsAdded, myPos, sittingOut, isFullscreen, isWebVersion,
}) => (
  <div className={`played-cards ${sittingOut === myPos ? ('played-cards-sittingOut') : null}`}>
    {sittingOut === 'player1' && (
      <Fragment>
        {myPos === 'player1' && (
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
        {myPos === 'player2' && (
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
        {myPos === 'player3' && (
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
        {myPos === 'player4' && (
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
      </Fragment>
    )}

    {sittingOut === 'player2' && (
    <Fragment>
      {myPos === 'player1' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key] ? currentTable[key].card : key}>
            {(currentTable[key] && currentTable[key].player === 'player1') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player3') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player4') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
          </Fragment>
        ))}
      </Fragment>
      )}
      {myPos === 'player2' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key] ? currentTable[key].card : key}>
            {(currentTable[key] && currentTable[key].player === 'player1') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player3') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player4') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
          </Fragment>
        ))}
      </Fragment>
      )}
      {myPos === 'player3' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key] ? currentTable[key].card : key}>
            {(currentTable[key] && currentTable[key].player === 'player1') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player3') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player4') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
          </Fragment>
        ))}
      </Fragment>
      )}
      {myPos === 'player4' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key] ? currentTable[key].card : key}>
            {(currentTable[key] && currentTable[key].player === 'player1') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player3') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player4') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
          </Fragment>
        ))}
      </Fragment>
      )}
    </Fragment>
    )}


    {sittingOut === 'player3' && (
    <Fragment>
      {myPos === 'player1' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key] ? currentTable[key].card : key}>
            {(currentTable[key] && currentTable[key].player === 'player1') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player2') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player4') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
          </Fragment>
        ))}
      </Fragment>
      )}
      {myPos === 'player2' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key] ? currentTable[key].card : key}>
            {(currentTable[key] && currentTable[key].player === 'player1') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player2') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player4') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
          </Fragment>
        ))}
      </Fragment>
      )}
      {myPos === 'player3' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key] ? currentTable[key].card : key}>
            {(currentTable[key] && currentTable[key].player === 'player1') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player2') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player4') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
          </Fragment>
        ))}
      </Fragment>
      )}
      {myPos === 'player4' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key] ? currentTable[key].card : key}>
            {(currentTable[key] && currentTable[key].player === 'player1') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player2') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player4') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
          </Fragment>
        ))}
      </Fragment>
      )}
    </Fragment>
    )}


    {sittingOut === 'player4' && (
    <Fragment>
      {myPos === 'player1' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key] ? currentTable[key].card : key}>
            {(currentTable[key] && currentTable[key].player === 'player1') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player2') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player3') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
          </Fragment>
        ))}
      </Fragment>
      )}
      {myPos === 'player2' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key] ? currentTable[key].card : key}>
            {(currentTable[key] && currentTable[key].player === 'player1') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player2') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player3') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
          </Fragment>
        ))}
      </Fragment>
      )}
      {myPos === 'player3' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key] ? currentTable[key].card : key}>
            {(currentTable[key] && currentTable[key].player === 'player1') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player2') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player3') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
          </Fragment>
        ))}
      </Fragment>
      )}
      {myPos === 'player4' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key] ? currentTable[key].card : key}>
            {(currentTable[key] && currentTable[key].player === 'player1') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player2') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
            {(currentTable[key] && currentTable[key].player === 'player3') ? (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            ) : (null)}
          </Fragment>
        ))}
      </Fragment>
      )}
    </Fragment>
    )}

    {/* Change for fourPRoom update  */}
    {/*  {myPos === 'player1' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key].card}>
            {currentTable[key].player === 'player1' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player2' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player3' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player4' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
          </Fragment>
        ))}
      </Fragment>
    )}
    {myPos === 'player2' && (
    <Fragment>
      {currentTable && Object.keys(currentTable).map((key, index) => (
        <Fragment key={currentTable[key].card}>
          {currentTable[key].player === 'player1' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player2' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player3' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player4' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
        </Fragment>
      ))}
    </Fragment>
    )}
    {myPos === 'player3' && (
    <Fragment>
      {currentTable && Object.keys(currentTable).map((key, index) => (
        <Fragment key={currentTable[key].card}>
          {currentTable[key].player === 'player1' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player2' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player3' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player4' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
        </Fragment>
      ))}
    </Fragment>
    )}
    {myPos === 'player4' && (
    <Fragment>
      {currentTable && Object.keys(currentTable).map((key, index) => (
        <Fragment key={currentTable[key].card}>
          {currentTable[key].player === 'player1' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player2' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player3' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player4' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
        </Fragment>
      ))}
    </Fragment>
    )} */}
  </div>
));

Render4PlayerCards.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
  sittingOut: PropTypes.string,
};

Render4PlayerCards.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
  sittingOut: null,
};

export default Render4PlayerCards;
