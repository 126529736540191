import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useSelector, useDispatch } from 'react-redux';
import { size } from 'lodash';

import { Button, Media } from 'reactstrap';

// import blogSlider1Img from '../../../../../images/redesign/landing-view/slider2_1.png';
// import blogSlider2Img from '../../../../../images/redesign/landing-view/slider2_2.png';
// import blogSlider3Img from '../../../../../images/redesign/landing-view/slider2_3.png';
import * as constants from '../../../../../constants/constants';

import {
  getAllBlogs,
} from '../../../../../actions/member';

const HomeBlogSlide = ({ history, blogArticleFunc, toggleHomeContainer }) => {
  const { i18n, t } = useTranslation('common');

  // const blogSliderWidth = 340;
  // const blogSliderHeight = 240;
  const dispatch = useDispatch();

  const allBlogs = useSelector(state => state.member.allBlogs);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllBlogs());
  }, [size(allBlogs)]);

  const handleReadArticle = (key) => {
    if (allBlogs) {
      blogArticleFunc(true);
      toggleHomeContainer(constants.TOP_HEADER_NAVIGATION.blog);
      try {
        history.push(`/blog/${i18n.language || constants.sendLanguage.lv}/${key}`);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Splide
      options={{
        type: 'loop',
        perPage: 3,
        rewind: true,
        //  gap: '20',
        // width: (blogSliderWidth * 6) + 30,
        // height: blogSliderHeight,
        // pauseOnHover: false,
        // isNavigation: false,
        // gap: 30,
        focus: 'center',
        pagination: false,
        // cover       : true,
        breakpoints: {
          991: {
            perPage: 2,
          },
          574: {
            perPage: 1,
          },
        },
        arrows: true,
        autoplay: true,
        trimSpace: false,
      }}
    >
      {
        allBlogs && Object.keys(allBlogs).slice(0, 6).map((key, index) => (
          <SplideSlide>
            <div className="slide-img-section cursor-pointer" onClick={() => handleReadArticle(allBlogs[key].key)}>
              <Media className="slide-img" src={allBlogs[key].imageUrl} alt="" />
              <div className="splide-img-dg" />
            </div>
            <div className="splide-slide-content">
              <div className="splide-slide-content-title mb-2 cursor-pointer" onClick={() => handleReadArticle(allBlogs[key].key)}>
                {
                  i18n.language === constants.sendLanguage.en ?
                    allBlogs[key].titleEN 
                    : i18n.language === constants.sendLanguage.lv ?
                      allBlogs[key].titleLV 
                      : i18n.language === constants.sendLanguage.ru ?
                        allBlogs[key].titleRU 
                        : allBlogs[key].titleLV
                }
              </div>
              <div className="splide-slide-content-description mb-2">
                {
                  i18n.language === constants.sendLanguage.en ?
                    allBlogs[key].descriptionEN
                    : i18n.language === constants.sendLanguage.lv ?
                      allBlogs[key].descriptionLV
                      : i18n.language === constants.sendLanguage.ru ?
                        allBlogs[key].descriptionRU
                        : allBlogs[key].descriptionLV
                }
              </div>
              <div className="splide-slide-content-footer">
                <Button onClick={() => handleReadArticle(allBlogs[key].key)}>{t('home.readArticle')}</Button>
              </div>
            </div>
          </SplideSlide>
        ))
      }
    </Splide>
  );
};

HomeBlogSlide.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  blogArticleFunc: PropTypes.func.isRequired,
  toggleHomeContainer: PropTypes.func.isRequired,
};

export default HomeBlogSlide;
