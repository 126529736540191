import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import classNames from 'classnames';
import Spinner from 'reactstrap/lib/Spinner';

import { useTranslation } from 'react-i18next';
import { orderBy, reverse, get, size, filter, map, } from 'lodash';
import MenuRow from '../../../Components/Components/MenuRow';

// import ScrollAreaWrapper from '../../UI/ScrollAreaWrapper';
import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';
import TopPlayeAvatar from '../Components/TopPlayerAvatar';
import TopPageMyPos from './TopPageMyPos';
import * as constants from '../../../../../../constants/constants';

import defaultImage from '../../../../../../images/redesign/common/default_image.svg';

import blockImage from '../../../../../../images/redesign/common/block.png';
import unblockImage from '../../../../../../images/redesign/common/unblock.png';
import coinImage from '../../../../../../images/redesign/common/coin.svg';
import ratingImage from '../../../../../../images/redesign/common/rating.svg';

import lightBlockImage from '../../../../../../images/redesign/light-mode/common/block.svg';
import lightUnblockImage from '../../../../../../images/redesign/light-mode/common/unblock.svg';
import draugiemLogoIcon from '../../../../../../images/icons/draugiem-lv.svg';
import facebookLogoIcon from '../../../../../../images/icons/social-facebook.svg';
import addFriendIcon from '../../../../../../images/redesign/mobile/icons/add-friend-icon.svg';
import removeFriendIcon from '../../../../../../images/redesign/mobile/icons/blocked-players-active-dark-icon.svg';
import { trimString } from '../../../../../../utils/stringUtils';
import IconPopover from '../../../Components/Components/IconPopover';

const TopPageList = React.memo(({
  filterLevels, handleSendAddFriend, handleRemoveFriend, friends, millionairesOpen, leaderboard, isFiltered, activeType, ignoredUsers, bannedUsers, block, unblock, renderRating, order, orderby2, showFields, handleOrderBy, screenMode, leaderboardLoading, openFilter, myLeaderboard, searchValue
}) => {
  const { t } = useTranslation('common');
  const [sortLeaderboard, setSortLeaderboard] = useState([]);
  const [addRemoveFriendPopoverOpen, setAddRemoveFriendPopoverOpen] = useState(null);
  const [blockUnblockPopoverOpen, setBlockUnblockPopoverOpen] = useState(null);

  let activeTypeFilter;
  let activeTypeAction;

  if (isFiltered || millionairesOpen || size(filterLevels) > 0) {
    activeTypeFilter = constants.filterLeaderboardType.filter(item => item.id === activeType.toString())[0].label;
    activeTypeAction = constants.filterLeaderboardType.filter(item => item.id === activeType.toString())[0].action;
  }

  const addIndex = data => {
    console.log('addIndex', order, order === constants.ORDER_BY_TYPE.desc, data);
    console.log('addIndex isFiltered', isFiltered);

    return data.map((row, index) => ({ id: (isFiltered && row.searchPos) ? (row.searchPos) : ((order && row.userCount && order === constants.ORDER_BY_TYPE.desc) ? (row.userCount - index) : index + 1), ...row }))
  };

  useEffect(() => {
    if (filterLevels && filterLevels.length > 0) {
      leaderboard = filter(leaderboard, (obj) => {
          return filterLevels.some(item => {
              return obj.lvl &&
                  (constants.PLAYER_LEVEL_RANGE[item].from ? obj.lvl >= constants.PLAYER_LEVEL_RANGE[item].from : true) &&
                  (constants.PLAYER_LEVEL_RANGE[item].to ? obj.lvl < constants.PLAYER_LEVEL_RANGE[item].to : true);
          });
      });
    } 
    setSortLeaderboard(addIndex(leaderboard));
  }, [leaderboard]);

  useEffect(() => {
    if (order && orderby2) {
      if (orderby2 === constants.TOP_LEADERBOARD_ORDER.place) {

        if (order === constants.ORDER_BY_TYPE.asc) {
          const leaderboardWithId = addIndex(leaderboard);
          setSortLeaderboard(leaderboardWithId);
        } else if (order === constants.ORDER_BY_TYPE.desc) {
          if (isFiltered) {
            const leaderboardWithId = addIndex(leaderboard.reverse());
          //  setSortLeaderboard(reverse([...leaderboardWithId]));
            setSortLeaderboard(leaderboardWithId);
          } else {
            const leaderboardWithId = addIndex(leaderboard.reverse());
            setSortLeaderboard(leaderboardWithId);

          //  const leaderboardWithId = addIndex(leaderboard);
          //  setSortLeaderboard(reverse([...leaderboardWithId]));
          }
        }
      } else {
        if (orderby2 === constants.TOP_LEADERBOARD_ORDER.player) {
          const leaderboardWithId = addIndex(leaderboard);

          if (order === constants.ORDER_BY_TYPE.asc) {
            setSortLeaderboard(leaderboardWithId.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })));
          } else {
            setSortLeaderboard(leaderboardWithId.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })).reverse());
          }
        } else if (((orderby2 === 'place' || orderby2 === 'points') && activeType.toString() === '1')
          || ((orderby2 === 'place' || orderby2 === 'balance') && activeType.toString() === '2')
          || ((orderby2 === 'place' || orderby2 === 'points') && activeType.toString() === '3')) {

          if (order === constants.ORDER_BY_TYPE.asc) {
            console.log('test 3', isFiltered);
            const leaderboardWithId = addIndex(leaderboard);


            setSortLeaderboard(orderBy(leaderboardWithId, [orderby2.toString()], [order.toString()]).reverse());
          } else if (order === constants.ORDER_BY_TYPE.desc) {
            if (isFiltered) {
              const leaderboardWithId = addIndex(leaderboard);

              setSortLeaderboard(orderBy(leaderboardWithId, [orderby2.toString()], [order.toString()]).reverse());

            } else {
              const leaderboardWithId = addIndex(leaderboard.reverse());

              setSortLeaderboard(orderBy(leaderboardWithId, [orderby2.toString()], [order.toString()]).reverse());
            }
          }
        } else {

          const leaderboardWithId = addIndex(leaderboard);


        /*  if (order === constants.ORDER_BY_TYPE.asc) {
            console.log('test 3');
            const leaderboardWithId = addIndex(leaderboard);
          //  setSortLeaderboard(leaderboardWithId);

            console.log('leaderboardWithId', leaderboardWithId, orderby2, order, activeTypeFilter);

          //  if (activeTypeFilter === )
            setSortLeaderboard(orderBy(leaderboardWithId, [orderby2.toString()], [order.toString()]));
          } else if (order === constants.ORDER_BY_TYPE.desc) {
            if (isFiltered) {
              console.log('test 4');
              const leaderboardWithId = addIndex(leaderboard);
            //  setSortLeaderboard(reverse([...leaderboardWithId]));

              console.log('leaderboardWithId', leaderboardWithId);

              setSortLeaderboard(orderBy(reverse([...leaderboardWithId]), [orderby2.toString()], [order.toString()]));


             // setSortLeaderboard(leaderboardWithId);
            } else {
              console.log('test 5');
              const leaderboardWithId = addIndex(leaderboard.reverse());

              console.log('leaderboardWithId', leaderboardWithId);

              setSortLeaderboard(orderBy(leaderboardWithId, [orderby2.toString()], [order.toString()]));
            //  setSortLeaderboard(leaderboardWithId);


            //  const leaderboardWithId = addIndex(leaderboard);
            //  setSortLeaderboard(reverse([...leaderboardWithId]));
            }
          }  */

          setSortLeaderboard(orderBy(leaderboardWithId, [orderby2.toString()], [order.toString()]));
        }
      }
      // }
    }
  }, [order, orderby2]);


  const selectOptions = [];
  map(constants.TOP_LEADERBOARD_ORDER, (item) => {
    const STYLE_TOP_TABLE = classNames(
      'layout-body-main-table-header-orderby',
      {
        'layout-body-main-table-header-orderby_asc_active': item === orderby2 && order === constants.ORDER_BY_TYPE.asc,
        'layout-body-main-table-header-orderby_desc_active': item === orderby2 && order !== constants.ORDER_BY_TYPE.asc,
      }
    );
    selectOptions[item] = {
      fieldStyle: STYLE_TOP_TABLE,
    };
  });

  return (
    <div className='top-page-main-body-wrapper'>
      {
        leaderboardLoading ? (
          <div className="d-flex align-items-center spinner-loading-section">
            <Spinner color={screenMode === constants.SCREEN_MODE.light ? constants.SCREEN_MODE.dark : constants.SCREEN_MODE.light} style={{ width: '5rem', height: '5rem' }} />
          </div>
        ) : (
          <>
            <div className="top-table-scrollarea-body-header">
              <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.place].fieldStyle} layout-body-main-table-header-place`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.place)}>
                {t('common.position')}
              </div>
              {/* <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.player].fieldStyle} layout-body-main-table-header-player`}>
                {t('common.ratings')}
              </div> */}
              <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.level].fieldStyle} layout-body-main-table-header-rating`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.ratings)}>
                {t('common.ratings')}
              </div>
              <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.ratings].fieldStyle} layout-body-main-table-header-balance`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.balance)}>
                {t('common.balance')}
              </div>
              <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.balance].fieldStyle} layout-body-main-table-header-points`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.points)}>
                {t('common.points')}
              </div>
              {/* <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.points].fieldStyle} layout-body-main-table-header-points`}>
                {t('common.points')}
              </div> */}
              <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.rounds].fieldStyle} layout-body-main-table-header-rounds`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.rounds)}>
                {t('common.parties')}
              </div>
              {/* <div>
                {t('ignoredUsers.block')}
              </div> */}
              {
                showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.gWon) !== -1 && (
                  <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.gWon].fieldStyle} layout-body-main-table-header-gwon`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.gWon)}>
                    {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.gWon])[0].tLabel}`)}
                  </div>
                )
              }
              {
                showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) !== -1 && (
                  <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.bgWon].fieldStyle} layout-body-main-table-header-bgwon`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.bgWon)}>
                    {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.bgWon])[0].tLabel}`)}
                  </div>
                )
              }
              {
                showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.sgWon) !== -1 && (
                  <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.sgWon].fieldStyle} layout-body-main-table-header-sgwon`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.sgWon)}>
                    {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.sgWon])[0].tLabel}`)}
                  </div>
                )
              }
              {
                showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.tblsWon) !== -1 && (
                  <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.tblsWon].fieldStyle} layout-body-main-table-header-tblswon`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.tblsWon)}>
                    {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.tblsWon])[0].tLabel}`)}
                  </div>
                )
              }
              {
                showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.achvCnt) !== -1 && (
                  <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.achvCnt].fieldStyle} layout-body-main-table-header-achvcnt`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.achvCnt)}>
                    {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.achvCnt])[0].tLabel}`)}
                  </div>
                )
              }
              {
                showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) !== -1 && (
                  <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.maxRndInGame].fieldStyle} layout-body-main-table-header-maxrndingame`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.maxRndInGame)}>
                    {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.maxRndInGame])[0].tLabel}`)}
                  </div>
                )
              }
              <div className="layout-body-main-table-header-block" />
            </div>

            <TopPageMyPos screenMode={screenMode} openFilter={openFilter} myLeaderboard={myLeaderboard} renderRating={renderRating} showFields={showFields} />
            {/* <ScrollAreaWrapper
              className="layout-mobile-body-main-scrollarea top-table-custom-scroll-body"
              contentClassName="top-scrollarea-body"
              show
              scrollbarId="top-page-scrollbar"
              rightOffset={0}
              topOffset={0}
              bottomOffset={0}
              vertical
            > */}
            <div
              className="layout-mobile-body-main-scrollarea top-table-custom-scroll-body"
              contentClassName="layout-body-main-scrollarea-body top-scrollarea-body"
              show
              rightOffset={0}
              topOffset={30}
              bottomOffset={0}
            >
              <div className="top-iframe-joyride-players-target" />

              {
                sortLeaderboard && sortLeaderboard.map((pos, index) => (
                  (index <= constants.TOP_LIST_LIMIT.limit && (!isFiltered || (isFiltered && activeTypeFilter && pos[activeTypeAction]))) && (
                    (pos.key && bannedUsers && !bannedUsers[pos.key]) && (
                      <Fragment key={pos.key}>
                        <MenuRow key={pos.key} nobackground contentClassName="top-table-mobile-menu-row-content menu-row-top-table">
                          <div key={pos.key} className="top-table-row">
                            <div className="top-table-row-text-place">
                              {(isFiltered || millionairesOpen || size(filterLevels) > 0) ? pos[activeTypeAction] : pos.id}
                              <div className="top-table-row-player-image-frame network-logo">
                                <TopPlayeAvatar photo={pos.photo} playerInfo={pos} screenMode={screenMode} />
                                {
                                  size(friends) > 0 && size(filter(friends, (item) => { return item.uid === pos.key; })) > 0 && (
                                    <Media
                                      src={filter(friends, (item) => { return item.uid === pos.key; })[0]?.socProvider === 'draugiem' ? draugiemLogoIcon : filter(friends, (item) => { return item.uid === pos.key; })[0]?.socProvider === 'facebook' ? facebookLogoIcon : ''}
                                      alt=""
                                      className="network-logo-top-mobile-icon"
                                    />
                                  )
                                }
                              </div>
                            </div>
                            <div className="top-table-row-text-player">
                              <div className="top-table-row-text">
                                {pos.name}
                                {/* {trimString(pos.name, 16)} */}
                              </div>
                              <div className="top-table-row-text" style={{marginLeft: 5}}>
                                {renderRating(t, pos.lvl || 1)}
                              </div>
                            </div>
                            <div className="top-table-row-text-rating">
                              <div className="menu-table-row-player-icon-wrapper">
                                <Media src={ratingImage} className="menu-table-row-player-icon" />
                                <div className="menu-table-row-player-icon-text">
                                  {pos ? (pos.rating || 1600) : '1600'}
                                </div>
                              </div>
                            </div>
                            <div className="top-table-row-text-balance">
                              <div className="menu-table-row-player-icon-wrapper">
                                <Media src={coinImage} className="menu-table-row-player-icon" />
                                <div className="menu-table-row-player-icon-text">
                                  {pos ? (pos.balance || 0) : '0'}
                                </div>
                              </div>
                            </div>
                            <div className="top-table-row-text-points">
                              {pos.points || 0}
                            </div>
                            <div className="top-table-row-text-rounds">
                              {pos.gamesPlayed || 0}
                            </div>
                            {
                              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.gWon) !== -1 && (
                                <div className="top-table-row-text-gwon">
                                  {pos.gWon || 0}
                                </div>
                              )
                            }
                            {
                              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) !== -1 && (
                                <div className="top-table-row-text-bgwon">
                                  {pos.bgWon || 0}
                                </div>
                              )
                            }
                            {
                              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.sgWon) !== -1 && (
                                <div className="top-table-row-text-sgwon">
                                  {pos.sgWon || 0}
                                </div>
                              )
                            }
                            {
                              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.tblsWon) !== -1 && (
                                <div className="top-table-row-text-tblswon">
                                  {pos.tblsWon || 0}
                                </div>
                              )
                            }
                            {
                              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.achvCnt) !== -1 && (
                                <div className="top-table-row-text-achvcnt">
                                  {pos.achvCnt || 0}
                                </div>
                              )
                            }
                            {
                              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) !== -1 && (
                                <div className="top-table-row-text-maxrndingame">
                                  {pos.maxRndInGame || 0}
                                </div>
                              )
                            }
                            <div className="top-table-row-text-mobile-friend d-flex align-items-center">
                              {
                                size(filter(friends, item => item.uid === pos.key)) > 0 ? (
                                  <div className='top-table-row-player-mobile-friend top-table-row-text d-flex align-items-center'
                                    style={{
                                      background: 'linear-gradient(180deg, #333333 0%, #121212 100%)',
                                      width: '3.4vmin',
                                      height: '3.4vmin',
                                      minWidth: '38px',
                                      minHeight: '38px',
                                      borderRadius: '50%',
                                      marginRight: '-10px',
                                      marginTop: '7px',
                                    }}
                                  >
                                    <Media
                                      className='top-table-row-text top-table-row-player-mobile-friend'
                                      src={removeFriendIcon}
                                      onClick={() => handleRemoveFriend(pos.key)}
                                      style={{
                                        width: '3.4vmin',
                                        height: '3.4vmin',
                                        minWidth: '38px',
                                        minHeight: '38px',
                                        objectFit: 'contain',
                                        marginTop: '0px',
                                        marginRight: '0px',
                                      }}
                                      onMouseEnter={() => setAddRemoveFriendPopoverOpen(pos.key)}
                                      onMouseLeave={() => setAddRemoveFriendPopoverOpen(null)}
                                      id={`remove-friend-mobile-${pos.key}`}
                                    />
                                    <IconPopover text={t('top.removeFriend')} popoverOpen={!!addRemoveFriendPopoverOpen && addRemoveFriendPopoverOpen === pos.key} targetId={`remove-friend-mobile-${pos.key}`} placement="top" />
                                  </div>
                                ) : (
                                  <>
                                    <Media
                                      className={`top-table-row-text ${size(filter(friends, item => item.uid === pos.key)) > 0 && 'top-table-row-player-mobile-friend-disabled'} top-table-row-player-mobile-friend`}
                                      src={addFriendIcon}
                                      onClick={() => handleSendAddFriend(pos.key)}
                                      onMouseEnter={() => setAddRemoveFriendPopoverOpen(pos.key)}
                                      onMouseLeave={() => setAddRemoveFriendPopoverOpen(null)}
                                      id={`add-friend-mobile-${pos.key}`}
                                    />
                                    <IconPopover text={t('top.addAsFriend')} popoverOpen={!!addRemoveFriendPopoverOpen && addRemoveFriendPopoverOpen === pos.key} targetId={`add-friend-mobile-${pos.key}`} placement="top" />
                                  </>

                                )
                              }
                            </div>
                            <div className="top-table-row-text-block">
                              {(pos[activeTypeAction] !== myLeaderboard?.position) && (
                                pos.key && ignoredUsers && ignoredUsers[pos.key] ? (
                                  <>
                                    <Media
                                      className={`top-table-row-player-unblock-${screenMode} top-table-row-text`}
                                      src={screenMode !== constants.SCREEN_MODE.light ? unblockImage : lightUnblockImage}
                                      onClick={() => unblock(pos.key)}
                                      onMouseEnter={() => setBlockUnblockPopoverOpen(pos.key)}
                                      onMouseLeave={() => setBlockUnblockPopoverOpen(null)}
                                      id={`unblock-mobile-${pos.key}`}
                                    />
                                    <IconPopover text={t('ignoredUsers.unBlock')} popoverOpen={!!blockUnblockPopoverOpen && blockUnblockPopoverOpen === pos.key} targetId={`unblock-mobile-${pos.key}`} placement="top" />
                                  </>
                                ) : (
                                  <>
                                    <Media
                                      className={`top-table-row-player-block-${screenMode} top-table-row-text`}
                                      src={screenMode !== constants.SCREEN_MODE.light ? blockImage : lightBlockImage}
                                      onClick={() => block(pos.key, pos.name)}
                                      onMouseEnter={() => setBlockUnblockPopoverOpen(pos.key)}
                                      onMouseLeave={() => setBlockUnblockPopoverOpen(null)}
                                      id={`block-mobile-${pos.key}`}
                                    />
                                    <IconPopover text={t('ignoredUsers.block')} popoverOpen={!!blockUnblockPopoverOpen && blockUnblockPopoverOpen === pos.key} targetId={`block-mobile-${pos.key}`} placement="top" />
                                  </>
                                )
                              )}
                            </div>
                          </div>
                        </MenuRow>
                      </Fragment>
                    )
                  )
                ))}
            </div>
          </>
        )
      }

    </div>
  );
});

TopPageList.propTypes = {
  leaderboard: PropTypes.shape(),
  // t: PropTypes.func.isRequired,
  renderRating: PropTypes.func.isRequired,
  isFiltered: PropTypes.bool,
  activeType: PropTypes.string,
  ignoredUsers: PropTypes.shape(),
  bannedUsers: PropTypes.shape(),
  block: PropTypes.func.isRequired,
  unblock: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderby: PropTypes.string,
  showFields: PropTypes.arrayOf(PropTypes.string.isRequired),
  handleOrderBy: PropTypes.func.isRequired,
};

TopPageList.defaultProps = {
  leaderboard: [],
  isFiltered: false,
  activeType: null,
  ignoredUsers: null,
  bannedUsers: null,
  order: null,
  orderby: null,
  //  type: null,
  showFields: [],
};

export default TopPageList;
