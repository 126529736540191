import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

// import Title from './Title';
import Toggle from './Toggle';

import * as constants from '../../../../../constants/constants';

const ToggleField = ({ handleDarkTheme, handleLightTheme, handleNormalTheme, darkTheme }) => {
  const { t } = useTranslation('common');

console.log({darkTheme}, "toggle");
  return (
    <Row className="toggle-field display-inline d-flex align-items">
      <Col sm="4" className="filter-body-section-row-label no-padding">
        <Toggle checked={darkTheme === constants.SCREEN_MODE.dark} onChange={handleDarkTheme} text={t('home.darkTheme')} toggleTheme />
      </Col>
      <Col sm="4" className="filter-body-section-row-label no-padding">
        <Toggle checked={darkTheme === constants.SCREEN_MODE.light} onChange={handleLightTheme} text={t('home.brightTheme')} toggleTheme />
      </Col>
      <Col sm="4" className="filter-body-section-row-label no-padding">
        <Toggle checked={darkTheme === constants.SCREEN_MODE.normal} onChange={handleNormalTheme} text={t('home.normalTheme')} toggleTheme />
      </Col>
    </Row>
  );
};

ToggleField.propTypes = {
  handleDarkTheme: PropTypes.func.isRequired,
  darkTheme: PropTypes.bool,
};

ToggleField.defaultProps = {
  darkTheme: false,
};

export default ToggleField;
