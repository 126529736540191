import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';

import closeImg from '../../../../images/icons/close.png';

import {
  debugSetParam,
} from '../../../../actions/game';
import { size, map } from 'lodash';

import {
  disableTimer,
  toggleAutomation,
  setNextDealCards,
  setUserBal,
} from '../../../../actions/admin';
import { FONT_MODE, HOLIDAY_TYPES_INGAME } from '../../../../constants/constants';
const TESTING_ALL_CARDS = ['♣︎-Q', '♠︎-Q', '♥-Q', '♦︎-Q', '♣︎-J', '♠︎-J', '♥-J', '♦︎-J', '♦︎-A', '♦︎-10', '♦︎-K', '♦︎-9', '♦︎-8', '♦︎-7', '♥-A', '♥-10', '♥-K', '♥-9', '♠︎-A', '♠︎-10', '♠︎-K', '♠︎-9', '♣︎-A', '♣︎-10', '♣︎-K', '♣︎-9'];

class TestingModal extends Component {
  static propTypes = {
  //  lastRoundModal: PropTypes.bool,
  //  closeLastRound: PropTypes.func.isRequired,
  //  lastRoundFunction: PropTypes.func.isRequired,
  //  testingModal: PropTypes.bool,
    role: PropTypes.string,
    //  toggleTestModal: PropTypes.func.isRequired,
    //  adminError: PropTypes.string,
    disableTime: PropTypes.func.isRequired,
    timerDisabled: PropTypes.bool,
    p1Name: PropTypes.string,
    p2Name: PropTypes.string,
    p3Name: PropTypes.string,
    roomId: PropTypes.string,
    setNextDeal: PropTypes.func.isRequired,
    //  handleChange: PropTypes.func.isRequired,
    setUserBalance: PropTypes.func.isRequired,
    disableAutomation: PropTypes.func.isRequired,
    debugParam: PropTypes.func.isRequired,
    uid: PropTypes.string,
    tournamentId: PropTypes.string,
    myPos: PropTypes.string,
    roomAutomated: PropTypes.bool,
    fontMode: PropTypes.string,

    toggleTestModal: PropTypes.func,
    testingModal: PropTypes.bool,
    adminError: PropTypes.string,
    selectedTestHoliday: PropTypes.array,
    handleSelectTestHoliday: PropTypes.func,
    toggleTestHoliday: PropTypes.func,
  }

  static defaultProps = {
  //  lastRoundModal: false,
    timerDisabled: false,
    role: null,
    //  adminError: '',
    p1Name: null,
    p2Name: null,
    p3Name: null,
    roomId: null,
    uid: null,
    tournamentId: null,
    myPos: null,
    roomAutomated: false,
    fontMode: FONT_MODE.normal
  };

  constructor(props) {
    super(props);
    this.state = {
      adminError: '',
      testingModal: false,
    };
  }


  handleChange = (event) => {
    const value = event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  };

  setNextDealCards = () => {
    const { roomId, setNextDeal, sittingOut } = this.props;
    const { nextDealCards1, nextDealCards2, nextDealCards3, nextDealCards4, nextDealTableCards } = this.state;
    const strippedCards1 = size(nextDealCards1) > 0 ? nextDealCards1.replace(' ', '') : [];
    const strippedCards2 = size(nextDealCards2) > 0 ? nextDealCards2.replace(' ', '') : [];
    const strippedCards3 = size(nextDealCards3) > 0 ? nextDealCards3.replace(' ', '') : [];
    const strippedCards4 = size(nextDealCards4) > 0 ? nextDealCards4.replace(' ', '') : [];
    const strippedDealTableCards = size(nextDealTableCards) > 0 ? nextDealTableCards.replace(' ', '') : [];

    const cardsSplit1 = size(strippedCards1) > 0 ? strippedCards1.split(',') : [];
    const cardsSplit2 = size(strippedCards2) > 0 ? strippedCards2.split(',') : [];
    const cardsSplit3 = size(strippedCards3) > 0 ? strippedCards3.split(',') : [];
    const cardsSplit4 = size(strippedCards4) > 0 ? strippedCards4.split(',') : [];
    const tableCardsSplit = size(strippedDealTableCards) > 0 ? strippedDealTableCards.split(',') : [];

    let player1Cards = [];
    let player2Cards = [];
    let player3Cards = [];
    let player4Cards = [];
    let tableCards = [];
    if (size(cardsSplit1) > 0) {
      map(cardsSplit1, item => {
        player1Cards.push(TESTING_ALL_CARDS[item]);
      })
    }
    if (size(cardsSplit2) > 0) {
      map(cardsSplit2, item => {
        player2Cards.push(TESTING_ALL_CARDS[item]);
      })
    }
    if (size(cardsSplit3) > 0) {
      map(cardsSplit3, item => {
        player3Cards.push(TESTING_ALL_CARDS[item]);
      })
    }
    if (size(cardsSplit4) > 0) {
      map(cardsSplit4, item => {
        player4Cards.push(TESTING_ALL_CARDS[item]);
      })
    }
    if (size(tableCardsSplit) > 0) {
      map(tableCardsSplit, item => {
        tableCards.push(TESTING_ALL_CARDS[item]);
      })
    }
    let playerCards = {
      player1: size(player1Cards) > 0 ? player1Cards : null,
      player2: size(player2Cards) > 0 ? player2Cards : null,
      player3: size(player3Cards) > 0 ? player3Cards : null,
      tableCards: size(tableCards) > 0 ? tableCards : null,
    }

    if (sittingOut) {
      playerCards = {
        player1: size(player1Cards) > 0 ? player1Cards : null,
        player2: size(player2Cards) > 0 ? player2Cards : null,
        player3: size(player3Cards) > 0 ? player3Cards : null,
        player4: size(player4Cards) > 0 ? player4Cards : null,
        tableCards: size(tableCards) > 0 ? tableCards : null,
      }
    }

    console.log(playerCards, "checkCheckCheck")
    setNextDeal(roomId, playerCards);
    this.setState({ adminError: '' });
  };

  setUserBal = () => {
    const {
      setUserBalance,
      uid,
      roomId,
      //  setUserTournamentBal,
      tournamentId,
      myPos,
    } = this.props;

    const { newBal } = this.state;

    if (myPos && uid && newBal && roomId) {
      if (tournamentId) {
      //  setUserTournamentBal(roomId, tournamentId, uid, myPos, newBal);
      } else {
        setUserBalance(roomId, uid, myPos, newBal);
      }
    }
  };

  // const TestingModal = React.memo(({ testingModal, role, toggleTestModal, adminError, disableTimer, timerDisabled, roomId, p1Name, p2Name, p3Name, handleChange, setNextDealCards, setUserBal, debugParam }) => {
  render() {
    const {
      role, disableTime, timerDisabled, roomId, p1Name, p2Name, p3Name, p4Name, debugParam, roomAutomated, disableAutomation, fontMode, sittingOut,
      adminError, testingModal, toggleTestModal, selectedTestHoliday, handleSelectTestHoliday, toggleTestHoliday,
    } = this.props;

    console.log(testingModal, "testingmodal", sittingOut);

    if (role === 'admin' || role === 'tester') {
      return (
        <Fragment>
          <Modal
            container={'div > div'}
            size="lg"
            isOpen={testingModal}
            toggle={toggleTestModal}
            className={`test-panel old-modal root-font-${fontMode}`}
          >
            <ModalHeader
              className="test-panel-header notification-header"
              close={(
                <Media
                  src={closeImg}
                  className="notification-header-close"
                  alt="X"
                  onClick={toggleTestModal}
                />
            )}
            />
            <ModalBody className="test-panel-body">
              <div style={{ color: 'red', fontSize: 14, marginBottom: 3 }}>
                {adminError}
              </div>
              <div className="test-panel-half-section">
                <Button
                  className="test-panel-button test-panel-button-top"
                  onClick={() => disableTime(roomId)}
                >
                  {timerDisabled
                    ? 'Ieslēgt taimeri'
                    : 'Izslēgt taimeri'}
                </Button>
              </div>

              <div className="test-panel-half-section">
                <Button
                  className="test-panel-button test-panel-button-top"
                  onClick={() => disableAutomation(roomId)}
                >
                  {roomAutomated
                    ? 'Izslēgt automāciju'
                    : 'Ieslēgt automāciju'}
                </Button>
              </div>

              <div style={{ fontSize: 12, marginBottom: 3 }}>
                Kārtis ir secībā
                <table className="test-panel-cards-table">
                  <thead>
                    <tr>
                      <td> 0 </td>
                      <td> 1 </td>
                      <td> 2 </td>
                      <td> 3 </td>
                      <td> 4 </td>
                      <td> 5 </td>
                      <td> 6 </td>
                      <td> 7 </td>
                      <td> 8 </td>
                      <td> 9 </td>
                      <td> 10 </td>
                      <td> 11 </td>
                      <td> 12 </td>
                      <td> 13 </td>
                      <td> 14 </td>
                      <td> 15 </td>
                      <td> 16 </td>
                      <td> 17 </td>
                      <td> 18 </td>
                      <td> 19 </td>
                      <td> 20 </td>
                      <td> 21 </td>
                      <td> 22 </td>
                      <td> 23 </td>
                      <td> 24 </td>
                      <td> 25 </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> ♣︎-Q </td>
                      <td> ♠︎-Q </td>
                      <td> ♥-Q </td>
                      <td> ♦︎-Q </td>
                      <td> ♣︎-J </td>
                      <td> ♠︎-J </td>
                      <td> ♥-J </td>
                      <td> ♦︎-J </td>
                      <td> ♦︎-A </td>
                      <td> ♦︎-10 </td>
                      <td> ♦︎-K </td>
                      <td> ♦︎-9 </td>
                      <td> ♦︎-8 </td>
                      <td> ♦︎-7 </td>
                      <td> ♥-A </td>
                      <td> ♥-10 </td>
                      <td> ♥-K </td>
                      <td> ♥-9 </td>
                      <td> ♠︎-A </td>
                      <td> ♠︎-10 </td>
                      <td> ♠︎-K </td>
                      <td> ♠︎-9 </td>
                      <td> ♣︎-A </td>
                      <td> ♣︎-10 </td>
                      <td> ♣︎-K </td>
                      <td> ♣︎-9 </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  fontSize: 12,
                  marginBottom: 5,
                  marginTop: 3,
                  textTransform: 'none',
                }}
              >
                Katram spēlētajam jāpiešķir 8 kārtis, kāršu nummurus jāatdala
                ar komatu
              </div>
              <div className="test-panel-third-section">
                <div style={{ fontSize: 12 }}>{p1Name}</div>
                <Input
                  type="text"
                  name="nextDealCards1"
                  onChange={this.handleChange}
                  className="test-panel-input"
                  placeholder={(sittingOut && sittingOut === "player4") ? 'Next dealer' : ''}
                  disabled={(sittingOut && sittingOut === "player4") ? true : false}
                />
              </div>
              <div className="test-panel-third-section">
                <div style={{ fontSize: 12 }}>{p2Name}</div>
                <Input
                  type="text"
                  name="nextDealCards2"
                  onChange={this.handleChange}
                  className="test-panel-input"
                  placeholder={(sittingOut && sittingOut === "player1") ? 'Next dealer' : ''}
                  disabled={(sittingOut && sittingOut === "player1") ? true : false}
                />
              </div>
              <div className="test-panel-third-section">
                <div style={{ fontSize: 12 }}>{p3Name}</div>
                <Input
                  type="text"
                  name="nextDealCards3"
                  onChange={this.handleChange}
                  className="test-panel-input"
                  placeholder={(sittingOut && sittingOut === "player2") ? 'Next dealer' : ''}
                  disabled={(sittingOut && sittingOut === "player2") ? true : false}
                />
              </div>
              {sittingOut ? (
                <div className="test-panel-third-section">
                  <div style={{ fontSize: 12 }}>{p4Name}</div>
                  <Input
                    type="text"
                    name="nextDealCards4"
                    onChange={this.handleChange}
                    className="test-panel-input"
                    placeholder={(sittingOut && sittingOut === "player3") ? 'Next dealer' : ''}
                    disabled={(sittingOut && sittingOut === "player3") ? true : false}
                  />
                </div>
              ):(null)}
              <div className="test-panel-third-section">
                  <div style={{ fontSize: 12 }}>Table Cards</div>
                  <Input
                    type="text"
                    name="nextDealTableCards"
                    className="test-panel-input"
                    onChange={this.handleChange}
                  />
                </div>
              <Button
                className="test-panel-button"
                onClick={this.setNextDealCards}
              >
                Likt nākošā dalījuma kārtis
              </Button>

              <Input
                type="number"
                name="newBal"
                onChange={this.handleChange}
                className="test-panel-input"
              />
              <Button className="test-panel-button" onClick={this.setUserBal}>
                Mainīt bilanci
              </Button>

              <div className="test-panel-half-section">
                <div style={{ fontSize: 12 }}>Id</div>
                <Input
                  type="text"
                  name="debugId"
                  onChange={this.handleChange}
                  className="test-panel-input"
                />
              </div>
              <div className="test-panel-half-section">
                <div style={{ fontSize: 12 }}>Vērtība</div>
                <Input
                  type="text"
                  name="debugValue"
                  onChange={this.handleChange}
                  className="test-panel-input"
                />
              </div>
              <Button
                className="test-panel-button"
                onClick={debugParam}
              >
                Likt debug parametru
              </Button>
              <div style={{ fontSize: 12 }}>Holiday Type</div>
              <div className="test-panel-half-section align-top">
                <Input type="select" name="selectedTestHoliday" className="test-panel-input py-0" value={selectedTestHoliday.id} disabled={/*loading*/false} onChange={handleSelectTestHoliday}>
                  {
                    map(HOLIDAY_TYPES_INGAME, item => (
                      <option value={item.id} className="background-222">{item.label}</option>
                    ))
                  }
                </Input>
              </div>
              <div className="test-panel-half-section pl-1 align-top">
                <Button
                  className="test-panel-button float-left my-0"
                  onClick={toggleTestHoliday}
                >
                  Toggle
                </Button>
              </div>


            </ModalBody>
            <ModalFooter className="notification-footer">
              <Button
                type="button"
                className="btn test-panel-button"
                onClick={toggleTestModal}
              >
                Aizvērt
              </Button>
            </ModalFooter>
          </Modal>
        </Fragment>
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  uid: state.member.uid,
  role: state.member.role,
  timerDisabled: state.game.globalParams.disableTimer,
  roomAutomated: state.game.globalParams.automated,
  tournamentId: state.game.globalParams.tournamentId,
  sittingOut: state.game.sittingOut,
  myPos: state.game.myPos,
  p1Name: (state.game.players && state.game.players.player1) ? state.game.players.player1.name : '',
  p2Name: (state.game.players && state.game.players.player2) ? state.game.players.player2.name : '',
  p3Name: (state.game.players && state.game.players.player3) ? state.game.players.player3.name : '',
  p4Name: (state.game.players && state.game.players.player4) ? state.game.players.player4.name : '',
});

const mapDispatchToProps = {
  debugParam: debugSetParam,
  setNextDeal: setNextDealCards,
  disableTime: disableTimer,
  disableAutomation: toggleAutomation,
  setUserBalance: setUserBal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestingModal);


/*
TestingModal.propTypes = {
  lastRoundModal: PropTypes.bool,
  closeLastRound: PropTypes.func.isRequired,
  lastRoundFunction: PropTypes.func.isRequired,
  testingModal: PropTypes.bool,
  role: PropTypes.string,
  toggleTestModal: PropTypes.func.isRequired,
  adminError: PropTypes.string,
  disableTimer: PropTypes.func.isRequired,
  timerDisabled: PropTypes.bool,
  p1Name: PropTypes.string,
  p2Name: PropTypes.string,
  p3Name: PropTypes.string,
  roomId: PropTypes.string,
  setNextDealCards: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setUserBal: PropTypes.func.isRequired,
};

TestingModal.defaultProps = {
  lastRoundModal: false,
  timerDisabled: false,
  role: '',
  adminError: '',
  p1Name: '',
  p2Name: '',
  p3Name: '',
  roomId: '',
};  */
