import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { filter, size, isEqual } from 'lodash';

import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
// import Media from 'reactstrap/lib/Media';
import Form from 'reactstrap/lib/Form';
// import FormGroup from 'reactstrap/lib/FormGroup';
import ScrollArea from 'react-scrollbar';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

// import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { connect } from 'react-redux';

import Moment from 'react-moment';
import moment from 'moment';

import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

import DateFormat from '../UI/DateFormat';
import Message from './AdminSupportMessage';

// import pencilImg from '../../../images/icons/pencil.svg';
// import checkImg from '../../../images/icons/check.png';
// import cancelImg from '../../../images/icons/close_old.png';
// import closeImg from '../../../images/icons/close.png';

import * as constants from '../../../constants/constants';

import {
  getAllReportedMessages,
  checkAdminReportMessages,
  getUserMessages,
  cancelUserMessages,
  answerSupportMessage,
  supportMessageFileUpload,
  getWarningPlayers,
  addWarningMark,
  getProfanitiesList,
} from '../../../actions/admin';
// import { errorMessages } from '../../../constants/messages';
// import { e } from 'mathjs';
import CountDownDate from '../UI/CountDownDate';
import { UncontrolledPopover } from 'reactstrap';

moment.locale('lv');
momentLocalizer();

class ReportedMessages extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    allReportedMessages: PropTypes.shape([]),
    fetchAllReportedMessages: PropTypes.func.isRequired,
    toggleCheckAdmin: PropTypes.func.isRequired,
    cancelActiveUserMessages: PropTypes.func.isRequired,
    fetchUserMessages: PropTypes.func.isRequired,
    answerSupportMsg: PropTypes.func.isRequired,
    chatMessages: PropTypes.shape({}),
    chatMessagesUserLastLanguage: PropTypes.string,
    supportAMessageFileUpload: PropTypes.func.isRequired,
    warningPlayers: PropTypes.shape({}),
    fetchWarningPlayers: PropTypes.func.isRequired,
    handleAddWarningPlayer: PropTypes.func.isRequired,
    fetchProfanitiesList: PropTypes.func.isRequired,
    setSupportMessageAsResponded: PropTypes.func.isRequired,
  }

  static defaultProps = {
    allShopDiscounts: [],
    chatMessages: {},
    warningPlayers: {},
    profanitiesList: {},
    allReportedMessages: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 50,
      openChatModal: false,
      openChatModalName: '',
      openChatModalUid: '',
      ableToSend: true,
      inputMessage: 'Brīdinājums! Agresija un rupjības čatā nav atļautas. Veiksmi!',
      popoverOpen: false,
      targetId: null,
      popOverMsg: null,
    };
    this.hiddenFileInput = React.createRef(null);
  }

  componentDidMount() {
    const { fetchProfanitiesList, fetchWarningPlayers, fetchAllReportedMessages } = this.props;

    console.log('ReportedMessages componentDidMount');

    fetchAllReportedMessages();
    fetchWarningPlayers();
    fetchProfanitiesList();
  }

  componentWillReceiveProps(nextProps) {
    const { warningPlayers, fetchWarningPlayers } = this.props;

    // console.log('componentWillReceiveProps');

   // if (isEqual(warningPlayers, nextProps.warningPlayers)){
   //   console.log(warningPlayers, nextProps.warningPlayers, "checkWarningPlayers");
    //  fetchWarningPlayers();
  //  }
  }

  handleClick = (e, index) => {
    e.preventDefault();

    console.log('setState');
    this.setState({
      currentPage: index,
    });
  }

  handleCheckAdmin = (key, reporterId, reporterName, reportedId, reportedName, message) => {
    const { toggleCheckAdmin } = this.props;

    toggleCheckAdmin(key, reporterId, reporterName, reportedId, reportedName, message);
  }

  openChatModalFunc = (uid, name) => {
    const { fetchUserMessages } = this.props;

    fetchUserMessages(uid).then(() => {
      //  this.scrollToBottom();
    });

    console.log('setState');
    this.setState({
      openChatModal: true,
      openChatModalName: name,
      openChatModalUid: uid,
      fileName: '',
      fileUploadUrl: '',
      //  uid,
    });
  }

  toggleChat = () => {
    const { openChatModal, openChatModalUid } = this.state;

    if (openChatModal) {
      const { cancelActiveUserMessages } = this.props;

      cancelActiveUserMessages(openChatModalUid);
    }

    console.log('setState');
    this.setState(prevState => ({
      openChatModal: !prevState.openChatModal,
      openChatModalUid: '',
    }));
  }

  setSupportMessageAsResponded = () => {
    const { setSupportMessageAsResponded } = this.props;
    const { openChatModalUid } = this.state;

    setSupportMessageAsResponded({ uid: openChatModalUid }).then(() => {
      console.log('setState');
      this.setState({
        openChatModal: false,
        //  uid: '',
      });
    });
  }

  _handleFileClick = () => {
    this.hiddenFileInput.current.click();
  };
  
  submitMessage = () => {
    const { answerSupportMsg, fetchUserMessages } = this.props;
    const {
      inputMessage, openChatModalUid, fileUploadUrl, fileName,
    } = this.state;
    if (inputMessage != '' || fileUploadUrl != '') {
      answerSupportMsg({
        uid: openChatModalUid, message: inputMessage, fileUploadUrl, fileName,
      }).then(() => {
        //  this.scrollToBottom();

        fetchUserMessages(openChatModalUid).then((res) => {
          //  this.scrollToBottom();
        });

        console.log('setState');
        this.setState({ inputMessage: 'Brīdinājums! Agresija un rupjības čatā nav atļautas. Veiksmi!', fileUploadUrl: '', fileName: '' });
      });
    }
  }

  chooseFile = (e) => {
    e.preventDefault();

    const { showNotification, t } = this.props;
    const target = e.target.files[0];
    const typeList = '.jpg,.jpeg,.png,.pdf,.msword,.doc,.rtf,.txt,ain';
    if (!target || (target && target.length === 0)) return;
    if (!typeList.includes((target.type).substring((target.type).length - 3)) || target.type == '') { return showNotification('Error!', "Unsupported file!", 'danger'); }
    if (target.size / 1024 > 1536) {
      showNotification('Warning', "Can't upload more than 1.5 MB", 'warning');
      this.setState({
        fileUploadUrl: '',
        fileName: '',
      });
    } else {
      this.setState({
        ableToSend: false,
      });
      const { supportAMessageFileUpload } = this.props;
      supportAMessageFileUpload(target).then((data) => {
        console.log('setState');
        this.setState({
          fileUploadUrl: data.url,
          fileName: target.name,
          ableToSend: true,
        });
        showNotification('Success', "File successfully attached!", 'success');
      });
    }
  }

  handleChangeChat = (event) => {
    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

      console.log('setState');
      this.setState({
        [event.target.name]: value,
      });
    }
  }

  handleEnter = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.submitMessage();
    }
  }

  handleWarningActivatedByAdmin = (warningPlayerId, warningMark) => {
    const { handleAddWarningPlayer, fetchWarningPlayers } = this.props;

    handleAddWarningPlayer(warningPlayerId, warningMark);
    // fetchWarningPlayers();
  }

  onHover = (key, idx, msg) => {
    const { popoverOpen } = this.state;
    console.log(key, idx, msg, "checkMsgsss")
    this.onHoverLeave();
    console.log('setState');
    this.setState({
      targetId: `admin-report-chat-popover-${key}-${idx}`,
      popoverOpen: true,
      popOverMsg: msg,
    });
  }

  onHoverLeave = () => {
    this.setState({
      popoverOpen: false,
      popOverMsg: null,
      targetId: null,
    });
  }


  table() {
    const {
      t,
      allReportedMessages,
      i18n,
      warningPlayers,
      profanitiesList,
    } = this.props;

    const {
      currentPage,
      pageSize,
      popoverOpen,
      targetId,
      popOverMsg,
    } = this.state;
    const pagesCount = Math.ceil(Object.keys(allReportedMessages).length / pageSize);

    console.log('render');

    return (
      <Fragment>
        {Object.keys(allReportedMessages).length > 0 && Object.keys(allReportedMessages)
          .sort((a, b) => (allReportedMessages[b].reportedTime - allReportedMessages[a].reportedTime))
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((key, index) => (
            <Fragment key={key}>
              <tr key={key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allUsers-table-col">
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={new Date(allReportedMessages[key].reportedTime)} />
                </td>
                <td className="allUsers-table-col">
                  <div className="d-flex flex-column align-items-center">
                    <Label>{allReportedMessages[key].reporterId}</Label>
                    <Label>{allReportedMessages[key].reporterName}</Label>
                  </div>
                </td>
                <td className="allUsers-table-col">
                  <div className="d-flex flex-column align-items-center">
                    <Label>{allReportedMessages[key].reportedMsgPlayerId}</Label>
                    <Label>{allReportedMessages[key].reportedMsgPlayerName}</Label>
                  </div>
                </td>
                <td className="allUsers-table-col">
                  {filter(warningPlayers, (item, warningPlayerKey) => { return warningPlayerKey === allReportedMessages[key].reportedMsgPlayerId})[0]?.warning || 0}
                </td>
                <td className="allUsers-table-col">
                  {filter(warningPlayers, (item, warningPlayerKey) => { return warningPlayerKey === allReportedMessages[key].reportedMsgPlayerId})[0]?.warning >= 10 && (
                    <CountDownDate showOption="admin_report" date={filter(warningPlayers, (item, warningPlayerKey) => { return warningPlayerKey === allReportedMessages[key].reportedMsgPlayerId})[0]?.cursorExpire} newDate={Date.now()} />
                  )}
                </td>
                {
                  filter(profanitiesList, item => item.word === allReportedMessages[key].message.toLowerCase())[0] ? (
                    <td
                      className="allUsers-table-col"
                      id={`admin-report-chat-popover-${key}-${allReportedMessages[key].reportedMsgPlayerId}`}
                      onMouseEnter={() => this.onHover(key, allReportedMessages[key].reportedMsgPlayerId, allReportedMessages[key].message)}
                      onMouseLeave={() => this.onHoverLeave()}
                    >
                      *****
                    </td>
                  ) : (
                    <td className="allUsers-table-col">
                      {allReportedMessages[key].message}
                    </td>
                  )
                }
                
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.openChatModalFunc(allReportedMessages[key].reportedMsgPlayerId, allReportedMessages[key].reportedMsgPlayerName)}>
                    Message
                  </Button>
                </td>
                <td className="allUsers-table-col">
                  <Button
                    color="primary"
                    disabled={allReportedMessages[key]?.adminView}
                    onClick={allReportedMessages[key]?.adminView ?
                      null : () => this.handleCheckAdmin(key, allReportedMessages[key].reporterId, allReportedMessages[key].reporterName, allReportedMessages[key].reportedMsgPlayerId, allReportedMessages[key].reportedMsgPlayerName, allReportedMessages[key].message)}>
                    {allReportedMessages[key]?.adminView ? 'Done' : 'View'}
                  </Button>
                </td>
                <td className="allUsers-table-col">
                  <Button
                    color="primary"
                    disabled={filter(warningPlayers, (item, warningPlayerKey) => { return warningPlayerKey === allReportedMessages[key].reportedMsgPlayerId})[0]?.cursorExpire && filter(warningPlayers, (item, warningPlayerKey) => { return warningPlayerKey === allReportedMessages[key].reportedMsgPlayerId})[0]?.cursor}
                    onClick={() => this.handleWarningActivatedByAdmin(allReportedMessages[key].reportedMsgPlayerId, filter(warningPlayers, (item, warningPlayerKey) => { return warningPlayerKey === allReportedMessages[key].reportedMsgPlayerId})[0]?.warning)}
                  >
                    {size(filter(warningPlayers, (item, warningPlayerKey) => { return warningPlayerKey === key })) > 0 ? 'Warned' : 'Warn'}
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        {pagesCount && Object.keys(allReportedMessages).length > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                  {`‹‹‹`}
                </PaginationLink>
              </PaginationItem>

              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 3 && i < currentPage + 3) {
                  return (
                    <PaginationItem active={i === currentPage} key={page}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                    {`›››`}
                  </PaginationLink>
                </PaginationItem>
            </Pagination>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      t,
      chatMessages,
      chatMessagesUserLastLanguage,
    } = this.props;

    const {
      openChatModalName,
      openChatModalUid,
      openChatModal,
      inputMessage,
      ableToSend,
      fileName,
      popOverMsg,
      targetId,
      popoverOpen
    } = this.state;

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-report-messages">
          <PopoverBody className="popover-body">
            A list of messages that have been reported in the game by other players.
          </PopoverBody>
        </UncontrolledPopover>
        <div style={{ marginTop: 100, color: '#fff' }}>
          <Row>
            <Col sm="4">
              <h2>
                {/* {t('shopDiscounts')} */}
                Report Messages
                <Button className="admin-help-button" id="admin-help-button-report-messages">
                  ?
                </Button>
              </h2>
            </Col>
          </Row>
          <table style={{ width: '100%', fontSize: 12 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  Id and Name of reporter
                </th>
                <th style={{ textAlign: 'center' }}>
                  Id and Name of reported player
                </th>
                <th style={{ textAlign: 'center' }}>
                  Warnings
                </th>
                <th style={{ textAlign: 'center' }}>
                  Valid Limitation
                </th>
                <th style={{ textAlign: 'center' }}>
                  Reason for report
                </th>
                <td style={{ textAlign: 'center' }}>
                  Message
                </td>
                <th style={{ textAlign: 'center' }}>
                  Status
                </th>
                <th style={{ textAlign: 'center' }}>
                  Warn
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>

        </div>

        <Modal container={'div > div'} isOpen={openChatModal} toggle={this.toggleChat}>
          <ModalHeader toggle={this.toggleChat}>
            {`Chat - ${openChatModalName} - ${openChatModalUid}`}
          </ModalHeader>
          <ModalBody>
            <Row className="chat-body">
              <Col className="chat-body-wrapper" md="12">
                <ScrollArea
                  speed={0.65}
                  className="admin-chat-scroll-area"
                  contentClassName="admin-chat-scroll-content"
                  //  smoothScrolling
                  verticalContainerStyle={{
                    background: 'transparent',
                    opacity: 1,
                    width: 10,
                  }}
                  verticalScrollbarStyle={{
                    background: '#fff',
                    borderRadius: 2,
                    width: 6,
                    minHeight: 30,
                    minScrollSize: 35,
                  }}
                  horizontal={false}
                  ref={(el) => { this.messagesScrollbar = el; }}
                >
                  {chatMessages && Object.keys(chatMessages).map(key => (
                    <Row key={key}>
                      <Col md="12">
                        <Message message={chatMessages[key]} chatMessagesUserLastLanguage={chatMessagesUserLastLanguage} />
                      </Col>
                    </Row>
                  ))}
                </ScrollArea>
              </Col>
            </Row>
            <Row className="chat-footer" style={{ height: 60 }}>
              <Form style={{ width: '100%', height: '100%' }}>
                <Col md="12" style={{ height: '100%' }}>
                  <Input
                    className="h-100 chat-footer-input chat-footer-input-admin"
                    type="textarea"
                    name="inputMessage"
                    id="inputMessage"
                    autoComplete="off"
                    placeholder='Write Message...'
                    style={{

                    }}
                    value={inputMessage}
                    onChange={this.handleChangeChat}
                    onKeyPress={this.handleEnter}
                  />

                </Col>
              </Form>
            </Row>
            <Row>
              <Button
                className="btn-warning admin-file-upload-button"
                onClick={this._handleFileClick}
              >
                {fileName === '' ? '+ Choose File' : fileName}
              </Button>
              <input
                id="files"
                hidden
                ref={this.hiddenFileInput}
                className="chat-footer-input chat-footer-input-admin admin-file-upload-input"
                accept=".jpg,.jpeg,.png,.pdf,.msword,.doc,.rtf,.txt,ain"
                type="file"
                onChange={this.chooseFile}
              />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={this.setSupportMessageAsResponded}>Mark As Read</Button>
            <Button className="contact-support-footer-button" disabled={!ableToSend} color="primary" onClick={this.submitMessage}>
              {/* {t('send')} */}
              Send
            </Button>
            <Button type="button" color="secondary" onClick={this.toggleChat}>Close</Button>
          </ModalFooter>
        </Modal>
        {
          popoverOpen && popOverMsg && targetId ? (
            <Popover container={'div > div'} popperClassName="last-round-popover" placement="top" isOpen={popoverOpen} target={targetId}>
              <PopoverBody className="last-round-popover-body">
                {popOverMsg}
              </PopoverBody>
            </Popover>
          ) : (null)
        }
        
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  // allReportedMessages: state.admin.allReportedMessages || [],
  chatMessages: state.admin.chatMessages || {},
  chatMessagesUserLastLanguage: state.admin.chatMessagesUserLastLanguage,
  warningPlayers: state.admin.warningPlayers || {},
  profanitiesList: state.admin.profanitiesList || {}, 
});

const mapDispatchToProps = {
  fetchAllReportedMessages: getAllReportedMessages,
  toggleCheckAdmin: checkAdminReportMessages,
  fetchUserMessages: getUserMessages,
  cancelActiveUserMessages: cancelUserMessages,
  supportAMessageFileUpload: supportMessageFileUpload,
  answerSupportMsg: answerSupportMessage,
  fetchWarningPlayers: getWarningPlayers,
  handleAddWarningPlayer: addWarningMark,
  fetchProfanitiesList: getProfanitiesList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('admin')(ReportedMessages));
