import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

class ScrollAreaButtons extends PureComponent {
  static propTypes = {
    scrollRef: PropTypes.shape(),
    rightOffset: PropTypes.number,
    speed: PropTypes.number,
  }

  static defaultProps = {
    scrollRef: {},
    rightOffset: 0,
    speed: 30,
  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
  //  console.log('componentDidMount');
  }

  componentDidUpdate() {
  //  console.log('componentDidUpdate');
  }

  scrollUp = () => {
    const {
      show, speed, scrollPos, scroll,
    } = this.props;

    console.log('scrollUp', {
      scroll, show, speed, scrollPos,
    });

    if (show && speed) {
      const curPos = scrollPos;
      const newPos = curPos - speed;

      scroll(newPos);
    }
  }

  scrollDown = () => {
    const {
      show, speed, scrollPos, scroll,
    } = this.props;

    console.log('scrollDown', {
      scroll, show, speed, scrollPos,
    });

    if (show && speed) {
      const curPos = scrollPos;
      const newPos = curPos + speed;

      scroll(newPos);
    }
  }

  handleUpButtonPress = () => {
  //  this.UpButtonPressTimer = setTimeout(() => this.scrollUp, 500);
    this.UpButtonPressTimer = setInterval(() => {
      this.scrollUp();
    }, 100);
  }

  handleUpButtonRelease = () => {
    clearInterval(this.UpButtonPressTimer);
  }

  handleDownButtonPress = () => {
    this.downButtonPressTimer = setInterval(() => {
      this.scrollDown();
    }, 100);
  }

  handleDownButtonRelease = () => {
    clearInterval(this.downButtonPressTimer);
  }

  render = () => {
    const {
      rightOffset, show, topOffset, bottomOffset, bgColor,
    } = this.props;

    //  console.log('ScrollAreaButtons render', { show });

    if (!show) {
      return null;
    }

    //  if (!scrollRef || scrollRef.containerHeight >= scrollRef.realHeight) {
    //    return null;
    //  }

    return (
      <Fragment>
        <div
          className="scrollArea-buttonUp"
          style={{ right: rightOffset, top: topOffset, background: bgColor }}
          onClick={this.scrollUp}
          onTouchStart={this.handleUpButtonPress}
          onTouchEnd={this.handleUpButtonRelease}
          onMouseDown={this.handleUpButtonPress}
          onMouseUp={this.handleUpButtonRelease}
          onMouseLeave={this.handleUpButtonRelease}
        >
          <div className="scrollArea-buttonUp-img" />
        </div>
        <div
          className="scrollArea-buttonDown"
          style={{ right: rightOffset, bottom: bottomOffset, background: bgColor }}
          onClick={this.scrollDown}
          onTouchStart={this.handleDownButtonPress}
          onTouchEnd={this.handleDownButtonRelease}
          onMouseDown={this.handleDownButtonPress}
          onMouseUp={this.handleDownButtonRelease}
          onMouseLeave={this.handleDownButtonRelease}
        >
          <div className="scrollArea-buttonDown-img" />
        </div>
      </Fragment>
    );
  }
}

export default ScrollAreaButtons;
