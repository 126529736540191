import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { map } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import { ModalBody, ModalHeader, Modal, ModalFooter, Media } from 'reactstrap';

import Moment from 'react-moment';
import { count } from 'lodash';
import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';

import CustomModal from '../../Components/Components/Modal';

import * as constants from '../../../../../constants/constants';
import closeImg from '../../../../../images/icons/close.png';

import defaultImageLight from '../../../../../images/redesign/light-mode/common/default_image.svg';
import defaultImageNormal from '../../../../../images/redesign/normal-mode/common/default_image.webp';
import defaultImageDark from '../../../../../images/redesign/common/default_image.svg';

import { trimString } from '../../../../../utils/stringUtils';
import { getCardImage } from '../../../../../utils/cardInfoUtils';


const PartyLog = React.memo(({
  party,
  partyRoomData,
  openPartyModal,
  closePartyLog,
  togglePartyLog,
  roomPlayerData,
  roomPlayerImgData,
  screenMode,
  pulesModal
}) => {
  const { i18n, t } = useTranslation('admin');
  if (Object.keys(party).length === 0) {
    return null;
  }

  const defaultImage = screenMode === constants.SCREEN_MODE.light ? defaultImageLight : (screenMode === constants.SCREEN_MODE.normal ? defaultImageNormal : defaultImageDark);
  let playerNames;
  const playerData = [];
  let secondBurriedCard = false;

  if (partyRoomData[0]) {
    playerNames = partyRoomData[0];
  }

  if (partyRoomData[1]) {
    const players = partyRoomData[1];
    Object.keys(partyRoomData[1]).map((key) => {
      if (players[key].type === constants.PLAYERJOINED) {
        playerData[players[key].data.joinedPlayerUid] = players[key.toString()].data.joinedPlayerName ? players[key.toString()].data.joinedPlayerName : (playerNames.player1 ? playerNames.player1 : '');
      }
    });
  }

  /*
  const partyArray = Object.entries(party || {});
  partyArray.sort((a, b) => a[1].time - b[1].time);
  const sortedParty = Object.fromEntries(partyArray); */

  const partyArray = [];
  Object.keys(party).map((key) => {
    partyArray.push({ ...party[key], key });
  });

  partyArray.sort((a, b) => b.time - a.time);

  let sortedParty = {};
  partyArray.map((item) => {
    sortedParty = Object.assign({ [item.key]: item }, sortedParty);
  });

  console.log('sortedParty', sortedParty);

  const colorCards = (str) => {
    return str.split(/(♥|♦)/)
    .map((part, index) => {
      if (part === '♥' || part === '♦') {
        return (<span key={index} style={{ color: 'red' }}>{part}</span>);
      }
      return <span key={index}>{part}</span>;
    })
  }
  
  return (
    <CustomModal
      isOpen={openPartyModal}
      toggle={closePartyLog}
      width={partyRoomData[0] && partyRoomData[0].player4 ? 965 : 765}
      title={pulesModal ? t('calculationOfPulesModal') : t('gameLogs')}
      body={(
        <div className="new-user-party-modal">
          <ScrollAreaWrapper
            className="user-party-table-scrollarea"
            contentClassName="user-party-table-body"
            show
            rightOffset={0}
            topOffset={23}
            bottomOffset={-3}
          >
            {party && Object.keys(sortedParty)
              .map((partyKey) => {
                const action = party[partyKey];
                if (partyKey === 'gameResult') {
                  return null;
                }
                if (action.type === 'roundOver'){
                  return null;
                }

                if (action.type === 'missedTurn') {
                  console.log("action penalty", action);
                }
                console.log('action', { action });
                let hasDivider = false;

                if (action.type === 'determineStrongest' || ((action.type === 'selectType' || action.type === 'selectTypeAutomated') && action.data.type !== 'parasta' && action.data.type !== 'garam')
                  || action.type === 'burriedCard' || action.type === 'burriedCardAutomated' || action.type === 'roundResult') {
                  if ((action.type === 'burriedCard' || action.type === 'burriedCardAutomated') && !secondBurriedCard) {
                    secondBurriedCard = true;
                  } else {
                    hasDivider = true;
                  }
                }

                if ((action.type === 'selectType' || action.type === 'selectTypeAutomated') && action.data.type === 'garam' && action.data.action === 'galdins') {
                  hasDivider = true;
                }

                else if (action.type === 'updatePoints' || action.type === 'roomClosed') {
                  hasDivider = true;
                }

                if (action.type === 'updatePoints') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}
                    >
                      {/* <Col sm="2">
                        <b>
                          {t('updatePoints')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                          {`${t('player')} ${action.data.playerName}`}
                        </div>
                        {pulesModal ? (
                          <>
                            <div>
                              {`${t('oldBal')}: ${action.data.oldBal}, ${t('newBal')}: ${action.data.newBal}   (${action.data.newBal - action.data.oldBal > 0 ? '+' : ''}${action.data.newBal - action.data.oldBal})`}
                            </div>
                            <div>
                              {`${t('oldPnts')}: ${action.data.oldPnts}, ${t('newPnts')}: ${action.data.newPnts}   (${action.data.newBal - action.data.oldBal > 0 ? '+' : ''}${action.data.newPnts - action.data.oldPnts})`}
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              {`${t('oldBal')}: ${action.data.oldBal}, ${t('newBal')}: ${action.data.newBal}`}
                            </div>
                            <div>
                              {`${t('oldPnts')}: ${action.data.oldPnts}, ${t('newPnts')}: ${action.data.newPnts}`}
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'cardPlayed') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('cardPlayed')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                          {colorCards(t('cardPlayedText', { player: playerNames ? playerNames[action.data.player] : action.data.player, playedCard: action.data.playedCard }))}
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'cardPlayedAutomated') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('cardPlayedAutomated')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                          {colorCards(t('cardPlayedText', { player: playerNames ? playerNames[action.data.player] : action.data.player, playedCard: action.data.playedCard }))}
                        </div>
                      </Col>
                    </Row>
                  );
                }


                if (action.type === 'determineStrongest') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('determineStrongest')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div className='game-log-description'>
                          {colorCards(t('determineStrongestText', { card: action.data.card, winPlayer: action.data.winPlayer, tablePoints: action.data.tablePoints ? (`${action.data.tablePoints}`) : ('0') }))}
                          {/*    {`Strongest card is ${action.data.card} played by ${action.data.winPlayer}. ${action.data.tablePoints ? (`Take ${action.data.tablePoints} points`) : ('')} `}  */}
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'selectType') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('selectType')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                          {/* {`${t('selectTypeText1', { player: action.data.player })} ${t(action.data.type)}`}
                          {action.data.type === 'garam' && action.data.action === 'next' && (' ')}
                          {action.data.type === 'garam' && action.data.action === 'galdins' && (`${t('galdinsPlayed')}`)}
                          {action.data.type === 'garam' && action.data.action === 'nextRound' && (`${t('nextRoundPlayed')}`)} */}
                          {
                            action.data.type === 'garam' ? (
                              action.data.action !== 'galdins' ? (
                                t('passedText', {player: action.data.player})
                              ) : (
                                t('passedTextGaldins', {player: action.data.player})
                              )
                            ) : (
                              (action.data.type === 'zole') ? (
                                `${t('player')} ${action.data.player} ${t('selectZole')}`
                              ) : (action.data.type === 'maza') ? (
                                `${t('player')} ${action.data.player} ${t('selectSmZole')}`
                              ) : (
                                `${t('player')} ${action.data.player} ${t('takeTable')}`
                              )
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'selectTypeAutomated') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('selectTypeAutomated')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                          {/* {`${t('selectTypeText1', { player: action.data.player })} ${t(action.data.type)}`}
                          {action.data.type === 'garam' && action.data.action === 'next' && (' ')}
                          {action.data.type === 'garam' && action.data.action === 'galdins' && (`${t('galdinsPlayed')}`)}
                          {action.data.type === 'garam' && action.data.action === 'nextRound' && (`${t('nextRoundPlayed')}`)} */}
                          {
                            action.data.type === 'garam' ? (
                              action.data.action !== 'galdins' ? (
                                t('passedText', {player: action.data.player})
                              ) : (
                                t('passedTextGaldins', {player: action.data.player})
                              )
                            ) : (
                              (action.data.type === 'zole') ? (
                                `${t('player')} ${action.data.player} ${t('selectZole')}`
                              ) : (action.data.type === 'maza') ? (
                                `${t('player')} ${action.data.player} ${t('selectSmZole')}`
                              ) : (
                                `${t('player')} ${action.data.player} ${t('takeTable')}`
                              )
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'burriedCard') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('burriedCard')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                          {colorCards(t('burriedCardText', { burriedCard: action.data.burriedCard, newPnts: action.data.newPnts }))}
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'burriedCardAutomated') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('burriedCardAutomated')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                          {colorCards(t('burriedCardText', { burriedCard: action.data.burriedCard, newPnts: action.data.newPnts }))}
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'roundResult') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('roundResult')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                          {action.data.type === 'galdins' ? (
                            <Fragment>
                              {t('lostGaldins', { player: action.data.winner, tricks: action.data.largeTricks, points: action.data.largePoints })}
                              {/* {`${action.data.winner} ${t('lostGame')} ${t(action.data.type)}`} */}
                            </Fragment>
                          ) : (
                            <Fragment>
                              {`${t('largePlayer')} (${action.data.largePlayer}) ${action.data.winner === 'large' ? t('wonGame') : t('lostGame')} ${action.data.type !== 'parasta' ? t(action.data.type) : ''}`}
                              {(action.data.type === 'zole' || action.data.type === 'parasta') && ` ${t('points2', { points: action.data.largePoints })}`}

                              {action.data.scoreType === 'jani' && (` ${t('withJani')}`)}
                              {action.data.scoreType === 'bezstikis' && (` ${t('withBezstikis')}`)}
                            </Fragment>
                          )}
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'playerQuit') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('playerQuit')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                          {t('playerQuit2', { player: action.data.player })}
                          {/* {t('playerQuitText', { player: action.data.player, roundType: action.data.roundType })} */}
                        </div>
                      </Col>
                    </Row>
                  );
                }
                // if (action.type === 'roundOver') {
                //   return (
                //     <Row className={
                //       classNames('d-flex flex-row', {
                //         [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                //       })}>
                //       {/* <Col sm="2">
                //         <b>
                //           {t('roundOver')}
                //         </b>
                //       </Col> */}
                //       <Col sm="2">
                //         <Moment format="HH:mm:ss" locale="lv">
                //           {action.time}
                //         </Moment>
                //       </Col>
                //       <Col sm="10">
                //         <div />
                //       </Col>
                //     </Row>
                //   );
                // }

                if (action.type === 'newPule') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('puleAdded')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                          {action.data.type ? t('commonPuleAdded') : t('privatePuleAdded', { player: action.data.player })}
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'puleChange') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('puleChange')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                          { action.data.changeTo === 'P-' ? (
                            t('roundPuleChanged2', { game: action.data.party, pule: action.data.changeTo })
                          ) : (
                            t('roundPuleChangedPersonal', { game: action.data.party, pule: action.data.changeTo })
                          )}
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'roomClosed') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('roomClosed')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        {action.data.type === 'tournamentEnd' && (
                          <div>
                            {`${t('roomWasClosed')} ${t('tournamentEnd')}`}
                          </div>
                        )}
                        {action.data.type === 'lastRound' && (
                          <div>
                            {(action.data.playerUid === 'tournament') ? (
                              i18n.language === constants.sendLanguage.ru ? (

                                `${t('roomWasClosedRu')} ${action.data.player} ${t('lastRoundTournamentRu')}`
                              ) : (
                                `${t('roomWasClosed')} ${action.data.player} ${t('lastRoundTournamentLast')}`
                              )
                            ) : (
                              `${t('roomWasClosed')} ${action.data.player} ${t('lastRound')}`
                            )}
                          </div>
                        )}
                        {action.data.type === 'missedTurn' && (
                          <div>
                            {`${t('roomWasClosed')} ${action.data.player} ${t('missedTurn')}`}
                          </div>
                        )}
                        {action.data.type === 'leftRoom' && (
                          <div>
                            {`${t('roomWasClosed')} ${action.data.player} ${t('leftRoom')}`}
                          </div>
                        )}
                        {action.data.type === 'lastPlayerLeftRoom' && (
                          <div>
                            {`${t('roomWasClosed')} ${t('lastPlayerLeftRoom')}`}
                          </div>
                        )}
                        {action.data.type === 'endAutomatedRoom' && (
                          <div>
                            {`${t('endAutomatedRoom', { player: action.data.player })}`}
                          </div>
                        )}

                      </Col>
                    </Row>
                  );
                }

                if (action.type === constants.LEFTROOMPENALTY || action.type === constants.MINGAMESPENALTY || action.type === constants.MISSEDTURNPENALTY) {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t(action.type)}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        {action.data.data && Object.keys(action.data.data)
                          .map((actionKey) => {
                            const actionDataPlayerName = playerData[actionKey] ? playerData[actionKey] : (playerNames.player1 ? playerNames.player1 : '');

                            if (action.data.fullData) {
                              return (
                                <>
                                  <div>
                                    {actionDataPlayerName}
                                  </div>
                                  <div>
                                    {`${t('oldBal')}: ${action.data.fullData[actionKey].old}, ${t('newBal')}: ${action.data.fullData[actionKey].new}`}
                                  </div>
                                  {(action.data.fullData[actionKey].oldPoints && action.data.fullData[actionKey].newPoints) ? (
                                    <div>
                                      {`${t('oldPnts')}: ${action.data.fullData[actionKey].oldPoints}, ${t('newPnts')}: ${action.data.fullData[actionKey].newPoints}`}
                                    </div>
                                  ) : (null)}
                                </>
                              );
                            }

                            return (
                              <div>
                                <b style={{ display: 'inline-block' }}>
                                  {actionDataPlayerName}
                                </b>
                                {`: ${action.data.playerUid === actionKey ? (action.data.data[actionKey] > 0 ? -action.data.data[actionKey] : action.data.data[actionKey]) : action.data.data[actionKey]} `}
                              </div>
                            );
                          })}
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'startAutomated') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}
                    >
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                          {t('startAutomated', { player: action.data.playerName ? action.data.playerName : action.data.player })}
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'endAutomated') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('endAutomated')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                          {t('endAutomated', { player: action.data.playerName ? action.data.playerName : action.data.player })}
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'cardsDealt') {
                  return (
                    <>
                      <Row className={
                        classNames('d-flex flex-row', {
                          [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                        })}>
                        {/* <Col sm="2">
                          <b>
                            {t('dealtCards')}
                          </b>
                        </Col> */}
                        <Col sm="2">
                          <Moment format="HH:mm:ss" locale="lv">
                            {action.time}
                          </Moment>
                        </Col>
                        <Col sm="10">
                          {t('dealtCards')}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="py-2">
                          { Object.keys(partyRoomData[0]).map(key => {
                            return (
                              <div key={key} className="end-result-party-modal-cards">
                                <div className="end-result-party-modal-cards-photo-container">
                                  <Media src={(roomPlayerImgData && roomPlayerImgData[partyRoomData[0][key]] && roomPlayerImgData[partyRoomData[0][key]].photo !== '') ? roomPlayerImgData[partyRoomData[0][key]].photo : defaultImage} className={`end-result-party-modal-cards-photo-container-img ${(!roomPlayerImgData || !roomPlayerImgData[partyRoomData[0][key]] || roomPlayerImgData[partyRoomData[0][key]].photo === '') ? `end-result-party-modal-cards-photo-container-img-default-${screenMode}` : ''}`} />
                                </div>
                                <div className="end-result-party-modal-cards-info">
                                  <div className="end-result-party-modal-cards-info-title">
                                    {trimString(partyRoomData[0][key], 14)}
                                  </div>
                                  <div className="end-result-party-modal-cards-info-card-container">
                                    {(action.data[key] && action.data[key].cards) ? action.data[key].cards.map((cardKey, cardIndex) => {
                                      return (
                                        <div key={`${key}-${cardIndex}`} className={`end-result-party-modal-cards-info-card-container-wrapper end-result-party-modal-cards-info-card-container-wrapper-${cardIndex}`}>
                                          <Media src={getCardImage(cardKey)} className="end-result-party-modal-cards-info-card-container-wrapper-card" />
                                        </div>
                                      );
                                    }):(null)}
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          <div className="end-result-party-modal-cards-table">
                            <div className="end-result-party-modal-cards-info-table">
                              <div className="end-result-party-modal-cards-info-table-title">
                                {t('previousRound:tableCards')}
                              </div>
                              <div className="end-result-party-modal-cards-info-card-container">
                                {action.data.cardsOnTable ? action.data.cardsOnTable.map((cardKey, cardIndex) => {
                                  return (
                                    <div key={`cardsOnTable-${cardIndex}`} className={`end-result-party-modal-cards-info-card-container-wrapper end-result-party-modal-cards-info-card-container-wrapper-${cardIndex}`}>
                                      <Media src={getCardImage(cardKey)} className="end-result-party-modal-cards-info-card-container-wrapper-card" />
                                    </div>
                                  );
                                }):(null)}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                }

                if (action.type === 'endRoomPules') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('endRoomPules')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        {/* <div>
                          {`${action.data.player1 && action.data.player1.uid}: ${action.data.player1 && action.data.player1.points}, `}
                          {`${action.data.player2 && action.data.player2.uid}: ${action.data.player2 && action.data.player2.points}, `}
                          {`${action.data.player3 && action.data.player3.uid}: ${action.data.player3 && action.data.player3.points}, `}
                          {`${action.data.player4 ? action.data.player4.uid : ''}: ${action.data.player4 ? action.data.player4.points : ''}, `}
                        </div> */}
                        <div>
                          {action.data.player1 ? (`${(action.data.player1.name || action.data.player1.uid)}: ${action.data.player1.points}`) : ('')}
                        </div>
                        <div>
                          {action.data.player2 ? (`${action.data.player2.name || action.data.player2.uid}: ${action.data.player2.points}`) : ('')}
                        </div>
                        <div>
                          {action.data.player3 ? (`${action.data.player3.name || action.data.player3.uid}: ${action.data.player3.points}`) : ('')}
                        </div>
                        <div>
                          {action.data.player4 ? (`${action.data.player4.name || action.data.player4.uid}: ${action.data.player4.points}`): ('')}
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'setLast') {
                  return (
                    ((action.data?.joinedPlayerName && action.data?.joinedPlayerName !== 'tournament') || (roomPlayerData[action.data?.playerUid] && roomPlayerData[action.data?.playerUid] !== 'tournament')) ? (
                      <Row className={
                        classNames('d-flex flex-row', {
                          [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                        })}>
                        {/* <Col sm="2">
                          <b>
                          {t('choseLastRound')}
                          </b>
                        </Col> */}
                        <Col sm="2">
                          <Moment format="HH:mm:ss" locale="lv">
                            {action.time}
                          </Moment>
                        </Col>
                        <Col sm="10">
                          <div>
                            {`${t('player')} ${action.data.joinedPlayerName || roomPlayerData[action.data?.playerUid]} ${t('choseLastRound')}`}
                          </div>
                        </Col>
                      </Row>
                    ) : (null)
                  );
                }

                if (action.type === 'playerJoined') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('playerJoined')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                        {`${t('player')} ${action.data.joinedPlayerName || roomPlayerData[action.data.joinedPlayerUid]} ${t('joined')}`}
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (action.type === 'playerLeft') {
                  return (
                    <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })}>
                      {/* <Col sm="2">
                        <b>
                          {t('playerLeft')}
                        </b>
                      </Col> */}
                      <Col sm="2">
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10">
                        <div>
                          {`${t('player')} ${action.data.playerName} ${t('left')}`}
                        </div>
                      </Col>
                    </Row>
                  );
                }

                return (
                  <Row className={
                      classNames('d-flex flex-row', {
                        [`end-result-party-modal-divide-${screenMode} mb-2`]: hasDivider,
                      })} key={partyKey}>
                    {/* <Col sm="2">
                      <b>
                        {action.message}
                      </b>
                    </Col> */}
                    <Col sm="2">
                      <Moment format="DD-MM-YYYY, HH:mm:ss SS" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10">
                      {action.data && Object.keys(action.data)
                        .map((actionKey) => {
                          const actionData = action.data[actionKey];
                          return (
                            <div>
                              <b style={{ display: 'inline-block' }}>
                                {[actionKey]}
                              </b>
                              {`: ${actionData} `}
                            </div>
                          );
                        })}
                    </Col>
                  </Row>
                );
              })}
          </ScrollAreaWrapper>
        </div>
      )}
      footer={(
        <Button color="secondary" onClick={closePartyLog}>
          {t('common:common.ok')}
        </Button>
      )}
    />
  );
});

export default PartyLog;
