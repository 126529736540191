import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';

import warningImg from '../../../../../images/redesign/icons/warning.svg';
import alertImg from '../../../../../images/redesign/icons/warning.svg';

const ErrorNotification = ({ isVisible, text, isAlert, isSuccess, narrow }) => {
  const iconImg = isAlert ? alertImg : warningImg;

  console.log('ErrorNotification', isVisible);

    if (isVisible) {
        return (
            <div className={`notification-msg ${isSuccess ? 'notification-msg-success' : (isAlert ? 'notification-msg-alert' : 'notification-msg-warning')}`}>
                <div className={`notification-msg-icon ${narrow ? 'notification-msg-icon-narrow' : ''}`}>
                    <Media src={iconImg} className="notification-msg-icon-img"/>
                </div>
                <div className={`notification-msg-content ${narrow ? 'notification-msg-content-narrow' : ''}`}>
                    {text}
                </div>
            </div>
        );
    }
    return null;
};

ErrorNotification.propTypes = {
    isVisible: PropTypes.bool,
    text: PropTypes.string,
    isAlert: PropTypes.bool,
    isSuccess: PropTypes.bool,
    narrow: PropTypes.bool,
};

ErrorNotification.defaultProps = {
    isVisible: false,
    text: '',
    isAlert: false,
    isSuccess: false,
    narrow: false,
};

export default ErrorNotification;
