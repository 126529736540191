import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { filter, includes } from 'lodash';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Media from 'reactstrap/lib/Media';
// import Button from 'reactstrap/lib/Button';

import { Helmet } from 'react-helmet';
import Iframe from 'react-iframe';
// import Spinner from 'reactstrap/lib/Spinner';

// import { loadStripe } from '@stripe/stripe-js';

import CustomDate from './CustomDate';
import ContactSupport from '../ContactSupport';

import config from '../../../constants/config';
import * as constants from '../../../constants/constants';

import buttonClickedSound from '../../../sounds/click_feedback.flac';
import closeImg from '../../../images/icons/close.png';

/*
const Blocked = ({ banEndDate, banReason }) => (
  <Fragment>
    <div className="loading-background" />
    <div className="loading-logo" />
    <div className="bannedScreen" style={{ marginTop: '18%', color: '#fff' }}>
      <Row>
        <Col md={{ size: 4, offset: 4 }}>
          <Row>
            <Col md="12">
              <h2>
                Jūs esat bloķēts
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              {'Līdz:'}
              <CustomDate format="DD-MM-YYYY" date={banEndDate} />
            </Col>
            <Col md="6">
              {`Iemesls: ${banReason}`}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </Fragment>
);

export default Blocked; */
import {
  initFBPayment,
  initDraugiemPayment,
  initInboxPayment,
  fbPaymentCallback,
  initStripePayment,
  initEveryPayPayment,
} from '../../../actions/member';
import {
  getDiscounts,
} from '../../../actions/shop';
import PaymentTypeContent from '../Redesign/Menu/Modals/PaymentTypeContent';

class Blocked extends Component {
  static propTypes = {
    socProvider: PropTypes.string,
    banEndDate: PropTypes.string,
    banReason: PropTypes.string,
    t: PropTypes.func.isRequired,
    initFBPay: PropTypes.func.isRequired,
    fbCallback: PropTypes.func.isRequired,
    initDraugiemPay: PropTypes.func.isRequired,
    initInboxPay: PropTypes.func.isRequired,
    initStripePay: PropTypes.func.isRequired,
    initEveryPay: PropTypes.func.isRequired,
    toggleStripeLoading: PropTypes.func.isRequired,
    toggleTransactionSuccess: PropTypes.func.isRequired,
    toggleAboutUs: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    handleAppStore: PropTypes.func,
    handlePlayStore: PropTypes.func,
    isFullscreen: PropTypes.bool,
    isFullscreenEnabled: PropTypes.bool,
    toggleFullscreen: PropTypes.func,
    fetchDiscounts: PropTypes.func.isRequired,
    discounts: PropTypes.arrayOf(PropTypes.shape({})),
  }

  static defaultProps = {
    banEndDate: null,
    banReason: null,
    socProvider: null,
    fontMode: constants.FONT_MODE.normal,
    discounts: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      paymentErrorModal: false,
      supportModalOpen: false,
      payUnBanModalOpen: false,
      showInboxPayment: false,
      inboxIframeLink: null,
      processing: false,
      selectedPaymentOption: constants.PAYMENT_TYPES.swedbank,
      selectedProduct: {},
      selectedProductId: 0,
      isOnline: navigator.onLine,
      checkedTerms: false,
      paymentError: '',
      devMode: false,
    };

    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentDidMount() {
    const { fetchDiscounts } = this.props;
    const { hostname } = window.location;
    let devModeTemp = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devModeTemp = true;
      this.setState({
        devMode: devModeTemp,
      });
    }
    fetchDiscounts(devModeTemp);

    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  }

  handleOnline = () => {
    this.setState({ isOnline: true });
  }

  handleOffline = () => {
    this.setState({ isOnline: false });
  }

  toggleSupport = () => {
    const { setSupportAsRead, supportChatStatus } = this.props;
    const { supportModalOpen } = this.state;

    if (!supportModalOpen && supportChatStatus && !supportChatStatus.read) {
      setSupportAsRead();
    }

    this.playButtonSound();
    this.setState({ supportModalOpen: !supportModalOpen });
  };

  playButtonSound = () => {
    const { userSettings, uid } = this.props;

    if (userSettings && uid) {
      const userSettings2 = userSettings[uid] || userSettings.default || {}

      if (userSettings2 && userSettings2.soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
    }
  }

  togglePayUnBan = () => {
    this.setState(prevState => ({
      payUnBanModalOpen: !prevState.payUnBanModalOpen,
    }));
  }

  togglePaymentErrorModal = (paymentError) => {
    this.setState({
        paymentErrorModal: !this.state.paymentErrorModal,
        paymentError
    });
  };

  onPaymentOptionClick = (option) => {
    this.setState({
      selectedPaymentOption: option
    });
  }

  pay = () => {
    const { selectedPaymentOption, selectedProduct, selectedProductId, checkedTerms } = this.state;
    const { toggleStripeLoading, initEveryPay, t, i18n } = this.props;

    if (!checkedTerms) {
      this.togglePaymentErrorModal(t('paymentType.acceptTerms'));
    } else if (![constants.PAYMENT_TYPES.paypal.id, constants.PAYMENT_TYPES.textMessage.id].includes(selectedPaymentOption.id)) {
      toggleStripeLoading(true);

      initEveryPay(selectedProduct, selectedPaymentOption, selectedProductId, i18n.language).then((res) => {
        const { payment_link } = res.data;
        if (payment_link) {
          window.location.href = payment_link;
        }
      }).catch(error => {
        toggleStripeLoading(false);
        throw error;
      });
    } else {
      this.togglePaymentErrorModal(t('paymentType.notAvailable'));
    }
  }

  buyMoney = (prod) => {
    const {
      discounts, initFBPay, fbCallback, socProvider, initDraugiemPay, initInboxPay, toggleTransactionSuccess, toggleStripeLoading, isFullscreen, isFullscreenEnabled, toggleFullscreen,
    } = this.props;
    const { devMode } = this.state;

    if (config.isInAppFrame() && isFullscreenEnabled) {
      toggleFullscreen();
    }

    if (!config.isInAppFrame()) {
      this.playButtonSound();

      this.setState({ selectedProduct: constants.BUY_PAGE_PRODUCTS[prod], selectedProductId: prod });
      if (devMode) {
        const selectedProduct = { ...constants.BUY_PAGE_PRODUCTS[prod] };
        if (discounts && discounts[0] && discounts[0].type === constants.SPECIALPRICE && discounts[0].filterType === constants.CURRENT) {
          selectedProduct.realPrice = discounts[0].specialPrice[`money${prod}`];
          selectedProduct.discountType = constants.SPECIALPRICE;
        } else if (discounts && discounts[0] && discounts[0].type === constants.DISCOUNTPRICE && discounts[0].filterType === constants.CURRENT) {
          const productMoney = filter(constants.SHOP_PRODUCT_TYPES, (item) => { return item.id === prod; })[0];
          selectedProduct.discountType = constants.DISCOUNTPRICE;

          if (includes(discounts[0].selectedDiscountPackage, productMoney.label)) {
            selectedProduct.realPrice = parseFloat(productMoney.realPrice - ((productMoney.realPrice/100) * discounts[0].discountPrice)).toFixed(2)
          }
        } else if (discounts && discounts[0] && discounts[0].type === constants.BONUS && discounts[0].filterType === constants.CURRENT) {
          const productMoney = filter(constants.SHOP_PRODUCT_TYPES, (item) => { return item.id === prod; })[0];
          selectedProduct.discountType = constants.BONUS;

          if (includes(discounts[0].selectedDiscountPackage, productMoney.label)) {
            selectedProduct.realPrice = discounts[0].discountPrice;
          }
        }
       
        this.setState({ selectedProduct, selectedProductId: prod });
      }
      this.togglePayUnBan();
      // this.playButtonSound();

      // this.setState({ processing: true });

      // const { initStripePay } = this.props;
      // toggleStripeLoading(true);
      // //  const failTxt = t('menu.stripeShopNotWorking');

      // initStripePay(prod).then((result) => {
      //   if (result.data && result.data.data.status && result.data.data.status === constants.FROUNT_SIDE_STATUS.success && result.data.data.id) {
      //     const paymentSessionId = result.data.data.id;

      //     loadStripe(config.stripeBublicKey).then((stripe) => {
      //       stripe.redirectToCheckout({
      //         sessionId: paymentSessionId,
      //       })
      //         .then(() => {
      //           toggleTransactionSuccess(true);
      //           toggleStripeLoading(false);

      //           this.setState({ processing: false });
      //         });
      //     }).catch(() => {
      //     //  alert(failTxt);
      //       this.setState({ processing: false });
      //       toggleStripeLoading(false);
      //     });
      //   } else if (result.data.data && !result.data.data.status) {
      //   //  alert(failTxt);
      //     toggleStripeLoading(false);
      //     this.setState({ processing: false });
      //   } else if (result.data.data && result.data.data.status && result.data.data.status === 'error') {
      //   //  alert(failTxt);
      //     toggleStripeLoading(false);
      //     this.setState({ processing: false });
      //   }
      // }).catch((e) => {
      // //  alert(failTxt);
      // //  console.log(e);
      //   toggleStripeLoading(false);
      //   this.setState({ processing: false });
      // });

      // return;
    } else if (socProvider === constants.SOC_PROVIDERS.facebook) {
      this.playButtonSound();

      initFBPay(prod).then((res) => {
        if (res.data && res.data.data && res.data.data.status === constants.FROUNT_SIDE_STATUS.success) {
          const { product } = res.data.data;

          if (product) {
            if (window.FB && socProvider === constants.SOC_PROVIDERS.facebook) {
              window.FB.ui({
                method: 'pay',
                action: 'purchaseitem',
                product,
                quantity: 1, // optional, defaults to 1
                request_id: res.data.data.token, // optional, must be unique for each payment
              },
              (resp) => {
                fbCallback(resp);
                return null;
              });
            } else {
            //  console.log('no FB.ui or not facebook provider');
            }
          } else {
          //  console.log('error with product');
          }
        }
      });
    } else if (socProvider === constants.SOC_PROVIDERS.draugiem) {
      this.playButtonSound();
      initDraugiemPay(prod).then((res) => {
        if (res.data && res.data.data && res.data.data.status === constants.FROUNT_SIDE_STATUS.success) {
          const { product } = res.data.data;

          if (product) {
            if (window.draugiemWindowOpen && socProvider === constants.SOC_PROVIDERS.draugiem) {
              window.draugiemWindowOpen(product, 350, 400);
            } else {
            //  console.log('no draugiemWindowOpen or not draugiem provider');
            }
          } else {
        //    console.log('error with product');
          }
        }
      });
    } else if (socProvider === constants.SOC_PROVIDERS.inbox) {
      this.playButtonSound();
      initInboxPay(prod).then((res) => {
        if (res.data && res.data.data && res.data.data.status === constants.FROUNT_SIDE_STATUS.success) {
          const { product } = res.data.data;

          if (product) {
            this.setState({ showInboxPayment: true, inboxIframeLink: product });
          } else {
          //  console.log('error with product');
          }
        }
      });
    }
  }

  closeInboxModal = () => {
    this.setState({ showInboxPayment: false, inboxIframeLink: null });
  }

  toggleCheckedTerms = () => {
    this.setState(prevState => ({
      checkedTerms: !prevState.checkedTerms
    }));
  };

  render = () => {
    const {
      banEndDate,
      banReason,
      t,
      socProvider,
      fontMode,
      //  resetClose,
      //  closeErrorSubmit,
      //  showNotification,
      toggleAboutUs,
      handleAppStore,
      handlePlayStore,
    } = this.props;

    const {
      supportModalOpen, payUnBanModalOpen, showInboxPayment, inboxIframeLink, selectedProduct, selectedPaymentOption, paymentErrorModal, isOnline, checkedTerms, paymentError,
    } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>
            {`Zole - ${t('blocked.blockedTitle')}`}
          </title>
        </Helmet>
        <div className="loading-background" />
        <div className="loading-logo" />
        <div className="bannedScreen" style={{ marginTop: '18%', color: '#fff' }}>
          <Row>
            <Col md={{ size: 4, offset: 4 }}>
              <Row>
                <Col md="12">
                  <h2>
                    {t('blocked.blocked')}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  {t('blocked.blockedUntil')}
                  <CustomDate format="DD-MM-YYYY hh:mm" date={banEndDate} />
                </Col>
                <Col md="6">
                  {`  ${t('blocked.reason')} ${banReason}`}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 4, offset: 4 }}>
              <div className="blocked-button-wrapper">
                <div className="blocked-button" onClick={this.toggleSupport}>
                  {t('blocked.contactAdmin')}
                </div>
              </div>
            </Col>
            <Col md={{ size: 4, offset: 4 }}>
              <div className="blocked-button-wrapper">
                <div className="blocked-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money9)}>
                  {t('buyPage.payUnBan')}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <ContactSupport
          modalOpen={supportModalOpen}
          toggle={this.toggleSupport}
        //  uid={uid}
        //  name={name}
        //  supportChat={supportChat}
        //  supportChatStatus={supportChatStatus}
        //  resetClose={resetClose}
        //  closeErrorSubmit={closeErrorSubmit}
        //  showNotification={showNotification}
        //  sendSupportMessage={sendSupportMessage}
        //  setSupportAsRead={setSupportAsRead}
        />

        <Modal container={'div > div'} isOpen={payUnBanModalOpen} size="lg" className={`old-modal modal-vertically-centered root-font-${fontMode}`}>
          <ModalHeader
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.togglePayUnBan} />
            }
          >
            {t('paymentType.chooseTitle')}
          </ModalHeader>
          <ModalBody>
            <PaymentTypeContent
              selectedProduct={selectedProduct}
              selectedPaymentOption={selectedPaymentOption}
              setSelectedPaymentOption={this.onPaymentOptionClick}
              checkedTerms={checkedTerms}
              toggleCheckedTerms={this.toggleCheckedTerms}
              fontMode={fontMode}
              t={t}
              handleAppStore={handleAppStore}
              handlePlayStore={handlePlayStore}
            />
          </ModalBody>
          <ModalFooter>
            <Button className="btn notification-footer-button" onClick={this.pay} disabled={!isOnline}>
              {t('common.continue')}
            </Button>
            <hr className="payment-type-line-normal" />
            <p className="payment-type-footnote-text-normal">SIA 'Draxo Games'</p>
            <p className="payment-type-footnote-text-normal">Liepājas 49-6, Kuldīga</p>
            <p className="payment-type-footnote-text-normal">Latvija</p>
          </ModalFooter>
        </Modal>

        <Modal container={'div > div'} isOpen={paymentErrorModal} size="lg" className={`old-modal root-font-${fontMode}`}>
          <ModalHeader
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.togglePaymentErrorModal('')} />
            }
          >
            {t('common.zoleInfo')}
          </ModalHeader>
          <ModalBody className="text-center">
            {paymentError}
          </ModalBody>
          <ModalFooter>
            <Button className="btn notification-footer-button" onClick={() => this.togglePaymentErrorModal('')}>{t('common.ok')}</Button>
          </ModalFooter>
        </Modal>

        {socProvider && socProvider === constants.SOC_PROVIDERS.inbox ? (
          <Modal container={'div > div'} returnFocusAfterClose={false} isOpen={showInboxPayment} size="lg" className={`inbox-payment-modal old-modal root-font-${fontMode}`} toggle={this.closeInboxModal}>
            <ModalHeader
              toggle={this.closeInboxModal}
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeInboxModal} />
              }
            />
            <ModalBody>
              <Iframe
                url={inboxIframeLink}
                width="100%"
                height="600px"
                id="inboxPaymentIframe"
                className="inboxPaymentIframe"
                display="initial"
                position="relative"
              />
            </ModalBody>
          </Modal>
        ) : (null)}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    banEndDate: state.member.banEndDate || null,
    banReason: state.member.banReason || null,
    socProvider: state.member.socProvider,
    discounts: state.shop.discounts,
  };
};

const mapDispatchToProps = {
//  sendSupportMsg: sendSupportMessage,
  initFBPay: initFBPayment,
  fbCallback: fbPaymentCallback,
  initDraugiemPay: initDraugiemPayment,
  initInboxPay: initInboxPayment,
  initStripePay: initStripePayment,
  initEveryPay: initEveryPayPayment,
  fetchDiscounts: getDiscounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Blocked));
