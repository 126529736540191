import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { connect } from 'react-redux';
import classNames from 'classnames';

import { filter, orderBy, map } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
// import Media from 'reactstrap/lib/Media';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';


import Moment from 'react-moment';

import RoomLogs from './RoomLogs';
import Statistics from './MissedRoomsTotals';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import * as constants from '../../../constants/constants';

import {
  getSuspiciousFoldPlayers,
  getGlobalRoomData,
} from '../../../actions/admin';

const SuspiciousFoldPlayers = ({ openRoomModal }) => {

  const { t } = useTranslation('admin');
  const dispatch = useDispatch();
  
  const allSuspiciousFoldPlayers = useSelector(state => state.admin.suspiciousFoldPlayers || []);
  const globalRoomData = useSelector(state => state.admin.globalRoomData || {});

  const [openModal, setOpenModal] = useState(false);
  const [selectId, setSelectId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    dispatch(getSuspiciousFoldPlayers());
  }, []);

  useEffect(() => {
    if (selectId && globalRoomData[selectId]) {
      openRoomModal(globalRoomData[selectId]);
      setSelectId(null);
    }
  }, [selectId, globalRoomData]);

  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const openModalFunc = (item) => {
    dispatch(getGlobalRoomData(item.roomId));
    setSelectId(item.roomId);
  };

  // if (selectId && globalRoomData[selectId]) {
  //   openRoomModal(globalRoomData[selectId]);
  //   setSelectId(null);
  // }

  return (
    <Fragment>
      <table style={{ width: '100%', fontSize: 12, color: '#fff' }}>
        <colgroup>
          <col span="1" className="" />
        </colgroup>
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>
              {/* {t('date')} */}
              Date
            </th>
            <th style={{ textAlign: 'center' }}>
              {/* {t('playerId')} */}
              Player Id
            </th>
            <th style={{ textAlign: 'center' }}>
              {/* {t('name')} */}
              Name
            </th>
            <th style={{ textAlign: 'center' }}>
              {/* {t('roomId')} */}
              Room Id
            </th>
            <th style={{ textAlign: 'center' }}>
              {/* {t('count')} */}
              Count
            </th>
            <th style={{ textAlign: 'center' }}>
              {/* {t('view')} */}
              View
            </th>
          </tr>
        </thead>
        <tbody>
          {allSuspiciousFoldPlayers && map(allSuspiciousFoldPlayers, (item, key) => (
            <Fragment key={key}>
              <tr key={key} className="allUsers-table-row">
                <td className="allUsers-table-col">
                  <Moment format="DD-MM-YYYY, HH:mm:ss" locale="lv">
                    {item.dateAdded}
                  </Moment>
                </td>
                <td className="allUsers-table-col">
                  {item.uid}
                </td>
                <th style={{ textAlign: 'center' }}>
                  {item.userName}
                </th>
                <th style={{ textAlign: 'center' }}>
                  {item.roomId}
                </th>
                <td className="allUsers-table-col">
                  {item.count}
                </td>
                <td className="allUsers-table-col">
                  <Button onClick={() => openModalFunc(item)}>
                    {/* {t('view')} */}
                    View
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>

      <Modal container={`div > div`} isOpen={openModal} toggle={openModalFunc} size="lg">
        <ModalHeader toggle={openModalFunc}>
          {/* {t('data')} */}
          Data
        </ModalHeader>
        <ModalBody>
          <table style={{ width: '100%', fontSize: 12, color: '#fff' }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('date')} */}
                  Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('roomId')} */}
                  Room Id
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('bet')} */}
                  Bet
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('minGames')} */}
                  Min Rounds
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('view')} */}
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {
                map(globalRoomData, (item, key) => (
                  <Fragment key={key}>
                    <tr>
                      <td className="allUsers-table-col">
                        <Moment format={constants.FORMAT_DATE_TYPE.fullDateSecond} locale={constants.sendLanguage.lv}>
                          {item.date}
                        </Moment>
                      </td>
                      <td className="allUsers-table-col">
                        {item.roomId}
                      </td>
                      <td className="allUsers-table-col">
                        {item.bet}
                      </td>
                      <td className="allUsers-table-col">
                        {item.minGames}
                      </td>
                      <td className="allUsers-table-col">
                        <Button onClick={() => openRoomModal(item)}>
                          {/* {t('view')} */}
                          View
                        </Button>
                      </td>
                    </tr>
                  </Fragment>
                ))
              }
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={openModalFunc}>Close</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

SuspiciousFoldPlayers.propTypes = {
  member: PropTypes.shape({
    email: PropTypes.string,
  }),
  allSuspiciousFoldPlayers: PropTypes.shape({}),
};

SuspiciousFoldPlayers.defaultProps = {
  member: {},
  allSuspiciousFoldPlayers: [],
};

export default SuspiciousFoldPlayers;
