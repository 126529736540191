import axios from 'axios';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import lvTranslations from '../constants/translations/lv';
import ruTranslations from '../constants/translations/ru';
import enTranslations from '../constants/translations/en';

import {
  FirebaseStorage,
} from '../lib/firebase';

let lng = 'lv';
try {
  if (localStorage && localStorage.getItem('language') && localStorage.getItem('language') !== null && localStorage.getItem('language') !== undefined) { //x
    const userLang = navigator.language || navigator.userLanguage;

    console.log('userLang', userLang);

    lng = localStorage.getItem('language'); //x
  } else {
    const userLang = navigator.language || navigator.userLanguage;

    console.log('userLang', userLang);

    if (userLang === "en-US" || userLang === "en-GB" || userLang === "en") {
      lng = 'lv';
    } else if (userLang === "ru") {
      lng = 'lv';
    } else {
      lng = 'lv';
    }
  }
} catch(err) {
  lng = 'lv';
}

// console.log('lng', lng);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'lv',
    debug: false,
    detection: {
      order: ['localStorage'],

      // keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ['localStorage'],
    },

    interpolation: {
      escapeValue: false,
    },
    lng,
    resources: {
      lv: lvTranslations,
      ru: ruTranslations,
      en: enTranslations,
    },
  });

    //common
    //holiday
    //previousRound
    //achievements
    //game
    //playerInfo
    //chat
    //notifications
    //login
    //help
    //settings
    //updateProfile
    //nativeNotifications
    //bonus
    //admin
    //rulesPage
    //rules
    //tournamentHelp

let devMode = false;

try {
  const { hostname } = window.location;
  if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
    devMode = true;
  }
} catch (err) { }

let lvReference = FirebaseStorage.ref(`/translations`).child(`lv.json`);
let enReference = FirebaseStorage.ref(`/translations`).child(`en.json`);
let ruReference = FirebaseStorage.ref(`/translations`).child(`ru.json`);

if (devMode) {
  console.log('get dev translations');

  lvReference = FirebaseStorage.ref(`/translationsDev`).child(`lv.json`);
  enReference = FirebaseStorage.ref(`/translationsDev`).child(`en.json`);
  ruReference = FirebaseStorage.ref(`/translationsDev`).child(`ru.json`);
}

lvReference.getDownloadURL().then((url) => {
  axios.get(url).then((response) => {
    let translationsDb = response.data;

    console.log('translationsDb', translationsDb);

    if (translationsDb.common) { i18n.addResourceBundle('lv', 'common', translationsDb.common); }
    if (translationsDb.holiday) { i18n.addResourceBundle('lv', 'holiday', translationsDb.holiday); }
    if (translationsDb.previousRound) { i18n.addResourceBundle('lv', 'previousRound', translationsDb.previousRound); }
    if (translationsDb.achievements) { i18n.addResourceBundle('lv', 'achievements', translationsDb.achievements); }
    if (translationsDb.game) { i18n.addResourceBundle('lv', 'game', translationsDb.game); }
    if (translationsDb.playerInfo) { i18n.addResourceBundle('lv', 'playerInfo', translationsDb.playerInfo); }
    if (translationsDb.chat) { i18n.addResourceBundle('lv', 'chat', translationsDb.chat); }
    if (translationsDb.notifications) { i18n.addResourceBundle('lv', 'notifications', translationsDb.notifications); }
    if (translationsDb.login) { i18n.addResourceBundle('lv', 'login', translationsDb.login); }
    if (translationsDb.help) { i18n.addResourceBundle('lv', 'help', translationsDb.help); }
    if (translationsDb.settings) { i18n.addResourceBundle('lv', 'settings', translationsDb.settings); }
    if (translationsDb.updateProfile) { i18n.addResourceBundle('lv', 'updateProfile', translationsDb.updateProfile); }
    if (translationsDb.nativeNotifications) { i18n.addResourceBundle('lv', 'nativeNotifications', translationsDb.nativeNotifications); }
    if (translationsDb.bonus) { i18n.addResourceBundle('lv', 'bonus', translationsDb.bonus); }
    if (translationsDb.admin) { i18n.addResourceBundle('lv', 'admin', translationsDb.admin); }
    if (translationsDb.rulesPage) { i18n.addResourceBundle('lv', 'rulesPage', translationsDb.rulesPage); }
    if (translationsDb.rules) { i18n.addResourceBundle('lv', 'rules', translationsDb.rules); }
    if (translationsDb.tournamentHelp) { i18n.addResourceBundle('lv', 'tournamentHelp', translationsDb.tournamentHelp); }
  });
});

enReference.getDownloadURL().then((url) => {
  axios.get(url).then((response) => {
    let translationsDb = response.data;

    console.log('translationsDb', translationsDb);

    if (translationsDb.common) { i18n.addResourceBundle('en', 'common', translationsDb.common); }
    if (translationsDb.holiday) { i18n.addResourceBundle('en', 'holiday', translationsDb.holiday); }
    if (translationsDb.previousRound) { i18n.addResourceBundle('en', 'previousRound', translationsDb.previousRound); }
    if (translationsDb.achievements) { i18n.addResourceBundle('en', 'achievements', translationsDb.achievements); }
    if (translationsDb.game) { i18n.addResourceBundle('en', 'game', translationsDb.game); }
    if (translationsDb.playerInfo) { i18n.addResourceBundle('en', 'playerInfo', translationsDb.playerInfo); }
    if (translationsDb.chat) { i18n.addResourceBundle('en', 'chat', translationsDb.chat); }
    if (translationsDb.notifications) { i18n.addResourceBundle('en', 'notifications', translationsDb.notifications); }
    if (translationsDb.login) { i18n.addResourceBundle('en', 'login', translationsDb.login); }
    if (translationsDb.help) { i18n.addResourceBundle('en', 'help', translationsDb.help); }
    if (translationsDb.settings) { i18n.addResourceBundle('en', 'settings', translationsDb.settings); }
    if (translationsDb.updateProfile) { i18n.addResourceBundle('en', 'updateProfile', translationsDb.updateProfile); }
    if (translationsDb.nativeNotifications) { i18n.addResourceBundle('en', 'nativeNotifications', translationsDb.nativeNotifications); }
    if (translationsDb.bonus) { i18n.addResourceBundle('en', 'bonus', translationsDb.bonus); }
    if (translationsDb.admin) { i18n.addResourceBundle('en', 'admin', translationsDb.admin); }
    if (translationsDb.rulesPage) { i18n.addResourceBundle('en', 'rulesPage', translationsDb.rulesPage); }
    if (translationsDb.rules) { i18n.addResourceBundle('en', 'rules', translationsDb.rules); }
    if (translationsDb.tournamentHelp) { i18n.addResourceBundle('en', 'tournamentHelp', translationsDb.tournamentHelp); }
  });
});

ruReference.getDownloadURL().then((url) => {
  axios.get(url).then((response) => {
    let translationsDb = response.data;

    console.log('translationsDb', translationsDb);

    if (translationsDb.common) { i18n.addResourceBundle('ru', 'common', translationsDb.common); }
    if (translationsDb.holiday) { i18n.addResourceBundle('ru', 'holiday', translationsDb.holiday); }
    if (translationsDb.previousRound) { i18n.addResourceBundle('ru', 'previousRound', translationsDb.previousRound); }
    if (translationsDb.achievements) { i18n.addResourceBundle('ru', 'achievements', translationsDb.achievements); }
    if (translationsDb.game) { i18n.addResourceBundle('ru', 'game', translationsDb.game); }
    if (translationsDb.playerInfo) { i18n.addResourceBundle('ru', 'playerInfo', translationsDb.playerInfo); }
    if (translationsDb.chat) { i18n.addResourceBundle('ru', 'chat', translationsDb.chat); }
    if (translationsDb.notifications) { i18n.addResourceBundle('ru', 'notifications', translationsDb.notifications); }
    if (translationsDb.login) { i18n.addResourceBundle('ru', 'login', translationsDb.login); }
    if (translationsDb.help) { i18n.addResourceBundle('ru', 'help', translationsDb.help); }
    if (translationsDb.settings) { i18n.addResourceBundle('ru', 'settings', translationsDb.settings); }
    if (translationsDb.updateProfile) { i18n.addResourceBundle('ru', 'updateProfile', translationsDb.updateProfile); }
    if (translationsDb.nativeNotifications) { i18n.addResourceBundle('ru', 'nativeNotifications', translationsDb.nativeNotifications); }
    if (translationsDb.bonus) { i18n.addResourceBundle('ru', 'bonus', translationsDb.bonus); }
    if (translationsDb.admin) { i18n.addResourceBundle('ru', 'admin', translationsDb.admin); }
    if (translationsDb.rulesPage) { i18n.addResourceBundle('ru', 'rulesPage', translationsDb.rulesPage); }
    if (translationsDb.rules) { i18n.addResourceBundle('ru', 'rules', translationsDb.rules); }
    if (translationsDb.tournamentHelp) { i18n.addResourceBundle('ru', 'tournamentHelp', translationsDb.tournamentHelp); }
  });
});

export default i18n;
