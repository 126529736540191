import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';

const AdminMessage = React.memo(({ tournamentRound, message, t }) => {
  if (message.message || (message.userUid && message.userUid === 'game')) {
    if (message.type === 'win') {
      return t('win', { player: message.data.player, points: message.data.points });
    }
    if (message.type === 'winBezSt') {
      return t('winBezSt', { player: message.data.player });
    }
    if (message.type === 'lose') {
      return t('lose', { player: message.data.player, points: message.data.points });
    }
    if (message.type === 'loseBezSt') {
      return t('loseBezSt', { player: message.data.player });
    }
    if (message.type === 'winZole') {
      return t('winZole', { player: message.data.player, points: message.data.points });
    }
    if (message.type === 'winZoleBezSt') {
      return t('winZoleBezSt', { player: message.data.player });
    }
    if (message.type === 'loseZole') {
      return t('loseZole', { player: message.data.player, points: message.data.points });
    }
    if (message.type === 'loseZoleBezSt') {
      return t('loseZoleBezSt', { player: message.data.player });
    }
    if (message.type === 'winMaza') {
      return t('winMaza', { player: message.data.player });
    }
    if (message.type === 'loseMaza') {
      return t('loseMaza', { player: message.data.player });
    }
    if (message.type === 'loseGaldins') {
      return t('loseGaldins', { player: message.data.player, tricks: message.data.tricks, points: message.data.points });
    }
    if (message.type === 'burry') {
      return t('burry');
    }
    if (message.type === 'takeTable') {
      return t('takeTable', { player: message.data });
    }
    if (message.type === 'takeZole') {
      return t('takeZole', { player: message.data });
    }
    if (message.type === 'takeMaza') {
      return t('takeMaza', { player: message.data });
    }
    if (message.type === 'passLastParty') {
      return t('passLastParty', { player: message.data.playerName, lastPlayer: message.data.lastPlayerName });
    }
    if (message.type === 'passGaldins') {
      return t('passGaldins', { player: message.data });
    }
    if (message.type === 'passNew') {
      return t('passNew', { player: message.data });
    }
    if (message.type === 'pass') {
      return t('pass', { player: message.data });
    }
    if (message.type === 'lastRound') {
      return t('lastRound', { player: message.data });
    }
    if (message.type === 'lastRoundForTournament') {
      return t('lastRoundForTournament', { player: message.data });
    }
    if (message.type === 'lastRoundTournament') {
      if (tournamentRound === 'last') {
        return t('lastRoundTournamentLast');
      }
      return t('lastRoundTournament', { tournamentRound });
    }
    if (message.type === 'fourthPlayerMissed') {
      return t('fourthPlayerMissed');
    }
    if (message.type === 'largePlayerQuit') {
      return t('largePlayerQuit');
    }
  }
  return null;
});

AdminMessage.propTypes = {
  message: PropTypes.shape(),
  activeTournamentData: PropTypes.shape(),
  t: PropTypes.func.isRequired,
};

AdminMessage.defaultProps = {
  message: {},
};

const mapStateToProps = (state) => {
  if (state.game) {
    return {
      tournamentRound: state.game.globalParams.tournamentRound,
    }
  }
  return {};
};


export default compose(
  withTranslation('chat'),
  connect(mapStateToProps),
)(AdminMessage);
