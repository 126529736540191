import React from 'react';
import PropTypes from 'prop-types';

const PointsCounting = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>Zemāk ir uzskaitīta visa loģika, kā tiek skaitīti mazie punkti partijas beigās, kad visas kārtis ir izspēlētas:</p>

        <table>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td rowspan="2">
            <p>Spēles veids</p>
            </td>
            <td rowspan="2">
            <p>Lielā spēlētāja rezultāts partijas beigās</p>
            </td>
            <td rowspan="2">
            <p>Mazo punktu izmaiņa lielajam, ja ir 3 spēlētāju galds</p>
            </td>
            <td rowspan="2">
            <p>Mazo punktu izmaiņa lielajam, ja ir 4 spēlētāju galds</p>
            </td>
            <td rowspan="2">
            <p>Mazo punktu izmaiņa katram no mazajiem</p>
            </td>
          </tr>
          <tr>
          </tr>
          <tr>
            <td rowspan="6">
            <p>Parastā partija (ne zole)</p>
            </td>
            <td>
            <p>0 punkti, neviena stiķa</p>
            </td>
            <td>
            <p>-8</p>
            </td>
            <td>
            <p>-12</p>
            </td>
            <td>
            <p>+4</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>0-30 punkti</p>
            </td>
            <td>
            <p>-6</p>
            </td>
            <td>
            <p>-9</p>
            </td>
            <td>
            <p>+3</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>31-60 punkti</p>
            </td>
            <td>
            <p>-4</p>
            </td>
            <td>
            <p>-6</p>
            </td>
            <td>
            <p>+2</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>61-90 punkti</p>
            </td>
            <td>
            <p>+2*</p>
            </td>
            <td>
            <p>+3*</p>
            </td>
            <td>
            <p>-1*</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>91-120 punkti</p>
            </td>
            <td>
            <p>+4*</p>
            </td>
            <td>
            <p>+6*</p>
            </td>
            <td>
            <p>-2*</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>120 punkti, visi stiķi</p>
            </td>
            <td>
            <p>+6*</p>
            </td>
            <td>
            <p>+9*</p>
            </td>
            <td>
            <p>-3*</p>
            </td>
          </tr>
          <tr>
            <td colspan="5">
            <p>* Ja eksistē kopējā pule, tad lielais iegūst pa vienam papildus punktam no katra no mazajiem.</p>
            </td>
          </tr>
          <tr>
            <td rowspan="6">
            <p>Zole</p>
            </td>
            <td>
            <p>0 punkti, neviena stiķa</p>
            </td>
            <td>
            <p>-16</p>
            </td>
            <td>
            <p>-24</p>
            </td>
            <td>
            <p>+8</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>0-30 punkti</p>
            </td>
            <td>
            <p>-14</p>
            </td>
            <td>
            <p>-21</p>
            </td>
            <td>
            <p>+7</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>31-60 punkti</p>
            </td>
            <td>
            <p>-12</p>
            </td>
            <td>
            <p>-18</p>
            </td>
            <td>
            <p>+6</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>61-90 punkti</p>
            </td>
            <td>
            <p>+10</p>
            </td>
            <td>
            <p>+15</p>
            </td>
            <td>
            <p>-5</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>91-120 punkti</p>
            </td>
            <td>
            <p>+12</p>
            </td>
            <td>
            <p>+18</p>
            </td>
            <td>
            <p>-6</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>120 punkti, visi stiķi</p>
            </td>
            <td>
            <p>+14</p>
            </td>
            <td>
            <p>+21</p>
            </td>
            <td>
            <p>-7</p>
            </td>
          </tr>
          <tr>
            <td rowspan="2">
            <p>Mazā zole</p>
            </td>
            <td>
            <p>Lielais nepaņem nevienu stiķi (uzvar)</p>
            </td>
            <td>
            <p>+12</p>
            </td>
            <td>
            <p>+18</p>
            </td>
            <td>
            <p>-6</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>Lielais paņem stiķi (zaudē)</p>
            </td>
            <td>
            <p>-14</p>
            </td>
            <td>
            <p>-21</p>
            </td>
            <td>
            <p>+7</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>Galdiņš</p>
            </td>
            <td>
            <p>viens no spēlētājiem zaudē kā lielais</p>
            </td>
            <td>
            <p>-4</p>
            </td>
            <td>
            <p>-6</p>
            </td>
            <td>
            <p>+2</p>
            </td>
          </tr>
        </tbody>
        </table>

        <p>Gadījums, ja lielais uzvar un izņem cita spēlētāja personīgo puli:</p>

        <table>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td colspan="2">&nbsp;</td>
            <td colspan="2">
            <p>Mazo punktu izmaiņa lielajam</p>
            </td>
            <td>
            <p>Mazo punktu izmaiņa mazajam bez pules</p>
            </td>
            <td colspan="2">
            <p>Mazo punktu izmaiņa mazajam, kura puli izņēma</p>
            </td>
          </tr>
          <tr>
            <td rowspan="2">
            <p>Spēles veids</p>
            </td>
            <td rowspan="2">
            <p>Lielā spēlētāja rezultāts partijas beigās</p>
            </td>
            <td rowspan="2">
            <p>3 spēlētāju galds</p>
            </td>
            <td rowspan="2">
            <p>4 spēlētāju galds</p>
            </td>
            <td rowspan="2">
            <p>3/4 spēlētāju galds</p>
            </td>
            <td rowspan="2">
            <p>3 spēlētāju galds</p>

            <p>(personīgā pule vērta 3 punktus)</p>
            </td>
            <td rowspan="2">
            <p>4 spēlētāju galds (personīgā pule vērta 4 punktus)</p>
            </td>
          </tr>
          <tr>
          </tr>
          <tr>
            <td rowspan="3">
            <p>Parastā partija (eksistē tikai cita spēlētāja personīgā pule)</p>
            </td>
            <td>
            <p>61-90 punkti</p>
            </td>
            <td>
            <p>+5</p>
            </td>
            <td>
            <p>+7</p>
            </td>
            <td>
            <p>-1</p>
            </td>
            <td>
            <p>-4</p>
            </td>
            <td>
            <p>-5</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>91-120 punkti</p>
            </td>
            <td>
            <p>+7</p>
            </td>
            <td>
            <p>+10</p>
            </td>
            <td>
            <p>-2</p>
            </td>
            <td>
            <p>-5</p>
            </td>
            <td>
            <p>-6</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>120 punkti, visi stiķi</p>
            </td>
            <td>
            <p>+9</p>
            </td>
            <td>
            <p>+13</p>
            </td>
            <td>
            <p>-3</p>
            </td>
            <td>
            <p>-6</p>
            </td>
            <td>
            <p>-7</p>
            </td>
          </tr>
        </tbody>
        </table>

        <p>
        Gadījumā, ja istabas beigšanās brīdī spēles protokolā vēl ir palikušas kāda spēlētāja personīgās pules, tad tās tiek 'norakstītas' - par katru personīgo puli tās īpašnieks atdod pārējiem spēlētājiem pa vienam punktam.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>Далее показана логическая цепочка подсчета малых очков в конце партии, когда все карты разыграны:</p>

        <table>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td rowspan="2">
            <p>Вид игры</p>
            </td>
            <td rowspan="2">
            <p>Результат «большого» игрока в конце партии</p>
            </td>
            <td rowspan="2">
            <p>Очки «маленьких» «большому», если за столом 3 игрока</p>
            </td>
            <td rowspan="2">
            <p>Очки «маленьких»«большому», если за столом 4 игрока</p>
            </td>
            <td rowspan="2">
            <p>Очки «маленьких»  каждому из «маленьких»</p>
            </td>
          </tr>
          <tr>
          </tr>
          <tr>
            <td rowspan="6">
            <p>Обычная партия (не золе)</p>
            </td>
            <td>
            <p>0 очками, ни одной взятки</p>
            </td>
            <td>
            <p>-8</p>
            </td>
            <td>
            <p>-12</p>
            </td>
            <td>
            <p>+4</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>0-30 пункта</p>
            </td>
            <td>
            <p>-6</p>
            </td>
            <td>
            <p>-9</p>
            </td>
            <td>
            <p>+3</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>31-60 пункта</p>
            </td>
            <td>
            <p>-4</p>
            </td>
            <td>
            <p>-6</p>
            </td>
            <td>
            <p>+2</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>61-90 очками</p>
            </td>
            <td>
            <p>+2*</p>
            </td>
            <td>
            <p>+3*</p>
            </td>
            <td>
            <p>-1*</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>91-120 очками</p>
            </td>
            <td>
            <p>+4*</p>
            </td>
            <td>
            <p>+6*</p>
            </td>
            <td>
            <p>-2*</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>120 очками, все взятки</p>
            </td>
            <td>
            <p>+6*</p>
            </td>
            <td>
            <p>+9*</p>
            </td>
            <td>
            <p>-3*</p>
            </td>
          </tr>
          <tr>
            <td colspan="5">
            <p>* Если существует общая пуля», то «большой» получает по одному дополнительному пункту от каждого «маленького».</p>
            </td>
          </tr>
          <tr>
            <td rowspan="6">
            <p>Золе</p>
            </td>
            <td>
            <p>0 очками, ни одной взятки</p>
            </td>
            <td>
            <p>-16</p>
            </td>
            <td>
            <p>-24</p>
            </td>
            <td>
            <p>+8</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>0-30 очками</p>
            </td>
            <td>
            <p>-14</p>
            </td>
            <td>
            <p>-21</p>
            </td>
            <td>
            <p>+7</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>31-60 пункта</p>
            </td>
            <td>
            <p>-12</p>
            </td>
            <td>
            <p>-18</p>
            </td>
            <td>
            <p>+6</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>61-90 очками</p>
            </td>
            <td>
            <p>+10</p>
            </td>
            <td>
            <p>+15</p>
            </td>
            <td>
            <p>-5</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>91-120 очками</p>
            </td>
            <td>
            <p>+12</p>
            </td>
            <td>
            <p>+18</p>
            </td>
            <td>
            <p>-6</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>120 очками,все взятки</p>
            </td>
            <td>
            <p>+14</p>
            </td>
            <td>
            <p>+21</p>
            </td>
            <td>
            <p>-7</p>
            </td>
          </tr>
          <tr>
            <td rowspan="2">
            <p>Малая Золе</p>
            </td>
            <td>
            <p>“Большой» не взял ни одной взятки (победил)</p>
            </td>
            <td>
            <p>+12</p>
            </td>
            <td>
            <p>+18</p>
            </td>
            <td>
            <p>-6</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>“Большой» берет взятку (проигрывает)</p>
            </td>
            <td>
            <p>-14</p>
            </td>
            <td>
            <p>-21</p>
            </td>
            <td>
            <p>+7</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>Стол</p>
            </td>
            <td>
            <p>Один из игроков проигрывает как «большой»</p>
            </td>
            <td>
            <p>-4</p>
            </td>
            <td>
            <p>-6</p>
            </td>
            <td>
            <p>+2</p>
            </td>
          </tr>
        </tbody>
        </table>

        <p>Случай, если «большой» побеждает, и выбирает общую пулю другого игрока:</p>

        <table>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td colspan="2">&nbsp;</td>
            <td colspan="2">
            <p>Очки «маленьких» «большому»</p>
            </td>
            <td>
            <p>Очки «маленьких» «маленькому» без пули</p>
            </td>
            <td colspan="2">
            <p>Очки «маленьких» «маленькому» у кого забрали пулю</p>
            </td>
          </tr>
          <tr>
            <td rowspan="2">
            <p>Вид игры</p>
            </td>
            <td rowspan="2">
            <p>Результат «большого» игрока в конце партии</p>
            </td>
            <td rowspan="2">
            <p>3 игрока за столом</p>
            </td>
            <td rowspan="2">
            <p>4 игрока за столом</p>
            </td>
            <td rowspan="2">
            <p>3/4 игроков за столом</p>
            </td>
            <td rowspan="2">
            <p>3 игрока за столом</p>
            <p>(персональная пуля 3 пункта)</p>
            </td>
            <td rowspan="2">
            <p>4 игрока за столом (персональная пуля 4 пункта)</p>
            </td>
          </tr>
          <tr>
          </tr>
          <tr>
            <td rowspan="3">
            <p>Обычная партия (существует только персональная пуля другого игрока)</p>
            </td>
            <td>
            <p>61-90 очками</p>
            </td>
            <td>
            <p>+5</p>
            </td>
            <td>
            <p>+7</p>
            </td>
            <td>
            <p>-1</p>
            </td>
            <td>
            <p>-4</p>
            </td>
            <td>
            <p>-5</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>91-120 очками</p>
            </td>
            <td>
            <p>+7</p>
            </td>
            <td>
            <p>+10</p>
            </td>
            <td>
            <p>-2</p>
            </td>
            <td>
            <p>-5</p>
            </td>
            <td>
            <p>-6</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>120 очками, все взятки</p>
            </td>
            <td>
            <p>+9</p>
            </td>
            <td>
            <p>+13</p>
            </td>
            <td>
            <p>-3</p>
            </td>
            <td>
            <p>-6</p>
            </td>
            <td>
            <p>-7</p>
            </td>
          </tr>
        </tbody>
        </table>

        <p>
В случае, если на момент окончания комнаты в протоколе игры еще остались персональные пули какого-то игрока, они «списываются» - за каждую персональную пулю ее владелец отдает остальным игрокам по одному очку.        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>Below is all the logic of how the small points are counted at the end of the game when all the cards are played:</p>

        <table>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td rowspan="2">
            <p>Type of game</p>
            </td>
            <td rowspan="2">
            <p>The result of the “Big” player at the end of the game</p>
            </td>
            <td rowspan="2">
            <p>Change of small points for the "Big" player if there is a 3-player table</p>
            </td>
            <td rowspan="2">
            <p>Change of small points for the "Big" player if there is a 4-player table</p>
            </td>
            <td rowspan="2">
            <p>Change of small points for the each of the “Small” players</p>
            </td>
          </tr>
          <tr>
          </tr>
          <tr>
            <td rowspan="6">
            <p>Regular game (not “Zole”)</p>
            </td>
            <td>
            <p>0 points, no “Sticks”</p>
            </td>
            <td>
            <p>-8</p>
            </td>
            <td>
            <p>-12</p>
            </td>
            <td>
            <p>+4</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>0-30 points</p>
            </td>
            <td>
            <p>-6</p>
            </td>
            <td>
            <p>-9</p>
            </td>
            <td>
            <p>+3</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>31-60 points</p>
            </td>
            <td>
            <p>-4</p>
            </td>
            <td>
            <p>-6</p>
            </td>
            <td>
            <p>+2</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>61-90 points</p>
            </td>
            <td>
            <p>+2*</p>
            </td>
            <td>
            <p>+3*</p>
            </td>
            <td>
            <p>-1*</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>91-120 points</p>
            </td>
            <td>
            <p>+4*</p>
            </td>
            <td>
            <p>+6*</p>
            </td>
            <td>
            <p>-2*</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>120 points or all “Sticks”</p>
            </td>
            <td>
            <p>+6*</p>
            </td>
            <td>
            <p>+9*</p>
            </td>
            <td>
            <p>-3*</p>
            </td>
          </tr>
          <tr>
            <td colspan="5">
            <p>* If there is common “Pule”, than the “Big” player collects one point from each “Small” player.</p>
            </td>
          </tr>
          <tr>
            <td rowspan="6">
            <p>“Zole”</p>
            </td>
            <td>
            <p>0 points, no “Sticks”</p>
            </td>
            <td>
            <p>-16</p>
            </td>
            <td>
            <p>-24</p>
            </td>
            <td>
            <p>+8</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>0-30 points</p>
            </td>
            <td>
            <p>-14</p>
            </td>
            <td>
            <p>-21</p>
            </td>
            <td>
            <p>+7</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>31-60 points</p>
            </td>
            <td>
            <p>-12</p>
            </td>
            <td>
            <p>-18</p>
            </td>
            <td>
            <p>+6</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>61-90 points</p>
            </td>
            <td>
            <p>+10</p>
            </td>
            <td>
            <p>+15</p>
            </td>
            <td>
            <p>-5</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>91-120 points</p>
            </td>
            <td>
            <p>+12</p>
            </td>
            <td>
            <p>+18</p>
            </td>
            <td>
            <p>-6</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>120 points, all “Sticks”</p>
            </td>
            <td>
            <p>+14</p>
            </td>
            <td>
            <p>+21</p>
            </td>
            <td>
            <p>-7</p>
            </td>
          </tr>
          <tr>
            <td rowspan="2">
            <p>“Small Zole”</p>
            </td>
            <td>
            <p>The “Big” player doesn't take a “Stick” (wins)</p>
            </td>
            <td>
            <p>+12</p>
            </td>
            <td>
            <p>+18</p>
            </td>
            <td>
            <p>-6</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>The “Big” player takes a “Stick” (loses)</p>
            </td>
            <td>
            <p>-14</p>
            </td>
            <td>
            <p>-21</p>
            </td>
            <td>
            <p>+7</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>“Table”</p>
            </td>
            <td>
            <p>One of the player loses as the “Big”</p>
            </td>
            <td>
            <p>-4</p>
            </td>
            <td>
            <p>-6</p>
            </td>
            <td>
            <p>+2</p>
            </td>
          </tr>
        </tbody>
        </table>

        <p>In case if the "Big" wins and removes another player's “Pule”:</p>

        <table>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td colspan="2">&nbsp;</td>
            <td colspan="2">
            <p>Change of the small points to the “Big” player</p>
            </td>
            <td>
            <p>Change of the small points to the “Small” player without “Pule”</p>
            </td>
            <td colspan="2">
            <p>Change of the small points to the “Small” player, which “Pule” was taken out</p>
            </td>
          </tr>
          <tr>
            <td rowspan="2">
            <p>Type of the game</p>
            </td>
            <td rowspan="2">
            <p>The result of the “Big” player at the end of the game </p>
            </td>
            <td rowspan="2">
            <p>3 player table</p>
            </td>
            <td rowspan="2">
            <p>4 player table</p>
            </td>
            <td rowspan="2">
            <p>3/4 player table</p>
            </td>
            <td rowspan="2">
            <p>3 player table(personal “Pule” is worth 3 points)</p>
            </td>
            <td rowspan="2">
            <p>4 player table (personal “Pule” is worth 4 points)</p>
            </td>
          </tr>
          <tr>
          </tr>
          <tr>
            <td rowspan="3">
            <p>Regular game (only other player’s personal “Pule)”</p>
            </td>
            <td>
            <p>61-90 points</p>
            </td>
            <td>
            <p>+5</p>
            </td>
            <td>
            <p>+7</p>
            </td>
            <td>
            <p>-1</p>
            </td>
            <td>
            <p>-4</p>
            </td>
            <td>
            <p>-5</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>91-120 points</p>
            </td>
            <td>
            <p>+7</p>
            </td>
            <td>
            <p>+10</p>
            </td>
            <td>
            <p>-2</p>
            </td>
            <td>
            <p>-5</p>
            </td>
            <td>
            <p>-6</p>
            </td>
          </tr>
          <tr>
            <td>
            <p>120 points, all “Sticks”</p>
            </td>
            <td>
            <p>+9</p>
            </td>
            <td>
            <p>+13</p>
            </td>
            <td>
            <p>-3</p>
            </td>
            <td>
            <p>-6</p>
            </td>
            <td>
            <p>-7</p>
            </td>
          </tr>
        </tbody>
        </table>

        <p>
          In case if the game time is over, but in the game's protocol players personal “Pule” is still left, they are deleted – for each personal “Pule”, its owner transfers one point for each player.
        </p>
      </>
    )
  }
};

PointsCounting.propTypes = {
  lang: PropTypes.string,
};

PointsCounting.defaultProps = {
  lang: 'lv',
};

export default PointsCounting;
