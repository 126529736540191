import {
  FirebaseStorage, Firebase, FirebaseRef, LeaderboardRef, StatusRef, UserStatsRef, TournamentsRef, AdminLogsRef, RoomsRef,
} from '../lib/firebase';

export function saveRefferalToken(token) {
  console.log('refferalToken', token);
  return dispatch => new Promise((resolve, reject) => {
    if (!token) return reject();

    try {
      localStorage.setItem('refferalToken', token); //x
    } catch (err) {
      console.log(err);
    }

    return resolve(dispatch({
      type: 'SET_REFFERAL_TOKEN',
      data: token,
    }));
  });
}


export function removeRefferalToken() {
  return dispatch => new Promise((resolve) => {
    try {
      localStorage.removeItem('refferalToken'); //x
    } catch (err) {
      console.log(err);
    }

    return resolve(dispatch({
      type: 'REMOVE_REFFERAL_TOKEN',
      data: null,
    }));
  });
}
