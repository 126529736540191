import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Media from 'reactstrap/lib/Media';
import { Button } from 'reactstrap';
import { FONT_MODE } from '../../../constants/constants';
import closeImg from '../../../images/icons/close.png';

const FileModal = React.memo(({
  fileUpload, fileName, modalOpen, toggleModal, fontMode, t, adminPanelOpen,
}) => {
  const downloadFile = (downFileUrl, downFileName) => {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const blob = xhr.response;
      const objectUrl = window.URL.createObjectURL(blob);

      anchor.href = objectUrl;
      anchor.download = downFileName;
      anchor.click();
    };
    xhr.open('GET', downFileUrl);
    xhr.send();
  };

  const [zoom, setZoom] = useState(false);
  
  const toggleZoom = () => {
    console.log('toggleZoom', zoom);
    if (zoom) {
      setZoom(false);
    } else {
      setZoom(true);
    }
  }

  console.log('zoom', zoom);

  return (
    <Modal container={'div > div'} isOpen={modalOpen} toggle={toggleModal} size="lg" className={`old-modal root-font-${fontMode}`}>
      <ModalHeader
        toggle={toggleModal}
        close={(
          <Media src={closeImg} className="notification-header-close" alt="X" onClick={toggleModal} />
        )}
      >
        {fileName}
      </ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column gap-20">
          <div className="d-flex flex-row align-items-end">
            <Button type="button" className="contact-support-modal-footer-button" color="link" onClick={() => downloadFile(fileUpload, fileName)}>{adminPanelOpen ? 'download' : t('support.download')}</Button>
          </div>
          <div style={{ width: 760, height: 600, overflow: 'hidden' }}>
          <Media
            className="frame-object"
            src={fileUpload}
            style={{
              width: 760,
              height: 600,
              objectFit: zoom ? 'cover' : 'contain',
              objectPosition: 'top',
              cursor: zoom ? 'zoom-out' : 'zoom-in',
              // zoom: zoom ? '1.5' : 'normal',
              // transform: zoom ? 'scale(1.5)' : 'scale(1)',
            }}
            onClick={() => toggleZoom()}
            />
          </div>

          {/* <iframe className="frame-object" title="old-file-download-modal-title" id="frame1" src={fileUpload} style={{ width: 760, height: 600, objectFit: 'contain', objectPosition: 'top' }} frameBorder="0" allowFullScreen /> */}
        </div>
      </ModalBody>
    </Modal>
  );
});

FileModal.propTypes = {
  fileUpload: PropTypes.string,
  fileName: PropTypes.string,
  modalOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  fontMode: PropTypes.string,
  t: PropTypes.func.isRequired,
  adminPanelOpen: PropTypes.bool,
};

FileModal.defaultProps = {
  fileUpload: null,
  fileName: null,
  modalOpen: false,
  fontMode: FONT_MODE.normal,
  adminPanelOpen: false,
};

export default withTranslation('common')(FileModal);
