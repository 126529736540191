import React, { useEffect, useMemo, useState } from "react";
import Media from 'reactstrap/lib/Media';
import defaultImage from '../../../../../../images/redesign/common/default_image.svg';
import normalDefaultImage from '../../../../../../images/redesign/normal-mode/common/default_image.webp';
import lightDefaultImage from '../../../../../../images/redesign/light-mode/common/default_image.svg';

import { getUserAvatar } from '../../../../../../actions/leaderboard';
import { SCREEN_MODE } from "../../../../../../constants/constants";

const TopPlayeAvatar = React.memo(({photo, playerInfo, screenMode}) => {

  const [playerAvatar, setPlayerAvatar] = useState(defaultImage);

  useEffect(() => {
    const getData = async () => {
      const userAvatar = await getUserAvatar(playerInfo.key);

      setPlayerAvatar(userAvatar);
    }
    if (photo) {
      setPlayerAvatar(photo);
    } else {
      getData();
    }
    // getData();
  }, [playerInfo]);

 // console.log("playerAvatar", playerAvatar)
  return (
    <Media className={`top-table-row-player-image ${playerAvatar ? '' : 'top-table-row-player-image-default'}`} src={playerAvatar ? playerAvatar : (screenMode === SCREEN_MODE.light ? lightDefaultImage : screenMode === SCREEN_MODE.normal ? normalDefaultImage : defaultImage)} />
  )
}) 

export default TopPlayeAvatar;
