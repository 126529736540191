import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

import { withTranslation } from 'react-i18next';
import LinkifyWithTargetBlank from '../../../UI/LinkifyWithTargetBlank';

// import defaultImage from '../../../../../images/Game/defaultImage.webp';
//import defaultImage from '../../../../../images/Game/defaultImage.webp';
import darkDefaultImage from '../../../../../images/redesign/common/default_image.svg';
import lightDefaultImage from '../../../../../images/redesign/light-mode/common/default_image.webp';
import normalDefaultImage from '../../../../../images/redesign/normal-mode/common/default_image.webp';
import { SCREEN_MODE } from '../../../../../constants/constants';

class FilteredMessage extends React.Component {
    static propTypes = {
      t: PropTypes.func.isRequired,
      uid: PropTypes.string,
      message: PropTypes.shape(),
      screenMode: PropTypes.string,
    };

    static defaultProps = {
      uid: null,
      message: null,
    };

    constructor(props) {
      super(props);
      this.state = {
        popoverOpen: false,
      };
    }

  onHover = (key) => {
    const { popoverOpen } = this.state;
    if (!popoverOpen) {
      this.setState({
        popoverOpen: key,
      });
    }
  }

  onHoverLeave = () => {
    this.setState({
      popoverOpen: false,
    });
  }

  render() {
    const { message, uid, screenMode } = this.props;
    const { popoverOpen } = this.state;

    if (message.filteredMessage && message.message) {
      let splitMessage = [];
      let splitFilteredMessage = [];

      splitMessage = message.message.split(/\n/);

      splitFilteredMessage = message.filteredMessage.split(/\n/);

      const defaultImage = screenMode === SCREEN_MODE.dark ? darkDefaultImage : screenMode === SCREEN_MODE.normal ? normalDefaultImage : lightDefaultImage;

      return (
        <Fragment>
          {(message.userUid && message.userUid.toString() === uid.toString()) ? (
            <Fragment>
              <Row>
                <Col sm="12" className="room-chat-message-col">
                  <LinkifyWithTargetBlank>
                    <div className="room-chat-message-user">
                      {splitFilteredMessage.map((row, index) => {
                        let words = [];
                        if (splitMessage[index]) {
                          words = splitMessage[index].split(' ');
                        }
                        const filteredWords = row.split(' ');

                        return (
                          <div key={index}>
                            {filteredWords.map((word, index2) => (
                              <>
                                {(words && words[index2] !== word) ? (
                                  <>
                                    <div
                                      style={{ display: 'inline-block', marginRight: 2 }}
                                      id={`chat-popover-${message.messageId}-${index2}`}
                                      onMouseEnter={() => this.onHover(`${message.messageId}-${index2}`)}
                                      onMouseLeave={this.onHoverLeave}
                                    >
                                      {`${word} `}
                                    </div>
                                    <Popover container={'div > div'} popperClassName="last-round-popover" placement="top" isOpen={popoverOpen === `${message.messageId}-${index2}`} target={`chat-popover-${message.messageId}-${index2}`}>
                                      <PopoverBody className="last-round-popover-body">
                                        {/* {`${words[index2]}`} */}
                                        {`${message.message}`}
                                      </PopoverBody>
                                    </Popover>
                                  </>
                                ) : (
                                  <>
                                    {`${word} `}
                                  </>
                                )}
                              </>
                            ))}
                          </div>
                        );
                      })}
                    </div>
                  </LinkifyWithTargetBlank>
                </Col>
              </Row>
            </Fragment>
          ) : (
            <Fragment>
              <Row>
                <Col sm="12" className="room-chat-message-col">
                    <LinkifyWithTargetBlank>
                      <div className={`room-chat-message-other room-chat-message-other-${screenMode}`}>
                        <div className="room-chat-message-other-image-frame">
                          <Media
                            className="room-chat-message-other-image"
                            src={message.userPhoto || defaultImage}
                            alt="photo"
                          />
                        </div>
                        {console.log('message.userName', message.userName)}
                        <div className={`room-chat-message-other-text room-chat-message-other-text-${screenMode}`}>
                          <span>
                            {`${(message.userName.split(" "))[0]} ${((message.userName.split(" "))[1]) ? (((message.userName.split(" "))[1])?.charAt(0)) : ''}`}
                          </span>
                          {splitFilteredMessage.map((row, index) => {
                            let words = [];
                            if (splitMessage[index]) {
                              words = splitMessage[index].split(' ');
                            }
                            const filteredWords = row.split(' ');

                            return (
                              <p key={index} style={{ marginBottom: 0, lineHeight: '20px' }}>
                                {filteredWords.map((word, index2) => (
                                  <>
                                    {(words[index2] !== word) ? (
                                      <>
                                        <div
                                          style={{ display: 'inline-block', marginRight: 2 }}
                                          id={`chat-popover-${message.messageId}-${index2}`}
                                          onMouseEnter={() => this.onHover(`${message.messageId}-${index2}`)}
                                          onMouseLeave={this.onHoverLeave}
                                        >
                                          {`${word} `}
                                        </div>
                                        <Popover container={'div > div'} popperClassName="last-round-popover" placement="top" isOpen={popoverOpen === `${message.messageId}-${index2}`} target={`chat-popover-${message.messageId}-${index2}`}>
                                          <PopoverBody className="last-round-popover-body">
                                            {/* {`${words[index2]}`} */}
                                            {`${message.message}`}
                                          </PopoverBody>
                                        </Popover>
                                      </>
                                    ) : (
                                      <>
                                        {`${word} `}
                                      </>
                                    )}
                                  </>
                                ))}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    </LinkifyWithTargetBlank>
                  </Col>
                </Row>
            </Fragment>
          )}
        </Fragment>
      );
    }

    return null;
  }
}

/*
FilteredMessage.propTypes = {
  message: PropTypes.shape(),
  uid: PropTypes.string,
};

FilteredMessage.defaultProps = {
  message: {},
  uid: '',
};  */

export default withTranslation('chat')(FilteredMessage);
