import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { composeInitialProps, useTranslation } from 'react-i18next';

import { useSelector, useReducer, useDispatch, shallowEqual } from 'react-redux';

import Joyride, {
  CallBackProps, STATUS,
} from 'react-joyride';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Media,
} from 'reactstrap';

import { map, filter, size, isEqual, find, includes, } from 'lodash';

import CustomTooltip from './CustomTooltip';
import CustomModal from '../../Components/Components/Modal';

import * as constants from '../../../../../constants/constants';

import closeImg from '../../../../../images/icons/close.png';
import hurmanImg from '../../../../../images/redesign/tutorial/hurman.webp';
import arrowImg from '../../../../../images/redesign/tutorial/arrow.png';
import { claimTutorialBonus } from '../../../../../actions/member';
import { getNewTourStateLoad, startNewTour, stopTour } from '../../../../../actions/tour';

const JoyrideComponent = ({ run, toggle, closeJoyride, tutorialBonus }) => {
  const { t } = useTranslation('common');

  const dispatch = useDispatch();
  const tourState = useSelector(state => state.tour);
  const member = useSelector(state => state.member);
  const [tourRound, setTourRound] = useState([]);
  const [completeTourModal, setCompleteTourModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [firstTourModalOpen, setFirstTourModalOpen] = useState(false);

  useEffect(() => {
    if (run) {
      try {
        if (!localStorage.getItem('joyrideActive')) {
          localStorage.setItem('joyrideActive', 'true');
        }
      } catch (err) {
        console.log(err);
      }
      dispatch(getNewTourStateLoad());
    }

    if (run && !tutorialBonus && !processing) {
      setFirstTourModalOpen(true);
    }

    if (run && tutorialBonus && !processing) {
      setProcessing(true);
    }

    if (run && processing) {
      dispatch(startNewTour());
    }

    if (!run) {
      setFirstTourModalOpen(false);
      setProcessing(false);
      try {
        localStorage.removeItem('joyrideActive');
      } catch (err) {
        console.log(err);
      }
    }
  }, [run, processing]);

  
  useEffect(() => {
    try {
      if (localStorage) {
        return () => {
          localStorage.removeItem('joyrideActive');
        };
      }

      return null;
    } catch (err) {
      return null;
    }
  }, []);

  useEffect(() => {
    if (tourState && run) {
      const pageStep = find(tourState.steps, (o, k) => { return k === 0; });
      if (size(pageStep) > 0) {
        if (pageStep.tab === constants.MENU_NAVIGATION.myInfo || pageStep.tab === constants.MENU_NAVIGATION.tournaments) {
          const pageSubStep = find(tourState.steps, (o, k) => { return k === tourState.stepIndex; });
          console.log(pageSubStep, "check!!");
          toggle(pageSubStep?.tab || pageStep.tab);
        } else {
          toggle(pageStep.tab);
        }
      }
    }
  }, [tourState]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, step, index } = data;

    try {
      if (action && step && member && !member.tutorialBonus) {
        if (localStorage.getItem('allTours')) {
          let allTours = JSON.parse(localStorage.getItem('allTours'));
          const { completeTour } = allTours;
          const newVal = [...completeTour];
          if (!includes(newVal, step.unit)) {
            newVal.push(step.unit);

            allTours = {
              ...allTours,
              completeTour: newVal,
            };
            console.log({ allTours });
            localStorage.removeItem('allTours');
            localStorage.setItem('allTours', JSON.stringify(allTours));
          }
        } else {
          let allTours = {
            completeTour: [],
          };
          const newVal = allTours.completeTour;
          newVal.push(step.unit);
          allTours = {
            ...allTours,
            completeTour: newVal,
          };
          // console.log({allTours}, "empty");
          localStorage.setItem('allTours', JSON.stringify(allTours));
        }
      }
    } catch (err) {
      console.log(err);
    }

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED];

    if (finishedStatuses.includes(status) || action === 'close' || action === 'stop') {
      try {
        if (member && !member.tutorialBonus) {
          // console.log("stop tour", member.tutorialBonus);
          if (localStorage.getItem('allTours') && JSON.parse(localStorage.getItem('allTours')).completeTour) {
            const allTours = JSON.parse(localStorage.getItem('allTours')).completeTour;
            allTours.sort((a, b) => { return a - b; });
            if (isEqual(constants.TOUR_NEW_ALL_STEPS, allTours)) {
              // console.log("You got 3000000 bonus. WWW");
              setCompleteTourModal(true);
              dispatch(claimTutorialBonus());
              localStorage.removeItem('allTours');
            } else {
              localStorage.removeItem('allTours');
            }
          }
        } else if (member && member.tutorialBonus) {
          if (localStorage.getItem('allTours')) {
            localStorage.removeItem('allTours');
          }
        }
      } catch (err) {
        console.log(err);
      }
      // console.log("close tour");
      setProcessing(false);
      closeJoyride();
    }
  };

  const toggleCompleteTourModal = () => {
    setCompleteTourModal(false);
  };

  const closeJoyrideFunc = () => {
    closeJoyride();
    setProcessing(false);
  };

  const closeTour = () => {
    setProcessing(false);
    closeJoyride();
  };

  const continueTutorial = () => {
    setProcessing(true);
    setFirstTourModalOpen(false);
  };

  console.log({tourState}, {run});

  return (
    <>
      {
        tourState && processing && (
          <Joyride
            {...tourState}
            tooltipComponent={CustomTooltip}
            callback={handleJoyrideCallback}
            continuous
            showProgress
            showSkipButton
            scrollToFirstStep
            spotlightClicks
            spotlightPadding={0}
            styles={{
              options: {
                zIndex: 10000,
                primaryColor: 'linear-gradient(180deg,#b4ec51,#429321)',
              },
            }}
          />
        )
      }
      <CustomModal
        isOpen={firstTourModalOpen}
        toggle={closeTour}
        size="md"
        title={t('menu.tutorial')}
        footer={(
          <Button className="modal-footer-button tutorial-result-button mb-3 tutorial-begin-modal-footer-button" color="secondary" onClick={continueTutorial}>
            {t('common.continue')}
          </Button>
        )}
        body={(
          <div className="d-flex align-items-center flex-column">
            <p className="tutorial-result-content">
              {t('bonusPage.gameGideBounsContent')}
            </p>
          </div>
        )}
      />
      <CustomModal
        isOpen={completeTourModal}
        toggle={toggleCompleteTourModal}
        size="md"
        verticalCentering
        largeText
        title={t('menu.tutorial')}
        footer={(
          <Button className="modal-footer-button tutorial-result-button mb-3" color="secondary" onClick={toggleCompleteTourModal}>
            {t('common.ok')}
          </Button>
        )}
        body={(
          <>
            {t('tutorial.earnedReward')}
          </>
        )}
      />
    </>
  );
};

JoyrideComponent.propsTypes = {
  run: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  closeJoyride: PropTypes.func.isRequired,
};

JoyrideComponent.defaultProps = {
  run: false,
};

export default JoyrideComponent;
