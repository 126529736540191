import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Media from 'reactstrap/lib/Media';
// import Button from 'reactstrap/lib/Button';
import * as constants from '../../../../../../constants/constants';

import giftImg from '../../../../../../images/redesign/player/gifts.svg';
import giftImgLight from '../../../../../../images/redesign/light-mode/player/gifts.svg';
import IconPopover from '../../../Components/Components/IconPopover';

const SendGiftButton = React.memo(({
  uid, toggleGiftsModal, screenMode, t
}) => {
  const [giftPopoverOpen, setGiftPopoverOpen] = useState(false);
  return (
    <>
      <Media
        className="player-icon-gift"
        src={screenMode !== constants.SCREEN_MODE.light ? giftImg : giftImgLight}
        alt=""
        onClick={() => toggleGiftsModal(uid)}
        onMouseEnter={() => setGiftPopoverOpen(true)}
        onMouseLeave={() => setGiftPopoverOpen(false)}
        id={`gift-popover-${uid}`}
      >{console.log("screen Mode: ", screenMode)}</Media>
      <IconPopover text={t('common:popovers.sendGift')} placement="right" targetId={`gift-popover-${uid}`} popoverOpen={giftPopoverOpen} />
    </>
  )

});

SendGiftButton.propTypes = {
  uid: PropTypes.string,
  toggleGiftsModal: PropTypes.func.isRequired,
};

SendGiftButton.defaultProps = {
  uid: null,
};

export default SendGiftButton;
