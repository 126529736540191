import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Media from 'reactstrap/lib/Media';
// import CustomInput from 'reactstrap/lib/CustomInput';

import ReactGA from 'react-ga';

import {
  toggleSound,
} from '../../../../../actions/userSettings';

import soundOnIcon from '../../../../../images/redesign/top-row/volumeOn.svg';
import soundOffIcon from '../../../../../images/redesign/top-row/volumeOff.svg';
import sound from '../../../../../sounds/click_feedback.flac';

import lightSoundOnIcon from '../../../../../images/redesign/light-mode/icons/volumeOn.png';
import lightSoundOffIcon from '../../../../../images/redesign/light-mode/icons/volumeOff.svg';

import * as constants from '../../../../../constants/constants';
import IconPopover from '../Components/IconPopover';

class SoundButton extends Component {
  static propTypes = {
    userSettings: PropTypes.shape().isRequired,
    changeSound: PropTypes.func.isRequired,
    inRoom: PropTypes.bool,
    screenMode: PropTypes.string,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    match: null,
    history: {},
    inRoom: false,
    screenMode: constants.SCREEN_MODE.normal,
  }

  constructor(props) {
    super(props);

    this.state = {
      isInbox: false,
      soundPopoverOpen: false,
    };

    this.audio = new Audio(sound);
  }

  componentDidMount() {
    if (window && window.location) {
      const { hostname } = window.location;

      if (hostname === 'inbox.spelezoli.lv' || hostname === 'inbox.dev.spelezoli.lv') {
        this.setState({ isInbox: true });
      }
    }
  }

  toggleSound = () => {
    const { changeSound, uid, userSettings } = this.props;
    if (userSettings[uid]) {
      if (!userSettings[uid].soundOn) {
        const playPromise = this.audio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
    }

    ReactGA.event({
      category: 'Sound',
      action: 'Change sound',
    });

    changeSound(uid);
  }

  setSoundPopoverOpen = (value) => {
    this.setState({ soundPopoverOpen: value });
  }

  render = () => {
    const {
      userSettings,
      uid,
      inRoom,
      screenMode,
      t,
    } = this.props;
    const { isInbox, soundPopoverOpen } = this.state;

    if (!uid || !userSettings) {
      return null;
    }

    let soundOn = false;
    if (userSettings[uid]) {
      if (userSettings[uid].soundOn) {
        soundOn = true;
      } else {
        soundOn = false;
      }
    } else if (userSettings.default) {
      if (userSettings.default.soundOn) {
        soundOn = true;
      } else {
        soundOn = false;
      }
    }
    // 'sound-button-inbox' : null} ${inRoom ? "sound-button-room" : "sound-button"}
    return (
      <>
        <div className={`${isInbox ? 'sound-button-inbox' : null} ${inRoom ? 'sound-button-room' : 'sound-button'}`} onMouseEnter={() => this.setSoundPopoverOpen(true)} onMouseLeave={() => this.setSoundPopoverOpen(false)} id="sound-button-popover">
          <Media src={soundOn ? (screenMode === constants.SCREEN_MODE.light ? lightSoundOnIcon : soundOnIcon) : (screenMode === constants.SCREEN_MODE.light ? lightSoundOffIcon : soundOffIcon)} className="sound-button-image" onClick={this.toggleSound} />
        </div>
        <IconPopover text={t(soundOn ? 'common:popovers.soundSettingOn' : 'common:popovers.soundSettingOff')} placement="bottom" targetId="sound-button-popover" popoverOpen={soundPopoverOpen} />
      </>
    );
  }
}

const mapStateToProps = state => ({
  userSettings: state.userSettings || {},
});

const mapDispatchToProps = {
  changeSound: toggleSound,
};

export default connect(mapStateToProps, mapDispatchToProps)(SoundButton);

