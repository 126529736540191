import React from 'react';
import PropTypes from 'prop-types';

const GameCoinsAndPoints = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Veidojot jaunu istabu, spēlētājs var noteikt spēles punktu un Zoles monētu attiecību.
        </p>
        <p>Piemērs: </p>
        <ul>
          <li>
          ja istabas likme ir 1:25, tas nozīmē 1 punkts = 25 monētas jeb par katru iegūto spēles punktu spēlētājs saņems 25 Zoles monētas;
          </li>
          <li>
          ja istabas likme ir 1:10, un spēle beidzas ar rezultātu Jānis +5, Andris -2 un Kārlis -3, tas nozīmē, ka Jānis iegūs 50 Zoles monētas, savukārt Jānis zaudēs 20 Zoles monētas un Kārlis zaudēs 30 Zoles monētas.
          </li>
        </ul>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
        Создавая новую комнату, игрок может установить соотношение игровых очков и монет Золе.
        </p>
        <p>Пример: </p>
        <ul>
          <li>
            если ставка комнаты 1:20, это означает, что 1 пункт = 20 монетам или за каждое потерянное очко игрок расплатится 20 монетами;
          </li>
          <li>
            если ставка комнаты 1:10, и игра кончается результатом Янис +5, Андрис -2 и Карлис -3, это означает, что Янис получит 50 монет Золе,а Андрис потеряет 20 монет Золе и Карлис потеряет 30 монет Золе.
          </li>
        </ul>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
        When creating a new room, player can determine the ratio of game points to “Zole ’coins.
        </p>
        <p>For example: </p>
        <ul>
          <li>
            if the room bet is 1:20, it means 1 point=20 coin, or for each point lost in the game, the player will lose 20 "Zole" coins.
          </li>
          <li>
            if the room bet is 1:10, and the game ends with a result Janis +5, Andris -2, and Karlis -3, this means that Janis will have 50 “Zole” coins, Andris will lose 20 and Karlis will lose 30 “Zole” coins.
          </li>
        </ul>
      </>
    )
  }
};

GameCoinsAndPoints.propTypes = {
  lang: PropTypes.string,
};

GameCoinsAndPoints.defaultProps = {
  lang: 'lv',
};

export default GameCoinsAndPoints;
