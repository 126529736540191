import React from 'react';
import PropTypes from 'prop-types';

import CustomDate from '../UI/CustomDate';

class Clock extends React.Component {
  static propTypes = {
    offset: PropTypes.number,
  };

  static defaultProps = {
    offset: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      date: null,
    };
  }

  componentDidMount() {
    this.intervalID = setInterval(() => {
      this.setState({
        date: Date.now(),
      });
    }, 1000);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { date } = this.state;
    const nextDate = nextState.date;

    if (date && nextDate && date !== nextDate) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const { offset } = this.props;
    const {
      date,
    } = this.state;

    if (!date) {
      return null;
    }

    return (
      <div className="game-clock">
        <CustomDate format="DD-MM-YYYY hh:mm:ss" date={offset ? (date + offset) : date} />
      </div>
    );
  }
}

export default Clock;
