import React from 'react';
// import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Splide, SplideSlide } from '@splidejs/react-splide';

// import { Col, Row, Button, Label, NavLink } from 'reactstrap';

import slideBanner1Img from '../../../../../images/redesign/landing-view/slider_1.webp';
import slideBanner2Img from '../../../../../images/redesign/landing-view/slider_2.webp';
import slideBanner3Img from '../../../../../images/redesign/landing-view/slider_3.webp';
import slideBanner4Img from '../../../../../images/redesign/landing-view/slider_4.webp';
import slideBanner5Img from '../../../../../images/redesign/landing-view/slider_5.webp';

const HomeGameSlide = () => {
  const { t } = useTranslation('common');

  // const slideBannerWidth = 360;
  // const slideBannerHeight = 340;

  return (
    <Splide
      options={{
        type: 'loop',
        perPage: 3,
        rewind: true,
        //  gap: '20',
        // width: (slideBannerWidth * 6),
        // height: slideBannerHeight + 50,
        // pauseOnHover: false,
        // isNavigation: false,
        // gap: 30,
        focus: 'center',
        pagination: true,
        // cover       : true,
        breakpoints: {
          991: {
            perPage: 3,
          },
          574: {
            perPage: 1,
          },
        },
        arrows: true,
        autoplay: true,
        trimSpace: false,
      }}
    >
      <SplideSlide>
        <div className="home-game-slide">
          <img className="slide-img" src={slideBanner1Img} alt="" />
          <div className="splide-img-dg" />
        </div>
      </SplideSlide>
      <SplideSlide>
        <div className="home-game-slide">
          <img className="slide-img" src={slideBanner2Img} alt="" />
          <div className="splide-img-dg" />
        </div>
      </SplideSlide>
      <SplideSlide>
        <div className="home-game-slide">
          <img className="slide-img" src={slideBanner3Img} alt="" />
          <div className="splide-img-dg" />
        </div>
      </SplideSlide>
      <SplideSlide>
        <div className="home-game-slide">
          <img className="slide-img" src={slideBanner4Img} alt="" />
          <div className="splide-img-dg" />
        </div>
      </SplideSlide>
      <SplideSlide>
        <div className="home-game-slide">
          <img className="slide-img" src={slideBanner5Img} alt="" />
          <div className="splide-img-dg" />
        </div>
      </SplideSlide>
    </Splide>
  );
};

export default HomeGameSlide;
