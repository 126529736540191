import React from 'react';
import PropTypes from 'prop-types';

const StoreCoins = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Zoles veikalā var iegādāties dažādas Zoles monētu pakas. Jo lielāka ir paka, jo izdevīgāka cena.
        </p>
        <p>Pašaik veikalā ir pieejamas šādas spēles monētu pakas:</p>
        <ul>
          <li>250 Zoles monētas (EUR 0.85)</li>
          <li>500 Zoles monētas (EUR 1.41)</li>
          <li>1 500 Zoles monētas (EUR 2.85)</li>
          <li>4 000 Zoles monētas (EUR 4.27)</li>
          <li>15 000 Zoles monētas (EUR 14.23)</li>
        </ul>
        <p>
        Atceries: parasti spēles nauda tiek pieskaitīta uzreiz pēc iegādes, tomēr atsevišķās platformās, atkarībā no maksājuma metodes, tas var notikt nedaudz ilgāk. Gadījumā, ja spēles nauda netiek pieskaitīta 24 stundu laikā, lūgums sazināties ar spēles administrāciju čatā.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
        В магазине Золе можно приобрести различные пакеты с монетами Золе. Чем больше пакет, тем выгоднее цена.
        </p>
        <p>На данный момент в магазине есть в наличии следующие пакеты игровых монет:</p>
        <ul>
          <li>250 монет Золе ( 0.85 евро)</li>
          <li>500 монет Золе ( 1.41 евро)</li>
          <li>1 500 монет Золе (2.85 евро)</li>
          <li>4 000 монет Золе (4.27 евро)</li>
          <li>15 000 монет Золе (14.23 евро)</li>
        </ul>
        <p>
        Помни: обычно игровые деньги перечисляются сразу после приобретения, но на отдельных платформах, в зависимости от метода платежа, это может потребовать немного больше времени.В случае, если игровые деньги не перечислены в течении 24 часов, просьба связаться с администрацией игры в чате.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
        Players can buy different packages of "Zole" coins in the store. The bigger the package, the better the price.
        </p>
        <p>Currently, the following coin packs can be purchased in the store:</p>
        <ul>
          <li>250 “Zole” coins (EUR 0.85)</li>
          <li>500 “Zole” coins (EUR 1.41)</li>
          <li>1 500 “Zole” coins (EUR 2.85)</li>
          <li>4 000 “Zole” coins (EUR 4.27)</li>
          <li>15 000 “Zole” coins (EUR 14.23)</li>
        </ul>
        <p>
          NB! Game money is usually added immediately after purchase, however on some platforms, depending on the payment method, it may take a while. In case the game cash is not added within 24 hours, please contact the game administration in the chat.
        </p>
      </>
    )
  }
};

StoreCoins.propTypes = {
  lang: PropTypes.string,
};

StoreCoins.defaultProps = {
  lang: 'lv',
};

export default StoreCoins;
