import React from 'react';
import PropTypes from 'prop-types';

const CardsValue = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Acis tiek skaitītas šādi:
        </p>
        <ul>
          <li>dūzis - 11</li>
          <li>desmitnieks - 10</li>
          <li>kungs - 4</li>
          <li>dāma - 3</li>
          <li>kalps - 2</li>
          <li>devītnieks, astotnieks un septītnieks - 0</li>
        </ul>

        <p>Kāršu vērtības, ja stiķis tiek paņemts savā īpašumā:</p>
        <ul>
          <li>Dūzis - 11</li>
          <li>Desmitnieks - 10</li>
          <li>Kungs - 4</li>
          <li>Dāma - 3</li>
          <li>Kalps - 2</li>
          <li>Devītnieks - 0</li>
          <li>Astotnieks - 0</li>
          <li>Septītnieks - 0</li>
        </ul>

        <p>Parastās spēles mērķis ir savākt pēc iespējas vairāk punktu, uzvarot stiķus.</p>

        <p>
        Spēlētāju skaits pie viena galda var būt trīs vai četri, bet aktīvi spēlē tieši trīs (ja ir ceturtais spēlētājs, tad viņš tiek vienmēr pieskaitīts ‘mazajiem’, un gaida savu kārtu).
        </p>
        <p>Partija vienmēr notiek vienam spēlētājam (lielajam) spēlējot pret abiem pārējiem (mazajiem).</p>

      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          Очки считаются следующим образом:
        </p>
        <ul>
          <li>тузы  - 11</li>
          <li>десятки  - 10</li>
          <li>короли  - 4</li>
          <li>дамы  - 3</li>
          <li>валеты  - 2</li>
          <li>евятка, восьмерка и семерка - 0</li>
        </ul>

        <p>Достоинства карт, если берется взятка:</p>
        <ul>
          <li>туз - 11</li>
          <li>десятка - 10</li>
          <li>король - 4</li>
          <li>дамa - 3</li>
          <li>валет - 2</li>
          <li>девятка - 0</li>
          <li>восьмерка - 0</li>
          <li>семерка - 0</li>
        </ul>

        <p>Цель обычной игры в том, чтобы набрать как можно больше очков на взятках.</p>

        <p>
        За столом может быть три или четыре игрока, но активно играют трое (если есть четвертый игрок, он считается «маленьким» и ждет своей очереди).
        </p>
        <p>Один игрок (большой) играет против остальных двух участников (маленьких).</p>

      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
        The “Eyes” (Acis, lav.) are counted as follows:
        </p>
        <ul>
          <li>Ace - 11</li>
          <li>Ten - 10</li>
          <li>King - 4</li>
          <li>Queen - 3</li>
          <li>Jack - 2</li>
          <li>All other cards have zero value.</li>
        </ul>

        <p>Value of the cards, if the “Sticks” (Stiķi, lav.) are taken into possession</p>
        <ul>
          <li>Ace - 11</li>
          <li>Ten - 10</li>
          <li>King - 4</li>
          <li>Queen - 3</li>
          <li>Jack - 2</li>
          <li>All other cards have zero value.</li>
        </ul>

        <p>The aim of the regular game is to collect as many points as possible, by winning “Sticks.”</p>

        <p>
          The number of the players behind one table can be three of four, but actively are playing only three players (if there is a fourth player, he is counted as “Small” (Mazais, lav.), and waits for his turn.
        </p>
        <p>During the game, the “Big” (Lielais, lav) always has to play individually against the other two, called the “Small” (Mazie, lav.).</p>

      </>
    )
  }
};

CardsValue.propTypes = {
  lang: PropTypes.string,
};

CardsValue.defaultProps = {
  lang: 'lv',
};

export default CardsValue;
