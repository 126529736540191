import React from 'react';
import PropTypes from 'prop-types';

const PointsHistory = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
          Sākumlapas lejasdaļā ir pieejama poga 'Punktu Vēsture’, ar kuras palīdzību var atvērt logu ar pārskatu par spēles punktu izmaiņām pēdējo trīs dienu laikā. Atsevišķi tiek izdalīti pieskaitītie un noņemtie spēles punkti, kā arī iemesli, piemēram - spēles rezultāts, sods par nokavētu gājienu u.c.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          В нижней части начальной страницы есть кнопка «История очков», при помощи которой можно открыть окно с обзором изменений очков игры за последние три дня.Отдельно выделены приплюсованные и снятые очки игры, а так же указаны причины, например — результат игры, штраф за просроченный ход итд.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          At the bottom of the home page, there is a "History of points" button that allows player to open a window with an overview of changes in game points over the last three days. The added and removed points of the game are separated, as well as the reasons, for example – the result of the game, the penalty for a late move, etc.
        </p>
      </>
    )
  }
};

PointsHistory.propTypes = {
  lang: PropTypes.string,
};

PointsHistory.defaultProps = {
  lang: 'lv',
};

export default PointsHistory;
