import React from 'react';
import PropTypes from 'prop-types';

const Cards = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
          Kāršu kavā ir 26 kārtis, no dūža līdz devītniekam iekļauti visi četri masti, un papildus tiem arī kārava astotnieks un kārava septītnieks.
        </p>
        <p>
          Kārtis tiek iedalītas trumpjos (visi kāravi, kalpi un dāmas) un netrumpjos (pārējās kārtis).
        </p>
        <p>
          Jebkura trumpja kārts var pārsist netrumpja kārti neskatoties uz to stiprumu savā starpā (Kārava devītnieks ir spēcīgāks par Pīķa dūzi).
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          В колоде карт имеются 26 карты, от туза до девятки входят все четыре масти, и дополнительно к ним бубновая восьмерка и бубновая семерка.
        </p>
        <p>
          Карты делятся на козыри( все бубны, валеты и дамы) и некозыри (остальные карты).
        </p>
        <p>
          Любой козырь перебивает любую карту обычной масти (бубновая девятка сильнее пикового туза).
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          A deck consists total of 26 cards. It includes all the Aces, Kings, Queens, Jacks, Tens and Nines plus the 8 of diamonds and 7 of diamonds.
        </p>
        <p>
          The Queens, Jacks, and all Diamonds are permanent trumps giving a total of 14 trumps. Any trump card can overlap the non-trump card regardless of their strength between each other (Diamond's ninth is stronger than Ace of Spades).
        </p>
      </>
    )
  }
};

Cards.propTypes = {
  lang: PropTypes.string,
};

Cards.defaultProps = {
  lang: 'lv',
};

export default Cards;
