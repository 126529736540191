import React from 'react';
import PropTypes from 'prop-types';

const LeaveRoomPenalty = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Ja spēlētājs pamet iesāktu spēli, tad par to tiek piemērota soda nauda. Iesāktu spēli var pamest:
        </p>
        <ul>
          <li>nokavējot gājienu</li>
          <li>neveicot izvēlni</li>
          <li>aizverot istabu</li>
        </ul>

        <p>
        Atceries: ja vēlies iziet no istabas bez soda naudas, tad ir jāizvēlas ‘Pēdējā Partija’ un attiecīgi jāizspēlē iesāktā partija (vai arī atlikušais minimālo partiju skaits, ja tāds ir).
        </p>
        <p>Piemērotās soda naudas apjoms ir atkarīgs no:</p>
        <ul>
          <li>istabas likmes</li>
          <li>minimālo un jau izspēlēto partiju skaita</li>
        </ul>

        <p>Atceries: istaba tiek uzskatīta par patvaļīgi pamestu arī tad, ja spēlētājs ir nokavējis gājienu pārtraukta interneta savienojuma dēļ.</p>

        <p>Soda naudu par spēles pamešanu aprēķina šādi:</p>
        <p>max zaudējums + ((min partijas - izspēlētās partijas) x 10 x istabas likme)</p>

        <p>Piemērs:</p>
        <ul>
          <li>ja ir istaba ar likmi 1:10 un ir izspēlētas 2 partijas no 10 min partijām, tad soda nauda = 160 + ((10 - 2) x 10 x 10) = 160 + 800 = 960 monētas</li>
        </ul>

        <p>Ja spēlētājs pamet istabu partijas laikā vai pirms ir izspēlēts min partiju skaits, tad palicējiem tiek piešķirta kompensācija, ko aprēķina šādi:</p>
        <p>(0.25 x max zaudējums) + 0.25 x ((min partijas - izspēlētās partijas) x 10 x istabas likme)</p>

        <p>Piemērs:</p>
        <ul>
          <li>
            ja ir istaba ar likmi 1:10 un ir izspēlētas 2 partijas no 10 min partijām, tad soda nauda = (0.25 x 160) + 0.25 x ((10 - 2) x 10 x 10) = 40 + 200 = 240 monētas katram no palicējiem
          </li>
        </ul>
        <p>
        Atceries: ja istaba tiek aizvērta tehniskas un no spēlētāja neatkarīgas kļūdas dēļ, spēles administrācija atmaksā soda naudu, ja par to tiek ziņots administrācijai.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
        Если игрок покидает начатую игру, то за это налагается штраф. Начатую игру можно покинуть:
        </p>
        <ul>
          <li>просрочив ход</li>
          <li>не сделав выбор</li>
          <li>закрыв комнату</li>
        </ul>

        <p>
        Помни: если хочешь выйти из комнаты без штрафа, то следует выбрать «Последняя Партия» и соответственно нужно доиграть начатую партию (или же оставшееся минимальное число партий, если такое есть).
        </p>
        <p>Размер штрафа зависит от:</p>
        <ul>
          <li>тавки комнаты</li>
          <li>минимального числа и числа уже разыгранных партий</li>
        </ul>

        <p>Помни: считается, что вы самовольно покинули комнату и в том случае, если игрок просрочил ход из-за помех в соединении интернета.</p>

        <p>Штраф за то, что покинули игру начисляется следующим образом:</p>
        <p>max потеря + ((мин. партии – разыгранные партии) x 10 x ставка комнаты</p>

        <p>Пример:</p>
        <ul>
          <li>если комната со ставкой 1:10 и уже разыграны 2 партии из 10 мин. партий, то штраф = 160 + ((10 - 2) x 10 x 10) = 160 + 800 = 960 монет</li>
        </ul>

        <p>Если игрок покидает комнату во время партии или до того как разыграно мин. число партий, то оставшимся присваивается компенсация, которую начисляют следующим образом:</p>
        <p>(0.25 x max потеря) + 0.25 x ((мин. партии – разыгранные партии) x 10 x ставка комнаты)</p>

        <p>Пример:</p>
        <ul>
          <li>
            если комната со ставкой 1:10 и разыграны 2 партии из 10 мин. партий, то штраф = (0.25 x 160) + 0.25 x ((10 - 2) x 10 x 10) = 40 + 200 = 240 монет каждому оставшемуся игроку.
          </li>
        </ul>
        <p>
          Помни:если комната закрыта по техническим причинам или из-за ошибки не по вине игрока, то администрация возвращает штраф, если об этом информируется администрация.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          If a player leaves a game, a fine is imposed. Once started, the game can be left:
        </p>
        <ul>
          <li>when missing a move;</li>
          <li>not making a choice;</li>
          <li>closing the rooom.</li>
        </ul>

        <p>
          NB! If a player wants to leave the room without a fine, then the 'Last Game' has to be selected and played accordingly (or the remaining minimum number of games, if any).
        </p>
        <p>The amount of the fine imposed depends on:</p>
        <ul>
          <li>room rates;</li>
          <li>the minimum number of games and games already played.</li>
        </ul>

        <p>NB! The room is considered arbitrarily left even if the player missed the turn due to interrupted internet connection.</p>

        <p>The fine for leaving the game is calculated as follows:</p>
        <p>Maximum loss + ((minimum amount of games – already played games) x 10 x room rate.</p>

        <p>Example:</p>
        <ul>
          <li>if a room is with a bet of 1:10 and 2 games from 10 games are already played, then the fine will be: 160 + ((10 - 2) x 10 x 10) = 160 + 800 = 960 coins.</li>
        </ul>

        <p>If a player leaves the room during a game or before the number of minimum games has been played, other players will receive compensation calculated as follows:</p>
        <p>(0.25 x max loss) + 0.25 x ((min games – played games) x 10 x room rate).</p>

        <p>Example:</p>
        <ul>
          <li>
            if a room is with a bet of 1:10 and 2 games from 10 games are already played, then compensation will be = (0.25 x 160) + 0.25 x ((10 - 2) x 10 x 10) = 40 + 200 = 240 coins for each player.
          </li>
        </ul>
        <p>
          NB! If the room is closed due to a technical and player-independent error, the game administration will refund the penalty if the administration is notified.
        </p>
      </>
    )
  }
};

LeaveRoomPenalty.propTypes = {
  lang: PropTypes.string,
};

LeaveRoomPenalty.defaultProps = {
  lang: 'lv',
};

export default LeaveRoomPenalty;
