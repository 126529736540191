import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import ScrollArea from 'react-scrollbar';
import ScrollAreaButtons from './ScrollAreaButtons';

class ScrollAreaWrapper extends PureComponent {
  static propTypes = {
    scrollRef: PropTypes.shape(),
    onScroll: PropTypes.func,
    rightOffset: PropTypes.number,
    speed: PropTypes.number,
    horizontal: PropTypes.bool,
    vertical: PropTypes.bool,
    noScrollButton: PropTypes.bool,
    hideScrollBar: PropTypes.bool,
    height: PropTypes.number,
  }

  static defaultProps = {
    scrollRef: {},
    rightOffset: 0,
    speed: 30,
    horizontal: false,
    vertical: true,
    noScrollButton: false,
    hideScrollBar: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 0,
      isDragging: false,
      startX: 0,
      startY: 0,
    };
  }


  handleMouseDown = (e) => {
    e.currentTarget.style.cursor = 'grabbing';
    this.setState({
      isDragging: true,
      startX: e.clientX,
      startY: e.clientY,
    });
  };

  handleMouseUp = (e) => {
    document.body.style.cursor = 'grab';
    //e.currentTarget.style.cursor = 'grab';
    this.setState({ isDragging: false });
  };

  handleMouseMove = (e) => {
    if (!this.state.isDragging) return;
    document.body.style.cursor = 'grabbing';

    const { clientX, clientY } = e;
    const { startX, startY } = this.state;// TODO: rename prevX to startX and dont save delta in state as it's not needed

    const deltaX = clientX - startX;
    const deltaY = clientY - startY;

    this.setState({
      startX: clientX,
      startY: clientY,
    });
    const maxY = this.props.scrollRef.current.state.realHeight - this.props.scrollRef.current.state.containerHeight;

    //this.props.scrollRef.current.state.topPosition -= deltaY;
    this.props.scrollRef.current.scrollArea.scrollYTo(this.props.scrollRef.current.state.topPosition - deltaY);

    if (this.props.scrollRef.current.state.topPosition < 0) {
      //this.props.scrollRef.current.state.topPosition = 0;
      this.props.scrollRef.current.scrollArea.scrollYTo(0);

    } else if (this.props.scrollRef.current.state.topPosition > maxY){
      //this.props.scrollRef.current.state.topPosition = maxY;
      this.props.scrollRef.current.scrollArea.scrollYTo(maxY);
    }
    //this.props.onScroll();
  };

  // componentDidMount() {
  //   document.addEventListener('mouseup', this.handleMouseUp);
  //   document.addEventListener('mousemove', this.handleMouseMove);

  // }

  // componentWillUnmount() {
  //   document.removeEventListener('mouseup', this.handleMouseUp);
  //   document.removeEventListener('mousemove', this.handleMouseMove);
  // }

  componentDidUpdate(prevProps) {
  }

  updateScrollPos = (val) => {
    console.log("updateScrollPos: ", val)
    if (val.topPosition || val.topPosition === 0) {
      this.setState({ scrollPos: val.topPosition || 0 });
    }
  //  this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  render = () => {
    const {
      rightOffset, show, topOffset, bottomOffset, bgColor, className, contentClassName, horizontal, vertical, noScrollButton, scrollRef, onScroll, hideScrollBar, height,
    } = this.props;
    const { scrollPos } = this.state;

    //  console.log('ScrollArea render', { show });

    return (
      <Fragment>
        {
          !noScrollButton && (
            <ScrollAreaButtons
              scrollPos={scrollPos}
            //  show={show}
              show={
                this.tableScrollbar
                && this.tableScrollbar.state
                && this.tableScrollbar.state.realHeight
                  > this.tableScrollbar.state.containerHeight
                  ? true
                  : null
              }
              scroll={this.scroll}
              speed={30}
              rightOffset={rightOffset || 0}
              topOffset={topOffset || 0}
              bottomOffset={bottomOffset || 0}
            />
          )
        }
        <ScrollArea
          style={height ? {height: `${height}px`}: {}}
          speed={0.55}
        //  className={className}
          className={`default-scrollbar ${className} ${horizontal ? 'scrollarea-fit-content' : null}`}
          contentClassName={`default-scrollbar-content ${contentClassName} ${horizontal ? 'scrollarea-content-fit-content' : null}`}
          smoothScrolling
          stopScrollPropagation
          vertical={vertical}
          verticalContainerStyle={{
            background: 'transparent',
            opacity: 1,
            // width: 11,
          }}
          verticalScrollbarStyle={{
            background: 'rgba(255, 255, 255, 0.2)',
            borderRadius: 4,
            width: 4,
            minHeight: 10,
            display: hideScrollBar ? 'none': 'block',
            // minScrollSize: 25,
          }}
          horizontal={horizontal}
          horizontalContainerStyle={{
            background: 'transparent',
            opacity: 1,
            height: 5,
          }}
          horizontalScrollbarStyle={{
            background: 'rgba(255, 255, 255, 0.2)',
            borderRadius: 1,
            height: 4,
            minWidth: 10,
            display: hideScrollBar ? 'none': 'block',
            // minScrollSize: 25,
          }}
          onScroll={onScroll || this.updateScrollPos}
          ref={scrollRef || ((el) => {
            this.tableScrollbar = el;
          })}
        > 
          {/* <div
            style={{ userSelect: 'none' }}
            onMouseDown={this.handleMouseDown}
          > */}
            {this.props.children}
          {/* </div> */}
        </ScrollArea>
                  

      </Fragment>
    );
  }
}

export default ScrollAreaWrapper;
