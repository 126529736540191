import React from 'react';
import PropTypes from 'prop-types';

import Media from 'reactstrap/lib/Media';

import appStore from '../../../../../images/redesign/about-us/Appstore button.webp';
import playStore from '../../../../../images/redesign/about-us/Playstore button.webp';
import { Button } from 'reactstrap';

const currentVersion = require('./../../../../../../package.json').version;
const ANDROID_PATH_lv = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=lv';
const ANDROID_PATH_en = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=en';
const ANDROID_PATH_ru = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=ru';
const IOS_PATH_lv = 'https://apps.apple.com/lv/app/id1505526877';
const IOS_PATH_en = 'https://apps.apple.com/us/app/id1505526877';
const IOS_PATH_ru = 'https://apps.apple.com/ru/app/id1505526877';
const AboutUs = React.memo(({
  i18n, t, toggle
}) => (
  <div className="about-us-modal">
    <div className="about-us-modal-first-row">
      <p>
        {t('home.zoleGameVersion') + currentVersion}
      </p>
    </div>
    <div className="about-us-modal-second-row">
      <div className="about-us-modal-second-row-column">
        <div className="about-us-modal-second-row-column-section">
          <div className="about-us-modal-text">
            {t('common.developer')}:
          </div>
          <div className="about-us-modal-link">
            <a target="_blank" rel="noopener noreferrer" href="https://draxogames.com/">SIA 'Draxo Games'</a>
          </div>
        </div>
        <div className="about-us-modal-second-row-column-section">
          <div className="about-us-modal-text">
            {t('common.regNum')}:
          </div>
          <div className="about-us-modal-link">
            <span>40103927834</span>
          </div>
        </div>
        <div className="about-us-modal-second-row-column-section">
          <div className="about-us-modal-text">
            {t('common.address')}:
          </div>
          <div className="about-us-modal-link">
            <span>Liepājas 49-6, Kuldīga, LV-3301</span>
          </div>
        </div>
      </div>
      <div className="about-us-modal-second-row-column">
        <div className="about-us-modal-second-row-column-section">
          <div className="about-us-modal-text">
            WEB:
          </div>
          <div className="about-us-modal-link">
            <a target="_blank" rel="noopener noreferrer" href="https://spelezoli.lv/">https://spelezoli.lv/</a>
          </div>
        </div>
        <div className="about-us-modal-second-row-column-section">
          <div className="about-us-modal-text">
            {t('home.email')}:
          </div>
          <div className="about-us-modal-link">
            <a target="_blank" rel="noopener noreferrer" href="mailto: info@draxogames.com">info@draxogames.com</a>
          </div>
        </div>
        <div className="about-us-modal-second-row-column-section">
          <div className="about-us-modal-text">
            {t('common.phoneNum')}:
          </div>
          <div className="about-us-modal-link">
            <a href="tel:+37126622677">+371 26 62 26 77</a>
          </div>
        </div>
      </div>

    </div>
    <div className="about-us-modal-third-row">
      <div className="about-us-modal-third-row-section">
        <a className="about-us-modal-third-row-link-left" target="_blank" rel="noopener noreferrer" href={i18n.language == 'lv' ? ANDROID_PATH_lv : i18n.language == 'en' ? ANDROID_PATH_en : i18n.language == 'ru' ? ANDROID_PATH_ru : ANDROID_PATH_lv}>
          <Media src={playStore} className="about-us-modal-third-row-img about-us-modal-third-row-img-left" />
        </a>
      </div>
      <div className="about-us-modal-third-row-section">
        <a className="about-us-modal-third-row-link-right" target="_blank" rel="noopener noreferrer" href={i18n.language == 'lv' ? IOS_PATH_lv : i18n.language == 'en' ? IOS_PATH_en : i18n.language == 'ru' ? IOS_PATH_ru : IOS_PATH_lv}>
          <Media src={appStore} className="about-us-modal-third-row-img" />
        </a>
      </div>
    </div>
    <div className="about-us-modal-footer-row text-center">
        <Button onClick={toggle}>{t('common.close')}</Button>
    </div>
  </div>
));

AboutUs.propTypes = {
  t: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

AboutUs.defaultProps = {
};

export default AboutUs;
