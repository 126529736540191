const importAll = (requireContext) => {
    const images = {};
    requireContext.keys().forEach((key) => {
        const fileName = key.replace('./', '').replace(/\.\w+$/, '');
        images[fileName] = requireContext(key);
    });
    return images;
};
  
const images = importAll(require.context('../images/redesign/cards-info', false, /\.(svg)$/));
  
export const getCardImage = cardKey => {
    const cardKeyParts = cardKey.split('-');
    const cardLevel = cardKeyParts[1];
    const cardName = cardSymbolToName(cardKeyParts[0]);
    return images[`${cardLevel}-${cardName}`];
}

export const cardSymbolToName = symbol => {
    switch (symbol) {
        case '♦︎':
            return 'Diamond';
        case '♥':
            return 'Hearts';
        case '♣︎':
            return 'Clubs';
        case '♠︎':
            return 'Spades';
        default:
            return 'Unknown symbol';
    }
}