import React from 'react';
import PropTypes from 'prop-types';

const StorePremium = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
          Zoles veikalā var iegādāties vairākas īpašas Premium iespējas. Iegādātā Premium iespēja savu darbību sāk tūlīt pēc iegādes (ja vien tās aprakstā nav norādīts citādi).
        </p>
        <p>Pašlaik veikalā var iegādāties šādas Premium iespējas:</p>
        <ul>
        <li><b>Bilances dzēšana (EUR 5.00)</b>: ja bilance (Zoles monētas) ir negatīva, tad ar šo Premium iespēju vari negatīvo bilanci dzēst, lai tā būtu 0 monētas.</li>
        <li><b>Punktu dzēšana (EUR 5.00)</b>: ja spēles punktu skaits ir negatīvs, tad ar šo Premium iespēju vari negatīvo punktu skaitu dzēst, lai būtu 0 punkti.</li>
        <li><b>Profila restarts (EUR 5.00)</b>: ar šo var pilnībā restartēt profilu (naudas bilance, punktu skaits un izspēlētās partijas būs 0, tāpat arī noņemti iegūtie sasniegumi, saņemot iespēju tos atkārtot).</li>
        </ul>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          В магазине Золе можно приобресьти несколько особенных Premium возможностей. Приобретенная возможность Premium начинает действовать сразу после покупки (если только в ее описании не указано по другому).
        </p>
        <p>На данный момент в магазине можно прибресьти следующие Premium возможности:</p>
        <ul>
        <li><b>Погашение баланса ( 5.00 евро)</b>: если баланс отрицательный (монеты Золе), то данная Premium возможность позволяет погасить негативный баланс, чтобы он был 0 монет.</li>
        <li><b>Погашение очков ( 5.00 евро)</b>: если количество игровых очков негативное, то данная Premium возможность позволяет погасить негативное количество очков, чтобы они были 0 очков.</li>
        <li><b>Рестарт профиля ( 5.00 евро)</b>: этим можете полностью перезагрузить профиль (денежный баланс, количество очков и разыгранные партии будут 0, так же и отнятые достижения).</li>
        </ul>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          In the “Zole” store different Premium options can be purchased as well. The purchased Premium option can be used immediately after the purchase (unless otherwise stated in the description).
        </p>
        <p>Currently, the following Premium options can be purchased in the store:</p>
        <ul>
        <li><b>Deletion of the balance (EUR 5.00)</b>: if the balance (“Zole” coins) is negative, then this Premium option allows deleting the balance to be 0 coins.</li>
        <li><b>Deletion of the points (EUR 5.00)</b>: if the amount of points is negative, then this Premium option allows deleting the negative points.</li>
        <li><b>Restarting the profile (EUR 5.00)</b>: with this option player can restart his profile (money balance, points and played games will be 0, also the achievements will be deleted).</li>
        </ul>
      </>
    )
  }
};

StorePremium.propTypes = {
  lang: PropTypes.string,
};

StorePremium.defaultProps = {
  lang: 'lv',
};

export default StorePremium;
