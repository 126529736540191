import React from 'react';
import PropTypes from 'prop-types';

// import Row from 'reactstrap/lib/Row';

// import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';
import ScoreTabele3p from './ScoreTabele3p';
import ScoreTabele4p from './ScoreTabele4p';

import { connect } from 'react-redux';
import { getBetValue } from '../../../../../../common/services/data-service';
// import infoImg from '../../../../../../images/redesign/player/info.svg';
import { SCREEN_MODE } from '../../../../../../constants/constants';

const EndResultFines = React.memo(({
  t,
  points,
  totalPnts,

  myPos,
  bet,
  party,
  gameType,
  closeReason,

  player1ShortName,
  player2ShortName,
  player3ShortName,
  player4ShortName,

  player1Uid,
  player2Uid,
  player3Uid,
  player4Uid,
  fetchPartyLog,
  roomId,
  screenMode,
}) => {

  return (
    !player4ShortName ? (
      <ScoreTabele3p
        roomId={roomId}
        scoreTableOpen
        isEndResultTable
        fetchPartyLog={fetchPartyLog}
        closeReason={closeReason}


        totalPnts={totalPnts}
        points={points}

        t={t}
        myPos={myPos}
        bet={bet}
        party={party}
        gameType={gameType}

        player1ShortName={player1ShortName}
        player2ShortName={player2ShortName}
        player3ShortName={player3ShortName}
        player4ShortName={player4ShortName}

        player1Uid={player1Uid}
        player2Uid={player2Uid}
        player3Uid={player3Uid}
        player4Uid={player4Uid}
        screenMode={screenMode}
      />
    ) : (
      <ScoreTabele4p
        roomId={roomId}
        scoreTableOpen
        isEndResultTable
        fetchPartyLog={fetchPartyLog}
        closeReason={closeReason}

        t={t}
        totalPnts={totalPnts}
        points={points}

        myPos={myPos}
        bet={bet}
        party={party}
        gameType={gameType}

        player1ShortName={player1ShortName}
        player2ShortName={player2ShortName}
        player3ShortName={player3ShortName}
        player4ShortName={player4ShortName}

        player1Uid={player1Uid}
        player2Uid={player2Uid}
        player3Uid={player3Uid}
        player4Uid={player4Uid}
        screenMode={screenMode}
      />
    )
  );
});

EndResultFines.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
  roomId: PropTypes.string,
  screenMode: PropTypes.string,
};

EndResultFines.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
  roomId: '',
  screenMode: SCREEN_MODE.normal,
};

const mapStateToProps = (state, ownProps) => {
  if (state.game) {
    return ({
      myPos: state.game.myPos || null,
      globalParams: state.game.globalParams || {},
      players: state.game.players,
    });
  }
  return null;
};

export default connect(mapStateToProps, null)(EndResultFines);
