import React, { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { filter, map, includes } from 'lodash';
import {
  Col, NavLink, Button, DropdownItem, Label, Input, Media, Row,
} from 'reactstrap';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import { MENU_NAVIGATION, MENU_NAVIGATION_TYPE, filterLeaderboardType, filterLeaderboardActive, SCREEN_MODE } from '../../../../../../constants/constants';
import CustomModal from '../../../Components/Components/Modal';

import SearchDarkIcon from '../../../../../../images/redesign/mobile/icons/top-search-dark-icon.svg';
import SearchLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-search-light-icon.svg';
import ErrorNotification from '../../../Components/Components/ErrorNotification';
import config from '../../../../../../constants/config';

const TopSubHeader = ({ 
  screenMode,
  changeTab,
  parentTab,
  toggleTypeFilter,
  typeFilterOpen,
  activeType,
  activeTab,
  toggleType,
  showFields,
  toggleMillionaires,
  millionairesOpen,
  showOnlyActive,
  toggleShowActive,
  toggleSearch,
  searchOpen,
  filters,
  handleSearchValue,
  handleKeyPress,
  searchValue,
  toggleTopFilter,
  toggleTopTime,
  typeTimeOpen,
  toggleTime,
  fetchLeaderboardSearchFunc,
  onHover,
  onHoverLeave,
  popoverOpen,
  message,
  alertStatus
}) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    const element = document.getElementById(filter(MENU_NAVIGATION_TYPE, item => (item.id === parentTab.toString()))[0].label);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [parentTab]);

  return (
    <>
      <div className="top-search-joyride-portrait-target" style={{display: 'block', position: 'fixed', bottom: 50, height: 60, right: 10, width: 40, pointerEvents: 'none', background: 'white', opacity: 0}} />

      <Col xs="12" className="layout-mobile-subheader-navmenu">
        <div className="layout-mobile-subheader-navmenu-link top-sub-menu-filter" id="filter" onClick={toggleTopFilter}>
          <div className="top-sub-menu-filter-title">
            {t('top.sortBy')}
          </div>
          <div className="top-sub-menu-filter-content">
            {t(`top.${filter(filterLeaderboardType, ['id', activeType.toString()])[0].tLabel}`)}
          </div>
        </div>
        <div className="layout-mobile-subheader-navmenu-link top-sub-menu-filter" id="time" onClick={toggleTopTime}>
          <div className="top-sub-menu-filter-title">
            {t('top.timePeriod')}
          </div>
          <div className="top-sub-menu-filter-content">
            {t(`top.${filter(filterLeaderboardActive, ['id', activeTab.toString()])[0].tLabel}`)}
          </div>
        </div>
        <div className="layout-subheader-navmenu-link top-sub-menu-millionaires" id="ignoredUsers">
          <NavLink
            color="link"
            className={classNames('layout-mobile-subheader-navmenu-link-text', {
              'layout-mobile-subheader-navmenu-link-text-active': millionairesOpen,
            })}
            onClick={toggleMillionaires}
          >
            {t('top.millionaires')}
          </NavLink>
        </div>
        <div className="layout-mobile-subheader-navmenu-link top-sub-menu-active" xs={4} id="archievements">
          <Label check >
            <Input
              type="checkbox"
              bsSize="lg"
              name="active"
              checked={showOnlyActive}
              onChange={toggleShowActive}
              className={`tou-custom-checkbox tou-custom-checkbox-${screenMode} custom-control-input`}
            />
            <span className="rules custom-control-label top-sub-menu-active-title">
              {t('top.active')}
            </span>
          </Label>
        </div>
        <div className="layout-mobile-subheader-navmenu-link top-sub-menu-search" xs={4} id="gameHistory">
          <Media color="link" className="top-sub-menu-search-icon" id="top-mobile-search" src={SCREEN_MODE.light !== screenMode ? SearchDarkIcon : SearchLightIcon} onClick={toggleSearch} />
            {/* toggleFunc={toggleSearch}
            isOpen={searchOpen}
              <Row>
                <Col xs={{ size: 10, offset: 1 }}>
                  <Input
                    type="text"
                    name="searchValue"
                    id="searchValue"
                    value={filters.searchValue}
                    onChange={handleSearchValue}
                    onKeyPress={handleKeyPress}
                  />
                </Col>
              </Row> */}
        </div>
      </Col>
      <CustomModal
        isOpen={typeFilterOpen}
        toggle={toggleTopFilter}
        size="md"
        addClassName="top-filter-dropdown"
        // modalVerticalCentering
        body={(
          <Fragment>
            {
              map(filterLeaderboardType, (item, key) => (
                <DropdownItem className={`top-filter-mobile-dropdown-item-${screenMode}`} toggle={false} key={key} onClick={() => { toggleType(item.id); }}>
                  <span className={`top-filter-mobile-dropdown-item-${screenMode}-text`}>
                    {t(`top.${item.tLabel}`)}
                  </span>
                  {
                    item.isSortingParam
                      ? <div className={`layout-body-sorting-arrow${item.id === activeType ? '-active' : ''}`}/>
                      : (
                        <div className="layout-body-radio-outer-circle">
                          {includes(showFields.showFieldArray, item.label) && <div className="layout-body-radio-inner-circle"/>}
                        </div>
                      )
                  }
                </DropdownItem>
              ))
            }
            <DropdownItem className={`layout-body-ok-button top-filter-mobile-dropdown-item-${screenMode}-ok`} onClick={toggleTopFilter}>
              <span className={`top-filter-mobile-dropdown-item-${screenMode}-text`}>
                {t('game:ok')}
              </span>
            </DropdownItem>
          </Fragment>
        )}
      />
      <CustomModal
        isOpen={typeTimeOpen}
        toggle={toggleTopTime}
        size="md"
        addClassName="top-time-dropdown"
        modalVerticalCentering
        body={(
          <Fragment>
            {
              map(filterLeaderboardActive, (item, key) => (
                <DropdownItem className={`top-filter-mobile-dropdown-item-${screenMode}`} toggle={false} key={key} onClick={() => { toggleTime(item.id); }}>
                  <span className={`top-filter-mobile-dropdown-item-${screenMode}-text`}>
                    {t(`top.${item.tLabel}`)}
                  </span>
                  <div className={`layout-body-sorting-arrow${item.id === activeTab ? '-active' : ''}`} />
                </DropdownItem>
              ))
            }
            <DropdownItem className={`layout-body-ok-button top-filter-mobile-dropdown-item-${screenMode}-ok`} onClick={toggleTopTime}>
              <span className={`top-filter-mobile-dropdown-item-${screenMode}-text`}>
                {t('game:ok')}
              </span>
            </DropdownItem>
          </Fragment>
        )}
      />
      <CustomModal
        isOpen={searchOpen}
        toggle={toggleSearch}
        size="md"
        addClassName="top-search-dropdown"
        modalVerticalCentering
        innerAutoFocus={false}
        body={(
          <Fragment>
            <Row>
              <Col xs={{ size: 10, offset: 1 }}>
                <Input
                  type="text"
                  name="searchValue"
                  id="searchValue"
                  value={filters.searchValue}
                  onChange={handleSearchValue}
                  onKeyPress={handleKeyPress}
                  autoFocus={true}
                />
                <ErrorNotification
                  text={message}
                  isVisible={alertStatus && message}
                  narrow
                />
              </Col>
              <Col xs={{ size: 10, offset: 1 }} className="layout-default-button-wrapper mt-2">
                <Button
                  id="top-search-popover-new-mobile"
                  color="link"
                  className="layout-default-button"
                  onClick={() => { onHoverLeave(); fetchLeaderboardSearchFunc(); }}
                  onMouseEnter={onHover}
                  onMouseLeave={onHoverLeave}
                >
                  {t('top.search')}
                </Button>
                {/* <Popover
                  container={'div > div'}
                  placement="bottom"
                  isOpen={popoverOpen}
                  target="top-search-popover-new-mobile"
                  popperClassName={`popover top-search-popover top-search-popover-${screenMode}`}
                >
                  <PopoverBody>
                    <div>
                      <ul>
                        <li>{t('top.searchRule1')}</li>
                        <li>{t('top.searchRule2')}</li>
                        <li>{t('top.searchRule3')}</li>
                      </ul>
                    </div>
                  </PopoverBody>
                </Popover> */}
              </Col>
            </Row>
          </Fragment>
        )}
      />
    </>
  );
};

export default TopSubHeader;
