import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ReactTableContainer from 'react-table-container';

import { withTranslation } from 'react-i18next';

import Moment from 'react-moment';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';

class UserHistoryTable extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    history: PropTypes.arrayOf(PropTypes.shape({})),
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    type: null,
    history: [],
  }

  constructor(props) {
    super(props);
    this.state = {
    };

  }

  changeFilter = (type) => {
    const { filter } = this.state;

    if (filter === type) {
      this.setState({ filter: null });
    } else {
      this.setState({ filter: type });
    }
  }

  render() {
    const { t, type, history } = this.props;
    const { filter } = this.state;

    console.log('filter', { filter });

    return (
      <>
        <Row>
          <Col sm="1" />
          <Col sm="5">
            <Label check>
              <Input
                type="checkbox"
                onClick={() => { this.changeFilter('missedTurns') }}
                checked={filter === 'missedTurns'}
                readOnly
              />{' '}
              {/* {t('missedTurns')} */}
              Missed Turns
            </Label>
          </Col>
          <Col sm="5">
            <Label check>
              <Input
                type="checkbox"
                onClick={() => { this.changeFilter('admin') }}
                checked={filter === 'admin'}
                readOnly
              />{' '}
              {/* {t('admin')} */}
              Admin
            </Label>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <table className="user-history-table">
              <thead>
                <tr className="user-history-table-header-row">
                  <th className="user-history-table-header-col">
                    {/* {t('time')} */}
                    Time
                  </th>
                  <th className="user-history-table-header-col">
                    {/* {t('type')} */}
                    Type
                  </th>
                  <th className="user-history-table-header-col">
                    {type === 'balance' ? 'Old Balance' : 'Old Points'}
                  </th>
                  <th className="user-history-table-header-col">
                    {type === 'balance' ? 'New Balance' : 'New Points'}
                  </th>
                  <th className="user-history-table-header-col">
                    {/* {t('change')} */}
                    Change
                  </th>
                </tr>
              </thead>
              <tbody />
            </table>
            <ReactTableContainer
              width="100%"
              height="420px"
              scrollbarStyle={{
                background: {
                  background: 'transparent',
                  width: 1,
                  marginRight: 3,
                },

                backgroundFocus: {
                  background: 'transparent',
                  width: 1,
                  marginRight: 3,
                },
                foreground: {
                  background: 'fff',
                  width: 4,
                  left: -1,
                },

                foregroundFocus: {
                  background: 'fff',
                  width: 4,
                  left: -1,
                },
              }}
            >
              <table className="user-history-table">
                <colgroup>
                  <col span="1" className="" />
                </colgroup>
                <thead />
                <tbody>
                  {history && history.map((data, index) => {

                    console.log('data', { data });

                      if (filter === 'missedTurns' && !(data.type === 'missTurnMe' || data.type === 'leftRoom' || data.type === 'minGamesPenalty')) {
                        return null;
                      }

                      if (filter === 'admin' && !(data.type === 'adminChange' || data.type === 'adminChangeAuto')) {
                        return null;
                      }

                      return (
                      <Fragment key={data.key}>
                        <tr key={data.key} className={`user-history-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                          <td className="user-history-table-col">
                            <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                              {data.time}
                            </Moment>
                          </td>
                          <td className="user-history-table-col">
                            {data.type === 'game' && (
                              "Game result"
                            )}
                            {data.type === 'joinPrice' && (
                              "Room's participation fee"
                            )}
                            {data.type === 'dailyBonus' && (
                              "Daily bonus"
                            )}
                            {data.type === 'leaveTournament' && (
                              "Penalty fee for leaving a tournament"
                            )}
                            {data.type === 'joinTournament' && (
                              "Tournament participation fee"
                            )}
                            {data.type === 'winTournament' && (
                              "Tournament winnings"
                            )}
                            {data.type === 'canceledTournament' && (
                              "Tournament was canceled"
                            )}
                            {data.type === 'buyTournamentMoney' && (
                              "Tournament money purchase"
                            )}
                            {data.type === 'friendReceived' && (
                              "Received from a friend"
                            )}
                            {data.type === 'friendSent' && (
                              "Sent to a friend"
                            )}
                            {data.type === 'purchaseCallback' && (
                              "Money purchase"
                            )}
                            {data.type === 'purchase' && (
                              "Purchase"
                            )}
                            {data.type === 'missTurnMe' && (
                              "Penalty for a missed turn"
                            )}
                            {data.type === 'missTurnOther' && (
                              "Turn missed by other player"
                            )}
                            {data.type === 'leftRoom' && (
                              "Penalty for leaving the room"
                            )}
                            {data.type === 'leftRoomOther' && (
                              "Other player left the room"
                            )}
                            {data.type === 'minGamesPenalty' && (
                              "Penalty for leaving the room before minimum rounds played"
                            )}
                            {data.type === 'minGamesReward' && (
                              "Other player left the room before minimum games played"
                            )}
                            {data.type === 'adminChange' && (
                              "Game administration"
                            )}
                            {data.type === 'endRoomPules' && (
                              "Calculation of pules at the end of the room"
                            )}
                            {data.type === 'giftsSent' && (
                              "Sent gift"
                            )}
                            {data.type === 'giftRemoved' && (
                              "Gift removed"
                            )}
                            {data.type === 'achievement' && (
                              "Achievement"
                            )}
                            {data.type === 'balanceReset' && (
                              "Balance reset"
                            )}
                            {data.type === 'pointsReset' && (
                              "Points reset"
                            )}
                            {data.type === 'profileReset' && (
                              "Profile reset"
                            )}
                            {data.type === 'profanityPenalty' && (
                              "Profanity Penalty"
                            )}
                            {data.type === 'holiday' && (
                              "Holiday gift"
                            )}
                            {data.type === 'nameDay' && (
                              "Name day gift"
                            )}
                            {data.type === 'birthday' && (
                              "Birthday gift"
                            )}
                            {data.type === 'loginBonus' && (
                              "Login Bonus"
                            )}
                            {data.type === 'treasureChest' && (
                              "Treasure chest bonus"
                            )}
                            {data.type === 'adventeBonus' && (
                              "Advent bonus"
                            )}
                            {data.type === 'bonusCode' && (
                              "Gift Code"
                            )}
                            {data.type === 'emailBonus' && (
                              "Added email"
                            )}
                            {data.type === 'tutorialBonus' && (
                              "Game guide"
                            )}
                            {data.type === 'adminChangeAuto' && (
                              "Game administration (auto)"
                            )}
                          </td>
                          <td className="user-history-table-col">
                            {data.old}
                          </td>
                          <td className="user-history-table-col">
                            {data.new}
                          </td>
                          <td className="user-history-table-col">
                            {data.change}
                          </td>
                        </tr>
                      </Fragment>
                    )
                  })}
                </tbody>
              </table>
            </ReactTableContainer>
          </Col>
        </Row>
      </>
    );
  }
}

export default  withTranslation(['admin', 'common'])(UserHistoryTable);
