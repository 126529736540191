// import {
//  FONT_MODE, SCREEN_MODE
// } from '../constants/constants';

export default {
  uid: null,
  name: null,
  photo: null,
  balance: null,
  level: null,
  tutorialShown: true,
  firstTimeModal: true,
  totalPoints: null,
  smartLookStatus: null,
  activeRoom: null,
//  role: null,
  claimedAchievements: {},
  userAchievements: {},
  todayHolidays: null,
  claimedRewards: null,
  claimedRewardsFetched: false,
  showChestBonusModal: null,
  showAdventBonusModal: null,
  discounts: [],
  eventsData: [],
  fontMode: 'normal',
  // screenMode: SCREEN_MODE.normal,
};
