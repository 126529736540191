import React from 'react';
import PropTypes from 'prop-types';

import Media from 'reactstrap/lib/Media';

import coinImg from '../../../../../../images/redesign/common/coin.svg';
import ratingImg from '../../../../../../images/redesign/common/rating.svg';

const PlayerBalance = React.memo(({
  bal, rating,
}) => (
  <div className="player-balance">
    <Media src={coinImg} className="player-balance-coin" />
    <div className="player-balance-text">
      {bal}
    </div>
    <Media src={ratingImg} className="player-balance-coin" />
    <div className="player-balance-text">
      {rating || 1600}
    </div>
  </div>
));

PlayerBalance.propTypes = {
  bal: PropTypes.number,
  rating: PropTypes.number,
};

PlayerBalance.defaultProps = {
  bal: null,
  rating: null,
};

export default PlayerBalance;
