import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

// import { Link } from 'react-router-dom';

// import Lottie from 'react-lottie';

import Button from 'reactstrap/lib/Button';

import CustomModal from '../../../Components/Components/Modal';
import GameResultTitle from './GameResultTitle';
import GameResultBody from './GameResultBody';

import winSound from '../../../../../../sounds/game_win.mp3';
import loseSound from '../../../../../../sounds/game_lose.mp3';

class GameResultModal extends React.Component {
  static propTypes = {
    leaveRoom: PropTypes.func,
    closeModal: PropTypes.func.isRequired,
    closeResultModal: PropTypes.func,
    gameResult: PropTypes.shape(),
    memberUid: PropTypes.string,
    openModal: PropTypes.bool,
    modalType: PropTypes.string,
    openGameResultModal: PropTypes.bool,
    ignoredMessageName: PropTypes.string,
    t: PropTypes.func.isRequired,
    switchTabs: PropTypes.func,
    newLevel: PropTypes.string,
    closeLevelNotification: PropTypes.func.isRequired,
    setShowEndResultModal: PropTypes.func,
    soundOn: PropTypes.bool,
    players: PropTypes.shape(),
    lastRound: PropTypes.bool,
  }

  static defaultProps = {
    gameResult: null,
    modalType: '',
    ignoredMessageName: '',
    newLevel: '',
    memberUid: null,
    openModal: false,
    soundOn: false,
    players: {},
    lastRound: false,
  }

  constructor(props) {
    super(props);
    this.state = {
    //  activeTab: '1',
      playWinAnim: false,
      gameSound: 0,
    };

  //  this.toggle = this.toggle.bind(this);

    this.winAudio = new Audio(winSound);
    this.loseAudio = new Audio(loseSound);
  }

  componentDidMount() {
    // this.timeoutID = setTimeout(() => {
    //   this.closeResultModal();
    // }, 200);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  closeModal = () => {
    const { closeModal, modalType } = this.props;
    console.log('closeModal automatic call');

    closeModal();
  }

  closeResultModal = () => {
    console.log('closeResultModal');
    const { closeResultModal, closeModal, closeLevelNotification } = this.props;

    closeModal();
    closeResultModal();
  }

/*  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  } */

  getTitle = () => {
    const { t, gameResult } = this.props;

  //  console.log('getTitle', { gameResult });

    if (gameResult?.type === 'parasta') {
      return `${t('large')} ${gameResult?.winner === 'large' ? (t('wins')) : (t('loses'))}`
    }
    if (gameResult?.type === 'zole') {
      return `${t('large')} ${gameResult?.winner === 'large' ? (t('wins')) : (t('loses'))} ${t('zoli')}`
    }
    if (gameResult?.type === 'mazaZole') {
      return `${gameResult?.largePlayer} ${gameResult?.winner === 'large' ? (t('wins')) : (t('loses'))} ${t('mazoZoli')}`
    }
    if (gameResult?.type === 'galdins') {
      return `${gameResult?.winner} ${t('loses')} ${t('table')}`
    }
    if (gameResult?.type === 'pass') {
      return `${t('allPassed')}`
    }

    return null;
  }

  render() {
    const {
      t,
      gameResult,
      newLevel,
      modalType,
      openModal,

      memberUid,
      openGameResultModal,
      soundOn,
      setShowEndResultModal,
      leavePenalty,
      minGames,
      rPlayed,
      lastRound,
      players,
    } = this.props;
    const {
      activeTab,
      playWinAnim,
      gameSound,
    } = this.state;

    // if (!gameResult) {
    //   if (playWinAnim || gameSound > 0) {
    //     this.setState({ 
    //       playWinAnim: false, 
    //       gameSound: 0,
    //     });
    //   }
    //   return null;
    // }
    //console.log(gameResult, "gameResult----")
    if (!!gameResult && !playWinAnim) {
      setTimeout(() => {
        if (soundOn && gameSound < 1) {
          if (gameResult && gameResult?.type !== 'pass') {
            if (gameResult?.winnerUID && gameResult?.winnerUID.includes(memberUid)) {
              const playPromise = this.winAudio.play();
              if (playPromise !== undefined) {
                playPromise
                  .then(_ => {
                    // Automatic playback started!
                    // Show playing UI.
                    console.log("audio played auto");
                  })
                  .catch(error => {
                    // Auto-play was prevented
                    // Show paused UI.
                    console.log("playback prevented");
                  });
              }
            } else {
              const playPromise = this.loseAudio.play();
              if (playPromise !== undefined) {
                playPromise
                  .then(_ => {
                    // Automatic playback started!
                    // Show playing UI.
                    console.log("audio played auto");
                  })
                  .catch(error => {
                    // Auto-play was prevented
                    // Show paused UI.
                    console.log("playback prevented");
                  });
              }
            }
            this.setState({ 
              gameSound: gameSound + 1,
            });
          }
        }
        this.setState({ playWinAnim: true });
      }, 750);
    } else if (!gameResult && playWinAnim) {
      this.setState({ 
        playWinAnim: false, 
        gameSound: 0,
      });
    }

    // console.log('GameResultModal', { gameResult, minGames, rPlayed });
   // console.log('openModalModalModal', {openModal});

    return (
      <div>
        <CustomModal
          // isOpen={!openGameResultModal/* && !!gameResult*/}
          isOpen={openGameResultModal /* && !!gameResult*/}
          toggle={this.closeResultModal}
          size="lg"
          title={<GameResultTitle gameResult={gameResult} />}
          footer={(
            <Button className="notification-footer-button m-0" color="link" onClick={this.closeResultModal}>{t('ok')}</Button>
          )}
          addClassName={`game-result-body ${gameResult?.type === 'pass' && 'game-result-body-pass'}`}
          body={
            <div className="textAlign">
              <GameResultBody
                players={players}
                gameResult={gameResult}
                minGames={minGames}
                rPlayed={rPlayed}
                playWinAnim={playWinAnim}
                memberUid={memberUid}
                lastRound={lastRound}
              />
            </div>
          }
        />

      {/*  <Modal container={'div > div'} isOpen={openGameResultModal} toggle={this.closeResultModal} className="notification gameResultNotification">
          <ModalHeader
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeResultModal} />
            }
          >
            <>
              {gameResult?.type === 'parasta' && (
                `${t('large')} ${gameResult?.winner === 'large' ? (t('wins')) : (t('loses'))}`
              )}
              {gameResult?.type === 'zole' && (
                `${t('large')} ${gameResult?.winner === 'large' ? (t('wins')) : (t('loses'))} ${t('zoli')}`
              )}
              {gameResult?.type === 'mazaZole' && (
                `${gameResult?.largePlayer} ${gameResult?.winner === 'large' ? (t('wins')) : (t('loses'))} ${t('mazoZoli')}`
              )}
              {gameResult?.type === 'galdins' && (
                `${gameResult?.winner} ${t('loses')} ${t('table')}`
              )}
              {gameResult?.type === 'pass' && (
                <div>
                  {`${t('allPassed')}`}
                </div>
              )}
            </>
          </ModalHeader>
          <ModalBody className="notification-body">
            <TabContent activeTab={activeTab}>
              <>
                {gameResult?.scoreType && (
                <TabPane className="notification-body-tab" tabId="1">
                  <Row>
                    <Col sm="12">
                      <>
                        {(gameResult?.type === 'parasta' || gameResult?.type === 'zole') && (
                        <>
                          {gameResult?.scoreType === 'parasts' && gameResult?.winner === 'large' && (
                            `${t('largeWinsWith')} ${gameResult?.largePoints} ${t('points')}`
                          )}
                          {gameResult?.scoreType === 'parasts' && gameResult?.winner === 'small' && (
                            `${t('largeLosesWith')} ${gameResult?.largePoints} ${t('points')}`
                          )}
                          {gameResult?.scoreType === 'jani' && gameResult?.winner === 'large' && (
                            `${t('largeWinsWith')} ${gameResult?.largePoints} ${t('points')}`
                          )}
                          {gameResult?.scoreType === 'jani' && gameResult?.winner === 'small' && (
                            `${t('largeLosesWith')} ${gameResult?.largePoints} ${t('points')}`
                          )}
                          {gameResult?.scoreType === 'bezstikis' && gameResult?.winner === 'large' && (
                            `${t('largeWinsWith')} ${t('noTricks')}`
                          )}
                          {gameResult?.scoreType === 'bezstikis' && gameResult?.winner === 'small' && (
                            `${t('largeLosesWith')} ${t('noTricks')}`
                          )}
                        </>
                        )}
                        {gameResult?.type === 'mazaZole' && (
                        <>
                          {gameResult?.winner === 'large' && (
                            `${t('largeWins')}!`
                          )}
                          {gameResult?.winner === 'small' && (
                            `${t('smallWin')}!`
                          )}
                        </>
                        )}
                        {gameResult?.type === 'galdins' && (
                          `${gameResult?.largeTricks} ${t('tricks')} (+${gameResult?.largePoints})`
                        )}
                      </>

                    </Col>
                  </Row>
                </TabPane>
                )}

                {gameResult?.type === 'pass' && (
                  <>
                    {(minGames && minGames > rPlayed) ? (
                      <>
                        {(minGames - rPlayed) === 1 ? (
                          <div>{`${t('allPassedBodyMinGamesOne')}`}</div>
                        ) : (
                          <div>{`${t('allPassedBodyMinGames', { parties: minGames - rPlayed })}`}</div>
                        )}
                      </>
                    ) : (
                      <div>{`${t('allPassedBody')}`}</div>
                    )}

                    {(gameResult?.cardsOnTable && gameResult?.cardsOnTable.cards) ? (
                      <div className="notification-cards">
                        <div className="notification-cards-text">
                          {' '}
                          {`${t('cardsOnTable')}`}
                          {' '}
                        </div>
                        <div className={`notification-cards-card notification-cards-card-${gameResult?.cardsOnTable.cards[0]}`} />
                        <div className={`notification-cards-card notification-cards-card-${gameResult?.cardsOnTable.cards[1]}`} />
                      </div>
                    ) : null}
                  </>
                )}

                {gameResult?.type !== 'pass' && (
                <>
                  {(gameResult && gameResult?.winnerUID && gameResult?.winnerUID.includes(memberUid)) ? (
                    <div
                      className="winner-anim-image"
                    >
                      <Lottie
                        options={{
                          loop: false,
                          autoplay: false,
                          animationData: winnerAnimImg,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={202}
                        width={450}
                        isStopped={false}
                        isPaused={!playWinAnim}
                      />
                    </div>
                  ) : (
                    <div
                      className="winner-anim-image"
                    >
                      <Lottie
                        options={{
                          loop: false,
                          autoplay: false,
                          animationData: loserAnimImg,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={202}
                        width={450}
                        isStopped={false}
                        isPaused={!playWinAnim}
                      />
                    </div>
                  )}
                </>
                )}
              </>
            </TabContent>
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="notification-footer-button" color="link" onClick={this.closeResultModal}>{t('ok')}</Button>
          </ModalFooter>
        </Modal>  */}
      </div>
    );
  }
}

export default withTranslation('notifications')(GameResultModal);
