import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import ReactGA from 'react-ga';

import { connect } from 'react-redux';

import classNames from 'classnames';

import Col from 'reactstrap/lib/Col';
import Label from 'reactstrap/lib/Label';
import Media from 'reactstrap/lib/Media';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';

import * as constants from '../../../../../constants/constants';

import lvFlag from '../../../../../images/redesign/flags/lv.svg';
import ruFlag from '../../../../../images/redesign/flags/ru.svg';
import enFlag from '../../../../../images/redesign/flags/en.svg';

import {
  setUserLoginStatistics
} from '../../../../../actions/member';

const languagesList = ['lv', 'ru', 'en'];

class LanguageSelect extends PureComponent {
  static propTypes = {
    userSettings: PropTypes.shape(),
    changeSound: PropTypes.func,
    mobileNavigation: PropTypes.bool,
  }

  static defaultProps = {
    match: null,
    history: {},
    mobileNavigation: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      selectorOpen: false,
      curLang: null,
      isInbox: false,
    };
  }

  componentDidMount() {
    const { i18n } = this.props;
    const curLang = i18n.language;

    this.setState({ curLang: curLang || 'lv' });

    if (window && window.location) {
      const { hostname } = window.location;

      if (hostname === 'inbox.spelezoli.lv' || hostname === 'inbox.dev.spelezoli.lv') {
        this.setState({ isInbox: true });
      }
    }
  }

  toggleLanguage = (lang) => {
    const { i18n, setUserLoginStats } = this.props;

    this.setState({ curLang: lang });

    i18n.changeLanguage(lang);

    ReactGA.event({
      category: 'Language',
      action: 'Change Language',
    });

    try {
      if (window && window.localStorage && window.localStorage.setItem) { //x
        localStorage.setItem('language', lang); //x
      }
    } catch (err) {
      console.log(err);
    }

    setUserLoginStats(false, null, lang, null, null, null);
  }

  toggleLanguageSelector = () => {
    this.setState(prevState => ({
      selectorOpen: !prevState.selectorOpen,
    }));
  }

  renderLanguage = (lang) => {
    if (lang === 'lv') {
      return <Media src={lvFlag} className="language-image" />;
    } if (lang === 'ru') {
      return <Media src={ruFlag} className="language-image" />;
    } if (lang === 'en') {
      return <Media src={enFlag} className="language-image" />;
    }
  }

  renderDropLanguage = (lang) => {
    const { curLang } = this.state;
    if (lang === 'lv') {
      return <div><Label className={classNames('language-label', {'language-label-active': curLang === constants.sendLanguage.lv})}>Latviski</Label><Media src={lvFlag} className="language-image" /></div>;
    } if (lang === 'ru') {
      return <div><Label className={classNames('language-label', {'language-label-active': curLang === constants.sendLanguage.ru})}>Русский</Label><Media src={ruFlag} className="language-image" /></div>;
    } if (lang === 'en') {
      return <div><Label className={classNames('language-label', {'language-label-active': curLang === constants.sendLanguage.en})}>English</Label><Media src={enFlag} className="language-image" /></div>;
    }
  }

  renderMobileDropLanguage = (lang) => {
    const { curLang } = this.state;
    if (lang === 'lv') {
      return <Media src={lvFlag} className="language-image" />;
    } if (lang === 'ru') {
      return <Media src={ruFlag} className="language-image" />;
    } if (lang === 'en') {
      return <Media src={enFlag} className="language-image" />;
    }
  }

  render = () => {
    const { mobileNavigation } = this.props;
    const { selectorOpen, curLang, isInbox } = this.state;

    if (!curLang) {
      return null;
    }

    return (
      !mobileNavigation ? (
        <div className={`language ${isInbox ? 'language-inbox' : null}`}>
          <Col className="language-select">
            <Dropdown isOpen={selectorOpen} toggle={this.toggleLanguageSelector}>
              <DropdownToggle caret>
                {this.renderLanguage(curLang)}
              </DropdownToggle>
              <DropdownMenu>
                {languagesList.map(langKey => <DropdownItem key={langKey} onClick={(e) => { this.toggleLanguage(langKey); }}>{this.renderDropLanguage(langKey)}</DropdownItem>)}
              </DropdownMenu>
            </Dropdown>
          </Col>
        </div>
      ) : (
        <div className="mobile-navigation-language">
          {languagesList.map(langKey => <div key={langKey} onClick={(e) => { this.toggleLanguage(langKey); }}>{this.renderMobileDropLanguage(langKey)}</div>)}
        </div>
      )
    );
  }
}

const mapDispatchToProps = {
  setUserLoginStats: setUserLoginStatistics,
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(LanguageSelect));
// export default withTranslation('common')(LanguageSelect);
