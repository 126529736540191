import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import NavLink from 'reactstrap/lib/NavLink';

import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';

import CustomDate from '../../Components/Components/CustomDate';

import {
  getPointsHistory,
} from '../../../../../actions/member';
import { SCREEN_MODE } from '../../../../../constants/constants';
import config from '../../../../../constants/config';

class PointsHistory extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fetchPointsHistory: PropTypes.func.isRequired,
    pointsHistory: PropTypes.arrayOf(PropTypes.shape({})),
    offset: PropTypes.number,
    screenMode: PropTypes.string,
  }

  static defaultProps = {
    pointsHistory: [],
    offset: 0,
    screenMode: SCREEN_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
    };
  }

  toggle = async (tab) => {
    const { fetchPointsHistory, offset } = this.props;

    if (tab === '1') {
      await fetchPointsHistory('today', offset);
    } else if (tab === '2') {
      await fetchPointsHistory('yesterday', offset);
    } else if (tab === '3') {
      await fetchPointsHistory('2daysBefore', offset);
    }

    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      pointsHistory, screenMode, t,
    } = this.props;

    const { activeTab } = this.state;

    const isInAppFrame = config.isInAppFrame();
    const tableColAppFrameClass = isInAppFrame ? 'player-history-table-col-in-app-frame' : '';

    return (
      <Fragment>
        <Row className="player-history-tabs" style={{ marginBottom: 10 }}>
          <Col sm="12">
            <div className="player-history-tab-link">
              <NavLink
                className={`player-history-tab-link-text ${(activeTab === '1') && `player-history-tab-link-text-active-${screenMode}`}`}
                onClick={() => { this.toggle('1'); }}
              >
                {t('moneyHistory.today')}
              </NavLink>
            </div>
            <div className="player-history-tab-link">
              <NavLink
                className={`player-history-tab-link-text ${(activeTab === '2') && `player-history-tab-link-text-active-${screenMode}`}`}
                onClick={() => { this.toggle('2'); }}
              >
                {t('moneyHistory.yesterday')}
              </NavLink>
            </div>
            <div className="player-history-tab-link">
              <NavLink
                className={`player-history-tab-link-text ${(activeTab === '3') && `player-history-tab-link-text-active-${screenMode}`}`}
                onClick={() => { this.toggle('3'); }}
              >
                {t('moneyHistory.2daysAgo')}
              </NavLink>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Row className="player-history-table-header">
              <Col sm="2" className="player-history-table-header-col">
                {t('moneyHistory.time')}
              </Col>
              <Col sm="4" className="player-history-table-header-col">
                {t('moneyHistory.type')}
              </Col>
              <Col className="player-history-table-header-col">
                {t('moneyHistory.old')}
              </Col>
              <Col className="player-history-table-header-col">
                {t('moneyHistory.new')}
              </Col>
              <Col className="player-history-table-header-col">
                {t('moneyHistory.change')}
              </Col>
            </Row>
            <div
              className="player-history-table-scrollarea"
              contentClassName="player-history-table-body"
              show
              rightOffset={0}
              topOffset={23}
              bottomOffset={-3}
              style={isInAppFrame ? {height: '63vh'} : {}}
            >
              {(pointsHistory && pointsHistory.length > 0) ? pointsHistory.map((data, index) => (
                <Row key={data.key} className={`player-history-table-row d-flex justify-content-center align-items-center ${index % 2 === 0 ? ('odd') : ('even')} ${isInAppFrame && 'player-history-table-row-in-app-frame'}`} style={{minHeight: '4vmin', height: 'auto'}}>
                  <Col xs="2" sm="2" className={`player-history-table-col ${tableColAppFrameClass}`}>
                    <CustomDate format="hh:mm" date={data.time} />
                  </Col>
                  <Col xs="4" sm="4" className={`player-history-table-col player-history-table-col-highlight ${tableColAppFrameClass}`}>
                    {data.type === 'game' && (
                      t('moneyHistory.gameResult')
                    )}
                    {data.type === 'missTurnMe' && (
                      t('moneyHistory.missTurnMe')
                    )}
                    {data.type === 'missTurnOther' && (
                      t('moneyHistory.missTurnOther')
                    )}
                    {data.type === 'leftRoom' && (
                      t('moneyHistory.leftRoom')
                    )}
                    {data.type === 'leftRoomOther' && (
                      t('moneyHistory.leftRoomOther')
                    )}
                    {data.type === 'adminChange' && (
                      t('moneyHistory.adminChange')
                    )}
                    {data.type === 'endRoomPules' && (
                      t('moneyHistory.endRoomPules')
                    )}
                    {data.type === 'pointsReset' && (
                      t('moneyHistory.pointsReset')
                    )}
                    {data.type === 'profileReset' && (
                      t('moneyHistory.profileReset')
                    )}
                  </Col>
                  <Col className={`player-history-table-col ${tableColAppFrameClass}`}>
                    {data.old}
                  </Col>
                  <Col className={`player-history-table-col ${tableColAppFrameClass}`}>
                    {data.new}
                  </Col>
                  <Col className={`player-history-table-col player-history-table-col-last ${tableColAppFrameClass}`}>
                    <div className={`${data.change > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative'}`}>
                      {data.change}
                    </div>
                  </Col>
                </Row>
              )) : <div className="player-points-history-empty-entries">{((activeTab === '1') ? t('pointsHistory.todayHistoryEmptyEntries') : (activeTab === '2') ? t('pointsHistory.yesterdayHistoryEmptyEntries') : t('pointsHistory.twoDaysAgoHistoryEmptyEntries'))}</div>}
            </div>
          </Col>
        </Row>


      </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  pointsHistory: state.member.pointsHistory,
  offset: state.member.offset || 0,
});

const mapDispatchToProps = {
  fetchPointsHistory: getPointsHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(PointsHistory));
