import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import { signUp } from '../actions/member';
import {
  FONT_MODE,
} from '../constants/constants';

class SignUp extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    history: PropTypes.func.isRequired,
    member: PropTypes.shape({}).isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    toggleFont: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    fontMode: PropTypes.string,
  }

  static defaultProps = {
    fontMode: FONT_MODE.normal,
  }

  /* state = {
    error: null,
    success: null,
    loading: false,
  } */

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
    //  fontmode: FONT_MODE.normal,
    };
  }

  componentDidMount = () => {
    console.log('componentDidMount signup container');
  }

  componentDidUpdate = () => {
    console.log('componentDidUpdate signup container');
  }

  onFormSubmit = (data) => {
    const { onFormSubmit, i18n } = this.props;

    this.setState({ loading: true });

    const { language } = i18n;

    console.log('onFormSubmit', language);

    return onFormSubmit(data, language)
      .then((res) => {
        if (res.data) {
          this.setState({
            loading: false,
            success: 'auth/reg-success',
            error: null,
          });
        } else {
          this.setState({
            loading: false,
            success: null,
            error: res.code,
          });
        }
      }).catch((err) => {
        this.setState({
          loading: false,
          success: null,
          error: err,
        });
        throw err; // To prevent transition back
      });
  }

  render = () => {
    const {
      member, Layout, history, toggleFont, fontMode,
    } = this.props;
    const { error, loading, success } = this.state;

    console.log('render signup container', { props: this.props });

    return (
      <Layout
        error={error}
        member={member}
        loading={loading}
        success={success}
        history={history}
        onFormSubmit={this.onFormSubmit}
        toggleFont={toggleFont}
        fontMode={fontMode}
      />
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = {
  onFormSubmit: signUp,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(SignUp));
