import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { tournamentTutorialText } from '../../../constants/tournamentTutorial';
// import ScrollArea from 'react-scrollbar';
// import ScrollAreaButtons from '../UI/ScrollAreaButtons';
import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import closeImg from '../../../images/icons/close.png';

import {
  FONT_MODE,
} from '../../../constants/constants';

class TournamentTutorial extends React.PureComponent {
  static propTypes = {
    showTournamentTutorial: PropTypes.bool,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }),
    t: PropTypes.func.isRequired,
    toggleShowTournamentTutorial: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
  }

  static defaultProps = {
    showTournamentTutorial: false,
    fontMode: FONT_MODE.normal,
    i18n: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      // scrollPos: 0,
    };
  }

  updateScrollPos = (val) => {
  //  console.log('updateScrollPos', { val });
    if (val.topPosition || val.topPosition === 0) {
      // this.setState({ scrollPos: val.topPosition || 0 });
    }
  //  this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.tournamentTutorialScrollbar.scrollYTo(newVal);
  }

  render() {
    const {
      t,
      i18n,
      showTournamentTutorial,
      toggleShowTournamentTutorial,
      fontMode,
    } = this.props;

    // const { scrollPos } = this.state;

    return (
      <Modal container={'div > div'} returnFocusAfterClose={false} size="lg" isOpen={showTournamentTutorial} toggle={toggleShowTournamentTutorial} className={`old-modal root-font-${fontMode}`} >
        <ModalHeader
          toggle={toggleShowTournamentTutorial}
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={toggleShowTournamentTutorial} />
          }
        >
          {t('tournaments.tutorial')}
        </ModalHeader>
        <ModalBody>

          <Row className="tournaments-tutorial-info-modal">
            <Col>
              <ScrollAreaWrapper
                className="tournaments-tutorial-table-scrollarea"
                contentClassName="tournaments-tutorial-table-body"
                show
                rightOffset={12}
                topOffset={0}
                bottomOffset={0}
              >
                <div>

                  <div dangerouslySetInnerHTML={{ __html: i18n.language === 'lv' ? tournamentTutorialText.lv : i18n.language === 'en' ? tournamentTutorialText.en : i18n.language === 'ru' ? tournamentTutorialText.ru : tournamentTutorialText.lv}}/>

                </div>
              </ScrollAreaWrapper>
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter>
          <Button color="link" className="modal-footer-button" onClick={toggleShowTournamentTutorial}>{t('tournaments.close')}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation('common')(TournamentTutorial);
