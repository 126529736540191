import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

// import LinkifyWithTargetBlank from '../../../UI/LinkifyWithTargetBlank';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

const SplitMessage = React.memo(({ splitFilteredMessage, splitMessage, message, fontMode, inlineClassName }) => {

  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const onHover = (key) => {
    if (!popoverOpen) {
      setPopoverOpen(key)
    }
  }

  const onHoverLeave = () => {
    setPopoverOpen(false)
  }

  return (
    <div className={inlineClassName}>
      <span>
        {`${(message.userName.split(" "))[0]} ${((message.userName.split(" "))[1]) ? (((message.userName.split(" "))[1])?.charAt(0)) : ''}`}
      </span>
      {splitFilteredMessage.map((row, index) => {
        let words = [];
        if (splitMessage[index]) {
          words = splitMessage[index].split(' ');
        }
        const filteredWords = row.split(' ');

        return (
          <p key={index} style={{ marginBottom: 0, /*lineHeight: '20px',*/ display: `${index === 0 ? 'inline-block' : 'block'}` }}>
            {filteredWords.map((word, index2) => (
              <>
                {(words && words[index2] !== word) ? (
                  <>
                    <div
                      style={{ display: 'inline-block', marginRight: 2 }}
                      id={`chat-popover-${message.messageId}-${index2}`}
                      onMouseEnter={() => onHover(`${message.messageId}-${index2}`)}
                      onMouseLeave={() => onHoverLeave()}
                    >
                      {`${word} `}
                    </div>
                    <Popover container={'div > div'} popperClassName="last-round-popover new-chat-rude-popover" placement="top" isOpen={popoverOpen === `${message.messageId}-${index2}`} target={`chat-popover-${message.messageId}-${index2}`}>
                      <PopoverBody className="last-round-popover-body">
                        {/*  {`${words[index2]}`} */}
                        {`${message.message}`}
                      </PopoverBody>
                    </Popover>
                  </>
                ) : (
                  <>
                    {`${word} `}
                  </>
                )}
              </>
            ))}
          </p>
        );
      })}
    </div>
  );
});

export default withTranslation('chat')(SplitMessage);
