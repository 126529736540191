import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import classNames from 'classnames';
import { orderBy, reverse, get, size, filter, map, } from 'lodash';

// import MenuRow from '../Components/MenuRow';

import { useTranslation } from 'react-i18next';

import defaultImage from '../../../../../../images/redesign/common/default_image.svg';
import normalDefaultImage from '../../../../../../images/redesign/normal-mode/common/default_image.webp';
import lightDefaultImage from '../../../../../../images/redesign/light-mode/common/default_image.svg';
import tutorialImage from '../../../../../../images/icons/help2.webp';
import filterIcon from '../../../../../../images/redesign/rooms-table/filterIcon.svg';
import lightFilterIcon from '../../../../../../images/redesign/light-mode/rooms-table/filterIcon.png';
import ratingImage from '../../../../../../images/redesign/common/rating.svg';
import coinImage from '../../../../../../images/redesign/common/coin.svg';

import * as constants from '../../../../../../constants/constants';

const TopPageMyPos = React.memo(({
  myLeaderboard, renderRating, openFilter, screenMode, showFields
}) => {
  const { t } = useTranslation('common');
  return (
    <Fragment>
      {myLeaderboard && (
        <div className="layout-body-main-table-header" style={{backgroundColor: 'unset'}}>
          <div className='layout-body-main-table-header-wrapper' style={{paddingBottom: 0, paddingLeft: 10}}>
            <div className="top-table-row-text-self-place">
              {myLeaderboard.position}
              {console.log("myLeaderboard: ", myLeaderboard)}
              <div className="top-table-row-player-image-frame">
                <Media className={`top-table-row-player-image ${myLeaderboard.photo ? '' : 'top-table-row-player-image-default'}`} src={myLeaderboard.photo ? myLeaderboard.photo : (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage)} />
              </div>
            </div>
            <div className="top-table-row-text-self-player">
              <div className="top-table-row-player">
                <div className="top-table-row-text">
                  {myLeaderboard.name}
                </div>
                <div className="top-table-row-text" style={{marginLeft: 5}}>
                  {renderRating(t, myLeaderboard.lvl || 1600)}
                </div>
              </div>
            </div>
            
            <div className="top-table-row-text-self-rating">
              <div className="menu-table-row-player-icon-wrapper ">
                <Media src={ratingImage} className="menu-table-row-player-icon" />
                <div className="menu-table-row-player-icon-text">
                  {myLeaderboard.rating || 1600}
                </div>
              </div>
            </div>
            <div className="top-table-row-text-self-balance">
              <div className="menu-table-row-player-icon-wrapper">
                <Media src={coinImage} className="menu-table-row-player-icon" />
                <div className="menu-table-row-player-icon-text">
                  {myLeaderboard.balance}
                </div>
              </div>
            </div>
            <div className="top-table-row-text-self-points">
              {myLeaderboard.points}
            </div>
            <div className="top-table-row-text-self-rounds">
              {myLeaderboard.gamesPlayed}
            </div>
            {
              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) !== -1 && (
                <div className="top-table-row-text-self-gwon">
                  {myLeaderboard.gWon || 0}
                </div>
              )
            }
            {
              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) !== -1 && (
                <div className="top-table-row-text-self-bgwon">
                  {myLeaderboard.bgWon || 0}
                </div>
              )
            }
            {
              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.sgWon) !== -1 && (
                <div className="top-table-row-text-self-sgwon">
                  {myLeaderboard.sgWon || 0}
                </div>
              )
            }
            {
              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.tblsWon) !== -1 && (
                <div className="top-table-row-text-self-tblswon">
                  {myLeaderboard.tblsWon || 0}
                </div>
              )
            }
            {
              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.achvCnt) !== -1 && (
                <div className="top-table-row-text-self-achvcnt">
                  {myLeaderboard.achvCnt || 0}
                </div>
              )
            }
            {
              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) !== -1 && (
                <div className="top-table-row-text-self-maxrndingame">
                  {myLeaderboard.maxRndInGame || 0}
                </div>
              )
            }
            <div style={{display: 'block', minWidth: 120, maxWidth: 120}}/>
          </div>
        </div>
      )}
    </Fragment>
  );
});

TopPageMyPos.propTypes = {
  myLeaderboard: PropTypes.shape(),
  t: PropTypes.func.isRequired,
  renderRating: PropTypes.func.isRequired,
  openFilter: PropTypes.func.isRequired,
};

TopPageMyPos.defaultProps = {
  myLeaderboard: null,
};

export default TopPageMyPos;
