import React from 'react';

import { map, size, filter } from 'lodash';

import { Media } from 'reactstrap';
import { useTranslation } from 'react-i18next';


import DateFormat from '../../UI/DateFormat';
import * as constants from '../../../../constants/constants';
import TextEditor from '../../TextEditor/TextEditor';

const OtherBlogs = ({ otherBlogs, handleReadArticle, elementWidth }) => {
  const { i18n, t } = useTranslation('admin');
  return (
    otherBlogs && size(otherBlogs) > 0 && Object.keys(otherBlogs).slice(0, constants.BLOG_SHOW_TYPES.firstSection).map((key, index) => (
      <div className="blogs-page-first-section-item d-flex align-items-start flex-column mb-4" key={key} style={elementWidth ? {width: elementWidth} : {}}>
        <div className="blog-image sm-img cursor-pointer" onClick={() => handleReadArticle(otherBlogs[key].key)}>
          <Media className="blog-image-bg" src={otherBlogs[key].imageUrl} alt="x"/>
          <div className="blog-image-dg" />
        </div>
        <div className="title sm-title mt-2 cursor-pointer" onClick={() => handleReadArticle(otherBlogs[key].key)}>
          {
            i18n.language === constants.sendLanguage.en ?
              otherBlogs[key].titleEN
              : i18n.language === constants.sendLanguage.lv ?
                otherBlogs[key].titleLV
                : i18n.language === constants.sendLanguage.ru ?
                  otherBlogs[key].titleRU
                  : otherBlogs[key].titleLV
          }
        </div>
        <div className="description sm-description mt-2">
          <TextEditor viewEditorState={otherBlogs[key][constants.BLOG_RICH_TEXT[i18n.language || constants.sendLanguage.lv]]} readOnly />
        </div>
        {/* <div className="footer mt-4">
          <Button className="read-article">{t('home.readArticle')}</Button>
        </div> */}
      </div>
    ))
  );
};

export default OtherBlogs;
