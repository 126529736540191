import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import classNames from 'classnames';

import Moment from 'react-moment';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

/*
import {
  Table, Column, HeaderCell, Cell, ColumnGroup,
} from 'rsuite-table';

import 'rsuite-table/dist/css/rsuite-table.css'; */

import MenuRow from '../../../Components/Components/MenuRow';
import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';
import * as constants from '../../../../../../constants/constants';

import {
  getGamesHistory,
} from '../../../../../../actions/member';

class GamesHistory extends React.Component {
  static propTypes = {
    gamesHistory: PropTypes.arrayOf(PropTypes.shape({})),
  //  userSettings: PropTypes.shape({
   //   soundOn: PropTypes.bool,
   // }),
    //  i18n: PropTypes.shape(),
    achievementsNotification: PropTypes.bool,
    //  isFullscreen: PropTypes.bool,
    //  isFullscreenEnabled: PropTypes.bool,
    fetchGamesHistory: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    gamesHistory: [],
  //  userSettings: {},
    //  i18n: null,
    achievementsNotification: false,
    //  isFullscreen: false,
    //  isFullscreenEnabled: false,
  }

  constructor(props) {
    super(props);
    this.state = {
    };

    this.scrollAreaRef1 = React.createRef();
    this.scrollAreaRef2 = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillMount() {
    const { fetchGamesHistory } = this.props;

    fetchGamesHistory().then((res) => {
      console.log('results test 7', { res });
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    try {
      window.removeEventListener('resize', this.updateWindowDimensions);
    } catch (err) {
      console.log('err componentWillUnmount');
    }
  }

  updateWindowDimensions = () => {
    console.log('updateWindowDimensions');
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  updateScrollPos = (val) => {
    if (this.messagesScrollbar2) {
      setTimeout(() => {
        if (this.messagesScrollbar2) {
          this.messagesScrollbar2.scrollXTo(val.leftPosition);
        }
      }, 0);
    }

    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (x, y) => {
    if (this.datesRef && this.tableRef) {
      this.datesRef.scrollTop(-y);
      this.tableRef.scrollTop(-y);
    }
  }

  scroll2 = (x, y) => {
    if (this.datesRef && this.tableRef && this.tableRef.scrollY !== y) {
      this.tableRef.scrollTop(-y);
    }
  }


  playButtonSound = () => {
    const { soundOn } = this.props;

    if (soundOn) {
      this.buttonClickedAudio.play();
    }
  }

  getLoses = (rowData) => {
    const wins = rowData.wins || 0;
    const loses = rowData.loses || 0;

    const winsPercentage = Math.round((wins / (wins + loses)) * 100);
    let losesPercentage = Math.round((loses / (wins + loses)) * 100);

    if ((winsPercentage + losesPercentage) > 100) {
      losesPercentage -= 1;
    } else if ((winsPercentage + losesPercentage) < 100) {
      losesPercentage += 1;
    }

    return (
      <div>
        {rowData.loses ? (`${(wins + loses) ? (`${losesPercentage}%`) : 0}`) : 0}
      </div>
    );
  }

  handleScroll1 = (value) => {
    if (this.scrollAreaRef1.current && this.scrollAreaRef2.current) {
      this.scrollAreaRef2.current.state.eventType = 'api';
      if (this.scrollAreaRef1.current.state.eventType !== 'api') {
        if (value.topPosition /*|| value.topPosition === 0*/) {
          this.scrollAreaRef2.current.scrollArea.scrollYTo(value.topPosition);
        }
        this.scrollAreaRef2.current.state.eventType = undefined;
      }
    }
  };

  handleScroll2 = (value) => {
    if (this.scrollAreaRef1.current && this.scrollAreaRef2.current) {
      this.scrollAreaRef1.current.state.eventType = 'api';
        if (this.scrollAreaRef2.current.state.eventType !== 'api') {
          if (value.topPosition /*|| value.topPosition === 0*/) {
            this.scrollAreaRef1.current.scrollArea.scrollYTo(value.topPosition);
          }
          this.scrollAreaRef1.current.state.eventType = undefined;
        }
      }
  };

  render() {
    const {
      gamesHistory, changeTab, t, achievementsNotification, // i18n, isFullscreen, innerHeight, isFullscreenEnabled,
    } = this.props;

    return (
      <>
        <Helmet>
          <title>
            Zole - {t('myInfo.results')}
          </title>
        </Helmet>
        <div className="layout-body">
          <div className="layout-body-top">
            <Row className="layout-subheader">
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.myInfo)}>
                  {t('common:myInfo.myInfo')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.friends)}>
                  {t('common:myInfo.friends')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.ignoredUsers)}>
                  {t('common:myInfo.ignoredPlayers')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className={classNames("layout-subheader-link-text")} onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}>
                  {t('common:myInfo.achievements')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text my-info-results-button-desktop layout-subheader-link-text-active" onClick={() => changeTab(constants.MENU_NAVIGATION.gameHistory)}>
                  {t('common:myInfo.results')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text " onClick={() => changeTab(constants.MENU_NAVIGATION.weeklyStatus)}>
                  {t('common:myInfo.weeklyStatus')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.gameLogs)}>
                  {t('common:myInfo.gameLogs')}
                </Button>
              </div>
            </Row>
          </div>
          <div className="layout-body-main my-info-body-main" style={{display: 'block'}}>
            <div style={{display: 'inline-block', width: '120px', height: 'calc(100% - 10px)', verticalAlign: 'top'}}>
                <div
                  className="my-info-results-scrollarea-body-header"
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', height: 60, backgroundColor: 'rgba(0, 0, 0, 0.95)', paddingLeft: 15, paddingRight: 15, }}
                >
                  <Col xs="12" className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 w-100" style={{lineHeight: '76px', minWidth: '100px'}}>
                    {t('results.date')}
                  </Col>
                </div>

              <ScrollAreaWrapper
                className="my-info-results-scrollarea-body"
                contentClassName="my-info-results-scrollarea-body-content"
                show
                scrollbarId="top-page-scrollbar"
                rightOffset={12}
                topOffset={0}
                bottomOffset={0}
                horizontal={false}
                vertical
                scrollRef={this.scrollAreaRef1}
                onScroll={this.handleScroll1}
                hideScrollBar
              >

                {gamesHistory && gamesHistory.map(dayHistory => (
                  <MenuRow key={dayHistory.date} fitContent>
                      <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textAlign: 'center' }}>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: 'unset', minWidth: 'unset'}}>
                          {(!dayHistory.date || dayHistory.date === 'total') ? (
                            <div style={{paddingLeft: 15, paddingRight: 15}}>
                              {t('results.total')}
                            </div>

                          ) : (
                            <div style={{paddingLeft: 15, paddingRight: 15}}>
                              <Moment format="DD.MM" locale="lv">
                                {dayHistory.date}
                              </Moment>
                            </div>
                          )}
                        </Col>
                      </div>
                      </MenuRow>
                ))}
              </ScrollAreaWrapper>
            </div>
            
            <div className="my-info-results-scrollarea" style={{display: 'inline-block', width: 'calc(100% - 120px)'}}>
              <div style={{ width: 'fit-content', minWidth: '100%' }} className="h-100">
                <div className="my-info-results-scrollarea-body-header" style={{
                  whiteSpace: 'nowrap', overflow: 'hidden', height: 60, backgroundColor: 'rgba(0, 0, 0, 0.95)', paddingLeft: 15, paddingRight: 15,
                }}
                >
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{ lineHeight: '3vmin', width: '6.15%' }}>
                    {t('results.wins')}
                    <Row>
                      <Col xs="6">
                        #
                      </Col>
                      <Col xs="6">
                        %
                      </Col>
                    </Row>
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{ lineHeight: '3vmin', width: '6.15%' }}>
                    {t('results.loses')}
                    <Row>
                      <Col xs="6">
                        #
                      </Col>
                      <Col xs="6">
                        %
                      </Col>
                    </Row>
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                    {t('results.rooms')}
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                    {t('results.parties')}
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                    {t('results.coins')}
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                    {t('results.totalMoney')}
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                    {t('results.points')}
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100" style={{width: '6.15%'}}>
                      {t('results.wins')}
                      <br />
                      ({t('results.asSmall')})
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100" style={{width: '6.15%'}}>
                      {t('results.wins')}
                      <br />
                      ({t('results.asLarge')})
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100" style={{width: '6.15%'}}>
                      {t('results.loses')}
                      <br />
                      ({t('results.asSmall')})
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100" style={{width: '6.15%'}}>
                      {t('results.loses')}
                      <br />
                      ({t('results.asLarge')})
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                      {t('results.wins')}
                      <br />
                      ({t('results.zole')})
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                      {t('results.loses')}
                      <br />
                      ({t('results.zole')})
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                      {t('results.wins')}
                      <br />
                      ({t('results.table')})
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                      {t('results.loses')}
                      <br />
                      ({t('results.table')})
                  </Col>
                  <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 menu-table-row-text-last" style={{width: '6.15%'}}>
                    {t('results.rating')}
                  </Col>
                </div>
                <ScrollAreaWrapper
                  className="my-info-results-scrollarea-body"
                  contentClassName="my-info-results-scrollarea-body-content"
                  show
                  scrollbarId="top-page-scrollbar"
                  rightOffset={12}
                  topOffset={12}
                  bottomOffset={0}
                  horizontal={false}
                  vertical
                  scrollRef={this.scrollAreaRef2}
                  onScroll={this.handleScroll2}
                  // externalScrollPos={scrollPosition2}
                >
                  {gamesHistory && gamesHistory.map(dayHistory => (
                    <MenuRow key={dayHistory.date} fitContent>
                      <div key={dayHistory.date} style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        {/* <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90">
                          {(!dayHistory.date || dayHistory.date === 'total') ? (
                            t('results.total')
                          ) : (
                            <div>
                              <Moment format="DD.MM" locale="lv">
                                {dayHistory.date}
                              </Moment>
                            </div>
                          )}
                        </Col> */}
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                          <Row>
                            <Col xs="6" className="menu-table-row-text menu-table-row-text-breakspaces">
                              {dayHistory.wins ? dayHistory.wins : 0}
                            </Col>
                            <Col xs="6" className="menu-table-row-text menu-table-row-text-breakspaces">
                              {dayHistory.wins ? (`${(dayHistory.wins + (dayHistory.loses || 0)) ? (`${Math.round((dayHistory.wins / (dayHistory.wins + dayHistory.loses)) * 100)}%`) : 0}`) : 0}
                            </Col>
                          </Row>
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                          <Row>
                            <Col xs="6" className="menu-table-row-text menu-table-row-text-breakspaces">
                              {dayHistory.loses ? dayHistory.loses : 0}
                            </Col>
                            <Col xs="6" className="menu-table-row-text menu-table-row-text-breakspaces">
                              {this.getLoses(dayHistory)}
                            </Col>
                          </Row>
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                          {dayHistory.roomsPlayed ? dayHistory.roomsPlayed : 0}
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                          {(dayHistory.wins + dayHistory.loses) ? (dayHistory.wins + dayHistory.loses) : 0}
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                          {dayHistory.balChange ? dayHistory.balChange : 0}
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                          {dayHistory.totalMoney ? dayHistory.totalMoney : (dayHistory.balChange ? dayHistory.balChange : 0)}
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                          <div className={classNames({ 'menu-table-row-text-positive': (dayHistory.pointsChange >= 0 || !dayHistory.pointsChange), 'menu-table-row-text-negative': dayHistory.pointsChange < 0, 'm-auto': true })}>
                            {dayHistory.pointsChange ? dayHistory.pointsChange : 0}
                          </div>
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100" style={{width: '6.15%'}}>
                          {dayHistory.smallWins ? dayHistory.smallWins : 0}
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100" style={{width: '6.15%'}}>
                          {dayHistory.largeWins ? dayHistory.largeWins : 0}
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100" style={{width: '6.15%'}}>
                          {dayHistory.smallLoses ? dayHistory.smallLoses : 0}
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100" style={{width: '6.15%'}}>
                          {dayHistory.largeLoses ? dayHistory.largeLoses : 0}
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                          {dayHistory.zoleWins ? dayHistory.zoleWins : 0}
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                          {dayHistory.zoleLoses ? dayHistory.zoleLoses : 0}
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                          {dayHistory.galdinsWins ? dayHistory.galdinsWins : 0}
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90" style={{width: '6.15%'}}>
                          {dayHistory.galdinsLoses ? dayHistory.galdinsLoses : 0}
                        </Col>
                        <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 menu-table-row-text-last" style={{width: '6.15%'}}>
                          <div className={classNames({ 'menu-table-row-text-positive': (dayHistory.rating >= 0 || !dayHistory.rating), 'menu-table-row-text-negative': dayHistory.rating < 0, 'm-auto': true })}>
                            {dayHistory.rating ? dayHistory.rating : 0}
                          </div>
                        </Col>

                      </div>
                    </MenuRow>
                  ))}
                </ScrollAreaWrapper>
              </div>
            </div>
          </div>
        </div >
      </>
    );
  }
}

const mapStateToProps = state => ({
  soundOn: state.userSettings.soundOn,
  gamesHistory: state.member.gamesHistory || [],
  gamesHistoryTotal: state.member.gamesHistoryTotal || {},
});

const mapDispatchToProps = {
  fetchGamesHistory: getGamesHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(GamesHistory));
