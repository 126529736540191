import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';
import CustomModal from '../Components/Components/Modal';

// import closeImg from '../../../../images/icons/close.png';
// import lightCloseImg from '../../../../images/icons/close.png';

import winSound from '../../../../sounds/game_win.mp3';
import loseSound from '../../../../sounds/game_lose.mp3';
import * as constants from '../../../../constants/constants';

class Notification extends React.Component {
  static propTypes = {
    leaveRoom: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  //  gameResult: PropTypes.shape(),
  //  memberUid: PropTypes.string,
    openModal: PropTypes.bool,
    modalType: PropTypes.string,
  //  openGameResultModal: PropTypes.bool,
    ignoredMessageName: PropTypes.string,
    t: PropTypes.func.isRequired,
    switchTabs: PropTypes.func.isRequired,
    newLevel: PropTypes.string,
    oldLevel: PropTypes.string,
    buyMoney: PropTypes.func.isRequired,
    closeLevelNotification: PropTypes.func.isRequired,
  //  setShowEndResultModal: PropTypes.func.isRequired,
  //  soundOn: PropTypes.bool,
    insufficientBalanceAmount: PropTypes.number,
    insufficientBalanceBet: PropTypes.string,
    leavePenalty: PropTypes.number,
    screenMode: PropTypes.string,
    endResultWasClosed: PropTypes.bool,
    toggleGiftsModal: PropTypes.func,
  }

  static defaultProps = {
  //  gameResult: null,
    modalType: '',
    ignoredMessageName: '',
    newLevel: '',
    oldLevel: '',
  //  memberUid: null,
    openModal: false,
  //  soundOn: false,
    insufficientBalanceAmount: null,
    insufficientBalanceBet: null,
    leavePenalty: null,
    screenMode: constants.SCREEN_MODE.normal,
    levels: '',
  }

  constructor(props) {
    super(props);
    this.state = {
    //  activeTab: '1',
      levels: '',
      leaveReason: '',
      curLength: 0,
    };

    this.closeModal = this.closeModal.bind(this);
    this.closeLevelUpNotification = this.closeLevelUpNotification.bind(this);
    this.buyMoney = this.buyMoney.bind(this);

    this.winAudio = new Audio(winSound);
    this.loseAudio = new Audio(loseSound);
  }

  closeModal() {
    const { closeModal, modalType } = this.props;
    console.log('closeModal');

    if (modalType === 'levelUp') {
      this.closeLevelUpNotification();
    } else {
      closeModal();
    }
  }

  closeLevelUpNotification() {
    const { closeLevelNotification, closeModal } = this.props;

    closeLevelNotification().then(() => {
      closeModal();
    });
  }

  buyMoney() {
    const { closeModal, switchTabs } = this.props;
    switchTabs(constants.MENU_NAVIGATION.buyPageMoneyPacks);
    closeModal();
  }

  render() {
    const {
      leaveRoom,
      t,
      i18n,
    //  gameResult,
      buyMoney,
      newLevel,
      oldLevel,
      modalType,
      openModal,
      ignoredMessageName,
      insufficientBalanceAmount,
      insufficientBalanceBet,
    //  memberUid,
    //  openGameResultModal,
    //  soundOn,
      leavePenalty,
      screenMode,
      endResultWasClosed,
      toggleGiftsModal,
    } = this.props;

    const { levels, } = this.state;

    const title = modalType === 'levelUp' ? t('common:common.congratulations') : t('gameInfo');
    let levelsArr = [];
    if (modalType === 'levelUp' && levels === '') {
      // let initialLevel = oldLevel ? oldLevel : newLevel - 1;
      const extraDot = i18n.language === 'lv' ? '.' : '';
      const initialLevel = (oldLevel && oldLevel < newLevel) ? oldLevel : newLevel - 1;
      for (let i = initialLevel + 1; i <= newLevel; i++) {
        levelsArr.push(`${i}${extraDot}`);
      }
      this.setState({ levels: levelsArr.join(', ') });
    }

    return (
      <div>
        <CustomModal
          isOpen={(openModal && modalType) /*&& !endResultWasClosed*/}
          toggle={this.closeModal}
          size="lg"
          verticalCentering
          largeText
          title={title}
          addClassName="game-notification-modal"
          footer={(
            <div className="notification-footer">
              {modalType && modalType === 'leaveRoom' && (
                <Fragment>
                  <Button className="notification-footer-button" onClick={leaveRoom}>{t('yes')}</Button>
                  <Button className="notification-footer-button" onClick={this.closeModal}>{t('no')}</Button>
                </Fragment>
              )}

              {modalType && modalType === 'leaveRoomNotStarted' && (
                <Fragment>
                  <Button className="notification-footer-button" onClick={leaveRoom}>{t('yes')}</Button>
                  <Button className="notification-footer-button" onClick={this.closeModal}>{t('no')}</Button>
                </Fragment>
              )}

              {modalType && (modalType === 'proRoomMenu' || modalType === 'proRoom' || modalType === 'proBetMenu' || modalType === 'emailNotVerified') && (
                <Button className="notification-footer-button" color="primary" onClick={this.closeModal}>{t('ok')}</Button>
              )}

              {modalType && modalType === 'noBalance' && (
                <Button className="notification-footer-button" color="primary" onClick={this.closeModal}>{t('buyMoney')}</Button>
              )}

              {modalType && modalType === 'lowBalance' && (
                <Fragment>
                  <Button className="notification-footer-button" color="primary" onClick={leaveRoom}>{t('common:common.leave')}</Button>
                </Fragment>
              )}

              {modalType && modalType === 'lowBalanceTournament' && (
                <Fragment>
                  <Button className="notification-footer-button" color="primary" onClick={() => buyMoney(false)}>{t('buyMoney')}</Button>
                  <Button className="notification-footer-button" color="primary" onClick={leaveRoom}>{t('common:common.leave')}</Button>
                </Fragment>
              )}

              {modalType && (modalType === 'noBalanceMenu' || modalType === 'noBalanceTournament') && (
                <Fragment>
                  <Button className="notification-footer-button" color="primary" onClick={this.buyMoney}>{t('buyMoney')}</Button>
                  <Button className="notification-footer-button" color="primary" onClick={this.closeModal}>{t('no')}</Button>
                </Fragment>
              )}

              {modalType && modalType === 'tournamentEnd' && (
                <Fragment>
                  <Link to="/" className="btn notification-footer-button">
                    {t('exit')}
                  </Link>
                </Fragment>
              )}

              {modalType && modalType === 'gameError' && (
                <Fragment>
                  <Button onClick={leaveRoom} className="btn notification-footer-button">
                    {t('exit')}
                  </Button>
                </Fragment>
              )}

              {modalType && modalType === 'lowBalanceGift' && (
                <Button className="notification-footer-button" color="primary" onClick={() => {this.closeModal(); toggleGiftsModal();}}>{t('ok')}</Button>
              )}

              {modalType && modalType === 'youIgnoredPlayer' && (
                <Button className="notification-footer-button" color="primary" onClick={this.closeModal}>{t('ok')}</Button>
              )}

              {modalType && modalType === 'playerIgnoredYou' && (
                <Button className="notification-footer-button" color="primary" onClick={this.closeModal}>{t('ok')}</Button>
              )}

              {modalType && modalType === 'levelUp' && (
                <Button className="notification-footer-button" color="primary" onClick={this.closeLevelUpNotification}>{t('ok')}</Button>
              )}
            </div>
          )}
          body={
            <div className="notification-body notification-body-new">
              {modalType && modalType === 'lowBalanceTournament' && (
                <div>
                  PIRKT
                </div>
              )}

              {modalType && modalType === 'noBalanceMenu' && (
                <div className="text-center">
                  {t('noMoneyMenuDescription', { amount: insufficientBalanceAmount, bet: insufficientBalanceBet })}
                </div>
              )}

              {modalType && modalType === 'noBalanceTournament' && (
                <div>
                  {t('noBalanceTournament')}
                </div>
              )}

              {modalType && modalType === 'proRoom' && (
                <div>
                  {t('onlyProDescription')}
                </div>
              )}

              {modalType && modalType === 'proRoomMenu' && (
                <div>
                  {t('onlyProDescription')}
                </div>
              )}

              {modalType && modalType === 'emailNotVerified' && (
                <div>
                  {t('emailNotVerified')}
                </div>
              )}

              {modalType && modalType === 'proBetMenu' && (
                <div>
                  {t('onlyProBet')}
                </div>
              )}

              {modalType && modalType === 'leaveRoom' && (
                <div className="text-center modal-large-text">
                  {t('leaveRoomWarning', { leavePenalty })}
                </div>
              )}

              {modalType && modalType === 'leaveRoomNotStarted' && (
                <div>
                  {t('leaveRoomNotStarted')}
                </div>
              )}


              {modalType && modalType === 'tournamentEnd' && (
                <div>
                  {t('tournamentEnd')}
                </div>
              )}

              {modalType && modalType === 'gameError' && (
                <div>
                  {t('gameError')}
                </div>
              )}

              {modalType && modalType === 'youIgnoredPlayer' && (
                <div>
                  {t('youIgnoredPlayer', { player: ignoredMessageName })}
                </div>
              )}

              {modalType && modalType === 'playerIgnoredYou' && (
                <div>
                  {t('playerIgnoredYou', { player: ignoredMessageName })}
                </div>
              )}

              {modalType && modalType === 'lowBalanceGift' && (
                <div>
                  {t('lowBalanceGift')}
                </div>
              )}

              {modalType && modalType === 'levelUp' && (
                <Fragment>
                  <div>
                    {t('newLevel2', { levels: levels})}
                  </div>
                </Fragment>
              )}
            </div>
          }
        />
        {/* <Modal container={'div > div'} isOpen={(openModal && modalType)} toggle={this.closeModal} className="notification">
          <ModalHeader
            toggle={this.closeModal}
            className="notification-header"
            close={
              <Media src={screenMode === constants.SCREEN_MODE.light ? lightCloseImg : closeImg} className="notification-header-close" alt="X" onClick={this.closeModal} />
            }
          />
          <ModalBody className="notification-body">
            {modalType && modalType === 'lowBalanceTournament' && (
              <div>
                Pirkt naudu
              </div>
            )}

            {modalType && modalType === 'noBalanceMenu' && (
              <div>
                {t('noMoneyMenuDescription', { amount: insufficientBalanceAmount, bet: insufficientBalanceBet })}
              </div>
            )}

            {modalType && modalType === 'noBalanceTournament' && (
              <div>
                {t('noBalanceTournament')}
              </div>
            )}

            {modalType && modalType === 'proRoom' && (
              <div>
                {t('onlyProDescription')}
              </div>
            )}

            {modalType && modalType === 'proRoomMenu' && (
              <div>
                {t('onlyProDescription')}
              </div>
            )}

            {modalType && modalType === 'emailNotVerified' && (
              <div>
                {t('emailNotVerified')}
              </div>
            )}

            {modalType && modalType === 'proBetMenu' && (
              <div>
                {t('onlyProBet')}
              </div>
            )}

            {modalType && modalType === 'leaveRoom' && (
              <div>
                {t('leaveRoomWarning', { leavePenalty })}
              </div>
            )}

            {modalType && modalType === 'leaveRoomNotStarted' && (
              <div>
                {t('leaveRoomNotStarted')}
              </div>
            )}


            {modalType && modalType === 'tournamentEnd' && (
              <div>
                {t('tournamentEnd')}
              </div>
            )}

            {modalType && modalType === 'gameError' && (
              <div>
                {t('gameError')}
              </div>
            )}

            {modalType && modalType === 'youIgnoredPlayer' && (
              <div>
                {t('youIgnoredPlayer', { player: ignoredMessageName })}
              </div>
            )}

            {modalType && modalType === 'playerIgnoredYou' && (
              <div>
                {t('playerIgnoredYou', { player: ignoredMessageName })}
              </div>
            )}

            {modalType && modalType === 'lowBalanceGift' && (
              <div>
                {t('lowBalanceGift')}
              </div>
            )}

            {modalType && modalType === 'levelUp' && (
              <Fragment>
                <div>
                  {t('newLevel')}
                </div>
                <div className="levelProgress-old">
                  <Progress
                    color="success"
                    value={100}
                    className="levelProgress-old-bar"
                  />
                  <div className="levelProgress-old-level-wrapper" style={{ left: '45%' }}>
                    <div
                      className="levelProgress-old-level"
                      style={{ top: 10 }}
                    >
                      {newLevel}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </ModalBody>
          <ModalFooter className="notification-footer">

            {modalType && modalType === 'leaveRoom' && (
              <Fragment>
                <Button className="notification-footer-button" onClick={leaveRoom}>{t('yes')}</Button>
                <Button className="notification-footer-button" onClick={this.closeModal}>{t('no')}</Button>
              </Fragment>
            )}

            {modalType && modalType === 'leaveRoomNotStarted' && (
              <Fragment>
                <Button className="notification-footer-button" onClick={leaveRoom}>{t('yes')}</Button>
                <Button className="notification-footer-button" onClick={this.closeModal}>{t('no')}</Button>
              </Fragment>
            )}

            {modalType && (modalType === 'proRoomMenu' || modalType === 'proRoom' || modalType === 'proBetMenu' || modalType === 'emailNotVerified') && (
              <Button className="notification-footer-button" color="primary" onClick={this.closeModal}>{t('ok')}</Button>
            )}

            {modalType && modalType === 'noBalance' && (
              <Button className="notification-footer-button" color="primary" onClick={this.closeModal}>{t('buyMoney')}</Button>
            )}

            {modalType && modalType === 'lowBalance' && (
              <Fragment>
                <Button className="notification-footer-button" color="primary" onClick={leaveRoom}>{t('common:common.leave')}</Button>
              </Fragment>
            )}

            {modalType && modalType === 'lowBalanceTournament' && (
              <Fragment>
                <Button className="notification-footer-button" color="primary" onClick={() => buyMoney(false)}>{t('buyMoney')}</Button>
                <Button className="notification-footer-button" color="primary" onClick={leaveRoom}>{t('common:common.leave')}</Button>
              </Fragment>
            )}

            {modalType && (modalType === 'noBalanceMenu' || modalType === 'noBalanceTournament') && (
              <Button className="notification-footer-button" color="primary" onClick={this.buyMoney}>{t('buyMoney')}</Button>
            )}

            {modalType && modalType === 'tournamentEnd' && (
              <Fragment>
                <Link to="/" className="btn notification-footer-button">
                  {t('exit')}
                </Link>
              </Fragment>
            )}

            {modalType && modalType === 'gameError' && (
              <Fragment>
                <Button onClick={leaveRoom} className="btn notification-footer-button">
                  {t('exit')}
                </Button>
              </Fragment>
            )}

            {modalType && modalType === 'lowBalanceGift' && (
              <Button className="notification-footer-button" color="primary" onClick={this.closeModal}>{t('ok')}</Button>
            )}

            {modalType && modalType === 'youIgnoredPlayer' && (
              <Button className="notification-footer-button" color="primary" onClick={this.closeModal}>{t('ok')}</Button>
            )}

            {modalType && modalType === 'playerIgnoredYou' && (
              <Button className="notification-footer-button" color="primary" onClick={this.closeModal}>{t('ok')}</Button>
            )}

            {modalType && modalType === 'levelUp' && (
              <Button className="notification-footer-button" color="primary" onClick={this.closeLevelUpNotification}>{t('ok')}</Button>
            )}

          </ModalFooter>
        </Modal> */}
      </div>
    );
  }
}

export default withTranslation(['notifications', 'common'])(Notification);
