import { useState, useEffect } from 'react';
import { isMobileDevice } from '../../../utils/deviceUtils';

export function useNavBarHeight() {
    const [navBarHeight, setNavBarHeight] = useState(0);

    useEffect(() => {
        function updateNavBarHeight() {
            const heightDifference = document.documentElement.scrollHeight - window.innerHeight;
            setNavBarHeight(isMobileDevice() ? heightDifference : 0);
            // if (window.visualViewport) {
            //     const visualViewportHeight = window.visualViewport.height;
            //     const heightDifference = window.innerHeight - visualViewportHeight;
            //     setNavBarHeight(heightDifference);
            // } else {
            //     const heightDifference = document.documentElement.scrollHeight - window.innerHeight;
            //     setNavBarHeight(heightDifference);
            // }
        }

        updateNavBarHeight();

        window.addEventListener('resize', updateNavBarHeight);
        window.addEventListener('orientationchange', updateNavBarHeight);


        return () => {
            window.removeEventListener('resize', updateNavBarHeight);
            window.removeEventListener('orientationchange', updateNavBarHeight);
        };

        // window.visualViewport.addEventListener('resize', updateNavBarHeight);
        // window.addEventListener('orientationchange', updateNavBarHeight);

        // return () => {
        //     window.visualViewport.removeEventListener('resize', updateNavBarHeight);
        //     window.removeEventListener('orientationchange', updateNavBarHeight);
        // };

    }, []);

    return navBarHeight;
}