import React from 'react';
import PropTypes from 'prop-types';

import Media from 'reactstrap/lib/Media';

const CardsStrength = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
      <p>
      Jebkurš trumpis ir stiprāks par jebkuru netrumpja kārti. Tabulās kārtis sakārtotas pēc stipruma no stiprākās līdz vājākajai. Pēc stiprumā nākamā kārts pēc dūža ir desmitnieks, tad kungs.
      </p>
      <p>
      Trumpji sakārtoti dilstošā secībā pēc to stipruma:
      </p>
      <ul>
      <li>Kreica dāma,</li>
      <li>Pīķa dāma,</li>
      <li>Ercena dāma,</li>
      <li>Kārava dāma,</li>
      <li>Kreica kalps,</li>
      <li>Pīķa kalps,</li>
      <li>Ercena kalps,</li>
      <li>Kārava kalps,</li>
      <li>Kārava dūzis,</li>
      <li>Kārava desmitnieks,</li>
      <li>Kārava kungs,</li>
      <li>Kārava devītnieks,</li>
      <li>Kārava astotnieks,</li>
      <li>Kārava septītnieks</li>
      </ul>


      <p>Netrumpji sakārtoti dilstošā secībā pēc to stipruma:</p>
      <ul>
      <li>Dūzis,</li>
      <li>Desmitnieks,</li>
      <li>Kungs, </li>
      <li>Devītnieks</li>
      </ul>

      <p>
      Netrumpjiem no dažādiem mastiem nav savstarpējas ietekmes (pīķa dūzis uzlikts uz ercena devītnieka nevar to pārsist).
      </p>

      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
      <p>
        Любой козырь сильнее любой карты обычной масти. В таблицах карты расположены по старшинству от более сильных к слабым. По своей силе следующей картой за тузом следует десятка, затем король.
      </p>
      <p>
        Козыри расположены в убывающем порядке по своей силе:
      </p>
      <ul>
      <li>Крестовая Дама,</li>
      <li>Пиковая Дама,</li>
      <li>Червовая Дама,</li>
      <li>Бубновая Дама,</li>
      <li>Крестовый Валет,</li>
      <li>Пиковый Валет,</li>
      <li>Червовый Валет,</li>
      <li>Бубновый Валет,</li>
      <li>Бубновый Туз,</li>
      <li>Бубновая десятка,</li>
      <li>Бубновый Король,</li>
      <li>Бубновая девятка,</li>
      <li>Бубновая восьмерка,</li>
      <li>Бубновая семерка</li>
      </ul>

      <p>Некозыри расположены в убывающем порядке по своему старшинству:</p>
      <ul>
      <li>Туз,</li>
      <li>Десятка,</li>
      <li>Король,</li>
      <li>Девятка</li>
      </ul>

      <p>
        Некозырные карты разных мастей не имеют взаимного воздействия (пиковый туз не может перебить червовую девятку).
      </p>

      </>
    )
  } else if (lang === 'en') {
    return (
      <>
      <p>
        Any trump card can overlap the non-trump card regardless of their strength between each other. In the table below, the cards are sorted by strength from strongest to weakest. In strength, the next card after the ace is ten, then the king. The trumps are arranged in descending order according to their strength.
      </p>
      <ul>
      <li>Queen of Clubs</li>
      <li>Queen of Spades</li>
      <li>Queen of Hearts</li>
      <li>Queen of Diamonds</li>
      <li>Jack of Clubs</li>
      <li>Jack of Spades</li>
      <li>Jack of Hearts</li>
      <li>Jack of Diamonds</li>
      <li>Ace of Diamonds</li>
      <li>Ten of Diamonds</li>
      <li>King of Diamonds</li>
      <li>Nine of Diamonds</li>
      <li>Eight of Diamonds</li>
      <li>Seven of Diamonds.</li>
      </ul>


      <p>The non-trumps are arranged in descending order of strength:</p>
      <ul>
      <li>Ace,</li>
      <li>Ten,</li>
      <li>King,</li>
      <li>Nine.</li>
      </ul>

      <p>
        Non-trumps from different suits cannot leave any effect (the ace of spades placed on the ninth of the hearts cannot overdo it.
      </p>

      </>
    )
  }
};

CardsStrength.propTypes = {
  lang: PropTypes.string,
};

CardsStrength.defaultProps = {
  lang: 'lv',
};

export default CardsStrength;
