import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import CustomModal from '../../../Components/Components/Modal';

import Info from './Info';

import tutorialImage from '../../../../../../images/redesign/icons/info.svg';
import lightTutorialImage from '../../../../../../images/redesign/light-mode/icons/info.png';
import filterIcon from '../../../../../../images/redesign/rooms-table/filterIcon.svg';
import lightFilterIcon from '../../../../../../images/redesign/light-mode/rooms-table/filterIcon.png';

import * as constants from '../../../../../../constants/constants';
import { filter } from 'lodash';
import IconPopover from '../../../Components/Components/IconPopover';

class Header extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    openFilter: PropTypes.func.isRequired,
    openCreate: PropTypes.func.isRequired,
    disableCreate: PropTypes.bool,
    screenMode: PropTypes.string,
    //fontMode: PropTypes.string,
  }

  static defaultProps = {
    disableCreate: false,
    screenMode: constants.SCREEN_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
    };
  }

  componentWillMount() {
  }

  openInfo = () => {
    this.setState({ infoModalOpen: true });
  }

  closeInfo = () => {
    this.setState({ infoModalOpen: false });
  }

  onHover = () => {
    this.setState({
      popoverOpen: true,
    });
  }

  onHoverLeave = () => {
    this.setState({
      popoverOpen: false,
    });
  }

  render() {
    const {
      t,
      openFilter,
      openCreate,
      disableCreate,
      screenMode,
      //fontMode,
      activeTournament,
      finishedTournamentLastRound,
    } = this.props;

    const { infoModalOpen, popoverOpen } = this.state;
    console.log("screen mode in header: ", screenMode)

    return (
      <>
        <Row className="layout-subheader">
          <Col xs="6">
            {/**IMPORTANT: this wrapper is required, so in old Chrome versions onMouseLeave is triggered when button is disabled (otherwise popover does not close) */}
            <div
              onMouseEnter={() => this.onHover()}
              onMouseLeave={() => this.onHoverLeave()}
              id="newGame-button-dWeb-new"
              style={{width: 'max-content'}}
            >
              <Button
                type='button'
                color="link"
                className={classNames('layout-highlighted-button create-room-button', {
                  // 'disabled': disableCreate,
                })}
                onClick={openCreate}
                disabled={disableCreate}
              >
              {t('roomsTable.createRoom')}
            </Button>
            </div>

            <IconPopover text={t('common.inTournamentMessage')} popoverOpen={popoverOpen && (activeTournament && !finishedTournamentLastRound)} targetId="newGame-button-dWeb-new" placement="top" />

            {/* {(disableCreate) ? (
              <Popover container={'div > div'} popperClassName={`popover popover-font-${fontMode}`} placement="top" isOpen={popoverOpen} target="newGame-button" className={`new-design-popover-${screenMode}`}>
                <PopoverBody className={`new-design-popover-${screenMode}`}>
                  <div>
                    {t('common.inTournamentMessage')}
                  </div>
                </PopoverBody>
              </Popover>
            ) : (null)} */}
          </Col>
          <Col xs="6" className="layout-float-right rooms-table-header-tools">

            <Media color="link" className="layout-float-right rooms-table-header-tutorial" src={screenMode === constants.SCREEN_MODE.dark ? tutorialImage : (screenMode === constants.SCREEN_MODE.light ? lightTutorialImage : tutorialImage)} onClick={this.openInfo} />
            <div className="layout-float-right layout-divider mr-2" />
            <Button color="link" className={`layout-float-right layout-link-button rooms-table-filter-button layout-link-button-${screenMode}`} onClick={openFilter}>
              {t('roomsTable.filterRooms')}
            </Button>
            <div className="layout-float-right rooms-table-filter-icon-wrapper">
              <Media color="link" className="rooms-table-filter-icon" onClick={openFilter} src={screenMode === constants.SCREEN_MODE.dark ? filterIcon : (screenMode === constants.SCREEN_MODE.light ? lightFilterIcon : filterIcon)} />
            </div>
          </Col>
        </Row>

        <CustomModal
          isOpen={infoModalOpen}
          toggle={this.closeInfo}
          size="md"
          title={t('roomsTable.roomInfo')}
          footer={<>
            <Button color="link" className="modal-footer-button" onClick={this.closeInfo}>{t('common.close')}</Button>
          </>}
          body={(<Info screenMode={screenMode} />)}
        />

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  //  ignoredUsers: state.member.ignoredUsers || {},
});

const mapDispatchToProps = {
  //  unBlockUserFunc: unBlockUser,
  //  fetchIgnoredPlayers: getIgnoredPlayers,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Header));
