import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

// import ScrollArea from 'react-scrollbar';
import { filter, map, includes } from 'lodash';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Label from 'reactstrap/lib/Label';
import TabPane from 'reactstrap/lib/TabPane';
import TabContent from 'reactstrap/lib/TabContent';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import NavLink from 'reactstrap/lib/NavLink';
import DropdownItem from 'reactstrap/lib/DropdownItem';
// import ScrollAreaButtons from '../../UI/ScrollAreaButtons';

import CustomDropdown from '../../Components/Components/CustomDropdown';
import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';

import WhatIsTab from './Tabs/WhatIsTab';

import Cards from './Tabs/Cards';
import CardsStrength from './Tabs/CardsStrength';
import CardsValue from './Tabs/CardsValue';

import ChooseStage from './Tabs/ChooseStage';
import PlayStage from './Tabs/PlayStage';

import RoomTypes from './Tabs/RoomTypes';
import RoomBets from './Tabs/RoomBets';
import MinGames from './Tabs/MinGames';

import Pules from './Tabs/Pules';
import PointsCounting from './Tabs/PointsCounting';

import WhatAreGameCoins from './Tabs/WhatAreGameCoins';
import GameCoinsAndPoints from './Tabs/GameCoinsAndPoints';
import GameJoinFee from './Tabs/GameJoinFee';
import DailyBonus from './Tabs/DailyBonus';
import LeaveRoomPenalty from './Tabs/LeaveRoomPenalty';
import Bonuses from './Tabs/Bonuses';

import Store from './Tabs/Store';
import StoreCoins from './Tabs/StoreCoins';
import StorePremium from './Tabs/StorePremium';

import IgnorePlayers from './Tabs/IgnorePlayers';
import Achievements from './Tabs/Achievements';
import Friends from './Tabs/Friends';

import Tournaments from './Tabs/Tournaments';
import Ratings from './Tabs/Ratings';

import Gifts from './Tabs/Gifts';

import AdministrationChat from './Tabs/AdministrationChat';
import GameChat from './Tabs/GameChat';

import ViewLastRound from './Tabs/ViewLastRound';

import MoneyHistory from './Tabs/MoneyHistory';
import PointsHistory from './Tabs/PointsHistory';

import Recommendations from './Tabs/Recommendations';
import Prohibitions from './Tabs/Prohibitions';

import * as constants from '../../../../../constants/constants';

const tabsNav = [
  { tabId: '1', name: 'whatIsZole' },
  {
    tabId: '2',
    name: 'rules',
    children: [
      { tabId: '2.1', name: 'cards' },
      { tabId: '2.2', name: 'cardsStrength' },
      { tabId: '2.3', name: 'cardsValue' },
    ],
  },
  {
    tabId: '3',
    name: 'gameFlow',
    children: [
      { tabId: '3.1', name: 'chooseStage' },
      { tabId: '3.2', name: 'playStage' },
    ],
  },
  {
    tabId: '4',
    name: 'roomTypesAndBets',
    children: [
      { tabId: '4.1', name: 'roomTypes' },
      { tabId: '4.2', name: 'roomBet' },
      { tabId: '4.3', name: 'minGames' },
    ],
  },
  { tabId: '5', name: 'pules' },
  { tabId: '6', name: 'pointsCounting' },
  {
    tabId: '7',
    name: 'gameCoins',
    children: [
      { tabId: '7.1', name: 'whatAreGameCoins' },
      { tabId: '7.2', name: 'gameCoinsAndPoints' },
      { tabId: '7.3', name: 'gameJoinFee' },
      { tabId: '7.4', name: 'dailyBonus' },
      { tabId: '7.5', name: 'leaveRoomPenalty' },
      { tabId: '7.6', name: 'bonuses' },
    ],
  },
  {
    tabId: '8',
    name: 'store',
    content: Store,
    children: [
      { tabId: '8.1', name: 'storeCoins' },
      { tabId: '8.2', name: 'storePremium' },
    ],
  },
  { tabId: '9', name: 'ignorePlayers' },
  { tabId: '10', name: 'achievements' },
  { tabId: '11', name: 'friends' },
  { tabId: '12', name: 'tournaments' },
  { tabId: '13', name: 'ratings' },
  { tabId: '14', name: 'gifts' },
  {
    tabId: '15',
    name: 'chat',
    children: [
      { tabId: '15.1', name: 'administrationChat' },
      { tabId: '15.2', name: 'gameChat' },
    ],
  },
  { tabId: '16', name: 'viewLastRound' },
  {
    tabId: '17',
    name: 'moneyAndPointsHistory',
    children: [
      { tabId: '17.1', name: 'moneyHistory' },
      { tabId: '17.2', name: 'pointsHistory' },
    ],
  },
  { tabId: '18', name: 'recommendations' },
  { tabId: '19', name: 'prohibitions' },
];

const tabs = [
  { tabId: '1', name: 'whatIsZole', content: WhatIsTab },
  { tabId: '2.1', name: 'cards', content: Cards },
  { tabId: '2.2', name: 'cardsStrength', content: CardsStrength },
  { tabId: '2.3', name: 'cardsValue', content: CardsValue },
  { tabId: '3.1', name: 'chooseStage', content: ChooseStage },
  { tabId: '3.2', name: 'playStage', content: PlayStage },
  { tabId: '4.1', name: 'roomTypes', content: RoomTypes },
  { tabId: '4.2', name: 'roomBet', content: RoomBets },
  { tabId: '4.3', name: 'minGames', content: MinGames },
  { tabId: '5', name: 'pules', content: Pules },

  { tabId: '6', name: 'pointsCounting', content: PointsCounting },
  { tabId: '7.1', name: 'whatAreGameCoins', content: WhatAreGameCoins },
  { tabId: '7.2', name: 'gameCoinsAndPoints', content: GameCoinsAndPoints },
  { tabId: '7.3', name: 'gameJoinFee', content: GameJoinFee },
  { tabId: '7.4', name: 'dailyBonus', content: DailyBonus },
  { tabId: '7.5', name: 'leaveRoomPenalty', content: LeaveRoomPenalty },
  { tabId: '7.6', name: 'bonuses', content: Bonuses },
  { tabId: '8', name: 'store', content: Store },
  { tabId: '8.1', name: 'storeCoins', content: StoreCoins },
  { tabId: '8.2', name: 'storePremium', content: StorePremium },
  { tabId: '9', name: 'ignorePlayers', content: IgnorePlayers },
  { tabId: '10', name: 'achievements', content: Achievements },
  { tabId: '11', name: 'friends', content: Friends },
  { tabId: '12', name: 'tournaments', content: Tournaments },
  { tabId: '13', name: 'ratings', content: Ratings },
  { tabId: '14', name: 'gifts', content: Gifts },
  { tabId: '15.1', name: 'administrationChat', content: AdministrationChat },
  { tabId: '15.2', name: 'gameChat', content: GameChat },
  { tabId: '16', name: 'viewLastRound', content: ViewLastRound },
  { tabId: '17.1', name: 'moneyHistory', content: MoneyHistory },
  { tabId: '17.2', name: 'pointsHistory', content: PointsHistory },
  { tabId: '18', name: 'recommendations', content: Recommendations },
  { tabId: '19', name: 'prohibitions', content: Prohibitions },
];

// const RulesPage = ({ toggleHelpTab, helpTab, scrollPos, scroll, handleScroll, updateScrollPos, setScrollRef }) => {

class RulesPage extends React.Component {
  static propTypes = {
    userSettings: PropTypes.shape(),
    uid: PropTypes.string,
    title: PropTypes.string,
    modalFlag: PropTypes.bool,
  }

  static defaultProps = {
    uid: '',
    errorNotification: '',
    title: null,
    modalFlag: false,
  }

  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      helpTab: '1',
      typeFilterOpen: false,
      pageHeight: 0,
      pageWidth: 0,
    };
  }

  componentWillMount() {

  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const updatePageHeight = () => {
      const height = window.innerHeight;
      const width = window.innerWidth;
      this.setState({ pageHeight: height, pageWidth: width });
    };

    // Call the function initially to get the initial page height
    updatePageHeight();

    // Attach an event listener to window resize to update the height if the page changes
    window.addEventListener('resize', updatePageHeight);
  }

  componentWillUnmount() {
    try {
      window.removeEventListener('resize', this.updatePageHeight);
    } catch (err) {
      console.log('err componentWillUnmount');
    }
  }

  toggleHelpTab = (tab, index, isChild) => {
    const { playButtonSound } = this.props;
    const { helpTab, helpTabChild } = this.state;

    playButtonSound();

    if (!isChild) {
      const selectedTab = tabsNav[index];

      if (selectedTab.children) {
        if (selectedTab.content) {
          if (helpTab !== tab) {
            this.setState({
              helpTabParent: tab,
              helpTab: tab,
              helpTabChild: null,
            });
          }
        } else if (helpTab !== tab) {
          this.setState({
            helpTabParent: tab,
            helpTab: selectedTab.children[0].tabId,
            helpTabChild: selectedTab.children[0].tabId,
          });
        }
      } else if (helpTab !== tab) {
        this.setState({
          helpTab: tab,
          helpTabParent: null,
          helpTabChild: null,
        });
      }
    } else if (helpTabChild !== tab) {
      this.setState({
        helpTab: tab,
        helpTabChild: tab,
      });
    }
  }

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.messagesScrollbar.scrollYTo(newVal);
  }

  setScrollRef = (el) => {
    this.messagesScrollbar = el;
  }

  toggleTypeFilter = () => {
    this.setState(prevState => ({
      filterOpen: false,
      typeFilterOpen: !prevState.typeFilterOpen,
    }));
  }

  toggleType = (key) => {
    const { helpTab } = this.state;
    if (key && helpTab !== key) {
      this.setState({
        helpTab: key,
      });
    }
  }

  render() {
    const {
      t,
      toggleHelp,
      i18n,
      title,
      modalFlag,
    } = this.props;

    const {
    //  tabs,
    //  tabsNav,
      helpTab,
      helpTabChild,
      helpTabParent,
      scrollPos,
      typeFilterOpen,
      pageHeight,
      pageWidth,
    } = this.state;

    return (
      <div className="zole-rules-container">
        <Row className="zole-rules-page">
          {
            title && (
              <Col md="12">
                <h1 className="zole-rules-page-title">{t(title)}</h1>
              </Col>
            )
          }
          <Col sm="12" className='zole-rules-page-mobile-header'>
            <div className="zole-rules-page-header-tab">
              <CustomDropdown
                toggle={
                  `${filter(tabs, ['tabId', helpTab.toString()])[0].tabId}.${t(filter(tabs, ['tabId', helpTab.toString()])[0].name)}`
                  }
                toggleFunc={this.toggleTypeFilter}
                isOpen={typeFilterOpen}
                menu={(
                  <>
                    {map(tabs, item => (
                      <DropdownItem key={item.tabId} onClick={() => this.toggleType(item.tabId)}>
                        {`${item.tabId}. ${t(item.name)}`}
                      </DropdownItem>
                    ))}
                  </>
                  )}
                inlineMenuClassName={`landing-help-page-dropdown-list ${modalFlag && 'menu-rule-drop-down'}`}
              />
            </div>
          </Col>

          {
            tabs.map((tab, index) => (
              (helpTab === tab.tabId)
              ?
              <Col md="12">
                <h2 className="zole-rules-page-sub-title">{t(tab.name)}</h2>
              </Col>
              :
              <>{console.log("helpTab: ", tab)}</>
            ))
          }
          <Col sm="3" className="zole-rules-page-header">
            <Row className="zole-rules-page-header-tab">
              <div
                className={`contact-support-chat-body-scroll-area contact-support-chat-body-scroll-area-content zole-rules-page-body-scroll-area ${!modalFlag && 'contact-support-chat-body-scroll-area-limited'}`}
                show
                rightOffset={5}
                topOffset={0}
                bottomOffset={0}
              >
                {tabsNav.map((tab, index) => (
                  <>
                    <Col sm={{ size: 10, offset: 1 }} className="zole-rules-page-tab">
                      <div className={`zole-rules-page-tab-wrapper ${helpTab === tab.tabId && 'active'}`}>
                        <NavLink
                          className={`zole-rules-page-header-link ${helpTab === tab.tabId && 'zole-rules-page-header-link-active'}`}
                          onClick={() => this.toggleHelpTab(tab.tabId, index, false)}
                        >
                          {`${tab.tabId}. ${t(tab.name)}`}
                        </NavLink>
                      </div>
                    </Col>
                    {(helpTabParent && helpTabParent === tab.tabId && tab.children) ? (
                      <>
                        {tab.children.map((childTab, index2) => (
                          <Col sm={{ size: 10, offset: 2 }} className="zole-rules-page-tab zole-rules-page-tab-child">
                            <div className={`zole-rules-page-tab-wrapper ${helpTabChild === childTab.tabId && 'active'}`}>
                              <NavLink
                                className={`zole-rules-page-header-link ${helpTabChild === childTab.tabId && 'zole-rules-page-header-link-active'}`}
                                onClick={() => this.toggleHelpTab(childTab.tabId, index2, true)}
                              >
                                {`${childTab.tabId}. ${t(childTab.name)}`}
                              </NavLink>
                            </div>
                          </Col>
                        ))}
                      </>
                    ) : (null)}
                  </>
                ))}
              </div>
            </Row>
          </Col>
          <Col sm="9" className="zole-rules-page-content" style={{ paddingRight: 5 }}>
            <TabContent className="zole-rules-page-content-tab" activeTab={helpTab}>
              {map(tabs, item => (
                <>
                  <TabPane tabId={item.tabId}>
                    <div className={classNames({"help-content": !modalFlag, "menu-help-content": modalFlag})}>
                      <div
                        // className="contact-support-chat-body-scroll-area contact-support-chat-body-scroll-area-content  zole-rules-page-body-scroll-area"
                        // show
                        // rightOffset={5}
                        // topOffset={0}
                        // bottomOffset={0}
                      >
                        <Row>
                          <Col className="zole-rules-page-content-wrapper">
                            {item.content({ lang: i18n.language || constants.sendLanguage.lv })}
                          </Col>
                        </Row>
                      </div>
                    </div>
                    {
                      !modalFlag && (
                        <div className="mobile-help-content">
                          <Row>
                            <Col className="zole-rules-page-content-wrapper">
                              <ScrollAreaWrapper
                                show
                                rightOffset={2}
                                topOffset={0}
                                bottomOffset={0}
                                vertical={true}
                                height={pageWidth <= pageHeight ? pageHeight - 250 : pageHeight - 200}
                              >
                                {item.content({ lang: i18n.language || constants.sendLanguage.lv })}
                              </ScrollAreaWrapper>
                            </Col>
                          </Row>
                        </div>
                      )
                    }
                  </TabPane>

                </>
              ))}

            </TabContent>

          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation('rulesPage')(RulesPage);
