import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { get } from 'lodash';

import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';

import Media from 'reactstrap/lib/Media';
import classNames from 'classnames';

import CustomPopover from '../../../Components/Components/CustomPopover';

import * as constants from '../../../../../../constants/constants';

import coin from '../../../../../../images/redesign/common/coin.svg';
import rating from '../../../../../../images/redesign/common/rating.svg';
import HelpIcon from '../../../../../../images/icons/help2.svg';
import HelpLightIcon from '../../../../../../images/redesign/light-mode/player/info.svg';
import EmotionIcon from '../../../../../../images/redesign/player/emotions.svg';
import EmotionLightIcon from '../../../../../../images/redesign/light-mode/player/emotions.svg';
import UpdateProfile from '../../../User/UpdateProfile';
import defaultImage from '../../../../../../images/redesign/common/default_image.svg';
import lightDefaultImage from '../../../../../../images/redesign/light-mode/common/default_image.svg';
//
import proIcon from '../../../../../../images/icons/proIcon.svg';

const PlayerProfile = React.memo(({
    member,
    requestDeleteUser,
    updateProfile,
    userAvatarFileUpload,
    toggle,
    balance,
    activeGifts,
    gifts,
    screenMode,
    toggleScreenMode,
    t,
}) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const STYLE_PLAYER_BALANCE = classNames({
        'player-info-player-balance': balance > constants.PLAYER_ZOLE_RANGE.min,
        'player-info-player-balance-orange': balance >= constants.PLAYER_ZOLE_RANGE.maxmin && balance <= constants.PLAYER_ZOLE_RANGE.min,
        'player-info-player-balance-red': balance < constants.PLAYER_ZOLE_RANGE.maxmin,
    });

    const STYLE_COIN_BLINK = classNames('ml-2', {
        'player-info-player-balance-icon': balance > constants.PLAYER_ZOLE_RANGE.min,
        'player-info-player-balance-icon blink': balance <= constants.PLAYER_ZOLE_RANGE.min,
    });

    const onHover = () => {
        if (balance <= constants.PLAYER_ZOLE_RANGE.min) {
            setPopoverOpen(true);
        }
    };

    const onHoverLeave = () => {
        setPopoverOpen(false);
    };

    const switchTab = (key) => {
        toggle(key);
    };

    // const levelToMark = (level) => {
    //     const { t } = this.props;
    //     if (level < 10) {
    //         return t('myInfo.weak');
    //     } else if (level >= 10 && level < 20) {
    //         return t('myInfo.beginner');
    //     } else if (level >= 20 && level < 30) {
    //         return t('myInfo.untrained');
    //     } else if (level >= 30 && level < 40) {
    //         return t('myInfo.medium');
    //     } else if (level >= 40 && level < 50) {
    //         return t('myInfo.good')
    //     } else if (level >= 50 && level < 60) {
    //         return t('myInfo.great')
    //     } else if (level >= 60 && level < 70) {
    //         return t('myInfo.fan')
    //     } else if (level >= 70) {
            
    //     }
    // }

    const renderRating = (t, rating) => (
        <div className="my-info-ratings-step-wrapper">
            {rating < 10 ? (<div className="my-info-ratings-step my-info-ratings-step-weak my-info-ratings-step-active power-rankings-level-step player-info-player-mark-font">{t('myInfo.weak')}</div>) : (null)}
            {(rating >= 10 && rating < 20) ? (<div className="my-info-ratings-step my-info-ratings-step-beginner my-info-ratings-step-active power-rankings-level-step player-info-player-mark-font">{t('myInfo.beginner')}</div>) : (null)}
            {(rating >= 20 && rating < 30) ? (<div className="my-info-ratings-step my-info-ratings-step-untrained my-info-ratings-step-active power-rankings-level-step player-info-player-mark-font">{t('myInfo.untrained')}</div>) : (null)}
            {(rating >= 30 && rating < 40) ? (<div className="my-info-ratings-step my-info-ratings-step-medium my-info-ratings-step-active power-rankings-level-step player-info-player-mark-font">{t('myInfo.medium')}</div>) : (null)}
            {(rating >= 40 && rating < 50) ? (<div className="my-info-ratings-step my-info-ratings-step-good my-info-ratings-step-active power-rankings-level-step player-info-player-mark-font">{t('myInfo.good')}</div>) : (null)}
            {(rating >= 50 && rating < 60) ? (<div className="my-info-ratings-step my-info-ratings-step-great my-info-ratings-step-active power-rankings-level-step player-info-player-mark-font">{t('myInfo.great')}</div>) : (null)}
            {(rating >= 60 && rating < 70) ? (<div className="my-info-ratings-step my-info-ratings-step-fan my-info-ratings-step-active power-rankings-level-step player-info-player-mark-font">{t('myInfo.fan')}</div>) : (null)}
            {rating >= 70 ? (<div className="my-info-ratings-step my-info-ratings-step-pro my-info-ratings-step-active"><Media src={proIcon} alt="X" /></div>) : (null)}
        </div>
    );

    return (
        <div className={`player-info player-info-${screenMode}`}>
            <div className="player-info-player player-info-player-layout">
                <div className="player-info-player-background">
                    <div className="player-info-player-image-wrapper">
                        <UpdateProfile screenMode={screenMode} toggleScreenMode={toggleScreenMode} userAvatarFileUpload={userAvatarFileUpload} member={member} requestDeleteUser={requestDeleteUser} updateProfile={updateProfile} isMobile={true} />
                        {/* <div style={{ backgroundImage: member.photo ? `url(${member.photo})` : '' }} className="player-info-player-image" /> */}
                        <div style={{ backgroundImage: member.photo ? `url(${member.photo})` : (screenMode === constants.SCREEN_MODE.light ? `url(${lightDefaultImage})` : `url(${defaultImage})`), backgroundPosition: 'center', backgroundSize: member.photo ? '100%, 100%' : '80%, 93%' }} className="player-info-player-image" />

                        {/* <Media src={screenMode === constants.SCREEN_MODE.light ? HelpLightIcon : HelpIcon} className='player-info-help-icon' /> */}
                        {/* <Media src={screenMode === constants.SCREEN_MODE.light ? EmotionLightIcon : EmotionIcon} className='player-info-player-image-emotion' /> */}
                        <div className='player-info-player-mark text-uppercase'>
                            {renderRating(t, 35)}
                        </div>
                    </div>
                    <div className="player-info-player-name-wrapper">
                        <div className="player-info-player-name">
                            {member ? member.name : ''}
                        </div>
                    </div>
                    {
                        gifts && activeGifts && get(activeGifts, 'giftId', '') && (
                            <>
                                <img className="player-info-player-background-active-gift" id="player-info-active-gift" src={gifts[activeGifts.giftId].image} alt="x" />
                                <UncontrolledTooltip
                                    className="player-receivedGift-tooltip-new"
                                    placement="bottom"
                                    target='player-info-active-gift'
                                    container={'div > div'}
                                >
                                    <div className="player-receivedGift-tooltip-from">
                                        {activeGifts.fromName || ''}
                                    </div>
                                    <div className="player-receivedGift-tooltip-comment">
                                        {activeGifts.comment || ''}
                                    </div>
                                </UncontrolledTooltip>
                            </>
                        )
                    }
                </div>
            </div>

        </div>
    );
});

PlayerProfile.propTypes = {
    member: PropTypes.shape(),
    toggle: PropTypes.func.isRequired,
    balance: PropTypes.number,
    activeGifts: PropTypes.shape({}),
    gifts: PropTypes.shape({}),
    screenMode: PropTypes.string,
    toggleScreenMode: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

PlayerProfile.defaultProps = {
    member: null,
    activeGifts: null,
    gifts: null,
};

const mapStateToProps = state => ({
    member: state.member,
    balance: state.member.balance || 0,
    activeGifts: state.member.activeGifts || null,
    gifts: state.rooms.gifts,
});

export default connect(mapStateToProps, null)(PlayerProfile);
