import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ReactGA from 'react-ga';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import ControlledTooltip from 'reactstrap/lib/Tooltip';

import CustomModal from '../../../../Components/Components/Modal';
import ModalQuestion from '../../../../Components/Components/ModalQuestion';


import closeImg from '../../../../../../../images/redesign/my-info/my-info-gift-close.png';
import IconPopover from '../../../../Components/Components/IconPopover';
import { MENU_NAVIGATION } from '../../../../../../../constants/constants';

class GiftsHistory extends React.Component {
  static propTypes = {
    giftsHistory: PropTypes.shape(),
    notificationSettings: PropTypes.shape(),
    gifts: PropTypes.shape(),
    changeTab: PropTypes.func.isRequired,
    removeGift: PropTypes.func.isRequired,
    getGiftsHistory: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    giftsHistory: {},
    notificationSettings: {},
    gifts: {},
  }

  constructor(props) {
    super(props);
    this.state = {
    //  pointsProgressBar: 0,
      removeGiftPopoverOpen: null,
    };
  }

  setRemoveGiftPopoverOpen = (value) => {
    this.setState({removeGiftPopoverOpen: value});
  }

  componentDidMount() {
  //  const { member } = this.props;
  }

  startRemoveGift = (giftId) => {
    const { notificationSettings } = this.props;
    console.log('startRemoveGift', { giftId });

    if (notificationSettings && notificationSettings.removeGift === false) {
      this.approveRemove(giftId);
    } else {
      this.setState({ selectedGift: giftId, modalOpen: true });
    }
  }

  closeModal = () => {
    this.setState({ selectedGift: null, modalOpen: false });
  }

  buyMoney = () => {
    const { changeTab } = this.props;
    this.setState({ error: null, modalOpen: false, selectedGift: null });

    changeTab(MENU_NAVIGATION.buyPageMoneyPacks);
  }

  closeErrorModal = () => {
    this.setState({ error: null, modalOpen: false, selectedGift: null });
  }

  approveRemove = (passedGift) => {
    const { removeGift, getGiftsHistory } = this.props;
    const { selectedGift } = this.state;

    console.log('approveRemove', { passedGift, selectedGift });

    if (passedGift) {
      removeGift(passedGift).then((res) => {
        console.log('removeGift res', { res });

        if (res && res.data && res.data.status === 'success') {
          getGiftsHistory();

          ReactGA.event({
            category: 'Menu',
            action: 'Remove Gift',
          });
        }

        if (res && res.data && (res.data.status === 'error' && res.data.message === 'low balance')) {
          this.setState({ error: 'lowBalance', modalOpen: false, selectedGift: null });
        } else {
          this.setState({ selectedGift: null, modalOpen: false });
        }
      });
    } else if (selectedGift) {
      removeGift(selectedGift).then((res) => {
        console.log('removeGift res 2', { res });

        if (res && res.data && res.data.status === 'success') {
          getGiftsHistory();

          ReactGA.event({
            category: 'Menu',
            action: 'Remove Gift',
          });
        }

        if (res && res.data && (res.data.status === 'error' && res.data.message === 'low balance')) {
          this.setState({ error: 'lowBalance', modalOpen: false, selectedGift: null });
        } else {
          this.setState({ selectedGift: null, modalOpen: false });
        }
      });

    //  this.setState({ selectedGift: null, modalOpen: false });
    }
  }

  render() {
    const { giftsHistory, gifts, t } = this.props;
    const { modalOpen, error, removeGiftPopoverOpen } = this.state;

    return (
      <Row className="my-info-gifts mt-2">
        <Col className='d-flex flex-column'>
          <div className="my-info-gifts-title">
            {t('myInfo.giftsHistory')}
          </div>
          <div className="my-info-gifts-body">
            <Row className="mb-4 mx-0">
              {gifts && giftsHistory && Object.keys(giftsHistory).map((key, index) => (
                <div key={`${key} - ${index}`}>
                  <div className="my-info-gift-wrapper">
                    <div className="my-info-gift">

                      {giftsHistory[key] && (
                        <>
                          <div className="my-info-gift-collider" id={`my-info-gift-${key}`} />
                          <Media
                            className="my-info-gift-image"

                            src={gifts[giftsHistory[key].giftId] ? gifts[giftsHistory[key].giftId].image : ''}
                          />
                          <Media
                            className="my-info-gift-remove"
                            id={`remove-gift-popover-${index}`}
                            src={closeImg}
                            onClick={() => this.startRemoveGift(key)}
                            onMouseEnter={() => this.setRemoveGiftPopoverOpen(index)}
                            onMouseLeave={() => this.setRemoveGiftPopoverOpen(null)}
                          />
                          <IconPopover text={t('popovers.removeGift')} popoverOpen={removeGiftPopoverOpen === index} targetId={`remove-gift-popover-${index}`} placement="top"/>
                          <UncontrolledTooltip
                            className="default-tooltip player-receivedGift-tooltip-new"
                            placement="right"
                            target={`my-info-gift-${key}`}
                            container={'div > div > div'}
                          >
                            <div className="default-tooltip-from">
                              {giftsHistory[key].fromName || ''}
                            </div>
                            <div className="default-tooltip-comment">
                              {giftsHistory[key].comment || ''}
                            </div>
                          </UncontrolledTooltip>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Row>
          </div>
        </Col>

        <ModalQuestion
          isOpen={modalOpen}
          toggle={this.closeModal}
          title={t('myInfo.deleteGift')}
          footer={(
            <>
              <Button type="button" className="question-modal-approve-btn" color="link" onClick={() => this.approveRemove()}>
                {t('common.yes')}
              </Button>
              <Button type="button" className="question-modal-decline-btn" color="link" onClick={this.closeModal}>
                {t('common.no')}
              </Button>
            </>
          )}
          body={(
            <>
              {t('myInfo.deleteGiftMessage')}
            </>
          )}
        />

        {/*  <Modal container={'div > div > div'} isOpen={modalOpen} toggle={this.closeModal} className="notification">
          <ModalHeader
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeModal} />
              }
          >
            {t('myInfo.deleteGiftMessage')}
          </ModalHeader>
          <ModalFooter className="notification-footer">
            <Button type="button" className="btn notification-footer-button" color="link" onClick={() => this.approveRemove()}>
              {t('common.yes')}
            </Button>
            <Button type="button" className="btn notification-footer-button" color="link" onClick={this.closeModal}>
              {t('common.no')}
            </Button>
          </ModalFooter>
        </Modal>  */}

        <CustomModal
          isOpen={!!error}
          toggle={this.closeErrorModal}
          size="md"
          verticalCentering
          largeText
          title={t('home.notifications')}
          // title={error === 'lowBalance' ? t('myInfo.deleteGiftErrorBal') : t('myInfo.deleteGiftError')}
          footer={(
            <>
              {error === 'lowBalance' ? (
                <Button color="link" onClick={this.buyMoney}>{t('myInfo.buyMoney')}</Button>
              ) : (null)}
              <Button type="button" color="link" onClick={this.closeErrorModal}>
                {t('tournaments.close')}
              </Button>
            </>
          )}
          body={(
            <p className="text-center">
              {error === 'lowBalance' ? t('myInfo.notEnoughMoneyRemoveGift') : t('myInfo.deleteGiftError')}
            </p>
          )}
        />

        {/*  <Modal container={'div > div > div'} isOpen={!!error} toggle={this.closeErrorModal} className="notification">
          <ModalHeader
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeErrorModal} />
              }
          >
            {error === 'lowBalance' ? t('myInfo.deleteGiftErrorBal') : t('myInfo.deleteGiftError')}
          </ModalHeader>
          <ModalFooter className="notification-footer">
            {error === 'lowBalance' ? (
              <Button className="notification-footer-button" color="link" onClick={this.buyMoney}>{t('myInfo.buyMoney')}</Button>
            ) : (null)}
            <Button type="button" className="btn notification-footer-button" color="link" onClick={this.closeErrorModal}>
              {t('common.no')}
            </Button>
          </ModalFooter>
        </Modal>  */}
      </Row>
    );
  }
}


export default GiftsHistory;
