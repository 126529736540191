import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Media from 'reactstrap/lib/Media';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import { orderBy, reverse, get, size, filter, map, } from 'lodash';
import MenuRow from '../../Components/Components/MenuRow';

// import ScrollAreaWrapper from '../../../UI/ScrollAreaWrapper';

import ScrollArea from 'react-scrollbar';
import * as constants from '../../../../../constants/constants';

import defaultImage from '../../../../../images/redesign/common/default_image.svg';

// import blockImage from '../../../../../images/redesign/common/block.png';
// import unblockImage from '../../../../../images/redesign/common/unblock.png';
import coinImage from '../../../../../images/redesign/common/coin.svg';
import ratingImage from '../../../../../images/redesign/common/rating.svg';

const LandingTopList = React.memo(({
  isFiltered, leaderboard, activeType, renderRating, order, orderby, showFields, handleOrderBy,
}) => {
  const { t } = useTranslation('common');

  let activeTypeFilter;
  let activeTypeAction;
  let sortLeaderboard;

  if (isFiltered) {
    activeTypeFilter = constants.filterLeaderboardType.filter(item => item.id === activeType.toString())[0].label;
    activeTypeAction = constants.filterLeaderboardType.filter(item => item.id === activeType.toString())[0].action;
  }
  console.log('render TopPageList',leaderboard, leaderboard.length, showFields, order, orderby );
  sortLeaderboard = leaderboard;
  if (order && orderby) {
    if (orderby === constants.TOP_LEADERBOARD_ORDER.place && (order === constants.ORDER_BY_TYPE.asc || order === constants.ORDER_BY_TYPE.desc)) {
      sortLeaderboard = reverse(leaderboard);
    } else {
    // if (get(leaderboard, orderby, 0) !== 0) {
      if (orderby === constants.TOP_LEADERBOARD_ORDER.player) {
        if (order === constants.ORDER_BY_TYPE.asc) {
          sortLeaderboard.sort((a, b) => a.name.localeCompare(b.name, undefined,{ numeric: true }));
        } else {
          sortLeaderboard.sort((a, b) => a.name.localeCompare(b.name, undefined,{ numeric: true }));
          sortLeaderboard.reverse();
        }
      } else {
        sortLeaderboard = orderBy(leaderboard, [orderby.toString()], [order.toString()]);
      }
    }
    // }
  }
  const selectOptions = [];
  map(constants.TOP_LEADERBOARD_ORDER, (item) => {
    const STYLE_TOP_TABLE = classNames({
      'layout-body-main-table-header-orderby_asc_active': item === orderby && order === constants.ORDER_BY_TYPE.asc,
      'layout-body-main-table-header-orderby_desc_active': item === orderby && order !== constants.ORDER_BY_TYPE.asc,
      'layout-body-main-table-header-orderby': item !== orderby,
    });
    selectOptions[item] = {
      fieldStyle: STYLE_TOP_TABLE,
    };
  });

  return (
    <>
      
      <ScrollArea
        className="top-table-scrollarea top-table-scrollarea-body landing-top-table-scrollarea-body"
        contentClassName="top-scrollarea-body landing-top-scrollarea-body scrollarea-touch-action"
        show
        rightOffset={0}
        topOffset={28}
        bottomOffset={0}
        scrollbarId="top-page-scrollbar"
        horizontal
        vertical={false}
      >
        <div className="top-table-scrollarea-body-header landing-top-list-header">
        <div className="layout-body-main-table-header-md landing-top-list-header-position">
          {t('common.position')}
        </div>
        <div className="layout-body-main-table-header-lg landing-top-list-header-player">
          {t('top.player')}
        </div>
        <div className="layout-body-main-table-header-lsg landing-top-list-header-level">
          {t('common.level')}
        </div>
        <div className="landing-top-list-header-mobile">
          <div className="landing-top-list-header-column-rating-md">
            {t('common.ratings')}
          </div>
          <div className="landing-top-list-header-column-balance-md">
            {t('common.balance')}
          </div>
          <div className="landing-top-list-header-column-points-md">
            {t('common.points')}
          </div>
          <div className="landing-top-list-header-column-rounds-md">
            {t('common.parties')}
          </div>
          {
            showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) !== -1 && (
              <div className="landing-top-list-header-column-extra-md">
                {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.bgWon])[0].tLabel}`)}
              </div>
            )
          }
          {
            showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.sgWon) !== -1 && (
              <div className="landing-top-list-header-column-extra-md">
                {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.sgWon])[0].tLabel}`)}
              </div>
            )
          }
          {
            showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.tblsWon) !== -1 && (
              <div className="landing-top-list-header-column-extra-md">
                {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.tblsWon])[0].tLabel}`)}
              </div>
            )
          }
          {
            showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.achvCnt) !== -1 && (
              <div className="landing-top-list-header-column-extra-md">
                {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.achvCnt])[0].tLabel}`)}
              </div>
            )
          }
          {
            showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) !== -1 && (
              <div className="landing-top-list-header-column-extra-md">
                {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.maxRndInGame])[0].tLabel}`)}
              </div>
            )
          }
        </div>
        <div className="layout-body-main-table-header-lg landing-top-list-header-column landing-top-list-header-column-rating">
          {t('common.ratings')}
        </div>
        <div className="layout-body-main-table-header-md landing-top-list-header-column landing-top-list-header-balance">
          {t('common.balance')}
        </div>
        <div className="layout-body-main-table-header-md landing-top-list-header-column landing-top-list-header-points">
          {t('common.points')}
        </div>
        <div className="layout-body-main-table-header-md landing-top-list-header-column landing-top-list-header-rounds">
          {t('common.parties')}
        </div>
        {
          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) !== -1 && (
            <div className="layout-body-main-table-header-lg landing-top-list-header-column landing-top-list-header-extra">
              {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.bgWon])[0].tLabel}`)}
            </div>
          )
        }
        {
          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.sgWon) !== -1 && (
            <div className="layout-body-main-table-header-lg landing-top-list-header-column landing-top-list-header-extra">
              {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.sgWon])[0].tLabel}`)}
            </div>
          )
        }
        {
          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.tblsWon) !== -1 && (
            <div className="layout-body-main-table-header-lg landing-top-list-header-column landing-top-list-header-extra">
              {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.tblsWon])[0].tLabel}`)}
            </div>
          )
        }
        {
          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.achvCnt) !== -1 && (
            <div className="layout-body-main-table-header-lg landing-top-list-header-column landing-top-list-header-extra">
              {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.achvCnt])[0].tLabel}`)}
            </div>
          )
        }
        {
          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) !== -1 && (
            <div className="layout-body-main-table-header-lg landing-top-list-header-column landing-top-list-header-extra">
              {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.maxRndInGame])[0].tLabel}`)}
            </div>
          )
        }
      </div>
      {sortLeaderboard && sortLeaderboard.map((pos, index) => {
        if (index <= constants.TOP_LIST_LIMIT.limit && (!isFiltered || (isFiltered && activeTypeFilter && pos[activeTypeAction]))) {
          return (
            <Fragment key={pos.key}>
              <MenuRow key={pos.key}>
                <div key={pos.key} className="top-table-row">
                  <div className="top-table-row-text-md landing-top-list-body-position">
                    <div className="layout-divider" />
                    {orderby === constants.TOP_LEADERBOARD_ORDER.place && order === constants.ORDER_BY_TYPE.asc ?
                      (size(sortLeaderboard) > 100 ? (constants.TOP_LIST_LIMIT.limit + 1) - index : size(sortLeaderboard) - index)
                      : (isFiltered && activeTypeFilter) ? (pos[activeTypeAction] || null) : (index + 1)}
                  </div>
                  <div className="top-table-row-text-lg landing-top-list-body-player">
                    <div className="top-table-row-player">
                      <div className="top-table-row-player-image-frame top-table-row-player-image-frame-landing mr-2">
                        <Media className={`top-table-row-player-image ${pos.photo ? '' : 'top-table-row-player-image-default top-table-row-player-image-default-landing'}`} src={pos.photo || defaultImage} />
                      </div>
                      <div className="top-table-row-text">
                        {pos.name}
                      </div>
                      <div className="top-table-row-mobile-text-group">
                        <div className="top-table-row-mobile-first-line">
                          <div className="top-table-row-text">
                            {pos.name}
                          </div>
                          {renderRating(t, pos.lvl || 1600)}
                        </div>

                        <div className="top-table-row-mobile-second-line-landing">
                          <div className="menu-table-row-player-icon-wrapper-landing">
                            <Media src={ratingImage} className="menu-table-row-player-icon" />
                            <div className="menu-table-row-player-icon-text">
                              {pos ? (pos.rating || 1600) : '1600'}
                            </div>
                          </div>

                          <div className="menu-table-row-player-icon-wrapper-landing">
                            <Media src={coinImage} className="menu-table-row-player-icon" />
                            <div className="menu-table-row-player-icon-text">
                              {pos ? (pos.balance || 0) : '0'}
                            </div>
                          </div>

                          <div className="landing-top-list-body-column-points-md">
                            {pos.points || 0}
                          </div>

                          <div className="landing-top-list-body-column-rounds-md">
                            {pos.gamesPlayed || 0}
                          </div>

                          {
                            showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) !== -1 && (
                              <div className="landing-top-list-body-column-extra-md">
                                {pos.bgWon || 0}
                              </div>
                            )
                          }
                          {
                            showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.sgWon) !== -1 && (
                              <div className="landing-top-list-body-column-extra-md">
                                {pos.sgWon || 0}
                              </div>
                            )
                          }
                          {
                            showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.tblsWon) !== -1 && (
                              <div className="landing-top-list-body-column-extra-md">
                                {pos.tblsWon || 0}
                              </div>
                            )
                          }
                          {
                            showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.achvCnt) !== -1 && (
                              <div className="landing-top-list-body-column-extra-md">
                                {pos.achvCnt || 0}
                              </div>
                            )
                          }
                          {
                            showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) !== -1 && (
                              <div className="landing-top-list-body-column-extra-md">
                                {pos.maxRndInGame || 0}
                              </div>
                            )
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="top-table-row-text-lsg landing-top-list-body-level">
                    {renderRating(t, pos.lvl || 1600)}
                  </div>
                  <div className="top-table-row-text-lg landing-top-list-body-column landing-top-list-body-column-rating">
                    <div className="menu-table-row-player-icon-wrapper">
                      <Media src={ratingImage} className="menu-table-row-player-icon" />
                      <div className="menu-table-row-player-icon-text">
                        {pos ? (pos.rating || 1600) : '1600'}
                      </div>
                    </div>
                  </div>
                  <div className="top-table-row-text-md landing-top-list-body-column landing-top-list-body-column-balance">
                    <div className="menu-table-row-player-icon-wrapper">
                      <Media src={coinImage} className="menu-table-row-player-icon" />
                      <div className="menu-table-row-player-icon-text">
                        {pos ? (pos.balance || 0) : '0'}
                      </div>
                    </div>
                  </div>
                  <div className="top-table-row-text-md landing-top-list-body-column landing-top-list-body-column-points">
                    {pos.points || 0}
                  </div>
                  <div className="top-table-row-text-md landing-top-list-body-column landing-top-list-body-column-rounds">
                    {pos.gamesPlayed || 0}
                  </div>
                  <div className="top-table-row-text-md landing-top-list-body-column w-auto landing-top-list-body-column-btn">
                    {/* <Button type="button" className="btn rounded-circle">1</Button> */}
                  </div>
                  {
                    showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) !== -1 && (
                      <div className="top-table-row-text-md landing-top-list-body-column landing-top-list-body-column-extra">
                        {pos.bgWon || 0}
                      </div>
                    )
                  }
                  {
                    showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.sgWon) !== -1 && (
                      <div className="top-table-row-text-lg landing-top-list-body-column landing-top-list-body-column-extra">
                        {pos.sgWon || 0}
                      </div>
                    )
                  }
                  {
                    showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.tblsWon) !== -1 && (
                      <div className="top-table-row-text-lg landing-top-list-body-column landing-top-list-body-column-extra">
                        {pos.tblsWon || 0}
                      </div>
                    )
                  }
                  {
                    showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.achvCnt) !== -1 && (
                      <div className="top-table-row-text-lg landing-top-list-body-column landing-top-list-body-column-extra">
                        {pos.achvCnt || 0}
                      </div>
                    )
                  }
                  {
                    showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) !== -1 && (
                      <div className="top-table-row-text-lg landing-top-list-body-column landing-top-list-body-column-extra">
                        {pos.maxRndInGame || 0}
                      </div>
                    )
                  }
                </div>
              </MenuRow>
            </Fragment>
          );
        }
        return null;
      })}
      </ScrollArea>
    </>
  );
});

LandingTopList.propTypes = {
  leaderboard: PropTypes.shape(),
  // t: PropTypes.func.isRequired,
  renderRating: PropTypes.func.isRequired,
  isFiltered: PropTypes.bool,
  activeType: PropTypes.string,
  ignoredUsers: PropTypes.shape(),
  block: PropTypes.func.isRequired,
  unblock: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderby: PropTypes.string,
  showFields: PropTypes.arrayOf(PropTypes.string.isRequired),
  handleOrderBy: PropTypes.func.isRequired,
};

LandingTopList.defaultProps = {
  leaderboard: [],
  isFiltered: false,
  activeType: null,
  ignoredUsers: null,
  order: null,
  orderby: null,
//  type: null,
  showFields: [],
};

export default LandingTopList;
