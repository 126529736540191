import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import TextEditor from '../TextEditor/TextEditor';

import {
  Input, Row, Col, Button, TabPane, TabContent, NavLink,
  PopoverBody
} from 'reactstrap';

import {
  saveEmailTemplate,
  getTemplateData,
  updateTemplateData,
} from '../../../actions/admin';
import { UncontrolledPopover } from 'reactstrap/lib';

class EmailEditorComponent extends React.Component {
  static propTypes = {
  //  t: PropTypes.func.isRequired,
    fetchTemplateData: PropTypes.func.isRequired,
    saveTemplateData: PropTypes.func.isRequired,
  }

  static defaultProps = {
  //  error: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      name: 'default',
      activeData: {
        subject: {},
        header: {},
        text1: {},
        ignoreText: {},
        verifyText: {},
        footer1: {},
        footer2: {},
        footer3: {},
      },
      activeTab: 1,
    };

  }

  componentDidMount() {
    const { fetchTemplateData } = this.props;

  /* fetchTemplateData().then((res) => {
      if (res) {
        const { verifyEmail, resetEmail, resetPassword } = res;

        console.log('fetchTemplateData', verifyEmail, resetEmail, resetPassword);

        this.setState({ activeData: verifyEmail, verifyTemplate: verifyEmail, resetEmailTemplate: resetEmail, resetPasswordTemplate: resetPassword });
      }

    }); */

    this.fetchData();
  }

  fetchData = () => {
    const { fetchTemplateData } = this.props;
    const { activeTab } = this.state;

    fetchTemplateData().then((res) => {
      if (res) {
        const { verifyEmail, resetEmail, resetPassword } = res;

        console.log('fetchTemplateData', verifyEmail, resetEmail, resetPassword);
        if (activeTab === 1) {
          this.setState({ activeData: verifyEmail, verifyTemplate: verifyEmail, resetEmailTemplate: resetEmail, resetPasswordTemplate: resetPassword });
        } else if (activeTab === 2) {
          this.setState({ activeData: resetEmail, verifyTemplate: verifyEmail, resetEmailTemplate: resetEmail, resetPasswordTemplate: resetPassword });
        } else if (activeTab === 3) {
          this.setState({ activeData: resetPassword, verifyTemplate: verifyEmail, resetEmailTemplate: resetEmail, resetPasswordTemplate: resetPassword });
        }
        
      }

    });
  }

  exportHtml = () => {
    this.editor.exportHtml(data => {
      const { design, html } = data
      console.log('exportHtml', html);

      return html;
    })
  }

  saveDesign = () => {
    const { saveTemplate } = this.props;
    const { name } = this.state;

    this.editor.saveDesign(design => {
      console.log('saveDesign', design);

      const html = this.exportHtml();

      console.log('html', { html });

      saveTemplate(name, design, html);
    })
  }

  loadDesign = () => {
    const json = null;

    if (json) {
      this.editor.loadDesign(json);
    }
  }

  handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    this.setState({ [e.target.name]: value });
  }

  handleChangeVerify = (e, lang, type) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    console.log('handleChangeVerify', { lang, value, type });

    this.setState(prevState => ({ activeData: { ...prevState.activeData, [type]: { ...prevState.activeData[type], [lang]: value } } }));
  }

  saveTemplateData = () => {
    const { saveTemplateData } = this.props;
    const { activeTab, activeData } = this.state;

    let type;

    if (activeTab === 1) {
      type = 'verifyEmail';
    } else if (activeTab === 2) {
      type = 'resetEmail';
    } else if (activeTab === 3) {
      type = 'resetPassword';
    }

    saveTemplateData(type, activeData).then(() => {
      console.log('saveTemplateData then');
      this.fetchData();
    });
  }

  toggle = (tab) => {
    const { activeData, verifyTemplate, resetEmailTemplate, resetPasswordTemplate } = this.state;

    console.log('toggle', tab);

    if (tab === 1) {
      this.setState({ activeData: verifyTemplate, activeTab: 1 });
    } else if (tab === 2) {
      this.setState({ activeData: resetEmailTemplate, activeTab: 2 });
    } else if (tab === 3) {
      this.setState({ activeData: resetPasswordTemplate, activeTab: 3 });
    }
    
  }

  render() {
    const { verifyEmail, resetEmail } = this.props;
    const { activeTab, activeData, name, verifyTemplate, resetEmailTemplate, resetPasswordTemplate } = this.state;

    console.log('activeTab', { activeTab, activeData });

    return (
      <div>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-email-editor">
          <PopoverBody className="popover-body">
            An admin can edit the strings of the currently used email templates in all listed languages.
            <br /><br />
            Note: the changes are saved on dev, and are being deployed on the prod as other normal code changes.
          </PopoverBody>
        </UncontrolledPopover>
        <h2>
          Email Editor
          <Button className="admin-help-button" id="admin-help-button-email-editor">
            ?
          </Button>
        </h2>
        <button onClick={this.exportHtml}>Export HTML</button>
        <div> Verify Template </div>

      {/*  <TextEditor dbPath="testData/testData" languages={["lv", "en", "ru"]} readOnly={false} />  */}

        <Row>
          <Col>
            <div style={{ backgroundColor: `${activeTab === 1 ? 'buttonface' : '#495057'}` }}>
              <NavLink
                style={{ color: `${activeTab === 1 ? '#000' : '#FFF'}` }}
                onClick={() => { this.toggle(1); }}
              >
                Verify Email
              </NavLink>
            </div>
          </Col>
          <Col>
            <div style={{ backgroundColor: `${activeTab === 2 ? 'buttonface' : '#495057'}` }}>
              <NavLink
                style={{ color: `${activeTab === 2 ? '#000' : '#FFF'}` }}
                onClick={() => { this.toggle(2); }}
              >
                Reset Email
              </NavLink>
            </div>
          </Col>
          <Col>
            <div style={{ backgroundColor: `${activeTab === 3 ? 'buttonface' : '#495057'}` }}>
              <NavLink
                style={{ color: `${activeTab === 3 ? '#000' : '#FFF'}` }}
                onClick={() => { this.toggle(3); }}
              >
                Reset Password
              </NavLink>
            </div>
          </Col>
        </Row>

        {/* <TabContent className="main-tab-content" activeTab={activeTab}>
          <TabPane className="main-tab-pane" tabId={1}>

          </TabPane>

          <TabPane className="main-tab-pane" tabId={2}>

          </TabPane>

          <TabPane className="main-tab-pane" tabId={3}>

          </TabPane> */}

        <Row>
          <Col xs="12">
            Subject
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="subject"
              value={(activeData && activeData.subject) ? activeData.subject.lv : ''}
              onChange={e => this.handleChangeVerify(e, 'lv', 'subject')}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="subject"
              value={(activeData && activeData.subject) ? activeData.subject.en : ''}
              onChange={e => this.handleChangeVerify(e, 'en', 'subject')}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="subject"
              value={(activeData && activeData.subject) ? activeData.subject.ru : ''}
              onChange={e => this.handleChangeVerify(e, 'ru', 'subject')}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            header
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="header"
              value={(activeData && activeData.header) ? activeData.header.lv : ''}
              onChange={e => this.handleChangeVerify(e, 'lv', 'header')}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="header"
              value={(activeData && activeData.header) ? activeData.header.en : ''}
              onChange={e => this.handleChangeVerify(e, 'en', 'header')}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="header"
              value={(activeData && activeData.header) ? activeData.header.ru : ''}
              onChange={e => this.handleChangeVerify(e, 'ru', 'header')}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            text1
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="text1"
              value={(activeData && activeData.text1) ? activeData.text1.lv : ''}
              onChange={e => this.handleChangeVerify(e, 'lv', 'text1')}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="text1"
              value={(activeData && activeData.text1) ? activeData.text1.en : ''}
              onChange={e => this.handleChangeVerify(e, 'en', 'text1')}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="text1"
              value={(activeData && activeData.text1) ? activeData.text1.ru : ''}
              onChange={e => this.handleChangeVerify(e, 'ru', 'text1')}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            ignoreText
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="ignoreText"
              value={(activeData && activeData.ignoreText) ? activeData.ignoreText.lv : ''}
              onChange={e => this.handleChangeVerify(e, 'lv', "ignoreText")}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="ignoreText"
              value={(activeData && activeData.ignoreText) ? activeData.ignoreText.en : ''}
              onChange={e => this.handleChangeVerify(e, 'en', "ignoreText")}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="ignoreText"
              value={(activeData && activeData.ignoreText) ? activeData.ignoreText.ru : ''}
              onChange={e => this.handleChangeVerify(e, 'ru', "ignoreText")}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            verifyText
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="verifyText"
              value={(activeData && activeData.verifyText) ? activeData.verifyText.lv : ''}
              onChange={e => this.handleChangeVerify(e, 'lv', "verifyText")}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="verifyText"
              value={(activeData && activeData.verifyText) ? activeData.verifyText.en : ''}
              onChange={e => this.handleChangeVerify(e, 'en', "verifyText")}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="verifyText"
              value={(activeData && activeData.verifyText) ? activeData.verifyText.ru : ''}
              onChange={e => this.handleChangeVerify(e, 'ru', "verifyText")}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            footer1
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="footer1"
              value={(activeData && activeData.footer1) ? activeData.footer1.lv : ''}
              onChange={e => this.handleChangeVerify(e, 'lv', "footer1")}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="footer1"
              value={(activeData && activeData.footer1) ? activeData.footer1.en : ''}
              onChange={e => this.handleChangeVerify(e, 'en', "footer1")}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="footer1"
              value={(activeData && activeData.footer1) ? activeData.footer1.ru : ''}
              onChange={e => this.handleChangeVerify(e, 'ru', "footer1")}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            footer2
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="footer2"
              value={(activeData && activeData.footer2) ? activeData.footer2.lv : ''}
              onChange={e => this.handleChangeVerify(e, 'lv', "footer2")}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="footer2"
              value={(activeData && activeData.footer2) ? activeData.footer2.en : ''}
              onChange={e => this.handleChangeVerify(e, 'en', "footer2")}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="footer2"
              value={(activeData && activeData.footer2) ? activeData.footer2.ru : ''}
              onChange={e => this.handleChangeVerify(e, 'ru', "footer2")}
            />
          </Col>
        </Row>


        <Row>
          <Col xs="12">
            footer3
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="footer3"
              value={(activeData && activeData.footer3) ? activeData.footer3.lv : ''}
              onChange={e => this.handleChangeVerify(e, 'lv', "footer3")}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="footer3"
              value={(activeData && activeData.footer3) ? activeData.footer3.en : ''}
              onChange={e => this.handleChangeVerify(e, 'en', "footer3")}
            />
          </Col>
          <Col xs="4">
            <Input
              type="text"
              name="footer3"
              value={(activeData && activeData.footer3) ? activeData.footer3.ru : ''}
              onChange={e => this.handleChangeVerify(e, 'ru', "footer3")}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Button onClick={this.saveTemplateData}> Save Template Data </Button>
          </Col>
        </Row>


      <div style={{ height: 100 }} />



    {/*  <Input
        type="text"
        name="name"
        checked={name}
        onChange={this.handleChange}
      />

      <EmailEditor
        ref={editor => this.editor = editor}
      />  */}
    </div>
  )
  }
}

const mapStateToProps = state => ({
  allEvents: state.admin.allEvents || [],
  verifyEmail: state.admin.verifyEmail || {},
  resetEmail: state.admin.resetEmail || {},
});

const mapDispatchToProps = {
  saveTemplate: saveEmailTemplate,
  fetchTemplateData: getTemplateData,
  saveTemplateData: updateTemplateData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('admin')(EmailEditorComponent));
