import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  sendLanguage, CURRENT, PLANNED,
} from '../../../../../constants/constants';

const Timer = ({ item, handleCurState, curState }) => {
  const { t, i18n } = useTranslation('common')

  const [curDate, setCurDate] = useState(Date.now());
  const [curDay, setCurDay] = useState(null);
  const [timerTitle, setTimerTitle] = useState('');
  const [timerCountDown, setTimerCountDown] = useState('');

  useEffect(() => {

    const interval = setInterval(() => {
      setCurDate(Date.now());
      let diffDate = Date.now();
      if (item.startDate > curDate) {
        if (curState !== PLANNED) {
          handleCurState(PLANNED);
        }
        diffDate = item.startDate;
        if (!timerTitle || (timerTitle !== t('home.startEventTime'))) {
          setTimerTitle(t('home.startEventTime'))
        }
      } else if (item.startDate <= curDate && item.endDate >= curDate) {
        diffDate = item.endDate;
        if (!timerTitle || (timerTitle !== t('home.endEventTime'))) {
          setTimerTitle(t('home.endEventTime'))
        }
        if (curState !== CURRENT) {
          handleCurState(CURRENT);
        }
      } else {
        handleCurState(null);
      }
      const startDate = moment(new Date());
      const endDate = moment(new Date(diffDate));

      const duration = moment.duration(endDate.diff(startDate));
      const {
        days, hours, minutes, seconds,
      } = duration._data;

      let countDown = '';
      if (days && days > 0) {
        countDown += days + 'd' + ' ';
      }
      if (hours && hours > 0) {
        countDown += hours + 'h' + ' ';
      }
      if (minutes && minutes > 0) {
        countDown += minutes + 'm' + ' ';
      }
      if (seconds && seconds > 0) {
        countDown += seconds + 's' + ' ';
      }
      setTimerCountDown(countDown);
    }, 1000);
    return () => clearInterval(interval);
  }, [curDate, timerTitle]);

  useEffect(() => {
    if (item) {
      if (item.startDate > curDate) {
        setTimerTitle(t('home.startEventTime'))
      } else if (item.startDate <= curDate && item.endDate >= curDate) {
        setTimerTitle(t('home.endEventTime'))
      }
    }
  }, [item, curDay, i18n.language])

  return (
    <>
      {timerCountDown && (
        <>
          <div className='mr-1'>
            {`${timerTitle} (${i18n.language === sendLanguage.en ? item.titleEN : (i18n.language === sendLanguage.lv ? item.titleLV : (i18n.language === sendLanguage.ru ? item.titleRU : item.titleLV))}): `}
          </div>
          <div>
            {timerCountDown}
          </div>
        </>
      )}
    </>
  );
};

export default Timer;
