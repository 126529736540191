import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// const Render3PlayerCards = React.memo(({
//   currentTable, cardsAdded, myPos, isFullscreen, isWebVersion,
// }) => (
//   <div className="played-cards">
//     {myPos === 'player1' && (
//     <Fragment>
//       {currentTable && Object.keys(currentTable).map((key, index) => (
//         <Fragment key={currentTable[key] ? currentTable[key].card : key}>
//           {(currentTable[key] && currentTable[key].player === 'player1') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//           {(currentTable[key] && currentTable[key].player === 'player2') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//           {(currentTable[key] && currentTable[key].player === 'player3') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//         </Fragment>
//       ))}
//     </Fragment>
//     )}
//     {myPos === 'player2' && (
//     <Fragment>
//       {currentTable && Object.keys(currentTable).map((key, index) => (
//         <Fragment key={currentTable[key] ? currentTable[key].card : key}>
//           {(currentTable[key] && currentTable[key].player === 'player1') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//           {(currentTable[key] && currentTable[key].player === 'player2') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//           {(currentTable[key] && currentTable[key].player === 'player3') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//         </Fragment>
//       ))}
//     </Fragment>
//     )}
//     {myPos === 'player3' && (
//     <Fragment>
//       {currentTable && Object.keys(currentTable).map((key, index) => (
//         <Fragment key={currentTable[key] ? currentTable[key].card : key}>
//           {(currentTable[key] && currentTable[key].player === 'player1') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//           {(currentTable[key] && currentTable[key].player === 'player2') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//           {(currentTable[key] && currentTable[key].player === 'player3') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//         </Fragment>
//       ))}
//     </Fragment>
//     )}
//   </div>
// ));


const Render3PlayerCards = React.memo(({
  currentTable, cardsAdded, myPos, currentTurn, tableIsInProgress, gameState, firstToGo, isFullscreen, isWebVersion,
}) => {
  const items = [];

  if (myPos === 'player3') {

    // table cards
    Object.keys(currentTable).map((key, index) => {
      if (currentTable[key] && currentTable[key].player === 'player1') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player2') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player3') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      return null;
    });

    // table card backgrounds
    // if (currentTable && currentTable.length <= 3) {
    //   if ((gameState !== 'burry' && currentTurn === 'player1') || (gameState === 'burry' && firstToGo === 'player1')) {
    //     items.push(<div className="table-card-bg card-0" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-2" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-1" />);
    //     }
    //   } else if ((gameState !== 'burry' && currentTurn === 'player2') || (gameState === 'burry' && firstToGo === 'player2')) {
    //     items.push(<div className="table-card-bg card-2" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-1" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-0" />);
    //     }
    //   } else if ((gameState !== 'burry' && currentTurn === 'player3') || (gameState === 'burry' && firstToGo === 'player3')) {
    //     items.push(<div className="table-card-bg card-1" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-0" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-2" />);
    //     }
    //   }
    // }

    return (
      <div className="played-cards">
        {items}
      </div>
    );
  }

  if (myPos === 'player1') {
    // table cards
    Object.keys(currentTable).map((key, index) => {
      if (currentTable[key] && currentTable[key].player === 'player1') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player2') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player3') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      return null;
    });

    // table card backgrounds
    // if (currentTable && currentTable.length <= 3) {
    //   if ((gameState !== 'burry' && currentTurn === 'player1') || (gameState === 'burry' && firstToGo === 'player1')) {
    //     items.push(<div className="table-card-bg card-1" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-0" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-2" />);
    //     }
    //   } else if ((gameState !== 'burry' && currentTurn === 'player2') || (gameState === 'burry' && firstToGo === 'player2')) {
    //     items.push(<div className="table-card-bg card-0" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-2" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-1" />);
    //     }
    //   } else if ((gameState !== 'burry' && currentTurn === 'player3') || (gameState === 'burry' && firstToGo === 'player3')) {
    //     items.push(<div className="table-card-bg card-2" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-1" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-0" />);
    //     }
    //   }
    // }

    return (
      <div className="played-cards">
        {items}
      </div>
    );
  }

  if (myPos === 'player2') {
    // table cards
    Object.keys(currentTable).map((key, index) => {
      if (currentTable[key] && currentTable[key].player === 'player1') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player2') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player3') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      return null;
    });

    // table card backgrounds
    // if (currentTable && currentTable.length <= 3) {
    //   if ((gameState !== 'burry' && currentTurn === 'player1') || (gameState === 'burry' && firstToGo === 'player1')) {
    //     items.push(<div className="table-card-bg card-2" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-1" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-0" />);
    //     }
    //   } else if ((gameState !== 'burry' && currentTurn === 'player2') || (gameState === 'burry' && firstToGo === 'player2')) {
    //     items.push(<div className="table-card-bg card-1" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-0" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-2" />);
    //     }
    //   } else if ((gameState !== 'burry' && currentTurn === 'player3') || (gameState === 'burry' && firstToGo === 'player3')) {
    //     items.push(<div className="table-card-bg card-0" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-2" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-1" />);
    //     }
    //   }
    // }

    return (
      <div className="played-cards">
        {items}
      </div>
    );
  }

  return null;
});

Render3PlayerCards.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
  isFullscreen: PropTypes.bool,
  isWebVersion: PropTypes.bool,
};

Render3PlayerCards.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
  isFullscreen: false,
  isWebVersion: false,
};

export default Render3PlayerCards;
