import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Moment from 'react-moment';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';

import {
  Table, Column, HeaderCell, Cell, ColumnGroup,
} from 'rsuite-table';

import ScrollGamesHistory from '../UI/ScrollGamesHistory';

import 'rsuite-table/dist/css/rsuite-table.css';

class UserGamesHistory extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    modalOpen: PropTypes.bool,
    history: PropTypes.arrayOf(PropTypes.shape({})),
    closeFunc: PropTypes.func.isRequired,
    fetchFunction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape(),
  }

  static defaultProps = {
    type: null,
    modalOpen: false,
    history: [],
    i18n: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      hoverIndex: null,
    //  scrollPos: 0,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    console.log('updateWindowDimensions');
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

    updateScrollPos = (val) => {

      if (this.messagesScrollbar2) {
        setTimeout(() => {
          if (this.messagesScrollbar2) {
            this.messagesScrollbar2.scrollXTo(val.leftPosition);
          }
        }, 0);
      }

      this.setState({ scrollPos: val.topPosition || 0 });
    }

  scroll = (x, y) => {

    if (this.datesRef && this.tableRef) {

      this.datesRef.scrollTop(-y);
      this.tableRef.scrollTop(-y);
    }
  }

  scroll2 = (x, y) => {
    if (this.datesRef && this.tableRef && this.tableRef.scrollY !== y) {
      this.tableRef.scrollTop(-y);
    }
  }

  scrollWithButton = (type) => {
    if (this.datesRef && this.tableRef) {
      const currentY = this.tableRef.scrollY;
      const currentX = this.tableRef.scrollX;

      let newY;
      let newX;

      if (type === 'down') {
        newY = -this.tableRef.scrollY + 30;
      } else if (type === 'up') {
        newY = -this.tableRef.scrollY - 30;
      } else if (type === 'left') {
        newX = -this.tableRef.scrollX - 30;
      } else if (type === 'right') {
        newX = -this.tableRef.scrollX + 30;
      }

      if (newY < 0) {
        newY = 0;
      } else if (newY > 2000) {
      //  newY = 2000;
      }

      if (newX < 0) {
        newX = 0;
      }

      if (type === 'down' || type === 'up') {
        this.datesRef.scrollTop(newY);
        this.tableRef.scrollTop(newY);
      } else if (type === 'left' || type === 'right') {
        this.datesRef.scrollLeft(newX);
        this.tableRef.scrollLeft(newX);
      }
    }
  }

  playButtonSound = () => {
    const { userSettings } = this.props;

    if (userSettings) {

      if (userSettings.soundOn) {
        this.buttonClickedAudio.play();
      }
    }
  }

  handleMouseHover = (rowId) => {
    this.setState({ hoverIndex: rowId });
  }

  handleMouseLeave = () => {
    this.setState({ hoverIndex: null });
  }

  render() {
    const {
      t, modalOpen, closeFunc, type, history, i18n
    } = this.props;

    const { height, hoverIndex } = this.state;

    return (
      <Modal container={`div > div`} size="lg" isOpen={modalOpen} toggle={closeFunc} style={{maxWidth: 'none', margin: '0 10%', height: '70%' }}>
          <ModalHeader toggle={closeFunc}>
            {type === 'games' ? 'Results' : ""}
          </ModalHeader>
          <ModalBody style={{height: '100%'}}>
            <div className="my-info" style={{height:'100%'}}>
              <Row>
                <Col sm="12">
                  <Row className="game-history">
                    <Col
                      sm="12"
                      className="game-history-table"
                      style={{ height: (height > 600) ? (height * 0.60) : 360 }}
                    >
                      {(history && history.length) ? (
                        <>
                          <ScrollGamesHistory
                            datesRef={this.datesRef}
                            tableRef={this.tableRef}
                            scroll={this.scrollWithButton}
                          />
                          <div
                            className="game-history-table-dates"
                            style={{
                              width: 100,
                              float: 'left',
                              height: '100%',
                            }}
                          >
                            <Table
                              style={{ fontSize: 14 }}
                              data={history}
                              virtualized
                              hover={false}
                              minHeight={300}
                              height={(height > 600) ? ((height * 0.60) + 8) : 360}
                              shouldUpdateScroll={false}
                              ref={(el) => { this.datesRef = el; }}
                              onScroll={(x, y) => { this.scroll2(x, y); }}
                              rowHeight={32}
                              // className="game-history-table-section-date"
                              rowClassName={(rowData, rowIndex) => {
                                if (rowData) {
                                  const index = history.findIndex(element => element.date === rowData.date);
      
                                  if (rowData.last) return 'game-history-table-row-last'
                                  if (index % 2) {
                                    return `game-history-table-row-odd ${index === hoverIndex ? 'game-history-table-row-test' : ''}`;
                                  }
                                  return `game-history-table-row-even ${index === hoverIndex ? 'game-history-table-row-test' : ''}`;
                                }
                                return '';
                              }}
                            >
                              <Column width={100}>
                                <HeaderCell>Date</HeaderCell>
                                <Cell dataKey="date">
                                  {(rowData, rowIndex) => {
                                    if (!rowData.date || rowData.date === 'total') {
                                      return <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>Total</div>
                                    }
                                    return (
                                      <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                        <Moment format="DD.MM" locale="lv">
                                          {rowData.date}
                                        </Moment>
                                      </div>
                                    );
                                  }}
                                </Cell>
                              </Column>
                            </Table>
                          </div>
                          <div style={{ width: '100%', height: '100%' }}>
                            <Table
                              style={{ fontSize: 14 }}
                              data={history}
                              virtualized
                              minHeight={300}
                              height={(height > 600) ? ((height * 0.60) + 8) : 360}
                              shouldUpdateScroll={false}
                              onScroll={(x, y) => { this.scroll(x, y); }}
                              ref={(el) => { this.tableRef = el; }}
                              rowHeight={32}
                              hover={false}
                              rowClassName={(rowData) => {
                                if (rowData) {
                                  const index = history.findIndex(element => element.date === rowData.date);
      
                                  if (rowData.last) return 'game-history-table-row-last'
                                  if (index % 2) {
                                    return `game-history-table-row-odd ${index === hoverIndex ? 'game-history-table-row-test' : ''}`;
                                  }
                                  return `game-history-table-row-even ${index === hoverIndex ? 'game-history-table-row-test' : ''}`;
                                }
                                return '';
                                //  return (`${`${(rowData && rowData.newUser) ? ' admin-table-new-user' : ''}`}`)
                              }}
                            >
                              <ColumnGroup header='Wins'>
                                <Column width={100}>
                                  <HeaderCell>#</HeaderCell>
                                  <Cell dataKey="wins">
                                    {(rowData, rowIndex) => (
                                      <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                        {' '}
                                        {rowData.wins || 0}
                                        {' '}
                                      </div>
                                    )}
                                  </Cell>
                                </Column>
                                <Column width={100}>
                                  <HeaderCell>%</HeaderCell>
                                  <Cell dataKey="wins">
                                    {(rowData, rowIndex) => {
                                      const wins = rowData.wins || 0;
                                      const loses = rowData.loses || 0;
                                      return (
                                        <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                          {' '}
                                          {rowData.wins ? (`${(wins + loses) ? (`${Math.round((rowData.wins / (wins + loses)) * 100)}%`) : 0}`) : 0}
                                          {' '}
                                        </div>
                                      );
                                    }}
                                  </Cell>
                                </Column>
                              </ColumnGroup>
                              <ColumnGroup header='Loses'>
                                <Column width={100}>
                                  <HeaderCell>#</HeaderCell>
                                  <Cell dataKey="loses">
                                    {(rowData, rowIndex) => (
                                      <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                        {' '}
                                        {rowData.loses || 0}
                                        {' '}
                                      </div>
                                    )}
                                  </Cell>
                                </Column>
                                <Column width={100}>
                                  <HeaderCell>%</HeaderCell>
                                  <Cell dataKey="loses">
                                    {(rowData, rowIndex) => {
                                      const wins = rowData.wins || 0;
                                      const loses = rowData.loses || 0;

                                      const winsPercentage = Math.round((wins / (wins + loses)) * 100);
                                      let losesPercentage = Math.round((loses / (wins + loses)) * 100);
                                      if ((winsPercentage + losesPercentage) > 100) {
                                        losesPercentage -= 1;
                                      } else if ((winsPercentage + losesPercentage) < 100) {
                                        losesPercentage += 1;
                                      }

                                      return (
                                        <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                          {' '}
                                          {rowData.loses ? (`${(wins + loses) ? (`${losesPercentage}%`) : 0}`) : 0}
                                          {' '}
                                        </div>
                                      );
                                    }}
                                  </Cell>
                                </Column>
                              </ColumnGroup>

                              <Column width={100}>
                                <HeaderCell>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.wins')} */}
                                    Wins
                                  </span>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.asSmall')} */}
                                    As small
                                  </span>
                                </HeaderCell>
                                <Cell dataKey="smallWins">
                                  {(rowData, rowIndex) => (
                                    <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                      {' '}
                                      {rowData.smallWins || 0}
                                      {' '}
                                    </div>
                                  )}
                                </Cell>
                              </Column>
                              <Column width={100}>
                                <HeaderCell>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.wins')} */}
                                    Wins
                                  </span>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.asLarge')} */}
                                    As large
                                  </span>
                                </HeaderCell>
                                <Cell dataKey="largeWins">
                                  {(rowData, rowIndex) => (
                                    <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                      {' '}
                                      {rowData.largeWins || 0}
                                      {' '}
                                    </div>
                                  )}
                                </Cell>
                              </Column>
                              <Column width={100}>
                                <HeaderCell>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.loses')} */}
                                    Loses 
                                  </span>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.asSmall')} */}
                                    As small
                                  </span>
                                </HeaderCell>
                                <Cell dataKey="smallLoses">
                                  {(rowData, rowIndex) => (
                                    <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                      {' '}
                                      {rowData.smallLoses || 0}
                                      {' '}
                                    </div>
                                  )}
                                </Cell>
                              </Column>
                              <Column width={100}>
                                <HeaderCell>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.loses')} */}
                                    Loses
                                  </span>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.asLarge')} */}
                                    As large
                                  </span>
                                </HeaderCell>
                                <Cell dataKey="largeLoses">
                                  {(rowData, rowIndex) => (
                                    <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                      {' '}
                                      {rowData.largeLoses || 0}
                                      {' '}
                                    </div>
                                  )}
                                </Cell>
                              </Column>
                              <Column width={100}>
                                <HeaderCell>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.wins')} */}
                                    Wins
                                  </span>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.zole')} */}
                                    Zole
                                  </span>
                                </HeaderCell>
                                <Cell dataKey="zoleWins">
                                  {(rowData, rowIndex) => (
                                    <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                      {' '}
                                      {rowData.zoleWins || 0}
                                      {' '}
                                    </div>
                                  )}
                                </Cell>
                              </Column>
                              <Column width={100}>
                                <HeaderCell>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {`${t('results.loses')} `} */}
                                    Loses
                                  </span>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.zole')} */}
                                    Zole
                                  </span>
                                </HeaderCell>
                                <Cell dataKey="zoleLoses">
                                  {(rowData, rowIndex) => (
                                    <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                      {' '}
                                      {rowData.zoleLoses || 0}
                                      {' '}
                                    </div>
                                  )}
                                </Cell>
                              </Column>
                              <Column width={100}>
                                <HeaderCell>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.wins')} */}
                                    Wins
                                  </span>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.table')} */}
                                    Table
                                  </span>
                                </HeaderCell>
                                <Cell dataKey="galdinsWins">
                                  {(rowData, rowIndex) => (
                                    <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                      {' '}
                                      {rowData.galdinsWins || 0}
                                      {' '}
                                    </div>
                                  )}
                                </Cell>
                              </Column>
                              <Column width={100}>
                                <HeaderCell>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.wins')} */}
                                    Loses
                                  </span>
                                  <span className={`avoidwrap ${i18n.language === 'ru' ? 'font-size-10' : null}`}>
                                    {/* {t('results.table')} */}
                                    Table
                                  </span>
                                </HeaderCell>
                                <Cell dataKey="galdinsLoses">
                                  {(rowData, rowIndex) => (
                                    <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                      {' '}
                                      {rowData.galdinsLoses || 0}
                                      {' '}
                                    </div>
                                  )}
                                </Cell>
                              </Column>
                              <Column width={100}>
                                <HeaderCell>Rooms</HeaderCell>
                                <Cell dataKey="roomsPlayed">
                                  {(rowData, rowIndex) => (
                                    <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                      {' '}
                                      {rowData.roomsPlayed || 0}
                                      {' '}
                                    </div>
                                  )}
                                </Cell>
                              </Column>
                              <Column width={100}>
                                {/* <HeaderCell>{t('results.parties')}</HeaderCell> */}
                                <HeaderCell>Rounds</HeaderCell>
                                <Cell dataKey="roundsPlayed">
                                  {(rowData, rowIndex) => {
                                    const wins = rowData.wins || 0;
                                    const loses = rowData.loses || 0;

                                    return (
                                      <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                        {' '}
                                        {(wins + loses) ? (wins + loses) : 0}
                                        {' '}
                                      </div>
                                    );
                                  }}
                                </Cell>
                              </Column>
                              <Column width={100}>
                                {/* <HeaderCell>{t('results.coins')}</HeaderCell> */}
                                <HeaderCell>Coins</HeaderCell>
                                <Cell dataKey="balChange">
                                  {(rowData, rowIndex) => (
                                    <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                      {' '}
                                      {rowData.balChange || 0}
                                      {' '}
                                    </div>
                                  )}
                                </Cell>
                              </Column>
                              <Column width={100}>
                                {/* <HeaderCell>{t('results.points')}</HeaderCell> */}
                                <HeaderCell>Points</HeaderCell>
                                <Cell dataKey="pointsChange">
                                  {(rowData, rowIndex) => (
                                    <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                      {' '}
                                      {rowData.pointsChange || 0}
                                      {' '}
                                    </div>
                                  )}
                                </Cell>
                              </Column>
                              <Column width={100}>
                                {/* <HeaderCell>{t('results.rating')}</HeaderCell> */}
                                <HeaderCell>Rating</HeaderCell>
                                <Cell dataKey="rating">
                                  {(rowData, rowIndex) => (
                                    <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}> 0 </div>
                                  )}
                                </Cell>
                              </Column>
                            </Table>
                          </div>
                        </>
                      ) : (null)}

                    </Col>
                  </Row>

                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
    );
  }
}

export default  withTranslation(['admin', 'common'])(UserGamesHistory);
