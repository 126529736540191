import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import Media from 'reactstrap/lib/Media';

import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CustomDate from '../UI/CustomDate';
import LinkifyWithTargetBlank from '../UI/LinkifyWithTargetBlank';
import SplitMessage from './SplitMessage';

import defaultImage from '../../../images/Game/defaultImage.jpg';
import reportedMessageImg from '../../../images/redesign/chat/chat-report.png';
import reportMessageImg from '../../../images/redesign/chat/chat-report-submit.png';


import * as constants from '../../../constants/constants';
import {
  reportMessage,
} from '../../../actions/room';

const Message = React.memo(({ isReportedMessage, name, messageKey, roomId,  message, uid, t, messagesShow, profanitiesList, fontMode }) => {
  const dispatch = useDispatch();

  const [popoverOpen, setPopoverOpen] = useState(null);

  const onHover = (key) => {
    if (!popoverOpen) {
      setPopoverOpen(key)
    }
  }

  const onHoverLeave = () => {
    setPopoverOpen(null)
  }

  const handleReportMessage = () => {
    dispatch(reportMessage(uid, name, message.userUid, message.userName, messageKey, message.message, roomId))
  }


  const printGameMessageTypes = (type) => {
    if (constants.gameMessageTypes && constants.gameMessageTypes.filter(item => item.id === type)[0].data) {
      console.log('constants.gameMessageTypes.filter(item => item.id === type)[0].data', constants.gameMessageTypes.filter(item => item.id === type)[0].data);
    }

    if (constants.gameMessageTypes && constants.gameMessageTypes.filter(item => item.id === type)[0].data === constants.PLAYER_TRICKS_POINTS) {
      return t(constants.gameMessageTypes.filter(item => item.id === type)[0].label, { player: message.data.player, tricks: message.data.tricks, points: message.data.points })
    }
    if (constants.gameMessageTypes.filter(item => item.id === type)[0].data === constants.PLAYER_POINTS) {
      if (message.data.points > 90 && (type === 'win' || type === 'winZole')) {
        return `${t(constants.gameMessageTypes.filter(item => item.id === type)[0].label, { player: message.data.player, points: message.data.points })}${t('jani')}`
      } else if (message.data.points < 31 && (type === 'lose' || type === 'loseZole')) {
        return `${t(constants.gameMessageTypes.filter(item => item.id === type)[0].label, { player: message.data.player, points: message.data.points })}${t('jani2')}`
      }
      return t(constants.gameMessageTypes.filter(item => item.id === type)[0].label, { player: message.data.player, points: message.data.points })
    }
    if (constants.gameMessageTypes.filter(item => item.id === type)[0].data === constants.PLAYER) {
      return t(constants.gameMessageTypes.filter(item => item.id === type)[0].label, { player: message.data.player })
    }
    if (constants.gameMessageTypes.filter(item => item.id === type)[0].data === constants.TOURNAMENT_ROUND) {
      if (message.data && message.data.tournamentRound && message.data.tournamentRound === 'last') {
        return t(constants.gameMessageTypes.filter(item => item.id === 'lastRoundTournamentLast')[0].label)
      }
      return t(constants.gameMessageTypes.filter(item => item.id === type)[0].label, { tournamentRound: message.data ? message.data.tournamentRound : '1' })
    }
    if (constants.gameMessageTypes.filter(item => item.id === type)[0].data === constants.PLAYERNAME_LASTNAME) {
      return t(constants.gameMessageTypes.filter(item => item.id === type)[0].label, { player: message.data ? message.data.playerName : '', lastPlayer: message.data ? message.data.lastPlayerName : '' })
    }
    if (constants.gameMessageTypes.filter(item => item.id === type)[0].data === constants.DATA) {
      return t(constants.gameMessageTypes.filter(item => item.id === type)[0].label, { player: message.data })
    }
  }

  // let rudeWord= "";
  // Object.keys(profanitiesList).map((key) => {
  //   if (profanitiesList[key].word) {
  //     const msg = message.message;
  //     if (msg.toLowerCase().includes(profanitiesList[key].word.toLowerCase())) {
  //       rudeWord = profanitiesList[key].word.toLowerCase();
  //     }
  //   }
  // });

  let splitMessage = [];
  let splitFilteredMessage = [];
  if (message.filteredMessage && message.message && (message.userUid && message.userUid !== 'game')) {
    splitFilteredMessage = message.filteredMessage.split(/\n/);
  }

  if (message.message || (message.userUid && message.userUid === 'game')) {
    if (message.message) {
      splitMessage = message.message.split(/\n/);
      if (!uid) {
        return null;
      }
    }
    console.log(messageKey, messagesShow, "checkMessage==================")

    return (
        <Fragment>
          {messagesShow === constants.ALL ? (
            <Fragment>
              {message.userUid && message.userUid === 'game' ? (
                <Fragment>
                  <Row>
                    <Col xs="2" className="chat-message-col-left">
                      <div className="chat-message-game-label">
                        ZOLE
                      </div>
                      <div className="chat-message-game-time">
                        { message.time && (
                          <CustomDate format="DD/ hh:mm" date={message.time} />
                        )}
                      </div>
                    </Col>
                    <Col xs="10" className="chat-message-col-right">
                      <div className="chat-message-game">
                        {message && message.type ? (
                          <Fragment>
                            {constants.gameMessageTypes && constants.gameMessageTypes.filter(item => item.id === message.type)[0].data ? (
                              printGameMessageTypes(message.type)
                            ) : (
                              t(constants.gameMessageTypes.filter(item => item.id === message.type)[0].label)
                            )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            {
                              message.message
                            }
                          </Fragment>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              ) : (
                <Fragment>
                  {message.userUid && message.userUid.toString() === uid.toString() ? (
                    <Fragment>
                      <Row>
                        <Col xs="12" className="chat-message-col">
                          <LinkifyWithTargetBlank>
                            <SplitMessage splitFilteredMessage={splitFilteredMessage} splitMessage={splitMessage} fontMode={fontMode} message={message} inlineClassName="chat-message-user" />
                          </LinkifyWithTargetBlank>
                        </Col>
                        <Col xs="12" className="chat-message-col">
                          <div className="chat-message-user-time">
                            { message.time && (
                              <CustomDate format="DD/ hh:mm" date={message.time} />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Row>
                        <Col xs="1" className="chat-message-col">
                          <Media
                            className="chat-message-other-image"
                            src={message.userPhoto || defaultImage}
                            alt="photo"
                          />
                        </Col>
                        <Col xs="9" className="chat-message-col">
                          <Row>
                            <Col xs="12" className='d-flex flex-row'>
                              <LinkifyWithTargetBlank className>
                              <SplitMessage splitFilteredMessage={splitFilteredMessage} splitMessage={splitMessage} fontMode={fontMode} message={message} inlineClassName="chat-message-other relative" messageKey={messageKey} isReportedMessage={isReportedMessage} reportPopoverOpen={popoverOpen} reportPopoverLeave={onHoverLeave} />
                              <div 
                                id={`report-message-${messageKey}`}
                                onMouseEnter={() => onHover(`report-message-${messageKey}`)}
                                onMouseLeave={() => onHoverLeave()}
                                onClick={!isReportedMessage ? handleReportMessage : null}
                              >
                                <Media src={!isReportedMessage ? reportMessageImg : reportedMessageImg} style={{width: '18px',height: '18px'}} />
                              </div>
                              </LinkifyWithTargetBlank>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          { message.time && (
                          <div className="chat-message-other-time chat-message-other-time-game">
                            <CustomDate format="DD/ hh:mm" date={message.time} />
                                {`${(message.userName.split(" "))[0]} ${((message.userName.split(" "))[1]) ? (((message.userName.split(" "))[1])?.charAt(0)) : ''}`}
                          </div>
                          )}
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {message.userUid && message.userUid === 'game' && messagesShow === constants.GAME ? (
                <Fragment>
                  <Row>
                    <Col xs="2" className="chat-message-col-left">
                      <div className="chat-message-game-label">
                        ZOLE
                      </div>
                      <div className="chat-message-game-time">
                        { message.time && (
                          <CustomDate format="DD/ hh:mm" date={message.time} />
                        )}
                      </div>
                    </Col>
                    <Col xs="10" className="chat-message-col-right">
                      <div className="chat-message-game">
                        {message && message.type ? (
                          <Fragment>
                            {constants.gameMessageTypes && constants.gameMessageTypes.filter(item => item.id === message.type)[0].data ? (
                              printGameMessageTypes(message.type)
                            ) : (
                              t(constants.gameMessageTypes.filter(item => item.id === message.type)[0].label)
                            )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            {
                              message.message
                            }
                          </Fragment>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              ) : (
                <Fragment>
                  {messagesShow === constants.PLAYER && message.userUid && message.userUid !== constants.GAME && (
                    <Fragment>
                      {message.userUid && message.userUid.toString() === uid.toString() ? (
                        <Fragment>
                          <Row>
                            <Col xs="12" className="chat-message-col">
                              <LinkifyWithTargetBlank>
                                <SplitMessage splitFilteredMessage={splitFilteredMessage} splitMessage={splitMessage} fontMode={fontMode} message={message} inlineClassName="chat-message-user" />
                              </LinkifyWithTargetBlank>
                            </Col>
                            <Col xs="12" className="chat-message-col">
                              <div className="chat-message-user-time">
                                { message.time && (
                                  <CustomDate format="DD/ hh:mm" date={message.time} />
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Row>
                            <Col xs="1" className="chat-message-col">
                              <Media
                                className="chat-message-other-image"
                                src={message.userPhoto || defaultImage}
                                alt="photo"
                              />

                            </Col>
                            <Col xs="9" className="chat-message-col">
                              <Row>
                                <Col xs="12" className='d-flex flex-row'>
                                  <LinkifyWithTargetBlank>
                                    <SplitMessage splitFilteredMessage={splitFilteredMessage} splitMessage={splitMessage} fontMode={fontMode} message={message} inlineClassName="chat-message-other"  messageKey={messageKey} isReportedMessage={isReportedMessage} reportPopoverOpen={popoverOpen} reportPopoverLeave={onHoverLeave}/>
                                    <div
                                      id={`report-message-${messageKey}`}
                                      onMouseEnter={() => onHover(`report-message-${messageKey}`)}
                                      onMouseLeave={() => onHoverLeave()}
                                      onClick={!isReportedMessage ? handleReportMessage : null}
                                    >
                                      <Media src={!isReportedMessage ? reportMessageImg : reportedMessageImg} style={{width: '18px', height: '18px'}} />
                                    </div>
                                  </LinkifyWithTargetBlank>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              { message.time && (
                              <div className="chat-message-other-time chat-message-other-time-game">
                                <CustomDate format="DD/ hh:mm" date={message.time} />
                                {`${(message.userName.split(" "))[0]} ${((message.userName.split(" "))[1]) ? (((message.userName.split(" "))[1])?.charAt(0)) : ''}`}
                              </div>
                              )}
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
    );
  }
  return null;
});

Message.propTypes = {
  message: PropTypes.shape(),
  uid: PropTypes.string,
  t: PropTypes.func.isRequired,
  messagesShow: PropTypes.string,
};

Message.defaultProps = {
  message: {},
  uid: '',
  messagesShow: constants.ALL,
};

export default withTranslation(['chat'])(Message);
