import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as constants from '../../../../../constants/constants';

const SwitchSelect = React.memo(({
  options, selectedOption, selectedOptions, selectFunc, multiple, selectAllFunc, selectType, topPage,
}) => {
  const { t } = useTranslation('common');
  console.log(selectedOptions, "check new val");

  return (
    <>
      {multiple ? (
        <div className="switch-select">
          <div
            onClick={(() => selectFunc(selectType, constants.ALL))}
            className={`switch-select-option switch-select-option-first ${(selectedOptions.length === 0) ? 'switch-select-option-active' : ''}`}
          >
            <div className="switch-select-option-name">
              {
                topPage ? (
                  t(`top.${constants.ALL}`)
                ) : (
                  t(`common.${constants.ALL}`)
                )
              }
            </div>
          </div>
          {(options && options.length) ? options.map((option, index) => (
            <div
              onClick={(() => selectFunc(selectType, option.value))}
              className={`switch-select-option ${(index === options.length - 1) ? 'switch-select-option-last' : ''} ${(options.length !== selectedOptions.length && selectedOptions.includes(option.value)) ? 'switch-select-option-active' : ''}`}
            >
              <div className="switch-select-option-name">
                {
                  topPage ? (
                    t(`top.${option.name}`)
                  ) : (
                    option.name
                  )
                }
              </div>
            </div>
          )) : (null)}
        </div>
      ) : (
        <div className="switch-select">
          {/* {console.log('options', { options, selectedOption })} */}
          {(options && options.length) ? options.map((option, index) => (
            <div
              onClick={(() => selectFunc(selectType, option.value))}
              className={`switch-select-option ${index === 0 ? 'switch-select-option-first' : ''} ${(index === options.length - 1) ? 'switch-select-option-last' : ''} ${selectedOption === option.value ? 'switch-select-option-active' : ''}`}
            >
              <div className="switch-select-option-name">
                {
                  topPage ? (
                    t(`top.${option.name}`)
                  ) : (
                    option.name
                  )
                }
              </div>
            </div>
          )) : (null)}
        </div>
      )}

    </>
  );
});

SwitchSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  selectFunc: PropTypes.func.isRequired,
  selectAllFunc: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
  selectedOptions: PropTypes.arrayOf(PropTypes.string.isRequired),
  multiple: PropTypes.bool,
  topPage: PropTypes.bool,
};

SwitchSelect.defaultProps = {
  options: [],
  selectedOption: null,
  selectedOptions: [],
  multiple: false,
  topPage: false,
};

export default SwitchSelect;
