import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Button, Col, Media, Row, TabContent, TabPane } from 'reactstrap';
import * as constants from '../../../constants/constants';
import { filter } from 'lodash';

import Lottie from 'react-lottie';

import coins1 from '../../../images/BuyMoney/veikals_paka_1.png';
import coins2 from '../../../images/BuyMoney/veikals_paka_2.png';
import coins3 from '../../../images/BuyMoney/veikals_paka_3.png';
import coins4 from '../../../images/BuyMoney/veikals_paka_4.png';
import coins5 from '../../../images/BuyMoney/veikals_paka_5.png';

import bonus1 from '../../../images/icons/money_bonus_1.webp';
import bonus2 from '../../../images/icons/money_bonus_2.webp';
import bonus3 from '../../../images/icons/money_bonus_3.webp';
import bonus4 from '../../../images/icons/money_bonus_4.webp';

import balanceResetImg from '../../../images/BuyMoney/resetBalance.webp';
import pointsResetImg from '../../../images/BuyMoney/resetPoints.webp';
import profileResetImg from '../../../images/BuyMoney/resetProfile.webp';

import infoImg from '../../../images/icons/info-icon-3.png';
import coinImg from '../../../images/coin.svg';
import glitterAnimImg from '../../../images/BuyMoney/glitter.json';
import classNames from 'classnames';

import { getDiscounts } from '../../../actions/shop';

const RoomEndedModal = ({ discounts, balance, totalPoints, buyMoney, fetchDiscounts, t }) => {
  const [pauseGlitter, setPauseGlitter] = useState([true, true, true, true, true]);
  const [activeHover, setActiveHover] = useState(null);
  const [activeTab, setActiveTab] = useState(constants.SHOP_NAVIGATION.moneyPacks);
  const [curDate, setCurDate] = useState(new Date());

  useEffect(() => {
    const pauseGlitterCopy = [...pauseGlitter];

    const { hostname } = window.location;
    let devModeTemp = false;
    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devModeTemp = true;
    }
    fetchDiscounts(devModeTemp);

    const intervalID = setInterval(() => {
      setCurDate(Date.now());
    }, 1000);

    setTimeout(() => {
      pauseGlitterCopy[0] = false;
      setPauseGlitter(pauseGlitterCopy);
    }, 0);

    setTimeout(() => {
      pauseGlitterCopy[4] = false;
      setPauseGlitter(pauseGlitterCopy);
    }, 270);

    setTimeout(() => {
      pauseGlitterCopy[1] = false;
      setPauseGlitter(pauseGlitterCopy);
    }, 500);

    setTimeout(() => {
      pauseGlitterCopy[3] = false;
      setPauseGlitter(pauseGlitterCopy);
    }, 750);

    setTimeout(() => {
      pauseGlitterCopy[2] = false;
      setPauseGlitter(pauseGlitterCopy);
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const mouseEnter = (key) => {
    setActiveHover(key);
  }

  const mouseLeave = () => {
    setActiveHover(null);
  }

  const coinPacks = (
    <div className="buy-page-block-container room-ended-modal-money-pack">
      <Row>
        <Col />
        <Col>
          <Row>
            <Col sm="12">
              <div className="buy-page-coins-imageWrapper">
                <div className="buy-page-coins-background" />
                <Media className="buy-page-coins-image1" src={coins1} alt="" />

                <div
                  className="glitter-anim-image"
                  style={{ top: 30, left: 16 }}
                >
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: glitterAnimImg,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={42}
                    width={42}
                    isStopped={false}
                    isPaused={pauseGlitter[0]}
                  />
                </div>


              </div>
              <div className="buy-page-coins-wrapper">
                <Media src={coinImg} className="buy-page-coins-coin" />
                <div className="buy-page-coins-amount">
                  250
                </div>
              </div>
              <div className="buy-page-coins-button-wrapper">
                {
                  discounts.length > 0 ? (
                    filter(discounts, item => item.type === constants.SPECIALPRICE)[0] ? (
                      <>
                        <Button disabled style={{textDecorationLine: 'line-through'}} color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money1)}>
                          <div className="buy-page-coins-button-text">
                            0.85 EUR
                          </div>
                        </Button>
                        <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money1)}>
                          <div className="buy-page-coins-button-text">
                            {`${filter(discounts, item => item.type === constants.SPECIALPRICE)[0].specialPrice['money1']} EUR`}
                          </div>
                        </Button>
                      </>
                    ) : (
                      <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money1)}>
                        <div className="buy-page-coins-button-text">
                          0.85 EUR
                        </div>
                      </Button>
                    )
                  ) : (
                    <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money1)}>
                      <div className="buy-page-coins-button-text">
                        0.85 EUR
                      </div>
                    </Button>
                  )
                }
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col sm="12">
              <div className="buy-page-coins-imageWrapper">
                <div className="buy-page-coins-background" />
                <Media className="buy-page-coins-image2" src={coins2} alt="" />
                <Media className="buy-page-coins-bonus-image" src={bonus1} alt="" />

                <div
                  className="glitter-anim-image"
                  style={{ top: 38, left: 21 }}
                >
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: glitterAnimImg,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={57}
                    width={57}
                    isStopped={false}
                    isPaused={pauseGlitter[1]}
                  />
                </div>

              </div>
              <div className="buy-page-coins-wrapper">
                <Media src={coinImg} className="buy-page-coins-coin" />
                <div className="buy-page-coins-amount">
                  500
                </div>
              </div>
              <div className="buy-page-coins-button-wrapper">
                {
                  discounts.length > 0 ? (
                    filter(discounts, item => item.type === constants.SPECIALPRICE)[0] ? (
                      <>
                        <Button disabled style={{textDecorationLine: 'line-through'}} color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money2)}>
                          <div className="buy-page-coins-button-text">
                            1.41 EUR
                          </div>
                        </Button>
                        <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money2)}>
                          <div className="buy-page-coins-button-text">
                            {`${filter(discounts, item => item.type === constants.SPECIALPRICE)[0].specialPrice['money2']} EUR`}
                          </div>
                        </Button>
                      </>
                    ) : (
                      <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money2)}>
                        <div className="buy-page-coins-button-text">
                          1.41 EUR
                        </div>
                      </Button>
                    )
                  ) : (
                    <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money2)}>
                      <div className="buy-page-coins-button-text">
                        1.41 EUR
                      </div>
                    </Button>
                  )
                }
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col sm="12">
              <div className="buy-page-coins-imageWrapper">
                <div className="buy-page-coins-background" />
                <Media className="buy-page-coins-image3" src={coins3} alt="" />
                <Media className="buy-page-coins-bonus-image" src={bonus2} alt="" />

                <div
                  className="glitter-anim-image"
                  style={{ top: 33, left: 8 }}
                >
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: glitterAnimImg,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={72}
                    width={72}
                    isStopped={false}
                    isPaused={pauseGlitter[2]}
                  />
                </div>

              </div>
              <div className="buy-page-coins-wrapper">
                <Media src={coinImg} className="buy-page-coins-coin" />
                <div className="buy-page-coins-amount">
                  1500
                </div>
              </div>
              <div className="buy-page-coins-button-wrapper">
                {
                  discounts.length > 0 ? (
                    filter(discounts, item => item.type === constants.SPECIALPRICE)[0] ? (
                      <>
                        <Button disabled style={{textDecorationLine: 'line-through'}} color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money3)}>
                          <div className="buy-page-coins-button-text">
                            2.85 EUR
                          </div>
                        </Button>
                        <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money3)}>
                          <div className="buy-page-coins-button-text">
                            {`${filter(discounts, item => item.type === constants.SPECIALPRICE)[0].specialPrice['money3']} EUR`}
                          </div>
                        </Button>
                      </>
                    ) : (
                      <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money3)}>
                        <div className="buy-page-coins-button-text">
                          2.85 EUR
                        </div>
                      </Button>
                    )
                  ) : (
                    <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money3)}>
                      <div className="buy-page-coins-button-text">
                        2.85 EUR
                      </div>
                    </Button>
                  )
                }
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col sm="12">
              <div className="buy-page-coins-imageWrapper">
                <div className="buy-page-coins-background" />
                <Media className="buy-page-coins-image4" src={coins4} alt="" />
                <Media className="buy-page-coins-bonus-image" src={bonus3} alt="" />

                <div
                  className="glitter-anim-image"
                  style={{ top: 19, left: 9 }}
                >
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: glitterAnimImg,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={87}
                    width={87}
                    isStopped={false}
                    isPaused={pauseGlitter[3]}
                  />
                </div>

              </div>
              <div className="buy-page-coins-wrapper">
                <Media src={coinImg} className="buy-page-coins-coin" />
                <div className="buy-page-coins-amount">
                  4000
                </div>
              </div>
              <div className="buy-page-coins-button-wrapper">
                {
                  discounts.length > 0 ? (
                    filter(discounts, item => item.type === constants.SPECIALPRICE)[0] ? (
                      <>
                        <Button disabled style={{textDecorationLine: 'line-through'}} color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money4)}>
                          <div className="buy-page-coins-button-text">
                            4.27 EUR
                          </div>
                        </Button>
                        <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money4)}>
                          <div className="buy-page-coins-button-text">
                            {`${filter(discounts, item => item.type === constants.SPECIALPRICE)[0].specialPrice['money4']} EUR`}
                          </div>
                        </Button>
                      </>
                    ) : (
                      <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money4)}>
                        <div className="buy-page-coins-button-text">
                          4.27 EUR
                        </div>
                      </Button>
                    )
                  ) : (
                    <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money4)}>
                      <div className="buy-page-coins-button-text">
                        4.27 EUR
                      </div>
                    </Button>
                  )
                }
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col sm="12">
              <div className="buy-page-coins-imageWrapper">
                <div className="buy-page-coins-background" />
                <Media className="buy-page-coins-image5" src={coins5} alt="" />
                <Media className="buy-page-coins-bonus-image" src={bonus4} alt="" />

                <div
                  className="glitter-anim-image"
                  style={{ top: 18, left: -14 }}
                >
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: glitterAnimImg,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={102}
                    width={102}
                    isStopped={false}
                    isPaused={pauseGlitter[4]}
                  />
                </div>

              </div>
              <div className="buy-page-coins-wrapper">
                <Media src={coinImg} className="buy-page-coins-coin" />
                <div className="buy-page-coins-amount">
                  15000
                </div>
              </div>
              <div className="buy-page-coins-button-wrapper">
                {
                  discounts.length > 0 ? (
                    filter(discounts, item => item.type === constants.SPECIALPRICE)[0] ? (
                      <>
                        <Button disabled style={{textDecorationLine: 'line-through'}} color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money5)}>
                          <div className="buy-page-coins-button-text">
                            14.23 EUR
                          </div>
                        </Button>
                        <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money5)}>
                          <div className="buy-page-coins-button-text">
                            {`${filter(discounts, item => item.type === constants.SPECIALPRICE)[0].specialPrice['money5']} EUR`}
                          </div>
                        </Button>
                      </>
                    ) : (
                      <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money5)}>
                        <div className="buy-page-coins-button-text">
                          14.23 EUR
                        </div>
                      </Button>
                    )
                  ) : (
                    <Button color="link" className="buy-page-coins-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money5)}>
                      <div className="buy-page-coins-button-text">
                        14.23 EUR
                      </div>
                    </Button>
                  )
                }
              </div>
            </Col>
          </Row>
        </Col>
        <Col />
      </Row>
    </div>
  );

  const premiumPacks = (
    <div className="buy-page-block-container room-ended-modal-money-pack">
      <Row>
        <Col />
        <Col>
          <Row>
            <Col xs="12">
              <div className="buy-page-premium-imageWrapper">
                <Media className="buy-page-premium-image1" src={balanceResetImg} alt="" />
              </div>
              <div className="buy-page-premium-wrapper">
                <div className="buy-page-premium-amount">
                  {t('buyPage.balanceReset')}
                </div>
                <div onMouseEnter={() => mouseEnter(constants.SHOP_PRODUCT_NAMES.money6)} onMouseLeave={() => mouseLeave(constants.SHOP_PRODUCT_NAMES.money6)} className="buy-page-info-imageWrapper">
                  <Media className="buy-page-info-image" src={infoImg} alt="" />
                  <div className={`buy-page-info-hover ${activeHover === constants.SHOP_PRODUCT_NAMES.money6 ? null : 'display-none'}`}>
                    {t('buyPage.balanceResetDesc')}
                  </div>
                </div>
              </div>
              <div className="buy-page-coins-button-wrapper">
                {
                  discounts.length > 0 ? (
                    filter(discounts, item => item.type === constants.SPECIALPRICE)[0] ? (
                      <>
                        <Button disabled style={{textDecorationLine: 'line-through'}} color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money6)}>
                          <div className="buy-page-premium-button-text">
                            5.00 EUR
                          </div>
                        </Button>
                        <Button disabled={(!balance || (balance > 0))} color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money6)}>
                          <div className="buy-page-premium-button-text">
                            {`${filter(discounts, item => item.type === constants.SPECIALPRICE)[0].specialPrice['money6']} EUR`}
                          </div>
                        </Button>
                      </>
                    ) : (
                      <Button disabled={(!balance || (balance > 0))} color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money6)}>
                        <div className="buy-page-premium-button-text">
                          5.00 EUR
                        </div>
                      </Button>
                    )
                  ) : (
                    <Button disabled={(!balance || (balance > 0))} color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money6)}>
                      <div className="buy-page-premium-button-text">
                        5.00 EUR
                      </div>
                    </Button>
                  )
                }
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col xs="12">
              <div className="buy-page-premium-imageWrapper">
                <Media className="buy-page-premium-image2" src={pointsResetImg} alt="" />
              </div>
              <div className="buy-page-premium-wrapper">
                <div className="buy-page-premium-amount">
                  {t('buyPage.pointsReset')}
                </div>
                <div onMouseEnter={() => mouseEnter(constants.SHOP_PRODUCT_NAMES.money7)} onMouseLeave={() => mouseLeave(constants.SHOP_PRODUCT_NAMES.money7)} className="buy-page-info-imageWrapper">
                  <Media className="buy-page-info-image" src={infoImg} alt="" />
                  <div className={`buy-page-info-hover ${activeHover === constants.SHOP_PRODUCT_NAMES.money7 ? null : 'display-none'}`}>
                    {t('buyPage.pointsResetDesc')}
                  </div>
                </div>
              </div>
              <div className="buy-page-coins-button-wrapper">
                {
                  discounts.length > 0 ? (
                    filter(discounts, item => item.type === constants.SPECIALPRICE)[0] ? (
                      <>
                        <Button disabled style={{textDecorationLine: 'line-through'}} color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money7)}>
                          <div className="buy-page-premium-button-text">
                            5.00 EUR
                          </div>
                        </Button>
                        <Button disabled={(!totalPoints || (totalPoints > 0))} color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money7)}>
                          <div className="buy-page-premium-button-text">
                            {`${filter(discounts, item => item.type === constants.SPECIALPRICE)[0].specialPrice['money7']} EUR`}
                          </div>
                        </Button>
                      </>
                    ) : (
                      <Button disabled={(!totalPoints || (totalPoints > 0))} color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money7)}>
                        <div className="buy-page-premium-button-text">
                          5.00 EUR
                        </div>
                      </Button>
                    )
                  ) : (
                    <Button disabled={(!totalPoints || (totalPoints > 0))} color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money7)}>
                      <div className="buy-page-premium-button-text">
                        5.00 EUR
                      </div>
                    </Button>
                  )
                }
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col xs="12">
              <div className="buy-page-premium-imageWrapper">
                <Media className="buy-page-premium-image3" src={profileResetImg} alt="" />
              </div>
              <div className="buy-page-premium-wrapper">
                <div className="buy-page-premium-amount">
                  {t('buyPage.profileRestart')}
                </div>
                <div onMouseEnter={() => mouseEnter(constants.SHOP_PRODUCT_NAMES.money8)} onMouseLeave={() => mouseLeave(constants.SHOP_PRODUCT_NAMES.money8)} className="buy-page-info-imageWrapper">
                  <Media className="buy-page-info-image" src={infoImg} alt="" />
                  <div className={`buy-page-info-hover-2 ${activeHover === constants.SHOP_PRODUCT_NAMES.money8 ? null : 'display-none'}`}>
                    {t('buyPage.profileRestartDesc')}
                  </div>
                </div>
              </div>
              <div className="buy-page-coins-button-wrapper">
                {
                  discounts.length > 0 ? (
                    filter(discounts, item => item.type === constants.SPECIALPRICE)[0] ? (
                      <>
                        <Button disabled style={{textDecorationLine: 'line-through'}} color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money8)}>
                          <div className="buy-page-premium-button-text">
                            5.00 EUR
                          </div>
                        </Button>
                        <Button color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money8)}>
                          <div className="buy-page-premium-button-text">
                            {`${filter(discounts, item => item.type === constants.SPECIALPRICE)[0].specialPrice['money8']} EUR`}
                          </div>
                        </Button>
                      </>
                    ) : (
                      <Button color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money8)}>
                        <div className="buy-page-premium-button-text">
                          5.00 EUR
                        </div>
                      </Button>
                    )
                  ) : (
                    <Button color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.money8)}>
                      <div className="buy-page-premium-button-text">
                        5.00 EUR
                      </div>
                    </Button>
                  )
                }
              </div>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row>
            <Col xs="12">
              <div className="buy-page-premium-imageWrapper">
                <Media className="buy-page-premium-image3" src={profileResetImg} alt="" />
              </div>
              <div className="buy-page-premium-wrapper">
                <div className="buy-page-premium-amount">
                  {t('buyPage.hideAdsMonth')}
                </div>
                <div onMouseEnter={() => mouseEnter(constants.SHOP_PRODUCT_NAMES.hideAdsMonth)} onMouseLeave={() => mouseLeave(constants.SHOP_PRODUCT_NAMES.hideAdsMonth)} className="buy-page-info-imageWrapper">
                  <Media className="buy-page-info-image" src={infoImg} alt="" />
                  <div className={`buy-page-info-hover-2 ${activeHover === constants.SHOP_PRODUCT_NAMES.hideAdsMonth ? null : 'display-none'}`}>
                    {t('buyPage.hideAdsMonthDesc')}
                  </div>
                </div>
              </div>
              <div className="buy-page-coins-button-wrapper">
                {
                  discounts.length > 0 ? (
                    filter(discounts, item => item.type === constants.SPECIALPRICE)[0] ? (
                      <>
                        <Button disabled style={{ textDecorationLine: 'line-through' }} color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.hideAdsMonth)}>
                          <div className="buy-page-premium-button-text">
                            5.00 EUR
                          </div>
                        </Button>
                        <Button color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.hideAdsMonth)}>
                          <div className="buy-page-premium-button-text">
                            {`${filter(discounts, item => item.type === constants.SPECIALPRICE)[0].specialPrice['hideAdsMonth']} EUR`}
                          </div>
                        </Button>
                      </>
                    ) : (
                        <Button color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.hideAdsMonth)}>
                        <div className="buy-page-premium-button-text">
                          5.00 EUR
                        </div>
                      </Button>
                    )
                  ) : (
                      <Button color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.hideAdsMonth)}>
                      <div className="buy-page-premium-button-text">
                        5.00 EUR
                      </div>
                    </Button>
                  )
                }
              </div>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row>
            <Col xs="12">
              <div className="buy-page-premium-imageWrapper">
                <Media className="buy-page-premium-image3" src={profileResetImg} alt="" />
              </div>
              <div className="buy-page-premium-wrapper">
                <div className="buy-page-premium-amount">
                  {t('buyPage.hideAdsYear')}
                </div>
                <div onMouseEnter={() => mouseEnter(constants.SHOP_PRODUCT_NAMES.hideAdsYear)} onMouseLeave={() => mouseLeave(constants.SHOP_PRODUCT_NAMES.hideAdsYear)} className="buy-page-info-imageWrapper">
                  <Media className="buy-page-info-image" src={infoImg} alt="" />
                  <div className={`buy-page-info-hover-2 ${activeHover === constants.SHOP_PRODUCT_NAMES.hideAdsYear ? null : 'display-none'}`}>
                    {t('buyPage.hideAdsYearDesc')}
                  </div>
                </div>
              </div>
              <div className="buy-page-coins-button-wrapper">
                {
                  discounts.length > 0 ? (
                    filter(discounts, item => item.type === constants.SPECIALPRICE)[0] ? (
                      <>
                        <Button disabled style={{ textDecorationLine: 'line-through' }} color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.hideAdsYear)}>
                          <div className="buy-page-premium-button-text">
                            5.00 EUR
                          </div>
                        </Button>
                        <Button color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.hideAdsYear)}>
                          <div className="buy-page-premium-button-text">
                            {`${filter(discounts, item => item.type === constants.SPECIALPRICE)[0].specialPrice['hideAdsYear']} EUR`}
                          </div>
                        </Button>
                      </>
                    ) : (
                        <Button color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.hideAdsYear)}>
                        <div className="buy-page-premium-button-text">
                          5.00 EUR
                        </div>
                      </Button>
                    )
                  ) : (
                    <Button color="link" className="buy-page-premium-button" onClick={() => buyMoney(constants.SHOP_PRODUCT_NAMES.hideAdsYear)}>
                      <div className="buy-page-premium-button-text">
                        5.00 EUR
                      </div>
                    </Button>
                  )
                }
              </div>
            </Col>
          </Row>
        </Col>
        <Col />
      </Row>
    </div>
  );

  const moneyPackTabs = (
    <div>
      <Row>
        <Col />
        <Col className="buy-page-header-button-wrapper">
          <Button color="link" className={classNames('buy-page-header-button', { 'buy-page-header-button-active': activeTab === constants.SHOP_NAVIGATION.moneyPacks })} onClick={() => toggle(constants.SHOP_NAVIGATION.moneyPacks)}>
            {t('buyPage.moneyPacks')}
          </Button>
        </Col>
        <Col className="buy-page-header-button-wrapper">
          <Button color="link" className={classNames('buy-page-header-button', { 'buy-page-header-button-active': activeTab === constants.SHOP_NAVIGATION.premiumPacks })} onClick={() => toggle(constants.SHOP_NAVIGATION.premiumPacks)}>
            {t('buyPage.premiumPacks')}
          </Button>
        </Col>
        <Col />
      </Row>
      <TabContent className="buy-page-tabContent" activeTab={activeTab}>
        <TabPane tabId={constants.SHOP_NAVIGATION.moneyPacks} className="buy-page-tab">
          {coinPacks}
        </TabPane>
        <TabPane tabId={constants.SHOP_NAVIGATION.premiumPacks} className="buy-page-tab">
          {premiumPacks}
        </TabPane>
      </TabContent>
    </div>
  );

  const messageTitle = balance < 0 ? t('buyPage.negativeBalanceTitle') : t('buyPage.lowBalanceTitle');
  const messageDescription = balance < 0 ? t('buyPage.negativeBalanceMsg') : t('buyPage.lowBalanceMsg');

  return (
    <div>
      <div className="room-ended-modal-message">
        <div className="room-ended-modal-message-title">{messageTitle}</div>
        <div className="room-ended-modal-message-coins">
          <Media src={coinImg} className="room-ended-modal-message-coins-coin" />
          <div className="room-ended-modal-message-coins-label">{balance}</div>
        </div>
        <p className="room-ended-modal-message-description">{messageDescription}</p>
      </div>
      <div className="room-ended-modal-buy-options">
        { balance < 0
          ? moneyPackTabs
          : coinPacks
        }
      </div>
    </div>
  );
};

RoomEndedModal.propTypes = {
  discounts: PropTypes.arrayOf(PropTypes.shape({})),
  balance: PropTypes.number,
  totalPoints: PropTypes.number,
  buyMoney: PropTypes.func,
  fetchDiscounts: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

RoomEndedModal.defaultProps = {
  discounts: [],
  balance: 0,
};

const mapStateToProps = state => ({
  discounts: state.shop.discounts || {},
  totalPoints: state.member.totalPoints,
});

const mapDispatchToProps = {
  fetchDiscounts: getDiscounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(RoomEndedModal));
