import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
} from 'reactstrap';
// import Frame from '../../../../../images/redesign/player/frame.svg';
import Spinner from 'reactstrap/lib/Spinner';
import * as constants from '../../../../../constants/constants';
// import {
//   isAndroid,
//   isIOS,
//   isIOS13,
// } from 'react-device-detect';

const Footer = React.memo(({
  stage,
  handleUpdateUser,
  handleCloseButton,
  deleteUser,
  handleSubmitForgotPassword,
  loadingSubmit,
  handleSubmitRegister,
  success,
  terms,
  toggleScreenMode,
  darkTheme,
  setThemeFunction,
  loading,
  handleStage,
  setOpen,
}) => {
  const { t } = useTranslation('common');

  const handleScreenTheme = () => {
    setThemeFunction(darkTheme);
    handleCloseButton();
  };
  console.log({ stage });
  const dontAddEmail = () => {
    try {
      localStorage.setItem('cancelAddEmailModal', 'true'); //x
    } catch (err) {
      console.log(err);
    }
    setOpen(false);
  }

  if (stage === constants.stages.confirmation) {
    return (
      <>
        <Button className="question-modal-approve-btn delete-user-question-modal-approve-btn" onClick={deleteUser}>
          {t('common.yes')}
        </Button>
        <Button
          className="question-modal-decline-btn delete-user-question-modal-decline-btn"
          onClick={handleCloseButton}
        >
          {t('common.no')}
        </Button>
      </>
    );
  } else if (stage === constants.stages.promotion) {
    return (
      <>
        <Button
          style={{ width: 'auto' }}
          className="modal-footer-button"
          onClick={() => handleStage(constants.stages.addEmail)}
        >
          {
            t('home.addEmail')
          }
        </Button>
        <Button
          style={{ width: 'auto' }}
          className="modal-footer-button"
          onClick={dontAddEmail}
        >
          {
            t('home.noThanks')
          }
        </Button>
      </>
    );
  } else if (stage === constants.stages.forgotPassword && !success) {
    return (
      <>
        <Button
          style={{ width: 'auto' }}
          className="modal-footer-button"
          onClick={handleSubmitForgotPassword}
          disabled={loadingSubmit}
        >
          {
            loadingSubmit ? 
              <>{t('home.loading')}<Spinner type="grow" style={{ width: '1rem', height: '1rem' }} /></> 
            : 
              t('home.resetPassword')
          }
        </Button>
        <Button
          style={{ width: 'auto', marginTop: '3px' }}
          className="modal-footer-button"
          onClick={handleCloseButton}
          disabled={loadingSubmit}
        >
          {
            loadingSubmit ? 
              <>{t('home.loading')}<Spinner type="grow" style={{ width: '1rem', height: '1rem' }} /></> 
            :
              t('common.close')
          }
        </Button>
      </>
    );
  } else if (stage === constants.stages.forgotPassword && success) {
    return (
      <Button
        style={{ width: 'auto' }}
        className="modal-footer-button"
        onClick={handleCloseButton}
      >
        {
          t('common.close')
        }
      </Button>
    );
  } else if (stage === constants.stages.register && !success) {
    return (
      <Button
        style={{ width: 'auto' }}
        className="modal-footer-button"
        onClick={(e) => handleSubmitRegister(e)}
        disabled={!terms || loadingSubmit}
      >
        {
          loadingSubmit ? 
            <>{t('home.loading')}<Spinner type="grow" style={{ width: '1rem', height: '1rem' }} /></>
          : 
            t('home.register')
        }
      </Button>
    );
  } else if (stage === constants.stages.register && success) {
    return (
      <Button
        style={{ width: 'auto' }}
        className="modal-footer-button"
        onClick={handleCloseButton}
      >
        {
            t('common.close')
        }
      </Button>
    );
  } else if (stage === constants.stages.settings) {
    return (
      <Button
        style={{ width: 'auto' }}
        className="modal-footer-button"
        onClick={() => handleScreenTheme()}
      >
        {
          t('home.saveProfile')
        }
      </Button>
    );
  } else
    return (
      <Button
        style={{ width: 'auto' }}
        className="modal-footer-button"
        onClick={handleUpdateUser}
        disabled={loading}
      >
        {
          loading ? 
            <>{t('home.loading')}<Spinner type="grow" style={{ width: '1rem', height: '1rem' }} /></>
          : 
            t('home.saveProfile')
        }
      </Button>
    );
});

Footer.propTypes = {
  stage: PropTypes.string,
  handleUpdateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  handleCloseButton: PropTypes.func.isRequired,
  handleSubmitForgotPassword: PropTypes.func.isRequired,
  handleSubmitRegister: PropTypes.func.isRequired,
  loadingSubmit: PropTypes.bool,
  success: PropTypes.bool,
  terms: PropTypes.bool,
  toggleScreenMode: PropTypes.func.isRequired,
  darkTheme: PropTypes.bool,
  setThemeFunction: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Footer.defaultProps = {
  stage: null,
  loadingSubmit: false,
  success: false,
  terms: false,
  darkTheme: true,
  loading: false,
};

export default Footer;
