import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// import {
//   FirebaseStorage, Firebase, FirebaseRef, LeaderboardRef, StatusRef, UserStatsRef, TournamentsRef,
// } from '../../../../lib/firebase';
// import axios from 'axios';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import LinkifyWithTargetBlank from '../../UI/LinkifyWithTargetBlank';
import CustomDate from '../../UI/CustomDate';
import CustomModal from '../Components/Components/Modal';
import IconPopover from '../Components/Components/IconPopover';

import downloadImg from '../../../../images/icons/download.svg';
import eyeImg from '../../../../images/icons/eye.svg';
import { Button, Label } from 'reactstrap';
import { breakLongWord } from '../../../../utils/stringUtils';
import { SCREEN_MODE } from '../../../../constants/constants';
// const SupportMessage = React.memo(({
//  message, filteredMessage, isFiltered, date, userUid, uid,
// }) => {


class SupportMessage extends React.Component {
  static propTypes = {
    //  message: PropTypes.shape(),
    message: PropTypes.string,
    date: PropTypes.number,
    userUid: PropTypes.string,
    uid: PropTypes.string,
    filteredMessage: PropTypes.string,
    messageId: PropTypes.string,
    isFiltered: PropTypes.bool,
    fileUpload: PropTypes.string,
    fileUploadUrl: PropTypes.string,
    fileName: PropTypes.string,
    screenMode: PropTypes.string,
    t: PropTypes.func.isRequired,
    translationKey: PropTypes.string,
    translationKeyData: PropTypes.shape({}),
  };

  static defaultProps = {
    message: null,
    date: null,
    userUid: null,
    uid: null,
    filteredMessage: null,
    messageId: null,
    isFiltered: false,
    fileUpload: null,
    fileUploadUrl: null,
    fileName: null,
    modalOpen: false,
    modalFile: '',
    modalFileName: '',
    screenMode: SCREEN_MODE.dark,
  };

  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      downloadPopoverOpen: false,
      viewPopoverOpen: false,
    };
  }

  onDownloadHover = (key) => {
    const { downloadPopoverOpen } = this.state;
    if (!downloadPopoverOpen) {
      this.setState({
        downloadPopoverOpen: true,
      });
    }
  }
  onDownloadHoverLeave = () => {
    this.setState({
      downloadPopoverOpen: false,
    });
  }
  onViewHover = (key) => {
    const { downloadPopoverOpen } = this.state;
    if (!downloadPopoverOpen) {
      this.setState({
        viewPopoverOpen: true,
      });
    }
  }
  onViewHoverLeave = () => {
    this.setState({
      viewPopoverOpen: false,
    });
  }

  onHover = (key) => {
    const { popoverOpen } = this.state;
    if (!popoverOpen) {
      this.setState({
        popoverOpen: key,
      });
    }
  }

  onHoverLeave = () => {
    this.setState({
      popoverOpen: false,
    });
  }

  openFileLink = (modalFile, modalFileName) => {
    this.setState({
      modalFile,
      modalFileName,
      modalOpen: true,
    });
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
    }));
  }

  downloadFile = (downFileUrl, downFileName) => {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      let blob = xhr.response;
      const objectUrl = window.URL.createObjectURL(blob);

      anchor.href = objectUrl;
      anchor.download = downFileName;
      anchor.click();
    };
    xhr.open('GET', downFileUrl);
    // xhr.setRequestHeader("Content-Type", "text/plain;charset=UTF-8");
    // xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.send();
    // axios({
    //   //  method: 'get',
    //   method: 'HEAD',
    //   headers: {
    //     'Access-Control-Allow-Origin': '*',
    //   },
    //   mode: 'no-cors',
    //   url: 'https://ipv4.icanhazip.com/',
    // }).then(response => {
    //   console.log('CONNECTED TO INTERNET', response);
    // }).catch(error => {
    //   console.log(error);
    // });
  }

  render() {
    const {
      messageId, message, filteredMessage, isFiltered, date, userUid, uid, fileUpload, fileName, fileUploadUrl, screenMode, t, translationKey, translationKeyData,
    } = this.props;
    const { popoverOpen, downloadPopoverOpen, viewPopoverOpen, modalOpen, modalFile, modalFileName } = this.state;

    if (isFiltered && filteredMessage) {
      let splitMessage = [];
      let splitFilteredMessage = [];

      splitMessage = message.split(/\n/);

      splitFilteredMessage = filteredMessage.split(/\n/);
      console.log(message, filteredMessage, fileUpload, fileName, "check message");
      return (
        <Fragment>
          {uid && userUid && userUid.toString() === uid.toString() ? (
            <Row className="contact-support-chat-message-row">
              <Col md="12" className="contact-support-chat-message">

                <LinkifyWithTargetBlank>
                  <div className="contact-support-chat-message-user">
                    {splitFilteredMessage.map((row, index) => {
                      let words = [];
                      if (splitMessage[index]) {
                        words = splitMessage[index].split(' ');
                      }
                      const filteredWords = row.split(' ');

                      return (
                        <div key={index}>
                          {filteredWords.map((word, index2) => (
                            <>
                              {(words && words[index2] !== word) ? (
                                <>
                                  <div
                                    style={{ display: 'inline-block', marginRight: 2 }}
                                    id={`chat-popover-${messageId}-${index2}`}
                                    onMouseEnter={() => this.onHover(`${messageId}-${index2}`)}
                                    onMouseLeave={this.onHoverLeave}
                                  >
                                    {`${word} `}
                                  </div>
                                  <Popover container={'div > div'} popperClassName="last-round-popover last-round-new-popover" placement="top" isOpen={popoverOpen === `${messageId}-${index2}`} target={`chat-popover-${messageId}-${index2}`}>
                                    <PopoverBody className="last-round-popover-body">
                                      {`${words[index2]}`}
                                    </PopoverBody>
                                  </Popover>
                                </>
                              ) : (
                                <>
                                  {`${word} `}
                                </>
                              )}
                            </>
                          ))}
                          {
                            fileName && (
                              <>
                                {/* <LinkifyWithTargetBlank>
                                  <a href={fileUpload || ''} download={fileName || ''}>
                                    <Media className="contact-support-chat-message-user-attach-download" src={downloadImg} alt="x"  />
                                  </a>
                                </LinkifyWithTargetBlank> */}
                                <Media className="contact-support-chat-message-user-attach-download" onClick={() => this.downloadFile(fileUploadUrl, fileName)} src={downloadImg} alt="x" />
                                <a onClick={() => this.openFileLink(fileUploadUrl || '', fileName || '')}>
                                  <Media className="contact-support-chat-message-user-attach-open" src={eyeImg} alt="x" />
                                </a>
                              </>
                            )
                          }
                        </div>
                      );
                    })}
                  </div>
                </LinkifyWithTargetBlank>
                <div className="contact-support-chat-message-user-time">
                  {date && (
                    <CustomDate format="DD/MM hh:mm" date={date} />
                  )}
                </div>
              </Col>
            </Row>
          ) : (
            <Row className="contact-support-chat-message-row">
              <Col md="12" className="contact-support-chat-message">

                <LinkifyWithTargetBlank>
                  <div className="contact-support-chat-message-other">
                    {translationKey ? (
                      <p style={{ marginBottom: 0, lineHeight: '20px' }}>
                        {t(`support.${translationKey}`, translationKeyData)}
                      </p>
                    ) : (
                      <>
                        {splitMessage.map(row => (
                          <p style={{ marginBottom: 0, lineHeight: '20px' }}>
                            {row}
                          </p>
                        ))}
                      </>
                    )}
                    {
                      fileName && (
                        <>
                          {/* <LinkifyWithTargetBlank>
                          <a href={fileUpload || ''} download={fileName || ''}>
                            <Media className="contact-support-chat-message-user-attach-download" src={downloadImg} alt="x"  />
                          </a>
                        </LinkifyWithTargetBlank> */}
                          <Media className="contact-support-chat-message-user-attach-download" onClick={() => this.downloadFile(fileUploadUrl, fileName)} src={downloadImg} alt="x" />
                          <a onClick={() => this.openFileLink(fileUploadUrl || '', fileName || '')}>
                            <Media className="contact-support-chat-message-user-attach-open" src={eyeImg} alt="x" />
                          </a>
                        </>
                      )
                    }
                  </div>
                </LinkifyWithTargetBlank>
                <div className="contact-support-chat-message-other-time">
                  {date && (
                    <CustomDate format="DD/MM hh:mm" date={date} />
                  )}
                </div>
              </Col>
            </Row>
          )}
          <CustomModal
            isOpen={modalOpen}
            toggle={() => this.toggleModal()}
            size="lg"
            title={t('home.view')}
            body={
              <iframe className='frame-object frame-object-new-modal' id='frame1' src={fileUpload} style={{ width: 'auto', height: 'auto' }} frameBorder="0" allowFullScreen></iframe>
            }
          />
        </Fragment>
      );
    }
    {
      const splitMessage = message ? message.split(/\n/) : false;
      return (
        <Fragment>
          {uid && userUid && userUid.toString() === uid.toString() ? (
            <Row className="contact-support-chat-message-row">
              <Col xs="12" className="contact-support-chat-message">
                <div className="contact-support-chat-message-user">
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    {splitMessage && splitMessage.map(row => (
                      <>
                        <p style={{ marginBottom: 0, lineHeight: '20px', /*wordBreak: 'break-all',*/ paddingLeft: '15px', paddingRight: '15px' }}>
                          {breakLongWord(row, 60, {color: 'rgb(255, 132, 0)', textDecoration: 'underline'})}
                        </p>
                      </>
                    ))}
                  </div>
                  {fileName && (
                    <p style={{ marginBottom: 0, lineHeight: '20px' }}>
                      <span style={{color: '#2E8B57'}}>[{fileName}]</span>
                    </p>
                  )}
                  {
                    fileName && (
                      <>
                        {/* <a href={fileUpload || ''} download={fileName || ''} rel="origin-when-cross-origin"> */}
                        <Media
                          className="contact-support-chat-message-user-attach-download"
                          onClick={() => this.downloadFile(fileUploadUrl, fileName)}
                          src={downloadImg}
                          alt="x"
                          id={`${messageId}-download`}
                          onMouseEnter={this.onDownloadHover}
                          onMouseLeave={this.onDownloadHoverLeave}
                        />
                        {/* </a> */}

                        <IconPopover text={t('support.download')} popoverOpen={downloadPopoverOpen} targetId={`${messageId}-download`} placement="top"/>
                        <a onClick={() => this.openFileLink(fileUploadUrl || '', fileName || '')}>
                          <Media
                            className="contact-support-chat-message-user-attach-open"
                            src={eyeImg}
                            alt="x"
                            id={`${messageId}-view`}
                            onMouseEnter={this.onViewHover}
                            onMouseLeave={this.onViewHoverLeave}
                          />
                        </a>
                        <IconPopover text={t('support.view')} popoverOpen={viewPopoverOpen} targetId={`${messageId}-view`} placement="top"/>

                      </>
                    )
                  }
                </div>
              </Col>
              <Col xs="12">
                <div className="contact-support-chat-message-user-time">
                  {date && (
                    <CustomDate format="DD/MM hh:mm" date={date} />
                  )}
                </div>
              </Col>
            </Row>
          ) : (
            <Row className="contact-support-chat-message-row">
              <Col xs="12" className="contact-support-chat-message">
                <div className="contact-support-chat-message-other">
                  {translationKey ? (
                    <p style={{ marginBottom: 0, lineHeight: '20px' }}>
                      {t(`support.${translationKey}`, translationKeyData)}
                    </p>
                  ) : (
                    <>
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        {splitMessage && splitMessage.map(row => (
                          <p style={{ marginBottom: 0, lineHeight: '20px', wordBreak: 'break-all', paddingLeft: '15px', paddingRight: '15px' }}>
                            {breakLongWord(row, 60, {color: 'rgb(44, 117, 141)', textDecoration: 'underline'})}
                          </p>
                        ))}
                      </div>
                      {
                        fileName && (
                          <p style={{ marginBottom: 0, lineHeight: '20px' }}>
                            <span style={{color: '#2E8B57'}}>[{fileName}]</span>
                          </p>
                        )
                      }
                    </>
                  )}

                  {
                    fileName && (
                      <>
                        {/* <a href={fileUpload || ''} download={fileName || ''} rel="origin-when-cross-origin"> */}
                        <Media 
                          className="contact-support-chat-message-user-attach-download"
                          src={downloadImg}
                          onClick={() => this.downloadFile(fileUploadUrl, fileName)}
                          alt="x"
                          id={`${messageId}-download`}
                          onMouseEnter={this.onDownloadHover}
                          onMouseLeave={this.onDownloadHoverLeave}
                        />
                        <IconPopover text={t('support.download')} popoverOpen={downloadPopoverOpen} targetId={`${messageId}-download`} placement="top"/>
                        {/* </a> */}
                        <a onClick={() => this.openFileLink(fileUploadUrl || '', fileName || '')}>
                          <Media
                            className="contact-support-chat-message-user-attach-open"
                            src={eyeImg}
                            alt="x"
                            id={`${messageId}-view`}
                            onMouseEnter={this.onViewHover}
                            onMouseLeave={this.onViewHoverLeave}
                          />
                        </a>
                        <IconPopover text={t('support.view')} popoverOpen={viewPopoverOpen} targetId={`${messageId}-view`} placement="top"/>
                      </>
                    )
                  }
                </div>
              </Col>
              <Col xs="12">
                <div className="contact-support-chat-message-other-time">
                  {date && (
                    <CustomDate format="DD/MM hh:mm" date={date} />
                  )}
                </div>
              </Col>
            </Row>
          )}
          <CustomModal
            isOpen={modalOpen}
            toggle={() => this.toggleModal()}
            size="lg"
            title={modalFileName}
            body={(
              <div className="d-flex flex-column gap-20">
                <div className="d-flex flex-row align-items-end">
                  <Button onClick={() => this.downloadFile(modalFile, modalFileName)}>{t('support.download')}</Button>
                </div>
                <iframe className="frame-object frame-object-new-modal" title="contanct-support-download" id="frame1" src={modalFile} style={{ width: 'auto', height: 'auto' }} frameBorder="0" allowFullScreen />
              </div>
            )}
          />
        </Fragment>
      );
    }
  }
}

/*
SupportMessage.propTypes = {
  message: PropTypes.string,
  date: PropTypes.number,
  userUid: PropTypes.string,
  uid: PropTypes.string,
};

SupportMessage.defaultProps = {
  message: null,
  uid: null,
  userUid: null,
  date: null,
}; */

export default SupportMessage;
