import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PaginationNew = ({ show, currentPage, pagesCount, handlePagination }) => {

    if (!show) return null;

    return (
        <div className="pagination-wrapper-new">
            <Pagination aria-label="Page navigation example">
                <PaginationItem disabled={currentPage === 0}>
                    <PaginationLink style={{ color: '#000' }} onClick={e => handlePagination(e, 0)} href="#">
                        {`‹‹‹`}
                    </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={currentPage <= 0}>
                    <PaginationLink
                        style={{ color: '#000' }}
                        onClick={e => handlePagination(e, currentPage - 1)}
                        previous
                        href="#"
                    />
                </PaginationItem>

                {[...Array(pagesCount)].map((page, i) => {
                    if (i > currentPage - 4 && i < currentPage + 4) {
                    return (
                        <PaginationItem active={i === currentPage} key={page}>
                            <PaginationLink style={{ color: '#000' }} onClick={e => handlePagination(e, i)} href="#">
                                {i + 1}
                            </PaginationLink>
                        </PaginationItem>
                    );
                    }

                    return null;
                })}

                <PaginationItem disabled={currentPage >= pagesCount - 1}>
                    <PaginationLink
                        style={{ color: '#000' }}
                        onClick={e => handlePagination(e, currentPage + 1)}
                        next
                        href="#"
                    />
                </PaginationItem>

                <PaginationItem disabled={currentPage >= pagesCount - 1}>
                    <PaginationLink style={{ color: '#000' }} onClick={e => handlePagination(e, pagesCount - 1)} href="#">
                        {`›››`}
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </div>
    );
};

export default PaginationNew;