import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import TopPageMillionaires from './TopPageMillionaires';

import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

import leaderboardImg from '../../../images/icons/leaderboard.svg';
import coinImg from '../../../images/coin.svg';
import closeImg from '../../../images/icons/close.png';

import buttonClickedSound from '../../../sounds/click_feedback.flac';

import {
  getLeaderboardMillionaires,
} from '../../../actions/leaderboard';

import {
  blockUser,
  unBlockUser,
//  getIgnoredPlayers,
} from '../../../actions/member';

import {
  FONT_MODE
} from '../../../constants/constants';

class TopPage extends React.Component {
  static propTypes = {
  //  leaderboard: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,

    fetchLeaderboardMillionaires: PropTypes.func.isRequired,
    blockUserFunc: PropTypes.func.isRequired,
    unBlockUserFunc: PropTypes.func.isRequired,
    //  fetchIgnoredPlayers: PropTypes.func.isRequired,
    notificationSettings: PropTypes.shape({
      ignorePlayer: PropTypes.bool,
    }),
   // userSettings: PropTypes.shape({
   //   soundOn: PropTypes.bool,
  //  }),
    ignoredUsers: PropTypes.shape({}),
    //  i18n: PropTypes.shape(),
    uid: PropTypes.string,
    fontMode: PropTypes.string,
  }

  static defaultProps = {
    notificationSettings: {},
  //  userSettings: {},
    ignoredUsers: {},
    //  i18n: null,
    uid: null,
    fontMode: FONT_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 0,
      showOnlyActive: false,
    };

    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentDidMount() {
    const { fetchLeaderboardMillionaires } = this.props;

    fetchLeaderboardMillionaires(false);
  }


  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  playButtonSound = () => {
    const { soundOn, uid } = this.props;

      if (soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
  }

  block = (id, name) => {
    const { blockUserFunc, notificationSettings } = this.props;

    this.playButtonSound();
    //  blockUserFunc(id, name).then((res) => {
    //  });

    if (notificationSettings && notificationSettings.ignorePlayer === false) {
      blockUserFunc(id, name);
    } else {
      this.setState(prevState => ({
        blockUserModal: !prevState.blockUserModal,
        blockUserSelectedUid: id,
        blockUserSelectedName: name,
      }));
    }
  }

  blockUserConfirm = () => {
    const { blockUserFunc } = this.props;
    const { blockUserSelectedUid, blockUserSelectedName } = this.state;

    if (blockUserSelectedUid && blockUserSelectedName) {
      blockUserFunc(blockUserSelectedUid, blockUserSelectedName);

      this.playButtonSound();

      this.setState({
        blockUserModal: false,
        blockUserSelectedUid: null,
        blockUserSelectedName: null,
      });
    }
  };

  closeBlockModal = () => {
    this.setState({
      blockUserModal: false,
      blockUserSelectedUid: null,
      blockUserSelectedName: null,
    });
  }

  unblock = (id) => {
    const { unBlockUserFunc } = this.props;

    this.playButtonSound();
    unBlockUserFunc(id).then(() => {
    //  if (res && res.status === 'success') {
    //    fetchIgnoredPlayers();
    //  }
    });
  }

  onHoverActivePlayer = () => {
    this.setState({ popoverOpen4ActivePlayer: true });
  }

  onHoverLeaveActivePlayer = () => {
    this.setState({ popoverOpen4ActivePlayer: false });
  }

  handleChange = (event) => {
    const { fetchLeaderboardMillionaires } = this.props;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    fetchLeaderboardMillionaires(value);
    this.setState({
      [event.target.name]: value,
    });
  }

  render() {
    const {
    //  leaderboard,
      t,
      //  i18n,
      //  toggle,
      ignoredUsers,
      fontMode,
    } = this.props;

    const {
      scrollPos,
      blockUserModal,
      blockUserSelectedName,
      popoverOpen4ActivePlayer
    } = this.state;

    return (
      <div className="top">
        <Helmet>
          <title>
            Zole - {t('route.leaderboard')}
          </title>
        </Helmet>
        <Row className="top-tabs">
          <Col sm="12">
            <Row className="top-tabs-header">
              <Col sm="4">
                <Media src={leaderboardImg} className="top-tabs-header-image" />
                <div className="top-tabs-header-text">
                  {t('top.top')}
                </div>
              </Col>
              {<Col xs="4" >
                <Label check
                  onMouseEnter={this.onHoverActivePlayer}
                  onMouseLeave={this.onHoverLeaveActivePlayer}
                  style={{marginTop: 5, marginLeft: 20}}>
                <Input
                  type="checkbox"
                  id="top-checkbox-popover"
                  name="showOnlyActive"
                  onChange={this.handleChange}
                  onMouseEnter={this.onHoverActivePlayer}
                  onMouseLeave={this.onHoverLeaveActivePlayer}
                />
                {t('top.showOnlyActivePlayers')}
                </Label>
                <Popover
                  container={'div > div'}
                  placement="bottom"
                  isOpen={popoverOpen4ActivePlayer}
                  target="top-checkbox-popover"
                  popperClassName={`popover-font-${fontMode}`}
                >
                  <PopoverBody>
                    <div>
                      {t('top.showActivePlayerRule')}
                    </div>
                  </PopoverBody>
                </Popover>
              </Col>}
              <Col style={{ paddingRight: 0 }} />
              <Col />
              <Col />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <table className="top-table-header">
              <thead className="top-table-header">
                <tr className="top-table-header-row">
                  <th className="top-table-header-col col-index">
                    {t('common.position')}
                  </th>
                  <th className="top-table-header-col col-player">
                    {t('top.player')}
                  </th>
                  <th className="top-table-header-col">
                    {t('common.level')}
                  </th>
                  <th className="top-table-header-col">
                    {t('common.points')}
                  </th>
                  <th className="top-table-header-col">
                    <Media src={coinImg} className="top-table-header-coin" />
                  </th>
                  <th className="top-table-header-col">
                    {t('common.parties')}
                  </th>
                  <th className="top-table-header-col">
                    {t('ignoredUsers.block')}
                  </th>
                </tr>
              </thead>
              <tbody />
            </table>
            {/*  <ScrollAreaButtons
              scrollPos={scrollPos}
              show
              scroll={this.scroll}
              speed={30}
              rightOffset={12}
              topOffset={34}
            />
            <ScrollArea
              speed={0.55}
              className="top-scrollarea"
              contentClassName="top-scrollarea-body"
              smoothScrolling
              stopScrollPropagation
              verticalContainerStyle={{
                background: 'transparent',
                opacity: 1,
                width: 11,
              }}
              verticalScrollbarStyle={{
                background: '#fff',
                borderRadius: 1,
                width: 10,
                minHeight: 10,
                minScrollSize: 25,
              }}
              horizontal={false}
              onScroll={this.updateScrollPos}
              ref={(el) => {
                this.tableScrollbar = el;
              }}
            > */}
            <ScrollAreaWrapper
              className="top-scrollarea"
              contentClassName="top-scrollarea-body"
              show
              rightOffset={12}
              topOffset={34}
              bottomOffset={0}
            >
              <Row>
                <Col sm="12">
                  <TopPageMillionaires block={this.block} unblock={this.unblock} ignoredUsers={ignoredUsers} />
                </Col>
              </Row>
            </ScrollAreaWrapper>
          </Col>
        </Row>
        <Modal container={'div > div'} isOpen={blockUserModal} toggle={() => this.closeBlockModal()} className={`notification root-font-${fontMode}`}>
          <ModalHeader
            toggle={() => this.closeBlockModal(null, null)}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.closeBlockModal(null, null)} />
            }
          />
          <ModalBody className="notification-body" >
            {t('common.blockPlayerConfirm', { player: blockUserSelectedName })}
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button color="link" className="btn notification-footer-button" onClick={this.blockUserConfirm}>
              {t('common.yes')}
            </Button>
            <Button color="link" className="btn notification-footer-button" onClick={() => this.closeBlockModal(null, null)}>
              {t('common.no')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
//  userSettings: state.userSettings[state.member.uid] || state.userSettings.default || {},
  soundOn: state.userSettings.soundOn,
  //  leaderboard: state.leaderboard.leaderboardMillionaires || [],
  uid: state.member.uid || '',
  ignoredUsers: state.member.ignoredUsers || {},
});

const mapDispatchToProps = {
  fetchLeaderboardMillionaires: getLeaderboardMillionaires,
  blockUserFunc: blockUser,
  unBlockUserFunc: unBlockUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TopPage));
