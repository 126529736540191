import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class OnlineCount extends Component {
  static propTypes = {
    usersCount: PropTypes.number,
    roomsCount: PropTypes.number,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    usersCount: null,
    roomsCount: null,
  }

  constructor(props) {
    super(props);
    this.state = {
    //  leaderboardPosition: '',
    };
  }

  render() {
    const {
      t, usersCount, roomsCount,
    } = this.props;

    return (
      <div className="online-stats">
        <div className="d-flex align-items-center">
          <span className="online-stats-text">{`${t('menu.onlineCount')}: `}</span>
          <span className="online-stats-count">{`${usersCount} ${t('menu.players')}`}</span>
          <div className="online-stats-divider" />
          <span className="online-stats-count">{`${roomsCount} ${t('menu.rooms')}`}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  usersCount: state.users.usersCount || null,
  roomsCount: state.users.roomsCount || null,
});

const mapDispatchToProps = {
//  removeGiftFunction: removeGift,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(OnlineCount));
