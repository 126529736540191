import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import Spinner from 'reactstrap/lib/Spinner';

import { filter, size } from 'lodash';

import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import * as constants from '../../../constants/constants';
import coinImg from '../../../images/coin.svg';
// import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';
import config from '../../../constants/config';

const TopPageList = React.memo(({
  handleSendAddFriend, handleRemoveFriend, friends, t, i18n, leaderboard, myLeaderboard, isFiltered, activeType, ignoredUsers, bannedUsers, block, unblock, showFields, name, leaderboardLoading
}) => {
  let activeTypeFilter;
  let activeTypeAction;

  if (isFiltered) {
    activeTypeFilter = constants.filterLeaderboardType.filter(item => item.id === activeType.toString())[0].label;
    activeTypeAction = constants.filterLeaderboardType.filter(item => item.id === activeType.toString())[0].action;
  }

  return (
    <Fragment>
      <div className="top-table-wrapper">
        <table className="top-table" tabindex="0">
          <colgroup>
            <col span="1" className="" />
          </colgroup>
          <thead className="top-table-header">
            <tr className="top-table-header-row">
              <th className="top-table-header-col col-index">
                {t('common.position')}
              </th>
              <th className="top-table-header-col col-player">
                {t('top.player')}
              </th>
              <th className="top-table-header-col col-lvl">
                {t('common.level')}
              </th>
              <th className="top-table-header-col">
                {t('common.points')}
              </th>
              <th className="top-table-header-col">
                <Media src={coinImg} className="top-table-header-coin" />
              </th>
              <th className="top-table-header-col">
                {t('common.parties')}
              </th>
              <th className="top-table-header-col">
                {t('route.friends')}
              </th>
              <th className="top-table-header-col">
                {t('ignoredUsers.block')}
              </th>
              {
                showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.gWon) !== -1 && (
                  <th className="top-table-header-col">
                    {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.gWon])[0].tLabel}`)}
                  </th>
                )
              }
              {
                showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) !== -1 && (
                  <th className="top-table-header-col">
                    {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.bgWon])[0].tLabel}`)}
                  </th>
                )
              }
              {
                showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.sgWon) !== -1 && (
                  <th className="top-table-header-col">
                    {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.sgWon])[0].tLabel}`)}
                  </th>
                )
              }
              {
                showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.tblsWon) !== -1 && (
                  <th className="top-table-header-col">
                    {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.tblsWon])[0].tLabel}`)}
                  </th>
                )
              }
              {
                showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.achvCnt) !== -1 && (
                  <th className="top-table-header-col">
                    {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.achvCnt])[0].tLabel}`)}
                  </th>
                )
              }
              {
                showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) !== -1 && (
                  <th className="top-table-header-col">
                    {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.maxRndInGame])[0].tLabel}`)}
                  </th>
                )
              }
            </tr>
          </thead>
          <tbody>
            {console.log('myLeaderboard', myLeaderboard)}

            <Fragment>
              {myLeaderboard && (myLeaderboard.position === 0 || myLeaderboard.position > 99) && (
              <tr className="top-table-row-user">
                <td className="top-table-col col-index">
                  {myLeaderboard.position || '-'}
                </td>
                <td className="top-table-col col-player">
                  {name && name !== myLeaderboard.name ? name : myLeaderboard.name}
                </td>
                <td className="top-table-col col-lvl">
                  <div style={{ position: 'relative' }}>
                    <div className="col-lvl-wrapper">
                      <div className="col-lvl-lvl">
                        <div className="col-lvl-text">
                          {myLeaderboard.lvl}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="top-table-col">
                  {myLeaderboard.points}
                </td>
                <td className="top-table-col">
                  {myLeaderboard.balance}
                </td>
                <td className="top-table-col">
                  {myLeaderboard.gamesPlayed}
                </td>
                <td />
                <td />
                {
                  showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.gWon) !== -1 && (
                    <td className="top-table-col">
                      {myLeaderboard.gWon || 0}
                    </td>
                  )
                }
                {
                  showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) !== -1 && (
                    <td className="top-table-col">
                      {myLeaderboard.bgWon || 0}
                    </td>
                  )
                }
                {
                  showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.sgWon) !== -1 && (
                    <td className="top-table-col">
                      {myLeaderboard.sgWon || 0}
                    </td>
                  )
                }
                {
                  showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.tblsWon) !== -1 && (
                    <td className="top-table-col">
                      {myLeaderboard.tblsWon || 0}
                    </td>
                  )
                }
                {
                  showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.achvCnt) !== -1 && (
                    <td className="top-table-col">
                      {myLeaderboard.achvCnt || 0}
                    </td>
                  )
                }
                {
                  showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) !== -1 && (
                    <td className="top-table-col">
                      {myLeaderboard.maxRndInGame || 0}
                    </td>
                  )
                }
              </tr>
              )}
            </Fragment>
            <Fragment>
              {leaderboard && leaderboard.map((pos, index) => (
                (index <= constants.TOP_LIST_LIMIT.limit && (!isFiltered || (isFiltered && activeTypeFilter && pos[activeTypeAction]))) && (
                  (pos.key && bannedUsers && !bannedUsers[pos.key]) && (
                    <Fragment key={pos.key}>
                      <tr key={pos.key} className="top-table-row">
                        <td className="top-table-col">
                          {(isFiltered && activeTypeFilter) ? (pos[activeTypeAction] || null) : (index + 1)}
                        </td>
                        <td className="top-table-col col-player">
                          {pos.name}
                        </td>
                        <td className="top-table-col col-lvl">
                          <div style={{ position: 'relative' }}>
                            <div className="col-lvl-wrapper">
                              <div className="col-lvl-lvl">
                                <div className="col-lvl-text">
                                  {pos.lvl}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="top-table-col">
                          { pos.points || 0 }
                        </td>
                        <td className="top-table-col">
                          { bannedUsers && bannedUsers[pos.key] ? 0 : pos.balance }
                        </td>
                        <td className="top-table-col">
                          {pos.gamesPlayed || 0}
                        </td>
                        <td>
                          {
                            size(filter(friends, item => item.uid === pos.key)) > 0 ? (
                              <Button
                                color="link"
                                className={`ignored-users-table-button  ${i18n.language === 'ru' || i18n.language === 'lv' ? 'font-size-9' : null}`}
                                onClick={() => handleRemoveFriend(pos.key)}
                              >
                                {t('top.removeFriend')}
                              </Button>
                            ) : (
                              <Button
                                color="link"
                                className={`ignored-users-table-button  ${i18n.language === 'ru' || i18n.language === 'lv' ? 'font-size-9' : null}`}
                                onClick={() => handleSendAddFriend(pos.key)}
                              >
                                {t('top.addAsFriend')}
                              </Button>
                            )
                          }
                        </td>
                        <td>
                          {
                            (pos[activeTypeAction] !== myLeaderboard?.position) && (
                              pos.key && ignoredUsers && ignoredUsers[pos.key] ? (
                                <Button color="link" className={`ignored-users-table-button  ${i18n.language === 'ru' ? 'font-size-9' : null}`} onClick={() => unblock(pos.key)}>
                                  {t('ignoredUsers.unBlock')}
                                </Button>
                              ) : (
                                <Button color="link" className={`ignored-users-table-button  ${i18n.language === 'ru' ? 'font-size-9' : null}`} onClick={() => block(pos.key, pos.name)}>
                                  {t('ignoredUsers.block')}
                                </Button>
                              )
                            ) 
                          }
                        </td>
                        {
                          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.gWon) !== -1 && (
                            <td className="top-table-col">
                              {pos.gWon || 0}
                            </td>
                          )
                        }
                        {
                          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) !== -1 && (
                            <td className="top-table-col">
                              {pos.bgWon || 0}
                            </td>
                          )
                        }
                        {
                          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.sgWon) !== -1 && (
                            <td className="top-table-col">
                              {pos.sgWon || 0}
                            </td>
                          )
                        }
                        {
                          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.tblsWon) !== -1 && (
                            <td className="top-table-col">
                              {pos.tblsWon || 0}
                            </td>
                          )
                        }
                        {
                          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.achvCnt) !== -1 && (
                            <td className="top-table-col">
                              {pos.achvCnt || 0}
                            </td>
                          )
                        }
                        {
                          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) !== -1 && (
                            <td className="top-table-col">
                              {pos.maxRndInGame || 0}
                            </td>
                          )
                        }
                      </tr>
                    </Fragment>
                  )
                )
              ))}
            </Fragment>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
});

TopPageList.propTypes = {
  leaderboard: PropTypes.shape(),
  myLeaderboard: PropTypes.shape(),
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape(),
  isFiltered: PropTypes.bool,
  activeType: PropTypes.string,
  ignoredUsers: PropTypes.shape(),
  bannedUsers: PropTypes.shape({}),
  block: PropTypes.func.isRequired,
  unblock: PropTypes.func.isRequired,
  showFields: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  leaderboardLoading: PropTypes.bool,
  handleSendAddFriend: PropTypes.func.isRequired,
  friends: PropTypes.shape({}),
  handleRemoveFriend: PropTypes.func.isRequired,
};

TopPageList.defaultProps = {
  leaderboard: null,
  myLeaderboard: null,
  isFiltered: false,
  activeType: null,
  ignoredUsers: null,
  bannedUsers: null,
  i18n: null,
  showFields: [],
  name: null,
  leaderboardLoading: false,
  friends: {},
};

export default TopPageList;
