import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { filter } from 'lodash';
import {
    Button,
  Col, Media, NavLink,
} from 'reactstrap';
import { MENU_NAVIGATION, MENU_NAVIGATION_TYPE, SCREEN_MODE } from '../../../../../../constants/constants';
import tutorialImage from '../../../../../../images/icons/help2.svg';
import lightInfoImg from '../../../../../../images/redesign/light-mode/player/info.svg';
import TournamentTutorial from '../Components/TournamentTutorial';
import ReactGA from 'react-ga';

const TournamentsSubHeader = ({ changeTab, activeTab, screenMode }) => {
    const { t } = useTranslation('common');
    const [showTournamentTutorial, setShowTournamentTutorial] = useState(false);
//   useEffect(() => {
//     const element = document.getElementById(filter(MENU_NAVIGATION_TYPE, item => (item.id === activeTab.toString()))[0].label);
//     console.log("NotActiveMenu: ", activeTab, element)
//     if (element) {
//       element.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, [activeTab]);

    const toggleShowTournamentTutorial = () => {
        if (!showTournamentTutorial) {
            ReactGA.event({
                category: 'Menu',
                action: 'Tournaments Help',
            });
        }
        setShowTournamentTutorial(!showTournamentTutorial);
    }

    return (
        <>
            <div className='tournament-subheader-wrapper'>
                <div className="layout-subheader-link">
                <Button color="link" className={`layout-subheader-link-text ${activeTab === MENU_NAVIGATION.tournaments && 'layout-subheader-link-text-active'} active-tournaments-button active-tournaments-button-joyride-target`} onClick={() => changeTab(MENU_NAVIGATION.tournaments)}>
                    {t('tournaments.activeTournaments')}
                </Button>
                </div>
                <div className="layout-subheader-link">
                <Button color="link" className={`layout-subheader-link-text ${activeTab === MENU_NAVIGATION.futureTournaments && 'layout-subheader-link-text-active'} future-tournaments-button future-tournaments-button-joyride-target`} onClick={() => changeTab(MENU_NAVIGATION.futureTournaments)}>
                    {t('tournaments.futureTournaments')}
                </Button>
                </div>
                <div className="layout-subheader-link">
                <Button color="link" className={`layout-subheader-link-text ${activeTab === MENU_NAVIGATION.tournamentsHistory && 'layout-subheader-link-text-active'} past-tournaments-button past-tournaments-button-joyride-target`} onClick={() => changeTab(MENU_NAVIGATION.tournamentsHistory)}>
                    {t('tournaments.tournamentHistory')}
                </Button>
                </div>

                <div className="layout-subheader-link">
                <div className='tournament-tutorial-button-wrapper-menu'>
                    <Media className="tournament-header-tutorial-menu" style={{}} src={screenMode === SCREEN_MODE.light ? lightInfoImg : tutorialImage} onClick={() => toggleShowTournamentTutorial()} />
                </div>
                </div>
            </div>
            <TournamentTutorial showTournamentTutorial={showTournamentTutorial} toggleShowTournamentTutorial={() => toggleShowTournamentTutorial()} />
        </>
    );
};

export default TournamentsSubHeader;
