import React from 'react';
import PropTypes from 'prop-types';

const GameJoinFee = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Par pieslēgšanos istabai vai par jaunas istabas izveidi tiek ieturēta noteikta maksa - 20% no likmes.
        </p>
        <p>Piemērs:</p>
        <ul>
          <li>ja istabas likme ir 1:10, tad maksa būs 2 Zoles monētas, savukārt pie likmes 1:50 maksa būs 10 Zoles monētas.</li>
        </ul>
        <p>
          Lai izveidotu jaunu istabu vai lai pievienotos esošai, spēlētāja bilancei ir jābūt vismaz tik lielai, lai nosegtu maksimālo iespējamo zaudējumu vienā partijā, kā arī maksu par istabas izveidi vai pievienošanos. Lielākais iespējamais zaudējums ir spēlēt Zoli un neiegūt nevienu punktu, kas rezultējas kā -16 spēles punkti jeb 160 monētas par maksimālo zaudējumu un 2 monētas par istabas izveidi vai pievienošanos.
        </p>
        <p>Minimālais spēles monētu apjoms, lai uzsāktu spēli:</p>
        <ul>
        <li>istaba ar likmi 1:1 - 17 Zoles monētas</li>
        <li>istaba ar likmi 1:5 - 81 Zoles monēta</li>
        <li>istaba ar likmi 1:10 - 162 Zoles monētas</li>
        <li>istaba ar likmi 1:25 - 405 Zoles monētas</li>
        <li>istaba ar likmi 1:50 - 810 Zoles monētas</li>
        <li>istaba ar likmi 1:100 - 1 620 Zoles monētas</li>
        <li>istaba ar likmi 1:500 - 8 100 Zoles monētas</li>
        <li>istaba ar likmi 1:1000 -  16 200 Zoles monētas</li>
        <li>istaba ar likmi 1:5000 - 81 000 Zoles monētas</li>
        <li>istaba ar likmi 1:10000 - 162 000 Zoles monētas</li>
        </ul>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          За подключение к комнате или за создание новой комнаты взымается определенная плата - 20% от ставки.
        </p>
        <p>Пример:</p>
        <ul>
          <li>если ставка комнаты 1:10, то плата составит 2 монеты Золе, а если ставка 1:50 то плата составит 10 монет Золе.</li>
        </ul>
        <p>
          Чтобы создать новую комнату или присоедениться к уже действующей, баланс игрока должен быть хотя бы таким, чтобы он покрывал максимально возможную потерю в одной партии, а так же плату за создание комнаты или присоединение к ней. Наибольшая возможная потеря это играть Золе и не получить ни одного пункта, что в сумме получается — 16 игровых очками или 160 монет за максимальную потерю и 2 монеты за создание комнаты или присоединение к ней.
        </p>
        <p>Минимальное количество монет для начатия игры:</p>
        <ul>
        <li>комната со ставкой 1:1 - 17 монет Золе</li>
        <li>комната со ставкой 1:5 - 81 монет Золе</li>
        <li>комната со ставкой 1:10 - 162 монет Золе</li>
        <li>комната со ставкой 1:25 - 405 монет Золе</li>
        <li>комната со ставкой 1:50 - 810 монет Золе</li>
        <li>комната со ставкой 1:100 - 1 620 монет Золе</li>
        <li>комната со ставкой 1:500 - 8 100 монет Золе</li>
        <li>комната со ставкой 1:1000 -  16 200 монет Золе</li>
        <li>комната со ставкой 1:5000 - 81 000 монет Золе</li>
        <li>комната со ставкой 1:10000 - 162 000 монет Золе</li>
        </ul>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          A certain fee of 20% from the bet is charged for joining a room or creating a new room.
        </p>
        <p>Example:</p>
        <ul>
          <li>if the room bet is 1:10, the fee will be 2 "Zole" coins, while at the rate of 1:50 the fee will be 10 "Zole" coins.</li>
        </ul>
        <p>
          To create a new room or join an existing one, the player's balance must be at least large enough to cover the maximum possible loss per game, as well as the fee for creating or joining a room. The largest possible loss is to play the "Zole" and not get any points, which results in -16 game points or 160 coins for the maximum loss and 2 coins for creating or joining a room.
        </p>
        <p>The minimum amount of coins to start the game:</p>
        <ul>
        <li>room with bet 1:1 - 17 “Zole” coins</li>
        <li>room with bet 1:5 - 81 “Zole” coins</li>
        <li>room with bet 1:10 - 162 “Zole” coins</li>
        <li>room with bet 1:25 - 405 “Zole” coins</li>
        <li>room with bet 1:50 - 810 “Zole” coins</li>
        <li>room with bet 1:100 - 1 620 “Zole” coins</li>
        <li>room with bet 1:500 - 8 100 “Zole” coins</li>
        <li>room with bet 1:1000 -  16 200 “Zole” coins</li>
        <li>room with bet 1:5000 - 81 000 “Zole” coins</li>
        <li>room with bet 1:10000 - 162 000 “Zole” coins</li>
        </ul>
      </>
    )
  }
};

GameJoinFee.propTypes = {
  lang: PropTypes.string,
};

GameJoinFee.defaultProps = {
  lang: 'lv',
};

export default GameJoinFee;
