import React from 'react';
import PropTypes from 'prop-types';

const ChooseStage = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Partijas sākumā katram spēlētājam tiek iedalītas astoņas kārtis, un atlikušās divas noliktas galda vidū aizklātas (sauktas par “galdu”).
        </p>
        <p>
        Spēlētājam, kurš atrodas pa kreisi no dalītāja, tiek dota pirmā iespēja (pirmā roka) veikt izvēli spēlēt kā lielajam, vai “laist garām”, ja nevēlas spēlēt kā lielais. Ja pirmais palaiž garām, tad izvēle pāriet pie nākošā spēlētāja pa kreisi.
        </p>
        <p>
        Ja visi trīs spēlētāji palaiž garām, tad vai nu tiek ierakstīta kopēja pule, vai arī visi sāk spēlēt “galdiņu”, atkarībā no istabas veida.
        </p>
        <p>
        Ja spēlētājs veicot izvēli ir gatavs spēlēt viens pats pret abiem pārējiem, viņam ir vairākas iespējas - spēlēt “parasto” partiju, “zoli” vai “mazo zoli” (ja mazā zole ir atļauta istabā).
        </p>
        <p>
        Lai spēlētu parasto partiju, lielais “paņem galdu”, tas ir - paņem savā īpašumā galda vidū esošās divas kārtis, un no visām savām 10 kārtīm rokās izvēlas kuras divas aizklāt un atlikt malā. Partiju nesāk izspēlēt, pirms lielais ir atlicis šīs kārtis malā.
        </p>
        <p>
        Lai spēlētu zoli, lielajam tas ir jāpiesaka (dzīvē mutiski, šeit - spiežot pogu “zole”). Kas nozīmē, ka galdā esošās aizklātās kārtis tiek atdotas abiem mazajiem, un lielais nevar tās apmainīt. Šajā gadījumā galda kārtis drīkst apskatīt tikai pēc tam, kad visas kārtis no rokām ir izspēlētas.
        </p>
        <p>
        Lai spēlētu mazo zoli (ja tas ir atļauts), lielajam tas ir jāpiesaka tieši tāpat kā spēlējot parasto zoli.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          В начале партии каждому игроку даются восемь карт, а остальные две кладутся закрытыми на середину стола (именуемые “стол”).
        </p>
        <p>
          Игроку, который находится слева от раздающего (первая рука) первому дается право выбора играть как «большому» или пасовать. В случае паса, слово переходит ко второму игроку слева.
        </p>
        <p>
          Если все три игрока пасуют, то либо пишется общая пуля, либо все начинают игру со столом, в зависимости от вида комнаты.
        </p>
        <p>
          Если игрок выбирает играть один против двоих остальных игроков, у него есть несколько возможностей — играть «обычную» партию, «Золе» или «Малую Золе» (если это разрешено в комнате).
        </p>
        <p>
          Чтобы играть обычную партию, «большой» берет «стол», то есть — берет себе две карты с середины стола, и из всех 10 карт имеющихся на руках выбирает которые две закрыть и отложить. Партию не начинают разыгрывать, если первый «большой» отложил эти карты в сторону.
        </p>
        <p>
          Чтобы играть Золе, «большой» должен об этом заявить (играя вживую устно, здесь — нажав кнопку «Золе»). Это означает, что закрытые карты, находящиеся на столе, отдаются «маленьким», и «большой» не может их обменять. В этом случае, карты на столе можно открыть только тогда, когда разыграны все карты, имеющиеся на руках.
        </p>
        <p>
          Чтобы играть Малую Золе (если это разрешено), «большой» должен об этом заявить так же, как играя обычную Золе.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          In the beginning of the game, the dealer shuffles the deck and passes to each player eight cards, remaining 2 cards are left face down on the table.
        </p>
        <p>
          The player on the left from the dealer gets one chance to declare he will be “Big” (Lielais, lav.). If the first player passes the chance to be “Big”, the opportunity to declare this status goes to the next player on the left.        </p>
        <p>
          If all players pass on the chance to be “Big”, there are two options – either game is continued with “Pules” (Pules, lav.), a certain amount of cash deposit in the game bank, or players can continue playing a game of “Table” (Galdiņš, lav.), the choice depends on the game room type.
        </p>
        <p>
          If the player, after making his choice, is ready to play alone against the other two players, he has several options – to play regular game (parastā partija, lav.), “Zole” (Zole, lav.), or “Small Zole” (Mazā zole, lav.), if the “Small Zole” is allowed in the game room.
        </p>
        <p>
          To play the regular game, “Big” takes the “Table”, i.e., he takes the cards from the middle of the table, and from his 10 cards in his hand, chooses which two cards to set aside. The game does not start until the “Big” has not put these cards aside.
        </p>
        <p>
          To start to play “Zole”, the “Big” has to announce it (here – by clicking the button “Zole”). This means that the cards on the table are returned to both "Small" players, and the "Big" can't exchange them. In this case, the cards on the table can be viewed only after all the cards have been played.
        </p>
        <p>
          To play “Small Zole” (if it is allowed in the game room), the “Big” has to announce it, just like in the regular “Zole” card game.
        </p>
      </>
    )
  }
};

ChooseStage.propTypes = {
  lang: PropTypes.string,
};

ChooseStage.defaultProps = {
  lang: 'lv',
};

export default ChooseStage;
