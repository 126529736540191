import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class TournamentTimer extends React.Component {
  static propTypes = {
    time: PropTypes.number,
    offset: PropTypes.number,
    t: PropTypes.func.isRequired,
    showLetters: PropTypes.bool,
  }

  static defaultProps = {
    time: 0,
    offset: 0,
    showLetters: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      minutes: 0,
      seconds: 0,
    };
  }

  componentDidMount() {
    const { time } = this.props;
    this.intervalID = setInterval(() => {
      const { offset } = this.props;

      if (time) {
        const gameLength = time - (Date.now() + offset);
        const minutes = Math.floor((gameLength % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((gameLength % (1000 * 60)) / 1000);
        if (minutes >= 0 && seconds >= 0) {
          this.setState({
            minutes: minutes < 10 ? `0${minutes}` : minutes,
            seconds: seconds < 10 ? `0${seconds}` : seconds,
          });
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const { time, tm, i18n, showLetters } = this.props;
    const { minutes, seconds } = this.state;

    if (!time) return null;

    if (showLetters) {
      if (i18n.language === 'ru') {
        return (` ${minutes}м:${seconds}с`);
      }
      return (` ${minutes}m:${seconds}s`);
    }
    return (` ${minutes}:${seconds}`);
  }
}

export default (withTranslation(['common', 'notifications'])(TournamentTimer))