import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

class ScrollAreaButtons extends PureComponent {
  static propTypes = {
    scrollRef: PropTypes.shape(),
    rightOffset: PropTypes.number,
    speed: PropTypes.number,
    topOffset: PropTypes.number,
    horizontalScroll: PropTypes.bool,
    scrollPos: PropTypes.number,
    enabled: PropTypes.bool,
  }

  static defaultProps = {
    scrollRef: {},
    rightOffset: 0,
    speed: 30,
    topOffset: 0,
    horizontalScroll: false,
    scrollPos: 0,
    enabled: false,
  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
  //  console.log('componentDidMount');
  }

  componentDidUpdate() {
  //  console.log('componentDidUpdate');
  }

  scrollUp = () => {
    const {
      show, speed, scrollPos, scroll,
    } = this.props;

   // console.log('scrollUp', {
   //   scroll, show, speed, scrollPos,
   // });

    if (show && speed) {
      const curPos = scrollPos;
      const newPos = curPos - speed;

      scroll(newPos);
    }
  }

  scrollDown = () => {
    const {
      show, speed, scrollPos, scroll,
    } = this.props;

   // console.log('scrollDown', {
   //   scroll, show, speed, scrollPos,
   // });

    if (show && speed) {
      const curPos = scrollPos;
      const newPos = curPos + speed;

      scroll(newPos);
    }
  }

  scrollLeft = () => {
    const {
      show, speed, scrollPos, scroll,
    } = this.props;
   // console.log('scrollLeft', {
  //    scroll, show, speed, scrollPos,
  //  });
    if (show && speed) {
      const curPos = scrollPos;
      const newPos = curPos - speed;

      scroll(newPos);
    }
  }

  scrollRight = () => {
    const {
      show, speed, scrollPos, scroll,
    } = this.props;
  
    if (show && speed) {
      const curPos = scrollPos;
      const newPos = curPos + speed;

      scroll(newPos);
    }
  }

  handleUpButtonPress = () => {
  //  this.UpButtonPressTimer = setTimeout(() => this.scrollUp, 500);
    this.UpButtonPressTimer = setInterval(() => {
      this.scrollUp();
    }, 100);
  }

  handleUpButtonRelease = () => {
    clearInterval(this.UpButtonPressTimer);
  }

  handleDownButtonPress = () => {
    this.downButtonPressTimer = setInterval(() => {
      this.scrollDown();
    }, 100);
  }

  handleDownButtonRelease = () => {
    clearInterval(this.downButtonPressTimer);
  }

  handleLeftButtonPress = () => {
    this.leftButtonPressTimer = setInterval(() => {
      this.scrollLeft();
    }, 100);
  }

  handleLeftButtonRelease = () => {
    clearInterval(this.leftButtonPressTimer);
  }

  handleRightButtonPress = () => {
    this.rightButtonPressTimer = setInterval(() => {
      this.scrollRight();
    }, 100);
  }

  handleRightButtonRelease = () => {
    clearInterval(this.rightButtonPressTimer);
  }

  render = () => {
    const {
      rightOffset, show, topOffset, leftOffset, bottomOffset, bgColor, horizontalScroll, enabled,
    } = this.props;

    //  console.log('ScrollAreaButtons render', { show });

    if (!show) {
      return null;
    }

    //  if (!scrollRef || scrollRef.containerHeight >= scrollRef.realHeight) {
    //    return null;
    //  }
    // console.log({horizontalScroll});
    const scrollButtonDisplayStyle = enabled ? 'unset' : 'none';
    return (
      <Fragment>
        {
          horizontalScroll ? (
            <>
              <div
                className="scrollArea-buttonLeft"
                style={{ top: topOffset, left: rightOffset, background: bgColor, display: 'none' }}
                onClick={this.scrollLeft}
                onTouchStart={this.handleLeftButtonPress}
                onTouchEnd={this.handleLeftButtonRelease}
                onMouseDown={this.handleLeftButtonPress}
                onMouseUp={this.handleLeftButtonRelease}
                onMouseLeave={this.handleLeftButtonRelease}
              >
                <div className="scrollArea-buttonLeft-img" />
              </div>
              <div
                className="scrollArea-buttonRight"
                style={{ top: topOffset, right: rightOffset, background: bgColor, display: 'none' }}
                onClick={this.scrollRight}
                onTouchStart={this.handleRightButtonPress}
                onTouchEnd={this.handleRightButtonRelease}
                onMouseDown={this.handleRightButtonPress}
                onMouseUp={this.handleRightButtonRelease}
                onMouseLeave={this.handleRightButtonRelease}
              >
                <div className="scrollArea-buttonRight-img" />
              </div>
            </>
          ) : (
            <>
              <div
                className="scrollArea-buttonUp"
                style={{ right: rightOffset, top: topOffset, background: bgColor, display: scrollButtonDisplayStyle }}
                onClick={this.scrollUp}
                onTouchStart={this.handleUpButtonPress}
                onTouchEnd={this.handleUpButtonRelease}
                onMouseDown={this.handleUpButtonPress}
                onMouseUp={this.handleUpButtonRelease}
                onMouseLeave={this.handleUpButtonRelease}
              >
                <div className="scrollArea-buttonUp-img" />
              </div>
              <div
                className="scrollArea-buttonDown"
                style={{ right: rightOffset, bottom: bottomOffset, background: bgColor, display: scrollButtonDisplayStyle }}
                onClick={this.scrollDown}
                onTouchStart={this.handleDownButtonPress}
                onTouchEnd={this.handleDownButtonRelease}
                onMouseDown={this.handleDownButtonPress}
                onMouseUp={this.handleDownButtonRelease}
                onMouseLeave={this.handleDownButtonRelease}
              >
                <div className="scrollArea-buttonDown-img" />
              </div>
            </>
          )
        }
      </Fragment>
    );
  }
}

export default ScrollAreaButtons;
