import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Button from 'reactstrap/lib/Button';
import Label from 'reactstrap/lib/Label';

import * as constants from '../../../../../constants/constants';
const Tooltip = ({
//  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
  //  locale,
  size,
  t,
}) => {

  const getTitleNumber = (title, result) => {
    if (title) {
      const titleArray = title.split('.');
      if (result && result === constants.GAME_GUIDE_TITLE.no) {
        return titleArray[0] + '.' + titleArray[1];
      } else if (title && result && result === constants.GAME_GUIDE_TITLE.label) {
        return titleArray[2];
      }
    }
    return '';
  };

  return (
    <div className="tooltip-body" {...tooltipProps}>
      {step.title && (
        <h2 className="tooltip-body-title">
          <Label className="tooltip-body-title-no">
            {() => getTitleNumber(step.title, constants.GAME_GUIDE_TITLE.no)}
          </Label>
          {() => getTitleNumber(step.title, constants.GAME_GUIDE_TITLE.label)}
        </h2>
      )}
      <div className="tooltip-body-content">{step.content}</div>
      <div className="tooltip-body-footer">
        <Button className="tooltip-body-skip tooltip-next_section_button" {...skipProps}>
          {t('tutorial.skip')}
        </Button>
        <div className="tooltip-body-right-buttons">
          {index + 1 < size && (
          <Button className="tooltip-body-button tooltip-next_page-button" {...primaryProps}>
            {`${t('tutorial.next')} (${index + 1} / ${size})`}
          </Button>
          )}
          {index + 1 === size && (
          <Button className="tooltip-body-button" {...closeProps}>
            {t('common.close')}
          </Button>
          )}
          {index > 0 && (
          <Button className="tooltip-body-button tooltip-back-button" {...backProps}>
            {t('tutorial.back')}
          </Button>
          )}
        </div>
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  continuous: PropTypes.bool,
  index: PropTypes.number,
  step: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  backProps: PropTypes.shape({}),
  closeProps: PropTypes.shape({}),
  primaryProps: PropTypes.shape({}),
  tooltipProps: PropTypes.shape({}),
  skipProps: PropTypes.shape({}),
  size: PropTypes.number,
  t: PropTypes.shape({}),
};

Tooltip.defaultProps = {
  continuous: false,
  index: null,
  step: {},
  backProps: {},
  closeProps: {},
  primaryProps: {},
  tooltipProps: {},
  skipProps: {},
  size: null,
  t: {},
};

export default withTranslation('common')(Tooltip);
