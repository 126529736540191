import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import NavLink from 'reactstrap/lib/NavLink';
import Media from 'reactstrap/lib/Media';
import * as constants from '../../../../../../constants/constants';

import roomsTabIcon from '../../../../../../images/redesign/mobile/icons/rooms-menu-icon.svg';
import roomsActiveTabIcon from '../../../../../../images/redesign/mobile/icons/rooms-menu-active-icon.svg';
import TopTabIcon from '../../../../../../images/redesign/mobile/icons/top-menu-icon.svg';
import TopActiveTabIcon from '../../../../../../images/redesign/mobile/icons/top-menu-active-icon.svg';
import PowerRankingsTabIcon from '../../../../../../images/redesign/mobile/icons/power-ranking-menu-icon.svg';
import PowerRankingsActiveTabIcon from '../../../../../../images/redesign/mobile/icons/power-ranking-menu-active-icon.svg';
import MyInfoTabIcon from '../../../../../../images/redesign/mobile/icons/my-info-menu-icon.svg';
import MyInfoActiveTabIcon from '../../../../../../images/redesign/mobile/icons/my-info-menu-active-icon.svg';
import TournamentsTabIcon from '../../../../../../images/redesign/mobile/icons/tournaments-menu-icon.svg';
import TournamentsActiveTabIcon from '../../../../../../images/redesign/mobile/icons/tournaments-menu-active-icon.svg';

import roomsTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/rooms-menu-icon.svg';
import TopTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-menu-icon.svg';
import PowerRankingsTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/power-ranking-menu-icon.svg';
import MyInfoTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/my-info-menu-icon.svg';
import TournamentsTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/tournaments-menu-icon.svg';

const LayoutMobileHeader = React.memo(({
    activeTab, t, toggle, achievementsNotification, hasActiveRegistration, showUserSettingsModal, screenMode, joyrideRun
}) => (

    <Row className="layout-mobile-header">
        <Col xs="12" className={`layout-mobile-header-wrapper ${joyrideRun && 'layout-mobile-header-wrapper-joyride'}`}>
            <div xs="3" className="layout-header-link">
                <NavLink
                    className={classNames('layout-header-link-text', {
                        'layout-header-link-text-active': activeTab === constants.MENU_NAVIGATION.rooms
                    })}
                    onClick={() => { toggle(constants.MENU_NAVIGATION.rooms); }}
                >
                    {
                        (screenMode == constants.SCREEN_MODE.light) ? <Media src={roomsTabLightIcon} className='' alt={t('menu.rooms')} /> : <Media src={(activeTab === constants.MENU_NAVIGATION.rooms) ? roomsActiveTabIcon : roomsTabIcon} className='' alt={t('menu.rooms')} />
                    }

                    {t('menu.rooms')}
                </NavLink>
            </div>
            <div xs="3" id="top-tab-menubar-portrait-id" className="layout-header-link top-tab top-tab-menubar top-tab-menubar-portrait">
                <NavLink
                    className={classNames('layout-header-link-text', {
                        'layout-header-link-text-active': activeTab === constants.MENU_NAVIGATION.top || activeTab === constants.MENU_NAVIGATION.topMillionaires
                    })}
                    onClick={() => { toggle(constants.MENU_NAVIGATION.top); }}
                >
                    {
                        (screenMode == constants.SCREEN_MODE.light) ? <Media src={TopTabLightIcon} className='' alt={t('menu.top')} /> : <Media src={(activeTab === constants.MENU_NAVIGATION.top || activeTab === constants.MENU_NAVIGATION.topMillionaires) ? TopActiveTabIcon : TopTabIcon} className='' alt={t('menu.top')} />
                    }
                    {t('menu.top')}
                </NavLink>
            </div>
            <div xs="3" id="powerRankings-tab" className="layout-header-link top-tab power-rankings-tab power-rankings-tab-menubar-portrait">
                <NavLink
                    className={classNames('layout-header-link-text', {
                        'layout-header-link-text-active': constants.POWER_RANKINGS_TABS.includes(activeTab)
                    })}
                    onClick={() => { toggle(constants.MENU_NAVIGATION.powerRankings); }}
                >
                    {
                        (screenMode == constants.SCREEN_MODE.light) ? <Media src={PowerRankingsTabLightIcon} className='' alt={t('menu.powerRankings')} /> : <Media src={(constants.POWER_RANKINGS_TABS.includes(activeTab)) ? PowerRankingsActiveTabIcon : PowerRankingsTabIcon} className='' alt={t('menu.powerRankings')} />
                    }

                    {t('menu.powerRankings')}
                </NavLink>
            </div>
            <div xs="3" className="layout-header-link my-info-tab my-info-tab-menubar-portrait" id="my-info-tab-menubar-portrait-id">
                <NavLink
                    className={classNames('layout-header-link-text', {
                        'layout-header-link-text-active': constants.MY_INFO_TABS.includes(activeTab),
                    })}
                    onClick={() => { toggle(constants.MENU_NAVIGATION.myInfo); }}
                >
                    {
                        (screenMode == constants.SCREEN_MODE.light) ? <Media src={MyInfoTabLightIcon} className='' alt={t('menu.myInfo')} /> : <Media src={(constants.MY_INFO_TABS.includes(activeTab)) ? MyInfoActiveTabIcon : MyInfoTabIcon} className='' alt={t('menu.myInfo')} />
                    }

                    {t('menu.myInfo')}
                </NavLink>
            </div>
            <div xs="3" className={`layout-header-link tournaments-tab tournaments-tab-menubar-portrait ${hasActiveRegistration && 'layout-header-link-notification'}`} id="tournaments-tab-menubar-portrait-id">
                <NavLink
                    className={classNames('layout-header-link-text', {
                        'layout-header-link-text-active': activeTab === constants.MENU_NAVIGATION.tournaments || activeTab === constants.MENU_NAVIGATION.futureTournaments || activeTab === constants.MENU_NAVIGATION.tournamentsHistory,
                    })}
                    onClick={() => { toggle(hasActiveRegistration ? constants.MENU_NAVIGATION.futureTournaments : constants.MENU_NAVIGATION.tournaments); }}
                >
                    {
                        (screenMode == constants.SCREEN_MODE.light) ? <Media src={TournamentsTabLightIcon} className='' alt={t('menu.tournaments')} /> : <Media src={(activeTab === constants.MENU_NAVIGATION.tournaments || activeTab === constants.MENU_NAVIGATION.futureTournaments || activeTab === constants.MENU_NAVIGATION.tournamentsHistory) ? TournamentsActiveTabIcon : TournamentsTabIcon} className='' alt={t('menu.tournaments')} />
                    }
                    
                    {t('menu.tournaments')}
                </NavLink>
            </div>
        </Col>
    </Row>
));

LayoutMobileHeader.propTypes = {
    activeTab: PropTypes.string,
    t: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    showUserSettingsModal: PropTypes.func.isRequired,
    achievementsNotification: PropTypes.bool,
    hasActiveRegistration: PropTypes.bool,
};

LayoutMobileHeader.defaultProps = {
    activeTab: '1',
    achievementsNotification: false,
    hasActiveRegistration: false,
};

export default LayoutMobileHeader;
