import React from 'react';
import PropTypes from 'prop-types';

const GameChat = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
          Istabas skatā ir pieejams istabas čats, kur istabā esošie spēlētāji var rakstīt ziņas. Tāpat arī šeit tiek publicēti sistēmas ziņojumi, piemēram - par galda paņemšanu vai palaišanu garām.
        </p>
        <p>
          Atceries: ir aizliegts rakstīt rupjības vai draudēt citiem spēlētājiem.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          В виде комнаты доступен чат комнаты, где находящиеся в комнате игроки могут писать сообщения. Также здесь публикуются системные сообщения, например — об игре или пропущенном ходе.
        </p>
        <p>
          Помните: запрещено писать грубости или угрожать остальным игрокам.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          Room chat is available in the room view, where players in the room can write messages. System reports are also published here, for example, about picking up or missing a table.
        </p>
        <p>
          NB! It is forbidden to write offensively or threaten other players.
        </p>
      </>
    )
  }
};

GameChat.propTypes = {
  lang: PropTypes.string,
};

GameChat.defaultProps = {
  lang: 'lv',
};

export default GameChat;
