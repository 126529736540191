import React, { Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';

import { map, get } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import MenuRow from '../../../Components/Components/MenuRow';

import ScrollAreaWrapper from '../../../../UI/ScrollAreaWrapper';
import LandScapeLayoutMobileHeader from '../../Layout/Mobile/LandScapeLayoutMobileHeader';
import MyInfoSubHeader from './MyInfoSubHeader';

import * as constants from '../../../../../../constants/constants';

import {
  getWeeklyStatistics,
} from '../../../../../../actions/member';

const WeeklyStatus = ({ changeTab, achievementsNotification, activeTab, showUserSettingsModal, handleClickStart, OpenMobileDropdownModal }) => {

  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const weeklyStatistics = useSelector(state => state.member.weeklyStatistics);
  const screenMode = useSelector(state => state.member.screenMode);
  const fontMode = useSelector(state => state.member.fontMode);

  useEffect(() => {
    dispatch(getWeeklyStatistics());
  }, [screenMode, fontMode]);

  const getTrend = (week1, week2) => {
    const trend = week1 - week2;

    return trend;
  };
console.log("activeTab: ", activeTab)
  return (
    <div className="layout-mobile-body layout-mobile-body-myinfo-page">
      <Helmet>
        <title>
          Zole - {t('common:myInfo.weeklyStatus')}
        </title>
      </Helmet>

      <div className="layout-mobile-body-top">
        {/* <Row className="layout-mobile-subheader d-none">
          <MyInfoSubHeader changeTab={changeTab} activeTab={activeTab} />
        </Row> */}
      </div>
      <div className="layout-mobile-body-main">
        <div className="layout-mobile-body-main-title d-none">{t('common:myInfo.weeklyStatus')}</div>
        <Row className="layout-body-main-table-header weekly-status-layout-body-main-table-header weekly-status-layout-body-main-table-header-mobile">
          <Col xs={4} sm="4" className='text-center no-padding mt-3' />
          <Col xs={2} sm="2" className='text-center no-padding mt-3'>
            {t('myInfo.lastWeek')}
          </Col>
          <Col xs={2} sm="2" className='text-center no-padding mt-3'>
            {t('myInfo.twoWeeksAgo')}
          </Col>
          <Col xs={2} sm="2" className='text-center pl-0 no-padding mt-3'>
            {t('myInfo.trend')}
          </Col>
          <Col xs={2} sm="2" className='text-center pl-0 no-padding mt-3'>
            {t('myInfo.average')}
          </Col>
        </Row>

        <div
          className="layout-body-main-scrollarea layout-mobile-body-main-scrollarea weekly-status-scrollbar"
          contentClassName="layout-body-main-scrollarea-body"
          show
          rightOffset={0}
          topOffset={30}
          bottomOffset={0}
        >
          {weeklyStatistics && (
            <Fragment>
              {constants.WEEKLY_STATISTICS_DATA && map(constants.WEEKLY_STATISTICS_DATA, (item, key) => (
                <MenuRow key={key}>
                  <Row key={key} className='align-items-center'>
                    <Col xs={4} sm="4">
                      {t(`myInfo.${key}`)}
                    </Col>
                    <Col xs={2} sm="2" className='text-center'>
                      {get(weeklyStatistics, `oneWeekAgo.${key}`, 0)}
                    </Col>
                    <Col xs={2} sm="2" className='text-center'>
                      {get(weeklyStatistics, `twoWeeksAgo.${key}`, 0)}
                    </Col>
                    <Col xs={2} sm="2" className='text-center'>
                      <div className={
                        classNames({
                          'menu-table-row-weekly-large-trend': get(weeklyStatistics, `oneWeekAgo.${key}`, 0) - get(weeklyStatistics, `twoWeeksAgo.${key}`, 0) > 0,
                          'menu-table-row-weekly-low-trend': get(weeklyStatistics, `oneWeekAgo.${key}`, 0) - get(weeklyStatistics, `twoWeeksAgo.${key}`, 0) < 0,
                          'menu-table-row-weekly-neutral-trend': get(weeklyStatistics, `oneWeekAgo.${key}`, 0) - get(weeklyStatistics, `twoWeeksAgo.${key}`, 0) === 0,
                        })
                      }>
                        {getTrend(get(weeklyStatistics, `oneWeekAgo.${key}`, 0), get(weeklyStatistics, `twoWeeksAgo.${key}`, 0))}
                      </div>
                    </Col>
                    <Col xs={2} sm="2" className='text-center'>
                      {(get(weeklyStatistics, `oneWeekAgo.${key}`, 0) + get(weeklyStatistics, `twoWeeksAgo.${key}`, 0)) / 2}
                    </Col>
                  </Row>
                </MenuRow>
              ))}
            </Fragment>
          )}
        </div>
        {/* <div className="menu-table-row-filler-out" /> */}

      </div>
    </div>
  );
}

WeeklyStatus.propTypes = {
  achievementsNotification: PropTypes.bool,
  userSettings: PropTypes.shape({
    soundOn: PropTypes.bool,
  }),
  uid: PropTypes.string,
  changeTab: PropTypes.func.isRequired,
  showUserSettingsModal: PropTypes.func.isRequired,
  handleClickStart: PropTypes.func.isRequired,
  OpenMobileDropdownModal: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
};

WeeklyStatus.defaultProps = {
  userSettings: {},
  achievementsNotification: false,
  uid: null,
  activeTab: constants.MENU_NAVIGATION.weeklyStatus,
};

export default WeeklyStatus;
