import React from 'react';
import PropTypes from 'prop-types';
// import { Row, Col } from 'reactstrap';

// import Row from 'reactstrap/lib/Row';
// import Col from 'reactstrap/lib/Col';

// import { Link } from 'react-router-dom';
import moment from 'moment';
import Moment from 'react-moment';

const DateFormat = ({ date, formatType, lang }) => (
  <Moment format={formatType} locale={lang}>
    {date || ''}
  </Moment>
);

DateFormat.propTypes = {
  date: PropTypes.string,
  formatType: PropTypes.string,
  lang: PropTypes.string,
};

DateFormat.defaultProps = {
  date: '',
  formatType: '',
  lang: 'lv',
};

export default DateFormat;
