import React from 'react';
import PropTypes from 'prop-types';

import Linkify from 'react-linkify';

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

const LinkifyWithTargetBlank = React.memo(({ children }) => {

  return (
    <Linkify componentDecorator={componentDecorator}>
      {children}
    </Linkify>
  );
})

LinkifyWithTargetBlank.propTypes = {
  date: PropTypes.number,
  format: PropTypes.string,
};

LinkifyWithTargetBlank.defaultProps = {
  date: '',
  format: '',
};

export default LinkifyWithTargetBlank;
