import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import moment from 'moment';
import Moment from 'react-moment';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

import DateFormat from '../UI/DateFormat';
import * as constants from '../../../constants/constants';

import closeImg from '../../../images/icons/close.png';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

moment.locale('lv');
momentLocalizer();

class AllGiftCodes extends React.Component {
  static propTypes = {
    allGiftCodes: PropTypes.shape([]),
    addGiftCode: PropTypes.func.isRequired,
    editGiftCode: PropTypes.func.isRequired,
    deleteGiftCode: PropTypes.func.isRequired,
    claimedPlayers: PropTypes.shape({}),
    fetchClaimedPlayers: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    allGiftCodes: {},
    claimedPlayers: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 50,
      giftCodeToEdit: '',
      openModal: false,
      deleteModal: false,
      deleteModalSelectedGiftCode: '',
      claimedPlayerModal: false,
      claimedModalId: '',
      code: '',
      expires: new Date(),
      playerLimit: '',
      value: '',
    };

    this.handleClick = this.handleClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.editGiftCode = this.editGiftCode.bind(this);
    this.addGiftCode = this.addGiftCode.bind(this);
  }

  handleChange = (event) => {
    const { playerLimit, value } = this.state;
    const newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    console.log('newValue', newValue, parseInt(newValue, 10), parseInt(newValue, 10) >= 0);
    console.log('event.target.name', value, playerLimit, event.target.name);

    if (event.target.name === 'playerLimit') {
      this.setState({
        [event.target.name]: parseInt(newValue, 10) >= 0 ? parseInt(newValue, 10) : playerLimit,
      });
    } else if (event.target.name === 'value') {
      this.setState({
        [event.target.name]: parseInt(newValue, 10) >= 0 ? parseInt(newValue, 10) : value,
      });
    } else {
      this.setState({
        [event.target.name]: newValue,
      });
    }
  }

  ChangeExpires = (date) => {
    this.setState({ expires: date });
  }

  openModal = (id) => {
    const { allGiftCodes } = this.props;
    const giftCode = allGiftCodes[id];
    this.setState({
      giftCodeToEdit: id,
      code: giftCode.code || '',
      expires: new Date(giftCode.expires) || null,
      playerLimit: giftCode.playerLimit || '',
      value: giftCode.value || '',
      openModal: true,
      openAddModal: false,
      deleteModal: false,
      claimedPlayerModal: false,
    });
  }

  openAddModal = () => {
    this.setState({
      code: '',
      expires: new Date(),
      playerLimit: '',
      value: '',
      openModal: false,
      openAddModal: true,
      deleteModal: false,
      claimedPlayerModal: false,
    });
  }

  claimedPlayerModal = (code) => {
    const { fetchClaimedPlayers } = this.props;

    fetchClaimedPlayers(code);
    this.setState({
      openModal: false,
      openAddModal: false,
      claimedPlayerModal: true,
      claimedModalId: code,
    });
  }

  toggleClaimedPlayer = () => {
    this.setState(prevState => ({
      claimedPlayerModal: !prevState.claimedPlayerModal,
      claimedModalId: '',
    }));
  }

  toggleDeleteModal = (code) => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
      deleteModalSelectedGiftCode: code,
    }));
  }

  deleteGiftCode = () => {
    const { deleteGiftCode } = this.props;
    const { deleteModalSelectedGiftCode } = this.state;

    if (deleteModalSelectedGiftCode) {
      deleteGiftCode(deleteModalSelectedGiftCode);

      this.setState({
        deleteModal: false,
        deleteModalSelectedGiftCode: '',
      });
    }
  }

  editGiftCode = () => {
    const { allGiftCodes, editGiftCode } = this.props;
    const {
      giftCodeToEdit, code, expires, playerLimit, value,
    } = this.state;

    const giftCode = allGiftCodes[giftCodeToEdit];

    editGiftCode({
      data: {
        code, expires, playerLimit, value,
      },
      oldData: giftCode,
    }).then(() => {
      this.setState({ openAddModal: false, openModal: false });
    }).catch((err) => {
      console.log(err);
    });
  }


  toggleAdd = () => {
    this.setState(prevState => ({
      openAddModal: !prevState.openAddModal,
    }));
  }

  toggle = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }


  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  table = () => {
    const {
      t,
      allGiftCodes,
    } = this.props;
    const {
      currentPage,
      pageSize,
    } = this.state;
    const pagesCount = Math.ceil(Object.keys(allGiftCodes).length / pageSize);

    return (
      <Fragment>
        {allGiftCodes && Object.keys(allGiftCodes)
          .reverse()
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((key, index) => (
            <Fragment key={key}>
              <tr key={key} className={`allGiftCodes-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allGiftCodes-table-col">
                  {allGiftCodes[key].finishedDate ? (
                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={allGiftCodes[key].finishedDate} />
                  ) : (
                    allGiftCodes[key].code || ''
                  )}
                </td>
                <td className="allGiftCodes-table-col">
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={allGiftCodes[key].expires} />
                </td>
                <td className="allGiftCodes-table-col">
                  {allGiftCodes[key].claimedCount || 0} / {allGiftCodes[key].playerLimit || 0}
                </td>
                <td className="allGiftCodes-table-col">
                  {allGiftCodes[key].value || 0}
                </td>
                <td className="allGiftCodes-table-col" style={{ width: '10%' }}>
                  <Button className="allGiftCodes-table-col-button" color="primary" onClick={() => this.claimedPlayerModal(allGiftCodes[key].code)}>
                    {/* {t('claimedPlayer')} */}
                    Claimed Player
                  </Button>
                </td>
                <td className="allGiftCodes-table-col" style={{ width: '10%' }}>
                  <Button className="allGiftCodes-table-col-button" color="primary" onClick={() => this.openModal(key)}>
                    {/* {t('edit')} */}
                    Edit
                  </Button>
                </td>
                <td className="allGiftCodes-table-col" style={{ width: '10%' }}>
                  <Button className="allGiftCodes-table-col-button" color="danger" onClick={() => this.toggleDeleteModal(allGiftCodes[key].code)}>
                    {/* {t('delete')} */}
                    Delete
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        {Object.keys(allGiftCodes).length > pageSize && (
        <div className="pagination-wrapper">
          <Pagination aria-label="Page navigation example">
            <PaginationItem disabled={currentPage === 0}>
              <PaginationLink onClick={e => this.handleClick(e, 0)} href="#">
                {`‹‹‹`}
              </PaginationLink>
            </PaginationItem>

            <PaginationItem disabled={currentPage <= 0}>
              <PaginationLink
                onClick={e => this.handleClick(e, currentPage - 1)}
                previous
                href="#"
              />
            </PaginationItem>

            {[...Array(pagesCount)].map((page, i) => {
              if (i > currentPage - 3 && i < currentPage + 3) {
                return (
                  <PaginationItem active={i === currentPage} key={page}>
                    <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              }

              return null;
            })}

            <PaginationItem disabled={currentPage >= pagesCount - 1}>
              <PaginationLink
                onClick={e => this.handleClick(e, currentPage + 1)}
                next
                href="#"
              />
            </PaginationItem>

            <PaginationItem disabled={currentPage >= pagesCount - 1}>
              <PaginationLink onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                {`›››`}
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
        )}
      </Fragment>
    );
  }

  addGiftCode() {
    const { addGiftCode } = this.props;
    const {
      code, expires, playerLimit, value,
    } = this.state;

    addGiftCode({
      code, expires, playerLimit, value,
    }).then(() => {
      this.setState({ openAddModal: false });
    });
  }

  render() {
    const {
      t, claimedPlayers,
    } = this.props;

    const {
      code,
      expires,
      playerLimit,
      value,
      deleteModal,
      openModal,
      openAddModal,
      claimedPlayerModal,
      claimedModalId,
    } = this.state;

    console.log('playerLimit', playerLimit);

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-all-gift-codes">
          <PopoverBody className="popover-body">
            The administration can create a gift code that can be later used by the players to redeem the game coins. Can be used as a reward in contests or to promote the game in social platforms.
          </PopoverBody>
        </UncontrolledPopover>
        <div style={{ marginTop: 100, color: '#fff' }}>
          <Row>
            <Col md="6">
              <h2>
                {/* {t('gitcode')} */}
                Gift Code
                <Button className="admin-help-button" id="admin-help-button-all-gift-codes">
                  ?
                </Button>
              </h2>
            </Col>
            <Col md="6">
              <Button onClick={() => this.openAddModal()}>
                {/* {t('addGiftCode')} */}
                Add a Gift Code
              </Button>
            </Col>
          </Row>
          <table className="textAlign" style={{ width: '100%', fontSize: 12, color: '#fff' }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  {/* {t('code')} */}
                  Code
                </th>
                <th>
                  {/* {t('expireTime')} */}
                  Expire Time
                </th>
                <th>
                  {/* {t('claimedPlayer')} / {t('totalPlayer')} */}
                  Claimed Player / Total Player
                </th>
                <th>
                  {/* {t('value')} */}
                  Value
                </th>
                <th style={{ width: '10%' }}>
                  {/* {t('claimedPlayer')} */}
                  Claimed Player
                </th>
                <th style={{ width: '10%' }}>
                  {/* {t('edit')} */}
                  Edit
                </th>
                <th style={{ width: '10%' }}>
                  {/* {t('delete')} */}
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>
        </div>

        {/* Delete Gift Code modal */}
        <Modal container={'div > div'} isOpen={deleteModal} toggle={() => this.toggleDeleteModal('')} className="notification">
          <ModalHeader
            toggle={() => this.toggleDeleteModal('')}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.toggleDeleteModal('')} />
            }
          />
          <ModalBody className="notification-body">
            Do you really want to delete this gift code?
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="btn notification-footer-button" onClick={this.deleteGiftCode}>
              Yes
            </Button>
            <Button type="button" className="btn notification-footer-button" onClick={() => this.toggleDeleteModal('')}>
              No
            </Button>
          </ModalFooter>
        </Modal>

        {/* Edit Gift Code modal */}
        <Modal container={'div > div'} className="admin-modal" isOpen={openModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Edit
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="code">
                  Code
                </Label>
                <Input
                  type="text"
                  name="code"
                  id="code"
                  value={code}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="expires">
                  Expire Time
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                    //  time={false}
                      format={constants.FORMAT_DATE_TYPE.pointDate}
                      culture={constants.sendLanguage.lv}
                      onChange={this.ChangeExpires}
                      value={expires}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="playerLimit">
                  Amount of uses
                </Label>
                <Input
                  type="number"
                  name="playerLimit"
                  id="playerLimit"
                  value={playerLimit}
                  onChange={this.handleChange}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <FormGroup>
                <Label for="value">
                  Value
                </Label>
                <Input
                  type="number"
                  name="value"
                  id="value"
                  value={value}
                  onChange={this.handleChange}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.editGiftCode}>Save</Button>
            {' '}
            <Button color="secondary" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>

        {/* Add Gift Code modal */}
        <Modal container={'div > div'} className="admin-modal" isOpen={openAddModal} toggle={this.toggleAdd}>
          <ModalHeader toggle={this.toggleAdd}>
            Add a Gift Code
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="code">
                  Code
                </Label>
                <Input
                  type="text"
                  name="code"
                  id="code"
                  value={code}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="expires">
                  Expire Time
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                  //  time={false}
                      format={constants.FORMAT_DATE_TYPE.pointDate}
                      culture={constants.sendLanguage.lv}
                      onChange={this.ChangeExpires}
                      value={expires}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="playerLimit">
                  Amount of uses
                </Label>
                <Input
                  type="number"
                  name="playerLimit"
                  id="playerLimit"
                  value={playerLimit}
                  onChange={this.handleChange}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <FormGroup>
                <Label for="value">
                  {/* {t('codeValue')} */}
                  The amount of game coins which will be added upon activating it
                </Label>
                <Input
                  type="number"
                  name="value"
                  id="value"
                  value={value}
                  onChange={this.handleChange}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.addGiftCode}>Add</Button>
            {' '}
            <Button color="secondary" onClick={this.toggleAdd}>Close</Button>
          </ModalFooter>
        </Modal>


        <Modal container={'div > div'} size="lg" isOpen={claimedPlayerModal} toggle={this.toggleClaimedPlayer}>
          <ModalHeader toggle={this.toggleClaimedPlayer}>
            {/* {t('claimedPlayer')} */}
            Claimed Player
          </ModalHeader>
          <ModalBody>
            {claimedPlayers && (
              <table className="textAlign" style={{ width: '100%', fontSize: 15 }}>
                <colgroup>
                  <col span="1" />
                </colgroup>
                <thead className="giftCode-table-header">
                  <tr>
                    <th>
                      {/* {t('code')} */}
                      Code
                    </th>
                    <th>
                      {/* {t('name')} */}
                      Name
                    </th>
                  </tr>
                </thead>
                <tbody className="giftCode-table-body">
                  {Object.keys(claimedPlayers).map((key, index) => (
                    <tr key={key} className={`giftCode-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                      <td className="giftCode-table-col">
                        {claimedModalId}
                      </td>
                      <td className="giftCode-table-col">
                        {claimedPlayers[key].name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleClaimedPlayer}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default withTranslation('admin')(AllGiftCodes);
