import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Row, Col, Progress } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { filter } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
// import Label from 'reactstrap/lib/Label';
import Button from 'reactstrap/lib/Button';
import Progress from 'reactstrap/lib/Progress';

import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import * as constants from '../../../constants/constants';

// import lvFlag from '../../../images/flags/lv.png';
// import ruFlag from '../../../images/flags/ru.png';
// import enFlag from '../../../images/flags/en.png';
import { clickCampaign } from '../../../actions/member';
import { getCampaignLoadingBanner, getCampaignLobbyBanner } from '../../../actions/shop';

const Loading = ({ fontMode, loading, loadingProgress, campaignData, devMode, clickACampaign, fetchCampaign, adsHidden, loggedInUserDataFetched, dontSaveStatistics }) => {
  const { i18n, t } = useTranslation('common');

  useEffect(() => {
    if (!dontSaveStatistics) {
      fetchCampaign('loading', 'old', devMode);
    }

    console.log('fetchCampaign loggedInUserDataFetched', loggedInUserDataFetched, adsHidden);

    // if (loggedInUserDataFetched && (!adsHidden || adsHidden < Date.now())) {
      if (campaignData && campaignData[0] && campaignData[0]?.externalBanner) {
        const scriptTag = document.createElement("script");
        scriptTag.src = campaignData[0]?.externalBanner;
        scriptTag.async = true;

        console.log('campaignData[0]?.externalBanner', campaignData[0]?.externalBanner);

        /*
        scriptTag.addEventListener('load', () => {
          console.log('banner loaded');
          setLoaded(true);
        }); */

        scriptTag.addEventListener('load', () => {
          const promise1 = new Promise((r) => setTimeout(r, 1000));
          console.log('scriptTag load inputs 1');

          try {
            Promise.all([promise1]).then(() => {
              const inputs = document.getElementsByTagName('iframe');
              let bannerIframe = null;

              console.log('scriptTag load inputs 2', inputs);

              console.log('inputs.length', inputs.length);
              if (inputs) {
                for (var i = 0; i < inputs.length; i++) {
                  console.log('input', inputs[i].clientHeight, inputs[i].clientWidth, inputs[i].offsetHeight);

                  if (inputs[i].clientHeight === 180 && inputs[i].clientWidth === 980) {
                    bannerIframe = inputs[i];
                  }
                }
              }
              console.log('scriptTag load bannerIframe', bannerIframe);

              if (bannerIframe && bannerIframe.contentDocument) {
                console.log('bannerIframe.contentDocument', bannerIframe.contentDocument);

                bannerIframe.contentDocument.body.addEventListener('mousedown', handleClickCampaignExternal2);
              } else if (bannerIframe && bannerIframe.document) {
                console.log('bannerIframe.document', bannerIframe.document);

                bannerIframe.document.addEventListener("click", handleClickCampaignExternal2);
              }
            });
          } catch (err) {
            console.log(err);
          }

          setLoaded(true);
        });

        const bannerRef3 = document.getElementById('bannerRef');

        console.log('bannerRef3', bannerRef3);

        if (bannerRef3 && campaignData[0]?.externalBanner) {
          bannerRef3.appendChild(scriptTag);
          setExternalBannerSet(true);
        }
      }
    // }
  }, [])

  const helpLoadingEN = {
    helpLoadingTab: "en",
    helpLoadingHeader: "game does not load?",
    helpLoadingTitle: "What to do if the game does not load?",
    helpLoadingDescription1: "Reload the page by pressing Ctrl + F5",
    helpLoadingDescription2: "Open the site in a private / incognito mode",
    helpLoadingDescription3: "Clear the cache / cookies",
    helpLoadingDescription4: "Try from another browser (Chrome, Firefox, Edge, Safari, Opera)"
  };

  const helpLoadingLV = {
    helpLoadingTab: "lv",
    helpLoadingHeader: "spēle neielādējas?",
    helpLoadingTitle: "Ko darīt, ja spēle neielādējas?",
    helpLoadingDescription1: "Pārlādēt lapu vairākas reizes, nospiežot Ctrl + F5.",
    helpLoadingDescription2: "Atvērt lapu privātā / incognito režīmā",
    helpLoadingDescription3: "Iztīrīt kešatmiņu / cookies",
    helpLoadingDescription4: "Pamēģināt no citas pārlūkprogrammas (Chrome, Firefox, Edge, Safari, Opera)"
  };

  const helpLoadingRU = {
    helpLoadingTab: "ru",
    helpLoadingHeader: "игра не загружается?",
    helpLoadingTitle: "Что делать, если игра не загружается?",
    helpLoadingDescription1: "Перезагрузите страницу, нажав Ctrl + F5",
    helpLoadingDescription2: "Открыть сайт в приватном режиме/режиме инкогнито",
    helpLoadingDescription3: "Очистить кеш/куки",
    helpLoadingDescription4: "Попробуйте из другого браузера (Chrome, Firefox, Edge, Safari, Opera)"
  };

  const [toggleHelp, setToggleHelp] = React.useState(helpLoadingLV.helpLoadingTab);

  const [externalBannerSet, setExternalBannerSet] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    setToggleHelp(i18n.language || helpLoadingLV.helpLoadingTab);
  }, []);
  
  const helpLoadingToggle = (item) => {
    if (toggleHelp !== item) {
      setToggleHelp(item);
    }
  };

  const handleClickCampaign = (targetLink, campaignKey) => {
    window.open(targetLink, '_blank')
    clickACampaign(campaignKey, 'loading', 'old', devMode);
  }

  const handleClickCampaignExternal2 = () => {
    console.log('handleClickCampaignExternal2', devMode, campaignData);

    if (campaignData[0] && campaignData[0]?.key) {
      clickACampaign(campaignData[0]?.key, 'loading', 'old', devMode);
    }
  }

  let campaignShow = false;

  // if (loggedInUserDataFetched && (!adsHidden || adsHidden < Date.now())) {
    if (campaignData) {
      if (devMode && filter(campaignData.selectedEnvs, item => item.value === 'dev')[0]) {
        campaignShow = true;
      } else if (!devMode && filter(campaignData.selectedEnvs, item => item.value === 'prod')[0]) {
        campaignShow = true;
      }
      campaignShow = filter(campaignData[0]?.selectedDesigns, item => item?.value === 'old')[0] ? true : false;
    }
  // }

  console.log('campaignLoadingData', campaignData, campaignShow);

  return (
    <Fragment>
      <Helmet>
        <title>
          {t('home.zoleLoading')}
        </title>
      </Helmet>

      <Row className="mt-2" style={{ minHeight: 180, marginLeft: 'auto', marginRight: 'auto', display: 'flex', justifyContent: 'center' }} >
        <div
          width="100%"
          style={{
            width: '100%',
            cursor: 'pointer',
            position: 'relative',
            height: 'auto',
            display: 'inline',
          }}>
          <a onClick={() => handleClickCampaign(campaignData[0]?.pageToOpen, campaignData[0]?.adFormMeasure, campaignData[0]?.key)} width="100%" style={{ height: 180, display: 'block', width: '100%', cursor: 'pointer' }} >
            <div id="bannerRef" style={{ height: 180, overflow: 'hidden' }} />
          </a>
        </div>

        {/* <a
          onClick={() => handleClickCampaign(campaignData[0]?.pageToOpen, campaignData[0]?.key)}
          style={{ display: 'flex', justifyContent: 'center' }}
          width="980px"
          height="180px"
        >
          <div id="bannerRef" />
        </a> */}
      </Row>
      {
        (campaignShow && campaignData && campaignData[0] && !campaignData[0]?.externalBanner) && (
          <Row className="mt-2" style={{ marginLeft: '30px', marginRight: '0px', display: 'flex', justifyContent: 'center' }} >
            <a style={{ display: 'flex', justifyContent: 'center' }} onClick={() => handleClickCampaign(campaignData[0]?.pageToOpen, campaignData[0]?.key)} width="980px" height="180px" >
              <Media style={{ objectFit: 'contain' }} src={i18n.language === 'lv' ? campaignData[0]?.imageUrl : i18n.language === 'en' ? campaignData[0]?.imageUrlEn : i18n.language === 'ru' ? campaignData[0]?.imageUrlRu : campaignData[0]?.imageUrl} width="980px" height="180px" alt="" />
            </a>
          </Row>
        )
      }
      <div className="logo-wrapper loading">
        <div className="logo loading" />
        <div className="logo-domain">
          {constants.DOMAIN_NAME}
        </div>
      </div>
      <div className="loadingScreen">
        <Row>
          <Col md={{ size: 4, offset: 4 }}>
            <div>
              <Progress color="success" value={loadingProgress} />
            </div>
          </Col>
        </Row>
        <Row className="loadingText">
          <Col md={{ size: 4, offset: 4 }}>
            {t('home.zoleLoading')}
          </Col>
        </Row>
      </div>
      <div className="old-help-loading-section">
        <div className="old-help-loading-section-header mt-2">
          <Button className={classNames('old-help-loading-section-header-menu', { 'old-help-loading-section-header-menu-active': toggleHelp === helpLoadingLV.helpLoadingTab })} onClick={() => helpLoadingToggle(helpLoadingLV.helpLoadingTab)}>{helpLoadingLV.helpLoadingHeader}</Button>
          <Button className={classNames('old-help-loading-section-header-menu', { 'old-help-loading-section-header-menu-active': toggleHelp === helpLoadingEN.helpLoadingTab })} onClick={() => helpLoadingToggle(helpLoadingEN.helpLoadingTab)}>{helpLoadingEN.helpLoadingHeader}</Button>
          <Button className={classNames('old-help-loading-section-header-menu', { 'old-help-loading-section-header-menu-active': toggleHelp === helpLoadingRU.helpLoadingTab })} onClick={() => helpLoadingToggle(helpLoadingRU.helpLoadingTab)}>{helpLoadingRU.helpLoadingHeader}</Button>
        </div>
        <div className="old-help-loading-section-body mt-2">
          <ul className={classNames('old-help-loading-section-body-description', { 'old-help-loading-section-body-description-active': toggleHelp === helpLoadingLV.helpLoadingTab })}>
            <li>{helpLoadingLV.helpLoadingTitle}</li>
            <li>{helpLoadingLV.helpLoadingDescription1}</li>
            <li>{helpLoadingLV.helpLoadingDescription2}</li>
            <li>{helpLoadingLV.helpLoadingDescription3}</li>
            <li>{helpLoadingLV.helpLoadingDescription4}</li>
          </ul>
          <ul className={classNames('old-help-loading-section-body-description', { 'old-help-loading-section-body-description-active': toggleHelp === helpLoadingEN.helpLoadingTab })}>
            <li>{helpLoadingEN.helpLoadingTitle}</li>
            <li>{helpLoadingEN.helpLoadingDescription1}</li>
            <li>{helpLoadingEN.helpLoadingDescription2}</li>
            <li>{helpLoadingEN.helpLoadingDescription3}</li>
            <li>{helpLoadingEN.helpLoadingDescription4}</li>
          </ul>
          <ul className={classNames('old-help-loading-section-body-description', { 'old-help-loading-section-body-description-active': toggleHelp === helpLoadingRU.helpLoadingTab })}>
            <li>{helpLoadingRU.helpLoadingTitle}</li>
            <li>{helpLoadingRU.helpLoadingDescription1}</li>
            <li>{helpLoadingRU.helpLoadingDescription2}</li>
            <li>{helpLoadingRU.helpLoadingDescription3}</li>
            <li>{helpLoadingRU.helpLoadingDescription4}</li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

Loading.propTypes = {
  clickACampaign: PropTypes.func.isRequired,
  campaignData: PropTypes.shape([]),
};

Loading.defaultProps = {
  campaignData: [],
};

const mapStateToProps = state => ({
  campaignData: state.shop.campaignLoadingData || [],
  adsHidden: state.member.adsHidden || null,
  loggedInUserDataFetched: state.member.loggedInUserDataFetched || null,
});

const mapDispatchToProps = {
  fetchCampaign: getCampaignLoadingBanner,
  clickACampaign: clickCampaign,
};

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
