import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

import { removeAutomated } from '../../../actions/game';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const AutomatedOverlay = React.memo(({
  roomId, resetAutomated, automated, t, fontMode,
}) => (
  <Modal container={'div > div'} isOpen={true} /*toggle={() => resetAutomated(roomId)}*/ size="lg" className={`old-modal root-font-${fontMode}`} style={{maxWidth: 600}}>
    <ModalHeader
      // toggle={() => resetAutomated(roomId)}
      // close={
      //   <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => resetAutomated(roomId)} />
      // }
    >
      {t('settings:autoPlay')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
      }
    </ModalHeader>
    <ModalBody className="text-center">
      {t('automatedMessage')}
    </ModalBody>
    <ModalFooter>
      <div className="automation">
        <Button color="danger" className="automation-button" onClick={() => resetAutomated(roomId)}>
          {t('return')}
        </Button>
      </div>
    </ModalFooter>
  </Modal>
));

AutomatedOverlay.propTypes = {
  fastGame: PropTypes.bool,
  resetAutomated: PropTypes.func.isRequired,
};

AutomatedOverlay.defaultProps = {
  fastGame: null,
};

const mapStateToProps = (state, ownProps) => ({
  fastGame: state.game.globalParams.fastGame,
});

const mapDispatchToProps = {
  resetAutomated: removeAutomated,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedOverlay);
