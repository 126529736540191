import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { map, size, filter } from 'lodash';

import ScrollArea from 'react-scrollbar';
import CustomDropdown from '../Components/Components/CustomDropdown';
import DropdownItem from 'reactstrap/lib/DropdownItem';

import { Col, Row, Button, Label, NavLink } from 'reactstrap';

import TextEditor from '../../TextEditor/TextEditor';
import DateFormat from '../../UI/DateFormat';
// import closeImg from '../../../../images/icons/close.png';
import * as constants from '../../../../constants/constants';

import {
  getAllVersions,
} from '../../../../actions/member';

const VersionHistory = () => {
  const { i18n, t } = useTranslation('common');
  const dispatch = useDispatch();

  const allVersions = useSelector(state => state.member.allVersions);

  const [activeTab, setActiveTab] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);
  const [typeFilterOpen, setTypeFilterOpen] = useState(false);

  const [allVersionsContent, setAllVersionsContent] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!allVersions) {
      dispatch(getAllVersions());
    }
  }, [allVersions]);

  if (!allVersionsContent && allVersions) {
    setAllVersionsContent(allVersions);
  }

  const toggleActiveTab = (key) => {
    if (activeTab !== key) {
      setActiveTab(key);
    }
  };

  const toggleTypeFilter = () => {
    setFilterOpen(false);
    setTypeFilterOpen(prevTypeFilterOpen => !prevTypeFilterOpen);
  };

  return (
    <Row className="zole-rules-page">
      <Col md="12">
        <h1 className="zole-rules-page-title">{t('home.gameUpdates')}</h1>
      </Col>
      <Col sm="12" className="zole-rules-page-mobile-header">
        <div className="zole-rules-page-header-tab">
          <CustomDropdown
            toggle={
              allVersionsContent && (
                <DateFormat formatType={constants.FORMAT_DATE_TYPE.pointsfullDate} date={filter(allVersionsContent, (item, idx) => { return idx === activeTab})[0].versionDate} />
              )
            }
            toggleFunc={toggleTypeFilter}
            isOpen={typeFilterOpen}
            menu={(
              <>
                {allVersionsContent && map(allVersionsContent, (item, idx) => (
                  <DropdownItem key={item.key} onClick={() => toggleActiveTab(idx)}>
                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.pointsfullDate} date={item.versionDate}/>
                  </DropdownItem>
                ))}
              </>
              )}
            inlineMenuClassName="landing-version-history-dropdown-list"
          />
        </div>
      </Col>
      <Col xs="4" className="zole-rules-page-header">
        <Row className="zole-rules-page-header-tab">
          <div
            className="contact-support-chat-body-scroll-area"
          >
            <div className="latest-version-section">
              <div className="latest-version-section-body">
                {
                  allVersionsContent && size(allVersionsContent) > 0 && Object.keys(allVersionsContent)
                    .map((key, index) => (
                      // <TextEditor viewEditorState={latestVersion[key][localStorage.getItem('language')]} readOnly />
                      <Col sm={{ size: 10, offset: 1 }} className="zole-rules-page-tab p-0">
                        <div className={classNames('zole-rules-page-tab-wrapper',
                          {
                            'active': activeTab === index,
                          })}
                        >
                          <NavLink
                            className={classNames('zole-rules-page-header-link', { 'zole-rules-page-header-link-active': activeTab === index })}
                            onClick={() => toggleActiveTab(index)}
                          >
                            <DateFormat formatType={constants.FORMAT_DATE_TYPE.pointsfullDate} date={allVersionsContent[key].versionDate}/>
                          </NavLink>
                        </div>
                      </Col>
                    ))
                }
              </div>
            </div>
          </div>
        </Row>
      </Col>
      <Col sm="8" className="zole-rules-page-content" style={{ paddingRight: 5 }}>
        <div
          className="contact-support-chat-body-scroll-area"
        >
          <Row>
            <Col className="zole-rules-page-content-wrapper zole-rules-page-content-version-history-wrapper">
              {
                allVersionsContent && size(allVersionsContent) > 0 && (
                  <TextEditor style={{display: 'inline'}} viewEditorState={filter(allVersionsContent, (item, idx) => { return idx === activeTab })[0][i18n.language || constants.sendLanguage.lv]} readOnly />
                )
              }
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default VersionHistory;
