import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Label,
  Alert,
  Input,
  Button,
  FormGroup,
} from 'reactstrap';
import classNames from 'classnames';
import {
  isMobileSafari,
  isMobile,
} from 'react-device-detect';
// import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as EmailValidator from 'email-validator';

// import logoImg from '../../../images/Menu/zole_logo.webp';
import closeImg from '../../../images/landing-view/close.svg';
import { errorMessages } from '../../../constants/messages';

class ForgotPassword extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    success: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    submittedEmail: PropTypes.string,
  }

  static defaultProps = {
    error: null,
    success: null,
    member: {},
    submittedEmail: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      email: (props.member && props.member.email) ? props.member.email : (props?.submittedEmail || ''),
      inError: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.emailRef = React.createRef();
  }

  componentDidMount() {
    if (this.emailRef.current) {
      this.emailRef.current.focus();
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleSubmit = (e) => {
    e.preventDefault();
    const { onFormSubmit, history, i18n } = this.props;
    const { email } = this.state;

    if (!EmailValidator.validate(email)) {
      return this.setState({
        inError: errorMessages.wrongEmail,
      })
    }

    return onFormSubmit(this.state, i18n.language)
      .then(() => {
        setTimeout(() => history.push('/login'), 10000)
        this.setState({
          inError: '',
        })
      })
      .catch(() => {
        this.setState({
          inError: '',
        })
      });
  }

  goToLogin = (e) => {
    e.preventDefault();
    const { history } = this.props;

    history.push('/login');

    this.setState({ date: Date.now() });
  }

  render() {
    const { loading, error, success, t } = this.props;
    const { email, inError } = this.state;
    console.log(success, error, inError, "returnedSuccess====")


    console.log('successsuccess', success, error, inError);

    return (
      <div className="landing-container-body">
        <Row className="landing-header">
          <Col sm="10">
            {/* <img className="landing-header-logo" src={logoImg} alt="" /> */}
          </Col>
          <Col sm="2" className="landing-header-links">
          {/*  <Link to="/login">{t('home.close')}<img src={closeImg} alt="" /></Link>  */}
            <a href="#" onClick={this.goToLogin}>
              <span>
                {t('home.close')}
                <img src={closeImg} alt="" />
              </span>
            </a>
          </Col>
        </Row>

        <div className={classNames('landing-content', {
          'landing-content-mobile': isMobile
        })}>

            <div className="landing-form-title">
              <h1>{t('home.iForgotPass')}</h1>
            </div>

            <Row style={{margin:0}}>
              <Col lg={{ size: 4, offset: 4 }} style={{textAlign: 'center'}}>
                {(!!error || !!inError) && <Alert color="danger">{t(`member.${error || inError}`)}</Alert>}
                {!!success && <Alert color="success">{t(`member.${success}`, {email})}</Alert>}

                <Form onSubmit={this.handleSubmit}  className="common-form">
                  <FormGroup>
                    <Label for="email">{t('home.registeredEmail')}*</Label>
                    <Input
                      name="email"
                      id="email"
                      placeholder="john@doe.corp"
                      value={email}
                      disabled={loading}
                      onChange={this.handleChange}
                      maxLength={100}
                      innerRef={this.emailRef}
                    />
                  </FormGroup>
                  <Button className="common-button lite-shadow submit-button" disabled={loading}>
                    {loading ? t('home.loading') : t('home.send')}
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>

        </div>
    );
  }
}

//withRouter

export default  withTranslation('common')(ForgotPassword);
