import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import NavLink from 'reactstrap/lib/NavLink';
import Media from 'reactstrap/lib/Media';
import * as constants from '../../../../../constants/constants';

import gearIcon from '../../../../../images/icons/gear.svg';
import IconPopover from '../../Components/Components/IconPopover';

const LayoutHeader = React.memo(({
  activeTab, t, toggle, achievementsNotification, hasActiveRegistration, showUserSettingsModal,
}) => {
  const [settingsPopoverOpen, setSettingsPopoverOpen] = useState(false);

  const powerRankingsTabs = [
    constants.MENU_NAVIGATION.powerRankings,
    constants.MENU_NAVIGATION.powerRankingsPoints,
    constants.MENU_NAVIGATION.powerRankingsCoins,
    constants.MENU_NAVIGATION.powerRankingsWinsCount,
    constants.MENU_NAVIGATION.powerRankingsWinPercentage,
    constants.MENU_NAVIGATION.powerRankingsLossCount,
    constants.MENU_NAVIGATION.powerRankingsLossPercentage,
  ];

  return (
    <Row className="layout-header">
      <Col xs="10">
        <div xs="3" className="layout-header-link">
          <NavLink
            className={classNames('layout-header-link-text', {
              'layout-header-link-text-active': activeTab === constants.MENU_NAVIGATION.rooms
            })}
            onClick={() => { toggle(constants.MENU_NAVIGATION.rooms); }}
          >
            {t('menu.rooms')}
          </NavLink>
        </div>
        <div xs="3" id="top-tab" className="layout-header-link top-tab top-tab-menubar">
          <NavLink
            className={classNames('layout-header-link-text', {
              'layout-header-link-text-active': activeTab === constants.MENU_NAVIGATION.top || activeTab === constants.MENU_NAVIGATION.topMillionaires
            })}
            onClick={() => { toggle(constants.MENU_NAVIGATION.top); }}
          >
            {t('menu.top')}
          </NavLink>
        </div>
        <div xs="3" id="powerRankings-tab" className="layout-header-link top-tab power-rankings-tab">
          <NavLink
            className={classNames('layout-header-link-text', {
              'layout-header-link-text-active': powerRankingsTabs.includes(activeTab)
            })}
            onClick={() => { toggle(constants.MENU_NAVIGATION.powerRankings); }}
          >
            {t('menu.powerRankings')}
          </NavLink>
        </div>
        <div xs="3" className="layout-header-link my-info-tab">
          <NavLink
            className={classNames('layout-header-link-text', {
              'layout-header-link-text-active': constants.MY_INFO_TABS.includes(activeTab),
              // 'layout-header-link-notification': achievementsNotification,
            })}
            onClick={() => { toggle(constants.MENU_NAVIGATION.myInfo); }}
          >
            {t('menu.myInfo')}
          </NavLink>
        </div>
        <div xs="3" className="layout-header-link tournaments-tab">
          <NavLink
            className={classNames('layout-header-link-text', {
              'layout-header-link-text-active': activeTab === constants.MENU_NAVIGATION.tournaments || activeTab === constants.MENU_NAVIGATION.futureTournaments || activeTab === constants.MENU_NAVIGATION.tournamentsHistory,
              'layout-header-link-notification': hasActiveRegistration,
            })}
            onClick={() => { toggle(hasActiveRegistration ? constants.MENU_NAVIGATION.futureTournaments : constants.MENU_NAVIGATION.tournaments); }}
          >
            {t('menu.tournaments')}
          </NavLink>
        </div>
      </Col>
      <Col xs="2" className="layout-header-right">
        <div className="top-settings-section" onMouseEnter={() => setSettingsPopoverOpen(true)} onMouseLeave={() => setSettingsPopoverOpen(false)} id="user-settings-button-popover">
          <Media className="user-settings-button" src={gearIcon} alt={t('menu.settings')} onClick={showUserSettingsModal} />
          <IconPopover text={t('popovers.gameSettings')} placement="top" targetId="user-settings-button-popover" popoverOpen={settingsPopoverOpen} />
        </div>
      </Col>
    </Row>
  );
});

LayoutHeader.propTypes = {
  activeTab: PropTypes.string,
  t: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  showUserSettingsModal: PropTypes.func.isRequired,
  achievementsNotification: PropTypes.bool,
  hasActiveRegistration: PropTypes.bool,
};

LayoutHeader.defaultProps = {
  activeTab: '1',
  achievementsNotification: false,
  hasActiveRegistration: false,
};

export default LayoutHeader;
