import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Row, Col, Alert, Label, FormGroup, Form } from 'reactstrap';
import * as constants from '../../../../constants/constants';
import CustomModal from '../Components/Components/Modal';
import { TextInput } from '../Components/Components/TextInput';
import Footer from '../Components/Components/Footer';

import Dot from '../../../../images/redesign/player/dot.svg';

const ForgotPasswordComponent = ({ inError, loadingSubmit, messageType, toggleForgotPassword, forgotPasswordOpen, email, handleInputChange, handleSubmitForgotPassword, error, success, loading }) => {
  const { t } = useTranslation('common');
  console.log(inError, messageType, "in Error")

  return (
    <CustomModal
      onButtonClose={toggleForgotPassword}
      title={t('home.resetPassword')}
      isOpen={forgotPasswordOpen}
      footer={<Footer success={success} loadingSubmit={loadingSubmit} stage={constants.stages.forgotPassword} handleSubmitForgotPassword={handleSubmitForgotPassword} handleCloseButton={toggleForgotPassword} />}
      size={constants.SCREEN_MODE_TYPE.sm}
      toggle={toggleForgotPassword}
      body={(
        <Row className="forgot-password-section d-flex align-items-center">
          <>
            <Col lg={10} className="forgot-password-section-content mt-2">
              <div className="forgot-password-section-content-result">
                {
                  !success ? (
                    <Label>{t('home.forgotPasswordContent')}</Label>
                  ) : (
                    <Label>
                      {t('home.successForgot', { email })}
                    </Label>
                  )
                }
              </div>
              {
                !success ? (
                  <div className="forgot-password-section-content-input mb-5">
                    <Form className="common-form">
                      <FormGroup>
                        <TextInput
                          width="100%"
                          contentEditable
                          inputType={constants.PROFILE_FIELDS.email}
                          secondaryPlaceholder={t('home.registeredEmail2')}
                          initialValue={email}
                          onChange={(value, type) => handleInputChange(value, type)}
                          maxLength={100}
                          autoFocus
                        />
                        {
                          (!!error || !!inError) ? (
                            <Alert className="new-design-alert" color={constants.FROUNT_SIDE_STATUS.danger} isOpen >
                              <p>{t(`member.${error || inError}`)}</p>
                            </Alert>
                          ) : (null)
                        }
                      </FormGroup>
                    </Form>
                  </div>
                ) : null
              }

            </Col>
          </>
        </Row>
      )}
    />
  );
};

ForgotPasswordComponent.propTypes = {
  toggleForgotPassword: PropTypes.func.isRequired,
  forgotPasswordOpen: PropTypes.bool,
  handleInputChange: PropTypes.func.isRequired,
  email: PropTypes.string,
  handleSubmitForgotPassword: PropTypes.func.isRequired,
  error: PropTypes.string,
  success: PropTypes.string,
  loading: PropTypes.bool,
  messageType: PropTypes.string,
  loadingSubmit: PropTypes.bool,
};

ForgotPasswordComponent.defaultProps = {
  forgotPasswordOpen: false,
  error: null,
  success: null,
  loading: false,
  email: '',
  messageType: null,
  loadingSubmit: false,
};

export default ForgotPasswordComponent;
