import React from 'react';
import PropTypes from 'prop-types';
import { includes, map } from 'lodash';

import { Col, Row, Button, Label, Media, } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import classNames from 'classnames';

import * as constants from '../../../constants/constants';
import CustomModal from './Components/Components/Modal';
import NewMobileDetector from './Menu/Modals/MobileDetector';

import ReactGA from 'react-ga';

import LanguageSelect from './Components/Layout/LanguageSelect';
import HomeContainer from './HomeContainer';
import HelpGame from './Menu/RulesPage/RulesPage';
import VersionHistory from './Menu/VersionHistory';
import LatestVersion from './Menu/Modals/LatestVersion';
import TermsOfUse from './Menu/TermsOfUsePage';
import Contacts from './Menu/Contacts';
import Blogs from './Menu/Blogs';
import appstoreButton from '../../../images/redesign/landing-view/appstorebutton.png';
import playstoreButton from '../../../images/redesign/landing-view/playstorebutton.png';
import Cookies from './Menu/Modals/Cookies';
import MobileNavigation from './Menu/MobileNavigation';

import logoImg from '../../../images/redesign/landing-view/zoleLogo.svg';
import menuIcon from '../../../images/redesign/common/menuIcon.svg';
import loginMobileLandscapeButton from '../../../images/redesign/common/login-mobile-landscape-button.svg';
import lightMenuIcon from '../../../images/redesign/light-mode/icons/menuIcon.png';
import mobileLogo from '../../../images/redesign/common/mobileLogo.png';
// import mobileLandScapeLogo from '../../../images/redesign/common/logo-landscape.svg';
import close from '../../../images/redesign/common/close.svg';
import lightClose from '../../../images/redesign/light-mode/icons/close.png';
import returnImg from '../../../images/icons/return.svg';
import NormalReturnImg from '../../../images/redesign/normal-mode/icons/return.svg';
import maxFullscreenImg from '../../../images/redesign/top-row/minimized.svg';
import minFullscreenImg from '../../../images/redesign/top-row/maximized.svg';
import lightMaxFullscreenImg from '../../../images/redesign/light-mode/icons/minimized.svg';
import lightMinFullscreenImg from '../../../images/redesign/light-mode/icons/maximized.png';
import defaultFont from '../../../images/redesign/top-row/defaultFont.webp';
import largeFont from '../../../images/redesign/top-row/largeFont.webp';
import xLargeFont from '../../../images/redesign/top-row/xLargeFont.webp';
import config from '../../../constants/config';

const ANDROID_PATH_lv = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=lv';
const ANDROID_PATH_en = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=en';
const ANDROID_PATH_ru = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=ru';
const IOS_PATH_lv = 'https://apps.apple.com/lv/app/id1505526877';
const IOS_PATH_en = 'https://apps.apple.com/us/app/id1505526877';
const IOS_PATH_ru = 'https://apps.apple.com/ru/app/id1505526877';

class Home extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    soundOn: PropTypes.bool,
    selectPage: PropTypes.string,
    blogId: PropTypes.string,
    toggleFullscreen: PropTypes.func.isRequired,
    isFullscreenEnabled: PropTypes.bool,
    screenMode: PropTypes.string,
    toggleNewDesign: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    toggleFont: PropTypes.func.isRequired,
    seoLang: PropTypes.string,
    verifiedEmail: PropTypes.bool,
  }

  static defaultProps = {
    soundOn: false,
    selectPage: null,
    blogId: '',
    screenMode: constants.SCREEN_MODE.dark,
    fontMode: constants.FONT_MODE.normal,
    seoLang: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      activeMenu: constants.TOP_HEADER_NAVIGATION.home,
      versionHistoryOpen: false,
      cookiesModalOpen: false,
      cookiesFrom: '',
      blogArticleOpen: false,
      blogId: '',
      mobileNavigation: false,
      isFullscreenEnabled: false,
      devMode: false,
    };

    this.goToLogin = this.goToLogin.bind(this);
  }

  componentDidMount() {
    const { history, blogId, seoLang, i18n } = this.props;

    const { hostname } = window.location;

    let devMode = false;
    if (hostname.includes('dev.') || hostname.includes('localhost')) {
      devMode = true;
    }

    this.setState({
      devMode,
    });

    try {
      if (!localStorage.getItem('zoleCookies') || !config.isInAppFrame()) { //x
        this.setState({
          cookiesModalOpen: true,
        });
      }
    } catch (err) {
      console.log(err);
    }

    const { pathname } = window.location;
    if (includes(pathname, '/landing')) {
      history.push('/landing');
      this.setState({
        activeMenu: constants.TOP_HEADER_NAVIGATION.home,
      });
    } else if (includes(pathname, '/home') && seoLang) {
      i18n.changeLanguage(seoLang);
      try {
        localStorage.setItem('language', seoLang); //x
      } catch (err) {
        console.log(err);
      }
      history.push(`/home/${seoLang}`);
      this.setState({
        activeMenu: constants.TOP_HEADER_NAVIGATION.home,
      });
    } else if (includes(pathname, '/terms-of-use')) {
      history.push('/terms-of-use');
      this.setState({
        activeMenu: constants.TOP_HEADER_NAVIGATION.termsOfUse,
      });
    } else if (includes(pathname, '/blog')) {
      // const urlParams = new URLSearchParams(search);
      // blogId = urlParams.get('blogId') || '';
      if (blogId) {
        this.setState({
          blogArticleOpen: true,
          blogId,
        });
      } else {
        this.setState({
          blogArticleOpen: false,
          blogId: '',
        });
      }
      this.setState({
        activeMenu: constants.TOP_HEADER_NAVIGATION.blog,
      });
    } else if (includes(pathname, '/game-rule')) {
      history.push('/game-rule');
      this.setState({
        activeMenu: constants.TOP_HEADER_NAVIGATION.helpPage,
      });
    } else if (includes(pathname, '/version-history')) {
      this.setState({
        activeMenu: constants.FOOTER_NAVIGATION_LINK.versionHistory,
      });
    } else if (includes(pathname, '/contacts')) {
      this.setState({
        activeMenu: constants.TOP_HEADER_NAVIGATION.contacts,
      });
    } else {
      history.push('/landing');
      
      this.setState({
        activeMenu: constants.TOP_HEADER_NAVIGATION.home,
      });
    }
    this.setState({ date: Date.now() })
  }

  componentDidUpdate() {
    console.log('componentDidUpdate home');
  }

  goToLogin = (e) => {
    e.preventDefault();
    const { history } = this.props;

    console.log('history.push to login', { history: history.location });

    history.push('/login');

    console.log('history.push to login 2', { history: history.location });

    this.setState({ date: Date.now() });

    //  window.location.reload();
  }

  goToRegister = (e) => {
    e.preventDefault();
    const { history } = this.props;

    history.push('/sign-up');

    this.setState({ date: Date.now() })
  }

  toggleHomeContainer = (item, cookiesFrom) => {
    const { history } = this.props;
    const { activeMenu, mobileNavigation } = this.state;

    if (activeMenu !== item && item !== constants.TOP_HEADER_NAVIGATION.login && item !== constants.TOP_HEADER_NAVIGATION.lang) {
      this.setState({
        activeMenu: item,
        versionHistoryOpen: false,
      });
    }

    if (item === constants.TOP_HEADER_NAVIGATION.home) {
      history.push('/landing');
    } else if (item === constants.FOOTER_NAVIGATION_LINK.termsOfUse || item === constants.TOP_HEADER_NAVIGATION.termsOfUse) {
      history.push('/terms-of-use');
    } else if (item === constants.TOP_HEADER_NAVIGATION.blog) {

      ReactGA.event({
        category: 'Menu',
        action: 'Blogs',
      });

      history.push('/blog');
      this.setState({
        blogArticleOpen: false,
        blogId: '',
      });
    } else if (item === constants.TOP_HEADER_NAVIGATION.helpPage) {
      history.push('/game-rule');
    } else if (item === constants.FOOTER_NAVIGATION_LINK.versionHistory || item === constants.TOP_HEADER_NAVIGATION.versionHistory) {
      history.push('/version-history/');
      this.setState({
        versionHistoryOpen: true,
      });
    } else if (item === constants.TOP_HEADER_NAVIGATION.contacts || item === constants.FOOTER_NAVIGATION_LINK.contacts) {
      ReactGA.event({
        category: 'Menu',
        action: 'Contacts',
      });

      history.push('/contacts');
    }

    if (cookiesFrom) {
      this.setState({
        cookiesFrom: constants.COOKIES_TERMS_OPEN.home,
      });
    } else {
      this.setState({
        cookiesFrom: '',
      });
    }

    if (mobileNavigation) {
      this.setState({
        mobileNavigation: false,
      });
    }
  }

  playButtonSound = () => {
    const { soundOn } = this.props;

    if (soundOn) {
      this.buttonClickedAudio.play();
    }
  }

  toggleVersioHistory = () => {
    console.log("prevState.versionHistoryOpen");
    const { versionHistoryOpen } = this.state;
    this.setState(prevState => ({
      versionHistoryOpen: !prevState.versionHistoryOpen,
    }));

    if (!versionHistoryOpen) {
      ReactGA.event({
        category: 'Menu',
        action: 'Version History',
      });
    }

    console.log({ versionHistoryOpen });
  }

  closeCookiesModal = () => {
    this.setState({
      cookiesModalOpen: false,
    });
  }

  handleCookiesSet = () => {
    try {
      if (!localStorage.getItem('zoleCookies')) { //x
        localStorage.setItem('zoleCookies', JSON.stringify(true)); //x
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({
      cookiesModalOpen: false,
    });
  }

  blogArticleFunc = (flag) => {
    this.setState({
      blogArticleOpen: flag,
    });
  }

  toggleMobileNavigation = () => {
    this.setState(prevState => ({
      mobileNavigation: !prevState.mobileNavigation,
    }));
  }

  toggleNewDesignFunc = () => {
    const { toggleNewDesign } = this.props;

    try {
      if (localStorage.getItem('newDesign')) { //x
        localStorage.removeItem('newDesign'); //x
        toggleNewDesign(false);

        ReactGA.event({
          category: 'Menu',
          action: 'Old Design',
        });
      } else {
        localStorage.setItem('newDesign', true); //x
        toggleNewDesign(true);

        ReactGA.event({
          category: 'Menu',
          action: 'New Design',
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  toggleFontFunc = () => {
    const { fontMode, toggleFont } = this.props;

    ReactGA.event({
      category: 'Menu',
      action: 'Change Font Size',
    });

    if (fontMode === constants.FONT_MODE.normal) {
      toggleFont(constants.FONT_MODE.large);
    } else if (fontMode === constants.FONT_MODE.large) {
      toggleFont(constants.FONT_MODE.xLarge);
    } else {
      toggleFont(constants.FONT_MODE.normal);
    }
  }

  goLanding = (e) => {
    const { history } = this.props;
    e.preventDefault();
    history.push('/landing');
  }

  render() {
    const { t, i18n, selectPage, history, toggleFullscreen, isFullscreenEnabled, screenMode, fontMode, seoLang, isLoggedIn, member } = this.props;
    const { activeMenu, versionHistoryOpen, cookiesModalOpen, cookiesFrom, blogArticleOpen, blogId, mobileNavigation, devMode } = this.state;

    const slideHeight = 370;
    const slideWidth = 470;
    const slideBannerWidth = 360;
    const slideBannerHeight = 340;
    const blogSliderWidth = 340;
    const blogSliderHeight = 240;

    let latestUid = null;

    try {
      latestUid = localStorage.getItem('latestUid') || null; //x
    } catch (err) {
      latestUid = 'null';
      console.log(err);
    }

    // const fithScreenH = window.innerHeight / 3;

    // if (fithScreenH < slideBannerHeight) {
    //   const scaleDown = fithScreenH / slideBannerHeight;
    //   slideBannerHeight = fithScreenH;
    //   slideBannerWidth *= scaleDown;
    // }

    // console.log(activeMenu, {selectPage}, 'render landing 2222');
    console.log("activeMenu111111111111111: ", activeMenu);
    return (
      <>
        <div className="top-row top-row-new new-design-landing-header d-flex">
          <div className="logo-wrapper">
            <a className="logo" target="_blank" rel="noopener noreferrer" href="https://spelezoli.lv/" alt="ZOLE" />
          </div>
          <div className="top-settings-new text-uppercase">
            <div className={classNames("top-settings-new-section", { "top-settings-new-section-active": constants.TOP_HEADER_NAVIGATION.home === activeMenu })} onClick={() => this.toggleHomeContainer(constants.TOP_HEADER_NAVIGATION.home)}>
              {t(`menu.${constants.TOP_HEADER_NAVIGATION.home}`)}
            </div>
            <div className={classNames("top-settings-new-section", { "top-settings-new-section-active": constants.TOP_HEADER_NAVIGATION.helpPage === activeMenu })} onClick={() => this.toggleHomeContainer(constants.TOP_HEADER_NAVIGATION.helpPage)}>
              {t(`menu.${constants.TOP_HEADER_NAVIGATION.helpPage}`)}
            </div>
            <div className={classNames("top-settings-new-section", { "top-settings-new-section-active": constants.TOP_HEADER_NAVIGATION.blog === activeMenu })} onClick={() => this.toggleHomeContainer(constants.TOP_HEADER_NAVIGATION.blog)}>
              {t(`menu.${constants.TOP_HEADER_NAVIGATION.blog}`)}
            </div>
            <div className={classNames("top-settings-new-section", { "top-settings-new-section-active": constants.TOP_HEADER_NAVIGATION.contacts === activeMenu })} onClick={() => this.toggleHomeContainer(constants.TOP_HEADER_NAVIGATION.contacts)}>
              {t(`menu.${constants.TOP_HEADER_NAVIGATION.contacts}`)}
            </div>
            {(!isLoggedIn && latestUid === 'null') &&
              <Button className="layout-highlighted-button top-settings-new-section-login-button d-flex align-items-center" onClick={this.goToLogin}>{t(`menu.${constants.TOP_HEADER_NAVIGATION.login}`)}</Button>
            }
            <>
              <div className="top-settings-new-divider ml-3" />
              {/* <img src={screenMode === constants.SCREEN_MODE.light ? (isFullscreenEnabled ? lightMinFullscreenImg : lightMaxFullscreenImg) : (isFullscreenEnabled ? minFullscreenImg : maxFullscreenImg)} alt="x" onClick={toggleFullscreen} className="top-settings-new-section-fullscreen-button"/> */}
              {/* <div className="top-settings-new-divider ml-3" /> */}
              <div className="top-settings-section top-settings-section-language top-settings-section-language-new">
                <LanguageSelect />
              </div>
              {/* <div className="top-settings-new-divider mr-3" />
              <Media src={fontMode === constants.FONT_MODE.xLarge ? xLargeFont : fontMode === constants.FONT_MODE.large ? largeFont : defaultFont} className="cursor-pointer mr-3" alt="x" onClick={this.toggleFontFunc} /> */}
              {
                devMode && activeMenu === constants.TOP_HEADER_NAVIGATION.home && (
                  <>
                    {/* <div className="top-settings-new-divider ml-3 mr-3" /> */}
                    {/* <div className="top-settings-new-section" onClick={this.toggleNewDesignFunc}>
                      {t('home.oldDesign')}
                    </div> */}
                  </>
                )
              }
            </>
          </div>
        </div>
        <div className={classNames("top-row new-design-landing-mobile-header", { 'no-landing-mobile-header': activeMenu !== constants.TOP_HEADER_NAVIGATION.home }, `landing-mobile-${activeMenu}-header`)}>
          <div className="logo-wrapper d-flex align-items-center">
            <a className="logo" target="_blank" rel="noopener noreferrer" href="https://spelezoli.lv/" alt="ZOLE" />
          </div>
        </div>
        <div className='mobile-landscape-navigation-logo-wrapper d-none'>
        </div>

        <div className="new-design-landing-mobile-navigation">
          <Media className="mobile-navigation-logo" src={mobileLogo} onClick={() => this.toggleHomeContainer(constants.TOP_HEADER_NAVIGATION.home)} alt="x" />
          <div className="left-part d-flex align-items-start">
            {(!isLoggedIn && latestUid === 'null') &&
              <Button className="layout-highlighted-button" onClick={(e) => this.goToLogin(e)}>{t('home.login')}</Button>
            }
            <Button className="mobile-landscape-login-button  d-none" onClick={(e) => this.goToLogin(e)}>
              <Media src={loginMobileLandscapeButton} alt='mobile-landscape-login-button' />
            </Button>
          </div>
          <div className="right-part d-flex align-items-end">
            {/* <Media src={screenMode === constants.SCREEN_MODE.light ? returnImg : NormalReturnImg} className='return-home' alt="x" onClick={() => this.toggleHomeContainer(constants.TOP_HEADER_NAVIGATION.home)} /> */}
            <Media src={!mobileNavigation ? (screenMode === constants.SCREEN_MODE.light ? lightMenuIcon : menuIcon) : (screenMode === constants.SCREEN_MODE.light ? lightClose : close)} alt="x" onClick={this.toggleMobileNavigation} />
          </div>
        </div>
        {
          mobileNavigation && (
            <div className={classNames('new-design-landing-mobile-navigation-section', { 'display-show': mobileNavigation, 'display-hide': !mobileNavigation })}>
              <MobileNavigation toggleMobileNavigation={this.toggleMobileNavigation} activeMenu={activeMenu} toggleVersioHistory={this.toggleVersioHistory} toggleHomeContainer={this.toggleHomeContainer} mobileNavigation={mobileNavigation} />
            </div>
          )
        }
        <div className="new-design-landing-container">
          {
            activeMenu === constants.TOP_HEADER_NAVIGATION.home ? (
              <HomeContainer history={history} toggleHomeContainer={this.toggleHomeContainer} goToLogin={this.goToLogin} goToRegister={this.goToRegister} blogArticleFunc={this.blogArticleFunc} isLoggedIn={isLoggedIn} />
            ) : activeMenu === constants.TOP_HEADER_NAVIGATION.helpPage ? (
              <HelpGame playButtonSound={this.playButtonSound} title={constants.TOP_HEADER_NAVIGATION.helpPage} />
            ) : activeMenu === constants.FOOTER_NAVIGATION_LINK.termsOfUse || selectPage === constants.FOOTER_NAVIGATION_LINK.termsOfUse ? (
              <div className="terms-of-use-container">
                <TermsOfUse playButtonSound={this.playButtonSound} title={constants.TOP_HEADER_NAVIGATION.termsOfUse} cookiesFrom={cookiesFrom} />
              </div>
            ) : activeMenu === constants.FOOTER_NAVIGATION_LINK.versionHistory ? (
              <div className="zole-rules-container">
                <VersionHistory playButtonSound={this.playButtonSound} title={constants.TOP_HEADER_NAVIGATION.versionHistory} />
              </div>
            ) : activeMenu === constants.TOP_HEADER_NAVIGATION.blog || selectPage === constants.FOOTER_NAVIGATION_LINK.blog ? (
              <div className="blogs-container d-flex align-items-center">
                <Blogs history={history} blogArticleFunc={this.blogArticleFunc} playButtonSound={this.playButtonSound} title={constants.TOP_HEADER_NAVIGATION.blog} blogArticleOpen={blogArticleOpen} blogId={blogId} />
              </div>
            ) : activeMenu === constants.TOP_HEADER_NAVIGATION.contacts || selectPage === constants.TOP_HEADER_NAVIGATION.contacts ? (
              <div className="contacts-container d-flex align-items-center">
                <Contacts history={history} playButtonSound={this.playButtonSound} />
              </div>
            ) : (
              <HomeContainer history={history} goToLogin={this.goToLogin} goToRegister={this.goToRegister} blogArticleFunc={this.blogArticleFunc} isLoggedIn={isLoggedIn} />
            )
          }

          <div className={classNames("new-design-landing-container-footer d-flex align-items-center", { 'no-landing-footer': activeMenu !== constants.TOP_HEADER_NAVIGATION.home })}>
            <div className="new-design-landing-container-footer-first d-flex align-items-center">
              <div className="zole-logo d-flex align-items-center">
                <Media src={logoImg} alt="x" />
              </div>
              <div className="support d-flex align-items-center">
                <p className="support-content d-flex align-items-center">{t('home.availableNext')}</p>
                <div className="support-by d-flex align-items-center">
                  <a target="_blank" rel="noopener noreferrer" href={i18n.language === constants.sendLanguage.lv ? ANDROID_PATH_lv : i18n.language === constants.sendLanguage.en ? ANDROID_PATH_en : i18n.language === constants.sendLanguage.ru ? ANDROID_PATH_ru : ANDROID_PATH_lv}>
                    <Media src={playstoreButton} className="mr-2 about-us-modal-third-row-img about-us-modal-third-row-img-left" />
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href={i18n.language === constants.sendLanguage.lv ? IOS_PATH_lv : i18n.language === constants.sendLanguage.en ? IOS_PATH_en : i18n.language === constants.sendLanguage.ru ? IOS_PATH_ru : IOS_PATH_lv}>
                    <Media src={appstoreButton} className="mr-2 about-us-modal-third-row-img" />
                  </a>
                </div>
              </div>
            </div>
            <div className="help-menu d-flex align-items-center">
              {/* <a className="mr-2">{t('home.technicalSupport')}</a> */}
              <a className="mx-2 text-decoration-underline" onClick={() => this.toggleHomeContainer(constants.TOP_HEADER_NAVIGATION.helpPage)}>{t('home.gameHelp')}</a>
              <a className="mx-2 text-decoration-underline" onClick={() => this.toggleVersioHistory()}>{t('home.versionHistory')}</a>
              <a className="mx-2 text-decoration-underline" onClick={() => this.toggleHomeContainer(constants.FOOTER_NAVIGATION_LINK.termsOfUse)}>{t('home.termsOfUse')}</a>
              {/* <a className="mr-2">{t('home.aboutUs')}</a> */}
              {/* <a className="mr-2">{t('home.advertising')}</a> */}
              <a className="mx-2 text-decoration-underline" onClick={() => this.toggleHomeContainer(constants.FOOTER_NAVIGATION_LINK.contacts)}>{t('home.contacts')}</a>
            </div>
            <div className="help-develop d-flex align-items-center">
              <p className="help-develop-title mb-0">{t('home.developer')}</p>
              <p className="help-develop-content ml-1">SIA “Draxo Games”</p>
            </div>
            <div className="help-manufacture">
              © SIA “Draxo Games”, {new Date().getFullYear()}
            </div>
          </div>
        </div>
        {
          versionHistoryOpen && (
            <LatestVersion versionHistoryOpen={versionHistoryOpen} toggleVersioHistory={this.toggleVersioHistory} toggleHomeContainer={this.toggleHomeContainer} />
          )
        }
        <Cookies cookiesModalOpen={cookiesModalOpen && !config.isInAppFrame()} closeCookiesModal={this.closeCookiesModal} handleCookiesSet={this.handleCookiesSet} toggleHomeContainer={this.toggleHomeContainer} />

        {latestUid === 'null' && !member.uid && (
            <NewMobileDetector />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

export default connect(mapStateToProps)(withTranslation('common')(Home));
