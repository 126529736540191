import React from 'react';
import PropTypes from 'prop-types';

const RoomTypes = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Istabas sākumā jānosaka viens no diviem galvenajiem paveidiem, kas notiek tad, ja visi trīs spēlētāji palaiž garām. Vai nu tiek ierakstītas kopējās pules (P), vai arī tiek spēlēts ‘galdiņš’ (G). Papildus tam var atļaut arī spēlēt mazo zoli (PM vai GM).
        </p>
        <ul>
          <li>
          <b>Parastā jeb spēle ar pulēm</b>: ja visi trīs spēlētāji palaiž garām, tad spēles protokolā tiek ierakstīta Kopējā Pule (ja ir četri spēlētāji pie galda, un protokolā neeksistē neviena pule, tad ieraksta divas kopējās pules).
          </li>
          <li>
          <b>Galdiņš</b>: ja visi trīs spēlētāji palaiž garām, tad tiek spēlēta partija, kuras mērķis ir savākt pēc iespējas mazāk stiķu. Tas spēlētājs, kurš ir savācis visvairāk stiķu, tas zaudē kā lielais. Gadījumā, ja vairākiem spēlētājiem ir vienāds augstākais savākto stiķu skaits, tiem spēlētājiem tiek saskaitīti savāktie punkti, un zaudē tas, kurš savācis vairāk punktu.
          </li>
          <li>
          <b>Mazā zole</b>: līdzīgs princips kā spēlējot galdiņu, kur mērķis ir nepaņemt pilnīgi nevienu stiķi. Tikko kā mazās zoles spēlētājs paņem vienu stiķi, tā automātiski zaudē kā lielais spēlējot zoli.
          </li>
        </ul>
        <p>
        Istabas tiek iedalītas arī pēc tā, cik ilgs laiks ir atvēlēts gājienam:
        </p>
        <ul>
          <li><b>parastā istaba</b>: gājiena veikšanai ir 25 sekundes</li>
          <li><b>ātrā istaba</b>: gājiena veikšanai ir 15 sekundes</li>
          <li><b>zibensistaba</b>: gājiena veikšanai ir 5 sekundes</li>
        </ul>

        <p>Tāpat pastāv vēl vairāki citi istabu iestatījumi:</p>
        <ul>
          <li><b>Pro istaba</b>: var spēlēt tikai spēlētāji, kuri ir sasnieguši vismaz 10.līmeni</li>
          <li><b>Privāta istaba</b>: pie istabas izveides tiek ģenerēta parole, līdz ar to istabai var pievienoties tikai ar šo paroli</li>
          <li>
          <b>4-spēlētāju istaba</b>: istabā piedalās 4 spēlētāji, no kuriem 3 piedalās partijā, kamēr ceturtais gaida; nākamajā partijā spēlētāju kārtība atkal mainās pulksteņa rādītāja virzienā.
          </li>
        </ul>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          Вначале комнаты следует определиться, что происходит тогда, если все три игрока говорят пас. Пишутся общие пули (P), или выбор падает на игру со столом (G). Дополнительно можно разрешить играть и Малую Золе (PM или GM).
        </p>
        <ul>
          <li>
          <b>Обычная или игра с пулями</b>: если все три игрока говорят пас, то в протоколе игры записывается Общая Пуля (если за столом четыре игрока, и в протоколе нет ни одной «пули», то записываются две общие «пули»).
          </li>
          <li>
          <b>Игра со столом</b>: если все три игрока сказали пас, то играется партия, цель которой  набрать как можно меньше взяток. Игрок, который набрал большее количество взяток, проигрывает как «большой». В случае, если несколько игроков набрали одинаковое количество взяток, то сосчитываются набранные ими очки, и проигрывает тот, который набрал наибольшое количество очков.
          </li>
          <li>
          <b>Малая Золе</b>: Принцип игры похож как в Игре со столом, цель которой не взять ни одной взятки. Как только игрок Малой Золе возьмет взятку, он автоматически проигрывает как «большой».
          </li>
        </ul>
        <p>
          Комнаты делятся и по тому, какое время отводится для того, чтобы сделать ход:
        </p>
        <ul>
          <li><b>обычная комната</b>: ход нужно сделать за 25 секунд</li>
          <li><b>быстрая комната</b>: ход нужно сделать за 15 секунд</li>
          <li><b>молниеносная комната</b>: ход нужно сделать за 5 секунд</li>
        </ul>

        <p>Существуют еще и другие параметры разделения комнат:</p>
        <ul>
          <li><b>Про комната</b>: могут играть только игроки достигнувшие как минимум 10-ого уровня</li>
          <li><b>Приватная комната</b>: создавая комнату, ставится пароль, поэтому к комнате можно присоедениться только зная пароль</li>
          <li>
          <b>комната 4-ых игроков</b>: участвуют 4 игрока, 3 из которых участвуют в игре, четвертый ждет, в следующей партии распорядок игроков меняется по часовой стрелке.
          </li>
        </ul>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
        At the beginning of the game room, one of the two main types should be defined, what happens when all three players passes over. Either common “Pules” (P) are recorded, or players continue to play “Table” (G). In addition, it is possible to allow play “Small Zole” (PM or GM).
        </p>
        <ul>
          <li>
          <b>Regular game or game with “Pules”</b>: if all three players pass the move, then in the game protocol is recorded a Common “Pules” (if there are four players, and in the protocol, there are no “Pules”, then two Common “Pules” should be written).
          </li>
          <li>
          <b>“Table”</b>: if all three players pass, then a game with the goal to collect as few “Sticks,” as possible, is played. The player who collected the most of the Sticks loses as the “Big”. In case if several players have the same number of Sticks collected, the points collected are counted and the one who has collected the most of the points loses.
          </li>
          <li>
          <b>“Small Zole”</b>: similar principle as playing “Table”, where the goal is not to take any of the Sticks. When the player of Small Zole takes one “Stick,” he automatically loses as the “Big”.
          </li>
        </ul>
        <p>
          The rooms are also divided according to time, which is allowed to make a move:
        </p>
        <ul>
          <li><b>Regular room</b>: player for the move has 25 seconds</li>
          <li><b>Fast room</b>: player for the move has 15 seconds</li>
          <li><b>Extra fast room</b>: player for the move has 5 seconds.</li>
        </ul>

        <p>There are also several other room options:</p>
        <ul>
          <li><b>Pro room</b>: only players, who reached at least the 10th level, can play here.</li>
          <li><b>Private room</b>: when creating a room, a special password is generated, thus, only people who have the password can enter the room.</li>
          <li>
          <b>Four player room</b>: there are 4 players in the room, 3 of whom are participating in the game while the fourth is waiting; in the next game, the order of the player’s changes again in a clockwise direction.
          </li>
        </ul>
      </>
    )
  }
};

RoomTypes.propTypes = {
  lang: PropTypes.string,
};

RoomTypes.defaultProps = {
  lang: 'lv',
};

export default RoomTypes;
