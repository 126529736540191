import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import Media from 'reactstrap/lib/Media';

import BlockUserModal from '../../Modals/BlockUser';

import * as constants from '../../../../../../constants/constants';

import negativeImg from '../../../../../../images/redesign/mobile/icons/negative-coin-icon.svg';

import buttonClickedSound from '../../../../../../sounds/click_feedback.flac';

class NegativePlayer extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    soundOn: PropTypes.bool,
    index: PropTypes.number,
    screenMode: PropTypes.string,
    bal: PropTypes.number,
  };

  static defaultProps = {
    soundOn: true,
    index: null,
    screenMode: '',
    bal: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      negativeUserModal: false,
    };

    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentDidMount() {

  }


  playButtonSound = () => {
    const { soundOn } = this.props;

    if (soundOn) {
      const playPromise = this.buttonClickedAudio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            // Automatic playback started!
            // Show playing UI.
            console.log("audio played auto");
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
            console.log("playback prevented");
          });
      }
    }
  }

  toggleNegativeUser = () => {
    this.playButtonSound();

    this.setState(prevState => ({
      negativeUserModal: !prevState.negativeUserModal,
    }));
  };

  render() {
    const {
      t,
      index,
      screenMode,
      bal,
    } = this.props;

    const { negativeUserModal } = this.state;

    if (index === 1) {
      return null;
    }

    return (
      <>
        {bal <= constants.PLAYER_ZOLE_RANGE.min && (
          <Media
            className="negative-player-icon"
            src={negativeImg}
            alt=""
            onClick={this.toggleNegativeUser}
          />
        )}
        <BlockUserModal
          t={t}
          blockUserModal={negativeUserModal}
          toggleNegativeUser={this.toggleNegativeUser}
        />
      </>
    );
  }
}

export default (withTranslation(['game', 'notifications'])(NegativePlayer));
