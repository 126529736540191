import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

const GameResultTitle = React.memo(({ gameResult, t }) => {
  // if (gameResult.type === 'parasta') {
  //   return `${t('large')} ${gameResult.winner === 'large' ? (t('wins')) : (t('loses'))}`
  // }
  // if (gameResult.type === 'zole') {
  //   return `${t('large')} ${gameResult.winner === 'large' ? (t('wins')) : (t('loses'))} ${t('zoli')}`
  // }
  // if (gameResult.type === 'mazaZole') {
  //   return `${gameResult.largePlayer} ${gameResult.winner === 'large' ? (t('wins')) : (t('loses'))} ${t('mazoZoli')}`
  // }
  // if (gameResult.type === 'galdins') {
  //   return `${gameResult.winner} ${t('loses')} ${t('table')}`
  // }
  if (gameResult?.type === 'pass') {
    return `${t('allPassed')}`
  } else {
    return `${t('gameResult')}`
  }
  return null;
});

GameResultTitle.propTypes = {
  gameResult: PropTypes.shape(),
  t: PropTypes.func.isRequired,
};

GameResultTitle.defaultProps = {
  gameResult: {},
};

export default withTranslation('notifications')(GameResultTitle);
