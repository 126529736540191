import Store from '../store/game';
import * as constants from '../constants/constants';

export const initialState = {};

export default function leaderboardReducer(state = initialState, action) {
  switch (action.type) {
    case 'LEADERBOARD_REPLACE': {
      if (action.data) {
        const arr = Object.keys(action.data).map(key => ({
          position: action.data[key].pos,
          points: action.data[key].totalPnts,
          gamesPlayed: action.data[key].gPlayed,
          name: action.data[key].name,
          balance: action.data[key].bal,
          lvl: action.data[key].lvl || 1,
          key,
        })) || [];

        //  arr.sort((a, b) => a.position - b.position);

        arr.sort((a, b) => {
          const n = b.points - a.points;
          if (n !== 0) {
            return n;
          }

          return b.balance - a.balance;
        });

        return {
          ...state,
          leaderboard: arr,
        };
      }
      return state;
    }

    case 'LEADERBOARD_UPDATE': {
      if (action.data) {
        const { user, index } = action.data;

        const newArr = state.leaderboard;

        newArr[index - 1] = {
          position: user.pos,
          points: user.totalPnts,
          gamesPlayed: user.gPlayed,
          name: user.name,
          balance: user.bal,
          lvl: user.lvl,
        };

        return {
          ...state,
          leaderboard: newArr,
        };
      }
      return state;
    }

    case 'LEADERBOARD_YEAR_REPLACE': {
      if (action.data) {
        const arr = Object.keys(action.data).map(key => ({
          position: action.data[key].pos,
          points: action.data[key].totalPnts,
          gamesPlayed: action.data[key].gPlayed,
          name: action.data[key].name,
          balance: action.data[key].bal,
          lvl: action.data[key].lvl,
          key,
        })) || [];

        //  arr.sort((a, b) => a.position - b.position);

        arr.sort((a, b) => {
          const n = b.points - a.points;
          if (n !== 0) {
            return n;
          }

          return b.balance - a.balance;
        });

        return {
          ...state,
          leaderboardYear: arr,
        };
      }
      return state;
    }

    case 'LEADERBOARD_MONTH_REPLACE': {
      if (action.data) {
        const arr = Object.keys(action.data).map(key => ({
          position: action.data[key].pos,
          points: action.data[key].totalPnts,
          gamesPlayed: action.data[key].gPlayed,
          name: action.data[key].name,
          balance: action.data[key].bal,
          lvl: action.data[key].lvl,
          key,
        })) || [];

        //  arr.sort((a, b) => a.position - b.position);

        arr.sort((a, b) => {
          const n = b.points - a.points;
          if (n !== 0) {
            return n;
          }

          return b.balance - a.balance;
        });

        return {
          ...state,
          leaderboardMonth: arr,
        };
      }
      return state;
    }

    case 'LEADERBOARD_WEEK_REPLACE': {
      if (action.data) {
        const arr = Object.keys(action.data).map(key => ({
          position: action.data[key].pos,
          points: action.data[key].totalPnts,
          gamesPlayed: action.data[key].gPlayed,
          name: action.data[key].name,
          balance: action.data[key].bal,
          lvl: action.data[key].lvl,
          key,
        })) || [];

        //  arr.sort((a, b) => a.position - b.position);

        arr.sort((a, b) => {
          const n = b.points - a.points;
          if (n !== 0) {
            return n;
          }

          return b.balance - a.balance;
        });

        return {
          ...state,
          leaderboardWeek: arr,
        };
      }
      return state;
    }

    case 'LEADERBOARD_DAILY_REPLACE': {
      if (action.data) {
        const arr = Object.keys(action.data).map(key => ({
          position: action.data[key].pos,
          points: action.data[key].totalPnts,
          gamesPlayed: action.data[key].gPlayed,
          name: action.data[key].name,
          balance: action.data[key].bal,
          lvl: action.data[key].lvl,
          key,
        })) || [];

        //  arr.sort((a, b) => a.position - b.position);

        arr.sort((a, b) => {
          const n = b.points - a.points;
          if (n !== 0) {
            return n;
          }

          return b.balance - a.balance;
        });

        return {
          ...state,
          leaderboardDaily: arr,
        };
      }
      return state;
    }

    case 'LEADERBOARD_DAILY_UPDATE': {
      if (action.data) {
        const { user, index } = action.data;

        const newArr = state.leaderboardDaily;

        newArr[index - 1] = {
          position: user.pos,
          points: user.totalPnts,
          gamesPlayed: user.gPlayed,
          name: user.name,
          balance: user.bal,
          lvl: user.lvl,
        };

        return {
          ...state,
          leaderboardDaily: newArr,
        };
      }
      return state;
    }

    case 'LEADERBOARD_WEEK_UPDATE': {
      if (action.data) {
        const { user, index } = action.data;

        const newArr = state.leaderboardWeek;

        newArr[index - 1] = {
          position: user.pos,
          points: user.totalPnts,
          gamesPlayed: user.gPlayed,
          name: user.name,
          balance: user.bal,
          lvl: user.lvl,
        };

        return {
          ...state,
          leaderboardWeek: newArr,
        };
      }
      return state;
    }

    case 'LEADERBOARD_MONTH_UPDATE': {
      if (action.data) {
        const { user, index } = action.data;

        const newArr = state.leaderboardMonth;

        newArr[index - 1] = {
          position: user.pos,
          points: user.totalPnts,
          gamesPlayed: user.gPlayed,
          name: user.name,
          balance: user.bal,
          lvl: user.lvl,
        };

        return {
          ...state,
          leaderboardMonth: newArr,
        };
      }
      return state;
    }

    case 'LEADERBOARD_YEAR_UPDATE': {
      if (action.data) {
        const { user, index } = action.data;

        const newArr = state.leaderboardYear;

        newArr[index - 1] = {
          position: user.pos,
          points: user.totalPnts,
          gamesPlayed: user.gPlayed,
          name: user.name,
          balance: user.bal,
          lvl: user.lvl,
        };

        return {
          ...state,
          leaderboardYear: newArr,
        };
      }
      return state;
    }

    case 'MY_LEADERBOARD_POS': {
      return {
        ...state,
        myLeaderboard: action.data,
      };
    }

    case 'MY_LEADERBOARD_YEAR_POS': {
      return {
        ...state,
        myLeaderboardYear: action.data,
      };
    }

    case 'MY_LEADERBOARD_MONTH_POS': {
      return {
        ...state,
        myLeaderboardMonth: action.data,
      };
    }

    case 'MY_LEADERBOARD_WEEK_POS': {
      return {
        ...state,
        myLeaderboardWeek: action.data,
      };
    }

    case 'MY_LEADERBOARD_DAILY_POS': {
      return {
        ...state,
        myLeaderboardDaily: action.data,
      };
    }

    case 'LANDING_LEADERBOARD_FILTERED_REPLACE': {
      if (action.data) {
        const arr = Object.keys(action.data).map(key => ({
        //  position: action.data[key].pos,
          points: action.data[key]?.totalPnts || 0,
          gamesPlayed: action.data[key]?.gPlayed || 0,
          name: action.data[key]?.name || '',
          balance: action.data[key]?.bal || 0,
          lvl: action.data[key]?.lvl || 1,
          rating: action.data[key]?.ratings || 0,
          gWon: action.data[key]?.gWon || 0,
          bgWon: action.data[key]?.bgWon || 0,
          sgWon: action.data[key]?.sgWon || 0,
          achvCnt: action.data[key]?.achvCnt || 0,
          maxRndInGame: action.data[key]?.maxRndInGame || 0,
          tblsWon: action.data[key]?.tblsWon || 0,
          photo: action.data[key]?.photo || '',
          key,
        })) || [];
        // console.log(action.data,action.filter,constants.LEADERBOARD_FILTER_TYPES.totalPnts, "landing leaderboard data");

        //  arr.sort((a, b) => a.position - b.position);

        if (action.filter === constants.LEADERBOARD_FILTER_TYPES.totalPnts) {
          arr.sort((a, b) => {
            const n = b.points - a.points;
            if (n !== 0) {
              return n;
            }

            return b.balance - a.balance;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.bal) {
          arr.sort((a, b) => {
            const n = b.balance - a.balance;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.gPlayed) {
          arr.sort((a, b) => {
            const n = b.gamesPlayed - a.gamesPlayed;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.bigGamesWon) {
          arr.sort((a, b) => {
            const n = b.bigGamesWon - a.bigGamesWon;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.smallGamesWon) {
          arr.sort((a, b) => {
            const n = b.smallGamesWon - a.smallGamesWon;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) {
          arr.sort((a, b) => {
            const n = b.maxRndInGame - a.maxRndInGame;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.tblsWon) {
          arr.sort((a, b) => {
            const n = b.tblsWon - a.tblsWon;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        }

        console.log('topData LEADERBOARD_FILTERED_REPLACE', arr);

        // const type = constants.LEADERBOARD_RESULT_TYPES.landingLeaderboard;
        return {
          ...state,
          landingLeaderboard: arr,
        };
      }
      return state;
    }

    case 'LEADERBOARD_FILTERED_REPLACE': {
      console.log('LEADERBOARD_FILTERED_REPLACE', action);

      const { userCount, reverseOrdering } = action;

      if (action.data) {
        if (reverseOrdering) {

        }

        const arr = Object.keys(action.data).map((key, index) => ({
        //  position: action.data[key].pos,
          points: action.data[key]?.totalPnts || 0,
          gamesPlayed: action.data[key]?.gPlayed || 0,
          name: action.data[key]?.name || '',
          balance: action.data[key]?.bal || 0,
          lvl: action.data[key]?.lvl || 1,
          rating: action.data[key]?.ratings || 0,
          gWon: action.data[key]?.gWon || 0,
          bgWon: action.data[key]?.bgWon || 0,
          sgWon: action.data[key]?.sgWon || 0,
          achvCnt: action.data[key]?.achvCnt || 0,
          maxRndInGame: action.data[key]?.maxRndInGame || 0,
          tblsWon: action.data[key]?.tblsWon || 0,
          photo: action.data[key]?.photo || '',
          key,
          userCount,
          balPos: action.data[key]?.balPos,
          gPlayedPos: action.data[key]?.gPlayedPos,
          pointsPos: action.data[key]?.pointsPos,
          //  reversePos: userCount - index,
        })) || [];

        console.log('arr', arr);

        //  arr.sort((a, b) => a.position - b.position);

        if (action.filter === constants.LEADERBOARD_FILTER_TYPES.totalPnts) {
          arr.sort((a, b) => {
            const n = b.points - a.points;
            if (n !== 0) {
              return n;
            }

            return b.balance - a.balance;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.bal) {
          arr.sort((a, b) => {
            const n = b.balance - a.balance;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.gPlayed) {
          arr.sort((a, b) => {
            const n = b.gamesPlayed - a.gamesPlayed;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.gWon) {
          arr.sort((a, b) => {
            const n = b.gWon - a.gWon;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.bigGamesWon) {
          arr.sort((a, b) => {
            const n = b.bgWon - a.bgWon;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.smallGamesWon) {
          arr.sort((a, b) => {
            const n = b.sgWon - a.sgWon;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) {
          arr.sort((a, b) => {
            const n = b.maxRndInGame - a.maxRndInGame;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.tblsWon) {
          arr.sort((a, b) => {
            const n = b.tblsWon - a.tblsWon;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        }

        console.log('topData LEADERBOARD_FILTERED_REPLACE', arr);

        let type;
        let myDataType;
        if (!action.millionairesOpen) {
          type = constants.LEADERBOARD_RESULT_TYPES.leaderboard;
          myDataType = constants.LEADERBOARD_RESULT_TYPES.myLeaderboard;
        } else {
          type = constants.LEADERBOARD_RESULT_TYPES.leaderboardMillionaires;
          myDataType = constants.LEADERBOARD_RESULT_TYPES.myLeaderboard;
        }
        return {
          ...state,
          [type]: arr,
          [myDataType]: action.myData,
          leaderboardLoading: action.isLoading,
        };
      }
      return state;
    }

    case 'LEADERBOARD_SEARCH_REPLACE': {
      console.log('LEADERBOARD_SEARCH_REPLACE', action);

      if (action.data) {
        const arr = [];

        Object.keys(action.data).map((key) => {
          console.log('action.data key', key);
          if (action.data[key].pointsPos && action.data[key].gPlayedPos && action.data[key].balPos) {
            let searchPos = action.data[key].pointsPos;

            if (action.filter === 'bal') {
              searchPos = action.data[key].balPos;
            } else if (action.filter === 'gPlayed') {
              searchPos = action.data[key].gPlayedPos;
            }

            console.log('LEADERBOARD_SEARCH_REPLACE searchPos', searchPos);

            arr.push({
              pointsPos: action.data[key]?.pointsPos,
              balPos: action.data[key]?.balPos,
              gPlayedPos: action.data[key]?.gPlayedPos,
              points: action.data[key]?.totalPnts || 0,
              gamesPlayed: action.data[key]?.gPlayed || 0,
              name: action.data[key]?.name,
              balance: action.data[key]?.bal || 0,
              lvl: action.data[key]?.lvl || 1,
              rating: action.data[key]?.ratings || 0,
              gWon: action.data[key]?.gWon || 0,
              bgWon: action.data[key]?.bgWon || 0,
              sgWon: action.data[key]?.sgWon || 0,
              achvCnt: action.data[key]?.achvCnt || 0,
              maxRndInGame: action.data[key]?.maxRndInGame || 0,
              tblsWon: action.data[key]?.tblsWon || 0,
              photo: action.data[key]?.photo || '',
              key,
              searchPos: searchPos || null,
            });
          }

          return null;
        });

        console.log('constants.LEADERBOARD_FILTER_TYPES', constants.LEADERBOARD_FILTER_TYPES, arr);

        if (action.filter === constants.LEADERBOARD_FILTER_TYPES.totalPnts) {
          arr.sort((a, b) => {
            const n = b.points - a.points;
            if (n !== 0) {
              return n;
            }

            return b.balance - a.balance;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.bal) {
          arr.sort((a, b) => {
            const n = b.balance - a.balance;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.gPlayed) {
          arr.sort((a, b) => {
            const n = b.gamesPlayed - a.gamesPlayed;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.gWon) {
          arr.sort((a, b) => {
            const n = b.gWon - a.gWon;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.bigGamesWon) {
          arr.sort((a, b) => {
            const n = b.bgWon - a.bgWon;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.smallGamesWon) {
          arr.sort((a, b) => {
            const n = b.sgWon - a.sgWon;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) {
          arr.sort((a, b) => {
            const n = b.maxRndInGame - a.maxRndInGame;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        } else if (action.filter === constants.LEADERBOARD_FILTER_TYPES.tblsWon) {
          arr.sort((a, b) => {
            const n = b.tblsWon - a.tblsWon;
            if (n !== 0) {
              return n;
            }

            return b.points - a.points;
          });
        }

        let type;
        let myDataType;

        if (!action.millionairesOpen) {
          type = constants.LEADERBOARD_RESULT_TYPES.leaderboardSearch;
          myDataType = constants.LEADERBOARD_RESULT_TYPES.myLeaderboardSearch;
        } else {
          type = constants.LEADERBOARD_RESULT_TYPES.leaderboardMillionairesSearch;
          myDataType = constants.LEADERBOARD_RESULT_TYPES.myLeaderboardSearch;
        }

        console.log('LEADERBOARD_SEARCH_REPLACE arr', arr);

        return {
          ...state,
          [type]: arr.slice(0, constants.TOP_RANGE.limit),
          [myDataType]: action.myData,
          leaderboardLoading: action.isLoading,
        };
      }
      return state;
    }

    case 'LEADERBOARD_MILLIONAIRES_REPLACE': {
      if (action.data) {
        const arr = Object.keys(action.data).map(key => ({
        //  position: action.data[key].pos,
          points: action.data[key].totalPnts,
          gamesPlayed: action.data[key].gPlayed,
          name: action.data[key].name,
          balance: action.data[key].bal,
          lvl: action.data[key].lvl,
          key,
        })) || [];

        //  arr.sort((a, b) => a.position - b.position);

        arr.sort((a, b) => {
          const n = b.balance - a.balance;
          if (n !== 0) {
            return n;
          }

          return b.points - a.points;
        });

        return {
          ...state,
          leaderboardMillionaires: arr,
        };
      }
      return state;
    }

    case 'POWER_RANKINGS_GET_DATA': {
      return {
        ...state,
        rankings: action.data,
        isLoading: action.isLoading,
      };
    }

    default:
      return state;
  }
}
