import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
// import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Badge, Col, Row, Media, Progress, Button } from 'reactstrap';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import NavLink from 'reactstrap/lib/NavLink';
import { size, map, filter } from 'lodash';
import * as constants from '../../../../../../constants/constants';
import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';
import { Firebase } from '../../../../../../lib/firebase';

import PlayerType from './PlayerType';

// import gPlayedImg from '../../../../../../images/icons/games_played.svg';
// import leaderboardImg from '../../../../../../images/icons/leaderboard.svg';
// import gWonImg from '../../../../../../images/icons/wins.svg';
// import giftsImg from '../../../../../../images/icons/gift.svg';
// import infoImg from '../../../../../../images/icons/info_chats.svg';
import closeImg from '../../../../../../images/redesign/components/modal/close.png';
import lightCloseImg from '../../../../../../images/redesign/light-mode/components/modal/close.png';
import coin from '../../../../../../images/redesign/common/coin.svg';
import rating from '../../../../../../images/redesign/common/rating.svg';
import defaultImage from '../../../../../../images/redesign/common/default_image.svg';
// import lightDefaultImage from '../../../../../../images/redesign/light-mode/common/default_image.svg';
import { countDigits } from '../../../../../../utils/numUtils';

import {
  getFriends,
  addFriend,
  removeFriend,
} from '../../../../../../actions/member';

class PlayerModalRight extends React.Component {
  static propTypes = {
    playerInfo: PropTypes.shape(),
    gifts: PropTypes.shape(),
    closePlayerModal: PropTypes.func.isRequired,
    posClassName: PropTypes.string,
    modalOpen: PropTypes.bool,
    photo: PropTypes.string,
    t: PropTypes.func.isRequired,
    screenMode: PropTypes.string,
    member: PropTypes.shape(),
    position: PropTypes.string,
    mId: PropTypes.string,
    uid: PropTypes.string,
    addAFriend: PropTypes.func.isRequired,
    removeAFriend: PropTypes.func.isRequired,
    friends: PropTypes.shape(),
    fetchFriends: PropTypes.func.isRequired,
    socProvider: PropTypes.string,
  };

  static defaultProps = {
    playerInfo: null,
    gifts: null,
    posClassName: null,
    modalOpen: false,
    photo: '',
    screenMode: constants.SCREEN_MODE.normal,
    member: null,
    position: null,
    mId: '',
    uid: '',
    friends: {},
    socProvider: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: constants.PLYERINFO_NEW_TABS.statistics,
      devMode: false,
    };
  }

  componentDidMount() {
    const {
      fetchFriends, socProvider,
    } = this.props;
    const { hostname } = window.location;

    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }
    this.setState({ devMode });

    if (socProvider === 'facebook') {
      Firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          window.FB.getLoginStatus((response) => {
            if (response.status === 'connected') {
              const { accessToken } = response.authResponse;

              window.FB.api(
                `/${user.providerData[0].uid}/friends`,
                'GET',
                { access_token: accessToken },
                (resp) => {
                  //  this.setState({ friends: resp.data });

                  console.log(resp);
                  if (resp && resp.data) {
                    fetchFriends(resp).then((res) => {
                      console.log(res);
                    });
                  }
                },
              );
            }
          });
        } else {
          //  console.log('no user');
        }
      });
    } else if (socProvider === 'draugiem') {

      fetchFriends(devMode);
    } else {
      fetchFriends();
    }
  }

  playerInfoTab = (tab) => {

    const { activeTab } = this.state;
    console.log(tab, "infoModal")
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  generateImageUrl = (key, borders, index) => {
    let img;
    let imgkey;
    imgkey = key;
    if (key === 'highestEarned') {
      imgkey = 'balance';
    }
    if (size(borders) > 1) {
      if (borders[index + 1]) {
        // eslint-disable-next-line
        img = require(`../../../../../../images/Ach/${imgkey}_${borders[index]
          }_${borders[index + 1]}.png`);
      } else {
        // eslint-disable-next-line
        img = require(`../../../../../../images/Ach/${imgkey}_${borders[index]}.png`);
      }
    } else {
      if (constants.BIG_SIZE_ACH.includes(imgkey)) {
        img = require(`../../../../../../images/Ach/${imgkey}_1.svg`);
      } else {
        img = require(`../../../../../../images/Ach/${imgkey}_1.png`);
      }
    }
   
    // console.log("generatge image key", key, borders, index, img);
    return img;
  }

  generateImageUrlData = (key, borders, index, isBottomImg) => {
    const { t, i18n } = this.props;
    let name = '';
    let firstName = '';
    let thirdName = '';
    firstName = t(constants.ACHIEVEMENT_DATA[key]?.firstName) ?? '';
    name = t(constants.ACHIEVEMENT_DATA[key]?.name) ?? '';
    thirdName = t(constants.ACHIEVEMENT_DATA[key]?.thirdName ?? name);
    const noCounter = [
      'gamesPlayed',
      'gamesWon',
      'fastGamesPlayed',
      'lightningGamesPlayed',
      'unlimitedGamesPlayed',
      'maxDailyLogin',
      'reachedTournamentTop10',
      'fastGamesPlayed',
    ];

    let img;
    let imgKey = key;
    if (key === 'highestEarned') {
      imgKey = 'balance';
    }

    if (size(borders) > 1) {
      if (borders[index + 1]) {
        // eslint-disable-next-line
        img = require(`../../../../../../images/Ach/${imgKey}_${borders[index]}_${borders[index + 1]
        }.png`);
      } else {
        // eslint-disable-next-line
        img = require(`../../../../../../images/Ach/${imgKey}_${borders[index]}.png`);
      }
    } else {
      if (constants.BIG_SIZE_ACH.includes(imgKey)) {
        img = require(`../../../../../../images/Ach/${imgKey}_1.svg`);
      } else {
        img = require(`../../../../../../images/Ach/${imgKey}_1.png`);
      }
      // eslint-disable-next-line import/no-dynamic-require
    }
    
    const val = borders[index] >= 1000 ? `${borders[index] / 1000}k` : borders[index];
    if (
      val === constants.ACHIEVEMENT_THIRD_VALUE
      && i18n.language === constants.sendLanguage.ru
    ) {
      name = thirdName || name;
    } else if (
      val === constants.ACHIEVEMENT_SECOND_VALUE
      && key === 'storePurchase'
    ) {
      if (i18n.language === constants.sendLanguage.ru) {
        name = thirdName || name;
      }
    } else if (
      val === constants.ACHIEVEMENT_SECOND_VALUE
      && key === 'supportMessagesSent'
    ) {
      if (i18n.language === constants.sendLanguage.ru) {
        name = thirdName || name;
      }
    }
    
    return (
      <>
        <div
          className={`${isBottomImg ? "giftsHistory-new-bottom" : "giftsHistory-new"} d-flex justify-content-center`}
          style={{alignItems: 'end'}}
        >
          <img
            className="h-100"
            src={img}
            alt="X"
            id={`achievements-${key}-${borders[index]}${isBottomImg ? '-bottom' : ''}`}
          />
        </div>

        <UncontrolledTooltip
          className="giftsHistory-tooltip player-receivedGift-tooltip-new player-receivedGift-tooltip-new-player-modal-right"
          placement="bottom"
          target={`achievements-${key}-${borders[index]}${isBottomImg ? '-bottom' : ''}`}
          container={'div > div'}
        >
          <div className="giftsHistory-tooltip-from">
            {key === 'fastGamesPlayed'
              || key === 'gamesPlayed'
              || key === 'lightningGamesPlayed'
              || key === 'unlimitedGamesPlayed'
              || key === 'maxDailyLogin'
              || key === 'reachedTournamentTop10'
              || key === 'gamesWon' ? (
                index === 0 && firstName ? (
                  <span>{`${firstName}!`}</span>
                ) : (
                  <span>
                    {`${noCounter.includes(key) ? val : ''} ${index === 0 && firstName ? firstName : name
                    }!`}
                  </span>
                )
              ) : (
              // eslint-disable-next-line max-len
                <span>
                  {`${noCounter.includes(key) ? '' : val} ${index === 0 && firstName
                    ? key === 'maxSuccessionWins'
                      && i18n.language === constants.sendLanguage.ru
                      ? t('3maxSuccessionWins')
                      : firstName
                    : key === 'earnedInADay'
                      && (i18n.language === constants.sendLanguage.ru
                      || i18n.language === constants.sendLanguage.en)
                      ? name.toLocaleLowerCase()
                      : name
                  }!`}
                </span>
              )}
          </div>
        </UncontrolledTooltip>
      </>
    );
  };

  closePlayerModalFunc = () => {
    const { closePlayerModal } = this.props;

    closePlayerModal();
  }

  changeDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // add 1 to month as it starts from 0
    const year = date.getFullYear();
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  }

  handleSendAddFriend = (selectId) => {
    const { addAFriend, fetchFriends } = this.props;
    addAFriend(selectId).then((res) => {
      if (res && res.status === constants.FROUNT_SIDE_STATUS.success) {
        fetchFriends();
        console.log("sent success")
      } else {
        console.log("sent error")
      }
    });
  }

  handleRemoveFriend = (selectId) => {
    const { removeAFriend, fetchFriends } = this.props;
    removeAFriend(selectId).then((res) => {
      if (res && res.status === constants.FROUNT_SIDE_STATUS.success) {
        fetchFriends();
        console.log("sent success")
      } else {
        console.log("sent error")
      }
    });
  }

  getCirclePaddingRight = (numberInCircle) => {
    const digits = countDigits(numberInCircle);
    if (digits === 1) {
      return 10;
    } else if (digits === 2) {
      return 5;
    } else if (digits === 3) {
      return 1;
    }
    return 0;
  }

  

  render() {
    const { mId, uid, position, playerInfo, gifts, closePlayerModal, posClassName, modalOpen, photo, t, screenMode, member, largePlayer, currentType, sittingOut, gameState, friends } = this.props;
    const { activeTab } = this.state;

    if (!modalOpen) {
      return null;
    }

    let totalAch = 0;
    if (playerInfo && playerInfo.userAchievements) {
      map(playerInfo.userAchievements, (item, key) => {
        console.log(item, key, constants.ACHIEVEMENT_DATA[key], "debug");
        if (size(constants.UNIQUE_ACHIEVEMENT_DATA[key]) > 0 && item) {
          totalAch += 1;
        } else if (size(constants.ACHIEVEMENT_DATA[key]) > 0) {
          map(constants.ACHIEVEMENT_DATA[key].borders, (border, idx) => {
            console.log(border, item, "achievementCheck")
            if (border <= item) {
              totalAch += 1;
            }
          });
        }
        // if (constants.achievementsData[key]) {
        //   if (size(constants.achievementsData[key].borders) > 1) {
        //     map(constants.achievementsData[key].borders, (border, idx) => {
        //       if (border < item) {
        //         totalAch += 1;
        //       }
        //     });
        //     // const filterBorder = constants.achievementsData[key].borders.filter(idx => console.log(constants.achievementsData[key].borders[idx], item,));
        //     // totalAch += size(filterBorder);
        //   } else {
        //     totalAch += 1;
        //   }
        // }
      });
    }
    // console.log(totalAch, "total Ach");
    // console.log(playerInfo, "check playerInfo");
    console.log(largePlayer,playerInfo, sittingOut, "largePlayerlargePlayer")
    return (
      <div style={{zIndex: 999999999999999999, position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh'}}>
        {/* <div className="player-info-new-modal-backdrop" /> */}
        <div className="player-info-new-modal-backdrop" onClick={this.closePlayerModalFunc} />
        <div className={`player-info-new-modal player-info-new-modal-${posClassName}`} backdropClassName="player-info-modal-backdrop">
          <div className="player-info-new-header">
            <Media src={screenMode === constants.SCREEN_MODE.light || screenMode === constants.SCREEN_MODE.normal ? lightCloseImg : closeImg} alt="X" className="player-info-new-header-close" onClick={this.closePlayerModalFunc} />
            <div className="player-info-new-header-title-wrapper">
              <div className="player-info-new-player">
                <div className="player-info-new-player-background">
                  <div className="player-info-new-player-image-wrapper">
                    <div className="player-info-new-player-image-background" />
                    <div style={{ backgroundImage: photo ? `url(${photo})` : `url(${defaultImage})` }} className="player-info-new-player-image" />
                    <div className="player-info-new-player-image-overlay" />
                  </div>
                  <div className="player-info-new-player-name-wrapper">
                    {(largePlayer) ? (
                      <PlayerType
                        t={t}
                        currentType={currentType}
                        sittingOut={sittingOut && position === sittingOut}
                        gameState={gameState}
                        largePlayer={!!(largePlayer && position === largePlayer)}
                      />
                    ) : (
                      <Fragment>
                        {(sittingOut && playerInfo.position === sittingOut) ? (
                          <div className="player-type-wrapper">
                            <div className="player-type-mazais">{t('dealer')}</div>
                          </div>
                        ) : (
                          <div className="player-type-none" />
                        )}
                      </Fragment>
                    )}
                    <div className="player-info-new-player-name">
                      {playerInfo?.name || ''}
                    </div>
                    {/* {
                      mId && mId !== uid && (
                        <div className="player-info-new-player-name-wrapper-friend">
                          {
                            size(friends) > 0 && size(filter(friends, (item, key) => { return item.uid === uid; })) > 0 ? (
                              <>
                                <div style={{position: 'absolute', top: 50, left: 0}}>
                                  <Badge color="success">{t('friend')}</Badge>
                                </div>
                                <Button className='layout-default-button layout-float-right btn btn-link' onClick={() => this.handleRemoveFriend(uid)}>
                                  {t('common:top.removeFriend')}
                                </Button>
                              </>
                            ) : (
                              <Button className='layout-default-button layout-float-right btn btn-link' onClick={() => this.handleSendAddFriend(uid)}>
                                {t('addAsFriend')}
                              </Button>
                            )
                          }
                        </div>
                      )
                    } */}
                  </div>
                  {/* {(largePlayer) ? (
                    <PlayerType
                      t={t}
                      currentType={currentType}
                      sittingOut={sittingOut && playerInfo.position === sittingOut}
                      gameState={gameState}
                      largePlayer={!!(largePlayer && playerInfo.position === largePlayer)}
                    />
                  ) : (
                    <Fragment>
                        {(sittingOut && playerInfo.position === sittingOut) ? (
                        <div className="player-type-wrapper">
                          <div className="player-type-mazais">{t('dealer')}</div>
                        </div>
                      ) : (
                        <div className="player-type-none" />
                      )}
                    </Fragment>
                  )} */}
                  <div className="player-info-new-player-wrapper">
                    <Media src={coin} className="player-info-new-player-balance-icon mr-2" />
                    <div className="player-info-new-player-balance">
                      {playerInfo?.bal || 0}
                    </div>
                    <Media src={rating} className="player-info-new-player-rating-icon" />
                    <div className="player-info-new-player-rating">
                      {playerInfo?.rating || 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="player-info-new-player-reg-date text-right">
              <div className="d-block">{t('common:myInfo.registered')}:</div>
              <div className="d-block">{`${playerInfo && playerInfo?.dateCreated ? this.changeDateFormat(playerInfo.dateCreated) : t('common:myInfo.oldRegisteredDate')}`}</div>
            </div>
            {
              mId && mId !== uid && (
                <div className="player-info-new-player-name-wrapper-friend">
                  {
                    size(friends) > 0 && size(filter(friends, (item, key) => { return item.uid === uid; })) > 0 ? (
                      <>
                        <div style={{position: 'absolute', top: 50, right: 0}}>
                          <Badge color="success">{t('friend')}</Badge>
                        </div>
                        <Button className='layout-default-button layout-float-right btn btn-link' onClick={() => this.handleRemoveFriend(uid)}>
                          {t('common:top.removeFriend')}
                        </Button>
                      </>
                    ) : (
                      <Button className='layout-default-button layout-float-right btn btn-link' onClick={() => this.handleSendAddFriend(uid)}>
                        {t('addAsFriend')}
                      </Button>
                    )
                  }
                </div>
              )
            }
          </div>
          <div className="player-info-new-body">
            <div className="player-info-new-body-content">
              <Col sm="12">
                <div className="player-info-new-row player-info-new-tabs">
                  <div className="player-info-new-tab-link">
                    <NavLink
                      className={classNames('player-info-new-tab-link-text cursor-pointer', {
                        [`player-info-new-tab-link-text-active-${screenMode}`]: activeTab === constants.PLYERINFO_NEW_TABS.statistics
                      })}
                      onClick={() => this.playerInfoTab(constants.PLYERINFO_NEW_TABS.statistics)}
                    >
                      {t('statistics')}
                    </NavLink>
                  </div>
                  <div className="player-info-new-tab-link">
                    <NavLink
                      className={classNames('player-info-new-tab-link-text player-info-new-tab-link-text-trophy cursor-pointer', {
                        [`player-info-new-tab-link-text-active-${screenMode}`]: activeTab === constants.PLYERINFO_NEW_TABS.trophies
                      })}
                      onClick={() => this.playerInfoTab(constants.PLYERINFO_NEW_TABS.trophies)}
                    >
                      {t('trophies')}<label className="player-info-new-tab-link-text-trophy-bage">{totalAch}</label>
                    </NavLink>
                  </div>
                  <div className="player-info-new-tab-link">
                    <NavLink
                      className={classNames('player-info-new-tab-link-text player-info-new-tab-link-text-gifts cursor-pointer', {
                        [`player-info-new-tab-link-text-active-${screenMode}`]: activeTab === constants.PLYERINFO_NEW_TABS.gifts
                      })}
                      onClick={() => this.playerInfoTab(constants.PLYERINFO_NEW_TABS.gifts)}
                    >
                      {t('gifts')}<label className="player-info-new-tab-link-text-gifts-bage">{size(playerInfo?.giftsHistory || {})}</label>
                    </NavLink>
                  </div>
                </div>
                {
                  activeTab === constants.PLYERINFO_NEW_TABS.statistics ? (
                    <>
                      <div className="player-info-new-row player-info-new-statistics">
                        <Col xs="4">
                          <div className={`player-info-new-statistics-points-${screenMode}`}>
                            {t('ratingPoints')}: <label>{playerInfo?.rating || 0}</label>
                          </div>
                          <div className="player-info-new-statistics-progress">
                            <Progress
                              color="link"
                              value={playerInfo?.rating ? playerInfo.rating / constants.PLYERINFO_NEW_PERCENTRATE.percent : 0}
                              className="my-info-ratings-progressbar"
                            />
                          </div>
                          <div className="player-info-new-statistics-percent">
                            {t('betterThan')}: <label className="mr-1">{playerInfo?.rating ? playerInfo.rating / constants.PLYERINFO_NEW_PERCENTRATE.percent : 0}%</label> {t('common:menu.players')}
                          </div>
                        </Col>
                        <Col xs="4">
                          <div className={`player-info-new-statistics-points-${screenMode}`}>
                            {t('gPlayed')}: <label>{playerInfo?.gPlayed || 0}</label>
                          </div>
                          <div className="player-info-new-statistics-progress">
                            <Progress
                              color="link"
                              value={playerInfo?.betterGPlayed || 0}
                              className="my-info-ratings-progressbar"
                            />
                          </div>
                          <div className="player-info-new-statistics-percent">
                            {t('betterThan')}: <label className="mr-1">{playerInfo?.betterGPlayed || 0}%</label> {t('common:menu.players')}
                          </div>
                        </Col>
                        <Col xs="4">
                          <div className={`player-info-new-statistics-points-${screenMode}`}>
                            {t('largeZole')}: <label>{playerInfo?.bgWon || 0}</label>
                          </div>
                          <div className="player-info-new-statistics-progress">
                            <Progress
                              color="link"
                              value={playerInfo?.betterBGWon || 0}
                              className="my-info-ratings-progressbar"
                            />
                          </div>
                          <div className="player-info-new-statistics-percent">
                            {t('betterThan')}: <label className="mr-1">{playerInfo?.betterBGWon || 0}% </label>{t('common:menu.players')}
                          </div>
                        </Col>
                      </div>
                      <div className="player-info-new-row player-info-new-statistics">
                        <Col xs="4">
                          <div className={`player-info-new-statistics-points-${screenMode}`}>
                            {t('smallZole')}: <label>{playerInfo?.sgWon || 0}</label>
                          </div>
                          <div className="player-info-new-statistics-progress">
                            <Progress
                              color="link"
                              value={playerInfo?.betterSGWon || 0}
                              className="my-info-ratings-progressbar"
                            />
                          </div>
                          <div className="player-info-new-statistics-percent">
                            {t('betterThan')}: <label className="mr-1">{playerInfo?.betterSGWon || 0}%</label> {t('common:menu.players')}
                          </div>
                        </Col>
                        <Col xs="4">
                          <div className={`player-info-new-statistics-points-${screenMode}`}>
                            {t('winningTables')}: <label>{playerInfo?.tblsWon || 0}</label>
                          </div>
                          <div className="player-info-new-statistics-progress">
                            <Progress
                              color="link"
                              value={playerInfo?.betterTblsWon || 0}
                              className="my-info-ratings-progressbar"
                            />
                          </div>
                          <div className="player-info-new-statistics-percent">
                            {t('betterThan')}: <label className="mr-1">{playerInfo?.betterTblsWon || 0}%</label> {t('common:menu.players')}
                          </div>
                        </Col>
                        <Col xs="4">
                          <div className={`player-info-new-statistics-points-${screenMode}`}>
                            {t('archievements')}: <label>{playerInfo?.achvCnt || 0}</label>
                          </div>
                          <div className="player-info-new-statistics-progress">
                            <Progress
                              color="link"
                              value={playerInfo?.betterAchvCnt || 0}
                              className="my-info-ratings-progressbar"
                            />
                          </div>
                          <div className="player-info-new-statistics-percent">
                            {t('betterThan')}: <label className="mr-1">{playerInfo?.betterAchvCnt || 0}% </label>{t('common:menu.players')}
                          </div>
                        </Col>
                      </div>
                      <div className="player-info-new-row player-info-new-statistics">
                        <Col xs="4">
                          <div className={`player-info-new-statistics-points-${screenMode}`}>
                            {t('maxNumberOfPartiesInRoom')}: <label>{playerInfo?.maxRndInGame || 0}</label>
                          </div>
                          <div className="player-info-new-statistics-progress">
                            <Progress
                              color="link"
                              value={playerInfo?.betterMaxRndInGame || 0}
                              className="my-info-ratings-progressbar"
                            />
                          </div>
                          <div className="player-info-new-statistics-percent">
                            {t('betterThan')}: <label className="mr-1">{playerInfo?.betterMaxRndInGame || 0}%</label> {t('common:menu.players')}
                          </div>
                        </Col>
                        <Col xs="4">
                          <div className={`player-info-new-statistics-points-${screenMode}`}>
                            {t('averageNumberOfPartiesPerRoom')}: <label>{playerInfo?.averageNumberOfPartiesPerRoom || 0}</label>
                          </div>
                          <div className="player-info-new-statistics-progress">
                            <Progress
                              color="link"
                              value={playerInfo?.averageNumberOfPartiesPerRoom ? playerInfo.averageNumberOfPartiesPerRoom / constants.PLYERINFO_NEW_PERCENTRATE.percent : 0}
                              className="my-info-ratings-progressbar"
                            />
                          </div>
                          <div className="player-info-new-statistics-percent">
                            {t('betterThan')}: <label className="mr-1">{playerInfo?.averageNumberOfPartiesPerRoom ? playerInfo.averageNumberOfPartiesPerRoom / constants.PLYERINFO_NEW_PERCENTRATE.percent : 0}%</label> {t('common:menu.players')}
                          </div>
                        </Col>
                        <Col xs="4">
                          <div className={`player-info-new-statistics-points-${screenMode}`}>
                            {t('howManyRoomsPlayedWithThisPlayer')}: <label>{playerInfo?.howManyRoomsPlayedWithThisPlayer || 0}</label>
                          </div>
                          <div className="player-info-new-statistics-progress">
                            <Progress
                              color="link"
                              value={playerInfo?.howManyRoomsPlayedWithThisPlayer ? playerInfo.howManyRoomsPlayedWithThisPlayer / constants.PLYERINFO_NEW_PERCENTRATE.percent : 0}
                              className="my-info-ratings-progressbar"
                            />
                          </div>
                          <div className="player-info-new-statistics-percent">
                            {t('betterThan')}: <label className="mr-1">{playerInfo?.howManyRoomsPlayedWithThisPlayer ? playerInfo.howManyRoomsPlayedWithThisPlayer / constants.PLYERINFO_NEW_PERCENTRATE.percent : 0}% </label>{t('common:menu.players')}
                          </div>
                        </Col>
                      </div>
                    </>
                  ) : activeTab === constants.PLYERINFO_NEW_TABS.trophies ? (
                    <div className="giftsHistory-tab-new-row">
                      <ScrollAreaWrapper
                        className="giftsHistory-tab-new-row-scrollarea"
                        show
                        rightOffset={0}
                        topOffset={0}
                        bottomOffset={0}
                      >
                        <Row>
                            {(playerInfo && playerInfo.userAchievements) && map(playerInfo.userAchievements, (item, key) => ( // regular achievements render
                            constants.achievementsData[key] && (
                              // findAchievements(key, item, constants.achievementsData[key].borders)
                              (size(constants.achievementsData[key].borders) > 1) ? (
                                map(constants.achievementsData[key].borders, (border, index) => (
                                  (border <= item) && (
                                    <Col xs="3">
                                      {this.generateImageUrlData(
                                        key,
                                        constants.achievementsData[key].borders,
                                        index
                                      )}
                                    </Col>
                                  )
                                ))
                              ) : (null)
                            )
                          ))}
                        </Row>
                        <Row>
                            {(playerInfo && playerInfo.userAchievements) && map(playerInfo.userAchievements, (item, key) => ( // unique achievements render
                            constants.achievementsData[key] && (
                              // findAchievements(key, item, constants.achievementsData[key].borders)
                              (!(size(constants.achievementsData[key].borders) > 1)) ? (
                                <Col xs="3">
                                  {/* {playerInfo.giftsHistory[key] && ( */}
                                  <img
                                    // id={`giftsHistory-${key}`}
                                    className="giftsHistory-new"
                                    src={this.generateImageUrl(key, constants.achievementsData[key].borders)}
                                    alt="X"
                                    id={`achievements-unique-${key}`}
                                  />
                                  <UncontrolledTooltip
                                    className="giftsHistory-tooltip player-receivedGift-tooltip-new player-receivedGift-tooltip-new-player-modal-right"
                                    placement="bottom"
                                    target={`achievements-unique-${key}`}
                                    container={'div > div'}
                                  >
                                    {t(key)}
                                  </UncontrolledTooltip>
                                  {/* )} */}
                                </Col>
                              ) : (null)
                            )
                          ))}
                        </Row>
                      </ScrollAreaWrapper>
                    </div>
                  ) : (
                    <div className="giftsHistory-tab-new-row">
                      <ScrollAreaWrapper
                        className="giftsHistory-tab-new-row-scrollarea"
                        show
                        rightOffset={0}
                        topOffset={0}
                        bottomOffset={0}
                      >
                        <Row>
                          {(gifts && playerInfo && playerInfo.giftsHistory) && Object.keys(playerInfo.giftsHistory).map(key => (
                            <Col xs="2" key={`${key}`}>
                              {playerInfo.giftsHistory[key] && (
                                <div
                                  id={`giftsHistory-${key}`}
                                  className="giftsHistory-new-item"
                                  style={{ backgroundImage: `url(${gifts[playerInfo.giftsHistory[key].giftId] ? gifts[playerInfo.giftsHistory[key].giftId].image : ''})` }}
                                >
                                  <>
                                    <UncontrolledTooltip
                                      className="giftsHistory-tooltip player-receivedGift-tooltip-new player-receivedGift-tooltip-new-player-modal-right"
                                      placement="bottom"
                                      target={`giftsHistory-${key}`}
                                      container={'div > div'}
                                    >
                                      <div className="giftsHistory-tooltip-from">
                                        {playerInfo.giftsHistory[key].fromName || ''}
                                      </div>
                                      <div className="giftsHistory-tooltip-comment">
                                        {playerInfo.giftsHistory[key].comment || ''}
                                      </div>
                                    </UncontrolledTooltip>
                                  </>

                                </div>
                              )}
                            </Col>
                          ))}
                        </Row>
                      </ScrollAreaWrapper>
                    </div>
                  )
                }


                <div className="player-info-new-row player-info-new-statistics">
                  {/*  <Media src={giftsImg} className="player-info-new-img player-info-new-img-gifts" /> */}
                  <Col xs="6">
                    <div className="player-info-new-text">
                      {t('latestTrophies')}
                    </div>
                    <div className="giftsHistory-row">
                      <>
                        {(playerInfo && playerInfo.achievementsHistory) && map(playerInfo.achievementsHistory, (item, key) => (
                          <Col xs="3" sm="3">
                            {this.generateImageUrlData(item.achievementId, constants.achievementsData[item.achievementId].borders, item.newLevel - 1, true)}
                          </Col>
                        ))}

                        {
                          (playerInfo && playerInfo.achievementsHistory && Object.keys(playerInfo.achievementsHistory).length < totalAch) && (
                            <div className="giftsHistory-row-circle" style={{paddingRight: this.getCirclePaddingRight(totalAch - 3)}}>
                              {`+${totalAch - 3}`}
                            </div>
                          )
                        }
                      </>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="player-info-new-text">
                      {t('latestGifts')}
                    </div>
                    <div className="giftsHistory-row">
                      <>
                        {(gifts && playerInfo && playerInfo.giftsHistoryData) && Object.keys(playerInfo.giftsHistoryData).map(key => (
                          <Col key={`${key}`} xs="3" sm="3">
                            {playerInfo.giftsHistoryData[key] && (
                              <div
                                id={`giftsHistory-aaaa-${key}`}
                                className="giftsHistory"
                                style={{ backgroundImage: `url(${gifts[playerInfo.giftsHistoryData[key].giftId] ? gifts[playerInfo.giftsHistoryData[key].giftId].image : ''})` }}
                              >
                                <>
                                  <UncontrolledTooltip
                                    className="giftsHistory-tooltip player-receivedGift-tooltip-new player-receivedGift-tooltip-new-player-modal-right"
                                    placement="bottom"
                                    target={`giftsHistory-aaaa-${key}`}
                                    container={'div > div'}
                                  >
                                    <div className="giftsHistory-tooltip-from">
                                      {playerInfo.giftsHistoryData[key].fromName || ''}
                                    </div>
                                    <div className="giftsHistory-tooltip-comment">
                                      {playerInfo.giftsHistoryData[key].comment || ''}
                                    </div>
                                  </UncontrolledTooltip>
                                </>

                              </div>
                            )}
                          </Col>
                        ))}
                        {
                          (playerInfo && playerInfo.giftsHistory && size(playerInfo.giftsHistory) > 3) && (
                            <div className="giftsHistory-row-circle" style={{paddingRight: this.getCirclePaddingRight(totalAch - 3)}}>
                              {playerInfo.giftsHistory && size(playerInfo.giftsHistory) > 3 ? `+${size(playerInfo.giftsHistory) - 3}` : '--'}
                            </div>
                          )
                        }
                      </>
                    </div>
                  </Col>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </div>
    );
  }
}




const mapStateToProps = state => ({
  member: state.member || {},
  mId: state.member.uid,
  currentType: state.game.currentType,
  largePlayer: state.game.largePlayer,
  gameState: state.game.globalParams.gameState,
  sittingOut: state.game.sittingOut,
  friends: state.member.friends || {},
  socProvider: state.member.socProvider || null,
});

const mapDispatchToProps = {
  addAFriend: addFriend,
  removeAFriend: removeFriend,
  fetchFriends: getFriends,
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation(['playerInfo', 'common'])(PlayerModalRight)));
