export const generalMessages = {
  enterGiftCode: 'enterGiftCode',
  verifySuccessMessage: 'verifySuccessMessage',
  wrongCode: 'wrongCode',
  codeExpire: 'codeExpire',
  limitClaim: 'limitClaim',
  claimSuccessMessage: 'claimSuccessMessage',
  alreadyClaimed: 'alreadyClaimed',
  giftCodeLongFailed: 'giftCodeLongFailed',
};

export const successMessages = {};

export const errorMessages = {
  // Defaults
  default: 'Hmm, an unknown error occured',
  timeout: 'Server Timed Out. Check your internet connection',
  invalidJson: 'Response returned is not valid JSON',

  // Firebase Related
  invalidFirebase: 'Firebase is not connected correctly',

  // Member
  memberExists: 'memberExists',
  missingFirstName: 'missingFirstName',
  missingLastName: 'missingLastName',
  missingEmail: 'missingEmail',
  missingBirthday: 'missingBirthday',
  wrongEmail: 'wrongEmail',
  missingPassword: 'missingPassword',
  missingConfirmPassword: 'missingConfirmPassword',
  wrongPassword: 'wrongPassword',
  passwordsDontMatch: 'passwordsDontMatch',
  unverifiedEmail: 'unverifiedEmail',
  errorUpdating: 'errorUpdating',
  errorSendingEmailVerification: 'errorSendingEmailVerification',
  changePhoto: "If you don't apply or close changed avatar, you can't change your profile.",
  checkEmail: 'checkEmail',
  missingGiftCode: 'missingGiftCode',
  missingExpire: 'missingExpire',
  giftCodePatternFailed: 'giftCodePatternFailed',
  giftCodeLongFailed: 'giftCodeLongFailed',
  missingPlayerLimit: 'Missing Player count that can be used the code.',
  missingValue: 'Missing the amount of game coins which will be added upon activating it.',
  wrongEventDate: 'wrongEventDate',
  missingLVTitle: 'missingLVTitle',
  missingENTitle: 'missingENTitle',
  missingRUTitle: 'missingRUTitle',
  missingLVDescription: 'missingLVDescription',
  missingENDescription: 'missingENDescription',
  missingRUDescription: 'missingRUDescription',
  missingLocations: 'missingLocations',
  missingPlatforms: 'missingPlatforms',
  missingDesigns: 'missingDesigns',
  missingEnvs: 'missingEnvs',
  missingPhoto: 'missingPhoto',
  missingData: 'missingData',
  uploadFileImage: 'uploadFileImage',
  uploadImageClose: 'uploadImageClose',
  maxNameChangeLimit: 'maxNameChangeLimit',
  actionFailed: 'actionFailed',
  missingTitle: 'missingTitle',
  missingMessage: 'missingMessage',
  changedEmail: 'changedEmail',
  wrongDate: 'wrongDate',
  recoverSuccess: 'recoverSuccess',
  wrongPasswordServer: 'auth/wrong-password',
  useNotFoundServer: 'auth/user-not-found',
  enterGiftCode: 'enterGiftCode',
  giftCodeLongFailed: 'giftCodeLongFailed',
  verifySuccessMessage: 'verifySuccessMessage',
  wrongCode: 'wrongCode',
  codeExpire: 'codeExpire',
  limitClaim: 'limitClaim',
  claimSuccessMessage: 'claimSuccessMessage',
  alreadyClaimed: 'alreadyClaimed',
  pendingInviteEmail: 'pendingInviteEmail',
  sentPerDayMoney: 'sentPerDayMoney',
  sentPerDayMoneyLimit: 'sentPerDayMoneyLimit',
};
