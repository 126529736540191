import React from 'react';

import { map, filter } from 'lodash';
import { Label, Col } from 'reactstrap';
import * as constants from '../../../../../constants/constants';
// import starMark from '../../../../../images/icons/starMark.svg';
// import starUnMark from '../../../../../images/icons/starUnMark.svg';

import Star from './Star';

// Create an array of 5: Array.from({length: 5}, (v,i) => i)
const StarRating = ({ handleRating, rating, t, }) => {
  // Manages on Hover selection of a star
  const [selection, setSelection] = React.useState(0);

  // Manages rating selection
  // const [rating, setRating] = React.useState(0);

  const hoverOver = event => {
    let starId = 0;
    if (event && event.target && event.target.getAttribute("star-id")) {
      starId = event.target.getAttribute("star-id");
    }
    setSelection(starId);
  };
  return (
    <Col className="rating-stars-section" xs="12">
      <Col
        xs="6"
        className="rating-stars-point-section"
        onMouseOver={hoverOver}
        onMouseOut={() => hoverOver(null)}
        onClick={event => handleRating(event.target.getAttribute("star-id"))}
      >
        {
          map(constants.FEEDBACK_RATING, (item, key) => (
            <Star key={item.id} starId={item.id} marked={selection ? selection >= item.id : rating >= item.id} />
          ))
        }
      </Col>
      <Col xs="6" className="rating-stars-point-label">
        <Label>{rating > 0 && t(`common.${filter(constants.FEEDBACK_RATING, ['id', parseInt(rating, 10)])[0].label}`)}</Label>
      </Col>
    </Col>
  );
};

export default StarRating;
