import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';


import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import {
  sendChatMsg,
  readChat,
  stopReadChat,
  setChatAsRead,
  getEmotions,
  setEmotion,
  setProfanitiesNotification,
  getReportedMessagesInRoom,
} from '../../actions/room';

import closeImg from '../../images/icons/close.png';

import { toggleRoomChat } from '../../actions/userSettings';

import ChatComponent from '../../web/components/Game/Chat';

import {
  FONT_MODE, FORMAT_DATE_TYPE, FROUNT_SIDE_STATUS,
} from '../../constants/constants';
import DateFormat from '../../web/components/UI/DateFormat';

class Chat extends Component {
  static propTypes = {
    name: PropTypes.string,
    photo: PropTypes.string,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    emotions: PropTypes.shape(),
    roomChats: PropTypes.shape(),
    sendChatMessage: PropTypes.func.isRequired,
    setChatMessageAsRead: PropTypes.func.isRequired,
    readChatMessages: PropTypes.func.isRequired,
    stopReadChatMessages: PropTypes.func.isRequired,
    sendEmotion: PropTypes.func.isRequired,
    fetchEmotions: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    toggleRoomChatFunc: PropTypes.func.isRequired,
    supportChatStatus: PropTypes.shape(),
    setProfanitiesNotif: PropTypes.func.isRequired,
    toggleSupport: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    userSettings: PropTypes.shape(),
    roomId: PropTypes.string,
    uid: PropTypes.string,
    profanitiesList: PropTypes.shape(),
    profanitiesNotification: PropTypes.string,
    fontMode: PropTypes.string,
    cursor: PropTypes.bool,
    cursorExpire: PropTypes.number,
    fetchReportedMessagesInRoom: PropTypes.func.isRequired,
    reportedMessages: PropTypes.shape({}),
  }

  static defaultProps = {
    match: null,
    name: '',
    photo: '',
    emotions: {},
    roomChats: {},
    supportChatStatus: {},
    userSettings: {},
    roomId: null,
    uid: null,
    profanitiesList: {},
    profanitiesNotification: null,
    fontMode: FONT_MODE.normal,
    cursor: null,
    cursorExpire: null,
    reportedMessages: {},
  }

  constructor(props) {
    super(props);
    this.state = {
    //  roomId: '',
      openChat: true,
      emotionsFetched: false,
      chatRead: false,
      showFirstProfanityNotif: false,
      showProfanityExceededNotif: false,
    };
  }

  componentDidMount = () => {
    const {
      roomId,
      userSettings,
      fetchReportedMessagesInRoom
      //  uid,
    } = this.props;

    //  const curUserSettings = userSettings[uid] || userSettings.default || {};

    if (roomId && userSettings && (userSettings.roomChatOpen || userSettings.roomChatOpen === undefined)) {
    //  setTimeout(() => {
      this.readChatMessages(roomId);
      fetchReportedMessagesInRoom(roomId);

      this.setState({ chatRead: true });
      //  }, 2000);

      this.setState({
        openChat: true,
      });
    } else {
      this.setState({
        openChat: false,
      });
    }
  }

  componentWillUnmount() {
    const { stopReadChatMessages, roomId } = this.props;

    if (roomId) {
      stopReadChatMessages(roomId);
    }
  }

  toggleChat = () => {
    const {
      setChatMessageAsRead, roomId, playButtonSound, toggleRoomChatFunc, userSettings, uid,
    } = this.props;
    const { chatRead } = this.state;

    setChatMessageAsRead(roomId);
    playButtonSound();

    const curUserSettings = userSettings[uid] || userSettings.default || {};

    if (!curUserSettings.roomChatOpen && !chatRead) {
      this.readChatMessages(roomId);

      this.setState({ chatRead: true });
    } else {
    //  this.stopReadChatMessages(roomId);
    }

    toggleRoomChatFunc(uid);

    this.setState(prevState => ({
      openChat: !prevState.openChat,
    }));
  }

  fetchEmotions = () => {
    const { fetchEmotions } = this.props;
    const { emotionsFetched } = this.state;

    const { hostname } = window.location;

    let devMode = false;

    if (hostname.includes('dev.') || hostname.includes('localhost')) {
      devMode = true;
    }

    if (!emotionsFetched) {
      fetchEmotions(devMode);
      this.setState({ emotionsFetched: true });
    }
  }


  splitMessage = (text) => {
    const splitText = text.replace(/\s/g, '');
    // const desiredText = splitText.replace(/[^\w\s]/gi, '');

    const desiredText = splitText.replace(/[^āčēģīķļņšūž\w\s]+/gi, '');

    return desiredText.toString();
  }

  sendChatMessage = (inputMessage) => {
    const {
      cursor, cursorExpire, sendChatMessage, roomId, playButtonSound, setProfanitiesNotif, profanitiesList, profanitiesNotification, uid // name, photo, uid,
    } = this.props;
    return new Promise((resolve, reject) => { 
      const testMessage = inputMessage.toLowerCase();
      const checkedMessage = this.splitMessage(testMessage);

      console.log(checkedMessage, profanitiesList, "falkMessage");

      if (cursor && cursorExpire > Date.now()) {
        this.setState({
          openCursorModal: true,
        })
        return resolve({ status: FROUNT_SIDE_STATUS.error, openCursorModal: true});
      } 

      let hasProfanities = false;
      if (profanitiesList) {
        Object.keys(profanitiesList).map((key) => {
          if (profanitiesList[key].word) {
            if (testMessage.includes(profanitiesList[key].word.toLowerCase()) || checkedMessage.includes(profanitiesList[key].word.toLowerCase())) {
              hasProfanities = true;
            }
          }
          return null;
        });
      }

      playButtonSound();
      return sendChatMessage(roomId, inputMessage, hasProfanities, uid) // , name, uid, photo
        .then((res) => {
          if (res && res.status === 'success' && res.data && res.data === 'profanities exceeded') {
            this.setState({ showProfanityExceededNotif: true });
          } else {
            if (hasProfanities) {
              if ((!profanitiesNotification || profanitiesNotification !== roomId)) {
                setProfanitiesNotif(roomId);
              }
              this.setState({ showFirstProfanityNotif: true });
            }
          }

          return resolve({status: res.status});
        })
        .catch(err => console.log(err));
    });
  }

  closeProfanityNotif = () => {
    this.setState({ showFirstProfanityNotif: false });
  }

  closeProfanityExceeded = () => {
    this.setState({ showProfanityExceededNotif: false });
  }

  setEmotion = (key) => {
    const {
      sendEmotion, emotions, roomId, playButtonSound,
    } = this.props;
    const { emotionSetClicked } = this.state;

    if (emotions[key] && !emotionSetClicked) {
      playButtonSound();
      this.setState({ emotionSetClicked: true });

      setTimeout(() => {
        this.setState({ emotionSetClicked: false });
      }, 2000);
      return sendEmotion(roomId, emotions[key].image)
        .catch(err => console.log(err));
    }
    return null;
  }

  setChatAsRead = () => {
    const { setChatMessageAsRead, roomId } = this.props;

    return setChatMessageAsRead(roomId)
      .then(res => (res.status))
      .catch(err => console.log(err));
  }

  readChatMessages = (roomId) => {
    const { readChatMessages } = this.props;

    return readChatMessages(roomId)
      .catch((err) => {
        console.log(err);
      //  this.setState({
      //    loading: false,
      //    error: err,
      //  });
      });
  }

  /*  stopReadChatMessages = (roomId) => {
    const { stopReadChatMessages } = this.props;

    return stopReadChatMessages(roomId)
      .catch(err => this.setState({
        loading: false,
        error: err,
      }));
  } */

  toggleCursorModal = () => {
    this.setState({
      openCursorModal: false,
    })
  };


  render = () => {
    const {
      t,
      emotions,
      roomChats,
      toggleSupport,
      roomId,
      uid,
      supportChatStatus,
      userSettings,
      playButtonSound,
      fontMode,
      profanitiesList,
      cursor,
      cursorExpire,
      reportedMessages,
      name
    } = this.props;

    const {
      openChat,
      //  chatRead,
      showFirstProfanityNotif,
      showProfanityExceededNotif,
      openCursorModal,
    } = this.state;

    return (
      <>
        <ChatComponent
          openChat={openChat}
          toggleChat={this.toggleChat}
          toggleSupport={toggleSupport}
          uid={uid}
          roomId={roomId}
          emotions={emotions}
          chatMessages={roomChats}
          sendMessage={this.sendChatMessage}
          setChatAsRead={this.setChatAsRead}
          setEmotion={this.setEmotion}
          supportChatStatus={supportChatStatus}
          userSettings={userSettings || {}}
          playButtonSound={playButtonSound}
          fetchEmotions={this.fetchEmotions}
          fontMode={fontMode}
          profanitiesList={profanitiesList}
          cursor={cursor}
          cursorExpire={cursorExpire}
          reportedMessages={reportedMessages}
          name={name}
        />
        <Modal container={'div > div'} isOpen={showFirstProfanityNotif} toggle={this.closeProfanityNotif} className={`notification gifts-send-confirmation old-modal root-font-${fontMode}`} >
          <ModalHeader
            toggle={this.closeProfanityNotif}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeProfanityNotif} />
        }
          >
            {t('common.warning')}
          </ModalHeader>
          <ModalBody className="notification-body">
            {t('common.firstProfanity')}
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button type="button" className="btn notification-footer-button" onClick={this.closeProfanityNotif}>
              {t('common.ok')}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal container={'div > div'} isOpen={showProfanityExceededNotif} toggle={this.closeProfanityExceeded} className={`notification gifts-send-confirmation old-modal root-font-${fontMode}`} >
          <ModalHeader
            toggle={this.closeProfanityExceeded}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeProfanityExceeded} />
        }
          />
          <ModalBody className="notification-body">
            {t('common.profanityExceeded')}
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button type="button" className="btn notification-footer-button" onClick={this.closeProfanityExceeded}>
              {t('common.ok')}
            </Button>
          </ModalFooter>
        </Modal>
        
      </>
    );
  }
}

const mapStateToProps = state => ({
  name: state.member.name || '',
  uid: state.member.uid || '',
  cursor: state.member.cursor || null,
  cursorExpire: state.member.cursorExpire || null,
  photo: state.member.photo || '',
  emotions: state.rooms.emotions || {},
  roomChats: state.rooms.roomChats ? state.rooms.roomChats : {},
  //  userSettings: state.userSettings || {},
  userSettings: state.userSettings[state.member.uid] ? (state.userSettings[state.member.uid]) : (state.userSettings.default ? state.userSettings.default : {}),
  profanitiesList: state.rooms.profanitiesList || {},
  profanitiesNotification: state.rooms.profanitiesNotification || null,
  reportedMessages: state.rooms.reportedMessages || {},
});

const mapDispatchToProps = {
  sendChatMessage: sendChatMsg,
  setChatMessageAsRead: setChatAsRead,
  readChatMessages: readChat,
  stopReadChatMessages: stopReadChat,
  fetchEmotions: getEmotions,
  sendEmotion: setEmotion,
  toggleRoomChatFunc: toggleRoomChat,
  setProfanitiesNotif: setProfanitiesNotification,
  fetchReportedMessagesInRoom: getReportedMessagesInRoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Chat));
