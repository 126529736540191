// returns nested object with most recent date field
export const getMostRecentSubObject = (obj, dateFieldName) => {
    let mostRecentDate = 0;
    let mostRecentObject = null;
  
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const currentDate = obj[key][dateFieldName];
        if (currentDate > mostRecentDate) {
          mostRecentDate = currentDate;
          mostRecentObject = obj[key];
        }
      }
    }
  
    return mostRecentObject;
};