import React from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';

import { useTranslation } from 'react-i18next';

import CustomModal from '../../../../Components/Components/Modal';

const RatingsInfo = React.memo(({toggleRatingsInfo, toggleRatingsModal}) => {
  const { t } = useTranslation('common');

  return (
    <CustomModal
      isOpen={toggleRatingsInfo}
      toggle={toggleRatingsModal}
      size="md"
      title={t('myInfo.ratingsInfo')}
      body={(
        <Row className="flex-column my-info-ratings-description">
          <Col className="mb-2">{t('myInfo.ratingsInfo1')}</Col>
          <Col className="mb-2">{t('myInfo.ratingsInfo2')}</Col>
          <Col className="mb-2" >{t('myInfo.ratingsInfo3')}</Col>
        </Row>
      )}
      footer={(
        <Button color="secondary" onClick={toggleRatingsModal}>
          {t('common.close')}
        </Button>
      )}
    />
  );
});

export default RatingsInfo;
