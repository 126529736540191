/* global document */

// import './wdyr.js';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'core-js/stable';

/*
import 'core-js/es/set';
import 'core-js/es/map';
import 'core-js/es/array/includes';
import 'core-js/features/set';
import 'core-js/features/string/repeat';
import 'core-js/features/string/includes'; */
// import 'core-js/features/array/includes';

// import 'babel-polyfill';

// import fetch from 'unfetch';
import 'abortcontroller-polyfill';
// import process from 'process';

import Promise from 'promise-polyfill';

import Fullscreen from 'react-fullscreen-crossbrowser';
// import { Firebase } from '../lib/firebase';
import ReactGA from 'react-ga';

import i18n from './i18n';

// import 'whatwg-fetch';

import 'moment/locale/lv';
import 'moment/locale/ru';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// import { WebpMachine } from "webp-hero";
// import 'webp-hero/dist-cjs/polyfills.js';

import { BrowserRouter as Router } from 'react-router-dom';
// import { Router } from "react-router-dom";

// import { createBrowserHistory } from 'history';

import { PersistGate } from 'redux-persist/es/integration/react';

import configureStore from '../store/persistedStore';
import * as serviceWorker from './register-service-worker';
import Routes from './routes/index';

// Components
import Loading from './components/UI/Loading';

// Load css
import './styles/style.scss';

import './styles/styleNew.scss';

/* ReactGA.initialize('UA-147571548-1', {
  debug: false,
  gaOptions: {
    storage: 'none',
    storeGac: false,
  }
});
ReactGA.pageview('/'); */

/*
const process = typeof window !== 'undefined' ? window.process : {
  env: {
    NODE_ENV: 'development',
  },
}; */

// replace console.* for disable log on production
try {
  if (process.env.NODE_ENV === 'production') {
    const { hostname } = window.location;

    if (hostname === 'inbox.dev.spelezoli.lv' || hostname === 'inbox.spelezoli.lv' || hostname === 'dra.dev.spelezoli.lv' || hostname === 'dev.spelezoli.lv' || hostname === 'fb.dev.spelezoli.lv' || hostname === 'fb.spelezoli.lv' || hostname === 'pre-prod-test.firebaseapp.com') {
      console.log('keep console in dev hosting');
    } else {
      console.log = () => { };
      console.error = () => { };
      console.debug = () => { };
    }
  }
} catch (err) {
  console.log(err);
}

// const webpMachine = new WebpMachine();
// webpMachine.polyfillDocument();

document.addEventListener('visibilitychange', () => {
  if (document.hidden) {
    console.log('========= document is hidden');
  } else {
    console.log('========= document is showing');
  }
});

try {
  document.addEventListener('resume', () => {
    document.dispatchEvent(new Event('visibilitychange'));
  });
} catch (err) {
  console.log(err);
}

/* try {
  for (var key in localStorage) {
      if (key.includes('firebaseio.com')) {
        localStorage.removeItem(key); //x
      }
  }
} catch(err) {

} */

try {
  if (localStorage && localStorage.getItem('firebase:previous_websocket_failure')) { //x
    console.log('force long polling');
  //  Firebase.database.INTERNAL.forceLongPolling();
  }
} catch (err) {
  console.log(err);
}
//  Firebase.database.INTERNAL.forceWebSockets();

/*
let isFullscreenEnabled = false;

const toggleFullscreen = () => {
//  console.log('toggleFullscreen', { isFullscreenEnabled });
  isFullscreenEnabled = !isFullscreenEnabled;
}; */

const { persistor, store } = configureStore();


// const history = createBrowserHistory();


// i18n.then(() => {
const Root = () => (
  <Provider store={store}>
    {console.log('render root 1')}
    <PersistGate loading={<Loading dontSaveStatistics />} persistor={persistor}>
      <Router>
        <Routes />
      </Router>
    </PersistGate>
    </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
// });
