import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import classNames from 'classnames';

import Moment from 'react-moment';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import NavLink from 'reactstrap/lib/NavLink';
import Media from 'reactstrap/lib/Media';
/*
import {
  Table, Column, HeaderCell, Cell, ColumnGroup,
} from 'rsuite-table';

import 'rsuite-table/dist/css/rsuite-table.css'; */

import MyInfoSubHeader from './MyInfoSubHeader';
import MenuRow from '../../../Components/Components/MenuRow';
import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';
import LandScapeLayoutMobileHeader from '../../Layout/Mobile/LandScapeLayoutMobileHeader';
import * as constants from '../../../../../../constants/constants';

import {
  getGamesHistory,
} from '../../../../../../actions/member';

class GamesHistory extends React.Component {
  static propTypes = {
    gamesHistory: PropTypes.arrayOf(PropTypes.shape({})),
  //  userSettings: PropTypes.shape({
  //    soundOn: PropTypes.bool,
   // }),
    //  i18n: PropTypes.shape(),
    achievementsNotification: PropTypes.bool,
    //  isFullscreen: PropTypes.bool,
    //  isFullscreenEnabled: PropTypes.bool,
    fetchGamesHistory: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  //  showUserSettingsModal: PropTypes.func.isRequired,
    handleClickStart: PropTypes.func.isRequired,
    OpenMobileDropdownModal: PropTypes.func.isRequired,
    activeTab: PropTypes.string,
    screenMode: PropTypes.string,
  }

  static defaultProps = {
    gamesHistory: [],
  //  userSettings: {},
    //  i18n: null,
    achievementsNotification: false,
    //  isFullscreen: false,
    //  isFullscreenEnabled: false,
    activeTab: constants.MENU_NAVIGATION.gameHistory,
    screenMode: constants.SCREEN_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
    }

    this.scrollAreaRef1 = React.createRef();
    this.scrollAreaRef2 = React.createRef();
    this.curSelectPos = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillMount() {
    const { fetchGamesHistory } = this.props;

    fetchGamesHistory().then((res) => {
      console.log('results test 7', { res });
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    try {
      window.removeEventListener('resize', this.updateWindowDimensions);
    } catch (err) {
      console.log('err componentWillUnmount');
    }
  }

  updateWindowDimensions = () => {
    console.log('updateWindowDimensions');
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  updateScrollPos = (val) => {
    if (this.messagesScrollbar2) {
      setTimeout(() => {
        if (this.messagesScrollbar2) {
          this.messagesScrollbar2.scrollXTo(val.leftPosition);
        }
      }, 0);
    }

    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (x, y) => {
    if (this.datesRef && this.tableRef) {
      this.datesRef.scrollTop(-y);
      this.tableRef.scrollTop(-y);
    }
  }

  scroll2 = (x, y) => {
    if (this.datesRef && this.tableRef && this.tableRef.scrollY !== y) {
      this.tableRef.scrollTop(-y);
    }
  }


  playButtonSound = () => {
    const { soundOn } = this.props;

    if (soundOn) {
      this.buttonClickedAudio.play();
    }
  }

  getLoses = (rowData) => {
    const wins = rowData.wins || 0;
    const loses = rowData.loses || 0;

    const winsPercentage = Math.round((wins / (wins + loses)) * 100);
    let losesPercentage = Math.round((loses / (wins + loses)) * 100);

    if ((winsPercentage + losesPercentage) > 100) {
      losesPercentage -= 1;
    } else if ((winsPercentage + losesPercentage) < 100) {
      losesPercentage += 1;
    }

    return (
      <div>
        {rowData.loses ? (`${(wins + loses) ? (`${losesPercentage}%`) : 0}`) : 0}
      </div>
    );
  }

  handleScroll1 = (value) => {
    if (this.scrollAreaRef1.current && this.scrollAreaRef2.current) {
      this.scrollAreaRef2.current.state.eventType = 'api';
      if (this.scrollAreaRef1.current.state.eventType !== 'api') {
        if (value.topPosition /*|| value.topPosition ===  0*/) {
            this.scrollAreaRef2.current.scrollArea.scrollYTo(value.topPosition);
        }
        this.scrollAreaRef2.current.state.eventType = undefined;
      }
      //console.log("value.topPosition1", value.topPosition)
    }
  };

  handleScroll2 = (value) => {
    if (this.scrollAreaRef1.current && this.scrollAreaRef2.current) {
      this.scrollAreaRef1.current.state.eventType = 'api';
      if (this.scrollAreaRef2.current.state.eventType !== 'api') {
        if (value.topPosition /*|| value.topPosition === 0*/) {
            this.scrollAreaRef1.current.scrollArea.scrollYTo(value.topPosition);
        }
        this.scrollAreaRef1.current.state.eventType = undefined;
      }
      //console.log("value.topPosition2", value.topPosition)
    }
  };


  render() {
    const {
      screenMode, activeTab, handleClickStart, OpenMobileDropdownModal, gamesHistory, changeTab, t, achievementsNotification, // i18n, isFullscreen, innerHeight, isFullscreenEnabled,
    } = this.props;
    return (
      <div className="layout-mobile-body layout-mobile-body-game-history-page">
        <Helmet>
          <title>
            Zole - {t('myInfo.results')}
          </title>
        </Helmet>

        <div className="layout-mobile-body-top">
          {/* <Row className="layout-mobile-subheader d-none">
            <MyInfoSubHeader changeTab={changeTab} activeTab={activeTab} />
          </Row> */}
        </div>
        <div className="layout-mobile-body-main my-info-body-main" style={{display: 'block'}}>
          <div className="layout-mobile-body-main-title d-none">{t('common:myInfo.results')}</div>
          <div className="my-info-results-scrollareaCANCEL" style={{display: 'inline-block', width: '20%', height: 'calc(100% - 43px)', verticalAlign: 'top'}}>
            <div
              className="my-info-results-scrollarea-body-header d-flex align-items-center"
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', height: 60, backgroundColor: 'rgba(0, 0, 0, 0.95)', paddingLeft: 15, paddingRight: 15, }}
            >
              {t('results.date')}
            </div>

            <ScrollAreaWrapper
              className="layout-mobile-body-main-scrollarea my-info-results-scrollarea-body"
              contentClassName="my-info-results-scrollarea-body-content"
              show
              scrollbarId="top-page-scrollbar"
              rightOffset={12}
              topOffset={0}
              bottomOffset={0}
              horizontal={false}
              vertical
              scrollRef={this.scrollAreaRef1}
              onScroll={this.handleScroll1}
              hideScrollBar
            >

              {gamesHistory && gamesHistory.map(dayHistory => (
                <MenuRow key={dayHistory.date} fitContent>
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textAlign: 'center' }}>
                      {(!dayHistory.date || dayHistory.date === 'total') ? (
                        <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
                          {t('results.total')}
                        </div>

                      ) : (
                        <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
                          <Moment format="DD.MM" locale="lv">
                            {dayHistory.date}
                          </Moment>
                        </div>
                      )}
                    </div>
                    </MenuRow>
              ))}
            </ScrollAreaWrapper>
          </div>
          
          <div className="my-info-results-scrollarea" style={{display: 'inline-block', width: '80%'}}>
            <div style={{ width: 'fit-content', minWidth: '100%' }} className="h-100">
              <div className="my-info-results-scrollarea-body-header" style={{
                whiteSpace: 'nowrap', overflow: 'hidden', height: 60, backgroundColor: 'rgba(0, 0, 0, 0.95)', paddingLeft: 15, paddingRight: 15,
              }}
              >

                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 mt-3 menu-table-row-text-mw-120">
                  <span>{t('results.wins')}</span>
                  <Row>
                    <Col xs="6">
                      #
                    </Col>
                    <Col xs="6">
                      %
                    </Col>
                  </Row>
                </Col>
                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 mt-3 menu-table-row-text-mw-120">
                  <span>{t('results.loses')}</span>
                  <Row>
                    <Col xs="6">
                      #
                    </Col>
                    <Col xs="6">
                      %
                    </Col>
                  </Row>
                </Col>
                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 mt-3">
                  {t('results.rooms')}
                </Col>
                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 mt-3">
                  {t('results.parties')}
                </Col>
                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 mt-3">
                  {t('results.coins')}
                </Col>
                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 mt-3">
                  {t('results.totalMoney')}
                </Col>
                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 mt-3">
                  {t('results.points')}
                </Col>
                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100 mt-3 menu-table-row-text-mw-120">
                  <span style={{ display: 'block' }}>
                    {`${t('results.wins')} `}
                  </span>
                  <span style={{ display: 'block' }}>
                    {` (${t('results.asSmall')})`}
                  </span>
                </Col>
                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100 mt-3 menu-table-row-text-mw-120">
                  <span style={{ display: 'block' }}>
                    {`${t('results.wins')} `}
                  </span>
                  <span style={{ display: 'block' }}>
                    {` (${t('results.asLarge')})`}
                  </span>
                </Col>
                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100 mt-3 menu-table-row-text-mw-120">
                  <span style={{ display: 'block' }}>
                    {`${t('results.loses')} `}
                  </span>
                  <span style={{ display: 'block' }}>
                    {` (${t('results.asSmall')})`}
                  </span>
                </Col>
                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100 mt-3 menu-table-row-text-mw-120">
                  <span style={{ display: 'block' }}>
                    {`${t('results.loses')} `}
                  </span>
                  <span style={{ display: 'block' }}>
                    {` (${t('results.asLarge')})`}
                  </span>
                </Col>
                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 mt-3 menu-table-row-text-mw-120">
                  <span style={{ display: 'block' }}>
                    {`${t('results.wins')} `}
                  </span>
                  <span style={{ display: 'block' }}>
                    {` (${t('results.zole')})`}
                  </span>
                </Col>
                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 mt-3 menu-table-row-text-mw-120">
                  <span style={{ display: 'block' }}>
                    {`${t('results.loses')} `}
                  </span>
                  <span style={{ display: 'block' }}>
                    {` (${t('results.zole')})`}
                  </span>
                </Col>
                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 mt-3 menu-table-row-text-mw-120">
                  <span style={{ display: 'block' }}>
                    {`${t('results.wins')}`}
                  </span>
                  <span style={{ display: 'block' }}>
                    {` (${t('results.table')})`}
                  </span>
                </Col>
                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 mt-3 menu-table-row-text-mw-120">
                  <span style={{ display: 'block' }}>
                    {`${t('results.loses')}`}
                  </span>
                  <span style={{ display: 'block' }}>
                    {` (${t('results.table')})`}
                  </span>
                </Col>

                <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 menu-table-row-text-last mt-3">
                  {t('results.rating')}
                </Col>
              </div>
              <ScrollAreaWrapper
                className="layout-mobile-body-main-scrollarea my-info-results-scrollarea-body"
                contentClassName="my-info-results-scrollarea-body-content"
                show
                scrollbarId="top-page-scrollbar"
                rightOffset={12}
                topOffset={12}
                bottomOffset={0}
                horizontal={false}
                vertical
                scrollRef={this.scrollAreaRef2}
                onScroll={this.handleScroll2}

                // externalScrollPos={scrollPosition2}
              >
                {gamesHistory && gamesHistory.map(dayHistory => (
                  <MenuRow key={dayHistory.date} fitContent>
                    <div key={dayHistory.date} style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                      {/* <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90">
                        {(!dayHistory.date || dayHistory.date === 'total') ? (
                          t('results.total')
                        ) : (
                          <div>
                            <Moment format="DD.MM" locale="lv">
                              {dayHistory.date}
                            </Moment>
                          </div>
                        )}
                      </Col> */}
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90">
                        <Row>
                          <Col xs="6" className="menu-table-row-text menu-table-row-text-breakspaces">
                            {dayHistory.wins ? dayHistory.wins : 0}
                          </Col>
                          <Col xs="6" className="menu-table-row-text menu-table-row-text-breakspaces">
                            {dayHistory.wins ? (`${(dayHistory.wins + (dayHistory.loses || 0)) ? (`${Math.round((dayHistory.wins / (dayHistory.wins + dayHistory.loses)) * 100)}%`) : 0}`) : 0}
                          </Col>
                        </Row>
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90">
                        <Row>
                          <Col xs="6" className="menu-table-row-text menu-table-row-text-breakspaces">
                            {dayHistory.loses ? dayHistory.loses : 0}
                          </Col>
                          <Col xs="6" className="menu-table-row-text menu-table-row-text-breakspaces">
                            {this.getLoses(dayHistory)}
                          </Col>
                        </Row>
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90">
                        {dayHistory.roomsPlayed ? dayHistory.roomsPlayed : 0}
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90">
                        {(dayHistory.wins + dayHistory.loses) ? (dayHistory.wins + dayHistory.loses) : 0}
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90">
                        {dayHistory.balChange ? dayHistory.balChange : 0}
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90">
                        {dayHistory.totalMoney ? dayHistory.totalMoney : (dayHistory.balChange ? dayHistory.balChange : 0)}
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90">
                        <div className={classNames({ 'menu-table-row-text-positive': (dayHistory.pointsChange >= 0 || !dayHistory.pointsChange), 'menu-table-row-text-negative': dayHistory.pointsChange < 0, 'm-auto': true })}>
                          {dayHistory.pointsChange ? dayHistory.pointsChange : 0}
                        </div>
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100">
                        {dayHistory.smallWins ? dayHistory.smallWins : 0}
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100">
                        {dayHistory.largeWins ? dayHistory.largeWins : 0}
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100">
                        {dayHistory.smallLoses ? dayHistory.smallLoses : 0}
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-100">
                        {dayHistory.largeLoses ? dayHistory.largeLoses : 0}
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90">
                        {dayHistory.zoleWins ? dayHistory.zoleWins : 0}
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90">
                        {dayHistory.zoleLoses ? dayHistory.zoleLoses : 0}
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90">
                        {dayHistory.galdinsWins ? dayHistory.galdinsWins : 0}
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90">
                        {dayHistory.galdinsLoses ? dayHistory.galdinsLoses : 0}
                      </Col>
                      <Col className="menu-table-row-text menu-table-row-text-breakspaces min-width-90 menu-table-row-text-last">
                        <div className={classNames({ 'menu-table-row-text-positive': (dayHistory.rating >= 0 || !dayHistory.rating), 'menu-table-row-text-negative': dayHistory.rating < 0, 'm-auto': true })}>
                          {dayHistory.rating ? dayHistory.rating : 0}
                        </div>
                      </Col>

                    </div>
                  </MenuRow>
                ))}
              </ScrollAreaWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  soundOn: state.userSettings.soundOn,
  gamesHistory: state.member.gamesHistory || [],
  gamesHistoryTotal: state.member.gamesHistoryTotal || {},
});

const mapDispatchToProps = {
  fetchGamesHistory: getGamesHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(GamesHistory));
