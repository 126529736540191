import React, { Fragment, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';

import classNames from 'classnames';

import { filter, map, orderBy, size } from 'lodash';
import Input from 'reactstrap/lib/Input';

import 'react-widgets/dist/css/react-widgets.css';

import * as constants from '../../../constants/constants';
import pencilImg from '../../../images/icons/pencil.svg';
import cancelImg from '../../../images/icons/close_old.png';
import checkImg from '../../../images/icons/check.png';

import {
  getDesignPlatforms, updateDesignPlatform,
} from '../../../actions/admin';

import { Button, Media, PopoverBody } from 'reactstrap';
import { UncontrolledPopover } from 'reactstrap/lib';

const AdminPlatform = ({}) => {

  const { t } = useTranslation('admin');
  const dispatch = useDispatch();

  const [frequency, setFrequency] = useState(null);
  const [selectFrequencyId, setSelectFrequencyId] = useState(null);
  const [isFrequency, setIsFrequency] = useState(false);
  
  const designPlatforms = useSelector(state => state.admin.designPlatforms || {});
  const designPlatformsLoading = useSelector(state => state.admin.designPlatformsLoading || false);

  useEffect(() => {
    dispatch(getDesignPlatforms());
  }, []);

  const handleOldChange = (id, value) => {
    dispatch(updateDesignPlatform(id, 'old', !value));
  };

  const handleNewChange = (id, value) => {
    dispatch(updateDesignPlatform(id, 'new', !value));
  };

  const handleDevMode = (id, value) => {
    if (value && !designPlatforms[id].devMode) return;
    dispatch(updateDesignPlatform(id, 'devMode', !value));
  };

  const handleShowReminderChange = (id, value) => {
    dispatch(updateDesignPlatform(id, 'showReminder', !value));
  }

  const handleFrequencyChange = (id) => {
    setIsFrequency(!isFrequency);
    setSelectFrequencyId(id);
  }

  const updateFrequencyChange = () => {
    let curFrequency = frequency;
    setFrequency(curFrequency);
    dispatch(updateDesignPlatform(selectFrequencyId, 'frequency', curFrequency || null ));
    setIsFrequency(false);
    setFrequency(null);
    setSelectFrequencyId(null);
    console.log(curFrequency, frequency, "curFrequency");
  }

  const cancelFrequencyChange = () => {
    setIsFrequency(false);
    setFrequency(null);
    setSelectFrequencyId(null);
  }

  const changeFrequency = (e) => {
    console.log(e.target.value, "checkValue")
    if (parseInt(e.target.value, 10) > 0 && parseInt(e.target.value, 10) < 31) {
      setFrequency(parseInt(e.target.value, 10) || filter(designPlatforms, (design, key) => { return key === selectFrequencyId; })[0]?.frequency );
    }
  }
  console.log(designPlatforms, "rerenderDesignPlatforms")

  return (
    <Fragment>
      <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-design">
        <PopoverBody className="popover-body">
          The admin can set up the platform and available designs (old or new), and also configure the reminder about the new design that is displayed for the players.
        </PopoverBody>
      </UncontrolledPopover>
      <h2>
        Design
        <Button className="admin-help-button" id="admin-help-button-design">
          ?
        </Button>
      </h2>
      <table style={{ width: '100%', fontSize: 12, color: '#fff' }}>
        <colgroup>
          <col span="1" className="" />
        </colgroup>
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>
              Platform
            </th>
            <th style={{ textAlign: 'center' }}>
              Old design
            </th>
            <th style={{ textAlign: 'center' }}>
              New design
            </th>
            <th style={{ textAlign: 'center' }}>
              Show reminder
            </th>
            <th style={{ textAlign: 'center' }}>
              DevMode
            </th>
            <th style={{ textAlign: 'center' }}>
              Frequency
            </th>
          </tr>
        </thead>
        <tbody>
          {map(constants.PLATFORMS, item => (
            <Fragment key={item.id}>
              <tr key={item.id}>
                <td className="allUsers-table-col">
                  {item.label}
                </td>
                <td className="allUsers-table-col">
                  {filter(designPlatforms, (design, key) => { return console.log(design, key, item.id)})}
                  <Input type="checkbox" name="oldDesign" disabled={designPlatformsLoading} checked={filter(designPlatforms, (design, key) => { return key === item.id })[0]?.old} onChange={() => handleOldChange(item.id, filter(designPlatforms, (design, key) => { return key === item.id; })[0]?.old)} />
                </td>
                <th style={{ textAlign: 'center' }}>
                  <Input type="checkbox" name="newDesign" disabled={designPlatformsLoading} checked={filter(designPlatforms, (design, key) => { return key === item.id; })[0]?.new} onChange={() => handleNewChange(item.id, filter(designPlatforms, (design, key) => { return key === item.id; })[0]?.new)} />
                </th>
                <th style={{ textAlign: 'center' }}>
                  <Input type="checkbox" name="showReminder" disabled={designPlatformsLoading || !filter(designPlatforms, (design, key) => { return key === item.id; })[0]?.new} checked={filter(designPlatforms, (design, key) => { return key === item.id })[0]?.showReminder} onChange={() => handleShowReminderChange(item.id, filter(designPlatforms, (design, key) => { return key === item.id })[0]?.showReminder)} />
                </th>
                <th style={{ textAlign: 'center' }}>
                  <Input type="checkbox" name="devMode" disabled={designPlatformsLoading} checked={filter(designPlatforms, (design, key) => { return key === item.id; })[0]?.devMode} onChange={() => handleDevMode(item.id, filter(designPlatforms, (design, key) => { return key === item.id; })[0]?.devMode)} />
                </th>
                <th className='d-flex align-items-center flex-row' style={{ textAlign: 'center' }}>
                  <div style={{ width: '100px' }}>
                    <Input type="number" onWheel={(e) => e.target.blur()} min={0} max={30} step={1} disabled={designPlatformsLoading || !isFrequency || selectFrequencyId !== item.id }  name="frequency" id="frequency" value={selectFrequencyId ===item.id && isFrequency ? frequency : filter(designPlatforms, (design, key) => { return key === item.id; })[0]?.frequency || 0} onChange={(e) => changeFrequency(e)} />
                  </div>
                  <div style={{ width: '200px' }}>
                    {
                      !isFrequency ? (
                        <Media src={pencilImg} style={{ width: '28px' }} onClick={() => handleFrequencyChange(item.id)} className="menu-player-edit-image" />
                      ) : isFrequency && selectFrequencyId === item.id ? (
                        <Media src={checkImg} style={{ width: '28px' }} onClick={() => updateFrequencyChange()} className="menu-player-edit-image" />
                      ) : (
                        <Media src={pencilImg} style={{ width: '28px' }} onClick={() => handleFrequencyChange()} className="menu-player-edit-image" />
                      )
                    }
                    <Media src={cancelImg} style={{ width: '28px' }} onClick={() => cancelFrequencyChange()} className="menu-player-edit-image" />
                  </div>
                </th>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default AdminPlatform;
