import React from 'react';
import PropTypes from 'prop-types';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import { useTranslation } from 'react-i18next';

import { filter } from 'lodash';
import * as constants from '../../../../../constants/constants';

const Star = ({ starId, marked }) => {
  const { t } = useTranslation('common');
  return (
    <>
      <span
        star-id={starId}
        role="button"
        style={{ color: "#ff9933", cursor: "pointer" }}
        id={`star-id-${starId}`}
      >
        {marked ? "\u2605" : "\u2606"}
      </span>
      <UncontrolledTooltip
        className="player-receivedGift-tooltip player-receivedGift-tooltip-new"
        placement="bottom"
        target={`star-id-${starId}`}
        container={'div > div'}
      >
        <div className="player-receivedGift-tooltip-from">
          {starId > 0 && t(`common.${filter(constants.FEEDBACK_RATING, ['id', parseInt(starId, 10)])[0].label}`)}
        </div>
      </UncontrolledTooltip>
    </>
  );
};

Star.propTypes = {
  starId: PropTypes.string,
  marked: PropTypes.string,
};

Star.defaultProps = {
  starId: null,
  marked: null,
};

export default Star;
