import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Row, Col, Media, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import ScrollArea from 'react-scrollbar';
import PropTypes from 'prop-types';

import swedbank from '../../../../../images/BuyMoney/payment swedbank.png';
import seb from '../../../../../images/BuyMoney/payment seb new.png';
import citadele from '../../../../../images/BuyMoney/payment citadele.png';
import luminorLight from '../../../../../images/BuyMoney/payment luminor light.png';
import luminorNormal from '../../../../../images/BuyMoney/payment luminor normal.png';
import luminorDark from '../../../../../images/BuyMoney/payment luminor dark.png';

import card from '../../../../../images/BuyMoney/payment bank card.png';
import paypal from '../../../../../images/BuyMoney/payment paypal.png';
import paypalNormal from '../../../../../images/BuyMoney/payment paypal normal.png';

import textMessage from '../../../../../images/BuyMoney/payment text message.png';
import coinOld from '../../../../../images/coin.svg';
import coinNew from '../../../../../images/redesign/common/coin.svg';
import infoIcon from '../../../../../images/redesign/icons/info.svg';
import infoIconLightMode from '../../../../../images/redesign/light-mode/icons/info.png';
import closeImg from '../../../../../images/landing-view/close.svg';

import appStore from '../../../../../images/redesign/about-us/Appstore button.webp';
import playStore from '../../../../../images/redesign/about-us/Playstore button.webp';

import * as constants from '../../../../../constants/constants';
import { termsOfText } from '../../../../../constants/termsOfUse';
import PaymentRadioCol from '../../../UI/PaymentRadioCol';
const currentVersion = require('./../../../../../../package.json').version;


const PaymentTypeImageMap = {
  [`${constants.PAYMENT_TYPES.swedbank.id}-${constants.SCREEN_MODE.light}`]: swedbank,
  [`${constants.PAYMENT_TYPES.swedbank.id}-${constants.SCREEN_MODE.normal}`]: swedbank,
  [`${constants.PAYMENT_TYPES.swedbank.id}-${constants.SCREEN_MODE.dark}`]: swedbank,

  [`${constants.PAYMENT_TYPES.seb.id}-${constants.SCREEN_MODE.light}`]: seb,
  [`${constants.PAYMENT_TYPES.seb.id}-${constants.SCREEN_MODE.normal}`]: seb,
  [`${constants.PAYMENT_TYPES.seb.id}-${constants.SCREEN_MODE.dark}`]: seb,

  [`${constants.PAYMENT_TYPES.citadele.id}-${constants.SCREEN_MODE.light}`]: citadele,
  [`${constants.PAYMENT_TYPES.citadele.id}-${constants.SCREEN_MODE.normal}`]: citadele,
  [`${constants.PAYMENT_TYPES.citadele.id}-${constants.SCREEN_MODE.dark}`]: citadele,

  [`${constants.PAYMENT_TYPES.luminor.id}-${constants.SCREEN_MODE.light}`]: luminorLight,
  [`${constants.PAYMENT_TYPES.luminor.id}-${constants.SCREEN_MODE.normal}`]: luminorNormal,
  [`${constants.PAYMENT_TYPES.luminor.id}-${constants.SCREEN_MODE.dark}`]: luminorDark,

  [`${constants.PAYMENT_TYPES.card.id}-${constants.SCREEN_MODE.light}`]: card,
  [`${constants.PAYMENT_TYPES.card.id}-${constants.SCREEN_MODE.normal}`]: card,
  [`${constants.PAYMENT_TYPES.card.id}-${constants.SCREEN_MODE.dark}`]: card,

  [`${constants.PAYMENT_TYPES.paypal.id}-${constants.SCREEN_MODE.light}`]: paypal,
  [`${constants.PAYMENT_TYPES.paypal.id}-${constants.SCREEN_MODE.normal}`]: paypalNormal,
  [`${constants.PAYMENT_TYPES.paypal.id}-${constants.SCREEN_MODE.dark}`]: paypal,

  [`${constants.PAYMENT_TYPES.textMessage.id}-${constants.SCREEN_MODE.light}`]: textMessage,
  [`${constants.PAYMENT_TYPES.textMessage.id}-${constants.SCREEN_MODE.normal}`]: textMessage,
  [`${constants.PAYMENT_TYPES.textMessage.id}-${constants.SCREEN_MODE.dark}`]: textMessage,
};

const PaymentTypeContent = (props) => {
  const [termsOpen, setTermsOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);

  let newDesign = false;

  try {
    newDesign = localStorage.getItem('newDesign') || false; //x
  } catch (err) {
    console.log(err);
  }

  const {
    devMode, selectedProduct, t, selectedPaymentOption, setSelectedPaymentOption, screenMode, toggleAboutUs, checkedTerms, toggleCheckedTerms, fontMode, i18n, handleAppStore, handlePlayStore,
  } = props;
  const infoIconImg = screenMode === constants.SCREEN_MODE.light && newDesign ? infoIconLightMode : infoIcon;
  const coinImg = newDesign ? coinNew : coinOld;

  const toggleTermsWrapper = (event) => {
    const { toggleTerms } = props;
    if (newDesign) {
      toggleTerms();
    } else {
      setTermsOpen(!termsOpen);
    }
  };

  const toggleAboutWrapper = (event) => {
    const { toggleAboutUs } = props;
    if (newDesign) {
      toggleAboutUs();
    } else {
      setAboutOpen(!aboutOpen);
    }
  };

  return (
    <div className="payment-type-scrollarea">
      <div className="d-flex justify-content-center flex-column payment-type-message">
        <div className="d-flex justify-content-center">
          {t('paymentType.youArePurchasing')}
          {selectedProduct.type === 'gameMoney' ? (
            <div className='payment-type-message-coin-wrapper'>
              <Media src={coinImg} className="payment-type-message-coin" />
              <span className="payment-type-coin-amount">{selectedProduct.amount}</span>
              {' '}
            </div>
          ) : (
            t(`paymentType.${selectedProduct.type}`)
          )}

          &nbsp;{t('paymentType.for')}
          {
            selectedProduct.discountType &&
              (selectedProduct.discountType === constants.SPECIALPRICE || selectedProduct.discountType === constants.DISCOUNTPRICE) &&
              selectedProduct.realPrice ? (
                <>
                  &nbsp;
                  <span className="payment-type-old-price">
                    {selectedProduct.price}
                    &nbsp;
                    EUR
                  </span>
                  &nbsp;
                  <span className="payment-type-new-price">
                    {selectedProduct.realPrice}
                    &nbsp;EUR
                  </span>
                </>
              ) : selectedProduct.discountType && selectedProduct.discountType === constants.BONUS && selectedProduct.realPrice ? (
                <>
                  &nbsp;
                  <span className="payment-type-price">
                    {selectedProduct.price}
                    &nbsp;
                    EUR
                  </span>
                  &nbsp;
                  <span className="payment-type-new-price">
                    {`+${selectedProduct.realPrice}`}
                    <Media src={coinImg} className="buy-page-coins-coin" />
                  </span>
                </>
              ) : (
                <>
                  &nbsp;{selectedProduct.price}
                  &nbsp;EUR
                </>
              )
          }
        </div>
        <div>
          {selectedProduct.descriptionId && (
            t(selectedProduct.descriptionId)
          )}
        </div>
      </div>

      <Row className="payment-type-row">
        {Object.keys(constants.PAYMENT_TYPES).map((key) => {
          if (!devMode && constants.PAYMENT_TYPES[key].id === constants.PAYMENT_TYPES.textMessage.id) {
            return null;
          }

          return(
            <PaymentRadioCol
              key={constants.PAYMENT_TYPES[key].id}
              xs="3"
              paymentOption={constants.PAYMENT_TYPES[key]}
              title={t(`paymentType.${constants.PAYMENT_TYPES[key].id}Title`)}
              image={PaymentTypeImageMap[`${constants.PAYMENT_TYPES[key].id}-${screenMode}`]}
              onClick={setSelectedPaymentOption}
              checked={selectedPaymentOption.id === constants.PAYMENT_TYPES[key].id}
              extraPrice={constants.PAYMENT_TYPES[key].id === constants.PAYMENT_TYPES.textMessage.id && '1.49'}
            // hidden={!devMode && constants.PAYMENT_TYPES[key].id === constants.PAYMENT_TYPES.textMessage.id}
            />
          )
        })}

      </Row>
      <Row className="payment-type-row pt-1">
        <Col className="payment-type-col">
          <Media src={infoIconImg} style={{ display: 'inline-block', marginRight: '6px', width: '24px', height: '24px' }} />
          {t('paymentType.info1')}
          <span
            onClick={toggleAboutWrapper}
            className={`payment-type-link ${newDesign ? `payment-type-link-${screenMode}` : 'payment-type-link-normal'}`}
          >
            {t('paymentType.info1_2')}
          </span>
        </Col>
      </Row>
      <Row className="payment-type-row pt-1">
        <Col className="payment-type-col">
          <Media src={infoIconImg} style={{ display: 'inline-block', marginRight: '6px', width: '24px', height: '24px' }} />
          {t('paymentType.info2')}
        </Col>
      </Row>
      <Row className="payment-type-row">
        <Col className="payment-type-col text-center">
          <div className="custom-checkbox">
            <label>
              <input type="checkbox" checked={checkedTerms} onChange={toggleCheckedTerms} />

              <span className={`checkmark ${!newDesign && "checkmark-old"}`}></span>
              <span className={`payment-type-terms ${!newDesign && "payment-type-terms-old"}`}>
                {t('paymentType.iAgreeTo')}
                <span
                  onClick={toggleTermsWrapper}
                  className={`payment-type-link ${newDesign ? `payment-type-link-${screenMode}` : `payment-type-link-normal`}`}
                >
                  {t('paymentType.termsAndConditions')}
                </span>
              </span>
            </label>
          </div>
        </Col>
      </Row>
      {termsOpen && (
        <Modal container={'div > div'} size="lg" isOpen={termsOpen} toggle={toggleTermsWrapper} className={`old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={toggleTermsWrapper}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={toggleTermsWrapper} />
            }
          >{t('home.termsOfUse')}</ModalHeader>
          <ModalBody style={{ height: 450 }}>
            <ScrollArea
              keyboardShouldPersistTaps="handled"
              speed={0.55}
              className="chat-body-scroll-area"
              contentClassName="chat-body-scroll-content"
              verticalContainerStyle={{
                background: 'transparent',
                opacity: 1,
                width: 10,
              }}
              verticalScrollbarStyle={{
                background: '#fff',
                borderRadius: 1,
                width: 7,
                minHeight: 10,
                minScrollSize: 25,
              }}
              horizontal={false}
            >
              <div dangerouslySetInnerHTML={{ __html: termsOfText[i18n.language || constants.sendLanguage.lv] }} />
            </ScrollArea>
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button type="button" className="btn notification-footer-button" onClick={toggleTermsWrapper}>
              {t('home.close')}
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {aboutOpen && (
        <Modal container={'div > div'} className={`about-modal old-modal root-font-${fontMode}`} size="lg" isOpen={aboutOpen} toggle={toggleAboutWrapper} >
          <ModalHeader
            className="about-modal-header"
            toggle={toggleAboutWrapper}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={toggleAboutWrapper} />
            }
          >
            {t('menu.aboutPage').toUpperCase()}
            <div className="about-modal-header-version">
              {t('home.zoleGameVersion')}
              {currentVersion}
            </div>
          </ModalHeader>
          <ModalBody className="about-modal-body">
            <hr />

            <div className="about-modal-body-column">
              <div className="about-modal-body-column-section">
                <p>{t('common.developer')}:</p>
                <a target="_blank" rel="noopener noreferrer" href="https://draxogames.com/">SIA 'Draxo Games'</a>
              </div>
              <div className="about-modal-body-column-section">
                <p>{t('common.regNum')}:</p>
                <span>40103927834</span>
              </div>
              <div className="about-modal-body-column-section">
                <p>{t('common.address')}:</p>
                <span>Liepājas 49-6, Kuldīga, LV-3301</span>
              </div>
            </div>

            <div className="about-modal-body-column">
              <div className="about-modal-body-column-section">
                <p>WEB:</p>
                <a target="_blank" rel="noopener noreferrer" href="https://spelezoli.lv/">https://spelezoli.lv/</a>
              </div>
              <div className="about-modal-body-column-section">
                <p>{t('home.email')}:</p>
                <a target="_blank" rel="noopener noreferrer" href="mailto: info@draxogames.com">info@draxogames.com</a>
              </div>
              <div className="about-modal-body-column-section">
                <p>{t('common.phoneNum')}:</p>
                <a href="tel:+37126622677">+371 26 62 26 77</a>
              </div>
            </div>

            <hr />

            <div className="about-modal-apps">
              <Media src={appStore} onClick={handleAppStore} className="about-modal-apps-img" />
              <Media src={playStore} onClick={handlePlayStore} className="about-modal-apps-img" />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="btn notification-footer-button about-modal-close-button" onClick={toggleAboutWrapper}>
              {t('common.close')}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}
PaymentTypeContent.propTypes = {
  selectedProduct: PropTypes.shape.isRequired,
  selectedPaymentOption: PropTypes.shape.isRequired,
  setSelectedPaymentOption: PropTypes.func.isRequired,
  screenMode: PropTypes.string,
  fontMode: PropTypes.string,
  checkedTerms: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  screenMode: state.member.screenMode ? state.member.screenMode : constants.SCREEN_MODE.normal,
});

export default connect(mapStateToProps)(withTranslation('common')(PaymentTypeContent));
