import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import closeImg from '../../../images/icons/close.png';

import { setCheckedVersion } from '../../../actions/member';

import {
  FONT_MODE, NEW_VERSION,
} from '../../../constants/constants';

import config from '../../../constants/config';

const isInAppFrame = config.isInAppFrame();

class NewVersion extends React.Component {
  static propTypes = {
    newVersion: PropTypes.number,
    t: PropTypes.func.isRequired,
    setCheckedVersionFunc: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    openChangeLogVersionModal: PropTypes.bool,
    closeTriggerChangeLogModal: PropTypes.func.isRequired,
    hidden: PropTypes.bool,
  }

  static defaultProps = {
    newVersion: null,
    fontMode: FONT_MODE.normal,
    openChangeLogVersionModal: false,
    hidden: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      version: NEW_VERSION || '3.10.1',
    };
  }

  closeModal = () => {
    const { closeTriggerChangeLogModal, setCheckedVersionFunc } = this.props;
    const { version } = this.state;
    closeTriggerChangeLogModal();
    setCheckedVersionFunc(version);
  }

  render() {
    const { newVersion, t, i18n, fontMode, setCheckedVersionFunc, openChangeLogVersionModal, hidden } = this.props;
    const { version } = this.state;

    console.log('newVersion modal', openChangeLogVersionModal, newVersion, version);

    if (hidden) {
      return null;
    }

    if ((!newVersion || newVersion >= version) && !openChangeLogVersionModal) {
      if (!newVersion) {
        console.log('setCheckedVersionFunc');
        setCheckedVersionFunc(version);
      }
      return null;
    }

    return (
      <Modal container={'div > div'} isOpen size="lg" className={`${isInAppFrame ? 'modal-is-app-frame' : ''} notification new-version-modal old-modal root-font-${fontMode}`}>
        <ModalHeader
          toggle={this.closeModal}
          className="notification-header"
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeModal} />
            }
        />
        <ModalBody className="notification-body">
          <div className="new-version-modal-scrollarea">
            {(!i18n.language || i18n.language === 'lv') ? (
              <>
                <h2 className="new-version-header">Sveiki, zolmaņi!</h2>
                <p className="new-version-body">Pēdējā laikā esam strādājuši pie kārtējā spēles atjauninājuma, kurā ir iekļauti šādi jaunumi un labojumi:</p>
                <ul className="new-version-body">
                  <li> Draugiem.lv WEB versijā ir pārstrādāts skats, kā tiek attēlota izvēlne ‘Jauna istaba', tādējādi palielinot aktīvo spēles lauku (gaidām viedokli par šāda veida izmaiņām).</li>
                  <li> Izlabotas attēlošanas problēmas istabu sarakstā, kur istabas parādījās ar lielu kavēšanos vai pēc lapas pārlādes.</li>
                  <li> Izstājoties no turnīra, spēlētājam ir iespēja ievadīt pamešanas iemeslu, tādējādi mūs informējot par nepieciešamiem uzlabojumiem.</li>
                  <li> Pēc turnīra pēdējās kārtas pabeigšanas tagad ir iespējams uzreiz piedalīties istabās, pat ja turnīrs vēl nav beidzies.</li>
                  <li> Var tikt piemērota soda nauda arī tad, kad administrācija izsaka brīdinājumu par rupjību vai agresiju čatā.</li>
                  <li> Novērsta problēma ar nepareizu lielo/mazo burtu attēlošanu vairākos sarakstos.</li>
                  <li> Spēle ir atkal pieejama Facebook platformā.</li>
                  <li> Vairāki saraksti tagad ir sadalīti pa lapām (piemēram: turnīri, spēļu izraksti, draugi, ignorētie un bloķētie spēlētāji).</li>
                  <li> Saņemto dāvanu tagad ir iespējams noņemt arī ar pogu pie profila attēla.</li>
                  <li> Jaunajā dizainā novērsta atļauto kāršu attēlošanas problēma mobilajās ierīcēs.</li>
                  <li> Dažādi uzlabojumi.</li>
                </ul>
                <p className="new-version-body">Jūsu Zoles administrācija</p>
              </>
            ) : (null)}


            {(i18n.language === 'en') ? (
              <>
                <h2 className="new-version-header">Hello players!</h2>

                <p className="new-version-body">We've recently been working on a regular update to the game that includes the following news and fixes:</p>
                <ul className="new-version-body">
                  <li> Draugiem.lv WEB version has a redesigned view of how the 'New Room' menu is displayed, thus increasing the active playing field (we are waiting for feedback on this type of change).</li>
                  <li> Fixed an issue with the display of the room list, in which rooms appeared with a long delay or after page reload.</li>
                  <li> When leaving a tournament, a player now has the option to indicate the reason for withdrawing, thus informing us of the necessary improvements.</li>
                  <li> Now you can play in casual rooms immediately after the end of the last round of the tournament, even if the tournament has not yet closed.</li>
                  <li> Penalty may also be imposed if the administration issues a warning for rudeness or aggression in the chat.</li>
                  <li> Fixed a bug with incorrect display of uppercase/lowercase letters in some lists.</li>
                  <li> The game is available again on the Facebook platform.</li>
                  <li> Некоторые списки теперь разделены на страницы (например: Турниры, Журналы игр, Мои друзья, Игнор-лист и Забаненные игроки).</li>
                  <li> Some lists are now divided into pages (for example: Tournaments, Game Logs, My Friends, Ignored and Blocked Players).</li>
                  <li> You can now also delete a received gift using the button next to your profile image</li>
                  <li> On mobile devices in the New design is fixed an issue with the display of allowed cards.</li>
                  <li> Various improvements.</li>
                </ul>
                <p className="new-version-body">Your Zole Administration</p>

              </>
            ) : (null)}



            {(i18n.language === 'ru') ? (
              <>
                <h2 className="new-version-header">Здравствуйте, уважаемый игрок!</h2>

                <p className="new-version-body">Недавно мы закончили работу над очередным обновлением игры, которое включает в себя следующие улучшения и исправления:</p>
                <ul className="new-version-body">
                  <li> В WEB-версии Draugiem.lv переработано отображение меню "Новая комната", что позволило увеличить активное игровое поле (ждём ваше мнение по данному типу изменений).</li>
                  <li> Решена проблема с отображением списка комнат, в котором комнаты появлялись с большой задержкой или после перезагрузки страницы.</li>
                  <li> При выходе из турнира игрок теперь имеет возможность указать причину выхода, так информируя нас о необходимых улучшениях.</li>
                  <li> Теперь можно сразу после завершения последнего раунда турнира играть в обычных комнатах, даже если турнир еще не завершился.</li>
                  <li> Штраф может быть наложен также в случае вынесения администрацией предупреждения за грубость или агрессию в чате.</li>
                  <li> Исправлен баг с неправильным отображением заглавных/строчных букв в некоторых списках.</li>
                  <li> Игра снова доступна на платформе Facebook.</li>
                  <li> Некоторые списки теперь разделены на страницы (например: Турниры, Журналы игр, Мои друзья, Игнор-лист и Забаненные игроки).</li>
                  <li> Удалить полученный подарок теперь возможно также с помощью кнопки рядом с аватаркой.</li>
                  <li> На мобильных устройствах в новом дизайне исправлена ​​проблема с отображением разрешенных карт.</li>
                  <li> Различные усовершенствования.</li>
                </ul>
                <p className="new-version-body">Ваша администрация</p>
              </>
            ) : (null)}
          </div>
        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button color="link" className="notification-footer-button" onClick={this.closeModal}>{t('common.ok')}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  newVersion: state.member.newVersion || null,
});

const mapDispatchToProps = {
  setCheckedVersionFunc: setCheckedVersion,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(NewVersion));
