import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Col, Row, Button, Label, Alert, Form, FormGroup, Media } from 'reactstrap';
import { TextInput } from '../Components/Components/TextInput';
import FacebookAuthComponent from './FacebookAuthComponent';
import Spinner from 'reactstrap/lib/Spinner';

import * as constants from '../../../../constants/constants';

import draLoginImg from '../../../../images/redesign/common/draugiem.svg';
import fbLoginImg from '../../../../images/redesign/common/facebook.svg';
import googleLoginImg from '../../../../images/redesign/common/google.svg';
import { errorMessages } from '../../../../constants/messages';

import ErrorNotification from '../Components/Components/ErrorNotification';

const LoginFormComponent = ({ runFacebookAuth, stopFacebookAuth, passFacebookAuth, failFacebookAuth, checkFacebookLogin, history, email, password, FB, loading, FBLogin, googleLogin, draLogin, messageType, success, stateSuccess, error, stateError, handleSubmit, handleInputChange, toggleForgotPassword, toggleSignUp }) => {

  const { t } = useTranslation('common');

  return (
    <>
      <div className="new-design-login-container-body-content-section-form-with">
        <div className="title mb-2">
          <Label>{t('common.login')}</Label>
        </div>
        <div className="social">
          {/* <Button className={classNames('social-button', {'disabled': !FB || loading})} onClick={FBLogin}>
            <img src={fbLoginImg} alt="x" />
            <Label>{t('buyPage.facebook')}</Label>
          </Button> */}
          <FacebookAuthComponent runFacebookAuth={runFacebookAuth} stopFacebookAuth={stopFacebookAuth} passFacebookAuth={passFacebookAuth} failFacebookAuth={failFacebookAuth} checkFacebookLogin={checkFacebookLogin} loading={loading} t={t} history={history} />
          <Button className={classNames('social-button', { 'disabled': loading })} onClick={googleLogin}>
            <img src={googleLoginImg} alt="x" />
            <Label>{t('buyPage.google')}</Label>
          </Button>
          <Button className={classNames('social-button', { 'disabled': loading })} onClick={draLogin}>
            <img src={draLoginImg} alt="x" />
            <Label>{t('buyPage.draugiem')}</Label>
          </Button>
        </div>
      </div>

      <Row className="new-design-login-container-body-content-section-form-input">
        <Col lg={{ size: 12, offset: 12 }} className="input-col">

          {
            // messageType === constants.stages.login && (!!success || !!stateSuccess) && ? (
            //   <Alert className="input-col-message" color="success">{t(`member.${success}`)}</Alert>
            // ) : 
            // messageType === constants.stages.login && !!error ? (
            //   <Alert className="input-col-message" color="danger">{t(`member.${error}`)}</Alert>
            // ) : (null)
          }
          <Form onSubmit={handleSubmit} className="common-form">
            <FormGroup>
              <TextInput
                width='100%'
                contentEditable
                inputType={constants.PROFILE_FIELDS.email}
                secondaryPlaceholder={t('home.email')}
                initialValue={email}
                onChange={(value, type) => handleInputChange(value, type)}
                maxLength={100}
              />
              {
                messageType === constants.stages.login && (stateError === errorMessages.missingEmail || stateError === errorMessages.wrongEmail) && (
                  <Alert className="input-col-message" color="danger">{t(`member.${error || stateError}`)}</Alert>
                )
              }
            </FormGroup>
            <FormGroup>
              <TextInput
                type={constants.PROFILE_FIELDS.password}
                width='100%'
                contentEditable
                inputType={constants.PROFILE_FIELDS.password}
                secondaryPlaceholder={t('home.pass')}
                initialValue={password}
                onChange={(value, type) => handleInputChange(value, type)}
                maxLength={100}
              />
              {
                messageType === constants.stages.login && ((stateError === errorMessages.missingPassword && stateError !== errorMessages.wrongEmail)) && (
                  <Alert className="input-col-message" color="danger">{t(`member.${error || stateError}`)}</Alert>
                )
              }
            </FormGroup>

            <Row>
              <Col className="text-left login-section">
                <Button className="layout-highlighted-button top-settings-new-section-login-button login-section-button d-flex align-items-center" disabled={loading}>
                  {loading ? <>{t('home.loading')}<Spinner type="grow" style={{ width: '1rem', height: '1rem' }} /></> : t('home.login')}
                </Button>
              </Col>
              <Col className="text-right d-flex align-items-end forgot-password-button" onClick={toggleForgotPassword}>
                {t('home.resetPassword')}
              </Col>
            </Row>
          </Form>
        </Col>

        <ErrorNotification
          text={t(`member.${error || stateError}`)}
          isVisible={stateError === errorMessages.wrongPasswordServer || stateError === errorMessages.useNotFoundServer || stateError === errorMessages.unverifiedEmail}
        />
        <ErrorNotification
          text={t(`member.${success}`)}
          isVisible={success === errorMessages.recoverSuccess}
          isSuccess
          isAlert
        />

      </Row>

      <div className="new-design-login-container-body-content-section-form-footer">
        <div className="section">
          <Label>{t('home.notRegistered')}</Label>
          <Button className="footer-section-button" onClick={toggleSignUp}>{t('home.register')}</Button>
        </div>
      </div>
    </>
  );
};

LoginFormComponent.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  FB: PropTypes.shape({}),
  loading: PropTypes.bool,
  FBLogin: PropTypes.func.isRequired,
  googleLogin: PropTypes.func.isRequired,
  draLogin: PropTypes.func.isRequired,
  messageType: PropTypes.string,
  success: PropTypes.string,
  stateSuccess: PropTypes.string,
  error: PropTypes.string,
  stateError: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  toggleForgotPassword: PropTypes.func.isRequired,
  toggleSignUp: PropTypes.func.isRequired,
  checkFacebookLogin: PropTypes.func.isRequired,
  passFacebookAuth: PropTypes.func.isRequired,
  failFacebookAuth: PropTypes.func.isRequired,
  stopFacebookAuth: PropTypes.func.isRequired,
  runFacebookAuth: PropTypes.func.isRequired,
};

LoginFormComponent.defaultProps = {
  email: '',
  password: '',
  FB: null,
  loading: false,
  messageType: null,
  success: '',
  stateSuccess: '',
  error: '',
  stateError: '',
};

export default LoginFormComponent;
