import { leaveRoom } from '../../../actions/room';

export const leaveRoomm = (key) => {
  return async (dispatch) => {
    try {
      await dispatch(leaveRoom(key));
      dispatch({ type: 'LEFT_ROOM_N', data: true });
    } catch ({ message }) {
      alert(`leaveRoom error: ${message}`);
    }
  };
};

export const clearLeaveRoom = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'LEFT_ROOM_N', data: false });
    } catch ({ message }) {
      alert(`leaveRoom error: ${message}`);
    }
  };
};

export const setPrivateRoomPasswordAction = (password) => {
  return async (dispatch) => {
    dispatch({ type: 'SET_PRIVATE_ROOM_PASSWORD', data: password });
  };
};
