import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import { connect } from 'react-redux';

import { verifyEmail, afterEmailLogin, updateProfileResetEmail } from '../../../actions/member';

import { Firebase, FirebaseRef } from '../../../lib/firebase';

import * as constants from '../../../constants/constants';

class EmailVerify extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    updateProfileResetEmailFunc: PropTypes.func.isRequired,
    afterLogin: PropTypes.func.isRequired,
  }
  // static defaultProps = {}

  constructor(props) {
    super(props);
    this.state = {
      mode: null,
      lang: constants.sendLanguage.lv,
      newPassword: '',
      uid: '',
      customToken: '',
      actionCode: '',
      screenHeight: window.innerHeight,
      verifyError: false,
    };
  }

  componentDidMount() {
  //  const { history, verifyUserEmail } = this.props;
    const { search } = window.location;

    window.addEventListener('resize', this.handleResize);

    let mode;
    let actionCode;
    let continueUrl;
    let lang;
    let uid;
    let customToken;
    let resetCode;

    try {
      const urlParams = new URLSearchParams(search);
      mode = urlParams.get('mode');
      actionCode = urlParams.get('oobCode');
      continueUrl = urlParams.get('continueUrl');
      lang = urlParams.get('lang') || constants.sendLanguage.lv;
      uid = urlParams.get('uid') || '';
      customToken = urlParams.get('customToken') || '';
      resetCode = urlParams.get('resetCode') || '';
    } catch (err) {
      mode = this.getParameterByName('mode');
      actionCode = this.getParameterByName('oobCode');
      continueUrl = this.getParameterByName('continueUrl');
      lang = this.getParameterByName('lang') || 'en';
      uid = '';
      customToken = '';
      resetCode = this.getParameterByName('resetCode') || '';
    }

    this.setState({ mode, actionCode, lang, uid, customToken });

    console.log('mode', mode, actionCode, continueUrl, lang, uid);

    switch (mode) {
      case 'resetPassword':
        // Display reset password handler and UI.
        this.handleResetPassword(actionCode, continueUrl, lang, uid);
        break;
      case 'recoverEmail':
        // Display email recovery handler and UI.
        this.handleRecoverEmail(actionCode, continueUrl, lang, uid, customToken, resetCode);
        break;
      case 'verifyEmail':
        // Display email verification handler and UI.
        this.handleVerifyEmail(actionCode, continueUrl, lang, uid, customToken);
        break;
      default:
      // Error: invalid mode.
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ screenHeight: window.innerHeight });
  };

  handleResetPassword = (actionCode, continueUrl, lang) => {
    const { history, i18n } = this.props;
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    //  var accountEmail;
    // Verify the password reset code is valid.
    Firebase.auth().verifyPasswordResetCode(actionCode).then(async (email) => {
      const accountEmail = email;

      try {
        if (lang && window && window.localStorage && window.localStorage.setItem) { //x
          localStorage.setItem('language', lang); //x
        }
      } catch (err) {
        console.log(err);
      }

      if (lang) {
        i18n.changeLanguage(lang);
      }

      setTimeout(() => {
        history.push(`/reset-password?email=${email}&actionCode=${actionCode}&lang=${lang}`);
      }, 200);

    //  history.push(`/reset-password?email=${email}&actionCode=${actionCode}&lang=${lang}`);
      // TODO: Show the reset screen with the user's email and ask the user for
      // the new password.

      this.setState({ accountEmail, actionCode });
    }).catch((error) => {
      console.log(error);
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
    });
  }

  submitResetPassword = () => {
    console.log('submitResetPassword');
    const { history } = this.props;
    const { newPassword, actionCode } = this.state;

    console.log(newPassword);
    console.log(actionCode);

    if (newPassword && newPassword.length > 6) {
      // Save the new password.
      Firebase.auth().confirmPasswordReset(actionCode, newPassword).then((resp) => {
        // Password reset has been confirmed and new password updated.

        history.push('/landing');

        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      }).catch(function(error) {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      });
    }
  }

  handleRecoverEmail = async (actionCode, continueUrl, lang, uid, customToken, resetCode) => {
    const { restoreUsersEmailToPrevious, updateProfileResetEmailFunc, history } = this.props;
    console.log('handleRecoverEmail', actionCode, customToken, resetCode, uid);
  //  const { history } = this.props;
    // Localize the UI to the selected language as determined by the lang
    // parameter.
   // let restoredEmail = null;
    // Confirm the action code is valid.
  //  Firebase.auth().checkActionCode(actionCode).then((info) => {
      // Get the restored email address.
    //  console.log('checkActionCode', info);
    //  restoredEmail = info['data']['email'];

    //  console.log(restoredEmail);

    //  await Firebase.auth().signInWithCustomToken(customToken);

    //  restoreUsersEmailToPrevious();
    await updateProfileResetEmailFunc(uid, resetCode).then(async (res) => {
      console.log('updateProfileResetEmailFunc res', res, resetCode, uid);
      if (res && res.status === 'success') {
        await Firebase.auth().signInWithCustomToken(customToken);
        history.push('/');
      } else {
        this.setState({ verifyError: true });
      }

    //  signInWithEmailLink(auth, email, window.location.href)
    //    .then((result) => {
          // Clear email from storage.


    //   })
    //    .catch((error) => {

     //   });
    }).catch((err) => {
      this.setState({ verifyError: true });
    });

      // Revert to the old email.
    //  return Firebase.auth().applyActionCode(actionCode);
   // }).then(() => {
      // Account email reverted to restoredEmail
   //   console.log('Account email reverted to restoredEmail', restoredEmail);

    //  this.setState({ emailRecoverySuccess: true, emailRecoveryError: null });

      // TODO: Display a confirmation message to the user.

      // You might also want to give the user the option to reset their password
      // in case the account was compromised:
    //  Firebase.auth().sendPasswordResetEmail(restoredEmail).then(() => {
        // Password reset confirmation sent. Ask user to check their email.
    //  }).catch(function(error) {
        // Error encountered while sending password reset code.
    //  });
   /* }).catch((error) => {
      console.log('error', error);
      // Invalid code.

      this.setState({ emailRecoverySuccess: null, emailRecoveryError: true });
    }); */
  }

  handleVerifyEmail = (actionCode, continueUrl, lang, uid, customToken) => {
    const { history, afterLogin } = this.props;
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    Firebase.auth().applyActionCode(actionCode).then(async (resp) => {
      // Email address has been verified.
      await Firebase.auth().signInWithCustomToken(customToken);

      try {
        if (lang && window && window.localStorage && window.localStorage.setItem) { //x
          localStorage.setItem('language', lang); //x
        }
      } catch (err) {
        console.log(err);
      }

      await FirebaseRef.child(`users/${uid.toString()}/verifiedEmail`).set(true);

      setTimeout(() => {
        FirebaseRef.child(`users/${uid.toString()}/verifiedEmail`).set(true);
        afterLogin();
        history.push('/email-verify-success');
      }, 1000);
    }).catch((error) => {
      this.setState({ verifyError: true });
    });
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  toStart = () => {
    const { history } = this.props;

    history.push('/login');
  }

  render() {
    const { t } = this.props;
    const { mode, emailRecoverySuccess, emailRecoveryError, screenHeight, verifyError } = this.state;
    const labelStyle = { paddingTop: screenHeight/2 + 'px', paddingBottom: '5px', position: 'relative', textAlign: 'center', color: '#FFF' };
    return (
      <>
        <div style={labelStyle}>
          { (mode === 'verifyEmail' || mode === 'recoverEmail') && (
            <>
              {verifyError ? t('menu.emailVerifyError') : t('menu.emailVerify')}
            </>
          )}
        </div>
        <div>
          {/* { mode === 'recoverEmail' && ( */}
            <Button color="link" className="email-verify-button" onClick={this.toStart}>
              {t('route.login')}
            </Button>
          {/* )} */}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = {
  verifyUserEmail: verifyEmail,
  updateProfileResetEmailFunc: updateProfileResetEmail,
  afterLogin: afterEmailLogin,
//  restoreUsersEmailToPrevious: restoreUsersEmail,
};

export default withTranslation('common', 'route')(withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailVerify)));
