import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { FORMAT_DATE_TYPE } from '../../../constants/constants';
import { withTranslation } from 'react-i18next';

const CountDownDate = React.memo(({ date, newDate, showOption, t, showWithoutText }) => {

  console.log('CountDownDate', date, newDate);

  const startDate = moment(new Date(newDate));
  const endDate = moment(new Date(date));

  const duration = moment.duration(endDate.diff(startDate));
  const days = duration._data.days;
  const hours = duration._data.hours;
  const minutes = duration._data.minutes;
  const seconds = duration._data.seconds;

  console.log('CountDownDate 2', minutes, seconds);

  let countDown = '';

  if (showWithoutText) {
    if (days && days > 0) {
      countDown += (days < 10 ? `0${days}` : days) + ':';
    }
    if (hours && hours > 0) {
      countDown += (hours < 10 ? `0${hours}` : hours) + ':';
    }
    if (minutes && minutes > 0) {
      countDown += (minutes < 10 ? `0${minutes}` : minutes) + ':';
    } else {
      countDown += '00:';
    }
    if (seconds && seconds > 0) {
      countDown += (seconds < 10 ? `0${seconds}` : seconds);
    } else {
      countDown += '00';
    }
  } else {

    if (days && days > 0) {
      countDown += days + `${showOption === FORMAT_DATE_TYPE.cursorExpireTime ? t('common.daysShort') : showOption === 'admin_report' ? 'd' : t('common.daysShort')}` + ' ';
    }
    if (hours && hours > 0) {
      countDown += hours + `${showOption === FORMAT_DATE_TYPE.cursorExpireTime ? t('common.hoursShort') : showOption === 'admin_report' ? 'h' : t('common.hoursShort')}` + ' ';
    }
    if (minutes && minutes > 0) {
      countDown += minutes + `${showOption === FORMAT_DATE_TYPE.cursorExpireTime ? t('common.minutesShort') : showOption === 'admin_report' ? 'min' : t('common.minutesShort')}` + ' ';
    }
    if (showOption !== FORMAT_DATE_TYPE.cursorExpireTime && showOption !== 'admin_report') {
      if (seconds && seconds > 0) {
        countDown += seconds + 'Seconds' + ' ';
      }
    }

    if (minutes === 0 && showOption === 'admin_report') {
      if (seconds && seconds > 0) {
        countDown += seconds + ' Seconds' + ' ';
      }
    }
  }

  console.log('countDown', countDown);

  return (
    <div>
      {countDown}
    </div>
  );
});

CountDownDate.propTypes = {
  date: PropTypes.number,
  newDate: PropTypes.number,
  showOption: PropTypes.string,
};

CountDownDate.defaultProps = {
  date: null,
  newDate: null,
  showOption: null,
};

export default withTranslation("common")(CountDownDate);

