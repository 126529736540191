import { filter, size, includes } from 'lodash';
import {
  TOUR_FIRST_PAGE,
  TOUR_EXTRA_BEFORE_PAGES,
  TOUR_PAGE_LIMIT,
  TOUR_EXTRA_PAGES,
  TOUR_STEP_TYPES,
  getTourSteps,
} from '../constants/constants';

export const getTourStateLoad = () => (dispatch) => {
  return dispatch({ type: 'TOUR_LOAD' });
};
export const getNewTourStateLoad = () => (dispatch) => {
  return dispatch({ type: 'TOUR_NEW_LOAD' });
};
export const getNewTourStateLoadMobile = () => (dispatch) => {
  return dispatch({ type: 'TOUR_NEW_LOAD_MOBILE' });
};
export const getNewTourStateLoadMobileIFrame = () => (dispatch) => {
  return dispatch({ type: 'TOUR_NEW_LOAD_MOBILE_IFRAME' });
};
export const getNewTourStateLoadMobileDesktop = () => (dispatch) => {
  return dispatch({ type: 'TOUR_NEW_LOAD_MOBILE_DESKTOP' });
};
export const getNewTourStateLoadMobilePortrait = () => (dispatch) => {
  return dispatch({ type: 'TOUR_NEW_LOAD_MOBILE_PORTRAIT' });
};
export const nextTourPage = (index, step) => (dispatch) => {
  if (step.stepType + 1 <= TOUR_PAGE_LIMIT) {
    if (TOUR_EXTRA_BEFORE_PAGES.includes(step.stepType)) {
      return dispatch({ type: 'TOUR_NEXT_PAGE', steps: filter(getTourSteps(TOUR_STEP_TYPES.old), ['stepType', step.stepType + 1]), stepIndex: TOUR_FIRST_PAGE });
    }
    return dispatch({ type: 'TOUR_NEXT_PAGE', steps: filter(getTourSteps(TOUR_STEP_TYPES.old), ['stepType', step.stepType + 1]), stepIndex: 0 });
  }
  return dispatch({ type: 'TOUR_NEXT_PAGE', steps: step, stepIndex: index });
};
export const nextNewTourPage = (index, step) => (dispatch) => {
  if (step.stepType + 1 <= TOUR_PAGE_LIMIT) {
    if (TOUR_EXTRA_BEFORE_PAGES.includes(step.stepType)) {
      return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: filter(getTourSteps(TOUR_STEP_TYPES.new), ['stepType', step.stepType + 1]), stepIndex: TOUR_FIRST_PAGE });
    }
    return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: filter(getTourSteps(TOUR_STEP_TYPES.new), ['stepType', step.stepType + 1]), stepIndex: 0 });
  }
  return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: step, stepIndex: index });
};

export const nextTourStep = (index, step) => (dispatch) => {
  return dispatch({ type: 'TOUR_NEXT_STEP', step, index });
};

export const prevTourStep = (index, step) => (dispatch) => {
  if (index.toString() && step) {
    if (TOUR_EXTRA_PAGES.includes(step.stepType) && index === TOUR_FIRST_PAGE) {
      return dispatch({ type: 'TOUR_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.old), ['stepType', step.stepType - 1]), stepIndex: size(filter(getTourSteps(TOUR_STEP_TYPES.old), ['stepType', step.stepType - 1])) - 1 });
    }

    if (!TOUR_EXTRA_PAGES.includes(step.stepType) && index === 0) {
      return dispatch({ type: 'TOUR_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.old), ['stepType', step.stepType - 1]), stepIndex: size(filter(getTourSteps(TOUR_STEP_TYPES.old), ['stepType', step.stepType - 1])) - 1 });
    }
    return dispatch({ type: 'TOUR_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.old), ['stepType', step.stepType]), stepIndex: index - 1 });
  }
};
export const prevNewTourStep = (index, step) => (dispatch) => {
  if (index.toString() && step) {
    if (TOUR_EXTRA_PAGES.includes(step.stepType) && index === TOUR_FIRST_PAGE) {
      return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.new), ['stepType', step.stepType - 1]), stepIndex: size(filter(getTourSteps(TOUR_STEP_TYPES.new), ['stepType', step.stepType - 1])) - 1 });
    }

    if (!TOUR_EXTRA_PAGES.includes(step.stepType) && index === 0) {
      return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.new), ['stepType', step.stepType - 1]), stepIndex: size(filter(getTourSteps(TOUR_STEP_TYPES.new), ['stepType', step.stepType - 1])) - 1 });
    }
    return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.new), ['stepType', step.stepType]), stepIndex: index - 1 });
  }
};

export const stopTour = (index, step) => (dispatch) => {
  return dispatch({ type: 'TOUR_STOP', step, index });
};

export const startTour = (index, step) => (dispatch) => {
  return dispatch({ type: 'TOUR_START', step, index });
};

export const startNewTour = (index, step) => (dispatch) => {
  return dispatch({ type: 'TOUR_NEW_START', step, index });
};

export const startNewTourMobile = (index, step) => (dispatch) => {
  return dispatch({ type: 'TOUR_NEW_START_MOBILE', step, index });
};

export const startNewTourMobileIFrame = (index, step) => (dispatch) => {
  return dispatch({ type: 'TOUR_NEW_START_MOBILE_IFRAME', step, index });
};

export const startNewTourMobileDesktop = (index, step) => (dispatch) => {
  return dispatch({ type: 'TOUR_NEW_START_MOBILE_DESKTOP', step, index });
};

export const startNewTourMobilePortrait = (index, step) => (dispatch) => {
  return dispatch({ type: 'TOUR_NEW_START_MOBILE_PORTRAIT', step, index });
};
// export const prevTourPage = (index, step) => (dispatch) => {
//   return dispatch({ type: 'TOUR_BACK_PAGE', step, index});
// };

export const nextNewMobileTourPage = (index, step) => (dispatch) => {
  if (step.stepType + 1 <= TOUR_PAGE_LIMIT) {
    if (TOUR_EXTRA_BEFORE_PAGES.includes(step.stepType)) {
      return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobile), ['stepType', step.stepType + 1]), stepIndex: TOUR_FIRST_PAGE });
    }
    return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobile), ['stepType', step.stepType + 1]), stepIndex: 0 });
  }
  return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: step, stepIndex: index });
};

export const prevNewMobileTourStep = (index, step) => (dispatch) => {
  if (index.toString() && step) {
    if (TOUR_EXTRA_PAGES.includes(step.stepType) && index === TOUR_FIRST_PAGE) {
      return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobile), ['stepType', step.stepType - 1]), stepIndex: size(filter(getTourSteps(TOUR_STEP_TYPES.newMobile), ['stepType', step.stepType - 1])) - 1 });
    }

    if (!TOUR_EXTRA_PAGES.includes(step.stepType) && index === 0) {
      return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobile), ['stepType', step.stepType - 1]), stepIndex: size(filter(getTourSteps(TOUR_STEP_TYPES.newMobile), ['stepType', step.stepType - 1])) - 1 });
    }
    return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobile), ['stepType', step.stepType]), stepIndex: index - 1 });
  }
};

export const nextNewMobileIFrameTourPage = (index, step) => (dispatch) => {
  if (step.stepType + 1 <= TOUR_PAGE_LIMIT) {
    if (TOUR_EXTRA_BEFORE_PAGES.includes(step.stepType)) {
      return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobileIFrame), ['stepType', step.stepType + 1]), stepIndex: TOUR_FIRST_PAGE });
    }
    return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobileIFrame), ['stepType', step.stepType + 1]), stepIndex: 0 });
  }
  return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: step, stepIndex: index });
};

export const prevNewMobileIFrameTourStep = (index, step) => (dispatch) => {
  if (index.toString() && step) {
    if (TOUR_EXTRA_PAGES.includes(step.stepType) && index === TOUR_FIRST_PAGE) {
      return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobileIFrame), ['stepType', step.stepType - 1]), stepIndex: size(filter(getTourSteps(TOUR_STEP_TYPES.newMobileIFrame), ['stepType', step.stepType - 1])) - 1 });
    }

    if (!TOUR_EXTRA_PAGES.includes(step.stepType) && index === 0) {
      return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobileIFrame), ['stepType', step.stepType - 1]), stepIndex: size(filter(getTourSteps(TOUR_STEP_TYPES.newMobileIFrame), ['stepType', step.stepType - 1])) - 1 });
    }
    return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobileIFrame), ['stepType', step.stepType]), stepIndex: index - 1 });
  }
};

export const nextNewMobileDesktopTourPage = (index, step) => (dispatch) => {
  if (step.stepType + 1 <= TOUR_PAGE_LIMIT) {
    if (TOUR_EXTRA_BEFORE_PAGES.includes(step.stepType)) {
      return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobileDesktop), ['stepType', step.stepType + 1]), stepIndex: TOUR_FIRST_PAGE });
    }
    return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobileDesktop), ['stepType', step.stepType + 1]), stepIndex: 0 });
  }
  return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: step, stepIndex: index });
};

export const prevNewMobileDesktopTourStep = (index, step) => (dispatch) => {
  if (index.toString() && step) {
    if (TOUR_EXTRA_PAGES.includes(step.stepType) && index === TOUR_FIRST_PAGE) {
      return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobileDesktop), ['stepType', step.stepType - 1]), stepIndex: size(filter(getTourSteps(TOUR_STEP_TYPES.newMobileDesktop), ['stepType', step.stepType - 1])) - 1 });
    }

    if (!TOUR_EXTRA_PAGES.includes(step.stepType) && index === 0) {
      return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobileDesktop), ['stepType', step.stepType - 1]), stepIndex: size(filter(getTourSteps(TOUR_STEP_TYPES.newMobileDesktop), ['stepType', step.stepType - 1])) - 1 });
    }
    return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobileDesktop), ['stepType', step.stepType]), stepIndex: index - 1 });
  }
};

export const nextNewMobilePortraitTourPage = (index, step) => (dispatch) => {
  if (step.stepType + 1 <= TOUR_PAGE_LIMIT) {
    if (TOUR_EXTRA_BEFORE_PAGES.includes(step.stepType)) {
      return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobilePortrait), ['stepType', step.stepType + 1]), stepIndex: TOUR_FIRST_PAGE });
    }
    return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobilePortrait), ['stepType', step.stepType + 1]), stepIndex: 0 });
  }
  return dispatch({ type: 'TOUR_NEW_NEXT_PAGE', steps: step, stepIndex: index });
};

export const prevNewMobilePortraitTourStep = (index, step) => (dispatch) => {
  if (index.toString() && step) {
    if (TOUR_EXTRA_PAGES.includes(step.stepType) && index === TOUR_FIRST_PAGE) {
      return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobilePortrait), ['stepType', step.stepType - 1]), stepIndex: size(filter(getTourSteps(TOUR_STEP_TYPES.newMobilePortrait), ['stepType', step.stepType - 1])) - 1 });
    }

    if (!TOUR_EXTRA_PAGES.includes(step.stepType) && index === 0) {
      return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobilePortrait), ['stepType', step.stepType - 1]), stepIndex: size(filter(getTourSteps(TOUR_STEP_TYPES.newMobilePortrait), ['stepType', step.stepType - 1])) - 1 });
    }
    return dispatch({ type: 'TOUR_NEW_PREV_STEP', steps: filter(getTourSteps(TOUR_STEP_TYPES.newMobilePortrait), ['stepType', step.stepType]), stepIndex: index - 1 });
  }
};
