import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { withRouter } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import {
  isAndroid,
  isIOS,
  isIOS13,
} from 'react-device-detect';

import { withTranslation } from 'react-i18next';

import {
  FONT_MODE,
} from '../../constants/constants';

const ANDROID_PATH_lv = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=lv';
const ANDROID_PATH_en = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=en';
const ANDROID_PATH_ru = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=ru';
const IOS_PATH_lv = 'https://apps.apple.com/lv/app/id1505526877';
const IOS_PATH_en = 'https://apps.apple.com/us/app/id1505526877';
const IOS_PATH_ru = 'https://apps.apple.com/ru/app/id1505526877';

class MobileDetector extends Component {

  static propTypes = {
    t: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
  };

  static defaultProps = {
    fontMode: FONT_MODE.normal,
  };

    state = {
      modalViewed: false,
      // os: null,
    }

    openMobile = (os) => {
    /*  if (isAndroid) {
      window.location.href =
        window.parent.location = ANDROID_PATH;
    }else if(isIOS) {
      window.location.href =
        window.parent.location = IOS_PATH;
    } else{
      window.location.href =
        window.parent.location = ANDROID_PATH;
    } */

      const { i18n } = this.props;

      try {
        if (os === 'android') {
          if (i18n && i18n.language) {
            if (i18n.language === 'lv') {
              window.parent.location = ANDROID_PATH_lv;
            } else if (i18n.language === 'en') {
              window.parent.location = ANDROID_PATH_en;
            } else if (i18n.language === 'ru') {
              window.parent.location = ANDROID_PATH_ru;
            } else {
              window.parent.location = ANDROID_PATH_lv;
            }
          } else {
            window.parent.location = ANDROID_PATH_lv;
          }
        } else if (os === 'ios') {
          if (i18n && i18n.language) {
            if (i18n.language === 'lv') {
              window.parent.location = IOS_PATH_lv;
            } else if (i18n.language === 'en') {
              window.parent.location = IOS_PATH_en;
            } else if (i18n.language === 'ru') {
              window.parent.location = IOS_PATH_ru;
            } else {
              window.parent.location = IOS_PATH_lv;
            }
          } else {
            window.parent.location = IOS_PATH_lv;
          }
        }
      } catch (err) {
        console.log(err);

        if (os === 'android') {
          if (i18n.language) {
            if (i18n.language === 'lv') {
              window.parent.location = ANDROID_PATH_lv;
            } else if (i18n.language === 'en') {
              window.parent.location = ANDROID_PATH_en;
            } else if (i18n.language === 'ru') {
              window.parent.location = ANDROID_PATH_ru;
            } else {
              window.parent.location = ANDROID_PATH_lv;
            }
          } else {
            window.parent.location = ANDROID_PATH_lv;
          }
        } else if (os === 'ios') {
          if (i18n.language) {
            if (i18n.language === 'lv') {
              window.parent.location = IOS_PATH_lv;
            } else if (i18n.language === 'en') {
              window.parent.location = IOS_PATH_en;
            } else if (i18n.language === 'ru') {
              window.parent.location = IOS_PATH_ru;
            } else {
              window.parent.location = IOS_PATH_lv;
            }
          } else {
            window.parent.location = IOS_PATH_lv;
          }
        }
      }
    }

    closeModal = () => {
      this.setState({ modalViewed: true });
    }

    renderContent = () => {
      const { modalViewed } = this.state;
      const { t, fontMode } = this.props;
      let renderModal = false;
      let os;
      const history = window.location.pathname || '';
      if (!modalViewed) {
        if (isAndroid) {
          if (history && !history.includes('admin')) {
            renderModal = true;
          }
          os = 'android';
        }

        if (isIOS || isIOS13) {
          if (history && !history.includes('admin')) {
            renderModal = true;
          }
          os = 'ios';
        }

        console.log('renderModal', renderModal, os);

        if (renderModal) {
          return (
            <Modal container="div" size="sm" isOpen={renderModal} toggle={this.closeModal} className={`mobile-modal old-modal root-font-${fontMode}`}>
              <ModalHeader toggle={this.closeModal} />
              <ModalBody>
                <p className="mobile-modal-text">{t('common.downloadMobileAppQuiz')}</p>
              </ModalBody>
              <ModalFooter>
                <Button className="mobile-modal-button" onClick={() => this.openMobile(os)}>{t('common.yes')}</Button>
                <Button className="mobile-modal-button" onClick={this.closeModal}>{t('common.no')}</Button>
              </ModalFooter>
            </Modal>
          );
        }

        return null;
      }
      return null;
    }

    render() {
    //  const { isMobile, os } = this.state;

      return this.renderContent();
    /*  return (
        <div className="internet-error-wrapper">
          {isMobile && (
            <Modal isOpen={isMobile} toggle={this.closeModal} className="internet-error">
              <ModalHeader toggle={this.closeModal}></ModalHeader>
              <ModalBody>
                <p className="internet-error-text">Ir pieejama spēles mobilā versija</p>
                <p> {os} </p>
              </ModalBody>
              <ModalFooter>
                <Button className="internet-error-button" onClick={this.openMobile}> Atvērt </Button>
                <Button className="internet-error-button" onClick={this.closeModal}> Palikt </Button>
              </ModalFooter>
            </Modal>

          )}
        </div>
      ); */
    }
}

export default withTranslation('common')(MobileDetector);
