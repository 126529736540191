export const getPlayersArranged = (players, myPos, sittingOut) => {
  const playersArranged = [];

  if (players && players.playerList) {
    if (
      sittingOut &&
      players.player1 &&
      players.player2 &&
      players.player3 &&
      players.player4
    ) {
    //  console.log('getPlayersArranged');
    //  console.log(sittingOut);
    //  console.log(players);

      //  0 =  'player-left'; nākosais
      //  1 = 'player-firstperson'; es
      //  2 = 'player-right'; iepriekšējais
      //  3 = 'player-sittingOut';

    //  if (sittingOut === 'player1') {
        if (myPos === 'player1') {
          playersArranged[0] = { ...players.player2, position: 'player2' };
          playersArranged[1] = { ...players.player1, position: 'player1' };
          playersArranged[2] = { ...players.player4, position: 'player4' };
          playersArranged[3] = { ...players.player3, position: 'player3' };
        } else if (myPos === 'player2') {
          playersArranged[0] = { ...players.player3, position: 'player3' };
          playersArranged[1] = { ...players.player2, position: 'player2' };
          playersArranged[2] = { ...players.player1, position: 'player1' };
          playersArranged[3] = { ...players.player4, position: 'player4' };
        } else if (myPos === 'player3') {
          playersArranged[0] = { ...players.player4, position: 'player4' };
          playersArranged[1] = { ...players.player3, position: 'player3' };
          playersArranged[2] = { ...players.player2, position: 'player2' };
          playersArranged[3] = { ...players.player1, position: 'player1' };
        } else if (myPos === 'player4') {
          playersArranged[0] = { ...players.player1, position: 'player1' };
          playersArranged[1] = { ...players.player4, position: 'player4' };
          playersArranged[2] = { ...players.player3, position: 'player3' };
          playersArranged[3] = { ...players.player2, position: 'player2' };
        }
    /*  } else if (sittingOut === 'player2') {
        if (myPos === 'player1') {
          playersArranged[0] = { ...players.player3, position: 'player3' };
          playersArranged[1] = { ...players.player1, position: 'player1' };
          playersArranged[2] = { ...players.player4, position: 'player4' };
          playersArranged[3] = { ...players.player2, position: 'player2' };
        } else if (myPos === 'player2') {
          playersArranged[0] = { ...players.player3, position: 'player3' };
          playersArranged[1] = { ...players.player2, position: 'player2' };
          playersArranged[2] = { ...players.player1, position: 'player1' };
          playersArranged[3] = { ...players.player4, position: 'player4' };
        } else if (myPos === 'player3') {
          playersArranged[0] = { ...players.player4, position: 'player4' };
          playersArranged[1] = { ...players.player3, position: 'player3' };
          playersArranged[2] = { ...players.player1, position: 'player1' };
          playersArranged[3] = { ...players.player2, position: 'player2' };
        } else if (myPos === 'player4') {
          playersArranged[0] = { ...players.player1, position: 'player1' };
          playersArranged[1] = { ...players.player4, position: 'player4' };
          playersArranged[2] = { ...players.player3, position: 'player3' };
          playersArranged[3] = { ...players.player2, position: 'player2' };
        }
      } else if (sittingOut === 'player3') {
        if (myPos === 'player1') {
          playersArranged[0] = { ...players.player2, position: 'player2' };
          playersArranged[1] = { ...players.player1, position: 'player1' };
          playersArranged[2] = { ...players.player4, position: 'player4' };
          playersArranged[3] = { ...players.player3, position: 'player3' };
        } else if (myPos === 'player2') {
          playersArranged[0] = { ...players.player4, position: 'player4' };
          playersArranged[1] = { ...players.player2, position: 'player2' };
          playersArranged[2] = { ...players.player1, position: 'player1' };
          playersArranged[3] = { ...players.player3, position: 'player3' };
        } else if (myPos === 'player3') {
          playersArranged[0] = { ...players.player4, position: 'player4' };
          playersArranged[1] = { ...players.player3, position: 'player3' };
          playersArranged[2] = { ...players.player2, position: 'player2' };
          playersArranged[3] = { ...players.player1, position: 'player1' };
        } else if (myPos === 'player4') {
          playersArranged[0] = { ...players.player1, position: 'player1' };
          playersArranged[1] = { ...players.player4, position: 'player4' };
          playersArranged[2] = { ...players.player2, position: 'player2' };
          playersArranged[3] = { ...players.player3, position: 'player3' };
        }
      } else if (sittingOut === 'player4') {
        if (myPos === 'player1') {
          playersArranged[0] = { ...players.player2, position: 'player2' };
          playersArranged[1] = { ...players.player1, position: 'player1' };
          playersArranged[2] = { ...players.player3, position: 'player3' };
          playersArranged[3] = { ...players.player4, position: 'player4' };
        } else if (myPos === 'player2') {
          playersArranged[0] = { ...players.player3, position: 'player3' };
          playersArranged[1] = { ...players.player2, position: 'player2' };
          playersArranged[2] = { ...players.player1, position: 'player1' };
          playersArranged[3] = { ...players.player4, position: 'player4' };
        } else if (myPos === 'player3') {
          playersArranged[0] = { ...players.player1, position: 'player1' };
          playersArranged[1] = { ...players.player3, position: 'player3' };
          playersArranged[2] = { ...players.player2, position: 'player2' };
          playersArranged[3] = { ...players.player4, position: 'player4' };
        } else if (myPos === 'player4') {
          playersArranged[0] = { ...players.player1, position: 'player1' };
          playersArranged[1] = { ...players.player4, position: 'player4' };
          playersArranged[2] = { ...players.player3, position: 'player3' };
          playersArranged[3] = { ...players.player2, position: 'player2' };
        }
      } */
    } else if (
      !sittingOut &&
      players.player1 &&
      players.player2 &&
      players.player3
    ) {
      if (myPos === 'player1') {
        playersArranged[0] = { ...players.player2, position: 'player2' };
        playersArranged[1] = { ...players.player1, position: 'player1' };
        playersArranged[2] = { ...players.player3, position: 'player3' };
      } else if (myPos === 'player2') {
        playersArranged[0] = { ...players.player3, position: 'player3' };
        playersArranged[1] = { ...players.player2, position: 'player2' };
        playersArranged[2] = { ...players.player1, position: 'player1' };
      } else if (myPos === 'player3') {
        playersArranged[0] = { ...players.player1, position: 'player1' };
        playersArranged[1] = { ...players.player3, position: 'player3' };
        playersArranged[2] = { ...players.player2, position: 'player2' };
      }
    }
  }
  return playersArranged;
};
