import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import Lottie from 'react-lottie';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import TabPane from 'reactstrap/lib/TabPane';
import TabContent from 'reactstrap/lib/TabContent';
// import Progress from 'reactstrap/lib/Progress';

import closeImg from '../../images/icons/close.png';
import winnerAnimImg from '../../images/Game/winner.json';
import loserAnimImg from '../../images/Game/loser.json';

import winSound from '../../sounds/game_win.mp3';
import loseSound from '../../sounds/game_lose.mp3';

import {
  FONT_MODE,
  MENU_NAVIGATION,
} from '../../constants/constants';

class Notification extends React.Component {
  static propTypes = {
    leaveRoom: PropTypes.func,
    closeModal: PropTypes.func.isRequired,
    closeResultModal: PropTypes.func,
    gameResult: PropTypes.shape(),
    memberUid: PropTypes.string,
    openModal: PropTypes.bool,
    modalType: PropTypes.string,
    openGameResultModal: PropTypes.bool,
    ignoredMessageName: PropTypes.string,
    t: PropTypes.func.isRequired,
    switchTabs: PropTypes.func,
    newLevel: PropTypes.string,
    oldLevel: PropTypes.string,
    buyMoney: PropTypes.func,
    closeLevelNotification: PropTypes.func.isRequired,
    setShowEndResultModal: PropTypes.func,
    soundOn: PropTypes.bool,
    fontMode: PropTypes.string,
    endResultWasClosed: PropTypes.bool,
    toggleGiftsModal: PropTypes.func,
  }

  static defaultProps = {
    gameResult: null,
    modalType: '',
    ignoredMessageName: '',
    newLevel: '',
    oldLevel: '',
    memberUid: null,
    openModal: false,
    soundOn: false,
    fontMode: FONT_MODE.normal,
    endResultWasClosed: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      playWinAnim: false,
      levels: '',
    };

    this.closeModal = this.closeModal.bind(this);
    this.closeResultModal = this.closeResultModal.bind(this);
    this.closeLevelUpNotification = this.closeLevelUpNotification.bind(this);
    this.buyMoney = this.buyMoney.bind(this);
    this.toggle = this.toggle.bind(this);

    this.winAudio = new Audio(winSound);
    this.loseAudio = new Audio(loseSound);
  }

  closeModal() {
    const { closeModal, modalType } = this.props;
    console.log('closeModal');

    if (modalType === 'levelUp') {
      this.closeLevelUpNotification();
    } else {
      closeModal();
    }
  }

  closeLevelUpNotification() {
    const { closeLevelNotification, closeModal } = this.props;

    closeLevelNotification().then(() => {
      closeModal();
    });
  }

  closeResultModal() {
    console.log('closeResultModal');
    const { closeResultModal, closeModal, closeLevelNotification } = this.props;

    closeModal();
    closeResultModal();
  }

  buyMoney() {
    const { closeModal, switchTabs } = this.props;
    switchTabs(MENU_NAVIGATION.buyPageMoneyPacks);
    closeModal();
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      leaveRoom,
      t,
      i18n,
      gameResult,
      buyMoney,
      newLevel,
      oldLevel,
      modalType,
      openModal,
      ignoredMessageName,
      insufficientBalanceAmount,
      insufficientBalanceBet,
      memberUid,
      openGameResultModal,
      soundOn,
      setShowEndResultModal,
      leavePenalty,
      minGames,
      rPlayed,
      lastRound,
      fontMode,
      endResultWasClosed,
      toggleGiftsModal,
    } = this.props;
    const {
      activeTab,
      playWinAnim,
      levels,
    } = this.state;

    let levelsArr = [];
    if (modalType === 'levelUp' && levels === '') {
      // let initialLevel = oldLevel ? oldLevel : newLevel - 1;
      const extraDot = i18n.language === 'lv' ? '.' : '';
      const initialLevel = (oldLevel && oldLevel < newLevel) ? oldLevel : newLevel - 1;
      for (let i = initialLevel + 1; i <= newLevel; i++) {
        levelsArr.push(`${i}${extraDot}`);
      }
      this.setState({ levels: levelsArr.join(', ') });
    }

    if (!!gameResult && !playWinAnim) {
      setTimeout(() => {
        if (soundOn) {
          if (gameResult && gameResult.type !== 'pass') {
            if (gameResult.winnerUID && gameResult.winnerUID.includes(memberUid)) {
              try {
                  const playPromise = this.winAudio.play();
                  console.log("win win win", playPromise)
                  if (playPromise !== undefined) {
                    playPromise
                      .then(_ => {
                        // Automatic playback started!
                        // Show playing UI.
                        console.log("audio played auto");
                      })
                      .catch(error => {
                        // Auto-play was prevented
                        // Show paused UI.
                        console.log("playback prevented");
                      });
                  }
              } catch (err) {
                console.log(err);
              }
            } else {
              try {
               const playPromise = this.loseAudio.play();
                if (playPromise !== undefined) {
                  playPromise
                    .then(_ => {
                      // Automatic playback started!
                      // Show playing UI.
                      console.log("audio played auto");
                    })
                    .catch(error => {
                      // Auto-play was prevented
                      // Show paused UI.
                      console.log("playback prevented");
                    });
                }
              } catch (err) {
                console.log(err);
              }
            }
          }
        }
        this.setState({ playWinAnim: true });
      }, 750);
    } else if (!gameResult && playWinAnim) {
      this.setState({ playWinAnim: false });
    }

    const remainingParties = minGames - rPlayed;
    return (
      <div>
        <Modal container={'div > div'} isOpen={(openModal && !gameResult) || (openModal && !openGameResultModal) || (openModal && modalType/* && modalType !== 'levelUp' */)} toggle={this.closeModal} className={`notification old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={this.closeModal}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeModal} />
            }
          >
            {
              modalType && modalType === 'levelUp' ? (
                t('common:common.congratulations')
              ) : (
                t('common:home.notifications')
              )
            }
          </ModalHeader>
          <ModalBody className="notification-body">
            {/*  {modalType && modalType === 'noBalance' && (
              <div>
                {t('noMoneyDescription')}
              </div>
            )}  */}

            {/*  {modalType && modalType === 'lowBalance' && (
              <div>
                {`Spēlētajam ${lowBalPlayer} nepietiek naudas lai turpinātu, istaba tiks aizvērta`}
              </div>
            )} */}

            {modalType && modalType === 'lowBalanceTournament' && (
              <div>
                PIRKT
              </div>
            )}

            {modalType && modalType === 'noBalanceMenu' && (
              <div>
                {t('noMoneyMenuDescription', { amount: insufficientBalanceAmount, bet: insufficientBalanceBet })}
              </div>
            )}

            {modalType && modalType === 'noBalanceTournament' && (
              <div>
                {t('noBalanceTournament')}
              </div>
            )}

            {modalType && modalType === 'proRoom' && (
              <div>
                {t('onlyProDescription')}
              </div>
            )}

            {modalType && modalType === 'proRoomMenu' && (
              <div>
                {t('onlyProDescription')}
              </div>
            )}

            {modalType && modalType === 'emailNotVerified' && (
              <div>
                {t('emailNotVerified')}
              </div>
            )}

            {modalType && modalType === 'proBetMenu' && (
              <div>
                {t('onlyProBet')}
              </div>
            )}

            {modalType && modalType === 'leaveRoom' && (
              <div>
                {t('leaveRoomWarning', { leavePenalty })}
              </div>
            )}

            {modalType && modalType === 'leaveRoomNotStarted' && (
              <div>
                {t('leaveRoomNotStarted')}
              </div>
            )}


            {modalType && modalType === 'tournamentEnd' && (
              <div>
                {t('tournamentEnd')}
              </div>
            )}

            {modalType && modalType === 'gameError' && (
              <div>
                {t('gameError')}
              </div>
            )}

            {modalType && modalType === 'youIgnoredPlayer' && (
              <div>
                {t('youIgnoredPlayer', { player: ignoredMessageName })}
              </div>
            )}

            {modalType && modalType === 'playerIgnoredYou' && (
              <div>
                {t('playerIgnoredYou', { player: ignoredMessageName })}
              </div>
            )}

            {modalType && modalType === 'lowBalanceGift' && (
              <div>
                {t('lowBalanceGift')}
              </div>
            )}

            {modalType && modalType === 'levelUp' && (
              <Fragment>
                <div>
                  {t('newLevel2', { levels: levels })}
                </div>
              </Fragment>
            )}
          </ModalBody>
          <ModalFooter className="notification-footer">

            {modalType && modalType === 'leaveRoom' && (
              <Fragment>
                <Button className="notification-footer-button" onClick={leaveRoom}>{t('yes')}</Button>
                <Button className="notification-footer-button" onClick={this.closeModal}>{t('no')}</Button>
              </Fragment>
            )}

            {modalType && modalType === 'leaveRoomNotStarted' && (
              <Fragment>
                <Button className="notification-footer-button" onClick={leaveRoom}>{t('yes')}</Button>
                <Button className="notification-footer-button" onClick={this.closeModal}>{t('no')}</Button>
              </Fragment>
            )}

            {modalType && (modalType === 'proRoomMenu' || modalType === 'proRoom' || modalType === 'proBetMenu' || modalType === 'emailNotVerified') && (
              <Button className="notification-footer-button" color="primary" onClick={this.closeModal}>{t('ok')}</Button>
            )}

            {modalType && modalType === 'noBalance' && (
              <Button className="notification-footer-button" color="primary" onClick={this.closeModal}>{t('buyMoney')}</Button>
            )}

            {modalType && modalType === 'lowBalance' && (
              <Fragment>
                <Button className="notification-footer-button" color="primary" onClick={leaveRoom}>{t('common:common.leave')}</Button>
              </Fragment>
            )}

            {modalType && modalType === 'lowBalanceTournament' && (
              <Fragment>
                <Button className="notification-footer-button" color="primary" onClick={() => buyMoney(false)}>{t('buyMoney')}</Button>
                <Button className="notification-footer-button" color="primary" onClick={leaveRoom}>{t('common:common.leave')}</Button>
              </Fragment>
            )}

            {modalType && (modalType === 'noBalanceMenu' || modalType === 'noBalanceTournament') && (
              <Button className="notification-footer-button" color="primary" onClick={this.buyMoney}>{t('buyMoney')}</Button>
            )}

            {modalType && modalType === 'tournamentEnd' && (
              <Fragment>
                <Link to="/" className="btn notification-footer-button">
                  {t('exit')}
                </Link>
              </Fragment>
            )}

            {modalType && modalType === 'gameError' && (
              <Fragment>
                <Button onClick={leaveRoom} className="btn notification-footer-button">
                  {t('exit')}
                </Button>
              </Fragment>
            )}

            {modalType && modalType === 'lowBalanceGift' && (
              <Button className="notification-footer-button" color="primary" onClick={() => {this.closeModal(); toggleGiftsModal();}}>{t('ok')}</Button>
            )}

            {modalType && modalType === 'youIgnoredPlayer' && (
              <Button className="notification-footer-button" color="primary" onClick={this.closeModal}>{t('ok')}</Button>
            )}

            {modalType && modalType === 'playerIgnoredYou' && (
              <Button className="notification-footer-button" color="primary" onClick={this.closeModal}>{t('ok')}</Button>
            )}

            {modalType && modalType === 'levelUp' && (
              <Button className="notification-footer-button" color="primary" onClick={this.closeLevelUpNotification}>{t('ok')}</Button>
            )}

          </ModalFooter>
        </Modal>

        <Modal container={'div > div'} isOpen={openGameResultModal && !!gameResult/* && !endResultWasClosed && modalType !== 'lastRound' */} toggle={this.closeResultModal} className={`notification gameResultNotification root-font-${fontMode}`}>
          <ModalHeader
            className="notification-header game-result-animate-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeResultModal} />
            }
          >

            {gameResult && (
            <>
              {gameResult.type === 'parasta' && (
                `${t('large')} ${gameResult.winner === 'large' ? (t('wins')) : (t('loses'))}`
              )}
              {gameResult.type === 'zole' && (
                `${t('large')} ${gameResult.winner === 'large' ? (t('wins')) : (t('loses'))} ${t('zoli')}`
              )}
              {gameResult.type === 'mazaZole' && (
                `${gameResult.largePlayer} ${gameResult.winner === 'large' ? (t('wins')) : (t('loses'))} ${t('mazoZoli')}`
              )}
              {gameResult.type === 'galdins' && (
                `${gameResult.winner} ${t('loses')} ${t('table')}`
              )}
              {gameResult.type === 'pass' && (
                <div>
                  {`${t('allPassed')}`}
                </div>
              )}
            </>
            )}
          </ModalHeader>
          <ModalBody className="notification-body">
            <TabContent activeTab={activeTab}>
              <Fragment>
                {gameResult && gameResult.scoreType && (
                <TabPane className="notification-body-tab" tabId="1">
                  <Row>
                    <Col sm="12">
                      <Fragment>
                        {(gameResult.type === 'parasta' || gameResult.type === 'zole') && (
                        <Fragment>
                          {gameResult.scoreType === 'parasts' && gameResult.winner === 'large' && (
                            `${t('largeWinsWith')} ${gameResult.largePoints} ${t('points')}`
                          )}
                          {gameResult.scoreType === 'parasts' && gameResult.winner === 'small' && (
                            `${t('largeLosesWith')} ${gameResult.largePoints} ${t('points')}`
                          )}
                          {gameResult.scoreType === 'jani' && gameResult.winner === 'large' && (
                            `${t('largeWinsWith')} ${gameResult.largePoints} ${t('points')}`
                          )}
                          {gameResult.scoreType === 'jani' && gameResult.winner === 'small' && (
                            `${t('largeLosesWith')} ${gameResult.largePoints} ${t('points')}`
                          )}
                          {gameResult.scoreType === 'bezstikis' && gameResult.winner === 'large' && (
                            `${t('largeWinsWith')} ${t('noTricks')}`
                          )}
                          {gameResult.scoreType === 'bezstikis' && gameResult.winner === 'small' && (
                            `${t('largeLosesWith')} ${t('noTricks')}`
                          )}
                        </Fragment>
                        )}
                        {gameResult.type === 'mazaZole' && (
                        <Fragment>
                          {gameResult.winner === 'large' && (
                            `${t('largeWin!')}`
                          )}
                          {gameResult.winner === 'small' && (
                            `${t('smallWin!')}`
                          )}
                        </Fragment>
                        )}
                        {gameResult.type === 'galdins' && (
                          `${gameResult.largeTricks} ${t('tricks')} (+${gameResult.largePoints})`
                        )}
                      </Fragment>

                    </Col>
                  </Row>
                </TabPane>
                )}

                {gameResult && gameResult.type === 'pass' && (
                  <>
                    { remainingParties === 1 
                      ? lastRound
                        ? <div className="mb-1">{`${t('allPassedBodyMinGamesOne')}`}</div>
                        : <div className="mb-1">{`${t('allPassedBody')} ${t('allPassedLastGameNotPressed')}`}</div>
                      : <div className="mb-1">{`${t('allPassedBody')}`}</div>
                    }

                    {(gameResult.cardsOnTable && gameResult.cardsOnTable.cards) ? (
                      <div className="notification-cards">
                        <div className="notification-cards-text">
                          {' '}
                          {`${t('cardsOnTable')}`}
                          {' '}
                        </div>
                        <div className={`notification-cards-card notification-cards-card-${gameResult.cardsOnTable.cards[0]}`} />
                        <div className={`notification-cards-card notification-cards-card-${gameResult.cardsOnTable.cards[1]}`} />
                      </div>
                    ) : null}
                  </>
                )}

                {gameResult && gameResult.type !== 'pass' && (
                <Fragment>
                  {(gameResult && gameResult.winnerUID && gameResult.winnerUID.includes(memberUid)) ? (
                    <div
                      className="winner-anim-image"
                    >
                      <Lottie
                        options={{
                          loop: false,
                          autoplay: false,
                          animationData: winnerAnimImg,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={202}
                        width={450}
                        isStopped={false}
                        isPaused={!playWinAnim}
                      />
                    </div>
                  ) : (
                    <div
                      className="winner-anim-image"
                    >
                      <Lottie
                        options={{
                          loop: false,
                          autoplay: false,
                          animationData: loserAnimImg,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={202}
                        width={450}
                        isStopped={false}
                        isPaused={!playWinAnim}
                      />
                    </div>
                  )}
                </Fragment>
                )}
              </Fragment>
            </TabContent>
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="notification-footer-button" color="link" onClick={this.closeResultModal}>{t('ok')}</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withTranslation(['notifications', 'common'])(Notification);
