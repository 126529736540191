import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { map, size, round } from 'lodash';

import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Media from 'reactstrap/lib/Media';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import ScrollArea from 'react-scrollbar';
import Message from '../AdminSupportMessage';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { connect } from 'react-redux';

import Moment from 'react-moment';
import moment from 'moment';
import * as constants from '../../../../constants/constants';
import { errorMessages } from '../../../../constants/messages';

import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

import DateFormat from '../../UI/DateFormat';
import closeImg from '../../../../images/icons/close.png';

import {
  getAllFeedbacks,
  deleteFeedback,
  checkFeedbackTime,
  startFeedback,
  stopFeedback,
  getUserMessages,
  cancelUserMessages,
  setSupportMessageAsResponded,
  supportMessageFileUpload,
  answerSupportMessage,
} from '../../../../actions/admin';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

moment.locale('lv');
momentLocalizer();

class FeedbackUserMessage extends React.Component {
  static propTypes = {
    loading: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    getUserMessages: PropTypes.func.isRequired,
    chatMessages: PropTypes.shape({}),
    setSupportMessageAsResponded: PropTypes.func.isRequired,
    supportMessageFileUpload: PropTypes.func.isRequired,
    answerSupportMessage: PropTypes.func.isRequired,
    cancelUserMessages: PropTypes.func.isRequired,
    uid: PropTypes.string,
    name: PropTypes.string,
    isOpen: PropTypes.bool,
    toggleChatModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    chatMessages: {},
    uid: '',
    name: '',
    isOpen: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      openChatModal: this.props.isOpen || false,
      openChatModalName: this.props.name || '',
      openChatModalUid: this.props.uid || '',
      fileName: '',
      fileUploadUrl: '',
      inputMessage: '',
      ableToSend: true,
    };
    this.hiddenFileInput = React.createRef(null);
  }

  componentDidMount() {
    const { getUserMessages, name, uid } = this.props;

    getUserMessages(uid).then(() => {
      //  this.scrollToBottom();
    });

    this.setState({
      openChatModal: this.props.isOpen || false,
      openChatModalName: name,
      openChatModalUid: uid,
      fileName: '',
      fileUploadUrl: '',
      //  uid,
    });
  }

  submitMessage = () => {
    const { answerSupportMessage } = this.props;
    const {
      inputMessage, openChatModalUid, fileUploadUrl, fileName,
    } = this.state;
    if (inputMessage != '' || fileUploadUrl != '') {
      answerSupportMessage({
        uid: openChatModalUid, message: inputMessage, fileUploadUrl, fileName,
      }).then(() => {
        //  this.scrollToBottom();
        this.setState({ inputMessage: '', fileUploadUrl: '', fileName: '' });
      });
    }
  }

  toggleChat = () => {
    const { toggleChatModal } = this.props;
    const { openChatModal, openChatModalUid } = this.state;

    if (openChatModal) {
      const { cancelUserMessages } = this.props;

      cancelUserMessages(openChatModalUid);
    }

    this.setState(prevState => ({
      openChatModal: false,
      openChatModalUid: '',
    }));

    if (toggleChatModal) {
      toggleChatModal();
    }
  }

  handleChangeChat = (event) => {
    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

      this.setState({
        [event.target.name]: value,
      });
    }
  }

  handleEnter = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.submitMessage();
    }
  }

  _handleFileClick = () => {
    this.hiddenFileInput.current.click();
  };

  chooseFile = (e) => {
    e.preventDefault();

    const { showNotification, t } = this.props;
    const target = e.target.files[0];
    const typeList = '.jpg,.png,.pdf,.doc,.rtf,.txt,ain';
    if (!target || (target && target.length === 0)) return;
    if (!typeList.includes((target.type).substring((target.type).length - 3)) || target.type == '') { return showNotification('Error!', "Unsupported file!", 'danger'); }
    if (target.size / constants.ALLLOW_FILE.unitSize > constants.ALLLOW_FILE.allowSize) {
      showNotification('Warning', "Can't upload more than 1.5 MB", 'warning');
      this.setState({
        fileUploadUrl: '',
        fileName: '',
      });
    } else {
      this.setState({
        ableToSend: false,
      });
      const { supportMessageFileUpload } = this.props;
      supportMessageFileUpload(target).then((data) => {
        this.setState({
          fileUploadUrl: data.url,
          fileName: target.name,
          ableToSend: true,
        });
        showNotification('Success', "File successfully attached!", 'success');
      });
    }
  }

  setSupportMessageAsResponded = () => {
    const { setSupportMessageAsResponded } = this.props;
    const { openChatModalUid } = this.state;

    setSupportMessageAsResponded({ uid: openChatModalUid }).then(() => {
      this.setState({
        openChatModal: false,
        //  uid: '',
      });
    });
  }

  render() {
    const {
      t,
      chatMessages,
      chatMessagesUserLastLanguage,
    } = this.props;
    const {
      openChatModal,
      openChatModalName,
      openChatModalUid,
      inputMessage,
      fileName,
      ableToSend,
    } = this.state;
    // console.log({feedbackStartTime}, "3");
    return (
      <Modal container={'div > div'} isOpen={openChatModal} toggle={this.toggleChat}>
        <ModalHeader toggle={this.toggleChat}>
          {`Chat - ${openChatModalName} - ${openChatModalUid}`}
        </ModalHeader>
        <ModalBody>
          <Row className="chat-body">
            <Col className="chat-body-wrapper" md="12">
              <ScrollArea
                speed={0.65}
                className="admin-chat-scroll-area"
                contentClassName="admin-chat-scroll-content"
                //  smoothScrolling
                verticalContainerStyle={{
                  background: 'transparent',
                  opacity: 1,
                  width: 10,
                }}
                verticalScrollbarStyle={{
                  background: '#fff',
                  borderRadius: 2,
                  width: 6,
                  minHeight: 30,
                  minScrollSize: 35,
                }}
                horizontal={false}
                ref={(el) => { this.messagesScrollbar = el; }}
              >
                {chatMessages && Object.keys(chatMessages).map(key => (
                  <Row key={key}>
                    <Col md="12">
                      <Message message={chatMessages[key]} chatMessagesUserLastLanguage={chatMessagesUserLastLanguage} />
                    </Col>
                  </Row>
                ))}
              </ScrollArea>
            </Col>
          </Row>
          <Row className="chat-footer" style={{ height: 60 }}>
            <Form style={{ width: '100%', height: '100%' }}>
              <Col md="12" style={{ height: '100%' }}>
                <Input
                  className="h-100 chat-footer-input chat-footer-input-admin"
                  type="textarea"
                  name="inputMessage"
                  id="inputMessage"
                  autoComplete="off"
                  placeholder='Write Message...'
                  style={{

                  }}
                  value={inputMessage}
                  onChange={this.handleChangeChat}
                  onKeyPress={this.handleEnter}
                />

              </Col>
            </Form>
          </Row>
          <Row>
            <Button
              className="btn-warning admin-file-upload-button"
              onClick={this._handleFileClick}
            >
              {fileName === '' ? '+ Choose File' : fileName}
            </Button>
            <input
              id="files"
              hidden
              ref={this.hiddenFileInput}
              className="chat-footer-input chat-footer-input-admin admin-file-upload-input"
              accept=".jpg,.png,.pdf,.doc,.rtf,.txt"
              type="file"
              onChange={this.chooseFile}
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={this.setSupportMessageAsResponded}>Mark As Read</Button>
          <Button className="contact-support-footer-button" disabled={!ableToSend} color="primary" onClick={this.submitMessage}>
            {/* {t('send')} */}
            Send
          </Button>
          <Button type="button" color="secondary" onClick={this.toggleChat}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  chatMessages: state.admin.chatMessages || [],
  chatMessagesUserLastLanguage: state.admin.chatMessagesUserLastLanguage,
});

const mapDispatchToProps = {
  fetchAllFeedbacks: getAllFeedbacks,
  getUserMessages: getUserMessages,
  cancelUserMessages: cancelUserMessages,
  setSupportMessageAsResponded: setSupportMessageAsResponded,
  supportMessageFileUpload: supportMessageFileUpload,
  answerSupportMessage: answerSupportMessage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('admin')(FeedbackUserMessage));
