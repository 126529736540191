import { Platform } from 'react-native';
import Store from '../store/userSettings';

export const initialState = Store;

export default function userSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_NEW_GAME_PARAM': {
      console.log('userSettings case TOGGLE_NEW_GAME_PARAM', state[action.uid]);
      if (state && !state[action.uid]) {
        if (action.data === 'parasta' || action.data === 'G') {
          return {
            ...state,
            [action.uid]: {
              ...state.default,
              parasta: !state.default.parasta,
              G: !state.default.G,
            },
          };
        } if (action.data === 'atra') {
          return {
            ...state,
            [action.uid]: {
              ...state.default,
              atra: !state.default.atra,
            },
          };
        } if (action.data === 'pro') {
          return {
            ...state,
            [action.uid]: {
              ...state.default,
              pro: !state.default.pro,
            },
          };
        } if (action.data === 'maza') {
          return {
            ...state,
            [action.uid]: {
              ...state.default,
              maza: !state.default.maza,
            },
          };
        } if (action.data === 'fourPRoom') {
          return {
            ...state,
            [action.uid]: {
              ...state.default,
              fourPRoom: !state.default.fourPRoom,
            },
          };
        } if (action.data === 'privateRoom') {
          return {
            ...state,
            [action.uid]: {
              ...state.default,
              privateRoom: !state.default.privateRoom,
            },
          };
        }
        return {
          ...state,
          [action.uid]: {
            ...state.default,
          },
        };
      }
      if (action.data === 'parasta' || action.data === 'G') {
        return {
          ...state,
          [action.uid]: {
            ...state[action.uid],
            parasta: !state[action.uid].parasta,
            G: !state[action.uid].G,
          },
        };
      } if (action.data === 'atra') {
        return {
          ...state,
          [action.uid]: {
            ...state[action.uid],
            atra: !state[action.uid].atra,
          },
        };
      } if (action.data === 'pro') {
        return {
          ...state,
          [action.uid]: {
            ...state[action.uid],
            pro: !state[action.uid].pro,
          },
        };
      } if (action.data === 'maza') {
        return {
          ...state,
          [action.uid]: {
            ...state[action.uid],
            maza: !state[action.uid].maza,
          },
        };
      } if (action.data === 'fourPRoom') {
        return {
          ...state,
          [action.uid]: {
            ...state[action.uid],
            fourPRoom: !state[action.uid].fourPRoom,
          },
        };
      } if (action.data === 'privateRoom') {
        return {
          ...state,
          [action.uid]: {
            ...state[action.uid],
            privateRoom: !state[action.uid].privateRoom,
          },
        };
      }
      return {
        ...state,
      };
    }

    case 'TOGGLE_ROOMS_FILTER_PARAM': {
      console.log('userSettings case TOGGLE_ROOMS_FILTER_PARAM', state[action.uid]);
      if (state && !state[action.uid]) {
        if (action.data) {
          return {
            ...state,
            [action.uid]: {
              ...state.default,
              roomsFilter: action.data,
            },
          };
        }
        return {
          ...state,
          [action.uid]: {
            ...state.default,
          },
        };
      }
      if (action.data) {
        return {
          ...state,
          [action.uid]: {
            ...state[action.uid],
            roomsFilter: action.data,
          },
        };
      }
      return {
        ...state,
      };
    }

    case 'SET_NEW_GAME_BET': {
      console.log('userSettings case SET_NEW_GAME_BET', state[action.uid]);

      if (state && !state[action.uid]) {
        if (action.data) {
          return {
            ...state,
            [action.uid]: {
              ...state.default,
              bet: action.data,
            },
          };
        }
        return {
          ...state,
          [action.uid]: {
            ...state.default,
          },
        };
      }
      if (action.data) {
        return {
          ...state,
          [action.uid]: {
            ...state[action.uid],
            bet: action.data,
          },
        };
      }
      return {
        ...state,
      };
    }

    case 'SET_NEW_GAME_SPEED': {
      console.log('userSettings case SET_NEW_GAME_SPEED', state[action.uid]);

      if (state && !state[action.uid]) {
        if (action.data) {
          return {
            ...state,
            [action.uid]: {
              ...state.default,
              speed: action.data,
            },
          };
        }
        return {
          ...state,
          [action.uid]: {
            ...state.default,
          },
        };
      }
      if (action.data) {
        return {
          ...state,
          [action.uid]: {
            ...state[action.uid],
            speed: action.data,
          },
        };
      }
      return {
        ...state,
      };
    }

    case 'SET_NEW_MIN_GAMES': {
      console.log('userSettings case SET_NEW_MIN_GAMES', state[action.uid]);

      if (state && !state[action.uid]) {
        if (action.data) {
          return {
            ...state,
            [action.uid]: {
              ...state.default,
              minGames: action.data,
            },
          };
        }
        return {
          ...state,
          [action.uid]: {
            ...state.default,
          },
        };
      }
      if (action.data) {
        return {
          ...state,
          [action.uid]: {
            ...state[action.uid],
            minGames: action.data,
          },
        };
      }
      return {
        ...state,
      };
    }

    case 'SET_SOUND': {
      console.log('userSettings case SET_SOUND', state[action.uid], action);

      if (state && !state[action.uid]) {
        if (action.data || action.data === false) {
          console.log('SET_SOUND 1', action.data);
          return {
            ...state,
            [action.uid]: {
              ...state.default,
              soundOn: action.data,
              sound: action.data,
            },
            soundOn: action.data,
          };
        }
        console.log('SET_SOUND 2', action.data);
        return {
          ...state,
          [action.uid]: {
            ...state.default,
          },
          soundOn: action.data,
        };
      }
      if (action.data || action.data === false) {
        console.log('SET_SOUND 3', action.data, state[action.uid]);
        return {
          ...state,
          [action.uid]: {
            ...state[action.uid],
            soundOn: action.data,
            sound: action.data,
          },
          soundOn: action.data,
        };
      }
      console.log('SET_SOUND 4', action.data);
      return {
        ...state,
        soundOn: action.data,
      };
    }

    case 'TOGGLE_SOUND': {
      console.log('userSettings case TOGGLE_SOUND', state[action.uid]);
      if (state && !state[action.uid]) {
        return {
          ...state,
          [action.uid]: {
            ...state.default,
            soundOn: !state.default.soundOn || false,
          },
        };
      }
      return {
        ...state,
        [action.uid]: {
          ...state[action.uid],
          soundOn: !state[action.uid].soundOn || false,
        },
      };
    }

    case 'TOGGLE_AUTO_PLAY': {
      console.log('userSettings case TOGGLE_AUTO_PLAY', state[action.uid]);

      if (state && !state[action.uid]) {
        return {
          ...state,
          [action.uid]: {
            ...state.default,
            autoPlay: !state.default.autoPlay || false,
          },
        };
      }
      return {
        ...state,
        [action.uid]: {
          ...state[action.uid],
          autoPlay: !state[action.uid].autoPlay || false,
        },
      };
    }

    case 'SET_DEFAULT_SETTINGS': {
      console.log('userSettings case SET_DEFAULT_SETTINGS', state[action.uid], { state, action });
      if (state && (!state[action.uid] || (state[action.uid] && !state[action.uid].notificationSettings))) {
        return {
          ...state,
          [action.uid]: {
            ...initialState.default,
            notificationSettings: {
              leaveRoom: true,
              lastRound: true,
              quitRound: true,
              ignorePlayer: true,
              quitAccount: true,
              purchaseGift: true,
              removeGift: true,
              /* Stop for production mode */
            //  newDesign: true,
              showAfterRound: true,
              showAtRoomEnd: true,
            },
          },
        };
      }
      return {
        ...state,
      };
    }

    /*  case 'SET_DEFAULT_GAME_SETTINGS': {
      console.log('SET_DEFAULT_GAME_SETTINGS', { state });
      if (state && (!state[action.uid] || (state[action.uid] && !state[action.uid].gameSettings))) {
        return {
          ...state,
          [action.uid]: {
            ...state.default,
            gameSettings: {
              doubleClickPlay: false,
            },
          },
        };
      }
      return {
        ...state,
      };
    } */

  //  case 'SET_NEW_DESIGN_SETING': {

  //  }

    case 'GET_ALL_NOTIFICATION_SETINGS': {
     /* return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          ...action.settings,
        },
      }; */
      console.log('userSettings case GET_ALL_NOTIFICATION_SETINGS', state[action.uid], action, state[action.uid]);

      if (state && (!state[action.uid])) {
        return {
          ...state,
          [action.uid]: {
            ...state.default,
            notificationSettings: { ...action.settings },
          },
        };
      }
      return {
        ...state,
        [action.uid]: {
          ...state[action.uid],
          notificationSettings: { ...action.settings },
        },
      };
    }

    case 'CHANGE_NOTIFICATION_SETINGS': {
      console.log('userSettings case CHANGE_NOTIFICATION_SETINGS', state[action.uid], { action, state });

      if (state && (!state[action.uid])) { // || !state[action.uid].notificationSettings
        const newSettings = { ...state.default.notificationSettings };

        newSettings[action.setting] = !newSettings[action.setting];

        console.log('newSettingsCheck', { newSettings });

        return {
          ...state,
          [action.uid]: {
            ...state.default,
            notificationSettings: { ...newSettings },
          },
        };
      }
      const newSettings = { ...state[action.uid].notificationSettings };

      newSettings[action.setting] = !newSettings[action.setting];

      console.log('newSettings', newSettings)

      return {
        ...state,
        [action.uid]: {
          ...state[action.uid],
          notificationSettings: { ...newSettings },
        },
      };
    }

    case 'CHANGE_GAME_SETINGS': {
      console.log('userSettings case CHANGE_GAME_SETINGS', state[action.uid], { action, state });

      if (state && (!state[action.uid] || !state[action.uid].gameSettings)) {
        const newSettings = { ...state.default.gameSettings };
        newSettings[action.setting] = !newSettings[action.setting];


        if (state[action.uid]) {
          return {
            ...state,
            [action.uid]: {
            //  ...state.default,
              ...state[action.uid],
              gameSettings: { ...newSettings },
            },
          };
        }
        return {
          ...state,
          [action.uid]: {
            ...state.default,
            //  ...state[action.uid],
            gameSettings: { ...newSettings },
          },
        };

      /*  return {
          ...state,
          [action.uid]: {
            ...state.default,
            ...state[action.uid],
            gameSettings: { ...newSettings },
          },
        }; */
      }
      const newSettings = { ...state[action.uid].gameSettings };

      newSettings[action.setting] = !newSettings[action.setting];

      return {
        ...state,
        [action.uid]: {
          ...state[action.uid],
          gameSettings: { ...newSettings },
        },
      };
    }

    case 'GET_ALL_GAME_SETINGS': {
      console.log('userSettings case GET_ALL_GAME_SETINGS', state[action.uid], action, state[action.uid]);

      if (state && (!state[action.uid])) {
        return {
          ...state,
          [action.uid]: {
            ...state.default,
            gameSettings: { ...action.settings },
          },
        };
      }
      return {
        ...state,
        [action.uid]: {
          ...state[action.uid],
          gameSettings: { ...action.settings },
        },
      };
    }

    case 'GET_ALL_NEW_GAME_SETINGS': {
      console.log('userSettings case GET_ALL_NEW_GAME_SETINGS', state[action.uid], action, state[action.uid]);
      if (state && (!state[action.uid])) {
        return {
          ...state,
          [action.uid]: {
            ...state.default,
            G: action.settings.G,
            bet: action.settings.bet,
            fourPRoom: action.settings.fourPRoom,
            maza: action.settings.maza,
            minGames: action.settings.minGames,
            parasta: action.settings.parasta,
            privateRoom: action.settings.privateRoom,
            pro: action.settings.pro,
            speed: action.settings.speed,
          },
        };
      }
      return {
        ...state,
        [action.uid]: {
          ...state[action.uid],
          G: action.settings.G,
          bet: action.settings.bet,
          fourPRoom: action.settings.fourPRoom,
          maza: action.settings.maza,
          minGames: action.settings.minGames,
          parasta: action.settings.parasta,
          privateRoom: action.settings.privateRoom,
          pro: action.settings.pro,
          speed: action.settings.speed,
        },
      };
    }

    case 'TOGGLE_ALL_NOTIFICATION_SETTINGS': {
      console.log('userSettings case TOGGLE_ALL_NOTIFICATION_SETTINGS', state[action.uid]);
      if (state && (!state[action.uid] || !state[action.uid].notificationSettings)) {
        const newSettings = { ...state.default.notificationSettings };

        newSettings.leaveRoom = false;
        newSettings.lastRound = false;
        newSettings.quitRound = false;
        newSettings.ignorePlayer = false;
        newSettings.quitAccount = false;
        newSettings.purchaseGift = false;
        newSettings.removeGift = false;
        /* Stop for production mode */

        return {
          ...state,
          [action.uid]: {
            ...state.default,
            notificationSettings: { ...newSettings },
          },
        };
      }
      const newSettings = { ...state[action.uid].notificationSettings };

      if (newSettings.leaveRoom && newSettings.lastRound && newSettings.quitRound && newSettings.ignorePlayer
          && newSettings.quitAccount && newSettings.purchaseGift && newSettings.removeGift) {
        newSettings.leaveRoom = false;
        newSettings.lastRound = false;
        newSettings.quitRound = false;
        newSettings.ignorePlayer = false;
        newSettings.quitAccount = false;
        newSettings.purchaseGift = false;
        newSettings.removeGift = false;
        /* Stop for production mode */
      } else {
        newSettings.leaveRoom = true;
        newSettings.lastRound = true;
        newSettings.quitRound = true;
        newSettings.ignorePlayer = true;
        newSettings.quitAccount = true;
        newSettings.purchaseGift = true;
        newSettings.removeGift = true;
        /* Stop for production mode */
      }

      return {
        ...state,
        [action.uid]: {
          ...state[action.uid],
          notificationSettings: { ...newSettings },
        },
      };
    }

    case 'TOGGLE_ROOM_CHAT': {
      if (state && !state[action.uid]) {
        return {
          ...state,
          [action.uid]: {
            ...state.default,
            roomChatOpen: !state.default.roomChatOpen || false,
          },
        };
      }
      return {
        ...state,
        [action.uid]: {
          ...state[action.uid],
          roomChatOpen: !state[action.uid].roomChatOpen || false,
        },
      };
    }

    case 'TOGGLE_SCORE_TABLE': {
      if (state && !state[action.uid]) {
        return {
          ...state,
          [action.uid]: {
            ...state.default,
            scoreTableOpen: !state.default.scoreTableOpen || false,
          },
        };
      }
      return {
        ...state,
        [action.uid]: {
          ...state[action.uid],
          scoreTableOpen: !state[action.uid].scoreTableOpen || false,
        },
      };
    }

    case 'TOGGLE_MOBILE_SCORE_TABLE': {
      if (state && !state[action.uid]) {
        return {
          ...state,
          [action.uid]: {
            ...state.default,
            scoreTableOpen: !action.mobileScoreTableOpen ? true : (!state.default.scoreTableOpen || false),
          },
        };
      }
      return {
        ...state,
        [action.uid]: {
          ...state[action.uid],
          scoreTableOpen: !action.mobileScoreTableOpen ? true : (!state[action.uid].scoreTableOpen || false),
        },
      };
    }

    default:
      return state;
  }
}
