import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';

import closeImg from '../../../../images/icons/close.png';

import {
  FONT_MODE
} from '../../../../constants/constants';

const QuitRoundModal = React.memo(({
  t, quitRoundModal, closeQuitRoundModal, approveQuitRound, fontMode,
}) => (
  <Modal container={'div > div'} isOpen={quitRoundModal} toggle={closeQuitRoundModal} className={`notification old-modal root-font-${fontMode}`}>
    <ModalHeader
      className="notification-header"
      close={
        <Media src={closeImg} className="notification-header-close" alt="X" onClick={closeQuitRoundModal} />
      }
    >{t('common:home.confirm')} </ModalHeader>
    <ModalBody className="notification-body">
      {t('quitRoundConfirm')}
    </ModalBody>
    <ModalFooter className="notification-footer">
      <Button className="btn notification-footer-button" onClick={approveQuitRound}>
        {t('yes')}
      </Button>
      <Button type="button" className="btn notification-footer-button" onClick={closeQuitRoundModal}>
        {t('no')}
      </Button>
    </ModalFooter>
  </Modal>
));

QuitRoundModal.propTypes = {
  quitRoundModal: PropTypes.bool,
  closeQuitRoundModal: PropTypes.func.isRequired,
  approveQuitRound: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  fontMode: PropTypes.string,
};

QuitRoundModal.defaultProps = {
  quitRoundModal: false,
  fontMode: FONT_MODE.normal,
};

export default QuitRoundModal;
