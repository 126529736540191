import React from 'react';

const MessageAppInfo = ({ message }) => {
  console.log(message, "contacts us2 admin")

  return (
  <div className="d-flex flex-column">
    {message && (message?.env.toLowerCase() === 'ios' || message?.env.toLowerCase() === 'android') && (
      <>
        <span>{`OS: ${message?.env} ${message?.osVersion}`}</span>
        <span>{`App: ${message?.appVersion}`}</span>
      </>
    )}
    {message && message?.env && (message?.env.toLowerCase() !== 'ios' || message?.env.toLowerCase() !== 'android') && (
      <>
        <span>{`Platform: ${message?.env ? message?.env.toUpperCase() : ''}`}</span>
        <span>{`Design: ${message?.design ? message?.design.toUpperCase() : ''}`}</span>
      </>
    )}
  </div>
);
}

export default MessageAppInfo;
