import React from 'react';
import PropTypes from 'prop-types';

const PlayStage = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Kad ir noteikts kurš spēlētājs ir lielais, kuri divi mazie, un visiem trim spēlētājiem rokās ir tieši 8 kārtis, drīkst izdarīt gājienu spēlētājs, kurš ir pirmais aiz dalītāja. Nākošos gājienus drīkst izdarīt tas spēlētājs, kurš ir uzvarējis iepriekšējo stiķi.
        </p>
        <p>
        Gājienu izdara atklāti uzliekot jebkuru vienu no savām kārtīm galdā. Nākošajam spēlētājam pa kreisi ir obligāti jāliek tās pašas kategorijas kārts, ja viņam tāda ir.
        </p>
        <p>
        Ja pirmā kārts ir trumpis, tad ir jāliek trumpis. Ja pirmā kārts nav trumpis, tad ir jāliek tāda paša masta kārts. Trešajam spēlētājam ir tas pats nosacījums, kas otrajam. Ja kādam no spēlētājiem rokās nav pieprasītās kategorijas kārts (piemēram - pirmais spēlētājs ir uzlicis trumpi, bet otrajam rokās nav neviena trumpja), tad ir atļauts uzlikt jebkuru kārti.
        </p>
        <p>
        Trešajam spēlētājam ir jāliek tādas kategorijas kārts, kādu pieprasa pirmā uzliktā, ne otrā (piemēram - pirmais spēlētājs uzliek trumpi, otrais uzliek netrumpi, jo rokās nebija trumpja, tad trešajam tāpat ir obligāti jāliek trumpis).
        </p>
        <p>
        Kad katrs spēlētājs ir uzlicis kārti, tiek aprēķināts spēlētājs, kurš visas trīs galdā uzliktās kārtis (stiķi) paņem savā īpašumā un atliek malā aizklāti.
        </p>
        <p>
        Stiķa uzvarētājs tiek noteikts atkarībā no augstākminētajiem kāršu stiprumiem. Ja neviens no spēlētājiem “nepārsit” pirmo uzlikto kārti, tad pirmais spēlētājs uzvar stiķi.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          Когда определили, который игрок «большой», а которые два «маленьких», и у всех трех игроков на руках 8 карт, ход делает игрок, который первый за раздающим. Следующие ходы делает тот игрок, который победил предыдущую взятку.
        </p>
        <p>
          Ход делается открыто положив на стол одну из своих карт. Следующий игрок слева обязательно должен класть карту той же категории, если она у него есть.
        </p>
        <p>
          На козырь всегда необходимо класть козырь. Если первая карта не козырь,то следует класть карту той же масти.По отношению к третьему игроку такие же условия, как и ко второму. Если у игрока на руках нет карты нужной категории (например, первый игрок положил козырь, а у второго игрока нет козыря), тогда разрешается класть любую карту.
        </p>
        <p>
          Третий игрок должен класть карту той категории, какую требует первая положенная, не вторая (например,первый игрок положил козырь, второй положил некозырную карту, так как козыря не было, то третий игрок обязательно должен класть козырь).
        </p>
        <p>
          Когда каждый игрок положил карту, определяется игрок, который забирает себе все три на стол положенные карты (взятку) и откладывает в сторону закрытыми.
        </p>
        <p>
          Победитель взятки определяется в зависимости от выше описанного старшинства карт. Если ни один из игроков « не перебивает» первую положенную карту, то первый игрок выигрывает взятку.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          Once it has been determined which player is the "Big", which two are "Small", and all three players have exactly 8 cards in their hands, the player who is first behind the dealer can make a move. The next move can be made by the player who has won the previous “Stick.”
        </p>
        <p>
          The move is made by openly placing any of the cards on the table. The next player to the left must place cards of the same suits (if he has one).
        </p>
        <p>
          If the first card is a trump, then the player must also place a trump. If the first card is not a trump, then a card of the same suit must be placed. The third player has the same rules as the second. If one of the players does not have a card of the required suits (for example, the first player has placed a trump, but the second player has no trumps in his hands), then any card is allowed to be placed.
        </p>
        <p>
          The third player must place a card of the suits required by the first, not the second player (for example, the first player places a trump, the second did not place a trump because he had no trumps in his hands, the third player still must also put a trump).
        </p>
        <p>
          When each player has placed a card on the table, the player, who got the “Stick”, takes all three cards and puts those asides with face-down.
        </p>
        <p>
          The winner of the “Stick” is determined by the card strengths. If none of the players places a stronger card than the first player, the first player wins the “Stick.”
        </p>
      </>
    )
  }
};

PlayStage.propTypes = {
  lang: PropTypes.string,
};

PlayStage.defaultProps = {
  lang: 'lv',
};

export default PlayStage;
