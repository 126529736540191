import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { size } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';

import { connect } from 'react-redux';
import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';

import { getBetValue } from '../../../../../../common/services/data-service';
import infoImg from '../../../../../../images/redesign/player/info.svg';

const EndResultFines = React.memo(({
  t,
  points,
  totalPnts,

  myPos,
  bet,
  party,
  gameType,
  closeReason,

  player1ShortName,
  player2ShortName,
  player3ShortName,
  player4ShortName,

  player1Uid,
  player2Uid,
  player3Uid,
  player4Uid,
  fetchPartyLog,
  screenMode,
  isEndResultTable,
}) => (
  <Fragment>
    <Row className="end-result-table-score">
      <Col sm="12">
        <Row className="end-result-table-header">
          <Col className="end-result-table-header-col">
            #
          </Col>
          {myPos === 'player1' && (
            <>
              <Col className="end-result-table-header-col">
                {player4ShortName}
              </Col>
              <Col className="end-result-table-header-col">
                {player1ShortName}
              </Col>
              <Col className="end-result-table-header-col">
                {player2ShortName}
              </Col>
              <Col className="end-result-table-header-col">
                {player3ShortName}
              </Col>
            </>
          )}
          {myPos === 'player2' && (
            <>
              <Col className="end-result-table-header-col">
                {player1ShortName}
              </Col>
              <Col className="end-result-table-header-col">
                {player2ShortName}
              </Col>
              <Col className="end-result-table-header-col">
                {player3ShortName}
              </Col>
              <Col className="end-result-table-header-col">
                {player4ShortName}
              </Col>
            </>
          )}
          {myPos === 'player3' && (
            <>
              <Col className="end-result-table-header-col">
                {player2ShortName}
              </Col>
              <Col className="end-result-table-header-col">
                {player3ShortName}
              </Col>
              <Col className="end-result-table-header-col">
                {player4ShortName}
              </Col>
              <Col className="end-result-table-header-col">
                {player1ShortName}
              </Col>
            </>
          )}
          {myPos === 'player4' && (
            <>
              <Col className="end-result-table-header-col">
                {player3ShortName}
              </Col>
              <Col className="end-result-table-header-col">
                {player4ShortName}
              </Col>
              <Col className="end-result-table-header-col">
                {player1ShortName}
              </Col>
              <Col className="end-result-table-header-col">
                {player2ShortName}
              </Col>
            </>
          )}
          <Col className="end-result-table-header-col justify-content-center">
            {t('game:pules')}
          </Col>
          <Col className="end-result-table-header-col" />
        </Row>
        <ScrollAreaWrapper
          className="end-result-table-scrollarea end-result-table-scrollarea-scoretable"
          contentClassName="end-result-table-body"
          show
          rightOffset={0}
          topOffset={23}
          bottomOffset={-3}
          noScrollButton
        >
          {points && Object.keys(points).map((key, index) => (
            <>
              {(index + 1) === size(points) && points[key].id === '-' && isEndResultTable && (
                <div className="pules-delimiter-bg" />
              )}
              <Row
                key={key}
                className="end-result-table-row"
              >
                <Col className="end-result-table-col d-flex align-self-center">
                  {(index + 1) === size(points) && points[key].id === '-' && isEndResultTable ? t('common.pules') : points[key].id}
                </Col>

                {myPos === 'player1' && (
                  <>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player4 === 0 ? 'player-history-table-col-neutral' : (points[key].player4 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player4}
                      </div>
                    </Col>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player1 === 0 ? 'player-history-table-col-neutral' : (points[key].player1 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player1}
                      </div>
                    </Col>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player2 === 0 ? 'player-history-table-col-neutral' : (points[key].player2 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player2}
                      </div>
                    </Col>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player3 === 0 ? 'player-history-table-col-neutral' : (points[key].player3 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player3}
                      </div>
                    </Col>
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player1 === 0 ? 'player-history-table-col-neutral' : (points[key].player1 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player1}
                      </div>
                    </Col>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player2 === 0 ? 'player-history-table-col-neutral' : (points[key].player2 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player2}
                      </div>
                    </Col>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player3 === 0 ? 'player-history-table-col-neutral' : (points[key].player3 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player3}
                      </div>
                    </Col>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player4 === 0 ? 'player-history-table-col-neutral' : (points[key].player4 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player4}
                      </div>
                    </Col>
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player2 === 0 ? 'player-history-table-col-neutral' : (points[key].player2 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player2}
                      </div>
                    </Col>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player3 === 0 ? 'player-history-table-col-neutral' : (points[key].player3 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player3}
                      </div>
                    </Col>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player4 === 0 ? 'player-history-table-col-neutral' : (points[key].player4 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player4}
                      </div>
                    </Col>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player1 === 0 ? 'player-history-table-col-neutral' : (points[key].player1 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player1}
                      </div>
                    </Col>
                  </>
                )}
                {myPos === 'player4' && (
                  <>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player3 === 0 ? 'player-history-table-col-neutral' : (points[key].player3 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player3}
                      </div>
                    </Col>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player4 === 0 ? 'player-history-table-col-neutral' : (points[key].player4 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player4}
                      </div>
                    </Col>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player1 === 0 ? 'player-history-table-col-neutral' : (points[key].player1 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player1}
                      </div>
                    </Col>
                    <Col className="end-result-table-header-col">
                      <div className={`${points[key].player2 === 0 ? 'player-history-table-col-neutral' : (points[key].player2 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                        {points[key].player2}
                      </div>
                    </Col>
                  </>
                )}

                <Col className="end-result-table-col d-flex align-items-center">
                  {points[key].pule === 'P' && (
                    <div> P </div>
                  )}
                  {points[key].pule === 'P-' && (
                    <span className={`strikeout-new-${screenMode}`}> P </span>
                  )}
                  {points[key].pule === 'player1' && (
                    <div className="score-table-pules-col-div">
                      { player1ShortName }
                    </div>
                  )}
                  {points[key].pule === 'player1-' && (
                    <span className={`score-table-pules-col-div strikeout-new-${screenMode}`}>
                      { player1ShortName }
                    </span>
                  )}
                  {points[key].pule === 'player2' && (
                    <div className="score-table-pules-col-div">
                      { player2ShortName }
                    </div>
                  )}
                  {points[key].pule === 'player2-' && (
                    <span className={`score-table-pules-col-div strikeout-new-${screenMode}`}>
                      { player2ShortName }
                    </span>
                  )}
                  {points[key].pule === 'player3' && (
                    <div className="score-table-pules-col-div">
                      { player3ShortName }
                    </div>
                  )}
                  {points[key].pule === 'player3-' && (
                    <span className={`score-table-pules-col-div strikeout-new-${screenMode}`}>
                      { player3ShortName }
                    </span>
                  )}
                  {points[key].pule === 'player4' && (
                    <div className="score-table-pules-col-div">
                      { player4ShortName }
                    </div>
                  )}
                  {points[key].pule === 'player4-' && (
                    <span className={`score-table-pules-col-div strikeout-new-${screenMode}`}>
                      { player4ShortName }
                    </span>
                  )}
                </Col>
                <Col className="end-result-table-col end-result-table-col-last">
                  <Media className="end-result-table-col-last-info-button" onClick={() => fetchPartyLog(index + 1, (index + 1) === size(points) && points[key].id === '-' && isEndResultTable)} src={infoImg} alt="x" />
                  {/* <Button color="link" className="menu-table-row-default-button" onClick={() => fetchPartyLog(index + 1)}>
                        Info
                      </Button> */}
                </Col>
              </Row>
            </>
          ))}
        </ScrollAreaWrapper>
      </Col>
    </Row>
  </Fragment>
));

EndResultFines.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
};

EndResultFines.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
};

const mapStateToProps = (state, ownProps) => {
  if (state.game) {
    return ({
      myPos: state.game.myPos || null,
      globalParams: state.game.globalParams || {},
      players: state.game.players,
    });
  }
  return null;
};

export default connect(mapStateToProps, null)(EndResultFines);
