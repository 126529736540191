import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import NavLink from 'reactstrap/lib/NavLink';
import Media from 'reactstrap/lib/Media';


import Ratings from '../Components/MyInfo/Ratings';
import Statistics from '../Components/MyInfo/Statistics_Mobile';
import GiftsHistory from '../Components/MyInfo/GiftsHistory';
import MyInfoSubHeader from './MyInfoSubHeader';
// import Friends from './Friends';
import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';
import LandScapeLayoutMobileHeader from '../../Layout/Mobile/LandScapeLayoutMobileHeader';

import * as constants from '../../../../../../constants/constants';

import { removeGift, getGiftsHistory, getPercentPosInfo, getPercentGPlayedInfo, getPercentGWonInfo, getPercentTotalPntsInfo, getPercentBalInfo } from '../../../../../../actions/member';

class MyInfo extends Component {
  static propTypes = {
    member: PropTypes.shape().isRequired,
    achievementsNotification: PropTypes.bool,
    gifts: PropTypes.shape(),
    notificationSettings: PropTypes.shape(),
    getGiftsHistoryFunction: PropTypes.func.isRequired,
    removeGiftFunction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    getPercentPosInfoFunction: PropTypes.func.isRequired,
    getPercentGPlayedInfoFunction: PropTypes.func.isRequired,
    getPercentGWonInfoFunction: PropTypes.func.isRequired,
    getPercentTotalPntsInfoFunction: PropTypes.func.isRequired,
    getPercentBalInfoFunction: PropTypes.func.isRequired,
    betterPos: PropTypes.number,
    betterGPlayed: PropTypes.number,
    betterGWon: PropTypes.number,
    betterTotalPnts: PropTypes.number,
    betterBal: PropTypes.number,
    screenMode: PropTypes.string,
    fontMode: PropTypes.string,
    showUserSettingsModal: PropTypes.func.isRequired,
    handleClickStart: PropTypes.func.isRequired,
    OpenMobileDropdownModal: PropTypes.func.isRequired,
    activeTab: PropTypes.string,
    toggleBottomBarTab: PropTypes.func.isRequired,
  }

  static defaultProps = {
    achievementsNotification: false,
    gifts: {},
    notificationSettings: {},
    betterPos: 0,
    betterGPlayed: 0,
    betterGWon: 0,
    betterTotalPnts: 0,
    betterBal: 0,
    screenMode: constants.SCREEN_MODE.normal,
    fontMode: constants.FONT_MODE.normal,
    activeTab: constants.MENU_NAVIGATION.myInfo,
    //  leaderboardData: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      //  leaderboardPosition: '',
    };
    this.getRatingData = this.getRatingData.bind(this);

  }

  getRatingData = () => {
    const { getPercentPosInfoFunction, getPercentGPlayedInfoFunction, getPercentGWonInfoFunction, getPercentTotalPntsInfoFunction, getPercentBalInfoFunction } = this.props;
    getPercentPosInfoFunction();
    getPercentGPlayedInfoFunction();
    getPercentGWonInfoFunction();
    getPercentTotalPntsInfoFunction();
    getPercentBalInfoFunction();
  }

  componentDidMount() {
    this.getRatingData();
  }

  componentDidUpdate(prevProps) {
    const { fontMode, screenMode } = this.props;
    if (prevProps.fontMode !== fontMode || prevProps.screenMode !== screenMode) {
      this.getRatingData();
    }
  }

  render() {
    const {
      activeTab, showUserSettingsModal, handleClickStart, OpenMobileDropdownModal, screenMode, member, t, changeTab, achievementsNotification, gifts, removeGiftFunction, getGiftsHistoryFunction, notificationSettings, betterPos, betterGPlayed, betterGWon, betterTotalPnts, betterBal, toggleBottomBarTab,
    } = this.props;

    return (
      <div className="layout-mobile-body layout-mobile-body-myinfo-page">
        <Helmet>
          <title>
            Zole - {t('route.myInfo')}
          </title>
        </Helmet>

        <div className="layout-mobile-body-top">
          {/* <Row className="layout-mobile-subheader d-none">
            <MyInfoSubHeader changeTab={changeTab} activeTab={activeTab} />
          </Row> */}
        </div>
        <div className="layout-mobile-body-main">
          <div className="layout-mobile-body-main-title d-none">{t('route.myInfo')}</div>
          <Ratings
            changeTab={changeTab}
            screenMode={screenMode}
            t={t}
          />
          <div
            className="layout-mobile-body-main-scrollarea my-info-scrollarea my-info-body-joyride"
            //  className="ach-table-scrollarea"
            show
            rightOffset={0}
            topOffset={0}
            bottomOffset={0}
          >
            <Statistics
              changeTab={changeTab}
              t={t}
              member={member}
              betterPos={betterPos}
              betterGPlayed={betterGPlayed}
              betterGWon={betterGWon}
              betterTotalPnts={betterTotalPnts}
              betterBal={betterBal}
              screenMode={screenMode}
            />
            <GiftsHistory
              getGiftsHistory={getGiftsHistoryFunction}
              removeGift={removeGiftFunction}
              giftsHistory={member.giftsHistory}
              gifts={gifts}
              t={t}
              notificationSettings={notificationSettings}
              changeTab={changeTab}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  gifts: state.rooms.gifts || {},
  betterPos: state.member.betterPos || 0,
  betterGPlayed: state.member.betterGPlayed || 0,
  betterGWon: state.member.betterGWon || 0,
  betterTotalPnts: state.member.betterTotalPnts || 0,
  betterBal: state.member.betterBal || 0,
});

const mapDispatchToProps = {
  removeGiftFunction: removeGift,
  getGiftsHistoryFunction: getGiftsHistory,
  getPercentPosInfoFunction: getPercentPosInfo,
  getPercentGPlayedInfoFunction: getPercentGPlayedInfo,
  getPercentGWonInfoFunction: getPercentGWonInfo,
  getPercentTotalPntsInfoFunction: getPercentTotalPntsInfo,
  getPercentBalInfoFunction: getPercentBalInfo,
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(MyInfo));
