import React from 'react';
import PropTypes from 'prop-types';

const Recommendations = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
          Ja iepriekšējie divi spēlētāji ir palaiduši garām, ir lielāka iespēja, ka galdiņa kārtis būs trumpji, un palīdzēs uzvarēt spēlējot kā lielajam.
        </p>
        <p>
          Ja ir pacelts galds, un ir vairāki netrumpji, kurus var norakt, labāk atstāt pēc iespējas mazāk dažādu mastu rokās, vislabāk ja var atstāt tikai vienu mastu, lai varētu likt trumpjus uz abiem pārējiem.
        </p>
        <p>
          Ne vienmēr vajag paņemt visus stiķus. Ja lielajam rokās ir 7 trumpji un netrumpja devītnieks, un mazie galdā ir uzlikuši cita masta kungu un devītnieku, var atdot mazajiem šo stiķi ar tikai četriem punktiem, lai vēlāk ar trumpi iegūtu vairāk punktu savā īpašumā.
        </p>
        <p>
          Kā mazajam ar pirmo gājienu, ja otrais spēlētājs ir lielais, vajadzētu iziet ar tā masta kārti, kurš rokās ir visvairāk. Bet ja otrais spēlētājs ir otrs mazais, tad vēlams iziet ar kārti, kura ir vienīgā no kāda netrumpju masta.
        </p>
        <p>
          Spēlējot kā mazajam, jācenšas likt lielajam likt kārti otrajam. Tā, lai otrs mazais var izdarīt gājienu pēdējais, un pieņemt labāko lēmumu zinot visas kārtis galdā.
        </p>
        <p>
          Spēlējot mazo zoli kā mazajam, ja ir jāliek trešā kārts, un nav iespējams atdot stiķi lielajam, ieteicams likt spēcīgāko kārti, iespējams vēlāk liekot vājāku var likt lielajam paņemt stiķi un likt viņam zaudēt.
        </p>
        <p>
          Jāmēģina sekot līdzi jau savāktajiem punktiem, un kuras kārtis ir jau izspēlētas partijā. Reizēm ir vērts uzlikt stiprāko kārti uz salīdzinoši maziem punktiem, lai uzreiz sasniegtu nepieciešamos 60 vai 61 punktu un garantētu uzvaru.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          Если два предыдущих игрока сказали пас, есть большая вероятность, что карты на столе будут козырными, и помогут одержать победу играя как «большому».
        </p>
        <p>
          Если открыт стол, и есть несколько некозырных карт, лучше оставить как можно меньше мастей на руках, в идеале только одну масть, чтобы позже положить козыри на остальных двоих.
        </p>
        <p>
          Ни всегда нужно брать все взятки. Если у «большого» на руках 7 козырей и некозырная девятка, и «маленькие» положили на стол короля и девятку другой масти, можно отдать взятку только четырьмя очками, чтобы позже с козырем получить большее число очков.
        </p>
        <p>
          Как «маленькому» первый ход, если второй игрок «большой», нужно делать картой той масти, которой на руках большее количество. А если второй игрок «маленький», тогда желательно ходить картой, которая является единственной из некозырной масти.
        </p>
        <p>
          Играя как «маленькому», нужно стараться, чтобы вторую карту брал «большой»,чтобы второй «маленький» мог делать ход последним и,зная все карты, смог бы сделать наиболее правильный выбор.
        </p>
        <p>
          Играя Малую Золе как «маленькому», если нужно класть третью карту, и нет возможности отдать взятку «большому», рекомендуем класть более сильную карту, возможно позже ставя более слабую сможете заставить «большого» взять взятку и он проиграет.
        </p>
        <p>
          Нужно следить за набранными очками и уже разыгранными картами в партии. Иногда есть смысл положить более сильную карту на сравнительно маленькие очки только для того, чтобы преодолеть рубеж 61 пункта и гарантировать победу.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          If the previous two players have missed the move, there is a greater chance that the table cards will be trump's and will help you win by playing as the "Big".
        </p>
        <p>
        If the table is raised and there are several no-trumps, which can be dug up, it is better to leave only a few different suits, if possible, only one suit, so that the trumps can be placed on the other two.
        </p>
        <p>
          Not all “Sticks” should be taken. If the "Big" has 7 trumps and a no-trump ninth in his hands, and the “Small” placed a king and a ninth of a different suit on the table, give this stick to the “Small”, so you could get more points later with the trump.
        </p>
        <p>
          The "Small" with the first move, if the second player is the "Big", should go with the suit, which he has the most in his hands. But, if the second player is the second "Small", then it is better to play a card that is the only one from a no-trump suit.
        </p>
        <p>
          When playing as the “Small”, a player should try to force the “Big” player to put the card as a second player. So that the second "Small" can make the move as the last one, and make the best decision knowing all the cards on the table.
        </p>
        <p>
          When playing the “Small Zole” as the “Small” player, if you have to put the third card and it is not possible to give the “Stick” to the “Big”, it is recommended to put the strongest card. Possibly later, by putting a weaker card, you will force the “Big” to take the “Stick”, and make him lose.
        </p>
        <p>
          Players have to try to keep track of the points already collected and which cards have already been played in the hand. Sometimes it is worth putting the strongest card on relatively small points just to get over 61 points and guarantee the victory.
        </p>
      </>
    )
  }
};

Recommendations.propTypes = {
lang: PropTypes.string,
};

Recommendations.defaultProps = {
lang: 'lv',
};


export default Recommendations;
