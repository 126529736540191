import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Media from 'reactstrap/lib/Media';
// import CustomInput from 'reactstrap/lib/CustomInput';
import { size } from 'lodash';

import ReactGA from "react-ga";

// import {
//   toggleSound,
// } from '../../../actions/userSettings';

import {
  USER_SYSTEM_SETTINGS,
} from '../../../constants/constants';

import soundOnIcon from '../../../images/icons/volume_on.png';
import soundOffIcon from '../../../images/icons/volume_off.png';
// import sound from '../../../sounds/click_feedback.flac';

const SoundButton = React.memo(({beforeSettings, beforeChangeSetting, uid, inRoom, customClassName}) => {

  const [isInbox, setIsInbox] = useState(false);
  const [soundOnStatus, setSoundOnStatus] = useState(false);

  useEffect(() => {
    if (window && window.location) {
      const { hostname } = window.location;

      if (hostname === 'inbox.spelezoli.lv' || hostname === 'inbox.dev.spelezoli.lv') {
        setIsInbox(true);
      }
    }
  }, []);
  useEffect(() => {
    console.log(beforeSettings, size(beforeSettings), "soundBefore***")
    if (beforeSettings.indexOf(USER_SYSTEM_SETTINGS.soundOn) === -1) {
      setSoundOnStatus(false);
    } else {
      setSoundOnStatus(true);
    }
  }, [size(beforeSettings)])

  // componentDidMount() {
  //   const { beforeSettings } = this.state;

  //   if (beforeSettings[USER_SYSTEM_SETTINGS.soundOn]) {
  //     this.setState({
  //       soundOnStatus: true
  //     })
  //   }
  //   if (window && window.location) {
  //     const { hostname } = window.location;

  //     if (hostname === 'inbox.spelezoli.lv' || hostname === 'inbox.dev.spelezoli.lv') {
  //       this.setState({ isInbox: true });
  //     }
  //   }
  // }

  // componentWillReceiveProps(nextProps) {
  //   const { beforeSettings } = this.props;

  //   if (nextProps.beforeSettings[USER_SYSTEM_SETTINGS.soundOn] !== beforeSettings[USER_SYSTEM_SETTINGS.soundOn]) {
  //     this.setState({
  //       soundOnStatus: beforeSettings[USER_SYSTEM_SETTINGS.soundOn] ? true : false
  //     })
  //   }
  // }

  const toggleSound = () => {
    // if (userSettings[uid]) {
    //   if (!userSettings[uid].soundOn) {
    //     this.audio.play();
    //   }
    // }
    beforeChangeSetting(USER_SYSTEM_SETTINGS.soundOn);

  //  ReactGA.event({
  //    category: 'Sound',
  //    action: 'Change sound'
  //  });

    // changeSound(uid);
  }

  // render = () => {
  //   const {
  //     userSettings,
  //     uid,
  //     inRoom,
  //     beforeSettings,
  //   } = this.props;
  //   const { isInbox, soundOnStatus } = this.state;

  //   if (!uid || !userSettings) {
  //     return null;
  //   }

    // let soundOn = false;

    // if (userSettings[uid]) {
    //   if (userSettings[uid].soundOn) {
    //     soundOn = true;
    //   } else {
    //     soundOn = false;
    //   }
    // } else if (userSettings.default) {
    //   if (userSettings.default.soundOn) {
    //     soundOn = true;
    //   } else {
    //     soundOn = false;
    //   }
    // }
    // console.log("soundBefore", beforeSettings);
    // if (beforeSettings.indexOf(USER_SYSTEM_SETTINGS.soundOn) === -1) {
    //     soundOn = false;
    // } else {
    //   soundOn = true;
    // }
    // 'sound-button-inbox' : null} ${inRoom ? "sound-button-room" : "sound-button"}
    console.log(soundOnStatus, "soundStatus")
    return (
      <div className={`${isInbox ? "sound-button-inbox" : null} ${inRoom ? "sound-button-room" : "sound-button"} ${customClassName}`}>
        <Media src={soundOnStatus ? (soundOnIcon) : (soundOffIcon)} className="sound-button-image" onClick={toggleSound} />
      </div>
    );
  // }
})

SoundButton.propTypes = {
  // userSettings: PropTypes.shape().isRequired,
  changeSound: PropTypes.func.isRequired,
  inRoom: PropTypes.bool,
  beforeChangeSetting: PropTypes.func.isRequired,
  beforeSettings: PropTypes.array,
  customClassName: PropTypes.string,
}

SoundButton.defaultProps = {
  match: null,
  history: {},
  inRoom: false,
  beforeSettings: [],
  customClassName: '',
}

const mapStateToProps = state => ({
  // userSettings: state.userSettings || {},
});

const mapDispatchToProps = {
  // changeSound: toggleSound,
};

export default connect(mapStateToProps, mapDispatchToProps)(SoundButton);
