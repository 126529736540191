import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import ContactSupport from '../../ContactSupport/mobile/ContactSupport';
import { isMobileSafari } from 'react-device-detect';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as constants from '../../../../../constants/constants';
import Media from 'reactstrap/lib/Media';
import classNames from 'classnames';

import {
	setUserLoginStatistics
} from '../../../../../actions/member';

import {
	changeNotificationSettings,
} from '../../../../../actions/userSettings';

import SoundButton from '../../Components/Layout/SoundButton';

import openFullscreen from '../../../../../images/redesign/top-row/minimized.svg';
import closeFullscreen from '../../../../../images/redesign/top-row/maximized.svg';
import lightOpenFullscreen from '../../../../../images/redesign/light-mode/icons/maximized.png';
import lightCloseFullscreen from '../../../../../images/redesign/light-mode/icons/minimized.svg';

import DarkThemeModeIcon from '../../../../../images/redesign/mobile/icons/dark-theme-mode-icon.svg';
import NormalThemeModeIcon from '../../../../../images/redesign/mobile/icons/normal-theme-mode-icon.svg';
import LightThemeModeIcon from '../../../../../images/redesign/mobile/icons/light-theme-mode-icon.svg';
import DarkSelectedThemeIcon from '../../../../../images/redesign/mobile/icons/dark-theme-selected-mode-icon.svg';
import NormalSelectedThemeModeIcon from '../../../../../images/redesign/mobile/icons/normal-theme-selected-mode-icon.svg';
import LightSelectedThemeModeIcon from '../../../../../images/redesign/mobile/icons/light-theme-selected-mode-icon.svg';

import lvFlag from '../../../../../images/redesign/flags/lv.svg';
import ruFlag from '../../../../../images/redesign/flags/ru.svg';
import enFlag from '../../../../../images/redesign/flags/en.svg';

import gearIcon from '../../../../../images/icons/gear.svg';

import xLargeFontLight from '../../../../../images/redesign/light-mode/top-row/xLargeFont.webp';
import largeFontLight from '../../../../../images/redesign/light-mode/top-row/largeFont.webp';
import defaultFontLight from '../../../../../images/redesign/light-mode/top-row/defaultFont.webp';
import xLargeFont from '../../../../../images/redesign/top-row/xLargeFont.webp';
import largeFont from '../../../../../images/redesign/top-row/largeFont.webp';
import defaultFont from '../../../../../images/redesign/top-row/defaultFont.webp';

import CloseIcon from '../../../../../images/redesign/mobile/icons/close-icon.svg';
import {
	handleSetTheme,
} from '../../../../../actions/member';
import config from '../../../../../constants/config';
import IconPopover from '../../Components/Components/IconPopover';

const DropdownMenuModalContent = (({
  designPlatform, t, i18n, supportChatStatus, toggleBottomBarTab, toggleSupport, supportModalOpen, screenMode, handleClickStart, notificationSettings, logout, toggleNewDesign, uid, roomStatus, showUserSettingsModal, toggleFont, fontMode, closeModal, isFullscreenEnabled, toggleFullscreen, activeTab, newDesign,
}) => {
	const [darkTheme, setDarkTheme] = useState(screenMode || constants.SCREEN_MODE.normal);
	const [curLang, setCurLang] = useState('lv');
	const dispatch = useDispatch();
	const usersCount = useSelector(state => state.users.usersCount);
	const roomsCount = useSelector(state => state.users.roomsCount);

	const [mainSettingsPopoverOpen, setMainSettingsPopoverOpen] = useState(false);
	const [fontPopoverOpen, setFontPopoverOpen] = useState(false);
	const [fullscreenPopoverOpen, setFullscreenPopoverOpen] = useState(false);

	const [lightThemePopoverOpen, setLightThemePopoverOpen] = useState(false);
	const [normalThemePopoverOpen, setNormalThemePopoverOpen] = useState(false);
	const [darkThemePopoverOpen, setDarkThemePopoverOpen] = useState(false);

	const themePopoverSetters = { light: setLightThemePopoverOpen, normal: setNormalThemePopoverOpen, dark: setDarkThemePopoverOpen };
	const themePopoverVariables = { light: lightThemePopoverOpen, normal: normalThemePopoverOpen, dark: darkThemePopoverOpen };

	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [screenHeight, setScreenHeight] = useState(window.innerHeight);

	const updateScreenSize = () => {
		setScreenWidth(window.innerWidth);
		setScreenHeight(window.innerHeight);
	};

	useEffect(() => {
		updateScreenSize();
		window.addEventListener('resize', updateScreenSize);
		return () => {
			window.removeEventListener('resize', updateScreenSize);
		};
	}, []);


	useEffect(() => {
		const curLang = i18n.language;
		setCurLang(curLang);
	}, []);

	const handleStartJoyride = (e) => {
		handleClickStart(e);
	};

	const toggleLanguage = (lang) => {
		setCurLang(lang);
		i18n.changeLanguage(lang);

		ReactGA.event({
			category: 'Language',
			action: 'Change Language',
		});

		try {
			if (window && window.localStorage && window.localStorage.setItem) {
				localStorage.setItem('language', lang);
			}
		} catch (err) {
			console.log(err);
		}

		dispatch(setUserLoginStatistics(false, null, lang, null, null, null));
	}



	const toggleNewDesignFunc = () => {
		try {
      if (newDesign || localStorage.getItem('newDesign')) { // || notificationSettings?.newDesign
				console.log("switchDesign-new")
				localStorage.removeItem('newDesign');
				toggleNewDesign(false);

				ReactGA.event({
					category: 'Menu',
					action: 'Old Design',
				});
			} else {
				console.log("switchDesign-old")
				localStorage.setItem('newDesign', true);

				toggleNewDesign(true);

				ReactGA.event({
					category: 'Menu',
					action: 'New Design',
				});
			}
		} catch (err) {
			console.log(err);
		}
		// dispatch(changeNotificationSettings(constants.USER_SETTINGS.new_design, uid));
	};

	const handleToggleTheme = React.useCallback((theme) => {
		theme === constants.SCREEN_MODE.dark && setDarkTheme(constants.SCREEN_MODE.dark);
		theme === constants.SCREEN_MODE.light && setDarkTheme(constants.SCREEN_MODE.light);
		theme === constants.SCREEN_MODE.normal && setDarkTheme(constants.SCREEN_MODE.normal);
		try {
			localStorage.setItem('screenMode', JSON.stringify(theme));
		} catch (err) {
			console.log(err);
		}
		dispatch(handleSetTheme(theme));
	});

	const getFontMode = () => {
		if (screenMode === constants.SCREEN_MODE.light) {
			if (fontMode === constants.FONT_MODE.xLarge) {
				return xLargeFontLight;
			} else if (fontMode === constants.FONT_MODE.large) {
				return largeFontLight;
			} else {
				return defaultFontLight;
			}
		} else {
			if (fontMode === constants.FONT_MODE.xLarge) {
				return xLargeFont;
			} else if (fontMode === constants.FONT_MODE.large) {
				return largeFont;
			} else {
				return defaultFont;
			}
		}
	}

	const toggleFontFunc = () => {
		ReactGA.event({
			category: 'Menu',
			action: 'Change Font Size',
		});

		if (fontMode === constants.FONT_MODE.normal) {
			toggleFont(constants.FONT_MODE.large);
		} else if (fontMode === constants.FONT_MODE.large) {
			toggleFont(constants.FONT_MODE.xLarge);
		} else {
			toggleFont(constants.FONT_MODE.normal);
		}
	}

	const options = constants.SCREEN_MODE_OPTIONS;
	const selectedOption = darkTheme;
	const isInAppFrame = config.isInAppFrame();

	const isLandscapeLayout = screenWidth > screenHeight;
	return (
		<div className="dropdown-menu-modal-content">
			<div className='close-button' onClick={closeModal} style={isInAppFrame ? { top: 16, right: -38, padding: 0, width: 30, height: 30, cursor: 'pointer' } : {}}>
				<Media src={CloseIcon} alt='close-icon' style={isInAppFrame ? { width: 10, height: 10 } : {}} />
			</div>
			<div className='navigation-links'>
				{!isLandscapeLayout &&
					<>
						{/* <a
                            href="#"
                            className={`bottom-bar-links-help ${supportChatStatus && supportChatStatus.read === false ? 'incoming-dropdown' : ''}`}
                            onClick={() => toggleSupport()}
                        >
                            <span>
                                {t('menu.help')}
                            </span>
                            <ContactSupport
                                modalOpen={supportModalOpen}
                                toggle={toggleSupport}
                                screenMode={screenMode}
                            />
                        </a>
                        <a href="#" onClick={(e) => handleStartJoyride(e)}>
                            <span>
                                {t('menu.tutorial')}
                            </span>
                        </a> */}
						<a href="#" onClick={() => toggleBottomBarTab('moneyHistory')}>
							<span>
								{t('menu.moneyHistory')}
							</span>
						</a>
						<a href="#" onClick={() => toggleBottomBarTab('pointsHistory')}>
							<span>
								{t('menu.pointsHistory')}
							</span>
						</a>
					</>
				}

				<a href="#" onClick={() => toggleBottomBarTab('bannedUsers')}>
					<span>
						{t('menu.bannedUsers')}
					</span>
				</a>
				<a href="#" onClick={() => toggleBottomBarTab('helpPage')}>
					<span>
						{t('menu.helpPage')}
					</span>
				</a>
				<a href="#" onClick={() => toggleBottomBarTab('aboutPage')}>
					<span>
						{t('menu.aboutPage')}
					</span>
				</a>
				<a href="#" onClick={() => toggleBottomBarTab('termsOfUse')}>
					<span>
						{t('home.termsOfUse')}
					</span>
				</a>
				<a href="/landing" target='_blank'>
					<span>
						{t('menu.home')}
					</span>
				</a>
				<a href="/blog" target='_blank'>
					<span>
						{t('menu.blog')}
					</span>
				</a>
				<a href="/contacts" target='_blank'>
					<span>
						{t('menu.contacts')}
					</span>
				</a>
				<a href="/version-history" target='_blank' style={{ flex: '66.66% 0' }}>
					<span>
						{t('menu.changelog')}
					</span>
				</a>
			</div>

			<div className='theme-control-tools'>
				<div xs={4} className="migrate-to-old-design-section" onClick={() => { toggleNewDesignFunc(newDesign ? true : false); }}>
					{
						newDesign ? (
							t('common:home.oldDesign')
						) : (
							t('common:home.newDesign')
						)
					}
				</div>
				<div xs={4} className='theme-control-tool-section'>
					<div className="switch-select">
						{(options && options.length) ? options.map((option, index) => (
							<>
								<div
									onClick={(() => handleToggleTheme(option.value))}
									key={index}
									className={`switch-select-option ${index === 0 ? 'switch-select-option-first' : ''} ${(index === options.length - 1) ? 'switch-select-option-last' : ''} ${selectedOption === option.value ? 'switch-select-option-active' : ''}`}
									id={`${option.value}-theme-button-popover`}
									onMouseEnter={() => themePopoverSetters[option.value](true)}
									onMouseLeave={() => themePopoverSetters[option.value](false)}
								>

									<div className="switch-select-option-img">
										{
											(option.name === 'dark') ? <Media src={(selectedOption === option.value) ? DarkSelectedThemeIcon : DarkThemeModeIcon} className='dark-image' alt='dark-image' /> : (option.name === 'normal') ? <Media src={(selectedOption === option.value) ? NormalSelectedThemeModeIcon : NormalThemeModeIcon} className='normal-image' alt='normal-image' /> : <Media src={(selectedOption === option.value) ? LightSelectedThemeModeIcon : LightThemeModeIcon} className='light-image' alt='light-image' />
										}
									</div>
								</div>
								<IconPopover text={t(`popovers.${option.value}ThemeSetting`)} placement="bottom" targetId={`${option.value}-theme-button-popover`} popoverOpen={themePopoverVariables[option.value]} />
							</>
						)) : (null)}
					</div>
				</div>
				{!config.isInAppFrame() ? (
					<div xs={4} className="logout-section" onClick={logout}>
						{roomStatus ? (
							t('common:menu.exitRoom')
						) : (
							t('common:menu.logout')
						)}
					</div>
				) : (
					<div xs={4} className="logout-section" />
				)}

			</div>
			<div className='website-setting-tools'>
				<div className='language-select'>
					<div className={classNames('language-label', { 'language-label-active': curLang === constants.sendLanguage.lv })}><Media onClick={() => toggleLanguage('lv')} src={lvFlag} className="language-image" /></div>
					<div className={classNames('language-label', { 'language-label-active': curLang === constants.sendLanguage.en })}><Media onClick={() => toggleLanguage('en')} src={enFlag} className="language-image" /></div>
					<div className={classNames('language-label', { 'language-label-active': curLang === constants.sendLanguage.ru })}><Media onClick={() => toggleLanguage('ru')} src={ruFlag} className="language-image" /></div>
				</div>
				<div className='extra-tools'>
					<div className='setting-section'>
						<Media
							className="settings-button-image"
							src={gearIcon} alt={t('menu.settings')}
							onClick={showUserSettingsModal}
							onMouseEnter={() => setMainSettingsPopoverOpen(true)}
							onMouseLeave={() => setMainSettingsPopoverOpen(false)}
							id="game-settings-button-popover"
						/>
						<IconPopover text={t('popovers.gameSettings')} placement="bottom" targetId="game-settings-button-popover" popoverOpen={mainSettingsPopoverOpen} />
					</div>
					{!isMobileSafari && (
						<>
							<div className="fullscreen-section fullscreen-section-mobile" onMouseEnter={() => setFullscreenPopoverOpen(true)} onMouseLeave={() => setFullscreenPopoverOpen(false)} id="fullscreen-button-popover">
								{isFullscreenEnabled ? (
									<Media className="fullscreen-button-image" src={screenMode === constants.SCREEN_MODE.light ? lightCloseFullscreen : closeFullscreen} onClick={toggleFullscreen} />
								) : (
									<Media className="fullscreen-button-image" src={screenMode === constants.SCREEN_MODE.light ? lightOpenFullscreen : openFullscreen} onClick={toggleFullscreen} />
								)}
								<IconPopover text={t(isFullscreenEnabled ? 'popovers.fullscreenSettingOn' : 'popovers.fullscreenSettingOff')} placement="bottom" targetId="fullscreen-button-popover" popoverOpen={fullscreenPopoverOpen} />
							</div>
						</>
					)}
					<div className='font-setting'>
						<Media src={getFontMode()} className="font-button-image" alt={t('menu.settings')} onClick={toggleFontFunc} onMouseEnter={() => setFontPopoverOpen(true)} onMouseLeave={() => setFontPopoverOpen(false)} id="font-setting-popover-dropdown" />
						<IconPopover text={t('popovers.fontSetting')} placement="bottom" targetId="font-setting-popover-dropdown" popoverOpen={fontPopoverOpen} />
					</div>
					<div className='sound-setting mobile-sound-setting'>
						<SoundButton uid={uid} screenMode={screenMode} t={t} />
					</div>
				</div>
			</div>

			<div className="mobile-online-stats">
				<div className="mobile-online-stats-wrapper">
					<span className="online-stats-text">{`${t('menu.onlineCount')}: `}</span>
					<span className="online-stats-count">{`${usersCount} ${t('menu.players')}`}</span>
					<div className="online-stats-divider" />
					<span className="online-stats-count">{`${roomsCount} ${t('menu.rooms')}`}</span>
				</div>
			</div>
		</div>
	);
});

DropdownMenuModalContent.propTypes = {
	t: PropTypes.func.isRequired,
	toggleBottomBarTab: PropTypes.func.isRequired,
	toggleSupport: PropTypes.func.isRequired,
	supportChatStatus: PropTypes.shape(),
	supportModalOpen: PropTypes.bool,
	designPlatform: PropTypes.shape({}),
};

DropdownMenuModalContent.defaultProps = {
	designPlatform: {},
};

const mapStateToProps = state => ({
  newDesign: state.member.newDesign,
});

export default connect(mapStateToProps, null)(withTranslation(['common'])(DropdownMenuModalContent));

// export default withTranslation('common')(DropdownMenuModalContent);
