import React from 'react';
import PropTypes from 'prop-types';

const Pules = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Ja visi trīs spēlētāji palaiž garām, tad spēles protokolā tiek ierakstīta Kopējā Pule (ja ir četri spēlētāji pie galda, un protokolā neeksistē neviena pule, tad ieraksta divas kopējās pules). Kopējo puļu skaitam protokolā nav ierobežojuma, ja visi palaiž garām vairākkārt, tad protokolā ieraksta vairākas kopējas pules.
        </p>
        <p>
        Ja protokolā eksistē vismaz viena jebkāda veida pule, un lielais partiju zaudē, tad protokolā tiek ierakstīta viņa Personīgā Pule.
        </p>
        <p>
        Ja lielais uzvar, un protokolā eksistē tikai Kopējās Pules, tad lielais iegūst pa vienam papildus punktam no katra mazā spēlētāja, un kopējā pule tiek izsvītrota no protokola.
        </p>
        <p>
        Ja lielais uzvar, un protokolā eksistē šī spēlētāja Personīgā Pule (sava pule), tad punktu saņemšana netiek izmainīta, bet sava pule tiek izsvītrota. Šajā gadījumā savai pulei ir augstāka prioritāte nekā jebkurai kopējai vai cita spēlētāja personīgajai pulei.
        </p>
        <p>
        Ja lielais uzvar, un protokolā eksistē tikai citu spēlētāju Personīgās Pules (nav ne savas, ne kopējās), tad tās tiek izņemtas tādā pat secībā, kā tika ierakstītas. Izņemot cita spēlētāja puli, no viņa iegūst tik daudz punktu, cik spēlētāju atrodas pie galda (3 vai 4).
        </p>
        <p>
        Ja istaba tiek pabeigta (izspēlēta pēdējā partija, vai kāds to priekšlaicīgi pamet), un protokolā eksistē neizsvītrotas personīgās pules, tad tās tiek norakstītas:
        </p>
        <p>
        Par katru personīgo puli tās īpašnieks atdod pa vienam punktam katram no pie galda esošajiem spēlētājiem. Līdz ar to trijnieku galdā īpašnieks zaudē 2 punktus par katru puli, bet četrniekos - 3.
        </p>
        <p>
        Jāņem vērā, ka var būt gadījumi, ka pēc puļu norakstīšanas punktos izmaiņas nav. Piemēram, ja pirmajam spēlētājam ir 1 personīgā pule, otrajam 2, bet trešajam neviena, tad pirmais spēlētājs zaudē 2 punktus par savu puli, bet iegūst 2 no otrā spēlētāja, un punktos nav izmaiņas.
        </p>
        <p>
        Puļu esamība nekādā veidā neietekmē punktu uzskaiti, spēlējot zoli un mazo zoli. Tāpat arī šajos izspēles veidos pules netiek pievienotas vai izņemtas.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
        Если все три игрока сказали пас, то в протоколе игры пишется общая «пуля» (если за столом играют четыре игрока, и в протоколе нет ни одной «пули» то пишут две общие «пули»). Числу общих «пуль» в протоколе нет ограничений, если все неоднократно говорят пас, то в протоколе записывают несколько общих «пулей».
        </p>
        <p>
        Если в протоколе имеется хоть одна «пуля», и «большой» проигрывает, то в протоколе пишется его Персональная Пуля.
        </p>
        <p>
        Если «большой» побеждает, и в протоколе имеются только Общие Пули, то «большой» получает по одному дополнительному очку с каждого «маленького» игрока, и общая «пуля» списывается.
        </p>
        <p>
        Если «большой» побеждает, и в протоколе имеется Персональная Пуля этого игрока (своя пуля), то получение очков не меняется, а списывается своя «пуля». В этом случае своя «пуля» намного приоритетней чем любая другая общая или персональная «пуля» другого игрока.
        </p>
        <p>
        Если «большой» побеждает, и в протоколе имеются только Персональные Пули других игроков (нет ни своей, ни общей), то их списывают в таком же порядке, как и записали.За исключением «пули» другого игрока, с него получают столько очков, сколько игроков за столом (3 или 4).
        </p>
        <p>
        Если стол завершен (сыграна последняя игра или кто-то отключается от стола), а в протоколе есть не удаленные личные пулы, они списываются:
        </p>
        <p>
        За каждый личный пул его владелец возвращает по одному очку каждому игроку за столом. Таким образом, в таблице из трех хозяин теряет по 2 очка за каждый пул, а в четверке - по 3.
        </p>
        <p>
        Следует отметить, что возможны случаи, когда при списании пулов не происходит изменение баллов. Например, если у первого игрока есть 1 личный пул, у второго 2, а у третьего нет, то первый игрок теряет 2 очка для своего пула, но получает 2 очка от второго игрока, и очки не меняются.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
        If all three players miss the move, the common “Pules” is recorded in the game report (if there are four players at the table and there is no “Pules” in the protocol, than two “Pules” are recorded). There is no limit to the number of common “Pules” in the protocol, if everyone misses several times, then several common “Pules” are recorded in the protocol.
        </p>
        <p>
        If there is at least one “Pule” of any kind in the protocol and the “Big” loses, then players Personal “Pule“ is recorded in the protocol.
        </p>
        <p>
        If the “Big” wins and in the protocol there are only common “Pules”, than the “Big” gets one extra point from each “Small” player and common “Pule” is deleted from the protocol.
        </p>
        <p>
        If the “Big” wins and in the protocol there is his personal “Pule”, then the receipt of points is not changed, but the “Big” personal “Pule” is deleted from the protocol. In this case priority of a personal “Pule” is higher, than any other player’s.
        </p>
        <p>
        If the “Big” wins, and in the protocol there are only personal “Pules” from other players (not the winner’s, nor common “Pules”), than “Pules” are taken out from the protocol in the same order, as they were written in.
        </p>
        <p>
        If the table is terminated (the last game is played, or someone disconnects from the table), and there are non-deleted personal pools in the protocol, they are written off:
        </p>
        <p>
        For each personal pool, its owner returns one point to each of the players at the table. Thus, in the table of three, the owner loses 2 points for each pool, but in fours - 3.
        </p>
        <p>
        It should be noted that there may be cases where there is no change in the points at the write-off of the pools. For example, if the first player has 1 personal pool, the second 2, and the third none, then the first player loses 2 points for his pool, but gains 2 points from the second player, and the points do not change.
        </p>
      </>
    )
  }
};

Pules.propTypes = {
  lang: PropTypes.string,
};

Pules.defaultProps = {
  lang: 'lv',
};

export default Pules;
