import React from 'react';
import PropTypes from 'prop-types';

const Achievements = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Par noteiktām darbībām un veikumiem spēlē ir iespējams iegūt kādu no sasniegumiem. Par katru no iegūtajiem sasniegumiem ir iespējams iegūt arī noteiktu skaitu Zoles monētu, kuras tiek automātiski pievienotas spēlētāja bilancei.
        </p>
        <p>Spēlē ir pieejamas vairākas sasniegumu kategorijas:</p>
        <ul>
        <li>vienreizēji sasniegumi</li>
        <li>izspēlētas partijas</li>
        <li>uzvarētas partijas</li>
        <li>kopumā nopelnīts</li>
        <li>nopelnīts dienā</li>
        <li>uzvaras pēc kārtas</li>
        <li>zaudējumi pēc kārtas</li>
        <li>izspēlēta Zole</li>
        <li>uzvarēta Zole</li>
        <li>izspēlētas ātrās partijas</li>
        <li>izspēlētas zibens partijas</li>
        <li>izspēlēta mazā Zole</li>
        <li>uzvarēta mazā Zole</li>
        <li>iegriezts laimes rats</li>
        <li>dalība turnīros</li>
        <li>saņemtas dāvanas</li>
        <li>atrašanās turnīra TOP 10</li>
        <li>atrašanās TOP 100</li>
        <li>nosūtītas dāvanas</li>
        <li>izspēlētas partijas vienā istabā</li>
        <li>nosūtītas vēstules administrācijai</li>
        <li>veikti pirkumi Zoles veikalā</li>
        <li>izspēlēti galdiņi</li>
        <li>uzvarēti galdiņi</li>
        <li>zaudēti galdiņi</li>
        <li>dienas pēc kārtas</li>
        </ul>
        <p>Pilns saraksts ar sasniegumiem (iegūtiem un vēl neiegūtiem, kā arī atlīdzību apjomi) ir pieejams sadaļā <b>{`Mans Info => Sasniegumi.`}</b></p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          За определенные действия в игре можно получить какое-то достижение. За каждое полученное достижение можно иметь определенное число монет Золе, которые присоеденяются к балансу игрока, когда нажимается кнопка «Забрать».
        </p>
        <p>В игре доступны несколько категорий достижений:</p>
        <ul>
          <li>одноразовые достижения</li>
          <li>разыгранные партии</li>
          <li>партии, в которых одержали победу</li>
          <li>общая сумма заработанного</li>
          <li>Заработано за день</li>
          <li>победы по счету</li>
          <li>проигрыши по счету</li>
          <li>разыгранная Золе</li>
          <li>победа в Золе</li>
          <li>разыгранные быстрые партии</li>
          <li>сыгранных молниеносных партий</li>
          <li>разыгранная Малая Золе</li>
          <li>победа в Малой Золе</li>
          <li>раскручено колесо фортуны</li>
          <li>участие в турнирах</li>
          <li>полученные подарки</li>
          <li>нахождение в ТОП 10</li>
          <li>нахождение в ТОП 100</li>
          <li>отправленные подарки</li>
          <li>разыгранные партии в одной комнате</li>
          <li>посланные письма администрации</li>
          <li>сделанные покупки в магазине Золе</li>
          <li>разыгранные столы</li>
          <li>победа в столах</li>
          <li>проигрыши в столах</li>
          <li>Дней подряд</li>
        </ul>
        <p>Полный список с достижениями (полученными и не полученными, а так же размеры вознаграждений) доступен в списке   <b>{`Мой Инфо => Достижения.`}</b></p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          For certain actions in the game it is possible to get one of the achievements. For each of the achievements it is possible to get a certain number of "Zole" coins, which are added to the player's balance when the "Collect" button is pressed.
        </p>
        <p>There are several achievement categories available in the game:</p>
        <ul>
        <li>unique/one-time achievements</li>
        <li>played games</li>
        <li>won games</li>
        <li>total earnings</li>
        <li>earned in a day</li>
        <li>wins in a row</li>
        <li>consecutive losses</li>
        <li>played “Zole”</li>
        <li>won “Zole”</li>
        <li>played quick games</li>
        <li>lightning games played</li>
        <li>played “Small Zole”</li>
        <li>won “Small Zole”</li>
        <li>turned wheel of fortune</li>
        <li>participation in tournaments</li>
        <li>received gifts</li>
        <li>one of the tournament TOP 10</li>
        <li>one of the tournament TOP 100</li>
        <li>send gifts</li>
        <li>played games in one room</li>
        <li>send messages to the administration</li>
        <li>made purchases in the Zole store</li>
        <li>played “Tables”</li>
        <li>won “Tables”</li>
        <li>lost “Tables”.</li>
        </ul>
        <p>A complete list of achievements (earned and not yet earned, as well as reward amounts) is available in the list <b>{`My Info => Achievements`}</b></p>
      </>
    )
  }
};

Achievements.propTypes = {
  lang: PropTypes.string,
};

Achievements.defaultProps = {
  lang: 'lv',
};

export default Achievements;
