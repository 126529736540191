import React from 'react';
import PropTypes from 'prop-types';

import Media from 'reactstrap/lib/Media';

import img from '../../../../../../images/Rules/Gifts.webp';

const Gifts = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
          Istabas skatā ir iespēja pasniegt dāvanu kādam no spēlētājiem (arī sev). Katra dāvana maksā noteiktu skaitu Zoles monētu, kas pēc iegādes tiek atskaitītas no spēlētāja bilances. Kopā ar dāvanu ir iespējams atstāt arī teksta ziņu. Vienas dāvanas cena ir no 25 līdz 75 Zoles monētām.
        </p>
        <p>
          Pašlaik ir pieejamas 10 dažādas dāvanas:
        </p>
        <Media src={img} alt="Dāvanas" style={{ width: '80%' }} />
        <p>
          Dāvana pie spēlētāja profila tiek rādīta 6 stundas kopš pasniegšanas brīža. Ja spēlētājs šajā laikā saņem citu dāvanu, tad laika atskaite sākas no jauna un pie profila tiek rādīta jaunākā dāvana.
        </p>
        <p>
          Sadaļā
          {' '}
          <b>Mans Info</b>
          {' '}
          ir iespējams redzēt pēdējās 6 saņemtās dāvanas. Ja nevēlies, lai pasniegtā dāvana tiek rādīta pie profila, to ir iespējams noņemt - vienas dāvanas dzēšana izmaksā 300 Zoles monētas.
        </p>
      </>
    );
  } if (lang === 'ru') {
    return (
      <>
        <p>
          В виде комнаты есть возможность вручить подарок любому игроку ( и себе). Каждый подарок стоит определенное количество монет Золе, которые отсчитываются с баланса игрока. Вместе с подарком можно послать и текстовое сообщение. Цена одного подарка от 25 до 75 монет Золе.
        </p>
        <p>
          На данный момент доступны 10 подарков:
        </p>
        <Media src={img} alt="Dāvanas" style={{ width: '80%' }} />
        <p>
          Подарки возле профиля игрока видны в течении 6 часов с момента их присвоения. Если игрок в это время получает другой подарок, то отсчет времени начинается сначала и возле профиля показывается новый подарок.
        </p>
        <p>
          В разделе
          {' '}
          <b>Мой Инфо</b>
          {' '}
          можно увидеть 6 последних полученных подарков. Если не хотите, чтобы возле вашего профиля были видны подарки, их можно снять — удаление одного подарка будет стоить 300 монет Золе.
        </p>
      </>
    );
  } if (lang === 'en') {
    return (
      <>
        <p>
          In the room view, it is possible to send a gift to one of the players (incl., to yourself). Each gift costs a certain number of "Zole" coins, which are removed from the player's balance, upon the purchase. It is possible to send a text message with the gift. The price of one gift varies from 25 to 75 "Zole" coins.
        </p>
        <p>
          There are currently 10 different gifts available:
        </p>
        <Media src={img} alt="Dāvanas" style={{ width: '80%' }} />
        <p>
          The gift is displayed on the player's profile for 6 hours from the moment of presentation. If the player receives another gift during this time, the time countdown starts again and the latest gift is displayed next to the profile.
        </p>
        <p>
          In the "My Info" section players can see the last 6 gifts received. If the player does not want the presented gift to be displayed next to the profile, it can be removed – deleting one gift costs 300 "Zole" coins.
        </p>
      </>
    );
  }
};

Gifts.propTypes = {
  lang: PropTypes.string,
};

Gifts.defaultProps = {
  lang: 'lv',
};

export default Gifts;
