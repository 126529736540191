import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import Leaderboard from './TournamentLeaderboard';

import closeImg from '../../../../../images/icons/close.png';

import {
  getTournamentPlayers,
  closeTournamentResults,
} from '../../../../../actions/tournaments';
import CustomModal from '../../Components/Components/Modal';

class Tournaments extends React.PureComponent {
  static propTypes = {
    tournamentPlayers: PropTypes.shape(),
    uid: PropTypes.string,
    t: PropTypes.func.isRequired,
    fetchTournamentPlayers: PropTypes.func.isRequired,
    showTournamentResults: PropTypes.func.isRequired,
    closeTournamentResult: PropTypes.func.isRequired,
  }

  static defaultProps = {
    tournamentPlayers: {},
    uid: null,
  }

  constructor(props) {
    super(props);
    this.state = {
    //  openModal: false,
    //  openJoinModal: false,
    //  tournamentId: '',
    //  scrollPos: 0,
    //  tournamentToJoin: null,
    //  tournamentToJoinFee: null,
    //  registerPopoverOpen: {},
    };
  }

  componentWillMount() {
    const { showTournamentResults, fetchTournamentPlayers } = this.props;
    fetchTournamentPlayers(showTournamentResults.tournamentId);
  }

  // componentWillReceiveProps(nextProps) {

  //  }

  //  updateScrollPos = (val) => {
  //    this.setState({ scrollPos: val.topPosition || 0 });
  //  }

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  render() {
    const {
      t,
      tournamentPlayers,
      showTournamentResults,
      closeTournamentResult,
      uid,
      screenMode
    } = this.props;

    console.log('tournamentPlayerstournamentPlayers', tournamentPlayers, showTournamentResults);

    if (!tournamentPlayers || !showTournamentResults) {
      return null;
    }

    if (showTournamentResults.canceled && showTournamentResults.playersCount) {
      return (
        <CustomModal
          isOpen={showTournamentResults}
          toggle={closeTournamentResult}
          size="lg"
          verticalCentering
          largeText
          title={t('home.notifications')}
          footer={<>
            <Button color="link" className="modal-footer-button" onClick={closeTournamentResult}>{t('common.close')}</Button>
          </>}
          body={(<>{t('tournaments.canceled', { playersCount: showTournamentResults.playersCount })}</>)}
        />
      );
    }

    return (
      <CustomModal
        isOpen={showTournamentResults}
        toggle={closeTournamentResult}
        returnFocusAfterClose={false}
        size="lg"
        title={t('tournaments.results', { name: showTournamentResults.name })}
        addClassName="tournament-leaderboard-modal"
        customTitleClassName="tournament-end-modal-title"
        disableFlex={true}
        footer={(
          <Button className="notification-footer-button m-0" color="link" onClick={() => closeTournamentResult()}>{t('tournaments.close')}</Button>
        )}
        body={<Leaderboard leaderboard={tournamentPlayers} screenMode={screenMode} tournamentId={showTournamentResults.tournamentId} uid={uid} />}
      />
    );
  }
}

const mapStateToProps = state => ({
  uid: state.member.uid || null,
  showTournamentResults: state.member.showTournamentResults || false,
  tournamentPlayers: (state.tournaments && state.tournaments.tournamentPlayers) ? state.tournaments.tournamentPlayers : {},
});

const mapDispatchToProps = {
  fetchTournamentPlayers: getTournamentPlayers,
  closeTournamentResult: closeTournamentResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Tournaments));
