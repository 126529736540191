import React from 'react';
import PropTypes from 'prop-types';

const IgnorePlayers = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Istabas skatā pie katra spēlētāja ir poga ‘Ignorēt’ - noklikšķinot uz tās, spēlētājs tiks ievietots ignorēto sarakstā. Kamēr spēlētājs ir ignorēto sarakstā, jūs abi nevarēsiet spēlēt vienā istabā.
        </p>
        <p>Atceries: ignorēšana neattiecas uz pašlaik aktīvo istabu.</p>

        <p>
        Saraksts ar ignorētajiem spēlētājiem ir pieejams sadaļā <b>{`Mans Info => Ignorētie`}</b>. Šeit ir iespējams pārskatīt visus ignorētos spēlētus un laiku, kopš kura katrs ir ignorēts, kā arī ir iespēja spēlētāju atbloķēt jeb izņemt no ignorēto saraksta. Tiklīdz spēlētājs ir izņemts no saraksta, atkal varat spēlēt vienā istabā.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
        В виде комнаты у каждого игрока есть кнопка «Игнорировать» - нажав на нее, игрок попадает в список игнорированных. Пока игрок находится в списке игнорированных, вы оба не сможете играть в одной комнате.
        </p>
        <p>Помните: игнорирование не относится к активной на данный момент комнате. </p>

        <p>
        Список с игнорированными игроками доступен в разделе <b>{`Мой Инфо => Игнорированные игроки`}</b>. Здесь можно увидеть всех игнорированных игроков и время, когда каждый из них включен в список игнорированных, а так же есть возможность разблокировать игрока или исключить из списка игнорированных. Как только игрок убран из списка, вы снова можете играть в одной комнате.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
        In the room view, next to each player, there is an ‘Ignore’ button - by clicking on it, a player will be put the on the “Ignore list”. As long as a player is on the “Ignore list”, you will not be able to play in the same room.
        </p>
        <p>NB! Ignoring does not apply to the currently active room.</p>

        <p>
        The list of ignored players is available in <b>{`My Info => Ignored Players`}</b>. Here it is possible to review all ignored games and since when each player was ignored, as well as it is possible to unblock or remove a player from the ignored list. Once a player is removed from the list, both players can play in the same room again.
        </p>
      </>
    )
  }
};

IgnorePlayers.propTypes = {
  lang: PropTypes.string,
};

IgnorePlayers.defaultProps = {
  lang: 'lv',
};

export default IgnorePlayers;
