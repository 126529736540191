import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const cardNumMap = {
  player1: { // sittingOut
    player1: { player2: 0, player3: 3, player4: 2 },
    player2: { player2: 1, player3: 0, player4: 2 },
    player3: { player2: 2, player3: 1, player4: 0 },
    player4: { player2: 0, player3: 2, player4: 1 },
  },
  player2: { // sittingOut
    player1: { player1: 1, player3: 0, player4: 2 },
    player2: { player1: 2, player3: 0, player4: 3 },
    player3: { player1: 2, player3: 1, player4: 0 },
    player4: { player1: 0, player3: 2, player4: 1 },
  },
  player3: { // sittingOut
    player1: { player1: 1, player2: 0, player4: 2 },
    player2: { player1: 2, player2: 1, player4: 0 },
    player3: { player1: 3, player2: 2, player4: 0 },
    player4: { player1: 0, player2: 2, player4: 1 },
  },
  player4: { // sittingOut
    player1: { player1: 1, player2: 0, player3: 2 },
    player2: { player1: 2, player2: 1, player3: 0 },
    player3: { player1: 0, player2: 2, player3: 1 },
    player4: { player1: 0, player2: 3, player3: 2 },
  },
};


const Render4PlayerCards = React.memo(({
  currentTable, cardsAdded, myPos, sittingOut, isFullscreen, isWebVersion, currentTurn, gameState, firstToGo,
}) => {
  const items = [];


  console.log('testcurrentTable Render4PlayerCards', currentTable, myPos, sittingOut, cardsAdded);
  /*
  const items2 = [];

  for (let i = 0; i < 4; i++) {
    if (currentTable && currentTable[i] && sittingOut) {
      items2.push(cardNumMap[sittingOut][myPos][currentTable[i].player]);
    }
  }

  console.log('items2', items2);

  if (myPos === 'player3') {
    Object.keys(currentTable).map((key, index) => {
      if (currentTable[key] && currentTable[key].player === 'player1') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player2') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player3') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player4') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      return null;
    });

    if (currentTable && currentTable.length < 3) {
      if (currentTurn === 'player1') {
        items.push(<div className="table-card-bg card-0" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-2" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-1" />);
        }
      } else if (currentTurn === 'player2') {
        items.push(<div className="table-card-bg card-2" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-1" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-0" />);
        }
      } else if (currentTurn === 'player3') {
        items.push(<div className="table-card-bg card-1" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-0" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-2" />);
        }
      }
    }

    console.log('items', { items });

    return (
      <div className="table-cards">
        {items}
      </div>
    );
  }

  if (myPos === 'player1') {
    Object.keys(currentTable).map((key, index) => {
      if (currentTable[key] && currentTable[key].player === 'player1') {
        //  p1 = true;
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player2') {
        //  p2 = true;
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player3') {
        //  p3 = true;
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player4') {
        //  p3 = true;
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      return null;
    });

    if (currentTable && currentTable.length < 3) {
      if (currentTurn === 'player1') {
        items.push(<div className="table-card-bg card-1" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-0" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-2" />);
        }
      } else if (currentTurn === 'player2') {
        items.push(<div className="table-card-bg card-0" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-2" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-1" />);
        }
      } else if (currentTurn === 'player3') {
        items.push(<div className="table-card-bg card-2" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-1" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-0" />);
        }
      }
    }

    //  console.log('items', { items });

    return (
      <div className="table-cards">
        {items}
      </div>
    );
  }

  if (myPos === 'player2') {
    Object.keys(currentTable).map((key, index) => {
      if (currentTable[key] && currentTable[key].player === 'player1') {
        //  p1 = true;
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player2') {
        //  p2 = true;
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player3') {
        //  p3 = true;
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      return null;
    });

    if (currentTable && currentTable.length < 3) {
      if (currentTurn === 'player1') {
        items.push(<div className="table-card-bg card-2" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-1" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-0" />);
        }
      } else if (currentTurn === 'player2') {
        items.push(<div className="table-card-bg card-1" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-0" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-2" />);
        }
      } else if (currentTurn === 'player3') {
        items.push(<div className="table-card-bg card-0" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-2" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-1" />);
        }
      }
    }

    console.log('items', { items });

    return (
      <div className="table-cards">
        {items}
      </div>
    );
  } */

  if (!sittingOut) {
    return null;
  }

  if (!myPos) {
    return null;
  }

  /*
  const items2 = [];

  if (currentTable) {

    if (myPos === 'player1') {
      currentTable && Object.keys(currentTable).map((key, index) => {
        if (currentTable[key].player === 'player1') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
        if (currentTable[key].player === 'player2') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
        if (currentTable[key].player === 'player3') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
        if (currentTable[key].player === 'player4') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
      })
    } else if (myPos === 'player2') {
      Object.keys(currentTable).map((key, index) => {
        if (currentTable[key].player === 'player1') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
        if (currentTable[key].player === 'player2') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
        if (currentTable[key].player === 'player3') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
        if (currentTable[key].player === 'player4') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
      })
    } else if (myPos === 'player3') {
      Object.keys(currentTable).map((key, index) => {
        if (currentTable[key].player === 'player1') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
        if (currentTable[key].player === 'player2') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
        if (currentTable[key].player === 'player3') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
        if (currentTable[key].player === 'player4') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
      })
    } else if (myPos === 'player4') {
      Object.keys(currentTable).map((key, index) => {
        if (currentTable[key].player === 'player1') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
        if (currentTable[key].player === 'player2') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
        if (currentTable[key].player === 'player3') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
        if (currentTable[key].player === 'player4') {
          items2.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
        }
      })
    }
  }

  console.log('items2', items2);  */

  let items3 = [];
  let setCardIndexes = [];
  let setCardIndexes2 = [];

  if (currentTable) {
    Object.keys(currentTable).map((key, index) => {
      setCardIndexes.push(cardNumMap[sittingOut][myPos][currentTable[key].player]);
      items3.push(<div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-${cardNumMap[sittingOut][myPos][currentTable[key].player]} card-order-${index} table-card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />)
    });
  }

  console.log('setCardIndexes', setCardIndexes);
  console.log('items3', items3);

  const curTurnIndex = cardNumMap[sittingOut][myPos][gameState !== 'burry' ? currentTurn : firstToGo];

  console.log('curTurnIndex', curTurnIndex);

  
  if (currentTable && currentTable.length <= 3) {
    console.log('set backgrounds', curTurnIndex);

    // card background with question mark
    items3.push(<div className={`table-card-bg card-${curTurnIndex}`} />);

    if (myPos !== sittingOut) {
      // card backgrounds without question mark
      if (!setCardIndexes.includes(0) && curTurnIndex !== 0) {
        items3.push(<div className="table-card table-card-bg-after card-0" />);
      }
      if (!setCardIndexes.includes(1) && curTurnIndex !== 1) {
        items3.push(<div className="table-card table-card-bg-after card-1" />);
      }
      if (!setCardIndexes.includes(2) && curTurnIndex !== 2) {
        items3.push(<div className="table-card table-card-bg-after card-2" />);
      }
    } else {
      // card backgrounds without question mark for sitting out player
      if (!setCardIndexes.includes(0) && curTurnIndex !== 0) {
        items3.push(<div className="table-card table-card-bg-after card-0" />);
      }
      if (!setCardIndexes.includes(3) && curTurnIndex !== 3) {
        items3.push(<div className="table-card table-card-bg-after card-3" />);
      }
      if (!setCardIndexes.includes(2) && curTurnIndex !== 2) {
        items3.push(<div className="table-card table-card-bg-after card-2" />);
      }
    }
  }

  console.log('items3 2', items3);

  return (
    <div className={`table-cards ${sittingOut === myPos ? ('played-cards-sittingOut') : null}`} style={{position: 'fixed'}}>
      {items3}
    </div>
  );

  return null;

  return <div className={`played-cards ${sittingOut === myPos ? ('played-cards-sittingOut') : null}`}>
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key] ? currentTable[key].card : key}>
            <div key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-${cardNumMap[sittingOut][myPos][currentTable[key].player]} card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          </Fragment>
        ))}
      </Fragment>

    {/* Change for fourPRoom update  */}
    {/* {myPos === 'player1' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key].card}>
            {currentTable[key].player === 'player1' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player2' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player3' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player4' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
          </Fragment>
        ))}
      </Fragment>
    )}
    {myPos === 'player2' && (
    <Fragment>
      {currentTable && Object.keys(currentTable).map((key, index) => (
        <Fragment key={currentTable[key].card}>
          {currentTable[key].player === 'player1' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player2' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player3' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player4' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
        </Fragment>
      ))}
    </Fragment>
    )}
    {myPos === 'player3' && (
    <Fragment>
      {currentTable && Object.keys(currentTable).map((key, index) => (
        <Fragment key={currentTable[key].card}>
          {currentTable[key].player === 'player1' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player2' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player3' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player4' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
        </Fragment>
      ))}
    </Fragment>
    )}
    {myPos === 'player4' && (
    <Fragment>
      {currentTable && Object.keys(currentTable).map((key, index) => (
        <Fragment key={currentTable[key].card}>
          {currentTable[key].player === 'player1' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player2' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player3' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
          {currentTable[key].player === 'player4' && (
          <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          )}
        </Fragment>
      ))}
    </Fragment>
    )} */}
  </div>
});

Render4PlayerCards.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
  sittingOut: PropTypes.string,
};

Render4PlayerCards.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
  sittingOut: null,
};

export default Render4PlayerCards;
