import React from 'react';
import PropTypes from 'prop-types';

const RoomBets = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>Ir iespējamas istabas ar šādām likmēm:</p>
        <ul>
          <li>1:1 (pieejama tikai spēlētājiem ar 1.-2.līmeni)</li>
          <li>1:5 (pieejama tikai spēlētājiem ar 1.-2.līmeni)</li>
          <li>1:10</li>
          <li>1:25</li>
          <li>1:50</li>
          <li>1:100</li>
          <li>1:500 (šī un augstākas likmes ir pieejamas spēlētājiem, kas uzvarējuši vismaz 300 reizes)</li>
          <li>1:1000</li>
          <li>1:5000</li>
          <li>1:10000</li>
        </ul>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>Возможны следующие ставки комнат:</p>
        <ul>
          <li>1:1 (доступны только игрокам 1-2 уровней)</li>
          <li>1:5 (доступны только игрокам 1-2 уровней)</li>
          <li>1:10</li>
          <li>1:25</li>
          <li>1:50</li>
          <li>1:100</li>
          <li>1:500 (эта и более высокие ставки доступны игрокам, которые выиграли как минимум 300 партий)</li>
          <li>1:1000</li>
          <li>1:5000</li>
          <li>1:10000</li>
        </ul>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>There are rooms available with following bets:</p>
        <ul>
          <li>1:1 (only available to players with levels 1-2)</li>
          <li>1:5 (only available to players with levels 1-2)</li>
          <li>1:10</li>
          <li>1:25</li>
          <li>1:50</li>
          <li>1:100</li>
          <li>1:500 (this and higher bets are available to players who have won at least 300 games)</li>
          <li>1:1000</li>
          <li>1:5000</li>
          <li>1:10000</li>
        </ul>
      </>
    )
  }
};

RoomBets.propTypes = {
  lang: PropTypes.string,
};

RoomBets.defaultProps = {
  lang: 'lv',
};

export default RoomBets;
