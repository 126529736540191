import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/* import {
  Media,
} from 'reactstrap'; */

// import Media from 'reactstrap/lib/Media';

// import lielaisImg from '../../../../images/Game/LIELAIS.svg';
// import mazaisImg from '../../../../images/Game/MAZAIS.svg';

const PlayerType = React.memo(({
  t, currentType, gameState, largePlayer, sittingOut,
}) => (
  <Fragment>
    {sittingOut ? (
      <div className="player-type-wrapper">
        <div className="player-type-mazais">{t('dealer')}</div>
      </div>
    ) : (
      <Fragment>
        {currentType !== 'galdins' && (gameState === 'play'
        || gameState === 'burry') && (
        <div className="player-type-wrapper">
          <Fragment>
            {largePlayer ? (
              <Fragment>
                {currentType === 'parasta' && (
                <div className="player-type-lielais">{t('large')}</div>
                )}
                {currentType === 'zole' && (
                <div className="player-type-lielais">{t('zole')}</div>
                )}
                {currentType === 'maza' && (
                <div className="player-type-lielais">{t('smallZole')}</div>
                )}
              </Fragment>
            ) : (
              <div className="player-type-mazais">{t('small')}</div>
            )}
          </Fragment>
        </div>
        )}
      </Fragment>
    )}
  </Fragment>
));

PlayerType.propTypes = {
  currentType: PropTypes.string,
  gameState: PropTypes.string,
  largePlayer: PropTypes.bool,
  sittingOut: PropTypes.string,
};

PlayerType.defaultProps = {
  currentType: null,
  gameState: null,
  largePlayer: false,
  sittingOut: null,
};

export default PlayerType;
