import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';

import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import closeImg from '../../../images/icons/close.png';

import { PopoverBody, UncontrolledPopover } from 'reactstrap';

class Settings extends React.Component {
  static propTypes = {
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
      mobileVersion: null,
      mobileVersionDev: null,
      treasureChestEnabled: null,
    };

  }


  componentDidMount() {
    console.log('getStatistic componentDidMount');
    const { getMobileVersion, getTreasureChestEnabled } = this.props;

    getMobileVersion(false).then((res) => {
      console.log(res);

      this.setState({ mobileVersion: res.data });
    });

    getMobileVersion(true).then((res) => {
      console.log(res);

      this.setState({ mobileVersionDev: res.data });
    });

    getTreasureChestEnabled().then((res) => {
      console.log(res);

      this.setState({ treasureChestEnabled: res.data });
    });
  }

  componentDidUpdate() {

  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  changeMobileVersion = () => {
    const { setMobileVersion } = this.props;
    const { mobileVersion } = this.state;

    setMobileVersion(mobileVersion, null);
  }

  changeMobileVersionDev = () => {
    const { setMobileVersion } = this.props;
    const { mobileVersionDev } = this.state;

    setMobileVersion(mobileVersionDev, true);
  }

  changeTreasureChestEnabled = () => {
    const { setTreasureChestEnabled } = this.props;
    const { treasureChestEnabled } = this.state;

    setTreasureChestEnabled(treasureChestEnabled);
  }

  render() {
    const {
      t,
    } = this.props;

    const {
      mobileVersion,
      mobileVersionDev,
      treasureChestEnabled,
    } = this.state;

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-settings">
          <PopoverBody className="popover-body">
            The admin can set up the minimum version of the mobile app for the prod and dev versions. 
            <br /><br />
            The setting of Treasure Chest is related to the draugiem.lv yearly Advent event with a reward to everyone who opens the banner from their portal.
          </PopoverBody>
        </UncontrolledPopover>
        <div style={{ marginTop: 100, color: '#fff' }}>
          <h2>
            {/* {t('settings')} */}
            settings
            <Button className="admin-help-button" id="admin-help-button-settings">
              ?
            </Button>
          </h2>
          <div>
            <Label for="mobileVersion">
              {/* {t('mobileVersion')} */}
              Mobile Version
            </Label>
            <Input
              type="text"
              name="mobileVersion"
              id="mobileVersion"
              value={mobileVersion}
              onChange={this.handleChange}
            />

            <Button color="primary" onClick={this.changeMobileVersion}>
              {/* {t('changeMobileVersion')} */}
              Change Mobile Version
            </Button>
          </div>
          <div>
            <Label for="mobileVersionDev">
              Developer Version Mobile Version
            </Label>
            <Input
              type="text"
              name="mobileVersionDev"
              id="mobileVersionDev"
              value={mobileVersionDev}
              onChange={this.handleChange}
            />

            <Button color="primary" onClick={this.changeMobileVersionDev}>
              {/* {t('changeMobileVersion')} */}
              Change Mobile Version
            </Button>
          </div>
          <div>
            <Label for="treasureChestEnabled">
              {/* {t('treasureChestEnabled')} */}
              Treasure Chest Enabled
            </Label>
            <Input
              style={{ marginLeft: 25, position: 'relative' }}
              type="checkbox"
              name="treasureChestEnabled"
              id="treasureChestEnabled"
              checked={treasureChestEnabled}
              onChange={this.handleChange}
            />

            <Button style={{ marginLeft: 25 }} color="primary" onClick={this.changeTreasureChestEnabled}>
              {t('changeTreasureChestEnabled')}
              Change Treasure Chest Enabled
            </Button>
          </div>

        </div>
      </Fragment>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    // profanitiesList: state.admin.profanitiesList || {},
  };
};

const mapDispatchToProps = {
  // fetchProfanitiesList: getProfanitiesList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(Settings));
