import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Media from 'reactstrap/lib/Media';

const EmotionsMobile = React.memo(({
  emotions, setEmotion, openEmotions, openEmotionsFunc,
}) => {
  console.log('Emotions', { openEmotions });

  if (!openEmotions) {
    return null;
  }

  const { t } = useTranslation('playerInfo');

  return (
    <div className="player-info-body emotions-section">
      <Row className="emotions-wrapper">
        {emotions && Object.keys(emotions).map(key => (
          <div key={key} className="emotions-emotion">
            <Media
              src={emotions[key] ? emotions[key].image : null}
              className={`emotions-emotion-image ${key === 'E6' ? 'emotions-emotion-image-handshake' : null}`}
              onClick={() => setEmotion(key)}
            />
          </div>
        ))}
      </Row>
    </div>
  );
});

EmotionsMobile.propTypes = {
  playerInfo: PropTypes.shape(),
  gifts: PropTypes.shape(),
  openEmotionsFunc: PropTypes.func.isRequired,
  posClassName: PropTypes.string,
  modalOpen: PropTypes.bool,
};

EmotionsMobile.defaultProps = {
  playerInfo: null,
  gifts: null,
  posClassName: null,
  modalOpen: false,
};

export default EmotionsMobile;
