import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import ScrollArea from 'react-scrollbar';

import LanguageSelect from './UI/LanguageSelect';
import { Label } from 'reactstrap';

import { withRouter } from 'react-router-dom';

// import loginIconImg from '../../images/landing-view/login-icon.svg';
import { termsOfText } from '../../constants/termsOfUse';
import logoImg from '../../images/Menu/zole_logo.webp';

class RulesPage extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
    };

  //  this.goToLogin = this.goToLogin.bind(this);
  }

  goToLogin = (e) => {
    e.preventDefault();
    const { history } = this.props;
    console.log('history.push');

    history.push('/login');
  }

  goToHome2 = (e) => {
    e.preventDefault();
    const { history } = this.props;
    history.push('/landing');
  }

  goToHome = (e) => {
    e.preventDefault();
    const { history } = this.props;

    console.log('history.push to goToHome', { history: history.location });

    history.push('/login');

    console.log('history.push to goToHome 2', { history: history.location });

    this.setState({ date: Date.now() });
  }

  render() {
    const { i18n, t } = this.props;

    return (
      <div className="rules-page" style={{ maxHeight: '85vh', height: '85vh', position: 'relative', backgroundImage: 'none' }}>
        <Row className="rules-page-header">
          <Col sm="8">
            <img className="rules-page-header-logo" src={logoImg} onClick={this.goToHome} alt="" />
            <Label className="rules-page-header-logo-back" onClick={this.goToHome}>{t('home.back')}</Label>
          </Col>
          <Col sm="2" className="rules-page-header-links">
          {/*  <Link to="/landing">
              <img src={loginIconImg} alt="" />
              {t('home.login')}
            </Link> */}
            <a href="#" className="mr-2" onClick={this.goToHome}>
              <span>
                {t('home.login')}
              </span>
            </a>
            {/*  <a href="#" onClick={this.goToLogin}>
              <img src={loginIconImg} alt="" />
              {t('home.login')}
            </a> */}
          </Col>
          <Col sm="2">
            <LanguageSelect />
          </Col>
        </Row>

        <Row className="rules-page-body" style={{ position: 'relative', maxHeight: 'calc(100% - 40px)', height: 'calc(100% - 40px)' }}>
          <Col sm={{ size: 10, offset: 1 }}>
            <ScrollArea
              speed={0.55}
              className="rules-page-scroll-area"
              contentClassName="rules-page-scroll-content"
              style={{ height: '90%' }}
              verticalContainerStyle={{
                background: 'transparent',
                opacity: 1,
                width: 10,
              }}
              verticalScrollbarStyle={{
                background: '#fff',
                borderRadius: 1,
                width: 7,
                right: -10,
                minHeight: 10,
                minScrollSize: 25,
              }}
              horizontal={false}
            >
              <div dangerouslySetInnerHTML={{ __html: i18n.language == 'lv' ? termsOfText.lv : i18n.language == 'en' ? termsOfText.en : i18n.language == 'ru' ? termsOfText.ru : termsOfText.lv}}/>
            </ScrollArea>
          </Col>
        </Row>

      </div>
    );
  }
}

export default withTranslation('common')(withRouter(RulesPage));
