import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

import CustomModal from '../../Components/Components/ModalQuestion';

import buttonClickedSound from '../../../../../sounds/click_feedback.flac';
import hurryUpClickedSound from '../../../../../sounds/alarm.mp3';
import * as constants from '../../../../../constants/constants';

import {
  setLastRound, startUnlimitedTimer
} from '../../../../../actions/game';
import IconPopover from '../../Components/Components/IconPopover';

class LastRound extends React.Component {
  static propTypes = {
    startUnlimitedTimerFunction: PropTypes.func.isRequired,
    lastRoundFunction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    soundOn: PropTypes.bool,
    roomId: PropTypes.string,
    lastRoundPlayerName: PropTypes.string,
    lastRoundReason: PropTypes.string,
    minGames: PropTypes.number,
    rPlayed: PropTypes.number,
    screenMode: PropTypes.string,
    currentPlayerPosition: PropTypes.string,
    tournamentRoom: PropTypes.bool,
  };

  static defaultProps = {
    soundOn: true,
    roomId: null,
    lastRoundPlayerName: null,
    lastRoundReason: null,
    minGames: 0,
    rPlayed: 0,
    tournamentRoom: false,
    screenMode: constants.SCREEN_MODE.normal,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };

    this.buttonClickedAudio = new Audio(buttonClickedSound);
    this.hurryUpClickedAudio = new Audio(hurryUpClickedSound);
  }

  componentDidMount() {

  }

  approve = () => {
    const { lastRoundFunction, roomId } = this.props;

    lastRoundFunction(roomId);
    this.playButtonSound(this.buttonClickedAudio);
    this.setState({ modalOpen: false });
  }

  openModal = () => {
    const { lastRound, minGames, rPlayed } = this.props;

    if (!(lastRound || (minGames && minGames > rPlayed + 1))) {
      this.setState({ modalOpen: true });
    }
  }

  closeModal = () => {
    this.setState({ modalOpen: false });
  }

  hurryUp = () => {
    const { unlimitedTimerData, startUnlimitedTimerFunction,
      roomId, currentPlayerPosition, playerHasToPutCard } = this.props;

    if(!playerHasToPutCard && (!unlimitedTimerData || !unlimitedTimerData[currentPlayerPosition])){
      this.playButtonSound(this.hurryUpClickedAudio);
      startUnlimitedTimerFunction(roomId);
    }
  }

  onLastRoundHover = () => {
    const { lastRoundPopoverOpen } = this.state;
    if (!lastRoundPopoverOpen) {
      this.setState({ lastRoundPopoverOpen: true });
    }
  }

  onLastRoundHoverLeave = () => {
    this.setState({
      lastRoundPopoverOpen: false,
    });
  }

  onHurryIconHover = () => {
    const { hurryPopoverOpen } = this.state;
    if (!hurryPopoverOpen) {
      this.setState({ hurryPopoverOpen: true });
    }
  }

  onHurryIconHoverLeave = () => {
    this.setState({
      hurryPopoverOpen: false,
    });
  }

  playButtonSound = (audioData) => {
    const { soundOn } = this.props;

    if (soundOn) {
      const playPromise = audioData.play();
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            // Automatic playback started!
            // Show playing UI.
            console.log("audio played auto");
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
            console.log("playback prevented");
          });
      }
    }
  }

  
  render() {
    const {
      t,
      lastRoundPlayerName,
      lastRoundReason,
      minGames,
      rPlayed,
      lastRound,
      screenMode,
      playerHasToPutCard,
      isSpeedUnlimited,
      unlimitedTimerData,
      currentPlayerPosition,
      tournamentRoom,
    } = this.props;

    const { modalOpen, lastRoundPopoverOpen, hurryPopoverOpen } = this.state;
    return (
      <Fragment>
        { !tournamentRoom &&
          <div className={`last-round-icon ${!lastRound && 'active cursor-pointer'} ${(lastRound || (minGames && minGames > rPlayed + 1)) && 'disabled'}`}>
            <div
              className="last-round-icon-click-area"
              onClick={this.openModal}
              id="last-round-popover"
              onMouseEnter={this.onLastRoundHover}
              onMouseLeave={this.onLastRoundHoverLeave}
            />
          </div>
        }
        { isSpeedUnlimited &&
          <div
            className={`hurry-icon ${!playerHasToPutCard && 'active cursor-pointer'} ${(playerHasToPutCard || (unlimitedTimerData && unlimitedTimerData[currentPlayerPosition])) && 'disabled'}`}
          >
            <div
              className="hurry-icon-click-area"
              onClick={this.hurryUp}
              id="hurry-popover"
              onMouseEnter={this.onHurryIconHover}
              onMouseLeave={this.onHurryIconHoverLeave}
            />
          </div>
        }

        {(lastRoundPlayerName || (minGames && minGames > rPlayed + 1)) && !tournamentRoom ? (
          <IconPopover
            text={
              lastRoundPlayerName
              ? (lastRoundReason
                ? t('notifications:lastRoundActiveTournament', { player: lastRoundPlayerName })
                : t('notifications:lastRoundActive', { player: lastRoundPlayerName }))
              : (minGames && minGames > rPlayed + 1 && t('notifications:minGames', { amount: minGames }))
            }
            placement="top"
            targetId="last-round-popover"
            popoverOpen={lastRoundPopoverOpen} />

          // <Popover container={'div > div'} popperClassName={`last-round-popover last-round-new-popover last-round-new-popover-${screenMode}`} placement="top" isOpen={lastRoundPopoverOpen} target="last-round-popover">
          //   <PopoverBody className="last-round-popover-body">
          //     {lastRoundPlayerName ? (
          //       <>
          //         {lastRoundReason ? (
          //           <div>
          //             {t('notifications:lastRoundActiveTournament', { player: lastRoundPlayerName })}
          //           </div>
          //         ) : (
          //           <div>
          //             {t('notifications:lastRoundActive', { player: lastRoundPlayerName })}
          //           </div>
          //         )}
          //       </>
          //     ) : (
          //       <>
          //         {minGames && minGames > rPlayed + 1 && (
          //         <div>
          //           {t('notifications:minGames', { amount: minGames })}
          //         </div>
          //         )}
          //       </>
          //     )}
          //   </PopoverBody>
          // </Popover>
        ) : (
          !tournamentRoom && (
            <IconPopover text={t('common:popovers.lastRound')} placement="top" targetId="last-round-popover" popoverOpen={lastRoundPopoverOpen} />
          )
        )}

        {(!playerHasToPutCard && isSpeedUnlimited) && (
          <Popover container={'div > div'} popperClassName={`last-round-popover last-round-new-popover last-round-new-popover-${screenMode}`} placement="top" isOpen={hurryPopoverOpen} target="hurry-popover">
            <PopoverBody className="last-round-popover-body">
            <div>
              {(unlimitedTimerData && unlimitedTimerData[currentPlayerPosition])
                ? t('notifications:alreadyHurried')
                : t('notifications:hurryUp')
              }
              </div>

            </PopoverBody>
          </Popover>
        )}

        <CustomModal
          isOpen={modalOpen}
          toggle={this.closeModal}
          size="md"
          verticalCentering
          largeText
          title={t('lastRound')}
          footer={(
            <>
              <Button className="question-modal-approve-btn" onClick={this.approve}>
                {t('yes')}
              </Button>
              <Button className="question-modal-decline-btn" onClick={this.closeModal}>
                {t('no')}
              </Button>
            </>
          )}
          body={(
            <p className="text-center">
              {t('lastRoundConfirm')}
            </p>
          )}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  notificationSettings: (state.userSettings[state.member.uid] && state.userSettings[state.member.uid].notificationSettings) ? (state.userSettings[state.member.uid].notificationSettings) : (state.userSettings.default ? state.userSettings.default.notificationSettings : null),
  uid: state.member.uid,
  unlimitedTimerData: state.game.unlimitedTimerData,
  tournamentRoom: state.game.globalParams.tournamentRoom,

});


const mapDispatchToProps = {
  lastRoundFunction: setLastRound,
  startUnlimitedTimerFunction: startUnlimitedTimer,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('game', 'notifications')(LastRound));
