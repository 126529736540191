import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { updateProfile, logout, updateAvatarFileUpload, requestDeleteUser, sendVerify, updateProfilePhoto, updateProfileOld, } from '../actions/member';

import UpdateProfileComponent from '../web/components/User/UpdateProfile';
import * as constants from '../constants/constants';

import { Firebase } from '../lib/firebase';

class UpdateProfile extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    member: PropTypes.shape({}).isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    onFormSubmitPhoto: PropTypes.func.isRequired,
    updateAvatarFileUpload: PropTypes.func.isRequired,
    requestDeleteUser: PropTypes.func.isRequired,
    sendAVerify: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    run: PropTypes.bool,
    newVersion: PropTypes.string,
    openModal: PropTypes.bool,
    holidayModalOpen: PropTypes.bool,
    bonusModalOpen: PropTypes.bool,
    weeklyDataOpen: PropTypes.bool,
    fontMode: PropTypes.string,
  };

  static defaultProps = {
    run: false,
    newVersion: '',
    openModal: false,
    holidayModalOpen: false,
    bonusModalOpen: false,
    weeklyDataOpen: false,
    fontMode: constants.FONT_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      loading: false,
      alertVisible: false,
      deleteStatus: false,
      deleteCount: 10,
      msgType: null,
      msgTypes: [],
    };
    this.deleteCountId = React.createRef(null);
  }

  userAvatarFileUpload = (data) => {
    const { updateAvatarFileUpload} = this.props;

    return updateAvatarFileUpload(data).catch((err) => {
      console.log(err);
    });
  }

  requestDeleteUser = () => {
    const { requestDeleteUser, signOut, t, showNotification, history } = this.props;
    const { deleteCount, deleteStatus } = this.state;
    let deleteC = 0;
    this.setState({ loading: true });

    return requestDeleteUser().then((res) => {
      if (res.status === constants.SERVER_STATUS_CODE.error) {
        // {showNotification(t('warning!'), t('error'), 'warning');}
        this.setState({ 
          loading: false,
          error: t('member.actionFailed'),
          success: constants.FROUNT_SIDE_STATUS.danger,
          alertVisible: true,
        });
      } else {
        this.setState({ 
          loading: false,
          error: t('member.changeSuccess'),
          success: constants.FROUNT_SIDE_STATUS.success,
          alertVisible: true,
          deleteStatus: true,
        });
        setTimeout(() => {
          if (deleteStatus) {
            console.log(deleteStatus, deleteCount, deleteC, "after 10 check");
            clearInterval(this.deleteCountId.current);
            signOut();
            history.push('/landing');
          }
          this.setState({
            alertVisible: false,
            success: null,
            error: null,
          });
        }, 10000);

        deleteC = deleteCount;
        this.deleteCountId.current = setInterval(() => {
          deleteC -= 1;
          console.log(deleteStatus, deleteCount, deleteC, "interval check");
          this.setState({
            deleteCount: deleteC,
          });
        }, 1000);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  exitDeleteFunc = () => {
    const {deleteStatus, deleteCount, deleteC} = this.state;
    console.log(deleteStatus, deleteCount, deleteC,"exit delete func");
    this.setState({
      alertVisible: false,
      success: null,
      error: null,
      deleteStatus: false,
      deleteCount: 10,
    });
    clearInterval(this.deleteCountId.current);
  }

  sendAVerify = () => {
    const { sendAVerify, t, showNotification } = this.props;

    return sendAVerify();
  }

  onFormSubmit = (data, addEmailFlag) => {
    const { onFormSubmit, t , showNotification} = this.props;

    this.setState({ loading: true, msgType: '', msgTypes: []});

    return new Promise(async (resolve, reject) => {
      onFormSubmit(data, addEmailFlag).then(async (res) => {
        console.log('onFormSubmit updatProfile', res, "=================");
        if (res.status === constants.FROUNT_SIDE_STATUS.error && res.message === 'maxNameChangeLimit') {
          // showNotification(t('warning!'), t('errorAccountRenameCount'), 'warning');
          this.setState({
            loading: false,
            success: constants.FROUNT_SIDE_STATUS.warning,
            error: t('member.maxNameChangeLimit'),
            alertVisible: true,
          });
          // this.setState({loading: false, error:  t('errorAccountRenameCount')});
        } else if (data && data.changeEmail && res && res.status === constants.FROUNT_SIDE_STATUS.success && res.emailChanged) {
          const { history, signOut } = this.props;

         /* this.setState({
            loading: false,
            success: constants.FROUNT_SIDE_STATUS.success,
            error: t('member.successReAuth'),
            alertVisible: true,
          }); */

          // showNotification(t('success!'), t('successReAuth'), 'success');

          if (res && res.customToken) {
            console.log('updatProfile call relog');

            this.setState({
              loading: false,
              success: constants.FROUNT_SIDE_STATUS.success,
              error: `${t('member.changeSuccess')} ${t('member.verifiedEmailSent2')}`,
              alertVisible: true,
            });

          //  setTimeout(() => {
            await Firebase.auth().signInWithCustomToken(res.customToken);
          //  }, 3500);
          } else {
            this.setState({
              loading: false,
              success: constants.FROUNT_SIDE_STATUS.success,
              error: t('member.successReAuth'),
              alertVisible: true,
            });

            console.log('updatProfile call signOut');

            setTimeout(() => {
              signOut();
              history.push('/landing');
            }, 3500);
          }
          
        } else {
          // showNotification(t('success!'), t('success'), 'success');
          if (!res.status || res.status !== constants.FROUNT_SIDE_STATUS.success) {
            this.setState({
              loading: false,
              success: constants.FROUNT_SIDE_STATUS.danger,
              error: res.message ? t(`member.${res.message}`) : t('member.actionFailed'),
              alertVisible: true,
              msgType: res?.msgType || '',
              msgTypes: res?.msgTypes || [],
            });
          } else {
            this.setState({
              loading: false,
              success: constants.FROUNT_SIDE_STATUS.success,
              error: t('member.changeSuccess'),
              alertVisible: true,
            });
          }
        }
      }).catch(err => {
        console.log(err);
        let { message } = err;
        let text;
        if (message === 'auth/requires-recent-login') {
        //  message = 'Lai nomainītu epastu nepieciešama nesena ielogošanās';
          text = t('member.reAuth');
        } else if (message === 'auth/email-already-in-use') {
        //  message = 'Epasta adrese jau aizņemta';
          text = t('member.auth/email-already-in-use', {email: data.email});
        } else {
          text = t(`member.${message}`);
        }
        console.log("error message", {text});
        // showNotification(t('warning!'), text, 'warning');
        this.setState({
          loading: false,
          success: constants.FROUNT_SIDE_STATUS.danger,
          error: text,
          alertVisible: true,
        });
      });
      // setTimeout(() => {
      //   this.setState({
      //     alertVisible: false,
      //     success: null,
      //     error: null,
      //   });
      // }, 10000);
    });
  }

  onFormSubmitPhoto = (data) => {
    const { onFormSubmitPhoto, t , showNotification} = this.props;

    this.setState({ loading: true, msgType: '', msgTypes: []});

    return new Promise(async (resolve, reject) => {
      onFormSubmitPhoto(data).then(async (res) => {
        console.log('onFormSubmit updatProfile', res, "=================");
        if (res.status === constants.FROUNT_SIDE_STATUS.error && res.message === 'maxNameChangeLimit') {
          // showNotification(t('warning!'), t('errorAccountRenameCount'), 'warning');
          this.setState({
            loading: false,
            success: constants.FROUNT_SIDE_STATUS.warning,
            error: t('member.maxNameChangeLimit'),
            alertVisible: true,
          });
          // this.setState({loading: false, error:  t('errorAccountRenameCount')});
        } else if (data && data.changeEmail && res && res.status === constants.FROUNT_SIDE_STATUS.success && res.emailChanged) {
          const { history, signOut } = this.props;

         /* this.setState({
            loading: false,
            success: constants.FROUNT_SIDE_STATUS.success,
            error: t('member.successReAuth'),
            alertVisible: true,
          }); */

          // showNotification(t('success!'), t('successReAuth'), 'success');

          if (res && res.customToken) {
            console.log('updatProfile call relog');

            this.setState({
              loading: false,
              success: constants.FROUNT_SIDE_STATUS.success,
              error: `${t('member.changeSuccess')} ${t('member.verifiedEmailSent2')}`,
              alertVisible: true,
            });

          //  setTimeout(() => {
            await Firebase.auth().signInWithCustomToken(res.customToken);
          //  }, 3500);
          } else {
            this.setState({
              loading: false,
              success: constants.FROUNT_SIDE_STATUS.success,
              error: t('member.successReAuth'),
              alertVisible: true,
            });

            console.log('updatProfile call signOut');

            setTimeout(() => {
              signOut();
              history.push('/landing');
            }, 3500);
          }
          
        } else {
          // showNotification(t('success!'), t('success'), 'success');
          if (!res.status || res.status !== constants.FROUNT_SIDE_STATUS.success) {
            this.setState({
              loading: false,
              success: constants.FROUNT_SIDE_STATUS.danger,
              error: res.message ? t(`member.${res.message}`) : t('member.actionFailed'),
              alertVisible: true,
              msgType: res?.msgType || '',
              msgTypes: res?.msgTypes || [],
            });
          } else {
            this.setState({
              loading: false,
              success: constants.FROUNT_SIDE_STATUS.success,
              error: t('member.changeSuccess'),
              alertVisible: true,
            });
          }
        }
      }).catch(err => {
        console.log(err);
        let { message } = err;
        let text;
        if (message === 'auth/requires-recent-login') {
        //  message = 'Lai nomainītu epastu nepieciešama nesena ielogošanās';
          text = t('member.reAuth');
        } else if (message === 'auth/email-already-in-use') {
        //  message = 'Epasta adrese jau aizņemta';
          text = t('member.auth/email-already-in-use', {email: data.email});
        } else {
          text = t(`member.${message}`);
        }
        console.log("error message", {text});
        // showNotification(t('warning!'), text, 'warning');
        this.setState({
          loading: false,
          success: constants.FROUNT_SIDE_STATUS.danger,
          error: text,
          alertVisible: true,
        });
      });
      // setTimeout(() => {
      //   this.setState({
      //     alertVisible: false,
      //     success: null,
      //     error: null,
      //   });
      // }, 10000);
    });
  }

  closeAlertFunc = () => {
    this.setState({
      alertVisible: false,
      success: null,
      error: null,
      msgType: null,
      msgTypes: [],
    });
  }

  render = () => {
    const { memberUid, fontMode, run, newVersion, openModal, holidayModalOpen, bonusModalOpen, weeklyDataOpen, lastName, firstName, name, modalOpen, toggle, email, photo, contactEmail, birthday, verifiedEmail, showNotification, socProvider, history, signOut, isInAppFrame, loggedInUserDataFetched } = this.props;
    const { error, loading, success, alertVisible, deleteStatus, deleteCount, msgType, msgTypes } = this.state;

    console.log('loggedInUserDataFetched test111', loggedInUserDataFetched, verifiedEmail, email);

    return (
      <UpdateProfileComponent
        key={memberUid}
        alertVisible={alertVisible}
        deleteStatus={deleteStatus}
        deleteCount={deleteCount}
        error={error}
        firstName={firstName}
        lastName={lastName}
        name={name}
        photo={photo}
        email={email}
        contactEmail={contactEmail}
        verifiedEmail={verifiedEmail}
        loggedInUserDataFetched={loggedInUserDataFetched}
        modalOpen={modalOpen}
        toggle={toggle}
        loading={loading}
        success={success}
        onFormSubmit={this.onFormSubmit}
        onFormSubmitPhoto={this.onFormSubmitPhoto}
        updateAvatarFileUpload={this.userAvatarFileUpload}
        requestDeleteUser={this.requestDeleteUser}
        sendVerify={this.sendAVerify}
        showNotification={showNotification}
        socProvider={socProvider}
        exitDeleteFunc={this.exitDeleteFunc}
        signOut={signOut}
        history={history}
        closeAlertFunc={this.closeAlertFunc}
        run={run}
        newVersion={newVersion}
        openModal={openModal}
        holidayModalOpen={holidayModalOpen}
        bonusModalOpen={bonusModalOpen}
        weeklyDataOpen={weeklyDataOpen}
        birthday={birthday}
        fontMode={fontMode}
        msgType={msgType}
        msgTypes={msgTypes}
        isInAppFrame={isInAppFrame}
      />
    );
  }
}

const mapStateToProps = state => ({
  memberUid: state.member.uid || null,
  loggedInUserDataFetched: state.member.loggedInUserDataFetched,

});

const mapDispatchToProps = {
  onFormSubmit: updateProfileOld,
  onFormSubmitPhoto: updateProfilePhoto,
  signOut: logout,
  updateAvatarFileUpload,
  requestDeleteUser,
  sendAVerify: sendVerify,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation('common')(UpdateProfile)));
