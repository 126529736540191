import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Firebase } from '../lib/firebase';

import {
  login, getDraugiemAuthUrl, resetPassword, signUp, recoverUserEmail,
} from '../actions/member';
import * as constants from '../constants/constants';
import { errorMessages } from '../constants/messages';

import { saveRefferalToken } from '../actions/refferal';

class Login extends Component {
  static propTypes = {
    Layout: PropTypes.shape({}).isRequired,
    member: PropTypes.shape({}).isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    getDraugiemAuthUrlFunc: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    toggleFullscreen: PropTypes.func.isRequired,
    onForgotPasswordFormSubmit: PropTypes.func.isRequired,
    registerFormSubmit: PropTypes.func.isRequired,
    resetPasswordFlag: PropTypes.bool,
    isFullscreenEnabled: PropTypes.bool,
    screenMode: PropTypes.string,
    recoverUserEmailSumbit: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    toggleFont: PropTypes.func.isRequired,
    newDesign: PropTypes.bool,
    saveRefToken: PropTypes.func.isRequired,
  }

  static defaultProps = {
    resetPasswordFlag: null,
    isFullscreenEnabled: null,
    screenMode: constants.SCREEN_MODE.dark,
    fontMode: constants.FONT_MODE.normal,
    newDesign: null,
  }

  /* state = {
    error: null,
    success: null
  } */

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      loading: false,
      messageType: constants.stages.login,
      //  resetPasswordFlag: false,
      //  isFullscreenEnabled: false,
      //  screenMode: constants.SCREEN_MODE.dark,
      recoverUser: null,
      devMode: false,
    };
  }

  componentDidMount = () => {
    console.log('componentDidMount login container');
    const { saveRefToken } = this.props;
    const { hostname } = window.location;

    console.log('hostname', hostname);

    if (hostname === 'inbox.dev.spelezoli.lv' || hostname === 'dra.dev.spelezoli.lv' || hostname === 'dev.spelezoli.lv' || hostname === 'localhost') {
      this.setState({ devMode: true });
    }

    try {
      const searchParams = new URLSearchParams(window.location.search);
      const refferalToken = searchParams.get('refferalToken');

      console.log('refferalToken', refferalToken);

      if (refferalToken) {
        // this.setState({ refferalToken });

        saveRefToken(refferalToken);
      }
    } catch (err) {
      console.log(err);
    }
  }

  componentDidUpdate = () => {
    console.log('componentDidUpdate login container');
  }

  onFormSubmit = (data, language) => {
    const { onFormSubmit } = this.props;

    this.setState({ loading: true });

    return onFormSubmit(data, language)
      .then(() => this.setState({
        success: 'auth/success-login',
        error: null,
        messageType: constants.stages.login,
        loading: false,
      })).catch((err) => {
        console.log(err.code, 'changed email container');
        this.setState({
          success: null,
          error: err.code,
          messageType: constants.stages.login,
          loading: false,
          recoverUser: (err.code === errorMessages.changedEmail && err?.recoverUser) && err?.recoverUser 
        });
        throw err; // To prevent transition back
      });
  }

  onForgotPasswordFormSubmit = (data, language) => {
    const { onForgotPasswordFormSubmit } = this.props;

    this.setState({ loading: true });

    console.log('onForgotPasswordFormSubmit', data, language);

    return onForgotPasswordFormSubmit(data, language)
      .then(() => this.setState({
        loading: false,
        success: 'auth/success-password-emailed',
        error: null,
        messageType: constants.stages.forgotPassword,
      })).catch((err) => {
        this.setState({
          loading: false,
          success: null,
          error: err.code,
          messageType: constants.stages.forgotPassword,
        });
        throw err; // To prevent transition back
      });
  }

  submitResetPassword = (email, lang, actionCode, password, confirmPass) => {
    const { history } = this.props;
    this.setState({ loading: true });
    if (!password || !confirmPass) {
      return this.setState({
        loading: false,
        success: null,
        error: errorMessages.missingPassword,
        messageType: constants.stages.resetPassword,
      });
    }
    if (password !== confirmPass) {
      return this.setState({
        loading: false,
        success: null,
        error: errorMessages.passwordsDontMatch,
        messageType: constants.stages.resetPassword,
      });
    }
    if (password.length < 6) {
      return this.setState({
        loading: false,
        success: null,
        error: errorMessages.wrongPassword,
        messageType: constants.stages.resetPassword,
      });
    }
    if (password && password.length >= 6) {
      // Save the new password.
      Firebase.auth().confirmPasswordReset(actionCode, password).then(() => {
        // Password reset has been confirmed and new password updated.
        return this.onFormSubmit({ email, password }, lang)
          .then(() => {
            history.push('/');
            this.setState({ loading: true });
          });
      }).catch((error) => {
        this.setState({
          loading: false,
          success: null,
          error: error.code,
          messageType: constants.stages.forgotPassword,
        });
        throw error;
      });
    }
  }

  //  _getDraugiemAuthUrl = (redirectUrl) => {
  //    const { getDraugiemAuthUrl } = this.props;
  //    console.log('_getDraugiemAuthUrl');
  //    return getDraugiemAuthUrl(redirectUrl);
  //  }

  initialState = () => {
    this.setState({
      error: '',
      success: '',
      messageType: constants.stages.login,
    });
  }

  onRegisterFormSubmit = (data, lang) => {
    const { registerFormSubmit } = this.props;
    this.setState({ loading: true });
    return registerFormSubmit(data, lang)
      .then((res) => {
        if (res.type === 'USER_DATA' && res.data && res.data.uid) {
          this.setState({
            loading: false,
            success: 'auth/reg-success',
            error: null,
            messageType: constants.stages.register,
          });
        } else {
          console.log('onRegisterFormSubmit: ', res);

          this.setState({
            loading: false,
            success: null,
            error: res.code,
            messageType: constants.stages.register,
          });
        }
      }).catch((err) => {
        this.setState({
          loading: false,
          success: null,
          error: err.code,
          messageType: constants.stages.register,
        });
        throw err; // To prevent transition back
      });
  }

  recoverUserSubmit = (recover, language) => {
    const { recoverUserEmailSumbit } = this.props;
    const { devMode } = this.state;

    //  let recoverData = { ...recover, devMode };

    console.log('recoverUserSubmit', recover, devMode, language);

    recoverUserEmailSumbit(recover, devMode, language).then((res) => {
      this.setState({
        loading: false,
        success: errorMessages.recoverSuccess,
        error: null,
      });
    }).catch((err) => {
      console.log(err);

      this.setState({
        loading: false,
        success: null,
        error: err.code,
      });
      throw err; // To prevent transition back
    });
  }

  toggleRecoverUser = () => {
    this.setState({
      error: '',
    });
  }

  render = () => {
    const {
      fontMode, toggleFont, newDesign, member, Layout, getDraugiemAuthUrlFunc, toggleFullscreen, resetPasswordFlag, isFullscreenEnabled, screenMode,
    } = this.props;
    const {
      error, success, loading, messageType, recoverUser,
    } = this.state;

    // console.log('render login container', { props: this.props });

    return (
      <Layout
        error={error}
        member={member}
        success={success}
        loadingSubmit={loading}
       // history={history}
        getDraugiemAuthUrl={getDraugiemAuthUrlFunc}
        onFormSubmit={this.onFormSubmit}
        onForgotPasswordFormSubmit={this.onForgotPasswordFormSubmit}
        toggleFullscreen={toggleFullscreen}
        messageType={messageType}
        initialState={this.initialState}
        onSubmitResetPassword={this.submitResetPassword}
        resetPasswordFlag={resetPasswordFlag}
        onRegisterFormSubmit={this.onRegisterFormSubmit}
        isFullscreenEnabled={isFullscreenEnabled}
        screenMode={screenMode}
        recoverUser={recoverUser}
        toggleRecoverUser={this.toggleRecoverUser}
        onRecoverUserSubmit={this.recoverUserSubmit}
        fontMode={fontMode}
        toggleFont={toggleFont}
        newDesign={newDesign}
      />
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});


const mapDispatchToProps = {
  onFormSubmit: login,
  getDraugiemAuthUrlFunc: getDraugiemAuthUrl,
  onForgotPasswordFormSubmit: resetPassword,
  registerFormSubmit: signUp,
  recoverUserEmailSumbit: recoverUserEmail,
  saveRefToken: saveRefferalToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
