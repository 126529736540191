
import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import { map, filter } from 'lodash';

import SwitchSelection from '../../../Components/Components/SwitchSelection';
import SwitchSelect from '../../../Components/Components/SwitchSelect';
import Toggle from '../../../Components/Components/Toggle';

import {
  toggleFilterParam,
} from '../../../../../../actions/userSettings';
import * as constants from '../../../../../../constants/constants';


class Filter extends React.Component {
  static propTypes = {
    uid: PropTypes.string,
    t: PropTypes.func.isRequired,
    userSettings: PropTypes.shape(),
    handleChange: PropTypes.func.isRequired,
    searchValue: PropTypes.string,
    activeType: PropTypes.string,
    activeTab: PropTypes.string,
    filterLevels: PropTypes.arrayOf(PropTypes.string.isRequired),
    handleActiveTab: PropTypes.func.isRequired,
    handleActiveType: PropTypes.func.isRequired,
    handleFilterLevel: PropTypes.func.isRequired,
    handleShowOnlyActive: PropTypes.func.isRequired,
    handleMillionairesOpen: PropTypes.func.isRequired,
    millionairesOpen: PropTypes.bool,
    showOnlyActive: PropTypes.bool,
    saveFilters: PropTypes.func.isRequired,
    handleKeyPress: PropTypes.func.isRequired,
    selectFieldArray: PropTypes.arrayOf(PropTypes.string.isRequired),
    handleSelectType: PropTypes.func.isRequired,
    showNoOlderMeOpen: PropTypes.bool,
    handleShowNoOlderMeOpen: PropTypes.func.isRequired,
  }

  static defaultProps = {
    uid: null,
    userSettings: {},
    searchValue: '',
    activeType: constants.filterLeaderboardTypeVal.totalPnts,
    activeTab: constants.filterLeaderboardActiveTypes.allTime,
    filterLevels: [],
    showOnlyActive: false,
    millionairesOpen: false,
    selectFieldArray: [],
    showNoOlderMeOpen: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      // filterLevels: props.filterLevels,
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   const { filterLevels } = this.props;
  //   if (filterLevels !== nextProps.filterLevels) {
  //     this.setState({
  //       filterLevels: nextProps.filterLevels,
  //     });
  //   }
  // };

  saveFilters = () => {
    const { toggleFilterParameter, uid } = this.props;
    const { filters } = this.state;

    // console.log('saveFilters', { filters, uid });
    toggleFilterParameter(filters, uid);
  }

  selectFunc = (selectType, selectItem) => {
    const { handleActiveTab, handleFilterLevel } = this.props;

    if (selectType === constants.TOP_SELECTION_TYPE.topPeriod) {
      handleActiveTab(constants.filterLeaderboardActive.filter(item => item.value === selectItem)[0].id);
    }
    if (selectType === constants.TOP_SELECTION_TYPE.topLevel) {
      handleFilterLevel(selectItem);
    }
  }

  render() {
    const {
      t,
      handleChange,
      searchValue,
      activeTab,
      activeType,
      millionairesOpen,
      showOnlyActive,
      handleShowOnlyActive,
      handleMillionairesOpen,
      handleActiveType,
      filterLevels,
      saveFilters,
      handleKeyPress,
      selectFieldArray,
      handleSelectType,
      handleShowNoOlderMeOpen,
      showNoOlderMeOpen,
    } = this.props;
// console.log(selectFieldArray, "show filed check filter");
// console.log(filterLevels, "show filed check levels");
    return (

      <Row className="filter-body-section">
        <Col>
          {/* <Row className="filter-body-section-row filter-body-section-search-row">
            <Col xs="12" className="filter-body-section-row-search-filter-col">
              <Input
                type="text"
                name="searchValue"
                id="searchValue"
                className="filter-body-section-row-search-filter"
                placeholder={t('top.searchPlayer')}
                value={searchValue}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
            </Col>
          </Row> */}
          <div style={{maxHeight: 'calc(100vh - 190px)', overflowY: 'auto', overflowX: 'hidden'}}>
            <Row className="filter-body-section-row filter-body-section-search-row">
              <Col xs="12">
                <Row>
                  <Col sm="12" className="filter-body-section-row-label">{t('top.topPeriod')}</Col>
                  <Col sm="12" className="filter-body-section-row-text">
                    <SwitchSelection options={constants.filterLeaderboardActive} selectedOption={constants.filterLeaderboardActive.filter(item => item.id === activeTab.toString())[0].value} selectFunc={this.selectFunc} selectType={constants.TOP_SELECTION_TYPE.topPeriod} topPage />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" className="filter-body-section-row-label filter-body-section-row-player-label">{t('top.playerLevel')}</Col>
                  <Col sm="12" className="filter-body-section-row-text">
                    <SwitchSelection options={constants.PLAYER_LEVEL_TYPE_FIELDS} selectedOptions={filterLevels} selectFunc={this.selectFunc} selectType={constants.TOP_SELECTION_TYPE.topLevel} multiple topPage />
                  </Col>
                </Row>
                <Row className="filter-body-section-row-action">
                  <div className="filter-body-section-row-label">
                    <Toggle checked={showOnlyActive} onChange={handleShowOnlyActive} text={t('top.onlyActive')} />
                  </div>
                  <div className="filter-body-section-row-label">
                    <Toggle checked={millionairesOpen} onChange={handleMillionairesOpen} text={t('top.onlyMillionaires')} />
                  </div>
                  <div className="filter-body-section-row-label">
                    <Toggle checked={showNoOlderMeOpen} onChange={handleShowNoOlderMeOpen} text={t('top.showNoOlderMe')} />
                  </div>
                </Row>
              </Col>
            </Row>
            <Row className="filter-body-section-row filter-body-section-search-row filter-body-section-search-row-visible-columns">
              <Col sm="12" className="filter-body-section-row-label filter-body-section-row-label">{t('top.visibleColumns')}</Col>
              <Col xs="12" className="">
                <Row className="filter-body-section-row-visible-columns">
                  {
                    map(constants.filterLeaderboardType, (item, key) => (
                      constants.SHOW_PLACE_FILEDS.indexOf(item.label) === -1 && (
                        <div className="filter-body-section-row-label-visible-column">
                          <Toggle checked={item.id === activeType.toString()} name={item.label} onChange={() => handleActiveType(item.id)} text={t(`top.${item.tLabel}`)} />
                        </div>
                      )
                    ))
                  }
                </Row>
              </Col>
            </Row>

            <Row className="filter-body-section-row filter-body-section-search-row filter-body-section-search-row-visible-columns">
              <Col sm="12" className="filter-body-section-row-label filter-body-section-row-label">{t('top.extraColumns')}</Col>
              <Col xs="12" className="">
                <Row className="filter-body-section-row-visible-columns">
                  {
                    map(constants.filterLeaderboardType, (item, key) => (
                      constants.SHOW_PLACE_FILEDS.indexOf(item.label) !== -1 && (
                        <div className="filter-body-section-row-label-visible-column">
                          <Toggle checked={selectFieldArray.includes(item.label)} name={item.label} onChange={() => handleSelectType(item.id)} text={t(`top.${item.tLabel}`)} />
                        </div>
                      )
                    ))
                  }
                </Row>
              </Col>
            </Row>

          </div>
          <div className="modal-footer">
            <Button
              className="layout-highlighted-button"
              color="link"
              onClick={saveFilters}
              id="newGame-button"
              onMouseEnter={this.onHover}
              onMouseLeave={this.onHoverLeave}
            >
              {t('common.save')}
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  level: state.member.level || null,
  uid: state.member.uid || null,
  userSettings: state.userSettings[state.member.uid] || state.userSettings.default || {},
});

const mapDispatchToProps = {
  toggleFilterParameter: toggleFilterParam,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Filter));
