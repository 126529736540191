import React from 'react';
import PropTypes from 'prop-types';

const Store = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Spēles sākumlapā ir poga ‘Zoles Veikals’, ar kuru var atvērt spēles veikalu, kur var iegādāties spēles naudu, kā arī atsevišķas Premium iespējas.
        </p>
        <p>Pašlaik pieejamas šādas apmaksas metodes:</p>
        <ul>
        <li><b>draugiem.lv</b>: portālā iebūvētais maciņš, kā arī tam piesaistītā bankas karte, i-banka un SMS</li>
        <li><b>Facebook.com</b>: bankas karte un PayPal</li>
        <li><b>WEB (spelezoli.lv)</b>: bankas karte</li>
        <li><b>Mobile App</b>: bankas karte, Apple Pay, Google Pay</li>
        </ul>
        <p>Atceries: atšķirīgu tarifu un valūtu dēļ katrā platformā cenas Zoles veikalā var nedaudz atšķirties.</p>

      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
        На начальной странице игры есть кнопка «Магазин Золе», при помощи которой вы можете открыть магазин, где можно приобресьти игровые деньги, а так  же отдельные Premium возможности.
        </p>
        <p>На данный момент возможны следующие виды оплаты:</p>
        <ul>
        <li><b>на портале draugiem.lv</b>: встроенный кошелек, а так же банковская карта к нему, интернетбанк и SMS</li>
        <li><b>Facebook.com</b>: банковская карта и PayPal</li>
        <li><b>WEB (spelezoli.lv)</b>: банковская карта</li>
        <li><b>Mobile App</b>: банковская карта, Apple Pay, Google Pay</li>
        </ul>
        <p>Помни: из-за различия тарифов и валют цены в магазине Золе на платформах могут быть разными.</p>

      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
        On the home page, there is a button "Zole Store", which opens a game store, where players can buy game coins, as well as some Premium features.
        </p>
        <p>The following payment methods are currently available:</p>
        <ul>
        <li><b>draugiem.lv</b>: the wallet built into the portal, as well as the bank card, i-bank, and SMS attached to it;</li>
        <li><b>Facebook.com</b>: bank card and PayPal;</li>
        <li><b>WEB (spelezoli.lv)</b>: bank card;</li>
        <li><b>Mobile App</b>: bank card, Apple Pay, Google Pay. </li>
        </ul>
        <p>NB! Due to different tariffs and currencies on each platform, prices in the "Zole store" may differ slightly.</p>

      </>
    )
  }
};

Store.propTypes = {
  lang: PropTypes.string,
};

Store.defaultProps = {
  lang: 'lv',
};

export default Store;
