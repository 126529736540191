import React from 'react';
import PropTypes from 'prop-types';
import { map, filter, find } from 'lodash';

import { withTranslation } from 'react-i18next';

import * as constants from '../../../../constants/constants';

const PlayerPositionName = React.memo(({ myPos, player1ShortName, player2ShortName, player3ShortName, player4ShortName }) => (
  <div className="score-table-header-player">
    {
      myPos === constants.ROOM_PLAYER_POSITION.player1 ? (
        player1ShortName || ''
      )
        : myPos === constants.ROOM_PLAYER_POSITION.player2 ? (
          player2ShortName || ''
        )
          : myPos === constants.ROOM_PLAYER_POSITION.player3 ? (
            player3ShortName || ''
          )
            : myPos === constants.ROOM_PLAYER_POSITION.player4 ? (
              player4ShortName || ''
            ) : (null)
    }
  </div>
));

export default withTranslation('notifications')(PlayerPositionName);
