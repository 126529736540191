import React, { Fragment, useParams } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Media, Button, Spinner } from 'reactstrap';

import {
  FONT_MODE,
} from '../../../constants/constants';
import CustomModal from './Components/Components/Modal';

import checkAction from '../../../images/redesign/icons/checkAction.svg';
import checkFalseAction from '../../../images/redesign/icons/checkFalseAction.svg';
import { setTransactionComplete } from "../../../actions/shop";

const NewTransactionSuccess = ({ stripeStatus, isFullscreen, history, fontMode, setTransactionCompleteFunc, paymentState }) => {
  const { t } = useTranslation('common');
  const stripeStatusCondition = stripeStatus === 'true' || stripeStatus === true; // has 2 conditions to still support stripe payments

  const togglePage = () => {

    const activePage = '5';
    // history.push(`/activeLink/${activePage}`);
    history.push('/');
    setTransactionCompleteFunc(true);
  };
  return (
    <Fragment>
      <div>
        <CustomModal
          isOpen={true}
          toggle={() => this.toggleModal()}
          size="md"
          zIndex={'unset'}
          title={stripeStatus !== null ? (
              stripeStatusCondition ? (
                  t('common.paymentSuccess')
                ) : (
                  paymentState === 'canceled' ? t('common.paymentCanceled') : t('common.paymentFailed')
                )
            ) : (
              t('home.stripeLoading')
            )}
          addClassName={stripeStatusCondition ? "payment-true-modal" : "payment-false-modal"}
          footerClose={true}
          body={
            <div className="d-flex align-items-center">
              <div className={stripeStatusCondition ? "payment-true-modal-content" : "payment-false-modal-content"}>
                { stripeStatus === null
                  ? <Spinner className="payment-loading-spinner" children={false} />
                  : <Media src={stripeStatusCondition ? checkAction : checkFalseAction} className="contact-message-sent-success-image" />
                }
              </div>
            </div>
          }
          footer={
            <Button onClick={() => togglePage()} disabled={stripeStatus === null}>
              {t('common.continue')}
            </Button>
          }
        />
      </div>
    </Fragment>
  )
}

NewTransactionSuccess.propTypes = {
  stripeStatus: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  fontMode: PropTypes.string,
};

NewTransactionSuccess.defaultProps = {
  stripeStatus: false,
  isFullscreen: false,
  history: {},
  fontMode: FONT_MODE.normal,
};

const mapDispatchToProps = {
  setTransactionCompleteFunc: setTransactionComplete,
};

export default connect(null, mapDispatchToProps)(NewTransactionSuccess);
