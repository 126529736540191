import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import Row from 'reactstrap/lib/Row';
// import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Container from 'reactstrap/lib/Container';
import Media from 'reactstrap/lib/Media';
import Spinner from 'reactstrap/lib/Spinner';

import { useTranslation } from 'react-i18next';
// import logoImg from '../../../images/Menu/zole_logo.webp';
import checkAction from '../../../images/icons/checkAction.svg';
import checkFalseAction from '../../../images/icons/checkFalseAction.svg';
import { FONT_MODE } from '../../../constants/constants';
import { setTransactionComplete } from '../../../actions/shop';
import { Helmet } from 'react-helmet';

const TransactionSuccess = ({ stripeStatus, paymentState, isFullscreen, history, fontMode, setTransactionCompleteFunc, pageTitle }) => {
  const { t } = useTranslation('common');
  const stripeStatusCondition = stripeStatus === 'true' || stripeStatus === true; // has 2 conditions to still support stripe payments
  const togglePage = () => {

    const activePage = '5';
    // history.push(`/activeLink/${activePage}`);
    history.push('/');
    setTransactionCompleteFunc(true);
  };
  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="test" style={isFullscreen ? { height: '100vh', width: '100vw '} : {}}>
        <div className={`${isFullscreen ? 'overflow-manage' : ''}`}>
          <Container className="landing-container">
            <div className="landing-transaction-bg" />
            <div className="landing-transaction-logo" />
          </Container>
        </div>
      </div>

      <Modal container={'div > div'} isOpen className={`transactionSuccessModal old-modal root-font-${fontMode}`}>
        <ModalHeader className="transaction-success-header">
          { stripeStatus !== null ? (
              stripeStatusCondition ? (
                  t('common.paymentSuccess')
                ) : (
                  paymentState === 'canceled' ? t('common.paymentCanceled') : t('common.paymentFailed')
                )
            ) : (
              t('home.stripeLoading')
            )
          }
        </ModalHeader>
        <ModalBody>
          <div className="transaction-success-body">
            <div className="transaction-success-body-icon">
              { stripeStatus === null
                ? <Spinner className="transaction-loading-spinner" children={false} />
                : <Media src={stripeStatusCondition ? checkAction : checkFalseAction} className="event-tree-svg-before" />
              }
            </div>
            <div className="transaction-success-footer">
              <div>
                <Button color="link" className="buy-page-premium-button full-width" onClick={() => togglePage()} disabled={stripeStatus === null}>
                  {t('common.continue')}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  )};

TransactionSuccess.propTypes = {
  stripeStatus: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  fontMode: PropTypes.string,
};

TransactionSuccess.defaultProps = {
  stripeStatus: false,
  isFullscreen: false,
  history: {},
  fontMode: FONT_MODE.normal,
};

const mapDispatchToProps = {
  setTransactionCompleteFunc: setTransactionComplete,
};

export default connect(null, mapDispatchToProps)(TransactionSuccess);
