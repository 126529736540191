import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// import Moment from 'react-moment';
// import ScrollArea from 'react-scrollbar';

// import isEqual from 'react-fast-compare';

import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import NavLink from 'reactstrap/lib/NavLink';
// import ScrollAreaButtons from '../UI/ScrollAreaButtons';

import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import CustomDate from '../UI/CustomDate';
import { getMoneyHistoryText } from '../../../common/services/data-service';

import {
  getBalanceHistory,
} from '../../../actions/member';
// import { Container } from 'reactstrap';

class MoneyHistory extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fetchBalanceHistory: PropTypes.func.isRequired,
    balanceHistory: PropTypes.arrayOf(PropTypes.shape({})),
    offset: PropTypes.number,
  };

  static defaultProps = {
    balanceHistory: [],
    offset: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      scrollPos: 0,
    };
  }

  /*
  shouldComponentUpdate(nextProps, nextState) {
    if (!isEqual(nextProps, this.props)) {
      return true;
    }

    if (!isEqual(nextState, this.state)) {
      return true;
    }

    return false;
  } */

  toggle = async (tab) => {
    const { fetchBalanceHistory, offset } = this.props;

    if (tab === '1') {
      await fetchBalanceHistory('today', offset);
    } else if (tab === '2') {
      await fetchBalanceHistory('yesterday', offset);
    } else if (tab === '3') {
      await fetchBalanceHistory('2daysBefore', offset);
    }

    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  };

  scroll = (newVal) => {
    this.messagesScrollbar.scrollYTo(newVal);
  };

  render() {
    const { balanceHistory, t } = this.props;

    const { activeTab, scrollPos } = this.state;

    return (
      <Fragment>
        <Row className="money-history-tabs" style={{ marginBottom: 10 }}>
          <Col sm="12">
            <Row style={{ margin: 'auto' }}>
              <Col
                xs="4"
                md="4"
                lg={{ size: 3, offset: 1 }}
                className="money-history-tab"
              >
                <div
                  className={`money-history-tab-wrapper ${
                    activeTab === '1' && 'active'
                  }`}
                >
                  <NavLink
                    className="money-history-tab-link"
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    {t('moneyHistory.today')}
                  </NavLink>
                </div>
              </Col>
              <Col xs="4" md="4" lg="3" className="money-history-tab">
                <div
                  className={`money-history-tab-wrapper ${
                    activeTab === '2' && 'active'
                  }`}
                >
                  <NavLink
                    className="money-history-tab-link"
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    {t('moneyHistory.yesterday')}
                  </NavLink>
                </div>
              </Col>
              <Col xs="4" md="4" lg="3" className="money-history-tab">
                <div
                  className={`money-history-tab-wrapper ${
                    activeTab === '3' && 'active'
                  }`}
                >
                  <NavLink
                    className="money-history-tab-link"
                    onClick={() => {
                      this.toggle('3');
                    }}
                  >
                    {t('moneyHistory.2daysAgo')}
                  </NavLink>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Row className="money-history-table-header">
              <Col sm="2" className="money-history-table-header-col">
                {t('moneyHistory.time')}
              </Col>
              <Col sm="4" className="money-history-table-header-col" style={{textAlign: 'start'}}>
                {t('moneyHistory.type')}
              </Col>
              <Col className="money-history-table-header-col">
                {t('moneyHistory.old')}
              </Col>
              <Col className="money-history-table-header-col">
                {t('moneyHistory.new')}
              </Col>
              <Col className="money-history-table-header-col">
                {t('moneyHistory.change')}
              </Col>
            </Row>
            {/*  <ScrollAreaButtons
              scrollPos={scrollPos}
              //  scrollRef={this.messagesScrollbar && this.messagesScrollbar.state && this.messagesScrollbar.state.realHeight > this.messagesScrollbar.state.containerHeight
              // ? this.messagesScrollbar : null}
              show={
                balanceHistory && balanceHistory.length > 8
                  ? true
                  : null
              }
              scroll={this.scroll}
              speed={30}
              rightOffset={12}
              topOffset={23}
              bottomOffset={-3}
            />
            <ScrollArea
              speed={0.65}
              className="money-history-table-scrollarea"
              contentClassName="money-history-table-body"
              smoothScrolling
              stopScrollPropagation
              verticalContainerStyle={{
                background: 'transparent',
                opacity: 1,
                width: 11,
              }}
              verticalScrollbarStyle={{
                background: '#fff',
                borderRadius: 0,
                width: 10,
              }}
              horizontal={false}
              onScroll={this.updateScrollPos}
              ref={(el) => {
                this.messagesScrollbar = el;
              }}
            > */}
            <ScrollAreaWrapper
              className="money-history-table-scrollarea"
              contentClassName="money-history-table-body"
              show={balanceHistory && balanceHistory.length > 8 ? true : null}
              rightOffset={12}
              topOffset={23}
              bottomOffset={-3}
              disableAutoHeight={balanceHistory && balanceHistory.length === 0}
            >
              {(balanceHistory && balanceHistory.length > 0) ? balanceHistory.map((data, index) => {
                const typeText = getMoneyHistoryText(
                  data.type,
                  t,
                );
                return (
                  <Row
                    key={data.key}
                    className={`top-table-row-var-height ${
                      index % 2 === 0 ? 'odd' : 'even'
                    }`}
                  >
                    <Col xs="2" sm="2" className="money-history-table-col-var-height text-center">
                      <CustomDate
                        format="hh:mm"
                        date={data.time}
                      />
                    </Col>
                    <Col xs="4" sm="4" className="money-history-table-col-var-height">
                      {typeText}
                    </Col>
                    <Col className="money-history-table-col-var-height text-center">
                      {data.old}
                    </Col>
                    <Col className="money-history-table-col-var-height text-center">
                      {data.new}
                    </Col>
                    <Col className="money-history-table-col-var-height text-center">
                      {data.change}
                    </Col>
                  </Row>
                );
              }) : <div className="money-history-empty-entries">{((activeTab === '1') ? t('moneyHistory.todayMoneyHistoryEmptyEntries') : (activeTab === '2') ? t('moneyHistory.yesterdayMoneyHistoryEmptyEntries') : t('moneyHistory.twoDaysAgoMoneyHistoryEmptyEntries'))}</div>}
            </ScrollAreaWrapper>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  balanceHistory: state.member.balanceHistory || [],
  offset: state.member.offset || 0,
});

const mapDispatchToProps = {
  fetchBalanceHistory: getBalanceHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(MoneyHistory));
