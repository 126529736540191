import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LinkifyWithTargetBlank from '../../../UI/LinkifyWithTargetBlank';
import SplitMessage from './SplitMessage';

// import defaultImage from '../../../../../images/Game/defaultImage.webp';
import darkDefaultImage from '../../../../../images/redesign/common/default_image.svg';
import lightDefaultImage from '../../../../../images/redesign/light-mode/common/default_image.webp';
import normalDefaultImage from '../../../../../images/redesign/normal-mode/common/default_image.webp';

import reportedMessageImg from '../../../../../images/redesign/chat/chat-report.png';
import reportMessageImg from '../../../../../images/redesign/chat/chat-report-submit.png';

import AdminMessage from './AdminMessage';
import * as constants from '../../../../../constants/constants';
// import Popover from 'reactstrap/lib/Popover';
// import PopoverBody from 'reactstrap/lib/PopoverBody';
import {
  reportMessage,
} from '../../../../../actions/room';

const Message = React.memo(({
  isReportedMessage, name, messageKey, roomId, message, uid, messagesShow, fontMode, screenMode,
}) => {
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();

  const [popoverOpen, setPopoverOpen] = useState(null);

  const onHover = (key) => {
    if (!popoverOpen) {
      setPopoverOpen(key);
    }
  };

  const onHoverLeave = () => {
    setPopoverOpen(null);
  };

  const handleReportMessage = () => {
    console.log('handleReportMessage', uid, name, message.userUid, message.userName, messageKey, message.message, roomId);

    dispatch(reportMessage(uid, name, message.userUid, message.userName, messageKey, message.message, roomId));
  };

  let splitMessage = [];
  let splitFilteredMessage = [];
  if (message.filteredMessage && message.message && (message.userUid && message.userUid !== 'game')) {
    splitFilteredMessage = message.filteredMessage.split(/\n/);
  }

  if (message.message || (message.userUid && message.userUid === 'game')) {
    if (message.message) {
      splitMessage = message.message.split(/\n/);
      if (!uid) {
        return null;
      }
    }
    const defaultImage = screenMode === constants.SCREEN_MODE.dark ? darkDefaultImage : screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : lightDefaultImage;
    return (
      <Fragment>
        {
          messagesShow === constants.ALL ? (
            <Fragment>
              {message.userUid && message.userUid === 'game' ? (
                <Fragment>
                  <Row>
                    <Col xs="10" className="room-chat-message-col-right">
                      <div className="room-chat-message-game">
                        {message && message.type ? (
                          <AdminMessage message={message} />
                        ) : (
                          <>
                            {message.message}
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              ) : (
                <Fragment>
                  {(message.userUid && message.userUid.toString() === uid.toString()) ? (
                    <Row>
                      <Col xs="12" className="room-chat-message-col">
                        <LinkifyWithTargetBlank>
                          {console.log('message', { message })}
                          <div className={`room-chat-message-other room-chat-message-other-${screenMode}`}>
                            <div className="room-chat-message-other-image-frame">
                              <Media
                                className="room-chat-message-other-image"
                                src={message.userPhoto || defaultImage}
                                alt="photo"
                              />
                            </div>
                            <SplitMessage splitFilteredMessage={splitFilteredMessage} splitMessage={splitMessage} fontMode={fontMode} message={message} inlineClassName={`room-chat-message-other-text room-chat-message-other-text-${screenMode}`} />
                          </div>
                        </LinkifyWithTargetBlank>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col xs="12" className="room-chat-message-col">
                        <LinkifyWithTargetBlank>
                          <div className={`room-chat-message-other room-chat-message-other-${screenMode}`}>
                            <div className="room-chat-message-other-image-frame">
                              <Media
                                className="room-chat-message-other-image"
                                src={message.userPhoto || defaultImage}
                                alt="photo"
                              />
                            </div>
                            <SplitMessage splitFilteredMessage={splitFilteredMessage} splitMessage={splitMessage} fontMode={fontMode} message={message} inlineClassName={`room-chat-message-other-text room-chat-message-other-text-${screenMode}`} />
                            <div
                              className="room-chat-message-other-report"
                              id={`report-message-${messageKey}`}
                              onMouseEnter={() => onHover(`report-message-${messageKey}`)}
                              onMouseLeave={() => onHoverLeave()}
                              onClick={!isReportedMessage ? handleReportMessage : null}
                            >
                              <Media src={!isReportedMessage ? reportMessageImg : reportedMessageImg} />
                            </div>
                            {
                              popoverOpen === `report-message-${messageKey}` && (
                                <div className="report-message-popover">
                                  {
                                    isReportedMessage ? t('reportedMessage') : t('reportMessage')
                                  }
                                </div>
                              )
                            }
                          </div>
                        </LinkifyWithTargetBlank>
                      </Col>
                    </Row>
                  )}
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {messagesShow === constants.PLAYER && message.userUid && message.userUid !== constants.GAME && (
                <Fragment>
                  {(message.userUid && message.userUid.toString() === uid.toString()) ? (
                    <Row>
                      <Col xs="12" className="room-chat-message-col">
                        <LinkifyWithTargetBlank>
                          {console.log('message', { message })}
                          <div className={`room-chat-message-other room-chat-message-other-${screenMode}`}>
                            <div className="room-chat-message-other-image-frame">
                              <Media
                                className="room-chat-message-other-image"
                                src={message.userPhoto || defaultImage}
                                alt="photo"
                              />
                            </div>
                            <SplitMessage splitFilteredMessage={splitFilteredMessage} splitMessage={splitMessage} fontMode={fontMode} message={message} inlineClassName={`room-chat-message-other-text room-chat-message-other-text-${screenMode}`} />
                          </div>
                        </LinkifyWithTargetBlank>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="chat-">
                      <Col xs="12" className="room-chat-message-col">
                        <LinkifyWithTargetBlank>
                          <div className={`room-chat-message-other room-chat-message-other-${screenMode}`}>
                            <div className="room-chat-message-other-image-frame">
                              <Media
                                className="room-chat-message-other-image"
                                src={message.userPhoto || defaultImage}
                                alt="photo"
                              />
                            </div>
                            <SplitMessage splitFilteredMessage={splitFilteredMessage} splitMessage={splitMessage} fontMode={fontMode} message={message} inlineClassName={`room-chat-message-other-text room-chat-message-other-text-${screenMode}`} />
                            <div
                              className="room-chat-message-other-report"
                              id={`report-message-${messageKey}`}
                              onMouseEnter={() => onHover(`report-message-${messageKey}`)}
                              onMouseLeave={() => onHoverLeave()}
                              onClick={!isReportedMessage ? handleReportMessage : null}
                            >
                              <Media src={!isReportedMessage ? reportMessageImg : reportedMessageImg} />
                            </div>
                            {
                              popoverOpen === `report-message-${messageKey}` && (
                                <div className="report-message-popover">
                                  {
                                    isReportedMessage ? t('reportedMessage') : t('reportMessage')
                                  }
                                </div>
                              )
                            }
                          </div>
                        </LinkifyWithTargetBlank>
                      </Col>
                    </Row>
                  )}
                </Fragment>
              )}
            </Fragment>
          )
        }
      </Fragment>
    );
  }
  return null;
});

Message.propTypes = {
  message: PropTypes.shape(),
  uid: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Message.defaultProps = {
  message: {},
  uid: '',
};

export default Message;
