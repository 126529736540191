import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Moment from 'react-moment';

import { withTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Collapse from 'reactstrap/lib/Collapse';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import { filter, size } from 'lodash';
import * as constants from '../../../constants/constants';

import DateFormat from '../UI/DateFormat';

import ScoreTable from './ScoreTable/ScoreTable';
import { getBetValue } from '../../../common/services/data-service';

class RoomLogs extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    roomData: PropTypes.shape({}),
    roomId: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    roomParams: PropTypes.shape({}),
    t: PropTypes.func.isRequired,
    bots: PropTypes.shape({}),
  }

  static defaultProps = {
    member: {},
    roomData: {},
    roomId: '',
    roomParams: {},
    bots: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 50,
      collapse: false,
    };

    this.toggle = this.toggle.bind(this);
    this.openCollapse = this.openCollapse.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, index) {
    e.preventDefault();

    this.setState({
      currentPage: index,
    });
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  openCollapse(key) {
    const { collapse, roomData, roomId } = this.state;

    if (collapse === key) {
      this.setState({ collapse: '' });
    } else {
      /*  const { data } = roomData[roomId];

        const party = data[key];

        console.log('openCollapse', key, party);

        let collapseHasSuspicious = false;

        Object.keys(party).map((partyKey) => {
          const action = party[partyKey];
          if (action.data && action.data.isSuspicious) {
            collapseHasSuspicious = true;
          }
        }); */

      this.setState({ collapse: key });
    }
  }

  getGameResultTypeLabel(type) {
    if (type === 'parasta') {
      return 'regular';
    }
    if (type === 'mazaZole') {
      return 'small Zole';
    }
    if (type === 'galdins') {
      return 'Table';
    }
    return type;
  }

  getGameResultContentLabel(type) {
    if (type === 'parasta') {
      return 'regular game';
    }
    if (type === 'mazaZole') {
      return 'small Zole';
    }
    if (type === 'galdins') {
      return 'Table';
    }
    return type;
  }

  getSelectedGameTypeLabel(type) {
    if (type === 'parasta') {
      return 'took table';
    }
    if (type === 'garam') {
      return 'passed';
    }
    if (type === 'maza') {
      return 'selected small Zole';
    }
    return `selected ${type}`;
  }

  table() {
    const {
      t,
      i18n,
      roomData,
      roomId,
      roomParams,
      bots,
    } = this.props;
    const {
      currentPage,
      pageSize,
      collapse,
      collapseHasSuspicious,
    } = this.state;

    if (roomData && roomId && roomData[roomId]) {
      const { data, roomsPoints } = roomData[roomId];

      const pagesCount = Math.ceil(Object.keys(data).length / pageSize);

      let playerNames;
      const playerData = [];
      let count = 0;
      console.log('missed turn data', roomParams, data);

      if (data[0]) {
        playerNames = data[0];
      }
      if (data[1]) {
        const players = data[1];
        Object.keys(data[1]).map((key) => {
          if (players[key].type === constants.PLAYERJOINED) {
            count += 1;
            playerData[players[key].data.joinedPlayerUid] = players[key.toString()].data.joinedPlayerName ? players[key.toString()].data.joinedPlayerName : (playerNames[`player${count}`] ? playerNames[`player${count}`] : '');
          }
        });

        console.log('playerData1', playerData);

        if (!playerData.length) {
          Object.keys(data[1]).map((key) => {
            if (players[key].type === 'cardsDealt') {
              const cardsDealtData = players[key].data;

              console.log('cardsDealtData', cardsDealtData);

              Object.keys(cardsDealtData).map((key2) => {
                if (key2 === 'cardsOnTable') {
                  return null;
                }

                playerData[cardsDealtData[key2].playerUid] = cardsDealtData[key2].player ? cardsDealtData[key2].player : '';
              });
            }
          });
        }
      }

      console.log('playerData2', playerData);

      // -MXx5EivSJLz79dDaXwz

      return (
        <Fragment>
          {data && Object.keys(data)
            .slice(
              currentPage * pageSize,
              (currentPage + 1) * pageSize,
            )
            .map((key, index) => {
              const party = data[key];

              if (parseInt(key, 10) === 0 && index === 0 && party) {
                playerNames = party;
                return null;
              }

              if (!party) {
                return null;
              }

              const { gameResult } = party;

              console.log('party=========', key, index, { party });

              return (
                <Fragment key={key}>
                  <tr
                    onClick={() => this.openCollapse(key)}
                    key={key}
                    className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}
                    style={(party && party.gameResult && party.gameResult.hasSuspiciousRounds) ? { color: 'red', fontWeight: 'bold' } : null}
                  >
                    <td className="allUsers-table-col table-id">
                      {filter(party, (item, idx) => item?.type === 'endRoomPules')[0] ? (
                        'Pules'
                      ) : (
                        key
                      )}
                    </td>
                    <td className="allUsers-table-col">
                      {gameResult && gameResult.type && (
                        this.getGameResultTypeLabel(gameResult.type)
                      )}
                    </td>
                    <td className="allUsers-table-col">
                      {gameResult && gameResult.largePlayer}
                    </td>
                    <td className="allUsers-table-col">
                      {gameResult && gameResult.largePoints}
                    </td>
                    <td className="allUsers-table-col">
                      {gameResult && gameResult.largeTricks}
                    </td>
                    <td className="allUsers-table-col">
                      {`${(gameResult && gameResult.winner) ? gameResult.winner : ''}${(gameResult && gameResult.type === 'galdins') ? ' lost' : ''}`}
                    </td>
                    <td className="allUsers-table-col">
                      {(gameResult && gameResult.scoreType) ? (gameResult.scoreType === 'parasts' ? 'common' : (gameResult.scoreType === 'bezstikis' ? 'no-tricks' : gameResult.scoreType)) : ''}
                    </td>
                  </tr>
                  {collapse === key && (
                    <tr style={{ borderTop: '2px solid gray' }}>
                      <td colSpan="7">
                        <Collapse isOpen={collapse === key}>
                          <Row>
                            {Object.keys(party).sort((a, b) => party[a].time - party[b].time).map((partyKey) => {
                              const action = party[partyKey];
                              if (partyKey === 'gameResult') {
                                return null;
                              }
                              if (action.type === 'missedTurn') {
                                console.log("action penalty", action);
                              }
                              // console.log('action', { action });

                              if (action.data && action.data.isSuspicious) {
                                console.log('isSuspicious', { partyKey });
                              }

                              if (action.type === 'updatePoints') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('updatePoints')} */}
                                        Updated points
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {`Player ${action.data.playerName}, uid: ${action.data.playerUid}`}
                                      </div>
                                      <div>
                                        {`Old bilance: ${action.data.oldBal}, New bilance: ${action.data.newBal}`}
                                      </div>
                                      <div>
                                        {`Old points: ${action.data.oldPnts}, New points: ${action.data.newPnts}`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'cardPlayed') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('cardPlayed')} */}
                                        Played card
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {`${playerNames ? playerNames[action.data.player] : action.data.player} played card ${action.data.playedCard}`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'cardPlayedAutomated') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('cardPlayedAutomated')} */}
                                        Played card automated
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {`${playerNames ? playerNames[action.data.player] : action.data.player} played card ${action.data.playedCard}`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }


                              if (action.type === 'determineStrongest') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={action.data.isSuspicious ? { borderBottom: '1px solid gray', color: 'red', fontWeight: 'bold' } : { borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('determineStrongest')} */}
                                        Determines the strongest card
                                      </b>
                                    </Col>
                                    <Col sm="3" style={action.data.isSuspicious ? { borderBottom: '1px solid gray', color: 'red', fontWeight: 'bold' } : { borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={action.data.isSuspicious ? { borderBottom: '1px solid gray', color: 'red', fontWeight: 'bold' } : { borderBottom: '1px solid gray' }}>
                                      <div>
                                        {/* {t('determineStrongestText', { card: action.data.card, winPlayer: action.data.winPlayer, tablePoints: action.data.tablePoints ? (`Take ${action.data.tablePoints} points`) : ('') })} */}

                                        {`Strongest card is ${action.data.card} played by ${action.data.winPlayer}. ${(action.data.tablePoints || action.data.tablePoints === 0) ? (`Take ${action.data.tablePoints} points`) : ''} `}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'selectType') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('selectType')} */}
                                        Selected game type
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {/* {t('selectTypeText', { player: action.data.player, type: action.data.type })} */}
                                        {`Player ${action.data.player} ${size(filter(bots, (item) => { return action.data.playerUid === item.uid; })[0]) > 0 ? '(B)' : ''} ${this.getSelectedGameTypeLabel(action.data.type)}`}
                                        {action.data.type === 'garam' && action.data.action === 'next' && (' ')}
                                        {action.data.type === 'garam' && action.data.action === 'galdins' && (`, and Table will be played`)}
                                        {action.data.type === 'garam' && action.data.action === 'nextRound' && (`, and new round will be dealt`)}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'selectTypeAutomated') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('selectTypeAutomated')} */}
                                        Selected game type automated
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {`Player ${action.data.player} ${size(filter(bots, (item) => { return action.data.playerUid === item.uid; })[0]) > 0 ? '(B)' : ''} ${this.getSelectedGameTypeLabel(action.data.type)}`}
                                        {/* {t('selectTypeText', { player: action.data.player, type: action.data.type })} */}
                                        {action.data.type === 'garam' && action.data.action === 'next' && (' ')}
                                        {action.data.type === 'garam' && action.data.action === 'galdins' && (`, and Table will be played`)}
                                        {action.data.type === 'garam' && action.data.action === 'nextRound' && (`, and new round will be dealt`)}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'burriedCard') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('burriedCard')} */}
                                        Buried card
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {/* {t('burriedCardText', { burriedCard: action.data.burriedCard, newPnts: action.data.newPnts })} */}
                                        {`Large player buried card - ${action.data.burriedCard} (${action.data.newPnts} points)`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'burriedCardAutomated') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('burriedCardAutomated')} */}
                                        Buried card automated
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {/* {t('burriedCardText', { burriedCard: action.data.burriedCard, newPnts: action.data.newPnts })} */}
                                        {`Large player buried card - ${action.data.burriedCard} (${action.data.newPnts} points)`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'roundResult') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('roundResult')} */}
                                        Game Result
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {action.data.type === 'galdins' ? (
                                          <Fragment>
                                            {`${action.data.winner} loses ${this.getGameResultContentLabel(action.data.type)}`}
                                          </Fragment>
                                        ) : (
                                          <Fragment>
                                            {`Large Player (${action.data.largePlayer}) ${action.data.winner === 'large' ? 'wins' : 'loses'} ${this.getGameResultContentLabel(action.data.type)}`}
                                            {action.data.scoreType === 'jani' && (` with Jani`)}
                                            {action.data.scoreType === 'bezstikis' && (` with no-tricks`)}
                                          </Fragment>
                                        )}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'playerQuit') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('playerQuit')} */}
                                        Large Player Folded
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {/* {t('playerQuitText', { player: action.data.player, roundType: action.data.roundType })} */}
                                        {`${action.data.player} folded`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'roundOver') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('roundOver')} */}
                                        Game Finished
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div />
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'newPule') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('puleAdded')} */}
                                        Pule added
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {/* {action.data.type ? t('commonPuleAdded') : t('privatePuleAdded', { player: action.data.player })} */}
                                        {action.data.type ? `Common pule added` : `Added new private pule to player ${action.data.player}`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'puleChange') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('puleChange')} */}
                                        Pule change
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {`${action.data.party} Round pule changed to ${action.data.changeTo}`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }
                              if (action.type === 'lastPlayerLeft') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>Last Player left</b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {`${action.data.playerName}(${action.data.playerUid}) left the room`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                )
                              }
                              if (action.type === 'roomClosed') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('roomClosed')} */}
                                        Room closed
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      {action.data.type === 'tournamentEnd' && (
                                        <div>
                                          {/* {`${t('roomWasClosed')} ${t('tournamentEnd')}`} */}
                                          {`Room was closed because tournament ended.`}
                                        </div>
                                      )}
                                      {action.data.type === 'lastRound' && (
                                        <div>
                                          {(action.data.playerUid === 'tournament') ? (
                                            // i18n.language === constants.sendLanguage.ru ? (
                                            //   `${t('roomWasClosedRu')} ${action.data.player} ${t('lastRoundTournamentRu')}`
                                            // ) : (
                                            //   `${t('roomWasClosed')} ${action.data.player} ${t('lastRoundTournamentLast')}`
                                            // )
                                            `Room was closed because player ${action.data.player} played the last game of the tournament round`
                                          ) : (
                                            //`${t('roomWasClosed')} ${action.data.player} ${t('lastRound')}`
                                            `Room was closed because player ${action.data.player} played the last game`
                                          )}
                                          { /*
                                            {(action.data.playerUid === 'tournament') ? (
                                              `Room was closed because ${action.data.player} Last tournament game`
                                            ) : (
                                              `Room was closed because ${action.data.player} roomWasClosed`
                                            )}
                                            */}
                                        </div>
                                      )}
                                      {action.data.type === 'missedTurn' && (
                                        <div>
                                          {/* {`${t('roomWasClosed')} ${action.data.player} ${t('missedTurn')}`} */}
                                          {`Room was closed because player ${action.data.player} missed turn`}
                                        </div>
                                      )}
                                      {action.data.type === 'leftRoom' && (
                                        <div>
                                          {/* {`${t('roomWasClosed')} ${action.data.player} ${t('leftRoom')}`} */}
                                          {`Room was closed because ${action.data.player} left room`}
                                        </div>
                                      )}
                                      {action.data.type === 'lastPlayerLeftRoom' && (
                                        <div>
                                          {/* {`${t('roomWasClosed')} ${t('lastPlayerLeftRoom')}`} */}
                                          {`Room was closed because last player left room before it started`}
                                        </div>
                                      )}
                                      {action.data.type === 'endAutomatedRoom' && (
                                        <div>
                                          {/* {`${t('endAutomatedRoom', { player: action.data.player })}`} */}
                                          {`Room was closed because player ${action.data.player} missed a turn in automated mode and did not return in time.`}
                                        </div>
                                      )}

                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === constants.LEFTROOMPENALTY || action.type === constants.MINGAMESPENALTY || action.type === constants.MISSEDTURNPENALTY) {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {
                                          action.type === constants.LEFTROOMPENALTY ? 'Penalty for leaving'
                                            : action.type === constants.MINGAMESPENALTY ? 'Penalty for leaving the game before minium games played'
                                              : action.type === constants.MISSEDTURNPENALTY ? 'Missed turn penalty'
                                                : ''
                                        }
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      {action.data.data && Object.keys(action.data.data)
                                        .map((actionKey) => {
                                          const actionDataPlayerName = playerData[actionKey] ? playerData[actionKey] : (playerNames.player1 ? playerNames.player1 : '');

                                          // const actionDataPlayerName = playerNames.player1 ? playerNames.player1 : '';
                                          if (action.data.fullData) {
                                            return (
                                              <>
                                                <div>
                                                  {actionDataPlayerName}
                                                </div>
                                                <div>
                                                  {`Old balance: ${action.data.fullData[actionKey].old}, New balance: ${action.data.fullData[actionKey].new}`}
                                                </div>
                                                {(action.data.fullData[actionKey].oldPoints && action.data.fullData[actionKey].newPoints) ? (
                                                  <div>
                                                    {`Old points: ${action.data.fullData[actionKey].oldPoints}, New points: ${action.data.fullData[actionKey].newPoints}`}
                                                  </div>
                                                ) : (null)}
                                              </>
                                            );
                                          }

                                          return (
                                            <div>
                                              <b style={{ display: 'inline-block' }}>
                                                {actionDataPlayerName}
                                              </b>
                                              {`: ${action.data.playerUid === actionKey ? (action.data.data[actionKey] > 0 ? -action.data.data[actionKey] : action.data.data[actionKey]) : action.data.data[actionKey]} `}
                                            </div>
                                          );
                                        })}
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'startAutomated') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('startAutomated')} */}
                                        Automatic game started for player
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {`${action.data.playerName ? action.data.playerName : action.data.player}`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'endAutomated') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('endAutomated')} */}
                                        Automatic game ended for player
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {`${action.data.playerName ? action.data.playerName : action.data.player}`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'cardsDealt') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('dealtCards')} */}
                                        Dealt cards
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {`${action.data.player1 ? (`${action.data.player1.player} ${size(filter(bots, (item) => { return action.data.player1.playerUid === item.uid; })[0]) > 0 ? '(B)' : ''} ${action.data.player1.env ? `(${action.data.player1.env})` : ''}`) : ''}: ${action.data.player1.cards ? action.data.player1.cards : ''}`}
                                      </div>
                                      <div>
                                        {`${action.data.player2 ? (`${action.data.player2.player} ${size(filter(bots, (item) => { return action.data.player2.playerUid === item.uid; })[0]) > 0 ? '(B)' : ''} ${action.data.player2.env ? `(${action.data.player2.env})` : ''}`) : ''}: ${action.data.player2.cards ? action.data.player2.cards : ''}`}
                                      </div>
                                      <div>
                                        {`${action.data.player3 ? (`${action.data.player3.player} ${size(filter(bots, (item) => { return action.data.player3.playerUid === item.uid; })[0]) > 0 ? '(B)' : ''} ${action.data.player3.env ? `(${action.data.player3.env})` : ''}`) : ''}: ${action.data.player3.cards ? action.data.player3.cards : ''}`}
                                      </div>
                                      {action.data.player4 && action.data.player4.player && (
                                        <div>
                                          {`${action.data.player4 ? (`${action.data.player4.player} ${size(filter(bots, (item) => { return action.data.player4.playerUid === item.uid; })[0]) > 0 ? '(B)' : ''} ${action.data.player4.env ? `(${action.data.player4.env})` : ''}`) : ''}: ${action.data.player4.cards ? action.data.player4.cards : ''}`}
                                        </div>
                                      )}
                                      <div>
                                        {`${action.data.cardsOnTable && action.data.cardsOnTable}`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'endRoomPules') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('endRoomPules')} */}
                                        Pules in room end
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {`${action.data.player1 && action.data.player1.name}: ${action.data.player1 && action.data.player1.points}, `}
                                        {`${action.data.player2 && action.data.player2.name}: ${action.data.player2 && action.data.player2.points}, `}
                                        {`${action.data.player3 && action.data.player3.name}: ${action.data.player3 && action.data.player3.points}, `}
                                        {`${action.data.player4 ? action.data.player4.name : ''}: ${action.data.player4 ? action.data.player4.points : ''}, `}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'setLast') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('choseLastRound')} */}
                                        Choses last round
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {`Player ${action.data.playerName ? action.data.playerName : action.data.playerUid} choses last round`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'playerJoined') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('playerJoined')} */}
                                        Player joined room
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {`Player ${size(filter(bots, (item) => { return action.data.joinedPlayerUid === item.uid; })[0]) > 0 ? '(B)' : ''} ${action.data.joinedPlayerName || playerData[action.data.joinedPlayerUid] || ''}(${action.data.joinedPlayerUid}) ${action.data.autoMerge ? 'was automatically added' : 'joined'}`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              if (action.type === 'playerLeft') {
                                return (
                                  <Fragment>
                                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                      <b>
                                        {/* {t('playerLeft')} */}
                                        Player left room
                                      </b>
                                    </Col>
                                    <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecondSS} date={action.time} />
                                    </Col>
                                    <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                      <div>
                                        {`Player ${action.data.joinedPlayerName || playerData[action.data.playerUid] || ''} left`}
                                      </div>
                                    </Col>
                                  </Fragment>
                                );
                              }

                              return (
                                <Fragment key={partyKey}>
                                  <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                                    <b>
                                      {action.message}
                                    </b>
                                  </Col>
                                  <Col sm="3" style={{ borderBottom: '1px solid gray' }}>
                                    <Moment format="DD-MM-YYYY, HH:mm:ss SS" locale="lv">
                                      {action.time}
                                    </Moment>
                                  </Col>
                                  <Col sm="7" style={{ borderBottom: '1px solid gray' }}>
                                    {action.data && Object.keys(action.data)
                                      .map((actionKey) => {
                                        const actionData = action.data[actionKey];
                                        return (
                                          <div>
                                            <b style={{ display: 'inline-block' }}>
                                              {[actionKey]}
                                            </b>
                                            {`: ${actionData} `}
                                          </div>
                                        );
                                      })}
                                  </Col>
                                </Fragment>
                              );
                            })}
                          </Row>
                        </Collapse>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          {Object.keys(data).length > pageSize && (
            <div className="pagination-wrapper">
              <Pagination aria-label="Page navigation example">
                <PaginationItem disabled={currentPage === 0}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                    {`‹‹‹`}
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={currentPage <= 0}>
                  <PaginationLink
                    style={{ color: '#000' }}
                    onClick={e => this.handleClick(e, currentPage - 1)}
                    previous
                    href="#"
                  />
                </PaginationItem>

                {[...Array(pagesCount)].map((page, i) => {
                  if (i > currentPage - 3 && i < currentPage + 3) {
                    return (
                      <PaginationItem active={i === currentPage} key={page}>
                        <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    );
                  }

                  return null;
                })}

                <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink
                    style={{ color: '#000' }}
                    onClick={e => this.handleClick(e, currentPage + 1)}
                    next
                    href="#"
                  />
                </PaginationItem>

                <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                    {`›››`}
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
          )}
        </Fragment>
      );
    } return null;
  }

  render() {
    const { t, roomData, roomId } = this.props;

    let roomsPoints = {};
    let playerNames;
    let playerData;

    if (roomData && roomId && roomData[roomId]) {
      roomsPoints = roomData[roomId].roomsPoints;

      const { data } = roomData[roomId];
      console.log("player data", data);
      if (data[0]) {
        playerNames = data[0];
      }
      // if (data[1]) {
      //   playerData = data[1].filter(player => player.type === constants.PLAYERJOINED)
      // }
    }

    console.log('roomsPoints', { roomsPoints });
    console.log('playerNames', { playerNames });

    return (
      <>
        <table style={{ width: '100%', fontSize: 12 }}>
          <colgroup>
            <col span="1" className="" />
          </colgroup>
          <thead>
            <tr>
              <th className="text-center">
                Id
              </th>
              <th className="text-center">
                {/* {t('type')} */}
                Type
              </th>
              <th className="text-center">
                {/* {t('largePlayer')} */}
                Large Player
              </th>
              <th className="text-center">
                {/* {t('largePoints')} */}
                Large Points
              </th>
              <th className="text-center">
                {/* {t('largeTricks')} */}
                Large Tricks
              </th>
              <th className="text-center">
                {/* {t('winner')} */}
                Winner
              </th>
              <th className="text-center">
                {/* {t('winType')} */}
                Win Type
              </th>
            </tr>
          </thead>
          <tbody>
            {this.table()}
          </tbody>
        </table>
        <div style={{ position: 'relative', marginTop: 25, borderTop: '1px solid #FFF' }}>
          <ScoreTable
            points={roomsPoints || {}}
            playerNames={playerNames || {}}
          //  bet="1:10"
          />
        </div>
      </>
    );
  }
}

export default withTranslation('admin')(RoomLogs);
