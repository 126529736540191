import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import NavLink from 'reactstrap/lib/NavLink';

import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';

import CustomDate from '../../Components/Components/CustomDate';
import { getMoneyHistoryText } from '../../../../../common/services/data-service';

import {
  getBalanceHistory,
} from '../../../../../actions/member';
import { SCREEN_MODE } from '../../../../../constants/constants';
import config from '../../../../../constants/config';

class MoneyHistory extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fetchBalanceHistory: PropTypes.func.isRequired,
    balanceHistory: PropTypes.arrayOf(PropTypes.shape({})),
    offset: PropTypes.number,
    screenMode: PropTypes.string,
  };

  static defaultProps = {
    balanceHistory: [],
    offset: 0,
    screenMode: SCREEN_MODE.normal,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
    };
  }

  toggle = async (tab) => {
    const { fetchBalanceHistory, offset } = this.props;

    if (tab === '1') {
      await fetchBalanceHistory('today', offset);
    } else if (tab === '2') {
      await fetchBalanceHistory('yesterday', offset);
    } else if (tab === '3') {
      await fetchBalanceHistory('2daysBefore', offset);
    }

    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const { balanceHistory, screenMode, t } = this.props;
    const { activeTab } = this.state;

    const isInAppFrame = config.isInAppFrame();
    const tableColAppFrameClass = isInAppFrame ? 'player-history-table-col-in-app-frame' : '';

    return (
      <Fragment>
        <Row className="player-history-tabs" style={{ marginBottom: 10 }}>
          <Col sm="12">
            <div className="player-history-tab-link">
              <NavLink
                className={`player-history-tab-link-text ${(activeTab === '1') && `player-history-tab-link-text-active-${screenMode}`}`}
                onClick={() => { this.toggle('1'); }}
              >
                {t('moneyHistory.today')}
              </NavLink>
            </div>
            <div className="player-history-tab-link">
              <NavLink
                className={`player-history-tab-link-text ${(activeTab === '2') && `player-history-tab-link-text-active-${screenMode}`}`}
                onClick={() => { this.toggle('2'); }}
              >
                {t('moneyHistory.yesterday')}
              </NavLink>
            </div>
            <div className="player-history-tab-link">
              <NavLink
                className={`player-history-tab-link-text ${(activeTab === '3') && `player-history-tab-link-text-active-${screenMode}`}`}
                onClick={() => { this.toggle('3'); }}
              >
                {t('moneyHistory.2daysAgo')}
              </NavLink>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Row className="player-history-table-header">
              <Col sm="2" className="player-history-table-header-col">
                {t('moneyHistory.time')}
              </Col>
              <Col sm="4" className="player-history-table-header-col">
                {t('moneyHistory.type')}
              </Col>
              <Col className="player-history-table-header-col">
                {t('moneyHistory.old')}
              </Col>
              <Col className="player-history-table-header-col">
                {t('moneyHistory.new')}
              </Col>
              <Col className="player-history-table-header-col">
                {t('moneyHistory.change')}
              </Col>
            </Row>
            <div
              className="player-history-table-scrollarea"
              contentClassName="player-history-table-body"
              show
              rightOffset={15}
              topOffset={23}
              bottomOffset={-3}
              style={isInAppFrame ? {height: '63vh'} : {}}
            >
              {(balanceHistory && balanceHistory.length > 0) ? balanceHistory.map((data, index) => {
                const typeText = getMoneyHistoryText(
                  data.type,
                  t,
                );
                return (
                  <Row
                    key={data.key}
                    className={`player-history-table-row player-history-table-row-new ${isInAppFrame && 'player-history-table-row-in-app-frame'} ${
                      index % 2 === 0 ? 'odd' : 'even'
                    }`}
                  >
                    <Col xs="2" sm="2" className={`player-history-table-col ${tableColAppFrameClass}`}>
                      <CustomDate
                        format="hh:mm"
                        date={data.time}
                      />
                    </Col>
                    <Col xs="4" sm="4" className={`player-history-table-col player-history-table-col-highlight ${tableColAppFrameClass}`}>
                      {typeText}
                    </Col>
                    <Col className={`player-history-table-col ${tableColAppFrameClass}`}>
                      {data.old}
                    </Col>
                    <Col className={`player-history-table-col ${tableColAppFrameClass}`}>
                      {data.new}
                    </Col>
                    <Col className={`player-history-table-col player-history-table-col-last ${tableColAppFrameClass}`}>
                      <div className={`${data.change > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative'}`}>
                        {data.change}
                      </div>
                    </Col>
                  </Row>
                );
              }) : <div className="player-money-history-empty-entries">{((activeTab === '1') ? t('moneyHistory.todayMoneyHistoryEmptyEntries') : (activeTab === '2') ? t('moneyHistory.yesterdayMoneyHistoryEmptyEntries') : t('moneyHistory.twoDaysAgoMoneyHistoryEmptyEntries'))}</div>}
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  balanceHistory: state.member.balanceHistory || [],
  offset: state.member.offset || 0,
});

const mapDispatchToProps = {
  fetchBalanceHistory: getBalanceHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(MoneyHistory));
