import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

// import davana from '../../../images/Menu/davana.svg';
import * as constants from '../../../constants/constants';

import {
  claimChestBonus,
  claimAdventBonus,
} from '../../../actions/member';

class BonusModals extends React.Component {
    static propTypes = {
      showChestBonusModal: PropTypes.bool,
      showAdventBonusModal: PropTypes.bool,
      t: PropTypes.func.isRequired,
      claimChestBonusFunc: PropTypes.func.isRequired,
      claimAdventBonusFunc: PropTypes.func.isRequired,
      newVersion: PropTypes.string,
      // tutorialShown: PropTypes.bool,
      holidayModalOpen: PropTypes.bool,
      toggleBonusModal: PropTypes.func.isRequired,
      openModal: PropTypes.bool,
      fontMode: PropTypes.string,
    }

    static defaultProps = {
      showChestBonusModal: false,
      showAdventBonusModal: false,
      newVersion: '',
      // tutorialShown: false,
      holidayModalOpen: false,
      openModal: false,
      fontMode: constants.FONT_MODE.normal,
    }

    componentDidUpdate(prevProps) {
      const { showChestBonusModal, showAdventBonusModal, toggleBonusModal } = this.props;

      if ((!prevProps.showChestBonusModal && showChestBonusModal) || (!prevProps.showAdventBonusModal && showAdventBonusModal)) {
        toggleBonusModal(true);
      }
    }

    claimChestBonusFuncA = () => {
      const { claimChestBonusFunc, toggleBonusModal } = this.props;
      claimChestBonusFunc();
      toggleBonusModal(false);
    }

    claimAdventBonusFuncA = () => {
      const { claimAdventBonusFunc, toggleBonusModal } = this.props;
      claimAdventBonusFunc();
      toggleBonusModal(false);
    }

    render() {
      const {
        showChestBonusModal, showAdventBonusModal, t, newVersion, openModal, holidayModalOpen, fontMode,
      } = this.props;

      console.log('showAdventBonusModal', showAdventBonusModal, newVersion, constants.NEW_VERSION, openModal, holidayModalOpen);

      if (!openModal && !holidayModalOpen) {
        if (showChestBonusModal) {
          return (
            <Modal container={'div > div'} isOpen toggle={this.claimChestBonusFuncA} size="lg" className={`holiday-modal holidays-modal old-modal root-font-${fontMode}`}>
              <ModalBody style={{ paddingTop: 140 }} className="holiday-modal-body">
                <div>
                  {' '}
                  {t('claimChestBonus')}
                  {' '}
                </div>
              </ModalBody>
              <ModalFooter className="holiday-modal-footer">
                <Button color="link" className="holiday-modal-footer-button" onClick={this.claimChestBonusFuncA}>{t('thanks')}</Button>
              </ModalFooter>
            </Modal>
          );
        } if (showAdventBonusModal) {
          return (
            <Modal container={'div > div'} isOpen toggle={this.claimAdventBonusFuncA} size="lg" className="holiday-modal holidays-modal old-modal">
              <ModalBody style={{ paddingTop: 140 }} className="holiday-modal-body">
                <div>
                  {' '}
                  {t('claimAdventBonus')}
                  {' '}
                </div>
              </ModalBody>
              <ModalFooter className="holiday-modal-footer">
                <Button color="link" className="holiday-modal-footer-button" onClick={this.claimAdventBonusFuncA}>{t('thanks')}</Button>
              </ModalFooter>
            </Modal>
          );
        }
      }
      return null;
    }
}

const mapStateToProps = state => ({
  showChestBonusModal: state.member.showChestBonusModal || false,
  showAdventBonusModal: state.member.showAdventBonusModal || false,
  newVersion: state.member.newVersion || null,
  // tutorialShown: state.member.tutorialShown || null,
});

const mapDispatchToProps = {
  claimChestBonusFunc: claimChestBonus,
  claimAdventBonusFunc: claimAdventBonus,
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('holiday')(BonusModals));
