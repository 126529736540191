import React from 'react';

import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import config from '../../../../../constants/config';
import { isMobileDevice } from '../../../../../utils/deviceUtils';

const IconPopover = ({ targetId, popoverOpen, setPopoverOpen, placement, text, children, mobileEnable, addClassName }) => {
  try {
    const isInAppFrame = config.isInAppFrame();
    const isMobile = isMobileDevice();

    const targetElement = document.getElementById(targetId);

    if (!targetElement) {
      return null;
    }

    if (!popoverOpen) {
      return null;
    }

    return (
      <Popover
        container={'div > div'}
        placement={placement}
        isOpen={popoverOpen}
        target={targetId}
        popperClassName={`icon-popover ${addClassName} ${(isMobile && !mobileEnable) && 'icon-popover-mobile-disable'}`}
        onMouseEnter={setPopoverOpen && (() => setPopoverOpen(true))}
        onMouseLeave={setPopoverOpen && (() => setPopoverOpen(false))}
      >
        <PopoverBody
          className="icon-popover-body"
          onMouseLeave={setPopoverOpen && (() => setPopoverOpen(false))}
        >
          {text || children}
        </PopoverBody>
      </Popover>
    );
  } catch (err) {
    return null;
  }
};

export default IconPopover;
