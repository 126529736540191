import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

const MenuRow = React.memo(({
  children, key, style, fitContent, nobackground, screenMode, contentClassName, customRowClassName, compactHeight,
}) => (
  <Row className={`${compactHeight ? 'menu-table-row-wrapper-compact-height' : 'menu-table-row-wrapper'} ${customRowClassName}`} style={fitContent ? { width: 'fit-content', maxWidth: 'fit-content', minWidth: '100%' } : null}>
    <Col
      sm="12"
      key={key}
      data-roomkey={key}
      className={classNames(`menu-table-row-inner-wrapper menu-table-row-inner-wrapper-${screenMode} ${contentClassName}`, {
        'menu-table-row-inner-wrapper-noback': nobackground,
      })}
      style={fitContent ? {
        ...style, width: 'fit-content', maxWidth: 'fit-content', minWidth: '100%',
      } : style}
    >
      {children}
    </Col>
  </Row>
));

MenuRow.propTypes = {
  children: PropTypes.shape(),
  style: PropTypes.shape(),
  key: PropTypes.string,
  fitContent: PropTypes.bool,
  nobackground: PropTypes.bool,
  contentClassName: PropTypes.string,
  customRowClassName: PropTypes.string,
  compactHeight: PropTypes.bool,
};

MenuRow.defaultProps = {
  children: null,
  style: null,
  key: null,
  fitContent: false,
  nobackground: false,
  contentClassName: '',
  customRowClassName: '',
  compactHeight: false,
};

export default MenuRow;
