// import * as FirebaseModule from 'firebase';
import * as FirebaseModule from 'firebase/app';
import firebaseConfig from '../constants/firebase';

// require('firebase/functions');

 import 'firebase/auth';        // for authentication
 import 'firebase/storage';     // for storage
 import 'firebase/database';    // for realtime database
// import 'firebase/firestore';   // for cloud firestore
// import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions

// import '@firebase/firestore';
// import '@firebase/functions';

const {
  apiKey, authDomain, databaseURL, projectId, storageBucket, messagingSenderId, appId, databaseURL2, databaseURL3, databaseURL4, databaseURL5, databaseURL6, databaseURL7, databaseURL8,
} = firebaseConfig;

let hostname = '';
if (window && window.location) {
  hostname = window.location.hostname;
}

let firebaseInitialized = false;

const app = FirebaseModule.initializeApp({
  apiKey, authDomain, databaseURL, projectId, storageBucket, messagingSenderId, appId,
});

const db1 = app.database();
const storage = app.storage();
//const db2 = app.database(databaseURL2);
// const db3 = app.database(databaseURL3);
//const db4 = app.database(databaseURL4);
//const db5 = app.database(databaseURL5);
//const db6 = app.database(databaseURL6);
//const db7 = app.database(databaseURL7);

/* let db3;
if (!hostname.includes('dra') && !hostname.includes('fb')) {
//  const db3 = app.database(databaseURL3);
} */

firebaseInitialized = true;


/*
//ROOMS app
const app6 = FirebaseModule.initializeApp({
  apiKey, authDomain, databaseURL: databaseURL6, projectId, storageBucket, messagingSenderId, appId,
}, 'app6');

//DEFAULT app
if (apiKey && authDomain && databaseURL && projectId && storageBucket && messagingSenderId) {
//  FirebaseModule.initializeApp({
//    apiKey, authDomain, databaseURL, projectId, storageBucket, messagingSenderId, appId,
//  });

  const app1 = FirebaseModule.initializeApp({
    apiKey, authDomain, databaseURL, projectId, storageBucket, messagingSenderId, appId,
  });

  firebaseInitialized = true;
}

//STATUS app
const app4 = FirebaseModule.initializeApp({
  apiKey, authDomain, databaseURL: databaseURL4, projectId, storageBucket, messagingSenderId, appId,
}, 'app4');

//PUBLIC ROOMS app
const app5 = FirebaseModule.initializeApp({
  apiKey, authDomain, databaseURL: databaseURL5, projectId, storageBucket, messagingSenderId, appId,
}, 'app5');

//USER STATS app
const app7 = FirebaseModule.initializeApp({
  apiKey, authDomain, databaseURL: databaseURL7, projectId, storageBucket, messagingSenderId, appId,
}, 'app7');

//ADMIN LOGS app
let app3;
if (!hostname.includes('dra') && !hostname.includes('fb')) {
  app3 = FirebaseModule.initializeApp({
    apiKey, authDomain, databaseURL: databaseURL3, projectId, storageBucket, messagingSenderId, appId,
  }, 'app3');
}

//LEADERBOARD app
const app2 = FirebaseModule.initializeApp({
  apiKey, authDomain, databaseURL: databaseURL2, projectId, storageBucket, messagingSenderId, appId,
}, 'app2');

console.log('app6');
console.log(app6); */

export const FirebaseRef = firebaseInitialized ? db1.ref() : null;
export const LeaderboardRef = firebaseInitialized ? app.database(databaseURL2).ref() : null;
// export const AdminLogsRef = (db3 && firebaseInitialized) ? db3.ref() : null;
export const StatusRef = firebaseInitialized ? app.database(databaseURL4).ref() : null;
export const StatusDb = firebaseInitialized ? app.database(databaseURL4) : null;
// export const RoomsPublicRef = firebaseInitialized ? app.database(databaseURL5).ref() : null;
 export const RoomsRef = firebaseInitialized ? app.database(databaseURL6).ref() : null;
// export const UserStatsRef = firebaseInitialized ? app.database(databaseURL7).ref() : null;
export const Firebase = firebaseInitialized ? FirebaseModule : null;
export const FirebaseStorage = firebaseInitialized ? storage : null;

// USER STATS DB
let userStatsDb;

export const UserStatsRef = () => {
  if (!userStatsDb) {
    userStatsDb = app.database(databaseURL7);
  }

  return userStatsDb.ref();
}

// TOURNAMENTS DB
let tournamentsDb;

export const TournamentsRef = () => {
  if (!tournamentsDb) {
    tournamentsDb = app.database(databaseURL8);
  }

  return tournamentsDb.ref();
}

// ROOMS DB

/*
let roomsDb;
let roomsDbOffline = false;

export const RoomsRef = () => {
  if (!roomsDb) {
    console.log('init rooms db')
    roomsDb = app.database(databaseURL6);

  //  FirebaseModule.database.enableLogging(true);
  }

/*  if (roomsDbOffline) {
    console.log('rooms db go online');
    roomsDb.goOnline();
    roomsDbOffline = false;
  }   */

/*  return roomsDb.ref();
} */

export const cancelRoomsRef = () => {
/*  if (roomsDb) {
    console.log('rooms db go offline');
  //  roomsDb.goOffline();
  //  roomsDbOffline = true;
  } else {
    console.log('NO roomsDb cancel');
  } */

  return null;
}

/*
export const reconnectRoomsRef = async () => {
  if (roomsDb) {
  //  console.log('reconnectRoomsRef');
  //  roomsDbOffline = false;

  //  await roomsDb.goOnline();
  //  console.log('RoomsRef reconnected');
  } else {
  //  console.log('NO roomsDb reconnect');
  }

  return null;
} */


// ADMIN DB
let adminLogsDb;

export const AdminLogsRef = () => {
  if (!adminLogsDb) {
    adminLogsDb = app.database(databaseURL3);
  }

  return adminLogsDb.ref();
}



/*
// LEADERBOARD DB
let leaderboardDb;
let leaderboardDbOffline = false;

export const LeaderboardRef = () => {
  if (!leaderboardDb) {
    leaderboardDb = app.database(databaseURL2);
  }

/*  if (leaderboardDbOffline) {
    console.log('public db go online');
    leaderboardDb.goOnline();
    leaderboardDbOffline = false;
  } */
/*
  return leaderboardDb.ref();
}

export const cancelLeaderboardRef = () => {  */
/*  if (leaderboardDb) {
    console.log('public db go offline');
    leaderboardDb.goOffline();
    leaderboardDbOffline = true;
  } */

//  return null;
//} 


// ROOMS PUBLIC DB
let roomsPublicDb;
let roomsPublicDbOffline = false;

export const RoomsPublicRef = () => {
  if (!roomsPublicDb) {
    roomsPublicDb = app.database(databaseURL5);
  }

  /* if (roomsPublicDbOffline) {
    console.log('public db go online');
    roomsPublicDb.goOnline();
    roomsPublicDbOffline = false;
  } */

  return roomsPublicDb.ref();
}

export const cancelRoomsPublicRef = () => {
/*  if (roomsPublicDb) {
    console.log('public db go offline');
    roomsPublicDb.goOffline();
    roomsPublicDbOffline = true;
  }  */

  return null;
}
