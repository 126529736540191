import React from 'react';
import PropTypes from 'prop-types';

import Media from 'reactstrap/lib/Media';

import { withTranslation } from 'react-i18next';
import isEqual from 'react-fast-compare';
import { map, filter } from 'lodash';

import ScrollArea from 'react-scrollbar';

import coinImg from '../../../../../images/redesign/common/coin.svg';

import ScoreTableRow from './ScoreTable4pRow';
import EndResultPules from '../Modals/EndResultModal/EndResultPules4p';
import EndResultFines from '../Modals/EndResultModal/EndResultFines4p';
import * as constants from '../../../../../constants/constants';

class ScoreTable extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    player1ShortName: PropTypes.string,
    player2ShortName: PropTypes.string,
    player3ShortName: PropTypes.string,
    player4ShortName: PropTypes.string,
    player1Uid: PropTypes.string,
    player2Uid: PropTypes.string,
    player3Uid: PropTypes.string,
    player4Uid: PropTypes.string,
    points: PropTypes.shape(),
    totalPnts: PropTypes.shape({
      player1: PropTypes.number,
      player2: PropTypes.number,
      player3: PropTypes.number,
      player4: PropTypes.number,
    }),
    myPos: PropTypes.string,
    scoreTableOpen: PropTypes.bool,
    party: PropTypes.number,
    bet: PropTypes.string,
    fetchPartyLog: PropTypes.func,
    isEndResultTable: PropTypes.bool,
    closeReason: PropTypes.shape(),
    gameType: PropTypes.string,
    screenMode: PropTypes.string,
  };

  static defaultProps = {
    player1ShortName: null,
    player2ShortName: null,
    player3ShortName: null,
    player4ShortName: null,
    player1Uid: null,
    player2Uid: null,
    player3Uid: null,
    player4Uid: null,
    points: {},
    totalPnts: {},
    myPos: null,
    scoreTableOpen: true,
    party: null,
    bet: null,
    fetchPartyLog: null,
    isEndResultTable: false,
    closeReason: null,
    gameType: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 0,
    };
    this.pointsScrollbar = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  shouldComponentUpdate(nextProps) {
    if (!isEqual(nextProps, this.props)) {
      this.scrollToBottom();
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  scrollToBottom = () => {
    if (this.pointsScrollbar) {
      this.timeoutID = setTimeout(() => {
        if (this.pointsScrollbar) {
          this.pointsScrollbar.scrollArea.scrollBottom();
        }
      }, 1000);
    }
  };

  setLastRef = (el) => {
    this.lastRef = el;
  };

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.pointsScrollbar.scrollYTo(newVal);
  }

  fetchPartyLog = (party) => {
    const { fetchPartyLog, isEndResultTable } = this.props;

    if (fetchPartyLog && isEndResultTable) {
      fetchPartyLog(party);
    }
  }

  renderPoints = (key, index) => {
    const {
      points,
      player1ShortName,
      player2ShortName,
      player3ShortName,
      player4ShortName,
      myPos,
      party,
      //  fetchPartyLog,
      isEndResultTable,
    } = this.props;
    return (
      <tr
        key={key}
        className="score-table-row"
        ref={(el) => {
          if (party === index + 2) {
            this.lastRef = el;
          }
        }}
      >
        <ScoreTableRow
          player1={points[key].player1}
          player2={points[key].player2}
          player3={points[key].player3}
          player4={points[key].player4}
          pule={points[key].pule}
          player1ShortName={player1ShortName || ''}
          player2ShortName={player2ShortName || ''}
          player3ShortName={player3ShortName || ''}
          player4ShortName={player4ShortName || ''}
          myPos={myPos}
          index={points[key].id || null}
          roundIndex={index}
          isEndResultTable={isEndResultTable}
          fetchPartyLog={this.fetchPartyLog}
        />
      </tr>
    );
  };

  renderMissedRound = () => {
    const {
      player1Uid,
      player2Uid,
      player3Uid,
      player4Uid,
      //  points,
      player1ShortName,
      player2ShortName,
      player3ShortName,
      player4ShortName,
      myPos,
      party,
      //  fetchPartyLog,
      isEndResultTable,
      closeReason,
    } = this.props;

    if (closeReason && closeReason.playerUid) {
    //  const { playerUid } = closeReason;

      return (
        <tr
          key="missed-row"
          className="score-table-row"
          ref={(el) => { this.lastRef = el; }}
        >
          <ScoreTableRow
            player1={closeReason.playerUid === player1Uid ? -16 : 4}
            player2={closeReason.playerUid === player2Uid ? -16 : 4}
            player3={closeReason.playerUid === player3Uid ? -16 : 4}
            player4={closeReason.playerUid === player4Uid ? -16 : 4}
            pule={null}
            player1ShortName={player1ShortName || ''}
            player2ShortName={player2ShortName || ''}
            player3ShortName={player3ShortName || ''}
            player4ShortName={player4ShortName || ''}
            myPos={myPos}
            index="-"
            roundIndex={party - 1}
            isEndResultTable={isEndResultTable}
            fetchPartyLog={this.fetchPartyLog}
          />
        </tr>
      );
    }
    return null;
  };

  render() {
    const {
      t,
      player1ShortName,
      player2ShortName,
      player3ShortName,
      player4ShortName,
      totalPnts,
      points,
      myPos,
      scoreTableOpen,
      bet,
      isEndResultTable,
      closeReason,
      gameType,
      screenMode
    } = this.props;

    const { scrollPos } = this.state;

    let betRatio;

    if (bet) {
      betRatio = parseInt(bet.replace('1:', ''), 10);
    }
    let pPules = [];
    if (points) {
      map(points, (item, key) => {
        if (item.pule) {
          const puleType = item.pule;
          if (puleType !== constants.COMMON_PULE.p || puleType !== constants.COMMON_PULE._p) {
            pPules.push(puleType);
          }
        }
      });
    }

    return (
      <div className={`score-table ${!scoreTableOpen ? 'score-table-hidden' : ''}`}>
        <div className='score-table-top'>
          <table className="score-table-fourPlayer score-table-names">
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th className="score-table-id-header score-table-id-header-4-player">#</th>
                {myPos === 'player1' && (
                  <>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player4ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player4'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player1ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player1'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player2ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player2'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player3ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player3'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player1ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player1'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player2ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player2'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player3ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player3'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player4ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player4'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player2ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player2'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player3ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player3'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player4ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player4'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player1ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player1'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                  </>
                )}
                {myPos === 'player4' && (
                  <>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player3ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player3'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player4ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player4'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player1ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player1'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                    <th className="score-table-header score-table-header-4-player">
                      <div className="score-table-header-player">
                        {player2ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player2'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                  </>
                )}
                <th className="score-table-pules-header score-table-pules-header-4-player">
                  <div className="score-table-header-player">
                    {t('pules')}
                  </div>
                  <div className="plues-section">
                    {
                      // pPules && map(filter(pPules, (pule) => { return pule === 'P-' || pule === 'P'; }), item => (
                        pPules && map(filter(pPules, (pule) => { return pule === 'P'; }), item => (
                        <div className='under-pules' />
                      ))
                    }
                  </div>
                </th>
                {isEndResultTable ? (
                  <th className="score-table-info-header" />
                ) : (null)}
              </tr>
            </thead>
            <tbody />
          </table>
          <div className="score-table-scroll-area-wrapper">
            <ScrollArea
              speed={0.55}
              className="score-table-scroll-area"
              contentClassName="score-table-scroll-content score-table-fourPlayer-scroll-content"
              verticalContainerStyle={{
                background: 'transparent',
                opacity: 1,
                width: 11,
                marginRight: 2,
              }}
              verticalScrollbarStyle={{
                background: '#fff',
                borderRadius: 1,
                width: 10,
              }}
              minScrollSize={25}
              horizontal={false}
              onScroll={this.updateScrollPos}
              ref={(el) => {
                this.pointsScrollbar = el;
              }}
            >
              <table style={{ width: '100%' }}>
                <thead />
                <tbody>
                  {points && Object.keys(points).map(this.renderPoints)}
                  {(isEndResultTable && closeReason && (closeReason.reason === 'missedTurn' || closeReason.reason === 'leftRoom') && !closeReason.isAutomated) ? (
                    <>
                      {this.renderMissedRound()}
                    </>
                  ) : (null)}
                </tbody>
              </table>
            </ScrollArea>
          </div>
          <table className="score-table-points">
            <thead />
            <tbody style={{ width: '100%' }}>
              <tr>
                <th className="score-table-points-col-id score-table-points-col-id-4-player">
                  <div>{t('points')}</div>
                </th>
                <th className="score-table-points-col score-table-points-col-4-player">
                  <>
                    {myPos === 'player1' && (
                      <>{totalPnts ? totalPnts.player4 : ''}</>
                    )}
                    {myPos === 'player2' && (
                      <>{totalPnts ? totalPnts.player1 : ''}</>
                    )}
                    {myPos === 'player3' && (
                      <>{totalPnts ? totalPnts.player2 : ''}</>
                    )}
                    {myPos === 'player4' && (
                      <>{totalPnts ? totalPnts.player3 : ''}</>
                    )}
                  </>
                </th>
                <th className="score-table-points-col score-table-points-col-4-player">
                  <>
                    {myPos === 'player1' && (
                      <>{totalPnts ? totalPnts.player1 : ''}</>
                    )}
                    {myPos === 'player2' && (
                      <>{totalPnts ? totalPnts.player2 : ''}</>
                    )}
                    {myPos === 'player3' && (
                      <>{totalPnts ? totalPnts.player3 : ''}</>
                    )}
                    {myPos === 'player4' && (
                      <>{totalPnts ? totalPnts.player4 : ''}</>
                    )}
                  </>
                </th>
                <th className="score-table-points-col score-table-points-col-4-player">
                  <>
                    {myPos === 'player1' && (
                      <>{totalPnts ? totalPnts.player2 : ''}</>
                    )}
                    {myPos === 'player2' && (
                      <>{totalPnts ? totalPnts.player3 : ''}</>
                    )}
                    {myPos === 'player3' && (
                      <>{totalPnts ? totalPnts.player4 : ''}</>
                    )}
                    {myPos === 'player4' && (
                      <>{totalPnts ? totalPnts.player1 : ''}</>
                    )}
                  </>
                </th>
                <th className="score-table-points-col score-table-points-col-4-player">
                  <>
                    {myPos === 'player1' && (
                      <>{totalPnts ? totalPnts.player3 : ''}</>
                    )}
                    {myPos === 'player2' && (
                      <>{totalPnts ? totalPnts.player4 : ''}</>
                    )}
                    {myPos === 'player3' && (
                      <>{totalPnts ? totalPnts.player1 : ''}</>
                    )}
                    {myPos === 'player4' && (
                      <>{totalPnts ? totalPnts.player2 : ''}</>
                    )}
                  </>
                </th>
                <th className="score-table-points-col-pules score-table-points-col-pules-4-player" />
              </tr>
            </tbody>
          </table>
        </div>
        <table className={`score-table-sum score-table-sum-${screenMode}`}>
          <thead />
          <tbody style={{ width: '100%' }}>
            <tr>
              <th className="score-table-sum-col-id score-table-sum-col-id-4-player">
                <div>
                  <Media src={coinImg} className="player-balance-coin" />
                </div>
              </th>
              <th className="score-table-points-col score-table-points-col-4-player">
                <>
                  {myPos === 'player1' && (
                    <>
                      {totalPnts && totalPnts.player4 && betRatio
                        ? betRatio * totalPnts.player4
                        : 0}
                    </>
                  )}
                  {myPos === 'player2' && (
                    <>
                      {totalPnts && totalPnts.player1 && betRatio
                        ? betRatio * totalPnts.player1
                        : 0}
                    </>
                  )}
                  {myPos === 'player3' && (
                    <>
                      {totalPnts && totalPnts.player2 && betRatio
                        ? betRatio * totalPnts.player2
                        : 0}
                    </>
                  )}
                  {myPos === 'player4' && (
                    <>
                      {totalPnts && totalPnts.player3 && betRatio
                        ? betRatio * totalPnts.player3
                        : 0}
                    </>
                  )}
                </>
              </th>
              <th className="score-table-points-col score-table-points-col-4-player">
                <>
                  {myPos === 'player1' && (
                    <>
                      {totalPnts && totalPnts.player1 && betRatio
                        ? betRatio * totalPnts.player1
                        : 0}
                    </>
                  )}
                  {myPos === 'player2' && (
                    <>
                      {totalPnts && totalPnts.player2 && betRatio
                        ? betRatio * totalPnts.player2
                        : 0}
                    </>
                  )}
                  {myPos === 'player3' && (
                    <>
                      {totalPnts && totalPnts.player3 && betRatio
                        ? betRatio * totalPnts.player3
                        : 0}
                    </>
                  )}
                  {myPos === 'player4' && (
                    <>
                      {totalPnts && totalPnts.player4 && betRatio
                        ? betRatio * totalPnts.player4
                        : 0}
                    </>
                  )}
                </>
              </th>
              <th className="score-table-points-col score-table-points-col-4-player">
                <>
                  {myPos === 'player1' && (
                    <>
                      {totalPnts && totalPnts.player2 && betRatio
                        ? betRatio * totalPnts.player2
                        : 0}
                    </>
                  )}
                  {myPos === 'player2' && (
                    <>
                      {totalPnts && totalPnts.player3 && betRatio
                        ? betRatio * totalPnts.player3
                        : 0}
                    </>
                  )}
                  {myPos === 'player3' && (
                    <>
                      {totalPnts && totalPnts.player4 && betRatio
                        ? betRatio * totalPnts.player4
                        : 0}
                    </>
                  )}
                  {myPos === 'player4' && (
                    <>
                      {totalPnts && totalPnts.player1 && betRatio
                        ? betRatio * totalPnts.player1
                        : 0}
                    </>
                  )}
                </>
              </th>
              <th className="score-table-points-col score-table-points-col-4-player">
                <>
                  {myPos === 'player1' && (
                    <>
                      {totalPnts && totalPnts.player3 && betRatio
                        ? betRatio * totalPnts.player3
                        : 0}
                    </>
                  )}
                  {myPos === 'player2' && (
                    <>
                      {totalPnts && totalPnts.player4 && betRatio
                        ? betRatio * totalPnts.player4
                        : 0}
                    </>
                  )}
                  {myPos === 'player3' && (
                    <>
                      {totalPnts && totalPnts.player1 && betRatio
                        ? betRatio * totalPnts.player1
                        : 0}
                    </>
                  )}
                  {myPos === 'player4' && (
                    <>
                      {totalPnts && totalPnts.player2 && betRatio
                        ? betRatio * totalPnts.player2
                        : 0}
                    </>
                  )}
                </>
              </th>
              <th className="score-table-sum-col-pules score-table-sum-col-pules-4-player" />
            </tr>
          </tbody>
        </table>
        {isEndResultTable ? (
          <>
            {gameType && gameType.includes('P') ? (
              <EndResultPules />
            ) : null}
            {closeReason && closeReason.reason && (closeReason.reason === 'missedTurn' || closeReason.reason === 'leftRoom') ? (
              <EndResultFines />
            ) : null}
          </>
        ) : (null)}
      </div>
    );
  }
}

export default withTranslation('game')(ScoreTable);
