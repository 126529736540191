import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Moment from 'react-moment';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';

class UserAchievementsModal extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    openAchievementsModal: PropTypes.bool,
    openAchievementsModalName: PropTypes.string,
    openAchievementsModalUid: PropTypes.string,
    userAchievements: PropTypes.shape(),
    closeModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    openAchievementsModal: false,
    openAchievementsModalName: null,
    openAchievementsModalUid: null,
    userAchievements: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',

      achievementsData: {
        gamesPlayed: {
          borders: [1, 5, 10, 50, 100, 250, 500, 1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000],
        },
        gamesWon: {
          borders: [1, 3, 5, 10, 25, 50, 100, 250, 500, 1000, 5000, 10000, 25000, 100000, 500000],
        },
        highestEarned: {
          borders: [1000, 2500, 5000, 7500, 10000, 25000, 50000, 75000, 100000, 250000, 500000, 1000000, 3000000, 5000000, 10000000],
        //  borders: [1000, 2500, 5000, 10000, 25000, 50000, 75000, 100000, 250000, 500000, 1000000, 3000000, 5000000, 10000000],
        },
        earnedInADay: {
          borders: [750, 1000, 1500, 2000, 3000, 5000, 7500, 10000, 15000, 25000, 50000, 75000, 100000, 250000, 500000],
        },
        maxSuccessionWins: {
          borders: [3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100],
        },
        maxSuccessionLosses: {
          borders: [3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100],
        },
        zolePlayed: {
          borders: [1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000],
        },
        zoleWon: {
          borders: [1, 5, 10, 25, 50, 75, 100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000],
        },
        fastGamesPlayed: {
          borders: [1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000],
        },
        lightningGamesPlayed: {
          borders: [1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000],
        },
        unlimitedGamesPlayed: {
          borders: [1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000],
        },
        mazaZolePlayed: {
          borders: [1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000],
          // borders: [1, 3, 5, 10, 100, 250, 500, 1000, 10000, 25000, 50000],
        },
        mazaZoleWon: {
          borders: [1, 5, 10, 25, 50, 75, 100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000],
        },
        bonusSpins: {
          borders: [3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 75, 100, 250, 500, 1000],
        },
        joinedTournaments: {
          borders: [3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 75, 100, 250, 500, 1000],
        },
        giftsReceived: {
          borders: [1, 3, 5, 10, 25, 50, 75, 100, 250, 500, 750, 1000, 2000, 3000, 5000],
        },
        reachedTournamentTop10: {
          borders: [1, 3, 5, 7, 10, 15, 20, 25, 30, 35, 50, 75, 100, 250, 500],
        },
        reachedTop100: {
          borders: [1, 3, 5, 7, 14, 30, 60, 90, 120, 150, 180, 270, 365, 730, 1095],
        },
        giftsSent: {
          borders: [1, 3, 5, 10, 25, 50, 75, 100, 250, 500, 750, 1000, 2000, 3000, 5000],
        },
        maxParties: {
          borders: [3, 5, 7, 10, 15, 20, 25, 50, 75, 100, 150, 200, 250, 500, 1000],
        },
        supportMessagesSent: {
          borders: [1, 2, 3, 5, 7, 10, 15, 20, 25, 50, 75, 100, 250, 500, 1000],
        },
        storePurchase: {
          borders: [1, 2, 3, 5, 10, 15, 20, 25, 30, 35, 50, 75, 100, 250, 500],
        },
        galdinsPlayed: {
          borders: [1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000],
        },
        galdinsWon: {
          borders: [1, 5, 10, 25, 50, 75, 100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000],
        },
        galdinsLose: {
          borders: [1, 5, 10, 25, 50, 75, 100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000],
        },
        maxDailyLogin: {
          borders: [1, 3, 5, 7, 14, 30, 60, 90, 120, 150, 180, 270, 365, 730, 1095],
        }
      },
    };
  }

  toggle = (tab) => {
    const { activeTab } = this.state;

    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }


  findAchievement = (key, borders, userAchievement, claimedAchievement) => {
    const { t } = this.props;
    let name = '';
    let firstName = '';
    if (key === 'gamesPlayed') {
      firstName = 'First Game';
      name = 'Games Played';
    } else if (key === 'gamesWon') {
      firstName = 'First Win';
      name = 'Wins';
    } else if (key === 'zolePlayed') {
      firstName = 'Zole played';
      name = "Zoles played";
    } else if (key === 'zoleWon') {
      firstName = "Zole won";
      name = "Zoles won";
    } else if (key === 'mazaZolePlayed') {
      firstName = "small Zole played";
      name = "small Zoles played";
    } else if (key === 'mazaZoleWon') {
      firstName = "small Zole won";
      name = "small Zoles won";
    } else if (key === 'giftsSent') {
      firstName = "sent gift";
      name = "sent gifts";
    } else if (key === 'giftsReceived') {
      firstName = "received gift";
      name = "received gifts";
    } else if (key === 'storePurchase') {
      firstName = "shop purchase";
      name = "shop purchases";
    } else if (key === 'supportMessagesSent') {
      firstName = "message to administration";
      name = "messages to administration";
    } else if (key === 'maxParties') {
      name = "rounds in one game";
    } else if (key === 'maxSuccessionWins') {
      name = "consecutive wins";
    } else if (key === 'maxSuccessionLosses') {
      name = "consecutive losses";
    } else if (key === 'fastGamesPlayed') {
      firstName = "First fast game";
      name = "fast games played";
    } else if (key === 'lightningGamesPlayed') {
      firstName = t('lightningGamePlayed');
      name = "First lightning game";
    } else if (key === 'unlimitedGamesPlayed') {
      firstName = t('unlimitedGamePlayed');
      name = "First unlimited game";
    } else if (key === 'bonusSpins') {
      name = "times spun wheel of fortune";
    } else if (key === 'joinedTournaments') {
      name = "tournaments participated";
    } else if (key === 'reachedTournamentTop10') {
      firstName = " First time in tournament TOP";
      name = "times in tournament TOP 10";
    } else if (key === 'highestEarned') {
      name = "earned in total";
    } else if (key === 'earnedInADay') {
      name = "earned a day";
    } else if (key === 'reachedTop100') {
      firstName = " day was in the TOP 100";
      name = "days was in the TOP 100";
    } else if (key === 'galdinsPlayed') {
      firstName = "played table";
      name = "played tables";
    } else if (key === 'galdinsWon') {
      firstName = "won table";
      name = "won tables";
    } else if (key === 'galdinsLose') {
      firstName = "lost table";
      name = "lost tables";
    }else if (key === 'maxDailyLogin') {
      firstName = "First day played";
      name = "Played days in a row";
    }

    const noCounter = ['gamesPlayed', 'gamesWon', 'fastGamesPlayed', 'lightningGamesPlayed', 'unlimitedGamesPlayed'];

    const claimedAchievementsVal = claimedAchievement || 0;



    let pendingAchievementVal;

    borders.map((item, index) => {
      if (item <= userAchievement) {
        pendingAchievementVal = index + 1;
      }
      return null;
    });

    console.log('findAch', key, claimedAchievementsVal, pendingAchievementVal);

    return (
      <Row key={key}>
        <Col sm="4" className="ach-column">
          {name}
        </Col>
        <Col sm="4" className="ach-column">
          {pendingAchievementVal || 0}
        </Col>
        <Col sm="4" className="ach-column">
          {claimedAchievementsVal || 0}
        </Col>
      </Row>
    );
  };

  singleTimeAchievements = (key, userAchievement, claimedAchievement) => {
    const { t } = this.props;

    let name = '';

    if (key === 'winLarge61') {
      name = "Win as Large - 61 points";
    } else if (key === 'winLarge91') {
      name = "Win as Large - 91 points";
    } else if (key === 'winLarge120') {
      name = "Win as Large - 120 points";
    } else if (key === 'winSmall60') {
      name = "Win as Small - 60 points";
    } else if (key === 'winZoleAll') {
      name = "Win Zole as Large - without giving tricks";
    } else if (key === 'winZoleTwoAces') {
      name = "Win in Zole - 2 aces on the table";
    } else if (key === 'loseLarge60') {
      name = "Loss as Large - 60 points";
    } else if (key === 'loseLarge30') {
      name = "Loss as Large - 30 points";
    } else if (key === 'loseLarge0') {
      name = "Loss as Large - 0 points";
    } else if (key === 'take3Aces') {
      name = "Picked up trick with 3 aces";
    } else if (key === 'take0Points') {
      name = "Picked up trick with 0 points";
    } else if (key === 'takeTwoAces') {
      name = "Took table with 2 aces";
    } else if (key === 'takeTwoNines') {
      name = "Took table with 2 nines";
    } else if (key === 'loseZoleTwoNines') {
      name = "Lost Zole with 2 nines on the table";
    } else if (key === 'loseTableAllTricks') {
      name = "Lost table with all taken tricks";
    } else if (key === 'winSmall90') {
      name = "Win as Small - 90 points";
    } else if (key === 'winSmall120') {
      name = "Win as Small - 120 points";
    } else if (key === 'dealtOnlyQueensJacks') {
      name = "Have 4 Queens and 4 Jacks";
    } else if (key === 'dealtNoTrumps') {
      name = "Have no trumps";
    } else if (key === 'dealtOnly789') {
      name = "Have only 7, 8 or 9";
    }

    return (
      <Row key={key}>
        <Col xs="4" sm="4" className="ach-column">
          <span>{`${name}`}</span>
        </Col>
        <Col xs="4" sm="4" className="ach-column">
          <span>{`${userAchievement || 'false'}`}</span>
        </Col>
        <Col xs="4" sm="4" className="ach-column">
          <span>{`${claimedAchievement || 'false'}`}</span>
        </Col>
      </Row>
    );
  };


  render() {
    const {
      t, openAchievementsModal, openAchievementsModalName, openAchievementsModalUid, closeModal, userAchievementsData,
    } = this.props;
    const { activeTab, achievementsData } = this.state;

    //  const { userAchievements, claimedAchievements, claimedAchievementsHistory } = userAchievementsData;

    const userAchievements = userAchievementsData.userAchievements || {};
    const claimedAchievements = userAchievementsData.claimedAchievements || {};
    const claimedAchievementsHistory = userAchievementsData.claimedAchievementsHistory || {};
    const gamesPlayed = userAchievementsData.gamesPlayed || 0;
    const gamesWon = userAchievementsData.gamesWon || 0;

    console.log('achievementsData.maxDailyLogin.borders, userAchievements, claimedAchievements', achievementsData.maxDailyLogin, userAchievements, claimedAchievements);

    return (
      <Modal style={{ fontSize: 12 }} container={'div > div'} isOpen={openAchievementsModal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          Achievements
        </ModalHeader>
        <ModalBody style={{ fontSize: 12 }}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={`admin-panel-nav-link ${activeTab === '1' ? 'active' : null}`}
                onClick={() => { this.toggle('1'); }}
              >
                Achievements
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`admin-panel-nav-link ${activeTab === '2' ? 'active' : null}`}
                onClick={() => { this.toggle('2'); }}
              >
                History
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="4" className="ach-column">
                  Achievement
                </Col>
                <Col sm="4" className="ach-column">
                  Received
                </Col>
                <Col sm="4" className="ach-column">
                  Claimed
                </Col>
              </Row>
              <Row>
                <Col sm="12">

                  {this.singleTimeAchievements('winLarge61', userAchievements.winLarge61, claimedAchievements.winLarge61)}
                  {this.singleTimeAchievements('winLarge91', userAchievements.winLarge91, claimedAchievements.winLarge91)}
                  {this.singleTimeAchievements('winLarge120', userAchievements.winLarge120, claimedAchievements.winLarge120)}
                  {this.singleTimeAchievements('winSmall60', userAchievements.winSmall60, claimedAchievements.winSmall60)}
                  {this.singleTimeAchievements('winSmall90', userAchievements.winSmall90, claimedAchievements.winSmall90)}
                  {this.singleTimeAchievements('winSmall120', userAchievements.winSmall120, claimedAchievements.winSmall120)}
                  {this.singleTimeAchievements('winZoleAll', userAchievements.winZoleAll, claimedAchievements.winZoleAll)}
                  {this.singleTimeAchievements('take3Aces', userAchievements.take3Aces, claimedAchievements.take3Aces)}
                  {this.singleTimeAchievements('take0Points', userAchievements.take0Points, claimedAchievements.take0Points)}
                  {this.singleTimeAchievements('winZoleTwoAces', userAchievements.winZoleTwoAces, claimedAchievements.winZoleTwoAces)}
                  {this.singleTimeAchievements('loseLarge60', userAchievements.loseLarge60, claimedAchievements.loseLarge60)}
                  {this.singleTimeAchievements('loseLarge30', userAchievements.loseLarge30, claimedAchievements.loseLarge30)}
                  {this.singleTimeAchievements('loseLarge0', userAchievements.loseLarge0, claimedAchievements.loseLarge0)}
                  {this.singleTimeAchievements('takeTwoAces', userAchievements.takeTwoAces, claimedAchievements.takeTwoAces)}
                  {this.singleTimeAchievements('takeTwoNines', userAchievements.takeTwoNines, claimedAchievements.takeTwoNines)}
                  {this.singleTimeAchievements('loseZoleTwoNines', userAchievements.loseZoleTwoNines, claimedAchievements.loseZoleTwoNines)}
                  {this.singleTimeAchievements('loseTableAllTricks', userAchievements.loseTableAllTricks, claimedAchievements.loseTableAllTricks)}
                  {this.singleTimeAchievements('dealtOnlyQueensJacks', userAchievements.dealtOnlyQueensJacks, claimedAchievements.dealtOnlyQueensJacks)}
                  {this.singleTimeAchievements('dealtNoTrumps', userAchievements.dealtNoTrumps, claimedAchievements.dealtNoTrumps)}
                  {this.singleTimeAchievements('dealtOnly789', userAchievements.dealtOnly789, claimedAchievements.dealtOnly789)}

                  {this.findAchievement('gamesPlayed', achievementsData.gamesPlayed.borders, gamesPlayed, claimedAchievements.gamesPlayed)}

                  {this.findAchievement('gamesWon', achievementsData.gamesWon.borders, gamesWon, claimedAchievements.gamesWon)}

                  {this.findAchievement('highestEarned', achievementsData.highestEarned.borders, userAchievements.highestEarned, claimedAchievements.highestEarned)}

                  {this.findAchievement('earnedInADay', achievementsData.earnedInADay.borders, userAchievements.earnedInADay, claimedAchievements.earnedInADay)}

                  {this.findAchievement('maxSuccessionWins', achievementsData.maxSuccessionWins.borders, userAchievements.maxSuccessionWins, claimedAchievements.maxSuccessionWins)}

                  {this.findAchievement('maxSuccessionLosses', achievementsData.maxSuccessionLosses.borders, userAchievements.maxSuccessionLosses, claimedAchievements.maxSuccessionLosses)}

                  {this.findAchievement('zolePlayed', achievementsData.zolePlayed.borders, userAchievements.zolePlayed, claimedAchievements.zolePlayed)}

                  {this.findAchievement('zoleWon', achievementsData.zoleWon.borders, userAchievements.zoleWon, claimedAchievements.zoleWon)}

                  {this.findAchievement('fastGamesPlayed', achievementsData.fastGamesPlayed.borders, userAchievements.fastGamesPlayed, claimedAchievements.fastGamesPlayed)}

                  {this.findAchievement('lightningGamesPlayed', achievementsData.lightningGamesPlayed.borders, userAchievements.lightningGamesPlayed, claimedAchievements.lightningGamesPlayed)}

                  {this.findAchievement('unlimitedGamesPlayed', achievementsData.unlimitedGamesPlayed.borders, userAchievements.unlimitedGamesPlayed, claimedAchievements.unlimitedGamesPlayed)}

                  {this.findAchievement('mazaZolePlayed', achievementsData.mazaZolePlayed.borders, userAchievements.mazaZolePlayed, claimedAchievements.mazaZolePlayed)}

                  {this.findAchievement('mazaZoleWon', achievementsData.mazaZoleWon.borders, userAchievements.mazaZoleWon, claimedAchievements.mazaZoleWon)}

                  {this.findAchievement('bonusSpins', achievementsData.bonusSpins.borders, userAchievements.bonusSpins, claimedAchievements.bonusSpins)}

                  {this.findAchievement('joinedTournaments', achievementsData.joinedTournaments.borders, userAchievements.joinedTournaments, claimedAchievements.joinedTournaments)}

                  {this.findAchievement('giftsReceived', achievementsData.giftsReceived.borders, userAchievements.giftsReceived, claimedAchievements.giftsReceived)}

                  {this.findAchievement('reachedTournamentTop10', achievementsData.reachedTournamentTop10.borders, userAchievements.reachedTournamentTop10, claimedAchievements.reachedTournamentTop10)}

                  {this.findAchievement('reachedTop100', achievementsData.reachedTop100.borders, userAchievements.reachedTop100, claimedAchievements.reachedTop100)}

                  {this.findAchievement('giftsSent', achievementsData.giftsSent.borders, userAchievements.giftsSent, claimedAchievements.giftsSent)}

                  {this.findAchievement('maxParties', achievementsData.maxParties.borders, userAchievements.maxParties, claimedAchievements.maxParties)}

                  {this.findAchievement('supportMessagesSent', achievementsData.supportMessagesSent.borders, userAchievements.supportMessagesSent, claimedAchievements.supportMessagesSent)}

                  {this.findAchievement('storePurchase', achievementsData.storePurchase.borders, userAchievements.storePurchase, claimedAchievements.storePurchase)}

                  {this.findAchievement('galdinsPlayed', achievementsData.galdinsPlayed.borders, userAchievements.galdinsPlayed, claimedAchievements.galdinsPlayed)}

                  {this.findAchievement('galdinsWon', achievementsData.galdinsWon.borders, userAchievements.galdinsWon, claimedAchievements.galdinsWon)}

                  {this.findAchievement('galdinsLose', achievementsData.galdinsLose.borders, userAchievements.galdinsLose, claimedAchievements.galdinsLose)}


                  {this.findAchievement('maxDailyLogin', achievementsData.maxDailyLogin.borders, userAchievements.maxDailyLogin, claimedAchievements.maxDailyLogin)}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <Row>
                    <Col sm="3" className="ach-column">
                      Date
                    </Col>
                    <Col sm="3" className="ach-column">
                      Achievement
                    </Col>
                    <Col sm="2" className="ach-column">
                      Old value
                    </Col>
                    <Col sm="2" className="ach-column">
                      New value
                    </Col>
                    <Col sm="2" className="ach-column">
                      Reward
                    </Col>
                  </Row>
                  {claimedAchievementsHistory ? (Object.keys(claimedAchievementsHistory).map(key => (
                    <Row>

                      <Col sm="3" className="ach-column">
                        <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                          {claimedAchievementsHistory[key].time}
                        </Moment>
                      </Col>

                      <Col sm="3" className="ach-column">
                        {claimedAchievementsHistory[key].achievementId}
                      </Col>

                      <Col sm="2" className="ach-column">
                        {claimedAchievementsHistory[key].oldLevel}
                      </Col>

                      <Col sm="2" className="ach-column">
                        {claimedAchievementsHistory[key].newLevel}
                      </Col>

                      <Col sm="2" className="ach-column">
                        {claimedAchievementsHistory[key].value}
                      </Col>
                    </Row>
                  ))) : null}
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>{t('close')}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation('achievements')(UserAchievementsModal);
