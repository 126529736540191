import React from 'react';
import PropTypes from 'prop-types';

import defaultImage from '../../../../../images/redesign/common/default_image.svg';
import lightDefaultImage from '../../../../../images/redesign/light-mode/common/default_image.webp';
import normalDefaultImage from '../../../../../images/redesign/normal-mode/common/default_image.webp';
import { SCREEN_MODE } from '../../../../../constants/constants';
import { Media } from 'reactstrap';

const PlayerAvatar = ({ photo, screenMode, size, frameStyle, imageStyle, isOnline, showOnlineBage, }) => {
  const addFrameStyle = {...frameStyle, width: size, height: size};
  const addImageStyle = {...imageStyle, width: size, height: size};

  return (
    <div className="player-avatar-frame" style={addFrameStyle}>
      <Media
        className={`player-avatar-image ${photo ? '' : 'player-avatar-image-default'}`}
        style={addImageStyle}
        src={
          photo || (screenMode === SCREEN_MODE.light ? lightDefaultImage : (screenMode === SCREEN_MODE.normal ? normalDefaultImage : defaultImage))
        }
      />

      {showOnlineBage && (isOnline ? <div className="online-bage" /> : <div className="offline-bage" />)}

    </div>
  );
};

PlayerAvatar.propTypes = {
  screenMode: PropTypes.string,
  size: PropTypes.string,
  frameStyle: PropTypes.shape(),
  imageStyle: PropTypes.shape(),
  isOnline: PropTypes.bool,
  showOnlineBage: PropTypes.bool,
};

PlayerAvatar.defaultProps = {
  screenMode: SCREEN_MODE.dark,
  size: '36px',
  frameStyle: {},
  imageStyle: {},
  isOnline: false,
  showOnlineBage: false,
};

export default PlayerAvatar;
