import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import ReactDateTime from 'react-datetime';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import { connect } from 'react-redux';

import moment from 'moment';

import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';
import Spinner from 'reactstrap/lib/Spinner';
import DateFormat from '../UI/DateFormat';
import * as constants from '../../../constants/constants';
import {
  answerSupportMessage,
  cancelUserMessages,
  getProfanitiesList,
  getRoomChats,
  getUserMessages,
  supportMessageFileUpload,
} from '../../../actions/admin';
import { Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledPopover } from 'reactstrap';
import ScrollArea from 'react-scrollbar';
import Message from './AdminSupportMessage';

moment.locale('lv');
momentLocalizer();

class PlayerRoomMessages extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    playerRoomChatMsgs: PropTypes.shape([]),
    fetchRoomChats: PropTypes.func.isRequired,
    chatLoading: PropTypes.bool,
    profanitiesList: PropTypes.shape({}),
    blockUser: PropTypes.func.isRequired,
    unblockUser: PropTypes.func.isRequired,
    fetchUserMessages: PropTypes.func.isRequired,
    chatMessages: PropTypes.shape({}),
    cancelActiveUserMessages: PropTypes.func.isRequired,
    answerSupportMsg: PropTypes.func.isRequired,
    supportAMessageFileUpload: PropTypes.func.isRequired,
    setSupportMessageAsResponded: PropTypes.func.isRequired,
  }

  static defaultProps = {
    playerRoomChatMsgs: [],
    chatLoading: false,
    profanitiesList: {},
    chatMessages: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 50,
      filter: '',
      filterType: 'roomId',
      spinnerLoading: false,
      popoverOpen: null,
      blockUserId: null,
      openBlockModal: false,
      endDate: moment(),
      reason: '',
      openChatModal: false,
      openChatModalName: '',
      openChatModalUid: '',
      inputMessage: 'Brīdinājums! Agresija un rupjības čatā nav atļautas. Veiksmi!',
      penaltyMessage: ' (Tiks piemērots 300 spēles monētu sods)',
      ableToSend: true,
      isPenaltyChecked: false,

    };
    this.handlePenaltyChange = this.handlePenaltyChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.hiddenFileInput = React.createRef(null);
  }

  componentDidMount() {
    const { fetchRoomChats, fetchProfanitiesList } = this.props;
    const {filterType, filter} = this.state;

    fetchProfanitiesList();

    fetchRoomChats(filterType, filter);
  }

  componentWillReceiveProps = (nextProps) => {
    const nextPlayerRoomChatMsgs = nextProps.playerRoomChatMsgs;
    const { playerRoomChatMsgs } = this.props;

    if (playerRoomChatMsgs && !nextPlayerRoomChatMsgs) {
      this.setState({ currentPage: 0, playerRoomChatMsgs: null });
    } else if (nextPlayerRoomChatMsgs) {
      this.setState({
        playerRoomChatMsgs: nextPlayerRoomChatMsgs,
        spinnerLoading: false,
        currentPage: 0
      });
    }
  }

  componentWillUnmount = () => {
    const { fetchRoomChats } = this.props;

    fetchRoomChats();
  }

  handlePenaltyChange = (event) => {
    this.setState({ isPenaltyChecked: event.target.checked });
  }

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  handleCheckAdmin = (key) => {
    const { toggleCheckAdmin } = this.props;

    toggleCheckAdmin(key);
  }

  selectType = (e) => {
    this.setState({ filterType: e.target.value, });
  }

  handleChange(event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [event.target.name]: value,
    });
  }

  filter = () => {
    const { fetchRoomChats } = this.props;
    const { filter, filterType } = this.state;

    this.setState({
      spinnerLoading: true,
    });

    fetchRoomChats(filterType, filter);
  }

  onHover = (key) => {
    const { popoverOpen } = this.state;
    if (!popoverOpen) {
      this.setState({
        popoverOpen: key,
      });
    }
  }

  onHoverLeave = () => {
    this.setState({
      popoverOpen: '',
    });
  }

  changeEndDate = (date) => {
    this.setState({ endDate: date });
  }

  openModalBlock = (uid) => {
    this.setState({
      blockUserId: uid,
      openBlockModal: true,
    });
  }

  toggleBlock = () => {
    this.setState(prevState => ({
      openBlockModal: !prevState.openBlockModal,
    }));
  }

  blockUser = () => {
    const { blockUser } = this.props;
    const {
      blockUserId, reason, endDate,
    } = this.state;

    blockUser(blockUserId, endDate, reason).then((status) => {
      if (status === 'success') {
        const { fetchRoomChats } = this.props;
        const {filterType, filter} = this.state;
        fetchRoomChats(filterType, filter);
      }
    });
    this.setState({ openBlockModal: false });
  }

  unblockUser = (userId) => {
    const { unblockUser } = this.props;

    unblockUser(userId).then((status) => {
      if (status === 'success') {
        const { fetchRoomChats } = this.props;
        const {filterType, filter} = this.state;
        fetchRoomChats(filterType, filter);
      }
    });
  }

  openChatModalFunc = (uid, name) => {
    const { fetchUserMessages } = this.props;

    fetchUserMessages(uid).then(() => {
      //  this.scrollToBottom();
    });

    console.log('setState');
    this.setState({
      openChatModal: true,
      openChatModalName: name,
      openChatModalUid: uid,
      fileName: '',
      fileUploadUrl: '',
      //  uid,
    });
  }

  toggleChat = () => {
    const { openChatModal, openChatModalUid } = this.state;

    if (openChatModal) {
      const { cancelActiveUserMessages } = this.props;

      cancelActiveUserMessages(openChatModalUid);
    }

    console.log('setState');
    this.setState(prevState => ({
      openChatModal: !prevState.openChatModal,
      openChatModalUid: '',
    }));
  }

  setSupportMessageAsResponded = () => {
    const { setSupportMessageAsResponded } = this.props;
    const { openChatModalUid } = this.state;

    setSupportMessageAsResponded({ uid: openChatModalUid }).then(() => {
      console.log('setState');
      this.setState({
        openChatModal: false,
        //  uid: '',
      });
    });
  }

  _handleFileClick = () => {
    this.hiddenFileInput.current.click();
  };
  
  submitMessage = () => {
    const { answerSupportMsg, fetchUserMessages } = this.props;
    const {
      inputMessage, openChatModalUid, fileUploadUrl, fileName, isPenaltyChecked, penaltyMessage,
    } = this.state;
    if (inputMessage != '' || fileUploadUrl != '') {
      answerSupportMsg({
        uid: openChatModalUid,
        message: isPenaltyChecked ? (inputMessage + penaltyMessage) : inputMessage,
        fileUploadUrl,
        fileName,
        isPenaltyChecked,
      }).then(() => {
        //  this.scrollToBottom();

        fetchUserMessages(openChatModalUid).then((res) => {
          //  this.scrollToBottom();
        });

        console.log('setState');
        this.setState({ inputMessage: 'Brīdinājums! Agresija un rupjības čatā nav atļautas. Veiksmi!', fileUploadUrl: '', fileName: '' });
      });
    }
  }

  chooseFile = (e) => {
    e.preventDefault();

    const { showNotification, t } = this.props;
    const target = e.target.files[0];
    const typeList = '.jpg,.jpeg,.png,.pdf,.msword,.doc,.rtf,.txt,ain';
    if (!target || (target && target.length === 0)) return;
    if (!typeList.includes((target.type).substring((target.type).length - 3)) || target.type == '') { return showNotification('Error!', "Unsupported file!", 'danger'); }
    if (target.size / 1024 > 1536) {
      showNotification('Warning', "Can't upload more than 1.5 MB", 'warning');
      this.setState({
        fileUploadUrl: '',
        fileName: '',
      });
    } else {
      this.setState({
        ableToSend: false,
      });
      const { supportAMessageFileUpload } = this.props;
      supportAMessageFileUpload(target).then((data) => {
        console.log('setState');
        this.setState({
          fileUploadUrl: data.url,
          fileName: target.name,
          ableToSend: true,
        });
        showNotification('Success', "File successfully attached!", 'success');
      });
    }
  }

  handleChangeChat = (event) => {
    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

      console.log('setState');
      this.setState({
        [event.target.name]: value,
      });
    }
  }

  handleEnter = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.submitMessage();
    }
  }


  table() {
    const {
      t,
      playerRoomChatMsgs,
      i18n,
      profanitiesList,
    } = this.props;

    const {
      currentPage,
      pageSize,
      popoverOpen,
    } = this.state;
    const pagesCount = Math.ceil(Object.keys(playerRoomChatMsgs).length / pageSize);
    return (
      <Fragment>
        {Object.keys(playerRoomChatMsgs).length > 0 && Object.keys(playerRoomChatMsgs)
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((key, index) => (
            <Fragment key={key}>
              <tr key={key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allUsers-table-col">
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={new Date(playerRoomChatMsgs[key].time)} />
                </td>
                <td className="allUsers-table-col">
                  {playerRoomChatMsgs[key].userUid}
                </td>
                <td className="allUsers-table-col">
                  {playerRoomChatMsgs[key].userName}
                </td>
                <td className="allUsers-table-col">
                  {playerRoomChatMsgs[key].roomId}
                </td>
                <td className="allUsers-table-col">
                  {
                    playerRoomChatMsgs[key].filteredMessage !== playerRoomChatMsgs[key].message ? (
                      <>
                        <div 
                          id={`chat-popover-${playerRoomChatMsgs[key].roomId}-${index}`}
                          onMouseEnter={() => this.onHover(`chat-popover-${playerRoomChatMsgs[key].roomId}-${index}`)}
                          onMouseLeave={this.onHoverLeave}
                        >
                          {playerRoomChatMsgs[key].filteredMessage}
                        </div>
                        <Popover container={'div > div'} popperClassName="last-round-popover" placement="top" isOpen={popoverOpen === `chat-popover-${playerRoomChatMsgs[key].roomId}-${index}`} target={`chat-popover-${playerRoomChatMsgs[key].roomId}-${index}`}>
                          <PopoverBody className="last-round-popover-body">
                            {playerRoomChatMsgs[key].message}
                          </PopoverBody>
                        </Popover>
                      </>
                    ) : (
                      playerRoomChatMsgs[key].filteredMessage
                    )
                  }
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.openChatModalFunc(playerRoomChatMsgs[key].userUid, playerRoomChatMsgs[key].userName)}>
                    Message
                  </Button>
                </td>
                <td className="allUsers-table-col">
                  {playerRoomChatMsgs[key].blocked ? (
                    <Button color="primary" onClick={() => this.unblockUser(playerRoomChatMsgs[key].userUid)}>
                      Unblock
                    </Button>
                  ) : (
                    <Button color="primary" onClick={() => this.openModalBlock(playerRoomChatMsgs[key].userUid)}>
                      Block
                    </Button>
                  )}
                </td>
              </tr>
            </Fragment>
          ))}
        {pagesCount && Object.keys(playerRoomChatMsgs).length > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                  {`‹‹‹`}
                </PaginationLink>
              </PaginationItem>

              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 3 && i < currentPage + 3) {
                  return (
                    <PaginationItem active={i === currentPage} key={page}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                    {`›››`}
                  </PaginationLink>
                </PaginationItem>
            </Pagination>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      t,
      chatLoading,
      chatMessages,
    } = this.props;
    const {
      filter,
      filterType,
      spinnerLoading,
      openBlockModal,
      endDate,
      reason,
      openChatModal,
      openChatModalName,
      openChatModalUid,
      inputMessage,
      ableToSend,
      fileName,
      isPenaltyChecked,
      penaltyMessage,
    } = this.state;

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-player-room-messages">
          <PopoverBody className="popover-body">
            A list of messages from all rooms. Can be used to investigate possible insults or bad content.
          </PopoverBody>
        </UncontrolledPopover>
        <div style={{ marginTop: 100, color: '#fff' }}>
          <Row>
            <Col sm="12">
              <h2>
                Player Room Messages
                <Button className="admin-help-button" id="admin-help-button-player-room-messages">
                  ?
                </Button>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <Label for="filterType">
                Filter by
              </Label>
              <Input type="select" className="game-bet-select" value={filterType} onChange={this.selectType}>
                <option value="roomId" style={{ backgroundColor: '#222' }}>Room Id</option>
                <option value="userId" style={{ backgroundColor: '#222' }}>Player Id</option>
              </Input>
            </Col>
            <Col sm="4">
              <Label for="filter">
                Value
              </Label>
              <Input
                type="text"
                name="filter"
                id="filter"
                value={filter}
                onChange={this.handleChange}
              />
            </Col>
            <Col sm="1">
              <Button onClick={this.filter} style={{marginTop: 20}}>
                Filter
              </Button>
            </Col>
          </Row>
          <table style={{ width: '100%', fontSize: 12 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  player ID
                </th>
                <th style={{ textAlign: 'center' }}>
                  Name
                </th>
                <th style={{ textAlign: 'center' }}>
                  room ID
                </th>
                <th style={{ textAlign: 'center' }}>
                  message
                </th>
                <th style={{ textAlign: 'center' }}>
                  Chat
                </th>
                <th style={{ textAlign: 'center' }}>
                  Block
                </th>
                
              </tr>
            </thead>
            <tbody>
              {
                (spinnerLoading || chatLoading) ? (
                  <Spinner type="grow" color="warning" style={{ width: '2rem', height: '2rem' }} />
                ) : (
                  this.table()
                )
              }
            </tbody>
          </table>

        </div>

        <Modal container={'div > div'} isOpen={openBlockModal} toggle={this.toggleBlock}>
          <ModalHeader toggle={this.toggleBlock}>
            Block
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="endDate">
                  Until
                </Label>
                <ReactDateTime
                  closeOnSelect
                  onChange={this.changeEndDate}
                  value={endDate}
                  id="endDate"
                />
              </FormGroup>
              <FormGroup>
                <Label for="reason">
                  Reason
                </Label>
                <Input
                  type="text"
                  name="reason"
                  id="reason"
                  value={reason}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.blockUser}>Block</Button>
            <Button color="secondary" onClick={this.toggleBlock}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal container={'div > div'} isOpen={openChatModal} toggle={this.toggleChat}>
          <ModalHeader toggle={this.toggleChat}>
            {`Chat - ${openChatModalName} - ${openChatModalUid}`}
          </ModalHeader>
          <ModalBody>
            <Row className="chat-body">
              <Col className="chat-body-wrapper" md="12">
                <ScrollArea
                  speed={0.65}
                  className="admin-chat-scroll-area"
                  contentClassName="admin-chat-scroll-content"
                  //  smoothScrolling
                  verticalContainerStyle={{
                    background: 'transparent',
                    opacity: 1,
                    width: 10,
                  }}
                  verticalScrollbarStyle={{
                    background: '#fff',
                    borderRadius: 2,
                    width: 6,
                    minHeight: 30,
                    minScrollSize: 35,
                  }}
                  horizontal={false}
                  ref={(el) => { this.messagesScrollbar = el; }}
                >
                  {chatMessages && Object.keys(chatMessages).map(key => (
                    <Row key={key}>
                      <Col md="12">
                        <Message message={chatMessages[key]} />
                      </Col>
                    </Row>
                  ))}
                </ScrollArea>
              </Col>
            </Row>
            <Row className="chat-footer" style={{ height: 60 }}>
              <Form style={{ width: '100%', height: '100%' }}>
                <Col md="12" style={{ height: '100%' }}>
                  <Input
                    className="h-100 chat-footer-input chat-footer-input-admin"
                    type="textarea"
                    name="inputMessage"
                    id="inputMessage"
                    autoComplete="off"
                    placeholder='Write Message...'
                    style={{

                    }}
                    value={inputMessage}
                    onChange={this.handleChangeChat}
                    onKeyPress={this.handleEnter}
                  />

                </Col>
              </Form>
            </Row>
            <Row>
              <Col xs="6">
                <Button
                  className="btn-warning admin-file-upload-button"
                  onClick={this._handleFileClick}
                >
                  {fileName === '' ? '+ Choose File' : fileName}
                </Button>
                <input
                  id="files"
                  hidden
                  ref={this.hiddenFileInput}
                  className="chat-footer-input chat-footer-input-admin admin-file-upload-input"
                  accept=".jpg,.jpeg,.png,.pdf,.msword,.doc,.rtf,.txt,ain"
                  type="file"
                  onChange={this.chooseFile}
                />
              </Col>
              <Col xs="6">
                <label className="admin-penalty-checkbox">
                  <input
                    type="checkbox"
                    value={isPenaltyChecked}
                    onChange={this.handlePenaltyChange}
                  />
                  Apply 300 coins penalty
                </label>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={this.setSupportMessageAsResponded}>Mark As Read</Button>
            <Button className="contact-support-footer-button" disabled={!ableToSend} color="primary" onClick={this.submitMessage}>
              {/* {t('send')} */}
              Send
            </Button>
            <Button type="button" color="secondary" onClick={this.toggleChat}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  playerRoomChatMsgs: state.admin.playerRoomChatMsgs || [],
  chatLoading: state.admin.chatLoading,
  profanitiesList: state.admin.profanitiesList || {},
});

const mapDispatchToProps = {
  fetchRoomChats: getRoomChats,
  fetchProfanitiesList: getProfanitiesList,
  fetchUserMessages: getUserMessages,
  cancelActiveUserMessages: cancelUserMessages,
  answerSupportMsg: answerSupportMessage,
  supportAMessageFileUpload: supportMessageFileUpload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('admin')(PlayerRoomMessages));
