import React from 'react';
import PropTypes from 'prop-types';

const Prohibitions = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>Ir aizliegts:</p>
        <ul>
          <li>izteikt rupjības un apvainojumus, kā arī draudus spēles administrācijai un citiem spēlētājiem;</li>
          <li>publicēt jebkāda veida saturu, kas ir pretrunā ar Latvijas Republikā pastāvošo likumdošanu;</li>
          <li>spēlēt ar vairāk kā vienu savu kontu vienā un tajā pašā istabā;</li>
          <li>ar trešās puses programmatūras palīdzību iejaukties spēles darbībā ar mērķi ietekmēt spēles gaitu, savu vai citu spēlētāju rezultātus.</li>
        </ul>
        <p>
        Gadījumā, ja tiek pārkāpts kāds no augstāk esošajiem punktiem, spēles administrācija patur tiesības uz noteiktu laiku bloķēt spēlētāja piekļuvi spēles kontam.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>Запрещено:</p>
        <ul>
          <li>высказывать грубости и обвинения, а так же угрозы в адрес администрации и остальных игроков;</li>
          <li>выставлять какие либо публикации, которые противоречат ныне действующему законодательству Латвийской Республики;</li>
          <li>играть с более чем одним своим контом в одной и той же комнате;</li>
          <li>прибегнув к помощи программатуры третьей стороны вмешиваться в ход игры с целью повлиять на ход игры, на свои или результаты остальных игроков.</li>
        </ul>
        <p>
          В случае нарушения хотя бы одного из вышеупомянутых очками, администрация оставляет за собой право на определенное время блокировать доступ игрока к игровому конту.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>It is forbidden:</p>
        <ul>
          <li>to be offensive and insult, as well as to threat to the game administration and other players;</li>
          <li>to publish any kind of content that conflicts with the legislation existing in the Republic of Latvia;</li>
          <li>play with more than one of your accounts in the same room;</li>
          <li>interfere with the operation of the game with the help of third-party software to influence the course of the game, personal or other players' results.</li>
        </ul>
        <p>
          In case of a breach of any of the above-mentioned restrictions, the game administration reserves the right to block a player's access to the game account for a specified period of time.
        </p>
      </>
    )
  }
};

Prohibitions.propTypes = {
lang: PropTypes.string,
};

Prohibitions.defaultProps = {
lang: 'lv',
};

export default Prohibitions;
