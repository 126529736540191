import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getBetValue } from '../../../../common/services/data-service';
import Button from 'reactstrap/lib/Button';
import { useTranslation } from 'react-i18next';

const EndResultFines = React.memo(({ myPos, globalParams, players, showInfoModal }) => {
  const { minGames, bet, gameState, fourPRoom, rPlayed, closeReason } = globalParams;

  const  { t } = useTranslation('admin');
  let betValue;
  let leavePenalty;
  let leaveReward;
  let penaltyPlayer;

  if (players && closeReason && closeReason.reason && (closeReason.reason === 'missedTurn' || closeReason.reason === 'leftRoom')) {
    betValue = getBetValue(bet);

    if (players.player1 && players.player1.uid === closeReason.playerUid) {
      penaltyPlayer = 'player1';
    } else if (players.player2 && players.player2.uid === closeReason.playerUid) {
      penaltyPlayer = 'player2';
    } else if (players.player3 && players.player3.uid === closeReason.playerUid) {
      penaltyPlayer = 'player3';
    } else if (players.player4 && players.player4.uid === closeReason.playerUid) {
      penaltyPlayer = 'player4';
    }

    if (closeReason.isAutomated) {
      leavePenalty = 0;
      leaveReward = 0;
    } else {
      leavePenalty = fourPRoom ? Math.round(betValue * 24) : Math.round(betValue * 16);
      leaveReward = Math.round(0.25 * leavePenalty);
    }

    if (minGames && (rPlayed || rPlayed === 0) && minGames > rPlayed && betValue) {
      leavePenalty = leavePenalty + Math.round((minGames - rPlayed) * 10 * betValue);

      leaveReward = leaveReward + Math.round(0.25 * (minGames - rPlayed) * 10 * betValue);
    }
  } else {
    return null;
  }

    return (
      <table className="score-table-sum">
        <thead />
        <tbody style={{ width: '100%' }}>
          <tr>
            <th className="score-table-sum-col-id">
              <div>
                {closeReason.reason === 'missedTurn' ? (
                  t('missedTurnPenalty')
                ):(null)}
                {closeReason.reason === 'leftRoom' ? (
                  t('leftRoomPenalty')
                ):(null)}
              </div>
            </th>
            <th className="score-table-points-col d-flex align-items-center">
              <>
                {myPos === 'player1' && (
                  <>
                    {penaltyPlayer === 'player4' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    {penaltyPlayer === 'player1' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    {penaltyPlayer === 'player2' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
                {myPos === 'player4' && (
                  <>
                    {penaltyPlayer === 'player3' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
              </>
            </th>
            <th className="score-table-points-col d-flex align-items-center">
              <>
                {myPos === 'player1' && (
                  <>
                    {penaltyPlayer === 'player1' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    {penaltyPlayer === 'player2' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    {penaltyPlayer === 'player3' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
                {myPos === 'player4' && (
                  <>
                    {penaltyPlayer === 'player4' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
              </>
            </th>
            <th className="score-table-points-col d-flex align-items-center">
              <>
                {myPos === 'player1' && (
                  <>
                    {penaltyPlayer === 'player2' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    {penaltyPlayer === 'player3' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    {penaltyPlayer === 'player4' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
                {myPos === 'player4' && (
                  <>
                    {penaltyPlayer === 'player1' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
              </>
            </th>
            <th className="score-table-points-col d-flex align-items-center">
              <>
                {myPos === 'player1' && (
                  <>
                    {penaltyPlayer === 'player3' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    {penaltyPlayer === 'player4' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    {penaltyPlayer === 'player1' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
                {myPos === 'player4' && (
                  <>
                    {penaltyPlayer === 'player2' ? (-leavePenalty) : (leaveReward)}
                  </>
                )}
              </>
            </th>
            <th className="score-table-sum-col-pules" />
            <th className="score-table-sum-col-pules d-flex align-items-center">
              <Button color="link" className="end-result-modal-info-button" onClick={showInfoModal}>
                {t('info')}
              </Button>
            </th>
          </tr>
        </tbody>
      </table>
    );
});

EndResultFines.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
  showInfoModal: PropTypes.func.isRequired,
};

EndResultFines.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
};

const mapStateToProps = (state, ownProps) => {
  if (state.game) {
    return ({
      myPos: state.game.myPos || null,
      globalParams: state.game.globalParams || {},
      players: state.game.players,
    });
  } else {
    return null;
  }
};

export default connect(mapStateToProps, null)(EndResultFines);
