import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import { Helmet } from 'react-helmet';
import { sortBy, find, size, map, filter, includes } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import TabPane from 'reactstrap/lib/TabPane';
import TabContent from 'reactstrap/lib/TabContent';
import Media from 'reactstrap/lib/Media';
import Alert from 'reactstrap/lib/Alert';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';

import CustomModal from '../../Components/Components/Modal';
import TopPageList from './TopPageList';
import FilterComponent from './Components/Filter';
// import TopPageMillionaires from './TopPageMillionaires';

// import CustomModal from '../../Components/Components/Modal';
import ModalQuestion from '../../Components/Components/ModalQuestion';
import CustomDropdown from '../../Components/Components/CustomDropdown';
import SwitchSelect from '../../Components/Components/SwitchSelect';

import * as constants from '../../../../../constants/constants';

import buttonClickedSound from '../../../../../sounds/click_feedback.flac';
import proIcon from '../../../../../images/icons/proIcon.svg';

import {
  getLeaderboardFiltered,
  getLeaderboardMillionaires,
  getLeaderboardSearch,
} from '../../../../../actions/leaderboard';

import {
  blockUser,
  unBlockUser,
  getIgnoredPlayers,
  getFriends,
  addFriend,
  removeFriend,
} from '../../../../../actions/member';

import {
  getBannedUsers,
} from '../../../../../actions/users';

import filterIcon from '../../../../../images/redesign/rooms-table/filterIcon.png';
import lightFilterIcon from '../../../../../images/redesign/light-mode/rooms-table/filterIcon.png';

import { orderBy } from 'lodash';
import ErrorNotification from '../../Components/Components/ErrorNotification';
import Toggle from '../../Components/Components/Toggle';


const FilterMap = [
  { name: 'Visu laiku', value: '5' },
  { name: 'Šodien', value: '1' },
  { name: 'Šonedēļ', value: '2' },
  { name: 'Šomēnes', value: '3' },
  { name: 'Šogad', value: '4' },
];

const FilterMapRu = [
  { name: 'Все время', value: '5' },
  { name: 'Сегодня', value: '1' },
  { name: 'Эта неделя', value: '2' },
  { name: 'Этот месяц', value: '3' },
  { name: 'Этот год', value: '4' },
];

const FilterMapEn = [
  { name: 'All Time', value: '5' },
  { name: 'Today', value: '1' },
  { name: 'This Week', value: '2' },
  { name: 'This Month', value: '3' },
  { name: 'This Year', value: '4' },
];

const TypeMap = {
  1: 'Punkti',
  2: 'Bilance',
  3: 'Izspēlētās spēles',
  //  4: 'Uzvarētās lielās zoles',
  //  5: 'Uzvarētās mazās zoles',
  //  6: 'Uzvarētie galdi',
  //  7: 'Sasniegumu skaits',
  //  8: 'Raundi vienā spēlē'
};

const TypeMapRu = {
  1: 'Очки',
  2: 'Баланс',
  3: 'Количество сыгранных партий',
  //  4: 'Large zole won',
  //  5: 'Small zole won',
  //  6: 'Tables won',
  //  7: 'Amount of achievements',
  //  8: 'Max Rounds In one game'
};

const TypeMapEn = {
  1: 'Points',
  2: 'Balance',
  3: 'Games played',
  //  4: 'Large zole won',
  //  5: 'Small zole won',
  //  6: 'Tables won',
  //  7: 'Amount of achievements',
  //  8: 'Max Rounds In one game'
};

//let localFilters = {};
//try {
//  localFilters = localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')) : {};
//} catch (err) {
//  console.log(err);
//}
// const localFilters = {};

class TopPage extends React.Component {
  static propTypes = {
    leaderboardData: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    fetchLeaderboardFiltered: PropTypes.func.isRequired,
    // fetchLeaderboardMillionaires: PropTypes.func.isRequired,
    fetchLeaderboardSearch: PropTypes.func.isRequired,
    blockUserFunc: PropTypes.func.isRequired,
    unBlockUserFunc: PropTypes.func.isRequired,
    fetchIgnoredPlayers: PropTypes.func.isRequired,
    fetchBannedUsers: PropTypes.func.isRequired,
    notificationSettings: PropTypes.shape({
      ignorePlayer: PropTypes.bool,
    }),
    userSettings: PropTypes.shape({
      soundOn: PropTypes.bool,
    }),
    uid: PropTypes.string,
    ignoredUsers: PropTypes.shape({}),
    bannedUsers: PropTypes.shape({}),
    friends: PropTypes.shape({}),
    i18n: PropTypes.shape(),
    toggle: PropTypes.func.isRequired,
    screenMode: PropTypes.string,
    addAFriend: PropTypes.func.isRequired,
    removeAFriend: PropTypes.func.isRequired,
  }

  static defaultProps = {
    leaderboardData: {},
    notificationSettings: {},
    userSettings: {},
    ignoredUsers: {},
    bannedUsers: {},
    friends: {},
    i18n: null,
    uid: null,
    screenMode: constants.SCREEN_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: constants.filterLeaderboardActiveTypes.allTime,
      activeType: constants.filterLeaderboardTypeVal.totalPnts,
      sortingOptionTempId: null,
      extraColumnTempId: null,
      extraColumnTempValue: null,
      filterOpen: false,
      typeFilterOpen: false,
      scrollPos: 0,
      millionairesOpen: false,
      showOnlyActive: false,
      showNoOlderMeOpen: false,
      filterModalOpen: false,
      orderby: null,
      order: null,
      filters: {
        filterLevels: [],
        selectFieldArray: [], // showFieldArray: localFilters?.selectFieldArray || [],
        searchValue: '',
        activeTab: constants.filterLeaderboardActiveTypes.allTime,
        showOnlyActive: false,
        millionairesOpen: false,
        showNoOlderMeOpen: false,
        activeType: constants.filterLeaderboardTypeVal.totalPnts,
      },
      isFiltered: false,
      popoverOpen: false,
      alertType: '',
      alertStatus: false,
      message: '',
      showFields: {
        showFieldArray: [],
      },
      isFriendLoading: '',
      leaderboardTypeModalOpen: false,
    };

    //  this.toggleFilter = this.toggleFilter.bind(this);

    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentDidMount() {

    const { fetchLeaderboardSearch, fetchLeaderboardFiltered, fetchIgnoredPlayers, fetchBannedUsers, fetchFriends, } = this.props;
    const { activeTab, activeType, millionairesOpen, showOnlyActive, filters, showNoOlderMeOpen } = this.state;

    if (filters.searchValue) {
      console.log("here!!!");
      fetchLeaderboardSearch(filters.searchValue, filter(constants.filterLeaderboardType, ['id', activeType.toString()])[0].label, filter(constants.filterLeaderboardActive, ['id', activeTab.toString()])[0].label, showOnlyActive, millionairesOpen, showNoOlderMeOpen);
    } else {
      console.log(activeType, filter(constants.filterLeaderboardType, ['id', activeType.toString()])[0].label, activeTab, filter(constants.filterLeaderboardActive, ['id', activeTab])[0].label, "check check");
      fetchLeaderboardFiltered(filter(constants.filterLeaderboardType, ['id', activeType.toString()])[0].label, filter(constants.filterLeaderboardActive, ['id', activeTab.toString()])[0].label, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels);
    }
    fetchIgnoredPlayers();
    fetchBannedUsers();
    fetchFriends();
  }

  // componentWillReceiveProps(nexProps) {
  //   const { leaderboardData } = this.props;
  //   const nextLeaderboardData = nexProps.leaderboardData;
  //   const {
  //     leaderboard,
  //     leaderboardSearch,
  //     leaderboardMillionaires,
  //     leaderboardMillionairesSearch,
  //     myLeaderboard,
  //     myLeaderboardSearch,
  //   } = leaderboardData;

  //   const {
  //     nextLeaderboard,
  //     nextLeaderboardSearch,
  //     nextLeaderboardMillionaires,
  //     nextLeaderboardMillionairesSearch,
  //     nextMyLeaderboard,
  //     nextMyLeaderboardSearch,
  //   } = nextLeaderboardData;

  //   if (myLeaderboard !== nextMyLeaderboard || myLeaderboardSearch !== nextMyLeaderboardSearch || leaderboard !== nextLeaderboard || leaderboardSearch !== nextLeaderboardSearch || leaderboardMillionaires !== nextLeaderboardMillionaires || leaderboardMillionairesSearch !== nextLeaderboardMillionairesSearch) {
  //     this.fetchLeaderboardSearch();
  //   }
  // }

  toggle = (key) => {
    const {
      fetchLeaderboardFiltered, fetchLeaderboardSearch,
    } = this.props;
    console.log(key)
    if (key) {
      const { activeTab, activeType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters } = this.state;

      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          activeTab: key,
        },
      }));

      let filterType = '';
      filterType = constants.filterLeaderboardType.filter(itemKey => itemKey.id === activeType.toString())[0].label;
      let dateType = '';
      dateType = constants.filterLeaderboardActive.filter(itemKey => itemKey.id === key.toString())[0].label;
      if (!filters.searchValue) {
        if (activeTab.toString() !== key.toString()) {
          this.playButtonSound();
          fetchLeaderboardFiltered(filterType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels);
        }
        this.setState({
          activeTab: key,
          isFiltered: false,
        });
      } else {
        fetchLeaderboardSearch(filters.searchValue, filterType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen);
        this.setState({
          activeTab: key,
          isFiltered: true,
        });
      }
    }
  }

  toggleType = (key) => {
    const {
      fetchLeaderboardFiltered, fetchLeaderboardSearch,
    } = this.props;
    if (key) {
      console.log("call toggle type", key);
      const { searchValue, activeType, activeTab, showOnlyActive, millionairesOpen, showNoOlderMeOpen, showFields, filters, } = this.state;

      let dateType = '';
      dateType = constants.filterLeaderboardActive.filter(itemKey => itemKey.id === activeTab.toString())[0].label;
      let keyType = '';
      keyType = constants.filterLeaderboardType.filter(itemKey => itemKey.id === key.toString())[0].label;

      console.log(key, keyType, "selectType====")
      if (constants.SHOW_PLACE_FILEDS.indexOf(keyType) !== -1) {
        const { selectFieldArray } = filters;
        const newVal = selectFieldArray;
        const index = newVal.indexOf(keyType);

        if (index === -1) {
          if (newVal.length > 0) {
            newVal.pop();
          }
          newVal.push(keyType);
        } else {
          newVal.splice(index, 1);
        }

        this.setState(prevState => ({
          filters: {
            ...prevState.filters,
            selectFieldArray: [...newVal],
          },
          showFields: {
            ...prevState.showFields,
            showFieldArray: [...newVal],
          },
        }));


        const filtersTemp = filters;

        try {
          if (localStorage.getItem('filters')) { //x
            localStorage.removeItem('filters'); //x
            localStorage.setItem('filters', JSON.stringify(filtersTemp)); //x
          } else {
            localStorage.setItem('filters', JSON.stringify(filtersTemp)); //x
          }
        } catch (err) {
          console.log(err);
        }

        return;
      }


      if (!filters.searchValue) {
        if (activeType.toString() !== key.toString()) {
          this.playButtonSound();
          fetchLeaderboardFiltered(keyType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels);
        }
        this.setState({
          activeType: key,
          isFiltered: false,
          order: null,
          orderby: null,
        });
      } else {
        fetchLeaderboardSearch(filters.searchValue, keyType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen);
        this.setState({
          activeType: key,
          isFiltered: true,
          order: null,
          orderby: null,
        });
      }
      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          activeType: key,
        },
      }));
    }
  }

  setSortingOptionTemp = (item) => {
    this.setState({sortingOptionTempId: item.id});
  }
  setExtraColumnTemp = (item) => {
    const { extraColumnTempId } = this.state;
    this.setState({
      extraColumnTempId: extraColumnTempId !== item.id ? item.id : null,
      extraColumnTempValue: extraColumnTempId !== item.id ? item.value : null,
    });
  }

  toggleLeaderboardTypeModalOpen = () => {
    const { leaderboardTypeModalOpen, showFields, activeType, sortingOptionTempId, extraColumnTempId, extraColumnTempValue } = this.state;
    if (!leaderboardTypeModalOpen) {
      this.setState({
        sortingOptionTempId: activeType,
        extraColumnTempId: showFields.showFieldArray[0] ? constants.filterLeaderboardExtraColumns.find(item => item.value === showFields.showFieldArray[0]).id : null,
        extraColumnTempValue: showFields.showFieldArray[0] || null,
        leaderboardTypeModalOpen: true
      });
    } else {
      if (activeType !== sortingOptionTempId) {
        this.toggleType(sortingOptionTempId);
      }
      if (showFields.showFieldArray[0] !== extraColumnTempValue) {
        if (extraColumnTempValue) {
          this.toggleType(extraColumnTempId);
        } else if (showFields.showFieldArray[0]) {
          this.toggleType(constants.filterLeaderboardExtraColumns.find(item => item.value === showFields.showFieldArray[0]).id);
        }
      }

      this.setState({
        sortingOptionTempId: null,
        extraColumnTempId: null,
        extraColumnTempValue: null,
        leaderboardTypeModalOpen: false
      });
    }
  }

  openFilter = () => {
    this.setState({ filterModalOpen: true });
  }

  closeFilter = () => {
    this.setState({
      filterModalOpen: false,
    });
  }

  updateScrollPos = (val) => {
    console.log('updateScrollPos', { val });
    if (val.topPosition || val.topPosition === 0) {
      this.setState({ scrollPos: val.topPosition || 0 });
    }
  }

  scroll = (newVal) => {
    console.log('scroll', { newVal });
    this.tableScrollbar.scrollYTo(newVal);
  }

  playButtonSound = () => {
    const { soundOn, uid } = this.props;

    if (soundOn) {
      const playPromise = this.buttonClickedAudio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            // Automatic playback started!
            // Show playing UI.
            console.log("audio played auto");
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
            console.log("playback prevented");
          });
      }
    }
  }

  toggleFilter = () => {
    this.setState(prevState => ({
      filterOpen: !prevState.filterOpen,
      typeFilterOpen: false,
    }));
  }

  selectFilterMap = () => {
    const { i18n } = this.props;

    if (i18n) {
      if (i18n.language === 'lv') {
        return FilterMap;
      } if (i18n.language === 'ru') {
        return FilterMapRu;
      } if (i18n.language === 'en') {
        return FilterMapEn;
      }
    }
  }

  // changeFilterValue = (value) => {
  //   this.setState(prevState => ({
  //     filterOpen: !prevState.filterOpen,
  //   }));
  // }

  toggleSearch = () => {
    const { searchOpen } = this.state;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        searchValue: ''
      },
      searchOpen: !prevState.searchOpen,
      //  typeFilterOpen: false,
    }));
  }

  toggleTypeFilter = () => {
    this.setState(prevState => ({
      filterOpen: false,
      typeFilterOpen: !prevState.typeFilterOpen,
    }));
  }

  toggleMillionaires = () => {
    const { fetchLeaderboardSearch, fetchLeaderboardFiltered } = this.props;
    const { millionairesOpen, showOnlyActive, searchValue, activeTab, activeType, filters, showNoOlderMeOpen, } = this.state;

    let filterType = '';
    filterType = constants.filterLeaderboardType.filter(itemKey => itemKey.id === activeType.toString())[0].label;
    let dateType = '';
    dateType = constants.filterLeaderboardActive.filter(itemKey => itemKey.id === activeTab.toString())[0].label;

    this.setState(prevState => ({
      millionairesOpen: !prevState.millionairesOpen,
      isFiltered: false,
      filters: {
        ...prevState.filters,
        millionairesOpen: !prevState.filters.millionairesOpen,
        searchValue: '',
      },
    }));

    if (filters.searchValue) {
      fetchLeaderboardSearch(filters.searchValue, filterType, dateType, showOnlyActive, !millionairesOpen, showNoOlderMeOpen);
    } else {
      fetchLeaderboardFiltered(filterType, dateType, showOnlyActive, !millionairesOpen, showNoOlderMeOpen, filters.filterLevels);
    }
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  handleSearchValue = (event) => {
    event.persist();
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        searchValue: event.target.value
      }
    }));
  }

  handleKeyPress = (target) => {
    if (target.charCode === 13) {
      this.fetchLeaderboardSearch();
      this.setState({
        filterModalOpen: false,
        // searchOpen: false,
      });
    }
  }

  fetchLeaderboardSearch = () => {
    // e.preventDefault();

    const { fetchLeaderboardSearch, fetchLeaderboardFiltered, t } = this.props;
    const { activeTab, activeType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters } = this.state;

    let dateType = '';
    dateType = constants.filterLeaderboardActive.filter(itemKey => itemKey.id === activeTab.toString())[0].label;
    let keyType = '';
    keyType = constants.filterLeaderboardType.filter(itemKey => itemKey.id === activeType.toString())[0].label;

    console.log(filters.searchValue, "searchValueCheck")
    if ((size(filters.searchValue) === 0) || (size(filters.searchValue) !== 0 && size(filters.searchValue) < 4)) {
      this.setState({
        alertStatus: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: t('top.wrongSearch'),
        searchOpen: true,
        isFiltered: false,
      });
      setTimeout(() => {
        this.setState({
          alertStatus: false,
          alertType: '',
          message: '',
        });
      }, 10000);
      fetchLeaderboardFiltered(filter(constants.filterLeaderboardType, ['id', activeType.toString()])[0].label, filter(constants.filterLeaderboardActive, ['id', activeTab.toString()])[0].label, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels);
      return ;
    }
    
    if (filters.searchValue) {
      fetchLeaderboardSearch(filters.searchValue, keyType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen);
      this.setState({
        isFiltered: true,
        searchOpen: true,
      });
    } else {
      this.playButtonSound();
      console.log(showNoOlderMeOpen, "showNoOlderMeOpen");
      fetchLeaderboardFiltered(keyType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels);
      this.setState({
        isFiltered: false,
        searchOpen: true,
      });
    }
  }

  onHover = () => {
    this.setState({ popoverOpen: true });
  }

  onHoverLeave = () => {
    this.setState({ popoverOpen: false });
  }

  block = (id, name) => {
    const { blockUserFunc, notificationSettings } = this.props;
    console.log('block', { id, name });

    this.playButtonSound();
    //  blockUserFunc(id, name).then((res) => {
    //  });

    if (notificationSettings && notificationSettings.ignorePlayer === false) {
      blockUserFunc(id, name);
    } else {
      this.setState(prevState => ({
        blockUserModal: !prevState.blockUserModal,
        blockUserSelectedUid: id,
        blockUserSelectedName: name,
      }));
    }
  }

  blockUserConfirm = () => {
    const { blockUserFunc } = this.props;
    const { blockUserSelectedUid, blockUserSelectedName } = this.state;

    if (blockUserSelectedUid && blockUserSelectedName) {
      blockUserFunc(blockUserSelectedUid, blockUserSelectedName);

      this.playButtonSound();
      this.setState({
        blockUserModal: false,
        blockUserSelectedUid: null,
        blockUserSelectedName: null,
      });
    }
  };

  closeBlockModal = () => {
    this.setState({
      blockUserModal: false,
      blockUserSelectedUid: null,
      blockUserSelectedName: null,
    });
  }

  unblock = (id) => {
    const { unBlockUserFunc } = this.props;

    this.playButtonSound();
    unBlockUserFunc(id).then(() => {
      //  if (res && res.status === 'success') {
      //    fetchIgnoredPlayers();
      //  }
    });
  }

  renderRating = (t, rating) => (
    <div className="my-info-ratings-step-wrapper">
      {console.log('renderRating', rating)}
      {!rating || rating < 10 ? (<div className="my-info-ratings-step my-info-ratings-step-weak my-info-ratings-step-active">{t('myInfo.weak')}</div>) : (null)}
      {(rating >= 10 && rating < 20) ? (<div className="my-info-ratings-step my-info-ratings-step-beginner my-info-ratings-step-active">{t('myInfo.beginner')}</div>) : (null)}
      {(rating >= 20 && rating < 30) ? (<div className="my-info-ratings-step my-info-ratings-step-untrained my-info-ratings-step-active">{t('myInfo.untrained')}</div>) : (null)}
      {(rating >= 30 && rating < 40) ? (<div className="my-info-ratings-step my-info-ratings-step-medium my-info-ratings-step-active">{t('myInfo.medium')}</div>) : (null)}
      {(rating >= 40 && rating < 50) ? (<div className="my-info-ratings-step my-info-ratings-step-good my-info-ratings-step-active">{t('myInfo.good')}</div>) : (null)}
      {(rating >= 50 && rating < 60) ? (<div className="my-info-ratings-step my-info-ratings-step-great my-info-ratings-step-active">{t('myInfo.great')}</div>) : (null)}
      {(rating >= 60 && rating < 70) ? (<div className="my-info-ratings-step my-info-ratings-step-fan my-info-ratings-step-active">{t('myInfo.fan')}</div>) : (null)}
      {rating >= 70 ? (<div className="my-info-ratings-step my-info-ratings-step-pro my-info-ratings-step-active"><Media src={proIcon} alt="X" /></div>) : (null)}
    </div>
  );

  handleOrderBy = async (orderField) => {
    const { fetchLeaderboardFiltered, fetchLeaderboardSearch } = this.props;
    const { orderby, order } = this.state;
    const orderByField = orderField;

    console.log('handleOrderBy', orderField);

    const { activeTab, activeType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters } = this.state;

    let dateType = '';
    dateType = constants.filterLeaderboardActive.filter(itemKey => itemKey.id === activeTab.toString())[0].label;
    let keyType = '';
    keyType = constants.filterLeaderboardType.filter(itemKey => itemKey.id === activeType.toString())[0].label;

    let orderType = keyType;
    let isEqual = false;

    console.log('orderByField === place', orderByField === 'place');
    console.log('orderByField === points', orderByField === 'points');
    console.log('activeType', activeType === 1);

    if ((orderByField === 'place' || orderByField === 'points') && activeType.toString() === '1') {
      console.log('set isequal');
      orderType = 'totalPnts';
      isEqual = true;
    //  this.setState({ activeType: 1 });
    } else if ((orderByField === 'place' || orderByField === 'balance') && activeType.toString() === '2') {
      orderType = 'bal';
      isEqual = true;
    //  this.setState({ activeType: 2 });
    } else if ((orderByField === 'place' || orderByField === 'gamesPlayed') && activeType.toString() === '3') {
      orderType = 'gPlayed';
      isEqual = true;
    //  this.setState({ activeType: 3 });
    }

    if (orderby === orderByField) {
    //  if (!filters.searchValue) {
    //    fetchLeaderboardFiltered(keyType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels);
    //  }
    //  fetchLeaderboardFiltered(keyType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels);

      //filter, time, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filterLevels, onSuccess, reverseOrdering

      if (!order) {
        if (!filters.searchValue) {
        //  await fetchLeaderboardFiltered(orderType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels, null, false);
        }

        this.setState({
          order: constants.ORDER_BY_TYPE.asc,
        });
      } else {
        if (!filters.searchValue) {
          if (isEqual) {
          //  await fetchLeaderboardFiltered(orderType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels, null, true);
          } else {
          //  await fetchLeaderboardFiltered(orderType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels, null, false);
          }
        }

        if (order === constants.ORDER_BY_TYPE.asc) {
          if (isEqual) {
            await fetchLeaderboardFiltered(orderType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels, null, true);
          }
          this.setState({
            order: constants.ORDER_BY_TYPE.desc,
          });
        } else if (order === constants.ORDER_BY_TYPE.desc) {
          //if (isEqual) {
            if (!filters.searchValue) {
              await fetchLeaderboardFiltered(orderType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels, null, false);
            } else {
              await fetchLeaderboardSearch(filters.searchValue, orderType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen);
            }
          //}

          this.setState({
            order: null,
            orderby: null,
          });
        }
      }
    } else {
      if (!filters.searchValue) {
        if (!order || order === constants.ORDER_BY_TYPE.desc) {
          await fetchLeaderboardFiltered(orderType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels, null, false);
        }
      //  await fetchLeaderboardFiltered(orderType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels, null, false);
      } else {
        await fetchLeaderboardSearch(filters.searchValue, orderType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen);
      }

      this.setState({
        orderby: orderByField,
        order: constants.ORDER_BY_TYPE.asc,
      });
    }
  };

  handleActiveType = (key) => {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        activeType: key,
        order: null,
        orderby: null,
      },
    }));
  }

  handleSelectType = (key) => {
    const { filters } = this.state;
    const { selectFieldArray } = filters;

    const keyType = filter(constants.filterLeaderboardType, ['id', key.toString()])[0].label;

    const newVal = selectFieldArray;
    const index = newVal.indexOf(keyType);

    if (index === -1) {
      if (newVal.length > 0) {
        newVal.pop();
      }
      newVal.push(keyType);
    } else {
      newVal.splice(index, 1);
    }

    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        selectFieldArray: [...newVal],
      },
      showFields: {
        ...prevState.showFields,
        selectFieldArray: [...newVal],
      },
    }));
  }

  handleActiveTab = (key) => {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        activeTab: key,
      },
    }));
  }

  handleFilterLevel = (key) => {
    const { filters } = this.state;
    const { filterLevels } = filters;

    if (key === 'all') {
      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          filterLevels: [],
        },
      }));
    } else {
      let newVal = [...filterLevels];
      const index = newVal.indexOf(key);

      if (index === -1) {
        newVal.push(key);
      } else {
        newVal.splice(index, 1);
      }

      if (newVal.length === size(constants.PLAYER_LEVEL_TYPE_FIELDS)) {
        newVal = [];
      }

      console.log('newVal', { index, newVal });

      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          filterLevels: newVal,
        },
      }));
    }
  }

  handleShowOnlyActive = () => {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        showOnlyActive: !prevState.filters.showOnlyActive,
      },
    }));
  }

  handleMillionairesOpen = () => {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        millionairesOpen: !prevState.filters.millionairesOpen,
      },
    }));
  }

  handleShowNoOlderMeOpen = () => {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        showNoOlderMeOpen: !prevState.filters.showNoOlderMeOpen,
      },
    }));
  }

  fetchSaveFiltersLeaderboardSearch = (filtersValue) => {
    const { fetchLeaderboardSearch, fetchLeaderboardFiltered, t } = this.props;
    const { filters } = this.state;
    const { searchValue, activeTab, activeType, showOnlyActive, millionairesOpen, showNoOlderMeOpen } = filtersValue;

    let dateType = '';
    dateType = constants.filterLeaderboardActive.filter(itemKey => itemKey.id === activeTab.toString())[0].label;
    let keyType = '';
    keyType = constants.filterLeaderboardType.filter(itemKey => itemKey.id === activeType.toString())[0].label;

    if ((size(searchValue) !== 0 && size(searchValue) < 4)) {
      console.log("checkcheck")
      this.setState({
        alertStatus: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: t('top.wrongSearch'),
        searchOpen: true,
        isFiltered: false,
      });
      setTimeout(() => {
        this.setState({
          alertStatus: false,
          alertType: '',
          message: '',
        });
      }, 10000);
      fetchLeaderboardFiltered(keyType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels);
      return;
    }

    if (searchValue) {
      fetchLeaderboardSearch(searchValue, keyType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen);
      this.setState({
        isFiltered: true,
        searchOpen: false,
      });
    } else {
      this.playButtonSound();
      fetchLeaderboardFiltered(keyType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filters.filterLevels);
      this.setState({
        isFiltered: false,
        searchOpen: false,
      });
    }
  }

  saveFilters = () => {
    const { filters } = this.state;
    try {
      if (localStorage.getItem('filters')) { //x
        localStorage.removeItem('filters'); //x
        localStorage.setItem('filters', JSON.stringify(filters)); //x
      } else {
        localStorage.setItem('filters', JSON.stringify(filters)); //x
      }
    } catch (err) {
      console.log(err);
    }

    this.setState(prevState => ({
      activeTab: filters.activeTab,
      millionairesOpen: filters.millionairesOpen,
      showNoOlderMeOpen: filters.showNoOlderMeOpen,
      showOnlyActive: filters.showOnlyActive,
      searchValue: filters.searchValue,
      activeType: filters.activeType,
      isFiltered: filters.searchValue ? true : false,
      showFields: {
        ...prevState.showFields,
        showFieldArray: filters.selectFieldArray,
      },
    }));

    this.fetchSaveFiltersLeaderboardSearch(filters);
    this.closeFilter();
  }

  handleSendAddFriend = (selectId) => {
    const { addAFriend, fetchFriends } = this.props;
    this.setState({
      isFriendLoading: selectId
    })
    addAFriend(selectId)
  }

  handleRemoveFriend = (selectId) => {
    const { removeAFriend } = this.props;
    removeAFriend(selectId);
  }

  render() {
    const {
      leaderboardData,
      ignoredUsers,
      bannedUsers,
      t,
      i18n,
      toggle,
      screenMode,
      friends,
    } = this.props;

    const {
      activeTab,
      filterOpen,
      typeFilterOpen,
      activeType,
      scrollPos,
      millionairesOpen,
      showNoOlderMeOpen,
      isFiltered,
      popoverOpen,
      blockUserModal,
      blockUserSelectedName,
      searchOpen,
      filterModalOpen,
      orderby,
      order,
      filters,
      showOnlyActive,
      alertStatus,
      alertType,
      message,
      showFields,
      isFriendLoading,
      //  searchValue,
      leaderboardTypeModalOpen,
      sortingOptionTempId,
      extraColumnTempId,
    } = this.state;

    console.log("showFields: ", showFields, activeType)

    const {
      leaderboard,
      leaderboardMillionaires,
      myLeaderboard,

      leaderboardSearch,
      leaderboardMillionairesSearch,
      myLeaderboardSearch,
      leaderboardLoading,
    } = leaderboardData;

    console.log('rerender', showFields);

    console.log('leaderboardData', {isFiltered}, { leaderboardData });
    console.log("filters XXX: ", filters)
    return (
      <>
        <Helmet>
          <title>
            Zole - {t('top.top')}
          </title>
        </Helmet>
        <div className="layout-body">
          <div className="layout-body-top">
            <Row className="layout-subheader">
              <Col xs="12">
                <div className="layout-float-left top-filter-new-section">
                  <Button color="link" className="layout-default-button" onClick={this.toggleLeaderboardTypeModalOpen}>
                    {t(`top.${filter(constants.filterLeaderboardType, ['id', activeType.toString()])[0].tLabel}`)}
                    <div className="triangle-down"/>
                  </Button>
                  <CustomModal
                    headerShow={false}
                    isOpen={leaderboardTypeModalOpen}
                    addClassName="top-leaderboard-modal-wrapper"
                    toggle={this.toggleLeaderboardTypeModalOpen}
                    body={
                      <div className="top-leaderboard-modal">
                        <div className="top-leaderboard-modal-section">
                          <div className="top-leaderboard-modal-section-title">
                            {t('top.sortByTitle')}
                          </div>
                          <SwitchSelect
                            options={constants.filterLeaderboardSortingType}
                            selectedOption={sortingOptionTempId}
                            selectedOptionParam="id"
                            selectFunc={this.setSortingOptionTemp}
                            selectFuncParam={null}
                            trans
                            transPath="top."
                          />
                          <div className="top-leaderboard-modal-section-description">
                            <div className="top-leaderboard-modal-section-description-first-msg">
                              {t('top.sortByDescription1')}
                            </div>
                            {t('top.sortByDescription2')}
                          </div>
                        </div>

                        <div className="top-leaderboard-modal-section">
                          <div className="top-leaderboard-modal-section-title">
                            {t('top.extraColumnTitle')}
                          </div>
                          {constants.filterLeaderboardExtraColumns.map(item => (
                            <div className="top-leaderboard-modal-section-toggle-wrapper">
                              <Toggle
                                key={item.name}
                                checked={extraColumnTempId === item.id}
                                name={t(`top.${item.name}`)}
                                onChange={() => this.setExtraColumnTemp(item)}
                                text={t(`top.${item.tLabel}`)}
                              />
                            </div>
                          ))}
                        </div>

                      </div>
                    }
                    footer={
                      <Button color="link" className="question-modal-approve-btn" onClick={this.toggleLeaderboardTypeModalOpen}>
                        {t('common.ok')}
                      </Button>
                    }
                  />
                  {/* <CustomDropdown
                    toggle={
                      t(`top.${filter(constants.filterLeaderboardType, ['id', activeType.toString()])[0].tLabel}`)
                    }
                    toggleFunc={this.toggleTypeFilter}
                    inlineMenuClassName="custom-dropdown-menu-wider"
                    isOpen={typeFilterOpen}
                    menu={(
                      <>
                        {map(constants.filterLeaderboardType, (item, key) => (
                          <DropdownItem toggle={false} key={key} onClick={() => { this.toggleType(item.id); }}>
                            {t(`top.${item.tLabel}`)}
                            {
                              item.isSortingParam
                                ? <div className={`layout-body-sorting-arrow${item.id === activeType ? '-active' : ''}`}/>
                                : (
                                  <div className="layout-body-radio-outer-circle">
                                    {includes(showFields.showFieldArray, item.label) && <div className="layout-body-radio-inner-circle"/>}
                                  </div>
                                )
                            }
                          </DropdownItem>
                        ))}
                        <DropdownItem className="layout-body-ok-button">
                          {t('game:ok')}
                        </DropdownItem>
                      </>
                    )}
                  /> */}
                </div>
                <div xs="7" className="layout-float-left layout-offset-left">
                  <SwitchSelect options={this.selectFilterMap()} selectedOption={activeTab} selectFunc={this.toggle} />
                </div>

                <div xs="2" className="layout-float-right">
                  {/*  <Button color="link" className="layout-link-button top-table-millionaires-link" onClick={() => toggle('2.1')}>
                    {t('top.millionaires')}
                  </Button> */}
                  <Button color="link" className={`layout-link-button top-table-millionaires-link ${millionairesOpen && 'top-table-active-link'}`} onClick={this.toggleMillionaires}>
                    {t('top.millionaires')}
                  </Button>
                </div>
                <div className="layout-float-right layout-divider h-100" />
                <div xs="2" className="layout-float-right top-search-section">
                  <CustomDropdown
                    toggle={(
                      <>
                        {t('top.search')}
                      </>
                    )}
                    buttonType="link"
                    toggleFunc={this.toggleSearch}
                    isOpen={searchOpen}
                    innerAutoFocus={false}
                    menu={(
                      <Row>
                        <Col xs={{ size: 10, offset: 1 }}>
                          <Input
                            type="text"
                            name="searchValue"
                            id="searchValue"
                            value={filters.searchValue}
                            onChange={this.handleSearchValue}
                            onKeyPress={this.handleKeyPress}
                            autoFocus={true}
                          />
                          <ErrorNotification
                            text={message}
                            isVisible={alertStatus && message}
                            narrow
                          />
                        </Col>
                        <Col xs={{ size: 10, offset: 1 }} className="layout-default-button-wrapper mt-2">
                          <Button
                            id="top-search-popover-new"
                            color="link"
                            className="layout-default-button"
                            onClick={this.fetchLeaderboardSearch}
                            onMouseEnter={this.onHover}
                            onMouseLeave={this.onHoverLeave}
                          >
                            {t('top.search')}
                          </Button>
                          {/* <Popover
                            container={'div > div'}
                            placement="bottom"
                            isOpen={popoverOpen}
                            target="top-search-popover-new"
                            popperClassName={`popover top-search-popover top-search-popover-${screenMode}`}
                          >
                            <PopoverBody>
                              <div>
                                <ul>
                                  <li>{t('top.searchRule1')}</li>
                                  <li>{t('top.searchRule2')}</li>
                                  <li>{t('top.searchRule3')}</li>
                                </ul>
                              </div>
                            </PopoverBody>
                          </Popover> */}
                        </Col>
                      </Row>
                    )}
                    inlineMenuClassName="top-table-search"
                  />
                </div>
                <div className="layout-float-right layout-divider h-100" />
                <div className="layout-float-right top-filter-icons-section">
                  <Button color="link" className="layout-float-right layout-link-button rooms-table-filter-button" onClick={this.openFilter}>
                    {t('roomsTable.filterRooms')}
                  </Button>
                  <div className="layout-float-right rooms-table-filter-icon mt-3">
                    <Media color="link" className="rooms-table-filter-icon-wrapper" onClick={this.openFilter} src={constants.SCREEN_MODE.light !== screenMode ? filterIcon : lightFilterIcon} />
                  </div>
                </div>
              </Col>

            </Row>
          </div>
          <div className="layout-body-main top-page-main-body-section">
            <TopPageList
              t={t}
              i18n={i18n}
              isFiltered={isFiltered}
              activeType={activeType}
              ignoredUsers={ignoredUsers}
              bannedUsers={bannedUsers}
              openFilter={this.openFilter}
              block={this.block}
              unblock={this.unblock}
              renderRating={this.renderRating}
              leaderboard={isFiltered ? (millionairesOpen ? leaderboardMillionairesSearch : leaderboardSearch) : (millionairesOpen ? leaderboardMillionaires : leaderboard)}
              myLeaderboard={isFiltered ? myLeaderboardSearch : myLeaderboard}
              order={order}
              orderby2={orderby}
              showFields={showFields.showFieldArray}
              handleOrderBy={this.handleOrderBy}
              screenMode={screenMode}
              leaderboardLoading={leaderboardLoading}
              millionairesOpen={millionairesOpen}
              friends={friends}
              handleSendAddFriend={this.handleSendAddFriend}
              handleRemoveFriend={this.handleRemoveFriend}
              filterLevels={filters?.filterLevels}
              isFriendLoading={isFriendLoading}
            />
          </div>
        </div>
        <CustomModal
          isOpen={filterModalOpen}
          toggle={this.closeFilter}
          size="lg"
          title={t('roomsTable.filterRooms')}
          footer={null}
          width={1024}
          body={(
            <>
              <FilterComponent
                searchValue={filters.searchValue}
                handleChange={this.handleSearchValue}
                toggleGameTypeFilter={this.toggleGameTypeFilter}
                toggleGameBetFilter={this.toggleGameBetFilter}
                filterType={this.filterType}
                filterBet={this.filterBet}
                activeTab={filters.activeTab}
                handleActiveTab={this.handleActiveTab}
                activeType={filters.activeType}
                handleActiveType={this.handleActiveType}
                handleFilterLevel={this.handleFilterLevel}
                filterLevels={filters.filterLevels}
                millionairesOpen={filters.millionairesOpen}
                showOnlyActive={filters.showOnlyActive}
                handleShowOnlyActive={this.handleShowOnlyActive}
                handleMillionairesOpen={this.handleMillionairesOpen}
                showNoOlderMeOpen={filters.showNoOlderMeOpen}
                handleShowNoOlderMeOpen={this.handleShowNoOlderMeOpen}
                saveFilters={this.saveFilters}
                handleKeyPress={this.handleKeyPress}
                selectFieldArray={filters.selectFieldArray}
                handleSelectType={this.handleSelectType}
              />
            </>
          )}
        />

        <ModalQuestion
          isOpen={blockUserModal}
          toggle={this.closeBlockModal}
          title={t('common.blockPlayer')}
          footer={(
            <>
              <Button color="link" className="question-modal-approve-btn" onClick={this.blockUserConfirm}>
                {t('common.yes')}
              </Button>
              <Button color="link" className="question-modal-decline-btn" onClick={() => this.closeBlockModal(null, null)}>
                {t('common.no')}
              </Button>
            </>
          )}
          body={(
            <>
              {t('common.blockPlayerText', { player: blockUserSelectedName })}
            </>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  userSettings: state.userSettings[state.member.uid] || state.userSettings.default || {},
  soundOn: state.userSettings.soundOn,
  leaderboardData: state.leaderboard || {},
  //  leaderboardMillionaires: state.leaderboard.leaderboardMillionaires || [],
  uid: state.member.uid || '',
  ignoredUsers: state.member.ignoredUsers || null,
  bannedUsers: state.users.bannedUsers || {},
  friends: state.member.friends || {},
});

const mapDispatchToProps = {
  fetchLeaderboardFiltered: getLeaderboardFiltered,
  fetchLeaderboardMillionaires: getLeaderboardMillionaires,
  fetchLeaderboardSearch: getLeaderboardSearch,
  blockUserFunc: blockUser,
  unBlockUserFunc: unBlockUser,
  fetchIgnoredPlayers: getIgnoredPlayers,
  fetchBannedUsers: getBannedUsers,
  fetchFriends: getFriends,
  addAFriend: addFriend,
  removeAFriend: removeFriend
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TopPage));
