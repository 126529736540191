import React from 'react';
import PropTypes from 'prop-types';

import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';

const Toggle = React.memo(({
  checked, onChange, text, showAsEnabled, name, disabled, toggleTheme, inlineLabelClassName
}) => (
  toggleTheme ? (
    <>
      <Label className={`switch ${showAsEnabled ? 'switch-always-enabed' : ''}`} >
        <Input
          type="checkbox"
          name={name}
          onClick={onChange}
          checked={checked}
          disabled={disabled}
          readOnly
        />
        <span className="slider round" />
      </Label>
      {
        text ? (
          <div className={`switch-text ${inlineLabelClassName}`}>{text}</div>
        ) : (null)
      }
    </>
  ) : (
    <>
      <Label className={`switch ${showAsEnabled ? 'switch-always-enabed' : ''}`}>
        <Input
          type="checkbox"
          name={name}
          onClick={onChange}
          checked={checked}
          disabled={disabled}
          readOnly
        />
        <span className="slider round" />
      </Label>
      {
        text ? (
          <div className={`switch-text ${inlineLabelClassName}`}>{text}</div>
        ) : (null)
      }
    </>
  )
));

/*

<Label className="switch" onClick={() => this.changeSetting('leaveRoom')}>
  <Input
    type="checkbox"
    onClick={() => this.changeSetting('leaveRoom')}
    checked={notificationSettings.leaveRoom}
    readOnly
  />
  <span className="slider round" />
</Label>

<label className="switch">
  <input
    type="checkbox"
    readOnly
    checked={checked}
    onChange={onChange}
  />
  <span className="slider round" />
</label>
*/

Toggle.propTypes = {
  checked: PropTypes.bool,
  showAsEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  name: PropTypes.string,
  toggleTheme: PropTypes.bool,
};

Toggle.defaultProps = {
  checked: false,
  disabled: false,
  showAsEnabled: false,
  text: null,
  name: null,
  toggleTheme: false,
};

export default Toggle;
