export const termsOfText = {
  lv: `<p class="second-level-bulletpoints"><b>1. Lietošanas noteikumi</b></p>
  <p>1.1. Šie noteikumi (turpmāk – Noteikumi) nosaka portāla Spelezoli.lv (turpmāk – Portāls) lietošanas kārtību. Noteikumi ir saistoši visām personām, kuras lieto Portālu, reģistrējas tajā un/vai spēlē kādu no piedāvātajām online spēlēm Portālā.</p>
  <p>1.2. Portālu uztur un administrē SIA 'Draxo Games', reģ. Nr. 40103927834, juridiskā adrese: Liepājas iela 49 - 6, Kuldīga, Kuldīgas nov., LV-3301, e-pasta adrese: info@draxogames.com (turpmāk – 'Draxo Games', 'mēs', 'mums').</p>
  <p>1.3. Apstiprinot šos Noteikumus vai reģistrējot savu profilu Portālā, un/vai piedaloties kādā no spēlēm, lietotājs apliecina, ka piekrīt šiem Lietošanas Noteikumiem un citiem saistošiem noteikumiem, kas ir publiskoti Portālā.</p>
  <p>1.4. Lai varētu spēlēt kādu no piedāvātajām online spēlēm, lietotājam ir nepieciešams Portālā izveidot savu profilu, norādot pieprasīto informāciju. Tāpat ir iespējams autorizēties un ienākt Portālā ar kādu no iepriekš izveidotiem Facebook.com, Draugiem.lv, Google, Apple vai Inbox.lv kontiem.</p>

  <p class="second-level-bulletpoints"><b>2. Portāla lietotājiem aizliegts:</b></p>
  <p>2.1. izmantot Draugiem.lv, Facebook.com vai Inbox.lv lietotāja profilu, kas izveidots vai tiek izmantots pretēji sociālo tīklu lietošanas noteikumiem;</p>
  <p>2.2. jebkādā veidā izpaust savas spēles datus pretiniekiem, kā arī saspēlēties ar kādu no spēlētājiem ar nolūku iegūt konkrētam spēlētājam labvēlīgu rezultātu;</p>
  <p>2.3. lietot necenzētu, godu un cieņu aizskarošu saturu spēles profilā, spēles čata logā vai sarakstē ar spēlētājiem un/vai spēles administrāciju jebkurā valodā un veidā, kā arī jebkādā veidā nepamatoti apvainot spēlētājus vai spēles administrāciju;</p>
  <p>2.4. veikt jebkāda veida darbības, kas traucē pilnvērtīgu spēles izmantošanu citiem spēlētājiem;</p>
  <p>2.5. Veikt jebkāda veida darbības, kas saistītas ar trešo pušu produktu vai pakalpojumu reklamēšanu Portālā bez iepriekšējas saskaņošanas ar Portāla administrāciju.</p>

  <p class="second-level-bulletpoints"><b>3. Portāla administrācija</b></p>
  <p>3.1. Portāla administrācijas uzdevums ir nodrošināt pilnvērtīgu pieeju spēlēm Portāla lietotājiem, kā arī atbildēt uz spēlētāju ziņojumiem, kas ir iesniegti kādā no pieejamajiem saziņas veidiem.</p>
  <p>3.2. Sazināties ar administrāciju ir iespējams, izmantojot iebūvēto čatu, sociālo tīklu kontaktu formu (ja tāda ir), kā arī rakstot uz e-pastu info@draxogames.com.</p>
  <p>3.3. Noteikumu pārkāpumu gadījumā Portāla administrācija patur tiesības bloķēt spēlētāja piekļuvi spēlei un/vai Portālam bez iepriekšējā brīdinājuma.</p>
  <p>3.4. Spēlētāja bloķēšanas gadījumā spēlēs izmantotā virtuālā nauda nekādā veidā netiek kompensēta.</p>

  <p class="second-level-bulletpoints"><b>4. Maksas pakalpojumi</b></p>
  <p>4.1. Portāla spēlē ir iespējams iegādāties virtuālo naudu vai kādus īpašus Premium pakalpojumus.</p>
  <p>4.2. Ja lietotājs izvēlas kādu maksas pakalpojumu, tad Portālā piedāvātos maksas pakalpojumus iespējams saņemt pēc tam, kad ir veikta priekšapmaksa par attiecīgo pakalpojumu.</p>
  <p>4.3. Visas cenas ir norādītas eiro un ar pievienotās vērtības nodokli.</p>
  <p>4.4. Norēķināties par spēlē iegādāto virtuālo naudu un pakalpojumiem ir iespējams, izmantojot kādu no pieejamajām apmaksas metodēm.</p>
  <p>4.5. Lietotājs drīkst veikt apmaksu tikai no sev piederošas norēķinu kartes vai konta; ir kategoriski aizliegts izmantot 3-šai personai piederošu norēķinu līdzekli bez iepriekšējas saskaņošanas ar šo personu.</p>
  <p>4.6. Pirkuma apstrāde parasti notiek uzreiz, bet dažos gadījumos tas var ilgt līdz pat vienai stundai. Jautājumu un problēmu gadījumā vari sazināties ar mums <a href="/contacts" target="_blank">šeit</a>.</p>
  <p>4.7. Pirkuma apstrāde un virtuālās naudas piegāde ir bez maksas un notiek uzreiz pēc pirkuma veikšanas, tādēļ pirkuma atcelšana nav iespējama.</p>

  <p class="second-level-bulletpoints"><b>5. Privātums</b></p>
  <p>5.1. Lai spēlētu kādu no Portālā piedāvātajām spēlēm, ir jāreģistrē un jāizveido savs profils.</p>
  <p>5.2. Profilu var izveidot, aizpildot nepieciešamo informāciju un reģistrējoties, vai arī autorizējoties ar sociālo tīklu profilu.</p>
  <p>5.3. Ja tiek veikts maksājums, tad mēs apstrādājam informāciju, kas saistīta ar šādiem darījumiem.</p>
  <p>5.4. Mēs saņemam informāciju par ierīcēm, kuras tu lieto, lai piekļūtu Portālam (piemēram, ierīces veids, operētājsistēma, interneta pārlūks, mobilo sakaru operators, IP adrese, pieslēgšanās laiks un ilgums).</p>
  <p>5.5. Mēs saņemam informāciju no trešo pušu mājaslapām un mobilajām lietotnēm, kuras tu izmanto, kad autorizējies Portālā (piemēram, veic autorizāciju ar Draugiem.lv vai Facebook pasi).</p>
  <p>5.6. Mēs izmantojam sīkdatnes.</p>

  <p class="second-level-bulletpoints"><b>6. Lietotāja dati tiek izmantoti, lai:</b></p>
  <p>6.1. nodrošinātu lietotājam iespēju saņemt Portālā piedāvātos pakalpojumus;</p>
  <p>6.2. uzturētu un uzlabotu Portālu, kā arī izstrādāju jaunus pakalpojumus;</p>
  <p>6.3. piedāvātu un personalizētu reklāmu, kā arī novērtētu Portāla darbību un reklāmas efektivitāti;</p>
  <p>6.4. pārbaudītu identitāti un novērstu profilu pretlikumīgu izmantošanu, kā arī nodrošinātu drošu Portāla vidi;</p>
  <p>6.5. aizsargātu lietotāja, mūsu un citu lietotāju intereses (piemēram, ja Portāls tiek izmantots pretlikumīgu darbību veikšanai);</p>
  <p>6.6. sniegtu lietotājam atbildes un sazinātos ar viņu interesējošos jautājumos vai lai informētu par noteikumu izmaiņām;</p>
  <p>6.7. nosūtītu jaunumus un reklāmu.</p>

  <p class="second-level-bulletpoints"><b>7. Lietotāja datu apstrāde</b></p>
  <p>7.1. Lietotāja personas dati tiks apstrādi saskaņā ar piemērojamiem tiesību aktiem. Atkarībā no situācijas datu apstrādes pamats var būt piekrišana (piemēram, jaunumu saņemšana), līguma saistību izpilde (piemēram, veicot virtuālo pakalpojumu iegādi un apmaksu), mūsu juridisks pienākums (piemēram, mums ir jāapstrādā tavi personas dati saskaņā ar likumu prasībām), mūsu leģitīmās intereses (piemēram, lai veicinātu Portāla popularitāti un attīstību, lai izmeklētu iespējamu pretlikumīgu rīcību).</p>
  <p>7.2. Mēs apstrādājam personas datus, kas saņemti tieši no tevis, piemēram, no tīmekļa vietnēm (tirgvedības reklāmām, piesakoties jaunumiem, piedaloties konkursos, loterijās un aptaujās, izdarot atsauksmes), no un par iekārtām, kuras tu lieto, kad apmeklē Portālu, no klientu apkalpošanas informācijas, no sazināšanās pa tālruni, e-pastu vai citiem komunikācijas kanāliem.</p>

  <p class="second-level-bulletpoints"><b>8. Personas datu nodošana</b></p>
  <p>8.1. Mēs nepārdosim, neiznomāsim un citādi neatklāsim tavus personas datus trešajām personām, ja vien tas nav īpaši norādīts tālāk.</p>
  <p>8.2. Mēs varam nodot tavus personas datus trešajām personām, kas mums nodrošina pakalpojumus saskaņā ar nolūkiem, kas aprakstīti šajos privātuma noteikumos, un piemērojamo tiesību aktu ietvaros. Šādām trešajām personām var tikt nodoti dati tikai minimālā un nepieciešamā apmērā, lai mēs varētu nodrošināt savus pakalpojumus, turklāt tikai tad, ja tās ir uzņēmušās atbilstošas konfidencialitātes saistības.</p>
  <p>8.3. Tavi personas dati var tikt nodoti trešajām personām, ar kurām mēs sadarbojamies un kuras mums sniedz pakalpojumus, lai mēs varētu nodrošināt un uzlabot mūsu kā uzņēmuma un arī Portāla darbību (piemēram, datu izvietošanas un uzturēšanas, datu un portāla drošības, maksājumu apstrādes, risku un krāpniecības novēršanas un atklāšanas, datu analīzes, īsziņu un e-pasta izsūtīšanas, juridiskos un finanšu pakalpojumus).</p>
  <p>8.4. Mēs varam nodot tavus personas datus rūpīgi izvēlētām trešajām personām, lai īstenotu kopīgus vai neatkarīgus tiešās reklāmas un marketinga pasākumus. Tavu datu nodošana šādiem nolūkiem var notikt tikai tad, ja trešās personas plānotie mērķi ir saderīgi ar mūsu mērķiem, kas aprakstīti šajā privātuma politikā.</p>
  <p>8.5. Tavi personas dati var tikt nodoti trešajai personai, ja notiek SIA 'Draxo Games' kā uzņēmuma apvienošana, iegūšana, nodošana vai uzņēmuma pamatlīdzekļu pārdošana citam pakalpojumu sniedzējam.</p>
  <p>8.6. Tavi personas dati var arī tikt nodoti citām personām vai apstrādāti citos gadījumos, ja esam saņēmuši tavu piekrišanu.</p>
  <p>8.7. Mēs varam apstrādāt nepersonalizētu informāciju (piemēram, par Portāla lietotāju skaitu).</p>

  <p class="second-level-bulletpoints"><b>9. Personas datu pārvaldīšana</b></p>
  <p>9.1. Tev ir tiesības pašam labot sevis norādīto informāciju Portāla sadaļā "Profils", kā arī tev ir tiesības dzēst savu profilu.</p>
  <p>9.2. Ciktāl mēs apstrādājam tavus personas datus, pamatojoties uz piekrišanu, tev ir tiesības jebkurā laikā atsaukt savu piekrišanu personas datu apstrādei.</p>
  <p>9.3. Tev ir tiesības prasīt mūsu apstiprinājumu, vai tiek apstrādāti tavi personas dati, un, ja tiek apstrādāti, saņemt informāciju par saviem personas datiem, ko esi mums sniedzis, pamatojoties uz savu piekrišanu vai līgumu, un ja apstrādāšana tiek veikta automatizēti.</p>
  <p>9.4. Tev ir tiesības iebilst pret savu personas datu apstrādi tiešā mārketinga nolūkiem. Tu vari atrakstīties no tiešā mārketinga, nosūtot mums ziņu uz e-pasta adresi info@draxogames.com vai nospiežot uz saites "atteikties" mūsu e-pasta vēstulēs.</p>
  <p>9.5. Tev ir tiesības iebilst pret personas datu apstrādi, ko mēs veicam, pamatojoties uz mūsu leģitīmajām interesēm, tomēr mēs turpināsim apstrādāt personas datus pat tad, ja būsi iebildis pret to, ja mums būs likumīgi iemesli turpināt datu apstrādi.</p>
  <p>9.6. Tev ir tiesības jebkurā brīdī dzēst savu profilu. Ja profils tiek dzēsts, tad tiks dzēsta arī ar to saistītā informācija.</p>
  <p>9.7. Tev ir tiesības iesniegt sūdzību par tavu personas datu apstrādi uzraudzības iestādē (Datu valsts inspekcijā).</p>

  <p class="second-level-bulletpoints"><b>10. Personas datu apstrādāšanas termiņš</b></p>
  <p>10.1. Mēs varam apstrādāt tavus personas datus tik ilgi, kamēr tas nepieciešams saskaņā ar attiecīgo apstrādes nolūku. Piemēram, ja datu apstrāde pamatojas uz tavu piekrišanu, un turpmākai apstrādei nav cita likumīga pamata, tad tavi personas dati tiks dzēsti pēc tava pieprasījuma. Ja datu apstrādes pamats ir līguma izpilde, tad mēs apstrādāsim datus, kamēr mums tas ir pamatoti nepieciešams likumisko un līgumisko tiesību un pienākumu izpildei un nodrošināšanai.</p>
  <p>10.2. Pēc profila, datu un/vai informācijas dzēšanas tā uzreiz vairs nebūs redzama citiem lietotājiem, taču minētais profils, dati un/vai informācija mūsu datu bāzēs var tikt saglabāta līdz 90 dienām no dzēšanas brīža, lai izvairītos no krāpnieciskām darbībām un citas pretlikumīgas rīcības.</p>
  <p>10.3. Papildus norādītajam laikam mēs varam apstrādāt tavus personas datus, piemēram, ja to paredz normatīvie akti, vai nodokļu un juridisko prasību un tiesību nodrošināšanai, vai arī īpašos gadījumos, kamēr vien tas ir attaisnoti nepieciešams (piemēram, likuma prasību izpildei).</p>
  <p>10.4. Kad nepieciešamais datu apstrādes laiks būs beidzies, mēs izdzēsīsim tavus personas datus vai padarīsim tos neidentificējamus, lai tos vairs nevar saistīt ar tevi.</p>

  <p class="second-level-bulletpoints"><b>11. Sīkdatnes</b></p>
  <p>11.1. Izmantojot Portāla tīmekļa vietni, jūs piekrītat, ka jūsu datorā vai citās ierīcēs, no kurām piekļūstat mūsu tīmekļa vietnei, var tikt izvietotas sīkdatnes, tīmekļa bāksignāli un tamlīdzīgas uzglabāšanas tehnoloģijas. Šajos sīkdatņu noteikumos aprakstīts, kādas sīkdatnes mēs izmantojam mūsu tīmekļa vietnē un kādiem nolūkiem.</p>

  <p>11.2. Sīkdatnes ir sīkas teksta datnes, kuras rada tīmekļa vietne un kuras tiek uzglabātas jūsu ierīcē, jums apmeklējot mūsu mājas lapu. Jūsu izmantotā pārlūkprogramma izmanto sīkdatnes, lai nosūtītu informāciju atpakaļ uz tīmekļa vietni katrā nākamajā apmeklējuma reizē, lai tīmekļa vietne varētu atpazīt lietotāju un atcerēties lietotāja izvēles (piemēram, autorizēšanās informāciju un citus iestatījumus). Tādā veidā jūsu nākamais apmeklējums būs vieglāks un vietne jums noderīgāka.</p>
  <p>11.3. Mūsu tīmekļa vietnes darbībā mēs izmantojam dažāda veida sīkdatnes. Turpmāk norādītās sīkdatnes var tikt uzglabātas jūsu pārlūkprogrammā.</p>
  <p>11.3.1. Obligātās sīkdatnes. Šīs sīkdatnes ir svarīgas, jo nepieciešamas netraucētas tīmekļa vietnes darbības nodrošināšanai. Bez šīm sīkdatnēm nav iespējams nodrošināt savienojumu ar lietotāju profilu un pilnā apmērā izmantot tīmekļa vietni. Šādas sīkdatnes identificē lietotāja ierīci, taču neatklāj lietotāja identitāti un nevāc lietotāja informāciju. Šīs sīkdatnes tiek uzglabātas lietotāja ierīcē līdz pārlūkprogrammas aizvēršanai.</p>
  <p>11.3.2. Funkcionālās sīkdatnes. Šādas sīkdatnes nodrošina ērtu un pilnīgu mūsu tīmekļa vietnes lietošanu, kā arī palīdz lietotājiem efektīvi izmantot mūsu tīmekļa vietni un personalizēt to. Funkcionālās sīkdatnes saglabā lietotāja izvēles un nodrošina atsevišķas funkcijas. Piemēram, lai nodrošinātu "Atcerēties mani" funkcionalitāti, kas ļauj lietotājam ievadīt savu e-pastu un paroli tikai vienu reizi. Šīs sīkdatnes tiek pastāvīgi saglabātas lietotāja ierīcē.</p>
  <p>11.3.3. Analīzes sīkdatnes. Šādas sīkdatnes vāc informāciju par lietotāju mijiedarbību ar mūsu tīmekļa vietni, piemēram, kuras sadaļas visbiežāk tiek apmeklētas un kuri pakalpojumi visbiežāk tiek izmantoti. Ievāktā informācija tiek izmantota analīzes nolūkos, lai saprastu, kas lietotājus interesē un kā padarīt tīmekļa vietni lietotājam draudzīgāku. Analīzes nolūkos mēs varam izmantot trešo personu sīkdatnes. Šīs sīkdatnes tiek pastāvīgi saglabātas lietotāja ierīcē.</p>
  <p>11.3.4. Mērķorientētas sīkdatnes. Šīs sīkdatnes tiek izmantotas mūsu tīmekļa vietnē, lai individuāli jums un jūsu interesēm pielāgotu mārketingu un nākotnē sniegtu jums personalizētus pakalpojumus. Šīs sīkdatnes atceras, ka jūs apmeklējāt mūsu tīmekļa vietni, un var tikt izmantotas, lai parādītu jums sociālajos medijos un citās vietnēs personalizētu reklāmu. Mērķorientētas reklāmas nolūkos mēs varam izmantot trešo personu sīkdatnes. Šīs sīkdatnes tiek pastāvīgi saglabātas lietotāja ierīcē.</p>
  <p>11.3.5. Trešo personu sīkdatnes. Mūsu tīmekļa vietne izmanto trešo personu pakalpojumus, piemēram, analīzes pakalpojumus, lai mēs zinātu, kas mūsu tīmekļa vietnē ir populārs un kas ne, tādējādi padarot mūsu tīmekļa vietni lietojamāku. Mēs nekontrolējam trešo personu sīkdatnes un vairāk informācijas par šādām sīkdatnēm un to privātuma noteikumus varat iegūt, apmeklējot attiecīgo trešo personu tīmekļa vietnes. Jebkādu no trešo personu sīkdatnēm iegūtu informāciju apstrādā attiecīgais pakalpojuma sniedzējs. Jūs jebkurā laikā varat izvēlēties atteikties no trešo personu sīkdatņu veiktas apstrādes.</p>
  <p>11.4 Apmeklējot mūsu vietni, jums tiek parādīts informatīvais paziņojums, ka tīmekļa vietne izmanto sīkdatnes. Ja vēlaties atsaukt savu piekrišanu saglabāt jūsu ierīcē sīkdatnes, varat izdzēst visas pārlūkprogrammā saglabātās sīkdatnes un iestatīt pārlūkprogrammu bloķēt saglabātās sīkdatnes. Noklikšķinot uz pogas "palīdzība" jūsu pārlūkprogrammā, jūs varat atrast norādes, kā neļaut pārlūkprogrammai saglabāt sīkdatnes, kā arī kādas sīkdatnes jau ir saglabātas un, ja vēlaties, dzēst tās. Izmaiņas iestatījumos veicamas katrai jūsu izmantotajai pārlūkprogrammai un katrai ierīcei atsevišķi. Tomēr, lūdzu, ņemiet vērā, ka, nesaglabājot dažas sīkdatnes, iespējams, jūs nevarēsiet pilnībā izmantot visas mūsu tīmekļa vietnes funkcijas un pakalpojumus.</p>

  <p class="second-level-bulletpoints"><b>12. Izmaiņas</b></p>
  <p>Mums ir tiesības laiku pa laikam mainīt šos noteikumus. Visas noteikumu izmaiņas tiks ievietotas Portālā, un būtisku izmaiņu gadījumā mēs tevi informēsim par šādām izmaiņām.</p>

  <p class="second-level-bulletpoints"><b>13. Saziņa ar mums</b></p>
  <p>Tev ir iespējams sazināties ar mums par datu apstrādes un aizsardzības jautājumiem, nosūtot e-pasta vēstuli uz info@draxogames.com vai sūtot vēstuli SIA 'Draxo Games' datu aizsardzības speciālistam Kuldīgā, Liepājas ielā 49-6, LV-3301.</p>

  <p class="mb-1">Pēdējais atjauninājums: 01.03.2022.</p>`,
  en: `<p class="second-level-bulletpoints"><b>1. Terms of Use</b></p>
  <p>1.1. These Terms (from now on The Terms) determine the procedure for using the portal Spelezoli.lv (from now on The Portal). The Terms are binding on all persons who use the Portal, register on it, and/or play any of the online games offered on the Portal.</p>
  <p>1.2. The portal is maintained and administered by SIA 'Draxo Games', reg. No. 40103927834, legal address: Liepājas Street 49 - 6, Kuldīga, Kuldīgas nov., LV-3301, e-mail address: info@draxogames.com (from now on - 'Draxo Games', 'we', 'us').</p>
  <p>1.3. By confirming these Terms or registering their profile on the Portal and/or participating in any of the games, the user agrees to these Terms of Use and other binding terms published on the Portal.</p>
  <p>1.4. In order to play any of the offered online games, the user needs to create their profile on the Portal, providing the required information. It is also possible to log in and enter the Portal with one of the previously created Facebook.com, Draugiem.lv, Google, Apple, or Inbox.lv accounts.</p>

  <p class="second-level-bulletpoints"><b>2. Users of the portal are prohibited from:</b></p>
  <p>2.1. using Draugiem.lv, Facebook.com or Inbox.lv user profile, which has been created or is used contrary to the terms of use of social networks;</p>
  <p>2.2. disclosing the details of your game to your opponents in any way, as well as to play with any of the players in order to obtain a result favorable to a particular player;</p>
  <p>2.3. using uncensored, defamatory content in the game profile, game chat window, or correspondence with players and/or the game administration in any language and manner, and in any way unreasonably insult the players or the game administration;</p>
  <p>2.4. taking any action that interferes with the full use of the game by other players;</p>
  <p>2.5. performing any kind of activities related to the advertising of third-party products or services on the Portal without prior approval of the Portal administration.</p>

  <p class="second-level-bulletpoints"><b>3. Portal administration</b></p>
  <p>3.1. The task of the Portal administration is to provide full access to the games for the users of the Portal, as well as to respond to the player's reports submitted in one of the available means of communication.</p>
  <p>3.2. You can contact the administration via the built-in chat, social networking contact form (if any), or by writing to info@draxogames.com.</p>
  <p>3.3. In case of violation of the rules, the administration of the Portal reserves the right to block the player's access to the game and/or the Portal without prior notice.</p>
  <p>3.4. In the event of a player being blocked, the virtual money used in the games will not be reimbursed in any way.</p>

  <p class="second-level-bulletpoints"><b>4. Paid services</b></p>
  <p>4.1. In the game of the portal it is possible to buy virtual money or some special Premium services.</p>
  <p>4.2. If the user chooses a paid service, then the paid services offered on the Portal can be received after the prepayment for the respective service has been made.</p>
  <p>4.3. All prices are in euros and include value-added tax.</p>
  <p>4.4. You can pay for the virtual money and services purchased in the game using one of the available payment methods.</p>
  <p>4.5. The user may make a payment only from his own payment card or account; it is strictly prohibited to use a payment instrument belonging to a 3rd person without prior coordination with that person.</p>
  <p>4.6. Order processing is usually instant, but sometimes it can take up to an hour. If you have any difficulties, please contact us <a href="/contacts" target="_blank">here</a>.</p>
  <p>4.7. Processing of the purchase and delivery of the goods is free of charge and takes place immediately after the purchase, therefore the return of the goods is not possible.</p>

  <p class="second-level-bulletpoints"><b>5. Privacy</b></p>
  <p>5.1. To play any of the games offered on the Portal, you must register and create your profile.</p>
  <p>5.2. You can create a profile by filling in the required information and registering, or by logging in with a social networking profile.</p>
  <p>5.3. When a payment is made, we process the information associated with such transactions.</p>
  <p>5.4. We receive information about the devices you use to access the Site (such as the type of device, operating system, web browser, mobile operator, IP address, connection time, and duration).</p>
  <p>5.5. We receive information from third-party websites and mobile applications that you use when you log in to the Portal (for example, authorize with Draugiem.lv or Facebook passport).</p>
  <p>5.6. We use cookies.</p>

  <p class="second-level-bulletpoints"><b>6. User data is used to:</b></p>
  <p>6.1. provide the user with an opportunity to receive the services offered on the Portal;</p>
  <p>6.2. maintain and improve the Portal, as well as develop new services;</p>
  <p>6.3. offer and personalize advertising, as well as evaluate the operation of the Portal and the effectiveness of advertising;</p>
  <p>6.4. verify the identity and prevent the illegal use of profiles, as well as ensure a secure environment of the Portal;</p>
  <p>6.5. protect the interests of the user, us, and other users (for example, if the Portal is used to perform illegal activities);</p>
  <p>6.6. provide answers to the user and contact him with questions of interest or to inform about changes in the rules;</p>
  <p>6.7. send newsletters and advertisements.</p>

  <p class="second-level-bulletpoints"><b>7. User data processing</b></p>
  <p>7.1. The user's personal data will be processed in accordance with applicable law. Depending on the situation, the processing of data may be based on consent (eg receiving news), fulfilling contractual obligations (eg purchasing and paying for virtual services), our legal obligation (eg we must process your personal data in accordance with legal requirements), our legitimate interests (for example, to promote the popularity and development of the Portal in order to investigate possible illegal actions).</p>
  <p>7.2. We process personal data received directly from you, such as from websites (marketing advertisements, newsletters, contests, lotteries and surveys, feedback), from and about the equipment you use when you visit the Site, from customer service information, by telephone, e-mail or other communication channels.</p>

  <p class="second-level-bulletpoints"><b>8. Transfer of personal data</b></p>
  <p>8.1. We will not sell, rent or otherwise disclose your personal information to third parties unless specifically stated below.</p>
  <p>8.2. We may transfer your personal data to third parties who provide services to us in accordance with the purposes described in this Privacy Policy and within the applicable law. Data may be transferred to such third parties only to the minimum extent necessary for us to provide our services, and only if they have entered into appropriate confidentiality obligations.</p>
  <p>8.3. Your personal data may be transferred to third parties with whom we interact and provide services to us so that we can ensure and improve our operations as a company and also the Portal (eg data hosting and maintenance, data and portal security, payment processing, risk and fraud prevention and detection, data analysis, texting and e-mailing, legal and financial services).</p>
  <p>8.4. We may transfer your personal data to carefully selected third parties for joint or independent direct advertising and marketing activities. The transfer of your data for such purposes may only take place if the purposes intended by the third party are compatible with our purposes described in this Privacy Policy.</p>
  <p>8.5. Your personal data may be transferred to a third party if SIA 'Draxo Games' mergers, acquires, transfers, or sells the company's fixed assets to another service provider.</p>
  <p>8.6. Your personal data may also be transferred or processed in other cases if we have received your consent.</p>
  <p>8.7. We may process non-personal information (such as the number of users of the Site).</p>

  <p class="second-level-bulletpoints"><b>9. Management of personal data</b></p>
  <p>9.1. You have the right to edit the information provided by you in the "Profile" section of the Portal, as well as have the right to delete your profile.</p>
  <p>9.2. To the extent that we process your personal data on the basis of consent, you have the right to withdraw your consent to the processing of personal data at any time.</p>
  <p>9.3. You have the right to request our confirmation that your personal data is being processed and, if it is being processed, to receive information about your personal data that you have provided to us on the basis of your consent or agreement and that the processing is automated.</p>
  <p>9.4. You have the right to object to the processing of your personal data for direct marketing purposes. You can unsubscribe from direct marketing by sending us a message at info@draxogames.com or by clicking the "unsubscribe" link in our emails.</p>
  <p>9.5. You have the right to object against the processing of personal data that we carry out on the basis of our legitimate interests, however, we will continue to process personal data even if you object to it if we have legitimate reasons to continue processing the data.</p>
  <p>9.6. You have the right to delete your profile at any time. If the profile is deleted, the information associated with it will also be deleted.</p>
  <p>9.7. You have the right to lodge a complaint about the processing of your personal data with the supervisory authority (Data State Inspectorate).</p>

  <p class="second-level-bulletpoints"><b>10. Term of personal data processing</b></p>
  <p>10.1. We may process your personal data for as long as necessary for the purpose of the processing. For example, if the processing of data is based on your consent and there is no other legal basis for further processing, then your personal data will be deleted at your request. If the processing is based on the performance of a contract, we will process the data for as long as we reasonably need to fulfill and enforce our legal and contractual rights and obligations.</p>
  <p>10.2. After deleting a profile, data, and/or information, it will no longer be visible to other users immediately, but that profile, data, and/or information may be stored in our databases for up to 90 days from the date of deletion to prevent fraud and other illegal activities.</p>
  <p>10.3. In addition to the time specified, we may process your personal data, for example, where required by law, or to secure tax and legal requirements and rights, or in special cases for as long as is reasonably necessary (for example, to comply with legal requirements).</p>
  <p>10.4. When the required processing time has elapsed, we will delete your personal data or make it unidentifiable so that it can no longer be associated with you.</p>

  <p class="second-level-bulletpoints"><b>11. Cookies</b></p>
  <p>11.1. By using the Website, you agree that cookies, web beacons, and similar storage technologies may be placed on your computer or other devices from which you access our website. These cookie policies describe what cookies we use on our website and for what purposes.</p>

  <p>11.2. Cookies are small text files created by a website that is stored on your device when you visit our website. The browser you use uses cookies to send information back to the website each time you visit so that the website can recognize the user and remember the user's choices (such as login information and other settings). This will make your next visit easier and the site more useful to you.</p>
  <p>11.3. We use various types of cookies in the operation of our website. The following cookies may be stored in your browser.</p>
  <p>11.3.1. Required cookies. These cookies are important because they are necessary for the smooth operation of the website. Without these cookies, it is not possible to connect to a user profile and make full use of the website. Such cookies identify the user's device but do not reveal the user's identity or collect user information. These cookies are stored on the user's device until the browser is closed.</p>
  <p>11.3.2. Functional cookies. Such cookies ensure the easy and complete use of our website, as well as help users to use and personalize our website efficiently. Functional cookies store user preferences and provide certain functions. For example, to provide a "Remember Me" functionality that allows the user to enter their email and password only once. These cookies are stored permanently on the user's device.</p>
  <p>11.3.3. Analysis cookies. Such cookies collect information about user interactions with our website, such as which sections are most frequently visited and which services are most frequently used. The information collected is used for analytical purposes to understand what users are interested in and how to make the website more user-friendly. We may use third-party cookies for analysis purposes. These cookies are stored permanently on the user's device.</p>
  <p>11.3.4. Targeted cookies. These cookies are used on our website to tailor marketing to you and your interests and to provide you with personalized services in the future. These cookies remember that you visited our website and may be used to show you personalized ads on social media and other sites. We may use third-party cookies for the purpose of targeted advertising. These cookies are stored permanently on the user's device.</p>
  <p>11.3.5. Third-party cookies. Our website uses third-party services, such as analytics, to let us know what's popular and what's not, making our website more usable. We do not control third-party cookies and you can obtain more information about such cookies and their privacy policy by visiting the websites of such third parties. Any information obtained from third-party cookies is processed by the respective service provider. You may opt-out of third-party cookie processing at any time.</p>
  <p>11.4 When you visit our website, you will be notified that the website uses cookies. If you wish to revoke your consent to the storage of cookies on your device, you can delete all cookies stored in your browser and set your browser to block cookies. By clicking the "help" button in your browser, you can find instructions on how to prevent your browser from storing cookies, as well as what cookies have already been stored and, if you wish, delete them. Changes to settings are made for each browser you use and for each device. However, please note that without storing some cookies, you may not be able to take full advantage of all the features and services of our website.</p>

  <p class="second-level-bulletpoints"><b>12. Changes</b></p>
  <p>We reserve the right to change these terms from time to time. All changes to the rules will be posted on the Portal, and in the event of significant changes, we will notify you of such changes.</p>

  <p class="second-level-bulletpoints"><b>13. Contact us</b></p>
  <p>You can contact us about data processing and protection issues by sending an e-mail to info@draxogames.com or by sending a letter to SIA 'Draxo Games' data protection specialist in Kuldiga, Liepājas Street 49-6, LV-3301.</p>

  <p class="mb-1">Last update: 01.03.2022</p`,
  ru: `<p class="second-level-bulletpoints"><b>1. Условия Использования</b></p>
  <p>1.1. Настоящие Условия (далее – Условия) определяют порядок использования портала Spelezoli.lv (далее – Портал). Условия являются обязательными для всех лиц, которые используют Портал, регистрируются на нем и/или играют в любую из онлайн-игр, предлагаемых на Портале.</p>
  <p>1.2. Портал поддерживается и управляется SIA 'Draxo Games', рег. Нет. 40103927834, юридический адрес: ул. Лиепаяс 49 - 6, Кулдига, Кулдигас нов., LV-3301, адрес электронной почты: info@draxogames.com (далее – «Draxo Games», «мы», «нам»).</p>
  <p>1.3. Подтверждая настоящие Условия или регистрируя свой профиль на Портале и/или участвуя в любой из игр, пользователь соглашается с настоящими Условиями использования и другими обязательными условиями, опубликованными на Портале.</p>
  <p>1.4. Чтобы играть в любую из предлагаемых онлайн-игр, пользователю необходимо создать свой профиль на Портале, предоставив необходимую информацию. Также можно войти и войти на Портал с одной из ранее созданных учетных записей Facebook.com, Draugiem.lv, Google, Apple или Inbox.lv.</p>

  <p class="second-level-bulletpoints"><b>2. Пользователям портала запрещается:</b></p>
  <p>2.1. использовать профиль пользователя Draugiem.lv, Facebook.com или Inbox.lv, созданный или используемый вопреки условиям использования социальных сетей;</p>
  <p>2.2. каким-либо образом раскрывать подробности своей игры своим оппонентам, а также играть с любым из игроков с целью получения результата, благоприятного для конкретного игрока;</p>
  <p>2.3. использовать в профиле игры, окне игрового чата или в переписке с игроками и/или администрацией игры нецензурный, дискредитирующий контент на любом языке и в любой форме, а также любым образом необоснованно оскорблять игроков или администрацию игры;</p>
  <p>2.4. предпринимать любые действия, мешающие полноценному использованию игры другими игроками;</p>
  <p>2.5. Совершать любые действия, связанные с рекламой товаров или услуг третьих лиц на Портале без предварительного согласования с администрацией Портала.</p>

  <p class="second-level-bulletpoints"><b>3. Администрация портала</b></p>
  <p>3.1. Задачей администрации Портала является обеспечение полного доступа к играм для пользователей Портала, а также реагирование на жалобы игрока, поданные в одном из доступных средств связи.</p>
  <p>3.2. Связаться с администрацией можно через встроенный чат, форму обратной связи в социальных сетях (если есть) или написав на адрес info@draxogames.com.</p>
  <p>3.3. В случае нарушения правил администрация Портала оставляет за собой право заблокировать доступ игрока к игре и/или Порталу без предварительного уведомления.</p>
  <p>3.4. В случае блокировки игрока виртуальные деньги, использованные в играх, никаким образом не компенсируются.</p>

  <p class="second-level-bulletpoints"><b>4. Платные услуги</b></p>
  <p>4.1. В игре портала можно купить виртуальные деньги или какие-то специальные Премиум-услуги.</p>
  <p>4.2. Если пользователь выбирает платную услугу, то предлагаемые на Портале платные услуги могут быть получены после внесения предоплаты за соответствующую услугу.</p>
  <p>4.3. Все цены указаны в евро и включают налог на добавленную стоимость.</p>
  <p>4.4. Оплатить приобретенные в игре виртуальные деньги и услуги можно одним из доступных способов оплаты.</p>
  <p>4.5. Пользователь может совершать платежи только со своей платежной карты или счета; Категорически запрещается использовать платежный инструмент, принадлежащий третьему лицу, без предварительного согласования с этим лицом.</p>
  <p>4.6. Обработка заказа обычно происходит мгновенно, но иногда может занять до часа. Если у вас возникли трудности, пожалуйста, свяжитесь с нами <a href="/contacts" target="_blank">здесь</a>.</p>
  <p>4.7. Обработка покупки и доставка товара осуществляется бесплатно и происходит сразу после покупки, поэтому возврат товара невозможен.</p>

  <p class="second-level-bulletpoints"><b>5. Конфиденциальность</b></p>
  <p>5.1. Чтобы играть в любую из игр, предлагаемых на Портале, вам необходимо зарегистрироваться и создать свой профиль.</p>
  <p>5.2. Вы можете создать профиль, заполнив необходимую информацию и зарегистрировавшись, или войдя в систему с помощью профиля в социальной сети.</p>
  <p>5.3. При совершении платежа мы обрабатываем информацию, связанную с такими транзакциями.</p>
  <p>5.4. Мы получаем информацию об устройствах, которые вы используете для доступа к Сайту (например, тип устройства, операционная система, веб-браузер, оператор мобильной связи, IP-адрес, время и продолжительность подключения).</p>
  <p>5.5. Мы получаем информацию со сторонних веб-сайтов и мобильных приложений, которые вы используете при входе на Портал (например, авторизуетесь с помощью Draugiem.lv или паспорта Facebook).</p>
  <p>5.6. Мы используем файлы ''cookies''.</p>

  <p class="second-level-bulletpoints"><b>6. Пользовательские данные используются для:</b></p>
  <p>6.1. предоставлять пользователю возможность получать услуги, предлагаемые на Портале;</p>
  <p>6.2. поддерживать и улучшать Портал, а также разрабатывать новые услуги;</p>
  <p>6.3. предлагать и персонализировать рекламу, а также оценивать работу Портала и эффективность рекламы;</p>
  <p>6.4. проверять личность и предотвращать незаконное использование профилей, а также обеспечивать безопасную среду Портала;</p>
  <p>6.5. защищать интересы пользователя, нас и других пользователей (например, если Портал используется для совершения незаконных действий);</p>
  <p>6.6. предоставлять ответы пользователю и обращаться к нему с интересующими вопросами или для информирования об изменениях в правилах;</p>
  <p>6.7. отправлять информационные бюллетени и рекламу.</p>

  <p class="second-level-bulletpoints"><b>7. Обработка пользовательских данных</b></p>
  <p>7.1. Персональные данные пользователя будут обрабатываться в соответствии с действующим законодательством. В зависимости от ситуации обработка данных может основываться на согласии (например, получение новостей), выполнении договорных обязательств (например, Пополнить и оплата виртуальных услуг), нашем правовом обязательстве (например, мы должны обрабатывать ваши персональные данные в соответствии с требованиями законодательства) , наши законные интересы (например, способствовать популяризации и развитию Портала с целью расследования возможных незаконных действий).</p>
  <p>7.2. Мы обрабатываем персональные данные, полученные непосредственно от вас, например, с веб-сайтов (рекламные объявления, информационные бюллетени, конкурсы, лотереи и опросы, отзывы), с оборудования, которое вы используете при посещении Сайта, из информации о службе поддержки клиентов, по телефону, электронной -почта или другие каналы связи.</p>

  <p class="second-level-bulletpoints"><b>8. Передача персональных данных</b></p>
  <p>8.1. Мы не будем продавать, сдавать в аренду или иным образом раскрывать вашу личную информацию третьим лицам, если иное не указано ниже.</p>
  <p>8.2. Мы можем передавать ваши персональные данные третьим лицам, которые предоставляют нам услуги в соответствии с целями, описанными в настоящей Политике конфиденциальности, и в рамках применимого законодательства. Данные могут быть переданы таким третьим сторонам только в минимальном и необходимом объеме, необходимом нам для предоставления наших услуг, и только если они взяли на себя соответствующие обязательства по конфиденциальности.</p>
  <p>8.3. Ваши личные данные могут быть переданы третьим лицам, с которыми мы взаимодействуем и предоставляем нам услуги, чтобы мы могли обеспечить и улучшить нашу деятельность как компании, а также Портала (например, размещение и обслуживание данных, безопасность данных и портала, обработка платежей, риск предотвращение и обнаружение мошенничества, анализ данных, отправка текстовых и электронных сообщений, юридические и финансовые услуги).</p>
  <p>8.4. Мы можем передавать ваши персональные данные тщательно отобранным третьим сторонам для совместной или независимой прямой рекламы и маркетинговой деятельности. Передача ваших данных для таких целей может иметь место только в том случае, если цели, намеченные третьей стороной, совместимы с нашими целями, описанными в настоящей Политике конфиденциальности.</p>
  <p>8.5. Ваши личные данные могут быть переданы третьему лицу, если SIA «Draxo Games» объединяет, приобретает, передает или продает основные средства компании другому поставщику услуг.</p>
  <p>8.6. Ваши личные данные также могут быть переданы или обработаны в других случаях, если мы получили ваше согласие.</p>
  <p>8.7. Мы можем обрабатывать неличную информацию (например, количество пользователей Сайта).</p>

  <p class="second-level-bulletpoints"><b>9. Управление персональными данными</b></p>
  <p>9.1. Вы имеете право редактировать предоставленную вами информацию в разделе «Профиль» Портала, а также имеете право удалить свой профиль.</p>
  <p>9.2. В той мере, в какой мы обрабатываем ваши персональные данные на основании согласия, вы имеете право отозвать свое согласие на обработку персональных данных в любое время.</p>
  <p>9.3. Вы имеете право запросить у нас подтверждение того, что ваши персональные данные обрабатываются и, если они обрабатываются, получить информацию о ваших персональных данных, которые вы предоставили нам на основании вашего согласия или соглашения, и что обработка автоматизирована .</p>
  <p>9.4. Вы имеете право возражать против обработки ваших персональных данных в целях прямого маркетинга. Вы можете отказаться от подписки на прямой маркетинг, отправив нам сообщение по адресу info@draxogames.com или щелкнув ссылку «отписаться» в наших электронных письмах.</p>
  <p>9.5. Вы имеете право возразить против обработки персональных данных, которую мы осуществляем на основании наших законных интересов, однако мы будем продолжать обрабатывать персональные данные, даже если вы возражаете против этого, если у нас есть законные основания для продолжения обработки данных.</p>
  <p>9.6. Вы имеете право удалить свой профиль в любое время. Если профиль будет удален, информация, связанная с ним, также будет удалена.</p>
  <p>9.7. Вы имеете право подать жалобу на обработку ваших персональных данных в надзорный орган (Государственную инспекцию данных).</p>

  <p class="second-level-bulletpoints"><b>10. Срок обработки персональных данных</b></p>
  <p>10.1. Мы можем обрабатывать ваши персональные данные столько времени, сколько необходимо для целей обработки. Например, если обработка данных основана на вашем согласии и нет другого законного основания для дальнейшей обработки, то ваши персональные данные будут удалены по вашему запросу. Если обработка основана на выполнении контракта, мы будем обрабатывать данные до тех пор, пока нам это разумно необходимо для выполнения и обеспечения соблюдения наших юридических и договорных прав и обязательств.</p>
  <p>10.2. После удаления профиля, данных и/или информации они больше не будут сразу видны другим пользователям, но этот профиль, данные и/или информация могут храниться в наших базах данных до 90 дней с даты удаления для предотвращения мошенничества или других незаконных действий.</p>
  <p>10.3. В дополнение к указанному времени мы можем обрабатывать ваши персональные данные, например, когда это требуется по закону, или для обеспечения налоговых и юридических требований и прав, или в особых случаях до тех пор, пока это разумно необходимо (например, для соблюдения правовые требования).</p>
  <p>10.4. По истечении необходимого времени обработки мы удалим ваши личные данные или сделаем их неидентифицируемыми, чтобы они больше не могли быть связаны с вами.</p>

  <p class="second-level-bulletpoints"><b>11. Файлы ''cookies''</b></p>
  <p>11.1. Используя Веб-сайт, вы соглашаетесь с тем, что файлы ''cookies'', веб-маяки и аналогичные технологии хранения могут размещаться на вашем компьютере или других устройствах, с которых вы заходите на наш веб-сайт. Эти политики использования файлов ''cookies'' описывают, какие файлы мы используем на нашем веб-сайте и для каких целей.</p>
  <p>11.2. Файлы ''cookies'' — это небольшие текстовые файлы, созданные веб-сайтом, которые сохраняются на вашем устройстве, когда вы посещаете наш веб-сайт. Браузер, который вы используете, использует файлы для отправки информации обратно на веб-сайт при каждом посещении, чтобы веб-сайт мог распознавать пользователя и запоминать выбор пользователя (например, данные для входа и другие настройки). Это облегчит ваш следующий визит и сделает сайт более полезным для вас.</p>
  <p>11.3. Мы используем различные типы файлов в работе нашего веб-сайта. В вашем браузере могут храниться следующие файлы ''cookies''.</p>
  <p>11.3.1. Требуемые файлы. Эти файлы важны, поскольку они необходимы для бесперебойной работы веб-сайта. Без этих файлов ''cookies'' невозможно подключиться к профилю пользователя и в полной мере использовать веб-сайт. Такие файлы идентифицируют устройство пользователя, но не раскрывают личность пользователя и не собирают информацию о пользователе. Эти файлы хранятся на устройстве пользователя до закрытия браузера.</p>
  <p>11.3.2. Функциональные файлы. Такие файлы обеспечивают простое и полное использование нашего веб-сайта, а также помогают пользователям эффективно использовать и персонализировать наш веб-сайт. Функциональные файлы сохраняют пользовательские настройки и обеспечивают определенные функции. Например, чтобы предоставить функцию «Запомнить меня», которая позволяет пользователю вводить свой адрес электронной почты и пароль только один раз. Эти файлы постоянно хранятся на устройстве пользователя.</p>
  <p>11.3.3. Файлы "cookies" для анализа. Такие файлы собирают информацию о взаимодействии пользователей с нашим веб-сайтом, например, какие разделы посещаются чаще всего и какие службы чаще всего используются. Собранная информация используется в аналитических целях, чтобы понять, что интересует пользователей и как сделать сайт более удобным для пользователей. Мы можем использовать сторонние файлы в целях анализа. Эти файлы постоянно хранятся на устройстве пользователя.</p>
  <p>11.3.4. Целевые файлы "cookies". Эти файлы используются на нашем веб-сайте для адаптации маркетинга к вам и вашим интересам, а также для предоставления вам персонализированных услуг в будущем. Эти файлы запоминают, что вы посещали наш веб-сайт, и могут использоваться для показа вам персонализированной рекламы в социальных сетях и на других сайтах. Мы можем использовать сторонние файлы для целевой рекламы. Эти файлы постоянно хранятся на устройстве пользователя.</p>
  <p>11.3.5. Файлы третьих лиц. Наш веб-сайт использует сторонние службы, такие как аналитика, чтобы мы знали, что популярно, а что нет, что делает наш веб-сайт более удобным для использования. Мы не контролируем сторонние файлы, и вы можете получить дополнительную информацию о таких файлах и их политике конфиденциальности, посетив веб-сайты таких третьих лиц. Любая информация, полученная из сторонних файлов, обрабатывается соответствующим поставщиком услуг. Вы можете в любое время отказаться от обработки сторонних файлов.</p>
  <p>11.4 При посещении нашего веб-сайта вы будете уведомлены о том, что веб-сайт использует файлы. Если вы хотите отозвать свое согласие на хранение файлов на вашем устройстве, вы можете удалить все файлы, хранящиеся в вашем браузере, и настроить браузер на блокировку файлов. Нажав кнопку «Помощь» в своем браузере, вы можете найти инструкции о том, как запретить браузеру сохранять файлы, а также какие файлы уже были сохранены и, при желании, удалить их. Изменения настроек вносятся для каждого используемого вами браузера и для каждого устройства. Однако обратите внимание, что без сохранения некоторых файлов вы не сможете в полной мере воспользоваться всеми функциями и услугами нашего веб-сайта.</p>

  <p class="second-level-bulletpoints"><b>12. Изменения</b></p>
  <p>Мы оставляем за собой право время от времени изменять эти условия. Все изменения правил будут размещены на Портале, и в случае внесения существенных изменений мы уведомим вас о таких изменениях.</p>

  <p class="second-level-bulletpoints"><b>13. Свяжитесь с нами</b></p>
  <p>Вы можете связаться с нами по вопросам обработки и защиты данных, отправив электронное письмо по адресу info@draxogames.com или отправив письмо специалисту по защите данных SIA 'Draxo Games' в Кулдиге, ул. Лиепаяс 49-6, LV-3301.</p>

  <p class="mb-1">Последнее обновление: 01.03.2022</p>`,
};
