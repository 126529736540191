import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import UserHistoryTable from './UserHistoryTable';
import { FORMAT_DATE_TYPE } from '../../../constants/constants';

class UserHistory extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    modalOpen: PropTypes.bool,
    history: PropTypes.arrayOf(PropTypes.shape({})),
    closeFunc: PropTypes.func.isRequired,
    fetchFunction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    type: null,
    modalOpen: false,
    history: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      fromDate: new Date(Date.now() - (1000 * 60 * 60 * 24 * 7)),
      toDate: new Date(),
    };

  }

  changeFromDate = (date) => {
    this.setState({ fromDate: date });
  }

  changeToDate = (date) => {
    this.setState({ toDate: date });
  }

  refetchHistory = () => {
    const { fetchFunction, userUid } = this.props;
    const { fromDate, toDate } = this.state;

    console.log(fromDate);
    console.log(toDate);

    console.log(Date.parse(fromDate));

    if (fromDate && toDate && Date.parse(fromDate) && Date.parse(toDate)) {
      fetchFunction(userUid, Date.parse(fromDate), Date.parse(toDate));
    }
  }

  render() {
    const { t, modalOpen, closeFunc, type, history } = this.props;
    const { fromDate, toDate } = this.state;

    console.log('history', { history });

    return (
        <Modal container={`div > div`} size="lg" isOpen={modalOpen} toggle={closeFunc}>
          <ModalHeader toggle={closeFunc}>
            {/* {type === 'balance' ? t('balanceHistory') : t('pointsHistory')} */}
            {type === 'balance' ? 'Balance History' : 'Points History'}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="user-history-table-filter-text">
                {/* {t('from')} */}
                From
              </Col>
              <Col sm="4" className="user-history-table-filter">
                <DateTimePicker
                  format={FORMAT_DATE_TYPE.pointDate}
                  culture="lv"
                  onChange={this.changeFromDate}
                  value={fromDate}
                  defaultValue={new Date()}
                />
              </Col>
              <Col className="user-history-table-filter-text">
                {/* {t('to')} */}
                To
              </Col>
              <Col sm="4" className="user-history-table-filter">
                <DateTimePicker
                  format={FORMAT_DATE_TYPE.pointDate}
                  culture="lv"
                  onChange={this.changeToDate}
                  value={toDate}
                  defaultValue={new Date()}
                />
              </Col>
              <Col sm="2">
                {/* <Button color="primary" onClick={this.refetchHistory}>{t('filter')}</Button> */}
                <Button color="primary" onClick={this.refetchHistory}>Filter</Button>
              </Col>
            </Row>
            <UserHistoryTable type={type} history={history}/>
          </ModalBody>
          <ModalFooter>
            {/* <Button color="secondary" onClick={closeFunc}>{t('close')}</Button> */}
            <Button color="secondary" onClick={closeFunc}>Close</Button>
          </ModalFooter>
        </Modal>
    );
  }
}

export default  withTranslation(['admin', 'common'])(UserHistory);
