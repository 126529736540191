import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Form,
  Label,
  Alert,
  Input,
  Button,
  FormGroup,
  Media,
} from 'reactstrap';

import ReactGA from 'react-ga';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Firebase, FirebaseRef } from '../../../../lib/firebase';
import classNames from 'classnames';

import { fbLoginPopup as checkFacebookLoginState } from '../../../../actions/member';
import fbLoginImg from '../../../../images/redesign/common/facebook.svg';

class Login extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    checkFacebookLogin: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    failFacebookAuth: PropTypes.func.isRequired,
    passFacebookAuth: PropTypes.func.isRequired,
    stopFacebookAuth: PropTypes.func.isRequired,
    runFacebookAuth: PropTypes.func.isRequired,
  }

  static defaultProps = {
    loading: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      FB: null,
    };

    this.FBLogin = this.FBLogin.bind(this);
  }

  componentDidMount() {

    if (window.FB) {
      this.setState({ FB: window.FB });
    } else {
      document.addEventListener('FBObjectReady', this.initializeFacebookLogin);
    }
  }

  componentDidUpdate() {
    console.log('componentDidUpdate login');
  }

  componentWillUnmount() {
    document.removeEventListener('FBObjectReady', this.initializeFacebookLogin);
  }

  // FB stuff

  initializeFacebookLogin = () => {
    this.setState({ FB: window.FB });
  }

  facebookLoginHandler = (response, fromLoginForm) => {
    const { i18n, checkFacebookLogin, history, passFacebookAuth, failFacebookAuth, stopFacebookAuth } = this.props;

    console.log('facebookLoginHandler', { checkFacebookLogin, response, fromLoginForm });

    checkFacebookLogin(response, i18n.language).then((res) => {
      if (res.data) {
        if (res.data.uid) {
          console.log('history.push');
          passFacebookAuth();
        } else {
          if (fromLoginForm) {
            failFacebookAuth();
          }
          stopFacebookAuth();
        }
      } else {
        if (fromLoginForm) {
            failFacebookAuth();
        }
        stopFacebookAuth();
      }
    }).catch((err) => {
      console.log(err);
      failFacebookAuth();
      stopFacebookAuth();
    });
  }


  FBLogin = (e) => {
    e.preventDefault();
    const { runFacebookAuth, loading } = this.props;
    const { FB } = this.state;

    console.log('FBLogin', { FB, loading });

    runFacebookAuth();

    if (!FB || loading) return;

    FB.getLoginStatus((response) => {
      console.log('FB res', { response });
      if (response.status === 'connected') {
        this.facebookLoginHandler(response, true);
      } else {
        FB.login((response2) => { console.log('FB res', { response2 }); this.facebookLoginHandler(response2, true); });
      }
    });
  }

  render() {
    const { t, loading } = this.props;
    const {
        FB,
    } = this.state;

    console.log('render login');

    return (
        <Button className={classNames('social-button', {'disabled': !FB || loading})} onClick={this.FBLogin}>
            <img src={fbLoginImg} alt="x" />
            <Label>{t('buyPage.facebook')}</Label>
        </Button>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = {
  checkFacebookLogin: checkFacebookLoginState,
};

//withRouter

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)));
