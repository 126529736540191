import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Row, Col, Label,
} from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';

import './textEditor.scss';

import htmlToDraft from 'html-to-draftjs';
import { sendLanguageType } from '../../../constants/constants';

const TextEditor = ({ editorState, editorStateSet, languages, handleEditState, readOnly, viewEditorState, viewLang, toggleEditorSet, chooseFile, addEditorClassName, notChangedContent }) => {
  const { t } = useTranslation('admin');

  let readEditor = null;
  if (readOnly && viewEditorState) {
    const blocksFromHtml = htmlToDraft(viewEditorState);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    if (contentState) {
      readEditor = EditorState.createWithContent(contentState);
    } else {
      readEditor = EditorState.createEmpty();
    }
  }

  if (!editorStateSet && !readOnly) {
    return null;
  }

  return (
    <div>
      {
        !readOnly ? (
          languages && !notChangedContent ? (
            languages.map(language => (
              <Row>
                <Col>
                  <Label>{sendLanguageType[language]}</Label>
                </Col>
                <Col xs="12" className={readOnly ? 'editor-readOnly' : ''}>
                  <Editor
                    className="content-management-text-area"
                    editorState={editorState[language] || {}}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName={`editorClassName ${addEditorClassName}`}
                    readOnly={readOnly}
                    onEditorStateChange={state => handleEditState(state, language)}
                    toolbar={{
                      image: { uploadCallback: chooseFile, alt: { present: true, mandatory: false } },
                      inputAccept: 'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel',
                    }}
                  />
                </Col>
              </Row>
            ))
          ) : (
            <Editor
              className="content-management-text-area"
              editorState={editorState || {}}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName={`editorClassName ${addEditorClassName}`}
              readOnly={readOnly}
              onEditorStateChange={state => handleEditState(state)}
              toolbar={{
                image: { uploadCallback: chooseFile, previewImage: true, alt: { present: true, mandatory: false }, urlEnabled: true },
                inputAccept: 'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel',
              }}
            />
          )
        ) : (
          <Row>
            <Col xs="12" className={readOnly ? 'editor-readOnly' : ''}>
              <Editor
                className="content-management-text-area"
                editorState={readEditor || {}}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName={`editorClassName ${addEditorClassName}`}
                toolbarHidden={readOnly}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        )
      }
    </div>
  );
};

TextEditor.propTypes = {
  editorState: PropTypes.shape({}),
  editorStateSet: PropTypes.bool,
  languages: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
  handleEditState: PropTypes.func.isRequired,
  viewEditorState: PropTypes.string,
  viewLang: PropTypes.string,
  toggleEditorSet: PropTypes.func.isRequired,
  chooseFile: PropTypes.func.isRequired,
  addEditorClassName: PropTypes.string,
  notChangedContent: PropTypes.bool,
};

TextEditor.defaultProps = {
  editorState: {},
  editorStateSet: false,
  languages: ['lv', 'en', 'ru'],
  readOnly: false,
  viewEditorState: null,
  viewLang: null,
  addEditorClassName: '',
  notChangedContent: false,
};

export default TextEditor;
