import Store from '../store/refferal';

export const initialState = Store;

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_REFFERAL_TOKEN': {
      console.log('SET_REFFERAL_TOKEN refferalToken', action.data);

      if (action.data) {
        return {
          ...state,
          refferalToken: action.data,
        };
      }
      return state;
    }

    case 'REMOVE_REFFERAL_TOKEN': {
      console.log('REMOVE_REFFERAL_TOKEN');

      if (action.data) {
        return {
          ...state,
          refferalToken: null,
        };
      }
      return state;
    }

    default:
      return state;
  }
}
