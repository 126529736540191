import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import CustomModal from '../../../../Components/Components/Modal';

import Info from '../Info';

import InfoImg from '../../../../../../../images/redesign/icons/info.svg';
import lightTutorialImage from '../../../../../../../images/redesign/light-mode/icons/info.png';
import filterIcon from '../../../../../../../images/redesign/rooms-table/filterIcon.svg';
import lightFilterIcon from '../../../../../../../images/redesign/light-mode/rooms-table/filterIcon.png';

import * as constants from '../../../../../../../constants/constants';

class MobileHeader extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        openFilter: PropTypes.func.isRequired,
        openCreate: PropTypes.func.isRequired,
        disableCreate: PropTypes.bool,
        screenMode: PropTypes.string,
    }

    static defaultProps = {
        disableCreate: false,
        screenMode: constants.SCREEN_MODE.normal,
    }

    constructor(props) {
        super(props);
        this.state = {
            popoverOpen: false,
        };
    }

    componentWillMount() {
    }

    openInfo = () => {
        this.setState({ infoModalOpen: true });
    }

    closeInfo = () => {
        this.setState({ infoModalOpen: false });
    }

    onHover = () => {
        const { popoverOpen } = this.state;

        if (!popoverOpen) {
            this.setState({
                popoverOpen: true,
            });
        }
    }

    onHoverLeave = () => {
        this.setState({
            popoverOpen: false,
        });
    }

    render() {
        const {
            t,
            openFilter,
            openCreate,
            disableCreate,
            screenMode,
        } = this.props;

        const { infoModalOpen } = this.state;
        return (
            <>
                <div className="layout-mobile-subheader d-none">
                    <div className='create-room-button-wrapper'>
                        <Button
                            type='button'
                            color="link"
                            className={classNames('layout-highlighted-button create-room-button create-room-button-portrait', {
                            })}
                            id="newGame-button"

                            onClick={openCreate}
                            onMouseEnter={this.onHover}
                            onMouseLeave={this.onHoverLeave}
                            disabled={disableCreate}
                        >
                            {t('roomsTable.createRoom')}
                        </Button>
                    </div>
                    <div className="rooms-table-header-tools">
                        <Media color="link" className="filter-image" src={screenMode === constants.SCREEN_MODE.dark ? filterIcon : (screenMode === constants.SCREEN_MODE.light ? lightFilterIcon : filterIcon)} onClick={openFilter} />
                        <Media color="link" className="info-image" src={screenMode === constants.SCREEN_MODE.dark ? InfoImg : (screenMode === constants.SCREEN_MODE.light ? lightTutorialImage : InfoImg)} onClick={this.openInfo} />
                    </div>
                </div>

                <CustomModal
                    isOpen={infoModalOpen}
                    toggle={this.closeInfo}
                    size="md"
                    title={t('roomsTable.roomInfo')}
                    footer={<>
                        <Button color="link" className="modal-footer-button" onClick={this.closeInfo}>{t('common.close')}</Button>
                    </>}
                    body={(<Info screenMode={screenMode} />)}
                />

            </>
        );
    }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(MobileHeader));
