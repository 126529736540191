import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import ReactGA from "react-ga";

import { connect } from 'react-redux';

import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';

import lvFlag from '../../../images/flags/lv.png';
import ruFlag from '../../../images/flags/ru.png';
import enFlag from '../../../images/flags/en.png';

import {
  setUserLoginStatistics,
} from '../../../actions/member';

const languagesList = ['lv', 'ru', 'en'];

class LanguageSelect extends PureComponent {
  static propTypes = {
    userSettings: PropTypes.shape(),
    changeSound: PropTypes.func,
    beforeSettings: PropTypes.array,
    beforeChangeSetting: PropTypes.func.isRequired,
    curLang: PropTypes.string,
    toggleLanguageFunc: PropTypes.func.isRequired,
    customClassName: PropTypes.string,
  }

  static defaultProps = {
    match: null,
    history: {},
    beforeSettings: [],
    curLang: null,
    customClassName: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      selectorOpen: false,
      curLang: null,
      isInbox: false,
    };
  }

  componentDidMount () {
    const { i18n } = this.props;

    // const curLang = i18n.language;

    // this.setState({ curLang: curLang || 'lv' });

    if (window && window.location) {
      const { hostname } = window.location;

      if (hostname === 'inbox.spelezoli.lv' || hostname === 'inbox.dev.spelezoli.lv') {
        this.setState({ isInbox: true });
      }
    }
  }

  toggleLanguage = (lang) => {
    const { i18n, toggleLanguageFunc, setUserLoginStats } = this.props;

    // this.setState({ curLang: lang });

    // // i18n.changeLanguage(lang);

    // ReactGA.event({
    //   category: 'Language',
    //   action: 'Change Language'
    // });

    toggleLanguageFunc(lang);

    setUserLoginStats(false, null, lang, null, null, null);
  }

  toggleLanguageSelector = () => {
    this.setState(prevState => ({
      selectorOpen: !prevState.selectorOpen,
    }));
  }

  renderLanguage = (lang) => {
    if (lang === 'lv') {
      return <Media src={lvFlag} className="language-image" />
    } else if (lang === 'ru') {
      return <Media src={ruFlag} className="language-image" />
    } else if (lang === 'en') {
      return <Media src={enFlag} className="language-image" />
    }
  }

  render = () => {
    const { curLang, customClassName } = this.props;
    const { selectorOpen, isInbox } = this.state;

    if (!curLang) {
      return null;
    }

    return (
      <div className={`language ${customClassName} ${isInbox ? 'language-inbox' : null}`}>
        <Col className="language-select">
          <Dropdown isOpen={selectorOpen} toggle={this.toggleLanguageSelector}>
            <DropdownToggle caret>
                {this.renderLanguage(curLang)}
              </DropdownToggle>
            <DropdownMenu>
              {languagesList.map((langKey) => {
                return <DropdownItem key={langKey} onClick={(e) => {this.toggleLanguage(langKey)}}>{this.renderLanguage(langKey)}</DropdownItem>
              })}
            </DropdownMenu>
          </Dropdown>
        </Col>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setUserLoginStats: setUserLoginStatistics,
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(LanguageSelect));
// export default withTranslation('common')(LanguageSelect);
