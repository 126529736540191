import React from 'react';
import PropTypes from 'prop-types';

const ViewLastRound = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
          Istabas skatā labajā pusē ir pieejama poga, ar kuras palīdzību var aplūkot iepriekšējā partijā izdalītās un galdā esošās kārtis.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          В правой стороне вида комнаты имеется кнопка, при помощи которой можете посмотреть разделенные и находящиеся на столе карты предыдущих партий.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          In the room view, there is a button on the right that allows players to view the cards dealt in the previous hand and on the table.
        </p>
      </>
    )
  }
};

ViewLastRound.propTypes = {
  lang: PropTypes.string,
};

ViewLastRound.defaultProps = {
  lang: 'lv',
};

export default ViewLastRound;
