import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import isEqual from 'react-fast-compare';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

import pro from '../../../../../images/redesign/common/PRO.svg';
import regularSpeed from '../../../../../images/redesign/common/karavs.svg';
import speed from '../../../../../images/redesign/common/Room types speed.svg';
import lightning from '../../../../../images/redesign/common/Room types lightning.svg';
import unlimited from '../../../../../images/redesign/common/Room types unlimited.svg';
import defaultImage from '../../../../../images/redesign/common/default_image.svg';
import lightDefaultImage from '../../../../../images/redesign/light-mode/common/default_image.webp';
import normalDefaultImage from '../../../../../images/redesign/normal-mode/common/default_image.webp';

import minGamesBell from '../../../../../images/redesign/rooms-table/Call bell.png';
import MenuRow from '../../Components/Components/MenuRow';

// import lockImg from '../../../../../images/redesign/rooms-table/lock.svg';
import * as constants from '../../../../../constants/constants';
import { isRegularRoom } from '../../../../../utils/roomUtils';
import IconPopover from '../../Components/Components/IconPopover';

const RoomsTableJoinedRender = React.memo(({
  joinedRoom, uid, leaveRoom, activeTournament, popoverOpen, onHover, onHoverLeave, joinRoomClickedFunc, joinPrivateRoomConfirm, joinedRoomPassword, screenMode, // joinRoomClicked
}) => {
  const { t } = useTranslation('common');

  console.log('RoomsTableJoinedRender', { joinedRoom, joinedRoomPassword });

  // let digitsArr = [];

  // if (joinedRoomPassword) {
  //   digitsArr = joinedRoomPassword.toString().split('').map(Number);
  // }
  console.log(joinedRoom, 'joinedRoom');

  if (joinedRoom && joinedRoom.key && joinedRoom.globalParams && joinedRoom.playersList
    && ((joinedRoom.playersList.player1 && joinedRoom.playersList.player1.uid === uid)
      || (joinedRoom.playersList.player2 && joinedRoom.playersList.player2.uid === uid)
      || (joinedRoom.playersList.player3 && joinedRoom.playersList.player3.uid === uid)
      || (joinedRoom.playersList.player4 && joinedRoom.playersList.player4.uid === uid))) {
    return (
      <MenuRow key={joinedRoom.key} contentClassName="rooms-table-joined-row">
        <Row key={joinedRoom.key} data-roomkey={joinedRoom.key} style={joinedRoom.removal ? { opacity: 0 } : { opacity: 1 }} className={`rooms-table-row rooms-table-row-${screenMode}`}>
          <Col xs="2">
            <>
              <div className="rooms-table-row-speed">
                {isRegularRoom(joinedRoom) && (
                  <Media className="rooms-table-row-speed-img-regular" src={regularSpeed} alt="" />
                )}
                {joinedRoom.globalParams.fastGame && (
                  <Media className="rooms-table-row-speed-img" src={speed} alt="Ātrā" />
                )}
                {joinedRoom.globalParams.lightningGame && (
                  <Media className="rooms-table-row-speed-img" src={lightning} alt="Zibens" />
                )}
                {joinedRoom.globalParams.unlimitedGame && ( // TODO 'unlimitedGame' path might change
                  <Media className="rooms-table-row-speed-img" src={unlimited} alt="Bez limita" />
                )}
              </div>
              <div className="rooms-table-row-type-wrapper">
                <div className="rooms-table-row-bet">
                  {joinedRoom.globalParams.bet}
                </div>
                <div className="rooms-table-row-type rooms-table-row-type-joined">
                  {`${joinedRoom.globalParams.gameType === 'P' ? t('common.parastas') : t('common.galds')}${joinedRoom.globalParams.smallGame ? `, ${t('common:newGame.smallGame')}` : ''}`}
                </div>
              </div>

              <div className="rooms-table-row-minGames">
                {joinedRoom.globalParams.minGames > 1 ? (
                  <div className="rooms-table-row-minGames-wrapper">
                    <Media className="rooms-table-row-minGames-bell-img" src={minGamesBell} alt="Ātrā" />
                    <div className="rooms-table-row-minGames-bell-count">{joinedRoom.globalParams.minGames}</div>
                  </div>
                ) : (null)}
              </div>

              <div className="rooms-table-row-pro">
                {joinedRoom.globalParams.proGame ? (
                  <div className="rooms-table-row-pro-wrapper">
                    <Media className="rooms-table-row-pro-img" src={pro} alt="Ātrā" />
                  </div>
                ) : (null)}
              </div>
            </>
          </Col>
          <Col xs="7">
            <Row>
              <Col xs="3">
                {(joinedRoom.playersList && joinedRoom.playersList.player1) ? (
                  <>
                    <div className="rooms-table-row-player">
                      <div className="rooms-table-row-player-image-frame">
                        <Media className={`rooms-table-row-player-image ${joinedRoom.playersList.player1.photo ? '' : 'rooms-table-row-player-image-default'}`} src={joinedRoom.playersList.player1.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                      </div>
                      <div className="rooms-table-row-player-name">
                        {`${joinedRoom.playersList.player1.shortName || joinedRoom.playersList.player1.name}`}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="rooms-table-row-player">
                    <div className="rooms-table-row-player-image-frame">
                      <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage)} />
                    </div>
                    <div className="rooms-table-row-player-waiting">
                      {(`${t('roomsTable.waiting')}...`)}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs="3">
                {(joinedRoom.playersList && joinedRoom.playersList.player2) ? (
                  <>
                    <div className="rooms-table-row-player">
                      <div className="rooms-table-row-player-image-frame">
                        <Media className={`rooms-table-row-player-image ${joinedRoom.playersList.player2.photo ? '' : 'rooms-table-row-player-image-default'}`} src={joinedRoom.playersList.player2.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                      </div>
                      <div className="rooms-table-row-player-name">
                        {`${joinedRoom.playersList.player2.shortName || joinedRoom.playersList.player2.name}`}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="rooms-table-row-player">
                    <div className="rooms-table-row-player-image-frame">
                      <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage)} />
                    </div>
                    <div className="rooms-table-row-player-waiting">
                      {(`${t('roomsTable.waiting')}...`)}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs="3">
                {(joinedRoom.playersList && joinedRoom.playersList.player3) ? (
                  <>
                    <div className="rooms-table-row-player">
                      <div className="rooms-table-row-player-image-frame">
                        <Media className={`rooms-table-row-player-image ${joinedRoom.playersList.player3.photo ? '' : 'rooms-table-row-player-image-default'}`} src={joinedRoom.playersList.player3.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                      </div>
                      <div className="rooms-table-row-player-name">
                        {`${joinedRoom.playersList.player3.shortName || joinedRoom.playersList.player3.name}`}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="rooms-table-row-player">
                    <div className="rooms-table-row-player-image-frame">
                      <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage)} />
                    </div>
                    <div className="rooms-table-row-player-waiting">
                      {(`${t('roomsTable.waiting')}...`)}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs="3">
                {(joinedRoom.globalParams && joinedRoom.globalParams.fourPRoom) ? (
                  <>
                    {(joinedRoom.playersList && joinedRoom.playersList.player4) ? (
                      <>
                        <div className="rooms-table-row-player">
                          <div className="rooms-table-row-player-image-frame">
                            <Media className={`rooms-table-row-player-image ${joinedRoom.playersList.player4.photo ? '' : 'rooms-table-row-player-image-default'}`} src={joinedRoom.playersList.player4.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                          </div>
                          <div className="rooms-table-row-player-name">
                            {`${joinedRoom.playersList.player4.shortName || joinedRoom.playersList.player4.name}`}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="rooms-table-row-player">
                        <div className="rooms-table-row-player-image-frame">
                          <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage)} />
                        </div>
                        <div className="rooms-table-row-player-waiting">
                          {(`${t('roomsTable.waiting')}...`)}
                        </div>
                      </div>
                    )}
                  </>
                ) : (null)}
              </Col>
            </Row>
          </Col>
          <Col xs="3" className="room-code-text">

            {(joinedRoom.playersList.player1 && joinedRoom.playersList.player1.uid === uid)
              || (joinedRoom.playersList.player2 && joinedRoom.playersList.player2.uid === uid)
              || (joinedRoom.playersList.player3 && joinedRoom.playersList.player3.uid === uid)
              || (joinedRoom.playersList.player4 && joinedRoom.playersList.player4.uid === uid)
              ? (
                <Fragment>
                  <div style={{ display: 'inline-block' }}>
                    {joinedRoomPassword && (
                      <>
                        <div className="rooms-table-row-password-text rooms-table-row-password-text-joined">
                          {t('roomsTable.roomCode')}
                        </div>
                        {joinedRoomPassword.toString().split('').map(digit => (
                          <div className="rooms-table-row-password-digit">
                            {digit}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  {(joinedRoom.playersList.player1 && joinedRoom.playersList.player2 && joinedRoom.playersList.player3 && (!joinedRoom.globalParams.fourPRoom || joinedRoom.playersList.player4)) ? (
                    <Link
                      to={`/zole/${joinedRoom.key}`}
                      className={classNames(`layout-default-button layout-float-right layout-default-button-${screenMode}`, {
                        'private-room-button': joinedRoom && joinedRoom.globalParams.privateRoom,
                      })}
                    >
                      {t('roomsTable.return')}
                    </Link>
                  ) : (
                    <Button
                      disabled={joinedRoom.filled}
                      color="link"
                      className={classNames(`layout-default-button layout-float-right layout-default-button-${screenMode}`, {
                        'private-room-button': joinedRoom && joinedRoom.globalParams.privateRoom,
                      })}
                      onClick={() => leaveRoom(joinedRoom.key)}
                    >
                      {t('roomsTable.leave')}
                    </Button>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {joinedRoom.globalParams.privateRoom ? (
                    <Button
                      color="link"
                      className={classNames('menu-table-row-default-button', {
                        // 'disabled': (activeTournament || joinRoomClicked || joinedRoom),
                        'private-room-button': joinedRoom && joinedRoom.globalParams.privateRoom,
                      })}
                      onClick={() => joinPrivateRoomConfirm(joinedRoom.key, 'player1')}
                      id={`join-room-button-${joinedRoom.key}`}
                      onMouseEnter={() => onHover(joinedRoom.key)}
                      onMouseLeave={() => onHoverLeave(joinedRoom.key)}
                    >
                      {t('roomsTable.join')}
                    </Button>
                  ) : (
                    <Button
                      className={'menu-table-row-default-button}'}
                      color="link"
                      onClick={() => joinRoomClickedFunc(joinedRoom.key, 'player1')}
                      id={`join-room-button-${joinedRoom.key}`}
                      onMouseEnter={() => onHover(joinedRoom.key)}
                      onMouseLeave={() => onHoverLeave(joinedRoom.key)}
                    >
                      {t('roomsTable.join')}
                    </Button>
                  )}
                  {(activeTournament) ? (
                    <IconPopover text={t('common.inTournamentMessage')} placement="left" popoverOpen={(!!(popoverOpen && popoverOpen[joinedRoom.key]))} targetId={`join-room-button-${joinedRoom.key}`} />
                  ) : (null)}
                </Fragment>
              )}
          </Col>
        </Row>
      </MenuRow>
    );
  }
  return null;
}, (prevProps, nextProps) => {
  if (!isEqual(prevProps.joinedRoom, nextProps.joinedRoom)) {
    return false;
  }

  if (prevProps.screenMode !== nextProps.screenMode) {
    return false; // props are not equal -> update the component
  }

  if (prevProps.uid !== nextProps.uid) {
    return false; // props are not equal -> update the component
  }

  if (prevProps.joinedRoomPassword !== nextProps.joinedRoomPassword) {
    return false; // props are not equal -> update the component
  }

  return true; // props are not equal -> update the component
});

RoomsTableJoinedRender.propTypes = {
  joinedRoom: PropTypes.shape(),
  uid: PropTypes.string,
  leaveRoom: PropTypes.func.isRequired,
  activeTournament: PropTypes.string,
  popoverOpen: PropTypes.shape(),
  onHover: PropTypes.func.isRequired,
  onHoverLeave: PropTypes.func.isRequired,
  joinRoomClickedFunc: PropTypes.func.isRequired,
  joinPrivateRoomConfirm: PropTypes.func.isRequired,
  joinedRoomPassword: PropTypes.string,
  screenMode: PropTypes.string,
};

RoomsTableJoinedRender.defaultProps = {
  joinedRoom: null,
  uid: null,
  activeTournament: null,
  popoverOpen: {},
  joinedRoomPassword: null,
  screenMode: null,
};

export default RoomsTableJoinedRender;
