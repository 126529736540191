import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../../../Components/Components/TextInput';
import {
  Button,
  Media,
  Label,
  Alert,
} from 'reactstrap';
import { filter, size } from 'lodash';
import CustomModal from '../../../Components/Components/Modal';
import reportImg from '../../../../../../images/redesign/player/report.png';
import reportPlayerImg from '../../../../../../images/redesign/player/report_player.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  reportPlayer,
  getReportedPlayersInRoom
} from '../../../../../../actions/member';
import { FROUNT_SIDE_STATUS } from '../../../../../../constants/constants';
import { errorMessages } from '../../../../../../constants/messages';
import IconPopover from '../../../Components/Components/IconPopover';

const ReportPlayer = ({name, uid, myId, roomId }) => {

  const { t } = useTranslation(['notifications', 'common']);
  const dispatch = useDispatch();

  const reportedPlayersInRoom = useSelector(state => state.member.reportedPlayers || {});
  const myName = useSelector(state => state.member.name || '');

  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportReason, setReportReason] = useState('');
  const [curLength, setCurLength] = useState(0);
  const [inputErrorVisible, setInputErrorVisible] = useState(false);
  const [inputStatus, setInputStatus] = useState('');
  const [inputMessage, setInputMessage] = useState('');
  const [alertError, setAlertError] = useState('');
  const [alertStatus, setAlertStatus] = useState('');
  const [reportPlayerPopoverOpen, setReportPlayerPopoverOpen] = useState(false);

  useEffect(() => {
      dispatch(getReportedPlayersInRoom(roomId, uid));
  }, [reportModalOpen])


  const toggleReportModal = () => {
    setReportModalOpen(!reportModalOpen);
    setReportReason('');
    setCurLength(0);
    setInputErrorVisible(false);
    setInputStatus('');
    setInputMessage('');
    setAlertError('');
    setAlertStatus('');
  }

  const handleOpenReportModal = () => {
    setReportModalOpen(!reportModalOpen);
  }

  const handleInputChange = (value, type) => {
    setReportReason(value);
    setCurLength(value.length)
  }

  const handleSubmitReport = () => {
    if (!reportReason) {
      setInputErrorVisible(true); 
      setInputStatus(FROUNT_SIDE_STATUS.danger);
      setInputMessage(t(`writeReport`));
      return;
    }
    if (!uid || !myId || !roomId || !myName) return
    if (size(filter(reportedPlayersInRoom, (item, key) => { return item.reportedPlayerId === uid})[0]) > 0) {
      setInputMessage(t('reportPlayerError'));
      setInputErrorVisible(true); 
      setInputStatus(FROUNT_SIDE_STATUS.danger);
      return setTimeout(() => {
        toggleReportModal();
      }, 3000);
    }
    
    dispatch(reportPlayer(reportReason, uid, name, myId, myName, roomId))
    setInputStatus(FROUNT_SIDE_STATUS.success);
    setInputMessage(t('reportPlayerSuccess'));
    setInputErrorVisible(true); 
    setTimeout(() => {
      toggleReportModal();
    }, 3000);
  }

  return (
    <>
      <div className="report-player-icon" id={`report-player-popover-${uid}`} onMouseEnter={() => setReportPlayerPopoverOpen(true)} onMouseLeave={() => setReportPlayerPopoverOpen(false)}>
        <Media
          className="report-player-image"
          src={size(filter(reportedPlayersInRoom, (item, key) => { return item.reportedPlayerId === uid})[0]) > 0 ? reportPlayerImg : reportImg}
          onClick={handleOpenReportModal}
        />
      </div>
      <IconPopover text={t('common:popovers.reportPlayer')} placement="left" targetId={`report-player-popover-${uid}`} popoverOpen={reportPlayerPopoverOpen} />

      <CustomModal
        isOpen={reportModalOpen}
        toggle={toggleReportModal}
        size="md"
        title={t('reportTitle')}
        body={
          <div className='report-player-section d-flex align-items-center flex-column'>
            <Alert className="new-design-alert" color={alertStatus} isOpen={alertError && alertStatus}>
              <p>{alertError}</p>
            </Alert>
            <div className='report-player-section-description d-flex align-items-center flex-column'>
              <Label>{t('reportDescription1')}</Label>
              <Label>{t('reportDescription2')}</Label>
            </div>
            <div className='report-player-section-reason w-100'>
              <TextInput
                contentEditable={true}
                width='100%'
                type="textarea"
                secondaryPlaceholder={t('reportReason')}
                initialValue={reportReason}
                onChange={(value, type) =>
                  handleInputChange(value, type)
                }
                maxLength={100}
                curLength={curLength}
                inlineMessageVisible={inputErrorVisible}
                inputStatus={inputStatus}
                inputMessage={inputMessage}
                customHeight="auto"
                rows="2"
              />

            </div>
          </div>
        }
        footer={
          <Button onClick={handleSubmitReport}>
            {t('submit')}
          </Button>
        }
      />
    </>
  )
};

export default ReportPlayer;
