import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  // Label,
  // Alert,
  // Input,
  Button,
  // FormGroup,
  Media,
  ModalFooter,
} from 'reactstrap';
import closeImg from '../../../images/icons/close.png';
// import { FONT_MODE } from '../../../constants/constants';

const RecoverEmail = React.memo(({ recoverUser, toggleRecoverUser, error, loading, recoverUserSubmit, fontMode, }) => {
  const { t } = useTranslation('common');

  const closeRecoverUser = () => {
    toggleRecoverUser();
  };

  const recoverUserFunc = () => {
    recoverUserSubmit(recoverUser);
  };

  return (
    <Row>
      <Col lg={{ size: 8, offset: 3 }}>
        <Modal container={'div > div'} isOpen toggle={() => closeRecoverUser()} className={`update-profile notification old-modal root-font-${fontMode}`}>
          <ModalHeader
            className="notification-header"
            close={<Media src={closeImg} className="notification-header-close" alt="X" onClick={() => closeRecoverUser()} />}
          >
            {t('member.recoverEmail')}
          </ModalHeader>
          <ModalBody className="update-profile-body-avatar">
            <Row>
              <Col sm="12">
                {t('member.previousEmailLogin')}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div>
              <Button color="link" className="update-profile-button" style={{ marginTop: 20 }} disabled={loading} onClick={() => recoverUserFunc()}>
                {loading ? t('home.loading') : t('common.yes')}
              </Button>
              <Button color="link" className="update-profile-button" style={{ marginTop: 20 }} disabled={loading} onClick={() => closeRecoverUser()}>
                {loading ? t('home.loading') : t('common.no')}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </Col>
    </Row>
  );
});

export default RecoverEmail;
