import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import { Col, Row, Button, Label, Media } from 'reactstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useTranslation } from 'react-i18next';

import { withRouter } from 'react-router-dom';

import * as constants from '../../../constants/constants';

import LandingTop from './Components/Layout/LandingTop';
import LanguageSelect from './Components/Layout/LanguageSelect';
import RegisterSlide from './Components/Components/RegisterSlide';
import HomeGameSlide from './Components/Components/HomeGameSlide';
import HomeBlogSlide from './Components/Components/HomeBlogSlide';

import loginIconImg from '../../../images/landing-view/login-icon.svg';
import appstoreButton from '../../../images/redesign/landing-view/appstorebutton.png';
import playstoreButton from '../../../images/redesign/landing-view/playstorebutton.png';
import advImg from '../../../images/redesign/landing-view/mobile_bg_1.webp'
import logoImg from '../../../images/redesign/landing-view/zoleLogo.svg';

const ANDROID_PATH_lv = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=lv';
const ANDROID_PATH_en = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=en';
const ANDROID_PATH_ru = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=ru';
const IOS_PATH_lv = 'https://apps.apple.com/lv/app/id1505526877';
const IOS_PATH_en = 'https://apps.apple.com/us/app/id1505526877';
const IOS_PATH_ru = 'https://apps.apple.com/ru/app/id1505526877';

const HomeContainer = ({ history, goToLogin, goToRegister, blogArticleFunc, toggleHomeContainer, isLoggedIn }) => {

  const { i18n, t } = useTranslation('common');

  let latestUid = null;

  try {
    latestUid = localStorage.getItem('latestUid') || null; //x
  } catch (err) {
    latestUid = 'null';
    console.log(err);
  }

  return (
    <>
      <div className="new-design-landing-container-sign">
        <div className="new-design-landing-container-sign-bg" />
        <div className="new-design-landing-container-sign-dg" />
        <div className="new-design-landing-container-sign-body">
          <Row className="sign-row">
            <Col sm={12} md={6} className="d-flex sign-row-start-game">
              <div className="sign-row-content">
                <div className="sign-row-content-title">
                  {t('home.play')}
                </div>
                <p className="sign-row-content-description">{t('home.playContent')}</p>
                {
                  (!isLoggedIn && latestUid === 'null') && <Button className="layout-highlighted-button sign-row-content-button" onClick={goToLogin}>{t('home.startGame')}</Button>
                }

              </div>
            </Col>
            <Col xs={12} sm={6} className="d-flex align-items-center">
              <RegisterSlide goToRegister={goToRegister} isLoggedIn={isLoggedIn} />
            </Col>
          </Row>
          <Row className="sign-row-banner d-flex align-items-center">
            <Col className="sign-row-banner-slide" md={12} lg={12}>
              <HomeGameSlide />
            </Col>
          </Row>
        </div>
      </div>
      <div className="new-design-landing-container-top">
        <div className="new-design-landing-container-top-bg" />
        <div className="new-design-landing-container-top-dg" />
        <div className="new-design-landing-container-top-bg-table" />
        <Row className="new-design-landing-container-top-list landing-top-table d-flex align-items-center">
          <Col md={12} lg={12} className="no-padding">
            <LandingTop goToRegister={goToRegister} />
          </Col>
        </Row>
        <div className="new-design-landing-container-top-footer" />
      </div>
      <div className="new-design-landing-container-blog d-flex align-items-center">
        <Row className="new-design-landing-container-blog-list d-flex align-items-center">
          <Col md={12} lg={12}>
            <div className="new-design-landing-container-blog-list-title mb-4">
              {t('menu.blog')}
            </div>
            <div className="new-design-landing-container-blog-list-body">
              <HomeBlogSlide history={history} blogArticleFunc={blogArticleFunc} toggleHomeContainer={toggleHomeContainer} />
            </div>
          </Col>
        </Row>
      </div>
      <div className="new-design-landing-container-mobile">
        <div className="new-design-landing-container-mobile-bg" />
        <div className="new-design-landing-container-mobile-bd" />
        <div className="new-design-landing-container-mobile-list d-flex align-items-center">
          <div className="new-design-landing-container-mobile-list-body">
            <Row className="new-design-landing-container-mobile-list-body-row d-flex align-items-center">
              <Col xs={12} sm={6} className="content-section">
                <div className="new-design-landing-container-mobile-list-body-row-content">
                  <div className="title mb-4">{t('home.playZoleYourphone')}</div>
                  <div className="description mb-4">{t('home.playZoleContent')}</div>
                  <div className="footer">
                    <div className="header mb-1">{t('home.downloadNow')}</div>
                    <div className="bottom">
                      <a target="_blank" rel="noopener noreferrer" href={i18n.language === constants.sendLanguage.lv ? ANDROID_PATH_lv : i18n.language === constants.sendLanguage.en ? ANDROID_PATH_en : i18n.language === constants.sendLanguage.ru ? ANDROID_PATH_ru : ANDROID_PATH_lv}>
                        <Media src={playstoreButton} className="mr-2 about-us-modal-third-row-img about-us-modal-third-row-img-left" />
                      </a>
                      <a target="_blank" rel="noopener noreferrer" href={i18n.language === constants.sendLanguage.lv ? IOS_PATH_lv : i18n.language === constants.sendLanguage.en ? IOS_PATH_en : i18n.language === constants.sendLanguage.ru ? IOS_PATH_ru : IOS_PATH_lv}>
                        <Media src={appstoreButton} className="mr-2 about-us-modal-third-row-img" />
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6} className="adv-section">
                <div className="new-design-landing-container-mobile-list-body-row-adv d-flex align-items-center">
                  <img src={advImg} alt="x" className="w-100" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeContainer;
