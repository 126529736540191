import React, { Fragment, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Row,
  Col,
  TabPane,
  TabContent,
  NavLink,
} from 'reactstrap/lib';
import ScrollAreaWrapper from '../../UI/ScrollAreaWrapper';

import { sendLanguage } from '../../../../constants/constants';
import { getNewHelpSections } from '../../../../actions/member';
import TextEditor from '../../TextEditor/TextEditor';

const NewHelpPage = ({ playButtonSound }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation('common');

  const newHelps = useSelector(state => state.member.newHelps);

  const [curLang, setCurLang] = useState(i18n.language || sendLanguage.lv);
  const [helpData, setHelpData] = useState([]);
  const [helpTab, setHelpTab] = useState(null);
  const [helpTabChild, setHelpTabChild] = useState(null);

  useEffect(() => {
    dispatch(getNewHelpSections());
  }, []);

  useEffect(() => {
    if (curLang !== i18n.language) {
      setCurLang(i18n.language || sendLanguage.lv);
    }
  }, [i18n]);

  useEffect(() => {
    if (newHelps && newHelps[curLang] && curLang) {
      const helpSectionData = JSON.parse(newHelps[curLang].helpSection) || [];
      setHelpData(helpSectionData);
      setHelpTab(helpSectionData[0].id || null);
    } else {
      setHelpData([]);
    }
  }, [curLang, newHelps]);

  const toggleHelpTab = (tab, index, isChild) => {
    playButtonSound();

    if (!isChild) {
      const selectedTab = helpData[index];

      if (selectedTab.subsections) {
        if (selectedTab.content && selectedTab.content !== '<p></p>\n') {
          if (helpTab !== tab) {
            setHelpTab(tab);
            setHelpTabChild(null);
          }
        } else if (helpTab !== tab) {
          setHelpTab(selectedTab.subsections[0].id);
          setHelpTabChild(selectedTab.subsections[0].id);
        }
      } else if (helpTab !== tab) {
        setHelpTab(tab);
        setHelpTabChild(null);
      }
    } else if (helpTabChild !== tab) {
      setHelpTab(tab);
      setHelpTabChild(tab);
    }
  };

  return (
    <Fragment>
      <div className="rules-page">
        <Row>
          <Col sm="3">
            <Row className="rules-page-header">
              <Col>
                <Row className="rules-page-header-tab menu-rules-page-nav-tab">
                  <ScrollAreaWrapper
                    className="contact-support-chat-body-scroll-area menu-rules-nav-header"
                    contentClassName="contact-support-chat-body-scroll-area-content"
                    show={false}
                    rightOffset={0}
                    topOffset={0}
                    bottomOffset={0}
                  >
                    {helpData.map((tab, index) => (
                      <>
                        <Col sm={{ size: 10, offset: 1 }} className="rules-page-tab">
                          <div className={`rules-page-tab-wrapper ${helpTab === tab.id && 'active'}`}>
                            <NavLink
                              className="rules-page-tab-link"
                              onClick={() => { toggleHelpTab(tab.id, index, false); }}
                            >
                              {`${tab.orderId}${t(tab.title)}`}
                            </NavLink>
                          </div>
                        </Col>
                        {(tab.subsections) ? (
                          <>
                            {tab.subsections.map(childTab => (
                              <Col sm={{ size: 8, offset: 3 }} className="rules-page-tab">
                                <div className={`rules-page-tab-wrapper ${helpTabChild === childTab.id && 'active'}`}>
                                  <NavLink
                                    className="rules-page-tab-link"
                                    onClick={() => { toggleHelpTab(childTab.id, index, true); }}
                                  >
                                    {`${childTab.orderId}${t(childTab.title)}`}
                                  </NavLink>
                                </div>
                              </Col>
                            ))}
                          </>
                        ) : (null)}
                      </>
                    ))}
                  </ScrollAreaWrapper>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm="8">
            <Row className="rules-page-content">
              <Col sm="12">
                <TabContent className="rules-page-content-tab" activeTab={helpTab}>
                  {helpData.map(tab => (
                    <>
                      <TabPane key={tab.id} tabId={tab.id}>
                        <div
                          className="contact-support-chat-body-scroll-area"
                          contentClassName="contact-support-chat-body-scroll-area-content"
                          // show={false}
                          // rightOffset={30}
                          // topOffset={30}
                          // bottomOffset={30}
                        >
                          <Row>
                            <Col className="rules-page-content-wrapper">
                              <TextEditor viewEditorState={tab.content} addEditorClassName="text-white" readOnly />
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                      {
                        tab.subsections && tab.subsections.map(subTab => (
                          <TabPane key={subTab.id} tabId={subTab.id}>
                            <div
                              className="contact-support-chat-body-scroll-area"
                              contentClassName="contact-support-chat-body-scroll-area-content"
                              // show={false}
                              // rightOffset={30}
                              // topOffset={30}
                              // bottomOffset={30}
                            >
                              <Row>
                                <Col className="rules-page-content-wrapper">
                                  <TextEditor viewEditorState={subTab.content} addEditorClassName="text-white" readOnly />
                                </Col>
                              </Row>
                            </div>
                          </TabPane>
                        ))
                      }
                    </>
                  ))}
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

NewHelpPage.propTypes = {
  playButtonSound: PropTypes.func.isRequired,
};

export default NewHelpPage;
