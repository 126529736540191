import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { filter, size } from 'lodash';
import Spinner from 'reactstrap/lib/Spinner';

import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import * as constants from '../../../constants/constants';
import coinImg from '../../../images/coin.svg';
// import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';
import config from '../../../constants/config';

const TopPageMillionaires = React.memo(({
  handleSendAddFriend, friends, t, i18n, leaderboardMillionaires, ignoredUsers, bannedUsers, block, unblock, isFiltered, activeType, showFields, leaderboardLoading
}) => {

  let activeTypeFilter;
  let activeTypeAction;

  if (isFiltered) {
    activeTypeFilter = constants.filterLeaderboardType.filter(item => item.id === activeType.toString())[0].label;
    activeTypeAction = constants.filterLeaderboardType.filter(item => item.id === activeType.toString())[0].action;
  }

  return (
    <Fragment>
      {leaderboardLoading ? (
        <div style={{ width: '100%', position: 'relative', display: 'block' }}>
          <div className="top-table-spinner-loading-section d-flex align-items-center">
            <Spinner color="light" style={{ width: '5rem', height: '5rem' }} />
          </div>
        </div>
      ) : (
        <>
          <div className={`top-table-wrapper ${config.isInAppFrame() && 'top-table-wrapper-iframe'}`}>
            <table className="top-table" tabindex="0">
              <colgroup>
                <col span="1" className="" />
              </colgroup>
              <thead className="top-table-header">
              <tr className="top-table-header-row">
                <th className="top-table-header-col col-index">
                  {t('common.position')}
                </th>
                <th className="top-table-header-col col-player">
                  {t('top.player')}
                </th>
                <th className="top-table-header-col">
                  {t('common.level')}
                </th>
                <th className="top-table-header-col">
                  {t('common.points')}
                </th>
                <th className="top-table-header-col">
                  <Media src={coinImg} className="top-table-header-coin" />
                </th>
                <th className="top-table-header-col">
                  {t('common.parties')}
                </th>
                <th className="top-table-header-col">
                  {t('route.friends')}
                </th>
                <th className="top-table-header-col">
                  {t('ignoredUsers.block')}
                </th>
                {
                  showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.gWon) !== -1 && (
                    <th className="top-table-header-col">
                      {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.gWon])[0].tLabel}`)}
                    </th>
                  )
                }
                {
                  showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) !== -1 && (
                    <th className="top-table-header-col">
                      {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.bgWon])[0].tLabel}`)}
                    </th>
                  )
                }
                {
                  showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.sgWon) !== -1 && (
                    <th className="top-table-header-col">
                      {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.sgWon])[0].tLabel}`)}
                    </th>
                  )
                }
                {
                  showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.tblsWon) !== -1 && (
                    <th className="top-table-header-col">
                      {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.tblsWon])[0].tLabel}`)}
                    </th>
                  )
                }
                {
                  showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.achvCnt) !== -1 && (
                    <th className="top-table-header-col">
                      {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.achvCnt])[0].tLabel}`)}
                    </th>
                  )
                }
                {
                  showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) !== -1 && (
                    <th className="top-table-header-col">
                      {t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.maxRndInGame])[0].tLabel}`)}
                    </th>
                  )
                }
              </tr>
            </thead>
              <tbody>
                {

                  leaderboardMillionaires && leaderboardMillionaires.map((pos, index) => (
                    (index <= constants.TOP_LIST_LIMIT.limit && (!isFiltered || (isFiltered && activeTypeFilter && pos[activeTypeAction]))) && (
                      (pos.key && bannedUsers && !bannedUsers[pos.key]) && (
                        <Fragment key={pos.key}>
                          <tr key={pos.key} className="top-table-row">
                            <td className="top-table-col">
                              {index + 1}
                            </td>
                            <td className="top-table-col col-player">
                              {pos.name}
                            </td>
                            <td className="top-table-col col-lvl">
                              <div style={{ position: 'relative' }}>
                                <div className="col-lvl-wrapper">
                                  <div className="col-lvl-lvl">
                                    <div className="col-lvl-text">
                                      {pos.lvl}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="top-table-col">
                              {pos.points || 0}
                            </td>
                            <td className="top-table-col">
                              {bannedUsers && bannedUsers[pos.key] ? 0 : pos.balance}
                            </td>
                            <td className="top-table-col">
                              {pos.gamesPlayed || 0}
                            </td>
                            <td>
                              <Button
                                color="link"
                                className={`ignored-users-table-button  ${i18n.language === 'ru' || i18n.language === 'lv' ? 'font-size-9' : null}`}
                                disabled={size(filter(friends, item => item.uid === pos.key)) > 0}
                                onClick={() => handleSendAddFriend(pos.key)}
                              >
                                {t('top.addAsFriend')}
                              </Button>
                            </td>
                            <td>
                              {pos.key && ignoredUsers && ignoredUsers[pos.key] ? (
                                <Button color="link" className={`ignored-users-table-button  ${i18n.language === 'ru' ? 'font-size-9' : null}`} onClick={() => unblock(pos.key)}>
                                  {t('ignoredUsers.unBlock')}
                                </Button>
                              ) : (
                                <Button color="link" className={`ignored-users-table-button  ${i18n.language === 'ru' ? 'font-size-9' : null}`} onClick={() => block(pos.key, pos.name)}>
                                  {t('ignoredUsers.block')}
                                </Button>
                              )}
                            </td>
                            {
                              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.gWon) !== -1 && (
                                <td className="top-table-col">
                                  {pos.gWon || 0}
                                </td>
                              )
                            }
                            {
                              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) !== -1 && (
                                <td className="top-table-col">
                                  {pos.bgWon || 0}
                                </td>
                              )
                            }
                            {
                              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.sgWon) !== -1 && (
                                <td className="top-table-col">
                                  {pos.sgWon || 0}
                                </td>
                              )
                            }
                            {
                              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.tblsWon) !== -1 && (
                                <td className="top-table-col">
                                  {pos.tblsWon || 0}
                                </td>
                              )
                            }
                            {
                              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.achvCnt) !== -1 && (
                                <td className="top-table-col">
                                  {pos.achvCnt || 0}
                                </td>
                              )
                            }
                            {
                              showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) !== -1 && (
                                <td className="top-table-col">
                                  {pos.maxRndInGame || 0}
                                </td>
                              )
                            }
                          </tr>
                        </Fragment>
                      )
                    )
                  ))

                }
              </tbody>
            </table>
          </div>
        </>
      )}
    </Fragment>
  );
});

TopPageMillionaires.propTypes = {
  leaderboardMillionaires: PropTypes.shape(),
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape(),
  isFiltered: PropTypes.bool,
  activeType: PropTypes.string,
  ignoredUsers: PropTypes.shape(),
  bannedUsers: PropTypes.shape({}),
  block: PropTypes.func.isRequired,
  unblock: PropTypes.func.isRequired,
  showFields: PropTypes.arrayOf(PropTypes.string),
  leaderboardLoading: PropTypes.bool,
  handleSendAddFriend: PropTypes.func.isRequired,
  friends: PropTypes.shape({}),
};

TopPageMillionaires.defaultProps = {
  leaderboardMillionaires: null,
  isFiltered: false,
  activeType: null,
  ignoredUsers: null,
  bannedUsers: null,
  i18n: null,
  showFields: [],
  leaderboardLoading: false,
  friends: {},
};

export default TopPageMillionaires;
