import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Media from 'reactstrap/lib/Media';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';

const PlayerGift = React.memo(({
  index, gifts, uid, posClassName, userGift,
}) => {
  if (!userGift || !uid || !gifts) {
    return null;
  }

  return (
    <Fragment>
      <div className={`player-gift-added-new ${posClassName ? `${posClassName}-gift-new` : null}`}>
        <Media
          className="player-receivedGift-image"
          id={`player-receivedGift-${index}`}
          trigger="hover"
          src={gifts[userGift.giftId] ? gifts[userGift.giftId].image : ''}
        />
        <UncontrolledTooltip
          className="player-receivedGift-tooltip-new"
          placement="bottom"
          target={`player-receivedGift-${index}`}
          container={'div > div'}
          //trigger="click" // use for debug only
        >
          <div className="player-receivedGift-tooltip-from">
            {userGift.fromName || ''}
          </div>
          <div className="player-receivedGift-tooltip-comment">
            {userGift.comment || ''}
          </div>
        </UncontrolledTooltip>
      </div>
    </Fragment>
  );
});

PlayerGift.propTypes = {
  index: PropTypes.number,
  gifts: PropTypes.shape(),
  //  roomGifts: PropTypes.shape(),
  userGift: PropTypes.shape(),
  uid: PropTypes.string,
  posClassName: PropTypes.string,
//  toggleGiftsModal: PropTypes.func.isRequired,
};

PlayerGift.defaultProps = {
  index: null,
  gifts: null,
  //  roomGifts: null,
  userGift: null,
  uid: null,
  posClassName: null,
};

export default PlayerGift;
