import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

import { removeAutomated } from '../../../../../actions/game';
import CustomModal from '../../Components/Components/Modal';

const AutomatedOverlay = React.memo(({
  roomId, resetAutomated, automated, t, isMobile, screenMode,
}) => {
  if (!automated) {
    return null;
  }

  return (
    <CustomModal
      isOpen={true}
      footerClose
      size="md"
      textCentering
      title={t('settings:autoPlay')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
      }
      footer={(
        <Button className="layout-highlighted-button" onClick={() => resetAutomated(roomId)}>
          {t('return')}
        </Button>
      )}
      body={<div className="text-center">{t('automatedMessage')}</div>}
      screenMode={screenMode}
    />
  );
});

AutomatedOverlay.propTypes = {
  roomId: PropTypes.string,
  automated: PropTypes.bool,
  resetAutomated: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

AutomatedOverlay.defaultProps = {
  roomId: null,
  automated: false,
  isMobile: false,
};

// const mapStateToProps = (state, ownProps) => ({
//  fastGame: state.game.globalParams.fastGame,
// });

const mapDispatchToProps = {
  resetAutomated: removeAutomated,
};

export default connect(null, mapDispatchToProps)(AutomatedOverlay);
