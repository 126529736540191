import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import PlayerBalance from './PlayerBlance';
import PlayerPopBalance from './PlayerPopBalance';

import * as constants from '../../../../../../constants/constants';
import PlayerHand from './PlayerHand';

const PlayerInfo = React.memo(({
  name, bal, rating, largePlayer, uid, myId, screenMode, activePos, gameState, currentHand, playerPosition, currentTurn,
}) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);


  const onHover = () => {
    if (bal <= constants.PLAYER_ZOLE_RANGE.min) {
      setPopoverOpen(true);
    }
  };

  const onHoverLeave = () => {
    setPopoverOpen(false);
  };

  return (
    <Fragment>
      <div className={`player-name-wrapper ${largePlayer ? 'player-name-wrapper-large' : ''} ${activePos ? 'player-name-wrapper-active' : ''}`}>
        <div className="player-name">
          {name}
        </div>
        <PlayerHand
          gameState={gameState}
          currentHand={currentHand}
          playerPosition={playerPosition}
          currentTurn={currentTurn}
        />
      </div>
      {
        uid === myId ? (
          <PlayerPopBalance bal={bal} rating={rating} onHover={onHover} onHoverLeave={onHoverLeave} popoverOpen={popoverOpen} screenMode={screenMode} />
        ) : (
          <PlayerBalance bal={bal} rating={rating} onHover={onHover} onHoverLeave={onHoverLeave} />
        )
      }

    </Fragment>
  );
});

PlayerInfo.propTypes = {
  name: PropTypes.string,
  bal: PropTypes.number,
  rating: PropTypes.number,
  largePlayer: PropTypes.bool,
  uid: PropTypes.string,
  myId: PropTypes.string,
  activePos: PropTypes.string,

  gameState: PropTypes.string,
  currentHand: PropTypes.number,
  playerPosition: PropTypes.string,
  currentTurn: PropTypes.string,
};

PlayerInfo.defaultProps = {
  name: null,
  bal: null,
  rating: null,
  largePlayer: false,
  uid: null,
  myId: null,
  activePos: null,

  gameState: null,
  currentHand: null,
  playerPosition: null,
  currentTurn: null,
};

export default PlayerInfo;
