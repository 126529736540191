import React from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';

import { useTranslation } from 'react-i18next';

import CustomModal from '../../../../Components/Components/Modal';

const StatisticsInfo = React.memo(({toggleStatisticsInfo, toggleStatisticsModal}) => {
  const { t } = useTranslation('common');

  return (
    <CustomModal
      isOpen={toggleStatisticsInfo}
      toggle={toggleStatisticsModal}
      size="md"
      title={t('myInfo.statisticsInfo')}
      body={(
        <Row className="flex-column my-info-ratings-description">
          <Col className="mb-2">
            <div className="my-info-ratings-description-title">
              {t('myInfo.statisticsInfoTitle1')}
            </div>
            {/* <div className="my-info-ratings-description-content">
              {t('myInfo.statisticsInfo1')}
            </div> */}
          </Col>
          <Col className="mb-2">
            <div className="my-info-ratings-description-title">
              {t('myInfo.statisticsInfoTitle2')}
            </div>
            {/* <div className="my-info-ratings-description-content">
              {t('myInfo.statisticsInfo2')}
            </div> */}
          </Col>
          <Col className="mb-2">
            <div className="my-info-ratings-description-title">
              {t('myInfo.statisticsInfoTitle3')}
            </div>
            {/* <div className="my-info-ratings-description-content">
              {t('myInfo.statisticsInfo3')}
            </div> */}
          </Col>
          <Col className="mb-2">
            <div className="my-info-ratings-description-title">
              {t('myInfo.statisticsInfoTitle4')}
            </div>
            {/* <div className="my-info-ratings-description-content">
              {t('myInfo.statisticsInfo4')}
            </div> */}
          </Col>
          <Col className="mb-2">
            <div className="my-info-ratings-description-title">
              {t('myInfo.statisticsInfoTitle5')}
            </div>
            {/* <div className="my-info-ratings-description-content">
              {t('myInfo.statisticsInfo5')}
            </div> */}
          </Col>
          <Col className="mb-2">
            <div className="my-info-ratings-description-title">
              {t('myInfo.statisticsInfoTitle6')}
            </div>
            {/* <div className="my-info-ratings-description-content">
              {t('myInfo.statisticsInfo6')}
            </div> */}
          </Col>
          <Col className="mb-2">
            <div className="my-info-ratings-description-title">
              {t('myInfo.statisticsInfoTitle7')}
            </div>
            {/* <div className="my-info-ratings-description-content">
              {t('myInfo.statisticsInfo7')}
            </div> */}
          </Col>
        </Row>
      )}
      footer={(
        <Button color="secondary" onClick={toggleStatisticsModal}>
          {t('common.close')}
        </Button>
      )}
    />
  );
});

export default StatisticsInfo;
