import { Platform } from 'react-native';

import config from '../constants/config';

export default {
  default: {
    parasta: true,
    G: false,
    atra: false,
    pro: false,
    privateRoom: false,
    fourPRoom: false,
    bet: '1:10',
    speed: 'parasta',
    minGames: 1,
    soundOn: true,
    roomChatOpen: true,
    scoreTableOpen: true,
    notificationSettings: {
      leaveRoom: true,
      lastRound: true,
      quitRound: true,
      ignorePlayer: true,
      quitAccount: true,
      purchaseGift: true,
      removeGift: true,
      showAfterRound: true,
      showAtRoomEnd: true,
      newDesign: !!(!config.isInAppFrame() && Platform.OS === 'web' && localStorage && localStorage.getItem('newDesign')),
    },
    gameSettings: {
      doubleClickPlay: false,
    },
    roomsFilter: {
      speed: [],
      bet: [],
      minGames: [],
      fourPRoom: [],
      gameType: [],
    },
  },
};
