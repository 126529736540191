import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

import TurnTimer from './TurnTimer';

import PlayerImage from './PlayersComponents/PlayerImage';
import PlayerType from './PlayersComponents/PlayerType';
import PlayerHand from './PlayersComponents/PlayerHand';
import PlayerInfo from './PlayersComponents/PlayerInfo';
import PlayerGift from './PlayersComponents/PlayerGift';
import PlayerEmotion from './PlayersComponents/PlayerEmotion';
import PlayerModal from './PlayersComponents/PlayerModal';

import blockImg from '../../../images/Game/block.svg';
import unblockImg from '../../../images/Game/unblock.svg';

import myTurnSound from '../../../sounds/my_turn4.mp3';
import myAlarmSound from '../../../sounds/alarm.mp3';

import giftImg from '../../../images/Game/gift.svg';
import ozollapasAnimImg from '../../../images/Holiday/ozollapas.json';

import reportedImg from '../../../images/redesign/chat/chat-report.png';
import reportImg from '../../../images/redesign/chat/chat-report-submit.png';
import closeImg from '../../../images/icons/close.png';

// Change for fourPRoom update
// import { GameService } from '../../../common/services';
import { getPlayersArranged } from '../../../common/services/game-service-new';

import {
  getOtherUserInfo,
} from '../../../actions/room';
import { FONT_MODE, FROUNT_SIDE_STATUS } from '../../../constants/constants';
import { sendFriend, startUnlimitedTimer } from '../../../actions/game';
import Lottie from 'react-lottie';
import { filter, size } from 'lodash';
import { Alert, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

let counter = 0;

class Players extends React.Component {
  static propTypes = {
    startUnlimitedTimerFunction: PropTypes.func.isRequired,
    gameT: PropTypes.func.isRequired,
    //  nextTimeStamp: PropTypes.number,
    endRoom: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    players: PropTypes.shape().isRequired,
    roomId: PropTypes.string,
    uid: PropTypes.string,
    //  offset: PropTypes.number,
    currentTurn: PropTypes.string,
    sittingOut: PropTypes.string,
    gameResultModalShown: PropTypes.bool,
    currentType: PropTypes.string,
    quitRound: PropTypes.bool,
    quitRoundFunction: PropTypes.func.isRequired,
    gifts: PropTypes.shape(),
    playerInfo: PropTypes.shape(),
    toggleGiftsModal: PropTypes.func.isRequired,
    roomGifts: PropTypes.shape(),
    ignoredUsers: PropTypes.shape(),
    unBlockUser: PropTypes.func.isRequired,
    toggleBlockUser: PropTypes.func.isRequired,
    //  emotions: PropTypes.shape(),
    closeResultModal: PropTypes.func.isRequired,
    toggleLastRound: PropTypes.func.isRequired,
    fetchUserInfo: PropTypes.func.isRequired,
    myPos: PropTypes.string,
    largePlayer: PropTypes.string,
    gameState: PropTypes.string,
    talking: PropTypes.string,
    currentHand: PropTypes.number,
    minGames: PropTypes.number,
    rPlayed: PropTypes.number,
    party: PropTypes.number,
    lastRound: PropTypes.bool,
    tournamentRoom: PropTypes.bool,
    //  disableTimer: PropTypes.bool,
    //  roomClosed: PropTypes.bool,
    //  fastGame: PropTypes.bool,
    soundOn: PropTypes.bool,
    lastRoundPlayer: PropTypes.string,
    lastRoundReason: PropTypes.string,
    showEndResultModal: PropTypes.bool,
    i18n: PropTypes.shape(),
    game: PropTypes.shape({}),
    //  fetchRoomDataFallback: PropTypes.shape().isRequired,
    //  getOffset: PropTypes.shape().isRequired,
    fontMode: PropTypes.string,
    speed: PropTypes.number,
    unlimitedTimerData: PropTypes.shape({}),
    sendAFriend: PropTypes.func.isRequired,
    isFullscreen: PropTypes.bool.isRequired,
    selectedTestHoliday: PropTypes.arrayOf(PropTypes.shape()),
    holidayTest: PropTypes.bool,
    reportedPlayersInRoom: PropTypes.shape({}),
    myName: PropTypes.string,
    reportPlayerFunc: PropTypes.func.isRequired,
    fetchReportedPlayersInRoom: PropTypes.func.isRequired,
  };

  static defaultProps = {
  //  nextTimeStamp: null,
    roomId: null,
    currentType: null,
    uid: null,
    currentTurn: null,
    sittingOut: null,
    gameResultModalShown: false,
    quitRound: false,
    gifts: null,
    playerInfo: null,
    roomGifts: null,
    ignoredUsers: null,
    //  emotions: {},
    myPos: null,
    largePlayer: null,
    gameState: null,
    talking: null,
    currentHand: null,
    minGames: null,
    rPlayed: null,
    party: null,
    lastRound: false,
    tournamentRoom: false,
    //  disableTimer: null,
    //  roomClosed: false,
    //  fastGame: null,
    soundOn: false,
    lastRoundPlayer: null,
    lastRoundReason: null,
    showEndResultModal: false,
    i18n: null,
    game: {},
    fontMode: FONT_MODE.normal,
    isFullscreen: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      playersArranged: [],
      blockPopoverOpen: false,
      lastRoundPlayerName: null,
      reportModalOpen: false,
      reportReason: '',
      reportReasonLength: 0,
      alertError: '',
      alertStatus: '',
      reportedPlayer: {},
    };
    this.myTurnAudio = new Audio(myTurnSound);
    this.myAlarmAudio = new Audio(myAlarmSound);
  }

  componentDidMount() {
    const { players, myPos, sittingOut, roomId, uid, fetchReportedPlayersInRoom } = this.props;
    //  const existingPlayersArranged = this.state.playersArranged;
    const playersArranged = getPlayersArranged(players, myPos, sittingOut);

  //  console.log('componentDidMount', playersArranged, myPos, sittingOut);

    if (playersArranged?.length > 0) {
      this.setState({ playersArranged: [...playersArranged] });
    }

    fetchReportedPlayersInRoom(roomId, uid);
  }

  componentWillReceiveProps(nextProps) {
    const { players, myPos, sittingOut } = nextProps;
    const existingPlayersArranged = this.state.playersArranged;

    const playersArranged = getPlayersArranged(players, myPos, sittingOut);

 //   console.log('componentWillReceiveProps', players, playersArranged, sittingOut, myPos);

    if (sittingOut) {
      if (playersArranged?.length > 0 && ((existingPlayersArranged.length === 4 && playersArranged?.length === 3) || existingPlayersArranged.length === 0 || (!existingPlayersArranged[0].uid || !existingPlayersArranged[1].uid || !existingPlayersArranged[2].uid || !existingPlayersArranged[3] || !existingPlayersArranged[3].uid))) {
      //  console.log('update playersArranged 1', { playersArranged, players });
        this.setState({ playersArranged });
      }
    } else if (playersArranged?.length > 0 && ((existingPlayersArranged.length === 4 && playersArranged?.length === 3) || existingPlayersArranged.length === 0 || (!existingPlayersArranged[0].uid || !existingPlayersArranged[1].uid || !existingPlayersArranged[2].uid || (existingPlayersArranged[3] && !existingPlayersArranged[3].uid)))) {
    //  console.log('update playersArranged 2', { playersArranged, players });
      this.setState({ playersArranged });
    } else if (playersArranged && playersArranged.length && existingPlayersArranged && existingPlayersArranged.length) {
      if (playersArranged[0].uid !== existingPlayersArranged[0].uid || playersArranged[0].name !== existingPlayersArranged[0].name || playersArranged[0].photo !== existingPlayersArranged[0].photo) {
      //  console.log('update playersArranged 3', { playersArranged, players });
        this.setState({ playersArranged });
      } else if (playersArranged[1].uid !== existingPlayersArranged[1].uid || playersArranged[1].name !== existingPlayersArranged[1].name || playersArranged[1].photo !== existingPlayersArranged[1].photo) {
      //  console.log('update playersArranged 4', { playersArranged, players });
        this.setState({ playersArranged });
      } else if (playersArranged[2].uid !== existingPlayersArranged[2].uid || playersArranged[2].name !== existingPlayersArranged[2].name || playersArranged[2].photo !== existingPlayersArranged[2].photo) {
      //  console.log('update playersArranged 5', { playersArranged, players });
        this.setState({ playersArranged });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      gameState, talking, uid, currentTurn, soundOn, myPos, lastRoundPlayer, roomId, fetchReportedPlayersInRoom,
    } = this.props;

    const { gameState: prevGameState, currentTurn: prevCurrentTurn, talking: prevTalking } = prevProps;

    const { lastRoundPlayerName, reportModalOpen } = this.state;
    const { reportModalOpen: prevReportModalOpen } = prevState;

    if (!lastRoundPlayerName && lastRoundPlayer) {
      const { players } = this.props;

      if (players) {
        let playerName;

        if (players.player1 && players.player1.uid === lastRoundPlayer) {
          playerName = players.player1.name;
        } else if (players.player2 && players.player2.uid === lastRoundPlayer) {
          playerName = players.player2.name;
        } else if (players.player3 && players.player3.uid === lastRoundPlayer) {
          playerName = players.player3.name;
        } else if (players.player4 && players.player4.uid === lastRoundPlayer) {
          playerName = players.player4.name;
        }

        if (playerName) {
          this.setState({ lastRoundPlayerName: playerName });
        }
      }
    }

    if (soundOn) {
      const turnChanged = prevCurrentTurn !== currentTurn;

      if ((prevGameState !== 'choose' || !prevTalking) && gameState === 'choose' && uid && talking && talking === uid) {
        console.log('play sound');
        this.playButtonSound(this.myTurnAudio);
      } else if (gameState === 'burry' && (myPos && myPos === currentTurn) && turnChanged) {
        console.log('play sound');
        this.playButtonSound(this.myTurnAudio);
      }
    //  else if (gameState === 'play' && (myPos && myPos === currentTurn) && turnChanged) {
      //  this.myTurnAudio.play();
    //  }
    }

    if (prevReportModalOpen !== reportModalOpen) {
      fetchReportedPlayersInRoom(roomId, uid);
    }
  }

  playButtonSound = (audioData) => {
    const { soundOn } = this.props;

    if (soundOn) {
      const playPromise = audioData.play();
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            // Automatic playback started!
            // Show playing UI.
            console.log("audio played auto");
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
            console.log("playback prevented");
          });
      }
    }
  }

  hurryUp = () => {
    const { unlimitedTimerData, startUnlimitedTimerFunction, roomId, uid,
      gameState, currentTurn, myPos, talking } = this.props;
    const { playersArranged } = this.state;
    const currentPlayerArray = playersArranged.filter(player => player.uid && player.uid === uid);
    const currentPlayer = currentPlayerArray[0] ? currentPlayerArray[0] : null;

    let playerHasToPutCard;
    if (currentPlayer) {
      playerHasToPutCard = ((gameState === 'play' && currentPlayer.position === currentTurn) || (gameState === 'play' && currentPlayer.position === myPos && currentTurn === null))
        || (gameState === 'burry' && currentPlayer.position === currentTurn)
        || (talking && currentPlayer.uid && gameState === 'choose' && talking === currentPlayer.uid)
    }

    if (!playerHasToPutCard && (!unlimitedTimerData || (currentPlayer && !unlimitedTimerData[currentPlayer.position]))){
      this.playButtonSound(this.myAlarmAudio);
      startUnlimitedTimerFunction(roomId);
    }
  }

  onLastRoundHover = () => {
    const { lastRoundPopoverOpen } = this.state;
    if (!lastRoundPopoverOpen) {
      this.setState({ lastRoundPopoverOpen: true });
    }
  }

  onLastRoundHoverLeave = () => {
    this.setState({
      lastRoundPopoverOpen: false,
    });
  }

  onHurryIconHover = () => {
    const { hurryUpPopoverOpen } = this.state;
    if (!hurryUpPopoverOpen) {
      this.setState({ hurryUpPopoverOpen: true });
    }
  }

  onHurryIconHoverLeave = () => {
    this.setState({
      hurryUpPopoverOpen: false,
    });
  }

  toggleLastRound = () => {
    const {
      lastRound, minGames, toggleLastRound, rPlayed,
    } = this.props;
    if (!lastRound && (!minGames || minGames <= rPlayed + 1)) {
      toggleLastRound();
    }
  }

  openPlayerModal = (uid, position) => {
    const { fetchUserInfo } = this.props;

    fetchUserInfo(uid);

    setTimeout(() => {
      this.setState({ openedPlayerModal: position, blockPopoverOpen: null });
    }, 200);
  }

  closePlayerModal = () => {
  //  const { fetchUserInfo } = this.props;

    this.setState({ openedPlayerModal: null, blockPopoverOpen: null });
  }

  toggleBlockUser = (uid, name) => {
    const { tournamentRoom, toggleBlockUser } = this.props;
    if (!tournamentRoom) {
      console.log('ReactGA - Ignore Player');

      ReactGA.event({
        category: 'Game',
        action: 'Ignore Player',
      });

      toggleBlockUser(uid, name);
      this.closePlayerModal();
    }

    this.setState({ blockPopoverOpen: null });
  }

  onBlockHover = (type, index) => {
    if (type === 'enter') {
      this.setState({ blockPopoverOpen: index });
    } else {
      this.setState({ blockPopoverOpen: null });
    }
  }

  handleSendAddFriendFunc = (selectId) => {
    const { sendAFriend } = this.props;

    sendAFriend(selectId); 
  }

  reportReasonChange = (event) => {
    this.setState({ reportReason: event.target.value, reportReasonLength: event.target.value.length});
  }

  handleOpenReportModal = (player) => {
    this.setState({ reportModalOpen: true, reportedPlayer: player});
  }

  toggleReportModal = () => {
    this.setState({ reportModalOpen: false, reportReason: '', reportReasonLength: 0, alertError: '', alertStatus: '' });
  }

  handleSubmitReport = () => {
    const { t, uid, roomId, myName, reportedPlayersInRoom, reportPlayerFunc } = this.props;
    const { reportReason, reportedPlayer } = this.state;
    if (!reportReason) {
      this.setState({alertStatus: FROUNT_SIDE_STATUS.danger, alertError: t(`writeReport`)});
      return;
    }
    if (!reportedPlayer.uid || !uid || !roomId || !myName) return;
    if (size(filter(reportedPlayersInRoom, (item, key) => { return item.reportedPlayerId === reportedPlayer.uid})[0]) > 0) {
      this.setState({alertStatus: FROUNT_SIDE_STATUS.danger, alertError: t(`reportPlayerError`)});

      return setTimeout(() => {
        this.toggleReportModal();
      }, 3000);
    }
    
    reportPlayerFunc(reportReason, reportedPlayer.uid, reportedPlayer.name, uid, myName, roomId);
    this.setState({alertStatus: FROUNT_SIDE_STATUS.success, alertError: t(`reportPlayerSuccess`)});

    setTimeout(() => {
      this.toggleReportModal()
    }, 3000);
  }

  render() {
    const {
      players,
      myPos,
      roomId,
      t,
      i18n,
      gameT,
      uid,
      //  offset,
      //  nextTimeStamp,
      currentTurn,
      sittingOut,
      largePlayer,
      currentType,
      gameState,
      talking,
      lastRound,
      //  disableTimer,
      minGames,
      party,
      rPlayed,
      roomClosed,
      currentHand,
      //  fastGame,
      endRoom,
      gameResultModalShown,
      quitRound,
      quitRoundFunction,
      gifts,
      toggleGiftsModal,
      roomGifts,
      ignoredUsers,
      unBlockUser,
      //  toggleBlockUser,
      closeResultModal,
      //  toggleLastRound,
      //  fetchRoomDataFallback,
      //  speed,
      tournamentRoom,
      //  closeReason,
      //  lastRoundPlayer,
      lastRoundReason,
      playerInfo,
      //  getOffset,
      showEndResultModal,
    //  game,
      fontMode,
      speed,
      unlimitedTimerData,
      isFullscreen,
      selectedTestHoliday,
      holidayTest,
      reportedPlayersInRoom,
    } = this.props;

    const x = new Date();
    const date = x.getDate();
    const month = x.getMonth();

    counter += 1;

    const {
      playersArranged, lastRoundPopoverOpen, hurryUpPopoverOpen, openedPlayerModal, blockPopoverOpen, lastRoundPlayerName, reportModalOpen, reportReason, reportReasonLength, alertError, alertStatus,
    } = this.state;

    
    const isSpeedUnlimited = speed === 60;
      //  console.log('playersArranged', playersArranged, players);
  //  console.log("my Pos", {myPos}, {game}, {largePlayer}, {currentType} )

  //  console.log('players', players);


    const currentPlayerArray = playersArranged.filter(player => player.uid && player.uid === uid);
    const currentPlayer = currentPlayerArray[0] ? currentPlayerArray[0] : null;

    let playerHasToPutCard = false;

    if (currentPlayer) {
      playerHasToPutCard = ((gameState === 'play' && currentPlayer.position === currentTurn) || (gameState === 'play' && currentPlayer.position === myPos && currentTurn === null))
        || (gameState === 'burry' && currentPlayer.position === currentTurn)
        || (talking && currentPlayer.uid && gameState === 'choose' && talking === currentPlayer.uid)
    }
    return (
      <Fragment>
        {playersArranged && playersArranged.map((player, index) => {
          let posClassName;


          // Change for fourPRoom update
          /*  if (myPos !== sittingOut) {
          if (index === 0) {
            posClassName = 'player-left';
          } else if (index === 1) {
            posClassName = 'player-firstperson';
          } else if (index === 2) {
            posClassName = 'player-right';
          } else if (index === 3) {
            posClassName = 'player-sittingOut';
          }
        } else { */
          if (index === 0) {
            posClassName = 'player-left';
          } else if (index === 1) {
            posClassName = 'player-firstperson';
          } else if (index === 2) {
            posClassName = 'player-right';
          } else if (index === 3) {
            posClassName = 'player-top';
          }
          //  }

        //  console.log('posClassName', posClassName);

          return (
            <Fragment key={`${player.position}-${party}-${index}`}>
              {player && player.uid && (
              <div
                id={`player-${player.position}`}
                className={`player player-${player.position} ${((gameState === 'play' || gameState === 'results') && player.position === currentTurn) && ' is-player-turn'} ${posClassName}`}
              >
                <div className="player-status-wrapper">
                  {(talking && gameState === 'choose' && talking === player.uid) && (
                  <div className="player-status">{gameT('actionChoose')}</div>
                  )}
                  {(gameState === 'burry' && player.position === currentTurn) && (
                  <div className="player-status">{gameT('actionBurry')}</div>
                  )}
                  {(gameState === 'play' && player.position === currentTurn) && (
                  <div className="player-status">{gameT('actionMove')}</div>
                  )}
                </div>

                <div className="player-image-timer-wrapper">
                  <PlayerImage
                    photo={player.photo || ''}
                    currentType={currentType}
                    gameState={gameState}
                    largePlayer={!!(largePlayer && player.position === largePlayer)}
                  />

                  <div style={{
                      display: (uid && !roomClosed && ((gameState === 'choose' && talking && talking === player.uid)
                      || (gameState === 'play' && currentTurn && player.position === currentTurn)
                      || (gameState === 'burry' && currentTurn && player.position === currentTurn)
                      || (gameState === 'results' && player.uid === uid))) ? null : 'none',
                  }}
                  >
                    <TurnTimer
                      endRoom={endRoom}
                      roomId={roomId}
                    //  nextTimeStamp={nextTimeStamp}
                      isRunning={((gameState === 'play' && player.position === currentTurn) || (gameState === 'play' && player.position === myPos && currentTurn === null))
                      || (gameState === 'burry' && player.position === currentTurn) || (talking && player.uid && gameState === 'choose' && talking === player.uid) || (uid && player.uid && gameState === 'results' && player.uid === uid)}
                    //  offset={offset}
                    //  gameState={gameState}
                    //  disableTimer={disableTimer || null}
                    //  fastGame={fastGame}
                    //  roomClosed={roomClosed}
                      closeResultModal={closeResultModal}
                      gameResultModalShown={gameResultModalShown}
                    //  fetchRoomDataFallback={fetchRoomDataFallback}
                    //  currentTurn={currentTurn}
                      myPos={myPos}
                      showEndResultModal={showEndResultModal}
                    />
                  </div>
                  {
                    uid !== player.uid && (
                      <Button color="link" className={`report-button ${isFullscreen && 'report-button-fullscreen'}`} onClick={() => this.handleOpenReportModal(player)}>
                        <Media
                          className="report-button-image"
                          src={size(filter(reportedPlayersInRoom, (item, key) => { return item.reportedPlayerId === player.uid})[0]) > 0 ? reportedImg : reportImg}
                          alt=""
                        />
                      </Button>
                    )
                  }
                  {
                    uid !== player.uid && (
                      <Button color="link" className={`player-gift ${isFullscreen && 'player-gift-fullscreen'}`} onClick={() => toggleGiftsModal(player.uid)}>
                        <Media
                          className="player-gift-image"
                          src={giftImg}
                          alt=""
                        />
                      </Button>
                    )
                  }

                  {roomGifts ? (
                    <PlayerGift
                      index={index}
                      gifts={gifts}
                  //  roomGifts={roomGifts}
                      uid={player.uid}
                      userGift={roomGifts ? roomGifts[player.uid] : null}
                      posClassName={posClassName}
                      toggleGiftsModal={toggleGiftsModal}
                    />
                  ) : (null)}

                  {/* index === 0 || index === 2 || index === 3  */}

                  {(index !== 1 && !tournamentRoom) && (
                    <>
                      {ignoredUsers && ignoredUsers[player.uid] ? (
                        <>
                          <div>
                            <Button
                              className={`block-button ${(tournamentRoom) && 'disabled'}`}
                              onClick={() => { unBlockUser(player.uid, player.name); }}
                              id={`unblock-popover-${index}`}
                              onMouseEnter={() => this.onBlockHover('enter', index)}
                              onMouseLeave={() => this.onBlockHover('leave', index)}
                            >
                              <Media className="block-button-image" src={unblockImg} alt="" />
                            </Button>
                          </div>
                          {/* {(tournamentRoom) ? (
                            <Popover container={'div > div'} popperClassName={`last-round-popover popover-font-${fontMode}`} isOpen={blockPopoverOpen === index} placement="top" target={`unblock-popover-${index}`}>
                              <PopoverBody className="last-round-popover-body">
                                <div>
                                  {t('blockUserTournament')}
                                </div>
                              </PopoverBody>
                            </Popover>
                          ) : (null)} */}
                        </>
                      ) : (
                        <>
                          <div>
                            <Button
                              className={`block-button ${isFullscreen && 'block-button-fullscreen'} ${(tournamentRoom) && 'disabled'}`}
                              onClick={() => { this.toggleBlockUser(player.uid, player.name); }}
                            //  id="block-popover"
                              id={`block-popover-${index}`}
                              onMouseEnter={() => this.onBlockHover('enter', index)}
                              onMouseLeave={() => this.onBlockHover('leave', index)}
                            >
                              <Media className="block-button-image" src={blockImg} alt="" />
                            </Button>
                          </div>
                          {/* {(tournamentRoom) ? (
                            <Popover container={'div > div'} popperClassName={`last-round-popover popover-font-${fontMode}`} placement="top" isOpen={blockPopoverOpen === index} target={`block-popover-${index}`}>
                              <PopoverBody className="last-round-popover-body">
                                <div>
                                  {t('blockUserTournament')}
                                </div>
                              </PopoverBody>
                            </Popover>
                          ) : (null)} */}
                        </>
                      )}
                    </>
                  )}
                </div>
                {(largePlayer) ? (
                  <PlayerType
                    t={gameT}
                    currentType={currentType}
                    sittingOut={sittingOut && player.position === sittingOut}
                    gameState={gameState}
                    largePlayer={!!(largePlayer && player.position === largePlayer)}
                  />
                ) : (
                  <Fragment>
                    {gameState === 'choose' || gameResultModalShown ? (
                      (sittingOut && player.position === sittingOut) ? (
                        <div className="player-type-wrapper">
                          <div className="player-type-mazais">{gameT('dealer')}</div>
                        </div>
                      ) : (<div className="player-type-none" />)
                    ) : (sittingOut && player.position === sittingOut) ? (
                      <div className="player-type-wrapper">
                        <div className="player-type-mazais">{gameT('dealer')}</div>
                      </div>
                    ) : (
                      // <div className="player-type-none" />
                      <div className="player-type-wrapper">
                        <div className="player-type-mazais">{gameT('table')}</div>
                      </div>
                    )}
                  </Fragment>
                )}

                <PlayerHand
                  gameState={gameState}
                  currentHand={currentHand}
                  playerPosition={player.position}
                  currentTurn={currentTurn}
                />

                <PlayerInfo
                  name={player.shortName}
                //  bal={player.bal}
                  bal={(player.position && players && players[player.position]) ? players[player.position].bal : null}
                  openPlayerModal={this.openPlayerModal}
                  myId={uid}
                  uid={player.uid}
                  position={player.position}
                  gifts={gifts}
                  fontMode={fontMode}
                />

                {((date && month && (date === 23 || date === 24) && month === 5) || (holidayTest && (selectedTestHoliday[0].id === 'ligo' || selectedTestHoliday[0].id === 'jani'))) && (
                  <div className="ozollapas-image">
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: ozollapasAnimImg,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      height={100}
                      width={60}
                      isStopped={false}
                      isPaused={false}
                    />
                  </div>
                )}

                <PlayerEmotion
                  //  index={index}
                  emotion={player.position && players[player.position] ? players[player.position].emotion : null}
                  posClassName={posClassName}
                />

                {openedPlayerModal && openedPlayerModal === player.position && playerInfo && playerInfo.uid === player.uid && (
                <PlayerModal
                  gifts={gifts}
                  playerInfo={playerInfo}
                  openedPlayerModal={openedPlayerModal}
                  closePlayerModal={this.closePlayerModal}
                  modalOpen={openedPlayerModal && openedPlayerModal === player.position && playerInfo && playerInfo.uid === player.uid}
                    //  showQuitRound={!!(largePlayer && myPos === largePlayer)}
                    //  quitRoundFunction={quitRoundFunction}
                    //  quitRound={quitRound}
                    //  lastRound={lastRound}
                    //  toggleLastRound={this.toggleLastRound}
                    //  lastRoundActive={lastRound || (minGames && minGames > rPlayed)}
                  gameT={gameT}
                  posClassName={posClassName}
                  uid={player.uid}
                  toggleGiftsModal={toggleGiftsModal}
                  renderIgnore={(index === 0 || index === 2 || index === 3)}
                  tournamentRoom={tournamentRoom}
                  ignoredUsers={ignoredUsers}
                  unBlockUser={unBlockUser}
                  toggleBlockUser={this.toggleBlockUser}
                  onBlockHover={this.onBlockHover}
                  blockPopoverOpen={blockPopoverOpen}
                  mId={uid}
                  handleSendAddFriend={this.handleSendAddFriendFunc}
                />
                )}

              </div>
              )}
            </Fragment>
          );
        })}
        <Fragment>
          {/* Last round button */}
          {!tournamentRoom && (
          <Fragment>
            <div className="last-round">
              <Button
                //  disabled={lastRound || (minGames && minGames > party)}
                className={`last-round-button ${(i18n && i18n.language === 'ru') ? 'last-round-button-ru' : null} ${lastRound && 'active'} ${(lastRound || (minGames && minGames > rPlayed + 1)) && 'disabled'}`}
                onClick={this.toggleLastRound}
                id="last-round-popover"
                onMouseEnter={this.onLastRoundHover}
                onMouseLeave={this.onLastRoundHoverLeave}
              >
                <div className="last-round-button-text">
                  {gameT('lastRound')}
                </div>
              </Button>
            </div>
            { isSpeedUnlimited &&
              <div
                className={`hurry-icon-old ${hurryUpPopoverOpen && 'active cursor-pointer'} ${(playerHasToPutCard || (currentPlayer && unlimitedTimerData && unlimitedTimerData[currentPlayer.position])) && 'disabled'}`}
                onClick={this.hurryUp}
                id="hurry-popover"
                onMouseEnter={this.onHurryIconHover}
                onMouseLeave={this.onHurryIconHoverLeave}
              />
              // <div className="hurry-up">
              //   <Button
              //     //  disabled={lastRound || (minGames && minGames > party)}
              //     className={`hurry-up-button ${(i18n && i18n.language === 'ru') ? 'last-round-button-ru' : null} ${(playerHasToPutCard || (currentPlayer && unlimitedTimerData && unlimitedTimerData[currentPlayer.position])) && 'disabled'}`}
              //     onClick={this.hurryUp}
              //     id="hurry-popover"
              //     onMouseEnter={this.onHurryIconHover}
              //     onMouseLeave={this.onHurryIconHoverLeave}
              //   >
              //     <div className="hurry-up-button-text">
              //       {gameT('hurryUp')}
              //     </div>
              //   </Button>
              // </div>
            }
            {(lastRoundPlayerName || (minGames && minGames > rPlayed + 1)) && (
              <Popover container={'div > div'} popperClassName={`last-round-popover popover-font-${fontMode}`} placement="top" isOpen={lastRoundPopoverOpen} target="last-round-popover">
                <PopoverBody className="last-round-popover-body">
                  {lastRoundPlayerName ? (
                    <>
                      {lastRoundReason ? (
                        <div>
                          {t('lastRoundActiveTournament', { player: lastRoundPlayerName })}
                        </div>
                      ) : (
                        <div>
                          {t('lastRoundActive', { player: lastRoundPlayerName })}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {minGames && minGames > rPlayed + 1 && (
                      <div>
                        {t('minGames', { amount: minGames })}
                      </div>
                      )}
                    </>
                  )}
                </PopoverBody>
              </Popover>
            )}
            {/* TODO: Add conditions */}
            {(isSpeedUnlimited) && ( 
              <Popover container={'div > div'} popperClassName={`last-round-popover popover-font-${fontMode}`} placement="top" isOpen={hurryUpPopoverOpen} target="hurry-popover">
                <PopoverBody className="last-round-popover-body">
                  <div>
                    { unlimitedTimerData && currentPlayer && unlimitedTimerData[currentPlayer.position] 
                      ? t('notifications:alreadyHurried')
                      : t('notifications:hurryUp')
                    }
                  </div>
                </PopoverBody>
              </Popover>
            )}
          </Fragment>
          )}

          {/* Quit round button */}
          {!!(gameState === 'play' && largePlayer && myPos === largePlayer) && (
          <div className="quit-round">
            <Button
              className={`quit-round-button ${quitRound && 'active'}`}
              onClick={quitRoundFunction}
            >
              <div className="quit-round-button-text">
                {gameT('quitRound')}
              </div>
            </Button>
          </div>
          )}
          {!!(gameState === 'play' && currentType === 'galdins' && myPos !== sittingOut) && (
            <div className="quit-round">
              <Button
                className={`quit-round-button ${quitRound && 'active'}`}
                onClick={quitRoundFunction}
              >
                <div className="quit-round-button-text">
                  {gameT('quitRound')}
                </div>
              </Button>
            </div>
          )}
        </Fragment>

        <Modal container={'div > div'} isOpen={reportModalOpen} toggle={this.toggleReportModal} className={`notification gifts-send-confirmation old-modal root-font-${fontMode}`} >
          <ModalHeader
            toggle={this.toggleReportModal}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleReportModal} />
            }
          >
            {t('reportTitle')}
          </ModalHeader>
          <ModalBody className="notification-body">
          <div className="d-flex align-items-center flex-column">
            <Alert className="new-design-alert" color={alertStatus} isOpen={alertError && alertStatus}>
              <p>{alertError}</p>
            </Alert>
            <div className="d-flex align-items-center flex-column">
              <Label>{t('reportDescription1')}</Label>
              <Label>{t('reportDescription2')}</Label>
            </div>
            <div className="w-100">
              <Input
                className="contact-support-chat-footer-inputaaa"
                type="textarea"
                name="inputMessageReport"
                id="inputMessageReport"
                autoComplete="off"
                placeholder={`${t('reportReason')}...`}
                maxLength={100}
                value={reportReason}
                onChange={this.reportReasonChange}
              />
              <div className="float-right">{reportReasonLength}/100</div>
            </div>
          </div>
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button type="button" className="btn notification-footer-button" onClick={this.handleSubmitReport}>
              {t('submit')}
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { roomId } = ownProps;

  //  let roomGifts = {};
  //  if (roomId && state.rooms.roomGifts && state.rooms.roomGifts[roomId]) {
  //    roomGifts = state.rooms.roomGifts[roomId];
  //  }

  //  const roomGifts = state.rooms.roomGifts[roomId];

  //  const roomId = match && match.params && match.params.id ? match.params.id : null;

  return {
  //  userSettings: state.userSettings || {},

    //  soundOn={userSettings.soundOn}
    players: state.game.players,
    uid: state.member.uid,
    //  offset:state.member.offset,
    ignoredUsers: state.member.ignoredUsers,
    //  nextTimeStamp:state.game.nextTimeStamp,
  //  game: state.game,
    currentTurn: state.game.currentTurn,
    sittingOut: state.game.sittingOut,
    currentType: state.game.currentType,
    largePlayer: state.game.largePlayer,
    gameState: state.game.globalParams.gameState,
    talking: state.game.globalParams.talking,
    lastRound: state.game.globalParams.lastRound,
    minGames: state.game.globalParams.minGames,
    party: state.game.globalParams.party,
    rPlayed: state.game.globalParams.rPlayed,
    lastRoundPlayer: state.game.globalParams.lastRoundPlayer || null,
    lastRoundReason: state.game.globalParams.lastRoundReason || null,
    //  closeReason:state.game.globalParams.closeReason,
    //  disableTimer:state.game.globalParams.disableTimer,
    //  fastGame:state.game.globalParams.fastGame,
    //  roomClosed:state.game.globalParams.roomClosed,
    currentHand: state.game.globalParams.currentHand,
    //  speed:state.game.globalParams.speed,
    tournamentRoom: state.game.globalParams.tournamentRoom,
    gifts: state.rooms.gifts || null,
    roomGifts: state.rooms.roomGifts ? state.rooms.roomGifts[roomId] : null,
    //  roomGifts: (roomId && state.rooms.roomGifts) ? state.rooms.roomGifts[roomId] : {},
    playerInfo: state.rooms.playerInfo || null,
    speed: state.game.globalParams.speed,
    roomClosed: state.game.globalParams.roomClosed,
    unlimitedTimerData: state.game.unlimitedTimerData,
  };
};

const mapDispatchToProps = {
  fetchUserInfo: getOtherUserInfo,
  startUnlimitedTimerFunction: startUnlimitedTimer,
  sendAFriend: sendFriend
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('notifications')(Players));
