import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';

import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';


import {
  isAndroid,
  isIOS,
  isIOS13,
} from 'react-device-detect';

import CustomModal from '../../Components/Components/Modal';

import * as constants from '../../../../../constants/constants';
import mobileDetector from '../../.././../../images/redesign/common/mobile_detector.png';
import appStore from '../../../../../images/redesign/about-us/appStore.png';
import playStore from '../../../../../images/redesign/about-us/googlePlay.png';

const ANDROID_PATH = {
  lv: 'https://play.google.com/store/apps/details?id=com.zole.app&hl=lv',
  en: 'https://play.google.com/store/apps/details?id=com.zole.app&hl=en',
  ru: 'https://play.google.com/store/apps/details?id=com.zole.app&hl=ru',
};

const IOS_PATH = {
  lv: 'https://apps.apple.com/lv/app/id1505526877',
  en: 'https://apps.apple.com/us/app/id1505526877',
  ru: 'https://apps.apple.com/ru/app/id1505526877',
};

const MobileDetector = ({}) => {

  const { i18n, t } = useTranslation('common');

  const [modalViewed, setModalViewed] = useState(false);
  const [os, setOs] = useState(null);
  const [renderModal, setRenderModal] = useState(false);

  useEffect(() => {
    const history = window.location.pathname || '';

    if (!modalViewed && (isAndroid || isIOS || isIOS13) && history && !history.includes('admin')) {
      setRenderModal(true);
    } else {
      setRenderModal(false);
    }
  }, [isAndroid, isIOS, isIOS13, modalViewed]);

  const closeModal = () => {
    setModalViewed(true);
  };

  return (
    <CustomModal
      isOpen={renderModal}
      className="new-version-modal"
      addClassName="mobile-detector-modal-wrapper"
      toggle={closeModal}
      size="md"
      title={t('common.mobileApp')}
      footer={(
        <Button color="secondary" onClick={closeModal}>
          {t('common.close')}
        </Button>
      )}
      footerClose
      body={(
        <div className="mobile-detector-modal">
          <div className="body-section">
            <div className="mobile-icon d-flex align-items-center">
              <Media src={mobileDetector} alt="x" />
            </div>
            <div className="mobile-content d-flex align-items-center">
              <p>{t('common.downloadMobileAppQuiz')}</p>
            </div>
          </div>
          <div className="footer-section">
            <div className="download-content d-flex align-items-center">
              <p>{t('common.downloadOn')}</p>
            </div>
            <div className="download-icons">
              <div className="about-us-modal-third-row-section">
                <a target="_blank" rel="noopener noreferrer" href={ANDROID_PATH[i18n.language || constants.sendLanguage.lv]}>
                  <Media src={playStore} className="about-us-modal-third-row-img about-us-modal-third-row-img-left" />
                </a>
              </div>
              <div className="about-us-modal-third-row-section">
                <a target="_blank" rel="noopener noreferrer" href={IOS_PATH[i18n.language || constants.sendLanguage.lv]}>
                  <Media src={appStore} className="about-us-modal-third-row-img" />
                </a>
              </div>
            </div>
          </div>

        </div>
      )}
    />
  );
}

export default withTranslation('common')(MobileDetector);
