import { map, size, filter as filterFunc } from 'lodash';
import {
  Firebase, FirebaseRef, LeaderboardRef, UserStatsRef, AdminLogsRef,
} from '../lib/firebase';
import * as constants from '../constants/constants';

export function getLeaderboard() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    LeaderboardRef.child('leaderboard/allTime')
      .orderByChild('pos')
      .startAt(1)
      .endAt(10)
      //  .limitToLast(10)
      .once('value', (snapshot) => {
        const data = snapshot.val() || {};
        return resolve(dispatch({ type: 'LEADERBOARD_REPLACE', data }));
      });
  }).catch((err) => { console.log(err.message); });
}

// getPosition
export function getPositionInLeaderboard() {
  const UID = (
    Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => LeaderboardRef.child(`leaderboard/allTime/${UID}`)
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};


      resolve(dispatch({
        type: 'MY_LEADERBOARD_POS',
        data: {
          position: data.pos,
          points: data.totalPnts,
          gamesPlayed: data.gPlayed,
          name: data.name,
          balance: data.bal,
          lvl: data.lvl,
          rating: data.ratingts || 0,
          gWon: data.gWon || 0,
          bgWon: data.bgWon || 0,
          sgWon: data.sgWon || 0,
          achvCnt: data.achvCnt || 0,
          maxRndInGame: data.maxRndInGame || 0,
        },
      }));
    }));
}

/*
export function getLeaderboardYear() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    LeaderboardRef.child('leaderboard/year')
      .orderByChild('totalPnts')
    //  .startAt(1)
    //  .endAt(10)
      .limitToLast(15)
      .once('value', (snapshot) => {
        const data = snapshot.val() || {};

        return resolve(dispatch({ type: 'LEADERBOARD_YEAR_REPLACE', data }));
      });
  }).catch((err) => { throw err.message; });
}


export function getLeaderboardMonth() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    LeaderboardRef.child('leaderboard/month')
      .orderByChild('totalPnts')
    //  .startAt(1)
    //  .endAt(10)
      .limitToLast(15)
      .once('value', (snapshot) => {
        const data = snapshot.val() || {};

        return resolve(dispatch({ type: 'LEADERBOARD_MONTH_REPLACE', data }));
      });
  }).catch((err) => { throw err.message; });
}


export function getLeaderboardWeek() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    LeaderboardRef.child('leaderboard/week')
      .orderByChild('totalPnts')
      .limitToLast(15)
      .once('value', (snapshot) => {
        const data = snapshot.val() || {};

        return resolve(dispatch({ type: 'LEADERBOARD_WEEK_REPLACE', data }));
      });
  }).catch((err) => { throw err.message; });
}


export function getLeaderboardDaily() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {

    LeaderboardRef.child('leaderboard/daily')
      .orderByChild('totalPnts')
    //  .startAt(1)
    //  .endAt(10)
      .limitToLast(15)
      .once('value', (snapshot) => {
        const data = snapshot.val() || {};

        return resolve(dispatch({ type: 'LEADERBOARD_DAILY_REPLACE', data }));
      });
  }).catch((err) => { throw err.message; });
}

// getPosition
export function getPositionInLeaderboardYear() {
  const UID = (
    Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return false;

  return dispatch => new Promise(resolve => LeaderboardRef.child(`leaderboard/year/${UID}`)
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};

      resolve(dispatch({
        type: 'MY_LEADERBOARD_YEAR_POS',
        data: {
          position: data.pos,
          points: data.totalPnts,
          gamesPlayed: data.gPlayed,
          name: data.name,
          balance: data.bal,
          lvl: data.lvl,
        },
      }));
    }));
}


// getPosition
export function getPositionInLeaderboardMonth() {
  const UID = (
    Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return false;

  return dispatch => new Promise(resolve => LeaderboardRef.child(`leaderboard/month/${UID}`)
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};

      resolve(dispatch({
        type: 'MY_LEADERBOARD_MONTH_POS',
        data: {
          position: data.pos,
          points: data.totalPnts,
          gamesPlayed: data.gPlayed,
          name: data.name,
          balance: data.bal,
          lvl: data.lvl,
        },
      }));
    }));
}


// getPosition
export function getPositionInLeaderboardWeek() {
  const UID = (
    Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return false;

  return dispatch => new Promise(resolve => LeaderboardRef.child(`leaderboard/week/${UID}`)
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};

      resolve(dispatch({
        type: 'MY_LEADERBOARD_WEEK_POS',
        data: {
          position: data.pos,
          points: data.totalPnts,
          gamesPlayed: data.gPlayed,
          name: data.name,
          balance: data.bal,
          lvl: data.lvl,
        },
      }));
    }));
}

// getPosition
export function getPositionInLeaderboardDaily() {
  const UID = (
    Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return false;

  return dispatch => new Promise(resolve => LeaderboardRef.child(`leaderboard/daily/${UID}`)
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};

      resolve(dispatch({
        type: 'MY_LEADERBOARD_DAILY_POS',
        data: {
          position: data.pos,
          points: data.totalPnts,
          gamesPlayed: data.gPlayed,
          name: data.name,
          balance: data.bal,
          lvl: data.lvl,
        },
      }));
    }));
} */

export function getLeaderboardSearch(searchValue, filter, time, showOnlyActive, millionairesOpen, showNoOlderMeOpen) {
  console.log('millionairesOpen', millionairesOpen);
  if (!time || !searchValue) {
    return () => new Promise(resolve => resolve());
  }
  if (!searchValue.length || searchValue.length < 4) {
    return () => new Promise(resolve => resolve());
  }
  if (Firebase === null) return () => new Promise(resolve => resolve());

  let posType = '';
  posType = constants.filterLeaderboardType.filter(item => item.label === filter)[0].action;

  const UID = (
    Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  return dispatch => new Promise((resolve) => {
    let myData;
    let topData;
    let promise2;

    FirebaseRef.child(`users/${UID}`).once('value', (snap) => {
      const userData = snap.val();
      const myCreated = userData.dateCreated;

      const promise1 = new Promise(resolve2 => LeaderboardRef.child(`leaderboard/${time}/${UID}`)
        .once('value', (snapshot) => {
          const data = snapshot.val() || {};

          const position = data[posType] || data.pos;
          myData = {
            position,
            points: data.totalPnts || 0,
            gamesPlayed: data.gPlayed || 0,
            name: data.name,
            balance: data.bal || 0,
            lvl: data.lvl || 1,
            rating: data.ratings || 0,
            gWon: data.gWon || 0,
            bgWon: data.bgWon || 0,
            sgWon: data.sgWon || 0,
            achvCnt: data.achvCnt || 0,
            maxRndInGame: data.maxRndInGame || 0,
            tblsWon: data.tblsWon || 0,
            photo: userData?.photo || data?.photo
          };

          dispatch({
            type: 'LEADERBOARD_SEARCH_REPLACE', data: [], myData, time, filter, millionairesOpen, isLoading: true,
          });
          return resolve2();
        }));
      if (millionairesOpen) {
        promise2 = new Promise((resolve2) => {
          const firstNameQuery = LeaderboardRef.child(`leaderboard/allTime`)
            .orderByChild('first')
            .startAt(searchValue.toLowerCase())
            .endAt(`${searchValue.toLowerCase()}\uf8ff`)
            .once('value');

          const lastNameQuery = LeaderboardRef.child(`leaderboard/allTime`)
            .orderByChild('last')
            .startAt(searchValue.toLowerCase())
            .endAt(`${searchValue.toLowerCase()}\uf8ff`)
            .once('value');
          const thirdNameQuery = LeaderboardRef.child(`leaderboard/allTime`)
            .orderByChild('name')
            .startAt(searchValue.toLowerCase())
            .endAt(`${searchValue.toLowerCase()}\uf8ff`)
            .once('value');

          const adminQuery = AdminLogsRef().child('admins').once('value');

          Promise.all([firstNameQuery, lastNameQuery, thirdNameQuery, adminQuery])
            .then(([firstSnapshot, lastSnapshot, nameSnapshot, adminSnapshot]) => {
              const firstData = firstSnapshot.val() || {};
              const lastData = lastSnapshot.val() || {};
              const nameData = nameSnapshot.val() || {};
              const adminUsers = adminSnapshot.val() || {};

              const data = { ...firstData, ...lastData, ...nameData };
              const adminUserKeys = map(adminUsers, (item, key) => { return key });

              if (showOnlyActive || showNoOlderMeOpen) {
                if (showOnlyActive && showNoOlderMeOpen) {
                  topData = Object.keys(data).filter(key => !adminUserKeys.includes(key) && myCreated && data[key].dateCreated && data[key].dateCreated > myCreated && data[key].bal && data[key].bal > constants.TOP_MILLIONAIRES_RANGE.start && data[key].bal < constants.TOP_MILLIONAIRES_RANGE.end && data[key].lastUpdate && data[key].lastUpdate >= constants.CALC_ACTIVATE_DATE()).reduce((obj, key) => {
                    obj[key] = data[key];
                    return obj;
                  }, {});
                } else if (showOnlyActive) {
                  topData = Object.keys(data).filter(key => !adminUserKeys.includes(key) && data[key].bal && data[key].bal > constants.TOP_MILLIONAIRES_RANGE.start && data[key].bal < constants.TOP_MILLIONAIRES_RANGE.end && data[key].lastUpdate && data[key].lastUpdate >= constants.CALC_ACTIVATE_DATE()).reduce((obj, key) => {
                    obj[key] = data[key];
                    return obj;
                  }, {});
                } else {
                  topData = Object.keys(data).filter(key => !adminUserKeys.includes(key) && myCreated && data[key].dateCreated && data[key].dateCreated > myCreated && data[key].bal && data[key].bal > constants.TOP_MILLIONAIRES_RANGE.start && data[key].bal < constants.TOP_MILLIONAIRES_RANGE.end).reduce((obj, key) => {
                    obj[key] = data[key];
                    return obj;
                  }, {});
                }
              } else {
                topData = Object.keys(data).filter(key => !adminUserKeys.includes(key) && data[key].bal && data[key].bal > constants.TOP_MILLIONAIRES_RANGE.start && data[key].bal < constants.TOP_MILLIONAIRES_RANGE.end).reduce((obj, key) => {
                  obj[key] = data[key];
                  return obj;
                }, {});
              }
              resolve2();
            });
        });
      } else {
        promise2 = new Promise((resolve2) => {
          const firstNameQuery = LeaderboardRef.child(`leaderboard/${time}`)
            .orderByChild('first')
            .startAt(searchValue.toLowerCase())
            .endAt(`${searchValue.toLowerCase()}\uf8ff`)
            .once('value');

          const lastNameQuery = LeaderboardRef.child(`leaderboard/${time}`)
            .orderByChild('last')
            .startAt(searchValue.toLowerCase())
            .endAt(`${searchValue.toLowerCase()}\uf8ff`)
            .once('value');
          const thirdNameQuery = LeaderboardRef.child(`leaderboard/${time}`)
            .orderByChild('name')
            .startAt(searchValue.toLowerCase())
            .endAt(`${searchValue.toLowerCase()}\uf8ff`)
            .once('value');

          const adminQuery = AdminLogsRef().child('admins').once('value');

          Promise.all([firstNameQuery, lastNameQuery, thirdNameQuery, adminQuery])
            .then(([firstSnapshot, lastSnapshot, nameSnapshot, adminSnapshot]) => {
              const firstData = firstSnapshot.val() || {};
              const lastData = lastSnapshot.val() || {};
              const nameData = nameSnapshot.val() || {};
              const adminUsers = adminSnapshot.val() || {};

              console.log('firstData', firstData, lastData, nameData);

              const data = { ...firstData, ...lastData, ...nameData };

              console.log('data', data);

              const adminUserKeys = map(adminUsers, (item, key) => { return key });

              console.log('adminUserKeys', adminUserKeys);

              if (showOnlyActive || showNoOlderMeOpen) {
                if (showOnlyActive && showNoOlderMeOpen) {
                  topData = Object.keys(data).filter(key => !adminUserKeys.includes(key) && myCreated && data[key].dateCreated && data[key].dateCreated > myCreated && data[key].lastUpdate && data[key].lastUpdate >= constants.CALC_ACTIVATE_DATE()).reduce((obj, key) => {
                    obj[key] = data[key];
                    return obj;
                  }, {});
                } else if (showOnlyActive) {
                  topData = Object.keys(data).filter(key => !adminUserKeys.includes(key) && data[key].lastUpdate && data[key].lastUpdate >= constants.CALC_ACTIVATE_DATE()).reduce((obj, key) => {
                    obj[key] = data[key];
                    return obj;
                  }, {});
                } else {
                  topData = Object.keys(data).filter(key => !adminUserKeys.includes(key) && myCreated && data[key].dateCreated && data[key].dateCreated > myCreated && data[key].bal && data[key].bal > constants.TOP_MILLIONAIRES_RANGE.start && data[key].bal < constants.TOP_MILLIONAIRES_RANGE.end).reduce((obj, key) => {
                    obj[key] = data[key];
                    return obj;
                  }, {});
                }
              } else {
                console.log("searchQuery1")
                topData = Object.keys(data).filter(key => !adminUserKeys.includes(key)).reduce((obj, key) => {
                  obj[key] = data[key];
                  return obj;
                }, {});
              }

              console.log('topData', topData);

              resolve2();
            });
        });
      }

      Promise.all([promise1, promise2]).then(() => {
        console.log('promise resolve', topData);
        return resolve(dispatch({
          type: 'LEADERBOARD_SEARCH_REPLACE', data: topData, myData, time, filter, millionairesOpen, isLoading: false,
        }))
      });
    });
  }).catch((err) => { console.log(err.message) });
}

export function getLeaderboardFiltered(filter, time, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filterLevels, onSuccess, reverseOrdering) {

  console.log('getLeaderboardFiltered', filter, time, showOnlyActive, millionairesOpen, showNoOlderMeOpen, filterLevels, onSuccess, reverseOrdering);
  if (Firebase === null) return () => new Promise(resolve => resolve());

  let posType = '';
  posType = constants.filterLeaderboardType.filter(item => item.label === filter)[0].action;
  const UID = (
    Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  return dispatch => new Promise((resolve) => {
    let myData;
    let topData;
    let userCount;

    FirebaseRef.child(`users/${UID}`).once('value', async (snap) => {
      const userData = snap.val();
      const myCreated = userData.dateCreated;

      const promise1 = new Promise(resolve2 => LeaderboardRef.child(`leaderboard/${time}/${UID}`)
        .once('value', (snapshot) => {
          const data = snapshot.val() || {};
          const position = data[posType] || data.pos || 0;
          myData = {
            //  ...data,
            position,
            points: data.totalPnts || 0,
            gamesPlayed: data.gPlayed || 0,
            name: data.name,
            balance: data.bal || 0,
            lvl: data.lvl || 1,
            rating: data.ratings || 0,
            gWon: data.gWon || 0,
            bgWon: data.bgWon || 0,
            sgWon: data.sgWon || 0,
            achvCnt: data.achvCnt || 0,
            maxRndInGame: data.maxRndInGame || 0,
            tblsWon: data.tblsWon || 0,
            dateCreated: data.dateCreated || null,
            photo: userData.photo || data?.photo,
          };
          dispatch({
            type: 'LEADERBOARD_FILTERED_REPLACE', data: [], myData, time, filter, millionairesOpen, isLoading: true,
          });
          return resolve2();
        }));
      let promise2;

      console.log('showNoOlderMeOpen', showNoOlderMeOpen, UID);

      if (showNoOlderMeOpen) {
        promise2 = new Promise(async (resolve2) => {
          const firstNameQuery = await LeaderboardRef.child(`leaderboard/${time}`)
            .orderByChild('dateCreated')
            .startAt(myCreated)
            .once('value');

          const adminQuery = await AdminLogsRef().child('admins').once('value');
          const adminUsers = adminQuery.val() || {};
          const adminUserKeys = map(adminUsers, (item, key) => key);

          const data = firstNameQuery.val() || {};

          console.log('showNoOlderMeOpen data', data);

          if (millionairesOpen) {
            if (showOnlyActive) {
              topData = Object.keys(data).filter(key => !adminUserKeys.includes(key) && data[key].bal >= constants.TOP_MILLIONAIRES_RANGE.start && data[key].bal <= constants.TOP_MILLIONAIRES_RANGE.end && data[key].lastUpdate && data[key].lastUpdate >= constants.CALC_ACTIVATE_DATE()).reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
            } else {
              topData = Object.keys(data).filter(key => !adminUserKeys.includes(key) && myCreated <= data[key].dateCreated && data[key].bal >= constants.TOP_MILLIONAIRES_RANGE.start && data[key].bal <= constants.TOP_MILLIONAIRES_RANGE.end).reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
            }
          } else if (showOnlyActive) {
            topData = filterFunc(data, obj => !adminUserKeys.includes(obj.key) && myCreated <= obj.dateCreated && obj.lastUpdate && obj.lastUpdate >= constants.CALC_ACTIVATE_DATE());
          } else {
            topData = filterFunc(data, obj => !adminUserKeys.includes(obj.key) && myCreated <= obj.dateCreated);
          }

          if (filterLevels && size(filterLevels) > 0) {
            let filterArray = {};
            map(filterLevels, (item) => {
              const filerTemp = filterFunc(topData, obj =>
                obj.lvl && (constants.PLAYER_LEVEL_RANGE[item].from ? obj.lvl >= constants.PLAYER_LEVEL_RANGE[item].from : null) && (constants.PLAYER_LEVEL_RANGE[item].to ? obj.lvl < constants.PLAYER_LEVEL_RANGE[item].to : null));

              filterArray = { ...filterArray, ...filerTemp };
            });
            topData = filterArray;
          }

          return resolve2();
        });
      } else if (reverseOrdering) {
        console.log('******************** IS reverseOrdering ******************');
        promise2 = new Promise(async (resolve2) => {
          let firstNameQuery;
          if (size(filterLevels) > 0) {
            firstNameQuery = await LeaderboardRef.child(`leaderboard/${time}`)
              .orderByChild(filter)
              .startAt(constants.TOP_RANGE.start)
              .endAt(constants.TOP_RANGE.end)
              .limitToLast(3000) // Database reads need some limit, or entire database is read. In production this slows down db.
              .once('value');
          } else {
            firstNameQuery = await LeaderboardRef.child(`leaderboard/${time}`)
              .orderByChild(filter)
              .startAt(constants.TOP_RANGE.start)
              .endAt(constants.TOP_RANGE.end)
              .limitToLast(300)
              .once('value');
          }

          const secondNameQuery = await FirebaseRef.child(`statistics/userCount`).once('value');

          const adminQuery = await AdminLogsRef().child('admins').once('value');

          const data = firstNameQuery.val() || {};
          const userCount = secondNameQuery.val() || {};
          const adminUsers = adminQuery.val() || {};
          const adminUserKeys = map(adminUsers, (item, key) => { return key });

          if (millionairesOpen) {
            if (showOnlyActive) {
              topData = Object.keys(data).filter(key => !adminUserKeys.includes(key) && data[key].bal >= constants.TOP_MILLIONAIRES_RANGE.start && data[key].bal <= constants.TOP_MILLIONAIRES_RANGE.end && data[key].lastUpdate && data[key].lastUpdate >= constants.CALC_ACTIVATE_DATE()).reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
            } else {
              topData = Object.keys(data).filter(key => !adminUserKeys.includes(key) && data[key].bal >= constants.TOP_MILLIONAIRES_RANGE.start && data[key].bal <= constants.TOP_MILLIONAIRES_RANGE.end).reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
            }
          } else if (showOnlyActive) {
            topData = filterFunc(data, obj => !adminUserKeys.includes(obj.key) && obj.lastUpdate && obj.lastUpdate >= constants.CALC_ACTIVATE_DATE());
          } else {
            topData = Object.keys(data).filter(key => !adminUserKeys.includes(key)).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (filterLevels && size(filterLevels) > 0) {
            let filterArray = {};
            map(filterLevels, (item) => {
              const filerTemp = filterFunc(topData, obj =>
                // console.log(obj.lvl, "obj level", constants.PLAYER_LEVEL_RANGE[item].from);
                obj.lvl && (constants.PLAYER_LEVEL_RANGE[item].from ? obj.lvl >= constants.PLAYER_LEVEL_RANGE[item].from : null) && (constants.PLAYER_LEVEL_RANGE[item].to ? obj.lvl < constants.PLAYER_LEVEL_RANGE[item].to : null));

              filterArray = { ...filterArray, ...filerTemp };
            });
            topData = filterArray;
          }

          return resolve2();
        });
      } else if (millionairesOpen) {
        promise2 = new Promise(async (resolve2) => {
          const firstNameQuery = await LeaderboardRef.child(`leaderboard/${time}`)
            .orderByChild('bal')
            .startAt(constants.TOP_MILLIONAIRES_RANGE.start)
            .endAt(constants.TOP_MILLIONAIRES_RANGE.end)
            .once('value');

          const adminQuery = await AdminLogsRef().child('admins').once('value');

          const data = firstNameQuery.val() || {};
          const adminUsers = adminQuery.val() || {};
          const adminUserKeys = map(adminUsers, (item, key) => { return key });

          let data2;

          if (showOnlyActive) {
            const filteredKeys = Object.keys(data).filter(key => !adminUserKeys.includes(key) && data[key].bal >= constants.TOP_MILLIONAIRES_RANGE.start && data[key].bal <= constants.TOP_MILLIONAIRES_RANGE.end && data[key].lastUpdate && data[key].lastUpdate >= constants.CALC_ACTIVATE_DATE());

            const filteredObj = {};
            Object.keys(filteredKeys).map((key) => {
              filteredObj[filteredKeys[key]] = { ...data[filteredKeys[key]] };
              return null;
            });

            data2 = filteredObj;
          } else {
            data2 = Object.keys(data).filter(key => !adminUserKeys.includes(key)).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }

          topData = Object.keys(data2).sort((a, b) => (b[filter] - a[filter])).reduce((obj, key) => {
            obj[key] = data2[key];
            return obj;
          }, {});

          if (filterLevels && size(filterLevels) > 0) {
            let filterArray = {};
            map(filterLevels, (item) => {
              const filerTemp = filterFunc(topData, obj =>
                // console.log(obj.lvl, "obj level", constants.PLAYER_LEVEL_RANGE[item].from);
                obj.lvl && (constants.PLAYER_LEVEL_RANGE[item].from ? obj.lvl >= constants.PLAYER_LEVEL_RANGE[item].from : null) && (constants.PLAYER_LEVEL_RANGE[item].to ? obj.lvl < constants.PLAYER_LEVEL_RANGE[item].to : null));

              filterArray = { ...filterArray, ...filerTemp };
            });
            topData = filterArray;
          }
          return resolve2();
        });
      } else if (showOnlyActive) {
        let showOnlyActiveLimitTo = 350;
        if (time === 'daily' || time === 'week' || time === 'month') {
          showOnlyActiveLimitTo = 100;
        } else if (time === 'year') {
          showOnlyActiveLimitTo = 250;
        }
        promise2 = new Promise(async (resolve2) => {
          let firstNameQuery;
          if (size(filterLevels) > 0) {
            firstNameQuery = await LeaderboardRef.child(`leaderboard/${time}`)
            // .orderByChild('lastUpdate')
            // .startAt(constants.CALC_ACTIVATE_DATE())
              .orderByChild(filter)
              .startAt(constants.TOP_RANGE.start)
              .endAt(constants.TOP_RANGE.end)
              .limitToLast(3000) // Database reads need some limit, or entire database is read. In production this slows down db.
              .once('value');
          } else {
            firstNameQuery = await LeaderboardRef.child(`leaderboard/${time}`)
            // .orderByChild('lastUpdate')
            // .startAt(constants.CALC_ACTIVATE_DATE())
              .orderByChild(filter)
              .startAt(constants.TOP_RANGE.start)
              .endAt(constants.TOP_RANGE.end)
              .limitToLast(showOnlyActiveLimitTo)
              .once('value');
          }

          const adminQuery = await AdminLogsRef().child('admins').once('value');

          const data = firstNameQuery.val() || {};
          const adminUsers = adminQuery.val() || {};
          const adminUserKeys = map(adminUsers, (item, key) => { return key });

          topData = Object.keys(data).filter(key => !adminUserKeys.includes(key) && data[key].lastUpdate && data[key].lastUpdate >= constants.CALC_ACTIVATE_DATE()).reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
          }, {});

          if (filterLevels && size(filterLevels) > 0) {
            let filterArray = {};
            map(filterLevels, (item) => {
              const filerTemp = filterFunc(topData, obj =>
                obj.lvl && (constants.PLAYER_LEVEL_RANGE[item].from ? obj.lvl >= constants.PLAYER_LEVEL_RANGE[item].from : null) && (constants.PLAYER_LEVEL_RANGE[item].to ? obj.lvl < constants.PLAYER_LEVEL_RANGE[item].to : null));

              filterArray = { ...filterArray, ...filerTemp };
            });
            topData = filterArray;
          }
          return resolve2();
        });
      } else {
        promise2 = new Promise(async (resolve2) => {
          let firstNameQuery;
          if (size(filterLevels) > 0) {
            firstNameQuery = await LeaderboardRef.child(`leaderboard/${time}`)
              .orderByChild(filter)
              .startAt(constants.TOP_RANGE.start)
              .endAt(constants.TOP_RANGE.end)
              .limitToLast(3000) // Database reads need some limit, or entire database is read. In production this slows down db.
              .once('value');
          } else {
            firstNameQuery = await LeaderboardRef.child(`leaderboard/${time}`)
              .orderByChild(filter)
              .startAt(constants.TOP_RANGE.start)
              .endAt(constants.TOP_RANGE.end)
              .limitToLast(300)
              .once('value');
          }

          const adminQuery = await AdminLogsRef().child('admins').once('value');
          const data = firstNameQuery.val() || {};

          const adminUsers = adminQuery.val() || {};
          const adminUserKeys = await map(adminUsers, (item, key) => { return key });
          topData = await Object.keys(data).filter(key => !adminUserKeys.includes(key)).reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
          }, {});
        
          // if (filterLevels && filterLevels.length > 0) {
          //   topData = filterFunc(topData, (obj) => {
          //       return filterLevels.some(item => {
          //           return obj.lvl &&
          //               (constants.PLAYER_LEVEL_RANGE[item].from ? obj.lvl >= constants.PLAYER_LEVEL_RANGE[item].from : true) &&
          //               (constants.PLAYER_LEVEL_RANGE[item].to ? obj.lvl < constants.PLAYER_LEVEL_RANGE[item].to : true);
          //       });
          //   });
          // } 
          return resolve2();
        });
      }

      // }slice(topData, [0], [constants.TOP_LIST_LIMIT.limit])


      Promise.all([promise1, promise2]).then(() => {
        resolve(dispatch({
          type: 'LEADERBOARD_FILTERED_REPLACE', data: topData, myData, time, filter, millionairesOpen, isLoading: false, userCount, reverseOrdering,
        }));
        if (onSuccess) {
          onSuccess();
        }
      });
    }).catch((err) => { console.log(err.message); });
  }).catch((err) => { console.log(err.message) });
}

export function getLeaderboardLandingFiltered(filter, time) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise((resolve) => {
    LeaderboardRef.child(`leaderboard/${time}`)
      .orderByChild(filter)
      .startAt(constants.TOP_RANGE.start)
      .endAt(constants.TOP_RANGE.end)
      .limitToLast(constants.TOP_RANGE.landingLimit)
      .once('value', (snapshot) => {
        const topData = snapshot.val() || {};
        resolve(dispatch({
          type: 'LANDING_LEADERBOARD_FILTERED_REPLACE', data: topData, time, filter,
        }));
      });
  }).catch((err) => { console.log(err.message) });
}

export function getLeaderboardMillionaires(showOnlyActive, searchValue) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    LeaderboardRef.child('leaderboard/allTime')
      .orderByChild('bal')
      .startAt(constants.TOP_RANGE.start)
      .endAt(constants.TOP_RANGE.end)
      .limitToLast(constants.TOP_RANGE.limit)
      .once('value', (snapshot) => {
        const data = snapshot.val() || {};
        let topData = {};
        if (showOnlyActive === true) {
          topData = Object.keys(data).filter(key => data[key].lastUpdate !== undefined && data[key].lastUpdate >= constants.CALC_ACTIVATE_DATE() && data[key].first && data[key].first.includes(searchValue.toLowerCase())).reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
          }, {});
        } else if (searchValue !== '') {
          topData = Object.keys(data).filter(key => data[key].first && data[key].first.includes(searchValue.toLowerCase())).reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
          }, {});
        } else {
          topData = data;
        }
        return resolve(dispatch({ type: 'LEADERBOARD_MILLIONAIRES_REPLACE', data: topData }));
      });
  }).catch((err) => { console.log(err.message) });
}

export function getRankingsData(switchOption, filter) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  let filterType = '';
  filterType = constants.POWER_RANKINGS_FILTER.filter(item => item.key === filter)[0].filter;
  const UID = (
    Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  return dispatch => new Promise((resolve) => {
    dispatch({ type: 'POWER_RANKINGS_GET_DATA', data: [], isLoading: true });

    let firebaseProm;
    firebaseProm = UserStatsRef().child('powerRankingsTotal').orderByChild('gPlayed');
    if (filter === constants.POWER_RANKINGS_SUB_NAVIGATION.winPercentage || filter === constants.POWER_RANKINGS_SUB_NAVIGATION.lossPercentage) {
      firebaseProm = firebaseProm.startAt(100);
    } else {
      firebaseProm = firebaseProm.startAt(10);
    }
    firebaseProm.once('value', async (snapshot) => {
      const data = snapshot.val() || {};
      let array = [];
      Object.keys(data).map((key) => {
        array.push({ key, ...data[key] });
      });

      if (filter !== constants.POWER_RANKINGS_SUB_NAVIGATION.lossCount && filter !== constants.POWER_RANKINGS_SUB_NAVIGATION.winsCount && filter !== constants.POWER_RANKINGS_SUB_NAVIGATION.winPercentage && filter !== constants.POWER_RANKINGS_SUB_NAVIGATION.lossPercentage) {
        if (switchOption) {
          array = array.sort((a, b) => a[filterType] - b[filterType]);
        } else {
          array = array.sort((a, b) => b[filterType] - a[filterType]);
        }
      } else {
        array = array.sort((a, b) => b[filterType] - a[filterType]);
      }

      array = array.slice(0, constants.POWER_RANKINGS_SHOW_LIMIT);

      await Promise.all(array.map(async (item, key) => {
        const userFirebaseProm = await FirebaseRef.child(`users/${item.key}`).once('value');
        const user = userFirebaseProm.val();
        if (user) {
          const totalBal = user.bal || 0;
          const totalRating = user.rating || 0;
          const lvl = user.lvl || 0;
          console.log({ totalBal }, { totalRating });
          array[key] = {
            ...item,
            totalBal,
            totalRating,
            lvl,
          };
        }
      }));

      console.log('POWER_RANKINGS_GET_DATA', array);

      return resolve(dispatch({ type: 'POWER_RANKINGS_GET_DATA', data: array, isLoading: false }));
    });
    // if (filter !== constants.POWER_RANKINGS_SUB_NAVIGATION.lossCount && filter !== constants.POWER_RANKINGS_SUB_NAVIGATION.winsCount && filter !== constants.POWER_RANKINGS_SUB_NAVIGATION.winPercentage && filter !== constants.POWER_RANKINGS_SUB_NAVIGATION.lossPercentage) {
    //   if (switchOption) {
    //     firebaseProm = firebaseProm.limitToFirst(constants.POWER_RANKINGS_SHOW_LIMIT);
    //   } else {
    //     firebaseProm = firebaseProm.limitToLast(constants.POWER_RANKINGS_SHOW_LIMIT);
    //   }
    // } else {
    //   firebaseProm = firebaseProm.limitToLast(constants.POWER_RANKINGS_SHOW_LIMIT);
    // }

    // firebaseProm.once('value', (snapshot) => {
    //   const data = snapshot.val() || {};
    //   let array = [];
    //   array = filter(data, (obj, key) => { return obj.gPlayed > constants.POWER_RANKINGS_ROUND_MIN_LIMIT; });
    //   Object.keys(data).map((key) => {
    //     array.push({ key, ...data[key] });
    //   });

    //   if (filter !== constants.POWER_RANKINGS_SUB_NAVIGATION.lossCount && filter !== constants.POWER_RANKINGS_SUB_NAVIGATION.winsCount && filter !== constants.POWER_RANKINGS_SUB_NAVIGATION.winPercentage && filter !== constants.POWER_RANKINGS_SUB_NAVIGATION.lossPercentage) {
    //     if (switchOption) {
    //       array = array.sort((a, b) => a[filterType] - b[filterType]);
    //     } else {
    //       array = array.sort((a, b) => b[filterType] - a[filterType]);
    //     }
    //   } else {
    //     array = array.sort((a, b) => b[filterType] - a[filterType]);
    //   }

    //   return resolve(dispatch({ type: 'POWER_RANKINGS_GET_DATA', data: array }));
    // });
  }).catch((err) => { console.log(err.message) });
}

export async function getUserAvatar(userId) {
  console.log('getUserAvatar', userId);
  if (Firebase === null) return null;

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  console.log('test photo fetch');
  const snap = await FirebaseRef.child(`users/${userId}/photo`).once('value')
  const avatar = snap.val() || null;
  //  console.log(avatar, "avatar===", userId)
  return avatar
}
