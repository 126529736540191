import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

class ScrollGamesHistory extends PureComponent {
  static propTypes = {
    scroll: PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
  //  console.log('componentDidMount');
  }

  componentDidUpdate() {
  //  console.log('componentDidUpdate');
  }

  handleUpButtonPress = () => {
  //  this.UpButtonPressTimer = setTimeout(() => this.scrollUp, 500);
    const { scroll } = this.props;

    this.UpButtonPressTimer = setInterval(() => {
      scroll('up');
    }, 100);
  }

  handleUpButtonRelease = () => {
    clearInterval(this.UpButtonPressTimer);
  }

  handleDownButtonPress = () => {
    const { scroll } = this.props;
    this.downButtonPressTimer = setInterval(() => {
      scroll('down');
    }, 100);
  }

  handleDownButtonRelease = () => {
    clearInterval(this.downButtonPressTimer);
  }

  handleLeftButtonPress = () => {
    const { scroll } = this.props;
    this.leftButtonPressTimer = setInterval(() => {
      scroll('left');
    }, 100);
  }

  handleLeftButtonRelease = () => {
    clearInterval(this.leftButtonPressTimer);
  }

  handleRightButtonPress = () => {
    const { scroll } = this.props;
    this.rightButtonPressTimer = setInterval(() => {
      scroll('right');
    }, 100);
  }

  handleRightButtonRelease = () => {
    clearInterval(this.rightButtonPressTimer);
  }

  render = () => {
    const {
      scroll,
    } = this.props;

    console.log('ScrollGamesHistory render');

    //  if (!scrollRef || scrollRef.containerHeight >= scrollRef.realHeight) {
    //    return null;
    //  }

    return (
      <Fragment>
        <div
          className="scrollArea-buttonUp"
        //  style={{ right: rightOffset, top: topOffset, background: bgColor }}
          onClick={() => scroll('up')}
          onTouchStart={this.handleUpButtonPress}
          onTouchEnd={this.handleUpButtonRelease}
          onMouseDown={this.handleUpButtonPress}
          onMouseUp={this.handleUpButtonRelease}
          onMouseLeave={this.handleUpButtonRelease}
        >
          <div className="scrollArea-buttonUp-img" />
        </div>
        <div
          className="scrollArea-buttonDown"
        //  style={{ right: rightOffset, bottom: bottomOffset, background: bgColor }}
          onClick={() => scroll('down')}
          onTouchStart={this.handleDownButtonPress}
          onTouchEnd={this.handleDownButtonRelease}
          onMouseDown={this.handleDownButtonPress}
          onMouseUp={this.handleDownButtonRelease}
          onMouseLeave={this.handleDownButtonRelease}
        >
          <div className="scrollArea-buttonDown-img" />
        </div>
        <div
          className="scrollArea-buttonLeft"
        //  style={{ bottom: bottomOffset, background: bgColor }}
          onClick={() => scroll('left')}
          onTouchStart={this.handleLeftButtonPress}
          onTouchEnd={this.handleLeftButtonRelease}
          onMouseDown={this.handleLeftButtonPress}
          onMouseUp={this.handleLeftButtonRelease}
          onMouseLeave={this.handleLeftButtonRelease}
        >
          <div className="scrollArea-buttonLeft-img" />
        </div>
        <div
          className="scrollArea-buttonRight"
        //  style={{ right: rightOffset + 16, bottom: bottomOffset, background: bgColor }}
          onClick={() => scroll('right')}
          onTouchStart={this.handleRightButtonPress}
          onTouchEnd={this.handleRightButtonRelease}
          onMouseDown={this.handleRightButtonPress}
          onMouseUp={this.handleRightButtonRelease}
          onMouseLeave={this.handleRightButtonRelease}
        >
          <div className="scrollArea-buttonRight-img" />
        </div>
      </Fragment>
    );
  }
}

export default ScrollGamesHistory;
