import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Media from 'reactstrap/lib/Media';

import closeImg from '../../../../../images/redesign/components/modal/close.png';
import lightCloseImg from '../../../../../images/redesign/light-mode/components/modal/close.png';
// import starsImg from '../../../../images/redesign/components/modal/modal_stars.png';

import * as constants from '../../../../../constants/constants';

const InlineModal = React.memo(({
  isOpen, toggle, body, footer, title, size, onButtonClose, warning, headerStyle, inlineClassName, titleTranslateFlag, screenMode,
}) => {
  const { t } = useTranslation('common');
  return (
    isOpen && (
      <>
      <div className={`inline-modal-front ${inlineClassName || ''}`}>
        <div className="inline-modal-header">
          <div className={warning ? 'inline-title-warning' : 'inline-title'}>
            <div className="inline-modal-title">
              {titleTranslateFlag ? t(`home.${title}`) : title}
            </div>
          </div>
          <Media className='inline-modal-close' src={[constants.SCREEN_MODE.light, constants.SCREEN_MODE.normal].includes(screenMode) ? lightCloseImg : closeImg} alt="X" onClick={toggle || onButtonClose} />
        </div>
        <div className={`inline-modal-body ${footer ? '' : 'inline-modal-body-noFooter'}`} style={{ fontSize: '16px' }}>
          {body}
        </div>
        {footer ? (
          <div>
            {footer}
          </div>
        ) : (null)}
      </div>
      <div className="inline-modal-backdrop" />
      </>
    )
  );
});

InlineModal.propTypes = {
  body: PropTypes.shape(),
  footer: PropTypes.shape(),
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  size: PropTypes.string,
  onButtonClose: PropTypes.func.isRequired,
  warning: PropTypes.bool,
  inlineClassName: PropTypes.string,
  titleTranslateFlag: PropTypes.bool,
};

InlineModal.defaultProps = {
  body: null,
  footer: null,
  title: null,
  isOpen: false,
  size: 'md',
  warning: false,
  inlineClassName: null,
};

export default InlineModal;
