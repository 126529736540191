import { Component, isValidElement, Children, createElement } from "react";
import PropTypes from "prop-types";

// import axios from 'axios';

const inBrowser = typeof navigator !== "undefined";

// these browsers don't fully support navigator.onLine, so we need to use a polling backup

// const unsupportedUserAgentsPattern = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;
const unsupportedUserAgentsPattern = /Windows.*Chrome|Linux.*Chrome/;
//const unsupportedUserAgentsPattern = /Windows.*Firefox|Linux.*Chrome/;
//const unsupportedUserAgentsPattern = /Linux.*Chrome/;

function fetchWithTimeout(url, options, delay, onTimeout) {
  const controller = new AbortController();
  const { signal } = controller;

  const timer = new Promise((resolve) => {
    setTimeout(resolve, delay, {
      timeout: true,
    });
  });
  return Promise.race([
    fetch(url, { ...options, signal }),
    timer
  ]).then(response => {
    if (response.timeout) {
    //  console.log('abort');
      controller.abort();
      onTimeout();

      return { status: 'error', err: 'timeout' };
    }
  //  return response;
    return { status: 'success', res: response };
  }).catch(err => {
  //  console.log(err);
    return { status: 'error', err: err };
  });
}

const ping = ({ url, timeout }) => {
//  console.log('ping');
  return new Promise(resolve => {
    const isOnline = () => resolve(true);
    const isOffline = () => resolve(false);

  /*  const xhr = new XMLHttpRequest();

    xhr.onerror = isOffline;
    xhr.ontimeout = isOffline;
    xhr.onreadystatechange = () => {
      console.log('onreadystatechange');
      console.log(xhr);
      if (xhr.readyState === xhr.HEADERS_RECEIVED) {
        if (xhr.status) {
          isOnline();
        } else {
          isOffline();
        }
      }
    };

    xhr.open("HEAD", url);
    xhr.timeout = timeout;
    xhr.send(); */

  /*  axios({
    //  method: 'get',
      method: 'HEAD',
      headers: {
    	  'Access-Control-Allow-Origin': '*',
    	},
		  mode: 'no-cors',
      url: 'https://ipv4.icanhazip.com/',
      timeout: timeout,    // 4 seconds timeout
    }).then(response => {
      console.log('CONNECTED TO INTERNET');
      isOnline();
    }).catch(error => {
      console.log('INTERNET CONNECTIVITY ISSUE');
      console.log(error);
      isOffline();
    }); */

    fetchWithTimeout('https://dra.spelezoli.lv/test', {
      method: 'HEAD',
      mode: 'no-cors',
      cache: "no-store",
    }, timeout, () => {
      console.log('timed out');
      isOffline();
    }).then((res) => {
    //  console.log('CONNECTED TO INTERNET');
    //  console.log('fetch res');
    //  console.log(res);
      if (res.status === 'success') {
        isOnline();
      } else {
        console.log('fetch err in res');
        console.log(res);
        isOffline();
      }
    });

  /*  .catch((err) => {
    //  console.log('CONNECTED TO INTERNET');
      console.log('fetch err');
      console.log(err);
    //  if (!res.timeout) {
    //  isOffline();
    //  }
  }); */

  /*  fetch('https://www.google.com/', { // Check for internet connectivity
      mode: 'no-cors',
    })
    .then((res) => {
        console.log('CONNECTED TO INTERNET');
        console.log(res);
        isOnline();
    }).catch((err) => {
       console.log('INTERNET CONNECTIVITY ISSUE');
       console.log(err);
       isOffline();
    })  */

  });
};

const propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  polling: PropTypes.oneOfType([
    PropTypes.shape({
      url: PropTypes.string,
      interval: PropTypes.number,
      timeout: PropTypes.number
    }),
    PropTypes.bool
  ]),
  wrapperType: PropTypes.string
};

const defaultProps = {
  polling: true,
  wrapperType: "span"
};

const defaultPollingConfig = {
  enabled: inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent),
  url: "https://dra.spelezoli.lv/",
  interval: 4000,
  timeout: 4500
};

// base class that detects offline/online changes
class Base extends Component {
  constructor() {
    super();
    console.log('navigator.onLine', { onLine: navigator.onLine });
    this.state = {
      online:
        inBrowser && typeof navigator.onLine === "boolean"
          ? navigator.onLine
          : true
    };
    // bind event handlers
    this.goOnline = this.goOnline.bind(this);
    this.goOffline = this.goOffline.bind(this);
  }

  componentDidMount() {
    window.addEventListener("online", this.goOnline);
    window.addEventListener("offline", this.goOffline);

  //  console.log('this.getPollingConfig()');
  //  console.log(this.getPollingConfig());

    if (this.getPollingConfig().enabled) {
      this.startPolling();
    }
  }

  componentWillUnmount() {
    try {
      window.removeEventListener("online", this.goOnline);
      window.removeEventListener("offline", this.goOffline);


      if (this.pollingId) {
        this.stopPolling();
      }
    } catch (err) {
      console.log('err componentWillUnmount');
    }
  }

  renderChildren() {
    const { children, wrapperType } = this.props;

    // usual case: one child that is a react Element
    if (isValidElement(children)) {
      return children;
    }

    // no children
    if (!children) {
      return null;
    }

    // string children, multiple children, or something else
    return createElement(wrapperType, {}, ...Children.toArray(children));
  }

  getPollingConfig() {
    switch (this.props.polling) {
      case true:
        return defaultPollingConfig;
      case false:
        return { enabled: false };
      default:
      //  console.log('case default');
        return Object.assign({}, defaultPollingConfig, this.props.polling);
    }
  }

  goOnline() {
    if (!this.state.online) {
      this.callOnChangeHandler(true);
      this.setState({ online: true });
    }
  }

  goOffline() {
    console.log('goOffline');
    if (this.state.online) {
      this.callOnChangeHandler(false);
      this.setState({ online: false });
    }
  }

  callOnChangeHandler(online) {
    if (this.props.onChange) {
      this.props.onChange(online);
    }
  }

  startPolling() {
    const { interval } = this.getPollingConfig();

    this.pollingId = setInterval(() => {
      const { url, timeout } = this.getPollingConfig();
      ping({ url, timeout }).then(online => {
      //  console.log(`ping result - ${online}`);
        online ? this.goOnline() : this.goOffline();
      });
    }, interval);
  }

  stopPolling() {
    clearInterval(this.pollingId);
  }
}
Base.propTypes = propTypes;
Base.defaultProps = defaultProps;

export class Online extends Base {
  render() {
    return this.state.online ? this.renderChildren() : null;
  }
}
Online.propTypes = propTypes;
Online.defaultProps = defaultProps;

export class Offline extends Base {
  render() {
    return !this.state.online ? this.renderChildren() : null;
  }
}
Offline.propTypes = propTypes;
Offline.defaultProps = defaultProps;

export class Detector extends Base {
  render() {
    return this.props.render({ online: this.state.online });
  }
}
Detector.propTypes = Object.assign({}, propTypes, {
  render: PropTypes.func.isRequired
});

Detector.defaultProps = defaultProps;
