import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import Moment from 'react-moment';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import classNames from 'classnames';

import { Helmet } from 'react-helmet';
// import ReactGA from 'react-ga';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

// import ScrollArea from 'react-scrollbar';
// import ScrollAreaButtons from '../UI/ScrollAreaButtons';
import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import Leaderboard from './TournamentLeaderboard';
import CustomDate from '../UI/CustomDate';

// import keyImg from '../../../images/icons/private.svg';
import closeImg from '../../../images/icons/close.png';
// import speedImg from '../../../images/icons/fast_game.svg';

// import tutorialImage from '../../../images/icons/game_help.webp';
import tutorialImage from '../../../images/icons/help2.webp';

import {
  getPlayerTournamentsHistory,
  getTournamentPlayers,
  getTournamentsHistory,
} from '../../../actions/tournaments';

import {
  FONT_MODE,
} from '../../../constants/constants';
import { getTournamentType } from '../../../utils/tournamentUtils';
import { Input, Label } from 'reactstrap';
import PaginationOld from './PaginationOld';

class TournamentsHistory extends React.PureComponent {
  static propTypes = {
    tournaments: PropTypes.arrayOf(PropTypes.shape()),
    tournamentPlayers: PropTypes.shape(),
    uid: PropTypes.string,
    // showTournamentTutorial: PropTypes.bool,
    toggleShowTournamentTutorial: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    fetchTournamentPlayers: PropTypes.func.isRequired,
    fetchTournamentsHistory: PropTypes.func.isRequired,
    fetchPlayerTournamentsHistory: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
  }

  static defaultProps = {
    tournaments: [],
    tournamentPlayers: {},
    uid: null,
    // showTournamentTutorial: false,
    fontMode: FONT_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      tournamentId: '',
      scrollPos: 0,
      tournamentInfoModalOpen: false,
      selectTournament: {},
      onlyParticipatedTournaments: false,
      pageSize: 20,
      currentPage: 0,
    };

    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    const { fetchTournamentsHistory, fetchPlayerTournamentsHistory, uid } = this.props;

    const { hostname } = window.location;

    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }

    fetchTournamentsHistory(devMode);
    fetchPlayerTournamentsHistory(uid);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.onlyParticipatedTournaments !== this.state.onlyParticipatedTournaments) {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollArea.scrollTop();
      }
    }
  }

  openModal = (id) => {
    this.setState({
      tournamentId: id,
      openModal: true,
    });
  }

  toggle = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }

  fetchTournamentPlayers = (tournamentId) => {
    const { fetchTournamentPlayers } = this.props;

    if (tournamentId) {
      fetchTournamentPlayers(tournamentId).then(() => {
        this.setState({ openModal: true });
      });
    }
  }

  updateScrollPos = (val) => {
    if (val.topPosition || val.topPosition === 0) {
      this.setState({ scrollPos: val.topPosition || 0 });
    }
  //  this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  openTournamentInfoModal = (selectId) => {
    const { tournaments } = this.props;

    this.setState({
      tournamentInfoModalOpen: true,
      selectTournament: {
        ...tournaments.filter(tournament => tournament.id === selectId)[0],
      },
    });
  }

  closeTournamentInfoModal = () => {
    this.setState({
      tournamentInfoModalOpen: false,
    });
  }

  toggleOnlyParticipatedTournaments = () => {
    this.setState(prevState => ({
      onlyParticipatedTournaments: !prevState.onlyParticipatedTournaments,
      currentPage: 0,
    }));
  }

  handlePagination = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  render() {
    const {
      t,
      tournaments,
      playerTournaments,
      uid,
      changeTab,
      tournamentPlayers,
      toggleShowTournamentTutorial,
      fontMode,
    } = this.props;

    const {
      openModal,
      tournamentId,
      // scrollPos,
      selectTournament,
      tournamentInfoModalOpen,
      onlyParticipatedTournaments,
      pageSize,
      currentPage,
    } = this.state;

    const playerTournamentsKeys = Object.keys(playerTournaments);
    const tournamentsShown = (onlyParticipatedTournaments ? tournaments.filter((tournament) => playerTournamentsKeys.includes(tournament.id)) : tournaments)
      .filter((tournament) => tournament.currentState !== 'play' && tournament.currentState !== 'pause');

    let pagesCount = Math.ceil(tournamentsShown.length / pageSize);

    return (
      <>
        <Helmet>
          <title>
            {`Zole - ${t('route.tournaments')}`}
          </title>
        </Helmet>

        {/*  <ScrollAreaButtons
          scrollPos={scrollPos}
        //  show={this.tableScrollbar && this.tableScrollbar.state && this.tableScrollbar.state.containerHeight < this.tableScrollbar.state.realHeight ? true : null}
          show={tournaments && tournaments.length > 7 ? true : null}
          scroll={this.scroll}
          speed={30}
          rightOffset={17}
          topOffset={50}
          bottomOffset={38}
        />
        <ScrollArea
          speed={0.55}
          className="tournaments-scrollarea"
          contentClassName="tournaments-scrollarea-body"
          smoothScrolling
          stopScrollPropagation
          verticalContainerStyle={{
            background: 'transparent',
            opacity: 1,
            width: 11,
          }}
          verticalScrollbarStyle={{
            background: '#fff',
            borderRadius: 1,
            width: 10,
            minHeight: 10,
            minScrollSize: 25,
          }}
          horizontal={false}
          onScroll={this.updateScrollPos}
          ref={(el) => {
            this.tableScrollbar = el;
          }}
        > */}
        <div className="tournaments-filter">
          <Label check
              style={{marginTop: 2, marginLeft: 20, alignItems: 'center'}}
              className="d-flex"
          >

              <Input
                  type="checkbox"
                  id="onlyParticipatedTournaments"
                  name="onlyParticipatedTournaments"
                  onChange={this.toggleOnlyParticipatedTournaments}
                  checked={onlyParticipatedTournaments}
                  className="mt-0"
              />
              <div>{t('tournaments.showParticipatedTournaments')}</div>
          </Label>
        </div>
        {(tournamentsShown.length === 0 && onlyParticipatedTournaments) ? (
          <div className="tournaments-empty-msg">
            {t('tournaments.noParticipatedTournaments')}
          </div>
        ) : (
          <>
            <ScrollAreaWrapper
              className={`tournaments-scrollarea tournaments-scrollarea-font-based-margin-${fontMode} mb-2`}
              contentClassName="tournaments-scrollarea-body tournaments-scrollarea-body-past"
              show={tournaments && tournaments.length > 7 ? true : null}
              rightOffset={17}
              topOffset={39}
              bottomOffset={67}
              scrollRef={this.scrollRef}
            >
              
              {tournaments && tournaments.length && (tournamentsShown.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((tournament, idx) => {
                const { startDate, registerTime } = tournament;
                let registerDate;
                if (startDate && registerTime) {
                  registerDate = startDate - (registerTime * 1000 * 60);
                }
                // console.log(idx, "number");

                return (
                  <Fragment key={tournament.id}>
                    <div className={classNames('active-tournaments', {
                      'active-tournaments-first': idx === 0,
                    })}
                    >
                      <div className="active-tournaments-info">
                        <div className="active-tournaments-info-section">
                          <div className="active-tournaments-info-section-title">
                            <div className="active-tournaments-info-section-title-left">
                              <Media src={tutorialImage} onClick={() => this.openTournamentInfoModal(tournament.id)} className="active-tournaments-info-section-title-left-img" alt="x"/>
                              <span className="active-tournaments-info-section-title-left-title">{tournament.name}</span>
                              <span className="active-tournaments-info-section-title-left-private">
                                { getTournamentType(tournament, t) }
                              </span>
                            </div>
                            <div className="active-tournaments-info-section-title-right history-tournaments-info-section-title-right">
                              <div>{`${t('tournaments.closed')} `}</div>
                            </div>
                          </div>
                          <div className="active-tournaments-info-section-time">
                            <span className="active-tournaments-info-section-time-rounds">{`${t('tournaments.rounds')}:`}</span>
                            <span className="active-tournaments-info-section-time-roundsValue">{tournament.rounds}</span>
                            <span className="active-tournaments-info-section-time-label">{`${t('tournaments.beginning')}:`}</span>
                            <span className="active-tournaments-info-section-time-content"><CustomDate format="DD.MM.YYYY hh:mm" date={startDate} /></span>
                          </div>
                        </div>
                        <div className="active-tournaments-info-button">
                          <Button color="link" className="btn notification-footer-button" onClick={() => this.fetchTournamentPlayers(tournament.id)}>{t('tournaments.result')}</Button>
                        </div>
                      </div>
                      <div className={
                        classNames('active-tournaments-sponsor', {
                          'active-tournaments-sponsor-two': tournament?.sponsorImg && tournament?.prizeImg
                        })}
                      >
                        {
                          tournament?.sponsorImg && (
                            <div className={
                              classNames('active-tournaments-sponsor-section', {
                                'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                              })}
                            >
                              <span>{t('tournaments.sponsorTournaments')}</span>
                              <Media src={tournament?.sponsorImg} alt="x" />
                            </div>
                          )
                        }
                        {
                          tournament?.prizeImg && (
                            <div className={
                              classNames('active-tournaments-sponsor-section', {
                                'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                              })}
                            >
                              <span>{t('tournaments.mainPrize')}</span>
                              <Media src={tournament?.prizeImg} alt="x" />
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </Fragment>
                );
              }))}
            </ScrollAreaWrapper>

            <PaginationOld
              show={tournamentsShown.length > pageSize}
              currentPage={currentPage}
              pagesCount={pagesCount}
              handlePagination={this.handlePagination}
            />
          </>
        )}


        <Modal container={'div > div'} size="lg" isOpen={tournamentInfoModalOpen} toggle={this.closeTournamentInfoModal} className={`old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={this.closeTournamentInfoModal}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeTournamentInfoModal} />
            }
          >
            {selectTournament?.name || t('tournaments.tournaments')}
          </ModalHeader>
          <ModalBody>
            <div className="tournament-info-section">
              <div className={classNames('tournament-info-section-main', {})}>
                {(selectTournament?.sponsorImg || selectTournament?.prizeImg) && (
                  <div className="tournament-info-section-main-row">
                    <div className="d-flex justify-content-center w-100">
                      {selectTournament?.sponsorImg && (
                        <div className="tournament-info-section-main-row-image-container">
                          <span>{t('tournaments.sponsorTournaments')}</span>
                          <Media className="" src={selectTournament?.sponsorImg} alt="x" />
                        </div>
                      )}
                      {selectTournament?.prizeImg && (
                        <div className="tournament-info-section-main-row-image-container">
                          <span>{t('tournaments.mainPrize')}</span>
                          <Media className="" src={selectTournament?.prizeImg} alt="x" />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content">
                    {t('tournaments.gameType')}
                  </div>
                  <div className="tournament-info-section-main-row-value">
                    { getTournamentType(selectTournament, t) }
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content">
                    {t('tournaments.bet')}
                  </div>
                  <div className="tournament-info-section-main-row-value">
                    {`${selectTournament?.bet}`}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content">
                    {t('tournaments.joiningFee')}
                  </div>
                  <div className="tournament-info-section-main-row-value">
                    {`${selectTournament?.entryFee}`}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content">
                    {t('tournaments.bonus')}
                  </div>
                  <div className="tournament-info-section-main-row-value">
                    {`${selectTournament?.bonus}`}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content">
                    {t('tournaments.intervalBetweenRounds')}
                  </div>
                  <div className="tournament-info-section-main-row-value">
                    {`${selectTournament?.newRoundPause} ${t('tournaments.minutes')}`}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content">
                    {t('tournaments.numberOfRounds')}
                  </div>
                  <div className="tournament-info-section-main-row-value">
                    {`${selectTournament?.rounds}`}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content">
                    {t('tournaments.numberOfGamesPerRound')}
                  </div>
                  <div className="tournament-info-section-main-row-value">
                    {`${selectTournament?.roundLength}`}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content">
                    {t('tournaments.startOfTournament')}
                  </div>
                  <div className="tournament-info-section-main-row-value">
                    <CustomDate format="DD.MM.YYYY hh:mm" date={selectTournament?.startDate} />
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content">
                    {t('tournaments.startOfRegistration')}
                  </div>
                  <div className="tournament-info-section-main-row-value">
                    <CustomDate format="DD.MM.YYYY hh:mm" date={selectTournament?.startDate - (selectTournament?.registerTime * 1000 * 60)} />
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content">
                    {t('tournaments.maxNumOfPlayers')}
                  </div>
                  <div className="tournament-info-section-main-row-value">
                    {`${selectTournament?.maxPlayers}`}
                  </div>
                </div>
                <div className='tournament-info-section-main-row'>
                  <div className='tournament-info-section-main-row-content'>
                    {t('tournaments.playerPerRoomLimit')}
                  </div>
                  <div className='tournament-info-section-main-row-value'>
                    {`${selectTournament?.playerPerRoomLimit ? (selectTournament.playerPerRoomLimit === 4 ? t('tournaments.playerPerRoomLimit4Only') : t('tournaments.playerPerRoomLimit3Only')) : t('tournaments.playerPerRoomLimitClasic')}`}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content">
                    {t('tournaments.winningPercent')}
                  </div>
                  <div className="tournament-info-section-main-row-value">
                    {`${selectTournament?.winnerPercent}%`}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="link" className="modal-footer-button" onClick={this.closeTournamentInfoModal}>{t('tournaments.close')}</Button>
          </ModalFooter>
        </Modal>
        {uid && tournamentPlayers && openModal ? (
          <Modal container={'div > div'} returnFocusAfterClose={false} size="lg" isOpen={openModal} toggle={this.toggle} className={`old-modal root-font-${fontMode}`}>
            <ModalHeader
              toggle={this.toggle}
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggle} />
              }
            >
              {t('tournaments.complete')/*t('tournaments.top')*/}
            </ModalHeader>
            <ModalBody>
              <Leaderboard tournament={selectTournament} leaderboard={tournamentPlayers} tournamentId={tournamentId} uid={uid} />
            </ModalBody>
            <ModalFooter>
              <Button color="link" className="modal-footer-button" onClick={this.toggle}>{t('tournaments.close')}</Button>
            </ModalFooter>
          </Modal>
        ) : (null)}
      </>
    );
  }
}

const mapStateToProps = state => ({
  ignoredUsers: state.member.ignoredUsers || {},
  uid: state.member.uid || null,
  playerTournaments: (state.tournaments && state.tournaments.playerTournamentsHistory) ? state.tournaments.playerTournamentsHistory : {},
  tournaments: (state.tournaments && state.tournaments.tournamentsHistory) ? state.tournaments.tournamentsHistory : [],
  tournamentPlayers: (state.tournaments && state.tournaments.tournamentPlayers) ? state.tournaments.tournamentPlayers : {},
});

const mapDispatchToProps = {
  fetchTournamentPlayers: getTournamentPlayers,
  fetchTournamentsHistory: getTournamentsHistory,
  fetchPlayerTournamentsHistory: getPlayerTournamentsHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TournamentsHistory));
