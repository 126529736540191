import React from 'react';
import PropTypes from 'prop-types';

const MinGames = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
          Pie istabas izvēles ir iespējams izvēlēties minimālo partiju skaitu. Pēc noklusējuma minimālais partiju skaits ir 1, tomēr ir iespējams izvēlēties arī:
        </p>
        <ul>
          <li>3 partijas</li>
          <li>5 partijas</li>
          <li>10 partijas</li>
          <li>15 partijas</li>
          <li>20 partijas</li>
          <li>25 partijas</li>
        </ul>
        <p>
          Ja nav izspēlēts noteiktais minimālais partiju skaits, tad nav iespējams izvēlēties opciju ‘Pēdējā Partija’. Ja nav izspēlēts minimālais partiju skaits un spēlētājs patvaļīgi aizver istabu, tad neizspēlēto istabu skaits ietekmēs arī piemēroto soda naudu.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          Выбирая комнату есть возможность выбрать минимальное число партий. Минимальное число партий — одна, но можно выбрать и:
        </p>
        <ul>
          <li>3 партии</li>
          <li>5 партий</li>
          <li>10 партий</li>
          <li>15 партий</li>
          <li>20 партий</li>
          <li>25 партий</li>
        </ul>
        <p>
          Если не разыграно минимальное установленное число партий, то невозможно выбрать опцию «Последняя Партия».Если не разыграно минимальное число партий и игрок самовольно закрывает комнату, то количество неразыгранных комнат повлияет на размер штрафа.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          When choosing the room, player can also choose the minimum amount of rounds. By default, the minimum number of rounds is 1, but player can also choose:
        </p>
        <ul>
          <li>3 rounds</li>
          <li>5 rounds</li>
          <li>10 rounds</li>
          <li>15 rounds</li>
          <li>20 rounds</li>
          <li>25 rounds</li>
        </ul>
        <p>
          If the specified minimum number of rounds has not been played, it is not possible to select the "Last round". If the minimum number of rounds is not played and the player arbitrarily closes the room, then the number of left rounds will also affect the imposed fine.
        </p>
      </>
    )
  }
};

MinGames.propTypes = {
  lang: PropTypes.string,
};

MinGames.defaultProps = {
  lang: 'lv',
};

export default MinGames;
