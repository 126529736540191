import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import RoomsTableRowRender from './RoomsTableRowRender';

const RoomsTableRender = React.memo(({
  rooms, joinedRoom, uid, leaveRoom, activeTournament, finishedTournamentLastRound, popoverOpen, joinRoomClicked, onHover, onHoverLeave, joinRoomClickedFunc, joinPrivateRoomConfirm, fontMode, tooltipId
}) => {
  if (rooms && rooms.length > 0) {
    return (
      <Fragment>
        {rooms.map((room, index) => {
          if (joinedRoom && joinedRoom.key && joinedRoom.globalParams && joinedRoom.playersList && room.key === joinedRoom.key) {
            return null;
          }

          return (
            <RoomsTableRowRender
              key={room.key}
              room={room}
              removal={room.removal}
              joinedRoom={joinedRoom}
              uid={uid}
              leaveRoom={leaveRoom}
              activeTournament={activeTournament}
              finishedTournamentLastRound={finishedTournamentLastRound}
              popoverOpen={popoverOpen}
              joinRoomClicked={joinRoomClicked}
              onHover={onHover}
              onHoverLeave={onHoverLeave}
              joinRoomClickedFunc={joinRoomClickedFunc}
              joinPrivateRoomConfirm={joinPrivateRoomConfirm}
              fontMode={fontMode}
              tooltipId={tooltipId}
            />
          );
        })}
      </Fragment>
    );
  }
  return null;
});

RoomsTableRender.propTypes = {
  rooms: PropTypes.arrayOf(PropTypes.shape()),
  joinedRoom: PropTypes.shape(),
  uid: PropTypes.string,
  leaveRoom: PropTypes.func.isRequired,
  activeTournament: PropTypes.string,
  popoverOpen: PropTypes.bool,
  joinRoomClicked: PropTypes.bool,
  onHover: PropTypes.func.isRequired,
  onHoverLeave: PropTypes.func.isRequired,
  joinRoomClickedFunc: PropTypes.func.isRequired,
  joinPrivateRoomConfirm: PropTypes.func.isRequired,
  tooltipId: PropTypes.string,
};

RoomsTableRender.defaultProps = {
  rooms: [],
  joinedRoom: null,
  uid: null,
  activeTournament: null,
  popoverOpen: false,
  joinRoomClicked: false,
  tooltipId: null,
};

export default RoomsTableRender;
