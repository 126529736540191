import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Label,
  Alert,
  Input,
  Button,
  FormGroup,
  Media,
  ModalFooter,
} from 'reactstrap';
import CustomModal from '../Components/Components/Modal';
import Footer from '../Components/Components/Footer';
import * as constants from '../../../../constants/constants';

const RecoverEmail = React.memo(({ recoverUser, toggleRecoverUser, error, loading, recoverUserSubmit, }) => {
  const { t } = useTranslation('common');
console.log(recoverUser, "recover email");
  return (
    <CustomModal
      onButtonClose={toggleRecoverUser}
      isOpen
      title={t('member.recoverEmail')}
      footer={<Footer loadingSubmit={loading} stage={constants.stages.confirmation} deleteUser={() => recoverUserSubmit(recoverUser)} handleCloseButton={toggleRecoverUser} />}
      size={constants.SCREEN_MODE_TYPE.sm}
      toggle={toggleRecoverUser}
      body={(
        <Row className="forgot-password-section d-flex align-items-center">
          <>
            <Col lg={8} className="forgot-password-section-content mt-2">
              {t('member.previousEmailLogin')}
            </Col>
          </>
        </Row>
      )}
    />
  );
});

export default RecoverEmail;
