import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';


import { Helmet } from 'react-helmet';


const Template = React.memo(({ pageTitle, containerClassName, isLoading, children, isFullscreen, notificationSettings, newDesign }) => {

  console.log('render Template Nothing', { pageTitle, containerClassName, children, isFullscreen, notificationSettings });

  if (newDesign) { //notificationSettings && notificationSettings.newDesign
    return (
      <div className="root-class-design20" style={isFullscreen ? { height: '100vh', width: '100vw ' } : {}}>
        <div className={`${isFullscreen ? 'overflow-manage-redesign' : ''}`} style={{ overflow: 'none' }}>
          <Container fluid className={`${containerClassName} ${isFullscreen ? 'root-class-design20-fullscreen root-class-design20-fullscreen-menu' : ''}`}>
            <div className={`game-bg ${isLoading ? 'loading-bg' : ''}`} />
            <div className="lobby-bg-table" />
            <Helmet>
              <title>{pageTitle}</title>
            </Helmet>

            <Row style={{ height: '100%', zIndex: 2, position: 'relative' }}>
              <Col xs="12" md={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} style={{ padding: 0, position: 'relative' }}>{children}</Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }

  return (
    <div className="test" style={isFullscreen ? { height: '100vh', width: '100vw '} : {}}>
      <div className={`${isFullscreen ? 'overflow-manage' : ''}`} style={{ overflow: 'none' }}>
        <Container className={`${containerClassName} ${isFullscreen ? 'app-wrapper-fullscreen app-wrapper-fullscreen-menu' : ''} app-wrapper `}>
          <div className={`game-bg ${isLoading ? 'loading-bg' : ''}`} />
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>

          <Row style={{ height: '100%' }}>
            <Col sm="12" style={{ padding: 0 }}>{children}</Col>
          </Row>
        </Container>
      </div>
    </div>
  )
});

Template.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.element.isRequired,
  isLoading: PropTypes.bool,
};

Template.defaultProps = {
  pageTitle: 'Zole',
  containerClassName: '',
  isLoading: false,
};

const mapStateToProps = state => ({
  isLoading: state.state.isLoading || {},
  newDesign: state.member.newDesign || false,
  notificationSettings: state.userSettings[state.member.uid] ? (state.userSettings[state.member.uid].notificationSettings) : (state.userSettings.default ? state.userSettings.default.notificationSettings : {}),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Template);
