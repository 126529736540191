import React from 'react';
import { Col, Media } from 'reactstrap';
import PropTypes from 'prop-types';

const PaymentRadioCol = (props) => {
  const { hidden, checked, title, paymentOption, image, onClick, extraPrice, xs, sm, md, lg, xl } = props;

  console.log('PaymentRadioCol', hidden);

  if (hidden) {
    return null;
  }

  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className="payment-type-col payment-type-col-select">
      <div className={`payment-type-radio-wrapper ${checked && "payment-type-radio-wrapper-checked"}`} onClick={() => onClick(paymentOption)}>
        <div className="payment-type-radio-outer-circle">
          { checked &&
            <div className="payment-type-radio-inner-circle"/>
          }
        </div>
        <div className="d-flex justify-content-center payment-type-radio-content">
          <Media src={image}/>
        </div>
        <div className="d-flex justify-content-center payment-type-radio-title">
          {title}
        </div>
        { extraPrice &&
          <div className="d-flex justify-content-center payment-type-radio-title payment-type-radio-title-extra-price">
            {`EUR ${extraPrice}`}
          </div>
        }
      </div>
    </Col>
  );
}

PaymentRadioCol.propTypes = {
  checked: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  paymentOption: PropTypes.shape().isRequired,
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  xs: PropTypes.string,
  sm: PropTypes.string,
  md: PropTypes.string,
  lg: PropTypes.string,
  xl: PropTypes.string,
};

export default PaymentRadioCol;
