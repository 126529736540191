import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Form,
  Label,
  Alert,
  Input,
  Button,
  FormGroup,
} from 'reactstrap';

import ReactGA from 'react-ga';

import * as constants from '../../../../constants/constants';

import { TextInput } from '../Components/Components/TextInput';

const ResetPassword = ({submitResetPassword, password, confirmPass, lang, messageType, error, success, loading, handleInputChange}) => {
  const { t } = useTranslation('common');
  return (
    <>
      <div className="new-design-login-container-body-content-section-form-with d-flex align-items-start">
        <div className="title">
          <Label>{t('home.resetPassword')}</Label>
        </div>
      </div>

      <Row className="new-design-login-container-body-content-section-form-input">
        <Col lg={{ size: 12, offset: 12 }} className="input-col">
          {
            messageType === constants.stages.resetPassword && success ? (
              <Alert className="input-col-message" color="success">{t(`member.${success}`)}</Alert>
            ) : messageType === constants.stages.resetPassword && error ? (
              <Alert className="input-col-message" color="danger">{t(`member.${error}`)}</Alert>
            ) : (null)
          }
          <Form className="common-form">
            <FormGroup>
              <TextInput
                type={constants.PROFILE_FIELDS.password}
                width='100%'
                contentEditable
                inputType={constants.PROFILE_FIELDS.password}
                secondaryPlaceholder={t('home.newPass')}
                initialValue={password}
                onChange={(value, type) => handleInputChange(value, type)}
                maxLength={100}
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                type={constants.PROFILE_FIELDS.password}
                width='100%'
                contentEditable
                inputType={constants.PROFILE_FIELDS.confirmPass}
                secondaryPlaceholder={t('home.confirmPass')}
                initialValue={confirmPass}
                onChange={(value, type) => handleInputChange(value, type)}
                maxLength={100}
              />
            </FormGroup>

            <Row>
              <Col sm="6" className="text-left login-section">
                {/* <Button className="layout-highlighted-button top-settings-new-section-login-button login-section-button d-flex align-items-center" onSubmit={submitResetPassword} disabled={loading}>
                  {loading ? t('home.loading') : t('home.resetPassword') }
                </Button> */}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <div className="new-design-login-container-body-content-section-form-footer">
        <div className="section">
          <Button className="footer-section-button" onClick={submitResetPassword} disabled={loading}>{t('home.reset')}</Button>
        </div>
      </div>
    </>
  );
};

ResetPassword.propTypes = {
  submitResetPassword: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  email: PropTypes.string,
  password: PropTypes.string,
  confirmPass: PropTypes.string,
  lang: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.string,
  loading: PropTypes.bool,
  messageType: PropTypes.string,
};

ResetPassword.defaultProps = {
  email: null,
  confirmPass: null,
  password: null,
  lang: null,
  error: null,
  success: null,
  loading: null,
  messageType: null,
};

export default ResetPassword;
