import React from 'react';
import PropTypes from 'prop-types';
import { isMobileDevice } from '../../../../../utils/deviceUtils';

class PlayerCard extends React.Component {
  static propTypes = {
    card: PropTypes.string,
    allowed: PropTypes.bool,
    gameState: PropTypes.string,
    playCard: PropTypes.func.isRequired,
    currentTurnUid: PropTypes.string,
    memberUid: PropTypes.string,
    selectedCard: PropTypes.string,
    doubleClickSelectedCard: PropTypes.string,
    tableIsInProgress: PropTypes.bool.isRequired,
    cardsLength: PropTypes.number,
    index: PropTypes.number,
    hasPlayedCard: PropTypes.bool,
    hoveredCard: PropTypes.string,
    changeHoveredCard: PropTypes.func.isRequired,
    cardsOnTable: PropTypes.arrayOf(PropTypes.string),
    currentType: PropTypes.string,
    largePlayer: PropTypes.string,
    myPos: PropTypes.string,
    cardsOnLoading: PropTypes.bool,
  }

  static defaultProps = {
    card: null,
    allowed: false,
    gameState: null,
    currentTurnUid: null,
    memberUid: null,
    selectedCard: null,
    doubleClickSelectedCard: null,
    cardsLength: 0,
    index: null,
    hasPlayedCard: null,
    hoveredCard: null,
    cardsOnTable: [],
    currentType: null,
    largePlayer: null,
    myPos: null,
    cardsOnLoading: true,
  }

  constructor(props) {
    super(props);
    this.state = {
    //  hasPlayedCard: false,
    };
  }

  render() {
    const {
      cardsLength,
      card,
      allowed,
      index,
      gameState,
      playCard,
      selectedCard,
      tableIsInProgress,
      memberUid,
      currentTurnUid,
      doubleClickSelectedCard,
      hasPlayedCard,
      hoveredCard,
      changeHoveredCard,
      cardsOnTable,
      currentType,
      largePlayer,
      myPos,
      cardsOnLoading,
    } = this.props;

    let startIndex = 0;
    if (cardsLength <= 1) {
      startIndex = 5;
    } else if (cardsLength <= 3) {
      startIndex = 4;
    } else if (cardsLength <= 5) {
      startIndex = 3;
    } else if (cardsLength <= 7) {
      startIndex = 2;
    } else if (cardsLength <= 9) {
      startIndex = 1;
    }

    let hoveredClass = '';
    if (allowed && currentTurnUid === memberUid) {
      if (hoveredCard === index && !isMobileDevice()) {
        hoveredClass = 'hovered';
      }
      //else if (hoveredCard === index + 1 || hoveredCard === index - 1) {
      //  hoveredClass = 'hoveredNeighbour';
      //}
    }
    // console.log({cardsOnLoading}, {cardsOnTable},{gameState}, "player card")
    return (
      <div key={`hover-${card}`} className={`${hoveredClass}`}>
        <div
          key={card}
          id={`hand-card-${card}`}
          className={`${hoveredClass} card2-wrapper card2-${startIndex + index} ${(allowed && gameState && gameState !== 'choose' && gameState !== 'results' && !tableIsInProgress) ? 'allowed2' : 'blocked'}`}
          onClick={e => playCard(e, { card, allowed })}
          onMouseOver={() => changeHoveredCard(index, card, 'over', allowed)}
          onFocus={() => changeHoveredCard(index, card, 'over', allowed)}
          onMouseOut={() => changeHoveredCard(index, card, 'out', allowed)}
          onBlur={() => changeHoveredCard(index, card, 'out', allowed)}
        >
          <div className="card2-innerWrapper">
            <div className={cardsOnTable.length === 2 && cardsOnTable && cardsOnTable.includes(card) && gameState && largePlayer && largePlayer === myPos && (gameState === 'burry') && 'take-redesign-card'} />
            <div className={`card2 card2-${card} ${(selectedCard === card || (doubleClickSelectedCard === card)) ? 'selected' : ''} `}>
              <div
                key={`overlay-${card}`}
                id={`hand-card-overlay-${card}`}
                className={`${(/*gameState === 'choose' ||*/ (!hasPlayedCard && currentTurnUid && memberUid === currentTurnUid && !allowed && gameState && gameState !== 'choose' && !tableIsInProgress)) ? 'blocked-overlay' : 'display-none'}`}
                onClick={e => playCard(e, { card, allowed })}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default (PlayerCard);
