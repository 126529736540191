/* eslint-disable no-fallthrough */
import { filter } from 'lodash';
import * as constants from '../constants/constants';

const INITIAL_STATE = {};

export default function tourReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'TOUR_LOAD':
      return {
        ...state,
        key: new Date(),
        run: false,
        continuous: true,
        loading: false,
        stepIndex: 0,
        steps: filter(constants.getTourSteps(constants.TOUR_STEP_TYPES.old), ['stepType', constants.TOUR_FIRST_PAGE]),
      };
    case 'TOUR_NEW_LOAD':
      return {
        ...state,
        key: new Date(),
        run: false,
        continuous: true,
        loading: false,
        stepIndex: 0,
        steps: filter(constants.getTourSteps(constants.TOUR_STEP_TYPES.new), ['stepType', constants.TOUR_FIRST_PAGE]),
      };
    case 'TOUR_NEW_LOAD_MOBILE':
      return {
        ...state,
        key: new Date(),
        run: false,
        continuous: true,
        loading: false,
        stepIndex: 0,
        steps: filter(constants.getTourSteps(constants.TOUR_STEP_TYPES.newMobile), ['stepType', constants.TOUR_FIRST_PAGE]),
      };
    case 'TOUR_NEW_LOAD_MOBILE_DESKTOP':
      return {
        ...state,
        key: new Date(),
        run: false,
        continuous: true,
        loading: false,
        stepIndex: 0,
        steps: filter(constants.getTourSteps(constants.TOUR_STEP_TYPES.newMobileDesktop), ['stepType', constants.TOUR_FIRST_PAGE]),
      };
    case 'TOUR_NEW_LOAD_MOBILE_IFRAME':
      return {
        ...state,
        key: new Date(),
        run: false,
        continuous: true,
        loading: false,
        stepIndex: 0,
        steps: filter(constants.getTourSteps(constants.TOUR_STEP_TYPES.newMobileIFrame), ['stepType', constants.TOUR_FIRST_PAGE]),
      };
    case 'TOUR_NEW_LOAD_MOBILE_PORTRAIT':
      return {
        ...state,
        key: new Date(),
        run: false,
        continuous: true,
        loading: false,
        stepIndex: 0,
        steps: filter(constants.getTourSteps(constants.TOUR_STEP_TYPES.newMobilePortrait), ['stepType', constants.TOUR_FIRST_PAGE]),
      };
    case 'TOUR_START':
      return {
        ...state, run: true, stepIndex: 0, steps: filter(constants.getTourSteps(constants.TOUR_STEP_TYPES.old), ['stepType', constants.TOUR_FIRST_PAGE]), completeTour: [],
      };
    case 'TOUR_NEW_START':
      return {
        ...state, run: true, stepIndex: 0, steps: filter(constants.getTourSteps(constants.TOUR_STEP_TYPES.new), ['stepType', constants.TOUR_FIRST_PAGE]), completeTour: [],
      };
    case 'TOUR_NEW_START_MOBILE':
      return {
        ...state, run: true, stepIndex: 0, steps: filter(constants.getTourSteps(constants.TOUR_STEP_TYPES.newMobile), ['stepType', constants.TOUR_FIRST_PAGE]), completeTour: [],
      };
    case 'TOUR_NEW_START_MOBILE_DESKTOP':
      return {
        ...state, run: true, stepIndex: 0, steps: filter(constants.getTourSteps(constants.TOUR_STEP_TYPES.newMobileDesktop), ['stepType', constants.TOUR_FIRST_PAGE]), completeTour: [],
      };
    case 'TOUR_NEW_START_MOBILE_IFRAME':
      return {
        ...state, run: true, stepIndex: 0, steps: filter(constants.getTourSteps(constants.TOUR_STEP_TYPES.newMobileIFrame), ['stepType', constants.TOUR_FIRST_PAGE]), completeTour: [],
      };
    case 'TOUR_NEW_START_MOBILE_PORTRAIT':
      return {
        ...state, run: true, stepIndex: 0, steps: filter(constants.getTourSteps(constants.TOUR_STEP_TYPES.newMobilePortrait), ['stepType', constants.TOUR_FIRST_PAGE]), completeTour: [],
      };
    case 'RESET':
      return { ...state, stepIndex: 0 };
    case 'TOUR_STOP':
      return { ...state, run: false, stepIndex: 0 };
    case 'TOUR_NEXT_STEP': {
      // // console.log(action, "next", (action.index + 1), state.steps[action.index + 1]);
      return { ...state, stepIndex: (action.index + 1) };
    }

    case 'TOUR_PREV_STEP': {
      return { ...state, stepIndex: action.stepIndex, steps: action.steps };
    }
    case 'TOUR_NEW_PREV_STEP': {
      return { ...state, stepIndex: action.stepIndex, steps: action.steps };
    }
    case 'RESTART': {
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
        completeTour: [],
      };
    }
    case 'TOUR_NEXT_PAGE': {
      return { ...state, stepIndex: action.stepIndex, steps: action.steps };
    }
    case 'TOUR_NEW_NEXT_PAGE': {
      return { ...state, stepIndex: action.stepIndex, steps: action.steps };
    }
    // case 'TOUR_BACK_PAGE': {
    //   if (action.step.stepType > 1) {
    //     if ((action.step.stepType - 1) === 2 || (action.step.stepType - 1) === 4) {
    //       return { ...state, stepIndex: 1, steps: filter(constants.tourSteps, ['stepType', action.step.stepType - 1]) };
    //     }
    //     return { ...state, stepIndex: 0, steps: filter(constants.tourSteps, ['stepType', action.step.stepType - 1]) };
    //   }
    // }
    default:
      return state;
  }
}
