import React from 'react';
import PropTypes from 'prop-types';

const Bonuses = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Spēles naudu ir iespējams iegūt arī dažādu bonusu formātā:
        </p>
        <ul>
          <li>500 Zoles monētas par ienākšanu kontā no mobilās (iOS un Android) aplikācijas</li>
          <li>300 Zoles monētas par pirmo reizi izietu spēles Ceļvedi</li>
          <li>300 Zoles monētas kā dāvana vārda dienā</li>
          <li>300 Zoles monētas kā dāvana dzimšanas dienā (GDPR ierobežojumu dēļ pagaidām nav pieejams visās platformās)</li>
          <li>100 Zoles monētas kā dāvana īpašos svētkos (piemēram, Lieldienas, Līgo, Jāņi u.c.)</li>
          <li>spēles administrācijas bonuss par ieguldījumu spēles testēšanā un kļūdu ziņošanā</li>
        </ul>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
        Spēles naudu ir iespējams iegūt arī dažādu bonusu formātā:
        </p>
        <ul>
          <li>500 Zoles monētas par ienākšanu kontā no mobilās (iOS un Android) aplikācijas</li>
          <li>300 Zoles monētas kā dāvana vārda dienā</li>
          <li>300 Zoles monētas kā dāvana dzimšanas dienā (GDPR ierobežojumu dēļ pagaidām nav pieejams visās platformās)</li>
          <li>100 Zoles monētas kā dāvana īpašos svētkos (piemēram, Lieldienas, Līgo, Jāņi u.c.)</li>
          <li>spēles administrācijas bonuss par ieguldījumu spēles testēšanā un kļūdu ziņošanā</li>
        </ul>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          Game coins can also be obtained in various bonus formats:
        </p>
        <ul>
          <li>500 “Zole” coins for logging into an account from a mobile (iOS and Android) APP</li>
          <li>300 Zole” coins as a gift in a name day</li>
          <li>300 “Zole” coins as a gift for a birthday (due to GDPR limitations, it is not yet available on all platforms)</li>
          <li>“Zole” coins as a gift in special celebration (for example, Easter, Summer solstice, etc.)</li>
          <li>game administration bonus for helping in testing and bug reporting.</li>
        </ul>
      </>
    )
  }
};

Bonuses.propTypes = {
  lang: PropTypes.string,
};

Bonuses.defaultProps = {
  lang: 'lv',
};

export default Bonuses;
