import React from 'react';
import classNames from 'classnames';
import Media from 'reactstrap/lib/Media';
import CustomDate from '../../../../Components/Components/CustomDate';
import { getTournamentType } from '../../../../../../../utils/tournamentUtils';

const TournamentInfo = React.memo(({ t, selectTournament }) => (
    <div className='tournament-info-section'>
        <div className={classNames('tournament-info-section-main', {})}>
            {(selectTournament?.sponsorImg || selectTournament?.prizeImg) && (
                <div className="tournament-info-section-main-row">
                    <div className="d-flex justify-content-center w-100">
                        {selectTournament?.sponsorImg && (
                            <div className="tournament-info-section-main-row-image-container">
                                <span>{t('tournaments.sponsorTournaments')}</span>
                                <Media className="" src={selectTournament?.sponsorImg} alt="x" />
                            </div>
                        )}
                        {selectTournament?.prizeImg && (
                            <div className="tournament-info-section-main-row-image-container">
                                <span>{t('tournaments.mainPrize')}</span>
                                <Media className="" src={selectTournament?.prizeImg} alt="x" />
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className='tournament-info-section-main-row'>
                <div className='tournament-info-section-main-row-content'>
                    {t('tournaments.gameType')}
                </div>
                <div className='tournament-info-section-main-row-value'>
                    {getTournamentType(selectTournament, t)}
                </div>
            </div>
            <div className='tournament-info-section-main-row'>
                <div className='tournament-info-section-main-row-content'>
                    {t('tournaments.bet')}
                </div>
                <div className='tournament-info-section-main-row-value'>
                    {`${selectTournament?.bet}`}
                </div>
            </div>
            <div className='tournament-info-section-main-row'>
                <div className='tournament-info-section-main-row-content'>
                    {t('tournaments.joiningFee')}
                </div>
                <div className='tournament-info-section-main-row-value'>
                    {`${selectTournament?.entryFee}`}
                </div>
            </div>
            <div className='tournament-info-section-main-row'>
                <div className='tournament-info-section-main-row-content'>
                    {t('tournaments.bonus')}
                </div>
                <div className='tournament-info-section-main-row-value'>
                    {`${selectTournament?.bonus}`}
                </div>
            </div>
            <div className='tournament-info-section-main-row'>
                <div className='tournament-info-section-main-row-content'>
                    {t('tournaments.intervalBetweenRounds')}
                </div>
                <div className='tournament-info-section-main-row-value'>
                    {`${selectTournament?.newRoundPause} ${t('tournaments.minutes')}`}
                </div>
            </div>
            <div className='tournament-info-section-main-row'>
                <div className='tournament-info-section-main-row-content'>
                    {t('tournaments.numberOfRounds')}
                </div>
                <div className='tournament-info-section-main-row-value'>
                    {`${selectTournament?.rounds}`}
                </div>
            </div>
            <div className='tournament-info-section-main-row'>
                <div className='tournament-info-section-main-row-content'>
                    {t('tournaments.numberOfGamesPerRound')}
                </div>
                <div className='tournament-info-section-main-row-value'>
                    {`${selectTournament?.roundLength}`}
                </div>
            </div>
            <div className='tournament-info-section-main-row'>
                <div className='tournament-info-section-main-row-content'>
                    {t('tournaments.startOfTournament')}
                </div>
                <div className='tournament-info-section-main-row-value'>
                    <CustomDate format="DD.MM.YYYY hh:mm" date={selectTournament?.startDate} />
                </div>
            </div>
            <div className='tournament-info-section-main-row'>
                <div className='tournament-info-section-main-row-content'>
                    {t('tournaments.startOfRegistration')}
                </div>
                <div className='tournament-info-section-main-row-value'>
                    <CustomDate format="DD.MM.YYYY hh:mm" date={selectTournament?.startDate - (selectTournament?.registerTime * 1000 * 60)} />
                </div>
            </div>
            <div className='tournament-info-section-main-row'>
                <div className='tournament-info-section-main-row-content'>
                    {t('tournaments.maxNumOfPlayers')}
                </div>
                <div className='tournament-info-section-main-row-value'>
                    {`${selectTournament?.maxPlayers}`}
                </div>
            </div>
            <div className='tournament-info-section-main-row'>
                <div className='tournament-info-section-main-row-content'>
                {t('tournaments.playerPerRoomLimit')}
                </div>
                <div className='tournament-info-section-main-row-value'>
                {`${selectTournament?.playerPerRoomLimit ? (selectTournament.playerPerRoomLimit === 4 ? t('tournaments.playerPerRoomLimit4Only') : t('tournaments.playerPerRoomLimit3Only')) : t('tournaments.playerPerRoomLimitClasic')}`}
                </div>
            </div>
            <div className='tournament-info-section-main-row'>
                <div className='tournament-info-section-main-row-content'>
                    {t('tournaments.winningPercent')}
                </div>
                <div className='tournament-info-section-main-row-value'>
                    {`${selectTournament?.winnerPercent}%`}
                </div>
            </div>
        </div>
    </div>
));

export default TournamentInfo;