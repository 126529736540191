import React from 'react';
import PropTypes from 'prop-types';
import Media from 'reactstrap/lib/Media';
import rokaImg from '../../../../../../images/redesign/game/Roka.svg';

const PlayerHand = React.memo(({
  gameState, currentHand, playerPosition, currentTurn,
}) => (
  <div className={`player-current-hand-new ${(gameState === 'choose' && currentTurn === playerPosition) ? '' : 'display-none'}`}>
    <div className="player-current-hand-new-text">
      {`${currentHand}.`}
    </div>
    <Media src={rokaImg} className="player-current-hand-new-image" />
  </div>
));

PlayerHand.propTypes = {
  gameState: PropTypes.string,
  currentHand: PropTypes.number,
  playerPosition: PropTypes.string,
  currentTurn: PropTypes.string,
};

PlayerHand.defaultProps = {
  gameState: null,
  currentHand: null,
  playerPosition: null,
  currentTurn: null,
};

export default PlayerHand;
