import React from 'react';
import PropTypes from 'prop-types';

const Friends = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Spēlē ir iespējams uzaicināt arī kādu no saviem draugiem. Pagaidām draugus ir iespējams uzaicināt, ja spēlējat:
        </p>
        <ul>
          <li>draugiem.lv</li>
          <li>Facebook.com</li>
        </ul>

        <p>
          Uzaicinātie un apstiprinātie draugi ir redzami sadaļā <b>{`Mans Info => Mani Draugi`}</b>. Šeit ir pieejama arī papildu informācija par draugiem:
        </p>

        <ul>
          <li>drauga vārds</li>
          <li>uzaicināts spēlēt no sākuma vai arī pats jau spēlē</li>
          <li>laiks, kopš kura jau spēlē</li>
          <li>līmenis</li>
          <li>punktu skaits</li>
          <li>bilance</li>
        </ul>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
          На игру можно пригласить и своих друзей. Пока можете пригласить друзей, если играете на:
        </p>
        <ul>
          <li>draugiem.lv</li>
          <li>Facebook.com</li>
        </ul>

        <p>
          Приглашенные и утвержденные друзья видны в разделе <b>{`Мой Инфо => Мои Друзья`}</b>. Здесь доступна и дополнительная информация о друзьях:
        </p>

        <ul>
          <li>имя друга</li>
          <li>приглашен на игру сначала или уже играет сам</li>
          <li>с какого времени играет</li>
          <li>уровень</li>
          <li>число очков</li>
          <li>баланс</li>
        </ul>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
          Players can also invite friends to the game. At the moment, players can invite friends by playing "Zole" in:
        </p>
        <ul>
          <li>draugiem.lv</li>
          <li>Facebook.com</li>
        </ul>

        <p>
          Invited and confirmed friends can be viewed in <b>{`My Info => My Friends`}</b>. Additional information about friends is also available here:
        </p>

        <ul>
          <li>name;</li>
          <li>invited to play or already was a player;</li>
          <li>since when is playing;</li>
          <li>level;</li>
          <li>amount of points;</li>
          <li>balance.</li>
        </ul>
      </>
    )
  }
};

Friends.propTypes = {
  lang: PropTypes.string,
};

Friends.defaultProps = {
  lang: 'lv',
};

export default Friends;
