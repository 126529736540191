import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as constants from '../../../../../constants/constants';

const SwitchSelect = React.memo(({
  options, selectedOption, selectedOptionParam, selectedOptions, selectFunc, selectFuncParam, multiple, selectAllFunc, trans, transPath, typeOption, innerClassName
}) => {
  const { i18n, t } = useTranslation('common');
  return (
    <>
      {multiple ? (
        <div className={innerClassName}>
          <div className={`switch-select`}>
            <div
              onClick={(() => selectFunc(constants.ALL))}
              className={`switch-select-option switch-select-option-first ${(selectedOptions.length === 0) ? 'switch-select-option-active' : ''}`}
            >
              <div className="switch-select-option-name">
                {
                  (typeOption === "bet" && i18n.language == 'lv') ? t(`common.onlyBetAll`) : t(`common.${constants.ALL}`) 
                }
              </div>
            </div>
            {(options && options.length) ? options.map((option, index) => (
              <div
                onClick={(() => selectFunc(selectFuncParam ? option[selectFuncParam] : option))}
                className={`switch-select-option ${(index === options.length - 1) ? 'switch-select-option-last' : ''} ${(options.length !== selectedOptions.length && selectedOptions.includes(option[selectedOptionParam])) ? 'switch-select-option-active' : ''}`}
              >
                <div className="switch-select-option-name text-uppercase">
                  {
                    trans ? (
                      t(`${transPath}${option.name}`)
                    ) : (
                      option.name
                    )
                  }
                </div>
              </div>
            )) : (null)}
          </div>
        </div>
      ) : (
        <div className={innerClassName}>
          <div className={`switch-select`}>
            {(options && options.length) ? options.map((option, index) => (
              <div
                onClick={(() => selectFunc(selectFuncParam ? option[selectFuncParam] : option))}
                className={`switch-select-option ${index === 0 ? 'switch-select-option-first' : ''} ${(index === options.length - 1) ? 'switch-select-option-last' : ''} ${selectedOption === (option[selectedOptionParam]) ? 'switch-select-option-active' : ''}`}
              >
                <div className="switch-select-option-name text-uppercase">
                  {
                    trans ? (
                      t(`${transPath}${option.name}`)
                    ) : (
                      option.name
                    )
                  }
                </div>
              </div>
            )) : (null)}
          </div>
        </div>
      )}

    </>
  );
});

SwitchSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  selectFunc: PropTypes.func.isRequired,
  selectAllFunc: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
  selectedOptionParam: PropTypes.string,
  typeOption: PropTypes.string,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
  transPath: PropTypes.string,
  selectFuncParam: PropTypes.string,
};

SwitchSelect.defaultProps = {
  options: [],
  selectedOption: null,
  selectedOptionParam: 'value',
  selectedOptions: [],
  multiple: false,
  typeOption: null,
  transPath: 'common.',
  selectFuncParam: 'value',
};

export default SwitchSelect;
