import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Media from 'reactstrap/lib/Media';

const Emotions = React.memo(({
  emotions, setEmotion, openEmotions, openEmotionsFunc,
}) => {
  console.log('Emotions', { openEmotions });

  if (!openEmotions) {
    return null;
  }

  const { t } = useTranslation('playerInfo');

  return (
    <>
      <div className="player-info-modal-backdrop" onClick={openEmotionsFunc} />
      <div className={`emotions ${openEmotions ? ('emotions-open') : ('emotions-closed')}`} toggle={openEmotionsFunc} backdropClassName="player-info-modal-backdrop">

        {/* <div className="player-info-header">
          <div className="player-info-header-title-wrapper">
            <div className="player-info-header-title">
              {t('emotions')}
            </div>
          </div>
        </div> */}
        <div className="player-info-body emotions-section">
          <Row className="emotions-wrapper">
            {emotions && Object.keys(emotions).map(key => (
              <div key={key} className="emotions-emotion">
                <Media
                  src={emotions[key] ? emotions[key].image : null}
                  className={`emotions-emotion-image ${key === 'E6' ? 'emotions-emotion-image-handshake' : null}`}
                  onClick={() => setEmotion(key)}
                />
              </div>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
});

Emotions.propTypes = {
  playerInfo: PropTypes.shape(),
  gifts: PropTypes.shape(),
  openEmotionsFunc: PropTypes.func.isRequired,
  posClassName: PropTypes.string,
  modalOpen: PropTypes.bool,
};

Emotions.defaultProps = {
  playerInfo: null,
  gifts: null,
  posClassName: null,
  modalOpen: false,
};

export default Emotions;
