import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Media,
} from 'reactstrap';

import frameLight from '../../../../../images/redesign/player/frame light.png';
import frame from '../../../../../images/redesign/player/frame.svg';
import { SCREEN_MODE } from '../../../../../constants/constants';
// import config from '../../../../../constants/config';

const Profile = React.memo(({ screenMode, selectedImage, photo, onClick, member, size, loading }) => {
  const { t } = useTranslation('common');

  const changeDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // add 1 to month as it starts from 0
    const year = date.getFullYear();
    if (day && month && year) {
      const formattedDate = `${day}.${month}.${year}`;
      return formattedDate;
    } else {
      return null;
    }
  };

  const isInAppFrame = true//config.isInAppFrame();

  return (
    <div className="profile">
      <div className="profile-body-reg-date text-center ">{`${t('myInfo.registered')}: ${changeDateFormat(member.dateCreated) ? changeDateFormat(member.dateCreated) : t('myInfo.longTimeAgo') }`}</div>
      <div
        className="profile-body d-flex align-items-center"
      >
        <Media
          className={`profile-body-select-img ${isInAppFrame ? 'profile-body-select-img-in-app-frame' : ''}`}
          src={(selectedImage) ? selectedImage : photo}
        />
        <Media
          className={`profile-body-frame-img ${isInAppFrame ? 'profile-body-frame-img-in-app-frame' : ''}`}//"profile-body-frame-img"
          src={screenMode === SCREEN_MODE.light ? frameLight : frame}
        />
        <div>
          <div
            className="profile-body-footer"
            onClick={!loading && onClick}
          >
            {t('home.changePhoto')}
          </div>
        </div>
      </div>
    </div>
  );
});

Profile.propTypes = {
  selectedImage: PropTypes.string,
  photo: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  member: PropTypes.string,
  size: PropTypes.string,
  screenMode: PropTypes.string,
};

Profile.defaultProps = {
  selectedImage: null,
  photo: null,
  member: null,
  size: null,
  screenMode: SCREEN_MODE.normal,
};

export default Profile;
