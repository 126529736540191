import React from 'react';
import PropTypes from 'prop-types';
import { tournamentTutorialText } from '../../../../../constants/tournamentTutorial';
const Tournaments = ({ lang }) => (
  <>
    <div dangerouslySetInnerHTML={{ __html: lang === 'lv' ? tournamentTutorialText.lv : (lang === 'en' ? tournamentTutorialText.en : (lang === 'ru' ? tournamentTutorialText.ru : tournamentTutorialText.lv))}}/>            
  </>
);

export default Tournaments;
