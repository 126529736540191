import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';
import { Col, Row, Button, Label, Media } from 'reactstrap';
import classNames from 'classnames';
import { map } from 'lodash';
import ScrollArea from 'react-scrollbar';
import LanguageSelect from '../Components/Layout/LanguageSelect';
import * as constants from '../../../../constants/constants';

import appstoreButton from '../../../../images/redesign/about-us/appStore.png';
import playstoreButton from '../../../../images/redesign/about-us/googlePlay.png';

// import closeImg from '../../../../images/icons/close.png';
const ANDROID_PATH_lv = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=lv';
const ANDROID_PATH_en = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=en';
const ANDROID_PATH_ru = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=ru';
const IOS_PATH_lv = 'https://apps.apple.com/lv/app/id1505526877';
const IOS_PATH_en = 'https://apps.apple.com/us/app/id1505526877';
const IOS_PATH_ru = 'https://apps.apple.com/ru/app/id1505526877';

const TermsOfUsePage = ({activeMenu, toggleHomeContainer, mobileNavigation, toggleVersioHistory, toggleMobileNavigation}) => {

  const { i18n, t } = useTranslation('common');
  return (
    <Row className="mobile-navigation-container">
      <Col xs="12" className="mobile-navigation-container-body">
        <ScrollArea
          speed={0.55}
          className="mobile-navigation-container-body-area"
          contentClassName="mobile-navigation-container-body-area-content"
          verticalContainerStyle={{
            background: 'transparent',
            opacity: 1,
            width: 10,
          }}
          verticalScrollbarStyle={{
            background: '#fff',
            borderRadius: 1,
            width: 7,
            minHeight: 10,
            minScrollSize: 25,
            display: 'none',
          }}
          horizontal={false}
          // ref={(el) => { this.messagesScrollbar = el; }}
        >
          <div className="mobile-navigation-link d-flex align-items-center">
            <div className="top-settings-new">
              {
                map(constants.TOP_HEADER_NAVIGATION, (item, key) => (
                  
                  <div className={classNames("top-settings-new-section", { "top-settings-new-section-active": item === activeMenu })} onClick={() => toggleHomeContainer(item)}>
                    {
                      item !== constants.TOP_HEADER_NAVIGATION.login && item !== constants.TOP_HEADER_NAVIGATION.lang ? (
                        t(`menu.${item}`)
                      ) : item === constants.TOP_HEADER_NAVIGATION.lang ? (
                        <>
                          <div className="top-settings-new-divider" />
                          <LanguageSelect mobileNavigation={mobileNavigation} />
                        </>
                      ) : (null)
                    }
                  </div>
                ))
              }
            </div>
          </div>
          <div className="mobile-navigation-footer">
            <div className="new-design-landing-container-footer-first mt-4 d-flex align-items-center">
              <div className="support d-flex align-items-center">
                <p className="support-content d-flex align-items-center">{t('home.availableNext')}</p>
                <div className="support-by d-flex align-items-center flex-column">
                  <a target="_blank" rel="noopener noreferrer" href={i18n.language === constants.sendLanguage.lv ? IOS_PATH_lv : i18n.language === constants.sendLanguage.en ? IOS_PATH_en : i18n.language === constants.sendLanguage.ru ? IOS_PATH_ru : IOS_PATH_lv}>
                    <Media className="mr-2" src={appstoreButton} className="about-us-modal-third-row-img mobile-support-store" />
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href={i18n.language === constants.sendLanguage.lv ? ANDROID_PATH_lv : i18n.language === constants.sendLanguage.en ? ANDROID_PATH_en : i18n.language === constants.sendLanguage.ru ? ANDROID_PATH_ru : ANDROID_PATH_lv}>
                    <Media className="mr-2" src={playstoreButton} className="about-us-modal-third-row-img about-us-modal-third-row-img-left" />
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="help-menu d-flex align-items-center mt-4">
              <a className="mr-2" onClick={() => toggleHomeContainer(constants.TOP_HEADER_NAVIGATION.helpPage)}>{t('home.gameHelp')}</a>
              <a className="mr-2" onClick={() => toggleVersioHistory()}>{t('home.versionHistory')}</a>
              <a className="mr-2" onClick={() => toggleHomeContainer(constants.FOOTER_NAVIGATION_LINK.termsOfUse)}>{t('home.termsOfUse')}</a>
              <a className="mr-2">{t('home.contacts')}</a>
            </div> */}
            <div className="help-develop d-flex align-items-center mt-4">
              <p className="help-develop-title">{t('home.developer')}</p>
              <p className="help-develop-content ml-1">SIA “Draxo Games”</p>
            </div>
            <div className="help-manufacture d-flex align-items-center mt-4">
              © SIA “Draxo Games”, 2023
            </div>
          </div>
        </ScrollArea>
      </Col>
    </Row>

  );
}

export default TermsOfUsePage;
