import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import classNames from 'classnames';
import { gsap } from 'gsap';

import ReactGA from 'react-ga';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import NavLink from 'reactstrap/lib/NavLink';

import Countdown from 'react-countdown';

// import styled, { keyframes } from 'styled-components';

import CustomDate from '../../Components/Components/CustomDate';

import CustomModal from '../../Components/Components/Modal';

import wheel from '../../../../../images/redesign/bonus-page/wheel-2.webp';
import glow from '../../../../../images/redesign/bonus-page/wheel-glow.png';
import wheelArrow from '../../../../../images/redesign/bonus-page/wheel-needle.png';

import coinImg from '../../../../../images/redesign/common/coin.svg';

import buttonClickedSound from '../../../../../sounds/click_feedback.flac';

// import InviteFriend from './InviteFriend';
import * as constants from '../../../../../constants/constants';

import {
    spinBonusWheel,
    claimSpinResults,
    getTimeOffset,
    getDailyBonusHistory,
} from '../../../../../actions/member';

import LandScapeLayoutMobileHeader from '../Layout/Mobile/LandScapeLayoutMobileHeader';
import config from '../../../../../constants/config';


/*
const rotate = r => keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(${r}deg);
  }
`;


const Wheel = styled.div`
  display: inline-flex;
  height: 100%;
  animation: ${(props => rotate(props.rotation))} 3.25s ease-in-out 1 forwards;
`;
*/

class BonusPage extends React.Component {
    static propTypes = {
        spinWheel: PropTypes.func.isRequired,
        claimSpin: PropTypes.func.isRequired,
        changeTab: PropTypes.func.isRequired,
        lastBonusSpin: PropTypes.number,
        offset: PropTypes.number,
        t: PropTypes.func.isRequired,
        getOffset: PropTypes.func.isRequired,
        getBonusHistory: PropTypes.func.isRequired,
        doRender: PropTypes.bool,
        activeTab: PropTypes.string,
        toggle: PropTypes.func,
       // userSettings: PropTypes.shape({
      //      soundOn: PropTypes.bool,
      //  }),
    }

    static defaultProps = {
        lastBonusSpin: null,
        offset: 0,
        doRender: true,
        activeTab: null,
      //  userSettings: {},
    };

    constructor(props) {
        super(props);
        this.state = {
            rotation: 0,
            spinResult: 0,
            spinComplete: false,
            spinning: false,
            openHistory: false,
            historyData: null,
        };

        //  this.spinWheel = this.spinWheel.bind(this);
        //  this.confirmResult = this.confirmResult.bind(this);

        this.buttonClickedAudio = new Audio(buttonClickedSound);

        this.wheelRef = React.createRef();
    }

    spinWheel = () => {
        const { spinWheel, claimSpin } = this.props;
    
        this.playButtonSound();
        this.setState({ rotation: 0, spinning: true, stopRotationAngle: null });
        
        let spinToFinish = false;
        const minRotationIncrement = 0.5;
        const maxRotationIncrement = 6.0;
        let rotationIncrement = 1;
        let rotationIncrementReached = 0;
        spinWheel()
            .then((res) => {
                if (!res.data.error) {
                    const { spinResult } = res.data;
                    let stopRotationAngle;
                    switch (spinResult) {
                        case 0:
                        stopRotationAngle = 1075;
                        break;
                        case 10:
                        stopRotationAngle = 1095;
                        break;
                        case 25:
                        stopRotationAngle = 1135;
                        break;
                        case 50:
                        stopRotationAngle = 1115;
                        break;
                        case 100:
                        stopRotationAngle = 1295;
                        break;
                        case 300:
                        stopRotationAngle = 1275;
                        break;
                        case -10:
                        stopRotationAngle = 1055;
                        break;
                        case -25:
                        stopRotationAngle = 1235;
                        break;
                        case -50:
                        stopRotationAngle = 1355;
                        break;
                        case -75:
                        stopRotationAngle = 1195;
                        break;
                        case -100:
                        stopRotationAngle = 1155;
                        break;
                        default:
                        stopRotationAngle = 1075;
                    }
                    this.setState({ stopRotationAngle, spinResult });
                } else {
                    this.setState({ stopRotationAngle: 0, spininng: false });
                    const { getOffset } = this.props;  
                    getOffset();
                }
    
            })
            .catch((error) => {
                console.log("bonus wheel request error", error);
                this.setState({ stopRotationAngle: 0, spininng: false });
            });
      
        // Start spinning the wheel continuously until the stop rotation angle is received
        const spinInterval = setInterval(() => {
            const { rotation, stopRotationAngle } = this.state;
        
            let newRotation = rotation + rotationIncrement;
            if (!spinToFinish) {
                if (rotationIncrement < maxRotationIncrement) { rotationIncrement += 0.1; }
                else { rotationIncrement = maxRotationIncrement; }
                if (newRotation >= 360 ) { newRotation = 0; }
            } else {
                const distanceToFinish = stopRotationAngle - rotation;
                const percentTowardsFinish = distanceToFinish / stopRotationAngle;
                rotationIncrement = minRotationIncrement + (rotationIncrementReached - minRotationIncrement) * Math.pow(percentTowardsFinish, 0.4);
            }
          
            this.setState({ rotation: newRotation });
            gsap.to('.bonus-page-wheel', { rotation: newRotation, duration: 0.01 });
          
            if (stopRotationAngle !== null) {
                if (!spinToFinish && newRotation === 0) {
                    spinToFinish = true;
                    rotationIncrementReached = rotationIncrement;
                }
                else if (spinToFinish && newRotation >= stopRotationAngle) {
                    clearInterval(spinInterval);
                    this.setState({
                        spinComplete: true, spinning: false, rotation: 0,
                    });
                    claimSpin();
                }
            }
        }, 10);
      };

    confirmResult = () => {
        const { changeTab, claimSpin } = this.props;

        claimSpin();

        //  changeTab('1');
        this.setState({ spinResult: 0, spinComplete: false, rotation: 0 });
    }

    playButtonSound = () => {
      const { soundOn } = this.props;

      if (soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
    }

    getBonusHistory = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { getBonusHistory } = this.props;

        getBonusHistory().then((res) => {
            //  if (res && res.history) {
            ReactGA.event({
                category: 'Menu',
                action: 'Open Bonus History',
            });

            this.setState({ openHistory: true, historyData: res.history || null });
            //  }
        });
    }

    closeHistory = () => {
        this.setState({ openHistory: false, historyData: null });
    }

    render() {
        const {
            offset, lastBonusSpin, t, activeTab, toggle, screenMode, hasActiveRegistration, achievementsNotification, handleClickStart, OpenMobileDropdownModal, showUserSettingsModal  // doRender
        } = this.props;

        const {
            rotation, spinComplete, spinResult, spinning, openHistory, historyData,
        } = this.state;

        //  if (!doRender) {
        //    return null;
        //  }

        const notSpined = (lastBonusSpin && new Date(lastBonusSpin).getDate() !== new Date(Date.now() + offset).getDate()) || (!lastBonusSpin);

        //  console.log(`notSpined`, { notSpined });
        const isInAppFrame = config.isInAppFrame();
        return (
            <Fragment>
                {(activeTab && activeTab === '4') ? (
                    <Helmet>
                        <title>
                            Zole - {t('route.bonusPage')}
                        </title>
                    </Helmet>
                ) : (null)}
                <div className="layout-mobile-body layout-mobile-body-bonus-page">
                    <div className="layout-mobile-body-top">
                        <div className="layout-subheader layout-subheader-bonus-page">
                            <div className="layout-subheader-title">
                                {t('bonusPage.dailyBonus')}
                            </div>
                        </div>
                    </div>
                    <div className="layout-mobile-body-main">
                        <div className="layout-subheader-title">
                            {t('bonusPage.dailyBonus')}
                        </div>
                        <div className="bonus-page-container">
                            {!spinComplete ? (
                                <div className='bonus-page-container-wheel'>
                                    <div xs="12" className="bonus-page-wheel-wrapper">
                                        <Media className="bonus-page-wheel-bg" src={glow} alt="" />
                                        {/*   <div style={{
                    position: 'relative', left: 'calc(50% - 20px)', width: '100%', height: '100%',
                  }}
                  >
                      <Wheel rotation={rotation}>  */}
                                        <Media ref={this.wheelRef} id="bonus-page-wheel" className="bonus-page-wheel" src={wheel} alt="" />
                                        <Media
                                            className="bonus-page-wheel-arrow"
                                            src={wheelArrow}
                                            alt=""
                                        />
                                        {/*  </Wheel>
                  </div>  */}
                                    </div>
                                    <div className='bonus-page-container-content'>
                                        {notSpined && (
                                            <>
                                                <Col xs="12" className="bonus-page-text d-flex justify-content-center flex-direction-row">
                                                    <div className="mr-2">
                                                        {t('bonusPage.spinAndWin')}
                                                    </div>
                                                    <div className="bonus-page-money">
                                                        <Media className="bonus-page-money-image" src={coinImg} alt="" />
                                                        <div className="bonus-page-money-text">
                                                            300
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs="12" className="bonus-page-spin-button">
                                                    <Button className={classNames('layout-default-button', {
                                                        'layout-highlighted-button': notSpined,
                                                        'bonus-page-spin-button-disabled': spinning || !notSpined
                                                    })} onClick={this.spinWheel} disabled={spinning || !notSpined}>
                                                        {t('bonusPage.spinWheel')}
                                                    </Button>
                                                    <NavLink
                                                        className="layout-default-button"
                                                        onClick={this.getBonusHistory}
                                                    >
                                                        {t('buyPage.history')}
                                                    </NavLink>
                                                </Col>
                                            </>
                                        )}

                                        {!notSpined && !spinning && (
                                            <Row className="bonus-page-warn">
                                                <Col xs="12">
                                                    <div className="bonus-page-warn-text">
                                                        {t('bonusPage.hoursLimit')}
                                                    </div>
                                                    <Countdown
                                                        date={new Date(Date.now() + offset).setHours(23, 59, 59)}
                                                        now={() => (Date.now() + offset)}
                                                        renderer={props => (
                                                            <div className="spin-timer">
                                                                {`${props.hours < 10 ? `0${props.hours}` : props.hours}:${props.minutes < 10 ? `0${props.minutes}` : props.minutes}:${props.seconds < 10 ? `0${props.seconds}` : props.seconds}`}
                                                            </div>
                                                        )}
                                                        onComplete={() => {
                                                            this.forceUpdate();
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs="12">
                                                    <div className="bonus-page-warn-text">
                                                        {t('bonusPage.lastTimeSpinned')}
                                                    </div>
                                                    {lastBonusSpin && (
                                                        <div className="spin-timer">
                                                            <CustomDate
                                                                format="DD.MM.YYYY hh:mm"
                                                                date={lastBonusSpin}
                                                            />
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col xs="12">
                                                    <NavLink
                                                        className="layout-default-button mt-1"
                                                        onClick={this.getBonusHistory}
                                                    >
                                                        {t('buyPage.history')}
                                                    </NavLink>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className='bonus-page-container-wheel'>
                                    <div className="bonus-page-wheel-wrapper test2">
                                        <Media className="bonus-page-wheel-bg" src={glow} alt="" />
                                        <Media className="bonus-page-wheel" style={{ marginTop: '0' }} src={wheel} alt="" style={{ transform: `rotate(${rotation}deg)` }} />
                                        <Media
                                            className="bonus-page-wheel-arrow"
                                            src={wheelArrow}
                                            alt=""
                                        />
                                    </div>
                                    <div className='bonus-page-container-content'>
                                        <Col xs="12" className="bonus-page-complete-text">
                                            {t('bonusPage.youReceivedBonus')}
                                        </Col>
                                        <Col xs="12" className="bonus-page-complete-money">
                                            <Media className="bonus-page-complete-money-image" src={coinImg} alt="" />
                                            <div className="bonus-page-complete-money-text">
                                                {spinResult}
                                            </div>
                                        </Col>
                                        <Col xs="12" className="bonus-page-complete-button">
                                            <Button className="layout-default-button layout-redHighlighted-button" onClick={this.confirmResult}>
                                                {t('bonusPage.okButton')}
                                            </Button>
                                            <NavLink
                                                className="layout-default-button"
                                                onClick={this.getBonusHistory}
                                            >
                                                {t('buyPage.history')}
                                            </NavLink>
                                        </Col>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>

                    <CustomModal
                        isOpen={openHistory}
                        toggle={this.closeHistory}
                        size="md"
                        title={t('common.bonusHistory')}
                        addClassName="bonus-page-modal"
                        footer={(
                            <Button color="secondary" onClick={this.closeHistory}>{t('common.close')}</Button>
                        )}
                        body={(
                            <>
                                <Row className="money-history-table-header ml-2">
                                    {/* <Col sm="2" /> */}
                                    <Col xs="6" className="money-history-table-header-col money-history-table-header-col-time">
                                        {t('moneyHistory.time')}
                                    </Col>
                                    <Col xs="6" className="money-history-table-header-col">
                                        {t('moneyHistory.bonus')}
                                    </Col>
                                    {/* <Col sm="2" /> */}
                                </Row>
                                <div
                                    className={`player-history-table-scrollarea ${isInAppFrame ? 'player-history-table-scrollarea-in-app-frame' : ''}`}
                                    contentClassName="player-history-table-body"
                                    show
                                    rightOffset={12}
                                    topOffset={23}
                                    bottomOffset={-3}
                                >
                                    {(historyData && Object.keys(historyData).length > 0) ? Object.keys(historyData).map((key, index) => (
                                        <Row key={key} className={`player-history-table-row-table-row ${index % 2 === 0 ? 'odd' : 'even'}`}>
                                            <Col xs="6" className={`player-history-table-col text-center ${isInAppFrame ? 'player-history-table-col-in-app-frame' : ''} player-history-table-col-time`}>
                                                <CustomDate
                                                    format="DD.MM.YYYY hh:mm"
                                                    date={historyData[key].time}
                                                />
                                            </Col>
                                            <Col xs="6" className={`player-history-table-col text-center ${isInAppFrame ? 'player-history-table-col-in-app-frame' : ''} player-history-table-col-highlight`}>
                                                {historyData[key].change}
                                            </Col>
                                        </Row>
                                    )) : <div className="d-flex align-items-center h-100 daily-bonus-history-empty-entries">{t('bonusPage.dailyBonusHistoryEmptyEntries')}</div>}
                                </div>
                            </>
                        )}
                    />
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
  // userSettings: state.userSettings[state.member.uid] || state.userSettings.default || {},
  soundOn: state.userSettings.soundOn,
    rooms: state.rooms.rooms || {},
    offset: state.member.offset,
    lastBonusSpin: state.member.lastBonusSpin,
    uid: state.member.uid || '',
});

const mapDispatchToProps = {
    spinWheel: spinBonusWheel,
    claimSpin: claimSpinResults,
    getOffset: getTimeOffset,
    getBonusHistory: getDailyBonusHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(BonusPage));
