import { constants } from 'react-redux-firebase';
import config from './config';
import { isLandscape, isMobileScreenResolution } from '../utils/screen';

export const FB_APP_ID = '570004516363887';

export const SERVER_STATUS_SUCCESS = 'success';
export const SERVER_STATUS_ERROR = 'error';
export const SERVER_STATUS_CODE = {
  success: 'success',
  error: 'error',
  danger: 'danger',
};

/* key code */
export const ENTERKEY = 13;

export const SPECIALPRICE = 'specialPrice';
export const DISCOUNTPRICE = 'discountPrice';
export const BONUS = 'bonus';

export const CURRENT = 'current';
export const PLANNED = 'planned';

/* Message */
export const ALL = 'all';
export const ZOLE = 'zole';
export const PLAYER = 'player';
export const None = 'none';
export const GAME = 'game';

/* Amin Action logs type */
export const SENDMESSAGE = 'sendMessage';
export const ADDUSERBALANCE = 'addUserBalance';
export const EDITUSER = 'editUser';
export const ADDUSERPOINTS = 'addUserPoints';
export const EDITBAN = 'editBan';
export const CREATEDTOURNAMENT = 'createdTournament';
export const BLOCKEDUSER = 'blockedUser';
export const EDITEDTOURNAMENT = 'editedTournament';
export const DELETEUSER = 'deleteUser';
export const UNBLOCKEDUSER = 'unblockedUser';
export const ADDPROFANITY = 'addProfanity';
export const DELETEPROFANITY = 'deleteProfanity';
export const DELETEDTOURNAMENT = 'deletedTournament';
export const FORCECLOSETOURNAMENT = 'forceCloseTournament';
export const CREATEGIFTCODE = 'createGiftCode';
export const EDITGIFTCODE = 'editGiftCode';
export const DELETEGIFTCODE = 'deleteGiftCode';
export const CREATENEWEVENT = 'createNewEvent';
export const EDITSELECTEVENT = 'editSelectEvent';
export const DELETEEVENT = 'deleteEvent';
export const CREATEDISCOUNT = 'createDiscount';
export const EDITDISCOUNT = 'editDiscount';
export const DELETEDISCOUNT = 'deleteDiscount';

export const adminActionTypes = [
  {
    id: 'sendMessage', bgColor: '#f9803057', changeData: 'undata', changeKey: ['message'],
  },
  {
    id: 'setMessageAsRead', bgColor: '#f9803057', changeData: 'undata', changeKey: ['userName'],
  },
  {
    id: 'addUserBalance', bgColor: '#6960e378', changeData: 'data', changeKey: ['old', 'new'],
  },
  {
    id: 'editUser', bgColor: '#aad5b43d', changeData: 'data', changeKey: ['oldName', 'userName'],
  },
  {
    id: 'editDob', bgColor: '#aad5b43d', changeData: 'data', changeKey: ['birthday'],
  },
  {
    id: 'addUserPoints', bgColor: '#03a9f43b', changeData: 'data', changeKey: ['old', 'new'],
  },
  {
    id: 'editBan', bgColor: '#ed9dc24f', changeData: 'data', changeKey: ['reason', 'blocked', 'endDate'],
  },
  {
    id: 'createdTournament', bgColor: '#e19da342', changeData: 'data', changeKey: ['name'],
  },
  {
    id: 'blockedUser', bgColor: '#c2aee157', changeData: 'undata', changeKey: ['userName'],
  },
  {
    id: 'editedTournament', bgColor: '#cddc392e', changeData: 'data', changeKey: ['changedlog'],
  },
  {
    id: 'unblockedUser', bgColor: '#c2ae1127', changeData: 'undata', changeKey: ['email'],
  },
  {
    id: 'addProfanity', bgColor: '#fd0c5915', changeData: 'missdata', changeKey: ['data'],
  },
  {
    id: 'deleteProfanity', bgColor: '#1d8c3922', changeData: 'missdata', changeKey: ['data'],
  },
  {
    id: 'deletedTournament', bgColor: '#88eea122', changeData: 'undata', changeKey: ['tournamentId'],
  },
  {
    id: 'forceCloseTournament', bgColor: '#007ea111', changeData: 'undata', changeKey: ['tournamentId'],
  },
  {
    id: 'createGiftCode', bgColor: '#c08ea111', changeData: 'data', changeKey: ['code', 'expires', 'playerLimit', 'value'],
  },
  {
    id: 'editGiftCode', bgColor: '#c498a111', changeData: 'data', changeKey: ['changedlog'],
  },
  {
    id: 'deleteGiftCode', bgColor: '#c18ea112', changeData: 'undata', changeKey: ['code'],
  },
  {
    id: 'createNewEvent', bgColor: '#03b9f43b', changeData: 'data', changeKey: ['startDate', 'endDate', 'titleLV', 'descriptionLV', 'titleEN', 'descriptionEN', 'titleRU', 'descriptionRU', 'pageToOpen', 'showInBanner'],
  },
  {
    id: 'editSelectEvent', bgColor: '#03c6f43b', changeData: 'data', changeKey: ['changedlog'],
  },
  {
    id: 'deleteEvent', bgColor: '#03c1f43b', changeData: 'undata', changeKey: ['eventId'],
  },
  {
    id: 'createDiscount', bgColor: '#6510c778', changeData: 'data', changeKey: ['startDate', 'endDate', 'titleLV', 'descriptionLV', 'titleEN', 'descriptionEN', 'titleRU', 'descriptionRU', 'type', 'value', 'showInBanner'],
  },
  {
    id: 'editDiscount', bgColor: '#6930c778', changeData: 'data', changeKey: ['changedlog'],
  },
  {
    id: 'deleteDiscount', bgColor: '#6960c778', changeData: 'undata', changeKey: ['discountId'],
  },
  {
    id: 'deleteUser', bgColor: '#a160c773', changeData: 'undata', changeKey: ['uid', 'name'],
  },
  {
    id: 'sendVerifiedEmail', bgColor: '#b730c773', changeData: 'undata', changeKey: ['uid'],
  },
  {
    id: 'createNewHint', bgColor: '#b730c773', changeData: 'data', changeKey: ['titleLV', 'titleEN', 'titleRU', 'descriptionLV', 'descriptionEN', 'descriptionRU', 'lvImageUrl', 'enImageUrl', 'ruImageUrl', 'showOptions', 'hintType', 'devMode'],
  },
  {
    id: 'editSelectHint', bgColor: '#6930c778', changeData: 'data', changeKey: ['changedlog'],
  },
  {
    id: 'deleteHint', bgColor: '#6960c778', changeData: 'undata', changeKey: ['hindId'],
  },
  {
    id: 'reportMessages', bgColor: '#f9803057', changeData: 'data', changeKey: ['reportedId', 'reportedName', 'message', 'adminView'],
  },
  {
    id: 'reportPlayers', bgColor: '#f9803057', changeData: 'data', changeKey: ['reportedId', 'reportedName', 'reason', 'adminView'],
  },
  {
    id: 'warningMark', bgColor: '#b730c773', changeData: 'data', changeKey: ['warning'],
  },
  {
    id: 'createSurvey', bgColor: '#b730c773', changeData: 'data', changeKey: ['startDate', 'endDate', 'question', 'description', 'questionType', 'devMode', 'answerList'],
  },
  {
    id: 'editSurvey', bgColor: '#6930c778', changeData: 'data', changeKey: ['selectedId', 'startDate', 'endDate', 'question', 'description', 'questionType', 'devMode', 'answerList'],
  },
  {
    id: 'deleteSurvey', bgColor: '#a160c773', changeData: 'data', changeKey: ['selectedId'],
  },
  {
    id: 'designPlatform', bgColor: '#a160c773', changeData: 'data', changeKey: ['old', 'new', 'showReminder', 'frequency', 'devMode'],
  },
  {
    id: 'updateTranslations', bgColor: '#459D9C', changeData: 'data', changeKey: ['key', 'before', 'after'],
  },
  {
    id: 'addMultiAccountPair', bgColor: '#3b1219', changeData: 'data', changeKey: ['uid1', 'uid2'],
  },
  {
    id: 'addMultiAccount', bgColor: '#5c242e', changeData: 'data', changeKey: ['uid1', 'uid2'],
  },
  {
    id: 'removeMultiAccountPair', bgColor: '#4a1b23', changeData: 'data', changeKey: ['uid1', 'uid2'],
  },
  {
    id: 'removeMultiAccount', bgColor: '#362521', changeData: 'data', changeKey: ['uid1', 'uid2'],
  },
  {
    id: 'addBlog', bgColor: '#1e6651', changeData: 'data', changeKey: ['title'],
  },
  {
    id: 'deleteBlog', bgColor: '#204039', changeData: 'data', changeKey: ['id'],
  },
  {
    id: 'updateBlog', bgColor: '#1e3628', changeData: 'data', changeKey: ['id'],
  },
  {
    id: 'enableLogrocket', bgColor: '#45345c', changeData: 'data', changeKey: ['uid'],
  },
  {
    id: 'disableLogrocket', bgColor: '#55436e', changeData: 'data', changeKey: ['uid'],
  },
];

export const adminActionTypesData = 'data';

/* admin room logs */
export const LEFTROOMPENALTY = 'leftRoomPenalty';
export const MINGAMESPENALTY = 'minGamesPenalty';
export const PLAYERJOINED = 'playerJoined';
export const MISSEDTURNPENALTY = 'missedTurnPenalty';

/* soc provider */
export const SOC_PROVIDERS = {
  google: 'google', facebook: 'facebook', draugiem: 'draugiem', inbox: 'inbox', mobile: 'mobile', stripe: 'stripe', everyPay: 'every-pay', apple: 'apple', password: 'password',
};

export const SOC_USER_PROVIDERS = {
  google: 'google', facebook: 'facebook', draugiem: 'draugiem', inbox: 'inbox', apple: 'apple', email: 'email',
};

export const socProvider = ['google', 'facebook', 'draugiem', 'password', 'inbox'];
export const EMAIL_PROVIDERS = 'password';

/* CLOSE REASON */
export const closeReason = [
  {
    id: 'lastRound',
    label: 'Last Round',
  },
  {
    id: 'gameError',
    label: 'Game Error',
  },
  {
    id: 'missedRoomsond',
    label: 'Online/tried to move',
    filterType: 'missedTurn',
    isOnline: true,
    triedToMove: true,
  },
  {
    id: 'missedRoomsont',
    label: 'Online/didn’t try to move',
    filterType: 'missedTurn',
    isOnline: true,
    triedToMove: false,
  },
  {
    id: 'missedRoomsoffd',
    label: 'Offline/tried to move',
    filterType: 'missedTurn',
    isOnline: false,
    triedToMove: true,
  },
  {
    id: 'missedRoomsofft',
    label: 'Offline/didn’t try to move',
    filterType: 'missedTurn',
    isOnline: false,
    triedToMove: false,
  },
  {
    id: 'leftRoom',
    label: 'Close Before End',
    filterType: 'leftRoom',
  },
  {
    id: 'leftBeforeStart',
    label: 'Left Before Start',
    filterType: 'leftBeforeStart',
  },
];

export const ROOMID = 'roomId';
export const TOURNAMENTID = 'tournamentId';
export const USERID = 'userId';
export const LASTROUND = 'lastRound';
export const MISSEDTURN = 'missedTurn';
export const GAMEERROR = 'gameError';
export const LEFTROOM = 'leftRoom';
export const MISSEDROOMSOND = 'missedRoomsond';
export const MISSEDROOMSONT = 'missedRoomsont';
export const MISSEDROOMSOFFD = 'missedRoomsoffd';
export const MISSEDROOMSOFFT = 'missedRoomsofft';
export const LeftBeforeStart = 'leftBeforeStart';
export const FILTER_CLOSE_REASON = {
  gameError: 'gameError',
  lastRound: 'lastRound',
  missedTurn: 'missedTurn',
  missedRoomsond: 'missedRoomsond',
  missedRoomsont: 'missedRoomsont',
  missedRoomsoffd: 'missedRoomsoffd',
  missedRoomsofft: 'missedRoomsofft',
  leftRoom: 'leftRoom',
  leftBeforeStart: 'leftBeforeStart',
};

/* leaderboard */
export const POINTPOS = 'pointsPos';

export const filterLeaderboardType = [
  {
    id: '1', label: 'totalPnts', action: 'pointsPos', name: 'totalPnts', value: 'totalPnts', tLabel: 'points', isSortingParam: true,
  },
  {
    id: '2', label: 'bal', action: 'balPos', name: 'bal', value: 'bal', tLabel: 'balance', isSortingParam: true,
  },
  {
    id: '3', label: 'gPlayed', action: 'gPlayedPos', name: 'gPlayed', value: 'gPlayed', tLabel: 'gPlayed', isSortingParam: true,
  },
  {
    id: '9', label: 'gWon', action: 'gWonPos', name: 'gWon', value: 'gWon', tLabel: 'gWon',
  },
  {
    id: '4', label: 'bgWon', action: 'bgWonPos', name: 'bgWon', value: 'bgWon', tLabel: 'largeZole',
  },
  {
    id: '5', label: 'sgWon', action: 'sgWonPos', name: 'sgWon', value: 'sgWon', tLabel: 'smallZole',
  },
  {
    id: '6', label: 'tblsWon', action: 'tblsWonPos', name: 'tblsWon', value: 'tblsWon', tLabel: 'winningTables',
  },
  {
    id: '7', label: 'achvCnt', action: 'achvCntPos', name: 'achvCnt', value: 'achvCnt', tLabel: 'archievements',
  },
  {
    id: '8', label: 'maxRndInGame', action: 'maxRndInGamePos', name: 'maxRndInGame', value: 'maxRndInGame', tLabel: 'maxNumberOfPartiesInRoom',
  },
];
export const filterLeaderboardTypeVal = {
  totalPnts: '1', bal: '2', gPlayed: '3', bgWon: '4', sgWon: '5', tblsWon: '6', achvCnt: '7', maxRndInGame: '8', gWon: '9',
};

export const filterLeaderboardSortingType = [
  {
    id: '1', label: 'totalPnts', action: 'pointsPos', name: 'points', value: 'totalPnts', tLabel: 'points',
  },
  {
    id: '2', label: 'bal', action: 'balPos', name: 'balance', value: 'bal', tLabel: 'balance',
  },
  {
    id: '3', label: 'gPlayed', action: 'gPlayedPos', name: 'gPlayed', value: 'gPlayed', tLabel: 'gPlayed',
  },
];

export const filterLeaderboardExtraColumns = [
  {
    id: '9', label: 'gWon', action: 'gWonPos', name: 'gWon', value: 'gWon', tLabel: 'gWon',
  },
  {
    id: '4', label: 'bgWon', action: 'bgWonPos', name: 'largeZole', value: 'bgWon', tLabel: 'largeZole',
  },
  {
    id: '5', label: 'sgWon', action: 'sgWonPos', name: 'smallZole', value: 'sgWon', tLabel: 'smallZole',
  },
  {
    id: '6', label: 'tblsWon', action: 'tblsWonPos', name: 'winningTables', value: 'tblsWon', tLabel: 'winningTables',
  },
  {
    id: '7', label: 'achvCnt', action: 'achvCntPos', name: 'archievements', value: 'achvCnt', tLabel: 'archievements',
  },
  {
    id: '8', label: 'maxRndInGame', action: 'maxRndInGamePos', name: 'maxNumberOfPartiesInRoom', value: 'maxRndInGame', tLabel: 'maxNumberOfPartiesInRoom',
  },
];

export const LEADERBOARD_RESULT_TYPES = {
  leaderboard: 'leaderboard',
  myLeaderboard: 'myLeaderboard',
  leaderboardMillionaires: 'leaderboardMillionaires',
  leaderboardSearch: 'leaderboardSearch',
  myLeaderboardSearch: 'myLeaderboardSearch',
  leaderboardMillionairesSearch: 'leaderboardMillionairesSearch',
  landingLeaderboard: 'landingLeaderboard',
};

export const LEADERBOARD_FILTER_TYPES = {
  totalPnts: 'totalPnts',
  bal: 'bal',
  gPlayed: 'gPlayed',
  bigGamesWon: 'bigGamesWon',
  smallGamesWon: 'smallGamesWon',
  gWon: 'gWon',
  bgWon: 'bgWon',
  sgWon: 'sgWon',
  tblsWon: 'tblsWon',
  achvCnt: 'achvCnt',
  maxRndInGame: 'maxRndInGame',
};

export const filterLeaderboardActive = [
  {
    id: '5', label: 'allTime', name: 'allTime', value: 'allTime', tLabel: 'allTime',
  },
  {
    id: '1', label: 'daily', name: 'today', value: 'daily', tLabel: 'today',
  },
  {
    id: '2', label: 'week', name: 'thisWeek', value: 'week', tLabel: 'thisWeek',
  },
  {
    id: '3', label: 'month', name: 'thisMonth', value: 'month', tLabel: 'thisMonth',
  },
  {
    id: '4', label: 'year', name: 'thisYear', value: 'year', tLabel: 'thisYear',
  },
];
export const filterLeaderboardActiveTypes = {
  allTime: '5', daily: '1', week: '2', month: '3', year: '4',
};
export const FILTER_LEADERBOARD_ACTIVE_TYPES = {
  allTime: 'allTime', daily: 'daily', week: 'week', month: 'month', year: 'year',
};

export const TOP_LEADERBOARD_ORDER = {
  place: 'place', player: 'name', level: 'lvl', ratings: 'ratings', balance: 'balance', points: 'points', rounds: 'gamesPlayed', gWon: 'gWon', bgWon: 'bgWon', sgWon: 'sgWon', tblsWon: 'tblsWon', achvCnt: 'achvCnt', maxRndInGame: 'maxRndInGame',
};

export const SHOW_PLACE_FILEDS = ['gWon', 'bgWon', 'sgWon', 'tblsWon', 'achvCnt', 'maxRndInGame'];

export const ORDER_BY_TYPE = {
  asc: 'asc', desc: 'desc',
};

export const PLAYER_LEVEL_TYPE = {
  pro: 'pro', fans: 'fans', great: 'great', good: 'good', medium: 'medium', untrained: 'untrained', beginner: 'beginner', weak: 'weak',
};

export const PLAYER_LEVEL_RANGE = {
  weak: { from: 1, to: 10 },
  beginner: { from: 10, to: 20 },
  untrained: { from: 20, to: 30 },
  medium: { from: 30, to: 40 },
  good: { from: 40, to: 50 },
  great: { from: 50, to: 60 },
  fans: { from: 60, to: 70 },
  pro: { from: 70 },
};

export const TOP_SELECTION_TYPE = {
  topPeriod: 'topPeriod', topLevel: 'topLevel',
};

export const PLAYER_LEVEL_TYPE_FIELDS = [
  {
    id: '1', name: 'pro', value: 'pro', from: 70,
  },
  {
    id: '2', name: 'fans', value: 'fans', from: '60', to: '70',
  },
  {
    id: '3', name: 'great', value: 'great', from: '50', to: '60',
  },
  {
    id: '4', name: 'good', value: 'good', from: '40', to: '50',
  },
  {
    id: '5', name: 'medium', value: 'medium', from: '30', to: '40',
  },
  {
    id: '6', name: 'untrained', value: 'untrained', from: '20', to: '30',
  },
  {
    id: '7', name: 'beginner', value: 'beginner', from: '10', to: '20',
  },
  {
    id: '8', name: 'weak', value: 'weak', from: '10',
  },
];

/* Game Message Type */
export const gameMessageTypes = [
  { id: 'win', label: 'win', data: 'player-points' },
  { id: 'winBezSt', label: 'winBezSt', data: 'player' },
  { id: 'lose', label: 'lose', data: 'player-points' },
  { id: 'loseBezSt', label: 'loseBezSt', data: 'player' },
  { id: 'winZole', label: 'winZole', data: 'player-points' },
  { id: 'winZoleBezSt', label: 'winZoleBezSt', data: 'player' },
  { id: 'loseZole', label: 'loseZole', data: 'player-points' },
  { id: 'loseZoleBezSt', label: 'loseZoleBezSt', data: 'player' },
  { id: 'winMaza', label: 'winMaza', data: 'player' },
  { id: 'loseMaza', label: 'loseMaza', data: 'player' },
  { id: 'loseGaldins', label: 'loseGaldins', data: 'player-tricks-points' },
  { id: 'burry', label: 'burry' },
  { id: 'takeTable', label: 'takeTable', data: 'data' },
  { id: 'takeZole', label: 'takeZole', data: 'data' },
  { id: 'takeMaza', label: 'takeMaza', data: 'data' },
  { id: 'passLastParty', label: 'passLastParty', data: 'playerName-lastName' },
  { id: 'passGaldins', label: 'passGaldins', data: 'data' },
  { id: 'passNew', label: 'passNew', data: 'data' },
  { id: 'pass', label: 'pass', data: 'data' },
  { id: 'lastRound', label: 'lastRound', data: 'data' },
  { id: 'lastRoundForTournament', label: 'lastRoundForTournament', data: 'data' },
  { id: 'lastRoundTournament', label: 'lastRoundTournament', data: 'tournamentRound' },
  { id: 'lastRoundTournamentLast', label: 'lastRoundTournamentLast' },
  { id: 'fourthPlayerMissed', label: 'fourthPlayerMissed' },
  { id: 'largePlayerQuit', label: 'largePlayerQuit' },
];

export const PLAYER_POINTS = 'player-points';
export const TOURNAMENT_ROUND = 'tournamentRound';
export const DATA = 'data';
export const PLAYERNAME_LASTNAME = 'playerName-lastName';
export const PLAYER_TRICKS_POINTS = 'player-tricks-points';

/* Bets type */
export const BETS_TYPE = [1, 5, 10, 25, 50, 100, 500, 1000, 5000, 10000];

/* split date-time */
export const getSplitDate = (key) => {
  const splitKey = key.split('-');
  const splitDate = {
    dayString: `${splitKey[0]}-${splitKey[1]}-${splitKey[2]}`,
    time: splitKey[3],
    dateKey: `${splitKey[2]}-${splitKey[3]}`,
  };
  return splitDate;
};

/* Date format */
export const FORMAT_DATE_TYPE = {
  fullDateSecondSS: 'DD-MM-YYYY, HH:mm:ss SS',
  fullDateSecond: 'DD-MM-YYYY, HH:mm:ss',
  fullDateMinute: 'DD-MM-YYYY, HH:mm',
  fullDayMonth: 'DD-MM-YYYY',
  fullMonthYear: 'YYYY-MM',
  fullDayMonthYear: 'YYYY-MM-DD',
  fullMonthDayYear: 'MM/DD/YYYY',
  pointDate: 'DD.MM.YYYY HH:mm',
  statisticsDate: 'YYYY-MM-DD-HH',
  pointsMonth: 'DD.MM',
  pointsMonthYear: 'MM.YYYY',
  pointsfullDate: 'DD.MM.YYYY',
  slashFullDate: 'DD/MM/YYYY',
  slashFullDateSmall: 'dd/mm/yyyy',
  daySecondsLeft: 'HH:mm:ss',
  cursorExpireTime: 'DD:HH:mm',
};

export const FORMAT_DAY_TYPE = {
  days: 'days',
  weeks: 'weeks',
  months: 'months',
};

/* Change Round of GamesInRoom in statistics */
export const CHANGE_ROUND = 19;

/* Active Player from Current Date */
export const CALC_ACTIVATE_DATE = () => Date.now() - (1000 * 60 * 60 * 24 * 30);

/* Range for Points, Balance */
export const TOP_RANGE = {
  start: -1000000000, end: 1000000000000, limit: 100, landingLimit: 10,
};
export const TOP_LIST_LIMIT = { limit: 99 };

/* Range for Millionaires */
export const TOP_MILLIONAIRES_RANGE = { start: 1000000, end: 1000000000000 };

/* Range for statistics */
export const STATISTICS_RANGE = { start: 0, end: 1000000 };
/* CALC TIME BY DAY, WEEK, MONTH, YEAR  */
export const CALC_ACTIVATE_DATE_BY_TIME = time => Date.now() - (1000 * 60 * 60 * 24 * time);
export const TIME_RANGE_TYPE = {
  day: 1,
  week: 7,
};

/* GAME SIDE NAVIGATION */
export const MENU_NAVIGATION = {
  rooms: '1',
  top: '2',
  topMillionaires: '2.1',
  myInfo: '3',
  bonusPage: '4',
  buyPage: '5',
  buyPageMoneyPacks: '5.1',
  buyPagePremiumPacks: '5.2',
  buyPageHistory: '5.3',
  buyPageRedeemCode: '5.4',
  buyPageDiscountEvent: '5.5',
  // resetBalancePage: '5.2',
  giftPage: '5.5',
  tournaments: '6',
  tournamentsHistory: '7',
  friends: '9',
  ignoredUsers: '10',
  archievements: '11',
  gameHistory: '12',
  powerRankings: '13',
  powerRankingsPoints: '13.1',
  powerRankingsCoins: '13.2',
  powerRankingsWinsCount: '13.3',
  powerRankingsWinPercentage: '13.4',
  powerRankingsLossCount: '13.5',
  powerRankingsLossPercentage: '13.6',
  weeklyStatus: '14',
  gameLogs: '15',
  futureTournaments: '16',
  moneyAndCoins: '17',
  moneyHistory: '17.1',
  pointsHistory: '17.2',
  more: '18',
};
export const MENU_NAVIGATION_TYPE = [
  { id: '1', label: 'rooms' },
  { id: '2', label: 'top' },
  { id: '2.1', label: 'topMillionaires' },
  { id: '3', label: 'myInfo' },
  { id: '4', label: 'bonusPage' },
  { id: '5.1', label: 'shop' },
  { id: '5.1', label: 'moneyPacks' },
  { id: '5.2', label: /* 'resetBalancePage' */'premiumPacks' },
  { id: '5.1', label: 'shop_discounts' },
  { id: '5.3', label: 'transactionHistory' },
  { id: '5.4', label: 'redeemGiftCode' },
  { id: '6', label: 'tournaments' },
  { id: '6', label: 'activeTournaments' },
  { id: '16', label: 'plannedTournaments' },
  { id: '7', label: 'tournamentsHistory' },
  { id: '9', label: 'friends' },
  { id: '10', label: 'ignoredUsers' },
  { id: '11', label: 'archievements' },
  { id: '12', label: 'gameHistory' },
  { id: '13', label: 'powerRankings' },
  { id: '13.1', label: 'powerRankingsPoints' },
  { id: '13.2', label: 'powerRankingsCoins' },
  { id: '13.3', label: 'powerRankingsWinsCount' },
  { id: '13.4', label: 'powerRankingsWinPercentage' },
  { id: '13.5', label: 'powerRankingsLossCount' },
  { id: '13.6', label: 'powerRankingsLossPercentage' },
  { id: '14', label: 'weeklyStatus' },
  { id: '15', label: 'gameLogs' },
  { id: '16', label: 'futureTournaments' },
  { id: '17', label: 'moneyAndCoins' },
  { id: '17.1', label: 'moneyHistory' },
  { id: '17.2', label: 'pointsHistory' },
];

/* SHOP NAVIGATION */
export const SHOP_NAVIGATION = {
  moneyPacks: '1',
  premiumPacks: '2',
  transactionsHistory: '3',
  redeemGiftCode: '4',
  discountEvent: '5',
};

export const MONEYPACKS_TYPES = ['money1', 'money2', 'money3', 'money4', 'money5'];
export const PREMIUMPACKS_TYPES = ['money6', 'money7', 'money8', 'money9', 'hideAdsMonth', 'hideAdsYear'];

export const ROOMS_NAVIGATION = {
  createRoom: '1',
  filterRooms: '2',
  roomLegends: '3',
};

export const TOP_NAVIGATION = {
  filter: '1',
  timePeriod: '2',
  onlyMillionaires: '3',
  onlyActive: '4',
  search: '5',
  onlyNoOlderThanMe: '6',
};

/* Page to Open in Events Banner */
export const PAGE_OPEN_LINK = {
  futureTournaments: 'futureTournaments',
  plannedTournaments: 'plannedTournaments',
  activeTournaments: 'activeTournaments',
  tournamentsHistory: 'tournamentsHistory',
  tournaments: 'tournaments',
  top: 'top',
  rooms: 'rooms',
  shop: 'shop',
  premiumPacks: 'premiumPacks',
  moneyPacks: 'moneyPacks',
  redeemGiftCode: 'redeemGiftCode',
};

/* Gift CODE ERR STATUS */
export const GIFT_CODE_ERROR_STATUS = {
  wrongCode: 'wrong code',
  missingGiftCode: 'missingGiftCode',
  codeExpired: 'Code expired',
  limitClaim: 'Claim limit reached',
  alreadyClaimed: 'code already claimed',
};
/* FROUNT RESULT STATUS */
export const FROUNT_SIDE_STATUS = {
  initiated: 'initiated',
  success: 'success',
  error: 'error',
  completed: 'completed',
  warning: 'warning',
  danger: 'danger',
};

/* SCREEN SIZE */
export const SCREEN_MODE_TYPE = {
  xs: '6',
  md: '3',
  sm: '4',
  none: '',
};

/* SHOP PRODUCT TYPES */
export const SHOP_PRODUCT_TYPES = [
  {
    id: 1, label: 'money1', price: '0.85 EUR', smsPrice: '1.28 EUR', productNr: 'Purchase - 250Z for 0.85 EUR', coin: 250, realPrice: 0.85, smsRealPrice: 1.28,
  },
  {
    id: 2, label: 'money2', price: '1.41 EUR', smsPrice: '2.12 EUR', productNr: 'Purchase - 500Z for 1.41 EUR', coin: 500, realPrice: 1.41, smsRealPrice: 2.12,
  },
  {
    id: 3, label: 'money3', price: '2.85 EUR', smsPrice: '4.28 EUR', productNr: 'Purchase - 1000Z for 2.85 EUR', coin: 1500, realPrice: 2.85, smsRealPrice: 4.28,
  },
  {
    id: 4, label: 'money4', price: '4.27 EUR', smsPrice: '6.40 EUR', productNr: 'Purchase - 4000Z for 4.27 EUR', coin: 4000, realPrice: 4.27, smsRealPrice: 6.4,
  },
  {
    id: 5, label: 'money5', price: '14.23 EUR', smsPrice: '21.35 EUR', productNr: 'Purchase - 15000Z for 14.23 EUR', coin: 15000, realPrice: 14.23, smsRealPrice: 21.35,
  },
  {
    id: 6, label: 'money6', price: '5.00 EUR', smsPrice: '7.50 EUR', productNr: 'Purchase - Reset balance', realPrice: 5, smsRealPrice: 7.5,
  },
  {
    id: 7, label: 'money7', price: '5.00 EUR', smsPrice: '7.50 EUR', productNr: 'Purchase - Reset points', realPrice: 5, smsRealPrice: 7.5,
  },
  {
    id: 8, label: 'money8', price: '5.00 EUR', smsPrice: '7.50 EUR', productNr: 'Purchase - Reset profile', realPrice: 5, smsRealPrice: 7.5,
  },
  {
    id: 9, label: 'money9', price: '10 EUR', smsPrice: '15 EUR', productNr: 'Payment for unbanning account', realPrice: 10, smsRealPrice: 15,
  },
  {
    id: 10, label: 'hideAdsMonth', price: '3 EUR', smsPrice: '5 EUR', productNr: 'Hide ads for 30 days', realPrice: 3, smsRealPrice: 5,
  },
  {
    id: 11, label: 'hideAdsYear', price: '25 EUR', smsPrice: '30 EUR', productNr: 'Hide ads for 1 year', realPrice: 25, smsRealPrice: 30,
  },
];

export const SHOP_PRODUCT_NAMES = {
  money1: 1, money2: 2, money3: 3, money4: 4, money5: 5, money6: 6, money7: 7, money8: 8, money9: 9, hideAdsMonth: 10, hideAdsYear: 11,
};

/* NO EMAIL USER */
export const NO_EMAIL_USER = 'no-email@spelezoli.lv';

/* Archievements */

export const ACHIEVEMENT_SECOND_VALUE = 2;

export const ACHIEVEMENT_THIRD_VALUE = 3;

export const achievementsData = {
  gamesPlayed: {
    borders: [
      1, 5, 10, 50, 100, 250, 500, 1000, 5000, 10000, 50000, 100000,
      250000, 500000, 1000000,
    ],
    rewards: [
      25, 50, 100, 300, 500, 750, 1000, 1500, 2000, 3000, 5000, 10000,
      15000, 25000, 50000,
    ],
  },
  gamesWon: {
    borders: [
      1, 3, 5, 10, 25, 50, 100, 250, 500, 1000, 5000, 10000, 25000,
      100000, 500000,
    ],
    rewards: [
      25, 50, 100, 300, 500, 750, 1000, 1500, 2000, 3000, 5000, 10000,
      15000, 25000, 50000,
    ],
  },
  highestEarned: {
    borders: [
      1000, 2500, 5000, 7500, 10000, 25000, 50000, 75000, 100000, 250000,
      500000, 1000000, 3000000, 5000000, 10000000,
    ],
    rewards: [
      100, 250, 500, 750, 1000, 1250, 1500, 1750, 2000, 3000, 5000, 7500,
      10000, 15000, 25000,
    ],
  },
  earnedInADay: {
    borders: [
      750, 1000, 1500, 2000, 3000, 5000, 7500, 10000, 15000, 25000, 50000,
      75000, 100000, 250000, 500000,
    ],
    rewards: [
      10, 30, 50, 70, 90, 300, 500, 700, 900, 1100, 2000, 3000, 4000,
      5000, 6000,
    ],
  },
  maxSuccessionWins: {
    borders: [3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100],
    rewards: [
      50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
      3000,
    ],
  },
  maxSuccessionLosses: {
    borders: [3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100],
    rewards: [
      50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
      3000,
    ],
  },
  zolePlayed: {
    borders: [
      1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000,
      250000, 500000, 1000000,
    ],
    rewards: [
      10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
      1000,
    ],
  },
  zoleWon: {
    borders: [
      1, 5, 10, 25, 50, 75, 100, 250, 500, 1000, 2500, 5000, 10000, 25000,
      50000,
    ],
    rewards: [
      50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
      3000,
    ],
  },
  fastGamesPlayed: {
    borders: [
      1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000,
      250000, 500000, 1000000,
    ],
    rewards: [
      10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
      1000,
    ],
  },
  lightningGamesPlayed: {
    borders: [
      1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000,
      250000, 500000, 1000000,
    ],
    rewards: [
      10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
      1000,
    ],
  },
  unlimitedGamesPlayed: {
    borders: [
      1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000,
      250000, 500000, 1000000,
    ],
    rewards: [
      10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
      1000,
    ],
  },
  mazaZolePlayed: {
    borders: [
      1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000,
      250000, 500000, 1000000,
    ],
    // borders: [1, 3, 5, 10, 100, 250, 500, 1000, 10000, 25000, 50000],
    rewards: [
      10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
      1000,
    ],
  },
  mazaZoleWon: {
    borders: [
      1, 5, 10, 25, 50, 75, 100, 250, 500, 1000, 2500, 5000, 10000, 25000,
      50000,
    ],
    rewards: [
      50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
      3000,
    ],
  },
  bonusSpins: {
    borders: [
      3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 75, 100, 250, 500, 1000,
    ],
    rewards: [
      20, 30, 40, 50, 60, 100, 200, 300, 400, 500, 700, 900, 1100, 1300,
      1500,
    ],
  },
  joinedTournaments: {
    borders: [
      3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 75, 100, 250, 500, 1000,
    ],
    rewards: [
      10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
      1000,
    ],
  },
  giftsReceived: {
    borders: [
      1, 3, 5, 10, 25, 50, 75, 100, 250, 500, 750, 1000, 2000, 3000, 5000,
    ],
    rewards: [
      10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
      1000,
    ],
  },
  reachedTournamentTop10: {
    borders: [1, 3, 5, 7, 10, 15, 20, 25, 30, 35, 50, 75, 100, 250, 500],
    rewards: [
      50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
      3000,
    ],
  },
  reachedTop100: {
    borders: [
      1, 3, 5, 7, 14, 30, 60, 90, 120, 150, 180, 270, 365, 730, 1095,
    ],
    rewards: [
      50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
      3000,
    ],
  },
  giftsSent: {
    borders: [
      1, 3, 5, 10, 25, 50, 75, 100, 250, 500, 750, 1000, 2000, 3000, 5000,
    ],
    rewards: [
      10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
      1000,
    ],
  },
  maxParties: {
    borders: [
      3, 5, 7, 10, 15, 20, 25, 50, 75, 100, 150, 200, 250, 500, 1000,
    ],
    rewards: [
      20, 30, 40, 50, 60, 100, 200, 300, 400, 500, 700, 900, 1100, 1300,
      1500,
    ],
  },
  supportMessagesSent: {
    borders: [1, 2, 3, 5, 7, 10, 15, 20, 25, 50, 75, 100, 250, 500, 1000],
    rewards: [
      50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
      3000,
    ],
  },
  storePurchase: {
    borders: [1, 2, 3, 5, 10, 15, 20, 25, 30, 35, 50, 75, 100, 250, 500],
    rewards: [
      50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
      3000,
    ],
  },
  galdinsPlayed: {
    borders: [
      1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000,
      250000, 500000, 1000000,
    ],
    rewards: [
      10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
      1000,
    ],
  },
  galdinsWon: {
    borders: [
      1, 5, 10, 25, 50, 75, 100, 250, 500, 1000, 2500, 5000, 10000, 25000,
      50000,
    ],
    rewards: [
      50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
      3000,
    ],
  },
  galdinsLose: {
    borders: [
      1, 5, 10, 25, 50, 75, 100, 250, 500, 1000, 2500, 5000, 10000, 25000,
      50000,
    ],
    rewards: [
      50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
      3000,
    ],
  },
  maxDailyLogin: {
    borders: [1, 3, 5, 7, 14, 30, 60, 90, 120, 150, 180, 270, 365, 730, 1095],
    rewards: [50, 100, 250, 500, 750, 1000, 1500, 2000, 2500, 3000, 5000, 7500, 10000, 15000, 25000],
  },
  winLarge61: {
    borders: [1],
    rewards: [300],
  },
  winLarge91: {
    borders: [1],
    rewards: [500],
  },
  winLarge120: {
    borders: [1],
    rewards: [1000],
  },
  winSmall60: {
    borders: [1],
    rewards: [300],
  },
  winSmall90: {
    borders: [1],
    rewards: [500],
  },
  winSmall120: {
    borders: [1],
    rewards: [2000],
  },
  winZoleAll: {
    borders: [1],
    rewards: [600],
  },
  winZoleTwoAces: {
    borders: [1],
    rewards: [1000],
  },
  loseLarge60: {
    borders: [1],
    rewards: [300],
  },
  loseLarge30: {
    borders: [1],
    rewards: [150],
  },
  loseLarge0: {
    borders: [1],
    rewards: [150],
  },
  takeTwoAces: {
    borders: [1],
    rewards: [1000],
  },
  takeTwoNines: {
    borders: [1],
    rewards: [1000],
  },
  loseZoleTwoNines: {
    borders: [1],
    rewards: [2000],
  },
  loseTableAllTricks: {
    borders: [1],
    rewards: [1000],
  },
  take3Aces: {
    borders: [1],
    rewards: [333],
  },
  take0Points: {
    borders: [1],
    rewards: [100],
  },
  dealtOnlyQueensJacks: {
    borders: [1],
    rewards: [1000],
  },
  dealtNoTrumps: {
    borders: [1],
    rewards: [2000],
  },
  dealtOnly789: {
    borders: [1],
    rewards: [3000],
  },
};

export const PLYERINFO_NEW_TABS = {
  statistics: '1', trophies: '2', gifts: '3',
};

export const PLYERINFO_NEW_PERCENTRATE = {
  percent: 10000,
};

/* Message Language Type */
export const sendLanguageType = {
  all: 'All', lv: 'Latvian', en: 'English', ru: 'Russian',
};
export const sendLanguage = {
  all: 'all', lv: 'lv', en: 'en', ru: 'ru',
};

/* Profile modal type */
export const PROFILE_MODAL_TYPE = {
  edit: 'edit', promotion: 'promotion', verify: 'verify', delete: 'delete',
};

/* Rating for Feedback */
export const FEEDBACK_RATING = [
  { id: 1, label: 'like1' },
  { id: 2, label: 'like2' },
  { id: 3, label: 'like3' },
  { id: 4, label: 'like4' },
  { id: 5, label: 'like5' },
];

export const FEEDBACK_LENGTH = 1000;
export const CONTACTS_LENGTH = 500;

/* New Design Profile Edit Fields */
export const PROFILE_FIELDS = {
  email: 'email',
  name: 'name',
  surname: 'surname',
  password: 'password',
  password2: 'password2',
  password3: 'password3',
  confirmPass: 'confirmPass',
  terms: 'terms',
  message: 'message',
  title: 'title',
  birthday: 'birthday',
  birthdayMobile: 'birthdayMobile',
};

/* USER NAME LOWER UPPER TYPE */
export const USERNAME_LOWER_UPPER = {
  lowerCaseLastName: 'lowerCaseLastName',
  lowerCaseName: 'lowerCaseName',
};

export const ALLLOW_FILE = {
  allowTypes: ['.jpg', '.jpeg', '.png', '.pdf', 'msword', '.doc', '.rtf', '.txt', '.ain'],
  allowSize: 1536,
  unitSize: 1024,
  mbSize: 1048576,
};

export const POWER_RANKINGS_SUB_NAVIGATION = {
  rating: 'rating',
  points: 'points',
  coins: 'coins',
  winsCount: 'winsCount',
  winPercentage: 'winPercentage',
  lossCount: 'lossCount',
  lossPercentage: 'lossPercentage',
};

export const POWER_RANKINGS_SUB_MENU = {
  rating: '13',
  points: '13.1',
  coins: '13.2',
  winsCount: '13.3',
  winPercentage: '13.4',
  lossCount: '13.5',
  lossPercentage: '13.6',
};

export const POWER_RANKINGS_FILTER = [
  { key: 'rating', filter: 'points', id: '13' },
  { key: 'points', filter: 'points', id: '13.1' },
  { key: 'coins', filter: 'bal', id: '13.2' },
  { key: 'winsCount', filter: 'wins', id: '13.3' },
  { key: 'winPercentage', filter: 'winsPerc', id: '13.4' },
  { key: 'lossCount', filter: 'loses', id: '13.5' },
  { key: 'lossPercentage', filter: 'losesPerc', id: '13.6' },
];

export const POWER_RANKINGS_SWITCH_OPTION = {
  best: 'best',
  worst: 'worst',
};

export const IGNORED_USERS_SWITCH_OPTION = {
  iIgnore: 'iIgnore',
  ignoresMe: 'ignoresMe',
};

export const POWER_RANKINGS_SHOW_LIMIT = 10;

/* BONUS VALUE */
export const ADD_OR_VERIFY_EMAIL_BONUS = 500;

export const stages = {
  profileData: 'profileData',
  changeEmail: 'changeEmail',
  addEmail: 'addEmail',
  changePass: 'changePass',
  deleteAccount: 'deleteAccount',
  confirmation: 'confirmation',
  settings: 'settings',
  login: 'login',
  forgotPassword: 'forgotPassword',
  resetPassword: 'resetPassword',
  register: 'register',
  promotion: 'promotion',
};

export const WEEKLY_VALUE = {
  oneWeek: 7,
  twoWeek: 14,
};

export const WEEKLY_STATISTICS_DATA = {
  daysPlayed: 'daysPlayed',
  gamesPlayed: 'gamesPlayed',
  roomsPlayed: 'roomsPlayed',
  wins: 'wins',
  loses: 'loses',
  purchases: 'purchases',
  tournaments: 'tournaments',
  achievements: 'achievements',
  receivedGifts: 'receivedGifts',
  sentGifts: 'sentGifts',
};

export const TOP_HEADER_NAVIGATION = {
  home: 'home',
  helpPage: 'helpPage',
  // aboutPage: 'aboutPage',
  blog: 'blog',
  // advertising: 'advertising',
  versionHistory: 'versionHistory',
  termsOfUse: 'termsOfUse',
  contacts: 'contacts',
  login: 'login',
  lang: 'lang',
};

export const PLAYER_ZOLE_RANGE = {
  min: 200,
  maxmin: 0,
};

export const NEW_DESIGN_ROOT_STYLE = {
  new_design: 'new-design-landing',
  new_login: 'new-design-login',
};

export const FOOTER_NAVIGATION_LINK = {
  // technicalSupport: 'Technical support',
  gameHelp: 'gameHelp',
  versionHistory: 'versionHistory',
  termsOfUse: 'termsOfUse',
  // aboutUs: 'About us',
  // advertising: 'Advertising',
  contacts: 'Contacts',
};

export const LANGUAGE_TYPE = {
  lv: 'Latvian',
  en: 'English',
  ru: 'Russian',
};

export const LANGUAGE_LIST = ['lv', 'en', 'ru'];

export const BLOG_SHOW_TYPES = {
  total: 13,
  firstSection: 2,
  secondSection: 3,
  thirdSection: 4,
};

export const FEEDBACK_SHOW_TIME_RANGE = {
  start: 1,
  end: 90,
};

export const EVENT_BANNER_TYPES = {
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  spring: 'Spring',
  summer: 'Summer',
  autumn: 'Autumn',
  winter: 'Winter',
  valentine_day: "Valentine's Day",
  easter: 'Easter',
  midsummer: 'Midsummer',
  september_1: 'September 1st',
  november_11: 'November 11th',
  november_18: 'November 18th',
  christmas: 'Christmas',
  christmas2: 'Christmas',
  new_year: 'New year',
};

export const TOUR_PAGE_LIMIT = 5;

export const STEP_PAGE_NUM = {
  firstStepPages: 8,
  secondStepPages: 4,
  thirdStepPages: 4,
  fourthStepPages: 6,
  fithStepPages: 1,
};

export const TOUR_STEP_TYPES = {
  old: 'old', new: 'new', newMobileDesktop: 'newMobileDesktop', newMobile: 'newMobile', newMobileIFrame: 'newMobileIFrame', newMobilePortrait: 'newMobilePortrait',
};

export const getTourSteps = (type) => {
  if (type === TOUR_STEP_TYPES.old) {
    return [
      {
        disableBeacon: true,
        target: '.menu-player-edit',
        title: 'tutorial.editProfileTitle',
        content: 'tutorial.editProfile',
        disableOverlayClose: true,
        stepType: 1,
        tab: MENU_NAVIGATION.rooms,
        unit: '1',
      },
      {
        disableBeacon: true,
        target: '.user-settings-button',
        title: 'tutorial.settingsTitle',
        content: 'tutorial.settingsOld',
        disableOverlayClose: true,
        stepType: 1,
        unit: '2',
      },
      {
        disableBeacon: true,
        target: '.fullscreen-button',
        title: 'tutorial.fullScreenTitle',
        content: 'tutorial.fullScreen',
        disableOverlayClose: true,
        stepType: 1,
        unit: '3',
      },
      {
        disableBeacon: true,
        target: '.bottom-bar',
        title: 'tutorial.usefulOptionsTitle',
        content: 'tutorial.usefulOptions',
        disableOverlayClose: true,
        stepType: 1,
        unit: '4',
      },
      {
        disableBeacon: true,
        target: config.isInDraugiemAppFrame() ? '.bonus-tab-sidebar' : '.bonus-tab',
        title: 'tutorial.dailyBounsTitle',
        content: 'tutorial.dailyBouns',
        disableOverlayClose: true,
        stepType: 1,
        unit: '5',
        placement: config.isInDraugiemAppFrame() ? 'top' : 'auto',
      },
      {
        disableBeacon: true,
        target: config.isInDraugiemAppFrame() ? '.shop-tab-sidebar' : '.shop-tab',
        title: 'tutorial.zoleShopTitle',
        content: 'tutorial.zoleShop',
        disableOverlayClose: true,
        stepType: 1,
        unit: '6',
        placement: config.isInDraugiemAppFrame() ? 'top' : 'auto',
      },
      {
        disableBeacon: true,
        target: config.isInDraugiemAppFrame() ? '.start-new-game-sidebar' : '.start-new-game',
        title: 'tutorial.createRoomTitle',
        content: 'tutorial.createRoom',
        disableOverlayClose: true,
        stepType: 1,
        unit: '7',
        placement: config.isInDraugiemAppFrame() ? 'right' : 'auto',
      },
      {
        disableBeacon: true,
        target: '.joyride-enter-room-zone-old', // '.rooms-table-section',
        title: 'tutorial.joinRoomTitle',
        content: 'tutorial.joinRoom',
        disableOverlayClose: true,
        stepType: 1,
        unit: '8',
        placement: 'left',
      },
      {
        disableBeacon: true,
        target: '.menu-top-tab',
        title: 'tutorial.topStepTitle',
        content: 'tutorial.topStep',
        disableOverlayClose: true,
        stepType: 2,
        tab: MENU_NAVIGATION.top,
        unit: '9',
      },
      {
        disableBeacon: true,
        target: '.menu-top-tab',
        title: 'tutorial.topStepTitle',
        content: 'tutorial.topStep',
        disableOverlayClose: true,
        stepType: 2,
        tab: MENU_NAVIGATION.top,
        unit: '10',
      },
      {
        disableBeacon: true,
        target: '.top-search-check-options',
        title: 'tutorial.topStepFilterTitle',
        content: ['tutorial.topStepFilter', 'tutorial.topStepFilter1', 'tutorial.topStepFilter2', 'tutorial.topStepFilter3'],
        disableOverlayClose: true,
        stepType: 2,
        unit: '11',
      },
      {
        disableBeacon: true,
        target: '.top-list-filter-options',
        title: 'tutorial.topfilterTitle',
        content: 'tutorial.topfilter',
        disableOverlayClose: true,
        stepType: 2,
        unit: '12',
      },
      {
        disableBeacon: true,
        target: '.top-search-part',
        title: 'tutorial.topSearchTitle',
        content: 'tutorial.topSearch',
        disableOverlayClose: true,
        stepType: 2,
        unit: '13',
      },
      {
        disableBeacon: true,
        target: '.top-page-section',
        title: 'tutorial.topListTitle',
        content: 'tutorial.topList',
        disableOverlayClose: true,
        stepType: 2,
        unit: '14',
      },
      {
        disableBeacon: true,
        target: '.power-rankings-tab',
        title: 'tutorial.powerRankingsTitle',
        content: 'tutorial.powerRankings',
        disableOverlayClose: true,
        stepType: 3,
        tab: MENU_NAVIGATION.powerRankings,
        unit: '15',
      },
      {
        disableBeacon: true,
        target: '.power-rankings-header-switch-section',
        title: 'tutorial.powerRankingsFilterTitle',
        content: 'tutorial.powerRankingsFilter',
        disableOverlayClose: true,
        stepType: 3,
        unit: '16',
      },
      {
        disableBeacon: true,
        target: '.power-rankings-section-wrapper',
        title: 'tutorial.powerRankingsListTitle',
        content: 'tutorial.powerRankingsList',
        disableOverlayClose: true,
        stepType: 3,
        unit: '17',
      },
      {
        disableBeacon: true,
        target: '.my-info-tab',
        title: 'tutorial.myInfoTitle',
        content: 'tutorial.myInfo',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.myInfo,
        unit: '18',
      },
      {
        disableBeacon: true,
        target: '.my-info-tab',
        title: 'tutorial.myInfoTitle',
        content: 'tutorial.myInfo',
        disableOverlayClose: true,
        stepType: 4,
        unit: '19',
      },
      {
        disableBeacon: true,
        target: '.my-info-wrapper',
        title: 'tutorial.myInfoDetailTitle',
        content: 'tutorial.myInfoDetail',
        disableOverlayClose: true,
        stepType: 4,
        unit: '20',
      },
      {
        disableBeacon: true,
        target: '.friends-menu-1',
        title: 'tutorial.myFriendsTitle',
        content: 'tutorial.myFriends',
        tab: MENU_NAVIGATION.friends,
        disableOverlayClose: true,
        stepType: 4,
        unit: '21',
        placement: 'bottom',
      },
      {
        disableBeacon: true,
        target: '.ignored-players-menu',
        title: 'tutorial.ignoredTitle',
        content: 'tutorial.ignored',
        tab: MENU_NAVIGATION.ignoredUsers,
        disableOverlayClose: true,
        stepType: 4,
        unit: '22',
      },
      {
        disableBeacon: true,
        target: '.achievements-menu',
        title: 'tutorial.achievementsTitle',
        content: 'tutorial.achievements',
        tab: MENU_NAVIGATION.archievements,
        disableOverlayClose: true,
        stepType: 4,
        unit: '23',
      },
      {
        disableBeacon: true,
        target: '.results-menu-tab',
        title: 'tutorial.resultsTitle',
        content: 'tutorial.results',
        tab: MENU_NAVIGATION.gameHistory,
        disableOverlayClose: true,
        stepType: 4,
        unit: '24',
      },
      {
        disableBeacon: true,
        target: '.weekly-statistics-menu',
        title: 'tutorial.weeklyStatisticsTitle',
        content: 'tutorial.weeklyStatistics',
        tab: MENU_NAVIGATION.weeklyStatus,
        disableOverlayClose: true,
        stepType: 4,
        unit: '25',
      },
      {
        disableBeacon: true,
        target: '.game-logs-menu',
        title: 'tutorial.gameLogsTitle',
        content: 'tutorial.gameLogs',
        tab: MENU_NAVIGATION.gameLogs,
        disableOverlayClose: true,
        stepType: 4,
        unit: '26',
      },
      {
        disableBeacon: true,
        target: '.tournaments-tab',
        title: 'tutorial.tournamentsStepTitle',
        content: 'tutorial.tournamentsStep',
        disableOverlayClose: true,
        stepType: 5,
        tab: MENU_NAVIGATION.tournaments,
        unit: '27',
      },
      {
        disableBeacon: true,
        target: '.tournaments-active',
        title: 'tutorial.tournamentsActiveTitle',
        content: 'tutorial.tournamentsActive',
        tab: MENU_NAVIGATION.tournaments,
        disableOverlayClose: true,
        stepType: 5,
        unit: '28',
      },
      {
        disableBeacon: true,
        target: '.tournaments-future',
        title: 'tutorial.tournamentsFutureTitle',
        content: 'tutorial.tournamentsFuture',
        tab: MENU_NAVIGATION.futureTournaments,
        disableOverlayClose: true,
        stepType: 5,
        unit: '29',
      },
      {
        disableBeacon: true,
        target: '.tournaments-history',
        title: 'tutorial.tournamentsPastTitle',
        content: 'tutorial.tournamentsPast',
        tab: MENU_NAVIGATION.tournamentsHistory,
        disableOverlayClose: true,
        stepType: 5,
        unit: '30',
      },
    ];
  }
  if (type === TOUR_STEP_TYPES.new) {
    return [
      {
        disableBeacon: true,
        target: '.player-edit-pencil',
        title: 'tutorial.editProfileTitle',
        content: 'tutorial.editProfile',
        disableOverlayClose: true,
        stepType: 1,
        tab: MENU_NAVIGATION.rooms,
        unit: '1',
      },
      {
        disableBeacon: true,
        target: '.user-settings-button',
        title: 'tutorial.settingsTitle',
        content: 'tutorial.settings',
        disableOverlayClose: true,
        stepType: 1,
        unit: '2',
      },
      {
        disableBeacon: true,
        target: (config.isInAppFrame() && !isMobileScreenResolution()) ? '.top-settings-area-joyride' : '.top-setting-menu-open-button',
        title: 'tutorial.topSettingsTitle',
        content: 'tutorial.topSettings',
        disableOverlayClose: true,
        stepType: 1,
        unit: '3',
      },
      {
        disableBeacon: true,
        target: '.bottom-bar',
        title: 'tutorial.usefulOptionsTitle',
        content: 'tutorial.usefulOptions',
        disableOverlayClose: true,
        stepType: 1,
        unit: '4',
        placement: 'top',
      },
      {
        disableBeacon: true,
        target: '.layout-footer-bonus-link',
        title: 'tutorial.dailyBounsTitle',
        content: 'tutorial.dailyBouns',
        disableOverlayClose: true,
        stepType: 1,
        unit: '5',
      },
      {
        disableBeacon: true,
        target: '.layout-footer-shop-link',
        title: 'tutorial.zoleShopTitle',
        content: 'tutorial.zoleShop',
        disableOverlayClose: true,
        stepType: 1,
        unit: '6',
      },
      {
        disableBeacon: true,
        target: '.create-room-button',
        title: 'tutorial.createRoomTitle',
        content: 'tutorial.createRoom',
        disableOverlayClose: true,
        stepType: 1,
        unit: '7',
      },
      {
        disableBeacon: true,
        target: '.joyride-enter-room-zone', // '.new-rooms-table-section',
        title: 'tutorial.joinRoomTitle',
        content: 'tutorial.joinRoom',
        disableOverlayClose: true,
        stepType: 1,
        unit: '8',
        placement: 'left',
      },
      {
        disableBeacon: true,
        target: '.top-tab-menubar',
        title: 'tutorial.topStepTitle',
        content: 'tutorial.topStep',
        disableOverlayClose: true,
        stepType: 2,
        tab: MENU_NAVIGATION.top,
        unit: '9',
      },
      {
        disableBeacon: true,
        target: '.top-tab-menubar',
        title: 'tutorial.topStepTitle',
        content: 'tutorial.topStep',
        disableOverlayClose: true,
        stepType: 2,
        tab: MENU_NAVIGATION.top,
        unit: '10',
      },
      {
        disableBeacon: true,
        target: '.top-table-millionaires-link',
        title: 'tutorial.topStepFilterTitle',
        content: 'tutorial.topStepFilter',
        disableOverlayClose: true,
        stepType: 2,
        unit: '11',
      },
      {
        disableBeacon: true,
        target: '.top-filter-icons-section',
        title: 'tutorial.topfilterTitle',
        content: 'tutorial.topfilter',
        disableOverlayClose: true,
        stepType: 2,
        unit: '12',
      },
      {
        disableBeacon: true,
        target: '.top-search-section',
        title: 'tutorial.topSearchTitle',
        content: 'tutorial.topSearch',
        disableOverlayClose: true,
        stepType: 2,
        unit: '13',
      },
      {
        disableBeacon: true,
        target: '.new-top-table-section',
        title: 'tutorial.topListTitle',
        content: 'tutorial.topList',
        disableOverlayClose: true,
        stepType: 2,
        unit: '14',
      },
      {
        disableBeacon: true,
        target: '.power-rankings-tab',
        title: 'tutorial.powerRankingsTitle',
        content: 'tutorial.powerRankings',
        disableOverlayClose: true,
        stepType: 3,
        tab: MENU_NAVIGATION.powerRankings,
        unit: '15',
      },
      {
        disableBeacon: true,
        target: '.new-power-rankings-header-switch-section',
        title: 'tutorial.powerRankingsFilterTitle',
        content: 'tutorial.powerRankingsFilter',
        disableOverlayClose: true,
        stepType: 3,
        unit: '16',
      },
      {
        disableBeacon: true,
        target: '.new-power-rankings-section',
        title: 'tutorial.powerRankingsListTitle',
        content: 'tutorial.powerRankingsList',
        disableOverlayClose: true,
        stepType: 3,
        unit: '17',
      },
      {
        disableBeacon: true,
        target: '.my-info-tab',
        title: 'tutorial.myInfoTitle',
        content: 'tutorial.myInfo',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.myInfo,
        unit: '18',
      },
      {
        disableBeacon: true,
        target: '.my-info-tab',
        title: 'tutorial.myInfoTitle',
        content: 'tutorial.myInfo',
        disableOverlayClose: true,
        stepType: 4,
        unit: '19',
      },
      {
        disableBeacon: true,
        target: '.new-my-info-section',
        title: 'tutorial.myInfoDetailTitle',
        content: 'tutorial.myInfoDetail',
        disableOverlayClose: true,
        stepType: 4,
        unit: '20',
      },
      {
        disableBeacon: true,
        target: '.my-info-friends-button-1',
        title: 'tutorial.myFriendsTitle',
        content: 'tutorial.myFriends',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.friends,
        unit: '21',
      },
      {
        disableBeacon: true,
        target: '.my-info-ignoredPlayers-button-desktop',
        title: 'tutorial.ignoredTitle',
        content: 'tutorial.ignored',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.ignoredUsers,
        unit: '22',
      },
      {
        disableBeacon: true,
        target: '.my-info-achievements-button-desktop',
        title: 'tutorial.achievementsTitle',
        content: 'tutorial.achievements',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.archievements,
        unit: '23',
      },
      {
        disableBeacon: true,
        target: '.my-info-results-button-desktop',
        title: 'tutorial.resultsTitle',
        content: 'tutorial.results',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.gameHistory,
        unit: '24',
      },
      {
        disableBeacon: true,
        target: '.my-info-weeklyStatus-button',
        title: 'tutorial.weeklyStatisticsTitle',
        content: 'tutorial.weeklyStatistics',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.weeklyStatus,
        unit: '25',
      },
      {
        disableBeacon: true,
        target: '.my-info-gameLogs-button',
        title: 'tutorial.gameLogsTitle',
        content: 'tutorial.gameLogs',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.gameLogs,
        unit: '26',
      },
      {
        disableBeacon: true,
        target: '.tournaments-tab',
        title: 'tutorial.tournamentsStepTitle',
        content: 'tutorial.tournamentsStep',
        disableOverlayClose: true,
        stepType: 5,
        tab: MENU_NAVIGATION.tournaments,
        unit: '27',
      },
      {
        disableBeacon: true,
        target: '.active-tournaments-button-desktop',
        title: 'tutorial.tournamentsActiveTitle',
        content: 'tutorial.tournamentsActive',
        disableOverlayClose: true,
        tab: MENU_NAVIGATION.tournaments,
        stepType: 5,
        unit: '28',
      },
      {
        disableBeacon: true,
        target: '.future-tournaments-button-desktop',
        title: 'tutorial.tournamentsFutureTitle',
        content: 'tutorial.tournamentsFuture',
        disableOverlayClose: true,
        tab: MENU_NAVIGATION.futureTournaments,
        stepType: 5,
        unit: '29',
      },
      {
        disableBeacon: true,
        target: '.past-tournaments-button-desktop',
        title: 'tutorial.tournamentsPastTitle',
        content: 'tutorial.tournamentsPast',
        tab: MENU_NAVIGATION.tournamentsHistory,
        disableOverlayClose: true,
        stepType: 5,
        unit: '30',
      },
    ];
  } if (type === TOUR_STEP_TYPES.newMobileDesktop) { // for landscape mobile layout on desktop screen
    return [
      {
        disableBeacon: true,
        target: '.bottom-part-overlay',
        title: 'tutorial.editProfileTitle',
        content: 'tutorial.editProfile',
        disableOverlayClose: true,
        stepType: 1,
        tab: MENU_NAVIGATION.rooms,
        unit: '1',
        expandedLandscapeSidebar: true,
        placement: 'top',
      },
      {
        disableBeacon: true,
        target: '.settings-menu-item-wrapper',
        title: 'tutorial.settingsTitle',
        content: 'tutorial.settingsLandscape',
        tab: 'mobileSettings',
        disableOverlayClose: true,
        stepType: 1,
        unit: '2',
        expandedLandscapeSidebar: true,
        placement: 'top',
      },
      // {
      //   disableBeacon: true,
      //   target: '.fullscreen-button-image',
      //   title: 'tutorial.fullScreenTitle',
      //   content: 'tutorial.fullScreen',
      //   disableOverlayClose: true,
      //   stepType: 1,
      //   unit: '3',
      // },
      {
        disableBeacon: true,
        target: '.more-menu-item-wrapper',
        title: 'tutorial.usefulOptionsTitle',
        content: 'tutorial.usefulOptionsLandscape',
        disableOverlayClose: true,
        stepType: 1,
        unit: '3',
        expandedLandscapeSidebar: true,
        placement: 'bottom',
      },
      {
        disableBeacon: true,
        target: '.daily-bonus-menu-item-wrapper',
        title: 'tutorial.dailyBounsTitle',
        content: 'tutorial.dailyBouns',
        disableOverlayClose: true,
        stepType: 1,
        unit: '4',
        expandedLandscapeSidebar: true,
        placement: 'top',
      },
      {
        disableBeacon: true,
        target: '.shop-menu-item-wrapper',
        title: 'tutorial.zoleShopTitle',
        content: 'tutorial.zoleShop',
        disableOverlayClose: true,
        stepType: 1,
        unit: '5',
        expandedLandscapeSidebar: true,
        placement: 'top',
      },
      {
        disableBeacon: true,
        target: '.create-room-button',
        title: 'tutorial.createRoomTitle',
        content: 'tutorial.createRoom',
        disableOverlayClose: true,
        stepType: 1,
        tab: MENU_NAVIGATION.rooms,
        unit: '6',
        expandedLandscapeSidebar: true,
        placement: 'top',
        scrollIntoViewElement: "create-room-button-landscape-id",
      },
      {
        disableBeacon: true,
        target: '.joyride-enter-room-zone-mobile',
        title: 'tutorial.joinRoomTitle',
        content: 'tutorial.joinRoom',
        disableOverlayClose: true,
        stepType: 1,
        unit: '7',
        placement: 'bottom',
      },
      {
        disableBeacon: true,
        target: '.top-menu-item-wrapper-overlay',
        title: 'tutorial.topStepTitle',
        content: 'tutorial.topStep',
        disableOverlayClose: true,
        stepType: 2,
        tab: MENU_NAVIGATION.top,
        unit: '8',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.top-menu-item-wrapper-overlay',
        title: 'tutorial.topStepTitle',
        content: 'tutorial.topStep',
        disableOverlayClose: true,
        stepType: 2,
        tab: MENU_NAVIGATION.top,
        unit: '9',
        placement: 'bottom',
        collapseTabBeforeStep: true,
        expandedLandscapeSidebar: true,
      },
      // {
      //   disableBeacon: true,
      //   target: '.top-millionaires',
      //   title: 'tutorial.topStepFilterTitle',
      //   content: 'tutorial.topStepFilter',
      //   disableOverlayClose: true,
      //   stepType: 2,
      //   unit: '11',
      //   placement: 'right',
      // },
      {
        disableBeacon: true,
        target: '.top-filter-menu-item-wrapper-overlay',
        title: 'tutorial.topfilterTitle',
        content: 'tutorial.topfilter',
        disableOverlayClose: true,
        stepType: 2,
        unit: '10',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.top-search-menu-item-wrapper-overlay',
        title: 'tutorial.topSearchTitle',
        content: 'tutorial.topSearch',
        disableOverlayClose: true,
        stepType: 2,
        unit: '11',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.top-iframe-joyride-players-target',
        title: 'tutorial.topListTitle',
        content: 'tutorial.topList',
        disableOverlayClose: true,
        stepType: 2,
        unit: '12',
        placement: 'right',
      },
      {
        disableBeacon: true,
        target: '.power-rank-menu-item-wrapper',
        title: 'tutorial.powerRankingsTitle',
        content: 'tutorial.powerRankings',
        disableOverlayClose: true,
        stepType: 3,
        tab: MENU_NAVIGATION.powerRankings,
        unit: '13',
        placement: 'bottom', // using 'right' has incorrect placement
        collapseTabBeforeStep: true,
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.new-power-rankings-header-switch-section-joyride',
        title: 'tutorial.powerRankingsFilterTitle',
        content: 'tutorial.powerRankingsFilter',
        disableOverlayClose: true,
        stepType: 3,
        unit: '14',
      },
      {
        disableBeacon: true,
        target: '.new-power-rankings-body-joyride',
        title: 'tutorial.powerRankingsListTitle',
        content: 'tutorial.powerRankingsList',
        disableOverlayClose: true,
        stepType: 3,
        unit: '15',
        placement: 'center',

      },
      {
        disableBeacon: true,
        target: '.my-info-menu-item-wrapper',
        title: 'tutorial.myInfoTitle',
        content: 'tutorial.myInfo',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.myInfo,
        unit: '16',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-menu-item-wrapper',
        title: 'tutorial.myInfoTitle',
        content: 'tutorial.myInfo',
        disableOverlayClose: true,
        stepType: 4,
        unit: '17',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-body-joyride',
        title: 'tutorial.myInfoDetailTitle',
        content: 'tutorial.myInfoDetail',
        disableOverlayClose: true,
        stepType: 4,
        unit: '18',
        placement: 'top-start',
      },
      {
        disableBeacon: true,
        target: '.my-info-friends-menu-item-wrapper-overlay',
        title: 'tutorial.myFriendsTitle',
        content: 'tutorial.myFriends',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.friends,
        unit: '19',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-ignored-menu-item-wrapper-overlay',
        title: 'tutorial.ignoredTitle',
        content: 'tutorial.ignored',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.ignoredUsers,
        unit: '20',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-achievements-menu-item-wrapper-overlay',
        title: 'tutorial.achievementsTitle',
        content: 'tutorial.achievements',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.archievements,
        unit: '21',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-results-menu-item-wrapper-overlay',
        title: 'tutorial.resultsTitle',
        content: 'tutorial.results',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.gameHistory,
        unit: '22',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-weekly-stats-menu-item-wrapper-overlay',
        title: 'tutorial.weeklyStatisticsTitle',
        content: 'tutorial.weeklyStatistics',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.weeklyStatus,
        unit: '23',
        placement: 'bottom',
        expandedLandscapeSidebar: true,

      },
      {
        disableBeacon: true,
        target: '.my-info-game-logs-menu-item-wrapper-overlay',
        title: 'tutorial.gameLogsTitle',
        content: 'tutorial.gameLogs',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.gameLogs,
        unit: '24',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.tournaments-menu-item-wrapper',
        title: 'tutorial.tournamentsStepTitle',
        content: 'tutorial.tournamentsStep',
        disableOverlayClose: true,
        stepType: 5,
        tab: MENU_NAVIGATION.tournaments,
        unit: '25',
        placement: 'bottom', // using 'right' has incorrect placement
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.tournament-active-menu-item-wrapper-overlay',
        title: 'tutorial.tournamentsActiveTitle',
        content: 'tutorial.tournamentsActive',
        disableOverlayClose: true,
        tab: MENU_NAVIGATION.tournaments,
        stepType: 5,
        unit: '26',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.tournament-future-menu-item-wrapper-overlay',
        title: 'tutorial.tournamentsFutureTitle',
        content: 'tutorial.tournamentsFuture',
        disableOverlayClose: true,
        tab: MENU_NAVIGATION.futureTournaments,
        stepType: 5,
        unit: '27',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.tournament-history-menu-item-wrapper-overlay',
        title: 'tutorial.tournamentsPastTitle',
        content: 'tutorial.tournamentsPast',
        tab: MENU_NAVIGATION.tournamentsHistory,
        disableOverlayClose: true,
        stepType: 5,
        unit: '28',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
    ];
  } if (type === TOUR_STEP_TYPES.newMobile) { // for landscape mobile layout on mobile
    return [
      {
        disableBeacon: true,
        target: '.bottom-part-overlay',
        title: 'tutorial.editProfileTitle',
        content: 'tutorial.editProfile',
        disableOverlayClose: true,
        stepType: 1,
        tab: MENU_NAVIGATION.rooms,
        unit: '1',
        expandedLandscapeSidebar: true,
        placement: 'top',
      },
      {
        disableBeacon: true,
        target: '.settings-menu-item-wrapper',
        title: 'tutorial.settingsTitle',
        content: 'tutorial.settingsLandscape',
        tab: 'mobileSettings',
        disableOverlayClose: true,
        stepType: 1,
        unit: '2',
        expandedLandscapeSidebar: true,
        placement: 'right',
      },
      // {
      //   disableBeacon: true,
      //   target: '.fullscreen-button-image',
      //   title: 'tutorial.fullScreenTitle',
      //   content: 'tutorial.fullScreen',
      //   disableOverlayClose: true,
      //   stepType: 1,
      //   unit: '3',
      // },
      {
        disableBeacon: true,
        target: '.more-menu-item-wrapper',
        title: 'tutorial.usefulOptionsTitle',
        content: 'tutorial.usefulOptionsLandscape',
        disableOverlayClose: true,
        stepType: 1,
        unit: '3',
        expandedLandscapeSidebar: true,
        placement: 'right',
      },
      {
        disableBeacon: true,
        target: '.daily-bonus-menu-item-wrapper',
        title: 'tutorial.dailyBounsTitle',
        content: 'tutorial.dailyBouns',
        disableOverlayClose: true,
        stepType: 1,
        unit: '4',
        expandedLandscapeSidebar: true,
        placement: 'right',
      },
      {
        disableBeacon: true,
        target: '.shop-menu-item-wrapper',
        title: 'tutorial.zoleShopTitle',
        content: 'tutorial.zoleShop',
        disableOverlayClose: true,
        stepType: 1,
        unit: '5',
        expandedLandscapeSidebar: true,
        placement: 'right',
      },
      {
        disableBeacon: true,
        target: '.create-room-button',
        title: 'tutorial.createRoomTitle',
        content: 'tutorial.createRoom',
        disableOverlayClose: true,
        stepType: 1,
        tab: MENU_NAVIGATION.rooms,
        unit: '6',
        expandedLandscapeSidebar: true,
        placement: 'auto',
        scrollIntoViewElement: "create-room-button-landscape-id",
      },
      // {
      //   disableBeacon: true,
      //   target: '.joyride-enter-room-zone-mobile',
      //   title: 'tutorial.joinRoomTitle',
      //   content: 'tutorial.joinRoom',
      //   disableOverlayClose: true,
      //   stepType: 1,
      //   unit: '7',
      //   placement: 'left',
      // },
      {
        disableBeacon: true,
        target: '.top-menu-item-wrapper-overlay',
        title: 'tutorial.topStepTitle',
        content: 'tutorial.topStep',
        disableOverlayClose: true,
        stepType: 2,
        tab: MENU_NAVIGATION.top,
        unit: '8',
        placement: 'auto',
        expandedLandscapeSidebar: true,
        scrollIntoViewElement: "top-menu-item-wrapper-overlay-landscape-id",
      },
      {
        disableBeacon: true,
        target: '.top-menu-item-wrapper-overlay',
        title: 'tutorial.topStepTitle',
        content: 'tutorial.topStep',
        disableOverlayClose: true,
        stepType: 2,
        tab: MENU_NAVIGATION.top,
        unit: '9',
        placement: 'auto',
        collapseTabBeforeStep: true,
        expandedLandscapeSidebar: true,
        scrollIntoViewElement: "top-menu-item-wrapper-overlay-landscape-id",
      },
      {
        disableBeacon: true,
        target: '.top-millionaires-joyride',
        title: 'tutorial.topStepFilterTitle',
        content: 'tutorial.topStepFilter',
        disableOverlayClose: true,
        stepType: 2,
        unit: '10',
        placement: 'auto',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.top-filter-menu-item-wrapper',
        title: 'tutorial.topfilterTitle',
        content: 'tutorial.topfilter',
        disableOverlayClose: true,
        stepType: 2,
        unit: '11',
        placement: 'auto',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.top-search-menu-item-wrapper',
        title: 'tutorial.topSearchTitle',
        content: 'tutorial.topSearch',
        disableOverlayClose: true,
        stepType: 2,
        unit: '12',
        placement: 'auto',
        expandedLandscapeSidebar: true,
        scrollIntoViewElement: "top-search-menu-item-wrapper-id",
      },
      {
        disableBeacon: true,
        target: '.top-iframe-joyride-players-target',
        title: 'tutorial.topListTitle',
        content: 'tutorial.topList',
        disableOverlayClose: true,
        stepType: 2,
        unit: '13',
        placement: 'top',
      },
      {
        disableBeacon: true,
        target: '.power-rank-menu-item-wrapper-overlay',
        title: 'tutorial.powerRankingsTitle',
        content: 'tutorial.powerRankings',
        disableOverlayClose: true,
        stepType: 3,
        tab: MENU_NAVIGATION.powerRankings,
        unit: '14',
        placement: 'auto', // using 'right' has incorrect placement
        collapseTabBeforeStep: true,
        expandedLandscapeSidebar: true,
        scrollIntoViewElement: "power-rank-menu-item-wrapper-overlay-id",
      },
      {
        disableBeacon: true,
        target: '.new-power-rankings-header-switch-section-joyride',
        title: 'tutorial.powerRankingsFilterTitle',
        content: 'tutorial.powerRankingsFilter',
        disableOverlayClose: true,
        stepType: 3,
        unit: '15',
      },
      {
        disableBeacon: true,
        target: '.new-power-rankings-body-joyride',
        title: 'tutorial.powerRankingsListTitle',
        content: 'tutorial.powerRankingsList',
        disableOverlayClose: true,
        stepType: 3,
        unit: '16',
      },
      {
        disableBeacon: true,
        target: '.my-info-menu-item-wrapper-overlay',
        title: 'tutorial.myInfoTitle',
        content: 'tutorial.myInfo',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.myInfo,
        unit: '17',
        placement: 'auto',
        expandedLandscapeSidebar: true,
        scrollIntoViewElement: "my-info-menu-item-wrapper-overlay-id",
      },
      {
        disableBeacon: true,
        target: '.my-info-menu-item-wrapper-overlay',
        title: 'tutorial.myInfoTitle',
        content: 'tutorial.myInfo',
        disableOverlayClose: true,
        stepType: 4,
        unit: '18',
        placement: 'auto',
        expandedLandscapeSidebar: true,
        scrollIntoViewElement: "my-info-menu-item-wrapper-overlay-id",

      },
      {
        disableBeacon: true,
        target: '.my-info-body-joyride',
        title: 'tutorial.myInfoDetailTitle',
        content: 'tutorial.myInfoDetail',
        disableOverlayClose: true,
        stepType: 4,
        unit: '19',
        placement: 'top-start',
      },
      {
        disableBeacon: true,
        target: '.my-info-friends-menu-item-wrapper-overlay',
        title: 'tutorial.myFriendsTitle',
        content: 'tutorial.myFriends',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.friends,
        unit: '20',
        placement: 'auto',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-ignored-menu-item-wrapper-overlay',
        title: 'tutorial.ignoredTitle',
        content: 'tutorial.ignored',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.ignoredUsers,
        unit: '21',
        placement: 'auto',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-achievements-menu-item-wrapper-overlay',
        title: 'tutorial.achievementsTitle',
        content: 'tutorial.achievements',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.archievements,
        unit: '22',
        placement: 'auto',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-results-menu-item-wrapper-overlay',
        title: 'tutorial.resultsTitle',
        content: 'tutorial.results',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.gameHistory,
        unit: '23',
        placement: 'auto',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-weekly-stats-menu-item-wrapper-overlay',
        title: 'tutorial.weeklyStatisticsTitle',
        content: 'tutorial.weeklyStatistics',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.weeklyStatus,
        unit: '24',
        placement: 'auto',
        expandedLandscapeSidebar: true,
        scrollIntoViewElement: "my-info-weekly-stats-menu-item-wrapper-overlay-id",
      },
      {
        disableBeacon: true,
        target: '.my-info-game-logs-menu-item-wrapper-overlay',
        title: 'tutorial.gameLogsTitle',
        content: 'tutorial.gameLogs',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.gameLogs,
        unit: '25',
        placement: 'auto',
        expandedLandscapeSidebar: true,
        scrollIntoViewElement: "my-info-game-logs-menu-item-wrapper-overlay-id",

      },
      {
        disableBeacon: true,
        target: '.tournaments-menu-item-wrapper',
        title: 'tutorial.tournamentsStepTitle',
        content: 'tutorial.tournamentsStep',
        disableOverlayClose: true,
        stepType: 5,
        tab: MENU_NAVIGATION.tournaments,
        unit: '26',
        placement: 'auto',
        expandedLandscapeSidebar: true,
        scrollIntoViewElement: "tournaments-menu-item-wrapper-id",
      },
      {
        disableBeacon: true,
        target: '.tournament-active-menu-item-wrapper-overlay',
        title: 'tutorial.tournamentsActiveTitle',
        content: 'tutorial.tournamentsActive',
        disableOverlayClose: true,
        tab: MENU_NAVIGATION.tournaments,
        stepType: 5,
        unit: '27',
        placement: 'auto',
        expandedLandscapeSidebar: true,
        //scrollIntoViewElement: "tournament-active-menu-item-wrapper-overlay-id",

      },
      {
        disableBeacon: true,
        target: '.tournament-future-menu-item-wrapper-overlay',
        title: 'tutorial.tournamentsFutureTitle',
        content: 'tutorial.tournamentsFuture',
        disableOverlayClose: true,
        tab: MENU_NAVIGATION.futureTournaments,
        stepType: 5,
        unit: '28',
        placement: 'auto',
        expandedLandscapeSidebar: true,
                // scrollIntoViewElement: "tournament-active-menu-item-wrapper-overlay-id",

      },
      {
        disableBeacon: true,
        target: '.tournament-history-menu-item-wrapper-overlay',
        title: 'tutorial.tournamentsPastTitle',
        content: 'tutorial.tournamentsPast',
        tab: MENU_NAVIGATION.tournamentsHistory,
        disableOverlayClose: true,
        stepType: 5,
        unit: '29',
        placement: 'auto',
        expandedLandscapeSidebar: true,
        // scrollIntoViewElement: "tournament-active-menu-item-wrapper-overlay-id",
      },
    ];
  } if (type === TOUR_STEP_TYPES.newMobileIFrame) { // for landscape mobile layout (iFrame)
    return [
      {
        disableBeacon: true,
        target: '.bottom-part-overlay',
        title: 'tutorial.editProfileTitle',
        content: 'tutorial.editProfile',
        disableOverlayClose: true,
        stepType: 1,
        tab: MENU_NAVIGATION.rooms,
        unit: '1',
        expandedLandscapeSidebar: true,
        placement: 'top',
      },
      {
        disableBeacon: true,
        target: '.settings-menu-item-wrapper',
        title: 'tutorial.settingsTitle',
        content: isLandscape() ? 'tutorial.settingsLandscape' : 'tutorial.settings',
        tab: 'mobileSettings',
        disableOverlayClose: true,
        stepType: 1,
        unit: '2',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: isLandscape() ? '.fullscreen-button-image-landscape' : '.fullscreen-button-image',
        title: 'tutorial.fullScreenTitle',
        content: 'tutorial.fullScreen',
        disableOverlayClose: true,
        stepType: 1,
        unit: '3',
      },
      {
        disableBeacon: true,
        target: '.more-menu-item-wrapper',
        title: 'tutorial.usefulOptionsTitle',
        content: isLandscape() ? 'tutorial.usefulOptionsLandscape' : 'tutorial.usefulOptions',
        disableOverlayClose: true,
        stepType: 1,
        unit: '4',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.daily-bonus-menu-item-wrapper',
        title: 'tutorial.dailyBounsTitle',
        content: 'tutorial.dailyBouns',
        disableOverlayClose: true,
        stepType: 1,
        unit: '5',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.shop-menu-item-wrapper',
        title: 'tutorial.zoleShopTitle',
        content: 'tutorial.zoleShop',
        disableOverlayClose: true,
        stepType: 1,
        unit: '6',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.create-room-button',
        title: 'tutorial.createRoomTitle',
        content: 'tutorial.createRoom',
        disableOverlayClose: true,
        stepType: 1,
        tab: MENU_NAVIGATION.rooms,
        unit: '7',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.joyride-enter-room-zone-mobile',
        title: 'tutorial.joinRoomTitle',
        content: 'tutorial.joinRoom',
        disableOverlayClose: true,
        stepType: 1,
        unit: '8',
        placement: 'left',
      },
      {
        disableBeacon: true,
        target: '.top-menu-item-wrapper-overlay',
        title: 'tutorial.topStepTitle',
        content: 'tutorial.topStep',
        disableOverlayClose: true,
        stepType: 2,
        tab: MENU_NAVIGATION.top,
        unit: '9',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.top-menu-item-wrapper-overlay',
        title: 'tutorial.topStepTitle',
        content: 'tutorial.topStep',
        disableOverlayClose: true,
        stepType: 2,
        tab: MENU_NAVIGATION.top,
        unit: '10',
        placement: 'bottom',
        collapseTabBeforeStep: true,
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.top-filter-menu-item-wrapper-overlay',
        title: 'tutorial.topfilterTitle',
        content: 'tutorial.topfilterIFrame',
        disableOverlayClose: true,
        stepType: 2,
        unit: '11',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.top-search-menu-item-wrapper-overlay',
        title: 'tutorial.topSearchTitle',
        content: 'tutorial.topSearch',
        disableOverlayClose: true,
        stepType: 2,
        unit: '12',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.top-iframe-joyride-players-target',
        title: 'tutorial.topListTitle',
        content: 'tutorial.topList',
        disableOverlayClose: true,
        stepType: 2,
        unit: '14',
        placement: 'right',
      },
      {
        disableBeacon: true,
        target: '.power-rank-menu-item-wrapper',
        title: 'tutorial.powerRankingsTitle',
        content: 'tutorial.powerRankings',
        disableOverlayClose: true,
        stepType: 3,
        tab: MENU_NAVIGATION.powerRankings,
        unit: '15',
        placement: 'bottom', // using 'right' has incorrect placement
        collapseTabBeforeStep: true,
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.new-power-rankings-header-switch-section-joyride',
        title: 'tutorial.powerRankingsFilterTitle',
        content: 'tutorial.powerRankingsFilter',
        disableOverlayClose: true,
        stepType: 3,
        unit: '16',
      },
      {
        disableBeacon: true,
        target: '.new-power-rankings-body-joyride',
        title: 'tutorial.powerRankingsListTitle',
        content: 'tutorial.powerRankingsList',
        disableOverlayClose: true,
        stepType: 3,
        unit: '17',
      },
      {
        disableBeacon: true,
        target: '.my-info-menu-item-wrapper',
        title: 'tutorial.myInfoTitle',
        content: 'tutorial.myInfo',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.myInfo,
        unit: '18',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-menu-item-wrapper',
        title: 'tutorial.myInfoTitle',
        content: 'tutorial.myInfo',
        disableOverlayClose: true,
        stepType: 4,
        unit: '19',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-body-joyride',
        title: 'tutorial.myInfoDetailTitle',
        content: 'tutorial.myInfoDetail',
        disableOverlayClose: true,
        stepType: 4,
        unit: '20',
        placement: 'top-start',
      },
      {
        disableBeacon: true,
        target: '.my-info-friends-menu-item-wrapper-overlay',
        title: 'tutorial.myFriendsTitle',
        content: 'tutorial.myFriends',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.friends,
        unit: '21',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-ignored-menu-item-wrapper-overlay',
        title: 'tutorial.ignoredTitle',
        content: 'tutorial.ignored',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.ignoredUsers,
        unit: '22',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-achievements-menu-item-wrapper-overlay',
        title: 'tutorial.achievementsTitle',
        content: 'tutorial.achievements',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.archievements,
        unit: '23',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-results-menu-item-wrapper-overlay',
        title: 'tutorial.resultsTitle',
        content: 'tutorial.results',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.gameHistory,
        unit: '24',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-weekly-stats-menu-item-wrapper-overlay',
        title: 'tutorial.weeklyStatisticsTitle',
        content: 'tutorial.weeklyStatistics',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.weeklyStatus,
        unit: '25',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.my-info-game-logs-menu-item-wrapper-overlay',
        title: 'tutorial.gameLogsTitle',
        content: 'tutorial.gameLogs',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.gameLogs,
        unit: '26',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.tournaments-menu-item-wrapper',
        title: 'tutorial.tournamentsStepTitle',
        content: 'tutorial.tournamentsStep',
        disableOverlayClose: true,
        stepType: 5,
        tab: MENU_NAVIGATION.tournaments,
        unit: '27',
        placement: 'bottom', // using 'right' has incorrect placement
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.tournament-active-menu-item-wrapper-overlay',
        title: 'tutorial.tournamentsActiveTitle',
        content: 'tutorial.tournamentsActive',
        disableOverlayClose: true,
        tab: MENU_NAVIGATION.tournaments,
        stepType: 5,
        unit: '28',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.tournament-future-menu-item-wrapper-overlay',
        title: 'tutorial.tournamentsFutureTitle',
        content: 'tutorial.tournamentsFuture',
        disableOverlayClose: true,
        tab: MENU_NAVIGATION.futureTournaments,
        stepType: 5,
        unit: '29',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
      {
        disableBeacon: true,
        target: '.tournament-history-menu-item-wrapper-overlay',
        title: 'tutorial.tournamentsPastTitle',
        content: 'tutorial.tournamentsPast',
        tab: MENU_NAVIGATION.tournamentsHistory,
        disableOverlayClose: true,
        stepType: 5,
        unit: '30',
        placement: 'bottom',
        expandedLandscapeSidebar: true,
      },
    ];
  }
  if (type === TOUR_STEP_TYPES.newMobilePortrait) {
    return [
      {
        disableBeacon: true,
        target: '.player-info-player-background',
        title: 'tutorial.editProfileTitle',
        content: 'tutorial.editProfile',
        disableOverlayClose: true,
        stepType: 1,
        tab: MENU_NAVIGATION.rooms,
        unit: '1',
        placement: 'top',
      },
      {
        disableBeacon: true,
        target: '.dropdown-menu-link',
        title: 'tutorial.settingsTitle',
        content: 'tutorial.settings',
        tab: 'mobileSettings',
        disableOverlayClose: true,
        stepType: 1,
        unit: '2',
        placement: 'bottom',
      },
      // {
      //   disableBeacon: true,
      //   target: '.fullscreen-button-image',
      //   title: 'tutorial.fullScreenTitle',
      //   content: 'tutorial.fullScreen',
      //   disableOverlayClose: true,
      //   stepType: 1,
      //   unit: '3',
      // },
      {
        disableBeacon: true,
        target: '.dropdown-menu-link',
        title: 'tutorial.usefulOptionsTitle',
        content: 'tutorial.usefulOptions',
        disableOverlayClose: true,
        stepType: 1,
        unit: '3',
        placement: 'bottom',
      },
      {
        disableBeacon: true,
        target: '.layout-mobile-footer-bonus-link',
        title: 'tutorial.dailyBounsTitle',
        content: 'tutorial.dailyBouns',
        disableOverlayClose: true,
        stepType: 1,
        unit: '4',
        placement: 'top',
      },
      {
        disableBeacon: true,
        target: '.layout-mobile-footer-shop-link',
        title: 'tutorial.zoleShopTitle',
        content: 'tutorial.zoleShop',
        disableOverlayClose: true,
        stepType: 1,
        unit: '5',
        placement: 'top',
      },
      {
        disableBeacon: true,
        target: '.create-room-button-portrait',
        title: 'tutorial.createRoomTitle',
        content: 'tutorial.createRoom',
        disableOverlayClose: true,
        stepType: 1,
        tab: MENU_NAVIGATION.rooms,
        unit: '6',
        placement: 'top',
      },
      {
        disableBeacon: true,
        target: '.joyride-enter-room-zone-mobile',
        title: 'tutorial.joinRoomTitle',
        content: 'tutorial.joinRoom',
        disableOverlayClose: true,
        stepType: 1,
        unit: '7',
        placement: 'bottom',
      },
      {
        disableBeacon: true,
        target: '.top-tab-menubar-portrait',
        title: 'tutorial.topStepTitle',
        content: 'tutorial.topStep',
        disableOverlayClose: true,
        stepType: 2,
        tab: MENU_NAVIGATION.top,
        unit: '8',
        placement: 'top',
        scrollIntoViewElement: 'top-tab-menubar-portrait-id',
        offsetY: -50,

      },
      {
        disableBeacon: true,
        target: '.top-tab-menubar-portrait',
        title: 'tutorial.topStepTitle',
        content: 'tutorial.topStep',
        disableOverlayClose: true,
        stepType: 2,
        tab: MENU_NAVIGATION.top,
        unit: '9',
        placement: 'top',
        collapseTabBeforeStep: true,
        scrollIntoViewElement: 'top-tab-menubar-portrait-id',
        offsetY: -50,
      },
      // {
      //   disableBeacon: true,
      //   target: '.top-millionaires',
      //   title: 'tutorial.topStepFilterTitle',
      //   content: 'tutorial.topStepFilter',
      //   disableOverlayClose: true,
      //   stepType: 2,
      //   unit: '11',
      //   placement: 'right',
      // },
      {
        disableBeacon: true,
        target: '.top-filters-joyride-portrait',
        title: 'tutorial.topfilterTitle',
        content: 'tutorial.topfilter',
        disableOverlayClose: true,
        stepType: 2,
        unit: '10',
        placement: 'top',
      },
      {
        disableBeacon: true,
        target: '.top-search-joyride-portrait-target',
        title: 'tutorial.topSearchTitle',
        content: 'tutorial.topSearch',
        disableOverlayClose: true,
        stepType: 2,
        unit: '11',
        placement: 'bottom',
        scrollIntoViewElement: 'top-mobile-search',
      },
      {
        disableBeacon: true,
        target: '.top-iframe-joyride-players-target',
        title: 'tutorial.topListTitle',
        content: 'tutorial.topList',
        disableOverlayClose: true,
        stepType: 2,
        unit: '12',
        placement: 'bottom',
      },
      {
        disableBeacon: true,
        target: '.power-rankings-tab-menubar-portrait',
        title: 'tutorial.powerRankingsTitle',
        content: 'tutorial.powerRankings',
        disableOverlayClose: true,
        stepType: 3,
        tab: MENU_NAVIGATION.powerRankings,
        unit: '13',
        placement: 'top',
        collapseTabBeforeStep: true,
        offsetY: -50,
      },
      {
        disableBeacon: true,
        target: '.new-power-rankings-header-switch-section-joyride',
        title: 'tutorial.powerRankingsFilterTitle',
        content: 'tutorial.powerRankingsFilter',
        disableOverlayClose: true,
        stepType: 3,
        unit: '14',
      },
      {
        disableBeacon: true,
        target: '.new-power-rankings-body-joyride',
        title: 'tutorial.powerRankingsListTitle',
        content: 'tutorial.powerRankingsList',
        disableOverlayClose: true,
        stepType: 3,
        unit: '15',
      },
      {
        disableBeacon: true,
        target: '.my-info-tab-menubar-portrait',
        title: 'tutorial.myInfoTitle',
        content: 'tutorial.myInfo',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.myInfo,
        unit: '16',
        placement: 'top',
        scrollIntoViewElement: 'my-info-tab-menubar-portrait-id',
        offsetY: -50,
      },
      {
        disableBeacon: true,
        target: '.my-info-tab-menubar-portrait',
        title: 'tutorial.myInfoTitle',
        content: 'tutorial.myInfo',
        disableOverlayClose: true,
        stepType: 4,
        unit: '17',
        placement: 'top',
        scrollIntoViewElement: 'my-info-tab-menubar-portrait-id',
        offsetY: -50,
      },
      {
        disableBeacon: true,
        target: '.my-info-body-joyride',
        title: 'tutorial.myInfoDetailTitle',
        content: 'tutorial.myInfoDetail',
        disableOverlayClose: true,
        stepType: 4,
        unit: '18',
        placement: 'top-start',
      },
      {
        disableBeacon: true,
        target: '.my-info-friends-button',
        title: 'tutorial.myFriendsTitle',
        content: 'tutorial.myFriends',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.friends,
        unit: '19',
        placement: 'top',
        offsetY: -50,
      },
      {
        disableBeacon: true,
        target: '.my-info-ignoredPlayers-button',
        title: 'tutorial.ignoredTitle',
        content: 'tutorial.ignored',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.ignoredUsers,
        unit: '20',
        placement: 'top',
        offsetY: -50,
      },
      {
        disableBeacon: true,
        target: '.my-info-achievements-button',
        title: 'tutorial.achievementsTitle',
        content: 'tutorial.achievements',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.archievements,
        unit: '21',
        placement: 'top',
        scrollIntoViewElement: 'my-info-achievements-id',
        offsetY: -50,
      },
      {
        disableBeacon: true,
        target: '.my-info-results-button',
        title: 'tutorial.resultsTitle',
        content: 'tutorial.results',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.gameHistory,
        unit: '22',
        placement: 'top',
        scrollIntoViewElement: 'my-info-results-id',
        offsetY: -50,
      },
      {
        disableBeacon: true,
        target: '.my-info-weekly-status-button',
        title: 'tutorial.weeklyStatisticsTitle',
        content: 'tutorial.weeklyStatistics',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.weeklyStatus,
        unit: '23',
        placement: 'top',
        scrollIntoViewElement: 'my-info-weekly-status-id',
        offsetY: -50,

      },
      {
        disableBeacon: true,
        target: '.my-info-game-logs-sub-menu-button',
        title: 'tutorial.gameLogsTitle',
        content: 'tutorial.gameLogs',
        disableOverlayClose: true,
        stepType: 4,
        tab: MENU_NAVIGATION.gameLogs,
        unit: '24',
        placement: 'top',
        scrollIntoViewElement: 'my-info-game-logs-id',
        offsetY: -50,

      },
      {
        disableBeacon: true,
        target: '.tournaments-tab-menubar-portrait',
        title: 'tutorial.tournamentsStepTitle',
        content: 'tutorial.tournamentsStep',
        disableOverlayClose: true,
        stepType: 5,
        tab: MENU_NAVIGATION.tournaments,
        unit: '25',
        placement: 'top',
        scrollIntoViewElement: 'tournaments-tab-menubar-portrait-id',
        offsetY: -50
      },
      {
        disableBeacon: true,
        target: '.active-tournaments-button-joyride-target',
        title: 'tutorial.tournamentsActiveTitle',
        content: 'tutorial.tournamentsActive',
        disableOverlayClose: true,
        tab: MENU_NAVIGATION.tournaments,
        stepType: 5,
        unit: '26',
        placement: 'top',
      },
      {
        disableBeacon: true,
        target: '.future-tournaments-button-joyride-target',
        title: 'tutorial.tournamentsFutureTitle',
        content: 'tutorial.tournamentsFuture',
        disableOverlayClose: true,
        tab: MENU_NAVIGATION.futureTournaments,
        stepType: 5,
        unit: '27',
        placement: 'top',
      },
      {
        disableBeacon: true,
        target: '.past-tournaments-button-joyride-target',
        title: 'tutorial.tournamentsPastTitle',
        content: 'tutorial.tournamentsPast',
        tab: MENU_NAVIGATION.tournamentsHistory,
        disableOverlayClose: true,
        stepType: 5,
        unit: '28',
        placement: 'top',
      },
    ];
  }
};

export const TOUR_PAGES = [1, 2, 3, 4, 5];

export const TOUR_EXTRA_PAGES = [2, 4];
export const TOUR_EXTRA_BEFORE_PAGES = [1, 3];

export const TOUR_FIRST_PAGE = 1;

export const TOUR_OLD_ALL_STEPS = ['1', '2', '3', '4', '5', '6', '7', '8', '10', '11', '12', '13', '14', '15', '16', '17', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];

export const TOUR_NEW_ALL_STEPS = ['1', '2', '3', '4', '5', '6', '7', '8', '10', '11', '12', '13', '14', '15', '16', '17', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];

export const TOUR_NEW_ALL_STEPS_MOBILE_DESKTOP = ['1', '2', '3', '4', '5', '6', '7', '9', '10', '11', '12', '13', '14', '15', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'];

export const TOUR_NEW_ALL_STEPS_MOBILE = ['1', '2', '3', '4', '5', '6', '9', '10', '11', '12', '13', '14', '15', '16', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29'];

export const TOUR_NEW_ALL_STEPS_IFRAME = ['1', '2', '3', '4', '5', '6', '7', '8', '10', '11', '12', '14', '15', '16', '17', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];

export const TOUR_NEW_ALL_STEPS_MOBILE_PORTRAIT = ['1', '2', '3', '4', '5', '6', '7', '9', '10', '11', '12', '13', '14', '15', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'];


export const CALC_SECONDS_BY_TIME = time => 1000 * 60 * 60 * 24 * time;

export const CALC_ACTIVE_FEEDBACK_TIME = (startTime, time) => startTime + (1000 * 60 * 60 * 24 * time);

export const POWER_RANKINGS_ROUND_MIN_LIMIT = 10;

export const COOKIES_TERMS_OPEN = {
  home: 'homePage',
  login: 'loginModal',
};


export const NEW_VERSION = '3.9.2';

export const SUSPICIOUS_PLAYERS = 'suspiciousPlayers';
export const TRANSACTIONS = 'transactions';
export const FOLD = 'fold';

export const SUSPICIOUSPLAYERS_TYPES = [
  { id: 'suspiciousPlayers', tLabel: 'Suspicious Players' },
  { id: 'transactions', tLabel: 'Transactions' },
  { id: 'fold', tLabel: 'Fold' },
];

export const BALANCE_PER_RANGE = [
  {
    id: '1', range: '>1000000', from: '1000000', label: '1 000 000 or more  coins',
  },
  {
    id: '2', range: '100000-999999', from: '100000', to: '999999', label: '100 000 - 999 999 coins',
  },
  {
    id: '3', range: '50000-99999', from: '50000', to: '99999', label: '50 000 - 99 999 coins',
  },
  {
    id: '4', range: '1000-49999', from: '1000', to: '49999', label: '1000 - 49 999 coins',
  },
  {
    id: '5', range: '1-1000', from: '1', to: '1000', label: '1 - 1000 coins',
  },
  {
    id: '6', range: '-1000-0', from: '-1000', to: '0', label: '-1000 - 0 coins',
  },
  {
    id: '7', range: '<-1000', from: '-1000', label: 'less than -1000 coins',
  },
];

export const DAILY_BONUS_BALANCE = 100;

export const COMMON_PULE = { p: 'P', _p: 'P-' };

export const GMAE_WIN_TYPE = {
  large: 'large', small: 'small', parasts: 'parasts', jani: 'jani', bezstikis: 'bezstikis',
};

export const GMAE_STATE = { choose: 'choose', burry: 'burry', results: 'results' };

export const GAME_GUIDE_TITLE = {
  no: 'no', label: 'label',
};

export const HOLIDAYS = {
  holidays: {
    newYear: '01-01',
    easter: '03-31',
    '4May': '05-04',
    ligo: '06-23',
    jani: '06-24',
    '1September': '09-01',
    lacplesa: '11-11',
    '18November': '11-18',
    christmas: '12-24',
    christmas2: '12-25',
    oldYear: '12-31',
  },
};

export const DOMAIN_NAME = 'spelezoli.lv';

export const TOURNAMENT_STATE = {
  play: 'play',
  pause: 'pause',
  registartion: 'registration',
};

export const GMAE_RESULT_TYPE = {
  parasta: 'parasta',
  zole: 'zole',
  mazaZole: 'mazaZole',
  galdins: 'galdins',
};

export const GMAE_RESULT_SCORE_TYPE = {
  parastaWinLarage: 'parasta-win-large',
  parastaWinSmall: 'parasta-win-small',
  zoleWinLarage: 'zole-win-large',
  zoleWinSmall: 'zole-win-small',
  janiWinLarge: 'jani-win-large',
  janiWinSmall: 'jani-win-small',
  bezstikisWinLarge: 'bezstikis-win-large',
  bezstikisWinSmall: 'bezstikis-win-small',
  mazaZoleWinLarge: 'mazaZole-win-large',
  mazaZoleWinSmall: 'mazaZole-win-small',
  galdinsWin: 'galdins-win',
};

export const PLAYER_SCORE_4_POSITION = {
  player1: [
    {
      id: '1',
      player: 'player4',
      label: 'player4-',
    },
    {
      id: '2',
      player: 'player1',
      label: 'player1-',
    },
    {
      id: '3',
      player: 'player2',
      label: 'player2-',
    },
    {
      id: '4',
      player: 'player3',
      label: 'player3-',
    },
  ],
  player2: [
    {
      id: '1',
      player: 'player1',
      label: 'player1-',
    },
    {
      id: '2',
      player: 'player2',
      label: 'player2-',
    },
    {
      id: '3',
      player: 'player3',
      label: 'player3-',
    },
    {
      id: '4',
      player: 'player4',
      label: 'player4-',
    },
  ],
  player3: [
    {
      id: '1',
      player: 'player2',
      label: 'player2-',
    },
    {
      id: '2',
      player: 'player3',
      label: 'player3-',
    },
    {
      id: '3',
      player: 'player4',
      label: 'player4-',
    },
    {
      id: '4',
      player: 'player1',
      label: 'player1-',
    },
  ],
  player4: [
    {
      id: '1',
      player: 'player3',
      label: 'player3-',
    },
    {
      id: '2',
      player: 'player4',
      label: 'player4-',
    },
    {
      id: '3',
      player: 'player1',
      label: 'player1-',
    },
    {
      id: '4',
      player: 'player2',
      label: 'player2-',
    },
  ],
};

export const PLAYER_SCORE_POSITION = {
  player1: [
    {
      id: '1',
      player: 'player2',
      label: 'player2-',
    },
    {
      id: '2',
      player: 'player1',
      label: 'player1-',
    },
    {
      id: '3',
      player: 'player3',
      label: 'player3-',
    },
  ],
  player2: [
    {
      id: '1',
      player: 'player3',
      label: 'player3-',
    },
    {
      id: '2',
      player: 'player2',
      label: 'player2-',
    },
    {
      id: '3',
      player: 'player1',
      label: 'player1-',
    },
  ],
  player3: [
    {
      id: '1',
      player: 'player1',
      label: 'player1-',
    },
    {
      id: '2',
      player: 'player3',
      label: 'player3-',
    },
    {
      id: '3',
      player: 'player2',
      label: 'player2-',
    },
  ],
};

export const ROOM_PLAYER_POSITION = {
  player1: 'player1', player2: 'player2', player3: 'player3', player4: 'player4',
};

export const SCREEN_MODE = {
  dark: 'dark',
  light: 'light',
  normal: 'normal',
};

export const SCREEN_MODE_OPTIONS = [
  { name: 'dark', value: 'dark' },
  { name: 'normal', value: 'normal' },
  { name: 'light', value: 'light' },
];

export const CONTACT_INFORMATION = {
  email: 'info@spelezoli.lv',
  phone: '+371 26 62 26 77',
  address: {
    info1: 'SIA “Draxo Games”',
    info2: 'Reģ. Nr.: 40103927834',
    info3: 'Liepājas iela 49 - 6, Kuldīga, Kuldīgas nov.,',
    info4: 'LV-3301, Latvija',
  },
};

export const PAYMENT_TYPES = {
  swedbank: { id: 'swedbank', displayName: 'Swedbank AS', available: true },
  seb: { id: 'seb', displayName: 'SEB Banka AS', available: true },
  citadele: { id: 'citadele', displayName: 'AS Citadele banka', available: true },
  luminor: { id: 'luminor', displayName: 'Luminor Bank AS', available: true },
  card: { id: 'card', displayName: 'VISA/MasterCard', available: true },
  paypal: { id: 'paypal', displayName: '', available: false },
  textMessage: { id: 'textMessage', displayName: '', available: false },
};

export const BUY_PAGE_PRODUCTS = {
  1: { type: 'gameMoney', price: '0.85', amount: '250' },
  2: { type: 'gameMoney', price: '1.41', amount: '500' },
  3: { type: 'gameMoney', price: '2.85', amount: '1500' },
  4: { type: 'gameMoney', price: '4.27', amount: '4000' },
  5: { type: 'gameMoney', price: '14.23', amount: '15000' },
  6: { type: 'balanceReset', price: '5.00' },
  7: { type: 'pointsReset', price: '5.00' },
  8: { type: 'profileRestart', price: '5.00' },
  9: { type: 'unban', price: '10.00', descriptionId: 'buyPage.payUnBanContent' },
  10: { type: 'hideAdsMonth', price: '3.00' },
  11: { type: 'hideAdsYear', price: '25.00' },
};

export const USER_SETTINGS = {
  new_design: 'newDesign',
};

export const LIMIT_DATE_RANGE = {
  minDate: new Date(new Date().getFullYear() - 100, 1, 1, 0, 0, 0),
  maxDate: new Date(),
};

export const ADMIN_BANS_RANGE = {
  start: 1,
  end: 150,
};

export const FONT_MODE = {
  normal: 'normal',
  small: 'small',
  large: 'large',
  xLarge: 'xLarge',
};

export const FONT_MODE_ARRAY = ['normal', 'large', 'xLarge'];
export const SCREEN_MODE_ARRAY = ['dark', 'normal', 'light'];

export const MY_INFO_TABS = [MENU_NAVIGATION.myInfo, MENU_NAVIGATION.friends, MENU_NAVIGATION.ignoredUsers, MENU_NAVIGATION.archievements, MENU_NAVIGATION.gameHistory, MENU_NAVIGATION.weeklyStatus, MENU_NAVIGATION.gameLogs];
export const POWER_RANKINGS_TABS = [
  MENU_NAVIGATION.powerRankings,
  MENU_NAVIGATION.powerRankingsPoints,
  MENU_NAVIGATION.powerRankingsCoins,
  MENU_NAVIGATION.powerRankingsWinsCount,
  MENU_NAVIGATION.powerRankingsWinPercentage,
  MENU_NAVIGATION.powerRankingsLossCount,
  MENU_NAVIGATION.powerRankingsLossPercentage,
];
export const BUY_PAGE_TABS = [
  MENU_NAVIGATION.buyPage,
  MENU_NAVIGATION.buyPageMoneyPacks,
  MENU_NAVIGATION.buyPagePremiumPacks,
  MENU_NAVIGATION.buyPageHistory,
  MENU_NAVIGATION.buyPageRedeemCode,
  MENU_NAVIGATION.buyPageDiscountEvent,
];
export const TOURNAMENTS_TABS = [
  MENU_NAVIGATION.tournaments,
  MENU_NAVIGATION.futureTournaments,
  MENU_NAVIGATION.tournamentsHistory,
];

export const ACHIEVEMENT_IDS = [
  'singleAchievements',
  'gamesPlayed',
  'gamesWon',
  'highestEarned',
  'earnedInADay',
  'maxSuccessionWins',
  'maxSuccessionLosses',
  'zolePlayed',
  'zoleWon',
  'fastGamesPlayed',
  'lightningGamesPlayed',
  'unlimitedGamesPlayed',
  'mazaZolePlayed',
  'mazaZoleWon',
  'bonusSpins',
  'joinedTournaments',
  'reachedTournamentTop10',
  'reachedTop100',
  'giftsReceived',
  'giftsSent',
  'maxParties',
  'supportMessagesSent',
  'storePurchase',
  'galdinsPlayed',
  'galdinsWon',
  'galdinsLose',
  'maxDailyLogin',
];

export const ACHIEVEMENT_FOR_STATISTICS = [
  'singleAchievements',
  'gamesPlayed',
  'gamesWon',
  'highestEarned',
  'earnedInADay',
  'maxSuccessionWins',
  'maxSuccessionLosses',
  'zolePlayed',
  'zoleWon',
  'fastGamesPlayed',
  'lightningGamesPlayed',
  'unlimitedGamesPlayed',
  'mazaZolePlayed',
  'mazaZoleWon',
  'bonusSpins',
  'joinedTournaments',
  'giftsReceived',
  'reachedTournamentTop10',
  'reachedTop100',
  'giftsSent',
  'maxParties',
  'supportMessagesSent',
  'storePurchase',
  'galdinsPlayed',
  'galdinsWon',
  'galdinsLose',
  'maxDailyLogin',
];

export const ACHIEVEMENT_LABEL = {
  singleAchievements: 'Unique Achievements',
  gamesPlayed: 'Games played',
  gamesWon: 'Games won',
  highestEarned: 'Earned in total',
  earnedInADay: 'Earned a day',
  maxSuccessionWins: 'Consecutive wins',
  maxSuccessionLosses: 'Consecutive losses',
  zolePlayed: 'Zole played',
  zoleWon: 'Zole won',
  fastGamesPlayed: 'Fast games played',
  lightningGamesPlayed: 'Lightning games played',
  unlimitedGamesPlayed: 'Unlimited games played',
  mazaZolePlayed: 'Small Zole played',
  mazaZoleWon: 'Small Zole won',
  bonusSpins: 'Times spun Lucky Wheel',
  joinedTournaments: 'Tournaments participated',
  giftsReceived: 'Received gifts',
  reachedTournamentTop10: 'Times in tournament Top 10',
  reachedTop100: 'Days was in the TOP 100',
  giftsSent: 'Sent gifts',
  maxParties: 'Games in one room',
  supportMessagesSent: 'Messages to administration',
  storePurchase: 'Shop purchases',
  galdinsPlayed: 'Played tables',
  galdinsWon: 'Won tables',
  galdinsLose: 'Lost tables',
  maxDailyLogin: 'Played days in a row',
};

export const UNIQUE_ACHIEVEMENT_DATA = {
  winLarge61: {
    label: 'winLarge61',
    reward: 100,
  },
  winLarge91: {
    label: 'winLarge91',
    reward: 100,
  },
  winLarge120: {
    label: 'winLarge120',
    reward: 300,
  },
  winSmall60: {
    label: 'winSmall60',
    reward: 100,
  },
  winSmall90: {
    label: 'winSmall90',
    reward: 100,
  },
  winSmall120: {
    label: 'winSmall120',
    reward: 300,
  },
  winZoleAll: {
    label: 'winZoleAll',
    reward: 300,
  },
  take3Aces: {
    label: 'take3Aces',
    reward: 100,
  },
  take0Points: {
    label: 'take0Points',
    reward: 100,
  },
  winZoleTwoAces: {
    label: 'winZoleTwoAces',
    reward: 300,
  },
  loseLarge60: {
    label: 'loseLarge60',
    reward: 100,
  },
  loseLarge30: {
    label: 'loseLarge30',
    reward: 100,
  },
  loseLarge0: {
    label: 'loseLarge0',
    reward: 100,
  },
  takeTwoAces: {
    label: 'takeTwoAces',
    reward: 100,
  },
  takeTwoNines: {
    label: 'takeTwoNines',
    reward: 100,
  },
  loseZoleTwoNines: {
    label: 'loseZoleTwoNines',
    reward: 300,
  },
  loseTableAllTricks: {
    label: 'loseTableAllTricks',
    reward: 100,
  },
  dealtNoTrumps: {
    label: 'dealtNoTrumps',
    reward: 300,
  },
  dealtOnlyQueensJacks: {
    label: 'dealtOnlyQueensJacks',
    reward: 1000,
  },
  dealtOnly789: {
    label: 'dealtOnly789',
    reward: 300,
  },
};

export const UNIQUE_ACHIEVEMENT_DATA_OLD_VALUES = {
  winLarge61: {
    label: 'winLarge61',
    reward: 300,
  },
  winLarge91: {
    label: 'winLarge91',
    reward: 500,
  },
  winLarge120: {
    label: 'winLarge120',
    reward: 1000,
  },
  winSmall60: {
    label: 'winSmall60',
    reward: 300,
  },
  winSmall90: {
    label: 'winSmall90',
    reward: 500,
  },
  winSmall120: {
    label: 'winSmall120',
    reward: 2000,
  },
  winZoleAll: {
    label: 'winZoleAll',
    reward: 600,
  },
  take3Aces: {
    label: 'take3Aces',
    reward: 333,
  },
  take0Points: {
    label: 'take0Points',
    reward: 100,
  },
  winZoleTwoAces: {
    label: 'winZoleTwoAces',
    reward: 1000,
  },
  loseLarge60: {
    label: 'loseLarge60',
    reward: 300,
  },
  loseLarge30: {
    label: 'loseLarge30',
    reward: 150,
  },
  loseLarge0: {
    label: 'loseLarge0',
    reward: 150,
  },
  takeTwoAces: {
    label: 'takeTwoAces',
    reward: 1000,
  },
  takeTwoNines: {
    label: 'takeTwoNines',
    reward: 1000,
  },
  loseZoleTwoNines: {
    label: 'loseZoleTwoNines',
    reward: 2000,
  },
  loseTableAllTricks: {
    label: 'loseTableAllTricks',
    reward: 1000,
  },
  dealtNoTrumps: {
    label: 'dealtNoTrumps',
    reward: 2000,
  },
  dealtOnlyQueensJacks: {
    label: 'dealtOnlyQueensJacks',
    reward: 1000,
  },
  dealtOnly789: {
    label: 'dealtOnly789',
    reward: 3000,
  },
};

export const ACHIEVEMENT_DATA = {
  gamesPlayed: {
    borders:    [1,  5,  10,  50,  100, 250, 500,  1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000],
    rewardsOld: [25, 50, 100, 300, 500, 750, 1000, 1500, 2000, 3000,  5000,  10000,  15000,  25000,  50000],
    rewards:    [10, 20, 30,  50,  100, 250, 500,  750,  1000, 2000,  3000,  4000,   5000,   7500,   10000],
    firstName: 'firstGame',
    name: 'gamesPlayed',
    dontShowValue: 'first',

    // last array item is repeated for the remaining achievements: 0 - firstGame, 1 - gamesPlayed, 2 - gamesPlayed ...
    translationKeys: {
      lv: ['firstGame', 'gamesPlayed'],
      en: ['firstGame', 'gamesPlayed'],
      ru: ['firstGame', 'gamesPlayed'],
    },
  },
  gamesWon: {
    borders:    [1,  3,  5,   10,  25,  50,  100,  250,  500,  1000, 5000, 10000, 25000, 100000, 500000],
    rewardsOld: [25, 50, 100, 300, 500, 750, 1000, 1500, 2000, 3000, 5000, 10000, 15000, 25000,  50000],
    rewards:    [10, 20, 30,  50,  100, 250, 500,  750,  1000, 2000, 3000, 4000,  5000,  7500,   10000],
    rev: true,
    firstName: 'firstWin',
    name: 'wins',
    thirdName: '3wins',
    dontShowValue: 'first',

    translationKeys: {
      lv: ['firstWin', 'wins'],
      en: ['firstWin', 'wins'],
      ru: ['firstWin', '3wins', 'wins'],
    },
  },
  highestEarned: {
    borders:    [1000, 2500, 5000, 7500, 10000, 25000, 50000, 75000, 100000, 250000, 500000, 1000000, 3000000, 5000000, 10000000],
    rewardsOld: [100,  250,  500,  750,  1000,  1250,  1500,  1750,  2000,   3000,   5000,   7500,    10000,   15000,   25000],
    rewards:    [25,   50,   75,   100,  250,   500,   750,   1000,  2000,   3000,   4000,   5000,    6000,    7500,    10000],
    rev: true,
    name: 'balance',
    numPlacement: 'after',

    translationKeys: {
      lv: ['balance'],
      en: ['balance'],
      ru: ['balance'],
    },
  },
  earnedInADay: {
    borders:    [750, 1000, 1500, 2000, 3000, 5000, 7500, 10000, 15000, 25000, 50000, 75000, 100000, 250000, 500000],
    rewardsOld: [10,  30,   50,   70,   90,   300,  500,  700,   900,   1100,  2000,  3000,  4000,   5000,   6000],
    rewards:    [10,  20,   30,   40,   50,   75,   100,  200,   300,   400,   500,   750,   1000,   2000,   3000],
    name: 'earnedInADay',
    numPlacement: 'after',

    translationKeys: {
      lv: ['earnedInADay'],
      en: ['earnedInADay'],
      ru: ['earnedInADay'],
    },
  },
  maxSuccessionWins: {
    borders:    [3,  5,  7,  10,  15,  20,  25,  30,   40,   50,   60,   70,   80,   90,   100],
    rewardsOld: [50, 60, 70, 80,  90,  200, 300, 400,  500,  600,  1000, 1500, 2000, 2500, 3000],
    rewards:    [25, 50, 75, 100, 300, 500, 750, 1000, 1250, 1500, 1750, 2000, 2500, 3000, 5000],
    name: 'maxSuccessionWins',
    firstName: 'maxSuccessionWins',

    translationKeys: {
      lv: ['maxSuccessionWins'],
      en: ['maxSuccessionWins'],
      ru: ['maxSuccessionWins'],
    },
  },
  maxSuccessionLosses: {
    borders:    [3,  5,  7,  10,  15,  20,  25,  30,   40,   50,   60,   70,   80,   90,   100],
    rewardsOld: [50, 60, 70, 80,  90,  200, 300, 400,  500,  600,  1000, 1500, 2000, 2500, 3000],
    rewards:    [25, 50, 75, 100, 300, 500, 750, 1000, 1250, 1500, 1750, 2000, 2500, 3000, 5000],
    name: 'maxSuccessionLosses',
    thirdName: '3maxSuccessionLosses',

    translationKeys: {
      lv: ['maxSuccessionLosses'],
      en: ['maxSuccessionLosses'],
      ru: ['maxSuccessionLosses', '3maxSuccessionLosses'],
    },
  },
  zolePlayed: {
    borders:    [1,  3,  5,  10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000],
    rewardsOld: [10, 20, 30, 40, 50,  100, 150, 200,  250,  300,   500,   600,    700,    800,    1000],
    rewards:    [10, 20, 30, 40, 50,  75,  100, 200,  300,  400,   500,   600,    700,    800,    1000],
    firstName: 'zolePlayed',
    name: 'zolesPlayed',
    thirdName: '3zolesPlayed',

    translationKeys: {
      lv: ['zolePlayed', 'zolesPlayed'],
      en: ['zolePlayed', 'zolesPlayed'],
      ru: ['zolePlayed', '3zolesPlayed', 'zolesPlayed'],
    },
  },
  zoleWon: {
    borders:    [1,  5,  10, 25,  50,  75,  100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000],
    rewardsOld: [50, 60, 70, 80,  90,  200, 300, 400, 500, 600,  1000, 1500, 2000,  2500,  3000],
    rewards:    [25, 50, 75, 100, 200, 300, 400, 500, 600, 700,  800,  900,  1000,  2000,  3000],
    firstName: 'zoleWon',
    name: 'zolesWon',

    translationKeys: {
      lv: ['zoleWon', 'zolesWon'],
      en: ['zoleWon', 'zolesWon'],
      ru: ['zoleWon', 'zolesWon'],
    },
  },
  fastGamesPlayed: {
    borders:    [1,  3,  5,  10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000],
    rewardsOld: [10, 20, 30, 40, 50,  100, 150, 200,  250,  300,   500,   600,    700,    800,    1000],
    rewards:    [10, 20, 30, 50, 100, 250, 500, 750,  1500, 2000,  3000,  4000,   5000,   7500,   10000],
    firstName: 'fastGamePlayed',
    name: 'fastGamesPlayed',
    thirdName: '3fastGamesPlayed',
    rev: true,
    dontShowValue: 'first',

    translationKeys: {
      lv: ['fastGamePlayed', 'fastGamesPlayed'],
      en: ['fastGamePlayed', 'fastGamesPlayed'],
      ru: ['fastGamePlayed', '3fastGamesPlayed', 'fastGamesPlayed'],
    },
  },
  lightningGamesPlayed: {
    borders:    [1,  3,  5,  10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000],
    rewardsOld: [10, 20, 30, 40, 50,  100, 150, 200,  250,  300,   500,   600,    700,    800,    1000],
    rewards:    [10, 20, 30, 50, 100, 250, 500, 750,  1500, 2000,  3000,  4000,   5000,   7500,   10000],
    firstName: 'lightningGamePlayed',
    name: 'lightningGamesPlayed',
    thirdName: '3lightningGamesPlayed',
    dontShowValue: 'first',

    translationKeys: {
      lv: ['lightningGamePlayed', 'lightningGamesPlayed'],
      en: ['lightningGamePlayed', 'lightningGamesPlayed'],
      ru: ['lightningGamePlayed', '3lightningGamesPlayed', 'lightningGamesPlayed'],
    },
  },
  unlimitedGamesPlayed: {
    borders:    [1,  3,  5,  10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000],
    rewardsOld: [10, 20, 30, 40, 50,  100, 150, 200,  250,  300,   500,   600,    700,    800,    1000],
    rewards:    [10, 20, 30, 50, 100, 250, 500, 750,  1500, 2000,  3000,  4000,   5000,   7500,   10000],
    firstName: 'unlimitedGamePlayed',
    name: 'unlimitedGamesPlayed',
    thirdName: '3unlimitedGamesPlayed',
    dontShowValue: 'first',

    translationKeys: {
      lv: ['unlimitedGamePlayed', 'unlimitedGamesPlayed'],
      en: ['unlimitedGamePlayed', 'unlimitedGamesPlayed'],
      ru: ['unlimitedGamePlayed', '3unlimitedGamesPlayed', 'unlimitedGamesPlayed'],
    },
  },
  mazaZolePlayed: {
    // borders: [1, 3, 5, 10, 100, 250, 500, 1000, 10000, 25000, 50000],
    borders:    [1,  3,  5,  10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000],
    rewardsOld: [10, 20, 30, 40, 50,  100, 150, 200,  250,  300,   500,   600,    700,    800,    1000],
    rewards:    [10, 20, 30, 50, 100, 250, 500, 750,  1000, 2000,  3000,  4000,   5000,   7500,   10000],
    firstName: 'mazaZolePlayed',
    name: 'mazasZolesPlayed',
    thirdName: '3mazasZolesPlayed',

    translationKeys: {
      lv: ['mazaZolePlayed', 'mazasZolesPlayed'],
      en: ['mazaZolePlayed', 'mazasZolesPlayed'],
      ru: ['mazaZolePlayed', '3mazasZolesPlayed', 'mazasZolesPlayed'],
    },
  },
  mazaZoleWon: {
    borders:    [1,  5,  10, 25,  50,  75,  100, 250,  500,  1000, 2500, 5000, 10000, 25000, 50000],
    rewardsOld: [50, 60, 70, 80,  90,  200, 300, 400,  500,  600,  1000, 1500, 2000,  2500,  3000],
    rewards:    [25, 50, 75, 100, 300, 500, 750, 1000, 1250, 1500, 1750, 2000, 2500,  3000,  5000],
    firstName: 'mazaZoleWon',
    name: 'mazasZolesWon',

    translationKeys: {
      lv: ['mazaZoleWon', 'mazasZolesWon'],
      en: ['mazaZoleWon', 'mazasZolesWon'],
      ru: ['mazaZoleWon', 'mazasZolesWon'],
    },
  },
  bonusSpins: {
    borders:    [3,  5,  7,  10,  15,  20,  25,  30,   40,   50,   75,   100,  250,  500,  1000],
    rewardsOld: [20, 30, 40, 50,  60,  100, 200, 300,  400,  500,  700,  900,  1100, 1300, 1500],
    rewards:    [25, 50, 75, 100, 300, 500, 750, 1000, 1250, 1500, 1750, 2000, 2500, 3000, 5000],
    name: 'bonusSpins',
    thirdName: '3bonusSpins',

    translationKeys: {
      lv: ['bonusSpins'],
      en: ['bonusSpins'],
      ru: ['bonusSpins', '3bonusSpins'],
    },
  },
  joinedTournaments: {
    borders: [3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 75, 100, 250, 500, 1000],
    rewards: [10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800, 1000],
    name: 'joinedTournaments',
    thirdName: '3joinedTournaments',

    translationKeys: {
      lv: ['joinedTournaments'],
      en: ['joinedTournaments'],
      ru: ['joinedTournaments', '3joinedTournaments'],
    },
  },
  giftsReceived: {
    borders:    [1,  3,  5 , 10, 25, 50,  75,  100, 250, 500, 750, 1000, 2000, 3000, 5000],
    rewardsOld: [10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600,  700,  800,  1000],
    rewards:    [10, 20, 30, 40, 50, 75,  100, 200, 300, 400, 500, 600,  700,  800,  1000],
    firstName: 'giftReceived',
    name: 'giftsReceived',
    thirdName: '3giftsReceived',

    translationKeys: {
      lv: ['giftReceived', 'giftsReceived'],
      en: ['giftReceived', 'giftsReceived'],
      ru: ['giftReceived', '3giftsReceived', 'giftsReceived'],
    },
  },
  reachedTournamentTop10: {
    borders: [1, 3, 5, 7, 10, 15, 20, 25, 30, 35, 50, 75, 100, 250, 500],
    rewards: [50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500, 3000],
    firstName: 'reachedFirstTournamentTop10',
    name: 'reachedTournamentTop10',
    thirdName: '3reachedTournamentTop10',
    dontShowValue: 'first',

    translationKeys: {
      lv: ['reachedFirstTournamentTop10', 'reachedTournamentTop10'],
      en: ['reachedFirstTournamentTop10', 'reachedTournamentTop10'],
      ru: ['reachedFirstTournamentTop10', '3reachedTournamentTop10', 'reachedTournamentTop10'],
    },
  },
  reachedTop100: {
    borders:    [1,  3,  5,  7,   14,  30,  60,  90,  120, 150, 180,  270,  365,  730,  1095],
    rewardsOld: [50, 60, 70, 80,  90,  200, 300, 400, 500, 600, 1000, 1500, 2000, 2500, 3000],
    rewards:    [25, 50, 75, 100, 200, 300, 400, 500, 600, 700, 800,  900,  1000, 2000, 3000],
    firstName: 'reachedFirstTop100',
    name: 'reachedTop100',
    thirdName: '3reachedTop100',
    dontShowValue: 'all',

    translationKeys: {
      lv: ['reachedFirstTop100', 'reachedTop100'],
      en: ['reachedFirstTop100', 'reachedTop100'],
      ru: ['reachedFirstTop100', '3reachedTop100', 'reachedTop100'],
    },
  },
  giftsSent: {
    borders:    [1,  3,  5,  10,  25,  50,  75,  100,  250,  500,  750,  1000, 2000, 3000, 5000],
    rewardsOld: [10, 20, 30, 40,  50,  100, 150, 200,  250,  300,  500,  600,  700,  800,  1000],
    rewards:    [25, 50, 75, 100, 300, 500, 750, 1000, 1250, 1500, 1750, 2000, 2500, 3000, 5000],
    firstName: 'giftSent',
    name: 'giftsSent',
    thirdName: '3giftsSent',

    translationKeys: {
      lv: ['giftSent', 'giftsSent'],
      en: ['giftSent', 'giftsSent'],
      ru: ['giftSent', '3giftsSent', 'giftsSent'],
    },
  },
  maxParties: {
    borders:    [3,  5,  7,  10, 15, 20,  25,  50,  75,  100,  150,  200,  250,  500,  1000],
    rewardsOld: [20, 30, 40, 50, 60, 100, 200, 300, 400, 500,  700,  900,  1100, 1300, 1500],
    rewards:    [10, 20, 30, 40, 50, 75,  100, 250, 500, 1000, 2000, 3000, 5000, 7500, 10000],
    name: 'maxParties',
    thirdName: '3maxParties',
    translationKeys: {
      lv: ['maxParties'],
      en: ['maxParties'],
      ru: ['3maxParties', 'maxParties'],
    },
  },
  supportMessagesSent: { 
    borders:    [1,  2,  3,  5,   7,   10,  15,  20,  25,  50,  75,   100,  250,  500,  1000],
    rewardsOld: [50, 60, 70, 80,  90,  200, 300, 400, 500, 600, 1000, 1500, 2000, 2500, 3000],
    rewards:    [25, 50, 75, 100, 200, 300, 400, 500, 600, 700, 800,  900,  1000, 2000, 3000],
    firstName: 'supportMessageSent',
    name: 'supportMessagesSent',
    thirdName: '3supportMessagesSent',

    translationKeys: {
      lv: ['supportMessageSent', 'supportMessagesSent'],
      en: ['supportMessageSent', 'supportMessagesSent'],
      ru: ['supportMessageSent', '3supportMessagesSent', '3supportMessagesSent', 'supportMessagesSent'],
    },
  },
  storePurchase: {
    borders:    [1,  2,  3,  5,   10,  15,  20,  25,  30,  35,  50,   75,   100,  250,  500],
    rewardsOld: [50, 60, 70, 80,  90,  200, 300, 400, 500, 600, 1000, 1500, 2000, 2500, 3000],
    rewards:    [25, 50, 75, 100, 200, 300, 400, 500, 600, 700, 800,  900,  1000, 2000, 3000],
    firstName: 'storePurchase',
    name: 'storePurchases',
    thirdName: '3storePurchases',

    translationKeys: {
      lv: ['storePurchase', 'storePurchases'],
      en: ['storePurchase', 'storePurchases'],
      ru: ['storePurchase', '3storePurchases', '3storePurchases', 'storePurchases'],
    },
  },
  galdinsPlayed: {
    borders:    [1,  3,  5,  10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000],
    rewardsOld: [10, 20, 30, 40, 50,  100, 150, 200,  250,  300,   500,   600,    700,    800,    1000],
    rewards:    [10, 20, 30, 40, 50,  75,  100, 200,  300,  400,   500,   600,    700,    800,    1000],
    firstName: 'galdinsPlayed',
    name: 'galdiniPlayed',
    thirdName: '3galdiniPlayed',

    translationKeys: {
      lv: ['galdinsPlayed', 'galdiniPlayed'],
      en: ['galdinsPlayed', 'galdiniPlayed'],
      ru: ['galdinsPlayed', '3galdiniPlayed', 'galdiniPlayed'],
    },
  },
  galdinsWon: {
    borders:    [1,  5,  10, 25,  50,  75,  100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000],
    rewardsOld: [50, 60, 70, 80,  90,  200, 300, 400, 500, 600,  1000, 1500, 2000,  2500,  3000],
    rewards:    [25, 50, 75, 100, 200, 300, 400, 500, 600, 700,  800,  900,  1000,  2000,  3000],
    firstName: 'galdinsWon',
    name: 'galdiniWon',

    translationKeys: {
      lv: ['galdinsWon', 'galdiniWon'],
      en: ['galdinsWon', 'galdiniWon'],
      ru: ['galdinsWon', 'galdiniWon'],
    },
  },
  galdinsLose: {
    borders:    [1,  5,  10, 25,  50,  75,  100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000],
    rewardsOld: [50, 60, 70, 80,  90,  200, 300, 400, 500, 600,  1000, 1500, 2000,  2500,  3000],
    rewards:    [25, 50, 75, 100, 200, 300, 400, 500, 600, 700,  800,  900,  1000,  2000,  3000],
    firstName: 'galdinsLose',
    name: 'galdiniLose',

    translationKeys: {
      lv: ['galdinsLose', 'galdiniLose'],
      en: ['galdinsLose', 'galdiniLose'],
      ru: ['galdinsLose', 'galdiniLose'],
    },
  },
  maxDailyLogin: {
    borders:    [1,  3,   5,   7,   14,  30,   60,   90,   120,  150,  180,  270,  365,   730,   1095],
    rewardsOld: [50, 100, 250, 500, 750, 1000, 1500, 2000, 2500, 3000, 5000, 7500, 10000, 15000, 25000],
    rewards:    [25, 50,  75, 100, 250, 500,  750,  1000, 2000, 3000, 4000, 5000, 6000,  10000, 15000],
    firstName: 'maxDailyLogin',
    name: 'maxDailyLogins',
    thirdName: '3maxDailyLogins',
    dontShowValue: 'first',

    translationKeys: {
      lv: ['maxDailyLogin', 'maxDailyLogins'],
      en: ['maxDailyLogin', 'maxDailyLogins'],
      ru: ['maxDailyLogin', '3maxDailyLogins', 'maxDailyLogins'],
    },
  },
};

export const ACHIEVEMENT_LEVEL = [
  { id: 1, label: 'Level 1', color: '#ff3203' },
  { id: 2, label: 'Level 2', color: '#17a2b8' },
  { id: 3, label: 'Level 3', color: '#006e04' },
  { id: 4, label: 'Level 4', color: '#blue' },
  { id: 5, label: 'Level 5', color: '#ff73ee' },
  { id: 6, label: 'Level 6', color: '#3373ee' },
  { id: 7, label: 'Level 7', color: '#ff3203' },
  { id: 8, label: 'Level 8', color: '#17a2b8' },
  { id: 9, label: 'Level 9', color: '#006e04' },
  { id: 10, label: 'Level 10', color: '#ff73ee' },
  { id: 11, label: 'Level 11', color: '#343a40' },
  { id: 12, label: 'Level 12', color: '#6f42c1' },
  { id: 13, label: 'Level 13', color: '#ff73ee' },
  { id: 14, label: 'Level 14', color: '#006e04' },
  { id: 15, label: 'Level 15', color: '#17a2b8' },
];

export const UNIQUE_ACHIEVEMNT_LEVEL = [
  { id: 'winLarge61', label: 'Win as Large - 61 points', color: '#ff3203' },
  { id: 'winLarge91', label: 'Win as Large - 91 points', color: '#17a2b8' },
  { id: 'winLarge120', label: 'Win as Large - 120 points', color: '#006e04' },
  { id: 'winSmall60', label: 'Win as Small - 60 points', color: '#blue' },
  { id: 'winSmall90', label: 'Win as Small - 90 points', color: '#ff3203' },
  { id: 'winSmall120', label: 'Win as Small - 120 points', color: '#17a2b8' },
  { id: 'winZoleAll', label: 'Win Zole as Large - without giving tricks', color: '#ff73ee' },
  { id: 'take3Aces', label: 'Picked up trick with 3 aces', color: '#3373ee' },
  { id: 'take0Points', label: 'Picked up trick with 0 points', color: '#ff3203' },
  { id: 'winZoleTwoAces', label: 'Win in Zole - 2 aces on the table', color: '#17a2b8' },
  { id: 'loseLarge60', label: 'Loss as Large - 60 points', color: '#006e04' },
  { id: 'loseLarge30', label: 'Loss as Large - 30 points', color: '#ff73ee' },
  { id: 'loseLarge0', label: 'Loss as Large - 0 points', color: '#343a40' },
  { id: 'takeTwoAces', label: 'Took table with 2 aces', color: '#6f42c1' },
  { id: 'takeTwoNines', label: 'Took table with 2 nines', color: '#ff73ee' },
  { id: 'loseZoleTwoNines', label: 'Lost Zole with 2 nines on the table', color: '#006e04' },
  { id: 'loseTableAllTricks', label: 'Lost table with all taken tricks', color: '#17a2b8' },
  { id: 'dealtNoTrumps', label: 'Have no trumps', color: '#006e04' },
  { id: 'dealtOnlyQueensJacks', label: 'Have 4 Queens and 4 Jacks', color: '#blue' },
  { id: 'dealtOnly789', label: 'Have only 7, 8 or 9', color: '#ff73ee' },
];

export const ACHIEVEMENT_COLOR = [
  '#ff3203',
  '#17a2b8',
  '#006e04',
  '#blue',
  '#ff73ee',
  '#3373ee',
  '#ff3203',
  '#17a2b8',
  '#006e04',
  '#ff73ee',
  '#343a40',
  '#6f42c1',
  '#ff73ee',
  '#006e04',
  '#17a2b8',
];

export const TIMEOUT_TYPE = {
  SIX_SECONDS: 6000,
};

export const GAMESPLAYED = 'gamesPlayed';
export const GAMESWON = 'gamesWon';

/* Admin Panel Navigation */
export const ADMIN_PANEL_NAVIGATION = [
  {
    key: 'users',
    label: 'users',
    title: 'Players',
    sublinks: [
      {
        id: '1',
        label: 'users',
        title: 'Search Players',
      },
      {
        id: '2',
        label: 'nonVerifiedAccounts',
        title: 'Non-verified Accounts',
      },
      {
        id: '3',
        label: 'blockedUser',
        title: 'Blocked Players',
      },
      {
        id: '4',
        label: 'suspiciousPlayers',
        title: 'Suspicious Players',
      },
      {
        id: '25',
        label: 'multiAccounts',
        title: 'Multi Accounts',
      },
      {
        id: '27',
        label: 'reportPlayers',
        title: 'Report Players',
      },
      {
        id: '28',
        label: 'reportMessages',
        title: 'Report Messages',
      },
      {
        id: '29',
        label: 'playerRoomMessages',
        title: 'Messages',
      },
      {
        id: '35',
        label: 'possibleBotPlayers',
        title: 'Possible Bot Players',
      },
    ],
  },
  {
    key: 'shop',
    label: 'shop',
    title: 'Shop',
    sublinks: [
      {
        id: '5',
        label: 'transactions',
        title: 'Transactions',
      },
      {
        id: '6',
        label: 'gitcode',
        title: 'Gift Codes',
      },
      {
        id: '7',
        label: 'shopDiscounts',
        title: 'Discounts',
      },
      {
        id: '8',
        label: 'events',
        title: 'Events',
      },
    ],
  },
  {
    id: '9',
    label: 'tournaments',
    title: 'Tournaments',
  },
  {
    key: 'admins',
    label: 'admins',
    title: 'Admins',
    sublinks: [
      {
        id: '10',
        label: 'logs',
        title: 'Rooms',
      },
      {
        id: '11',
        label: 'adminAction',
        title: 'Admin Actions',
      },
      {
        id: '12',
        label: 'admins',
        title: 'Admins',
      },
    ],
  },
  // {
  //   key: 'missedTurns',
  //   label: 'missedTurns',
  //   title: 'Missed Turns',
  //   sublinks: [
  //     {
  //       id: '13',
  //       label: 'missedRooms',
  //       title: 'Missed Rooms',
  //     },
  //     {
  //       id: '14',
  //       label: 'missedTurns',
  //       title: 'Missed Turns',
  //     },
  //   ],
  // },
  {
    id: '15',
    label: 'supportChats',
    title: 'Conversations',
  },
  {
    id: '16',
    label: 'statistics',
    title: 'Statistics',
  },
  {
    id: '17',
    label: 'bots',
    title: 'Bots',
  },
  {
    key: 'feedback',
    label: 'feedback',
    title: 'Feedback',
    sublinks: [
      {
        id: '18',
        label: 'feedback',
        title: 'Feedback',
      },
      {
        id: '31',
        label: 'createSurvey',
        title: 'Create Survey',
      },
      {
        id: '32',
        label: 'surveyResults',
        title: 'Survey Results',
      },
    ],
  },
  {
    key: 'settings',
    label: 'settings',
    title: 'Settings',
    sublinks: [
      {
        id: '19',
        label: 'settings',
        title: 'Settings',
      },
      {
        id: '36',
        label: 'profanities',
        title: 'Profanities',
      },
      {
        id: '20',
        label: 'emailEditor',
        title: 'Email Editor',
      },
      {
        id: '30',
        label: 'design',
        title: 'Design',
      },
      {
        id: '34',
        label: 'textKeys',
        title: 'Text keys',
      },
      {
        id: '37',
        label: 'helpEditor',
        title: 'Help Editor',
      },
    ],
  },
  {
    key: 'landingPage',
    label: 'landingPage',
    title: 'Landing Page',
    sublinks: [
      {
        id: '21',
        label: 'versionHistory',
        title: 'Version History',
      },
      {
        id: '22',
        label: 'blogs',
        title: 'Blogs',
      },
    ],
  },
  {
    id: '23',
    key: 'LogRocket',
    label: 'logRocket',
    title: 'LogRocket',
  },
  // {
  //   key: 'Firebase',
  //   label: 'firebase',
  //   title: 'Firebase',
  //   sublinks: [
  //     {
  //       id: '23',
  //       label: 'log',
  //       title: "Log"
  //     }
  //   ]
  // },
  {
    id: '24',
    label: 'notifications',
    title: 'Notifications',
  },
  {
    id: '26',
    label: 'hints',
    title: 'Hints',
  },
  {
    id: '33',
    label: 'campaigns',
    title: 'Campaigns',
  },
];

export const ADMIN_NAVIGATION_KEY = {
  users: '1',
  nonVerifiedAccounts: '2',
  blockedUser: '3',
  suspiciousPlayers: '4',
  multiAccounts: '25',
  transactions: '5',
  gitcode: '6',
  shopDiscounts: '7',
  events: '8',
  tournaments: '9',
  logs: '10',
  adminAction: '11',
  admins: '12',
  missedRooms: '13',
  missedTurns: '14',
  supportChats: '15',
  statistics: '16',
  bots: '17',
  feedback: '18',
  settings: '19',
  emailEditor: '20',
  versionHistory: '21',
  blogs: '22',
  logRocket: '23',
  // firebase: '23',
  notifications: '24',
  hints: '26',
  reportPlayers: '27',
  reportMessages: '28',
  playerRoomMessages: '29',
  design: '30',
  createSurvey: '31',
  surveyResults: '32',
  campaigns: '33',
  textKeys: '34',
  possibleBotPlayers: '35',
  profanities: '36',
  helpEditor: '37',
};

export const ADMIN_MAIN_MENU = {
  users: ['1', '2', '3', '4', '27', '28'],
  shop: ['5', '6', '7', '8'],
  admins: ['10', '11', '12'],
  settings: ['19', '20', '30', '37'],
  landingPage: ['21', '22'],
  logRocket: ['23'],
  // firebase: ['23'],
  notifications: ['24'],
  hints: ['26'],
  messages: ['29'],
  campaigns: ['33'],
};

export const HIGHESTEARNED = 'highestEarned';
export const BALANCE = 'balance';

export const USER_SYSTEM_SETTINGS = {
  leaveRoom: 'leaveRoom',
  lastRound: 'lastRound',
  quitRound: 'quitRound',
  ignorePlayer: 'ignorePlayer',
  quitAccount: 'quitAccount',
  purchaseGift: 'purchaseGift',
  removeGift: 'removeGift',
  newDesign: 'newDesign',
  dontShowAnyNotification: 'dontShowAnyNotification',
  showAfterRound: 'showAfterRound',
  showAtRoomEnd: 'showAtRoomEnd',
  doubleClickPlay: 'doubleClickPlay',
  soundOn: 'soundOn',
  autoPlay: 'autoPlay',
  autoMergeRooms: 'autoMergeRooms',
  showHint: 'showHint',
  showIgnoredRooms: 'showIgnoredRooms'
};

export const USER_NEW_SYSTEM_SETTINGS = {
  leaveRoom: 'leaveRoom',
  lastRound: 'lastRound',
  quitRound: 'quitRound',
  ignorePlayer: 'ignorePlayer',
  quitAccount: 'quitAccount',
  purchaseGift: 'purchaseGift',
  removeGift: 'removeGift',
  newDesign: 'newDesign',
  dontShowAnyNotification: 'dontShowAnyNotification',
  showAfterRound: 'showAfterRound',
  showAtRoomEnd: 'showAtRoomEnd',
  doubleClickPlay: 'doubleClickPlay',
};

export const LOGOUTTOOLTIPTITLE = 'logOutTooltipTitle';
export const LOGOUTTOOLTIPDESCRIPTION = 'logOutTooltipDescription';
export const FULLSCREENTOOLTIPTITLE = 'fullScreenTooltipTitle';
export const FULLSCREENTOOLTIPDESCRIPTION = 'fullScreenTooltipDescription';
export const EXITFULLSCREENTOOLTIPDESCRIPTION = 'exitFullScreenTooltipDescription';
export const SETTINGSTOOLTIPTITLE = 'settingsTooltipTitle';
export const SETTINGSTOOLTIPDESCRIPTION = 'settingsTooltipDescription';
export const FONTRESIZETOOLTIPTITLE = 'fontResizeTooltipTitle';
export const FONTRESIZETOOLTIPDESCRIPTION = 'fontResizeTooltipDescription';

export const LOGOUTTOOLTIPTARGET = 'menu_logout_button';
export const FULLSCREENTOOLTIPTARGET = 'menu_full_screen_button';
export const SETTINGSTOOLTIPTARGET = 'menu_settings_button';
export const FONTRESIZETOOLTIPTARGET = 'menu_font_resize_button';

export const DATA_OPEN = {
  open: 'open',
  close: 'close',
};

export const TOURNAMENT_OPTION_IMAGES = {
  sponsor: 'sponsor',
  prize: 'prize',
  banner: 'banner',
};

export const NOTIFICATION_SHOW_OPTIONS = {
  oneTime: 'one-time',
  everyDay: 'every-day',
  everyWeek: 'every-week',
};

export const BIG_SIZE_ACH = [
  'loseLarge0',
  'loseLarge30',
  'loseLarge60',
  'take0Points',
  'take3Aces',
  'winLarge61',
  'winLarge91',
  'winLarge120',
  'winSmall60',
  'winSmall90',
  'winSmall120',
  'winZoleAll',
  'winZoleTwoAces',
  'dealtOnlyQueensJacks',
  'dealtNoTrumps',
  'dealtOnly789',
];

export const SMALL_DAY_MONTHS = ['04', '06', '09', '11', '02'];

export const WEEK_DAYS = [
  { id: '1', value: 'Everyday' },
  { id: '2', value: 'Sunday' },
  { id: '3', value: 'Monday' },
  { id: '4', value: 'Tuesday' },
  { id: '5', value: 'Wednesday' },
  { id: '6', value: 'Thursday' },
  { id: '7', value: 'Friday' },
  { id: '8', value: 'Saturday' },
];

export const SHOW_HINT_EVERY_DAY = '1';

export const OLD_DESIGN = 'old';

export const NEW_DESIGN = 'new';

export const SPEED_TYPE = {
  unlimited: 'Unlimited',
  parasta: 'Slow (25s)',
  atra: 'Fast (15s)',
  lightning: 'Lightning (5s)',
};

export const HOLIDAY_TYPES = [
  { id: 'birthday', label: 'Birthday', type: 'birthday' },
  { id: 'nameDay', label: 'Name Day', type: 'nameDay' },
  {
    id: 'newYear', label: 'New Year (Jan 1st)', value: '01-01', type: 'holiday',
  },
  {
    id: 'easter', label: 'Easter', value: 'easter', type: 'holiday',
  },
  {
    id: '4May', label: 'Restoration of Independence (May 4th)', value: '05-04', type: 'holiday',
  },
  {
    id: 'ligo', label: 'Ligo (Jun 23rd)', value: '06-23', type: 'holiday',
  },
  {
    id: 'jani', label: 'Jani (Jun 24th)', value: '06-24', type: 'holiday',
  },
  {
    id: '1September', label: 'First day of School (Sep 1st)', value: '09-21', type: 'holiday',
  },
  {
    id: 'lacplesa', label: 'Lacplesis Day  (Nov 11th)', value: '11-11', type: 'holiday',
  },
  {
    id: '18November', label: 'Independence Day  (Nov 18th)', value: '11-18', type: 'holiday',
  },
  {
    id: 'christmas', label: 'Christmas Eve (Dec 24th)', value: '11-24', type: 'holiday',
  },
  {
    id: 'christmas2', label: 'Christmas Day (Dec 25th)', value: '11-25', type: 'holiday',
  },
  {
    id: 'oldYear', label: 'New Year Eve (Dec 31st)', value: '12-31', type: 'holiday',
  },
  {
    id: 'changeLog', label: 'Changelog Modal', value: 'changeLog', type: 'triggerModal',
  },
  {
    id: 'newHelp', label: 'Help Modal', value: 'newHelp', type: 'triggerModal',
  },
];

export const HOLIDAY_TYPES_INGAME = [
  {
    id: 'ligo', label: 'Ligo (Jun 23rd)', value: '06-23', type: 'holiday',
  },
  {
    id: 'jani', label: 'Jani (Jun 24th)', value: '06-24', type: 'holiday',
  },
];

export const BIRTHDAY_HOLIDAY = 'birthday';
export const NAMEDAY_HOLIDAY = 'nameDay';
export const PUB_HOLIDAYS = ['newYear', 'easter', '4May', 'ligo', 'jani', '1September', 'lacplesa', '18November', 'christmas', 'christmas2', 'oldYear'];

export const PLATFORMS = [
  { id: 'mWeb', label: 'mWeb' },
  { id: 'dWeb', label: 'dWeb' },
  { id: 'draugiem', label: 'draugiem.lv' },
  { id: 'facebook', label: 'Facebook' },
  { id: 'inbox', label: 'Inbox' },
  { id: 'iOS', label: 'iOS' },
  { id: 'Android', label: 'Android' },
];

export const DWEB_PLATFORM = 'dWeb';
export const WEB_PLATFORM = 'web';
export const MWEB_PLATFORM = 'mWeb';
export const DRAUGIEM_PLATFORM = 'draugiem';
export const FACEBOOK_PLATFORM = 'facebook';
export const INBOX_PLATFORM = 'inbox';
export const IOS_PLATFORM = 'iOS';
export const ANDROID_PLATFORM = 'android';
export const MOBILE_PLATFORM = 'mobile';
export const TABLET_PLATFORM = 'tablet';

export const SURVEY_TYPE = [
  { id: 'type1', value: 'freeType', label: 'Free Form' },
  { id: 'type2', value: 'oneSelect', label: 'One Choice' },
  { id: 'type3', value: 'multiSelect', label: 'Multi Choice' },
];

export const BLOG_RICH_TEXT = {
  lv: 'descriptionLV',
  en: 'descriptionEN',
  ru: 'descriptionRU',
};

export const DELETE = 'DELETE';
export const EDIT = 'EDIT';
export const CREATE = 'CREATE';
export const FEEDBACKVIEW = 'FEEDBACKVIEW';
