import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Media from 'reactstrap/lib/Media';
import classNames from 'classnames';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

import * as constants from '../../../../../../constants/constants';
import coinImg from '../../../../../../images/redesign/common/coin.svg';
import ratingImg from '../../../../../../images/redesign/common/rating.svg';

const PlayerBalance = React.memo(({
  bal, rating, onHover, onHoverLeave, popoverOpen, screenMode,
}) => {
  const normalBalance = bal > constants.PLAYER_ZOLE_RANGE.min;
  const lowBalance = bal >= constants.PLAYER_ZOLE_RANGE.maxmin && bal <= constants.PLAYER_ZOLE_RANGE.min;
  const negativeBalance = bal < constants.PLAYER_ZOLE_RANGE.maxmin;

  const STYLE_TOP_TABLE = classNames({
    'player-balance-text': normalBalance,
    'player-balance-text-orange': lowBalance,
    'player-balance-text-red': negativeBalance,
  });

  const STYLE_COIN_BLINK = classNames({
    'player-balance-coin': normalBalance,
    'player-balance-coin blink': lowBalance || negativeBalance,
  });

  const { t } = useTranslation('playerInfo');
  return (
    <div className="player-balance">
      <div onMouseEnter={onHover} onMouseLeave={onHoverLeave} id="player-balance-popover">
        <Media src={coinImg} className={STYLE_COIN_BLINK}  />
      </div>
      <Popover
        container={'div > div'}
        placement="left"
        isOpen={popoverOpen}
        target="player-balance-popover"
        onMouseEnter={onHover}
        onMouseLeave={onHoverLeave}
        popperClassName={`new-design-popover new-design-popover-${screenMode}`}
      >
        <PopoverBody
          onMouseLeave={onHoverLeave}
          className="new-design-popover-body"
        >
          <div className="new-design-popover-body-header">
            {negativeBalance ? t('negativeBalance') : t('lowBalance')}
          </div>
          <div className="new-design-popover-body-main">
            <div>
              {negativeBalance ? t('negativeBalanceContent') : t('lowBalanceContent')}
            </div>
          </div>
        </PopoverBody>
      </Popover>
      <div className={STYLE_TOP_TABLE} onMouseEnter={onHover} onMouseLeave={onHoverLeave}>
        {bal}
      </div>
      <Media src={ratingImg} className="player-balance-coin" onMouseEnter={onHover} onMouseLeave={onHoverLeave} />
      <div className="player-balance-text">
        {rating || 1600}
      </div>
    </div>
  );
});

PlayerBalance.propTypes = {
  bal: PropTypes.number,
  rating: PropTypes.number,
  onHover: PropTypes.func.isRequired,
  onHoverLeave: PropTypes.func.isRequired,
  popoverOpen: PropTypes.bool,
};

PlayerBalance.defaultProps = {
  bal: null,
  rating: null,
  popoverOpen: false,
};

export default PlayerBalance;
