// import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar-edit';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Button,
  Media,
  ModalFooter,
} from 'reactstrap';

import * as constants from '../../../constants/constants';

import closeImg from '../../../images/icons/close.png';
import defaultImage from '../../../images/Game/defaultImage.webp';

const ProfileAvatarEdit = React.memo(({
  fontMode, ableToSave, onClose, t, showCrop, toggleShowCrop, alertStatus, alertMessage, alertType, onBeforeFileLoad, onCrop, photo, preview, uploadAvatarFile, // handleEditPhoto
}) => {
  const [previewImg, setPreviewImg] = useState(preview);


  useEffect(() => {
    console.log(previewImg, 'use effect preview');
    setPreviewImg(previewImg);
  }, [previewImg]);

  return (
    <Row>
      <Col lg={{ size: 8, offset: 3 }}>
        <Modal container={'div > div'} isOpen={showCrop} toggle={() => toggleShowCrop()} className={`update-profile notification old-modal root-font-${fontMode}`}>
          <ModalHeader
            className="notification-header"
            close={<Media src={closeImg} className="notification-header-close" alt="X" onClick={() => toggleShowCrop()} />}
          >
            {t('home.changeProfileImg')}

          </ModalHeader>
          <ModalBody className="update-profile-body-avatar">
            <Row>
              <Col sm="12">
                <Alert color={alertType} isOpen={alertStatus}>
                  {/* <h5 className="alert-heading alert-heading-message">{alertType === constants.FROUNT_SIDE_STATUS.success ? t('support.success') : t('support.appearError')}</h5> */}
                  <p className="alert-heading alert-heading-body">{ alertMessage }</p>
                </Alert>
              </Col>
              <Col sm="7">
                <div className="active-image-style cursor-pointer">
                  <Avatar
                    width={281}
                    height={344}
                    imageWidth={281}
                    imageHeight={344}
                    onCrop={e => onCrop(e)}
                    onClose={e => onClose(e)}
                    onBeforeFileLoad={e => onBeforeFileLoad(e)}
                    label={t('home.uploadPhoto')}
                    labelStyle={{
                      width: '100%', fontSize: 11, textTransform: 'upperCase', /* fontVariant: 'all-petite-caps', */ cursor: 'pointer',
                    }}
                  />
                </div>
              </Col>
              <Col sm="5" style={{ marginTop: 20 }}>
                <img className="menu-player-avatar" src={preview || photo || defaultImage} alt="Preview" />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div>
              <Button color="link" className="update-profile-button" style={{ marginTop: 20 }} disabled={!ableToSave || !preview} onClick={() => uploadAvatarFile()}>
                {!ableToSave ? t('home.loading') : t('common.save')}
              </Button>
              <Button color="link" className="update-profile-button" style={{ marginTop: 20, marginLeft: 20 }} disabled={!ableToSave} onClick={() => toggleShowCrop()}>
                {!ableToSave ? t('home.loading') : t('common.cancel')}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </Col>
    </Row>

  );
});

ProfileAvatarEdit.propTypes = {
  showCrop: PropTypes.bool,
  photo: PropTypes.string,
  preview: PropTypes.string,
  // handleEditPhoto: PropTypes.func.isRequired,
  uploadAvatarFile: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toggleShowCrop: PropTypes.func.isRequired,
  onBeforeFileLoad: PropTypes.func.isRequired,
  onCrop: PropTypes.func.isRequired,
  alertStatus: PropTypes.bool,
  alertMessage: PropTypes.string,
  alertType: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  ableToSave: PropTypes.bool,
  fontMode: PropTypes.string,
};

ProfileAvatarEdit.defaultProps = {
  showCrop: false,
  photo: null,
  preview: null,
  alertStatus: false,
  alertMessage: '',
  alertType: '',
  ableToSave: true,
  fontMode: constants.FONT_MODE.normal,
};

export default ProfileAvatarEdit;
