import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import ScrollArea from 'react-scrollbar';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import { termsOfText } from '../../../constants/termsOfUse';

import closeImg from '../../../images/icons/close.png';

class TermsOfUsePage extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
    };

  //  this.goToLogin = this.goToLogin.bind(this);
  }

  /*  goToLogin = (e) => {
    e.preventDefault();
    const { history } = this.props;
    history.push('/login');
  } */

  render() {
    const { t, toggleHelp, i18n } = this.props;

    return (
      <div className="rules-page">
        <Helmet>
          <title>
            Zole - {t('common:menu.termsOfUse')}
          </title>
        </Helmet>
        {/*  <Row className="rules-page-header">
          <Col sm="8">
            <img className="rules-page-header-logo" src={logoImg} alt="" />
          </Col>
          <Col sm="2" className="rules-page-header-links">
            <a href="#" onClick={this.goToLogin}>
              <img src={loginIconImg} alt="" />
              {t('home.login')}
            </a>
          </Col>
          <Col sm="2">
            <LanguageSelect />
          </Col>
        </Row> */}

        <Row>
          <Col sm="6" />
          <Col sm="6">
            <Button className="help-button" onClick={toggleHelp}>
              <Media src={closeImg} className="notification-header-close" alt="X" />
            </Button>
          </Col>
        </Row>

        <Row className="rules-page-body">
          <Col sm={{ size: 10, offset: 1 }}>
            <ScrollArea
              speed={0.55}
              className="terms-of-use-scroll-area"
              contentClassName="terms-of-use-scroll-content"
              verticalContainerStyle={{
                background: 'transparent',
                opacity: 1,
                width: 10,
              }}
              verticalScrollbarStyle={{
                background: '#fff',
                borderRadius: 1,
                width: 7,
                minHeight: 10,
                minScrollSize: 25,
              }}
              horizontal={false}
            >
              <div dangerouslySetInnerHTML={{ __html: i18n.language == 'lv' ? termsOfText.lv : i18n.language == 'en' ? termsOfText.en : i18n.language == 'ru' ? termsOfText.ru : termsOfText.lv}}/>            
            </ScrollArea>
          </Col>
        </Row>

      </div>
    );
  }
}

export default withTranslation('common')(TermsOfUsePage);
