import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Label,
  Alert,
  Input,
  Button,
  FormGroup,
  Media,
} from 'reactstrap';

import { connect } from 'react-redux';

import ScrollArea from 'react-scrollbar';

import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Firebase } from '../../../lib/firebase';

import logoImg from '../../../images/Menu/zole_logo.webp';
import closeImg from '../../../images/landing-view/close.svg';
import { termsOfText } from '../../../constants/termsOfUse';
import {
  sendLanguage,
  TIMEOUT_TYPE,
  FONT_MODE,
} from '../../../constants/constants';

// import defaultFont from '../../../images/redesign/top-row/defaultFont.webp';
// import largeFont from '../../../images/redesign/top-row/largeFont.webp';
// import xLargeFont from '../../../images/redesign/top-row/xLargeFont.webp';
// import maxFont from '../../../images/redesign/top-row/maxFont.png';

// import history from '../../history';

import { saveRefferalToken } from '../../../actions/refferal';

class SignUp extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    success: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    saveRefToken: PropTypes.func.isRequired,
  }

  static defaultProps = {
    error: null,
    success: null,
    fontMode: FONT_MODE.normal,
  }

  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    password2: '',
    rules: false,
    rulesModal: false,
  }

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.nameRef = React.createRef();
  }

  componentDidMount() {
    const { saveRefToken } = this.props;
    if (this.nameRef.current) {
      this.nameRef.current.focus();
    }

    try {
      const searchParams = new URLSearchParams(window.location.search);
      const refferalToken = searchParams.get('refferalToken');

      console.log('refferalToken', refferalToken);

      if (refferalToken) {
        this.setState({ refferalToken });

        saveRefToken(refferalToken);
      }
    } catch (err) {
      console.log(err);
    }
  }

  //  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { onFormSubmit, i18n, history } = this.props;
    const { rules, success } = this.state;

    Firebase.auth().languageCode = i18n.language;
    if (rules) {
      console.log('history', { history });

      onFormSubmit(this.state)
        .then(() => {
          if (success) {
            setTimeout(() => history.push('/login'), TIMEOUT_TYPE.SIX_SECONDS);
          }
        })
        .catch((e) => {
        });
    }
  }

  toggleRules = () => {
    this.setState(prevState => ({ rulesModal: !prevState.rulesModal }));
  }

  goToLogin = (e) => {
    e.preventDefault();
    const { history } = this.props;

    history.push('/login');

    this.setState({ date: Date.now() });
  }

  toggleFontFunc = () => {
    const { toggleFont, fontMode } = this.props;

    if (fontMode === FONT_MODE.normal) {
      toggleFont(FONT_MODE.large);
    } else if (fontMode === FONT_MODE.large) {
      toggleFont(FONT_MODE.xLarge);
    } else {
      toggleFont(FONT_MODE.normal);
    }
  }

  render() {
    const {
      loading, error, success, t, fontMode, i18n,
    } = this.props;
    const {
      firstName, lastName, email, password, password2, rules, rulesModal,
    } = this.state;

    console.log('render signup');

    return (
      <div className="landing-container-body">
        <Row className="landing-header">
          <Col sm="10">
            <img className="landing-header-logo" src={logoImg} alt="" />
          </Col>
          <Col sm="2" className="landing-header-links">
            {/* <Media src={fontMode === FONT_MODE.xLarge ? xLargeFont : fontMode === FONT_MODE.large ? largeFont : defaultFont} className="user-settings-wrapper-font-mode cursor-pointer mr-2" alt="x" onClick={this.toggleFontFunc} /> */}
            {/* <Link to="/login">
              {t('home.close')}
              <img src={closeImg} alt="" />
            </Link>  */}
             <a href="#" onClick={this.goToLogin}>
              <span>
                {t('home.close')}
                <img src={closeImg} alt="" />
              </span>
            </a>
          </Col>
        </Row>

        <div className="landing-content">

          <div className="landing-form-title">
            <h1 style={{marginBottom: 10}}>{t('home.register')}</h1>
          </div>

          <Row style={{ margin: 0 }}>
            <Col lg={{ size: 4, offset: 4 }} style={{ textAlign: 'center' }}>
              {!!error && <Alert color="danger">{t(`member.${error}`)}</Alert>}
              {!!success && <Alert color="success">{t(`member.${success}`, { email })}</Alert>}


              <Form onSubmit={this.handleSubmit} className="common-form">
                <FormGroup style={{marginBottom: 5}}>
                  <Label for="firstName" style={{marginBottom: 3}}>
                    {t('home.firstName')}
                    *
                  </Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="John"
                    maxLength="20"
                    disabled={loading}
                    value={firstName}
                    onChange={this.handleChange}
                    maxLength={25}
                    innerRef={this.nameRef}
                  />
                </FormGroup>

                <FormGroup style={{marginBottom: 5}}>
                  <Label for="lastName" style={{marginBottom: 3}}>
                    {t('home.lastName')}
                    *
                  </Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Doe"
                    disabled={loading}
                    value={lastName}
                    onChange={this.handleChange}
                    maxLength={25}
                  />
                </FormGroup>
                <FormGroup style={{marginBottom: 5}}>
                  <Label for="email" style={{marginBottom: 3}}>
                    {t('home.email')}
                    *
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="john@doe.corp"
                    disabled={loading}
                    value={email}
                    onChange={this.handleChange}
                    maxLength={100}
                  />
                </FormGroup>
                <FormGroup style={{marginBottom: 5}}>
                  <Label for="password" style={{marginBottom: 3}}>
                    {t('home.pass')}
                    *
                  </Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    disabled={loading}
                    value={password}
                    onChange={this.handleChange}
                    maxLength={100}
                  />
                </FormGroup>
                <FormGroup style={{marginBottom: 5}}>
                  <Label for="password2" style={{marginBottom: 3}}>{t('home.confirmPass')}</Label>
                  <Input
                    type="password"
                    name="password2"
                    id="password2"
                    placeholder="••••••••"
                    disabled={loading}
                    value={password2}
                    onChange={this.handleChange}
                    maxLength={100}
                  />
                </FormGroup>
                <FormGroup style={{marginBottom: 5}}>
                  <Input
                    className="landing-content-rules-checkbox"
                    type="checkbox"
                    name="rules"
                    id="rules"
                    disabled={loading}
                    value={rules}
                    onChange={this.handleChange}
                  />
                  <Label className="display-inline landing-content-rules ml-4" for="rules">
                    <p className="landing-content-rules-button">
                      {' '}
                      {t('home.acceptRules1')}
                      {' '}
                    </p>
                    <Button type="button" onClick={this.toggleRules} className="landing-content-rules-button rules-link-tag" color="link">
                      {t('home.acceptRules2')}
                    </Button>
                  </Label>
                </FormGroup>
                <Button className="common-button lite-shadow submit-button landing-content-register-button" disabled={loading || !rules} style={{marginTop: 10}}>
                  {loading ? t('home.loading') : t('home.register')}
                </Button>
              </Form>


            </Col>
          </Row>
        </div>

        <Modal container={'div > div'} size="lg" isOpen={rulesModal} toggle={this.toggleRules} className={`old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={this.toggleRules}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleRules} />
              }
          >{t('home.termsOfUse')}</ModalHeader>
          <ModalBody style={{ height: 450 }}>
            <ScrollArea
              keyboardShouldPersistTaps="handled"
              speed={0.55}
              className="chat-body-scroll-area"
              contentClassName="chat-body-scroll-content"
              verticalContainerStyle={{
                background: 'transparent',
                opacity: 1,
                width: 10,
              }}
              verticalScrollbarStyle={{
                background: '#fff',
                borderRadius: 1,
                width: 7,
                minHeight: 10,
                minScrollSize: 25,
              }}
              horizontal={false}
            >
              <div dangerouslySetInnerHTML={{ __html: termsOfText[i18n.language || sendLanguage.lv] }}/>
              {/* <b>1. Lietošanas noteikumi</b>
              <p>1.1. Šie noteikumi (turpmāk – Noteikumi) nosaka portāla DraxoGames.com (turpmāk – Portāls) lietošanas kārtību. Noteikumi ir saistoši visām personām, kuras lieto Portālu, reģistrējas tajā un/vai spēlē kādu no piedāvātajām online spēlēm Portālā.</p>
              <p>1.2. Portālu uztur un administrē SIA 'Draxo Games', reģ. Nr. 40103927834, juridiskā adrese: Liepājas iela 49 - 6, Kuldīga, Kuldīgas nov., LV-3301, e-pasta adrese: info@draxogames.com (turpmāk – 'Draxo Games', 'mēs', 'mums').</p>
              <p>1.3. Apstiprinot šos Noteikumus vai reģistrējot savu profilu Portālā, un/vai piedaloties kādā no spēlēm, lietotājs apliecina, ka piekrīt šiem Lietošanas Noteikumiem un citiem saistošiem noteikumiem, kas ir publiskoti Portālā.</p>
              <p>1.4. Lai varētu spēlēt kādu no piedāvātajām online spēlēm, lietotājam ir nepieciešams Portālā izveidot savu profilu, norādot nepieciešamo informāciju. Tāpat ir iespējams autorizēties un ienākt Portālā ar kādu no iepriekš izveidotiem Facebook.com, Draugiem.lv vai Google kontiem.</p>
              <b>2. Portāla lietotājiem aizliegts:</b>
              <p>2.1. izmantot Draugiem.lv vai Facebook.com lietotāja profilu, kas izveidots vai tiek izmantots pretēji sociālo tīklu lietošanas noteikumiem;</p>
              <p>2.2. jebkādā veidā izpaust savas kārtis pretiniekiem, kā arī saspēlēties ar kādu no spēlētājiem ar nolūku iegūt konkrētam spēlētājam labvēlīgu rezultātu;</p>
              <p>2.3. lietot necenzētus, godu un cieņu aizskarošus vārdus spēles čata logā vai sarakstē ar spēlētājiem un/vai spēles administrāciju jebkurā valodā un veidā, kā arī jebkādā veidā nepamatoti apvainot spēlētājus vai spēles administrāciju;</p>
              <p>2.4. izteikt cilvēka cieņu, rasi, reliģisko piederību aizskarošas frāzes čatā un/vai sarakstē ar spēles administrāciju jebkurā valodā un veidā;</p>
              <p>2.5. veikt jebkāda veida darbības, kas traucē pilnvērtīgu spēles izmantošanu citiem spēlētājiem;</p>
              <p>Veikt jebkāda veida darbības, kas saistītas ar trešo pušu produktu vai pakalpojumu reklamēšanu Portālā bez iepriekšējas saskaņošanas ar Portāla administrāciju.</p>

              <b>3. Portāla administrācija</b>
              <p>3.1. Portāla administrācijas uzdevums ir nodrošināt pilnvērtīgu pieeju spēlēm Portāla lietotājiem, kā arī atbildēt uz spēlētāju ziņojumiem, kas ir iesniegti kādā no pieejamajiem saziņas veidiem.</p>
              <p>3.2. Sazināties ar administrāciju ir iespējams, izmantojot iebūvēot čatu, sociālo tīklu kontaktu formu (ja tāda ir), kā arī rakstot uz e-pastu info@draxogames.com.</p>
              <p>3.3. Noteikumu pārkāpumu gadījumā Portāla administrācija patur tiesības bloķēt spēlētāja piekļuvi spēlei un/vai Portālam bez iepriekšējā brīdinājuma.</p>
              <p>3.4. Spēlētāja bloķēšanas gadījumā spēlēs izmantotā virtuālā nauda nekādā veidā netiek kompensētas.</p>

              <b>4. Maksas pakalpojumi</b>
              <p>4.1. Katrā no Portāla spēlēm ir iespējams iegādāties virtuālo naudu vai kādus īpašus Premium pakalpojumus.</p>
              <p>4.2. Ja lietotājs izvēlas kādu maksas pakalpojumu, tad Portālā piedāvātos maksas pakalpojumus iespējams saņemt pēc tam, kad ir veikta priekšapmaksa par attiecīgo pakalpojumu.</p>
              <p>4.3. Visas cenas ir norādītas eiro un ar pievienotās vērtības nodokli.</p>
              <p>4.4. Norēķināties par spēlē iegādāto virtuālo naudu un pakalpojumiem ir iespējams, izmantojot kādu no pieejamajām apmaksas metodēm.</p>
              <p>4.5. Lietotājs drīkst veikt apmaksu tikai no sev piederošas norēķinu kartes vai konta; ir kategoriski aizliegts izmantot 3-šai personai piederošu norēķinu līdzekli bez iepriekšējas saskaņošanas ar šo personu.</p>

              <p>5. Privātums</p>
              <p>5.1. Lai spēlētu kādu no Portālā piedāvātajām spēlēm, ir jāreģistrē un jāizveido savs profils.</p>
              <p>5.2. Profilu var izveidot, aizpildot nepieciešamo informāciju un reģistrējoties, vai arī autorizējoties ar sociālo tīklu profilu.</p>
              <p>5.3. Ja tiek veikts maksājums, tad mēs apstrādājam informāciju, kas saistīta ar šādiem darījumiem.</p>
              <p>5.4. Mēs saņemam informāciju par ierīcēm, kuras tu lieto, lai piekļūtu Portālam (piemēram, ierīces veids, operētājsistēma, interneta pārlūks, mobilo sakaru operators, IP adrese, pieslēgšanās laiks un ilgums).</p>
              <p>5.5. Mēs saņemam informāciju no trešo pušu mājaslapām un mobilajām lietotnēm, kuras tu izmanto, kad autorizējies Portālā (piemēram, veic autorizāciju ar Draugiem.lv vai Facebook pasi).</p>
              <p>5.6. Mēs izmantojam sīkdatnes.</p>
              <p>5.7. Mēs apstrādājam lietotāja personas datus un citu informāciju, lai:</p>

              <b>6. Lietotāja dati tiek izmantoti, lai:</b>
              <p>6.1. nodrošinātu lietotājam iespēju saņemt Portālā piedāvātos pakalpojumus;</p>
              <p>6.2. uzturētu un uzlabotu Portālu, kā arī izstrādāju jaunus pakalpojumus;</p>
              <p>6.3. piedāvātu un personalizētu reklāmu, kā arī novērtētu Portāla darbību un reklāmas efektivitāti;</p>
              <p>6.4. pārbaudītu identitāti un novērstu profilu pretlikumīgu izmantošanu, kā arī nodrošinātu drošu Portāla vidi;</p>
              <p>6.5. aizsargātu lietotāja, mūsu un citu lietotāju intereses (piemēram, ja Portāls tiek izmantots pretlikumīgu darbību veikšanai);</p>
              <p>6.6. sniegtu lietotājam atbildes un sazinātos ar viņu interesējošos jautājumos vai lai informētu par noteikumu izmaiņām;</p>
              <p>6.7. nosūtītu  jaunumus un reklāmu.</p>

              <b>7. Lietotāja datu apstrāde</b>
              <p>7.1. Lietotāja personas dati tiks apstrādi saskaņā ar piemērojamiem tiesību aktiem. Atkarībā no situācijas datu apstrādes pamats var būt piekrišana (piemēram, jaunumu saņemšana), līguma saistību izpilde (piemēram, kad ievieto vai apmaksā sludinājumus), mūsu juridisks pienākums (piemēram, mums ir jāapstrādā tavi personas dati saskaņā ar likumu prasībām), mūsu leģitīmās intereses (piemēram, lai veicinātu Portāla popularitāti un attīstību, lai izmeklētu iespējamu pretlikumīgu rīcību).</p>
              <p>7.2. Mēs apstrādājam personas datus, kas saņemti tieši no tevis, piemēram, no tīmekļa vietnēm (tirgvedības reklāmām, piesakoties jaunumiem, piedaloties konkursos, loterijās un aptaujās, izdarot atsauksmes), no un par iekārtām, kuras tu lieto, kad apmeklē Portālu, no klientu apkalpošanas informācijas, no sazināšanās pa tālruni, e-pastu vai citiem komunikācijas kanāliem.</p>

              <b>8. Personas datu nodošana</b>
              <p>8.1. Mēs nepārdosim, neiznomāsim un citādi neatklāsim tavus personas datus trešajām personām, ja vien tas nav īpaši norādīts tālāk.</p>
              <p>8.2. Mēs varam nodot tavus personas datus trešajām personām, kas mums nodrošina pakalpojumus saskaņā ar nolūkiem, kas aprakstīti šajos privātuma noteikumos, un piemērojamo tiesību aktu ietvaros. Šādām trešajām personām var tikt nodoti dati tikai minimālā un nepieciešamā apmērā, lai mēs varētu nodrošināt savus pakalpojumus, turklāt tikai tad, ja tās ir uzņēmušās atbilstošas konfidencialitātes saistības.</p>
              <p>8.3. Tavi personas dati var tikt nodoti trešajām personām, ar kurām mēs sadarbojamies un kuras mums sniedz pakalpojumus, lai mēs varētu nodrošināt un uzlabot mūsu kā uzņēmuma un arī Portāla darbību (piemēram, datu izvietošanas un uzturēšanas, datu un portāla drošības, maksājumu apstrādes, risku un krāpniecības novēršanas un atklāšanas, datu analīzes, īsziņu un e-pasta izsūtīšanas, juridiskos un finanšu pakalpojumus).</p>
              <p>8.4. Mēs varam nodot tavus personas datus rūpīgi izvēlētām trešajām personām, lai īstenotu kopīgus vai neatkarīgus tiešās reklāmas un marketinga pasākumus. Tavu datu nodošana šādiem nolūkiem var notikt tikai tad, ja trešās personas plānotie mērķi ir saderīgi ar mūsu mērķiem, kas aprakstīti šajā privātuma politikā.</p>
              <p>8.5. Tavi personas dati var tikt nodoti trešajai personai, ja notiek SIA 'Draxo Games' kā uzņēmuma apvienošana, iegūšana, nodošana vai uzņēmuma pamatlīdzekļu pārdošana citam pakalpojumu sniedzējam.</p>
              <p>8.6. Tavi personas dati var arī tikt nodoti citām personām vai apstrādāti citos gadījumos, ja esam saņēmuši tavu piekrišanu.</p>
              <p>8.7. Mēs varam apstrādāt nepersonalizētu informāciju (piemēram, par Portāla lietotāju skaitu).</p>


              <b>9. Personas datu pārvaldīšana</b>
              <p>9.1. Tev ir tiesības pašam labot sevis norādīto informāciju Portāla sadaļā "Profila iestatījumi", kā arī tev ir tiesības dzēst savus sludinājumus un/vai profilu.</p>
              <p>9.2. Ciktāl mēs apstrādājam tavus personas datus, pamatojoties uz piekrišanu, tev ir tiesības jebkurā laikā atsaukt savu piekrišanu personas datu apstrādei.</p>
              <p>9.3. Tev ir tiesības prasīt mūsu apstiprinājumu, vai tiek apstrādāti tavi personas dati, un, ja tiek apstrādāti, saņemt informāciju par saviem personas datiem, ko esi mums sniedzis, pamatojoties uz savu piekrišanu vai līgumu, un ja apstrādāšana tiek veikta automatizēti.</p>
              <p>9.4. Tev ir tiesības iebilst pret savu personas datu apstrādi tiešā mārketinga nolūkiem. Tu vari atrakstīties no tiešā mārketinga, nosūtot mums ziņu uz e-pasta adresi info@zip.lv vai nospiežot uz saites "atteikties" mūsu e-pasta vēstulēs.</p>
              <p>9.5. Tev ir tiesības iebilst pret personas datu apstrādi, ko mēs veicam, pamatojoties uz mūsu leģitīmajām interesēm, tomēr mēs turpināsim apstrādāt personas datus pat tad, ja būsi iebildis pret to, ja mums būs likumīgi iemesli turpināt datu apstrādi.</p>
              <p>9.6. Tev ir tiesības jebkurā brīdī dzēst sludinājumus un savu profilu. Ja sludinājums un/vai profils tiek dzēsts, tad tiks dzēsta arī ar to saistītā informācija.</p>
              <p>9.7. Tev ir tiesības iesniegt sūdzību par tavu personas datu apstrādi uzraudzības iestādē (Datu valsts inspekcijā).</p>

              <b>10. Personas datu apstrādāšanas termiņš</b>
              <p>10.1. Mēs varam apstrādāt tavus personas datus tik ilgi, kamēr tas nepieciešams saskaņā ar attiecīgo apstrādes nolūku. Piemēram, ja datu apstrāde pamatojas uz tavu piekrišanu, un turpmākai apstrādei nav cita likumīga pamata, tad tavi personas dati tiks dzēsti pēc tava pieprasījuma. Ja datu apstrādes pamats ir līguma izpilde, tad mēs apstrādāsim datus, kamēr mums tas ir pamatoti nepieciešams likumisko un līgumisko tiesību un pienākumu izpildei un nodrošināšanai.</p>
              <p>10.2. Pēc profila, datu un/vai informācijas dzēšanas tā uzreiz vairs nebūs redzama citiem lietotājiem, taču minētais profils, dati un/vai informācija mūsu datu bāzēs var tikt saglabāta līdz 90 dienām no dzēšanas brīža, lai izvairītos no krāpnieciskām darbībām un citas pretlikumīgas rīcības.</p>
              <p>10.3. Papildus norādītajam laikam mēs varam apstrādāt tavus personas datus, piemēram, ja to paredz normatīvie akti, vai nodokļu un juridisko prasību un tiesību nodrošināšanai, vai arī īpašos gadījumos, kamēr vien tas ir attaisnoti nepieciešams (piemēram, likuma prasību izpildei).</p>
              <p>10.4. Kad nepieciešamais datu apstrādes laiks būs beidzies, mēs izdzēsīsim tavus personas datus vai padarīsim tos neidentificējamus, lai tos vairs nevar saistīt ar tevi.</p>

              <b>11. Sīkdatnes</b>
              <p>11.1. Izmantojot Portāla tīmekļa vietni, jūs piekrītat, ka jūsu datorā vai citās ierīcēs, no kurām piekļūstat mūsu tīmekļa vietnei, var tikt izvietotas sīkdatnes, tīmekļa bāksignāli un tamlīdzīgas uzglabāšanas tehnoloģijas. Šajos sīkdatņu noteikumos aprakstīts, kādas sīkdatnes mēs izmantojam mūsu tīmekļa vietnē un kādiem nolūkiem.</p>
              <p>11.2. Sīkdatnes ir sīkas teksta datnes, kuras rada tīmekļa vietne un kuras tiek uzglabātas jūsu ierīcē, jums apmeklējot mūsu mājas lapu. Jūsu izmantotā pārlūkprogramma izmanto sīkdatnes, lai nosūtītu informāciju atpakaļ uz tīmekļa vietni katrā nākamajā apmeklējuma reizē, lai tīmekļa vietne varētu atpazīt lietotāju un atcerēties lietotāja izvēles (piemēram, autorizēšanās informāciju un citus iestatījumus). Tādā veidā jūsu nākamais apmeklējums būs vieglāks un vietne jums noderīgāka.</p>
              <p>11.3. Mūsu tīmekļa vietnes darbībā mēs izmantojam dažāda veida sīkdatnes. Turpmāk norādītās sīkdatnes var tikt uzglabātas jūsu pārlūkprogrammā.</p>
              <p>11.3.1. Obligātās sīkdatnes. Šīs sīkdatnes ir svarīgas, jo nepieciešamas netraucētas tīmekļa vietnes darbības nodrošināšanai. Bez šīm sīkdatnēm nav iespējams nodrošināt savienojumu ar lietotāju profilu un pilnā apmērā izmantot tīmekļa vietni. Šādas sīkdatnes identificē lietotāja ierīci, taču neatklāj lietotāja identitāti un nevāc lietotāja informāciju. Šīs sīkdatnes tiek uzglabātas lietotāja ierīcē līdz pārlūkprogrammas aizvēršanai.</p>
              <p>11.3.2. Funkcionālās sīkdatnes. Šādas sīkdatnes nodrošina ērtu un pilnīgu mūsu tīmekļa vietnes lietošanu, kā arī palīdz lietotājiem efektīvi izmantot mūsu tīmekļa vietni un personalizēt to. Funkcionālās sīkdatnes saglabā lietotāja izvēles un nodrošina atsevišķas funkcijas. Piemēram, lai nodrošinātu "Atcerēties mani" funkcionalitāti, kas ļauj lietotājam ievadīt savu e-pastu un paroli tikai vienu reizi. Šīs sīkdatnes tiek pastāvīgi saglabātas lietotāja ierīcē.</p>
              <p>11.3.3. Analīzes sīkdatnes. Šādas sīkdatnes vāc informāciju par lietotāju mijiedarbību ar mūsu tīmekļa vietni, piemēram, kuras sadaļas visbiežāk tiek apmeklētas un kuri pakalpojumi visbiežāk tiek izmantoti. Ievāktā informācija tiek izmantota analīzes nolūkos, lai saprastu, kas lietotājus interesē un kā padarīt tīmekļa vietni lietotājam draudzīgāku. Analīzes nolūkos mēs varam izmantot trešo personu sīkdatnes. Šīs sīkdatnes tiek pastāvīgi saglabātas lietotāja ierīcē.</p>
              <p>11.3.4. Mērķorientētas sīkdatnes. Šīs sīkdatnes tiek izmantotas mūsu tīmekļa vietnē, lai individuāli jums un jūsu interesēm pielāgotu mārketingu un nākotnē sniegtu jums personalizētus pakalpojumus. Šīs sīkdatnes atceras, ka jūs apmeklējāt mūsu tīmekļa vietni, un var tikt izmantotas, lai parādītu jums sociālajos medijos un citās vietnēs personalizētu reklāmu. Mērķorientētas reklāmas nolūkos mēs varam izmantot trešo personu sīkdatnes. Šīs sīkdatnes tiek pastāvīgi saglabātas lietotāja ierīcē.</p>
              <p>11.3.5. Trešo personu sīkdatnes. Mūsu tīmekļa vietne izmanto trešo personu pakalpojumus, piemēram, analīzes pakalpojumus, lai mēs zinātu, kas mūsu tīmekļa vietnē ir populārs un kas ne, tādējādi padarot mūsu tīmekļa vietni lietojamāku. Mēs nekontrolējam trešo personu sīkdatnes un vairāk informācijas par šādām sīkdatnēm un to privātuma noteikumus varat iegūt, apmeklējot attiecīgo trešo personu tīmekļa vietnes. Jebkādu no trešo personu sīkdatnēm iegūtu informāciju apstrādā attiecīgais pakalpojuma sniedzējs. Jūs jebkurā laikā varat izvēlēties atteikties no trešo personu sīkdatņu veiktas apstrādes.</p>
              <p>11.4 Apmeklējot mūsu vietni, jums tiek parādīts informatīvais paziņojums, ka tīmekļa vietne izmanto sīkdatnes. Ja vēlaties atsaukt savu piekrišanu saglabāt jūsu ierīcē sīkdatnes, varat izdzēst visas pārlūkprogrammā saglabātās sīkdatnes un iestatīt pārlūkprogrammu bloķēt saglabātās sīkdatnes. Noklikšķinot uz pogas "palīdzība" jūsu pārlūkprogrammā, jūs varat atrast norādes, kā neļaut pārlūkprogrammai saglabāt sīkdatnes, kā arī kādas sīkdatnes jau ir saglabātas un, ja vēlaties, dzēst tās. Izmaiņas iestatījumos veicamas katrai jūsu izmantotajai pārlūkprogrammai un katrai ierīcei atsevišķi. Tomēr, lūdzu, ņemiet vērā, ka, nesaglabājot dažas sīkdatnes, iespējams, jūs nevarēsiet pilnībā izmantot visas mūsu tīmekļa vietnes funkcijas un pakalpojumus.</p>

              <b>12. Izmaiņas</b>
              <p>Mums ir tiesības laiku pa laikam mainīt šos noteikumus. Visas noteikumu izmaiņas tiks ievietotas Portālā, un būtisku izmaiņu gadījumā mēs tevi informēsim par šādām izmaiņām.</p>

              <b>13. Saziņa ar mums</b>
              <p>Tev ir iespējams sazināties ar mums par datu apstrādes un aizsardzības jautājumiem, nosūtot e-pasta vēstuli uz info@draxogames.com vai sūtot vēstuli SIA 'Draxo Games' datu aizsardzības speciālistam Kuldīgā, Liepājas ielā 49-6, LV-3301.</p> */}
            </ScrollArea>
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button type="button" className="btn notification-footer-button" onClick={this.toggleRules}>
              {t('home.close')}
            </Button>
          </ModalFooter>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = {
  saveRefToken: saveRefferalToken,
};

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp)));
