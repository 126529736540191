import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch, shallowEqual, connect } from 'react-redux';
import { isEqual } from 'lodash';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Label from 'reactstrap/lib/Label';

import {
  nextTourStep, prevNewTourStep, stopTour, nextNewTourPage, prevTourPage,
} from '../../../../../actions/tour';

import * as constants from '../../../../../constants/constants';
import closeImg from '../../../../../images/icons/close.svg';
import hurmanImg from '../../../../../images/redesign/tutorial/hurman.webp';
import arrowImg from '../../../../../images/redesign/tutorial/arrow.png';
import { setJoyrideEndedTrigger } from '../../../../../actions/state';

const Tooltip = ({
  index,
  step,
  tooltipProps,
  size,
  t,
  setJoyrideEndedTriggerFunc,
}) => {
  const dispatch = useDispatch();

  let titleNo = '';
  let titleLabel = '';
  if (step.title) {
    const titleArray = t(step.title).split('.');
    titleNo = `${titleArray[0]}.${titleArray[1]}`;
    titleLabel = `${titleArray[2]}`;
  }

  return (
    <>
      <div className="tooltip-main-arrow">
        <Media src={hurmanImg} alt="x" />
      </div>
      <div className="tooltip-body" {...tooltipProps}>
        <div className="tour-tooltip-header">
          <Media src={closeImg} className="tour-tooltip-header-close" alt="X" onClick={() => { dispatch(stopTour()); }} />
        </div>
        {step && step.title && (
          <h2 className="tooltip-body-title tooltip-body-new-title">
            <Label className="tooltip-body-title-no">
              {titleNo}
            </Label>
            {titleLabel}
          </h2>
        )}
        <div className="tooltip-body-content">{t(step && step.content)}</div>
        <div className="tooltip-body-footer">
          {
            step && step.stepType !== constants.TOUR_PAGE_LIMIT && (
              <Button
                className="tooltip-body-skip tooltip-next_section_button tooltip-body-white-button"
                disabled={step && step.stepType === constants.TOUR_PAGE_LIMIT}
                style={{ width: 230 }}
                onClick={() => { dispatch(nextNewTourPage(index, step)); }}
              >
                {t('tutorial.nextSection')}
              </Button>
            )
          }
          <div className="tooltip-body-right-buttons">
            {step && step.stepType !== constants.TOUR_PAGE_LIMIT ? (
              <Button
                className="tooltip-body-button tooltip-next_page-button tooltip-body-highlight-button"
                // disabled={index + 1 === size && step && step.stepType !== constants.TOUR_PAGE_LIMIT}
                onClick={() => {
                  index + 1 === size && step && step.stepType !== constants.TOUR_PAGE_LIMIT ? (
                    dispatch(nextNewTourPage(index, step))
                  ) : (
                    dispatch(nextTourStep(index, step))
                  )
                }}
              >
                {`${t('tutorial.next')} (${constants.TOUR_EXTRA_PAGES.includes(step.stepType) ? index : index + 1} / ${constants.TOUR_EXTRA_PAGES.includes(step.stepType) ? size - 1 : size})`}
                {/* {`${t('tutorial.next')} (${index + 1} / ${size})`} */}
              </Button>
            ) : (index + 1 < size && step.stepType === constants.TOUR_PAGE_LIMIT) ? (
              <Button
                className="tooltip-body-button tooltip-next_page-button tooltip-body-highlight-button"
                // disabled={index + 1 === size && step && step.stepType !== constants.TOUR_PAGE_LIMIT}
                onClick={() => {
                  index + 1 === size && step && step.stepType !== constants.TOUR_PAGE_LIMIT ? (
                    dispatch(nextNewTourPage(index, step))
                  ) : (
                    dispatch(nextTourStep(index, step))
                  )
                }}
              >
                {`${t('tutorial.next')} (${step && constants.TOUR_EXTRA_PAGES.includes(step.stepType) ? index : index + 1} / ${step && constants.TOUR_EXTRA_PAGES.includes(step.stepType) ? size - 1 : size})`}
              </Button>
            ) : (null)}
            {index + 1 === size && step && step.stepType === constants.TOUR_PAGE_LIMIT && (
              <Button className="tooltip-body-button tooltip-body-highlight-button" onClick={() => { dispatch(stopTour(index, step)); setJoyrideEndedTriggerFunc(true); }}>
                {t('common.close')}
              </Button>
            )}
            {step && step.stepType === constants.TOUR_FIRST_PAGE && index > 0 ? (
              <Button className="tooltip-body-button tooltip-back-button tootip-back-new-button" onClick={() => { dispatch(prevNewTourStep(index, step)); }}>
                {t('tutorial.back')}
              </Button>
            ) : step && step.stepType !== constants.TOUR_FIRST_PAGE && index >= 0 && (
              <Button className="tooltip-body-button tooltip-back-button tootip-back-new-button" onClick={() => { dispatch(prevNewTourStep(index, step)); }}>
                {t('tutorial.back')}
              </Button>
            )}
            {/* {step && step.stepType > 1 && (
              <Button className="tooltip-body-button tooltip-back_section-button" onClick={() => { dispatch(prevTourPage(index, step)); }}>
                {t('tutorial.backSection')}
              </Button>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

Tooltip.propTypes = {
  continuous: PropTypes.bool,
  index: PropTypes.number,
  step: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  backProps: PropTypes.shape({}),
  closeProps: PropTypes.shape({}),
  primaryProps: PropTypes.shape({}),
  tooltipProps: PropTypes.shape({}),
  skipProps: PropTypes.shape({}),
  size: PropTypes.number,
  t: PropTypes.shape({}),
  goToNextStep: PropTypes.func.isRequired,
};

Tooltip.defaultProps = {
  continuous: false,
  index: null,
  step: {},
  backProps: {},
  closeProps: {},
  primaryProps: {},
  tooltipProps: {},
  skipProps: {},
  size: null,
  t: {},
};

const mapDispatchToProps = {
  setJoyrideEndedTriggerFunc: setJoyrideEndedTrigger,
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(Tooltip));