import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';

/*
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media'; */

import { useTranslation } from 'react-i18next';

// import CustomDate from '../UI/CustomDate';
// import LinkifyWithTargetBlank from '../UI/LinkifyWithTargetBlank';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

// import defaultImage from '../../../images/Game/defaultImage.webp';
// import * as constants from '../../../constants/constants';

const SplitMessage = React.memo(({ splitFilteredMessage, splitMessage, message, fontMode, inlineClassName, messageKey, isReportedMessage, reportPopoverOpen, reportPopoverLeave }) => {
  const { t } = useTranslation('chat');

  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const onHover = (key) => {
    if (!popoverOpen) {
      setPopoverOpen(key);
    }
  };

  const onHoverLeave = () => {
    setPopoverOpen(false);
  };

  return (
    <div className={inlineClassName}>
      {splitFilteredMessage.map((row, index) => {
        let words = [];
        if (splitMessage[index]) {
          words = splitMessage[index].split(' ');
        }
        const filteredWords = row.split(' ');

        return (
          <div key={index}>
            {filteredWords.map((word, index2) => (
              <>
                {(words && words[index2] !== word) ? (
                  <>
                    <div
                      style={{ display: 'inline-block', marginRight: 2 }}
                      id={`chat-popover-${message.messageId}-${index2}`}
                      onMouseEnter={() => onHover(`${message.messageId}-${index2}`)}
                      onMouseLeave={() => onHoverLeave()}
                    >
                      {`${word.replace()} `}
                    </div>
                    <Popover container={'div > div'} popperClassName={`last-round-popover popover-font-${fontMode}`} placement="top" isOpen={popoverOpen === `${message.messageId}-${index2}`} target={`chat-popover-${message.messageId}-${index2}`}>
                      <PopoverBody className="last-round-popover-body">
                        {`${words[index2]}`}
                      </PopoverBody>
                    </Popover>
                  </>
                ) : (
                  <>
                    {`${word} `}
                  </>
                )}
                {messageKey && (
                  <Popover
                    container={'div > div'}
                    placement="left"
                    isOpen={reportPopoverOpen === `report-message-${messageKey}`}
                    target={`report-message-${messageKey}`}
                    popperClassName={`popover popover-font-${fontMode} chat-message-other-popover`}
                  >
                    <PopoverBody
                      onMouseLeave={reportPopoverLeave}
                    >
                      {isReportedMessage ? t('reportedMessage') : t('reportMessage')}
                    </PopoverBody>
                  </Popover>
                )}
              </>
            ))}
          </div>
        );
      })}
    </div>
  );
});

export default SplitMessage;
