import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import Media from 'reactstrap/lib/Media';

import BlockUserModal from '../../Modals/BlockUser';

import {
  blockUser,
  unBlockUser,
} from '../../../../../../actions/member';

import * as constants from '../../../../../../constants/constants';

import blockImg from '../../../../../../images/redesign/player/block.svg';
import unblockImg from '../../../../../../images/redesign/player/unblock.svg';

import lightBlockImage from '../../../../../../images/redesign/light-mode/common/block.svg';
import lightUnblockImage from '../../../../../../images/redesign/light-mode/common/unblock.svg';

import buttonClickedSound from '../../../../../../sounds/click_feedback.flac';
import IconPopover from '../../../Components/Components/IconPopover';

class LastRound extends React.Component {
  static propTypes = {
    block: PropTypes.func.isRequired,
    unblock: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    soundOn: PropTypes.bool,
    uid: PropTypes.string,
    name: PropTypes.string,
    tournamentRoom: PropTypes.bool,
    index: PropTypes.number,
    isIgnored: PropTypes.bool,
    notificationSettings: PropTypes.shape(),
    tournamentRoom: PropTypes.bool,
    screenMode: PropTypes.string
  };

  static defaultProps = {
    soundOn: true,
    uid: null,
    name: null,
    tournamentRoom: null,
    index: null,
    isIgnored: null,
    notificationSettings: {},
    tournamentRoom: false,
    screenMode: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      blockUserModal: false,
      tooltipId: null,
    };

    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }


  componentDidMount() {

  }

  onBlockHover = (type, index) => {
    if (type === 'enter') {
      this.setState({ blockPopoverOpen: index, tooltipId: `block-popover-${index}player` });
    } else {
      this.setState({ blockPopoverOpen: null, tooltipId: null });
    }
  }

  playButtonSound = () => {
    const { soundOn } = this.props;

    if (soundOn) {
      const playPromise = this.buttonClickedAudio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            // Automatic playback started!
            // Show playing UI.
            console.log("audio played auto");
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
            console.log("playback prevented");
          });
      }
    }
  }

  toggleBlockUser = () => {
    const { notificationSettings, uid, name } = this.props;
    this.playButtonSound();

    if (notificationSettings && notificationSettings.ignorePlayer === false) {
      this.blockUserInstant(uid, name);
    } else {
      this.setState(prevState => ({
        blockUserModal: !prevState.blockUserModal,
      //  blockUserSelectedUid: id,
        blockUserSelectedName: name,
      }));
    }
  };

  blockUserInstant = () => {
    const { block, uid, name } = this.props;

    if (uid && name) {
      console.log('ReactGA - Ignore Player');
      
      ReactGA.event({
        category: 'Game',
        action: 'Ignore Player',
      });

      block(uid, name);

      this.setState({
        blockUserModal: false,
      //  blockUserSelectedUid: null,
      //  blockUserSelectedName: null,
      });
    }
  };

  blockUser = () => {
    const { block, uid, name } = this.props;
    //  const { blockUserSelectedUid, blockUserSelectedName } = this.state;

    console.log('blockUser', { uid, name });

    if (uid && name) {
      ReactGA.event({
        category: 'Game',
        action: 'Ignore Player',
      });

      block(uid, name);

      this.playButtonSound();
      this.setState({ blockPopoverOpen: null });

      this.setState({
        blockUserModal: false,
      //  blockUserSelectedUid: null,
      //  blockUserSelectedName: null,
      });
    }
  };

  unBlockUser = () => {
    const { unblock, uid, name } = this.props;
    this.setState({ blockPopoverOpen: null });

    unblock(uid, name);
  }

  render() {
    const {
      t,
      tournamentRoom,
      index,
      isIgnored,
      screenMode
    } = this.props;

    const { blockUserModal, blockUserSelectedName, blockPopoverOpen, tooltipId } = this.state;

    if (index === 1 || tournamentRoom) {
      return null;
    }
console.log(index, isIgnored, blockPopoverOpen, "tournamentRoom");
    return (
      <>
        {isIgnored ? (
          <>
            <div>
              <Media
                className={`unblock-icon ${(tournamentRoom) && 'disabled'}`}
                src={screenMode !== constants.SCREEN_MODE.light ? unblockImg : lightUnblockImage}
                alt=""
                onClick={!tournamentRoom ? this.unBlockUser : false}
                id={`block-popover-${index}player`}
                onMouseEnter={() => this.onBlockHover('enter', index)}
                onMouseLeave={() => this.onBlockHover('leave', index)}
              />
            </div>
            {/* {(tournamentRoom) && (
              <Popover container={'div > div'} popperClassName="last-round-popover last-round-new-popover" isOpen={blockPopoverOpen === index} placement="top" target={`unblock-popover-${index}player`}>
                <PopoverBody className="last-round-popover-body">
                  <div>
                    {t('notifications:blockUserTournament')}
                  </div>
                </PopoverBody>
              </Popover>
            )} */}
            {!tournamentRoom && (
              <IconPopover text={t('common:popovers.unignorePlayer')} placement="right" targetId={`block-popover-${index}player`} popoverOpen={blockPopoverOpen === index} />
            )}
          </>
        ) : (
          <>
            <div>
              <Media
                className={`block-icon ${(tournamentRoom) && 'disabled'}`}
                src={screenMode !== constants.SCREEN_MODE.light ? blockImg : lightBlockImage}
                alt=""
                onClick={!tournamentRoom ? this.toggleBlockUser : false}
                id={`block-popover-${index}player`}
                onMouseEnter={() => this.onBlockHover('enter', index)}
                onMouseLeave={() => this.onBlockHover('leave', index)}
              />
            </div>
            {/* {(tournamentRoom) && (
              <Popover container={'div > div'} popperClassName="last-round-popover last-round-new-popover" placement="top" isOpen={blockPopoverOpen === index} target={`block-popover-${index}player`}>
                <PopoverBody className="last-round-popover-body">
                  <div>
                    {t('notifications:blockUserTournament')}
                  </div>
                </PopoverBody>
              </Popover>
            )} */}
            {!tournamentRoom && (
              <IconPopover text={t('common:popovers.ignorePlayer')} placement="right" targetId={`block-popover-${index}player`} popoverOpen={blockPopoverOpen === index} />
            )}
          </>
        )}


        <BlockUserModal
          t={t}
          blockUserModal={blockUserModal}
          blockUserSelectedName={blockUserSelectedName}
          toggleBlockUser={this.toggleBlockUser}
          blockUserFunction={this.blockUser}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  notificationSettings: (state.userSettings[state.member.uid] && state.userSettings[state.member.uid].notificationSettings) ? (state.userSettings[state.member.uid].notificationSettings) : (state.userSettings.default ? state.userSettings.default.notificationSettings : null),
});

const mapDispatchToProps = {
  block: blockUser,
  unblock: unBlockUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['game', 'notifications', 'common'])(LastRound));
