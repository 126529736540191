import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Media from 'reactstrap/lib/Media';

import minGamesBell from '../../../../../../images/redesign/rooms-table/Call bell.png';

import pro from '../../../../../../images/redesign/common/PRO.svg';
import slow from '../../../../../../images/redesign/common/karavs.svg';
import speed from '../../../../../../images/redesign/common/Room types speed.svg';
import lightning from '../../../../../../images/redesign/common/Room types lightning.svg';
import privateRoom from '../../../../../../images/icons/lock-outlined.svg';
import unlimited from '../../../../../../images/redesign/common/Room types unlimited.svg';
import { SCREEN_MODE } from '../../../../../../constants/constants';

const Info = React.memo((props) => {
  const { t } = useTranslation('rules');
  const { screenMode } = props;

  // TODO: refactor repeating parts into separate component to simplify code
  return (
    <div className="rooms-table-info-modal">

      <div className="rooms-table-info-modal-block">
        <div className="rooms-table-info-modal-icon">
          <Media className="rooms-table-info-modal-icon" src={unlimited} alt="Bez limita" />
        </div>
        <div className={`rooms-table-info-modal-text rooms-table-info-modal-highlighted`}>
          {t('rooms.types.1_3')}
        </div>
      </div>

      <div className="rooms-table-info-modal-block">
        <div className="rooms-table-info-modal-icon">
          <Media className="rooms-table-info-modal-icon" src={slow} alt="Lēnā" />
        </div>
        <div className={`rooms-table-info-modal-text rooms-table-info-modal-highlighted`}>
          {t('rooms.types.1_0')}
        </div>
      </div>

      <div className="rooms-table-info-modal-block">
        <div className="rooms-table-info-modal-icon">
          <Media className="rooms-table-info-modal-icon" src={speed} alt="Ātrā" />
        </div>
        <div className={`rooms-table-info-modal-text rooms-table-info-modal-highlighted`}>
          {t('rooms.types.1_1')}
        </div>
      </div>

      <div className="rooms-table-info-modal-block">
        <div className="rooms-table-info-modal-icon">
          <Media className="rooms-table-info-modal-icon" src={lightning} alt="Zibens" />
        </div>
        <div className={`rooms-table-info-modal-text rooms-table-info-modal-highlighted`}>
          {t('rooms.types.1_2')}
        </div>
      </div>

      <div className="rooms-table-info-modal-block">
        <div className="rooms-table-info-modal-icon">
          <Media className="rooms-table-info-modal-icon" src={pro} alt="Pro" />
        </div>
        <div className="rooms-table-info-modal-text rooms-table-info-modal-highlighted">
          {t('rooms.types.2_0')}
        </div>
      </div>

      <div className="rooms-table-info-modal-block">
        <div className="rooms-table-info-modal-icon">
          <Media className="rooms-table-info-modal-icon" src={privateRoom} alt="Private" />
        </div>
        <div className="rooms-table-info-modal-text rooms-table-info-modal-highlighted">
          {t('rooms.types.2_1')}
        </div>
      </div>

      <div className="rooms-table-info-modal-block">
        <div className="rooms-table-info-modal-icon">
        <div className="rooms-table-row-minGames-wrapper">
            <Media className="rooms-table-row-minGames-bell-img rooms-table-row-minGames-bell-img-legend" src={minGamesBell} alt="MinGames" />
            <div className="rooms-table-row-minGames-bell-count rooms-table-row-minGames-bell-count-legend">5</div>
        </div>
        </div>
        <div className="rooms-table-info-modal-text rooms-table-info-modal-highlighted">
          {t('rooms.types.2_3')}
        </div>
      </div>
      

    </div>
  );
});

Info.propTypes = {
  screenMode: PropTypes.string,
};

Info.defaultProps = {
};

export default Info;
