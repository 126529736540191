import { setPrevActiveTab } from '../actions/state';
import Store from '../store/state';

export const initialState = Store;


export default function stateReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOADING_REPLACE': {
      return {
        ...state,
        isLoading: action.data,
      };
    }

    case 'OPEN_ROOMS_TAB': {
      return {
        ...state,
        openRoomsTab: action.data,
      };
    }

    case 'OPEN_TOP_TAB': {
      return {
        ...state,
        openTopTab: action.data,
      };
    }

    case 'OPEN_TOURNAMENTS_TUTORIAL': {
      return {
        ...state,
        openTournamentsTutorial: action.data,
      };
    }

    case 'PREPARE_FOR_JOYRIDE': {
      return {
        ...state,
        prepareForJoyride: action.data,
      };
    }

    case 'EXPANDED_LANDSCAPE_SIDEBAR': {
      return {
        ...state,
        expandedLandscapeSidebar: action.data,
      };
    }

    case 'JOYRIDE_ENDED_TRIGGER': {
      return {
        ...state,
        joyrideEndedTrigger: action.data,
      };
    }

    case 'ACTIVE_TAB': {
      return {
        ...state,
        activeTab: action.data,
      };
    }

    case 'PW_OPTION': {
      return {
        ...state,
        pwOption: action.data,
      };
    }

    case 'IGNORED_SWITCH_CHECKED': {
      return {
        ...state,
        ignoredSwitchChecked: action.data,
      };
    }

    default:
      return state;
  }
}
