import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Media from 'reactstrap/lib/Media';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';

import gPlayedImg from '../../../../../../images/icons/games_played.svg';
import leaderboardImg from '../../../../../../images/icons/leaderboard.svg';
import gWonImg from '../../../../../../images/icons/wins.svg';
import giftsImg from '../../../../../../images/icons/gift.svg';
import infoImg from '../../../../../../images/icons/info_chats.svg';
import closeImg from '../../../../../../images/icons/close.png';

const PlayerModal = React.memo(({
  playerInfo, gifts, closePlayerModal, posClassName, modalOpen, photo, currentType, gameState, largePlayer,
}) => {
  console.log('PlayerModal', { modalOpen });

  if (!modalOpen) {
    return null;
  }

  const { t } = useTranslation('playerInfo');

  return (
    <>
      <div className="player-info-modal-backdrop" onClick={closePlayerModal} />
      <div className={`player-info-modal player-info-modal-${posClassName} player-info-new-modal`} toggle={closePlayerModal} backdropClassName="player-info-modal-backdrop">

      {/*  <div className="player-info-header">
          <Media src={infoImg} className="player-info-header-img" />
          <div className="player-info-text">
            {t('info')}
          </div>
          <Media src={closeImg} className="player-info-header-close" alt="X" onClick={closePlayerModal} />
        </div>
        <div className="player-info-header">
          <div className="modal-title">
            <div className="default-modal-title">
              {t('info')}
            </div>
          </div>
        </div> */}
        <div className="player-info-header">
          <div className="player-info-header-title-wrapper">
            <div className="player-info-header-title">
              {t('info')}
            </div>
          </div>
        </div>
        <div className="player-info-body player-info-new-body">
          <div className="player-info-row">
            <div className="player-info-level">
              <div className="player-info-level-text">
                {playerInfo.lvl}
              </div>
            </div>
            <div className="player-info-text">
              {playerInfo.name}
            </div>
          </div>
          <div className="player-info-row">
            <div className="player-info-text">
              {`${t('points')}: ${playerInfo.totalPnts}`}
            </div>
          </div>
          <div className="player-info-row">
            <Media src={gPlayedImg} className="player-info-img" />
            <div className="player-info-text">
              {`${t('gPlayed')}: ${playerInfo.gPlayed}`}
            </div>
          </div>
          <div className="player-info-row">
            <Media src={leaderboardImg} className="player-info-img" />
            <div className="player-info-text">
              {`${t('pos')}: ${playerInfo.pos}`}
            </div>
          </div>
          <div className="player-info-row">
            <Media src={gWonImg} className="player-info-img" />
            <div className="player-info-text">
              {`${t('gWon')}: ${playerInfo.gWon}`}
            </div>
          </div>
          <div className="player-info-row">
            <Media src={giftsImg} className="player-info-img player-info-img-gifts" />
            <div className="player-info-text">
              {`${t('lastGifts')}:`}
            </div>
          </div>
          <Row className="giftsHistory-row">
            {(gifts && playerInfo && playerInfo.giftsHistory) && Object.keys(playerInfo.giftsHistory).map(key => (
              <Col key={`${key}`} xs="2" sm="2">
                {playerInfo.giftsHistory[key] && (
                <div
                  id={`giftsHistory-${key}`}
                  className="giftsHistory"
                  style={{ backgroundImage: `url(${gifts[playerInfo.giftsHistory[key].giftId] ? gifts[playerInfo.giftsHistory[key].giftId].image : ''})` }}
                >

                  <>
                    <UncontrolledTooltip
                      className="giftsHistory-tooltip"
                      placement="bottom"
                      target={`giftsHistory-${key}`}
                      container={'div > div'}
                    >
                      <div className="giftsHistory-tooltip-from">
                        {playerInfo.giftsHistory[key].fromName || ''}
                      </div>
                      <div className="giftsHistory-tooltip-comment">
                        {playerInfo.giftsHistory[key].comment || ''}
                      </div>
                    </UncontrolledTooltip>
                  </>

                </div>
                )}
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
});

PlayerModal.propTypes = {
  playerInfo: PropTypes.shape(),
  gifts: PropTypes.shape(),
  closePlayerModal: PropTypes.func.isRequired,
  posClassName: PropTypes.string,
  modalOpen: PropTypes.bool,
  photo: PropTypes.string,
  currentType: PropTypes.string,
  gameState: PropTypes.string,
  largePlayer: PropTypes.bool,
};

PlayerModal.defaultProps = {
  playerInfo: null,
  gifts: null,
  posClassName: null,
  modalOpen: false,
  photo: null,
  currentType: null,
  gameState: null,
  largePlayer: false,
};

export default PlayerModal;
