import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Iframe from 'react-iframe';
import { filter, includes } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import Media from 'reactstrap/lib/Media';
import Label from 'reactstrap/lib/Label';

// import { loadStripe } from '@stripe/stripe-js';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import config from '../../../../../constants/config';

import CustomModal from '../Components/Modal';
import CustomDate from '../Components/CustomDate';
import ContactSupport from '../../ContactSupport/ContactSupport';

import buttonClickedSound from '../../../../../sounds/click_feedback.flac';

import * as constants from '../../../../../constants/constants';
// import { generalMessages } from '../../../../../constants/messages';

import closeImg from '../../../../../images/icons/close.png';
import logoImg from '../../../../../images/redesign/landing-view/zoleLogo.svg';

import {
  initFBPayment,
  initDraugiemPayment,
  initInboxPayment,
  fbPaymentCallback,
  initStripePayment,
  initEveryPayPayment,
} from '../../../../../actions/member';
import {
  getDiscounts,
} from '../../../../../actions/shop';
import PaymentTypeContent from '../../Menu/Modals/PaymentTypeContent';
import AboutUs from '../../Menu/Modals/AboutUs';
import TermsOfUsePage from '../../Menu/TermsOfUsePage';

class Blocked extends Component {
  static propTypes = {
    banEndDate: PropTypes.string,
    banReason: PropTypes.string,
    initFBPay: PropTypes.func.isRequired,
    fbCallback: PropTypes.func.isRequired,
    initDraugiemPay: PropTypes.func.isRequired,
    initInboxPay: PropTypes.func.isRequired,
    initStripePay: PropTypes.func.isRequired,
    initEveryPay: PropTypes.func.isRequired,
    socProvider: PropTypes.string,
    toggleStripeLoading: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    screenMode: PropTypes.string,
    isFullscreen: PropTypes.bool,
    isFullscreenEnabled: PropTypes.bool,
    toggleFullscreen: PropTypes.func,
    fetchDiscounts: PropTypes.func.isRequired,
    discounts: PropTypes.arrayOf(PropTypes.shape({})),
  }

  static defaultProps = {
    banEndDate: null,
    banReason: null,
    socProvider: null,
    screenMode: constants.SCREEN_MODE.normal,
    discounts: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      supportModalOpen: false,
      payUnBanModalOpen: false,
      paymentErrorModal: false,
      showInboxPayment: false,
      inboxIframeLink: null,
      processing: false,
      selectedPaymentOption: constants.PAYMENT_TYPES.swedbank,
      selectedProduct: {},
      selectedProductId: 0,
      isOnline: navigator.onLine,
      checkedTerms: false,
      paymentError: '',
      aboutModalOpen: false,
      termsModalOpen: false,
      devMode: false,
    };

    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentDidMount() {
    const { fetchDiscounts } = this.props;
    const { hostname } = window.location;
    let devModeTemp = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devModeTemp = true;
      this.setState({
        devMode: devModeTemp,
      });
    }

    fetchDiscounts(devModeTemp);

    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  }

  handleOnline = () => {
    this.setState({ isOnline: true });
  }

  handleOffline = () => {
    this.setState({ isOnline: false });
  }

  toggleSupport = () => {
    const { setSupportAsRead, supportChatStatus } = this.props;
    const { supportModalOpen } = this.state;

    if (!supportModalOpen && supportChatStatus && !supportChatStatus.read) {
      setSupportAsRead();
    }

    this.playButtonSound();
    this.setState({ supportModalOpen: !supportModalOpen });
  };

  playButtonSound = () => {
    const { userSettings, uid } = this.props;

    if (userSettings && uid) {
      const userSettings2 = userSettings[uid] || userSettings.default || {};

      if (userSettings2 && userSettings2.soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
    }
  }
  toggleAboutModal = () => {
    this.setState(prevState => ({
      aboutModalOpen: !prevState.aboutModalOpen,
    }));
  }

  toggleTermsModal = () => {
    this.setState(prevState => ({
      termsModalOpen: !prevState.termsModalOpen,
    }));
  }

  togglePayUnBan = () => {
    this.setState(prevState => ({
      payUnBanModalOpen: !prevState.payUnBanModalOpen,
    }));
  }

  togglePaymentErrorModal = (paymentError) => {
    this.setState({
      paymentErrorModal: !this.state.paymentErrorModal,
      paymentError
    });
  }

  onPaymentOptionClick = (option) => {
    this.setState({
      selectedPaymentOption: option
    });
  }

  pay = () => {
    const { selectedPaymentOption, selectedProduct, selectedProductId, checkedTerms } = this.state;
    const { toggleStripeLoading, initEveryPay, t, i18n } = this.props;

    if (!checkedTerms) {
      this.togglePaymentErrorModal(t('paymentType.acceptTerms'));
    } else if (![constants.PAYMENT_TYPES.paypal.id, constants.PAYMENT_TYPES.textMessage.id].includes(selectedPaymentOption.id)) {
      toggleStripeLoading(true);

      initEveryPay(selectedProduct, selectedPaymentOption, selectedProductId, i18n.language).then((res) => {
        const { payment_link } = res.data;
        if (payment_link) {
          window.location.href = payment_link;
        }
      }).catch(error => {
        toggleStripeLoading(false);
        throw error;
      });
    } else {
      this.togglePaymentErrorModal(t('paymentType.notAvailable'));
    }
  }

  buyMoney = (prod) => {
    const {
      discounts, initFBPay, fbCallback, socProvider, initDraugiemPay, initInboxPay, toggleStripeLoading, isFullscreen, isFullscreenEnabled, toggleFullscreen,
    } = this.props;
    const { devMode } = this.state;

    if (config.isInAppFrame() && isFullscreenEnabled) {
      toggleFullscreen();
    }

    if (!config.isInAppFrame()) {
      this.playButtonSound();

      this.setState({ selectedProduct: constants.BUY_PAGE_PRODUCTS[prod], selectedProductId: prod });
      if (devMode) {
        const selectedProduct = { ...constants.BUY_PAGE_PRODUCTS[prod] };
        if (discounts && discounts[0] && discounts[0].type === constants.SPECIALPRICE && discounts[0].filterType === constants.CURRENT) {
          selectedProduct.realPrice = discounts[0].specialPrice[`money${prod}`];
          selectedProduct.discountType = constants.SPECIALPRICE;
        } else if (discounts && discounts[0] && discounts[0].type === constants.DISCOUNTPRICE && discounts[0].filterType === constants.CURRENT) {
          const productMoney = filter(constants.SHOP_PRODUCT_TYPES, (item) => { return item.id === prod; })[0];
          selectedProduct.discountType = constants.DISCOUNTPRICE;

          if (includes(discounts[0].selectedDiscountPackage, productMoney.label)) {
            selectedProduct.realPrice = parseFloat(productMoney.realPrice - ((productMoney.realPrice/100) * discounts[0].discountPrice)).toFixed(2)
          }
        } else if (discounts && discounts[0] && discounts[0].type === constants.BONUS && discounts[0].filterType === constants.CURRENT) {
          const productMoney = filter(constants.SHOP_PRODUCT_TYPES, (item) => { return item.id === prod; })[0];
          selectedProduct.discountType = constants.BONUS;

          if (includes(discounts[0].selectedDiscountPackage, productMoney.label)) {
            selectedProduct.realPrice = discounts[0].discountPrice;
          }
        }
       
        this.setState({ selectedProduct, selectedProductId: prod });
      }
      this.togglePayUnBan();
      // this.playButtonSound();

      // this.setState({ processing: true });
      // toggleStripeLoading(true);
      // const { initStripePay } = this.props;

      // //  const failTxt = t('menu.stripeShopNotWorking');

      // initStripePay(prod).then((result) => {
      //   if (result.data && result.data.data.status && result.data.data.status === 'success' && result.data.data.id) {
      //     const paymentSessionId = result.data.data.id;

      //     loadStripe(config.stripeBublicKey).then((stripe) => {
      //       stripe.redirectToCheckout({
      //         sessionId: paymentSessionId,
      //       })
      //         .then(() => {
      //         //  alert(failTxt);
      //           toggleStripeLoading(false);
      //           this.setState({ processing: false });
      //         });
      //     }).catch(() => {
      //     //  alert(failTxt);
      //       toggleStripeLoading(false);
      //       this.setState({ processing: false });
      //     });
      //   } else if (result.data.data && !result.data.data.status) {
      //   //  alert(failTxt);
      //     toggleStripeLoading(false);
      //     this.setState({ processing: false });
      //   } else if (result.data.data && result.data.data.status && result.data.data.status === 'error') {
      //   //  alert(failTxt);
      //     toggleStripeLoading(false);
      //     this.setState({ processing: false });
      //   }
      // }).catch((e) => {
      // //  alert(failTxt);
      //   console.log(e);
      //   toggleStripeLoading(false);
      //   this.setState({ processing: false });
      // });

      // return;
    }

    // In App Iframe (fb/dra)
    if (socProvider === 'facebook') {
      this.playButtonSound();

      initFBPay(prod).then((res) => {
        console.log(res.data.data);
        if (res.data && res.data.data && res.data.data.status === 'success') {
          const { product } = res.data.data;

          if (product) {
            if (window.FB && socProvider === 'facebook') {
              window.FB.ui({
                method: 'pay',
                action: 'purchaseitem',
                product,
                quantity: 1, // optional, defaults to 1
                request_id: res.data.data.token, // optional, must be unique for each payment
              },
              (resp) => {
                console.log('fb pay resp', { resp });
                fbCallback(resp);
                return null;
              });
            } else {
              console.log('no FB.ui or not facebook provider');
            }
          } else {
            console.log('error with product');
          }
        }
      });
    } else if (socProvider === 'draugiem') {
      this.playButtonSound();
      initDraugiemPay(prod).then((res) => {
        console.log('res');
        console.log(res.data.data);
        if (res.data && res.data.data && res.data.data.status === 'success') {
          const { product } = res.data.data;

          if (product) {
            if (window.draugiemWindowOpen && socProvider === 'draugiem') {
              window.draugiemWindowOpen(product, 350, 400);
            } else {
              console.log('no draugiemWindowOpen or not draugiem provider');
            }
          } else {
            console.log('error with product');
          }
        }
      });
    } else if (socProvider === 'inbox') {
      this.playButtonSound();
      initInboxPay(prod).then((res) => {
        console.log('res');
        console.log(res.data.data);
        if (res.data && res.data.data && res.data.data.status === 'success') {
          const { product } = res.data.data;

          console.log('product', product);

          if (product) {
            this.setState({ showInboxPayment: true, inboxIframeLink: product });
          } else {
            console.log('error with product');
          }
        }
      });
    }
  }

  toggleCheckedTerms = () => {
    this.setState(prevState => ({
      checkedTerms: !prevState.checkedTerms
    }));
  };

  closeInboxModal = () => {
    this.setState({ showInboxPayment: false, inboxIframeLink: null });
  }

  render = () => {
    const {
      t,
      banEndDate,
      banReason,
      screenMode,
      i18n,
      socProvider,
    //  resetClose,
    //  closeErrorSubmit,
    //  showNotification,
    } = this.props;

    const {
      aboutModalOpen,
      termsModalOpen,
      supportModalOpen,
      payUnBanModalOpen,
      paymentErrorModal,
      showInboxPayment,
      inboxIframeLink,
      selectedPaymentOption,
      selectedProduct,
      isOnline,
      checkedTerms,
      paymentError,
    } = this.state;

    console.log('socProvider', socProvider, showInboxPayment, inboxIframeLink);

    return (
      <Fragment>
        <Helmet>
          <title>
            Zole - {t('blocked.blockedTitle')}
          </title>
        </Helmet>
        <div className="loading-background" />
        {/* <div className="bannedScreen" style={{ marginTop: '18%', color: '#fff' }}>
          <Row>
            <Col md={{ size: 4, offset: 4 }}>
              <Row>
                <Col md="12">
                  <h2>
                    Jūs esat bloķēts
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  {'Līdz: '}
                  <CustomDate format="DD-MM-YYYY hh:mm" date={banEndDate} />
                </Col>
                <Col md="6">
                  {`Iemesls: ${banReason}`}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 4, offset: 4 }}>
              <div className="blocked-button-wrapper">
                <div className="blocked-button" onClick={this.toggleSupport}>
                  Sazināties ar administrāciju
                </div>
              </div>
            </Col>
            <Col md={{ size: 4, offset: 4 }}>
              <div className="blocked-button-wrapper">
                <div className="blocked-button" onClick={this.togglePayUnBan}>
                  {t('buyPage.payUnBan')}
                </div>
              </div>
            </Col>
          </Row>
        </div> */}
        <div className="new-design-login-container-body-content d-flex align-items-center login-container-vertical-adjustment">
          <div className={`new-design-login-container-body-content-section new-design-login-container-body-content-section-${screenMode}`}>
            <div className="new-design-login-container-body-content-section-title d-flex align-items-center mb-4" >
              <img src={logoImg} alt="x" />
            </div>
            <div className={`new-design-login-container-body-content-section-form new-design-login-container-body-content-section-form-${screenMode}`} style={/*{background: 'rgba(20,34,85,.95)'}*/{}}>
              <>
                <div className="new-design-login-container-body-content-section-form-with">
                  <div className="title mb-2">
                    
                  </div>
                  <div className="social">
                    <Label>{t('blocked.blocked')}</Label>
                  </div>
                </div>

                <Row className={`new-design-login-container-body-content-section-form-input new-design-login-container-body-content-section-form-input-${screenMode}`}>
                  <Col md="6">
                    {t('blocked.blockedUntil')}
                    <CustomDate format="DD-MM-YYYY hh:mm" date={banEndDate} />
                  </Col>
                  <Col md="6">
                    {`${t('blocked.reason')} ${banReason}`}
                  </Col>
                  <Col md="12">
                    <Row>
                      <Col sm="12" className="text-left login-section">
                        <Button className="layout-highlighted-button top-settings-new-section-login-button login-section-button d-flex align-items-center" style={{width: 'auto'}} onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money9)}>
                          {t('buyPage.payUnBan')}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className={`new-design-login-container-body-content-section-form-footer new-design-login-container-body-content-section-form-footer-${screenMode}`}>
                  <div className="section">
                    <Button className="footer-section-button" style={{width: '350px'}} onClick={this.toggleSupport}>{t('blocked.contactAdmin')}</Button>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
        <ContactSupport
          modalOpen={supportModalOpen}
          toggle={this.toggleSupport}
          screenMode={screenMode}
        //  uid={uid}
        //  name={name}
        //  supportChat={supportChat}
        //  supportChatStatus={supportChatStatus}
        //  resetClose={resetClose}
        //  closeErrorSubmit={closeErrorSubmit}
        //  showNotification={showNotification}
        //  sendSupportMessage={sendSupportMessage}
        //  setSupportAsRead={setSupportAsRead}
        />
        <CustomModal
          isOpen={aboutModalOpen}
          toggle={this.toggleAboutModal}
          size="lg"
          title={t('menu.aboutPage')}
          addClassName="about-us-modal-wrapper"
          body={(
            <AboutUs i18n={i18n} t={t} toggle={this.toggleAboutModal} />
          )}
        />

        <CustomModal
          isOpen={termsModalOpen}
          toggle={this.toggleTermsModal}
          size="lg"
          title={t('home.termsOfUse')}
          body={(
            <TermsOfUsePage
              playButtonSound={this.playButtonSound}
              toggleHelp={this.toggleTermsModal}
              cookiesFrom={''}
            />
          )}
          footer={(
            <Button color="secondary" onClick={this.toggleTermsModal}>
              {t('common.close')}
            </Button>
          )}
        />

        <CustomModal
          isOpen={payUnBanModalOpen}
          toggle={this.togglePayUnBan}
          size="lg"
          title={t('paymentType.chooseTitle')}
          addClassName={"payment-type-content-modal"}
          body={
            <PaymentTypeContent
              toggleAboutUs={this.toggleAboutModal}
              toggleTerms={this.toggleTermsModal}
              selectedProduct={selectedProduct}
              selectedPaymentOption={selectedPaymentOption}
              setSelectedPaymentOption={this.onPaymentOptionClick}
              checkedTerms={checkedTerms}
              toggleCheckedTerms={this.toggleCheckedTerms}
              t={t}
            />
          }
          footer={
            <>
              <Button onClick={this.pay} disabled={!isOnline}>
                {t('common.continue')}
              </Button>
              <hr className={`payment-type-line-${screenMode}`} />
              <p className={`payment-type-footnote-text-${screenMode}`}>SIA 'Draxo Games'</p>
              <p className={`payment-type-footnote-text-${screenMode}`}>Liepājas 49-6, Kuldīga</p>
              <p className={`payment-type-footnote-text-${screenMode}`}>Latvija</p>
            </>
          }
        />

        <CustomModal
          isOpen={paymentErrorModal}
          toggle={() => this.togglePaymentErrorModal('')}
          size="md"
          verticalCentering
          largeText
          title={t('common.zoleInfo')}
          body={
            <p className="text-center">{paymentError}</p>
          }
          footer={
            <Button onClick={() => this.togglePaymentErrorModal('')}>
              {t('common.ok')}
            </Button>
          }
        />

        {socProvider && socProvider === 'inbox' ? (
          <Modal container={'div > div > div'} returnFocusAfterClose={false} isOpen={showInboxPayment} size="lg" className="inbox-payment-modal" toggle={this.closeInboxModal}>
            <ModalHeader
              toggle={this.closeInboxModal}
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeInboxModal} />
                }
            />
            <ModalBody>
              <Iframe
                url={inboxIframeLink}
                width="100%"
                height="600px"
                id="inboxPaymentIframe"
                className="inboxPaymentIframe"
                display="initial"
                position="relative"
              />
            </ModalBody>
          </Modal>
        ) : (null)}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  banEndDate: state.member.banEndDate || null,
  banReason: state.member.banReason || null,
  socProvider: state.member.socProvider,
  discounts: state.shop.discounts,
});

const mapDispatchToProps = {
//  sendSupportMsg: sendSupportMessage,
  initFBPay: initFBPayment,
  fbCallback: fbPaymentCallback,
  initDraugiemPay: initDraugiemPayment,
  initInboxPay: initInboxPayment,
  initStripePay: initStripePayment,
  initEveryPay: initEveryPayPayment,
  fetchDiscounts: getDiscounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Blocked));
