import React from 'react';
import PropTypes from 'prop-types';

import Media from 'reactstrap/lib/Media';

import img from '../../../../../../images/Rules/WhatIsZole.webp';

const WhatIsTab = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
          Zole ir Latvijā populāra kāršu spēle, ko citviet pazīst ar nosaukumu 'revelīts'. Parasti to spēlē trīs cilvēki, taču ir arī variācijas diviem (studentu zole), kā arī vairāk nekā trim cilvēkiem. Zole jeb zolīte latviešu tautas aprindās tiek uzskatīta par latviešu kāršu spēli, savukārt zoles spēlētāju vidū to dēvē pat par latviešu nacionālo kāršu spēli.
        </p>
        <p>
          Kā revelīts šī kāršu spēle ir minēta Emanuela Laskera 1931.gadā izdotajā grāmatā 'Kāršu spēles'. Precīzs laiks, kad zoli sāka spēlēt Latvijā, nav īsti zināms, tomēr ir skaidrs, ka zole pieder pie vācu kāršu spēļu „Schafkopf” grupas (spēlēm šajā grupā ir noteikti trumpji – visi kalpi vai visas dāmas, vai dažkārt abi kopā – kas ir stiprāki par pārējiem trumpjiem).
        </p>
        <p>
          Kā Andris Kolbergs raksta savā grāmatā 'Zolīte Latvijā un pasaulē': 'Pirmie apraksti par šo spēli Vācijā atrodami ap 19.gs vidu. Zolīte pieskaitāma tā sauktajam 'endischer Schafskopf' variantam, speciāli 'Drei endsch' (normālo 'Schafskopf' spēlē 4, bet 'Drei endsch' tikai trīs spēlmaņi). Zolīte ir kaut kas starp normālo 'Drei endsch', kuru spēlē ar 32 kārtīm, un 'aso Drei endsch', kur spēlē tikai 24 kārtis. Zolīti spēlē ar 26 kārtīm. Izskatīju veselu rindu vācu kāršu spēļu grāmatu, bet nevienā neatradu pie 'Schafskopf' tādu variantu, kādu spēlējam mēs latvieši. Jāsecina, ka zolīte ir speciāls 'Schafskopf' Baltijas variants. Latviešu apzīmējums zolīte būs attīstījies no vārda solo, jo vienmēr viens spēlē pret diviem.'
        </p>
        <Media src={img} alt="Kas ir Zole" style={{ width: '60%' }} />
      </>
    );
  } if (lang === 'ru') {
    return (
      <>
        <p>
          Золе популярная в Латвии Положить карту, которая еще известна под названием «ревелит». Обычно в ней участвуют три игрока, но есть вариации и с двумя игроками (студенческая золе), а так же могут играть и более трех участников. Золе или золите в Латвии считается латышской карточной игрой, а в кругах игроков золе ее нарекли даже латышской национальной карточной игрой.
        </p>
        <p>
          Эмануэл Ласкер в 1931 году изданной книге «Карточные игры»  говорит об этой карточной игре, называя ее ревелитом. Нет достоверных данных о том, когда в золе начали играть в Латвии, но одно несомненно, что золе пренадлежит к группе немецких карточных игр „Schafkopf” (в играх этой группы козыри — все валеты или все дамы, а иногда и оба вместе — они сильнее всех остальных козырей).
        </p>
        <p>
          Андрис Колбергс в своей книге «Золите в Латвии и мире» пишет следующее: “ Первые упоминания об этой игре в Германии датируются серединой 19-ого столетия. Золите принадлежит к так называемому 'endischer Schafskopf' варианту,специально 'Drei endsch' (обычно 'Schafskopf' играют 4, а 'Drei endsch' только три игрока). Золите это что-то между нормальным 'Drei endsch', которую играют с 32 картами, и 'острой Drei endsch', которую играют только с 24 картами.В золите играют с 26 картами. Просмотрел множество книг о немецких карточных играх, но не в одной при 'Schafskopf' не нашел такого варианта, какой играем мы латыши. Из этого можно сделать вывод, что золите является специальным Балтийским вариантом 'Schafskopf'. Латышское слово золите возможно происходит от слова соло, так как в этой игре один всегда играет против двоих'.
        </p>
        <Media src={img} alt="Kas ir Zole" style={{ width: '60%' }} />
      </>
    );
  } if (lang === 'en') {
    return (
      <>
        <p>
          “Zole” is popular is a Latvian trick-taking card game, which sometimes is also know by name “revelits”. Usually, there are 3 players, yet there are variations for 2 or more than 3 players. “Zole” is considered a Latvian card game, among the players, it is even called a Latvian national card game.
        </p>
        <p>
          As “revelite” this card game was firstly mentioned in 1931, in the book of Emanuel Lasker. The exact time when this card game began to be played in Latvia is not really known, game belongs to the Schafkopf group of Ace-Ten games, i.e. Jacks and Queens are permanent trumps in the game.
        </p>
        <p>
          Andris Kolbergs in his book “Zole in Latvia and around the world” writes: “First descriptions about this game can be found around 19th century, this game belongs to so-called “'endischer Schafkopf” version “Drei endsch”. Usually, “Schafskopf” is played by 4 players, while “Drei endsch” is played by three players. Card game “Zole” is something between the standard “Drei endsch”, which is played with 32 cards, and “aso Drei endsch”, which is played only with 24 cards. “Zole” is played with 26 cards. I have checked at a whole series of German card game books, but in none of them, I found a variant of 'Schafskopf' that we Latvians play. Thus, it can be considered that “Zolīte” is a special Baltic version of this card game. The Latvian name "Zole" may be evolved from the word solo, because there is always one playing against two.
        </p>
        <Media src={img} alt="What is Zole" style={{ width: '60%' }} />
      </>
    );
  }
};

WhatIsTab.propTypes = {
  lang: PropTypes.string,
};

WhatIsTab.defaultProps = {
  lang: 'lv',
};

export default WhatIsTab;
