import React from 'react';
import PropTypes from 'prop-types';

const WhatAreGameCoins = ({ lang }) => {
  if (lang === 'lv') {
    return (
      <>
        <p>
        Piereģistrējoties spēlē, katram spēlētājam sākotnēji tiek piešķirtas 500 Zoles monētas. Spēles monētas ir nepieciešamas, lai izveidotu istabu vai pieslēgtos kādai esošai istabai.
        </p>
        <p>
        Spēlēt iespējams tik ilgi kamēr spēlētāja virtuālajā kontā ir pietiekami daudz spēles monētu. Ja virtuālā nauda ir beigusies, to iespējams papildus iegādāties sadaļā ‘Zoles Veikals’. Virtuālā nauda jeb Zoles monētas ir paredzēta tikai tehniskai spēles nodrošināšanai, tā nevar nekādā veidā tikt izmaksāta, apmainīta vai aizstāta ar reālu naudu vai cita veida materiālajām vērtībām, vai balvām.
        </p>
      </>
    )
  } else if (lang === 'ru') {
    return (
      <>
        <p>
        Регистрируясь на игру, каждому игроку изначально даются 500 монет Золе. Монеты Золе необходимы для того, чтобы создать комнату или присоедениться к уже действующей комнате.
        </p>
        <p>
        Играть можно до тех пор, пока в виртуальном конте игрока достаточно игровых монет. Если виртуальные деньги кончились, то  их можно приобресьти в разделе «Магазин Золе». Виртуальные деньги или монеты Золе предназначены только для технического обеспечения игры, они не выплачиваются, не меняются на реальные деньги или другие материальные ценности или призы.
        </p>
      </>
    )
  } else if (lang === 'en') {
    return (
      <>
        <p>
        When registering for the game, each player is initially awarded 500 "Zole" coins. Game coins are needed to create a separate room or join in an existing room.
        </p>
        <p>
        The game continues as long as there are enough game coins in the player's virtual account. If the virtual money has run out, it can be purchased in the ‘Zole Shop’. Virtual cash or "Zole" coins are intended only for the technical provision of the game, coins cannot be paid-out, exchanged, or replaced in any way with real money or other material values or prizes.
        </p>
      </>
    )
  }
};

WhatAreGameCoins.propTypes = {
  lang: PropTypes.string,
};

WhatAreGameCoins.defaultProps = {
  lang: 'lv',
};

export default WhatAreGameCoins;
