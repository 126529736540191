import React from 'react';
import PropTypes from 'prop-types';

// import { withTranslation } from 'react-i18next';

import { useTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';

import CustomModal from '../../../Components/Components/Modal';
import { tournamentTutorialText } from '../../../../../../constants/tournamentTutorial';
import config from '../../../../../../constants/config';

const TournamentTutorial = React.memo(({ showTournamentTutorial, toggleShowTournamentTutorial }) => {
  const { i18n, t } = useTranslation('common');
  const isInAppFrame = config.isInAppFrame();
  return (
    <>
      <CustomModal
        isOpen={showTournamentTutorial}
        toggle={toggleShowTournamentTutorial}
        size="lg"
        addClassName="tournaments-tutorial-modal"
        title={t('tournaments.tutorial')}
        footer={<Button color="link" className="modal-footer-button" onClick={toggleShowTournamentTutorial}>{t('tournaments.close')}</Button>}
        body={(
          <Row>
            <Col>
              <div
                className={`tournaments-tutorial-table-scrollarea-new tournaments-tutorial-table-body`}
                show={false}
                rightOffset={12}
                topOffset={0}
                bottomOffset={0}
              >
                <div>
                  <div dangerouslySetInnerHTML={{ __html: i18n.language == 'lv' ? tournamentTutorialText.lv : i18n.language == 'en' ? tournamentTutorialText.en : i18n.language == 'ru' ? tournamentTutorialText.ru : tournamentTutorialText.lv}}/>
                </div>
              </div>
            </Col>
          </Row>
    )}
      />

    </>
  );
});

TournamentTutorial.propTypes = {
  showTournamentTutorial: PropTypes.bool,
  toggleShowTournamentTutorial: PropTypes.func.isRequired,
};

TournamentTutorial.defaultProps = {
  showTournamentTutorial: false,
};

export default TournamentTutorial;
