import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import classNames from 'classnames';

import isEqual from 'react-fast-compare';

import myTurnSound from '../../../sounds/my_turn4.mp3';
import dealingCardsSound from '../../../sounds/dealing_card.mp3';

import PlayerCard from './PlayerCard';

const erci = ['♥-9', '♥-K', '♥-10', '♥-A'];
const kreisti = ['♣︎-9', '♣︎-K', '♣︎-10', '♣︎-A'];
const piki = ['♠︎-9', '♠︎-K', '♠︎-10', '♠︎-A'];
const trumpji = ['♦︎-7', '♦︎-8', '♦︎-9', '♦︎-K', '♦︎-10', '♦︎-A', '♦︎-J', '♥-J', '♠︎-J', '♣︎-J', '♦︎-Q', '♥-Q', '♠︎-Q', '♣︎-Q'];

const cardOrder = ['♥-9', '♥-K', '♥-10', '♥-A', '♠︎-9', '♠︎-K', '♠︎-10', '♠︎-A', '♣︎-9', '♣︎-K', '♣︎-10', '♣︎-A', '♦︎-7', '♦︎-8', '♦︎-9', '♦︎-K', '♦︎-10', '♦︎-A', '♦︎-J', '♥-J', '♠︎-J', '♣︎-J', '♦︎-Q', '♥-Q', '♠︎-Q', '♣︎-Q'];

let counter = 0;

class PlayerCards extends React.Component {
  static propTypes = {
    cards: PropTypes.arrayOf(PropTypes.string),
    gameState: PropTypes.string,
    playCard: PropTypes.func.isRequired,
    currentTurnUid: PropTypes.string,
    memberUid: PropTypes.string,
    currentTable: PropTypes.arrayOf(PropTypes.shape()),
    selectedCard: PropTypes.string,
    doubleClickSelectedCard: PropTypes.shape(),
    isWebVersion: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    currentTurn: PropTypes.string,
    soundOn: PropTypes.bool,
    fourPRoom: PropTypes.bool,
    myPos: PropTypes.string,
    tableIsInProgress: PropTypes.bool.isRequired,
    cardsOnTable: PropTypes.arrayOf(PropTypes.string),
    currentType: PropTypes.string,
    largePlayer: PropTypes.string,
    cardsOnLoading: PropTypes.bool,
  }

  static defaultProps = {
    cards: [],
    gameState: null,
    currentTurnUid: null,
    memberUid: null,
    currentTable: null,
    isWebVersion: false,
    isFullscreen: false,
    selectedCard: null,
    doubleClickSelectedCard: null,
    currentTurn: null,
    soundOn: false,
    fourPRoom: false,
    myPos: null,
    cardsOnTable: [],
    currentType: null,
    largePlayer: null,
    cardsOnLoading: true,
  }

  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      playSound: false,
      hasPlayedCard: false,
    };

    this.myTurnAudio = new Audio(myTurnSound);
    this.dealingCardsAudio = new Audio(dealingCardsSound);

    window.addEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    const {
      cards,
      currentTable,
      memberUid,
      currentTurnUid,
    } = this.props;

    this.handleResize();

    const cardsClone = [...cards];

    cardsClone.sort((a, b) => cardOrder.indexOf(b) - cardOrder.indexOf(a));

    if (currentTurnUid && memberUid
      && currentTurnUid.toString() === memberUid.toString()) {
      console.log('cards test 1-1');
      if (currentTable && currentTable.length === 0) {
        console.log('cards test 1-2');
        const mappedCards = cardsClone.map(card => ({ card, allowed: true }));

        this.setState({ cards: [...mappedCards] });
      } else {
        console.log('cards test 1-3');
        const firstCard = currentTable[0];

        let playerHasCards = [];

        if (firstCard.card && kreisti.indexOf(firstCard.card) !== -1) {
          playerHasCards = cardsClone.filter(value => kreisti.includes(value));
        } else if (firstCard.card && erci.indexOf(firstCard.card) !== -1) {
          playerHasCards = cardsClone.filter(value => erci.includes(value));
        } else if (firstCard.card && piki.indexOf(firstCard.card) !== -1) {
          playerHasCards = cardsClone.filter(value => piki.includes(value));
        } else if (firstCard.card && trumpji.indexOf(firstCard.card) !== -1) {
          playerHasCards = cardsClone.filter(value => trumpji.includes(value));
        }

        console.log('cards test 1-4', playerHasCards);

        const mappedCards = cardsClone.map((card) => {
          if (playerHasCards && playerHasCards.length === 0) {
            return { card, allowed: true };
          }
          if (playerHasCards && playerHasCards.length > 0 && playerHasCards.includes(card)) {
            return { card, allowed: true };
          }
          return { card, allowed: false };
        });

        console.log('cards test 1-5', mappedCards);

        this.setState({ cards: [...mappedCards] });
      }
    } else {
      console.log('cards test 2-1');
      const mappedCards = cardsClone.map(card => ({ card, allowed: false }));

      console.log('cards test 2-2', mappedCards);

      this.setState({ cards: [...mappedCards] });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // const {
    //  cards,
    //  currentTurnUid,
    //  currentTable,
    //  gameState,
    //  selectedCard,
    //  tableIsInProgress,
    //  memberUid,
  //  } = nextProps;

    //  const {
    //  cards: curCardsProps,
    //  currentTurnUid: curCurrentTurnUid,
    //  currentTable: curCurrentTable,
    //  gameState: curGameState,
    //  selectedCard: curSelectedCard,
    //  tableIsInProgress: curTableIsInProgress,
    //  memberUid: curMemberUid,
    //  } = this.props;

    //  const { cards: curCards } = this.state;
    //  const { cards: nextCards } = nextState;

    const {
      tableIsInProgress,
      selectedCard,
      gameState,
      currentTurnUid,
      memberUid,
      currentTable,
      cards,
      isFullscreen,
    } = this.props;

    const {
      hoveredCard,
      cards: stateCards,
      width,
      height,
    } = this.state;

    if (nextProps.tableIsInProgress !== tableIsInProgress) {
      return true;
    }

    if (nextProps.selectedCard !== selectedCard) {
      return true;
    }

    //  if (cards.length !== curCards.length) {
    //    return true;
    //  }

    if (nextProps.gameState !== gameState) {
      return true;
    }

    if (nextProps.currentTurnUid !== currentTurnUid) {
      return true;
    }

    if (nextProps.memberUid !== memberUid) {
      return true;
    }

    //  if (currentTable !== curCurrentTable) {
    //    return true;
    //  }

    if (!isEqual(nextProps.currentTable, currentTable)) {
      return true;
    }

    //  if (JSON.stringify(curCards) !== JSON.stringify(nextCards)) {
    //    return true;
    //  }

    //  if (JSON.stringify(cards) !== JSON.stringify(curCardsProps)) {
    //    return true;
    //  }

    if (!isEqual(nextState.cards, stateCards)) {
      return true;
    }

    if (!isEqual(nextState.hoveredCard, hoveredCard)) {
      return true;
    }

    if (!isEqual(nextProps.cards, cards)) {
      return true;
    }

    if (nextState.width !== width) {
      return true;
    }

    if (nextState.height !== height) {
      return true;
    }

    if (nextProps.isFullscreen !== isFullscreen) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const {
      cards,
      currentTurnUid,
      memberUid,
      currentTable,
      currentTurn,
      soundOn,
      tableIsInProgress,
      gameState,
      //  cardPlayClicked,
      myPos,
      isFullscreen,
    } = this.props;

    const {
      playSound, hasPlayedCard, width, height, cards: prevCards,
    } = this.state;

    if (!width || !height || isFullscreen !== prevProps.isFullscreen) {
      this.handleResize();
    }

    const turnChanged = prevProps.currentTurn !== currentTurn;

    const cardsClone = [...cards];

    cardsClone.sort((a, b) => cardOrder.indexOf(b) - cardOrder.indexOf(a));

    if (soundOn && playSound && !tableIsInProgress) {
      this.setState({ playSound: false });

      setTimeout(() => {
        console.log('play sound');
        const playPromise = this.myTurnAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }, 350);
    }

    if (soundOn && ((cards && cards.length === 8 && gameState === 'results' && prevProps.gameState !== gameState) || (cards && cards.length === 8 && cards.length !== prevProps.cards.length && gameState === 'results'))) {
      try {
        const playPromise = this.dealingCardsAudio.play();
         if (playPromise !== undefined) {
           playPromise
             .then(_ => {
               // Automatic playback started!
               // Show playing UI.
               console.log("audio played auto 2");
             })
             .catch(error => {
               // Auto-play was prevented
               // Show paused UI.
               console.log("playback prevented 2");
             });
         }
       } catch (err) {
         console.log(err);
       }
    }

    let dataToUpdate = null;

    if (currentTurnUid && memberUid
      && currentTurnUid.toString() === memberUid.toString()) {
      if (currentTable && currentTable.length === 0) {
        const mappedCards = cardsClone.map(card => ({ card, allowed: true }));

        if (turnChanged && gameState === 'play') {
          dataToUpdate = { cards: [...mappedCards], playSound: true, hasPlayedCard: false };
        //  this.setState({ cards: [...mappedCards], playSound: true, hasPlayedCard: false });
        } else {
          dataToUpdate = { cards: [...mappedCards], hasPlayedCard: false };
        //  this.setState({ cards: [...mappedCards], hasPlayedCard: false });
        }
        //  } else if (cardPlayClicked) {
        //    const mappedCards = cardsClone.map(card => ({ card, allowed: true }));

        //    this.setState({ cards: [ ...mappedCards ] });
      } else if (currentTable) {
        const firstCard = currentTable[0];

        const lastCard = currentTable[currentTable.length - 1];

        if (lastCard && lastCard.player && lastCard.player === myPos) {
          const mappedCards = cardsClone.map(card => ({ card, allowed: false }));

          dataToUpdate = { cards: [...mappedCards], hasPlayedCard: true };
        //  this.setState({ cards: [...mappedCards], hasPlayedCard: true });
        } else if (firstCard) {
          let playerHasCards = [];

          if (firstCard && firstCard.card) {
            if (kreisti.indexOf(firstCard.card) !== -1) {
              playerHasCards = cardsClone.filter(value => kreisti.includes(value));
            } else if (erci.indexOf(firstCard.card) !== -1) {
              playerHasCards = cardsClone.filter(value => erci.includes(value));
            } else if (piki.indexOf(firstCard.card) !== -1) {
              playerHasCards = cardsClone.filter(value => piki.includes(value));
            } else if (firstCard.card && trumpji.indexOf(firstCard.card) !== -1) {
              playerHasCards = cardsClone.filter(value => trumpji.includes(value));
            }
          }

          const mappedCards = cardsClone.map((card) => {
            if (playerHasCards && playerHasCards.length === 0) {
              return { card, allowed: true };
            }
            if (playerHasCards && playerHasCards.length > 0 && playerHasCards.includes(card)) {
              return { card, allowed: true };
            }
            return { card, allowed: false };
          });

          if (turnChanged && gameState === 'play') {
            dataToUpdate = { cards: [...mappedCards], playSound: true, hasPlayedCard: false };
          //  this.setState({ cards: [...mappedCards], playSound: true, hasPlayedCard: false });
          } else {
            dataToUpdate = { cards: [...mappedCards], hasPlayedCard: false };
          //  this.setState({ cards: [...mappedCards], hasPlayedCard: false });
          }
        } else {
          const mappedCards = cardsClone.map(card => ({ card, allowed: true }));

          if (turnChanged && gameState === 'play') {
            dataToUpdate = { cards: [...mappedCards], playSound: true, hasPlayedCard: false };
          //  this.setState({ cards: [...mappedCards], playSound: true, hasPlayedCard: false });
          } else {
            dataToUpdate = { cards: [...mappedCards], hasPlayedCard: false };
          //  this.setState({ cards: [...mappedCards], hasPlayedCard: false });
          }
        }
      }
    } else {
      const mappedCards = cardsClone.map(card => ({ card, allowed: false }));

      dataToUpdate = { cards: [...mappedCards], hasPlayedCard: false };
    //  this.setState({ cards: [...mappedCards], hasPlayedCard: false });
    }

    if (dataToUpdate) {
      if (isEqual(dataToUpdate.cards, prevCards)) {
      //  console.log('prevCards are equal dont update cards', { cards: dataToUpdate.cards, prevCards });
        delete dataToUpdate.cards;

        if (hasPlayedCard === dataToUpdate.hasPlayedCard) {
          delete dataToUpdate.hasPlayedCard;
        }

        //  console.log('setState 1', { dataToUpdate });
        this.setState(dataToUpdate);
      } else {
      //  console.log('setState 2', { dataToUpdate });

        if (dataToUpdate.cards && prevCards && (dataToUpdate.cards.length || dataToUpdate.cards.length === 0) && prevCards.length && dataToUpdate.cards.length === prevCards.length - 1) {
        //  console.log('cards updated with one removed');
          setTimeout(() => {
            this.setState(dataToUpdate);
          }, 300);
        } else {
          this.setState(dataToUpdate);
        }

      //  this.setState(dataToUpdate);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const { width, height } = this.state;

    const { innerWidth: newWidth, innerHeight: newHeight } = window;

    if (!width || width - 10 > newWidth || width + 10 < newWidth) {
      this.setState({ width: newWidth, height: newHeight });
    } else if (!height || height - 10 > newHeight || height + 10 < newHeight) {
      this.setState({ width: newWidth, height: newHeight });
    }
  }

  changeHoveredCard = (index, card, type) => {
    const { gameState } = this.props;

    if (card.allowed && type === 'over' && gameState !== 'choose') {
      this.setState({ hoveredCard: index });
    } else {
      this.setState({ hoveredCard: null });
    }
  }

  /*
  renderCards = (card, index) => {
    const {
      cards, gameState, playCard, selectedCard, tableIsInProgress, memberUid, currentTurnUid, isFullscreen, isWebVersion, fourPRoom, doubleClickSelectedCard,
    } = this.props;
    const {
      hasPlayedCard, hoveredCard, width, height,
    } = this.state;

    let startIndex = 0;
    if (cards.length <= 1) {
      startIndex = 5;
    } else if (cards.length <= 3) {
      startIndex = 4;
    } else if (cards.length <= 5) {
      startIndex = 3;
    } else if (cards.length <= 7) {
      startIndex = 2;
    } else if (cards.length <= 9) {
      startIndex = 1;
    }

    let hoveredClass = '';
    if (card.allowed && currentTurnUid === memberUid) {
      if (hoveredCard === index) {
        hoveredClass = 'hovered';
      } else if (hoveredCard === index + 1 || hoveredCard === index - 1) {
        hoveredClass = 'hoveredNeighbour';
      }
    }

    //  if (index === 2) {
    //    hoveredClass = 'hoveredNeighbour';
    //  }

    let dynamicStyle = {};
    let dynamicStyleOverlay = {};

    let cardGap = 116 / 5;

    if (isFullscreen || isWebVersion) {
      if (width > height) {
        // LANDSCAPE MODE
        let cardWidth = width * 0.1;

        //  if (fourPRoom) {
        //    cardWidth = width * 0.25;
        //  }

        let cardHeight = cardWidth * 1.5;

        //  console.log('cardHeight landscape', { cardHeight, height, cardWidth, width });

        cardGap = cardWidth / 2;
        let cardHeightOffset = 0;

        if (cardGap < 45) {
          cardGap = 45;
        } else if (cardGap > 70) {
          cardGap = 70;
        }

        let dynamicHeight = false;

        cardHeightOffset = cardHeight - (cardHeight * 1.25);

        if (height < 650) {
          dynamicHeight = true;
        }

        if (isFullscreen && ((height < 650 && cardHeight > height * 0.08) || (height > 650 && height < 850 && cardHeight > height * 0.13))) {
        //  console.log('test1');
          cardHeight = height * 0.08;

          if (height > 650) {
            cardHeight = height * 0.13;
          }

          if (cardHeight < 96) {
            cardHeight = 96;
          }
          cardWidth = cardHeight / 1.5;

          cardGap = cardWidth / 2;

          cardHeightOffset = cardHeight - (cardHeight * 1.25);

          dynamicHeight = true;

        //  console.log('change card height landscape', { cardHeight, cardWidth });
        } else if (isFullscreen && height > 850 && height < 1000 && cardHeight > height * 0.13) {
        // console.log('test2');
          cardHeight = height * 0.13;

          if (cardHeight < 96) {
            cardHeight = 96;
          }
          cardWidth = cardHeight / 1.5;
          cardGap = cardWidth / 2;

          cardHeightOffset = cardHeight - (cardHeight * 1.25);

          dynamicHeight = true;

        //  console.log('change card height landscape', { cardHeight, cardWidth });
        } else if (!isFullscreen && isWebVersion && height < 1000 && cardHeight > height * 0.13) {
        //  console.log('test2');
          cardHeight = height * 0.18;

          if (cardHeight < 110) {
            cardHeight = 110;
          }
          cardWidth = cardHeight / 1.5;
          cardGap = cardWidth / 2;

          cardHeightOffset = cardHeight - (cardHeight * 1.25);

          dynamicHeight = true;

        //  console.log('change card height landscape', { cardHeight, cardWidth });
        } else if (cardWidth < 116) {
        //  console.log('test3');
          cardWidth = 116;
          cardHeight = 116 * 1.5;

          cardHeightOffset = cardHeight - (cardHeight * 1.25);
        //  cardHeightOffset = 0;
        } else if (cardWidth > 150) {
          cardWidth = 150;
          cardHeight = 150 * 1.5;

          cardHeightOffset = cardHeight - (cardHeight * 1.25);
        }

        //  console.log('cardHeightOffset', { cardHeightOffset });

        if (!dynamicHeight && cardHeightOffset < 65 && cardHeightOffset > 0) {
          // if small ofsset gradualy lower removed amount
          cardHeightOffset -= ((65 - cardHeightOffset) * 2);
        }

        if (hoveredClass === 'hovered') {
          cardHeightOffset += 34;
          if (fourPRoom) {
            cardWidth *= 0.80;
            cardHeight *= 0.80;
          }
        } else if (hoveredClass === 'hoveredNeighbour') {
          cardHeightOffset += 20;
          if (fourPRoom) {
            cardWidth *= 0.90;
            cardHeight *= 0.90;
          }
        }
        let left = ((index + (startIndex / 2) - 1) * cardGap) + 23;

        if (cards.length <= 1) {
          left = ((index + 4) * cardGap) + 23;
        } else if (cards.length <= 3) {
          left = ((index + 3) * cardGap) + 23;
        } else if (cards.length <= 5) {
          left = ((index + 2) * cardGap) + 23;
        } else if (cards.length <= 7) {
          left = ((index + 1) * cardGap) + 23;
        } else if (cards.length <= 9) {
          left = ((index) * cardGap) + 23;
        }

        if (index + startIndex === 5) {
          left = cardGap / 2;
        } else if (index + startIndex === 4) {
          left = -cardGap / 2;
        } else if (index + startIndex > 5) {
          left = ((index + startIndex - 5) * cardGap) + cardGap / 2;
        } else if (index + startIndex < 4) {
          left = ((index + startIndex - 4) * cardGap) - cardGap / 2;
        }

        let marginTop = 60 - cardHeightOffset;

        if (cardHeightOffset < 0) {
          marginTop = 60 + cardHeightOffset;
        }

        //  console.log('marginTop', { marginTop });

        if (height < 640) {
          marginTop = -30;
        } else if (height < 700) {
          marginTop = -20;
        } else if (height < 760) {
          marginTop = -10;
        } else if (dynamicHeight && marginTop > 100) {
          marginTop = 80 - (marginTop * 0.1);
        } else if (dynamicHeight && marginTop > 50) {
          marginTop = 40 - (marginTop * 0.05);
        } else if (dynamicHeight && marginTop > 25) {
          marginTop = 20;
        } else if (dynamicHeight && marginTop > 20) {
          marginTop = 10;
        }

        //  console.log('data landscape', { cardHeightOffset, marginTop });

        //  dynamicStyle = { marginTop: 60 - cardHeightOffset, left: left,  width: `${cardWidth}px !important`, height: `${cardHeight}px !important`, backgroundSize: `${cardWidth}px ${cardHeight}px !important` };

        dynamicStyle = {
          left, width: cardWidth, height: cardHeight, backgroundSize: `${cardWidth}px ${cardHeight}px`,
        };
        dynamicStyleOverlay = { width: cardWidth - 6, height: cardHeight - 6 };
      } else {
        // PORTRAIT MODE

        let cardWidth = width * 0.1;
        let cardHeight = cardWidth * 1.5;

        //  console.log('cardHeight portrait', { cardHeight, height, cardWidth, width });

        cardGap = cardWidth / 2;
        let cardHeightOffset = 0;

        if (cardGap < 35) {
          cardGap = 35;
        } else if (cardGap > 60) {
          cardGap = 60;
        }

        let dynamicHeight = false;

        cardHeightOffset = cardHeight - (116 * 1.25);

        if (width < 800 && cardHeight > width * 0.13) {
          cardHeight = height * 0.15;
          cardWidth = cardHeight / 1.5;

          cardGap = cardWidth / 2;

          cardHeightOffset = cardHeight - (116 * 1.25);

          dynamicHeight = true;
        } else if (width < 1000 && cardHeight > width * 0.13) {
          cardHeight = height * 0.14;

          if (height > 900) {
            cardHeight = height * 0.17;
          }

          cardWidth = cardHeight / 1.5;

          cardGap = cardWidth / 2;

          cardHeightOffset = cardHeight - (116 * 1.25);

          dynamicHeight = true;
        } else if (cardWidth < 116) {
          cardWidth = 116;
          cardHeight = 116 * 1.5;
          cardHeightOffset = cardHeight - (116 * 1.25);
        } else if (cardWidth > 150) {
          cardWidth = 150;
          cardHeight = 150 * 1.5;
          cardHeightOffset = cardHeight - (116 * 1.25);
        }

        if (!dynamicHeight && cardHeightOffset < 65 && cardHeightOffset > 0) {
          // if small ofsset gradualy lower removed amount
          cardHeightOffset -= ((65 - cardHeightOffset) * 2);
        }

        if (hoveredClass === 'hovered') {
          cardHeightOffset += 34;
          if (fourPRoom) {
            cardWidth *= 0.80;
            cardHeight *= 0.80;
          }
        } else if (hoveredClass === 'hoveredNeighbour') {
          cardHeightOffset += 20;
          if (fourPRoom) {
            cardWidth *= 0.90;
            cardHeight *= 0.90;
          }
        }
        let left = ((index + (startIndex / 2) - 1) * cardGap) + 23;

        if (cards.length <= 1) {
          left = ((index + 4) * cardGap) + 23;
        } else if (cards.length <= 3) {
          left = ((index + 3) * cardGap) + 23;
        } else if (cards.length <= 5) {
          left = ((index + 2) * cardGap) + 23;
        } else if (cards.length <= 7) {
          left = ((index + 1) * cardGap) + 23;
        } else if (cards.length <= 9) {
          left = ((index) * cardGap) + 23;
        }

        if (index + startIndex === 5) {
          left = cardGap / 2;
        } else if (index + startIndex === 4) {
          left = -cardGap / 2;
        } else if (index + startIndex > 5) {
          left = ((index + startIndex - 5) * cardGap) + cardGap / 2;
        } else if (index + startIndex < 4) {
          left = ((index + startIndex - 4) * cardGap) - cardGap / 2;
        }

        let marginTop = 60 - cardHeightOffset;

        //  console.log('marginTop', { marginTop, dynamicHeight });

        if (height < 640) {
          marginTop = -20;
          //    } else {
        } else if (height > 900) {
        //  marginTop = marginTop;

        } else if (dynamicHeight && marginTop > 100) {
          if (height < 700) {
            marginTop = 10 - (marginTop * 0.1);
          } else if (height < 840) {
            marginTop = 40;
          } else {
            marginTop = 60;
          }
          //  marginTop = 70 - (marginTop * 0.1);
        } else if (dynamicHeight && marginTop > 50) {
          marginTop = 37 - (marginTop * 0.05);
        } else if (dynamicHeight && marginTop > 25) {
          marginTop = 18;
        } else if (dynamicHeight && marginTop > 20) {
          marginTop = 10;
        }
        //  }
        //    }

        dynamicStyle = {
          left, width: cardWidth, height: cardHeight, backgroundSize: `${cardWidth}px ${cardHeight}px`,
        };
        dynamicStyleOverlay = { width: cardWidth - 6, height: cardHeight - 6 };
      }
    }

    //  console.log('dynamicStyle', { dynamicStyle });

    return (
      <div key={`hover-${card.card}`} className={`${hoveredClass}`}>
        <div
          key={card.card}
          id={`hand-card-${card.card}`}
          style={{ ...dynamicStyle }}
          className={`${hoveredClass} card card-${card.card} ${(selectedCard === card.card || (doubleClickSelectedCard && doubleClickSelectedCard.card === card.card)) ? 'selected' : ''} card-${startIndex + index} ${(card.allowed && gameState && gameState !== 'choose' && gameState !== 'results' && !tableIsInProgress) ? 'allowed' : 'blocked'}`}
          onClick={e => playCard(e, card)}
          onMouseOver={() => this.changeHoveredCard(index, card, 'over')}
          onFocus={() => this.changeHoveredCard(index, card, 'over')}
          onMouseOut={() => this.changeHoveredCard(index, card, 'out')}
          onBlur={() => this.changeHoveredCard(index, card, 'out')}
        >
          <div
            key={`overlay-${card.card}`}
            id={`hand-card-overlay-${card.card}`}
            style={dynamicStyleOverlay}
            className={`${(gameState === 'choose' || (!hasPlayedCard && currentTurnUid && memberUid === currentTurnUid && !card.allowed && gameState && gameState !== 'choose' && !tableIsInProgress)) ? 'blocked-overlay' : 'display-none'}`}
            onClick={e => playCard(e, card)}
          />
        </div>
      </div>
    );
  } */

  render() {
    const {
      gameState,
      isWebVersion,
      isFullscreen,
      playCard,
      selectedCard,
      tableIsInProgress,
      memberUid,
      currentTurnUid,
      fourPRoom,
      doubleClickSelectedCard,
      cardsOnTable,
      currentType,
      largePlayer,
      cardsOnLoading,
      myPos,
    } = this.props;

    const {
      cards, width, height, hasPlayedCard, hoveredCard,
    } = this.state;
    counter += 1;

    return (
      <div className={classNames('cards', {
        'cards-web': isWebVersion && !isFullscreen,
        '10Cards': cards && cards.length === 9,
        'cards-even': cards && cards.length % 2 === 0,
        'cards-odd': cards && cards.length % 2 !== 0,
        'display-none': cards && cards.length !== 8 && gameState === 'results',
      })}
      >
        {cards && cards.map((card, index) => (
          <PlayerCard
            card={card.card}
            allowed={card.allowed}
            index={index}
            cardsLength={cards.length}
            gameState={gameState}
            playCard={playCard}
            selectedCard={selectedCard}
            tableIsInProgress={tableIsInProgress}
            memberUid={memberUid}
            currentTurnUid={currentTurnUid}
            isFullscreen={isFullscreen}
            isWebVersion={isWebVersion}
            fourPRoom={fourPRoom}
            doubleClickSelectedCard={doubleClickSelectedCard ? doubleClickSelectedCard.card : null}
            width={width}
            height={height}
            hasPlayedCard={hasPlayedCard}
            hoveredCard={hoveredCard}
            changeHoveredCard={this.changeHoveredCard}
            cardsOnTable={cardsOnTable}
            currentType={currentType}
            largePlayer={largePlayer}
            myPos={myPos}
            cardsOnLoading={cardsOnLoading}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  memberUid: state.member.uid,
  //  cards:state.game.cards,
  currentTurn: state.game.currentTurn,
  currentTurnUid: (state.game.currentTurn && state.game.players && state.game.players[state.game.currentTurn]) ? state.game.players[state.game.currentTurn].uid : '',
  currentTable: state.game.currentTable,
  myPos: state.game.myPos,
  largePlayer: state.game.largePlayer,
  gameState: state.game.globalParams.gameState,
  cardsOnTable: state.game.cardsOnTable,
  currentType: state.game.currentType,
});

// const mapDispatchToProps = {

// };

export default connect(mapStateToProps, null)(PlayerCards);
