export function setLoading(_state) {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'LOADING_REPLACE', data: _state })));
}
export const setOpenRoomsTab = (tab) => {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'OPEN_ROOMS_TAB', data: tab })));
};

export const setOpenTopTab = (tab) => {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'OPEN_TOP_TAB', data: tab })));
};

export const setOpenTournamentsTutorial = (isOpen) => {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'OPEN_TOURNAMENTS_TUTORIAL', data: isOpen })));
};

export const setPrepareForJoyride = (close) => {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'PREPARE_FOR_JOYRIDE', data: close })));
};

export const setExpandedLandscapeSidebar = (value) => {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'EXPANDED_LANDSCAPE_SIDEBAR', data: value })));
};

export const setJoyrideEndedTrigger = (value) => {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'JOYRIDE_ENDED_TRIGGER', data: value })));
};

export const setActiveTab = (value) => {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'ACTIVE_TAB', data: value })));
};

export const setPwOption = (value) => {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'PW_OPTION', data: value })));
};

export const setIgnoredSwitchChecked = (value) => {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'IGNORED_SWITCH_CHECKED', data: value })));
}

export const setShopActiveTab = (value) => {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'SHOP_ACTIVE_TAB', data: value })));
};