import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import classNames from 'classnames';
import { map, filter } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import DropdownItem from 'reactstrap/lib/DropdownItem';

import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';
import LandScapeLayoutMobileHeader from '../../Layout/Mobile/LandScapeLayoutMobileHeader';
import MyInfoSubHeader from './MyInfoSubHeader';
import CustomDropdown from '../../../Components/Components/CustomDropdown';

import coinImg from '../../../../../../images/redesign/common/coin.svg';

import {
  getAchievements,
  claimAchievement,
} from '../../../../../../actions/member';
import {
  MENU_NAVIGATION,
  ACHIEVEMENT_IDS,
  ACHIEVEMENT_DATA,
  sendLanguage,
  UNIQUE_ACHIEVEMENT_DATA,
  BIG_SIZE_ACH,
  SCREEN_MODE
} from '../../../../../../constants/constants';

class Achievements extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fetchAchievements: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    claimAchievementFunc: PropTypes.func.isRequired,
    member: PropTypes.shape({
      achievements: PropTypes.shape({}),
      gamesPlayed: PropTypes.number,
      gamesWon: PropTypes.number,
    }),
    userAchievements: PropTypes.shape(),
    claimedAchievements: PropTypes.shape(),
    achievementsNotification: PropTypes.bool,
    gamesPlayed: PropTypes.number,
    gamesWon: PropTypes.number,
    activeTab: PropTypes.string,
    setAchievementsNotification: PropTypes.func.isRequired,
    showUserSettingsModal: PropTypes.func.isRequired,
    handleClickStart: PropTypes.func.isRequired,
    OpenMobileDropdownModal: PropTypes.func.isRequired,
    screenMode: PropTypes.string,
  }

  static defaultProps = {
    member: {},
    achievementsNotification: false,
    userAchievements: {},
    claimedAchievements: {},
    gamesPlayed: 0,
    gamesWon: 0,
    activeTab: 0,
    screenMode: SCREEN_MODE.normal,
  }

  constructor(props) {
    super(props);

    this.state = {
      achievementTab: 0,
      listScrollPos: 0,
      achScrollPos: 0,
      //  achievements: [],
      achievementsIds: ACHIEVEMENT_IDS,
      achievementsData: ACHIEVEMENT_DATA,
      achievementNotif: {
        singleAchievements: false,
        gamesPlayed: false,
        gamesWon: false,
        highestEarned: false,
        earnedInADay: false,
        maxSuccessionWins: false,
        maxSuccessionLosses: false,
        zolePlayed: false,
        zoleWon: false,
        fastGamesPlayed: false,
        lightningGamesPlayed: false,
        unlimitedGamesPlayed: false,
        mazaZolePlayed: false,
        mazaZoleWon: false,
        bonusSpins: false,
        joinedTournaments: false,
        giftsReceived: false,
        reachedTournamentTop10: false,
        reachedTop100: false,
        giftsSent: false,
        maxParties: false,
        supportMessagesSent: false,
        storePurchase: false,
        galdinsPlayed: false,
        galdinsWon: false,
        galdinsLose: false,
        maxDailyLogin: false,
        selectTypeAchievement: 'singleAchievements',
        typeAchievementOpen: false,
      },
      achievements: ['singleAchievementsTitle', 'gamesPlayedTitle', 'gamesWonTitle', 'balanceTitle', 'earnedInADayTitle', 'maxSuccessionWinsTitle', 'maxSuccessionLossesTitle', 'zolePlayedTitle', 'zoleWonTitle',
        'fastGamesPlayedTitle', 'lightningGamesPlayedTitle', 'unlimitedGamesPlayedTitle', 'mazaZolePlayedTitle', 'mazaZoleWonTitle', 'bonusSpinsTitle', 'joinedTournamentsTitle', 'reachedTournamentTop10Title',
        'reachedTop100Title', 'giftsReceivedTitle', 'giftsSentTitle', 'maxPartiesTitle', 'supportMessagesSentTitle', 'storePurchasesTitle', 'galdiniPlayedTitle', 'galdiniWonTitle', 'galdiniLoseTitle', 'maxDailyLoginTitle'],
    };
  }

  componentDidMount() {
    const { fetchAchievements } = this.props;

    fetchAchievements();

  //  this.setState({ achievements: [ t('singleAchievements'), t('gamesPlayed'), t('gamesWon'), t('balance'), t('earnedInADay'), t('maxSuccessionWins'), t('maxSuccessionLosses'), t('zolePlayed'), t('zoleWon'),
  //  t('fastGamesPlayed'), t('mazaZolePlayed'), t('mazaZoleWon'), t('bonusSpins'), t('joinedTournaments'), t('giftsReceived'),
  //  t('reachedTournamentTop10'), t('reachedTop100'), t('giftsSent'), t('maxParties'), t('supportMessagesSent'), t('storePurchase'), t('galdiniPlayed'), t('galdiniWon'), t('galdiniLose') ] });
  }

  changeAchievementTab = (tab) => {
    this.setState({ achievementTab: tab });
  }

  findAchievement = (key, borders, rewards, userAchievements, claimedAchievements, rev) => {
    const {
      t, i18n, achievementsNotification, setAchievementsNotification, claimAchievementFunc,
    } = this.props;
    const { achievementNotif, achievementsIds, achievementTab } = this.state;
    let name = '';
    let firstName = '';
    let secondName = '';
    let thirdName = '';
    if (key === 'gamesPlayed') {
      firstName = t('firstGame');
      name = t('gamesPlayed');
    } else if (key === 'gamesWon') {
      firstName = t('firstWin');
      thirdName = t('3wins');
      name = t('wins');
    } else if (key === 'zolePlayed') {
      firstName = t('zolePlayed');
      thirdName = t('3zolesPlayed');
      name = t('zolesPlayed');
    } else if (key === 'zoleWon') {
      firstName = t('zoleWon');
      name = t('zolesWon');
    } else if (key === 'mazaZolePlayed') {
      firstName = t('mazaZolePlayed');
      thirdName = t('3mazasZolesPlayed');
      name = t('mazasZolesPlayed');
    } else if (key === 'mazaZoleWon') {
      firstName = t('mazaZoleWon');
      name = t('mazasZolesWon');
    } else if (key === 'giftsSent') {
      firstName = t('giftSent');
      thirdName = t('3giftsSent');
      name = t('giftsSent');
    } else if (key === 'giftsReceived') {
      firstName = t('giftReceived');
      thirdName = t('3giftsReceived')
      name = t('giftsReceived');
    } else if (key === 'storePurchase') {
      firstName = t('storePurchase');
      secondName = t('2storePurchases');
      thirdName = t('3storePurchases');
      name = t('storePurchases');
    } else if (key === 'supportMessagesSent') {
      firstName = t('supportMessageSent');
      thirdName = t('3supportMessagesSent');
      name = t('supportMessagesSent');
    } else if (key === 'maxParties') {
      thirdName = t('3maxParties');
      name = t('maxParties');
    } else if (key === 'maxSuccessionWins') {
      thirdName = t('3maxSuccessionWins');
      name = t('maxSuccessionWins');
    } else if (key === 'maxSuccessionLosses') {
      thirdName = t('3maxSuccessionLosses');
      name = t('maxSuccessionLosses');
    } else if (key === 'fastGamesPlayed') {
      firstName = t('fastGamePlayed');
      thirdName = t('3fastGamesPlayed');
      name = t('fastGamesPlayed');
    } else if (key === 'lightningGamesPlayed') {
      firstName = t('lightningGamePlayed');
      thirdName = t('3lightningGamesPlayed');
      name = t('lightningGamesPlayed');
    } else if (key === 'unlimitedGamesPlayed') {
      firstName = t('unlimitedGamePlayed');
      thirdName = t('3unlimitedGamesPlayed');
      name = t('unlimitedGamesPlayed');
    } else if (key === 'bonusSpins') {
      thirdName= t('3bonusSpins');
      name = t('bonusSpins');
    } else if (key === 'joinedTournaments') {
      thirdName = t('3joinedTournaments');
      name = t('joinedTournaments');
    } else if (key === 'reachedTournamentTop10') {
      firstName = t('reachedFirstTournamentTop10');
      thirdName = t('3reachedTournamentTop10');
      name = t('reachedTournamentTop10');
    } else if (key === 'highestEarned') {
      name = t('balance');
    } else if (key === 'earnedInADay') {
      name = t('earnedInADay');
    } else if (key === 'reachedTop100') {
      firstName = t('reachedFirstTop100');
      thirdName = t('3reachedTop100');
      name = t('reachedTop100');
    } else if (key === 'galdinsPlayed') {
      firstName = t('galdinsPlayed');
      thirdName = t('3galdiniPlayed');
      name = t('galdiniPlayed');
    } else if (key === 'galdinsWon') {
      firstName = t('galdinsWon');
      name = t('galdiniWon');
    } else if (key === 'galdinsLose') {
      firstName = t('galdinsLose');
      name = t('galdiniLose');
    } else if (key === 'maxDailyLogin') {
      firstName = t('maxDailyLogin');
      thirdName = t('3maxDailyLogins');
      name = t('maxDailyLogins');
    }

    const noCounter = ['gamesPlayed', 'gamesWon', 'fastGamesPlayed', 'lightningGamesPlayed', 'unlimitedGamesPlayed', 'maxDailyLogin', 'reachedTournamentTop10'];

    let reward = 0;

    const claimedAchievementsVal = claimedAchievements || 0;

    borders.map((item, index) => {
      if (item <= userAchievements && claimedAchievementsVal <= index) {
        console.log(reward, key, userAchievements, claimedAchievements, "achievementCheckDouble===")
        reward += rewards[index];
      }
      return null;
    });
    
    if (reward) {
      if (!achievementsNotification) {
        console.log(reward, key, userAchievements, claimedAchievements, "achievementCheckDouble")
        setAchievementsNotification(true);
      }
      if (achievementNotif && !achievementNotif[key]) {
        this.setState(prevState => ({
          achievementNotif: {
            ...prevState.achievementNotif,
            [key]: true,
          },
        }));
      }
    }

    if (!reward && achievementNotif[key]) {
      if (achievementsNotification) {
        setAchievementsNotification(false);
      }

      this.setState(prevState => ({
        achievementNotif: {
          ...prevState.achievementNotif,
          [key]: false,
        },
      }));
    }

    if (achievementsIds && achievementsIds[achievementTab] === key) {
      let grayscale = false;

      return (
        <Row className="ach-table-row">
          {borders.map((item, index) => {
            let img;

            //  if (item <= userAchievements && claimedAchievementsVal <= index) {
            //    reward = reward + rewards[index];
            //  }

            let imgKey = key;
            if (key === 'highestEarned') {
              imgKey = 'balance';
            }

            try {
              if (borders[index + 1]) {
                // eslint-disable-next-line
                img = require(`../../../../../../images/Ach/${imgKey}_${borders[index]}_${borders[index + 1]}.png`);
              } else {
                // eslint-disable-next-line
                img = require(`../../../../../../images/Ach/${imgKey}_${borders[index]}.png`);
              }
            } catch (e) {
              console.log(e);
            }

            const val = borders[index] >= 1000 ? `${borders[index] / 1000}k` : borders[index];

            grayscale = !(borders[index + 1] && userAchievements >= borders[index]) || (!borders[index + 1] && userAchievements >= borders[index]);

            return (
              // eslint-disable-next-line
              <Col xs="2" sm="2" className="my-info-achievements-column my-info-achievements-column-mobile" key={key + index}>
                <div className="my-info-achievements-column-div">
                  <Media src={img} className={`${grayscale ? 'grayscale' : null} my-info-achievements-column-img`} />
                </div>
                {!rev ? (
                  <span>{`${(index === 0 && noCounter.includes(key)) ? ('') : val} ${(key === 'bonusSpins' || key === 'joinedTournaments' || key === 'maxParties' || key === 'maxSuccessionWins' || key === 'maxSuccessionLosses') && i18n.language === sendLanguage.ru ? (
                    index === 0 ? thirdName : name
                  ) : (key === 'giftsReceived' || key === 'reachedTop100' || key === 'giftsSent' || key === 'galdinsPlayed' || key === 'maxDailyLogin' || key === 'gamesWon' || key === 'zolePlayed' || key === 'fastGamesPlayed' || key === 'reachedTournamentTop10' || key === 'mazaZolePlayed' || key === 'lightningGamesPlayed' || key === 'unlimitedGamesPlayed') && i18n.language === sendLanguage.ru ? (
                    index === 0 ? firstName : index === 1 ? thirdName : name
                    ) : (key === 'storePurchase' && i18n.language === sendLanguage.ru) ? (
                    index === 0 ? firstName : index === 1 ? secondName : index === 2 ? thirdName : name
                      ) : (key === 'supportMessagesSent') && i18n.language === sendLanguage.ru ? (
                    index === 0 ? firstName : (index === 1 || index === 2) ? thirdName : name
                  ) : (
                    index === 0 && firstName ? firstName : name
                  )}`}</span>
                ) : (
                    <span>{`${(key === 'bonusSpins' || key === 'joinedTournaments' || key === 'maxParties' || key === 'maxSuccessionWins' || key === 'maxSuccessionLosses') && i18n.language === sendLanguage.ru ? (
                    index === 0 ? thirdName : name
                    ) : (key === 'giftsReceived' || key === 'reachedTop100' || key === 'giftsSent' || key === 'galdinsPlayed' || key === 'maxDailyLogin' || key === 'gamesWon' || key === 'zolePlayed' || key === 'fastGamesPlayed' || key === 'reachedTournamentTop10' || key === 'mazaZolePlayed' || key === 'lightningGamesPlayed' || key === 'unlimitedGamesPlayed') && i18n.language === sendLanguage.ru ? (
                    index === 0 ? firstName : index === 1 ? thirdName : name
                      ) : (key === 'storePurchase' && i18n.language === sendLanguage.ru) ? (
                    index === 0 ? firstName : index === 1 ? secondName : index === 2 ? thirdName : name
                        ) : (key === 'supportMessagesSent') && i18n.language === sendLanguage.ru ? (
                    index === 0 ? firstName : (index === 1 || index === 2) ? thirdName : name
                  ) : (
                    index === 0 && firstName ? firstName : name
                  )} ${noCounter.includes(key) ? ('') : (val)}`}</span>
                )}
                <div>
                  <span className={classNames({"my-info-achievements-count": !grayscale, "my-info-achievements-uncount": grayscale})}>{!grayscale ? borders[index] : userAchievements || 0} / {borders[index]}</span>
                </div>
                <div>
                  <Media src={coinImg} className="my-info-achievements-collect-button-coin mr-1" />
                  <div className="my-info-achievements-collect-button-text">
                    {rewards[index]}
                  </div>
                </div>
              </Col>
            );
          })}

          {/* <Col sm="12" className="d-flex align-items-center mt-4">
            <Button color="link" disabled={reward === 0} className={classNames('my-info-achievements-collect-button', { 'my-info-achievements-collect-button-active': reward > 0 })} onClick={() => claimAchievementFunc(key)}>
            </Button>
          </Col> */}
        </Row>
      );
    }
    return null;
  };

  singleTimeAchievements = (key, userAchievements, claimedAchievements) => {
    const {
      t, setAchievementsNotification, achievementsNotification, claimAchievementFunc,
    } = this.props;
    const { achievementNotif, achievementTab, achievementsIds } = this.state;

    let name = '';
    let reward = 0;

    if (key === 'winLarge61') {
      name = t('winLarge61');
      if (userAchievements && !claimedAchievements) {
        reward = 300;
      }
    } else if (key === 'winLarge91') {
      name = t('winLarge91');
      if (userAchievements && !claimedAchievements) {
        reward = 500;
      }
    } else if (key === 'winLarge120') {
      name = t('winLarge120');
      if (userAchievements && !claimedAchievements) {
        reward = 600;
      }
    } else if (key === 'winSmall60') {
      name = t('winSmall60');
      if (userAchievements && !claimedAchievements) {
        reward = 300;
      }
    } else if (key === 'winZoleAll') {
      name = t('winZoleAll');
      if (userAchievements && !claimedAchievements) {
        reward = 600;
      }
    } else if (key === 'winZoleTwoAces') {
      name = t('winZoleTwoAces');
      if (userAchievements && !claimedAchievements) {
        reward = 1000;
      }
    } else if (key === 'loseLarge60') {
      name = t('loseLarge60');
      if (userAchievements && !claimedAchievements) {
        reward = 300;
      }
    } else if (key === 'loseLarge30') {
      name = t('loseLarge30');
      if (userAchievements && !claimedAchievements) {
        reward = 150;
      }
    } else if (key === 'loseLarge0') {
      name = t('loseLarge0');
      if (userAchievements && !claimedAchievements) {
        reward = 150;
      }
    } else if (key === 'take3Aces') {
      name = t('take3Aces');
      if (userAchievements && !claimedAchievements) {
        reward = 333;
      }
    } else if (key === 'take0Points') {
      name = t('take0Points');
      if (userAchievements && !claimedAchievements) {
        reward = 100;
      }
    } else if (key === 'takeTwoAces') {
      name = t('takeTwoAces');
      if (userAchievements && !claimedAchievements) {
        reward = 1000;
      }
    } else if (key === 'takeTwoNines') {
      name = t('takeTwoNines');
      if (userAchievements && !claimedAchievements) {
        reward = 1000;
      }
    } else if (key === 'loseZoleTwoNines') {
      name = t('loseZoleTwoNines');
      if (userAchievements && !claimedAchievements) {
        reward = 2000;
      }
    } else if (key === 'loseTableAllTricks') {
      name = t('loseTableAllTricks');
      if (userAchievements && !claimedAchievements) {
        reward = 3000;
      }
    } else if (key === 'winSmall90') {
      name = t('winSmall90');
      if (userAchievements && !claimedAchievements) {
        reward = 500;
      }
    } else if (key === 'winSmall120') {
      name = t('winSmall120');
      if (userAchievements && !claimedAchievements) {
        reward = 2000;
      }
    } else if (key === 'dealtOnlyQueensJacks') {
      name = t('dealtOnlyQueensJacks');
      if (userAchievements && !claimedAchievements) {
        reward = 1000;
      }
    } else if (key === 'dealtNoTrumps') {
      name = t('dealtNoTrumps');
      if (userAchievements && !claimedAchievements) {
        reward = 2000;
      }
    } else if (key === 'dealtOnly789') {
      name = t('dealtOnly789');
      if (userAchievements && !claimedAchievements) {
        reward = 3000;
      }
    }

    if (reward) {
      if (!achievementsNotification) {
        setAchievementsNotification(true);
      }

      if (achievementNotif && !achievementNotif.singleAchievements) {
        this.setState(prevState => ({
          achievementNotif: {
            ...prevState.achievementNotif,
            singleAchievements: true,
          },
        }));
      }
    }

    //  const claimedAchievementsVal = claimedAchievements ? claimedAchievements : 0;

    let img;

    try {
      // eslint-disable-next-line
      if (BIG_SIZE_ACH.includes(key)) {
        img = require(`../../../../../../images/Ach/${key}_1.svg`);
      } else {
        img = require(`../../../../../../images/Ach/${key}_1.png`);
      }
    } catch (e) {
      console.log(e);
    }

    const grayscale = !userAchievements;


    if (achievementsIds && achievementsIds[achievementTab] === 'singleAchievements') {
      return (
        <Col xs="2" sm="2" className="my-info-achievements-column my-info-achievements-column-mobile" key={key}>
          <div className="my-info-achievements-column-div">
            <Media src={img} className={`${grayscale ? 'grayscale' : null}  my-info-achievements-column-img`} />
            <span>{`${name}`}</span>
          </div>
          <Media src={coinImg} className="my-info-achievements-collect-button-coin mr-1" />
          <div className="my-info-achievements-collect-button-text">
            {`${UNIQUE_ACHIEVEMENT_DATA[key].reward}`}
          </div>
          {/* <Button
            color="link"
            disabled={reward === 0}
            className={`${grayscale ? '' : 'my-info-achievements-collect-button-active'} my-info-achievements-collect-button my-info-achievements-collect-button-single`}
            onClick={() => claimAchievementFunc(key)}
          >
          </Button> */}
        </Col>
      );
    }
    return null;
  };

  /*  updateListScrollPos = (val) => {
    this.setState({ listScrollPos: val.topPosition || 0 });
  }

  updateAchScrollPos = (val) => {
    this.setState({ achScrollPos: val.topPosition || 0 });
  }

  scrollAch = (newVal) => {
    this.achScrollbar.scrollYTo(newVal);
  }

  scrollAchList = (newVal) => {
    this.achListScrollbar.scrollYTo(newVal);
  }  */

  toggleTypeAchievement = () => {
    this.setState(prevState => ({
      typeAchievementOpen: !prevState.typeAchievementOpen,
    }));
  }

  render() {
    const {
      t, screenMode, showUserSettingsModal, handleClickStart, OpenMobileDropdownModal, changeTab, achievementsNotification, claimedAchievements, userAchievements, gamesPlayed, gamesWon, activeTab,
    } = this.props;

    const {
      achievementTab, achievementsData, achievementNotif, achievements, achievementsIds, typeAchievementOpen // achScrollPos, listScrollPos,
    } = this.state;

    return (
      <div className="layout-mobile-body layout-mobile-body-achievements-page">
        <Helmet>
          <title>
            Zole - {t('common:route.achievements')}
          </title>
        </Helmet>

        <div className="layout-mobile-body-top">
          {/* <Row className="layout-mobile-subheader d-none">
            <MyInfoSubHeader changeTab={changeTab} activeTab={activeTab} />
          </Row> */}
        </div>
        <div className="layout-mobile-body-main">
          <div className="layout-mobile-body-main-title d-none">{t('common:route.achievements')}</div>
          <Row className="my-info-achievements">
            <Col xs="12" sm="12" className="my-info-achievements-header">
              <div className="my-info-achievements-header-tab">
                <CustomDropdown
                  toggle={(
                    <span>
                      {
                        t(achievements[achievementTab])
                      }
                    </span>
                  )
                  }
                  toggleFunc={this.toggleTypeAchievement}
                  isOpen={typeAchievementOpen}
                  menu={(
                    <>
                      {map(achievements, (item, key) => (
                        <DropdownItem key={key} className={key === achievementTab ? 'active-item' : ''} onClick={() => { this.changeAchievementTab(key); }}>
                          {t(item)}
                          {
                            achievementsIds && achievementNotif && achievementNotif[achievementsIds[key]] && (
                              <div className="layout-body-radio-outer-circle" />
                            )
                          }
                        </DropdownItem>
                      ))}
                    </>
                    )}
                  inlineMenuClassName="landing-version-history-dropdown-list"
                />
              </div>
            </Col>
            <Col xs="12" sm="12" className="my-info-achievements-right">
              <div
                className="layout-mobile-body-main-scrollarea my-info-achievements-scrollarea"
                show
                rightOffset={0}
                topOffset={0}
                bottomOffset={0}
              >
                {achievementTab === 0 && userAchievements && claimedAchievements && (
                  <Row className="my-info-achievements-row">
                      {this.singleTimeAchievements('winLarge61', userAchievements.winLarge61, claimedAchievements.winLarge61)}
                      {this.singleTimeAchievements('winLarge91', userAchievements.winLarge91, claimedAchievements.winLarge91)}
                      {this.singleTimeAchievements('winLarge120', userAchievements.winLarge120, claimedAchievements.winLarge120)}
                    {this.singleTimeAchievements('winSmall60', userAchievements.winSmall60, claimedAchievements.winSmall60)}
                    {this.singleTimeAchievements('winSmall90', userAchievements.winSmall90, claimedAchievements.winSmall90)}
                    {this.singleTimeAchievements('winSmall120', userAchievements.winSmall120, claimedAchievements.winSmall120)}
                      {this.singleTimeAchievements('winZoleAll', userAchievements.winZoleAll, claimedAchievements.winZoleAll)}
                      {this.singleTimeAchievements('take3Aces', userAchievements.take3Aces, claimedAchievements.take3Aces)}
                      {this.singleTimeAchievements('take0Points', userAchievements.take0Points, claimedAchievements.take0Points)}
                      {this.singleTimeAchievements('winZoleTwoAces', userAchievements.winZoleTwoAces, claimedAchievements.winZoleTwoAces)}
                      {this.singleTimeAchievements('loseLarge60', userAchievements.loseLarge60, claimedAchievements.loseLarge60)}
                      {this.singleTimeAchievements('loseLarge30', userAchievements.loseLarge30, claimedAchievements.loseLarge30)}
                      {this.singleTimeAchievements('loseLarge0', userAchievements.loseLarge0, claimedAchievements.loseLarge0)}
                      {this.singleTimeAchievements('takeTwoAces', userAchievements.takeTwoAces, claimedAchievements.takeTwoAces)}
                      {this.singleTimeAchievements('takeTwoNines', userAchievements.takeTwoNines, claimedAchievements.takeTwoNines)}
                      {this.singleTimeAchievements('loseZoleTwoNines', userAchievements.loseZoleTwoNines, claimedAchievements.loseZoleTwoNines)}
                    {this.singleTimeAchievements('loseTableAllTricks', userAchievements.loseTableAllTricks, claimedAchievements.loseTableAllTricks)}
                    {this.singleTimeAchievements('loseTableAllTricks', userAchievements.loseTableAllTricks, claimedAchievements.loseTableAllTricks)}
                    {this.singleTimeAchievements('dealtOnlyQueensJacks', userAchievements.dealtOnlyQueensJacks, claimedAchievements.dealtOnlyQueensJacks)}
                    {this.singleTimeAchievements('dealtNoTrumps', userAchievements.dealtNoTrumps, claimedAchievements.dealtNoTrumps)}
                    {this.singleTimeAchievements('dealtOnly789', userAchievements.dealtOnly789, claimedAchievements.dealtOnly789)}
                  </Row>
                )}
                {claimedAchievements && Object.keys(claimedAchievements).length > 0 && userAchievements && (
                  <Fragment>
                    {this.findAchievement('gamesPlayed', achievementsData.gamesPlayed.borders, achievementsData.gamesPlayed.rewards, gamesPlayed, claimedAchievements.gamesPlayed)}
                    {this.findAchievement('gamesWon', achievementsData.gamesWon.borders, achievementsData.gamesWon.rewards, gamesWon, claimedAchievements.gamesWon)}

                    {this.findAchievement('highestEarned', achievementsData.highestEarned.borders, achievementsData.highestEarned.rewards, userAchievements.highestEarned, claimedAchievements.highestEarned, true)}
                    {this.findAchievement('earnedInADay', achievementsData.earnedInADay.borders, achievementsData.earnedInADay.rewards, userAchievements.earnedInADay, claimedAchievements.earnedInADay, true)}

                    {this.findAchievement('maxSuccessionWins', achievementsData.maxSuccessionWins.borders, achievementsData.maxSuccessionWins.rewards, userAchievements.maxSuccessionWins, claimedAchievements.maxSuccessionWins)}

                    {this.findAchievement('maxSuccessionLosses', achievementsData.maxSuccessionLosses.borders, achievementsData.maxSuccessionLosses.rewards, userAchievements.maxSuccessionLosses, claimedAchievements.maxSuccessionLosses)}

                    {this.findAchievement('zolePlayed', achievementsData.zolePlayed.borders, achievementsData.zolePlayed.rewards, userAchievements.zolePlayed, claimedAchievements.zolePlayed)}

                    {this.findAchievement('zoleWon', achievementsData.zoleWon.borders, achievementsData.zoleWon.rewards, userAchievements.zoleWon, claimedAchievements.zoleWon)}

                    {this.findAchievement('fastGamesPlayed', achievementsData.fastGamesPlayed.borders, achievementsData.fastGamesPlayed.rewards, userAchievements.fastGamesPlayed, claimedAchievements.fastGamesPlayed)}

                    {this.findAchievement('lightningGamesPlayed', achievementsData.lightningGamesPlayed.borders, achievementsData.lightningGamesPlayed.rewards, userAchievements.lightningGamesPlayed, claimedAchievements.lightningGamesPlayed)}

                    {this.findAchievement('unlimitedGamesPlayed', achievementsData.unlimitedGamesPlayed.borders, achievementsData.unlimitedGamesPlayed.rewards, userAchievements.unlimitedGamesPlayed, claimedAchievements.unlimitedGamesPlayed)}

                    {this.findAchievement('mazaZolePlayed', achievementsData.mazaZolePlayed.borders, achievementsData.mazaZolePlayed.rewards, userAchievements.mazaZolePlayed, claimedAchievements.mazaZolePlayed)}

                    {this.findAchievement('mazaZoleWon', achievementsData.mazaZoleWon.borders, achievementsData.mazaZoleWon.rewards, userAchievements.mazaZoleWon, claimedAchievements.mazaZoleWon)}

                    {this.findAchievement('bonusSpins', achievementsData.bonusSpins.borders, achievementsData.bonusSpins.rewards, userAchievements.bonusSpins, claimedAchievements.bonusSpins)}

                    {this.findAchievement('joinedTournaments', achievementsData.joinedTournaments.borders, achievementsData.joinedTournaments.rewards, userAchievements.joinedTournaments, claimedAchievements.joinedTournaments)}

                    {this.findAchievement('giftsReceived', achievementsData.giftsReceived.borders, achievementsData.giftsReceived.rewards, userAchievements.giftsReceived, claimedAchievements.giftsReceived)}

                    {this.findAchievement('reachedTournamentTop10', achievementsData.reachedTournamentTop10.borders, achievementsData.reachedTournamentTop10.rewards, userAchievements.reachedTournamentTop10, claimedAchievements.reachedTournamentTop10)}

                    {this.findAchievement('reachedTop100', achievementsData.reachedTop100.borders, achievementsData.reachedTop100.rewards, userAchievements.reachedTop100, claimedAchievements.reachedTop100)}

                    {this.findAchievement('giftsSent', achievementsData.giftsSent.borders, achievementsData.giftsSent.rewards, userAchievements.giftsSent, claimedAchievements.giftsSent)}

                    {this.findAchievement('maxParties', achievementsData.maxParties.borders, achievementsData.maxParties.rewards, userAchievements.maxParties, claimedAchievements.maxParties)}

                    {this.findAchievement('supportMessagesSent', achievementsData.supportMessagesSent.borders, achievementsData.supportMessagesSent.rewards, userAchievements.supportMessagesSent, claimedAchievements.supportMessagesSent)}

                    {this.findAchievement('storePurchase', achievementsData.storePurchase.borders, achievementsData.storePurchase.rewards, userAchievements.storePurchase, claimedAchievements.storePurchase)}

                    {this.findAchievement('galdinsPlayed', achievementsData.galdinsPlayed.borders, achievementsData.galdinsPlayed.rewards, userAchievements.galdinsPlayed, claimedAchievements.galdinsPlayed)}

                    {this.findAchievement('galdinsWon', achievementsData.galdinsWon.borders, achievementsData.galdinsWon.rewards, userAchievements.galdinsWon, claimedAchievements.galdinsWon)}

                    {this.findAchievement('galdinsLose', achievementsData.galdinsLose.borders, achievementsData.galdinsLose.rewards, userAchievements.galdinsLose, claimedAchievements.galdinsLose)}

                    {this.findAchievement('maxDailyLogin', achievementsData.maxDailyLogin.borders, achievementsData.maxDailyLogin.rewards, userAchievements.maxDailyLogin, claimedAchievements.maxDailyLogin)}
                  </Fragment>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  claimedAchievements: state.member.claimedAchievements || {},
  userAchievements: state.member.userAchievements || {},
  gamesPlayed: state.member.gamesPlayed || 0,
  gamesWon: state.member.gamesWon || 0,
});

const mapDispatchToProps = {
  fetchAchievements: getAchievements,
  claimAchievementFunc: claimAchievement,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['achievements', 'common'])(Achievements));
