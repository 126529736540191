import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Col from 'reactstrap/lib/Col';

// import { removeGift } from '../../../actions/member';

class OnlineCount extends Component {
  static propTypes = {
    usersCount: PropTypes.number,
    roomsCount: PropTypes.number,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    usersCount: null,
    roomsCount: null,
  }

  constructor(props) {
    super(props);
    this.state = {
    //  leaderboardPosition: '',
    };
  }

  render() {
    const {
      t, usersCount, roomsCount,
    } = this.props;

    return (
      <div className="online-stats">
        {t('menu.onlineCount')}:
        <span className="ml-1">{`${usersCount} ${t('menu.players')}, ${roomsCount} ${t('menu.rooms')}`}</span>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  usersCount: state.users.usersCount || null,
  roomsCount: state.users.roomsCount || null,
});

// const mapDispatchToProps = {
 // removeGiftFunction: removeGift,
// };

export default connect(mapStateToProps, null)(withTranslation('common')(OnlineCount));
