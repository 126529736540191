import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { size, includes, filter, map, sumBy, countBy, flatMap, values } from 'lodash';
import { connect } from 'react-redux';
// import ReactStars from "react-rating-stars-component";

import {
  // Row,
  // Col,
  Modal,
  ModalHeader,
  ModalBody,
  // Form,
  Label,
  Alert,
  Input,
  Button,
  FormGroup,
  Media,
  Progress
} from 'reactstrap';
import moment from 'moment';

import momentLocalizer from 'react-widgets-moment';

import * as constants from '../../../constants/constants';
import DateFormat from '../UI/DateFormat';
import StarRating from '../UI/StarRating';

import {
  checkFeedbackModal,
  feedbackClose,
  feedbackSubmit,
  getSurvey,
  answerForSurveyQuestion,
  checkedIsSuveyFunc,
  getFeedback
} from '../../../actions/member';

import config from '../../../constants/config';

import closeImg from '../../../images/icons/close.png';
import checkAction from '../../../images/icons/checkAction.svg';
import { getCurrentPlatform } from '../../../utils/environment';

moment.locale('lv');
momentLocalizer();

class Feedback extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  //  member: PropTypes.shape({}),
    run: PropTypes.bool,
    fontMode: PropTypes.string,
    surveys: PropTypes.shape([]),
    feedbacks: PropTypes.shape([]),
    fetchSurvey: PropTypes.func.isRequired,
    fetchFeedback: PropTypes.func.isRequired,
    fetchFeedbackSubmit: PropTypes.func.isRequired,
    submitAnswerForSurveyQuestion: PropTypes.func.isRequired,
    feedbackCloseFunc: PropTypes.func.isRequired,
  }

  static defaultProps = {
  //  member: {},
    run: false,
    fontMode: constants.FONT_MODE.normal,
    surveys: [],
    feedbacks: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      additionalComment: '',
      loading: false,
      rating: 0,
      status: null,
      error: null,
      alertVisible: false,
      modalOpen: true,
      limitCount: constants.FEEDBACK_LENGTH,
      successFeedback: false,
      answerList: [],
      anotherAnswerOption: '',
      checkedQuestionAnswer: [],
      surveyIsOpen: true,
      surveyQuestionType: null,
      successSurvey: false,
      allowAnswerOption: false,
      surveyResults: {},
      platform: 'dWeb',
      curDate: Date.now(),
    };

    // try {
    //   window.addEventListener('resize', this.handleResize);
    // } catch (err) {
    //   console.log(err);
    // }
  }

  componentDidMount() {
    const { fetchFeedback, fetchSurvey } = this.props;

    const { hostname } = window.location;

    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }
    
    let screen = 'dWeb'
    const isInDraugiemAppFrame = config.isInDraugiemAppFrame();
    const isInFacebooxAppFrame = config.isInFacebookAppFrame();
    const isInInboxAppFrame = config.isInInboxAppFrame();

    if (window.innerWidth <= 1280) {
      screen = 'mWeb'
    } else if (isInDraugiemAppFrame) {
      screen = constants.DRAUGIEM_PLATFORM
    } else if (isInFacebooxAppFrame) {
      screen = constants.FACEBOOK_PLATFORM
    } else if (isInInboxAppFrame) {
      screen = constants.INBOX_PLATFORM
    }

    this.setState({
      devMode,
      platform: screen,
    })

    fetchFeedback(devMode, constants.OLD_DESIGN, screen);
    fetchSurvey(devMode);

    this.intervalID = setInterval(() => {
      this.setState({
        curDate: Date.now(),
      })
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handleResize = () => {
    const { fetchFeedback } = this.props;
    const { platform, devMode } = this.state;

    const isInDraugiemAppFrame = config.isInDraugiemAppFrame();
    const isInFacebooxAppFrame = config.isInFacebookAppFrame();
    const isInInboxAppFrame = config.isInInboxAppFrame();

    console.log('handleResize', isInDraugiemAppFrame, isInFacebooxAppFrame, isInInboxAppFrame, window.innerWidth);

    let currentPlatform = platform;
 
    if (!isInDraugiemAppFrame && !isInFacebooxAppFrame && !isInInboxAppFrame) {
      if (window.innerWidth > 1280) {
        if (currentPlatform !== constants.DWEB_PLATFORM) {
          fetchFeedback(devMode, constants.OLD_DESIGN, constants.DWEB_PLATFORM);
          this.setState({
            platform: constants.DWEB_PLATFORM,
          })
        }
      } else if (window.innerWidth <= 1280) {
        if (currentPlatform !== constants.MWEB_PLATFORM) {
          fetchFeedback(devMode, constants.OLD_DESIGN, constants.MWEB_PLATFORM);
          this.setState({
            platform: constants.MWEB_PLATFORM,
          })
        }
      }
    }
  };

  handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    this.setState({
      [e.target.name]: value,
      limitCount: constants.FEEDBACK_LENGTH - value.length,
    });
  }

  handleAllowAnswerChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      [e.target.name]: value,
    });
  }

  handleRating = (rating) => {
    this.setState({
      rating,
    });
  }

  handleFeedback = (feedbackId) => {
    const { t, fetchFeedbackSubmit, name, firstName, lastName, fetchFeedback } = this.props;
    const { rating, additionalComment, platform, devMode } = this.state;
    let userName = '';
    if (rating === 0) {
      this.setState({
        error: t('common.ratingWrong'),
        status: constants.FROUNT_SIDE_STATUS.danger,
        alertVisible: true,
      });
      setTimeout(() => {
        this.setState({
          alertVisible: false,
          status: null,
          error: null,
        });
      }, 10000);
      return;
    }
    if (!additionalComment || additionalComment === '' || additionalComment.trim().length === 0) {
      this.setState({
        error: t('common.wrongComment'),
        status: constants.FROUNT_SIDE_STATUS.danger,
        alertVisible: true,
      });
      setTimeout(() => {
        this.setState({
          alertVisible: false,
          status: null,
          error: null,
        });
      }, 10000);
      return;
    }
    userName = name ? name : firstName + lastName;
    console.log({platform}, "device check --- 1")
    fetchFeedbackSubmit(rating, additionalComment, userName, feedbackId, constants.OLD_DESIGN, platform).then(res => {
      this.setState({
        successFeedback: true,
      });
      fetchFeedback(devMode, constants.OLD_DESIGN, platform);
    }).catch(e => console.log(e));
  }

  closeModal = (id) => {
    const { feedbackCloseFunc, fetchSurvey } = this.props;
    const { devMode } = this.state;

    feedbackCloseFunc(id);

    this.setState({
      modalOpen: false,
    });

    fetchSurvey(devMode);
  }

  toggleRedeemGift = () => {
    this.setState({
      successFeedback: false,
      modalOpen: false,
    });
  }

  handleAnotherAnswer = (e) => {
    this.setState({
      anotherAnswerOption: e.target.value,
    })
  }

  handleCheckedQuestionAnswer = (e, type) => {
    const { checkedQuestionAnswer } = this.state;

    let temp = [...checkedQuestionAnswer];
    if (type === 'type2') {
      temp = [];
      const index = temp.indexOf(e.target.name);
      if (index === -1) {
        temp.push(e.target.name);
      }
    } else {
      const index = temp.indexOf(e.target.name);
      if (index === -1) {
        temp.push(e.target.name);
      } else {
        temp.splice(index, 1);
      }
    }

    this.setState({
      checkedQuestionAnswer: temp,
    });
  }

  handleSurveySubmit = (surveyKey, questionType) => {
    const { submitAnswerForSurveyQuestion, name, firstName, lastName, surveys, t } = this.props;
    const { checkedQuestionAnswer, additionalComment, anotherAnswerOption } = this.state;
    if (!surveyKey) return;

    if (questionType === constants.SURVEY_TYPE[2].id && checkedQuestionAnswer.length === 0) {
      this.setState({
        error: t('common.noChoice'),
        status: constants.FROUNT_SIDE_STATUS.danger,
        alertVisible: true,
      });
      setTimeout(() => {
        this.setState({
          alertVisible: false,
          status: null,
          error: null,
        });
      }, 10000);
      return;
    }

    if (questionType === constants.SURVEY_TYPE[0].id && (!additionalComment || additionalComment === '' || additionalComment.trim().length === 0)) {
      this.setState({
        error: t('common.noAnswer'),
        status: constants.FROUNT_SIDE_STATUS.danger,
        alertVisible: true,
      });
      setTimeout(() => {
        this.setState({
          alertVisible: false,
          status: null,
          error: null,
        });
      }, 10000);
      return;
    }

    if (questionType === constants.SURVEY_TYPE[1].id && size(checkedQuestionAnswer) === 0) return;
    const userName = name ? name : firstName + lastName;
    if (questionType !== constants.SURVEY_TYPE[0].id) {
      this.setState({
        answerList: [...surveys[0].answerList],
      })
    }
    
    const data = {
      surveyKey,
      questionType,
      userName,
      checkedQuestionAnswer,
      additionalComment,
      // anotherAnswerOption,
      design: 'old'
    };

    submitAnswerForSurveyQuestion(data).then(res => {
      if (res.status === constants.FROUNT_SIDE_STATUS.success ) {
        this.setState({
          successSurvey: true,
          // surveyIsOpen: false,
          surveyResults: res.data,
          surveyQuestionType: questionType,
        })
      }
    });
  }

  closeSurvey = () => {
    const { fetchSurvey } = this.props;
    const { devMode } = this.state;
    this.setState({
      surveyQuestionType: null,
      checkedQuestionAnswer: [],
      successSurvey: false,
      surveyIsOpen: false,
    })
    fetchSurvey(devMode);
  }

  handleAllowAnswer = () => {
    this.setState(prevState => ({
      allowAnswerOption: !prevState.allowAnswerOption, 
    }));
  }

  calculateSurveyResult = (type, answerLen) => {
    const { surveyResults } = this.state;

    const totalSubmittedType = sumBy(values(surveyResults), item => size(item.checkedQuestionAnswer));
    const valueCounts = countBy(flatMap(values(surveyResults), 'checkedQuestionAnswer'));

    return Math.floor((((valueCounts[type] || 0) / totalSubmittedType) * 100) * 100) / 100;
  }

  render() {
    const {
      t,
      // member,
      run,
      fontMode,
      surveys,
      feedbacks,
    } = this.props;
    const {
      additionalComment,
      loading,
      rating,
      status,
      error,
      alertVisible,
      modalOpen,
      limitCount,
      successFeedback,
      answerList,
      anotherAnswerOption,
      checkedQuestionAnswer,
      surveyIsOpen,
      surveyQuestionType,
      successSurvey,
      allowAnswerOption,
      devMode,
      curDate,
    } = this.state;
    // console.log('feedback test 111', surveys[0]?.selectedDesigns, userIsSurvey);

    // const devMode = false;

    let isSurveryEnv = false;
    let isFeedbackEnv = false;

    if (surveys[0]) {
      if (devMode && filter(surveys[0]?.selectedDesigns, (item) => item && item?.value === 'old')[0]) {
        isSurveryEnv = true;
      } else if (!devMode && !surveys[0].devMode && filter(surveys[0]?.selectedDesigns, (item) => item && item?.value === 'old')[0]) {
        isSurveryEnv = true;
      }
    }
    if (feedbacks[0]) {
      const currentPlatform = getCurrentPlatform(false);
      const platformAllowed = feedbacks[0].platforms.filter(platform => platform.value === currentPlatform).length > 0;
      const designAllowed = feedbacks[0].designs.filter(design => design.value === 'old').length > 0;
      isFeedbackEnv = platformAllowed && designAllowed;
    }
    // console.log(devMode, surveys[0], "check===========" )

    // console.log('isSurveryEnv', isSurveryEnv, devMode);

    return (
      <>
        {
          !run && feedbacks[0] && modalOpen && isFeedbackEnv && (
            <div className="feedback-section">
              <div className="feedback-header original-feedback-header" style={{ width: '100%'}}>
                <div className="feedback-title">
                  {t('common.rateGame')}
                </div>
                <div className="feedback-close feedback-header-close" onClick={() => this.closeModal(feedbacks[0]?.key)}><Media src={closeImg} className="notification-header-close" alt="X" /></div>
              </div>
              <div className="feedback-error-section">
                {
                  alertVisible && status && error && (
                    <Alert color={status} isOpen={alertVisible}>
                      <h5 className="alert-heading alert-heading-message">{status === constants.FROUNT_SIDE_STATUS.success ? t('support.success') : t('support.appearError')}</h5>
                      <p>{error}</p>
                    </Alert>
                  )
                }
              </div>
              <div className="feedback-body">
                <div className='feedback-body-rating-stars'>
                  <Label>{t('common.selectRate')}</Label>
                  <StarRating handleRating={this.handleRating} rating={rating} t={t} />
                </div>
                <div className='feedback-body-rating-comments'>
                  <Input
                    type="textarea"
                    name="additionalComment"
                    id="additionalComment"
                    maxLength={constants.FEEDBACK_LENGTH.toString()}
                    value={additionalComment}
                    disabled={loading}
                    onChange={this.handleChange}
                    placeholder={t('common.additionalComment')}
                  />
                  <Label className={limitCount > 10 ? 'feedback-body-rating-comments-limit-characters' : 'feedback-body-rating-comments-limited-characters'}>{limitCount}</Label>
                </div>
              </div>
              <div className="feedback-footer">
                <Button className="feedback-footer-button" onClick={() => this.handleFeedback(feedbacks[0]?.key)}>{t('common.submit')}</Button>
                <Button className="feedback-footer-button" onClick={() => this.closeModal(feedbacks[0]?.key)}>{t('common.close')}</Button>
              </div>
            </div>
          )
        }
        {
          !run && size(feedbacks) === 0 && size(surveys) > 0 && surveyIsOpen && isSurveryEnv && (!surveys[0].isPlan || (surveys[0].isPlan && curDate >= surveys[0].startDate && curDate < surveys[0].endDate)) && (
            <div className="feedback-section">
              <div className="feedback-header">
                <div className="feedback-close feedback-header-close" onClick={this.closeSurvey}><Media src={closeImg} className="notification-header-close" alt="X" /></div>
              </div>
              <div className="feedback-error-section">
                {
                  alertVisible && status && error && (
                    <Alert color={status} isOpen={alertVisible}>
                      <h5 className="alert-heading alert-heading-message">{status === constants.FROUNT_SIDE_STATUS.success ? t('support.success') : t('support.appearError')}</h5>
                      <p>{error}</p>
                    </Alert>
                  )
                }
              </div>
              {
                !successSurvey ? (
                  <>
                    <div className="feedback-body feedback-body-survey">
                      <div className='feedback-body-question-stars feedback-survey-question d-flex flex-column'>
                        <Label className='title'>{surveys[0].question}</Label>
                        <Label className='description'>{surveys[0].description}</Label>
                      </div>
                      {
                        surveys[0].questionType === constants.SURVEY_TYPE[0].id ? (
                          <div className='feedback-body-rating-comments'>
                            <Input
                              type="textarea"
                              name="additionalComment"
                              id="additionalComment"
                              maxLength={constants.FEEDBACK_LENGTH.toString()}
                              value={additionalComment}
                              disabled={loading}
                              onChange={this.handleChange}
                              placeholder='Tava atbilde'
                            />
                            <Label className={limitCount > 10 ? 'feedback-body-rating-comments-limit-characters' : 'feedback-body-rating-comments-limited-characters'}>{limitCount}</Label>
                          </div>
                        ) : surveys[0].questionType === constants.SURVEY_TYPE[1].id ? (
                          <div className='feedback-body-rating-comments survey-select-option'>
                            <div className='feedback-body-rating-comments survey-body-question-answers'>
                              {surveys[0].answerList && surveys[0].answerList && map(surveys[0].answerList, (item) => (
                                  <FormGroup >
                                    <Input type="radio" name={item.id} key={item.key} checked={includes(checkedQuestionAnswer, item.id)} onChange={(e) => this.handleCheckedQuestionAnswer(e, surveys[0].questionType)} />
                                    <Label>{item.value}</Label>
                                  </FormGroup>
                                ))
                              }
                              {/* <FormGroup>
                                <Label>Allow Answer Comment</Label>
                                <Input type="checkbox" name="allowAnswerOption" style={{ marginLeft: '-20px', position: 'absolute', left: '0px' }} value={allowAnswerOption} onChange={this.handleAllowAnswerChange} />
                              </FormGroup> */}
                              {/* <FormGroup>
                                <Input type="text" name="anotherAnswerOption" style={{ marginLeft: '-20px' }} value={anotherAnswerOption} disabled={!allowAnswerOption} placeholder={t('common.anotherOption')} onChange={this.handleAnotherAnswer} />
                              </FormGroup> */}
                            </div>
                          </div>
                        ) : (
                          <div className='feedback-body-rating-comments survey-body-question-answers'>
                            {surveys[0].answerList && map(surveys[0].answerList, (item) => {
                                if (!item || item.value === '') return;

                                return (
                                  <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                                    <Input className="mt-0" type="checkbox" name={item.id} key={item.key} checked={includes(checkedQuestionAnswer, item.id)} onChange={(e) => this.handleCheckedQuestionAnswer(e, surveys[0].questionType)} />
                                    <Label style={{ marginBottom: '0' }}>{item.value}</Label>
                                  </FormGroup>
                                );
                              })
                            }
                            {/* <FormGroup className='mb-0'>
                              <Input type="checkbox" name="allowAnswerOption" style={{ marginLeft: '-20px' }} value={allowAnswerOption} onChange={this.handleAllowAnswerChange} />
                              <Label>Allow Answer Comment</Label>
                            </FormGroup>
                            <FormGroup>
                              <Input type="text" name="anotherAnswerOption" style={{ marginLeft: '-20px' }} value={anotherAnswerOption} disabled={!allowAnswerOption} placeholder={t('common.anotherOption')} onChange={this.handleAnotherAnswer} />
                            </FormGroup> */}

                          </div>
                        )
                      }
                    </div>
                    <div className="feedback-footer">
                      <Button className="feedback-footer-button" style={{ marginLeft: 0 }} onClick={() => this.handleSurveySubmit(surveys[0].key, surveys[0].questionType)}>{t('common.submit')}</Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="feedback-body feedback-body-survey">
                      {
                        (surveyQuestionType === 'type3' || surveyQuestionType === 'type2') && (
                          <div className='feedback-body-question-stars feedback-survey-question d-flex flex-column'>
                            <Label className='title'>{surveys[0].question}</Label>
                            <Label className='description'>{surveys[0].description}</Label>
                          </div>
                        )
                      }
                      {
                        surveyQuestionType === 'type1' ? (
                          <div className='feedback-body-rating-comments feedback-survey-question d-flex align-items-center '>
                            <Media src={checkAction} className="event-tree-svg-before" />
                            <Label>{t('common.sentSuccess')}</Label>
                          </div>
                        ) : (
                          <div className='feedback-body-rating-comments feedback-survey-question'>
                            {
                              answerList && map(answerList, (item) => {
                                if (!item || item.value === '') return;
  
                                return (
                                  <div className='d-flex flex-column mb-4'>
                                    <div className='w-full d-flex flex-row items-center justify-between'>
                                      <label>{item.value}</label>
                                      <label className="ml-1 my-info-statistics-data">{`${this.calculateSurveyResult(item.id, size(answerList))}%`}</label>
                                    </div>
                                    <div className='w-full'>
                                      <Progress
                                        color="link"
                                        value={this.calculateSurveyResult(item.id, size(answerList))}
                                        className="my-info-statistics-progressbar"
                                      />
                                    </div>
                                  </div>
                                );
                              })
                            }
                          </div>
                        )
                      }
                    </div>
                    <div className="feedback-footer">
                      <Button className="feedback-footer-button" style={{ marginLeft: 0 }} onClick={() => this.closeSurvey()}>{t('common.ok')}</Button>
                    </div>
                  </>
                )
              }
            </div>
            
          )
        }
        <Modal container={'div > div'} isOpen={successFeedback} toggle={this.toggleRedeemGift} size="lg" className={`old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={this.toggleRedeemGift}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleRedeemGift} />
            }
          >
            {t('common.rateGame')}
          </ModalHeader>
          <ModalBody>
            <div className="feedback-success-modal">
              <div className="redeem-code-header">
                {t('common.completedFeedback')}
              </div>
              <div className="redeem-code-body">
                <div className="w-100 d-flex">
                  <Button color="link" className="buy-page-premium-button full-width" onClick={() => this.toggleRedeemGift()}>
                    {t('common.ok')}
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  // member: state.member,
  name: state.member.name || '',
  firstName: state.member.firstName || '',
  lastName: state.member.lastName || '',
  surveys: state.member.surveys || [],
  feedbacks: state.member.feedbacks || [],
});

const mapDispatchToProps = {
  fetchFeedback: getFeedback,
  fetchSurvey: getSurvey,
  fetchFeedbackSubmit: feedbackSubmit,
  submitAnswerForSurveyQuestion: answerForSurveyQuestion,
  feedbackCloseFunc: feedbackClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Feedback));
