import React, { useState, useRef, useEffect } from 'react';
import config from '../../../../constants/config';

// this component was created because of weird behaviour of ScrollAreaWrapper scrollbar, which could be because it tries to add custom scrollbar on top of built-in one,
// in here, only built-in scrollbar is modified and buttons added on top of it (if app is in iFrame)
const ScrollAreaNew = ({ children, maxHeight, height, overflowX, overflowY, customClassName }) => {
  console.log('ScrollAreaNew', maxHeight, height);

	const [hasScrollbar, setHasScrollbar] = useState(false);
	const scrollRef = useRef();
	const isInAppFrame = config.isInAppFrame();

	const updateScrollbarStatus = () => {
		const contentElement = scrollRef.current;
		if (contentElement) {
			const hasVerticalScrollbar = contentElement.scrollHeight > contentElement.clientHeight;
			setHasScrollbar(hasVerticalScrollbar);
		}
	};

	useEffect(() => {
		updateScrollbarStatus();

		const resizeHandler = () => {
			updateScrollbarStatus();
		};
		window.addEventListener('resize', resizeHandler);

		const observer = new MutationObserver(updateScrollbarStatus);
		const observerConfig = { attributes: true, childList: true, subtree: true };
		observer.observe(scrollRef.current, observerConfig);

		return () => {
			window.removeEventListener('resize', resizeHandler);
			observer.disconnect();
		};
	}, []);

	const scrollContent = (amount) => {
		if (scrollRef.current) {
			scrollRef.current.scrollTop += amount;
		}
	};

	const scrollButtonWrapperStyle = {
		width: 14,
		height: 14,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		borderRadius: '20%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		zIndex: 1,
	};
	const scrollButtonWrapperStyleUp = { ...scrollButtonWrapperStyle, right: 0, top: 0 };
	const scrollButtonWrapperStyleDown = { ...scrollButtonWrapperStyle, right: 0, bottom: 0 };

	const scrollButtonStyle = {
		width: 0,
		height: 0,
		borderLeft: '5px solid transparent',
		borderRight: '5px solid transparent',
		position: 'absolute',
		top: 2
	};
	const scrollButtonStyleUp = { ...scrollButtonStyle, borderBottom: '8px solid white' };
	const scrollButtonStyleDown = { ...scrollButtonStyle, borderTop: '8px solid white' };

	const scrollButtons = (
		<>
			<div onClick={() => scrollContent(-50)} style={scrollButtonWrapperStyleUp}>
				<div style={scrollButtonStyleUp} />
			</div>
			<div onClick={() => scrollContent(50)} style={scrollButtonWrapperStyleDown}>
				<div style={scrollButtonStyleDown} />
			</div>
		</>
	);

	return (
		<div className={customClassName || ''} style={{ width: '100%', height: height || '100%', maxHeight: maxHeight || 'unset', position: 'relative', overflowX: overflowX || 'hidden', overflowY: overflowY || 'scroll' }}>
			{isInAppFrame && hasScrollbar && scrollButtons}
			<div className={`layout-mobile-body-main-scrollarea ${isInAppFrame && 'thick-scrollbar'}`} style={{ position: 'relative' }} ref={scrollRef}>
				{children}
			</div>
		</div>
	);
}
export default ScrollAreaNew;
