import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/* import {
  Button,
  Media,
  UncontrolledTooltip,
} from 'reactstrap'; */

import Media from 'reactstrap/lib/Media';
// import Button from 'reactstrap/lib/Button';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';

const PlayerGift = React.memo(({
  index, gifts, uid, posClassName, userGift,
}) => {
//  let giftClass = `${posClassName}-gift`;

  /*  if (index === 0) {
    giftClass = 'player-left-gift';
  } else if (index === 1) {
    giftClass = 'player-firstperson-gift';
  } else if (index === 2) {
    giftClass = 'player-right-gift';
  } else if (index === 3) {
    giftClass = 'player-sittingOut-gift';
  } */

  return (
    <Fragment>

      <div className={`player-gift-added ${posClassName ? `${posClassName}-gift` : null}`}>
        {gifts && uid && userGift && (
          <>
            <Media
              className="player-receivedGift-image"
              id={`player-receivedGift-${index}`}
              trigger="hover"
              src={gifts[userGift.giftId] ? gifts[userGift.giftId].image : ''}
            />
            <UncontrolledTooltip
              className="player-receivedGift-tooltip player-receivedGift-tooltip-old"
              placement="bottom"
              target={`player-receivedGift-${index}`}
              container={'div > div > div > div'}
            >
              <div className="player-receivedGift-tooltip-from">
                {userGift.fromName || ''}
              </div>
              <div className="player-receivedGift-tooltip-comment">
                {userGift.comment || ''}
              </div>
            </UncontrolledTooltip>
          </>
        )}
      </div>
    </Fragment>
  );
});

PlayerGift.propTypes = {
  index: PropTypes.number,
  gifts: PropTypes.shape(),
  //  roomGifts: PropTypes.shape(),
  userGift: PropTypes.shape(),
  uid: PropTypes.string,
  posClassName: PropTypes.string,
  toggleGiftsModal: PropTypes.func.isRequired,
};

PlayerGift.defaultProps = {
  index: null,
  gifts: null,
  //  roomGifts: null,
  userGift: null,
  uid: null,
  posClassName: null,
};

export default PlayerGift;
