import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import isEqual from 'react-fast-compare';

import ReactGA from 'react-ga';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import previousRoundImg from '../../../images/Game/previous_round.webp';
import previousRoundHeaderImg from '../../../images/Game/previous_round_header.png';
import closeImg from '../../../images/icons/close.png';

import {
  getPreviousRound,
} from '../../../actions/game';

import {
  FONT_MODE,
} from '../../../constants/constants';

import config from '../../../constants/config';

const isInAppFrame = config.isInAppFrame();

class PreviousRound extends React.Component {
  static propTypes = {
    cards: PropTypes.arrayOf(PropTypes.string),
    //  gameState: PropTypes.string,
    t: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    fetchPreviousRound: PropTypes.func.isRequired,
    //  cardPlayed: PropTypes.string,
    //  currentTurnUid: PropTypes.string,
    //  memberUid: PropTypes.string,
    fourPRoom: PropTypes.bool,
    roomId: PropTypes.string,
    previousRound: PropTypes.shape(),
    players: PropTypes.shape(),
    fontMode: PropTypes.string,
  //  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    cards: [],
    //  gameState: null,
    //  cardPlayed: null,
    //  currentTurnUid: null,
    //  memberUid: null,
    roomId: null,
    fourPRoom: false,
    previousRound: null,
    players: null,
    fontMode: FONT_MODE.normal,
  //  currentTable: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  componentDidMount() {
  }

  shouldComponentUpdate(nextProps, nextState) {
  //  const { previousRound } = nextProps;
    const { previousRound } = this.props;

    //  const { modalOpen } = nextState;
    const { modalOpen } = this.state;

    if (nextState.modalOpen !== modalOpen) {
      return true;
    }

    //  if (JSON.stringify(previousRound) !== JSON.stringify(curPreviousRound)) {
    //    return true;
    //  }

    if (!isEqual(nextProps.previousRound, previousRound)) {
      return true;
    }

    return false;
  }

  toggle = () => {
    const { fetchPreviousRound, playButtonSound, roomId } = this.props;
    const { modalOpen } = this.state;

    playButtonSound();
    if (modalOpen) {
      this.setState({
        modalOpen: false,
      });
    } else {
      fetchPreviousRound(roomId).then(() => {
        ReactGA.event({
          category: 'Game',
          action: 'Open previous round',
        });

        this.setState({
          modalOpen: true,
        });
      });
    }
  };

  render() {
    const {
      previousRound, players, t, fourPRoom, fontMode,
    } = this.props;
    const { modalOpen } = this.state;

    const { lastDealtCards, beatCardPoints, playedTricks } = previousRound;
console.log("previousround===", previousRoundImg)
    return (
      <>
        <div className="previous-round">
          <Media
            src={previousRoundImg}
            className="previous-round-image"
            onClick={this.toggle}
          />
        </div>
        <Modal
          container={'div > div'}
          size="lg"
          isOpen={modalOpen}
          toggle={this.toggle}
          className={`previous-round-modal old-modal root-font-${fontMode} ${isInAppFrame ? 'modal-is-app-frame' : ''}`}
        >
          <ModalHeader
            toggle={this.toggle}
            className="previous-round-modal-header"
            close={(
              <Media
                src={closeImg}
                className="previous-round-modal-header-close"
                alt="X"
                onClick={this.toggle}
              />
            )}
          >
            <div className="previous-round-header">
              <Media
                src={previousRoundHeaderImg}
                className="previous-round-header-image"
                onClick={this.toggle}
              />
              <div className="previous-round-header-text">
                {t('previousRoundHeader')}
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="previous-round-modal-body">
            {lastDealtCards && lastDealtCards.player1 && (
              <div className="previous-round-player1">
                <Row>
                  <Col sm="4" className="previous-round-player-cards">
                    {t('previousRoundDealtCards')}
                  </Col>
                  <Col sm="4" className="previous-round-player-name">
                    {players && players.player1 && players.player1.name}
                  </Col>
                  <Col sm="4" className="previous-round-player-tricks">
                    {t('previousRoundTakenTricks')}
                  </Col>
                </Row>
                <Row className="previous-round-row">
                  <Col sm="3">
                    <div className="previous-round-cards">
                      {lastDealtCards.player1.map((card, index) => (
                        <div
                          className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                        />
                      ))}
                    </div>
                  </Col>
                  <Col sm="9">
                    <div className="previous-round-played-tricks">
                      {playedTricks ? (Object.keys(playedTricks).reverse().map((key, index) => (
                        <>
                          {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player1' ? (
                            <>
                              {(playedTricks[key].cards && playedTricks[key].cards.length > 1) ? (
                                <div className="previous-round-taken-cards">
                                  {playedTricks[key].cards.map((card, index2) => (
                                    <div
                                      style={{ zIndex: 15 - index }}
                                      className={`previous-round-taken-card previous-round-taken-card-${index2} previous-round-card-${card.card}`}
                                    />
                                  ))}
                                </div>
                              ) : (
                                <div className="previous-round-burried-cards">
                                  {playedTricks[key].cards.map((card, index2) => (
                                    <div
                                      style={{ zIndex: 15 - index }}
                                      className={`previous-round-taken-card previous-round-taken-card-${index2} previous-round-card-${card.card}`}
                                    />
                                  ))}
                                </div>
                              )}
                            </>
                          ) : (null)}
                        </>
                      ))) : (null)}
                    </div>
                  </Col>
                </Row>
                <div className="previous-round-points">
                  {beatCardPoints && beatCardPoints.tricks && (
                    <>
                      <b className="display-inline">
                        {`${beatCardPoints.player1 || 0} `}
                      </b>
                      <p className="display-inline">{t('pointsWith')}</p>
                      <b className="display-inline">
                        {` ${beatCardPoints.tricks.player1 || 0} `}
                      </b>
                      <p className="display-inline">
                        {`${
                          beatCardPoints.tricks.player1 === 1
                            ? t('trick')
                            : t('tricks')
                        }`}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
            {lastDealtCards && lastDealtCards.player2 && (
              <div className="previous-round-player2">
                <Row>
                  <Col sm="4" className="previous-round-player-cards">
                    {t('previousRoundDealtCards')}
                  </Col>
                  <Col sm="4" className="previous-round-player-name">
                    {players && players.player2 && players.player2.name}
                  </Col>
                  <Col sm="4" className="previous-round-player-tricks">
                    {t('previousRoundTakenTricks')}
                  </Col>
                </Row>
                {/*  <div className="previous-round-cards">
                      {lastDealtCards.player2.map((card, index) => (
                        <div
                          className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                        />
                      ))}
                </div>  */}
                <Row className="previous-round-row">
                  <Col sm="3">
                    <div className="previous-round-cards">
                      {lastDealtCards.player2.map((card, index) => (
                        <div
                          className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                        />
                      ))}
                    </div>
                  </Col>
                  <Col sm="9">
                    <div className="previous-round-played-tricks">
                      {playedTricks ? (Object.keys(playedTricks).reverse().map(key => (
                        <>
                          {/*  {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player2' ? (
                            <div className="previous-round-taken-cards">
                              {playedTricks[key].cards.map((card, index) => (
                                <div
                                  className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                />
                              ))}
                            </div>
                          ):(null)} */}
                          {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player2' ? (
                            <>
                              {(playedTricks[key].cards && playedTricks[key].cards.length > 1) ? (
                                <div className="previous-round-taken-cards">
                                  {playedTricks[key].cards.map((card, index) => (
                                    <div
                                      className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                    />
                                  ))}
                                </div>
                              ) : (
                                <div className="previous-round-burried-cards">
                                  {playedTricks[key].cards.map((card, index) => (
                                    <div
                                      className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                    />
                                  ))}
                                </div>
                              )}
                            </>
                          ) : (null)}
                        </>
                      ))) : (null)}
                    </div>
                  </Col>
                </Row>
                <div className="previous-round-points">
                  {beatCardPoints && beatCardPoints.tricks && (
                    <>
                      <b className="display-inline">
                        {`${beatCardPoints.player2 || 0} `}
                      </b>
                      <p className="display-inline">{t('pointsWith')}</p>
                      <b className="display-inline">
                        {` ${beatCardPoints.tricks.player2 || 0} `}
                      </b>
                      <p className="display-inline">
                        {`${
                          beatCardPoints.tricks.player2 === 1
                            ? t('trick')
                            : t('tricks')
                        }`}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
            {lastDealtCards && lastDealtCards.player3 && (
              <div className="previous-round-player3">
                <Row>
                  <Col sm="4" className="previous-round-player-cards">
                    {t('previousRoundDealtCards')}
                  </Col>
                  <Col sm="4" className="previous-round-player-name">
                    {players && players.player3 && players.player3.name}
                  </Col>
                  <Col sm="4" className="previous-round-player-tricks">
                    {t('previousRoundTakenTricks')}
                  </Col>
                </Row>
                {/*  <div className="previous-round-cards">
                      {lastDealtCards.player3.map((card, index) => (
                        <div
                          className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                        />
                      ))}
                </div> */}
                <Row className="previous-round-row">
                  <Col sm="3">
                    <div className="previous-round-cards">
                      {lastDealtCards.player3.map((card, index) => (
                        <div
                          className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                        />
                      ))}
                    </div>
                  </Col>
                  <Col sm="9">
                    <div className="previous-round-played-tricks">
                      {playedTricks ? (Object.keys(playedTricks).reverse().map(key => (
                        <>
                          {/*  {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player3' ? (
                            <div className="previous-round-taken-cards">
                              {playedTricks[key].cards.map((card, index) => (
                                <div
                                  className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                />
                              ))}
                            </div>
                          ):(null)}  */}
                          {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player3' ? (
                            <>
                              {(playedTricks[key].cards && playedTricks[key].cards.length > 1) ? (
                                <div className="previous-round-taken-cards">
                                  {playedTricks[key].cards.map((card, index) => (
                                    <div
                                      className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                    />
                                  ))}
                                </div>
                              ) : (
                                <div className="previous-round-burried-cards">
                                  {playedTricks[key].cards.map((card, index) => (
                                    <div
                                      className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                    />
                                  ))}
                                </div>
                              )}
                            </>
                          ) : (null)}
                        </>
                      ))) : (null)}
                    </div>
                  </Col>
                </Row>
                <div className="previous-round-points">
                  {beatCardPoints && beatCardPoints.tricks && (
                    <>
                      <b className="display-inline">
                        {`${beatCardPoints.player3 || 0} `}
                      </b>
                      <p className="display-inline">{t('pointsWith')}</p>
                      <b className="display-inline">
                        {` ${beatCardPoints.tricks.player3 || 0} `}
                      </b>
                      <p className="display-inline">
                        {`${
                          beatCardPoints.tricks.player3 === 1
                            ? t('trick')
                            : t('tricks')
                        }`}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
            {fourPRoom && lastDealtCards && lastDealtCards.player4 && (
              <div className="previous-round-player4">
                <Row>
                  <Col sm="4" className="previous-round-player-cards">
                    {t('previousRoundDealtCards')}
                  </Col>
                  <Col sm="4" className="previous-round-player-name">
                    {players && players.player4 && players.player4.name}
                  </Col>
                  <Col sm="4" className="previous-round-player-tricks">
                    {t('previousRoundTakenTricks')}
                  </Col>
                </Row>
                <Row className="previous-round-row">
                  <Col sm="3">
                    <div className="previous-round-cards">
                      {lastDealtCards.player4.map((card, index) => (
                        <div
                          className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                        />
                      ))}
                    </div>
                  </Col>
                  <Col sm="9">
                    <div className="previous-round-played-tricks">
                      {playedTricks ? (Object.keys(playedTricks).reverse().map(key => (
                        <>
                          {/*  {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player4' ? (
                            <div className="previous-round-taken-cards">
                              {playedTricks[key].cards.map((card, index) => (
                                <div
                                  className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                />
                              ))}
                            </div>
                          ):(null)}  */}
                          {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player4' ? (
                            <>
                              {(playedTricks[key].cards && playedTricks[key].cards.length > 1) ? (
                                <div className="previous-round-taken-cards">
                                  {playedTricks[key].cards.map((card, index) => (
                                    <div
                                      className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                    />
                                  ))}
                                </div>
                              ) : (
                                <div className="previous-round-burried-cards">
                                  {playedTricks[key].cards.map((card, index) => (
                                    <div
                                      className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                    />
                                  ))}
                                </div>
                              )}
                            </>
                          ) : (null)}
                        </>
                      ))) : (null)}
                    </div>
                  </Col>
                </Row>
                <div className="previous-round-points">
                  {beatCardPoints && beatCardPoints.tricks && (
                    <>
                      <b className="display-inline">
                        {`${beatCardPoints.player4 || 0} `}
                      </b>
                      <p className="display-inline">{t('pointsWith')}</p>
                      <b className="display-inline">
                        {` ${beatCardPoints.tricks.player4 || 0} `}
                      </b>
                      <p className="display-inline">
                        {`${
                          beatCardPoints.tricks.player4 === 1
                            ? t('trick')
                            : t('tricks')
                        }`}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="previous-round-tableCards">
              <div className="previous-round-player-name">
                {t('tableCards')}
              </div>
              <div className="previous-round-table-cards">
                {lastDealtCards && lastDealtCards.cardsOnTable && (
                  <>
                    {lastDealtCards.cardsOnTable.map((card, index) => (
                      <div
                        className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="previous-round-modal-footer">
            <Button
              type="button"
              className="previous-round-modal-footer-button"
              color="secondary"
              onClick={this.toggle}
            >
              {t('ok')}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  //  fastGame:state.game.globalParams.fastGame,
  //  sittingOut:state.game.sittingOut,
  //  currentTable:state.game.currentTable,
  previousRound: state.game.previousRound || {},
  players: state.game.players,
  fourPRoom: state.game.globalParams.fourPRoom,
  //  roomId: roomId,
});
const mapDispatchToProps = {
  fetchPreviousRound: getPreviousRound,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('previousRound')(PreviousRound));
